import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 5rem;
  margin: 0 5px 0 5px;
`

type Props = {
  onChange?: (value: SelectValue) => void
  onClear?: () => void
  disabled?: boolean
  value?: number
  defaultValue?: number
}

export const BillStatusSelector = (props: Props) => {
  const { value, disabled, defaultValue } = props

  function onChange(givenValue: any) {
    Number.isInteger(givenValue) &&
      props.onChange &&
      props.onChange(givenValue.toString())
  }

  function onClear() {
    props.onClear && props.onClear()
  }

  const options = [
    {
      value: 1,
      label: 'Unpaid',
    },
    {
      value: 2,
      label: 'Paid',
    },
  ]

  return (
    <StyledSelect
      style={{ minWidth: 100 }}
      placeholder="Please select"
      value={value ? value : undefined}
      onChange={onChange}
      onClear={onClear}
      disabled={disabled}
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={true}
      allowClear
    >
      {options.map((option) => {
        return (
          <Option key={option?.value - 1} value={option?.value - 1}>
            {option?.label}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
