import axios, { CancelTokenSource } from 'axios'

import { environment } from 'environment'
import moment from 'moment'
const { apiBaseUrl } = environment

export const getCollectors = async () => {
  const url = `${apiBaseUrl}/users/collectors`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }

  return result.data
}

export const getCollectorByCode = async (code: string) => {
  const url = `${apiBaseUrl}/users/collectors`
  console.log('url: ===>', url)

  const result = await axios.get(url, { params: { code } })
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const deleteById = async (id: any) => {
  const url = `${apiBaseUrl}/api/collectors/${id}`
  const response = await axios.delete(url)

  const success = response.status === 200
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const edit = async (values: any) => {
  const { _id: id, ...data } = values
  const url = `${apiBaseUrl}/api/collectors/${id}`
  const response = await axios.patch(url, data).catch((error) => {
    throw new Error('Cannot update. ' + error?.response?.data?.message)
  })

  if (response) {
    const success = response.status === 200
    if (!success) {
      throw new Error('Failed to update')
    }
    return response.data
  }

  throw new Error('Failed to update')
}

export const add = async (data: any) => {
  const url = `${apiBaseUrl}/api/collectors`
  const response = await axios.post(url, data).catch((error) => {
    throw new Error('Cannot create. ' + error?.response?.data?.message)
  })

  if (response) {
    const success = response.status === 201
    if (!success) {
      throw new Error('Failed to create')
    }
    return response.data
  }

  throw new Error('Failed to create')
}
