import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import { componentLevelAuthorization } from 'Auth/Authorization'
import {
  Admin,
  BillingManager,
  DataEntry,
  IT,
  KSWM,
  WasteCompany,
} from 'Auth/Role'

interface EditButtonProp extends ButtonProps {
  onClick: () => void
  roles?: any
}

export const EditButton = (props: EditButtonProp) => {
  const { roles = [Admin, WasteCompany, KSWM, BillingManager, IT, DataEntry] } =
    props
  function onClick(e: any) {
    e.stopPropagation()
    props.onClick && props.onClick()
  }

  return componentLevelAuthorization(
    <Button
      {...props}
      onClick={onClick}
      shape="circle"
      icon={<EditOutlined />}
    ></Button>,
    roles
  )
}
