import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import React from 'react'

interface DownloadButtonProp {
  onClick?: () => void
}

export const DownloadButton = (props: DownloadButtonProp) => {
  function onClick(e: any) {
    e.stopPropagation()
    props.onClick && props.onClick()
  }

  return (
    <Button
      {...props}
      onClick={onClick}
      shape="circle"
      icon={<DownloadOutlined />}
    ></Button>
  )
}
