import * as api from './api'

import { Progress, message } from 'antd'
import React, { useState } from 'react'

import { FileDrop } from 'Components/FileDrop/FileDrop'
import { propertyTypeStore } from 'Models/PropertyTypeModel/PropertyTypeModel'
//@ts-ignore
import readXlsxFile from 'read-excel-file'
import styled from 'styled-components'

const Container = styled.div``

const Title = styled.div`
  font-size: 2rem;
`

export type PropertyTypeExcelRecord = {
  Code: string
  Name_Khmer: string
  Name_English?: string
  Location_Code?: string
  Unit_Price: number
}

const schema = {
  Code: {
    prop: 'Code',
    type: String,
    required: true,
  },
  Name_Khmer: {
    prop: 'Name_Khmer',
    type: String,
    required: false,
  },
  Name_English: {
    prop: 'Name_English',
    type: String,
    required: false,
  },
  Location_Code: {
    prop: 'Location_Code',
    type: String,
    required: false,
  },
}

interface Props {}
export const ImportFromExcel = (props: Props) => {
  const [saving, setSaving] = useState(false)
  const [addedCount, setAddedCount] = useState(0)
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const percentage = (addedCount / totalRecordCount) * 100

  async function onFilesDrop(files: File[]) {
    const rows = (await readXlsxFile(files[0], { schema }))
      .rows as PropertyTypeExcelRecord[]

    setSaving(true)
    setTotalRecordCount(rows.length)
    try {
      await api.addRecords(rows, function onProgress(givenAddedCount) {
        setAddedCount(givenAddedCount)
      })
      message.success('Import successful')
      propertyTypeStore.loadAll()
    } catch (e: any) {
      message.error('Error: ' + e.message)
    }
  }

  if (saving) {
    return (
      <Container>
        <div>Importing</div>
        <div>
          Added {addedCount}/{totalRecordCount}
        </div>
        <Progress percent={percentage}></Progress>
      </Container>
    )
  }

  return (
    <Container>
      <Title>Import from Excel</Title>
      <FileDrop accept=".xlsx" onDrop={onFilesDrop}></FileDrop>
    </Container>
  )
}
