import { Form, message } from 'antd'
import {
  ICollectionCompany,
  collectionCompanyStore,
} from 'Models/CollectionCompanyModel/CollectionCompanyModel'
import React, { useState } from 'react'

import { CollectionCompanyForm } from '../Form/CollectionCompanyForm'
import moment from 'moment'

interface Prop {
  onSaveFinish?: () => void
}

export const CollectionCompanyAdd = (props: Prop) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  const initialValues = {}

  async function onSubmit(values: any) {
    const data = {
      ...values,
    }
    setSaving(true)
    try {
      await collectionCompanyStore.add(data)
    } catch (e: any) {
      console.error(e)
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return (
    <CollectionCompanyForm
      form={form}
      onSubmit={onSubmit}
      initialValues={initialValues}
      saving={saving}
    ></CollectionCompanyForm>
  )
}
