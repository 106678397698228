import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 3rem;
  margin: 0 5px 0 5px;
`

type Props = {
  onChange?: (value: SelectValue) => void
  onClear?: () => void
  disabled?: boolean
  value?: string
  defaultValue?: string
}

export const paymentMethodOptions = [
  {
    value: 'ABA',
    label: 'ABA - SAMRAAM Mobile',
  },
  {
    value: 'ABA-BILL-PAYMENT',
    label: 'ABA - ABA App',
  },
  {
    value: 'KHQR',
    label: 'KHQR',
  },
]

export const PaymentMethodSelector = (props: Props) => {
  const { value, disabled, defaultValue } = props

  function onChange(givenValue: any) {
    props.onChange && props.onChange(givenValue)
  }

  function onClear() {
    props.onClear && props.onClear()
  }

  return (
    <StyledSelect
      style={{ minWidth: 120 }}
      placeholder="Please select"
      value={value ? value : undefined}
      onChange={onChange}
      onClear={onClear}
      disabled={disabled}
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={true}
      allowClear
    >
      {paymentMethodOptions.map((option) => {
        return (
          <Option key={option?.value} value={option?.value}>
            {option?.label}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
