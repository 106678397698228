import { Button as BaseButton, Modal, Space } from 'antd'
import {
  ICitizen,
  ICustomImport,
  IProperty,
  citizenStore,
  customImportStore,
  propertyStore,
} from 'Models'
import React, { useEffect, useState } from 'react'

import Bluebird from 'bluebird'
import ConfirmationWithProgressModal from 'Components/ConfirmationWithProgressModal/ConfirmationWithProgressModal'
import { CustomImportSelector } from 'Components/CustomImportSelector/CustomImportSelector'
import { PropertyListWithEdit } from 'Pages/PropertyActivation/PropertyListWithEdit'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { handleApiError } from 'Utils'
import { isUserMOE } from 'Auth/Authorization'

const Container = styled.section`
  padding: 20px;
`

const CustomImportSelectorBox = styled(Space)`
  margin-bottom: 20px;
`

const Button = styled(BaseButton)`
  border-radius: 10px;
`

const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
`

let deletedCount = 0

export const ImportedDataPage = observer((props: any) => {
  const [selectCustomImportId, setSelectCustomImportId] = useState<string>()
  const [shouldShowDeleteAllModal, setShouldShowDeleteAllModal] =
    useState(false)
  const [addedCount, setAddedCount] = useState(0)
  const [totalRecordCount, setTotalRecordCount] = useState(1)
  const isMOE = isUserMOE()

  useEffect(() => {
    selectCustomImportId &&
      citizenStore.loadCitizensByImportId(selectCustomImportId)
      .catch(e => handleApiError(e))
  }, [selectCustomImportId])

  async function loadPropertiesByCustomImportId(id: string) {
    try {
      await propertyStore.loadPropertyByImportId(id)
    } catch (e) {
      handleApiError(e)
    }
  }

  async function onCustomImportIdSelected(id: string) {
    setSelectCustomImportId(id)
    loadPropertiesByCustomImportId(id)
  }

  async function deleteAllByCustomId() {
    if (selectCustomImportId) {
      try {
        // delete properties.
        await Bluebird.map(
          propertyStore.properties,
          async (property: IProperty) => {
            await property.delete()
            deletedCount = deletedCount + 1
            setAddedCount(deletedCount)
          },
          {
            concurrency: 5,
          }
        )
        propertyStore.emptyProperties()

        // delete citizen.
        await Bluebird.map(
          citizenStore.citizens,
          async (citizen: ICitizen) => {
            await citizen.delete()
            deletedCount = deletedCount + 1
            setAddedCount(deletedCount)
          },
          {
            concurrency: 5,
          }
        )
        citizenStore.emptyCitizens()

        // delete the importId
        const storeObj = customImportStore.customImports.find(
          (customImport: ICustomImport) => {
            return customImport.id === selectCustomImportId
          }
        )
        await storeObj?.delete()
      } catch (e: any) {
        handleApiError(e)
      }
      deletedCount = deletedCount + 1
      setAddedCount(deletedCount)
      setShouldShowDeleteAllModal(false)
    }
  }

  function prepareForDeletion() {
    if (selectCustomImportId) {
      setAddedCount(0)
      setTotalRecordCount(
        propertyStore.properties.length + citizenStore.citizens.length + 1 // the import id
      )
      setShouldShowDeleteAllModal(true)
    }
  }

  return (
    <Container>
      <HeaderBox>
        <CustomImportSelectorBox direction="vertical">
          <label> Select an Import </label>
          <CustomImportSelector
            onCustomImportIdSelected={onCustomImportIdSelected}
          />
        </CustomImportSelectorBox>

        {!isMOE && <Button danger={true} type="primary" onClick={prepareForDeletion}>
          Delete All
        </Button>}
      </HeaderBox>

      <PropertyListWithEdit
        properties={propertyStore.properties || []}
      ></PropertyListWithEdit>

      {shouldShowDeleteAllModal && (
        <Modal
          title="រក្សាទុកការកែសម្រួលអចលនទ្រព្យ? (Save Edit Property?)"
          visible={shouldShowDeleteAllModal}
          onCancel={() => setShouldShowDeleteAllModal(false)}
          footer={[]}
          width={600}
        >
          <ConfirmationWithProgressModal
            header="Deleting Properties, Citizen and it's importId"
            statusText="deleted"
            addedCount={addedCount}
            totalRecordCount={totalRecordCount}
            onCancelButtonClick={() => setShouldShowDeleteAllModal(false)}
            onConfirmButtonClick={deleteAllByCustomId}
          />
        </Modal>
      )}
    </Container>
  )
})
