import { Blue, PrimaryColor } from 'Utils/Color'
import { Button, DeleteConfirmButton } from 'Components'
import { IKSWM, kswmStore } from 'Models/PropertyDataGatheringModel/KswmModel'
import { Modal, Row, Space, Spin, message } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'

import { Coordinate } from 'Models/Types'
import { EditOutlined } from '@ant-design/icons'
import { KSWMEdit } from './Edit/KswmEdit'
import { KswmAdd } from './Add/KswmAdd'
import { KswmInfo } from './KswmInfo'
import { Map } from 'Components/Map'
import { Marker } from '@react-google-maps/api'
import assignedMarkerIcon from './assets/AssignedAndSelectedMarker.png'
import { observer } from 'mobx-react-lite'
import selectedMarkerIcon from './assets/SelectedMarker.png'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Left = styled.section`
  flex: 1;
  position: relative;
`

const Right = styled.section`
  flex: 0.3;
  height: 100%;
  padding: 30px;
  overflow: scroll;
  background: white;
`

const Spinner = styled(Spin)`
  position: absolute;
  background: white;
  border: 1px solid ${PrimaryColor};
  padding: 18px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
`

const SectionTitle = styled.div`
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: gray;
`

const SmallMarginSectionTitle = styled.div`
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  color: gray;
`

const AddBusinessSection = styled(Space)`
  border-radius: 8px;
  padding: 20px;
  box-shadow: ${Blue} 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
`

const BusinessInfoSection = styled(Space)`
  border-radius: 8px;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

interface Props {}
export const KswmPage = (props: Props) => {
  const { records } = kswmStore
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location: any) => {},
  })
  const [selectedRecord, setSelectedRecord] = useState<IKSWM>()
  const [showEditBusinessModal, setShowEditBusinessModal] = useState(false)
  const [selectedCoordinate, setSelectedCoordinate] = useState<Coordinate>()
  const [deleting, setDeleting] = useState(false)
  const [loadingBusinesses, setLoadingBusinesses] = useState(false)
  const initialCenter = {
    lat: 10.6343527660001,
    lng: 103.503359524,
  }
  const [center, setCenter] = useState(initialCenter)

  useEffect(() => {
    loadBusinesses()
  }, [center])

  function onMapDragEnd() {
    const lat = map.center.lat()
    const lng = map.center.lng()
    setCenter({
      lat,
      lng,
    })
  }

  async function loadBusinesses() {
    setLoadingBusinesses(true)
    const query = {
      geometry: `${center.lng},${center.lat}`,
      distant: 1000,
    }
    await kswmStore.loadNearbyRecords(query)
    setLoadingBusinesses(false)
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  function onBusinessMarkerClick(business: IKSWM) {
    setSelectedRecord(business)
    setSelectedCoordinate(undefined)
  }

  function onMapClick(event: any) {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    setSelectedCoordinate({
      lat,
      lng,
    })

    setSelectedRecord(undefined)
  }

  function onAddBusinissFinished(addedBusiness: IKSWM) {
    setSelectedCoordinate(undefined)
    setSelectedRecord(addedBusiness)
  }

  async function onDeleteClick() {
    setDeleting(true)
    await selectedRecord?.delete()
    setSelectedRecord(undefined)
    setDeleting(false)
  }

  async function onMarkerDragEnd(business: any, event: any) {
    const messageKey = 'update-location'
    message.loading({ content: 'Saving...', key: messageKey })
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    await business.edit({
      geometry: {
        x: lng,
        y: lat,
      },
    })
    message.success({ content: 'Success!', key: messageKey, duration: 2 })
  }

  return (
    <Container>
      <Left>
        <Map
          center={center}
          zoom={15}
          onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
          onClick={onMapClick}
        >
          {records.reduce((businessMarkers: ReactElement<any, any>[], business) => {
            const selected =
              selectedRecord && selectedRecord.OBJECTID == business.OBJECTID

            let markerIcon = selected ? selectedMarkerIcon : assignedMarkerIcon

            const label = business.isSaving ? 'Saving...' : ''
            const iconSize = selected ? 20 : 14
            if (business.geometry && business.geometry.lat && business.geometry.lng) {
              businessMarkers.push(
                // @ts-ignore
                <Marker
                  key={business.OBJECTID}
                  position={business.geometry}
                  onClick={() => onBusinessMarkerClick(business)}
                  label={label}
                  draggable
                  onDragEnd={(event) => onMarkerDragEnd(business, event)}
                  icon={{
                    url: markerIcon,
                    scaledSize: {
                      width: iconSize,
                      height: iconSize,
                    },
                  }}
                ></Marker>
              )
            }

            return businessMarkers
          }, [])}

          {selectedCoordinate && (
            <Marker position={selectedCoordinate}></Marker>
          )}
        </Map>
      </Left>
      <Right>
        {selectedRecord && (
          <BusinessInfoSection direction="vertical" style={{ width: '100%' }}>
            <SmallMarginSectionTitle>Info</SmallMarginSectionTitle>
            <KswmInfo kswm={selectedRecord}></KswmInfo>
            <Row>
              <Space style={{ marginLeft: 'auto' }}>
                <DeleteConfirmButton
                  danger
                  deleting={deleting}
                  onConfirmClick={onDeleteClick}
                  showLabel
                ></DeleteConfirmButton>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => setShowEditBusinessModal(true)}
                >
                  Edit
                </Button>
              </Space>
            </Row>
          </BusinessInfoSection>
        )}

        {selectedCoordinate && (
          <AddBusinessSection direction="vertical" style={{ width: '100%' }}>
            <SectionTitle>Add New Record</SectionTitle>
            <KswmAdd
              onSaveFinish={onAddBusinissFinished}
              coordinate={selectedCoordinate}
            ></KswmAdd>
          </AddBusinessSection>
        )}
      </Right>

      {loadingBusinesses && <Spinner size="large"></Spinner>}

      {showEditBusinessModal && (
        <Modal
          title="កែសម្រួលព័ត៌មាន (Edit Info)"
          visible={showEditBusinessModal}
          onCancel={() => setShowEditBusinessModal(false)}
          footer={[]}
          width={600}
        >
          <KSWMEdit
            record={selectedRecord}
            onSaveFinish={() => setShowEditBusinessModal(false)}
          ></KSWMEdit>
        </Modal>
      )}
    </Container>
  )
}
