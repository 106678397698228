import { Button, PropertyTypeSelector } from 'Components'
import { Form, Input, InputNumber as InputNumberBase, Rate, Switch } from 'antd'

import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'

const InputNumber = styled(InputNumberBase)`
  width: 135px;
`

interface Props {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
  initialValues?: any
}

export const BusinessForm = (props: Props) => {
  const { onSubmit, saving, form, initialValues } = props

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 7 }}
      initialValues={initialValues}
    >
      <Form.Item
        label="Name (Khmer)"
        name="Label_kh"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Name (English)"
        name="Label"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: false, message: 'Required' }]}
      >
        <PropertyTypeSelector></PropertyTypeSelector>
      </Form.Item>

      <Form.Item
        label="Phone"
        name="Phone_No"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Restaurants"
        name="Restaurants"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber></InputNumber>
      </Form.Item>

      <Form.Item
        label="Land Size"
        name="Land_Size"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber></InputNumber>
      </Form.Item>

      <Form.Item
        label="Rooms"
        name="Room"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber></InputNumber>
      </Form.Item>

      <Form.Item
        label="Star Rating"
        name="Star_Rating"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Rate></Rate>
      </Form.Item>

      <Form.Item
        label="Bars"
        name="Bars"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber></InputNumber>
      </Form.Item>

      <Form.Item
        label="Beds"
        name="Bed"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber></InputNumber>
      </Form.Item>

      <Form.Item
        label="Gyms"
        name="Gym"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber></InputNumber>
      </Form.Item>

      <Form.Item
        label="Estimated Fee"
        name="wastec_rev"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber></InputNumber>
      </Form.Item>

      <Form.Item
        label="Actual Fee"
        name="Actual_Collection_Fee"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber></InputNumber>
      </Form.Item>

      <Form.Item
        label="Verified"
        name="Verified_St"
        valuePropName="checked"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Switch></Switch>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
