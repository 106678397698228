import * as api from './api'

import { Instance, applySnapshot, flow, types } from 'mobx-state-tree'

import { Schedule } from 'Models/ScheduleModel'
import { type } from 'node:os'

const Coordinate = types.model('Coordinate', {
  lat: types.number,
  lng: types.number,
  rotation: types.number,
  isEngineOn: types.maybeNull(types.boolean)
})

const Truck = types
  .model('Truck', {
    OBJECTID: types.number,
    UnitID: types.maybeNull(types.string),
    Name: types.maybeNull(types.string),
    RouteID: types.maybeNull(types.string),
    schedules: types.optional(types.array(Schedule), []),
    currentLocation: types.maybeNull(Coordinate),
    isSelected: false,
    isSaving: false,
  })
  .actions((self) => ({
    setRouteId: flow(function* (routeId) {
      const dataToPatch = {
        OBJECTID: self.OBJECTID,
        RouteID: routeId,
      }
      yield api.patchTruck(dataToPatch)
      self.RouteID = routeId
    }),
    loadSchedules: flow(function* () {
      const schedules = yield api.getSchedulesByTruckId(self.OBJECTID + '')
      const mapped = schedules.map((object: any) => {
        return {
          ...object.attributes,
          OBJECTID: object.attributes.OBJECTID + '',
        }
      })
      applySnapshot(self.schedules, mapped)
    }),
    removeSchedule(item: any) {
      self.schedules.splice(self.schedules.indexOf(item), 1)
    },
    getCurrentLocation: flow(function* () {
      if (self.UnitID) {
        const location = yield api.getTruckCurrentLocation(self.UnitID)
        self.currentLocation = location
      }
    }),
    setSelected(selected: boolean) {
      self.isSelected = selected
    },
    setIsSaving(saving: boolean) {
      self.isSaving = saving
    },
  }))

const TruckStore = types
  .model('TruckStore', {
    trucks: types.optional(types.array(Truck), []),
  })
  .actions((self) => ({
    loadTrucks: flow(function* () {
      applySnapshot(self.trucks, [])
      const trucks = yield api.getTrucks()
      const mappedTrucks = trucks.map((truck: any) => {
        return {
          ...truck.attributes,
        }
      })

      applySnapshot(self.trucks, mappedTrucks)
    }),
    setTrucks(trucks: ITruck[]) {
      applySnapshot(self.trucks, trucks)
    },
  }))

export interface ITruck extends Instance<typeof Truck> {}

export const truckStore = TruckStore.create({
  trucks: [],
})

export interface WialonTruck {
  unitId: number
  isEngineOn: boolean | null;

  time?: number; // t
  latitude?: number; // y
  longitude?: number; // x
  course?: number; // c
  altitude?: number; // z
  speed?: number; // s
  satellitesCount?: number; //sc

  flag?: number; // f // not sure what kind of flag it is.
  lc?: number; // lc (not sure what it is)
}
