import { Form, message } from 'antd'
import { IProperty, user } from 'Models'
import React, { useState } from 'react'

import { CollectorForm } from '../Form/CollectorForm'
import { collectorStore } from 'Models/CollectorModel'
import moment from 'moment'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, KSWM, WasteCompany } from 'Auth/Role'

interface Prop {
  onSaveFinish?: () => void
}

export const CollectorAddContainer = (props: Prop) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  const initialValues = {}

  async function onSubmit(values: any) {
    setSaving(true)
    try {
      await collectorStore.addCollector({ ...values })
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return componentLevelAuthorization(
    <CollectorForm
      form={form}
      onSubmit={onSubmit}
      initialValues={initialValues}
      saving={saving}
    ></CollectorForm>,
    [Admin, KSWM]
  )
}
