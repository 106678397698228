import axios from 'axios'
import { user } from 'Models/UserModel'

const setup = () => {
  axios.interceptors.response.use(undefined, (error) => {
    if (!error.response) {
      return
    }
    console.log('Intercept error: ', error)
    const { status } = error.response
    // message.error("Can't connect to server got status: " + status)

    if (status === 401) {
      user.logout()
    }

    return Promise.reject(error)
  })
}

export default setup
