import React from 'react'
import { user } from 'Models/UserModel'
import { Accounting, Admin, MinistryOfEnvironment, permissions } from './Role'

export function componentLevelAuthorization(
  component: JSX.Element,
  roles: string[] = []
) {
  if (!roles.includes(user.role ?? '')) {
    return <></>
  }

  return component
}

export function isUserAdmin() {
  return Admin === user.role
}

export function isAccounting() {
  return Accounting === user.role
}

export function isUserMOE() {
  return MinistryOfEnvironment === user.role
}

export function hasPermission(permissionName: string) {
  //@ts-ignore
  return permissions[permissionName].includes(user.role)
}
