import { Button, message, Modal } from 'antd'
import { Spinner } from 'Components'
import { IBill, billStore } from 'Models/BillModel'
import React, { useEffect, useState } from 'react'

import { PlusCircleOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { propertyStore } from 'Models/PropertyModel'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'
import { BillSearchInput } from 'Components/BillSearchInput/BillSearchInput'
import { handleApiError } from 'Utils'
import { isUserMOE } from 'Auth/Authorization'
import { FilterDaySelector } from 'Components/FilterDaySelector/FilterDaySelector'
import moment from 'moment'
import { arcgisDateFormat } from 'Utils/Date'
import { BillStatusSelector } from 'Components/BillStatusSelector/BillStatusSelector'
import { collectorStore } from 'Models/CollectorModel'
import { CollectorList } from './CollectorList'
import { CollectorAddContainer } from './Add'
import { toJS } from 'mobx'

const Container = styled.div`
  padding: 20px;
`

const CitizenBox = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`

const PropertyBox = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`

const BillSearchBox = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`

const Label = styled.div`
  color: gray;
`

const FullRow = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AddButton = styled(Button)`
  margin-left: auto;
`

const TotalPendingAmount = styled.span`
  margin-left: 5px;
`

const ActivePropertyStatus = styled.div`
  background: #50e3c2;
  border-radius: 4px;
  margin-left: 10px;
  padding: 0 10px;
`

const InActivePropertyStatus = styled.div`
  background: #ffabab;
  border-radius: 4px;
  margin-left: 10px;
  padding: 0 10px;
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const CollectorPage = observer((props: any) => {
  const [loading, setLoading] = useState(false)
  const [shouldShowAddModal, setShouldShowAddModal] = useState(false)

  useEffect(() => {
    async function loadCollectors() {
      setLoading(true)
      try {
        await collectorStore.loadCollectors()
      } catch (e: any) {
        handleApiError(e)
      }
      setLoading(false)
    }

    loadCollectors()
  }, [])

  console.log('collector page')
  return (
    <Container>
      <Row style={{ marginBottom: 15 }}>
        <AddButton
          onClick={() => setShouldShowAddModal(true)}
          icon={<PlusCircleOutlined />}
        >
          Add
        </AddButton>
      </Row>

      {loading ? (
        <Spinner></Spinner>
      ) : (
        <CollectorList
          collectors={collectorStore.collectors.slice()}
          loading={loading}
        />
      )}

      {shouldShowAddModal && (
        <Modal
          title="បង្កើតថ្មី"
          visible={shouldShowAddModal}
          onCancel={() => setShouldShowAddModal(false)}
          footer={[]}
          width={600}
        >
          <CollectorAddContainer
            onSaveFinish={() => setShouldShowAddModal(false)}
          ></CollectorAddContainer>
        </Modal>
      )}
    </Container>
  )
})
