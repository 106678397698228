import { Form, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { IProperty } from 'Models/PropertyModel'

import moment from 'moment'
import styled from 'styled-components'
import { user } from 'Models'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, DataEntry, IT, KSWM, WasteCompany } from 'Auth/Role'
import { getFileList, getFileListAsString, getImage } from 'Utils/files'
import { PropertyForm } from 'Pages/PropertyActivation/Form'
import { getPropertyById, edit } from 'Models/PropertyModel/api'
import { Spinner } from 'Components'

const Container = styled.section``

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

interface Props {
  propertyId: number
  onSaveFinish?: () => Promise<void>
}

export const PropertyEditContainer = (props: Props) => {
  const [property, setProperty] = useState<Partial<IProperty | undefined>>()
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>(
    getImage(property?.Photo || '') ?? ''
  )

  const initialValues = {
    ...property,
    ActivateDate: property?.ActivateDate
      ? moment(property.ActivateDate)
      : undefined,
    LastBilledDate: property?.LastBilledDate
      ? moment(property?.LastBilledDate)
      : undefined,
    AttachmentPhotos: getFileList(property?.AttachmentPhotos || ''),
    ContractPhotos: getFileList(property?.ContractPhotos || ''),
  }

  async function onSubmit(values: any) {
    const lastBilledDate = values.LastBilledDate
      ? values.LastBilledDate.valueOf()
      : undefined
    const attachmentPhotos = getFileListAsString(values.AttachmentPhotos)
    const contractPhotos = getFileListAsString(values.ContractPhotos)
    let isActive = parseInt(values.IsActive)
    // if (isActive && (initialValues.IsActive !== 1)) {
    //   lastBilledDate = moment().startOf('month').valueOf()
    // } else {
    //   lastBilledDate = values.LastBilledDate
    //     ? values.LastBilledDate.valueOf()
    //     : undefined
    // }

    const data = {
      ...values,
      ActivateDate: values.ActivateDate
        ? values.ActivateDate.valueOf()
        : undefined,
      LastBilledDate: lastBilledDate,
      Editor: user.name,
      EditDate: moment().valueOf(),
      Photo: getFileListAsString(uploadedPhotoUrl),
      IsActive: isActive,
      AttachmentPhotos: attachmentPhotos,
      ContractPhotos: contractPhotos,
      // ...(values.IsActive && (initialValues.IsActive !== 1)) && { ActivatedBy: user.name }
    }
    setSaving(true)
    try {
      await edit({ OBJECTID: property?.OBJECTID, ...data })
    } catch (e: any) {
      message.error(e.message)
    }

    props.onSaveFinish && (await props.onSaveFinish())
    setSaving(false)
  }

  function onPhotoUploaded(url: string | undefined) {
    setUploadedPhotoUrl(url ?? '')
  }

  async function loadProperty() {
    try {
      setLoading(true)
      const result = await getPropertyById(props.propertyId)

      if (result?.length) {
        setProperty(result[0].attributes)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadProperty()
  }, [props.propertyId])

  if (loading) {
    return (
      <Center>
        <Spinner size="middle"></Spinner>
      </Center>
    )
  }

  return !!property ? (
    componentLevelAuthorization(
      <Container>
        <PropertyForm
          form={form}
          onSubmit={onSubmit}
          initialValues={initialValues}
          saving={saving}
          uploadedPhotoUrl={
            (uploadedPhotoUrl || getImage(property?.Photo || '')) ?? ''
          }
          onPhotoUploaded={onPhotoUploaded}
        ></PropertyForm>
      </Container>,
      [Admin, WasteCompany, KSWM, IT, DataEntry]
    )
  ) : (
    <Center>
      <p>Something went wrong</p>
    </Center>
  )
}
