import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import { PropertyQuery } from './api'

const Coordinate = types.model('Coordinate', {
  lat: types.number,
  lng: types.number,
})

const Path = types.model('Path', {
  id: types.string,
  coordinates: types.array(Coordinate),
})

export const CoverageArea = types
  .model('CoverageArea', {
    OBJECTID: types.identifier,
    Perimeter: types.maybeNull(types.number),
    Area: types.maybeNull(types.number),
    Acres: types.maybeNull(types.number),
    Hectares: types.maybeNull(types.number),
    Paths: types.array(Path),
    isSelected: false,
    isSaving: false,
    isDeleting: false,
  })
  .actions((self) => ({
    edit: flow(function* (values) {
      let data = {
        OBJECTID: self.OBJECTID,
        ...values,
      }
      yield api.edit(data)
      const updated = {
        ...self,
        ...values,
      }
      applySnapshot(self, updated)
    }),
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.OBJECTID + '')
      const store = getParent(self, 2) as Instance<typeof CoverageAreaStore>
      store.remove(self)
    }),
    setSelected(selected: boolean) {
      self.isSelected = selected
    },
    setIsSaving(saving: boolean) {
      self.isSaving = saving
    },

    setVerify: flow(function* (verifyValue: number) {
      const data = {
        OBJECTID: self.OBJECTID,
        Verified_St: verifyValue,
      }
      yield api.edit(data)
      const updated = {
        ...self,
        ...data,
      }
      applySnapshot(self, updated)
    }),
  }))

const CoverageAreaStore = types
  .model('CoverageAreaStore', {
    records: types.optional(types.array(CoverageArea), []),
    selected: types.safeReference(CoverageArea),
    loading: false,
  })
  .actions((self) => ({
    loadNearbyRecords: flow(function* (options: PropertyQuery = {}) {
      applySnapshot(self.records, [])
      self.loading = true
      const records = yield api.getNearbyRecords(options)

      const mappedRecords = records.map((record: any) => {
        const Paths = record.geometry.rings.map((path: any, index: number) => {
          const coordinates = path.map((coordinate: any) => {
            return {
              lng: coordinate[0],
              lat: coordinate[1],
            }
          })
          return {
            id: `${record.attributes.OBJECTID}-${index}`,
            coordinates,
          }
        })

        return {
          ...record.attributes,
          OBJECTID: record.attributes.OBJECTID + '',
          Paths,
        }
      })

      applySnapshot(self.records, mappedRecords)
      self.loading = false
    }),

    searchRecords: flow(function* (query: string) {
      applySnapshot(self.records, [])
      self.loading = true
      const properties = yield api.searchBusiness(query)
      const mappedRecords = properties?.map((record: any) => {
        return {
          ...record.attributes,
          OBJECTID: record.attributes.OBJECTID + '',
          geometry: {
            lng: record.geometry?.x,
            lat: record.geometry?.y,
          },
        }
      })

      applySnapshot(self.records, mappedRecords)
      self.loading = false
    }),

    add: flow(function* (record: any) {
      const data = {
        features: {
          attributes: {
            ...record,
          },
          geometry: record.geometry,
        },
      }
      const result = yield api.add(data)
      if (result.error) {
        throw new Error(result.error.details[0])
      } else {
        const OBJECTID = result?.addResults[0]?.objectId + ''
        const { x: lng, y: lat } = record.geometry
        const added = { ...record, geometry: { lat, lng }, OBJECTID }
        self.records.push(added)
        return self.records[self.records.length - 1]
      }
    }),

    remove(item: any) {
      self.records.splice(self.records.indexOf(item), 1)
    },

    emptyrecords() {
      applySnapshot(self.records, [])
    },

    setSelected(business: string | undefined) {
      // @ts-ignore:
      self.selected = business
    },
  }))

export interface ICoverageArea extends Instance<typeof CoverageArea> {}

export const coverageAreaStore = CoverageAreaStore.create({
  records: [],
})
