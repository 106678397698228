import { Button, Modal, Space, Table } from 'antd'
import { DeleteConfirmButton, EditButton } from 'Components'
import { Observer, observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { IProperty, propertyStore } from 'Models/PropertyModel'
import { ReactComponent as MapMarker } from 'Assets/map-marker.svg'
import { PropertyEditContainer } from './Edit'
import { PropertyMap } from './PropertyMap'
import moment from 'moment'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'
import { renderStatus } from 'Utils/property'
import { CheckOutlined } from '@ant-design/icons'
import { ConfirmButton } from 'Components/ConfirmButton'
import { Admin, KSWM, WasteCompany } from 'Auth/Role'
import { toJS } from 'mobx'

const Container = styled.div``

const SelectedDotWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SelectedDot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #50e3c2;
  margin-right: 6px;
`

const Amount = styled.div`
  text-align: right;
`

interface Props {
  properties: IProperty[]
  panMap?: (location: any) => void
  onPropertyClick?: (property: IProperty) => void
}

export const PropertyList = observer((props: Props) => {
  const { panMap } = props
  const [shouldShowEditPropertyModal, setShouldShowEditPropertyModal] =
    useState(false)
  const [shouldShowPropertyMap, setShouldShowPropertyMap] = useState(false)

  const [selectedProperty, setSelectedProperty] = useState<IProperty>()

  function onEditClick(property: IProperty) {
    setSelectedProperty(property)
    setShouldShowEditPropertyModal(true)
  }

  function onDeleteClick(property: IProperty) {
    property.delete()
  }

  function onMapButtonClick(property: IProperty) {
    if (panMap) {
      // panMap({
      //   lat: property.geometry?.lat,
      //   lng: property.geometry?.lng
      // })
      panMap(property)
    } else {
      setSelectedProperty(property)
      setShouldShowPropertyMap(true)
    }
  }

  async function onApproveClick(property: IProperty) {
    await property.setActiveStatus(true)
    propertyStore.removeProperty(property)
  }

  async function onMarkCintriClick(property: IProperty) {
    await property.setCintriClientStatus()
    propertyStore.removeProperty(property)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'OBJECTID',
      key: 'OBJECTID',
      render: function render(text: string, record: IProperty) {
        return (
          <SelectedDotWrapper>
            {record.isSelected && <SelectedDot />}
            {`${text}`}
          </SelectedDotWrapper>
        )
      },
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Owner',
      dataIndex: 'OwnerName',
      key: 'OwnerName',
    },
    {
      title: 'Contact',
      dataIndex: 'OwnerPhone',
      key: 'OwnerPhone',
    },
    {
      title: 'Address',
      dataIndex: 'StreetNo',
      key: 'StreetNo',
      render: function render(text: string, record: IProperty) {
        return (
          <span>{`${record.HouseNo} ${record.StreetNo} ${record.Sangkat} ${record.City}`}</span>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'CreateDate',
      key: 'CreateDate',
      render: function render(text: string, record: IProperty) {
        return (
          <span>{text ? moment(text).format('YYYY-MM-DD HH:mm') : ''}</span>
        )
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'EditDate',
      key: 'EditDate',
      render: function render(text: string, record: IProperty) {
        return (
          <span>{text ? moment(text).format('YYYY-MM-DD HH:mm') : ''}</span>
        )
      },
    },
    {
      title: 'Start service at',
      dataIndex: 'ActivateDate',
      key: 'ActivateDate',
      render: function render(text: string, record: IProperty) {
        return <span>{text ? moment(text).format('YYYY-MM-DD') : ''}</span>
      },
    },
    // {
    //   title: 'Last Billed',
    //   dataIndex: 'LastBilledDate',
    //   key: 'LastBilledDate',
    //   render: function render(text: string, record: IProperty) {
    //     return <span>{text ? moment(text).format('YYYY-MM-DD') : ''}</span>
    //   },
    // },
    {
      title: 'Collector',
      dataIndex: 'Collector',
      key: 'Collector',
    },
    {
      title: 'Fee',
      dataIndex: 'OldFee', // use old fee instead of CollectionFee for now
      key: 'OldFee',
      render: function render(text: string) {
        return <Amount>{thousandSeparator(text)}</Amount>
      },
    },
    {
      title: 'Status',
      dataIndex: 'IsActive',
      key: 'IsActive',
      render: function render(text: string, record: IProperty) {
        return renderStatus(record)
        // if (record.IsActive === 1) {
        //   return <ActivePropertyStatus>សកម្ម</ActivePropertyStatus>
        // } else if (record.IsActive === 2) {
        //   return <PreActivePropertyStatus>កំពុងចរចារ</PreActivePropertyStatus>
        // }

        // return <InActivePropertyStatus>អសកម្ម</InActivePropertyStatus>
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: function render(text: string, record: IProperty) {
        return (
          <Observer>
            {() => (
              <Space>
                <EditButton onClick={() => onEditClick(record)}></EditButton>
                <Button
                  onClick={() => onMapButtonClick(record)}
                  shape="circle"
                  icon={
                    <MapMarker style={{}} height={15} width={15}></MapMarker>
                  }
                ></Button>
                <ConfirmButton
                  onConfirmClick={() => onApproveClick(record)}
                  title="Are you sure to approve this property?"
                  icon={<CheckOutlined />}
                  roles={[Admin, WasteCompany, KSWM]}
                ></ConfirmButton>
                {/* <ConfirmButton
                  label="Cintri"
                  showLabel
                  onConfirmClick={() => onMarkCintriClick(record)}
                  title="Are you sure to mark this property as Cintri client?"
                  // icon={<CheckOutlined />}
                  roles={[Admin, WasteCompany, KSWM]}
                ></ConfirmButton> */}
                {/* <DeleteConfirmButton
                  deleting={record.isDeleting}
                  onConfirmClick={() => onDeleteClick(record)}
                ></DeleteConfirmButton> */}
              </Space>
            )}
          </Observer>
        )
      },
    },
  ]
  return (
    <Container>
      <Table
        columns={columns}
        dataSource={props.properties.slice()}
        onRow={(property, index) => {
          return {
            onClick: () =>
              props.onPropertyClick && props.onPropertyClick(property), // click row
          }
        }}
      />
      {shouldShowEditPropertyModal && (
        <Modal
          title="កែសម្រួលអចលនទ្រព្យ (Edit Property)"
          visible={shouldShowEditPropertyModal}
          onCancel={() => setShouldShowEditPropertyModal(false)}
          footer={[]}
          width={600}
        >
          <PropertyEditContainer
            property={selectedProperty!}
            onSaveFinish={() => setShouldShowEditPropertyModal(false)}
          ></PropertyEditContainer>
        </Modal>
      )}

      {shouldShowPropertyMap && selectedProperty && (
        <Modal
          title="ទីតាំងអចលនទ្រព្យ (Property Map)"
          visible={shouldShowPropertyMap}
          onCancel={() => setShouldShowPropertyMap(false)}
          footer={[]}
          width={window.innerWidth - 300}
        >
          <PropertyMap properties={[selectedProperty]}></PropertyMap>
        </Modal>
      )}
    </Container>
  )
})
