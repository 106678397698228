import { Marker, OverlayView } from '@react-google-maps/api'
import { Popover } from 'antd'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useLiveTruckLocation } from 'Utils/useLiveTruckLocation'
import redCar from '../assets/car_red.png'
import greenCar from '../assets/car_green.png'
import grayCar from '../assets/car_gray.png'
import { initialCenterPP } from '../SchedulePage'

const CarMarker = styled.img<{ rotation: any }>`
  position: relative;
  width: 20px;
  transform: ${(props) => `rotate(${props.rotation}deg) translate(-50%, -50%)`};
  /* &: hover {
		cursor: pointer;
	} */
`

interface Props {
  distance?: number
  coord?: {
    lat: number
    lng: number
  }
}

function LiveTruck({ distance, coord }: Props) {
  const {
    locations,
    // subscribe,
    // unsubscribe,
    filterTruckSubscribe,
    filterTruckUnsubscribe,
  } = useLiveTruckLocation()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const displayForDev = urlParams.get('debug') === 'true'

  useEffect(() => {
    filterTruckSubscribe({ coordinate: coord, distance })
    return () => {
      filterTruckUnsubscribe()
    }
  }, [coord, distance])

  const updateTruckIcon = (truckEngineState: boolean) => {
    switch (truckEngineState) {
      case true:
        return greenCar
      case false:
        return redCar
      default:
        return grayCar
    }
  }

  return (
    <>
      {locations
        .filter((location) => location.latitude && location.longitude)
        .map((location, index) => {
          const {
            unitId,
            latitude: lat,
            longitude: lng,
            speed,
            // @ts-ignore
            name,
            course,
            isEngineOn,
          } = location

          let content = (
            <span>
              Speed: {speed} km/h <br /> Name: {name}
              {displayForDev && (
                <>
                  <br /> UnitId: {unitId}
                  <br /> Lat: {lat}
                  <br /> Lng: {lng}
                </>
              )}
            </span>
          )

          return (
            <OverlayView
              key={`${unitId}_${index}`}
              position={{
                lat: location.latitude!,
                lng: location.longitude!,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <Popover content={content} trigger="click">
                <CarMarker
                  src={updateTruckIcon(isEngineOn!)}
                  rotation={course}
                ></CarMarker>
              </Popover>
            </OverlayView>
          )
        })}
    </>
  )
}

export default LiveTruck

// {locations?.reduce((filterLocations: any, location) => {
//   if (location.latitude && location.longitude) {
//     let content = (
//       <span>
//         {/* @ts-ignore */}
//         Speed: {location.speed} km/h <br /> Name: {location?.name}
//         <>
//           <br /> UnitId: {location?.unitId}
//           <br /> Lat: lat={location.latitude}
//           <br /> Lng: lng={location.longitude}
//         </>
//       </span>
//     )
//     filterLocations.push(
//       <OverlayView
//         // key={`${unitId}_${index}`}
//         position={{
//           lat: location.latitude,
//           lng: location.longitude,
//         }}
//         mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//       >
//         <Popover content={content} trigger="click">
//           <CarMarker
//             src={IMG.CAR_GRAY}
//             rotation={location.course}
//           ></CarMarker>
//         </Popover>
//       </OverlayView>
//       // <Marker
//       //   key={location.unitId}
//       //   icon={{
//       //   }}
//       //   // icon={{
//       //   //   //   path: "M61.2849 48.0244C61.2849 64.3164 48.0769 77.5244 31.7849 77.5244C15.4929 77.5244 2.28491 64.3164 2.28491 48.0244C2.28491 34.9504 22.2469 12.2714 29.6169 3.82141C31.1029 2.11741 33.7479 2.12141 35.2349 3.82441C42.6149 12.2764 61.2849 34.9514 61.2849 48.0244Z",
//       //   //   // path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
//       //   //   // url: RotateIcon
//       //   //   //     .makeIcon(
//       //   //   //       updateTruckIcon(location.isEngineOn)
//       //   //   //     )
//       //   //   //     .setRotation({deg: location.course})
//       //   //   //     .getUrl()
//       //   //   //   ,
//       //   //   // url: updateTruckIcon(location.isEngineOn),
//       //   //   // path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
//       //   //   path: 1,
//       //   //   fillColor: setFill(location?.isEngineOn!),
//       //   //   fillOpacity: 1,
//       //   //   strokeWeight: 0,
//       //   //   scale: 5,
//       //   //   // size: new google.maps.Size(20, 40),
//       //   //   // scaledSize: {
//       //   //   //   width: 20,
//       //   //   //   height: 40,
//       //   //   // },
//       //   //   // rotation: location.course,
//       //   // }}
//       //   position={{
//       //     lat: location.latitude,
//       //     lng: location.longitude,
//       //   }}
//       // >
//       // </Marker>
//     )
//   }

//   return filterLocations
// }, [])}
