import { Input, Space, Table, Tag } from 'antd'
import { Spinner } from 'Components'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import styled from 'styled-components'
import { searchLogs } from './api'
import { formatDate, formatDateTime } from 'Utils/Date'
import _ from 'lodash'

enum BILLING_LOG_TYPES {
  PRINT_INVOICE = 'PRINT_INVOICE',
  PRINT_RECEIPT = 'PRINT_RECEIPT',
  CREATE_INVOICE = 'CREATE_INVOICE',
  VIEW_INVOICE = 'VIEW_INVOICE',
  USER_VIEW_INVOICE = 'USER_VIEW_INVOICE', // for samraam mobile user view bill
  DISTRIBUTE_BILL = 'DISTRIBUTE_BILL',
  DELETE_BILL = 'DELETE_BILL',
  UPDATE_BILL_TO_UNPAID = 'UPDATE_BILL_TO_UNPAID',
  CITIZEN_VIEW_BILL = 'CITIZEN_VIEW_BILL',
}

const Container = styled.div`
  padding: 20px;
`

const renderType = (type: any) => {
  if (type == '1' || type === BILLING_LOG_TYPES.PRINT_INVOICE) {
    return <Tag color="blue">បោះពុម្ភវិក្កយបត្រ</Tag>
  } else if (type == '2' || type === BILLING_LOG_TYPES.PRINT_RECEIPT) {
    return <Tag color="blue">បោះពុម្ភបង្កាន់ដៃ</Tag>
  } else if (type == '3' || type === BILLING_LOG_TYPES.CREATE_INVOICE) {
    return <Tag color="blue">បង្កើតវិក្កយបត្រ</Tag>
  } else if (type === BILLING_LOG_TYPES.VIEW_INVOICE) {
    return <Tag color="blue">មើលវិក្កយបត្រ</Tag>
  } else if (type === BILLING_LOG_TYPES.DISTRIBUTE_BILL) {
    return <Tag color="blue">ចែកវិក្កយបត្រ</Tag>
  } else if (type === BILLING_LOG_TYPES.DELETE_BILL) {
    return <Tag color="blue">លុបវិក្កយបត្រ</Tag>
  } else {
    return <Tag color="blue">{type}</Tag>
  }
}

export const CheckLogsPage = observer((props: any) => {
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [result, setResult] = useState([])

  const handleSearch = async (e: any) => {
    const value = e.target.value

    if (value?.length > 3) {
      setFetching(true)
      const res = await searchLogs(value)
      setResult(res)
      setFetching(false)
    }
  }

  const columns = [
    {
      title: 'Invoice Id',
      dataIndex: 'invoiceId',
      key: 'invoiceId',
    },
    {
      title: 'Property Id',
      dataIndex: 'propertyId',
      key: 'propertyId',
    },
    {
      title: 'Bill for date',
      dataIndex: 'billForDate',
      key: 'billForDate',
      render: (value: any) => {
        return formatDate(value)
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Paid amount',
      dataIndex: 'paidAmount',
      key: 'paidAmount',
    },
    {
      title: 'Type',
      dataIndex: 'printType',
      key: 'printType',
      render: (value: any) => {
        return renderType(value)
      },
    },
    {
      title: 'Action by',
      dataIndex: ['createdBy', 'name'],
      key: 'createdBy',
    },
    {
      title: 'Create at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: any, record: any) => {
        return formatDateTime(value)
      },
    },
  ]

  return (
    <Container>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <div>
          <Space direction="horizontal" style={{ marginBottom: 10 }}>
            <Input
              onChange={handleSearch}
              placeholder="Type in: InvoiceId, PropertyId to search"
              style={{ width: 300 }}
            />
          </Space>
          {fetching ? (
            <div style={{ marginTop: 30 }}>
              <Spinner></Spinner>
            </div>
          ) : (
            <Table dataSource={result} columns={columns} />
          )}
        </div>
      )}
    </Container>
  )
})
