import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import { TransactionHistory } from './TransactionHistory/TransactionHistoryModel'
import { getTransactionHistoriesOfAnInvoice } from './TransactionHistory/api'

export const Bill = types
  .model('Bill', {
    OBJECTID: types.identifier,
    PropertyID: types.maybeNull(types.number),
    CreateDate: types.maybeNull(types.number),
    Amount: types.maybeNull(types.number),
    Paid: types.maybeNull(types.number),
    PaidDate: types.maybeNull(types.number),
    PaymentMethod: types.maybeNull(types.string),
    TransactionID: types.maybeNull(types.string),
    BillForDate: types.maybeNull(types.number),
    EditDate: types.maybeNull(types.number),
    Editor: types.maybeNull(types.string),
    PropertyStatus: types.maybeNull(types.number),
    UserViewBill: types.maybeNull(types.number),
    Province: types.maybeNull(types.number),
    PrintLogs: types.maybeNull(types.string),
    transactionHistories: types.optional(types.array(TransactionHistory), []),
    isDeleting: false,
  })
  .actions((self) => ({
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.OBJECTID + '')
      const store = getParent(self, 2) as Instance<typeof BillStore>
      store.removeBill(self)
    }),
    edit: flow(function* (values) {
      const data = {
        ...self,
        ...values,
      }
      yield api.edit(data)
      applySnapshot(self, data)
    }),
    loadTransactionHistories: flow(function* () {
      const records = yield getTransactionHistoriesOfAnInvoice(
        self.OBJECTID + ''
      )
      self.transactionHistories = records
    }),
  }))

const BillStore = types
  .model('BillStore', {
    bills: types.optional(types.array(Bill), []),
    loading: false,
  })
  .actions((self) => ({
    loadBills: flow(function* (propertyId: string) {
      applySnapshot(self.bills, [])
      const bills = yield api.getBillsByProperty(propertyId)
      const mappedBills = bills.map((bill: any) => {
        return {
          ...bill.attributes,
          OBJECTID: bill.attributes.OBJECTID + '',
        }
      })

      applySnapshot(self.bills, mappedBills)
    }),

    searchBills: flow(function* (query: string) {
      applySnapshot(self.bills, [])
      self.loading = true
      const bills = yield api.searchBill(query)
      const mappedBills = bills.map((bill: any) => {
        return {
          ...bill.attributes,
          OBJECTID: bill.attributes.OBJECTID + '',
          geometry: {
            lng: bill.geometry?.x,
            lat: bill.geometry?.y,
          },
        }
      })

      applySnapshot(self.bills, mappedBills)
      self.loading = false
    }),
    emptyBills() {
      applySnapshot(self.bills, [])
    },
    setBills(bills: IBill[]) {
      applySnapshot(self.bills, bills)
    },
    addBill: flow(function* (bill: IBill) {
      const data = {
        features: {
          attributes: {
            ...bill,
          },
        },
      }
      const result = yield api.add(data)
      if (result.error) {
        alert('Cannot add member ' + result.error.details[0])
      } else {
        const OBJECTID = result?.addResults[0]?.objectId + ''
        const addedBill = { ...bill, OBJECTID }
        self.bills.unshift(addedBill)
        return result
      }
    }),
    removeBill(item: any) {
      self.bills.splice(self.bills.indexOf(item), 1)
    },
  }))
  .views((self) => ({
    get unpaidBills() {
      return self.bills.filter((bill) => bill.Paid !== 1)
    },
    get unpaidBillTotalAmount() {
      return self.bills
        .filter((bill) => bill.Paid !== 1)
        .reduce((total, bill) => {
          return total + (bill.Amount || 0)
        }, 0)
    },
  }))

export interface IBill extends Instance<typeof Bill> {}

export const billStore = BillStore.create({
  bills: [],
})
