import { Space as BaseSpace, Progress, message } from 'antd'
import { ITruck, truckStore } from 'Models/TruckModel'
import React, { useEffect, useState } from 'react'
import { getWialonUnits, importUnit } from '../api'

import { Button } from 'Components'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { ISchedule, user } from 'Models'
import { PrimaryColor } from 'Utils/Color'
import { TruckList } from './TruckList'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { isUserMOE } from 'Auth/Authorization'

const Container = styled.section`
  height: 100%;
`

const Header = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eaeaea;
  padding: 10px;
  height: 72px;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-size: large;
`

const FullWidthSpace = styled(BaseSpace)`
  width: 100%;
`

const Space = styled(BaseSpace)``

interface Props {
  onTruckClick?: (truck: ITruck) => void
  onTruckCurrentLocationLoaded?: (truck: ITruck) => void
  selectedTruck?: ITruck
  onScheduleClick?: (schedule: ISchedule) => void
  selectedSchedule?: ISchedule
  onShowLiveLocationChange?: (isChecked: boolean) => void
}

export const ControlPanel = observer((props: Props) => {
  const { trucks } = truckStore
  const [loading, setLoading] = useState(false)
  const [importing, setImporting] = useState(false)
  const [currentImportUnitName, setCurrentImportUnitName] = useState('')
  const [unitsToImport, setUnitsToImport] = useState([])
  const [importedUnitCount, setImportedUnitCount] = useState(0)
  const [showLiveLocations, setShowLiveLocations] = useState(false)
  const isMOE = isUserMOE()

  useEffect(() => {
    async function loadTrucks() {
      setLoading(true)
      await truckStore.loadTrucks()
      setLoading(false)
    }
    loadTrucks()
  }, [])

  async function importMissingTruckFromWialon() {
    setLoading(true)
    setImporting(true)
    try {
      truckStore.setTrucks([])

      const units = await getWialonUnits()
      setUnitsToImport(units)
      for (let i = 0; i < units.length; i++) {
        const unit = units[i]
        setCurrentImportUnitName(unit.name)
        await importUnit(unit)
        setImportedUnitCount(i + 1)
      }

      await truckStore.loadTrucks()
    } catch (e: any) {
      console.log(e)
      message.error(e.message)
    }
    setCurrentImportUnitName('')
    setImporting(false)
    setLoading(false)
    setImportedUnitCount(0)
    setUnitsToImport([])
  }

  function onLiveLocationCheckboxChange(e: any) {
    const isChecked = e.target.checked
    setShowLiveLocations(isChecked)
    props.onShowLiveLocationChange && props.onShowLiveLocationChange(isChecked)
  }

  if (importing) {
    return (
      <Container>
        <Header>
          <div>Trucks</div>
        </Header>
        <FullWidthSpace direction="vertical" size="middle">
          <Title>
            Import Trucks ({importedUnitCount}/{unitsToImport.length})
          </Title>
          <div>Importing {currentImportUnitName}</div>
          <Progress
            percent={(importedUnitCount / unitsToImport.length) * 100}
          />
        </FullWidthSpace>
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <div>Trucks ({trucks.length})</div>
        <Space>
          {!isMOE && <Button onClick={importMissingTruckFromWialon}>Import Missing</Button>}
          <Checkbox
            checked={showLiveLocations}
            onChange={onLiveLocationCheckboxChange}
          >
            Live Locations
          </Checkbox>
        </Space>
      </Header>
      {!showLiveLocations && (
        <TruckList
          trucks={trucks}
          loading={loading}
          onTruckClick={props.onTruckClick}
          onTruckCurrentLocationLoaded={props.onTruckCurrentLocationLoaded}
          onScheduleClick={props.onScheduleClick}
          selectedSchedule={props.selectedSchedule}
          selectedTruck={props.selectedTruck}
        ></TruckList>
      )}
    </Container>
  )
})
