import axios, { AxiosResponse } from 'axios'

import Bluebird from 'bluebird'
import { ExcelRecord } from './CustomerImport'
import { addProperty } from './property_api'
import { createCustomImport } from 'Models/CustomImportModel/api'
import { environment } from 'environment'
import { getCitizenByLegacyId } from 'Models/CitizenModel/api'
const { apiBaseUrl } = environment

const addRecord = async (
  record: ExcelRecord,
  importId: string,
  cb: () => void
) => {
  const {
    CustomerLocationCode: LegacyID,
    Name,
    Village,
    Commune,
    District,
    Province,
    KhmerAddress: FullAdress,
  } = record

  const data = {
    LegacyID,
    Name: Name || '[N/A]',
    Village,
    Commune,
    District,
    Province,
    FullAdress,
    ImportId: importId,
  }

  const url = `${apiBaseUrl}/api/citizens/add`
  const body = {
    features: {
      attributes: {
        ...data,
      },
    },
  }

  // get the user by legacy id
  const citizens: any[] = LegacyID ? await getCitizenByLegacyId(LegacyID) : []
  let citizenId: string
  // check if the citizen exist, if it is use that citizen Id
  if (citizens.length > 0) {
    citizenId = citizens[0].OBJECTID
    // else create a new one and get it Id
  } else {
    const response = await axios.post(url, body)
    citizenId = response.data.addResults[0].objectId
  }

  const propertyData = {
    features: {
      attributes: {
        CitizenID: citizenId,
        StreetNo: Village,
        Sangkat: Commune,
        Khan: District,
        City: Province,
        OwnerName: Name || '[N/A]',
        ImportId: importId,
      },
    },
  }
  await addProperty(propertyData)

  cb()
  return citizenId
}

export const addRecords = async (
  records: ExcelRecord[],
  onProgress: (addedCount: number) => void
) => {
  const response = await createCustomImport()
  const { id: importId } = response

  let addedCount = 0
  function onAdded() {
    addedCount++
    onProgress(addedCount)
  }

  return Bluebird.map(
    records,
    function (record: any) {
      return addRecord(record, importId, onAdded)
    },
    {
      concurrency: 5,
    }
  )
}
