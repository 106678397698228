import React, { useState } from 'react'

import TypeMap from './TypeMap'
import styled from 'styled-components'

const Container = styled.div``

interface Props {
  code: string | null
}
export const PropertyTypeDisplay = (props: Props) => {
  const { code } = props

  return <Container>{code && TypeMap[code]}</Container>
}
