import React, { useEffect, useState } from 'react'
import { Spin, Row, Switch, Space, Button } from 'antd'

import { ControlPanel } from './ControlPanel'
import { Map } from 'Components/Map'
import { Marker, Polygon } from '@react-google-maps/api'
import { PrimaryColor } from 'Utils/Color'
import { observer } from 'mobx-react-lite'
import { routeStore } from 'Models/RouteModel'
import styled from 'styled-components'
import carIcon from '../../Pages/RouteAndTruck/car.svg'
import redCarIcon from '../../Pages/RouteAndTruck/red-car.svg'
import greyCarIcon from '../../Pages/RouteAndTruck/grey-car.svg'
import { handleApiError } from 'Utils'
import LiveTruck from './components/LiveTruck'
import LiveTruckController from './components/LiveTruckController'

import ScheduleHistory from './ScheduleHistory'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Left = styled.div`
  flex: 1;
  position: relative;
`

const Right = styled.section`
  flex: 0.3;
  height: 100%;
  padding: 20px;
  overflow: scroll;
  background: white;
`

const Spinner = styled(Spin)`
  position: absolute;
  background: white;
  border: 1px solid ${PrimaryColor};
  padding: 18px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
`

const initialCenter = {
  lat: 10.6343527660001,
  lng: 103.503359524,
}

export const initialCenterPP = {
  lat: 11.56293,
  lng: 104.917379,
}

export const SchedulePage = observer((props) => {
  const [room, setRoom] = useState('*')
  // const { locations, subscribe, unsubscribe } = useLiveTruckLocation()
  const [enable, setEnable] = useState(false)
  const [shouldLoadRoute, setShouldLoadRoute] = useState(false)

  const [currentLocation, setcurrentLocation] = useState()
  const { routes } = routeStore
  const [selectedRoute, setSelectedRoute] = useState({})
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location) => {},
  })
  const [center, setCenter] = useState(initialCenterPP)
  const [loadingRoute, setLoadingRoute] = useState(false)
  const [truckIcon, setTruckIcon] = useState(greyCarIcon)
  const [range, setRange] = useState(5)
  const [filter, setFilter] = useState({})

  function updateTruckIcon(truckEngineState) {
    switch (truckEngineState) {
      case true:
        return carIcon
      case false:
        return redCarIcon
      case null:
      default:
        return greyCarIcon
    }
  }

  // useEffect(() => {
  //   subscribe(room)
  //   return () => {
  //     unsubscribe(room)
  //   }
  // }, []);

  useEffect(() => {
    loadRoutes()
  }, [])

  useEffect(() => {
    if (shouldLoadRoute) {
      loadRoutes()
    }
  }, [center])

  async function loadRoutes() {
    setLoadingRoute(true)
    const query = {
      geometry: `${center.lng},${center.lat}`,
      distant: 1500,
    }
    try {
      await routeStore.loadRoutes(query)
    } catch (e) {
      handleApiError(e)
    }

    setLoadingRoute(false)
  }

  function onRouteClick(route) {
    setSelectedRoute(route)
  }

  function onMapDragEnd() {
    const lat = map.center.lat()
    const lng = map.center.lng()
    setCenter({
      lat,
      lng,
    })
  }

  function onMapLoaded(mapInstance) {
    setMap(mapInstance)
  }

  function onTruckCurrentLocationLoaded(location) {
    setcurrentLocation(location)
    map.panTo(location)
    location && setTruckIcon(updateTruckIcon(location.isEngineOn))
    console.log('location loaded', location)
  }

  function updateTruckIcon(truckEngineState) {
    switch (truckEngineState) {
      case true:
        return carIcon
      case false:
        return redCarIcon
      case null:
      default:
        return greyCarIcon
    }
  }

  function setFill(engineState) {
    switch (engineState) {
      case false:
        return 'red'
      case true:
        return 'green'
      default:
        return 'grey'
    }
  }

  return (
    <Container>
      <Left>
        <Map
          center={center}
          zoom={15}
          onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
        >
          {routes?.map((route) => {
            if (route.OBJECTID === selectedRoute.OBJECTID) {
              return route.Paths.map((Path) => {
                return (
                  <Polygon
                    key={Path.id}
                    path={Path.coordinates}
                    options={{
                      fillColor: 'purple',
                      strokeColor: '#424242',
                      strokeWeight: 1,
                      fillOpacity: 0.8,
                    }}
                    onClick={() => onRouteClick(route)}
                  />
                )
              })
            } else {
              return route.Paths.map((Path) => {
                return (
                  <Polygon
                    key={Path.id}
                    path={Path.coordinates}
                    options={{
                      fillColor: route.RouteColor,
                      strokeColor: '#424242',
                      strokeWeight: 1,
                      fillOpacity: 0.8,
                    }}
                    onClick={() => onRouteClick(route)}
                  />
                )
              })
            }
          })}
          {currentLocation && (
            <Marker
              position={currentLocation}
              animation="DROP"
              icon={{
                url: truckIcon,
                scaledSize: {
                  // width: 20,
                  // height: 40,
                  width: 40,
                  height: 80,
                },
              }}
            ></Marker>
          )}
          {enable ? <LiveTruck distance={range} coord={center} /> : null}

          {/* live location truck */}
          {/* {locations?.reduce((filterLocations, location) => {
            if (location.latitude && location.longitude) {
              filterLocations.push(
                <Marker
                  key={location.unitId}
                  animation="DROP"
                  icon={{
                    //   path: "M61.2849 48.0244C61.2849 64.3164 48.0769 77.5244 31.7849 77.5244C15.4929 77.5244 2.28491 64.3164 2.28491 48.0244C2.28491 34.9504 22.2469 12.2714 29.6169 3.82141C31.1029 2.11741 33.7479 2.12141 35.2349 3.82441C42.6149 12.2764 61.2849 34.9514 61.2849 48.0244Z",
                    // path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    // url: RotateIcon
                    //     .makeIcon(
                    //       updateTruckIcon(location.isEngineOn)
                    //     )
                    //     .setRotation({deg: location.course})
                    //     .getUrl()
                    //   ,
                    // url: updateTruckIcon(location.isEngineOn),
                    // path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                    path: 1,
                    fillColor: setFill(location.isEngineOn),
                    fillOpacity: 1,
                    strokeWeight: 0,
                    scale: 5,
                    // size: new google.maps.Size(20, 40),
                    // scaledSize: {
                    //   width: 20,
                    //   height: 40,
                    // },
                    rotation: location.course,
                  }}
                  position={{
                    lat: location.latitude,
                    lng: location.longitude,
                  }}
                >
                </Marker>
              )
            }

            return filterLocations
          }, [])} */}
          <SwitchWrapper>
            <Space>
              Load routes when map moved
              <Switch
                disabled={loadingRoute}
                checked={shouldLoadRoute}
                onChange={setShouldLoadRoute}
              />
            </Space>
          </SwitchWrapper>
        </Map>
        {loadingRoute && <Spinner size="large"></Spinner>}
      </Left>
      <Right>
        <Row style={{ marginBottom: '1em' }}>
          <Space>
            <Button
              onClick={() => loadRoutes()}
              disabled={loadingRoute}
              type="primary"
            >
              Load Nearby Routes
            </Button>
            <Space>
              Enable Truck Location
              <Switch checked={enable} onChange={setEnable} />
            </Space>
          </Space>
        </Row>
        {enable ? <LiveTruckController onSliderChange={setRange} /> : null}
        {selectedRoute.OBJECTID && (
          <ControlPanel
            selectedRoute={selectedRoute}
            onTruckCurrentLocationLoaded={onTruckCurrentLocationLoaded}
          />
        )}
        {!selectedRoute?.OBJECTID && <ScheduleHistory />}
      </Right>
    </Container>
  )
})

/**
 *
 * Solution can be found here:
 * https://stackoverflow.com/questions/6800613/rotating-image-marker-image-on-google-map-v3
 */
// var RotateIcon = function(options){
//   this.options = options || {};
//   this.rImg = options.img || new Image();
//   this.rImg.src = this.rImg.src || this.options.url || '';
//   this.options.width = this.options.width || this.rImg.width || 52;
//   this.options.height = this.options.height || this.rImg.height || 60;
//   var canvas = document.createElement("canvas");
//   canvas.width = this.options.width;
//   canvas.height = this.options.height;
//   this.context = canvas.getContext("2d");
//   this.canvas = canvas;
// };
// RotateIcon.makeIcon = function(url) {
//   return new RotateIcon({url: url});
// };
// RotateIcon.prototype.setRotation = function(options){
//   var canvas = this.context,
//       angle = options.deg ? options.deg * Math.PI / 180:
//           options.rad,
//       centerX = this.options.width/2,
//       centerY = this.options.height/2;

//   canvas.clearRect(0, 0, this.options.width, this.options.height);
//   canvas.save();
//   canvas.translate(centerX, centerY);
//   canvas.rotate(angle);
//   canvas.translate(-centerX, -centerY);
//   canvas.drawImage(this.rImg, 0, 0);
//   canvas.restore();
//   return this;
// };
// RotateIcon.prototype.getUrl = function(){
//   return this.canvas.toDataURL('image/png');
// };

const SwitchWrapper = styled.div`
  position: absolute;
  left: 2%;
  top: 10%;

  display: flex;
  align-items: center;

  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
`
