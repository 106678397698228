import { IProperty } from 'Models'
import { PropertyListItem } from './PropertyListItem'
import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section``

interface Props {
  properties: IProperty[]
  onPayButtonClick?: (property: IProperty) => void
}

export const PropertyList = observer((props: Props) => {
  const { properties } = props

  return (
    <Container>
      {properties?.map((property) => {
        return (
          <PropertyListItem
            key={property.OBJECTID}
            property={property}
            onPayButtonClick={props.onPayButtonClick}
          ></PropertyListItem>
        )
      })}
    </Container>
  )
})
