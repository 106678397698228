import axios, { CancelTokenSource } from 'axios'
import { user } from 'Models/UserModel/UserModel'
import { environment } from 'environment'
import moment from 'moment'
const { apiBaseUrl } = environment

export const getBillsByProperty = async (propertyId: string) => {
  const query = `where=PropertyID='${propertyId}'&orderByFields=BillForDate DESC`
  const url = `${apiBaseUrl}/api/billings/query?${query}`
  //   const url = `${apiBaseUrl}/api/billings/query`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const getBillsByPropertyAndMonth = async (
  propertyId: string,
  date?: string
) => {
  const start = moment(date)
    .subtract(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD')
  const end = moment(date).endOf('month').format('YYYY-MM-DD')
  const query = `where=PropertyID='${propertyId}'AND BillForDate >= '${start}' AND BillForDate < '${end}'`
  const url = `${apiBaseUrl}/api/billings/query?${query}`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const deleteById = async (id: string) => {
  const url = `${apiBaseUrl}/api/billings/delete/${id}`
  const response = await axios.delete(url)
  const success = response.data.deleteResults[0].success
  if (!success) {
    throw new Error('Failed to delete')
  }
  return response.data
}

export const edit = async (values: any) => {
  values.paymentMethod = 'CASH'
  const url = `${apiBaseUrl}/api/billings/patch`
  const data = {
    features: {
      attributes: values,
    },
  }

  const response = await axios.patch(url, data)

  const success = response.data.updateResults[0].success
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const add = async (data: any) => {
  data.paymentMethod = 'CASH'

  data.features.attributes = {
    ...data.features.attributes,
    Province: user?.province_code,
  }

  const url = `${apiBaseUrl}/api/billings/add`
  // axios.defaults.headers.common['samraam-agent'] = `android`
  const response = await axios.post(url, data)

  const success = response.data.addResults[0].success

  if (!success) {
    throw new Error('Failed to add')
  }

  return response.data
}

export async function patchProperty(property: any): Promise<any> {
  const url = `${apiBaseUrl}/api/properties/patch`
  const lastBilledDate = moment().startOf('month').valueOf()
  const data = {
    features: {
      attributes: {
        OBJECTID: property.OBJECTID,
        LastBilledDate: lastBilledDate,
      },
    },
  }
  try {
    const result = await axios.patch(url, data)
    return result?.data
  } catch (error) {
    console.log('patchProperty error: ', error)
  }
}

let searchRequest: CancelTokenSource
export const searchBill = async (query: string) => {
  if (searchRequest) {
    searchRequest.cancel()
  }

  searchRequest = axios.CancelToken.source()
  const url = `${apiBaseUrl}/api/billings/query?resultType=standard&resultRecordCount=200000&maxRecordCountFactor=3&${encodeURI(
    query
  )}`
  const result = await axios.get(url, { cancelToken: searchRequest.token })
  if (!result) {
    return []
  }
  if (result.data.error) {
    console.log('result.data.error: ', result.data.error)
    throw new Error('error' + result.data.error)
  }
  return result.data
}

export const getQueryByProvince = (where = false, and = true) => {
  if (user?.province_code) {
    if (where) {
      return `&where=Province=${user?.province_code}`
    }
    if (and) {
      return ` AND Province=${user?.province_code}`
    }
    return `Province=${user?.province_code}`
  }
  return ''
}
