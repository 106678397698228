import { Form, message } from 'antd'
import React, { useState } from 'react'

import { IUser } from 'Models'
import { UserForm } from '../Form/UserForm'
import styled from 'styled-components'

const Container = styled.section``

interface Props {
  user: IUser
  onSaveFinish?: () => void
}

export const UserEdit = (props: Props) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const { user } = props

  const initialValues = {
    ...user,
  }

  async function onSubmit(values: any) {
    console.log('values: ', values)

    const data = {
      ...values,
    }
    setSaving(true)
    try {
      await user.edit(data)
    } catch (e: any) {
      console.log(e)

      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return (
    <Container>
      <UserForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
      ></UserForm>
    </Container>
  )
}
