import React, { useState } from 'react'
import { TimePicker } from 'antd'
import './style.css'
import { Moment } from 'moment'

interface Props {
  onChange?: (value: Moment | null) => void
  getUnitId?: (unitId: string) => void
  default?: Moment
  disabled?: boolean
}

export const ScheduleTimePicker = (props: Props) => {
  const [value, setValue] = useState<any>(props?.default)

  function onChange(value: Moment | null) {
    setValue(value)
    props?.onChange && props?.onChange(value)
  }

  return (
    <TimePicker
      value={value}
      format={'HH:mm'}
      disabled={props?.disabled}
      showNow={false}
      onSelect={onChange}
    />
  )
}
