import React from 'react'
import styled from 'styled-components'

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
`

interface Props {}
export const LiveTruckLocationPage = (props: Props) => {
  return <IFrame src="https://truck-socket-client.samraam.com/"></IFrame>
}
