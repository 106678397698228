import { Form, message } from 'antd'
import {
  IPropertyType,
  propertyTypeStore,
} from 'Models/PropertyTypeModel/PropertyTypeModel'
import React, { useState } from 'react'

import { PropertyTypeForm } from '../Form/PropertyTypeForm'
import moment from 'moment'
import { prepareSubmitData } from '../Form/helper'
import { getFileListAsString } from 'Utils/files'

interface Prop {
  onSaveFinish?: () => void
}

export const PropertyTypeAddContainer = (props: Prop) => {
  const [saving, setSaving] = useState(false)

  const [form] = Form.useForm()
  const [calculationOptionForm] = Form.useForm()
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>('')

  const initialValues = {}

  async function onSubmit(values: any) {
    const data = prepareSubmitData({
      ...values,
      photo: getFileListAsString(uploadedPhotoUrl),
    })
    setSaving(true)
    try {
      await propertyTypeStore.addPropertyType(data)
    } catch (e: any) {
      console.error(e)
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  function onPhotoUploaded(url?: string) {
    setUploadedPhotoUrl(url ?? '')
  }

  return (
    <PropertyTypeForm
      form={form}
      onSubmit={onSubmit}
      initialValues={initialValues}
      calculationOptionForm={calculationOptionForm}
      saving={saving}
      onPhotoUploaded={onPhotoUploaded}
      uploadedPhotoUrl={uploadedPhotoUrl}
    ></PropertyTypeForm>
  )
}
