import axios from 'axios'

import { environment } from 'environment'
const { apiBaseUrl } = environment

export const getReportDaily = async (userId?: any, date?: any) => {
  let url = `${apiBaseUrl}/agent-report/daily-report`

  const result = await axios.get(url, { params: { userId, date } })
  // console.log('result.data', result.data)

  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}
