import { Button, Form, Input, InputNumber as InputNumberBase, Radio } from 'antd'

import { DatePicker } from 'antd'
import { FormInstance } from 'antd/lib/form'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const InputNumber = styled(InputNumberBase)`
  width: 135px;
`

interface CollectorFormProp {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
  initialValues?: any
}

export const CollectorForm = (props: CollectorFormProp) => {
  const { onSubmit, saving, form, initialValues } = props

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 4 }}
      initialValues={initialValues}
    >
      <Form.Item
        label="Name"
        name="name"
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Code"
        name="code"
      >
        <Input></Input>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
