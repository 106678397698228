import axios, { CancelTokenSource } from 'axios'

import { environment } from 'environment'

const { apiBaseUrl } = environment

export interface ReportQuery {
  geometry?: string
  distant?: number
}

export const getAllReports = async () => {
  let url = `${apiBaseUrl}/api/reports`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const getNearbyReports = async (query: ReportQuery) => {
  let url = `${apiBaseUrl}/api/reports/query?geometryType=esriGeometryPoint&units=esriSRUnit_Meter&geometry=${query.geometry}&distance=${query.distant}`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const deleteById = async (id: string) => {
  const url = `${apiBaseUrl}/api/reports/${id}`
  const response = await axios.delete(url)
  const success = response.data
  if (!success) {
    throw new Error('Failed to delete')
  }
  return response.data
}

export const edit = async (id: string, values: any) => {
  const url = `${apiBaseUrl}/api/reports/${id}`
  const data: any = {
    ...values,
  }

  const response = await axios.patch(url, data)
  const { error } = response.data
  if (error) {
    throw new Error(error)
  }
  const success = response.data
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}
