import { Form, message } from 'antd'
import React, { useState } from 'react'

import { BillForm } from '../Form/BillForm'
import { IBill } from 'Models/BillModel'
import moment from 'moment'
import styled from 'styled-components'
import { user } from 'Models'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, BillingManager, IT, KSWM, WasteCompany } from 'Auth/Role'
import { momentDate } from 'Utils/Date'

const Container = styled.section``

interface BillEditContainerProp {
  bill: IBill | undefined
  onSaveFinish?: () => void
}

export const BillEditContainer = (props: BillEditContainerProp) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const { bill } = props

  const initialValues = {
    ...bill,
    CurrentFee: bill?.Amount,
    BillForDate: bill?.BillForDate ? momentDate(bill.BillForDate) : undefined,
    PaidDate: bill?.PaidDate ? momentDate(bill.PaidDate) : undefined,
  }

  async function onSubmit(values: any) {
    const data = {
      ...values,
      BillForDate: values.BillForDate
        ? values.BillForDate.valueOf()
        : undefined,
      PaidDate: values.PaidDate ? values.PaidDate.valueOf() : undefined,
      Editor: user.name,
      EditDate: moment().valueOf(),
    }
    setSaving(true)
    try {
      await bill?.edit(data)
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return componentLevelAuthorization(
    <Container>
      <BillForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
        isEdit={true}
      ></BillForm>
    </Container>,
    [Admin, WasteCompany, KSWM, BillingManager, IT]
  )
}
