import { Form, message } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, KSWM, WasteCompany } from 'Auth/Role'
import { ICollector } from 'Models/CollectorModel'
import { CollectorForm } from '../Form/CollectorForm'

const Container = styled.section``

interface CollectorEditContainerProp {
  collector: ICollector
  onSaveFinish?: () => void
}

export const CollectorEditContainer = (props: CollectorEditContainerProp) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const { collector } = props


  async function onSubmit(values: any) {
    setSaving(true)
    try {
      await collector.edit(values)
    } catch (error: any) {
      message.error(error.message, 5)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return componentLevelAuthorization(
    (<Container>
      <CollectorForm
        form={form}
        onSubmit={onSubmit}
        initialValues={{...collector}}
        saving={saving}
      />
    </Container>),
    [Admin, WasteCompany, KSWM]
  )
}
