import * as api from './api'

import React, { useEffect, useState } from 'react'

import { Select } from 'antd'
import { Spinner } from 'Components'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 250px;
`

const Center = styled.div`
  width: 250px;
  display: flex;
  justify-content: center;
`

type CitizenPropertySelectorProp = {
  citizenId: string | undefined
  onPropertyIdSelected: (propertyId: string) => void
}

export const CitizenPropertySelector = (props: CitizenPropertySelectorProp) => {
  const { citizenId, onPropertyIdSelected } = props
  const [properties, setProperties] = useState<api.Property[]>([])
  const [selectedValue, setSelectedValue] = useState<string>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadProperties(citizenId: string) {
      setLoading(true)
      const properties = await api.getPropertiesByCitizenId(citizenId)
      setProperties(properties)
      setLoading(false)
    }

    if (citizenId) {
      setProperties([])
      loadProperties(citizenId)
    } else {
      setProperties([])
    }
    setSelectedValue('') // reset select value when citizen changes
  }, [citizenId])

  function onChange(value: any) {
    setSelectedValue(value.toString())
    onPropertyIdSelected(value.toString())
  }

  if (loading) {
    return (
      <Center>
        <Spinner size="middle"></Spinner>
      </Center>
    )
  }

  return (
    <StyledSelect value={selectedValue} onChange={onChange}>
      {properties?.map((property) => {
        return (
          <Option
            key={property.attributes.OBJECTID}
            value={property.attributes.OBJECTID + ''}
          >
            {`${property.attributes.Name}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
