import React from 'react'
import { IProperty } from 'Models'
import styled from 'styled-components'
import { Tag } from 'antd'

// const BaseDiv = styled.div`
//   border-radius: 4px;
//   margin-left: 10px;
//   padding: 0 10px;
//   width: fit-content;
// `

const BaseDiv = styled(Tag)`
  border-radius: 4px;
  padding: 2px 10px;
  border: 0;
`

const ActivePropertyStatus = styled(BaseDiv)`
  background: #50e3c2;
`

const InActivePropertyStatus = styled(BaseDiv)`
  background: #ffabab;
`

const PreActivePropertyStatus = styled(BaseDiv)`
  background: #ffcc00;
`
// ប្រមួលទិន្នន័យរួចរាល់
const CollectedPropertyStatus = styled(BaseDiv)`
  background: #6c5ce7;
`

const CintriClientStatus = styled(BaseDiv)`
  background: #1990ff;
  color: white;
`

const UnpaidPropertyStatus = styled(BaseDiv)`
  background: green;
`

const LogRecoredStatus = styled(BaseDiv)`
  background: #ffcc00;
`

const NotCooperatingStatus = styled(BaseDiv)`
  background: #000000;
  color: white;
`

const ClientCloseStatus = styled(BaseDiv)`
  background: gray;
  // color: white;
`

const ExceptionStatus = styled(BaseDiv)`
  background: #808000;
`

const SmallFont = styled.div`
  font-size: 9px;
`

export const renderStatus = (property: IProperty) => {
  const suspended = property.IsSuspense === 1 && (
    <SmallFont>(Suspended)</SmallFont>
  )
  if (property.IsActive === 1) {
    return <ActivePropertyStatus>សកម្ម {suspended}</ActivePropertyStatus>
  } else if (property.IsActive === 4) {
    return <CintriClientStatus>អតិថិជន Cintri {suspended}</CintriClientStatus>
  } else if (property.IsActive === 5) {
    return (
      <UnpaidPropertyStatus>មិនទាន់បង់លុយ {suspended}</UnpaidPropertyStatus>
    )
  } else if (property.IsActive === 6) {
    return <LogRecoredStatus>កំណត់ហេតុ {suspended}</LogRecoredStatus>
  } else if (property.IsActive === 7) {
    return <NotCooperatingStatus>មិនសហការ {suspended}</NotCooperatingStatus>
  } else if (property.IsActive === 8) {
    return <ClientCloseStatus>អតិថិជនបិទ {suspended}</ClientCloseStatus>
  } else if (property.IsActive === 9) {
    return <ClientCloseStatus>ទីតាំងបង់រួម {suspended}</ClientCloseStatus>
  } else if (property.IsActive === 10) {
    return <ExceptionStatus>ទីតាំងអនុគ្រោះ</ExceptionStatus>
  }

  return <InActivePropertyStatus>អសកម្ម {suspended}</InActivePropertyStatus>
}
