import { environment } from 'environment'
var CryptoJS = require('crypto-js')

export const encrypt = (value: any) => {
  return CryptoJS.AES.encrypt(value, environment.encryptKey).toString()
}

export const decrypt = (hashValue: any) => {
  const bytes = CryptoJS.AES.decrypt(hashValue, environment.encryptKey)
  return bytes.toString(CryptoJS.enc.Utf8)
}
