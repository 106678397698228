import { AddButton, Button, DeleteAllConfirmButton, Spinner } from 'Components'
import {
  ICollectionCompany,
  collectionCompanyStore,
} from 'Models/CollectionCompanyModel/CollectionCompanyModel'
import React, { useEffect, useState } from 'react'

import { Space as BaseSpace } from 'antd'
import { CollectionCompanyAdd } from './Add/CollectionCompanyAdd'
import { CollectionCompanyList } from './CollectionCompanyList'
import { CustomerImport } from './Import/CustomerImport'
import Modal from 'antd/lib/modal/Modal'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { isUserMOE } from 'Auth/Authorization'

const Container = styled.div``

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const Space = styled(BaseSpace)`
  margin-left: auto;
`

interface Props {}
export const CollectionCompanyPage = observer((props: Props) => {
  const { items } = collectionCompanyStore
  const [shouldShowAddModal, setShouldShowAddModal] = useState(false)
  const [shouldShowEditModal, setShouldShowEditModal] = useState(false)
  const [shouldShowImportModal, setShouldShowImportModal] = useState(false)
  const isMOE = isUserMOE()

  useEffect(() => {
    collectionCompanyStore.loadAll()
  }, [])

  async function onAddClick() {
    setShouldShowAddModal(true)
  }

  async function onEditClick() {
    setShouldShowEditModal(true)
  }

  return (
    <Container>
      <h1>Collection Company</h1>
      <Row>
        <Space>
          <AddButton onClick={onAddClick}></AddButton>{' '}
          {!isMOE && <Button onClick={() => setShouldShowImportModal(true)}>Import</Button>}
        </Space>
      </Row>

      <CollectionCompanyList
        onEditClick={onEditClick}
        collectionCompanies={collectionCompanyStore.items}
      ></CollectionCompanyList>

      {shouldShowAddModal && (
        <Modal
          title="បង្កើតក្រុមហ៊ុនប្រមូលសំរាម (Add Collection Company)"
          visible={shouldShowAddModal}
          onCancel={() => setShouldShowAddModal(false)}
          footer={[]}
          width={600}
        >
          <CollectionCompanyAdd
            onSaveFinish={() => setShouldShowAddModal(false)}
          ></CollectionCompanyAdd>
        </Modal>
      )}

      {shouldShowImportModal && (
        <Modal
          title="នាំចូលអតិថិជន (Import Customer)"
          visible={shouldShowImportModal}
          onCancel={() => setShouldShowImportModal(false)}
          footer={[]}
          width={1000}
        >
          <CustomerImport></CustomerImport>
        </Modal>
      )}
    </Container>
  )
})
