import { Form, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { IKSWM } from 'Models/PropertyDataGatheringModel/KswmModel'
import { KswmForm } from '../Form/KswmForm'
import moment from 'moment'
import styled from 'styled-components'

const Container = styled.section``

interface Props {
  record: IKSWM | undefined
  onSaveFinish?: () => void
}

export const KSWMEdit = (props: Props) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>('')
  const { record } = props

  const initialValues = {
    ...record,
  }

  useEffect(() => {
    setUploadedPhotoUrl(initialValues.Photo ?? '')
  }, [])

  function onPhotoUploaded(url: string | undefined) {
    setUploadedPhotoUrl(url ?? '')
  }

  async function onSubmit(values: any) {
    const data = {
      ...values,
      Photo: uploadedPhotoUrl,
    }
    setSaving(true)
    try {
      await record?.edit(data)
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return (
    <Container>
      <KswmForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
        onFileUploaded={onPhotoUploaded}
        uploadedPhotoUrl={uploadedPhotoUrl}
      ></KswmForm>
    </Container>
  )
}
