import { IProperty } from 'Models'
import { Modal } from 'antd'
import { PayBillForm } from './PayBillForm'
import React from 'react'
import styled from 'styled-components'

const Container = styled.section``

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`

interface Props {
  property: IProperty
  onSaveFinish?: () => void
}

export const PayBillContainer = (props: Props) => {
  function onSubmit(values: any) {
    console.log('onSubmit: ', values)
    props.onSaveFinish && props.onSaveFinish()
  }

  return (
    <Container>
      <Row>
        <div>Amount to pay: </div>
        <div>{props.property.Balance}</div>
      </Row>
      <PayBillForm onSubmit={onSubmit}></PayBillForm>
    </Container>
  )
}
