import { user } from './../UserModel/UserModel'
import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'
import { Admin } from 'Auth/Role'

const CalculationOption = types.model('CalculationOption', {
  name: types.maybe(types.string),
  unitPrice: types.maybe(types.number),
  minimumUnit: types.maybe(types.number),
  maximumUnit: types.maybe(types.number),
  landfillPrice: types.maybe(types.number),
})

export const PropertyType = types
  .model('PropertyType', {
    id: types.identifier,
    code: types.maybe(types.string),
    name_khmer: types.maybe(types.string),
    name_english: types.maybe(types.string),
    fixedFee: types.maybeNull(types.number),
    photo: types.maybe(types.string),
    calculationOptions: types.optional(types.array(CalculationOption), []),
    isSelected: false,
    isSaving: false,
    isDeleting: false,
  })
  .actions((self) => ({
    edit: flow(function* (values) {
      const data = {
        ...values,
        id: self.id,
      }
      yield api.edit(data)
      const updated = {
        ...self,
        ...values,
      }
      applySnapshot(self, updated)
    }),
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.id)
      const store = getParent(self, 2) as Instance<typeof PropertyTypeStore>
      store.remove(self)
    }),
    setSelected(selected: boolean) {
      self.isSelected = selected
    },
    setIsSaving(saving: boolean) {
      self.isSaving = saving
    },
  }))
  .views((self) => ({
    // >>> incorrect; need to change to what PropertyForm::onFeeCalculate()
    get getTotalDefaultPrice() {
      if (self.fixedFee) return self.fixedFee
      let total = 0
      if (!self.calculationOptions) {
        return total
      }
      for (const calculationOption of self.calculationOptions) {
        if (calculationOption.unitPrice) {
          const multiplyer = calculationOption.minimumUnit ?? 1
          total += calculationOption.unitPrice * multiplyer
        }
      }

      return total
    },
  }))

const PropertyTypeStore = types
  .model('PropertyTypeStore', {
    propertyTypes: types.optional(types.array(PropertyType), []),
    selectedPropertyType: types.safeReference(PropertyType),
    loading: false,
    selectedProvinceCode: types.maybe(types.string),
  })
  .actions((self) => ({
    loadAll: flow(function* (provinceCode?: string | null) {
      provinceCode = provinceCode || self.selectedProvinceCode

      applySnapshot(self.propertyTypes, [])
      self.loading = true

      if (
        user?.province_code !== '' &&
        user?.province_code &&
        user?.role !== Admin
      ) {
        provinceCode = user?.province_code
      }
      const propertyTypes = yield api.getPropertyTypes(provinceCode)
      applySnapshot(self.propertyTypes, propertyTypes)
      self.loading = false
    }),

    addPropertyType: flow(function* (values: any) {
      const data = {
        ...values,
        provinceCode: self.selectedProvinceCode || user?.province_code,
      }
      const added = yield api.add(data)
      self.propertyTypes.push(added)
    }),

    remove(item: any) {
      self.propertyTypes.splice(self.propertyTypes.indexOf(item), 1)
    },

    deleteAll: flow(function* () {
      yield api.deleteAllPropertyTypes()
      applySnapshot(self.propertyTypes, [])
    }),

    setSelectedPropertyType(propertyType: string | undefined) {
      // @ts-ignore:
      self.selectedPropertyType = propertyType
    },

    setSelectedProvinceCode(provinceCode: string | undefined) {
      // @ts-ignore:
      self.selectedProvinceCode = provinceCode
    },
  }))

export interface IPropertyType extends Instance<typeof PropertyType> {}
export interface ICalculationOption
  extends Instance<typeof CalculationOption> {}

export const propertyTypeStore = PropertyTypeStore.create({
  propertyTypes: [],
  selectedProvinceCode: '18',
})
