import { Button as BaseButton, Spin } from 'antd'
import { DeleteConfirmButton, EditButton } from 'Components'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import React, { useState } from 'react'

import { ISchedule } from 'Models'
import { ITruck } from 'Models/TruckModel'
import Modal from 'antd/lib/modal/Modal'
import { ScheduleItem } from 'Pages/Schedule/ScheduleItem/ScheduleItem'
import carIcon from '../car.png'
import redCarIcon from '../red-car.png'
import greyCarIcon from '../grey-car.png'
import { observer } from 'mobx-react-lite'
import { preProcessFile } from 'typescript'
import styled from 'styled-components'
import { handleApiError } from 'Utils'

interface ContainerInterface {
  selected: boolean
}

const Container = styled.div<ContainerInterface>`
  border-radius: 5px;
  border-bottom: 1px solid lightgray;
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  min-height: 50px;
  background: white;
  cursor: pointer;
  border: ${(props) => (props.selected ? '1px solid #00cec9' : 'none')};
  &:hover {
    background: lightgray;
  }
  &:active {
    background: #1890ff;
  }
`

const Button = styled(BaseButton)`
  margin: 3px;
`

const Children = styled.div`
  margin-left: 15px;
`

const TruckName = styled.div``

interface ScheduleProps {
  readonly selected: boolean
}

const Schedule = styled.div<ScheduleProps>`
  padding: 10px;
  border-radius: 5px;
  background: ${(props) => (props.selected ? '#00cec9' : 'transparent')};
  &:hover {
    background: coral;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const RouteName = styled.div``

const Time = styled.div`
  font-size: small;
`

interface Props {
  truck: ITruck
  selectedTruck?: ITruck
  onTruckClick?: (truck: ITruck) => void
  onTruckCurrentLocationLoaded?: (truck: ITruck) => void
  onScheduleClick?: (schedule: ISchedule) => void
  selectedSchedule?: ISchedule
}
export const TruckListItem = observer((props: Props) => {
  const {
    truck,
    selectedSchedule,
    selectedTruck,
    onTruckCurrentLocationLoaded,
  } = props
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingLocation, setLoadingLocation] = useState(false)
  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false)
  const [editingSchedule, setEditingSchedule] = useState<ISchedule>()
  const selected = truck.OBJECTID === selectedTruck?.OBJECTID
  const [truckIcon, setTruckIcon] = useState<string>(greyCarIcon)

  function updateTruckIcon(truckEngineState?: boolean | null) {
    console.log('truckEngineState', truckEngineState)
    switch (truckEngineState) {
      case true : return carIcon
      case false : return redCarIcon
      case null: default: return greyCarIcon
    }
  }

  function toggleExpand() {
    if (expanded) {
      setExpanded(false)
    } else {
      expand()
    }
  }

  async function expand() {
    setLoading(true)
    setExpanded(true)
    try {
      await truck.loadSchedules()
      for (let schedule of truck.schedules) {
        schedule.loadRoute()
      }
    } catch (e) {
      handleApiError(e)
    }
    setLoading(false)
  }

  async function onTruckClick() {
    if (!expanded) {
      expand()
    }
    props.onTruckClick && props.onTruckClick(truck)
  }

  async function onTruckLocationButtonClick() {
    setLoadingLocation(true)
    try {
      await truck.getCurrentLocation()
    } catch (e) {
      handleApiError(e)
    }
    onTruckCurrentLocationLoaded && onTruckCurrentLocationLoaded(truck)
    truck.currentLocation && setTruckIcon(updateTruckIcon(truck.currentLocation.isEngineOn))
    setLoadingLocation(false)
  }

  function onEditScheduleClick(schedule: ISchedule) {
    setEditingSchedule(schedule)
    setShowEditScheduleModal(true)
  }

  return (
    <Container onClick={onTruckClick} selected={selected}>
      <Row>
        <TruckName>{truck.Name}</TruckName>
        <div>
          <Button shape="circle" onClick={onTruckLocationButtonClick}>
            {loadingLocation ? (
              <Spin></Spin>
            ) : (
              <img width="10" src={truckIcon} alt="" />
            )}
          </Button>
          <Button
            type="text"
            shape="circle"
            icon={expanded ? <DownOutlined /> : <RightOutlined />}
            onClick={toggleExpand}
          />
        </div>
      </Row>

      {expanded && truck.schedules && (
        <Children>
          {loading && <Spin></Spin>}
          {truck.schedules.map((schedule) => {
            const isSelected = selectedSchedule?.OBJECTID === schedule.OBJECTID
            return (
              <Schedule
                selected={isSelected}
                key={schedule.OBJECTID}
                onClick={() =>
                  props.onScheduleClick && props.onScheduleClick(schedule)
                }
              >
                <Row>
                  <div>
                    <RouteName>{schedule.Route && schedule.Route.Name}</RouteName>
                    <Time>
                      {schedule.FromHour} - {schedule.ToHour}
                    </Time>
                  </div>
                  <EditButton onClick={() => onEditScheduleClick(schedule)}></EditButton>
                </Row>

              </Schedule>
            )
          })}
        </Children>
      )}

      {showEditScheduleModal && editingSchedule && (
        <Modal
          title="កែសម្រួលកាលវិភាគ (Edit Schedule)"
          visible={showEditScheduleModal}
          onCancel={() => setShowEditScheduleModal(false)}
          footer={[]}
          width={600}
        >
          <ScheduleItem
            schedule={editingSchedule}
            onScheduleDeleted={() => setShowEditScheduleModal(false)}
            onScheduleSaved={() => setShowEditScheduleModal(false)}
          ></ScheduleItem>
        </Modal>
      )}
    </Container>
  )
})
