import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { scheduleStore, user, userStore } from 'Models'
import Title from 'antd/lib/typography/Title'
import { blue, orange } from '@ant-design/colors'
import styled from 'styled-components'
import {
  Button,
  DatePicker,
  Empty,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import { StarOutlined } from '@ant-design/icons'
import moment from 'moment'
import {
  changeRouteScheduleStatus,
  getRouteHistory,
  patchRouteHistory,
} from 'Models/RouteModel/api'
import { ArgsProps } from 'antd/lib/notification'
import { toJS } from 'mobx'
import { Spinner } from 'Components'

type IDate = {
  key:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday'
}

const Card = styled.div<{ bck?: string }>`
  padding: 10px;
  margin-bottom: 10px;
  color: #fff;
`

const MT = styled.div`
  margin-top: 10px;
`

interface Props {
  onSuccess?(): void
}

const { Option } = Select

const convertDate = (date: any) => {
  return String(moment(date).format('LL'))
}

const fTime = (fTime: any) => {
  return moment(fTime).format('HH:mm')
}

interface IScheduleSort {
  FromHour: string
  ToHour: string
}

const ScheduleRouteStatus = observer((props: Props) => {
  const { schedules } = scheduleStore
  const me = user
  const [date, setDate] = useState<number>()
  const [datas, setDatas] = useState<any>()
  const [status, setStatus] = useState<string>()
  const [time, setTime] = useState<{ fromHour: number; toHour: number }>()
  const [scheduleIndex, setScheduleIndex] = useState<number>()
  const [routeId, setRouteId] = useState()
  const [scheduleId, setScheduleId] = useState()
  const [loading, setLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [routeHistoryId, setRouteHistoryId] = useState('')
  const [selected, setSelected] = useState<{
    header: string | undefined
    date: string
  }>()

  useEffect(() => {
    if (schedules[0]?.RouteID && date) {
      const key = moment(date).format('dddd')
      getRouteHistory(schedules[0]?.RouteID)
        .then((r) => {
          if (!!r?.data.length) {
            const routeHData = r?.data?.filter((d: any) => {
              return convertDate(d?.date) === convertDate(date)
            })
            const newData = schedules?.map((d) => {
              const existed = routeHData?.filter((r: any) => {
                return (
                  `${fTime(r?.fromHour!)}/${fTime(r?.toHour!)}` ===
                  `${d?.FromHour}/${d?.ToHour}`
                )
              })

              return { ...d, existed }
            })

            const data = newData?.filter((d: any) => d[key] === 1 || !!d[key])

            setDatas(data)
            setDataLoading(false)
          } else {
            const data = schedules?.filter((d: any) => d[key] === 1 || !!d[key])
            setDatas(data || [])
            setDataLoading(false)
          }
        })
        .catch((error) => {
          console.log(error)
          setDataLoading(false)
        })
    }
  }, [date, schedules[0]?.RouteID])

  const Data = [
    {
      header: moment(date).format('dddd'),
      times: datas,
    },
  ]

  const openNotification = (type?: ArgsProps['type'], msg?: string) => {
    notification.open({
      type,
      message: msg,
      placement: 'topRight',
    })
  }

  function onChange(value: any) {
    const date = moment(value).valueOf()
    setDate(date)
  }

  function onSelectSchedule(data: any, header: string, i: number) {
    setScheduleIndex(i + 1)
    setScheduleId(data?.OBJECTID)
    setRouteId(data?.RouteID)
    setTime({
      fromHour: moment(data?.FromHour, 'HH:mm').valueOf(),
      toHour: moment(data?.ToHour, 'HH:mm').valueOf(),
    })

    data?.existed?.length && setRouteHistoryId(data?.existed[0]?.id)

    setSelected({
      header,
      date: `${data?.FromHour}/${data?.ToHour}`,
    })
  }

  function onSubmit() {
    if (!scheduleId) {
      openNotification('error', 'please select a schedule')
      return
    }

    if (!status) {
      openNotification('error', 'please select a status')
      return
    }

    setLoading(true)
    const nextSchedule =
      !!Data[0]?.times[scheduleIndex as number]?.FromHour &&
      moment(
        Data[0]?.times[scheduleIndex as number]?.FromHour,
        'HH:mm'
      ).valueOf()

    console.log(nextSchedule)
    const data = {
      routeId,
      scheduleId,
      date,
      status,
      fromHour: time?.fromHour,
      toHour: time?.toHour,
      addedBy: me?.username,
      nextSchedule,
    }

    if (!!routeHistoryId) {
      //update route history status
      patchRouteHistory(routeHistoryId, status)
        .then(() => {
          setLoading(false)
          openNotification('success', 'Route status has been updated.')
          props?.onSuccess && props?.onSuccess()
        })
        .catch(() => {
          openNotification('error', 'something went wrong, please try again.')
          setLoading(false)
        })
    } else {
      changeRouteScheduleStatus(data)
        .then(() => {
          setLoading(false)
          openNotification('success', 'Route status has been created.')
          props?.onSuccess && props?.onSuccess()
        })
        .catch(() => {
          openNotification('error', 'something went wrong, please try again.')
          setLoading(false)
        })
    }
  }

  const handleChange = (value: string) => {
    setStatus(value)
  }

  if (!date) {
    return (
      <>
        <Row align="middle">
          <Space>
            <StarOutlined style={{ color: orange.primary }} />
            <div>Please select date to continuos</div>
          </Space>
        </Row>
        <div style={{ marginTop: 10 }} />
        <DatePicker
          // disabledDate={(current) => {
          //   let customDate = moment().format('YYYY-MM-DD')
          //   return current && current < moment(customDate, 'YYYY-MM-DD')
          // }}
          onChange={onChange}
        />
      </>
    )
  }

  if (!datas?.length && !dataLoading) {
    return <Empty description={'No Data'} />
  }

  if (dataLoading) {
    return <Spinner />
  }

  return (
    <div>
      <Title level={5}>Selected Date: {moment(date).format('dddd, LL')}</Title>
      {Data?.map((d, index) => {
        if (!d?.times?.length) return null

        d?.times?.sort((a: IScheduleSort, b: IScheduleSort) =>
          a.FromHour >= b.FromHour ? 1 : -1
        )
        d?.times?.sort((a: IScheduleSort, b: IScheduleSort) =>
          a.ToHour >= b.ToHour ? 1 : -1
        )

        return (
          <div key={index}>
            <u>{d.header}</u>
            {d?.times.map((item: any, i: number) => {
              const active =
                // @ts-ignore
                selected?.header === d?.header &&
                `${item?.FromHour}/${item?.ToHour}` === selected?.date

              const statusColor =
                !!item?.existed?.length &&
                item?.existed[0]?.status === 'មិនទាន់បានទៅយក'
                  ? '#fa2d2d'
                  : '#35c930'

              const bck = active
                ? blue.primary
                : !!item?.existed?.length
                ? statusColor
                : '#c6c6c6'

              return (
                <Card
                  key={i}
                  style={{ backgroundColor: bck }}
                  onClick={() => onSelectSchedule(item, d.header, i)}
                >
                  {item?.FromHour}-{item?.ToHour}
                  <div style={{ fontSize: 12 }}>
                    {!!item?.existed?.length && item?.existed[0]?.status}
                  </div>
                </Card>
              )
            })}
          </div>
        )
      })}
      <div>Select Status:</div>
      <MT />
      <Select
        onChange={handleChange}
        style={{ width: '100%' }}
        placeholder="please select status"
      >
        <Option value="មិនទាន់បានទៅយក">មិនទាន់បានទៅយក</Option>
        <Option value="ទៅយករួចហើយ">ទៅយករួចហើយ</Option>
      </Select>

      <MT />
      <div>
        <Button loading={loading} type="primary" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  )
})

export default ScheduleRouteStatus
