import axios from 'axios'
import { environment } from 'environment'
import moment from 'moment'
const { apiBaseUrl } = environment

export const createCustomImport = async () => {
  const url = `${apiBaseUrl}/custom-imports`
  const name = moment().format('YYYY-MMM-DD h:mm:ss A')
  const result = await axios.post(url, { name })
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const getCustomImport = async () => {
  const url = `${apiBaseUrl}/custom-imports`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const deleteCustomImportById = async (id: string) => {
  const url = `${apiBaseUrl}/custom-imports/${id}`
  const result = await axios.delete(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}
