import { Form, message } from 'antd'
import React, { useState } from 'react'

import { IProperty } from 'Models/PropertyModel'
import { PropertyForm } from '../Form/PropertyForm'
import moment from 'moment'
import styled from 'styled-components'
import { user } from 'Models'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, DataEntry, IT, KSWM, WasteCompany } from 'Auth/Role'
import { getFileList, getFileListAsString, getImage } from 'Utils/files'

const Container = styled.section``

interface Props {
  property: IProperty | undefined
  onSaveFinish?: () => void
}

export const PropertyEditContainer = (props: Props) => {
  const { property } = props

  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>(
    getImage(property?.Photo || '') ?? ''
  )

  console.log('property', property)
  const initialValues = {
    ...property,
    ActivateDate: property?.ActivateDate
      ? moment(property.ActivateDate)
      : undefined,
    LastBilledDate: property?.LastBilledDate
      ? moment(property?.LastBilledDate)
      : undefined,
    AttachmentPhotos: getFileList(property?.AttachmentPhotos || ''),
    ContractPhotos: getFileList(property?.ContractPhotos || ''),
  }

  async function onSubmit(values: any) {
    const lastBilledDate = values.LastBilledDate
      ? values.LastBilledDate.valueOf()
      : undefined
    const attachmentPhotos = getFileListAsString(values.AttachmentPhotos)
    const contractPhotos = getFileListAsString(values.ContractPhotos)
    let isActive = parseInt(values.IsActive)
    // if (isActive && (initialValues.IsActive !== 1)) {
    //   lastBilledDate = moment().startOf('month').valueOf()
    // } else {
    //   lastBilledDate = values.LastBilledDate
    //     ? values.LastBilledDate.valueOf()
    //     : undefined
    // }

    const data = {
      ...values,
      ActivateDate: values.ActivateDate
        ? values.ActivateDate.valueOf()
        : undefined,
      LastBilledDate: lastBilledDate,
      Editor: user.name,
      EditDate: moment().valueOf(),
      Photo: getFileListAsString(uploadedPhotoUrl),
      IsActive: isActive,
      AttachmentPhotos: attachmentPhotos,
      ContractPhotos: contractPhotos,
      // ...(values.IsActive && (initialValues.IsActive !== 1)) && { ActivatedBy: user.name }
    }
    setSaving(true)
    try {
      await property?.edit(data)
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  function onPhotoUploaded(url: string | undefined) {
    setUploadedPhotoUrl(url ?? '')
  }

  return componentLevelAuthorization(
    <Container>
      <PropertyForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
        uploadedPhotoUrl={uploadedPhotoUrl}
        onPhotoUploaded={onPhotoUploaded}
      ></PropertyForm>
    </Container>,
    [Admin, WasteCompany, KSWM, IT, DataEntry]
  )
}
