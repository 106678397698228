import { Button, message } from 'antd'
import { IProperty, IRoute, routeStore, scheduleStore } from 'Models'
import { Marker, Polyline } from '@react-google-maps/api'

import { Coordinate } from 'Models/Types'
import { Map } from 'Components/Map'
import React from 'react'
import { ScheduleItem } from 'Pages/Schedule/ScheduleItem/ScheduleItem'
import { observer } from 'mobx-react-lite'
import selectedMarkerIcon from './assets/SelectedMarker.png'
import selectedMarkerIconInactive from './assets/SelectedMarkerInactive.png'
import styled from 'styled-components'
import { isUserMOE } from 'Auth/Authorization'

const NORMAL_ROUTE_COLOR = 'gray'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2rem;
`

const MapWrapper = styled.div`
  height: 300px;
  width: 600px;
  padding-bottom: 1rem;
`

const DetailWrapper = styled.div`
  height: 300px;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 1rem;
`

const AssignRouteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`

interface Props {
  routes: IRoute[]
  property: IProperty
  coordinate: Coordinate
  loadingSchedules: boolean
  onAssignRouteClick?: () => void
}

export const RouteModal = observer((props: Props) => {
  const { schedules } = scheduleStore

  const { property, loadingSchedules } = props

  const { selectedRoute, setSelectedRoute } = routeStore

  const markerIcon = property.IsActive
    ? selectedMarkerIcon
    : selectedMarkerIconInactive
  const iconSize = 20
  const isMOE = isUserMOE()

  async function onAssignRouteClick() {
    if (selectedRoute && property) {
      const routeId = selectedRoute.OBJECTID + ''
      try {
        property.setIsSaving(true)
        await property.setRouteId(routeId)
        property.setIsSaving(false)
      } catch (e: any) {
        console.log(e)
        message.error(e.message)
      }
      props.onAssignRouteClick && props.onAssignRouteClick()
    }
  }

  return (
    <Container>
      <MapWrapper>
        <Map
          disableSelector={true}
          center={props.coordinate}
          isOverlap={false}
          zoom={15}
        >
          {props.routes.map((route) => {
            if (route.OBJECTID === selectedRoute?.OBJECTID) {
              return (
                <div>
                  {route.Paths.map((Path) => {
                    return (
                      <Polyline
                        key={Path.id}
                        path={Path.coordinates}
                        options={{
                          strokeColor: '#ff0000',
                          strokeWeight: 8,
                        }}
                        onClick={() => setSelectedRoute(route.OBJECTID)}
                      ></Polyline>
                    )
                  })}
                  {
                    property.geometry && property.geometry.lat && property.geometry.lng? 
                      (
                        // @ts-ignore
                        <Marker
                          key={property.OBJECTID}
                          position={property.geometry}
                          // onClick={() => onPropertyMarkerClick(property)}
                          label=""
                          icon={{
                            url: markerIcon,
                            scaledSize: {
                              width: iconSize,
                              height: iconSize,
                            },
                          }}
                        ></Marker>
                      )
                    :
                      <></>
                  }
                </div>
              )
            } else {
              return (
                <>
                  {route.Paths.map((Path) => {
                    return (
                      <Polyline
                        key={Path.id}
                        path={Path.coordinates}
                        options={{
                          strokeColor: NORMAL_ROUTE_COLOR,
                          strokeWeight: 5,
                        }}
                        onClick={() => setSelectedRoute(route.OBJECTID)}
                      ></Polyline>
                    )
                  })}
                  {property.geometry && property.geometry.lat && property.geometry.lng?
                    // @ts-ignore
                    <Marker
                      key={property.OBJECTID}
                      position={property.geometry}
                      // onClick={() => onPropertyMarkerClick(property)}
                      label=""
                      icon={{
                        url: markerIcon,
                        scaledSize: {
                          width: iconSize,
                          height: iconSize,
                        },
                      }}
                    ></Marker> : <></>}
                </>
              )
            }
          })}
        </Map>
      </MapWrapper>
      <DetailWrapper>
        {!selectedRoute ? (
          <div></div>
        ) : (
          <div>
            {schedules.map((schedule) => {
              return (
                <ScheduleItem
                  key={schedule.OBJECTID}
                  schedule={schedule}
                ></ScheduleItem>
              )
            })}

            {!loadingSchedules && !isMOE && (
              <AssignRouteWrapper>
                <Button
                  type="primary"
                  onClick={onAssignRouteClick}
                  disabled={property.isSaving}
                >
                  Assign Route
                </Button>
              </AssignRouteWrapper>
            )}
          </div>
        )}
      </DetailWrapper>
    </Container>
  )
})
