import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import { PropertyQuery } from './api'

const Geometry = types.model('Geometry', {
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
})

export const KSWM = types
  .model('KSWM', {
    OBJECTID: types.identifier,
    CustomerNo: types.maybeNull(types.string),
    LocationCode: types.maybeNull(types.string),
    Block: types.maybeNull(types.string),
    Sector: types.maybeNull(types.string),
    SideofStreet: types.maybeNull(types.string),
    HouseOrder: types.maybeNull(types.string),
    DevideOrder: types.maybeNull(types.string),
    Floor: types.maybeNull(types.string),
    Possition: types.maybeNull(types.string),
    CustomerName: types.maybeNull(types.string),
    BusinessName: types.maybeNull(types.string),
    BusinessType: types.maybeNull(types.string),
    Fee: types.maybeNull(types.number),
    VAT: types.maybeNull(types.string),
    Total: types.maybeNull(types.number),
    Currency: types.maybeNull(types.string),
    PaymentType: types.maybeNull(types.string),
    TrafficeCode: types.maybeNull(types.string),
    Collector: types.maybeNull(types.string),
    CollectionDate: types.maybeNull(types.string),
    ContactPerson: types.maybeNull(types.string),
    Sex: types.maybeNull(types.string),
    Title: types.maybeNull(types.string),
    PhoneNumber: types.maybeNull(types.string),
    Email: types.maybeNull(types.string),
    VATINNumber: types.maybeNull(types.string),
    ZoneName: types.maybeNull(types.string),
    HouseNo: types.maybeNull(types.string),
    Street: types.maybeNull(types.string),
    Village: types.maybeNull(types.string),
    Commune: types.maybeNull(types.string),
    District: types.maybeNull(types.string),
    Province: types.maybeNull(types.string),
    AgreementNo: types.maybeNull(types.string),
    AgreementDoneBy: types.maybeNull(types.string),
    AgreementDate: types.maybeNull(types.string),
    EntryBy: types.maybeNull(types.string),
    EntryDate: types.maybeNull(types.string),
    Region: types.maybeNull(types.string),
    Photo: types.maybeNull(types.string),
    HasLocation: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.string)
    ),
    geometry: types.maybe(Geometry),
    isSelected: false,
    isSaving: false,
    isDeleting: false,
  })
  .actions((self) => ({
    edit: flow(function* (values) {
      let data = {
        OBJECTID: self.OBJECTID,
        ...values,
      }
      yield api.edit(data)
      const updated = {
        ...self,
        ...values,
      }
      applySnapshot(self, updated)
    }),
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.OBJECTID + '')
      const store = getParent(self, 2) as Instance<typeof KswmStore>
      store.remove(self)
    }),
    setSelected(selected: boolean) {
      self.isSelected = selected
    },
    setIsSaving(saving: boolean) {
      self.isSaving = saving
    },

    setVerify: flow(function* (verifyValue: number) {
      const data = {
        OBJECTID: self.OBJECTID,
        Verified_St: verifyValue,
      }
      yield api.edit(data)
      const updated = {
        ...self,
        ...data,
      }
      applySnapshot(self, updated)
    }),
  }))

const KswmStore = types
  .model('KswmStore', {
    records: types.optional(types.array(KSWM), []),
    selected: types.safeReference(KSWM),
    loading: false,
  })
  .actions((self) => ({
    loadNearbyRecords: flow(function* (options: PropertyQuery = {}) {
      applySnapshot(self.records, [])
      self.loading = true
      const records = yield api.getNearbyRecords(options)
      const mappedRecords = records.map((record: any) => {
        return {
          ...record.attributes,
          OBJECTID: record.attributes.OBJECTID + '',
          geometry: {
            lng: record.geometry?.x,
            lat: record.geometry?.y,
          },
        }
      })

      applySnapshot(self.records, mappedRecords)
      self.loading = false
    }),

    searchRecords: flow(function* (query: string) {
      applySnapshot(self.records, [])
      self.loading = true
      const properties = yield api.searchBusiness(query)
      const mappedRecords = properties?.map((record: any) => {
        return {
          ...record.attributes,
          OBJECTID: record.attributes.OBJECTID + '',
          geometry: {
            lng: record.geometry?.x,
            lat: record.geometry?.y,
          },
        }
      })

      applySnapshot(self.records, mappedRecords)
      self.loading = false
    }),

    add: flow(function* (record: any) {
      const data = {
        features: {
          attributes: {
            ...record,
          },
          geometry: record.geometry,
        },
      }
      const result = yield api.add(data)
      if (result.error) {
        throw new Error(result.error.details[0])
      } else {
        const OBJECTID = result?.addResults[0]?.objectId + ''
        const { x: lng, y: lat } = record.geometry
        const added = { ...record, geometry: { lat, lng }, OBJECTID }
        self.records.push(added)
        return self.records[self.records.length - 1]
      }
    }),

    remove(item: any) {
      self.records.splice(self.records.indexOf(item), 1)
    },

    emptyrecords() {
      applySnapshot(self.records, [])
    },

    setSelected(business: string | undefined) {
      // @ts-ignore:
      self.selected = business
    },
  }))

export interface IKSWM extends Instance<typeof KSWM> {}

export const kswmStore = KswmStore.create({
  records: [],
})
