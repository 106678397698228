import React, { useEffect, useState } from 'react'
import { Select } from 'antd'

import styled from 'styled-components'
import { getAllLocations } from 'Models/UserModel/api'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 250px;
`

interface Props {
  defaultValue?: string
  onSelect?: (value: string) => void
}

export const LocationSelector = (props: Props) => {
  const [locations, setLocations] = useState<any[]>([])

  useEffect(() => {
    fetchAllLocation()
  }, [])

  const fetchAllLocation = async () => {
    const result = await getAllLocations()
    if (result?.length) {
      setLocations(result)
    }
  }

  const onSelect = (v: any) => {
    props.onSelect && props.onSelect(v)
  }

  return (
    <StyledSelect
      {...props}
      defaultValue={props.defaultValue}
      placeholder="Select a location"
      optionFilterProp="children"
      onSelect={onSelect}
      filterOption={(input, option) => {
        if (option && option.children) {
          const match =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          return match
        } else {
          return false
        }
      }}
    >
      <Option key={'NONE'} value={''}>
        None
      </Option>
      {locations?.map((location) => {
        return (
          <Option key={location?.code} value={location?.code}>
            {`${location?.name}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
