import { user } from './../UserModel/UserModel'
import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import { PropertyQuery } from './api'
import moment from 'moment'

const Geometry = types.model('Geometry', {
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
})

const CalculationOptions = types.model('CalculationOptions', {
  Bars: types.maybeNull(types.number),
  Floors: types.maybeNull(types.number),
  Restaurants: types.maybeNull(types.number),
  Room: types.maybeNull(types.number),
  Floor_Area: types.maybeNull(types.number),
  Gym: types.maybeNull(types.number),
  Bed: types.maybeNull(types.number),
  Land_Size: types.maybeNull(types.number),
  BuildingSize: types.maybeNull(types.number),
  Capacity: types.maybeNull(types.number),
  TableCount: types.maybeNull(types.number),
  BuildingCount: types.maybeNull(types.number),
  Cafes: types.maybeNull(types.number),
  Building: types.maybeNull(types.number),
  Chairs: types.maybeNull(types.number),
  Businesses: types.maybeNull(types.number),
  Marts: types.maybeNull(types.number),
})

const PropertyAttributes = types.model('PropertyAttributes', {
  OBJECTID: types.identifier,
  IDPOI: types.maybeNull(types.number),
  Name: types.maybeNull(types.string),
  CollectionFee: types.maybeNull(types.number),
  OldFee: types.maybeNull(types.number),
  Type: types.maybeNull(types.string),
  HouseNo: types.maybeNull(types.string),
  StreetNo: types.maybeNull(types.string),
  Sangkat: types.maybeNull(types.string),
  Khan: types.maybeNull(types.string),
  City: types.maybeNull(types.string),
  CitizenID: types.maybeNull(types.string),
  RouteID: types.maybeNull(types.string),
  OwnerName: types.maybeNull(types.string),
  OwnerPhone: types.maybeNull(types.string),
  HasLocation: types.maybeNull(types.number),
  IsActive: types.union(
    types.maybeNull(types.number),
    types.maybeNull(types.boolean)
  ),
  Star_Rating: types.union(
    types.maybeNull(types.number),
    types.maybeNull(types.string)
  ),
  Verified_St: types.union(
    types.maybeNull(types.number),
    types.maybeNull(types.boolean)
  ),
  Photo: types.maybeNull(types.string),
  LastBilledDate: types.maybeNull(types.number),
  ActivateDate: types.maybeNull(types.number),
  CreateDate: types.maybeNull(types.number),
  EditDate: types.maybeNull(types.number),
  Balance: types.maybeNull(types.number),
  geometry: types.maybe(Geometry),
  VATTIN: types.maybeNull(types.string),
  AddedBy: types.maybeNull(types.string),
  Province: types.maybeNull(types.number),
  ActivatedBy: types.maybeNull(types.string),
  isSelected: false,
  isSaving: false,
  isDeleting: false,
  IsActiveNote: types.maybeNull(types.string),
  AttachmentPhotos: types.maybeNull(types.string),
  ContractPhotos: types.maybeNull(types.string),
  Note: types.maybeNull(types.string),
  Village: types.maybeNull(types.string),
  BusinessStatus: types.maybeNull(types.string),
  Collector: types.maybeNull(types.string),
  DiscountedFee: types.maybeNull(types.number),
  DistributeMonth: types.maybeNull(types.string),
  IsSuspense: types.union(
    types.maybeNull(types.number),
    types.maybeNull(types.boolean)
  ),
  Isclosed: types.union(
    types.maybeNull(types.number),
    types.maybeNull(types.boolean)
  ),
})

export const Property = types
  .compose('Property', CalculationOptions, PropertyAttributes)
  .actions((self) => ({
    setRouteId: flow(function* (routeId) {
      const dataToPatch = {
        OBJECTID: self.OBJECTID,
        RouteID: routeId,
      }
      yield api.edit(dataToPatch)
      self.RouteID = routeId
    }),
    setCintriClientStatus: flow(function* () {
      const dataToPatch = {
        OBJECTID: self.OBJECTID,
        ActivatedBy: user.name,
        ActivateDate: moment().valueOf(),
        IsActive: 4,
      }
      yield api.edit(dataToPatch)
      self.IsActive = 4
    }),
    setActiveStatus: flow(function* (isActive) {
      const dataToPatch = {
        OBJECTID: self.OBJECTID,
        ActivatedBy: user.name,
        ActivateDate: moment().valueOf(),
        IsActive: isActive,
      }
      yield api.edit(dataToPatch)
      self.IsActive = isActive
    }),
    edit: flow(function* (values) {
      const HasLocation = values.geometry ? 1 : 0
      const data = {
        OBJECTID: self.OBJECTID,
        // ID: self.ID,
        ...values,
        HasLocation,
      }

      yield api.edit(data)
      const updated = {
        ...self,
        ...values,
        HasLocation,
        geometry: {
          lng: values.geometry?.x,
          lat: values.geometry?.y,
        },
      }
      // console.log('self', self)
      // console.log('updated', updated)
      applySnapshot(self, updated)
      // maybe call store to edit the entry here
    }),
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.OBJECTID + '') // self.ID
      // console.log('self: ', self)
      const store = getParent(self, 2) as Instance<typeof PropertyStore>
      // console.log('property store before remove: ', store)
      store.removeProperty(self)
    }),
    setSelected(selected: boolean) {
      self.isSelected = selected
    },
    setIsSaving(saving: boolean) {
      self.isSaving = saving
    },
  }))
  .views((self) => ({
    get FormattedAddress() {
      // console.log('formatted', self.HouseNo)
      const houseNo = self.HouseNo ? self.HouseNo + ', ' : ''
      const streetNo = self.StreetNo ? self.StreetNo + ', ' : ''
      const village = self.Village ? self.Village + ', ' : ''
      const sangkat = self.Sangkat ? self.Sangkat + ', ' : ''
      const khan = self.Khan ? self.Khan + ', ' : ''
      const city = self.City ? self.City + ', ' : ''

      return `${houseNo}${streetNo}${village}${sangkat}${khan}${city}`
    },
  }))

const PropertyStore = types
  .model('PropertyStore', {
    properties: types.optional(types.array(Property), []),
    selectedProperty: types.safeReference(Property),
    loading: false,
  })
  .actions((self) => ({
    loadAllProperties: flow(function* () {
      self.loading = true
      applySnapshot(self.properties, [])
      const properties = yield api.getAllProperties()
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    loadNearbyProperties: flow(function* (options: PropertyQuery = {}) {
      applySnapshot(self.properties, [])
      self.loading = true
      const properties = yield api.getNearbyProperties(options)
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    loadPropertyByCitizenId: flow(function* (citizenId) {
      self.loading = true
      applySnapshot(self.properties, [])
      const properties = yield api.getCitizenProperties(citizenId)
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    loadPropertyByRouteId: flow(function* (routeId) {
      self.loading = true
      applySnapshot(self.properties, [])
      const properties = yield api.getPropertiesByRouteId(routeId)
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    loadPropertyByImportId: flow(function* (importId: string) {
      self.loading = true
      applySnapshot(self.properties, [])
      const properties = yield api.getPropertiesByImportId(importId)
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    loadPendingActivationProperties: flow(function* () {
      self.loading = true
      applySnapshot(self.properties, [])
      const properties = yield api.getPendingActivationProperties()
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    loadPropertiesWithNoLocation: flow(function* () {
      self.loading = true
      applySnapshot(self.properties, [])
      const properties = yield api.getPropertiesWithNoLocation()

      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    checkPropertiesCanBill: flow(function* () {
      self.loading = true
      applySnapshot(self.properties, [])
      const properties = yield api.checkPropertiesCanBill()
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    searchProperties: flow(function* (query: string) {
      applySnapshot(self.properties, [])
      self.loading = true
      const properties = yield api.searchProperty(query)
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })

      applySnapshot(self.properties, mappedProperties)
      self.loading = false
    }),

    addProperty: flow(function* (property: IProperty) {
      // console.log('user', user)
      const data: any = {
        features: {
          attributes: {
            ...property,
            Province: user?.province_code,
          },
        },
      }
      if (property.geometry && property.geometry.lng && property.geometry.lat) {
        data.features.geometry = {
          x: property.geometry.lng,
          y: property.geometry.lat,
        }

        data.features.attributes.HasLocation = 1
      } else {
        data.features.attributes.HasLocation = 0
      }
      const result: any = yield api.add(data)
      if (result.error) {
        alert('Cannot add member ' + result.error.details[0])
      } else {
        const OBJECTID = result?.addResults[0]?.objectId + ''
        const addedProperty = { ...property, OBJECTID }
        self.properties.push(addedProperty)
        return result
      }
    }),

    removeProperty(item: any) {
      self.properties.splice(self.properties.indexOf(item), 1)
    },

    getLastProperty() {
      return self.properties[self.properties.length - 1]
    },

    // async deleteAllProperties() {
    //   if (self.properties.length !== 0) {
    //     await Bluebird.map(
    //       self.properties,
    //       (property: any) => {
    //         return property.delete()
    //       },
    //       {
    //         concurrency: 5,
    //       }
    //     )
    //     this.emptyProperties()
    //   }
    // },

    emptyProperties() {
      applySnapshot(self.properties, [])
    },

    setSelectedProperty(property?: IProperty) {
      self.selectedProperty = property
    },
  }))
  .views((self) => ({
    get getPropertyList() {
      return self.selectedProperty ? [self.selectedProperty] : self.properties
    },

    getPropertyById(id: string) {
      return self.properties.find((property) => property.OBJECTID + '' === id)
    },

    get propertyWithNoLocation() {
      return self.selectedProperty
        ? [self.selectedProperty]
        : self.properties.filter((property) => property.HasLocation !== 1)
    },
  }))

export interface IProperty extends Instance<typeof Property> {}

export const propertyStore = PropertyStore.create({
  properties: [],
  selectedProperty: undefined,
})
