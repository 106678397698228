import { Image } from 'antd'
import React from 'react'
import styled from 'styled-components'

import notFoundImage from 'Assets/ImageNotFound.png'

import { IReport } from 'Models'
import { FILE_VIEW_URL } from 'Utils/Constants'

interface Props {
  report: IReport
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 400px;
  height: 200px;
`

const Header = styled.div`
  padding: 1rem 0;
  font-size: 18px;

  border-bottom: 1px solid #555;
`

const Body = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 1rem;
`

const Description = styled.div`
  padding: 4px 1rem;
  overflow: scroll;
`

export const ReportInfoWindow = (props: Props) => {
  const { report } = props
  const baseFileUrl = FILE_VIEW_URL

  function getImage(url: string | null) {
    if (!url) return notFoundImage
    return url.includes('http') ? url : `${baseFileUrl}/${url}`
  }

  return (
    <Container>
      <Header>
        <b>{report.type ? report.type : 'Undefined Waste'}</b>
      </Header>

      <Body>
        <Image
          width={100}
          height={100}
          src={getImage(report.photos?.length ? report.photos[0] : '')}
        />
        <Description>
          {report.description ? (
            report.description
          ) : (
            <i>No Description Provide</i>
          )}
        </Description>
      </Body>
    </Container>
  )
}
