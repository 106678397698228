import { Button, Form, Input, InputNumber as InputNumberBase } from 'antd'

import { FormInstance } from 'antd/lib/form'
import {
  CalculationOptionSelector,
  CalculationOptionsTable,
  ImageUpload,
} from 'Components'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  formatNumber,
  parseNumber,
  validateIsNumber,
  validateNumberInput,
} from 'Utils/Form'

const InputNumber = styled(InputNumberBase)`
  width: 135px;
`

const SaveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;

  width: 100%;
`

const InnerFormWrapper = styled.div`
  background-color: #eee;
  border-radius: 4px;
  margin-bottom: 1rem;
`

interface Prop {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
  calculationOptionForm?: FormInstance
  initialValues?: any
  onPhotoUploaded: (url: string | undefined) => void
  uploadedPhotoUrl: string
}

export const PropertyTypeForm = (props: Prop) => {
  const {
    onSubmit,
    saving,
    form,
    calculationOptionForm,
    initialValues,
    onPhotoUploaded,
    uploadedPhotoUrl,
  } = props

  const [calculationOptions, setCalculationOptions] = useState<
    Record<string | number, any>[]
  >(initialValues.calculationOptions ?? [])

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === 'CalculationOptionsForm') {
          const { PropertyTypeForm: mainForm } = forms
          const updateValues = (() => {
            if (
              values.maximumUnit !== undefined &&
              values.maximumUnit < values.minimumUnit
            ) {
              let newValues = values
              newValues.maximumUnit = newValues.minimumUnit
              return newValues
            }

            return values
          })()
          const index = calculationOptions.findIndex(
            (calculationOption: any) => calculationOption.name === values.name
          )
          if (index !== -1) {
            const newList = [
              ...calculationOptions.filter((_, i) => {
                return i !== index
              }),
              updateValues,
            ]
            const updatedValues = { calculationOptions: newList }
            setCalculationOptions(newList)
            mainForm.setFieldsValue(updatedValues)
          } else {
            const newList = [...calculationOptions, updateValues]
            const updatedValues = { calculationOptions: newList }
            setCalculationOptions(newList)
            mainForm.setFieldsValue(updatedValues)
          }
        }
      }}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        size="large"
        labelCol={{ span: 4 }}
        name="PropertyTypeForm"
        initialValues={initialValues}
      >
        <Form.Item
          label="Code"
          name="code"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input placeholder="Code" />
        </Form.Item>

        <Form.Item
          label="Name (Khmer)"
          name="name_khmer"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input placeholder="Name (Khmer)" />
        </Form.Item>

        <Form.Item
          label="Name (English)"
          name="name_english"
          rules={[{ required: false, message: 'Required' }]}
        >
          <Input placeholder="Name (English)" />
        </Form.Item>

        <Form.Item label="Fixed Fee" name="fixedFee" rules={[validateIsNumber]}>
          <InputNumber
            min={1}
            formatter={(value) => formatNumber(value)}
            parser={(value) => parseNumber(value)}
          />
        </Form.Item>

        <Form.Item label="photo">
          <ImageUpload
            onFileUploaded={onPhotoUploaded}
            uploadedPhotoUrl={uploadedPhotoUrl}
          ></ImageUpload>
        </Form.Item>

        <Form.Item name="calculationOptions"></Form.Item>
      </Form>

      <CalculationOptionsTable
        form={form}
        calculationOptions={calculationOptions}
        setCalculationOptions={setCalculationOptions}
      />

      <InnerFormWrapper>
        <Form
          style={{ padding: '6px' }}
          name="CalculationOptionsForm"
          size="large"
          labelCol={{ span: 4 }}
          form={calculationOptionForm}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Required' }]}
          >
            <CalculationOptionSelector />
          </Form.Item>

          <Form.Item
            label="Unit Price"
            name="unitPrice"
            rules={validateNumberInput}
          >
            <InputNumber
              min={0}
              formatter={(value) => formatNumber(value)}
              parser={(value) => parseNumber(value)}
            />
          </Form.Item>

          <Form.Item
            label="Landfill Price"
            name="landfillPrice"
            rules={validateNumberInput}
          >
            <InputNumber
              min={0}
              formatter={(value) => formatNumber(value)}
              parser={(value) => parseNumber(value)}
            />
          </Form.Item>

          <Form.Item
            label="Minimum Units:"
            name="minimumUnit"
            rules={validateNumberInput}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item
            label="Maximum Units:"
            name="maximumUnit"
            rules={[{ required: false }, validateIsNumber]}
          >
            <InputNumber />
          </Form.Item>

          <SaveButtonWrapper>
            <Button
              type="primary"
              onClick={() => calculationOptionForm?.submit()}
              loading={saving}
            >
              Add
            </Button>
          </SaveButtonWrapper>
        </Form>
      </InnerFormWrapper>

      <SaveButtonWrapper>
        <Button type="primary" onClick={() => form?.submit()} loading={saving}>
          Save
        </Button>
      </SaveButtonWrapper>
    </Form.Provider>
  )
}
