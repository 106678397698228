import React, { useState } from 'react'
import { useAntdTable } from 'ahooks'
import {
  Button,
  DatePicker,
  Form,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import styled from 'styled-components'
import { monthOnlyFormat } from 'Utils/Date'
import { environment } from 'environment'
import { renderStatus } from 'Utils/property'
import { DownloadButton, EditButton, PropertyTypeSelector } from 'Components'
import { INVOICE_SERVER_URL } from 'Utils/Constants'
import { CollectorSelector } from 'Components/CollectorSelector'
import { IsActiveSelector } from 'Components/IsActiveSelector/IsActiveSelector'
import { PropertyEditContainer } from './Edit'
import { observer } from 'mobx-react-lite'
const { apiBaseUrl } = environment

type Props = {}

const Container = styled.div`
  padding: 20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface Result {
  total: number
  list: any[]
}

async function onDownloadClick(bill: any) {
  const url = `${INVOICE_SERVER_URL}/generate-invoice-by-billId/${bill.OBJECTID}`
  var link = document.createElement('a')
  link.setAttribute('download', `Invoice_${bill.OBJECTID}.pdf`)
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const toCurrency = (value?: number | string, symbol = '៛') => {
  if (!value) return ''

  const commaFormatted = String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  return `${commaFormatted}${symbol}`
}

const getMonth = (date: any) => {
  return moment(date).isValid()
    ? moment(date).add(1, 'd').format(monthOnlyFormat).toString()
    : ''
}

const getTableData = (
  {
    current,
    pageSize,
  }: {
    current: any
    pageSize: any
  },
  formData: any
): Promise<Result> => {
  let query = `page=${current}&limit=${pageSize}`

  Object?.entries(formData).forEach(([key, value]) => {
    if (value || value === 0) {
      query += `&${key}=${
        //@ts-ignore
        key === 'month' ? value.format('MM/YYYY').toString() : value
      }`
    }
  })

  return axios
    .get(`${apiBaseUrl}/api/properties/all-properties?${query}`)
    .then((res) => {
      return {
        total: res.data.total,
        list: res.data.data,
      }
    })
}

const AllPropertyListPage = observer((props: Props) => {
  const [form] = Form.useForm()
  const [selectedProperty, setSelectedProperty] = useState()

  const {
    tableProps,
    search: { submit },
    pagination: { total },
    refreshAsync,
  } = useAntdTable(getTableData, {
    defaultPageSize: 10,
    form,
  })

  const columns = [
    {
      title: 'លេខកូដទីតាំង',
      dataIndex: 'OBJECTID',
      key: 'OBJECTID',
    },
    {
      title: 'ឈ្មោះទីតាំង',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'អ្នកប្រមូលថ្លៃសេវា',
      dataIndex: 'Collector',
      key: 'Collector',
    },
    {
      title: 'តម្លៃចុងក្រោយ',
      dataIndex: 'DiscountedFee',
      key: 'DiscountedFee',
    },
    {
      title: 'ស្ថានភាព',
      dataIndex: 'IsActive',
      key: 'IsActive',
      render: (value: string, record: any) => {
        return renderStatus(record)
      },
    },
    {
      title: 'តម្លៃចាស់',
      dataIndex: 'OldFee',
      key: 'OldFee',
      render: (value: any, record: any) => {
        return toCurrency(value)
      },
    },
    {
      title: 'វិក្កយប័ត្រ',
      children: [
        {
          title: 'លេខវិក្កយប័ត្រ',
          dataIndex: ['bill', 'OBJECTID'],
          key: 'billId',
        },
        {
          title: 'តម្លៃសេវា',
          dataIndex: ['bill', 'Amount'],
          key: 'billAmount',
          render: (value: any, record: any) => {
            if (!record?.bill) {
              return <Tag color="red">មិនទាន់មានវិក្កយបត្រ</Tag>
            }

            return toCurrency(value)
          },
        },
        {
          title: 'សម្រាប់ខែ',
          dataIndex: ['bill', 'BillForDate'],
          key: 'billMonth',
          render: (value: any, record: any) => {
            return getMonth(value)
          },
        },
        {
          title: 'ការទូទាត់ប្រាក់',
          dataIndex: ['bill', 'Paid'],
          key: 'billPaid',
          render: (value: any, record: any) => {
            if (!record?.bill) {
              return null
            }

            const data =
              value === 1
                ? { color: 'green', title: 'បង់ប្រាក់រួចរាល់' }
                : { color: 'red', title: 'មិនទាន់បង់ប្រាក់' }

            return <Tag color={data.color}>{data.title}</Tag>
          },
        },
      ],
    },
    {
      title: 'Action',
      key: 'action',
      render: (value: string, record: any) => {
        return (
          <Space>
            <Tooltip title="ទាញយកវិក្កយប័ត្រ">
              <EditButton
                onClick={() => setSelectedProperty(record?.OBJECTID)}
              />
            </Tooltip>
            {!!record?.bill && (
              <Tooltip title="ទាញយកវិក្កយប័ត្រ">
                <DownloadButton onClick={() => onDownloadClick(record?.bill)} />
              </Tooltip>
            )}
          </Space>
        )
      },
    },
  ]

  async function onSaveFinish() {
    await refreshAsync()
    setSelectedProperty(undefined)
  }

  return (
    <Container>
      <Form form={form}>
        <Space>
          <Form.Item
            label="Collector"
            name="collector"
            rules={[{ required: false, message: 'Required' }]}
          >
            <CollectorSelector />
          </Form.Item>
          <Form.Item
            label="Status"
            name="IsActive"
            rules={[{ required: false, message: 'Required' }]}
          >
            <IsActiveSelector />
          </Form.Item>
          <Form.Item label="ប្រភេទទីតាំង" name="type" style={{ width: 320 }}>
            <PropertyTypeSelector />
          </Form.Item>
          <Form.Item label="ខែ" name="month" initialValue={''} onReset={submit}>
            <DatePicker
              placeholder="ជ្រើសរើសខែ"
              picker="month"
              format={'MM/YYYY'}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={submit}>
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Table
        columns={columns}
        rowKey="OBJECTID"
        {...tableProps}
        pagination={{ ...tableProps.pagination, pageSizeOptions: [10, 30, 50] }}
      />

      {!!selectedProperty && (
        <Modal
          title="កែសម្រួលទីតាំង (Edit Property)"
          visible={!!selectedProperty}
          onCancel={() => setSelectedProperty(undefined)}
          footer={[]}
          width={600}
        >
          <PropertyEditContainer
            propertyId={selectedProperty!}
            onSaveFinish={onSaveFinish}
          ></PropertyEditContainer>
        </Modal>
      )}
    </Container>
  )
})

export { AllPropertyListPage }
export default AllPropertyListPage
