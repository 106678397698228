import React, { useEffect, useState } from 'react'

import { IBill } from 'Models'
import { Spinner } from 'Components'
import { TransactionHistoryList } from './TransactionHistoryList'
import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.div``

interface Props {
  bill: IBill
}

export const TransactionHistory = observer((props: Props) => {
  const { bill } = props
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getRecords() {
      setLoading(true)
      try {
        await bill.loadTransactionHistories()
      } catch (e: any) {
        message.error(e.message)
      }
      setLoading(false)
    }

    getRecords()
  }, [bill])

  return (
    <Container>
      {loading && <Spinner></Spinner>}
      <TransactionHistoryList
        transactionHistories={bill.transactionHistories.slice()}
      ></TransactionHistoryList>
    </Container>
  )
})
