import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { Spinner } from 'Components'
import { observer } from 'mobx-react-lite'
import { propertyStore } from 'Models/PropertyModel'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 250px;
`

const Center = styled.div`
  width: 250px;
  display: flex;
  justify-content: center;
`

interface Prop {
  onPropertyIdSelected?: (propertyId: string) => void
}

export const PropertySelector = observer((props: Prop) => {
  const { onPropertyIdSelected } = props
  const { properties, selectedProperty, loading } = propertyStore
  const selectedValue = selectedProperty
    ? selectedProperty.OBJECTID + ''
    : undefined

  function onChange(id: any) {
    id && onPropertyIdSelected && onPropertyIdSelected(id.toString())
    id &&
      propertyStore.setSelectedProperty(
        propertyStore.getPropertyById(id.toString())
      )
  }

  if (loading) {
    return (
      <Center>
        <Spinner size="middle"></Spinner>
      </Center>
    )
  }

  return (
    <StyledSelect value={selectedValue} onChange={onChange}>
      {properties?.map((property) => {
        return (
          <Option key={property.OBJECTID} value={property.OBJECTID}>
            {`${property.Name}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
})
