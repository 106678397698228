import { boolean } from 'mobx-state-tree/dist/internal'
import { user } from 'Models/UserModel/UserModel'

export const PROPERTY_PAGE = 'PROPERTY_PAGE'
export const PROPERTY_ACTIVATION_PAGE = 'PROPERTY_ACTIVATION_PAGE'
export const SCHEDULE_PAGE = 'SCHEDULE_PAGE'
export const TRUCK_PAGE = 'TRUCK_PAGE'
export const ASSIGN_PROPERTY_TO_ROUTE_PAGE = 'ASSIGN_PROPERTY_TO_ROUTE_PAGE'
export const BILLING_PAGE = 'BILLING_PAGE'
export const PROPERTY_BILL_PAGE = 'PROPERTY_BILL_PAGE'
export const CITIZEN_PROFILE_PAGE = 'CITIZEN_PROFILE_PAGE'
export const LIVE_TRUCK_LOCATION_PAGE = 'LIVE_TRUCK_LOCATION_PAGE'
export const COLLECTION_POINTS_PAGE = 'COLLECTION_POINTS_PAGE'
export const USER_PAGE = 'USER_PAGE'
export const PROPERTY_TYPE_PAGE = 'PROPERTY_TYPE_PAGE'
export const BUSINESS_PAGE = 'BUSINESS_PAGE'
export const SHV_BILLING = 'SHV_BILLING'
export const COLLECTION_COMPANY_PAGE = 'COLLECTION_COMPANY_PAGE'
export const IMPORTED_DATA = 'IMPORTED_DATA'
export const REPORT_PAGE = 'REPORT_PAGE'
export const COLLECTOR_PAGE = 'COLLECTOR_PAGE'
export const DAILY_REPORT_PAGE = 'DAILY_REPORT_PAGE'
export const DISTRIBUTE_REPORT = 'DISTRIBUTE_REPORT'
export const LIST_ALL_PROPERTY = 'LIST_ALL_PROPERTY'
export const CHECK_LOGS = 'CHECK_LOGS'
export const GENERATE_BILL = 'GENERATE_BILL'

export const Pages = {
  PROPERTY_ACTIVATION_PAGE: PROPERTY_ACTIVATION_PAGE,
  PROPERTY_PAGE: PROPERTY_PAGE,
  PROPERTY_BILL_PAGE: PROPERTY_BILL_PAGE,
  SCHEDULE_PAGE: SCHEDULE_PAGE,
  TRUCK_PAGE: TRUCK_PAGE,
  ASSIGN_PROPERTY_TO_ROUTE_PAGE: ASSIGN_PROPERTY_TO_ROUTE_PAGE,
  BILLING_PAGE: BILLING_PAGE,
  CITIZEN_PROFILE_PAGE: CITIZEN_PROFILE_PAGE,
  LIVE_TRUCK_LOCATION_PAGE: LIVE_TRUCK_LOCATION_PAGE,
  COLLECTION_POINTS_PAGE: COLLECTION_POINTS_PAGE,
  PROPERTY_TYPE_PAGE: PROPERTY_TYPE_PAGE,
  USER_PAGE: USER_PAGE,
  BUSINESS_PAGE: BUSINESS_PAGE,
  SHV_BILLING: SHV_BILLING,
  COLLECTION_COMPANY_PAGE: COLLECTION_COMPANY_PAGE,
  IMPORTED_DATA: IMPORTED_DATA,
  REPORT_PAGE: REPORT_PAGE,
  COLLECTOR_PAGE: COLLECTOR_PAGE,
  DAILY_REPORT_PAGE: DAILY_REPORT_PAGE,
  DISTRIBUTE_REPORT: DISTRIBUTE_REPORT,
  LIST_ALL_PROPERTY: LIST_ALL_PROPERTY,
  CHECK_LOGS: CHECK_LOGS,
  GENERATE_BILL: GENERATE_BILL,
}

export const enableDruggingProperty = 'enableDruggingProperty'
export const showAllPropertyField = 'showAllPropertyField'
export const createProperty = 'createPropertyField'

export const Admin = 'Admin'
export const WasteCompany = 'Waste Company'
export const MinistryOfEnvironment = 'MOE'
export const KSWM = 'KSWM'
export const SmartSchedule = 'Smart Schedule'
export const BillingManager = 'Billing Manager'
export const Accounting = 'Accounting'
export const IT = 'IT'
export const Agent = 'Agent'
export const DataEntry = 'Data Entry'

export const Roles = [
  Admin,
  WasteCompany,
  MinistryOfEnvironment,
  // KSWM,
  // SmartSchedule,
  // BillingManager,
  Accounting,
  IT,
  Agent,
  DataEntry,
]
export const Locations = [
  {
    value: '18',
    label: 'Shihanoukville',
  },
  {
    value: '3',
    label: 'Kampong Cham',
  },
  {
    value: '801',
    label: 'Ang Snoul',
  },
]
export const AccessRules: { [key: string]: { [key: string]: boolean } } = {}

export const hasAccess = (page: string): boolean => {
  if (!AccessRules[page]) {
    return false
  }
  return AccessRules[page][user.role ?? '']
}

export const permissions = {
  [enableDruggingProperty]: [Admin, MinistryOfEnvironment],
  [showAllPropertyField]: [
    Admin,
    WasteCompany,
    MinistryOfEnvironment,
    IT,
    DataEntry,
  ],
  [createProperty]: [
    Admin,
    WasteCompany,
    MinistryOfEnvironment,
    KSWM,
    IT,
    DataEntry,
  ],
}

AccessRules[PROPERTY_ACTIVATION_PAGE] = {
  [Admin]: true,
  [WasteCompany]: true,
  [MinistryOfEnvironment]: true,
  [KSWM]: true,
}

AccessRules[PROPERTY_PAGE] = {
  [Admin]: true,
  [WasteCompany]: true,
  [MinistryOfEnvironment]: true,
  [KSWM]: true,
  [BillingManager]: true,
  [Accounting]: true,
  [IT]: true,
  [DataEntry]: true,
}

AccessRules[PROPERTY_BILL_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
  [IT]: true,
}

AccessRules[SCHEDULE_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
  [SmartSchedule]: true,
}

AccessRules[DAILY_REPORT_PAGE] = {
  [Admin]: true,
  [WasteCompany]: true,
  [MinistryOfEnvironment]: true,
  [SmartSchedule]: false,
  [Accounting]: true,
  [IT]: true,
}

AccessRules[DISTRIBUTE_REPORT] = {
  [Admin]: true,
  [WasteCompany]: true,
  [MinistryOfEnvironment]: true,
  [SmartSchedule]: false,
  [IT]: true,
}

AccessRules[CHECK_LOGS] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
  [SmartSchedule]: false,
  [IT]: true,
}

AccessRules[LIST_ALL_PROPERTY] = {
  [Admin]: true,
  [WasteCompany]: true,
  [MinistryOfEnvironment]: true,
  [IT]: true,
}

AccessRules[TRUCK_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: true,
}

AccessRules[ASSIGN_PROPERTY_TO_ROUTE_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
  [IT]: true,
}

AccessRules[BILLING_PAGE] = {
  [Admin]: true,
  [WasteCompany]: true,
  [MinistryOfEnvironment]: true,
  [KSWM]: true,
  [BillingManager]: true,
  [Accounting]: true,
  [IT]: true,
}

AccessRules[CITIZEN_PROFILE_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
}

AccessRules[COLLECTION_POINTS_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
}

AccessRules[PROPERTY_TYPE_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: true,
}

AccessRules[USER_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
}

AccessRules[BUSINESS_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
}

AccessRules[SHV_BILLING] = {
  [Admin]: false,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
}

AccessRules[COLLECTION_COMPANY_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
}

AccessRules[IMPORTED_DATA] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: false,
}

AccessRules[REPORT_PAGE] = {
  [Admin]: true,
  [WasteCompany]: false,
  [MinistryOfEnvironment]: true,
  [IT]: true,
}

AccessRules[COLLECTOR_PAGE] = {
  [Admin]: true,
  [WasteCompany]: true,
  [MinistryOfEnvironment]: true,
  [KSWM]: true,
}

AccessRules[GENERATE_BILL] = {
  [Admin]: true,
  [IT]: true,
}
