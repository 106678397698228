import { PAYMENT_RECORDS_SERVER_URL as SERVER_URL } from 'Utils/Constants'
import axios from 'axios'

export const getTransactionHistoriesOfAnInvoice = async (invoiceId: string) => {
  const filter = {
    where: {
      invoiceId: invoiceId,
    },
  }
  const url = `${SERVER_URL}/transactionHistories?filter=${JSON.stringify(
    filter
  )}`
  const result = await axios.get(url)
  console.log('result: ', result)
  if (!result) {
    throw new Error('Error while fetching transaction history')
  }
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}
