import * as api from './api'

import { Progress, message } from 'antd'
import React, { useState } from 'react'

import { Button } from 'antd'
import { FileDrop } from 'Components/FileDrop/FileDrop'
import { PendingDataTable } from './PendingDataTable'
//@ts-ignore
import readXlsxFile from 'read-excel-file'
import styled from 'styled-components'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, WasteCompany } from 'Auth/Role'

const Container = styled.div``

const Title = styled.div`
  font-size: 2rem;
`

const StartButton = styled(Button)`
  border-radius: 10px;
  width: 100%;
  height: 120px;
  font-size: large;
`

export type ExcelRecord = {
  Branch?: string
  Name?: string
  CustomerLocationCode?: string
  Village?: string
  Commune?: string
  District?: string
  Province?: string
  BusinessDescription?: string
  KhmerAddress?: string
}

const schema = {
  Branch: {
    prop: 'Branch',
    type: String,
    required: false,
  },
  CustomerLocationCode: {
    prop: 'CustomerLocationCode',
    type: String,
    required: false,
  },
  Name: {
    prop: 'Name',
    type: String,
    required: false,
  },
  Village: {
    prop: 'Village',
    type: String,
    required: false,
  },
  Commune: {
    prop: 'Commune',
    type: String,
    required: false,
  },
  District: {
    prop: 'District',
    type: String,
    required: false,
  },
  Province: {
    prop: 'Province',
    type: String,
    required: false,
  },
  BusinessDescription: {
    prop: 'BusinessDescription',
    type: String,
    required: false,
  },
  KhmerAddress: {
    prop: 'KhmerAddress',
    type: String,
    required: false,
  },
}

interface Props {
  onImportFinished?: () => void
}
export const CustomerImport = (props: Props) => {
  const { onImportFinished } = props
  const [saving, setSaving] = useState(false)
  const [addedCount, setAddedCount] = useState(0)
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const percentage = (addedCount / totalRecordCount) * 100
  const [pendingData, setPendingData] = useState<ExcelRecord[]>([])
  const hasData = pendingData.length > 0

  async function onFilesDrop(files: File[]) {
    const { rows } = await readXlsxFile(files[0], { schema })
    console.log('rows: ', rows)
    setPendingData(rows)
  }

  async function startImport() {
    setSaving(true)
    setTotalRecordCount(pendingData.length)
    try {
      await api.addRecords(
        pendingData,
        function onProgress(addedCount: number) {
          setAddedCount(addedCount)
        }
      )
      message.success('Import successful')
      onImportFinished && onImportFinished()
    } catch (e: any) {
      message.error('Error: ' + e.message)
    }
  }

  if (saving) {
    return (
      <Container>
        <div>Importing</div>
        <div>
          Added {addedCount}/{totalRecordCount}
        </div>
        <Progress percent={percentage}></Progress>
      </Container>
    )
  }

  return componentLevelAuthorization(
    (<Container>
      {hasData ? (
        <StartButton type="primary" onClick={startImport}>
          <div>Confirm that the data below is correct.</div>
          <div>Click here to Start Import</div>
        </StartButton>
      ) : (
        <FileDrop accept=".xlsx" onDrop={onFilesDrop}></FileDrop>
      )}
      {pendingData.length > 0 && (
        <PendingDataTable data={pendingData}></PendingDataTable>
      )}
    </Container>),
    [Admin, WasteCompany]
  )
}
