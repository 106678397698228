import { Form, message } from 'antd'
import React, { useState } from 'react'

import { IPropertyType } from 'Models/PropertyTypeModel/PropertyTypeModel'
import { PropertyTypeForm } from '../Form/PropertyTypeForm'
import { prepareSubmitData } from '../Form/helper'
import { getFileListAsString, getImage } from 'Utils/files'

interface Prop {
  propertyType: IPropertyType
  onSaveFinish?: () => void
}

export const PropertyTypeEditContainer = (props: Prop) => {
  const { propertyType } = props
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [calculationOptionForm] = Form.useForm()
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>(
    getImage(propertyType?.photo || '') ?? ''
  )

  const initialValues = { ...propertyType }

  async function onSubmit(values: any) {
    const data = prepareSubmitData({
      ...values,
      photo: getFileListAsString(uploadedPhotoUrl),
    })

    setSaving(true)
    try {
      await propertyType.edit(data)
    } catch (e: any) {
      console.error(e)
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  function onPhotoUploaded(url?: string) {
    setUploadedPhotoUrl(url ?? '')
  }

  return (
    <PropertyTypeForm
      form={form}
      onSubmit={onSubmit}
      calculationOptionForm={calculationOptionForm}
      initialValues={initialValues}
      saving={saving}
      onPhotoUploaded={onPhotoUploaded}
      uploadedPhotoUrl={uploadedPhotoUrl}
    ></PropertyTypeForm>
  )
}
