import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

export type Property = {
  attributes: {
    OBJECTID: number
    Name: string
    RouteID: string
  }
}

export const getPropertiesByCitizenId = async (
  citizenId: string
): Promise<Property[]> => {
  const query = `where=CitizenID='${citizenId}'`
  const url = `${apiBaseUrl}/api/properties/query?${query}`
  const result = await axios.get(url)
  return result.data
}
