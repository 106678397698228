import { IBill, billStore } from 'Models'
import React, { useState } from 'react'
import { Select, message } from 'antd'

import styled from 'styled-components'
import { getQueryByProvince } from 'Models/BillModel/api'

const { Option } = Select

interface SelectProp {
  loading: boolean
}

const StyledSelect = styled(Select)<SelectProp>`
  width: 250px;
  color: ${(props) => (props.loading ? 'coral' : 'black')};
`

interface Props {
  onBillIdSelected?: (id: string) => void
  onBillSelected?: (bill: IBill) => void
  disabled?: boolean
}

export const BillSearchInput = (props: Props) => {
  const { onBillIdSelected, onBillSelected, disabled } = props
  const { bills } = billStore

  const [loading, setLoading] = useState(false)

  async function search(query: string) {
    setLoading(true)
    const queryString = `where=(PropertyID LIKE '${query}%' OR OBJECTID LIKE '${query}%') ${getQueryByProvince(
      false
    )}`
    try {
      await billStore.searchBills(queryString)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }
    }
    setLoading(false)
  }

  function onChange(value: any) {
    const bill = bills.find((billObj) => {
      return billObj.OBJECTID + '' === value
    })
    if (bill) {
      onBillSelected && onBillSelected(bill)
    }
    onBillIdSelected && onBillIdSelected(value + '')
  }

  function onSearch(val: string) {
    if (val.length > 0) {
      search(val)
    } else {
      billStore.emptyBills()
    }
  }

  return (
    <StyledSelect
      {...props}
      showSearch
      loading={loading}
      placeholder="Type to Search Bill"
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      disabled={disabled}
      filterOption={(input, option) => {
        if (option && option.children) {
          const match =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          return match
        } else {
          return false
        }
      }}
    >
      <Option key={'NONE'} value={''}>
        None
      </Option>
      {bills.map((bill) => {
        return (
          <Option key={bill.OBJECTID} value={bill.OBJECTID + ''}>
            {`property's id: ${bill.PropertyID}, bill's id: ${bill.OBJECTID}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
