import React, { useState } from 'react'
import { Space, Table } from 'antd'

import { CheckCircleOutlined } from '@ant-design/icons'
import { ITransactionHistory } from 'Models/BillModel/TransactionHistory/TransactionHistoryModel'
import moment from 'moment'
import styled from 'styled-components'

const Container = styled.div``

const Success = styled(Space)`
  color: white;
  background-color: green;
  padding: 10px;
  border-radius: 8px;
`

interface Props {
  transactionHistories: ITransactionHistory[]
}
export const TransactionHistoryList = (props: Props) => {
  const { transactionHistories } = props

  const columns = [
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: function render (text: string, record: ITransactionHistory) {
        return (<span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>)
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: function render(text: string, record: ITransactionHistory) {
        return (
          <span>
            {record.status === 'Success' ? (
              <>
                <Success>
                  <CheckCircleOutlined
                    style={{ color: 'white', fontSize: 'x-large' }}
                  />
                  <div>{record.status}</div>
                </Success>
              </>
            ) : (
              <span>{record.status}</span>
            )}
          </span>
        )
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
  ]

  return (
    <Container>
      <Table columns={columns} dataSource={transactionHistories}></Table>
    </Container>
  )
}
