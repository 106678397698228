import React from 'react'
import styled from 'styled-components'
import { DownloadButton, MoreButton as BaseMoreButton } from 'Components'
import { IBill } from 'Models'
import { Spin } from 'antd'
import moment from 'moment'
import { INVOICE_SERVER_URL } from 'Utils/Constants'

const Title = styled.h3`
  margin-top: auto;
`

const Container = styled.section`
  height: 100%;
  overflow: scroll;
`

const Item = styled.div`
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  height: 40px;
  background: white;
  cursor: pointer;
  &:hover {
    background: lightgray;
  }
`

const LeftContainer = styled.div`
  margin-left: auto;
`

const MoreButton = styled(BaseMoreButton)`
  margin-left: 5px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

const UnpaidLabel = styled.div`
  background: #ffabab;
  width: fit-content;
  padding: 0px 8px;
  border-radius: 4px;
  margin-left: auto;
`

const PaidLabel = styled.div`
  background: #50e3c2;
  width: fit-content;
  padding: 0px 6px;
  border-radius: 4px;
  margin-left: auto;
`

interface Props {
  bills?: IBill[]
  onBillClick?: (bill: IBill) => void
  onMoreButtonClick?: (bill: IBill) => void
  loading?: boolean
}

async function onDownloadClick(bill: IBill) {
  const url = `${INVOICE_SERVER_URL}/generate-invoice-by-billId/${bill.OBJECTID}`
  var link = document.createElement('a')
  link.setAttribute('download', `Invoice_${bill.OBJECTID}.pdf`)
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const BillList = (props: Props) => {
  const {
    bills = [],
    loading
  } = props
  return (
    <Container>
      {loading && <Spin />}
      {bills.length === 0? <Title>There is no Bills.</Title> :
        bills.map(billObject => {
          //@ts-ignore
          const bill = billObject?.attributes
        return (
          <Item
            key={bill.OBJECTID}
          >
            <Row>
              <div>{`(id: ${bill.OBJECTID})`} for: {moment(bill.BillForDate).format('MMM-YYYY')}</div>
              <div>&nbsp;by: {bill?.Editor}</div>
              {bill.Paid? <PaidLabel>Paid: Yes</PaidLabel> : <UnpaidLabel>Paid: No</UnpaidLabel>}
              <LeftContainer>
              {! bill.Paid && <DownloadButton
                  onClick={() => onDownloadClick(bill)}
                ></DownloadButton>}
              </LeftContainer>
              
            </Row>
          </Item>
        )
      })}
    </Container>
  )
}
