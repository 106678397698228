import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import { PropertyQuery } from './api'

const Geometry = types.model('Geometry', {
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
})

export const Business = types
  .model('Business', {
    OBJECTID: types.identifier,
    Label: types.maybeNull(types.string),
    Label_kh: types.maybeNull(types.string),
    type: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.string)
    ),
    city_kh: types.maybeNull(types.string),
    sangkat_kh: types.maybeNull(types.string),
    Vill_Code: types.maybeNull(types.number),
    com_code: types.maybeNull(types.number),
    dist_code: types.maybeNull(types.number),
    Pro_Code: types.maybeNull(types.number),
    Bars: types.maybeNull(types.number),
    Floors: types.maybeNull(types.number),
    Restaurants: types.maybeNull(types.number),
    Room: types.maybeNull(types.number),
    Star_Rating: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.string)
    ),
    Verified_St: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    Floor_Area: types.maybeNull(types.string),
    Phone_No: types.maybeNull(types.string),
    Gym: types.maybeNull(types.number),
    Bed: types.maybeNull(types.number),
    Land_Size: types.maybeNull(types.number),
    wastec_rev: types.maybeNull(types.number),
    Actual_Collection_Fee: types.maybeNull(types.number),
    geometry: types.maybe(Geometry),
    isSelected: false,
    isSaving: false,
    isDeleting: false,
  })
  .actions((self) => ({
    edit: flow(function* (values) {
      let data = {
        OBJECTID: self.OBJECTID,
        ...values,
      }
      yield api.edit(data)
      const updated = {
        ...self,
        ...values,
      }
      applySnapshot(self, updated)
    }),
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.OBJECTID + '')
      const store = getParent(self, 2) as Instance<typeof BusinessStore>
      store.remove(self)
    }),
    setSelected(selected: boolean) {
      self.isSelected = selected
    },
    setIsSaving(saving: boolean) {
      self.isSaving = saving
    },

    setVerify: flow(function* (verifyValue: number) {
      const data = {
        OBJECTID: self.OBJECTID,
        Verified_St: verifyValue,
      }
      yield api.edit(data)
      const updated = {
        ...self,
        ...data,
      }
      applySnapshot(self, updated)
    }),
  }))

const BusinessStore = types
  .model('BusinessStore', {
    businesses: types.optional(types.array(Business), []),
    selectedProperty: types.safeReference(Business),
    loading: false,
  })
  .actions((self) => ({
    loadNearbyBusinesses: flow(function* (options: PropertyQuery = {}) {
      applySnapshot(self.businesses, [])
      self.loading = true
      const properties = yield api.getNearbyBusinesses(options)
      const mappedBusinesses = properties?.map((business: any) => {
        return {
          ...business.attributes,
          OBJECTID: business.attributes.OBJECTID + '',
          geometry: {
            lng: business.geometry?.x,
            lat: business.geometry?.y,
          },
        }
      })

      applySnapshot(self.businesses, mappedBusinesses)
      self.loading = false
    }),

    searchBusinesses: flow(function* (query: string) {
      applySnapshot(self.businesses, [])
      self.loading = true
      const properties = yield api.searchBusiness(query)
      const mappedBusinesses = properties?.map((business: any) => {
        return {
          ...business.attributes,
          OBJECTID: business.attributes.OBJECTID + '',
          geometry: {
            lng: business.geometry?.x,
            lat: business.geometry?.y,
          },
        }
      })

      applySnapshot(self.businesses, mappedBusinesses)
      self.loading = false
    }),

    addBusiness: flow(function* (business: any) {
      const data = {
        features: {
          attributes: {
            ...business,
          },
          geometry: business.geometry,
        },
      }
      const result = yield api.add(data)
      if (result.error) {
        throw new Error(result.error.details[0])
      } else {
        const OBJECTID = result?.addResults[0]?.objectId + ''
        const { x: lng, y: lat } = business.geometry
        const added = { ...business, geometry: { lat, lng }, OBJECTID }
        self.businesses.push(added)
        return self.businesses[self.businesses.length - 1]
      }
    }),

    remove(item: any) {
      self.businesses.splice(self.businesses.indexOf(item), 1)
    },

    emptyBusinesses() {
      applySnapshot(self.businesses, [])
    },

    setSelectedBusiness(business: string | undefined) {
      // @ts-ignore:
      self.selectedBusiness = business
    },
  }))

export interface IBusiness extends Instance<typeof Business> {}

export const businessStore = BusinessStore.create({
  businesses: [],
})
