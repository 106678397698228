import { Button, DatePicker, message, Modal } from 'antd'
import { Spinner } from 'Components'
import { IBill, billStore } from 'Models/BillModel'
import React, { useEffect, useState } from 'react'

import { BillAddContainer } from './Add'
import { BillList } from './BillList'
import { PlusCircleOutlined } from '@ant-design/icons'
import { PropertyStatusEditContainer } from './PropertyStatusEdit'
import { observer } from 'mobx-react-lite'
import { propertyStore } from 'Models/PropertyModel'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'
import { BillSearchInput } from 'Components/BillSearchInput/BillSearchInput'
import { handleApiError } from 'Utils'
import { isUserMOE } from 'Auth/Authorization'
import { FilterDaySelector } from 'Components/FilterDaySelector/FilterDaySelector'
import moment from 'moment'
import { arcgisDateFormat } from 'Utils/Date'
import { BillStatusSelector } from 'Components/BillStatusSelector/BillStatusSelector'
import { IsActiveSelector } from 'Components/IsActiveSelector/IsActiveSelector'
import { PaymentMethodSelector } from 'Components/PaymentMethodSelector/PaymentMethodSelector'
import { getQueryByProvince } from 'Models/BillModel/api'

const { RangePicker } = DatePicker
const Container = styled.div`
  padding: 20px;
`

const CitizenBox = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`

const BillSearchBox = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`

const Label = styled.div`
  color: gray;
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const BillingPage = observer((props: any) => {
  const { selectedProperty } = propertyStore
  const [shouldShowAddModal, setShouldShowAddModal] = useState(false)
  const [
    shouldShowEditPropertyStatusModal,
    setShouldShowEditPropertyStatusModal,
  ] = useState(false)
  const [loadingBills, setLoadingBills] = useState(false)
  const [selectedBill, setSeletectedBill] = useState<IBill>()
  const [filterDay, setFilterDay] = useState('')
  const [billStatus, setBillStatus] = useState('')
  const [propertyStatus, setPropertyStatus] = useState('')
  const [invoicePaidDate, setInvoicePaidDate] = useState<any>(null)
  const [billForMonth, setBillForMonth] = useState<any>(null)
  const [paymentMethod, setPaymentMethod] = useState('')

  useEffect(() => {
    billStore.emptyBills()
  }, [])

  function onBillSelectedFromSearch(bill: IBill) {
    setSeletectedBill(bill)
  }

  function displayBill() {
    return selectedBill ? [selectedBill] : billStore.bills
  }

  const combineQueryString = () => {
    let queryString = 'where=('
    let concate = ''

    if (billForMonth) {
      const selectMonth = moment(billForMonth).startOf('month')
      const fromBillForDate = selectMonth
        .subtract(3, 'day')
        .format(arcgisDateFormat)
      const toBillForDate = selectMonth.add(5, 'd').format(arcgisDateFormat)

      queryString += `(BillForDate BETWEEN timestamp '${fromBillForDate}' AND timestamp '${toBillForDate}')`
      concate = ' AND '
    }

    if (billStatus || billStatus == '0') {
      queryString += `${concate}(Paid=${billStatus})`
      concate = ' AND '
    }

    if (propertyStatus || propertyStatus == '0') {
      queryString += `${concate}(PropertyStatus=${propertyStatus})`
      concate = ' AND '
    }

    if (paymentMethod) {
      queryString += `${concate}(PaymentMethod='${paymentMethod}')`
      concate = ' AND '
    }

    if (invoicePaidDate) {
      const startDate = invoicePaidDate[0]
        .startOf('day')
        .format(arcgisDateFormat)
      const endDate = invoicePaidDate[1].endOf('day').format(arcgisDateFormat)

      queryString += `${concate}(PaidDate BETWEEN timestamp '${startDate}' AND timestamp '${endDate}')`
      concate = ' AND '
    }

    queryString += getQueryByProvince(false, concate === '' ? false : true)
    queryString += ')'

    if (queryString === 'where=()') {
      queryString = ''
    }

    queryString += '&orderByFields=PaidDate DESC'

    return queryString
  }

  const handleBillForMonth = async (date: any) => {
    setBillForMonth(date)
  }

  async function handlePaidDateSelect(selectedDate: any) {
    if (selectedDate.length >= 2) {
      setInvoicePaidDate(selectedDate)
    }
  }

  async function handleBillStatusSearch(paid: any) {
    setBillStatus(paid)
  }

  const handleIsActiveSelect = (value: any) => {
    setPropertyStatus(value)
  }

  const handlePaymentMethodSelect = (value: any) => {
    setPaymentMethod(value)
  }

  const handleSearch = async () => {
    const queryString = combineQueryString()
    console.log('queryString ===>', queryString)

    setLoadingBills(true)
    try {
      await billStore.searchBills(queryString)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }
    }
    setLoadingBills(false)
  }

  return (
    <Container>
      <SearchWrapper>
        {/* <CitizenBox>
          <Label>Create date</Label>
          <FilterDaySelector
            onChange={(v) => handleFilterDaySearch(v)}
            onClear={handleClear}
          />
        </CitizenBox> */}
        <BillSearchBox>
          <Label>Search by Bill/Property ID</Label>
          <BillSearchInput onBillSelected={onBillSelectedFromSearch} />
        </BillSearchBox>
        <div
          style={{ width: 1, height: 70, background: '#ccc', marginRight: 15 }}
        ></div>
        <CitizenBox>
          <Label>Payment status</Label>
          <BillStatusSelector
            onChange={(v) => handleBillStatusSearch(v)}
            // onClear={handleClearBillStatus}
          />
        </CitizenBox>
        <CitizenBox>
          <Label>Invoice paid date</Label>
          <RangePicker
            // picker="month"
            onChange={(v) => handlePaidDateSelect(v)}
            // placeholder="Select date"
            disabledDate={(current) => {
              let customDate = moment().format('DD-MM-YYYY')
              return (
                current &&
                current >= moment(customDate, 'DD-MM-YYYY').add(1, 'd')
              )
            }}
          />
        </CitizenBox>
        <CitizenBox>
          <Label>Bill for month</Label>
          <DatePicker
            onChange={(v) => handleBillForMonth(v)}
            placeholder="Select month"
            picker="month"
            format={'MM/YYYY'}
          />
        </CitizenBox>
        <CitizenBox>
          <Label>Property Status</Label>
          <IsActiveSelector
            notAvailable={true}
            onChange={handleIsActiveSelect}
          />
        </CitizenBox>
        <CitizenBox>
          <Label>Payment method</Label>
          <PaymentMethodSelector onChange={handlePaymentMethodSelect} />
        </CitizenBox>
        <CitizenBox>
          <Label>&nbsp;</Label>
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </CitizenBox>
      </SearchWrapper>
      {loadingBills ? (
        <Spinner></Spinner>
      ) : (
        <BillList bills={displayBill()}></BillList>
      )}

      {shouldShowAddModal && (
        <Modal
          title="បង្កើតវិក័យប័ត្រ (Add Bill)"
          visible={shouldShowAddModal}
          onCancel={() => setShouldShowAddModal(false)}
          footer={[]}
          width={600}
        >
          <BillAddContainer
            property={selectedProperty}
            onSaveFinish={() => setShouldShowAddModal(false)}
          ></BillAddContainer>
        </Modal>
      )}

      {shouldShowEditPropertyStatusModal && (
        <Modal
          title="កែសម្រួលវិក័យប័ត្រ (Edit Bill)"
          visible={shouldShowEditPropertyStatusModal}
          onCancel={() => setShouldShowEditPropertyStatusModal(false)}
          footer={[]}
          width={600}
        >
          <PropertyStatusEditContainer
            property={selectedProperty!}
            onSaveFinish={() => setShouldShowEditPropertyStatusModal(false)}
          ></PropertyStatusEditContainer>
        </Modal>
      )}
    </Container>
  )
})
