import { Redirect, Route } from 'react-router-dom'

import React from 'react'
import { observer } from 'mobx-react-lite'
import { user } from 'Models/UserModel'
import { pages } from 'Header'
import { hasAccess } from './Role'

export const PrivateRoute = observer(({ children, ...rest }) => {
  console.log(
    'private route, will not rerender if comment out this line' + user.name
  )

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const PAGE = pages.find((page) => page.key === location.pathname)

        if (user.isLoggedIn) {
          if (PAGE && !hasAccess(PAGE.id)) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: '/' },
                }}
              ></Redirect>
            )
          }
          return children
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            ></Redirect>
          )
        }
      }}
    ></Route>
  )
})
