import {
  Button,
  Form,
  Input,
  InputNumber as InputNumberBase,
  Radio,
} from 'antd'

import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'

const InputNumber = styled(InputNumberBase)`
  width: 135px;
`

interface Prop {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
  initialValues?: any
}

export const CollectionCompanyForm = (props: Prop) => {
  const { onSubmit, saving, form, initialValues } = props

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 4 }}
      initialValues={initialValues}
    >
      <Form.Item
        label="Name"
        name="Name"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
