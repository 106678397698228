import * as api from './api'

import {
  Instance,
  applySnapshot,
  getParent,
  flow,
  types,
} from 'mobx-state-tree'

export const Collector = types
  .model('Collector', {
    _id: types.maybeNull(types.string),
    collector_code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    isDeleting: false,
  })
  .actions((self) => ({
    delete: flow(function* () {
      const { _id: id } = self

      self.isDeleting = true
      yield api.deleteById(id)
      const store = getParent(self, 2) as Instance<typeof CollectorStore>
      store.removeCollector(self)
    }),
    edit: flow(function* (values) {
      const data = {
        ...self,
        ...values,
      }
      try {
        const apiData = yield api.edit(data)
        if (apiData) {
          const store = getParent(self, 2) as Instance<typeof CollectorStore>
          store.updateCollector(apiData)
        }
      } catch (error) {
        //@ts-ignore
        throw new Error(error.message)
      }
    }),
  }))

const CollectorStore = types
  .model('CollectorStore', {
    collectors: types.optional(types.array(Collector), []),
  })
  .actions((self) => ({
    loadCollectors: flow(function* () {
      const collectors = yield api.getCollectors()
      self.collectors = collectors
    }),
    collectorByCode: flow(function* (code: string) {
      console.log('code====>', code)

      return yield api.getCollectorByCode(code)
    }),
    emptyCollectors() {
      applySnapshot(self.collectors, [])
    },
    setCollectors(collectors: ICollector[]) {
      applySnapshot(self.collectors, collectors)
    },
    addCollector: flow(function* (collector: ICollector) {
      const result = yield api.add(collector)
      self.collectors.unshift(result)
      return result
    }),
    removeCollector(item: any) {
      self.collectors.splice(self.collectors.indexOf(item), 1)
    },
    updateCollector(item: any) {
      const index = self.collectors.findIndex(
        (collector) => collector._id === item._id
      )
      self.collectors[index] = item
    },
  }))

export interface ICollector extends Instance<typeof Collector> {}

export const collectorStore = CollectorStore.create({
  collectors: [],
})
