import {
  DeleteConfirmButton,
  DownloadButton,
  EditButton,
  HistoryButton,
} from 'Components'
import { Modal, Table, Tag, Tooltip } from 'antd'
import { Observer, observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { BillEditContainer } from './Edit'
import { IBill } from 'Models/BillModel'
import { INVOICE_SERVER_URL } from 'Utils/Constants'
import { TransactionHistory } from './TransactionHistory/TransactionHistory'
import { billStore } from 'Models/BillModel'
import moment from 'moment'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'
import { Admin, BillingManager } from 'Auth/Role'
import { formatDate, formatMonth } from 'Utils/Date'
import { isActiveOptions } from 'Components/IsActiveSelector/IsActiveSelector'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons'

const Container = styled.div``

const UnpaidLabel = styled.div`
  background: #ffabab;
  width: fit-content;
  padding: 0px 8px;
  border-radius: 4px;
`

const PaidLabel = styled.div`
  background: #50e3c2;
  width: fit-content;
  padding: 0px 6px;
  border-radius: 4px;
`
const Amount = styled.div`
  text-align: right;
`

interface BillListProps {
  bills: IBill[]
}

let selectedBill: IBill | null = null

export const BillList = observer((props: BillListProps) => {
  const { bills } = props
  const [editingBill, setEditingBill] = useState<IBill>()
  const [shouldShowBillEditModal, setShouldShowBillEditModal] = useState(false)
  const [shouldShowPaymentRecords, setShouldShowPaymentRecords] =
    useState(false)

  function onEditClick(bill: IBill) {
    setEditingBill(bill)
    setShouldShowBillEditModal(true)
  }

  let totalAmount = 0

  if (bills.length > 0) {
    totalAmount = bills.reduce((result, obj): any => {
      return result + (obj.Amount || 0)
    }, 0)
  }

  function onPaymentRecordButtonClick(bill: IBill) {
    selectedBill = bill
    setShouldShowPaymentRecords(true)
  }

  function onDeleteClick(bill: IBill) {
    bill.delete()
  }

  async function onDownloadClick(bill: IBill) {
    const url = `${INVOICE_SERVER_URL}/generate-invoice-by-billId/${bill.OBJECTID}`
    var link = document.createElement('a')
    link.setAttribute('download', `Invoice_${bill.OBJECTID}.pdf`)
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const renderPaymentMethodLabel = (paymentMethod: string) => {
    switch (paymentMethod) {
      case 'ABA':
        return 'ABA - SAMRAAM Mobile'
      case 'ABA-BILL-PAYMENT':
        return 'ABA - ABA App'
      case 'KHQR':
        return 'KHQR'
      default:
        return ''
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'OBJECTID',
      key: 'OBJECTID',
      render: function render(text: string, record: IBill) {
        let numberOfPrint = 0
        if (record?.PrintLogs) {
          numberOfPrint = record?.PrintLogs.split(',').length
        }

        console.log('numberOfPrint', numberOfPrint)

        return (
          <span>
            {text}{' '}
            {record?.UserViewBill === 1 ? (
              <Tag color="lime">
                <Tooltip title={`ម្ចាស់ផ្ទះបានមើលរួច`}>
                  <EyeOutlined />
                </Tooltip>
              </Tag>
            ) : (
              ''
            )}
            {numberOfPrint > 0 ? (
              <Tooltip title={`ធ្លាប់បាន download ចំនួន ${numberOfPrint} ដង`}>
                <Tag color="blue">
                  <DownloadOutlined />
                </Tag>
              </Tooltip>
            ) : (
              ''
            )}
          </span>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'CreateDate',
      key: 'CreateDate',
      render: function render(text: string, record: any) {
        return <span>{moment(record.CreateDate).format('YYYY-MM-DD')}</span>
      },
    },
    {
      title: 'For Date',
      dataIndex: 'BillForDate',
      key: 'BillForDate',
      render: function render(text: string, record: IBill) {
        if (record.BillForDate) {
          return <span>{formatMonth(record.BillForDate)}</span>
        }
        return null
      },
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: function render(text: string) {
        return <Amount>{thousandSeparator(text)}</Amount>
      },
    },
    {
      title: 'Paid',
      dataIndex: 'Paid',
      key: 'Paid',
      render: function render(text: string, record: IBill) {
        if (record.Paid) {
          return <PaidLabel>Yes</PaidLabel>
        }
        return <UnpaidLabel>No</UnpaidLabel>
      },
    },
    {
      title: 'Property Status',
      dataIndex: 'PropertyStatus',
      key: 'Property Status',
      render: function render(text: string, record: IBill) {
        if (text) {
          return isActiveOptions?.find((item) => item?.value === parseInt(text))
            ?.label
        }
        return 'N/A'
      },
    },
    {
      title: 'Paid Date',
      dataIndex: 'PaidDate',
      key: 'PaidDate',
      render: function render(text: string, record: IBill) {
        if (record.PaidDate) {
          return (
            <span>
              {formatDate(record.PaidDate)}
              {!!record?.PaymentMethod && (
                <Tag color="#005B7A" style={{ marginLeft: 5 }}>
                  {renderPaymentMethodLabel(record.PaymentMethod)}
                </Tag>
              )}
            </span>
          )
        }
        return null
      },
    },
    {
      title: 'Editor',
      dataIndex: 'Editor',
      key: 'Editor',
    },
    {
      title: 'Property id',
      dataIndex: 'PropertyID',
      key: 'PropertyID',
    },
    {
      title: 'Action',
      key: 'action',
      render: function render(text: string, record: IBill) {
        return (
          <Observer>
            {() => (
              <>
                {/* <HistoryButton
                  onClick={() => onPaymentRecordButtonClick(record)}
                ></HistoryButton> */}
                <DownloadButton
                  onClick={() => onDownloadClick(record)}
                ></DownloadButton>
                <EditButton
                  onClick={() => onEditClick(record)}
                  roles={[Admin, BillingManager]}
                ></EditButton>
                <DeleteConfirmButton
                  roles={[Admin, BillingManager]}
                  deleting={record.isDeleting}
                  onConfirmClick={() => onDeleteClick(record)}
                ></DeleteConfirmButton>
              </>
            )}
          </Observer>
        )
      },
    },
  ]

  return (
    <Container>
      <Table
        columns={columns}
        dataSource={bills.slice()}
        rowKey="OBJECTID"
        footer={() => {
          return (
            <div>
              <Tag color="#2db7f5">Total bills: {bills?.length}</Tag>{' '}
              <Tag color="#2db7f5">
                Total: {thousandSeparator(totalAmount)} ៛
              </Tag>{' '}
            </div>
          )
        }}
      />
      {shouldShowBillEditModal && (
        <Modal
          visible={shouldShowBillEditModal}
          onCancel={() => setShouldShowBillEditModal(false)}
          footer={[]}
          width={600}
          title="កែសម្រួលវិក័យប័ត្រ (Edit Bill)"
        >
          <BillEditContainer
            bill={editingBill}
            onSaveFinish={() => setShouldShowBillEditModal(false)}
          ></BillEditContainer>
        </Modal>
      )}

      {selectedBill && shouldShowPaymentRecords && (
        <Modal
          visible={shouldShowPaymentRecords}
          onCancel={() => setShouldShowPaymentRecords(false)}
          footer={[]}
          width={600}
          title="កំណត់ត្រាបង់ប្រាក់ (Payment Records)"
        >
          <TransactionHistory bill={selectedBill}></TransactionHistory>
        </Modal>
      )}
    </Container>
  )
})
