import { WIALON_SERVER_URL } from 'Utils/Constants'
import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

interface Coordinate {
  lat: number
  lng: number
  rotation: number
  isEngineOn: boolean | null
}

export const getTrucks = async () => {
  const url = `${apiBaseUrl}/api/trucks/query`
  const result = await axios.get(url)
  return result.data
}

export const getSchedulesByTruckId = async (truckId: string) => {
  const url = `${apiBaseUrl}/api/schedules/query?where=TruckID='${truckId}'`
  const result = await axios.get(url)
  return result.data
}

export const patchTruck = async (attributes: any) => {
  const data = {
    features: {
      attributes,
    },
  }
  const url = `${apiBaseUrl}/api/trucks/patch`
  const result = await axios.patch(url, data)
  return result.data
}

export const getTruckCurrentLocation = async (
  unitId: string
): Promise<Coordinate> => {
  const url = `${WIALON_SERVER_URL}/units/${unitId}/all_sensor_info`
  const result = await axios.get(url)
  const {
    longitude: lng,
    latitude: lat,
    course: rotation,
    isEngineOn,
  } = result.data
  return { lat, lng, rotation, isEngineOn: isEngineOn }
}
