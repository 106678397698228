import { IKSWM, kswmStore } from 'Models/PropertyDataGatheringModel/KswmModel'
import React, { useState } from 'react'

import { Image } from 'antd'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface Props {
  kswm: IKSWM
}
export const KswmInfo = observer((props: Props) => {
  const { kswm } = props

  return (
    <Container>
      <Image height={200} width={200} src={kswm.Photo + ''}></Image>
      <table className="styled-table">
        <tbody>
          <tr>
            <td>Customer No:</td>
            <td>{kswm.CustomerNo}</td>
          </tr>

          <tr>
            <td>Customer Name:</td>
            <td>{kswm.CustomerName}</td>
          </tr>

          <tr>
            <td>Contact Person:</td>
            <td>{kswm.ContactPerson}</td>
          </tr>

          <tr>
            <td>Sex</td>
            <td>{kswm.Sex}</td>
          </tr>

          <tr>
            <td>Phone Number:</td>
            <td>{kswm.PhoneNumber}</td>
          </tr>

          <tr>
            <td>Business Name:</td>
            <td>{kswm.BusinessName}</td>
          </tr>

          <tr>
            <td>Business Type:</td>
            <td>{kswm.BusinessType}</td>
          </tr>

          <tr>
            <td>Location Code:</td>
            <td>{kswm.LocationCode}</td>
          </tr>

          <tr>
            <td>Block</td>
            <td>{kswm.Block}</td>
          </tr>

          <tr>
            <td>Side of Street:</td>
            <td>{kswm.SideofStreet}</td>
          </tr>

          <tr>
            <td>House Order:</td>
            <td>{kswm.HouseOrder}</td>
          </tr>

          <tr>
            <td>Devide Order:</td>
            <td>{kswm.DevideOrder}</td>
          </tr>

          <tr>
            <td>Position:</td>
            <td>{kswm.Possition}</td>
          </tr>

          <tr>
            <td>Fee</td>
            <td>{kswm.Fee}</td>
          </tr>

          <tr>
            <td>VAT</td>
            <td>{kswm.VAT}</td>
          </tr>

          <tr>
            <td>Total</td>
            <td>{kswm.Total}</td>
          </tr>

          <tr>
            <td>Payment Type:</td>
            <td>{kswm.PaymentType}</td>
          </tr>

          <tr>
            <td>Traffic Code:</td>
            <td>{kswm.TrafficeCode}</td>
          </tr>

          <tr>
            <td>Collector:</td>
            <td>{kswm.Collector}</td>
          </tr>

          <tr>
            <td>Collection Date:</td>
            <td>{kswm.CollectionDate}</td>
          </tr>

          <tr>
            <td>VAT Number:</td>
            <td>{kswm.VATINNumber}</td>
          </tr>

          <tr>
            <td>Zone Name:</td>
            <td>{kswm.ZoneName}</td>
          </tr>

          <tr>
            <td>House NO</td>
            <td>{kswm.HouseNo}</td>
          </tr>

          <tr>
            <td>Street</td>
            <td>{kswm.Street}</td>
          </tr>

          <tr>
            <td>Village</td>
            <td>{kswm.Village}</td>
          </tr>

          <tr>
            <td>Commune</td>
            <td>{kswm.Commune}</td>
          </tr>

          <tr>
            <td>District</td>
            <td>{kswm.District}</td>
          </tr>

          <tr>
            <td>Agreement No:</td>
            <td>{kswm.AgreementNo}</td>
          </tr>

          <tr>
            <td>Agreement Done By:</td>
            <td>{kswm.AgreementDoneBy}</td>
          </tr>

          <tr>
            <td>Agreement Date:</td>
            <td>{kswm.AgreementDate}</td>
          </tr>

          <tr>
            <td>Entry Date:</td>
            <td>{kswm.EntryDate}</td>
          </tr>

          <tr>
            <td>Region:</td>
            <td>{kswm.Region}</td>
          </tr>
        </tbody>
      </table>
    </Container>
  )
})
