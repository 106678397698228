import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

export const getPropertyTypes = async (provinceCode = '18') => {
  const url = `${apiBaseUrl}/property-type-and-fees?provinceCode=${provinceCode}`
  const result = await axios.get(url)
  return result.data
}

export const getPropertyTypeByCode = async (code: string) => {
  const url = `${apiBaseUrl}/property-type-and-fees/${code}/code`
  const result = await axios.get(url)
  return result.data
}

export const edit = async (values: any) => {
  const url = `${apiBaseUrl}/property-type-and-fees/${values.id}`
  const data = {
    ...values,
  }
  const response = await axios.patch(url, data)
  return response.data
}

export const deleteById = async (id: string) => {
  const url = `${apiBaseUrl}/property-type-and-fees/${id}`
  const response = await axios.delete(url)
  return response.data
}

export const deleteAllPropertyTypes = async () => {
  const url = `${apiBaseUrl}/property-type-and-fees/destroy`
  const response = await axios.delete(url)
  return response.data
}

export const add = async (data: any) => {
  const url = `${apiBaseUrl}/property-type-and-fees`
  const response = await axios.post(url, data)
  return response.data
}
