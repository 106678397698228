import { WIALON_SERVER_URL } from 'Utils/Constants'
import axios from 'axios'
import { environment } from 'environment'

const { apiBaseUrl } = environment


export const getRoutes = async (query) => {
  const url = `${apiBaseUrl}/api/routes/query?geometryType=esriGeometryPoint&units=esriSRUnit_Meter&geometry=${query.geometry}&distance=${query.distant}`
  const result = await axios.get(url)
  return result.data
}

export const getWialonUnits = async () => {
  const url = `${WIALON_SERVER_URL}/units`
  const result = await axios.get(url)
  return result.data
}

export const importUnit = async (unit) => {
  const alreadyAdded = await isAlreadyImported(unit.unitId)
  if (alreadyAdded) {
    return {
      success: true,
    }
  } else {
    const dataToSave = {
      UnitID: unit.unitId,
      Name: unit.name,
    }
    const result = await addTruck(dataToSave)
    return result
  }
}

const addTruck = async (data) => {
  const toBeSaved = {
    features: {
      attributes: {
        ...data,
      },
    },
  }
  const url = `${apiBaseUrl}/api/trucks/add`
  const result = await axios.post(url, toBeSaved)
  if (result.data.error) {
    console.log('result.data.error: ', result.data.error)
    throw new Error(`Error ${result.error.details[0]}`)
  }
  const success = result.data.addResults[0].success
  const objectId = result.data.addResults[0].objectId
  return {
    success,
    objectId,
  }
}

const getTruckByUnitId = async (unitId) => {
  const url = `${apiBaseUrl}/api/trucks/query?where=UnitID='${unitId}'`
  const result = await axios.get(url)
  return result.data
}

const isAlreadyImported = async (unitId) => {
  const trucks = await getTruckByUnitId(unitId)
  return trucks.length > 0
}
