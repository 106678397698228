import React from 'react'
import { Button, Progress } from 'antd'
import styled from 'styled-components'

const Container = styled.div``

const ConfirmEditPropertyBox = styled.div`
  display: flex;
  align-item: flex-end;
  justify-content: space-between;
  flex-direction: row;
`

interface Props {
  header: string;
  statusText: string;
  addedCount: number;
  totalRecordCount: number;
  onCancelButtonClick: () => void;
  onConfirmButtonClick: () => void
}

const ConfirmationWithProgressModal = (props: Props) => {
  const { 
    header,
    statusText,
    addedCount,
    totalRecordCount,
    onCancelButtonClick,
    onConfirmButtonClick
  } = props 
  return (
    <Container>
      <div>{header}</div>
      <div>
        {statusText} {addedCount}/{totalRecordCount}
      </div>
      <Progress percent={(addedCount/totalRecordCount)*100}></Progress>

      <ConfirmEditPropertyBox>
        <Button
          danger={true}
          shape='round'
          type='primary'
          onClick={onCancelButtonClick}
        >
          No
        </Button>
        <Button
          type='primary'
          shape='round'
          onClick={onConfirmButtonClick}
        >
          Yes
        </Button>
      </ConfirmEditPropertyBox>
    </Container>
  )
}

export default ConfirmationWithProgressModal
