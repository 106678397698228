const types: { [key: string]: string } = {
  '110101': 'លំនៅឋាន( ផ្ទះល្វែងជាន់ផ្ទាល់ដី)',
  '110102': 'លំនៅឋាន( ផ្ទះល្វែងជាន់ទី១ឡើងទៅ)',
  '110103': 'លំនៅឋាន( ផ្ទះដីឡូតិ៍ធម្មតា)',
  '110104': 'លំនៅឋាន( ភូមិគ្រឹះផ្ទៃដីតូចជាង ៣០០ម២)',
  '110105': 'លំនៅឋាន( ភូមិគ្រឹះផ្ទៃដីចាប់ពី ៣០០ម២-៦០០ម២)',
  '110106': 'លំនៅឋាន( ភូមិគ្រឹះផ្ទៃដីធំជាង ៦០០ម២)',
  '110201': 'លំនៅឋានក្នុងបុរី/ក្រុងរណប( ផ្ទះល្វែង)',
  '110202': 'លំនៅឋានក្នុងបុរី/ក្រុងរណប( វីឡាភ្លោះ)',
  '110203': 'លំនៅឋានក្នុងបុរី/ក្រុងរណប( វីឡាទោលមានផ្ទៃដីតូចជាង ៣០០ម២)',
  '110204': 'លំនៅឋានក្នុងបុរី/ក្រុងរណប( វីឡាទោលមានផ្ទៃដីចាប់ពី ៣០០ម២ ឡើង)',
  '110301': 'ខុនដូ/អាផាតមិនគិតចំនួនយូនីត( តិចជាង ៣បន្ទប់/យូនីត)',
  '110302': 'ខុនដូ/អាផាតមិនគិតចំនួនយូនីត( ចាប់ពី ៣បន្ទប់/យូនីត)',
  '110401':
    'បន្ទប់ជួលឱ្យកម្មករគិតចំនួនបន្ទប់( បន្ទប់ជួលឱ្យកម្មករក្នុងស្រុកស្នាក់នៅ)',
  '110402':
    'បន្ទប់ជួលឱ្យកម្មករគិតចំនួនបន្ទប់( បន្ទប់ជួលឱ្យកម្មករបរទេសស្នាក់នៅ)',
  '110501': 'ផ្ទះជួលឱ្យបរទេសស្នាក់នៅ( ផ្ទះជួលឱ្យបរទេសស្នាក់នៅ)',
  '120101': 'ទីតាំងអាជីវកម្មទូទៅ( ទំហំផ្ទះ ១ល្វែង ស្ថិតក្នុងតំបន់១)',
  '120102': 'ទីតាំងអាជីវកម្មទូទៅ( ទំហំចាប់ពីផ្ទះ ២ល្វែងឡើង ស្ថិតក្នុងតំបន់១)',
  '120103': 'ទីតាំងអាជីវកម្មទូទៅ( ទំហំផ្ទះ ១ល្វែង ស្ថិតក្នុងតំបន់២)',
  '120104': 'ទីតាំងអាជីវកម្មទូទៅ( ទំហំចាប់ពីផ្ទះ ២ល្វែងឡើង ស្ថិតក្នុងតំបន់២)',
  '120201':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(អាហារដ្ឋាន ផ្ទះ១ល្វែង ១ជាន់ ក្នុងតំបន់ ២)',
  '120202':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(អាហារដ្ឋាន ធំជាងផ្ទះ១ល្វែង ឬលើសពី ១ជាន់ ក្នុងតំបន់ ២)',
  '120203':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច( អាហារដ្ឋាន ផ្ទះ១ល្វែង ១ជាន់ ក្នុងតំបន់ ១)',
  '120204':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(អាហារដ្ឋាន ធំជាងផ្ទះ១ល្វែង ឬលើសពី ១ជាន់ ក្នុងតំបន់ ១)',
  '120205':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(ភោជនីយដ្ឋាន ផ្ទះ១ល្វែង ១ជាន់ ក្នុងតំបន់ ២)',
  '120206':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច( អាហារដ្ឋាន ធំជាងផ្ទះ១ល្វែង ឬលើសពី ១ជាន់ ក្នុងតំបន់ ២)',
  '120207':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(ភោជនីយដ្ឋាន ផ្ទះ១ល្វែង ១ជាន់ ក្នុងតំបន់ ១)',
  '120208':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច( អាហារដ្ឋាន ធំជាងផ្ទះ១ល្វែង ឬលើសពី ១ជាន់ ក្នុងតំបន់ ១)',
  '120209': 'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច( ភោជនីយដ្ឋានប៊ូហ្វេ(គិតផ្ទៃអគារ))',
  '120210':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(អាហារដ្ឋាន និងខ្ចុះអាហារដ្ឋានតាមឆ្នេរ )',
  '120211':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(ភោជនីយដ្ឋានតាមឆ្នេរ តូចជាង៥០០ ម៉ែត្រការ៉េ (គិតផ្ទៃដី))',
  '120212':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(ភោជនីយដ្ឋានតាមឆ្នេរ ៥០០ ទៅ ១០០០ម៉ែត្រការ៉េ (គិតផ្ទៃដី))',
  '120213':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច(ភោជនីយដ្ឋានតាមឆ្នេរ ធំជាង១០០០ម៉ែត្រការ៉េ (គិតផ្ទៃដី))',
  '120214': 'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច( ហាងកាហ្វេទំនើប(គិតផ្ទៃអគារ))',
  '120215':
    'ភោជនីយដ្ឋាន​ អាហារដ្ឋានខ្នាតតូច( ហាងកាហ្វេទំនើប មានភោជនីយដ្ឋាន(គិតផ្ទៃអគារ))',
  '130101': 'បឹងហ្គាឡូ គិតចំនួនបន្ទប់( បឹងហ្គាឡូ)',
  '130201':
    'សណ្ឋាគារ គិតចំនួនបន្ទប់( មានអាហារដ្ឋាន/ភោជនីយដ្ឋាន(ចំនួន១) ស្ថិតក្នុងតំបន់១)',
  '130202':
    'សណ្ឋាគារ គិតចំនួនបន្ទប់( មានអាហារដ្ឋាន/ភោជនីយដ្ឋាន(ចំនួនចាប់ពី២) ស្ថិតក្នុងតំបន់១)',
  '130203':
    'សណ្ឋាគារ គិតចំនួនបន្ទប់( មានអាហារដ្ឋាន/ភោជនីយដ្ឋាន(ចំនួន១) ស្ថិតក្នុងតំបន់២)',
  '130204':
    'សណ្ឋាគារ គិតចំនួនបន្ទប់( មានអាហារដ្ឋាន/ភោជនីយដ្ឋាន(ចំនួនចាប់ពី២) ស្ថិតក្នុងតំបន់២)',
  '130205': 'សណ្ឋាគារ គិតចំនួនបន្ទប់(​ គ្មានអាហារដ្ឋាន ស្ថិតក្នុងតំបន់១)',
  '130206': 'សណ្ឋាគារ គិតចំនួនបន្ទប់(​ គ្មានអាហារដ្ឋាន ស្ថិតក្នុងតំបន់២)',
  '130301': 'រីសត គិតចំនួនបន្ទប់( រីសត)',
  '130401': 'ផ្ទះសំណាក់ គិតចំនួនបន្ទប់(ផ្ទះសំណាក់ ស្ថិតក្នុងតំបន់១)',
  '130402': 'ផ្ទះសំណាក់ គិតចំនួនបន្ទប់(ផ្ទះសំណាក់ ស្ថិតក្នុងតំបន់២)',
  '140101': 'កីឡាដ្ឋាន គិតផ្ទៃអគារ(​ ទំហំតូចជាង ៥០០ម២ )',
  '140202': 'កីឡាដ្ឋាន គិតផ្ទៃអគារ(​ ទំហំចាប់ពី   ៥០០ម២-១០០០ម២)',
  '140303': 'កីឡាដ្ឋាន គិតផ្ទៃអគារ ទំហំចាប់ពី ១០០០ម២',
  '140301': 'ក្លឹបហាត់ប្រាណគិតផ្ទៃអគារ(​ ទំហំតូចជាង ២០០ម២ )',
  '140302': 'ក្លឹបហាត់ប្រាណគិតផ្ទៃអគារ( ទំហំចាប់ពី ២០០ម២)',
  '140401': 'ហាងម៉ាស្សា ស្ទីម សូណា គិតផ្ទៃអគារ(​ ទំហំតូចជាង ២០០ម២ )',
  '140402': 'ហាងម៉ាស្សា ស្ទីម សូណា គិតផ្ទៃអគារ(​ ទំហំចាប់ពី ២០០ម២-៤០០ម២)',
  '140403': 'ហាងម៉ាស្សា ស្ទីម សូណា គិតផ្ទៃអគារ( ទំហំចាប់ពី ៤០០ម២)',
  '140501':
    'ក្លឹបហាត់ប្រាណ មានកន្លែងម៉ាស្សា ស្ទីម សូណា គិតផ្ទៃអគារ(​ ទំហំតូចជាង ២០០ម២ )',
  '140502':
    'ក្លឹបហាត់ប្រាណ មានកន្លែងម៉ាស្សា ស្ទីម សូណា គិតផ្ទៃអគារ(​ ទំហំចាប់ពី ២០០ម២-៤០០ម២)',
  '140503':
    'ក្លឹបហាត់ប្រាណ មានកន្លែងម៉ាស្សា ស្ទីម សូណា គិតផ្ទៃអគារ( ទំហំចាប់ពី ៤០០ម២)',
  '150101': 'រង្គសាល-ក្លឹប-PUB គិតផ្ទៃអគារ(​ ទំហំតូចជាង ២០០ម២ )',
  '150102': 'រង្គសាល-ក្លឹប-PUB គិតផ្ទៃអគារ(​ ទំហំចាប់ពី ២០០ម២-៤០០ម២)',
  '150103': 'រង្គសាល-ក្លឹប-PUB គិតផ្ទៃអគារ( ទំហំចាប់ពី ៤០០ម២)',
  '150201': 'KTV ខារ៉ាអូខេ គិតចំនួនបន្ទប់( KTV ខារ៉ាអូខេ កម្រិតធម្មតា)',
  '150202': 'KTV ខារ៉ាអូខេ គិតចំនួនបន្ទប់( KTV ខារ៉ាអូខេ កម្រិតVIP)',
  '150301':
    'បៀរហ្គាឌិន/សាច់អាំង/ហាងស្រាក្រឡុក( ចំណុះតិចជាង ១៥តុ ស្ថិតក្នុងតំបន់១)',
  '150302':
    'បៀរហ្គាឌិន/សាច់អាំង/ហាងស្រាក្រឡុក( ចំណុះចាប់ពី ១៥-៣០តុ ស្ថិតក្នុងតំបន់១)',
  '150303':
    'បៀរហ្គាឌិន/សាច់អាំង/ហាងស្រាក្រឡុក( ចំណុះច្រើនជាង ៣០តុ ស្ថិតក្នុងតំបន់១)',
  '150304':
    'បៀរហ្គាឌិន/សាច់អាំង/ហាងស្រាក្រឡុក( ចំណុះតិចជាង ១៥តុ ស្ថិតក្នុងតំបន់២)',
  '150305':
    'បៀរហ្គាឌិន/សាច់អាំង/ហាងស្រាក្រឡុក( ចំណុះចាប់ពី ១៥-៣០តុ ស្ថិតក្នុងតំបន់២)',
  '150306':
    'បៀរហ្គាឌិន/សាច់អាំង/ហាងស្រាក្រឡុក( ចំណុះច្រើនជាង ៣០តុ ស្ថិតក្នុងតំបន់២)',
  '150401': 'មហោស្រព/រោងភាពយន្ត( ចំណុះតិចជាង ២០០នាក់)',
  '150402': 'មហោស្រព/រោងភាពយន្ត( ចំណុះចាប់ពី ២០០-៤០០នាក់)',
  '150403': 'មហោស្រព/រោងភាពយន្ត( ចំណុះលើសពី ៤០០នាក់ឡើង (គិតចំនួនកៅអី))',
  '150501':
    'កាស៊ីណូមិនគិតបញ្ជូលភោជនីយដ្ឋាន និងក្លឹបកម្សាន្តផ្សេងៗ( ចំណុះតិចជាង ១៥០នាក់)',
  '150502':
    'កាស៊ីណូមិនគិតបញ្ជូលភោជនីយដ្ឋាន និងក្លឹបកម្សាន្តផ្សេងៗ( ចំណុះចាប់ពី១៥០- ៣០០នាក់)',
  '150503':
    'កាស៊ីណូមិនគិតបញ្ជូលភោជនីយដ្ឋាន និងក្លឹបកម្សាន្តផ្សេងៗ( ចំណុះចាប់ពី ៣០០-៤០០នាក់)',
  '150504':
    'កាស៊ីណូមិនគិតបញ្ជូលភោជនីយដ្ឋាន និងក្លឹបកម្សាន្តផ្សេងៗ( ចំណុះលើសពី ៤០០នាក់ )',
  '160101': 'ផ្សារទំនើប( អគារលក់ទំនិញ/ផ្សារទំនើប ស្ថិតនៅតំបន់១)',
  '160102': 'ផ្សារទំនើប( អគារលក់ទំនិញ/ផ្សារទំនើប ស្ថិតនៅតំបន់២)',
  '160201': 'ផ្សារលក់ដូរធម្មតា គិតផ្ទៃទីតាំងលក់ដូរ( ផ្សារ)',
  '160301': 'ហាងទំនិញ គិតផ្ទៃអគារ( ហាងទំនិញធម្មតា ស្ថិតនៅតំបន់១)',
  '160302': 'ហាងទំនិញ គិតផ្ទៃអគារ( ហាងទំនិញធម្មតា ស្ថិតនៅតំបន់២)',
  '160303': 'ហាងទំនិញ គិតផ្ទៃអគារ( មីនីម៉ាត ស្ថិតនៅតំបន់១)',
  '160304': 'ហាងទំនិញ គិតផ្ទៃអគារ( មីនីម៉ាត ស្ថិតនៅតំបន់២)',
  '160401': 'អគារពាណិជ្ជកម្ម គិតផ្ទៃអគារ( អគារពាណិជ្ជកម្ម ស្ថិតនៅតំបន់១)',
  '160402': 'អគារពាណិជ្ជកម្ម គិតផ្ទៃអគារ( អគារពាណិជ្ជកម្ម ស្ថិតនៅតំបន់២)',
  '170101': 'មន្ទីរពេទ្យ ឯកជន-ស្វយ័ត( មន្ទីរពេទ្យ)',
  '170201': 'មន្ទីរពេទ្យ គិតចំនួនគ្រែ( មន្ទីរសម្ភព)',
  '170301': 'មន្ទីរសម្រាកព្យាបាលជំងឺ គិតចំនួនគ្រែ( មន្ទីរសម្រាកព្យាបាលជំងឺ)',
  '170401': 'បន្ទប់ពិគ្រោះជំងឺ( បន្ទប់ពិគ្រោះជំងឺ)',
  '180101': 'សាកលវិទ្យាល័យ/មហាវិទ្យាល័យ/វិទ្យាស្ថាន( តិចជាង ១០បន្ទប់)',
  '180102': 'សាកលវិទ្យាល័យ/មហាវិទ្យាល័យ/វិទ្យាស្ថាន( ចាប់ពី ១០-៣០បន្ទប់)',
  '180103': 'សាកលវិទ្យាល័យ/មហាវិទ្យាល័យ/វិទ្យាស្ថាន( ច្រើនជាង ៣០បន្ទប់)',
  '180201': 'សាលារៀនចំណេះទូទៅឯកជន( តិចជាង ១០បន្ទប់)',
  '180202': 'សាលារៀនចំណេះទូទៅឯកជន( ចាប់ពី ១០-៣០បន្ទប់)',
  '180203': 'សាលារៀនចំណេះទូទៅឯកជន( ច្រើនជាង ៣០បន្ទប់)',
  '180301': 'សាលាអង្គការមិនយកថ្លៃសិក្សា( តិចជាង ១០បន្ទប់)',
  '180302': 'សាលាអង្គការមិនយកថ្លៃសិក្សា( ចាប់ពី ១០បន្ទប់)',
  '180401': 'សាលារៀនកម្រិតអន្តរជាតិ( តិចជាង ១០បន្ទប់)',
  '180402': 'សាលារៀនកម្រិតអន្តរជាតិ( ចាប់ពី ១០-២០បន្ទប់)',
  '180403': 'សាលារៀនកម្រិតអន្តរជាតិ( ច្រើនជាង ២០បន្ទប់)',
  '190101': 'សាលាចំណេះទូទៅ( សាលាចំណេះទូទៅ)',
  '200101':
    'ក្រុមហ៊ុនឯកជនទូទៅ/សាខាក្រុមហ៊ុន/ការិយាល័យតំណាងក្រុមហ៊ុន( បុគ្គលិកតិចជាង ១៥នាក់)',
  '200102':
    'ក្រុមហ៊ុនឯកជនទូទៅ/សាខាក្រុមហ៊ុន/ការិយាល័យតំណាងក្រុមហ៊ុន( បុគ្គលិកចាប់ពី ១៥-៥០នាក់)',
  '200103':
    'ក្រុមហ៊ុនឯកជនទូទៅ/សាខាក្រុមហ៊ុន/ការិយាល័យតំណាងក្រុមហ៊ុន( បុគ្គលិកចាប់ពី ៥០នាក់)',
  '200201':
    'ធនាគារ/គ្រឹះស្ថានមីក្រូហិរញ្ញវត្ថុ/ស្ថាប័នផ្តល់ប្រាក់កម្ចី( បុគ្គលិកតិចជាង ២៥នាក់)',
  '200202':
    'ធនាគារ/គ្រឹះស្ថានមីក្រូហិរញ្ញវត្ថុ/ស្ថាប័នផ្តល់ប្រាក់កម្ចី( បុគ្គលិកចាប់ពី ២៥-៥០នាក់)',
  '200203':
    'ធនាគារ/គ្រឹះស្ថានមីក្រូហិរញ្ញវត្ថុ/ស្ថាប័នផ្តល់ប្រាក់កម្ចី( បុគ្គលិកចាប់ពី ៥០នាក់)',
  '210101': 'ស្ថានីយ-ដេប៉ូប្រេងឥន្ធនៈ(មានតែកន្លែងចាក់ប្រេង និង ប្តូរប្រេង  )',
  '210102':
    'ស្ថានីយ-ដេប៉ូប្រេងឥន្ធនៈ(មានកន្លែងចាក់ប្រេង ប្តូរប្រេង  និង មីនីម៉ាត ឬ ហាងកាហ្វេ)',
  '210103':
    'ស្ថានីយ-ដេប៉ូប្រេងឥន្ធនៈ(មានកន្លែងចាក់ប្រេង ប្តូរប្រេង  មីនីម៉ាត និង ហាងកាហ្វេ)',
  '210104':
    'ស្ថានីយ-ដេប៉ូប្រេងឥន្ធនៈ(មានកន្លែងចាក់ប្រេង ប្តូរប្រេង  មីនីម៉ាត ហាងកាហ្វេ និង ភោជនីយដ្ឋាន)',
  '220101':
    'យានដ្ឋានជួសជុលរថយន្ត ទីតាំងលាងរថយន្ត(យានដ្ឋានជួសជុលរថយន្ត តូចជាង២០០ម២)',
  '220102':
    'យានដ្ឋានជួសជុលរថយន្ត ទីតាំងលាងរថយន្ត(យានដ្ឋានជួសជុលរថយន្ត ធំជាង២០០ម២)',
  '220103':
    'យានដ្ឋានជួសជុលរថយន្ត ទីតាំងលាងរថយន្ត(ទីតាំងកន្លែងប្តូរប្រេងរំអិល តូចជាង២០០ម២)',
  '220104':
    'យានដ្ឋានជួសជុលរថយន្ត ទីតាំងលាងរថយន្ត(ទីតាំងកន្លែងប្តូរប្រេងរំអិលធំជាង២០០ម២)',
  '220105': 'យានដ្ឋានជួសជុលរថយន្ត ទីតាំងលាងរថយន្ត(ទីតាំងលាងរថយន្ត តូចជាង២០០ម២)',
  '220106': 'យានដ្ឋានជួសជុលរថយន្ត ទីតាំងលាងរថយន្ត(ទីតាំងលាងរថយន្ត ធំជាង២០០ម២)',
  '220107':
    'យានដ្ឋានជួសជុលរថយន្ត ទីតាំងលាងរថយន្ត(ទីតាំងថែទាំរថយន្ត(ប៉ូលា បិតហ្វីល…) តូចជាង២០០ម២)',
  '220108':
    'យានដ្ឋានជួសជុលរថយន្ត ទីតាំងលាងរថយន្ត(ទីតាំងថែទាំរថយន្ត(ប៉ូលា បិតហ្វីល…) ធំជាង២០០ម២)',
  '230101': 'កំពង់ផែ គិតផ្ទៃដី( កំពង់ផែ)',
  '230201': 'ស្ថានីយរថភ្លើង គិតផ្ទៃអគារ( ស្ថានីយរថភ្លើង)',
  '230301': 'ចំណតរថយន្តអ្នកដំណើរ គិតផ្ទៃអគារ( ចំណតរថយន្តអ្នកដំណើរ)',
  '230401': 'ព្រលានយន្តហោះ គិតផ្ទៃអគារ( ព្រលានយន្តហោះ)',
  '230501': 'ផែស្ងួត គិតផ្ទៃដី( ទំហំតូចជាង ១០,០០០ម២)',
  '230502': 'ផែស្ងួត គិតផ្ទៃដី( ទំហំចាប់ពី ១០,០០០-២០,០០០ម២)',
  '230503': 'ផែស្ងួត គិតផ្ទៃដី( ទំហំធំជាង ២០,០០០ម២)',
  '230601': 'ឃ្លាំងស្តុកទំនិញ គិតផ្ទៃឃ្លាំង( ទំហំតូចជាង ៣០០ម២)',
  '230602': 'ឃ្លាំងស្តុកទំនិញ គិតផ្ទៃឃ្លាំង( ទំហំចាប់ពី ៣០០-៦០០ម២)',
  '230603': 'ឃ្លាំងស្តុកទំនិញ គិតផ្ទៃឃ្លាំង( ទំហំធំជាង ៦០០ម២)',
  '230701':
    'ដេប៉ូលក់គ្រឿងសំណង់ ខ្សាច់ គ្រួស ថ្ម គិតផ្ទៃក្រឡា( ទំហំតូចជាង ៥០០ម២)',
  '230702':
    'ដេប៉ូលក់គ្រឿងសំណង់ ខ្សាច់ គ្រួស ថ្ម គិតផ្ទៃក្រឡា( ទំហំចាប់ពី ៥០០-២០០០ម២)',
  '230703':
    'ដេប៉ូលក់គ្រឿងសំណង់ ខ្សាច់ គ្រួស ថ្ម គិតផ្ទៃក្រឡា( ទំហំធំចាប់ពី ២០០០-៥០០០ម២)',
  '230704':
    'ដេប៉ូលក់គ្រឿងសំណង់ ខ្សាច់ គ្រួស ថ្ម គិតផ្ទៃក្រឡា( ទំហំចាប់ពី ៥០០០-១០០០០ម២)',
  '230705':
    'ដេប៉ូលក់គ្រឿងសំណង់ ខ្សាច់ គ្រួស ថ្ម គិតផ្ទៃក្រឡា( ទំហំធំជាង ១០០០០ម២)',
  '230801': 'ទីតាំងលាយបេតុង( ម៉ាស៊ីនលាយបេតុង១)',
  '230802': 'ទីតាំងលាយបេតុង( ម៉ាស៊ីនលាយបេតុង២)',
  '230803': 'ទីតាំងលាយបេតុង( ម៉ាស៊ីនលាយបេតុង៣)',
  '230804': 'ទីតាំងលាយបេតុង( ម៉ាស៊ីនលាយបេតុងលើសពី៣)',
  '230901': 'ឃ្លាំងស្តុកប្រេងឥន្ធនៈ( ពោងស្តុកតិចជាង ៣)',
  '230902': 'ឃ្លាំងស្តុកប្រេងឥន្ធនៈ( ពោងស្តុកចាប់ពី ៣ទៅ៤)',
  '230903': 'ឃ្លាំងស្តុកប្រេងឥន្ធនៈ( ពោងស្តុកលើសពី ៤)',
  '231001': 'ចំណតរថយន្តដឹកទំនិញ/ផ្ទេរទំនិញ គិតផ្ទៃដី( ទំហំតូចជាង ៥០០ម២)',
  '231002': 'ចំណតរថយន្តដឹកទំនិញ/ផ្ទេរទំនិញ គិតផ្ទៃដី( ទំហំចាប់ពី ៥០០-១,៥០០ម២)',
  '231003': 'ចំណតរថយន្តដឹកទំនិញ/ផ្ទេរទំនិញ គិតផ្ទៃដី( ទំហំធំជាង ១,៥០០ម២)',
  '231101': 'អគារ/ឃ្លាំងលក់រថយន្ត-ម៉ូតូ គិតផ្ទៃអគារ( ទំហំតូចជាង ២០០ម២)',
  '231102': 'អគារ/ឃ្លាំងលក់រថយន្ត-ម៉ូតូ គិតផ្ទៃអគារ( ទំហំចាប់ពី ២០០-៤០០ម២)',
  '231103': 'អគារ/ឃ្លាំងលក់រថយន្ត-ម៉ូតូ គិតផ្ទៃអគារ( ទំហំធំជាង ៤០០ម២)',
  '240101': 'រោងចក្រ/ឧស្សាហកម្ម( កម្មករ/បុគ្គលិកក្រោម ២៥០នាក់)',
  '240102': 'រោងចក្រ/ឧស្សាហកម្ម( កម្មករ/បុគ្គលិកចាប់ពី ២៥០-៥០០នាក់)',
  '240103': 'រោងចក្រ/ឧស្សាហកម្ម( កម្មករ/បុគ្គលិកចាប់ពី ៥០០នាក់)',
  '240201': 'សិប្បកម្ម ( កម្មករ/បុគ្គលិកក្រោម ២០នាក់)',
  '240202': 'សិប្បកម្ម ( កម្មករ/បុគ្គលិកចាប់ពី ២០នាក់ទៅ៥០នាក់)',
  '240203': 'សិប្បកម្ម ( កម្មករ/បុគ្គលិកចាប់ពី ៥០នាក់)',
  '250101': 'ស្ថានទូត-កុងស៊ុល គិតផ្ទៃដី( តូចជាង ១,២០០ម២)',
  '250102': 'ស្ថានទូត-កុងស៊ុល គិតផ្ទៃដី( ចាប់ពី ១,២០០ម២)',
  '250201': 'អង្គការមិនមែនរដ្ឋាភិបាល( អង្គការមិនមែនរដ្ឋាភិបាល)',
  '260101': 'មន្ទីរ អង្គភាពរដ្ឋ( មន្ទីរ អង្គភាពរដ្ឋ)',
  '270101':
    'ការធ្វើកម្មវិធីនៅទីសាធារណៈ( កម្មវិធីអ្នកចូលរួមតិចជាង ១,០០០នាក់/ថ្ងៃ)',
  '270102':
    'ការធ្វើកម្មវិធីនៅទីសាធារណៈ( កម្មវិធីអ្នកចូលរួមចាប់ពី ១,០០០-១,៥០០នាក់/ថ្ងៃ)',
  '270103':
    'ការធ្វើកម្មវិធីនៅទីសាធារណៈ( កម្មវិធីអ្នកចូលរួមលើសពី ១,៥០០នាក់/ថ្ងៃ)',
  '270104':
    'ការធ្វើកម្មវិធីនៅទីសាធារណៈ( ការប្រគុំតន្ត្រីក្នុង ១ថ្ងៃ(មិនរាប់បញ្ចូលការបោសសំអាត))',
  '270105':
    'ការធ្វើកម្មវិធីនៅទីសាធារណៈ( ការរៀបចំពិធីមង្គលការ (បង់សេវាពេលសុំច្បាប់រៀបអាពាហ៍ពិពាហ៍)ក្រោម៥០តុ)',
  '270201':
    'ការលក់ដូរចល័តតាមដងផ្លូវក្នុង ១ថ្ងៃ( ការលក់ដូរផ្សេងៗតាមដងផ្លូវ(អាចម៉ៅការឱ្យប្រមូល))',
  '270301':
    'ការដ្ឋានសាងសង់ផ្សេងៗ គិតបរិមាណ( បញ្ចេញសំរាម សំណល់រឹង ចេញពីការដ្ឋានដោយប្រើប្រាស់ ក្រុមហ៊ុនផ្តល់សេវាប្រមូល និងដឹកជញ្ជូន រាប់បញ្ចូលទាំងការចូកដាក់រថយន្ត)',
}

export default types
