import React from 'react'
import { Space, Table } from 'antd'
import { DeleteConfirmButton, EditButton } from 'Components'
import { Observer, observer } from 'mobx-react-lite'

import { IPropertyType } from 'Models/PropertyTypeModel/PropertyTypeModel'
import styled from 'styled-components'

const Container = styled.div``

interface Props {
  propertyTypes: IPropertyType[]
  onEditClick: (propertyType: IPropertyType) => void
}
export const PropertyTypeList = observer((props: Props) => {
  const { propertyTypes, onEditClick } = props

  async function onDeleteClick(type: IPropertyType) {
    type.delete()
  }

  function numeralSorter(a: IPropertyType, b: IPropertyType) {
    if (a.name_khmer && b.name_khmer) {
      return a.name_khmer.localeCompare(b.name_khmer)
    }
    return 0
  }

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a: IPropertyType, b: IPropertyType) =>
        parseInt(a.id) - parseInt(b.id),
    },
    {
      title: 'Name',
      dataIndex: 'name_khmer',
      key: 'name_khmer',
      sorter: (a: IPropertyType, b: IPropertyType) => {
        if (a.name_khmer && b.name_khmer) {
          return a.name_khmer.localeCompare(b.name_khmer)
        }
        return 0
      },
    },
    {
      title: 'Name English',
      dataIndex: 'name_english',
      key: 'name_english',
      sorter: (a: IPropertyType, b: IPropertyType) => {
        if (a.name_english && b.name_english) {
          return a.name_english.localeCompare(b.name_english)
        }
        return 0
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: function render(text: string, record: IPropertyType) {
        return (
          <Observer>
            {() => (
              <Space>
                <EditButton onClick={() => onEditClick(record)}></EditButton>
                <DeleteConfirmButton
                  deleting={record.isDeleting}
                  onConfirmClick={() => onDeleteClick(record)}
                ></DeleteConfirmButton>
              </Space>
            )}
          </Observer>
        )
      },
    },
  ]
  return (
    <Container>
      <Table columns={columns} dataSource={propertyTypes.slice()} />
    </Container>
  )
})
