import { Form, message } from 'antd'
import React, { useState } from 'react'

import { propertyStore, citizenStore } from 'Models'
import { PropertyForm } from '../../PropertyActivation/Form/PropertyForm'
import moment from 'moment'
import styled from 'styled-components'
import { user } from 'Models'
import { Coordinate } from 'Models/Types'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, DataEntry, IT, KSWM, WasteCompany } from 'Auth/Role'
import { getFileListAsString } from 'Utils/files'

const Container = styled.section``

interface Props {
  coordinate?: Coordinate
  onSaveFinish?: () => void
}

export const PropertyAdd = (props: Props) => {
  const { coordinate } = props
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>('')

  async function onSubmit(values: any) {
    let lastBilledDate = undefined
    const attachmentPhotos = getFileListAsString(values.AttachmentPhotos)
    const contractPhotos = getFileListAsString(values.ContractPhotos)
    let isActive = parseInt(values.IsActive)
    if (isActive) {
      lastBilledDate = moment().startOf('month').valueOf()
    } else {
      lastBilledDate = values.LastBilledDate
        ? values.LastBilledDate.valueOf()
        : undefined
    }

    const data = {
      ...values,
      geometry: coordinate,
      ActivateDate: values.ActivateDate
        ? values.ActivateDate.valueOf()
        : undefined,
      LastBilledDate: values.LastBilledDate
        ? values.LastBilledDate.valueOf()
        : moment().startOf('month').valueOf(),
      Editor: user.name,
      EditDate: moment().valueOf(),
      // Photo: uploadedPhotoUrl,

      Photo: getFileListAsString(uploadedPhotoUrl),
      IsActive: isActive,
      AttachmentPhotos: attachmentPhotos,
      ContractPhotos: contractPhotos,

      AddedBy: user.name,
      // ...values.IsActive && { ActivatedBy: user.name }
    }
    setSaving(true)

    try {
      await propertyStore.addProperty(data)
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  function onPhotoUploaded(url?: string) {
    setUploadedPhotoUrl(url ?? '')
  }

  return componentLevelAuthorization(
    <Container>
      <PropertyForm
        form={form}
        onSubmit={onSubmit}
        saving={saving}
        uploadedPhotoUrl={uploadedPhotoUrl}
        onPhotoUploaded={onPhotoUploaded}
      ></PropertyForm>
    </Container>,
    [Admin, WasteCompany, KSWM, IT, DataEntry]
  )
}
