import './Firefly.css'

import React from 'react'
import logo from 'Images/logo.png'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
`

const Section = styled.div`
  padding: 100px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px;
  border-radius: 60px 10px 60px 10px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  z-index: 1000;
`

const Title = styled.h1`
  color: #058035;
`

const Logo = styled.img`
  width: 300px;
  margin-bottom: 30px;
`

interface Props {}
export const WelcomePage = (props: Props) => {
  return (
    <Container>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>

      <Section>
        <Logo src={logo}></Logo>
        <Title className="khmer-title">
          សូមស្វាគមន៍ប្រព័ន្ធគ្រប់គ្រងសំណល់រឹងថ្នាក់ជាតិ
        </Title>
        <Title>Welcome to National Solid Waste Management Platform</Title>
      </Section>
    </Container>
  )
}
