import * as Colors from 'Utils/Color'

import Dropzone, { DropEvent, FileRejection } from 'react-dropzone'
import React from 'react'

import { InboxOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const Instruction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed lightgray;
  padding: 20px 40px;
  margin: 20px;
  border-radius: 15px;
`

const Container = styled.div``

interface Props {
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void
  message?: string
  accept?: string
}
export const FileDrop = (props: Props) => {
  const message = props.message || 'Click or drag file to this area to import'
  return (
    <Container>
      <Dropzone accept={props.accept} onDrop={props.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <Instruction {...getRootProps()}>
            <input {...getInputProps()} />
            <InboxOutlined
              style={{
                fontSize: '40px',
                color: Colors.PrimaryColor,
                marginBottom: '10px',
              }}
            />
            <p>{message}</p>
          </Instruction>
        )}
      </Dropzone>
    </Container>
  )
}
