import { Button, Spin, message } from 'antd'
import { IRoute, scheduleStore } from 'Models'
import React, { useCallback, useEffect, useState } from 'react'

import { ITruck } from 'Models/TruckModel'

import { ScheduleForm } from './ScheduleForm'
import { ScheduleItem } from '../ScheduleItem/ScheduleItem'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, SmartSchedule, WasteCompany } from 'Auth/Role'
import { toJS } from 'mobx'
import ScheduleRouteStatus from '../ScheduleRouteStatus/ScheduleRouteStatus'

const Container = styled.section``

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface Props {
  selectedRoute: IRoute
  onTruckCurrentLocationLoaded?: (truck: ITruck) => void
}

export const ControlPanel = observer((props: Props) => {
  const { selectedRoute } = props
  const [loadingSchedules, setLoadingSchedules] = useState(false)
  const [isAddingMode, setIsAddingMode] = useState(false)
  const { schedules } = scheduleStore
  const [isSaving, setIsSaving] = useState(false)
  const [isUpdateRouteStatus, setIsUpdateRouteStatus] = useState(false)

  useEffect(() => {
    async function loadSchedules(routeId: string) {
      setLoadingSchedules(true)
      try {
        await scheduleStore.loadSchedulesByRoute(routeId)
      } catch (e: any) {
        console.log(e)
        message.error(e.message)
      }

      setLoadingSchedules(false)
    }

    if (selectedRoute && selectedRoute.OBJECTID) {
      loadSchedules(selectedRoute.OBJECTID)
    } else {
      scheduleStore.setSchedules([])
    }
  }, [selectedRoute])

  function onAddButtonClick() {
    setIsAddingMode(true)
  }

  const onUpdateRouteStatusClick = useCallback(() => {
    setIsUpdateRouteStatus(!isUpdateRouteStatus)
  }, [isUpdateRouteStatus])

  async function addNewSchedule(values: any) {
    setIsSaving(true)
    await scheduleStore.addSchedule({
      ...values,
      RouteID: selectedRoute.OBJECTID,
    })
    setIsSaving(false)
    onScheduleAdded()
  }

  async function onScheduleAdded() {
    setIsAddingMode(false)

    if (!selectedRoute.HasSchedule) {
      const dataToPatch = {
        ...selectedRoute,
        OBJECTID: selectedRoute.OBJECTID,
        HasSchedule: true,
      }
      await selectedRoute.edit(dataToPatch)
    }
  }

  async function onScheduleDeleted() {
    if (schedules.length === 0) {
      const dataToPatch = {
        ...selectedRoute,
        OBJECTID: selectedRoute.OBJECTID,
        HasSchedule: null,
      }
      await selectedRoute.edit(dataToPatch)
    }
  }

  if (loadingSchedules) {
    return (
      <Container>
        <h3>Schedules</h3>
        <Spin></Spin>
      </Container>
    )
  }

  if (isUpdateRouteStatus) {
    return (
      <Container>
        <Header>
          <div>Route update status </div>

          <Button onClick={onUpdateRouteStatusClick}>Cancel</Button>
        </Header>
        <ScheduleRouteStatus onSuccess={onUpdateRouteStatusClick} />
      </Container>
    )
  }

  if (isAddingMode) {
    return (
      <Container>
        <Header>
          <div>Add New Schedule</div>
          <Button onClick={() => setIsAddingMode(false)}>Cancel</Button>
        </Header>
        <ScheduleForm
          isNew={true}
          saving={isSaving}
          onDeleteClick={console.log}
          initialValues={{}}
          onFinish={addNewSchedule}
        ></ScheduleForm>
      </Container>
    )
  }
  console.log(toJS(schedules))

  return (
    <Container>
      <Header>
        <div>Schedules ({schedules.length})</div>

        <div>
          {!!schedules?.length && (
            <>
              {componentLevelAuthorization(
                <Button
                  style={{ marginRight: 10 }}
                  onClick={onUpdateRouteStatusClick}
                >
                  Update route status
                </Button>,
                [Admin]
              )}
            </>
          )}
          {componentLevelAuthorization(
            <Button onClick={onAddButtonClick}>Add</Button>,
            [Admin, SmartSchedule, WasteCompany]
          )}
        </div>
      </Header>

      {schedules.map((schedule) => {
        return (
          <ScheduleItem
            onTruckCurrentLocationLoaded={props.onTruckCurrentLocationLoaded}
            key={schedule.OBJECTID}
            schedule={schedule}
            onScheduleDeleted={onScheduleDeleted}
          ></ScheduleItem>
        )
      })}
    </Container>
  )
})
