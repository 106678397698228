import axios, { CancelTokenSource } from 'axios'

import { environment } from 'environment'

const { apiBaseUrl } = environment

export type Collector = {
  _id: string
  name: string
  collector_code: any
}
let cancelToken: { cancel: (arg0: string) => void }
export const getCollectors = async (keyword: string): Promise<Collector[]> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }
  cancelToken = axios.CancelToken.source()

  const url = `${apiBaseUrl}/users/collectors/?keyword=` + keyword
  //@ts-ignore
  const result = await axios.get(url, { cancelToken: cancelToken.token })

  if (!result) {
    return []
  }
  if (result.data.error) {
    throw new Error('error' + result.data.error)
  }
  return result.data
}
