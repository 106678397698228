import React, { useEffect, useState } from 'react'
import { Select, message } from 'antd'

import { Roles } from 'Auth/Role'
import { SelectValue } from 'antd/lib/select'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 250px;
`

interface Props {}

export const UserRoleSelector = (props: Props) => {
  return (
    <StyledSelect
      {...props}
      placeholder="Select a role"
      optionFilterProp="children"
      filterOption={(input, option) => {
        if (option && option.children) {
          const match =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          return match
        } else {
          return false
        }
      }}
    >
      <Option key={'NONE'} value={''}>
        None
      </Option>
      {Roles.map((role) => {
        return (
          <Option key={role} value={role}>
            {`${role}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
