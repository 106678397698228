import { Button, Popconfirm } from 'antd'
import { DeleteOutlined, WarningFilled } from '@ant-design/icons'
import React from 'react'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, KSWM, WasteCompany } from 'Auth/Role'

interface DeleteConfirmButtonProps {
  deleting?: boolean
  onConfirmClick: () => void
  danger?: boolean
  showLabel?: boolean
  roles?: any
}

export const DeleteConfirmButton = (props: DeleteConfirmButtonProps) => {
  const { deleting, onConfirmClick, showLabel, roles = [Admin, WasteCompany, KSWM] } = props

  return componentLevelAuthorization(
    (<Popconfirm
      title="Are you sure to delete?"
      onConfirm={onConfirmClick}
      okText="Delete"
      cancelText="Cancel"
      icon={<WarningFilled style={{ color: 'red' }} />}
    >
      <Button
        {...props}
        loading={deleting}
        shape={showLabel ? 'round' : 'circle'}
        icon={<DeleteOutlined />}
      >
        {showLabel && 'Delete'}
      </Button>
    </Popconfirm>),
    roles
  )
}
