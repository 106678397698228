export const validateIsRequired = { required: true, message: 'Required' }
export const validateIsNumber = {
  pattern: /^(\d*[.])?\d+$/,
  message: "Value must be a complete positive number!",
}
export const validateNumberInput = [
  validateIsRequired,
  validateIsNumber,
]

export function parseNumber(value: string | undefined) {
  if (value) {
    return value.replace(/៛\s?|(,*)/g, '')
  }
  return ''
}

export function formatNumber(value: string | number | undefined) {
  return `៛ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
