import { IProperty } from 'Models'
import assignedMarkerIcon from 'Assets/AssignedAndSelectedMarker.png'
import assignedMarkerIconInactive from 'Assets/AssignedAndSelectedMarkerInActive.png'
import selectedMarkerIcon from 'Assets/SelectedMarker.png'
import selectedMarkerIconInactive from 'Assets/SelectedMarkerInactive.png'
import activeYellowMarker from 'Assets/YellowMarkerActive.png'
import inactiveYellowMarker from 'Assets/YellowMarkerInactive.png'
import activePurpleMarker from 'Assets/PurpleColorActive.png'
import inactivePurpleMarker from 'Assets/PurpleColorInactive.png'
import activeCintriMarker from 'Assets/CyanMarkerActive.png'
import inactiveCintriMarker from 'Assets/CyanMarkerInactive.png'
import activeCloseMarker from 'Assets/ActiveClose.png'
import inactiveCloseMarker from 'Assets/InactiveClose.png'
import activeUnpaidMarker from 'Assets/ActiveUnpaid.png'
import inactiveUnpaidMarker from 'Assets/InactiveUnpaid.png'
import activeNotCooperateMarker from 'Assets/ActiveNotCooperate.png'
import inactiveNotCooperateMarker from 'Assets/InactiveNotCooperate.png'
import activeExceptionMarker from 'Assets/ActiveExceptionMarker.png'
import inactiveExceptionMarker from 'Assets/InactiveExceptionMarker.png'

export function getPropertyIcon(property: IProperty, selected?: boolean) {
  if (property.IsActive === 3 && selected) {
    return activePurpleMarker
  } else if (property.IsActive === 3 && !selected) {
    return inactivePurpleMarker
  } else if (property.IsActive === 4 && selected) {
    return activeCintriMarker
  } else if (property.IsActive === 4 && !selected) {
    return inactiveCintriMarker
  } else if (property.IsActive === 5 && selected) {
    return activeUnpaidMarker
  } else if (property.IsActive === 5 && !selected) {
    return inactiveUnpaidMarker
  } else if (property.IsActive === 6 && selected) {
    return activeYellowMarker
  } else if (property.IsActive === 6 && !selected) {
    return inactiveYellowMarker
  } else if (property.IsActive === 7 && selected) {
    return activeNotCooperateMarker
  } else if (property.IsActive === 7 && !selected) {
    return inactiveNotCooperateMarker
  } else if (property.IsActive === 8 && selected) {
    return activeCloseMarker
  } else if (property.IsActive === 8 && !selected) {
    return inactiveCloseMarker
  } else if (property.IsActive === 10 && selected) {
    return activeExceptionMarker
  } else if (property.IsActive === 10 && !selected) {
    return inactiveExceptionMarker
  }
  // else if (property.IsActive === 2 && selected) {
  //   return selectedYellowMarker
  // } else if (property.IsActive === 2 && !selected) {
  //   return unactiveWithFeeMarker
  // }
  else if (property.IsActive && selected) {
    return selectedMarkerIcon
  } else if (!property.IsActive && selected) {
    return selectedMarkerIconInactive
  } else if (property.IsActive && !selected) {
    return assignedMarkerIcon
  } else if (!property.IsActive && !selected) {
    return assignedMarkerIconInactive
  }
  let selectIcon = property.IsActive
    ? selectedMarkerIcon
    : selectedMarkerIconInactive
  let assignIcon = property.IsActive
    ? assignedMarkerIcon
    : assignedMarkerIconInactive
  let markerIcon = selected ? selectIcon : assignIcon
}
