import { IProperty, propertyStore } from 'Models/PropertyModel'
import React, { ReactElement, useEffect, useState } from 'react'
import { Spin, message } from 'antd'

import { ControlPanel } from './ControlPanel'
import { Map } from 'Components/Map'
import { Marker } from '@react-google-maps/api'
import { PrimaryColor } from 'Utils/Color'
import { PropertyQuery } from 'Models/PropertyModel/api'
import assignedAndSelectedMarkerIcon from './assets/AssignedAndSelectedMarker.png'
import assignedMarkerIcon from './assets/AssignedMarker.png'
import { observer } from 'mobx-react-lite'
import pendingMarkerIcon from './assets/PendingMarker.png'
import selectedMarkerIcon from './assets/SelectedMarker.png'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Left = styled.section`
  flex: 1;
  position: relative;
`

const Right = styled.section`
  flex: 0.3;
  height: 100%;
  padding: 30px;
  overflow: scroll;
  background: white;
`

const Spinner = styled(Spin)`
  position: absolute;
  background: white;
  border: 1px solid ${PrimaryColor};
  padding: 18px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
`

interface Props {}

const initialCenter = {
  lat: 10.6343527660001,
  lng: 103.503359524,
}

export const PropertyMapPage = observer((props: Props) => {
  const { properties, selectedProperty } = propertyStore

  const [center, setCenter] = useState(initialCenter)
  const [loadingProperties, setLoadingProperties] = useState(false)
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
  })

  useEffect(() => {
    loadProperties()
  }, [center])

  async function loadProperties() {
    setLoadingProperties(true)
    const query: PropertyQuery = {
      geometry: `${center.lng},${center.lat}`,
      distant: 150,
      calculateBalance: true,
    }
    await propertyStore.loadNearbyProperties(query)
    setLoadingProperties(false)
  }

  function onMapDragEnd() {
    console.log('onMapDragEnd: ')
    const lat = map?.center.lat()
    const lng = map?.center.lng()
    setCenter({
      lat,
      lng,
    })
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  async function onPropertyMarkerClick(property: IProperty) {
    console.log('property: ', property)
    // property.setSelected(true)
    propertyStore.setSelectedProperty(property)
  }

  return (
    <Container>
      <Left>
        <Map
          center={center}
          zoom={15}
          onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
        >
          {properties.reduce(
            (propertyMarkers: ReactElement<any, any>[], property) => {
              let markerIcon = property.RouteID
                ? assignedMarkerIcon
                : pendingMarkerIcon

              const isSelected =
                property.OBJECTID === selectedProperty?.OBJECTID
              if (isSelected) {
                markerIcon = selectedMarkerIcon
              }

              const label = property.isSaving ? 'Saving...' : ''
              const iconSize = isSelected ? 16 : 8
              if (
                property.geometry &&
                property.geometry.lat &&
                property.geometry.lng
              ) {
                propertyMarkers.push(
                  // @ts-ignore
                  <Marker
                    key={property.OBJECTID}
                    position={property.geometry}
                    onClick={() => onPropertyMarkerClick(property)}
                    label={label}
                    icon={{
                      url: markerIcon,
                      scaledSize: {
                        width: iconSize,
                        height: iconSize,
                      },
                    }}
                  ></Marker>
                )
              }

              return propertyMarkers
            },
            []
          )}
        </Map>
        {loadingProperties && <Spinner size="large"></Spinner>}
      </Left>
      <Right>
        <ControlPanel></ControlPanel>
      </Right>
    </Container>
  )
})
