import { IBusiness, businessStore } from 'Models/BusinessModel/BusinessModel'
import { Rate, Switch } from 'antd'
import React, { useState } from 'react'
import {
  codeToCommune,
  codeToDistrict,
  codeToProvince,
  codeToVillage,
} from 'Utils'

import { PropertyTypeDisplay } from 'Components'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'

const Container = styled.div``

interface Props {
  business: IBusiness
}
export const BusinessInfo = observer((props: Props) => {
  const { business } = props
  const [settingVerify, setSettingVerify] = useState(false)

  const verified =
    business &&
    (business.Verified_St === true ||
      // @ts-ignore
      business.Verified_St === '1' ||
      business.Verified_St === 1)

  async function setVerify(value: number) {
    setSettingVerify(true)
    await business.setVerify(value)
    setSettingVerify(false)
  }

  function onVerifyChange(newValue: boolean) {
    const verifyValue = newValue ? 1 : 0
    setVerify(verifyValue)
  }

  return (
    <Container>
      <table className="styled-table">
        <tbody>
          <tr>
            <td>Name Kh:</td>
            <td>{business.Label_kh}</td>
          </tr>
          <tr>
            <td>Name Latin:</td>
            <td>{business.Label}</td>
          </tr>
          <tr>
            <td>Type:</td>
            <td>
              <PropertyTypeDisplay
                code={business.type + ''}
              ></PropertyTypeDisplay>
            </td>
          </tr>

          <tr>
            <td>Phone:</td>
            <td>{business.Phone_No}</td>
          </tr>

          <tr>
            <td>Restaurants:</td>
            <td>{business.Restaurants}</td>
          </tr>

          <tr>
            <td>Rooms:</td>
            <td>{business.Room}</td>
          </tr>

          <tr>
            <td>Stars:</td>
            <td>
              {business.Star_Rating && (
                <Rate
                  value={parseFloat(business.Star_Rating + '')}
                  disabled
                ></Rate>
              )}
            </td>
          </tr>

          <tr>
            <td>Bars:</td>
            <td>{business.Bars}</td>
          </tr>

          <tr>
            <td>Beds:</td>
            <td>{business.Bed}</td>
          </tr>

          <tr>
            <td>Gyms:</td>
            <td>{business.Gym}</td>
          </tr>

          <tr>
            <td>Land Size:</td>
            <td>{business.Land_Size}</td>
          </tr>

          <tr>
            <td>Province:</td>
            <td>{codeToProvince(business.Pro_Code + '')?.name.km}</td>
          </tr>

          <tr>
            <td>District:</td>
            <td>{codeToDistrict(business.dist_code + '')?.name.km}</td>
          </tr>

          <tr>
            <td>Commune:</td>
            <td>{codeToCommune(business.com_code + '')?.name.km}</td>
          </tr>

          <tr>
            <td>Village:</td>
            <td>{codeToVillage(business.Vill_Code + '')?.name.km}</td>
          </tr>

          <tr>
            <td>Estimated Fee:</td>
            <td>៛{thousandSeparator(business.wastec_rev)}</td>
          </tr>

          <tr>
            <td>Actual Fee:</td>
            <td>៛{thousandSeparator(business.Actual_Collection_Fee)}</td>
          </tr>

          <tr>
            <td>Verified:</td>
            <td>
              <Switch
                loading={settingVerify}
                onChange={onVerifyChange}
                checked={verified}
              ></Switch>
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  )
})
