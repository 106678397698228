import { Row, Slider, Space, Switch } from 'antd'
import React, { useState } from 'react'

interface Props {
  onSliderChange(n: number): void
}

const LiveTruckController = ({ onSliderChange }: Props) => {
  const [range, setRange] = useState(5)

  function onChange(v: number) {
    setRange(v)
    onSliderChange(v)
  }

  return (
    <div>
      <div>
        <span style={{ color: '#a2a2a2', fontSize: 13 }}>
          <span style={{ color: 'red' }}>*</span> The nearer distance, the
          better performance.
        </span>
      </div>
      Distance: {range}
      <Slider defaultValue={range} min={5} max={50} onChange={onChange} />
    </div>
  )
}

export default LiveTruckController
