import { Button, Modal, Space, message } from 'antd'
import { CitizenSelector, PropertySelector, Spinner } from 'Components'
import { IBill, billStore } from 'Models/BillModel'
import React, { useEffect, useState } from 'react'

import { BillList } from 'Pages/Billing/BillList'
import { Citizen } from 'Components/CitizenSelector/api'
import { PlusCircleOutlined } from '@ant-design/icons'
import { PropertyList } from 'Pages/PropertyActivation/PropertyList'
import { PropertyMap } from 'Pages/PropertyActivation/PropertyMap'
import { PropertyStatusEditContainer } from 'Pages/Billing/PropertyStatusEdit'
import { Tabs } from 'antd'
import { citizenStore } from 'Models/CitizenModel/CitizenModel'
import { observer } from 'mobx-react-lite'
import { IProperty, propertyStore } from 'Models/PropertyModel'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'

const { TabPane } = Tabs

const Container = styled.div`
  padding: 20px;
`

const CitizenBox = styled(Space)`
  margin-bottom: 20px;
`

const PropertyBox = styled.div`
  margin-bottom: 20px;
`

const Label = styled.div`
  color: gray;
`

const FullRow = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const AddButton = styled(Button)`
  margin-left: auto;
`

const TotalPendingAmount = styled.span`
  margin-left: 5px;
`

const ActivePropertyStatus = styled.div`
  background: #50e3c2;
  border-radius: 4px;
  margin-left: 10px;
  padding: 0 10px;
`

const InActivePropertyStatus = styled.div`
  background: #ffabab;
  border-radius: 4px;
  margin-left: 10px;
  padding: 0 10px;
`

const PaidAmount = styled.div`
  color: green;
`

const UnpaidAmount = styled.div`
  color: #ffabab;
`

export const CitizenPage = observer((props: any) => {
  const { unpaidBillTotalAmount } = billStore
  const { selectedProperty } = propertyStore
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>()
  const [
    shouldShowEditPropertyStatusModal,
    setShouldShowEditPropertyStatusModal,
  ] = useState(false)
  const [loadingBills, setLoadingBills] = useState(false)

  // currentSectedProperty is to deal with click event.
  const [currentSelectedProperty, setCurrentSelectedProperty] = useState<IProperty>()
  const [propertyBills, setPropertyBills] = useState<IBill[]>([])

  const [tabKey, setTabKey] = useState('Property-List')

  const { citizen } = citizenStore

  // since the propertyBills is not observing the store, let use useEffect to update it
  useEffect(() => {
    const citizenBills = citizen?.bills ?? []
    const updatedBillList = currentSelectedProperty ?
      citizenBills.filter(bill => bill.PropertyID + '' === currentSelectedProperty.OBJECTID) :
      citizenBills
    setPropertyBills(updatedBillList)
  }, [citizen?.bills])

  function onCitizenSelected(citizen: Citizen) {
    const formatedCitizen = {
      ...citizen.attributes,
      OBJECTID: citizen.attributes.OBJECTID + '',
    }
    citizenStore.setCitizen(formatedCitizen)
    citizenStore.citizen?.loadProperties()
    citizenStore.citizen?.loadBills()
    setPropertyBills(citizenStore.citizen?.bills ?? [])
  }

  function onTabKeyChange(key: string) {
    setTabKey(key)
  }

  function onPropertyClick(property: IProperty) {
    // set the click property as currentSelectedProperty
    // and set the bills list.
    if (currentSelectedProperty === undefined) {
      property.setSelected(true)
      setCurrentSelectedProperty(property)
      const billsOfProperty = citizen?.bills ?
        citizen?.bills.filter(bill => bill.PropertyID + '' === property.OBJECTID) :
        []
      setPropertyBills(billsOfProperty)
    } else if (currentSelectedProperty.OBJECTID === property.OBJECTID) {
      property.setSelected(false)
      setCurrentSelectedProperty(undefined)
      setPropertyBills(citizen?.bills ?? [])
    } else {
      currentSelectedProperty.setSelected(false)
      property.setSelected(true)
      setCurrentSelectedProperty(property)
      const billsOfProperty = citizen?.bills ?
        citizen?.bills.filter(bill => bill.PropertyID + '' === property.OBJECTID) :
        []
      setPropertyBills(billsOfProperty)
    }
  }

  return (
    <Container>
      <CitizenBox direction="vertical">
        <Label>Citizen</Label>
        <CitizenSelector
          onCitizenSelected={onCitizenSelected}
        ></CitizenSelector>
        {citizen && (
          <Column>
            <Space>
              <div>Phone:</div>
              <div> {citizen.Phone}</div>
            </Space>
            <Space>
              <div>Amount Due:</div>
              {citizen.unpaidBillTotalAmount === 0 ? (
                <PaidAmount>{citizen.unpaidBillTotalAmount} Riel</PaidAmount>
              ) : (
                <UnpaidAmount>
                  {thousandSeparator(citizen.unpaidBillTotalAmount)} Riel
                </UnpaidAmount>
              )}
            </Space>
          </Column>
        )}
      </CitizenBox>
      <PropertyBox>
        {citizenStore.citizen?.loading && <Spinner></Spinner>}
        <Label>Property</Label>
        <Tabs defaultActiveKey="Property-List" onChange={onTabKeyChange}>
          <TabPane tab="List" key="Property-List">
            <PropertyList
              properties={(citizen && citizen.properties.slice()) || []}
              onPropertyClick={onPropertyClick}
            ></PropertyList>
          </TabPane>
          <TabPane tab="Map" key="Property-Map">
            {tabKey === 'Property-Map' && (
              <PropertyMap
                properties={(citizen && citizen.properties) || []}
              ></PropertyMap>
            )}
          </TabPane>
        </Tabs>
      </PropertyBox>
      <FullRow>
        {selectedPropertyId && (
          <div>
            <span>Total Pending Amount:</span>
            <TotalPendingAmount>
              ៛{thousandSeparator(unpaidBillTotalAmount)}
            </TotalPendingAmount>
          </div>
        )}
      </FullRow>

      <Label>Bills</Label>

      {loadingBills ? (
        <Spinner></Spinner>
      ) : (
        <BillList bills={propertyBills ?? []}></BillList>
      )}

      {shouldShowEditPropertyStatusModal && (
        <Modal
          visible={shouldShowEditPropertyStatusModal}
          onCancel={() => setShouldShowEditPropertyStatusModal(false)}
          footer={[]}
          width={600}
        >
          <PropertyStatusEditContainer
            property={selectedProperty!}
            onSaveFinish={() => setShouldShowEditPropertyStatusModal(false)}
          ></PropertyStatusEditContainer>
        </Modal>
      )}
    </Container>
  )
})
