import * as api from './api'

import React, { useEffect, useState } from 'react'
import { Select, message } from 'antd'

import { SelectValue } from 'antd/lib/select'
import { Truck } from './api'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  flex: 1 1 auto;
`

interface Props {
  onChange?: (value: SelectValue) => void
  getUnitId?: (unitId: string) => void
  default?: string
}

export const TruckSelector = (props: Props) => {
  const [trucks, setTrucks] = useState<Truck[]>([])
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(props?.default)

  useEffect(() => {
    loadTrucks()
  }, [])

  async function loadTrucks() {
    setLoading(true)

    try {
      const trucks = await api.getTrucks()
      setTrucks(trucks)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }
    }
    setLoading(false)
  }

  function onChange(value: any, option: any) {
    const truck = trucks?.find((EachTruck) => {
      return EachTruck.attributes.OBJECTID === Number(option?.objectID)
    })

    if (truck && props.getUnitId) {
      props.getUnitId(truck.attributes.UnitID)
      setValue(truck.attributes.UnitID)
    }

    props.onChange && props.onChange(value)
  }

  return (
    <StyledSelect
      {...props}
      showSearch
      loading={loading}
      placeholder="Select a truck"
      optionFilterProp="children"
      onChange={onChange}
      value={value}
      filterOption={(input, option) => {
        if (option && option.children) {
          const match =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          return match
        } else {
          return false
        }
      }}
    >
      <Option key={'NONE'} value={''}>
        None
      </Option>
      {trucks.map((truck) => {
        return (
          <Option
            key={truck.attributes.OBJECTID + ''}
            value={truck.attributes.UnitID + ''}
            objectID={truck.attributes?.OBJECTID}
          >
            {`${truck.attributes.Name}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
