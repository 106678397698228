import { ISchedule } from 'Models'
import { ITruck } from 'Models/TruckModel'
import React from 'react'
import { Spin } from 'antd'
import { TruckListItem } from './TruckListItem'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  height: 90%;
  overflow: scroll;
`

interface Props {
  trucks: ITruck[]
  selectedTruck?: ITruck
  onTruckClick?: (truck: ITruck) => void
  onTruckCurrentLocationLoaded?: (truck: ITruck) => void
  onScheduleClick?: (schedule: ISchedule) => void
  selectedSchedule?: ISchedule
  loading?: boolean
}

export const TruckList = observer((props: Props) => {
  const { trucks = [], loading } = props

  return (
    <Container>
      {loading && <Spin />}
      {trucks.map((truck) => {
        return (
          <TruckListItem
            truck={truck}
            key={truck.OBJECTID}
            onTruckClick={props.onTruckClick}
            onTruckCurrentLocationLoaded={props.onTruckCurrentLocationLoaded}
            onScheduleClick={props.onScheduleClick}
            selectedSchedule={props.selectedSchedule}
            selectedTruck={props.selectedTruck}
          />
        )
      })}
    </Container>
  )
})
