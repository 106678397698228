import './index.css'

import * as Sentry from '@sentry/react'

import App from './App'
import setup from 'Auth/AuthInterceptor'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import Axios from 'axios'
import { configure } from 'axios-hooks';
import { environment } from 'environment'
import moment from 'moment-timezone';

moment.tz.setDefault("Asia/Pnhom_Penh");

const axios = Axios.create({
  baseURL: environment.apiBaseUrl
})

configure({ cache: false, axios })

Sentry.init({
  dsn:
    'https://431554b7d0db4045a0a3340930e9e103@o567099.ingest.sentry.io/5710738',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

setup()

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,

  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
