export const PrimaryColor = '#1890ff'
export const Blue = '#1890ff'
export const Cyan = '#00cec9'

export function getRandomColor() {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
