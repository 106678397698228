import React from 'react'
import { Table } from 'antd'
import { Observer, observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { IReport } from 'Models'
import moment from 'moment'
import { DeleteConfirmButton } from 'Components'

import { handleApiError } from 'Utils'
import { MapPinButton } from 'Components/MapPinButton/MapPinButton'
import { ColumnsType } from 'antd/lib/table'
import { ReportStatusSelector } from 'Components/ReportStatusSelector'

interface Props {
  reports: IReport[]
  onLocationClick?: (report: IReport) => void
  onDeleteClick?: () => void
}

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const ReportList = observer((props: Props) => {
  const { reports, onLocationClick } = props

  async function onDeleteClick(report: IReport) {
    await report.delete()
    props.onDeleteClick && props.onDeleteClick()
  }

  async function onChange(report: IReport, value: string) {
    try {
      await report.edit({ status: value })
    } catch (e) {
      handleApiError(e)
    }
  }

  const columns: ColumnsType<IReport> = [
    {
      title: 'No.',
      render: (text, record, index) => {
        return index + 1
      },
      key: 'No.',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Name',
      dataIndex: ['citizen', 'name'],
      key: 'citizenName',
    },
    {
      title: 'Phone',
      dataIndex: ['citizen', 'phone'],
      key: 'citizenPhone',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      key: 'status',
      render: function render(text: string, record: IReport) {
        return (
          <Observer>
            {() => <ReportStatusSelector report={record} onChange={onChange} />}
          </Observer>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: function render(text: string, record: IReport) {
        return <span>{text ? moment(text).format('DD-MM-YYYY') : ''}</span>
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: function render(text: string, record: IReport) {
        return (
          <Observer>
            {() => (
              <ActionWrapper>
                <MapPinButton
                  entity={record}
                  size={20}
                  onClick={() => onLocationClick && onLocationClick(record)}
                />
                <DeleteConfirmButton
                  deleting={record.isDeleting}
                  onConfirmClick={() => onDeleteClick(record)}
                ></DeleteConfirmButton>
              </ActionWrapper>
            )}
          </Observer>
        )
      },
    },
  ]

  return (
    <>
      <Table columns={columns} dataSource={reports.slice()} />
    </>
  )
})
