import React, { useEffect, useState } from 'react'

import { Coordinate } from 'Models/Types'
import { Cyan } from 'Utils/Color'
import { Polyline } from '@react-google-maps/api'
import { coverageAreaStore } from 'Models/CoverageAreaModel/CoverageAreaModel'
import { message } from 'antd'

interface Props {
  center: Coordinate
}
export const CoverageArea = (props: Props) => {
  const { center } = props
  const { records: coverageAreas } = coverageAreaStore

  useEffect(() => {
    async function load() {
      const query = {
        geometry: `${center.lng},${center.lat}`,
        distant: 1500,
      }

      try {
        await coverageAreaStore.loadNearbyRecords(query)
      } catch (e: any) {
        console.log('error', e)
        if (e.response) {
          if (e.response.status === 401) {
            console.log('unauthorized')
            message.error('Your session is expired, please login again')
          } else {
            message.error(e.message)
          }
        } else {
          message.error(e.message)
        }
      }
    }

    load()
  }, [center])

  return (
    <>
      {coverageAreas.map((coverageArea) => {
        return coverageArea.Paths.map((Path) => {
          return (
            <Polyline
              key={Path.id}
              path={Path.coordinates}
              options={{
                strokeColor: Cyan,
                strokeWeight: 5,
              }}
            ></Polyline>
          )
        })
      })}
    </>
  )
}
