import { Button, Popconfirm } from 'antd'
import { DeleteOutlined, WarningFilled } from '@ant-design/icons'
import React, { ReactNode } from 'react'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, KSWM, WasteCompany } from 'Auth/Role'

interface ConfirmButtonProps {
  onConfirmClick: () => void
  danger?: boolean
  showLabel?: boolean
  label?: string
  icon?: ReactNode
  roles?: Array<string>
  title?: string
}

export const ConfirmButton = (props: ConfirmButtonProps) => {
  const { title, onConfirmClick, showLabel, label, icon, roles } = props

  return componentLevelAuthorization(
    <Popconfirm
      title={title || 'Are you sure to perform this action?'}
      onConfirm={onConfirmClick}
      okText="Confirm"
      cancelText="Cancel"
      icon={<WarningFilled style={{ color: 'red' }} />}
    >
      <Button {...props} shape={showLabel ? 'round' : 'circle'} icon={icon}>
        {showLabel && (label || 'Confirm')}
      </Button>
    </Popconfirm>,
    roles || [Admin, WasteCompany]
  )
}
