import {
  Button as BaseButton,
  Checkbox,
  Modal,
  Spin,
  Switch,
  Tabs,
  message,
} from 'antd'
import {
  IBill,
  IProperty,
  IRoute,
  billStore,
  propertyStore,
  routeStore,
  scheduleStore,
  user,
  userStore,
  IUser,
} from 'Models'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { BillAddContainer } from 'Pages/Billing/Add'
import { BillDetail } from 'Components/BillDetail/BillDetail'
import { BillMinimumList } from 'Components/BillMinimumList/BillMinimumList'
import { Coordinate } from 'Models/Types'
import { Map } from 'Components/Map'
import { Marker } from '@react-google-maps/api'
import { PrimaryColor } from 'Utils/Color'
import { PropertyActiveSelector } from 'Components/PropertyActiveSelector/PropertyActiveSelector'
import { PropertyAdd } from './Add/PropertyAdd'
import { PropertyDetail } from 'Pages/PropertyActivation'
import { PropertyList } from '../RoutePropertyAndSchedule/ControlPanel/PropertyList'
import { PropertySearchInput } from 'Components/PropertySearchInput/PropertySearchInput'
import { RouteModal } from 'Components/RouteModal'
import { handleApiError } from 'Utils'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { getPropertyIcon } from 'Utils'
import { hasPermission, isUserAdmin, isUserMOE } from 'Auth/Authorization'
import moment from 'moment'
import { AddedBySearchInput } from 'Components/AddedBySearchInput/AddedBySearchInput'
import { createProperty, enableDruggingProperty } from 'Auth/Role'
import { getCenterPoint } from 'Utils/Constants'
import { CollectorSearchInput } from 'Components/CollectorSearchInput/CollectorSearchInput'
import { collectorStore } from 'Models/CollectorModel'
import { propertyTypeStore } from 'Models/PropertyTypeModel/PropertyTypeModel'
import { getQueryByProvince } from 'Models/PropertyModel/api'

const { TabPane } = Tabs

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Left = styled.section`
  flex: 1;
  position: relative;
`

const Right = styled.section`
  flex: 0.7;
  height: 100%;
  padding: 30px;
  overflow: scroll;
  background: white;
`

const PropertySearch = styled(PropertySearchInput)`
  // position: absolute;
  // left: 46%;
  // top: 3%;
  margin-left: 0.5rem;
`

const Spinner = styled(Spin)`
  position: relative;
  background: white;
  border: 1px solid ${PrimaryColor};
  padding: 18px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: scroll;
`

const Button = styled(BaseButton)`
  border-radius: 10px;
  margin: 0.5rem;
`

const Title = styled.h3`
  margin-top: 10px;
`

const PaddingWrapper = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
`

const CustomCheckbox = styled(Checkbox)`
  margin: 0 4px;
`

const LocationEditableSwitchWrapper = styled.div`
  position: absolute;
  left: 2%;
  top: 10%;

  display: flex;
  align-items: center;

  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
`

const CustomSwitch = styled(Switch)`
  margin: 0 4px;
`

const FILTER_BUTTONS = {
  noActive: 'noActiveButton',
  propertyWithinMap: 'All Properties Within Map',
  nearByProperties: 'Nearby Properties',
  createProperty: 'Create Property',
  propertiesWithNoLocation: 'Properties With No Location',
  propertiesAddedByUser: 'Properties added by user',
  propertiesByCollector: 'Properties by collector',
  checkPropertiesCanBilled:
    'Check properties can release bill for ' + moment().format('MMMM'),
}

interface Props {}
export const PropertyPage = observer((props: Props) => {
  const { routes } = routeStore
  const { bills } = billStore
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location: any) => {},
  })
  const [
    showCreateCitizenAndPropertyModal,
    setShowCreateCitizenAndPropertyModal,
  ] = useState(false)
  const [selectedCoordinate, setSelectedCoordinate] = useState<Coordinate>()
  const [loadingProperties, setLoadingProperties] = useState(false)
  const [showPropertyDetailModal, setShowPropertyDetailModal] = useState(false)
  const [isRouteModalShow, setIsRouteModalShow] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const initialCenter = getCenterPoint()
  const [center, setCenter] = useState(initialCenter)
  const [tabKey, setTabKey] = useState('Property')
  const [selectedBill, setSelectedBill] = useState<IBill>()
  const [showBillDetail, setShowBillDetail] = useState(false)
  const [showBillModal, setShowBillModal] = useState(false)
  const [showAddBillModal, setShowAddBillModal] = useState(false)
  const [isPropertySelectorActive, setIsPropertySelectorActive] = useState(4) // 0 is false, 1 is true , 2 is TBD, 3 is suspense so 4 is undefined
  const [propertyActionModalTitle, setPropertyActionModalTitle] = useState(
    'ព័ត៌មានលម្អិតអំពីអចលនទ្រព្យ (Property Detail)'
  )
  // routeMap seem to be not needed at the moment.
  const [routeMap, setRouteMap] = useState<Record<string, IRoute>>({})
  const [loadingSchedules, setLoadingSchedules] = useState(false)
  const [loadingBills, setLoadingBills] = useState(false)
  const [togglePropertiesWithLocation, setTogglePropertiesWithLocation] =
    useState(false)
  const [isPropertyDraggable, setIsPropertyDraggable] = useState(false)
  const [isPrepareToAssignLocation, setIsPrepareToAssignLocation] =
    useState(false)
  const [activeButton, setActiveButton] = useState(FILTER_BUTTONS.noActive)
  const [shouldLoadPropertyOnMapMoved, setShouldLoadPropertyOnMapMoved] =
    useState(false)

  const isMOE = isUserMOE()
  const isAdmin = isUserAdmin()

  let tableTitle =
    activeButton === FILTER_BUTTONS.noActive ? 'Properties' : activeButton

  if (propertyStore.selectedProperty) {
    tableTitle = 'Selected property'
  }

  useEffect(() => {
    loadNearByProperties(isPropertySelectorActive)
    loadNearbyRoutes()
  }, [])

  useEffect(() => {
    if (propertyStore.selectedProperty) {
      setLoadingBills(true)
      loadSelectedPropertyBills().then((res) => {
        setLoadingBills(false)
      })

      // if (propertyStore.selectedProperty.RouteID) {
      //   routeStore.setSelectedRoute(propertyStore.selectedProperty.RouteID + '')
      // }
    }
  }, [propertyStore.selectedProperty, isPropertySelectorActive])

  // useEffect to handle center change
  useEffect(() => {
    if (!togglePropertiesWithLocation && shouldLoadPropertyOnMapMoved) {
      loadNearByProperties(isPropertySelectorActive)
      loadNearbyRoutes()
    }
  }, [center])
  // useEffect to handle togglePropertiesWithLocation
  useEffect(() => {
    if (togglePropertiesWithLocation) {
      loadPropertiesWithNoLocation()
    } else {
      loadNearByProperties(isPropertySelectorActive)
      loadNearbyRoutes()
    }
  }, [togglePropertiesWithLocation])

  useEffect(() => {
    if (isAdmin) {
      userStore.loadUsers()
    }

    const code = user?.province_code || '18'

    propertyTypeStore.loadAll(code)
    collectorStore.loadCollectors()
  }, [])

  useEffect(() => {
    // load schedule for the route.
    if (routeStore.selectedRoute && routeStore.selectedRoute.OBJECTID) {
      loadSchedules(routeStore.selectedRoute.OBJECTID)
    } else {
      scheduleStore.setSchedules([])
    }
  }, [routeStore.selectedRoute])

  async function loadSchedules(routeId: string) {
    setLoadingSchedules(true)
    try {
      await scheduleStore.loadSchedulesByRoute(routeId)
    } catch (e: any) {
      console.log(e)
      message.error(e.message)
    }

    setLoadingSchedules(false)
  }

  async function loadPropertiesWithNoLocation() {
    setIsFiltering(false)
    try {
      setLoadingProperties(true)
      await propertyStore.loadPropertiesWithNoLocation()
      setLoadingProperties(false)
    } catch (e) {
      handleApiError(e)
    }
  }

  async function loadSelectedPropertyBills() {
    setIsFiltering(false)
    try {
      propertyStore.selectedProperty &&
        (await billStore.loadBills(
          propertyStore.selectedProperty.OBJECTID + ''
        ))
    } catch (e: any) {
      console.log('error', e)
      if (e.response) {
        if (e.response.status === 401) {
          console.log('unauthorized')
          message.error('Your session is expired, please login again')
        } else {
          message.error(e.message)
        }
      } else {
        message.error(e.message)
      }
    }
  }

  function onMapDragEnd() {
    console.log('isFiltering', isFiltering)
    if (!isFiltering) {
      const lat = map.center.lat()
      const lng = map.center.lng()
      setCenter({
        lat,
        lng,
      })
    }
  }

  async function loadNearByProperties(isActive?: number) {
    setIsFiltering(false)
    const query = {
      geometry: `${center.lng},${center.lat}`,
      distant: 200,
      isActive,
    }
    try {
      setLoadingProperties(true)
      propertyStore.setSelectedProperty(undefined)
      setSelectedCoordinate(undefined)
      await propertyStore.loadNearbyProperties(query)
      setActiveButton(FILTER_BUTTONS.nearByProperties)
      setTogglePropertiesWithLocation(false)
      setLoadingProperties(false)
    } catch (e: any) {
      console.log('error', e)
      if (e.response) {
        if (e.response.status === 401) {
          console.log('unauthorized')
          message.error('Your session is expired, please login again')
        } else {
          message.error(e.message)
        }
      } else {
        message.error(e.message)
      }
    }
  }

  async function loadAllPropertiesWithinMap() {
    setIsFiltering(false)
    try {
      setLoadingProperties(true)
      await propertyStore.loadAllProperties()
      setActiveButton(FILTER_BUTTONS.propertyWithinMap)
      setTogglePropertiesWithLocation(false)
      setLoadingProperties(false)
    } catch (e: any) {
      console.log('error', e)
      if (e.response) {
        if (e.response.status === 401) {
          console.log('unauthorized')
          message.error('Your session is expired, please login again')
        } else {
          message.error(e.message)
        }
      } else {
        message.error(e.message)
      }
    }
  }

  async function loadNearbyRoutes() {
    setIsFiltering(false)
    const query = {
      geometry: `${center.lng},${center.lat}`,
      distant: 1500,
    }
    try {
      await routeStore.loadRoutes(query)
      const routesAsMap: Record<string, IRoute> = Object.assign(
        {},
        ...routes.map((route) => ({ [route.OBJECTID]: route }))
      )
      setRouteMap(routesAsMap)
    } catch (e: any) {
      console.log('error', e)
      if (e.response) {
        if (e.response.status === 401) {
          console.log('unauthorized')
          message.error('Your session is expired, please login again')
        } else {
          message.error(e.message)
        }
      } else {
        message.error(e.message)
      }
    }
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  function onPropertyMarkerClick(property: IProperty) {
    // setSelectedProperty(property)
    propertyStore.setSelectedProperty(property)

    // setPropertyList([property])

    setSelectedCoordinate(undefined)
    setShowCreateCitizenAndPropertyModal(false)
  }

  function onMapClick(event: any) {
    if (togglePropertiesWithLocation) {
      if (!loadingProperties) {
        propertyStore.selectedProperty &&
          onMarkerDragEnd(propertyStore.selectedProperty, event)
        propertyStore.setSelectedProperty(undefined)
        setIsPrepareToAssignLocation(false)
      }
    } else {
      if (!loadingProperties) {
        const lat = event.latLng.lat()
        const lng = event.latLng.lng()
        setSelectedCoordinate({
          lat,
          lng,
        })
        // setSelectedProperty(undefined)
        propertyStore.setSelectedProperty(undefined)

        setShowCreateCitizenAndPropertyModal(true)
      }
    }
  }

  function resetTable() {
    propertyStore.setSelectedProperty(undefined)
  }

  function onPropertySaveFinish() {
    setLoadingProperties(true)
    setShowCreateCitizenAndPropertyModal(false)
    setSelectedCoordinate(undefined)
    const createdProperty = propertyStore.getLastProperty()
    setLoadingProperties(false)
    // show route modal after create
    if (
      createdProperty.geometry &&
      createdProperty.geometry.lat &&
      createdProperty.geometry.lng
    ) {
      propertyStore.setSelectedProperty(createdProperty)
      setIsRouteModalShow(true)
      createdProperty.RouteID &&
        routeStore.setSelectedRoute(createdProperty.RouteID)
    }
  }

  function panMap(property: IProperty) {
    map.panTo(property.geometry)
    propertyStore.setSelectedProperty(property)
  }

  function handlePropertySelectedFromSearch(property: IProperty) {
    // propertyStore.setSelectedProperty(property)
    property.geometry &&
      property.geometry.lng &&
      property.geometry.lat &&
      map.panTo(property.geometry)
    setSelectedCoordinate(undefined)
  }

  async function handleAddedBySelectedFromSearch(id: string, value: string) {
    if (id === '') {
      setIsFiltering(false)
      loadNearByProperties()
    } else {
      setIsFiltering(true)
      await propertyStore.searchProperties(
        `where=((AddedByAgent='${id}' OR AddedBy='${value}') ${
          getQueryByProvince() !== '' ? ' AND ' + getQueryByProvince() : ''
        })`
      )
      setActiveButton(FILTER_BUTTONS.propertiesAddedByUser)
    }

    if (propertyStore.properties.length > 0) {
      let location = null
      const geometry = propertyStore.properties[0].geometry
      location = { lat: geometry?.lat, lng: geometry?.lng }
      map.panTo(location)
    }
  }

  async function handleCollectorSelectedFromSearch(id: string, value: string) {
    if (id === '') {
      setIsFiltering(false)
      loadNearByProperties()
    } else {
      setIsFiltering(true)

      await propertyStore.searchProperties(
        `where=((Collector='${id}') ${
          getQueryByProvince() !== '' ? ' AND ' + getQueryByProvince() : ''
        })`
      )
      setActiveButton(FILTER_BUTTONS.propertiesByCollector)
    }

    if (propertyStore.properties.length > 0) {
      let location = null
      const geometry = propertyStore.properties[0].geometry
      location = { lat: geometry?.lat, lng: geometry?.lng }
      map.panTo(location)
    }
  }

  function onPropertyDetailButtonClick(property: IProperty) {
    propertyStore.setSelectedProperty(property)
    setShowPropertyDetailModal(true)
  }

  function onTabKeyChange(key: string) {
    setTabKey(key)
  }

  function onBillMoreButtonClick(bill: IBill) {
    setSelectedBill(bill)
    setShowBillDetail(true)
  }

  async function onPropertyActiveSelectorChange(isActive: any) {
    const isActiveNum = parseInt(isActive.toString())
    setIsPropertySelectorActive(isActiveNum)
    loadNearByProperties(isActiveNum)
  }

  function onRouteButtonClick(property: IProperty) {
    propertyStore.setSelectedProperty(property)

    setIsRouteModalShow(true)
    property.RouteID && routeStore.setSelectedRoute(property.RouteID)
  }

  function onCloseRouteModal() {
    setIsRouteModalShow(false)
    routeStore.setSelectedRoute(undefined)
  }

  function onAssignRouteClick() {
    setIsRouteModalShow(false)
    routeStore.setSelectedRoute(undefined)
  }

  async function onMarkerDragEnd(property: IProperty, event: any) {
    const messageKey = 'update-location'
    message.loading({ content: 'Saving...', key: messageKey })
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    await property.edit({
      geometry: {
        x: lng,
        y: lat,
      },
    })
    message.success({ content: 'Success!', key: messageKey, duration: 2 })
  }

  function onMapPinButtonClick(property: IProperty) {
    // check the to select the property
    // then click on the map to assign location
    // to the property.

    propertyStore.setSelectedProperty(property)
    setIsPrepareToAssignLocation(true)
  }

  function handleBillButtonClick(property: IProperty) {
    setShowBillModal(true)
  }

  const isBtnActive = (type: string) => {
    return activeButton === type ? 'primary' : 'default'
  }

  return (
    <Container>
      <Left>
        <Map
          center={center}
          zoom={15}
          onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
          onDblClick={onMapClick}
          options={{ draggable: !loadingProperties }}
        >
          {loadingProperties && <Spinner />}
          {!loadingProperties &&
            propertyStore.properties.reduce(
              (
                propertiesFilter: ReactElement<any, any>[],
                property: IProperty
              ) => {
                const selected =
                  propertyStore.selectedProperty &&
                  propertyStore.selectedProperty.OBJECTID + '' ===
                    property.OBJECTID

                let markerIcon = getPropertyIcon(property, selected)

                const label = property.isSaving ? 'Saving...' : ''
                const iconSize = selected ? 20 : 14

                if (
                  (property.HasLocation === 1 ||
                    !togglePropertiesWithLocation) &&
                  property.geometry &&
                  property.geometry.lat &&
                  property.geometry.lng
                ) {
                  propertiesFilter.push(
                    // @ts-ignore
                    <Marker
                      key={property.OBJECTID}
                      position={property.geometry}
                      onClick={() => onPropertyMarkerClick(property)}
                      label={label}
                      draggable={isPropertyDraggable}
                      onDragEnd={(event) => onMarkerDragEnd(property, event)}
                      icon={{
                        url: markerIcon,
                        scaledSize: {
                          width: iconSize,
                          height: iconSize,
                        },
                      }}
                    ></Marker>
                  )
                }

                return propertiesFilter
              },
              []
            )}

          {selectedCoordinate && (
            <Marker position={selectedCoordinate}></Marker>
          )}
          {hasPermission(enableDruggingProperty) && (
            <LocationEditableSwitchWrapper>
              Enable Dragging Property:
              <CustomSwitch
                disabled={loadingProperties || isMOE}
                checked={isPropertyDraggable}
                onChange={(event) => setIsPropertyDraggable(event)}
              />
            </LocationEditableSwitchWrapper>
          )}
          <LocationEditableSwitchWrapper style={{ top: '15%' }}>
            Load property when map moved:
            <CustomSwitch
              disabled={loadingProperties}
              checked={shouldLoadPropertyOnMapMoved}
              onChange={(event) => setShouldLoadPropertyOnMapMoved(event)}
            />
          </LocationEditableSwitchWrapper>
        </Map>
      </Left>
      <Right>
        <Tabs defaultActiveKey="Property" onChange={onTabKeyChange}>
          <TabPane tab="Property" key="Property">
            <ButtonWrapper style={{ flexWrap: 'wrap' }}>
              {!showCreateCitizenAndPropertyModal && (
                <Button
                  onClick={() => loadNearByProperties()}
                  disabled={loadingProperties}
                  type={isBtnActive(FILTER_BUTTONS.nearByProperties)}
                >
                  Load Nearby Properties
                </Button>
              )}

              {!showCreateCitizenAndPropertyModal && (
                <Button
                  onClick={loadAllPropertiesWithinMap}
                  disabled={loadingProperties}
                  type={isBtnActive(FILTER_BUTTONS.propertyWithinMap)}
                >
                  Load Properties Within Map
                </Button>
              )}

              {/* {!showCreateCitizenAndPropertyModal &&
                !isMOE &&
                hasPermission(createProperty) && (
                  <Button
                    onClick={() => {
                      setShowCreateCitizenAndPropertyModal(true)
                      setTogglePropertiesWithLocation(false)
                    }}
                    disabled={loadingProperties}
                  >
                    Create Property +
                  </Button>
                )} */}

              {/* {!showCreateCitizenAndPropertyModal && (
                <Button
                  onClick={(e) => {
                    setTogglePropertiesWithLocation(true)
                    setActiveButton(FILTER_BUTTONS.propertiesWithNoLocation)
                  }}
                  disabled={loadingProperties}
                  type={isBtnActive(FILTER_BUTTONS.propertiesWithNoLocation)}
                >
                  Show Properties With No Location
                </Button>
              )} */}

              {!showCreateCitizenAndPropertyModal && (
                <Button
                  disabled={loadingProperties}
                  type={isBtnActive(FILTER_BUTTONS.checkPropertiesCanBilled)}
                  onClick={async () => {
                    setLoadingProperties(true)
                    setActiveButton(FILTER_BUTTONS.checkPropertiesCanBilled)
                    await propertyStore.checkPropertiesCanBill()
                    setLoadingProperties(false)
                  }}
                >
                  Check properties can billed
                </Button>
              )}

              {showCreateCitizenAndPropertyModal && (
                <Button
                  onClick={() => {
                    setShowCreateCitizenAndPropertyModal(false)
                    setSelectedCoordinate(undefined)
                  }}
                  disabled={loadingProperties}
                >
                  Back To Listing
                </Button>
              )}

              {/* {propertyStore.selectedProperty && (
                <Button
                  type="primary"
                  onClick={resetTable}
                  disabled={loadingProperties}
                  ghost
                >
                  Reset Table
                </Button>
              )} */}
            </ButtonWrapper>

            {!showCreateCitizenAndPropertyModal && (
              <PaddingWrapper>
                Search Property:
                <PropertySearch
                  onPropertySelected={handlePropertySelectedFromSearch}
                  disabled={loadingProperties}
                />
              </PaddingWrapper>
            )}

            {!showCreateCitizenAndPropertyModal && isAdmin && (
              <PaddingWrapper>
                Search added by: &nbsp;
                <AddedBySearchInput
                  onIdSelected={handleAddedBySelectedFromSearch}
                  disabled={loadingProperties}
                />
              </PaddingWrapper>
            )}

            {!showCreateCitizenAndPropertyModal && (
              <PaddingWrapper>
                Property by collector: &nbsp;
                <CollectorSearchInput
                  onIdSelected={handleCollectorSelectedFromSearch}
                  disabled={loadingProperties}
                />
              </PaddingWrapper>
            )}

            {!showCreateCitizenAndPropertyModal &&
              !togglePropertiesWithLocation &&
              propertyStore.properties && (
                <PaddingWrapper>
                  Filter by properties status:
                  <PropertyActiveSelector
                    // value={isPropertySelectorActive}
                    onChange={onPropertyActiveSelectorChange}
                    disabled={loadingProperties}
                    defaultValue={isPropertySelectorActive}
                  />
                </PaddingWrapper>
              )}

            {/* {!showCreateCitizenAndPropertyModal && (
              <PaddingWrapper>
                Show Properties With No Location:
                <CustomCheckbox
                  checked={togglePropertiesWithLocation}
                  onChange={(e) =>
                    setTogglePropertiesWithLocation(e.target.checked)
                  }
                  disabled={loadingProperties}
                />
              </PaddingWrapper>
            )} */}

            {isPrepareToAssignLocation && (
              <PaddingWrapper style={{ color: '#50e3c2' }}>
                CLICK SOMEWHERE ON THE MAP TO ASSIGN LOCATION
              </PaddingWrapper>
            )}

            {!showCreateCitizenAndPropertyModal && propertyStore.properties && (
              <PropertyList
                tableTitle={tableTitle}
                properties={
                  togglePropertiesWithLocation
                    ? propertyStore.propertyWithNoLocation
                    : propertyStore.getPropertyList.slice() || []
                }
                isPropertiesHasNoLocation={togglePropertiesWithLocation}
                onPropertyClick={panMap}
                onMoreButtonClick={onPropertyDetailButtonClick}
                onRouteButtonClick={onRouteButtonClick}
                onMapPinButtonClick={onMapPinButtonClick}
                onBillButtonClick={handleBillButtonClick}
              />
            )}

            {showCreateCitizenAndPropertyModal && (
              <div>
                <Title>បង្កើតអចលនទ្រព្យ (Create Property): </Title>
                <PropertyAdd
                  coordinate={selectedCoordinate}
                  onSaveFinish={onPropertySaveFinish}
                />
              </div>
            )}
          </TabPane>

          {/* <TabPane tab="Bill" key="Bill">
            <ButtonWrapper>
              {propertyStore.selectedProperty && !isMOE && (
                <Button onClick={() => setShowAddBillModal(true)}>
                  Create Bill +
                </Button>
              )}
            </ButtonWrapper>
            {propertyStore.selectedProperty && (
              <Title>
                Bills for Property{' '}
                <i>
                  {`${propertyStore.selectedProperty.Name}`}{' '}
                  {`(id ${propertyStore.selectedProperty.OBJECTID})`} :
                </i>
              </Title>
            )}
            <BillMinimumList
              bills={bills.slice()}
              onMoreButtonClick={onBillMoreButtonClick}
            />
          </TabPane> */}
        </Tabs>
      </Right>
      {showPropertyDetailModal && propertyStore.selectedProperty && (
        <Modal
          title={propertyActionModalTitle}
          visible={Boolean(
            showPropertyDetailModal && propertyStore.selectedProperty
          )}
          onCancel={() => {
            setShowPropertyDetailModal(false)
            setPropertyActionModalTitle(
              'ព័ត៌មានលម្អិតអំពីអចលនទ្រព្យ (Property Detail)'
            )
          }}
          footer={[]}
          width={600}
        >
          <PropertyDetail
            property={propertyStore.selectedProperty!}
            onEditClick={() =>
              setPropertyActionModalTitle('កែសម្រួលអចលនទ្រព្យ (Edit Property)')
            }
          />
        </Modal>
      )}
      {showBillDetail && selectedBill && (
        <Modal
          title="ព័ត៌មានលម្អិតអំពីវិក័យប័ត្រ (Bill Detail)"
          visible={showBillDetail}
          onCancel={() => setShowBillDetail(false)}
          footer={[]}
          width={600}
        >
          <BillDetail
            bill={selectedBill}
            onCloseModal={() => setShowBillDetail(false)}
          />
        </Modal>
      )}
      {showBillModal && (
        <Modal
          title="Bill for property"
          visible={showBillModal}
          onCancel={() => setShowBillModal(false)}
          width={600}
          footer={[
            <Button key="back" onClick={() => setShowBillModal(false)}>
              Close
            </Button>,
          ]}
        >
          <ButtonWrapper>
            {propertyStore.selectedProperty && !isMOE && (
              <Button onClick={() => setShowAddBillModal(true)}>
                Create Bill +
              </Button>
            )}
          </ButtonWrapper>
          {propertyStore.selectedProperty && (
            <div>
              <Title>
                Bills for Property{' '}
                <i>
                  {`${propertyStore.selectedProperty?.Name + ' ' || ''}`}
                  {`(id ${propertyStore.selectedProperty.OBJECTID})`} :
                </i>
              </Title>
              <span>
                Last billed at:{' '}
                {propertyStore.selectedProperty.LastBilledDate &&
                  moment(propertyStore.selectedProperty.LastBilledDate).format(
                    'YYYY-MM-DD HH:ss'
                  )}
              </span>
            </div>
          )}
          <BillMinimumList
            loading={loadingBills}
            bills={bills.slice()}
            onMoreButtonClick={onBillMoreButtonClick}
          />
        </Modal>
      )}
      {showAddBillModal && propertyStore.selectedProperty && (
        <Modal
          title="បង្កើតវិក័យប័ត្រ (Create Bill)"
          visible={showAddBillModal}
          onCancel={() => setShowAddBillModal(false)}
          footer={[]}
          width={600}
        >
          <BillAddContainer
            property={propertyStore.selectedProperty}
            onSaveFinish={() => setShowAddBillModal(false)}
          ></BillAddContainer>
        </Modal>
      )}

      <Modal
        title="Show Route"
        visible={isRouteModalShow}
        onCancel={onCloseRouteModal}
        footer={[]}
        width={700}
      >
        <RouteModal
          property={propertyStore.selectedProperty!}
          coordinate={{
            lng: propertyStore.selectedProperty?.geometry!.lng ?? center.lng,
            lat: propertyStore.selectedProperty?.geometry!.lat ?? center.lat,
          }}
          routes={routes.slice()}
          onAssignRouteClick={onAssignRouteClick}
          loadingSchedules={loadingSchedules}
        ></RouteModal>
      </Modal>
    </Container>
  )
})
