import { DeleteConfirmButton, EditButton } from 'Components'
import { Observer, observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Space, Table } from 'antd'

import { ICollectionCompany } from 'Models/CollectionCompanyModel/CollectionCompanyModel'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'

const Container = styled.div``

interface Props {
  collectionCompanies: ICollectionCompany[]
  onEditClick: (item: ICollectionCompany) => void
}
export const CollectionCompanyList = observer((props: Props) => {
  const { collectionCompanies, onEditClick } = props

  async function onDeleteClick(type: ICollectionCompany) {
    type.delete()
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      sorter: (a: ICollectionCompany, b: ICollectionCompany) =>
        `${a.Name}`.localeCompare(`${b.Name}`),
    },

    {
      title: 'Action',
      key: 'action',
      render: function render(text: string, record: ICollectionCompany) {
        return  (
          <Observer>
            {() => (
              <Space>
                <EditButton onClick={() => onEditClick(record)}></EditButton>
                <DeleteConfirmButton
                  deleting={record.isDeleting}
                  onConfirmClick={() => onDeleteClick(record)}
                ></DeleteConfirmButton>
              </Space>
            )}
          </Observer>
        )
      },
    },
  ]
  return (
    <Container>
      <Table columns={columns} dataSource={collectionCompanies.slice()} />
    </Container>
  )
})
