import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import axios from 'axios'
import { _isComputingDerivation } from 'mobx'
import { getLocationByCode } from './api'

const Token = types.model('Token', {
  id: '',
})

export const initialUser = {
  name: '',
  username: '',
  email: '',
  createdAt: null,
  isLoggedIn: false,
  isDeleting: false,
  token: { id: '' },
}

const Geometry = types.model('Geometry', {
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
})

const Location = types.model('Location', {
  _id: types.string,
  name: types.string,
  code: types.string,
  type: types.string,
  coordinates: types.maybeNull(Geometry),
})

export const User = types
  .model('User', {
    id: types.union(types.string, types.number),
    name: types.string,
    username: types.string,
    email: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    province_code: types.maybeNull(types.string),
    collector_code: types.maybeNull(types.string),
    isLoggedIn: false,
    isDeleting: false,
    token: types.maybe(Token),
    location: types.maybe(Location),
  })
  .actions((self) => ({
    setUser(user: any) {
      applySnapshot(self, user)
      localStorage.setItem('user', JSON.stringify(user))
      setToken(user.token.id)
    },
    edit: flow(function* (values) {
      const data = {
        ...values,
        id: self.id,
      }
      const updated = yield api.edit(data)
      const store = getParent(self, 2) as Instance<typeof UserStore>
      const updatedUser = {
        id: updated._id,
        ...updated,
      }

      applySnapshot(self, updatedUser)
      store.updateUser(updatedUser)
    }),
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteUser(self.id + '')
      const store = getParent(self, 2) as Instance<typeof UserStore>
      store.removeUser(self)
    }),
    logout() {
      applySnapshot(self, { id: self.id, ...initialUser })
      localStorage.removeItem('user')
      setToken('')
    },
  }))

const UserStore = types
  .model('UserStore', {
    users: types.optional(types.array(User), []),
  })
  .actions((self) => ({
    loadUsers: flow(function* () {
      const users = yield api.getUsers()
      applySnapshot(self.users, users)
    }),
    addUser: flow(function* (user: any) {
      const data = { ...user }
      const addedUser = yield api.add(data)
      if (addedUser?.user) {
        self.users.push(addedUser?.user)
      }
    }),
    removeUser(item: any) {
      self.users.splice(self.users.indexOf(item), 1)
    },
    updateUser(item: any) {
      const index = self.users.findIndex(
        (storeUser) => storeUser.id === item.id
      )
      self.users[index] = item
    },
    searchUsers: flow(function* (query: string) {
      applySnapshot(self.users, [])
      return yield api.searchUsers(query)
    }),
  }))

let userInfo: any = { id: '', ...initialUser }
// >>> THE BUG IS AROUND HERE
const storedUser = localStorage.getItem('user')

if (storedUser) {
  userInfo = JSON.parse(storedUser)
  setToken(userInfo.token.id)
}

function setToken(token: string) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export interface IUser extends Instance<typeof User> {}
export const user = User.create(userInfo)
export const userStore = UserStore.create({
  users: [],
})
