import { Button as BaseButton } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, WasteCompany } from 'Auth/Role'

const Button = styled(BaseButton)`
  border-radius: 10px;
`

interface Props {
  onClick: () => void
}

export const AddButton = (props: Props) => {
  function onClick(e: any) {
    e.stopPropagation()
    props.onClick && props.onClick()
  }

  return componentLevelAuthorization(
    (<Button
      type="primary"
      {...props}
      onClick={onClick}
      icon={<PlusCircleOutlined />}
    >
      Add
    </Button>),
    [Admin, WasteCompany]
  )
}
