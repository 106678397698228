import { message } from "antd"

export function handleApiError(e: any) {
  console.log('error', e)
  if (e.response) {
    if (e.response.status === 401) {
      console.log('unauthorized')
      message.error('Your session is expired, please login again')
    } else {
      message.error(e.message)
    }
  } else {
    message.error(e.message)
  }
}
