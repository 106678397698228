import { CALCULATION_OPTION_TYPES } from 'Utils/Constants'
import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'

type Props = {
  onChange?: (value: SelectValue) => void
  value?: string
}

export const CalculationOptionSelector = (props: Props) => {
  function onChange(givenValue: any) {
    givenValue && props.onChange && props.onChange(givenValue.toString())
  }

  return (
    <Select value={props.value ? props.value : undefined} onChange={onChange}>
      {Object.values(CALCULATION_OPTION_TYPES).map((calculationOptions, i) => {
        return (
          <Select.Option key={i} value={calculationOptions}>
            {calculationOptions}
          </Select.Option>
        )
      })}
    </Select>
  )
}
