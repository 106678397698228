import { DeleteConfirmButton, EditButton } from 'Components'
import { Modal, Space, Table, message } from 'antd'
import { Observer, observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { IUser } from 'Models'
import { UserEdit } from './Edit/UserEdit'
import moment from 'moment'
import styled from 'styled-components'
import { Locations } from 'Auth/Role'

const Container = styled.div``

interface Props {
  users: IUser[]
}
export const UserList = observer((props: Props) => {
  const { users } = props
  const [showUserEditModal, setShowUserEditModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUser>()

  async function onDeleteClick(user: IUser) {
    try {
      await user.delete()
    } catch (e: any) {
      message.error(e.message)
    }
  }

  function onEditClick(user: IUser) {
    setSelectedUser(user)
    setShowUserEditModal(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: IUser, b: IUser) => a.name.localeCompare(b.name),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      // >>> fix later.
      // sorter: (a: IUser, b: IUser) => a.role?.localeCompare(b.role ?? ''),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Location',
      dataIndex: ['location', 'name'],
      key: 'location',
      // render: (text: any, record: any) => {
      //   return Locations.find((location) => {
      //     return location?.value === text
      //   })?.label
      // },
    },
    {
      title: 'Collector name',
      dataIndex: 'collector_code',
      key: 'collector_code',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: IUser, b: IUser) =>
        moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
      render: function render(text: string, record: any) {
        return <span>{text ? moment(text).format('YYYY-MM-DD') : ''}</span>
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: function render(text: string, record: IUser) {
        if (record.username === 'apiuser') {
          return <div></div>
        }
        return (
          <Observer>
            {() => (
              <Space>
                <EditButton onClick={() => onEditClick(record)}></EditButton>
                <DeleteConfirmButton
                  deleting={record.isDeleting}
                  onConfirmClick={() => onDeleteClick(record)}
                ></DeleteConfirmButton>
              </Space>
            )}
          </Observer>
        )
      },
    },
  ]

  return (
    <Container>
      <Table columns={columns} dataSource={users} />
      {showUserEditModal && (
        <Modal
          title="កែសម្រួលព័ត៌មានអ្នកប្រើប្រាស់ (Edit User)"
          visible={showUserEditModal}
          onCancel={() => setShowUserEditModal(false)}
          footer={[]}
          width={600}
        >
          <UserEdit
            user={selectedUser!}
            onSaveFinish={() => setShowUserEditModal(false)}
          ></UserEdit>
        </Modal>
      )}
    </Container>
  )
})
