import { AddButton, Button, DeleteAllConfirmButton, Spinner } from 'Components'
import {
  IPropertyType,
  propertyTypeStore,
} from 'Models/PropertyTypeModel/PropertyTypeModel'
import React, { useEffect, useState } from 'react'

import { Space as BaseSpace } from 'antd'
import { ImportFromExcel } from './Import/ImportFromExcel'
import Modal from 'antd/lib/modal/Modal'
import { PropertyTypeAddContainer } from './Add/PropertyTypeAddContainer'
import { PropertyTypeEditContainer } from './Edit/PropertyTypeEditContainer'
import { PropertyTypeList } from './PropertyTypeList'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { handleApiError } from 'Utils'
import { isUserAdmin, isUserMOE } from 'Auth/Authorization'
import { getLocationByCode } from 'Models/UserModel/api'
import { user } from 'Models'
import { LocationSelector } from 'Components/LocationSelector/LocationSelector'

const Container = styled.div`
  padding: 20px;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const Space = styled(BaseSpace)`
  margin-left: auto;
`

interface Props {}
export const PropertyTypePage = observer((props: Props) => {
  const { propertyTypes, selectedProvinceCode } = propertyTypeStore
  const [shouldShowAddModal, setShouldShowAddModal] = useState(false)
  const [shouldShowEditModal, setShouldShowEditModal] = useState(false)
  const [shouldShowImportModal, setShouldShowImportModal] = useState(false)
  const [deletingAll, setDeletingAll] = useState(false)
  const [selectedPropertyType, setSelectedPropertyType] =
    useState<IPropertyType>()
  const isMOE = isUserMOE()
  const [selectedProvince, setSelectedProvince] = useState(false)

  useEffect(() => {
    loadAllPropertyTypes()
  }, [])

  async function loadAllPropertyTypes() {
    try {
      await propertyTypeStore.loadAll(user.province_code)
    } catch (e) {
      handleApiError(e)
    }
  }

  async function onAddClick() {
    setShouldShowAddModal(true)
  }

  function onEditClick(propertyType: IPropertyType) {
    setSelectedPropertyType(propertyType)
    setShouldShowEditModal(true)
  }

  async function onDeleteAllClick() {
    setDeletingAll(true)
    propertyTypeStore.deleteAll().catch((e) => handleApiError(e))
    setDeletingAll(false)
  }

  const handleProvinceSelect = (value: string) => {
    propertyTypeStore.setSelectedProvinceCode(value)
    propertyTypeStore.loadAll(value)
  }

  useEffect(() => {
    propertyTypeStore.setSelectedProvinceCode(user?.province_code || '18')
  }, [user?.province_code])

  return (
    <Container>
      {propertyTypeStore.loading && <Spinner></Spinner>}

      <Row>
        <Space>
          {!!selectedProvinceCode && isUserAdmin() && !user?.province_code && (
            <LocationSelector
              defaultValue={selectedProvinceCode}
              onSelect={handleProvinceSelect}
            />
          )}
          <AddButton onClick={onAddClick}></AddButton>{' '}
          {!isMOE && (
            <Button onClick={() => setShouldShowImportModal(true)}>
              Import
            </Button>
          )}
          <DeleteAllConfirmButton
            danger
            deleting={deletingAll}
            onConfirmClick={onDeleteAllClick}
          ></DeleteAllConfirmButton>
        </Space>
      </Row>
      <PropertyTypeList
        propertyTypes={propertyTypes}
        onEditClick={onEditClick}
      ></PropertyTypeList>

      {shouldShowAddModal && (
        <Modal
          title="បង្កើតប្រភេទអចលនទ្រព្យ (Add Property Type)"
          visible={shouldShowAddModal}
          onCancel={() => setShouldShowAddModal(false)}
          footer={[]}
          width={600}
        >
          <PropertyTypeAddContainer
            onSaveFinish={() => setShouldShowAddModal(false)}
          ></PropertyTypeAddContainer>
        </Modal>
      )}

      {shouldShowEditModal && selectedPropertyType && (
        <Modal
          title="កែសម្រួលប្រភេទអចលនទ្រព្យ (Edit Property Type)"
          visible={shouldShowEditModal}
          onCancel={() => setShouldShowEditModal(false)}
          footer={[]}
          width={600}
        >
          <PropertyTypeEditContainer
            propertyType={selectedPropertyType}
            onSaveFinish={() => setShouldShowEditModal(false)}
          ></PropertyTypeEditContainer>
        </Modal>
      )}

      {shouldShowImportModal && (
        <Modal
          title="បញ្ចូលប្រភេទអចលនទ្រព្យ (Import Property Type)"
          visible={shouldShowImportModal}
          onCancel={() => setShouldShowImportModal(false)}
          footer={[]}
          width={600}
        >
          <ImportFromExcel></ImportFromExcel>
        </Modal>
      )}
    </Container>
  )
})
