export type District = {
  name: {
    km: string
    latin: string
  }
  id: string
}

const districts: District[] = [
  { name: { km: 'ឆ្លូង', latin: 'Chhloung' }, id: '1001' },
  { name: { km: 'ក្រចេះ', latin: 'Kracheh' }, id: '1002' },
  { name: { km: 'ព្រែកប្រសព្វ', latin: 'Prek Prasab' }, id: '1003' },
  { name: { km: 'សំបូរ', latin: 'Sambour' }, id: '1004' },
  { name: { km: 'ស្នួល', latin: 'Snuol' }, id: '1005' },
  { name: { km: 'ចិត្របុរី', latin: 'Chetr Borei' }, id: '1006' },
  { name: { km: 'កែវសីមា', latin: 'Kaev Seima' }, id: '1101' },
  { name: { km: 'កោះញែក', latin: 'Kaoh Nheaek' }, id: '1102' },
  { name: { km: 'អូររាំង', latin: 'Ou Reang' }, id: '1103' },
  { name: { km: 'ពេជ្រាដា', latin: 'Pech Chreada' }, id: '1104' },
  { name: { km: 'សែនមនោរម្យ', latin: 'Saen Monourom' }, id: '1105' },
  { name: { km: 'ចំការមន', latin: 'Chamkar Mon' }, id: '1201' },
  { name: { km: 'ដូនពេញ', latin: 'Doun Penh' }, id: '1202' },
  { name: { km: '៧មករា', latin: 'Prampir Meakkakra' }, id: '1203' },
  { name: { km: 'ទួលគោក', latin: 'Tuol Kouk' }, id: '1204' },
  { name: { km: 'ដង្កោ', latin: 'Dangkao' }, id: '1205' },
  { name: { km: 'មានជ័យ', latin: 'Mean Chey' }, id: '1206' },
  { name: { km: 'ឫស្សីកែវ', latin: 'Russey Keo' }, id: '1207' },
  { name: { km: 'សែនសុខ', latin: 'Saensokh' }, id: '1208' },
  { name: { km: 'ពោធិ៍សែនជ័យ', latin: 'Pur SenChey' }, id: '1209' },
  { name: { km: 'ជ្រោយចង្វារ', latin: 'Chraoy Chongvar' }, id: '1210' },
  { name: { km: 'ព្រែកព្នៅ', latin: 'Praek Pnov' }, id: '1211' },
  { name: { km: 'ច្បារអំពៅ', latin: 'Chbar Ampov' }, id: '1212' },
  { name: { km: 'បឹងកេងកង', latin: 'Boeng Keng Kang' }, id: '1213' },
  { name: { km: 'កំបូល', latin: 'Kamboul' }, id: '1214' },
  { name: { km: 'ជ័យសែន', latin: 'Chey Saen' }, id: '1301' },
  { name: { km: 'ឆែប', latin: 'Chhaeb' }, id: '1302' },
  { name: { km: 'ជាំក្សាន្ដ', latin: 'Choam Ksant' }, id: '1303' },
  { name: { km: 'គូលែន', latin: 'Kuleaen' }, id: '1304' },
  { name: { km: 'រវៀង', latin: 'Rovieng' }, id: '1305' },
  { name: { km: 'សង្គមថ្មី', latin: 'Sangkum Thmei' }, id: '1306' },
  { name: { km: 'ត្បែងមានជ័យ', latin: 'Tbaeng Mean Chey' }, id: '1307' },
  { name: { km: 'ព្រះវិហារ', latin: 'Preah Vihear' }, id: '1308' },
  { name: { km: 'បាភ្នំ', latin: 'Ba Phnum' }, id: '1401' },
  { name: { km: 'កំចាយមារ', latin: 'Kamchay Mear' }, id: '1402' },
  { name: { km: 'កំពង់ត្របែក', latin: 'Kampong Trabaek' }, id: '1403' },
  { name: { km: 'កញ្ជ្រៀច', latin: 'Kanhchriech' }, id: '1404' },
  { name: { km: 'មេសាង', latin: 'Me Sang' }, id: '1405' },
  { name: { km: 'ពាមជរ', latin: 'Peam Chor' }, id: '1406' },
  { name: { km: 'ពាមរក៍', latin: 'Peam Ro' }, id: '1407' },
  { name: { km: 'ពារាំង', latin: 'Pea Reang' }, id: '1408' },
  { name: { km: 'ព្រះស្ដេច', latin: 'Preah Sdach' }, id: '1409' },
  { name: { km: 'ព្រៃវែង', latin: 'Prey Veng' }, id: '1410' },
  { name: { km: 'ពោធិ៍រៀង', latin: 'Pur Rieng' }, id: '1411' },
  { name: { km: 'ស៊ីធរកណ្ដាល', latin: 'Sithor Kandal' }, id: '1412' },
  { name: { km: 'ស្វាយអន្ទរ', latin: 'Svay Antor' }, id: '1413' },
  { name: { km: 'បាកាន', latin: 'Bakan' }, id: '1501' },
  { name: { km: 'កណ្ដៀង', latin: 'Kandieng' }, id: '1502' },
  { name: { km: 'ក្រគរ', latin: 'Krakor' }, id: '1503' },
  { name: { km: 'ភ្នំក្រវ៉ាញ', latin: 'Phnum Kravanh' }, id: '1504' },
  { name: { km: 'ពោធិ៍សាត់', latin: 'Pursat' }, id: '1505' },
  { name: { km: 'វាលវែង', latin: 'Veal Veaeng' }, id: '1506' },
  { name: { km: 'តាលោសែនជ័យ', latin: 'Ta Lou Senchey' }, id: '1507' },
  { name: { km: 'អណ្ដូងមាស', latin: 'Andoung Meas' }, id: '1601' },
  { name: { km: 'បានលុង', latin: 'Ban Lung' }, id: '1602' },
  { name: { km: 'បរកែវ', latin: 'Bar Kaev' }, id: '1603' },
  { name: { km: 'កូនមុំ', latin: 'Koun Mom' }, id: '1604' },
  { name: { km: 'លំផាត់', latin: 'Lumphat' }, id: '1605' },
  { name: { km: 'អូរជុំ', latin: 'Ou Chum' }, id: '1606' },
  { name: { km: 'អូរយ៉ាដាវ', latin: 'Ou Ya Dav' }, id: '1607' },
  { name: { km: 'តាវែង', latin: 'Ta Veaeng' }, id: '1608' },
  { name: { km: 'វើនសៃ', latin: 'Veun Sai' }, id: '1609' },
  { name: { km: 'អង្គរជុំ', latin: 'Angkor Chum' }, id: '1701' },
  { name: { km: 'អង្គរធំ', latin: 'Angkor Thum' }, id: '1702' },
  { name: { km: 'បន្ទាយស្រី', latin: 'Banteay Srei' }, id: '1703' },
  { name: { km: 'ជីក្រែង', latin: 'Chi Kraeng' }, id: '1704' },
  { name: { km: 'ក្រឡាញ់', latin: 'Kralanh' }, id: '1706' },
  { name: { km: 'ពួក', latin: 'Puok' }, id: '1707' },
  { name: { km: 'ប្រាសាទបាគង', latin: 'Prasat Bakong' }, id: '1709' },
  { name: { km: 'សៀមរាប', latin: 'Siem Reap' }, id: '1710' },
  { name: { km: 'សូទ្រនិគម', latin: 'Soutr Nikom' }, id: '1711' },
  { name: { km: 'ស្រីស្នំ', latin: 'Srei Snam' }, id: '1712' },
  { name: { km: 'ស្វាយលើ', latin: 'Svay Leu' }, id: '1713' },
  { name: { km: 'វ៉ារិន', latin: 'Varin' }, id: '1714' },
  { name: { km: 'ព្រះសីហនុ', latin: 'Preah Sihanouk' }, id: '1801' },
  { name: { km: 'ព្រៃនប់', latin: 'Prey Nob' }, id: '1802' },
  { name: { km: 'ស្ទឹងហាវ', latin: 'Stueng Hav' }, id: '1803' },
  { name: { km: 'កំពង់សីលា', latin: 'Kampong Seila' }, id: '1804' },
  { name: { km: 'កោះរ៉ុង', latin: 'Kaoh Rung' }, id: '1805' },
  { name: { km: 'សេសាន', latin: 'Sesan' }, id: '1901' },
  { name: { km: 'សៀមបូក', latin: 'Siem Bouk' }, id: '1902' },
  { name: { km: 'សៀមប៉ាង', latin: 'Siem Pang' }, id: '1903' },
  { name: { km: 'ស្ទឹងត្រែង', latin: 'Stueng Traeng' }, id: '1904' },
  { name: { km: 'ថាឡាបរិវ៉ាត់', latin: 'Thala Barivat' }, id: '1905' },
  {
    name: { km: 'បុរីអូរស្វាយសែនជ័យ', latin: 'Borei Ou Svay Senchey' },
    id: '1906',
  },
  { name: { km: 'ចន្ទ្រា', latin: 'Chantrea' }, id: '2001' },
  { name: { km: 'កំពង់រោទិ៍', latin: 'Kampong Rou' }, id: '2002' },
  { name: { km: 'រំដួល', latin: 'Rumduol' }, id: '2003' },
  { name: { km: 'រមាសហែក', latin: 'Romeas Haek' }, id: '2004' },
  { name: { km: 'ស្វាយជ្រំ', latin: 'Svay Chrum' }, id: '2005' },
  { name: { km: 'ស្វាយរៀង', latin: 'Svay Rieng' }, id: '2006' },
  { name: { km: 'ស្វាយទាប', latin: 'Svay Teab' }, id: '2007' },
  { name: { km: 'បាវិត', latin: 'Bavet' }, id: '2008' },
  { name: { km: 'អង្គរបូរី', latin: 'Angkor Borei' }, id: '2101' },
  { name: { km: 'បាទី', latin: 'Bati' }, id: '2102' },
  { name: { km: 'បូរីជលសារ', latin: 'Borei Cholsar' }, id: '2103' },
  { name: { km: 'គីរីវង់', latin: 'Kiri Vong' }, id: '2104' },
  { name: { km: 'កោះអណ្ដែត', latin: 'Kaoh Andaet' }, id: '2105' },
  { name: { km: 'ព្រៃកប្បាស', latin: 'Prey Kabbas' }, id: '2106' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '2107' },
  { name: { km: 'ដូនកែវ', latin: 'Doun Kaev' }, id: '2108' },
  { name: { km: 'ត្រាំកក់', latin: 'Tram Kak' }, id: '2109' },
  { name: { km: 'ទ្រាំង', latin: 'Treang' }, id: '2110' },
  { name: { km: 'អន្លង់វែង', latin: 'Anlong Veaeng' }, id: '2201' },
  { name: { km: 'បន្ទាយអំពិល', latin: 'Banteay Ampil' }, id: '2202' },
  { name: { km: 'ចុងកាល់', latin: 'Chong Kal' }, id: '2203' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '2204' },
  { name: { km: 'ត្រពាំងប្រាសាទ', latin: 'Trapeang Prasat' }, id: '2205' },
  { name: { km: 'ដំណាក់ចង្អើរ', latin: "Damnak Chang'aeur" }, id: '2301' },
  { name: { km: 'កែប', latin: 'Kaeb' }, id: '2302' },
  { name: { km: 'ប៉ៃលិន', latin: 'Pailin' }, id: '2401' },
  { name: { km: 'សាលាក្រៅ', latin: 'Sala Krau' }, id: '2402' },
  { name: { km: 'តំបែរ', latin: 'Dambae' }, id: '2501' },
  { name: { km: 'ក្រូចឆ្មារ', latin: 'Krouch Chhmar' }, id: '2502' },
  { name: { km: 'មេមត់', latin: 'Memot' }, id: '2503' },
  { name: { km: 'អូររាំងឪ', latin: 'Ou Reang Ov' }, id: '2504' },
  { name: { km: 'ពញាក្រែក', latin: 'Ponhea Kraek' }, id: '2505' },
  { name: { km: 'សួង', latin: 'Suong' }, id: '2506' },
  { name: { km: 'ត្បូងឃ្មុំ', latin: 'Tboung Khmum' }, id: '2507' },
  { name: { km: 'មង្គលបូរី', latin: 'Mongkol Borei' }, id: '0102' },
  { name: { km: 'ភ្នំស្រុក', latin: 'Phnum Srok' }, id: '0103' },
  { name: { km: 'ព្រះនេត្រព្រះ', latin: 'Preah Netr Preah' }, id: '0104' },
  { name: { km: 'អូរជ្រៅ', latin: 'Ou Chrov' }, id: '0105' },
  { name: { km: 'សិរីសោភ័ណ', latin: 'Serei Saophoan' }, id: '0106' },
  { name: { km: 'ថ្មពួក', latin: 'Thma Puok' }, id: '0107' },
  { name: { km: 'ស្វាយចេក', latin: 'Svay Chek' }, id: '0108' },
  { name: { km: 'ម៉ាឡៃ', latin: 'Malai' }, id: '0109' },
  { name: { km: 'ប៉ោយប៉ែត', latin: 'Paoy Paet' }, id: '0110' },
  { name: { km: 'បាណន់', latin: 'Banan' }, id: '0201' },
  { name: { km: 'ថ្មគោល', latin: 'Thma Koul' }, id: '0202' },
  { name: { km: 'បាត់ដំបង', latin: 'Battambang' }, id: '0203' },
  { name: { km: 'បវេល', latin: 'Bavel' }, id: '0204' },
  { name: { km: 'ឯកភ្នំ', latin: 'Aek Phnum' }, id: '0205' },
  { name: { km: 'មោងឫស្សី', latin: 'Moung Ruessei' }, id: '0206' },
  { name: { km: 'រតនមណ្ឌល', latin: 'Rotonak Mondol' }, id: '0207' },
  { name: { km: 'សង្កែ', latin: 'Sangkae' }, id: '0208' },
  { name: { km: 'សំឡូត', latin: 'Samlout' }, id: '0209' },
  { name: { km: 'សំពៅលូន', latin: 'Sampov Lun' }, id: '0210' },
  { name: { km: 'ភ្នំព្រឹក', latin: 'Phnum Proek' }, id: '0211' },
  { name: { km: 'កំរៀង', latin: 'Kamrieng' }, id: '0212' },
  { name: { km: 'គាស់ក្រឡ', latin: 'Koas Krala' }, id: '0213' },
  { name: { km: 'រុក្ខគិរី', latin: 'Rukh Kiri' }, id: '0214' },
  { name: { km: 'បាធាយ', latin: 'Batheay' }, id: '0301' },
  { name: { km: 'ចំការលើ', latin: 'Chamkar Leu' }, id: '0302' },
  { name: { km: 'ជើងព្រៃ', latin: 'Cheung Prey' }, id: '0303' },
  { name: { km: 'កំពង់ចាម', latin: 'Kampong Cham' }, id: '0305' },
  { name: { km: 'កំពង់សៀម', latin: 'Kampong Siem' }, id: '0306' },
  { name: { km: 'កងមាស', latin: 'Kang Meas' }, id: '0307' },
  { name: { km: 'កោះសូទិន', latin: 'Kaoh Soutin' }, id: '0308' },
  { name: { km: 'ព្រៃឈរ', latin: 'Prey Chhor' }, id: '0313' },
  { name: { km: 'ស្រីសន្ធរ', latin: 'Srei Santhor' }, id: '0314' },
  { name: { km: 'ស្ទឹងត្រង់', latin: 'Stueng Trang' }, id: '0315' },
  { name: { km: 'បរិបូណ៌', latin: 'Baribour' }, id: '0401' },
  { name: { km: 'ជលគីរី', latin: 'Chol Kiri' }, id: '0402' },
  { name: { km: 'កំពង់ឆ្នាំង', latin: 'Kampong Chhnang' }, id: '0403' },
  { name: { km: 'កំពង់លែង', latin: 'Kampong Leaeng' }, id: '0404' },
  { name: { km: 'កំពង់ត្រឡាច', latin: 'Kampong Tralach' }, id: '0405' },
  { name: { km: 'រលាប្អៀរ', latin: "Rolea B'ier" }, id: '0406' },
  { name: { km: 'សាមគ្គីមានជ័យ', latin: 'Sameakki Mean Chey' }, id: '0407' },
  { name: { km: 'ទឹកផុស', latin: 'Tuek Phos' }, id: '0408' },
  { name: { km: 'បរសេដ្ឋ', latin: 'Basedth' }, id: '0501' },
  { name: { km: 'ច្បារមន', latin: 'Chbar Mon' }, id: '0502' },
  { name: { km: 'គងពិសី', latin: 'Kong Pisei' }, id: '0503' },
  { name: { km: 'ឱរ៉ាល់', latin: 'Aoral' }, id: '0504' },
  { name: { km: 'ឧដុង្គ', latin: 'Odongk' }, id: '0505' },
  { name: { km: 'ភ្នំស្រួច', latin: 'Phnum Sruoch' }, id: '0506' },
  { name: { km: 'សំរោងទង', latin: 'Samraong Tong' }, id: '0507' },
  { name: { km: 'ថ្ពង', latin: 'Thpong' }, id: '0508' },
  { name: { km: 'បារាយណ៍', latin: 'Baray' }, id: '0601' },
  { name: { km: 'កំពង់ស្វាយ', latin: 'Kampong Svay' }, id: '0602' },
  { name: { km: 'ស្ទឹងសែន', latin: 'Stueng Saen' }, id: '0603' },
  { name: { km: 'ប្រាសាទបល្ល័ង្គ', latin: 'Prasat Ballangk' }, id: '0604' },
  { name: { km: 'ប្រាសាទសំបូរ', latin: 'Prasat Sambour' }, id: '0605' },
  { name: { km: 'សណ្ដាន់', latin: 'Sandan' }, id: '0606' },
  { name: { km: 'សន្ទុក', latin: 'Santuk' }, id: '0607' },
  { name: { km: 'ស្ទោង', latin: 'Stoung' }, id: '0608' },
  { name: { km: 'តាំងគោក', latin: 'Taing Kouk' }, id: '0609' },
  { name: { km: 'អង្គរជ័យ', latin: 'Angkor Chey' }, id: '0701' },
  { name: { km: 'បន្ទាយមាស', latin: 'Banteay Meas' }, id: '0702' },
  { name: { km: 'ឈូក', latin: 'Chhuk' }, id: '0703' },
  { name: { km: 'ជុំគិរី', latin: 'Chum Kiri' }, id: '0704' },
  { name: { km: 'ដងទង់', latin: 'Dang Tong' }, id: '0705' },
  { name: { km: 'កំពង់ត្រាច', latin: 'Kampong Trach' }, id: '0706' },
  { name: { km: 'ទឹកឈូ', latin: 'Tuek Chhou' }, id: '0707' },
  { name: { km: 'កំពត', latin: 'Kampot' }, id: '0708' },
  { name: { km: 'កណ្ដាលស្ទឹង', latin: 'Kandal Stueng' }, id: '0801' },
  { name: { km: 'កៀនស្វាយ', latin: 'Kien Svay' }, id: '0802' },
  { name: { km: 'ខ្សាច់កណ្ដាល', latin: 'Khsach Kandal' }, id: '0803' },
  { name: { km: 'កោះធំ', latin: 'Kaoh Thum' }, id: '0804' },
  { name: { km: 'លើកដែក', latin: 'Leuk Daek' }, id: '0805' },
  { name: { km: 'ល្វាឯម', latin: 'Lvea Aem' }, id: '0806' },
  { name: { km: 'មុខកំពូល', latin: 'Mukh Kampul' }, id: '0807' },
  { name: { km: 'អង្គស្នួល', latin: 'Angk Snuol' }, id: '0808' },
  { name: { km: 'ពញាឮ', latin: 'Ponhea Lueu' }, id: '0809' },
  { name: { km: 'ស្អាង', latin: "S'ang" }, id: '0810' },
  { name: { km: 'តាខ្មៅ', latin: 'Ta Khmau' }, id: '0811' },
  { name: { km: 'បុទុមសាគរ', latin: 'Botum Sakor' }, id: '0901' },
  { name: { km: 'គិរីសាគរ', latin: 'Kiri Sakor' }, id: '0902' },
  { name: { km: 'កោះកុង', latin: 'Kaoh Kong' }, id: '0903' },
  { name: { km: 'ខេមរភូមិន្ទ', latin: 'Khemara Phoumin' }, id: '0904' },
  { name: { km: 'មណ្ឌលសីមា', latin: 'Mondol Seima' }, id: '0905' },
  { name: { km: 'ស្រែ អំបិល', latin: 'Srae Ambel' }, id: '0906' },
  { name: { km: 'ថ្មបាំង', latin: 'Thma Bang' }, id: '0907' },
]

export default districts
