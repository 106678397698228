import { isArray } from 'lodash'
import { FILE_VIEW_URL } from './Constants'

export const getFileListAsString = (filesList: any) => {

  console.log('filesList', filesList)
  if (typeof filesList === 'string') {
    return filesList.replace(`${FILE_VIEW_URL}/`, '')
  } else if(isArray(filesList)) {
    const name = filesList
      .map((file: any) => {
        return file?.response
      })
      .toString()

    return name
  } else {
    return null
  }
}

export const getFileList = (dataAsString: string = '') => {
  if (dataAsString === '') {
    return []
  }

  const data = dataAsString.split(',')
  return data?.map((item) => {
    return {
      id: Math.random(),
      name: item,
      status: 'done',
      response: item,
      url: `${FILE_VIEW_URL}/${item}`,
      thumbUrl: `${FILE_VIEW_URL}/${item}?dim=200`,
    }
  })
}

export const getImage = (name: string = '') => {
  if (name === '') {
    return ''
  }

  if (name.includes(FILE_VIEW_URL)) {
    return name
  }

  return `${FILE_VIEW_URL}/${name}`
}
