import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 5rem;
  margin: 0 5px 0 5px;
`

type Props = {
  onChange?: (value: SelectValue) => void
  disabled?: boolean
  value?: number,
  defaultValue?: number
}

export const PropertyActiveSelector = (props: Props) => {
  const { value, disabled, defaultValue } = props

  function onChange(givenValue: any) {
    Number.isInteger(givenValue) && props.onChange && props.onChange(givenValue.toString())
  }

  return (
    <StyledSelect
      style={{minWidth: 100}}
      value={value ? value : undefined}
      onChange={onChange}
      disabled={disabled}
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={true}
    >
      <Option key={4} value={4}>
        All
      </Option>
      {['អសកម្ម', 'សកម្ម'].map((isActive, i) => {
        return (
          <Option key={i} value={i}>
            {isActive}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
