import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 5rem;
  margin: 0 5px 0 5px;
`

type Props = {
  onChange?: (value: SelectValue) => void
  onClear?: () => void
  disabled?: boolean
  value?: string
  defaultValue?: string
  notAvailable?: boolean
}

export const isActiveOptions = [
  {
    value: 0,
    label: 'អសកម្ម',
  },
  {
    value: 1,
    label: 'សកម្ម',
  },
  // {
  //   value: 4,
  //   label: 'អតិថិជនស៊ីនទ្រី',
  // },
  // {
  //   value: 5,
  //   label: 'មិនទាន់បង់លុយ',
  // },
  {
    value: 6,
    label: 'កំណត់ហេតុ',
  },
  {
    value: 7,
    label: 'មិនសហការ',
  },
  {
    value: 8,
    label: 'អតិថិជនបិទ',
  },
  {
    value: 9,
    label: 'ទីតាំងបង់រួម',
  },
  {
    value: 10,
    label: 'អនុគ្រោះ',
  },
]

export const IsActiveSelector = (props: Props) => {
  const { value, disabled, defaultValue, notAvailable = false } = props

  function onChange(givenValue: any) {
    props.onChange && props.onChange(givenValue)
  }

  function onClear() {
    props.onClear && props.onClear()
  }

  return (
    <StyledSelect
      style={{ minWidth: 200 }}
      placeholder="Please select"
      value={value ? value : undefined}
      onChange={onChange}
      onClear={onClear}
      disabled={disabled}
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={true}
      allowClear
    >
      {notAvailable && (
        <Option key={null} value="NULL">
          N/A
        </Option>
      )}
      {isActiveOptions.map((option) => {
        return (
          <Option key={option?.value} value={option?.value}>
            {option?.label}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
