import React, { useEffect } from 'react'
import { Select, message } from 'antd'

import { Spinner } from 'Components'
import { customImportStore } from 'Models'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 250px;
`

const Center = styled.div`
  width: 250px;
  display: flex;
  justify-content: center;
`

interface Prop {
  onCustomImportIdSelected?: (propertyId: string) => void
}

export const CustomImportSelector = observer((props: Prop) => {
  const { onCustomImportIdSelected } = props
  const selectedValue = customImportStore.selectedCustomImport
    ? customImportStore.selectedCustomImport.id + ''
    : undefined

  useEffect(() => {
    try {
      loadCustomImport()
    } catch (e: any) {
      console.log('error', e)
      message.error('Error: ', e)
    }
  }, [])

  async function loadCustomImport() {
    customImportStore.loadCustomImport()
  }

  function onChange(value: any) {
    value &&
      onCustomImportIdSelected &&
      onCustomImportIdSelected(value.toString())
    value && customImportStore.setSelectedCustomImports(value.toString())
  }

  if (customImportStore.loading) {
    return (
      <Center>
        <Spinner size="middle"></Spinner>
      </Center>
    )
  }

  return (
    <StyledSelect value={selectedValue} onChange={onChange}>
      {customImportStore.customImports.map((customImport) => {
        return (
          <Option key={customImport.id} value={customImport.id + ''}>
            {`${customImport.name}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
})
