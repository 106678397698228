import {
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Space,
  Switch,
  Table,
} from 'antd'
import { DeleteConfirmButton, EditButton } from 'Components'
import { Observer, observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'

import { IProperty, propertyStore } from 'Models/PropertyModel'
import { ReactComponent as MapMarker } from './assets/map-marker.svg'
import { PropertyMap } from './PropertyMap'
import moment from 'moment'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'
import ConfirmationWithProgressModal from 'Components/ConfirmationWithProgressModal/ConfirmationWithProgressModal'
import Bluebird from 'bluebird'
import { ColumnsType } from 'antd/lib/table/interface'
import { isUserMOE } from 'Auth/Authorization'

const Container = styled.div``

const UpdateAllBox = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 1rem;
`

interface Props {
  properties: IProperty[]
}

export const PropertyListWithEdit = observer((props: Props) => {
  const [shouldShowEditPropertyModal, setShouldShowEditPropertyModal] =
    useState(false)

  const [shouldShowEditAllPropertyModal, setShouldShowEditAllPropertyModal] =
    useState(false)

  const [propertiesToUpdate, setPropertiesToUpdate] = useState<
    Record<string, IProperty>
  >({})

  const [addedCount, setAddedCount] = useState(0)
  const [totalRecordCount, setTotalRecordCount] = useState(1)

  const [shouldShowPropertyMap, setShouldShowPropertyMap] = useState(false)

  const [selectedProperty, setSelectedProperty] = useState<IProperty>()

  const [currentProperties, setCurrentProperties] = useState<IProperty[]>()

  const isMOE = isUserMOE()

  useEffect(() => {
    setCurrentProperties(props.properties)
  }, [])

  function onEditClick(property: IProperty) {
    setAddedCount(0)
    setTotalRecordCount(1)
    setSelectedProperty(property)
    setShouldShowEditPropertyModal(true)
  }

  function onDeleteClick(property: IProperty) {
    const storeObj: IProperty | undefined = propertyStore.properties.find(
      (prop) => {
        return prop.OBJECTID === property.OBJECTID
      }
    )
    storeObj?.delete()
    setCurrentProperties((prevProperties) => {
      return prevProperties?.filter((prevProp) => {
        return prevProp.OBJECTID !== property.OBJECTID
      })
    })
  }

  function onMapButtonClick(property: IProperty) {
    setSelectedProperty(property)
    setShouldShowPropertyMap(true)
  }

  function addEditProperty(property: IProperty) {
    const id = property.OBJECTID
    setPropertiesToUpdate({
      ...propertiesToUpdate,
      [id]: property,
    })
  }

  function changeCurrentProperties<T>(
    inputProperty: IProperty,
    key: string,
    value: T
  ) {
    const newProperties = currentProperties?.map((property) => {
      // *** IObj cannot be indexed, so cannot make a common function ***
      if (property.OBJECTID === inputProperty.OBJECTID) {
        const newProperty: IProperty = {
          ...inputProperty,
          [key]: value,
        }
        addEditProperty(newProperty)
        return newProperty
      }
      return property
    })

    setCurrentProperties(newProperties)
  }

  function onActivateDateChange(
    date: moment.Moment | null,
    dateString: string,
    value: IProperty
  ) {
    // *** will not call edit here ***
    if (date) {
      changeCurrentProperties(value, 'ActivateDate', date.valueOf())
    }
  }

  function onLastBilledDateChange(
    date: moment.Moment | null,
    dateString: string,
    value: IProperty
  ) {
    // *** will not call edit here ***
    if (date) {
      changeCurrentProperties(value, 'LastBilledDate', date.valueOf())
    }
  }

  function onFeeChange(
    e: React.ChangeEvent<HTMLInputElement>,
    value: IProperty
  ) {
    if (e.target.value) {
      const fee = parseFloat(e.target.value)
      changeCurrentProperties(value, 'CollectionFee', fee)
    }
  }

  function onIsActiveChange(value: IProperty) {
    changeCurrentProperties(value, 'IsActive', !value.IsActive)
  }

  async function editSeletectedProperty() {
    if (selectedProperty) {
      // get the object id.
      const id = selectedProperty.OBJECTID
      // find the store object.
      const storeObj: IProperty | undefined = propertyStore.properties.find(
        (property) => {
          return property.OBJECTID === id
        }
      )
      // call the api.
      try {
        await storeObj?.edit(selectedProperty)
      } catch (e: any) {
        console.log(e)
        message.warning(e)
      }
      // remove from the to edit List.

      setPropertiesToUpdate((prevProperties) => {
        const newSet = { ...prevProperties }
        delete newSet[id]
        return newSet
      })
      setAddedCount(1)
      setShouldShowEditPropertyModal(false)
    }
  }

  function ShowEditAllPropertyModal() {
    setAddedCount(0)
    setTotalRecordCount(Object.keys(propertiesToUpdate).length)
    setShouldShowEditAllPropertyModal(true)
  }

  async function updateAllEditedProperties() {
    console.log('update all')
    try {
      await Bluebird.map(
        Object.values(propertiesToUpdate),
        async (property) => {
          // fine the store obj.
          const storeObj: IProperty | undefined = propertyStore.properties.find(
            (prop) => {
              return prop.OBJECTID === property.OBJECTID
            }
          )
          // call the api.
          await storeObj?.edit(property)
          // update count.
          setAddedCount(addedCount + 1)
        },
        {
          concurrency: 5,
        }
      )
    } catch (e: any) {
      console.log(e)
      message.warning(e)
    }
    setShouldShowEditAllPropertyModal(false)
    setPropertiesToUpdate({})
  }

  const columns: ColumnsType<IProperty> = [
    {
      title: 'ID',
      dataIndex: 'OBJECTID',
      key: 'OBJECTID',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Owner',
      dataIndex: 'OwnerName',
      key: 'OwnerName',
    },
    {
      title: 'Contact',
      dataIndex: 'OwnerPhone',
      key: 'OwnerPhone',
    },
    {
      title: 'Address',
      dataIndex: 'StreetNo',
      key: 'StreetNo',
      render: function render(text: string, record: IProperty) {
        return (
          <span>{`${record.HouseNo} ${record.StreetNo} ${record.Sangkat} ${record.City}`}</span>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'CreateDate',
      key: 'CreateDate',
      render: function render(text: string, record: IProperty) {
        return <span>{text ? moment(text).format('YYYY-MM-DD') : ''}</span>
      },
    },
    {
      title: 'Start service at',
      dataIndex: 'ActivateDate',
      key: 'ActivateDate',
      render: function render(text: string, record: IProperty) {
        const initialVal = text ? moment(text) : null
        return (
          <DatePicker
            value={initialVal}
            onChange={(date, dateString) =>
              onActivateDateChange(date, dateString, record)
            }
            disabled={isMOE}
          />
        )
      },
    },
    {
      title: 'Last Billed',
      dataIndex: 'LastBilledDate',
      key: 'LastBilledDate',
      render: function render(text: string, record: IProperty) {
        const initialVal = text ? moment(text) : null
        return (
          <DatePicker
            value={initialVal}
            onChange={(date, dateString) =>
              onLastBilledDateChange(date, dateString, record)
            }
            disabled={isMOE}
          />
        )
      },
    },
    {
      title: 'Fee',
      dataIndex: 'CollectionFee',
      key: 'CollectionFee',
      render: function render(text: string, record: IProperty) {
        return (
          <Input
            value={thousandSeparator(text)}
            type="number"
            onChange={(e) => onFeeChange(e, record)}
            disabled={isMOE}
          />
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'IsActive',
      key: 'IsActive',
      render: function render(text: string, record: IProperty) {
        return (
          <Switch
            checked={record.IsActive ? true : false}
            onClick={() => onIsActiveChange(record)}
            disabled={isMOE}
          />
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: function render(text: string, record: IProperty) {
        return (
          <Observer>
            {() => (
              <Space>
                <EditButton onClick={() => onEditClick(record)}></EditButton>
                <Button
                  onClick={() => onMapButtonClick(record)}
                  shape="circle"
                  icon={
                    <MapMarker style={{}} height={15} width={15}></MapMarker>
                  }
                ></Button>
                <DeleteConfirmButton
                  deleting={record.isDeleting}
                  onConfirmClick={() => onDeleteClick(record)}
                ></DeleteConfirmButton>
              </Space>
            )}
          </Observer>
        )
      },
    },
  ]
  return (
    <Container>
      <Table columns={columns} dataSource={currentProperties?.slice()} />
      {Object.keys(propertiesToUpdate).length !== 0 && (
        <UpdateAllBox>
          <Button
            type="primary"
            shape="round"
            onClick={ShowEditAllPropertyModal}
          >
            Update All
          </Button>
        </UpdateAllBox>
      )}
      {shouldShowEditPropertyModal && (
        <Modal
          title="រក្សាទុកការកែសម្រួលអចលនទ្រព្យ? (Save Edit Property?)"
          visible={shouldShowEditPropertyModal}
          onCancel={() => setShouldShowEditPropertyModal(false)}
          footer={[]}
          width={600}
        >
          <ConfirmationWithProgressModal
            header="Updating"
            statusText="updated"
            addedCount={addedCount}
            totalRecordCount={totalRecordCount}
            onCancelButtonClick={() => setShouldShowEditPropertyModal(false)}
            onConfirmButtonClick={editSeletectedProperty}
          />
        </Modal>
      )}

      {shouldShowEditAllPropertyModal && (
        <Modal
          title="រក្សាទុកការកែសម្រួលអចលនទ្រព្យទាំងអស់? (Save Edit Properties?)"
          visible={shouldShowEditAllPropertyModal}
          onCancel={() => setShouldShowEditAllPropertyModal(false)}
          footer={[]}
          width={600}
        >
          <ConfirmationWithProgressModal
            header="Updating All"
            statusText="updated"
            addedCount={addedCount}
            totalRecordCount={totalRecordCount}
            onCancelButtonClick={() => setShouldShowEditPropertyModal(false)}
            onConfirmButtonClick={updateAllEditedProperties}
          />
        </Modal>
      )}

      {shouldShowPropertyMap && selectedProperty && (
        <Modal
          title="ផែនទីអចលនទ្រព្យ (Property Map)"
          visible={shouldShowPropertyMap}
          onCancel={() => setShouldShowPropertyMap(false)}
          footer={[]}
          width={window.innerWidth - 300}
        >
          <PropertyMap properties={[selectedProperty]}></PropertyMap>
        </Modal>
      )}
    </Container>
  )
})
