import { Form, message } from 'antd'
import { IKSWM, kswmStore } from 'Models/PropertyDataGatheringModel/KswmModel'
import React, { useState } from 'react'

import { Coordinate } from 'Models/Types'
import { KswmForm } from '../Form/KswmForm'
import styled from 'styled-components'

const Container = styled.section``

interface Props {
  coordinate: Coordinate
  onSaveFinish?: (record: IKSWM) => void
}

export const KswmAdd = (props: Props) => {
  const { coordinate } = props
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>('')

  const initialValues = {}

  async function onSubmit(values: any) {
    const { lat: y, lng: x } = coordinate
    const data = {
      ...values,
      geometry: { x, y },
      Photo: uploadedPhotoUrl,
    }
    setSaving(true)
    try {
      const addedRecord = await kswmStore.add(data)
      setSaving(false)
      props.onSaveFinish && props.onSaveFinish(addedRecord)
    } catch (e: any) {
      setSaving(false)
      message.error(e.message)
    }
  }

  function onPhotoUploaded(url: string | undefined) {
    setUploadedPhotoUrl(url ?? '')
  }

  return (
    <Container>
      <KswmForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
        onFileUploaded={onPhotoUploaded}
        uploadedPhotoUrl={uploadedPhotoUrl}
      ></KswmForm>
    </Container>
  )
}
