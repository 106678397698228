import { isAccounting } from 'Auth/Authorization'
import { Button, Form, InputNumber as InputNumberBase, Radio } from 'antd'

import { DatePicker } from 'antd'
import { FormInstance } from 'antd/lib/form'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'

const InputNumber = styled(InputNumberBase)`
  width: 135px;
`

interface BillFormProp {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
  isEdit?: boolean
  initialValues?: any
}

const paidOptions = [
  {
    label: 'No',
    value: 0,
  },
  {
    label: 'Yes',
    value: 1,
  },
]

export const BillForm = (props: BillFormProp) => {
  const { onSubmit, saving, form, initialValues, isEdit = false } = props
  const [paid, setPaid] = useState(initialValues?.Paid)

  const isPaid = !!initialValues.Paid
  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 4 }}
      initialValues={initialValues}
    >
      <Form.Item
        label="For Month"
        name="BillForDate"
        rules={[{ required: true, message: 'Required' }]}
      >
        <DatePicker
          disabled={isPaid}
          picker="month"
          placeholder="Select month"
          // disabledDate={(current) => {
          //   let customDate = moment().subtract(2, 'months').format('DD-MM-YYYY')
          //   return current && current < moment(customDate, 'DD-MM-YYYY')
          // }}
        />
      </Form.Item>

      <Form.Item
        label="Current Fee"
        name="CurrentFee"
        rules={[{ required: false, message: 'Required' }]}
      >
        <InputNumber
          disabled
          min={0}
          formatter={(value) =>
            `៛ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => {
            if (value) {
              return value.replace(/៛\s?|(,*)/g, '')
            }
            return ''
          }}
        />
      </Form.Item>

      <Form.Item
        label="Amount"
        name="Amount"
        rules={[{ required: true, message: 'Required' }]}
      >
        <InputNumber
          disabled={isPaid || isAccounting() || isEdit}
          min={0}
          formatter={(value) =>
            `៛ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => {
            if (value) {
              return value.replace(/៛\s?|(,*)/g, '')
            }
            return ''
          }}
        />
      </Form.Item>

      <Form.Item
        label="Paid"
        name="Paid"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Radio.Group
          disabled={!isEdit}
          onChange={(event) => {
            setPaid(event.target.value)
          }}
          options={paidOptions}
        />
      </Form.Item>

      <Form.Item
        label="Paid Date"
        name="PaidDate"
        rules={[{ required: paid, message: 'Required' }]}
      >
        <DatePicker disabled={!isEdit} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
