import {
  Button,
  Form,
  Input,
  InputNumber as InputNumberBase,
  Switch,
  DatePicker,
  Radio,
} from 'antd'

import { FormInstance } from 'antd/lib/form'
import { IPropertyType } from 'Models/PropertyTypeModel/PropertyTypeModel'
import { ImagesUpload, ImageUpload, PropertyTypeSelector } from 'Components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { CALCULATION_OPTION_TYPES, FILE_SERVER_URL } from 'Utils/Constants'
import { validateIsNumber, validateNumberInput } from 'Utils/Form'
import { hasPermission } from 'Auth/Authorization'
import { showAllPropertyField } from 'Auth/Role'
import { BusinessStatusSelector } from 'Components/BusinessStatusSelector/BusinessStatusSelector'
import { CollectorSelector } from 'Components/CollectorSelector'
import { IsActiveSelector } from 'Components/IsActiveSelector/IsActiveSelector'

const { TextArea } = Input

const InputNumber = styled(InputNumberBase)`
  width: 135px;
`
interface Props {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
  initialValues?: any
  onPhotoUploaded: (url: string | undefined) => void
  uploadedPhotoUrl: string
}

export const PropertyForm = (props: Props) => {
  const {
    form,
    saving,
    onSubmit,
    initialValues,
    onPhotoUploaded,
    uploadedPhotoUrl,
  } = props

  const [selectedPropertyType, setSelectedPropertyType] =
    useState<IPropertyType>()
  const [requiredCalculationOption, setRequiredCalculationOption] = useState<
    string[]
  >([])
  const CALCULATION_OPTION_TYPES_INVERSE = Object.fromEntries(
    Object.entries(CALCULATION_OPTION_TYPES).map((obj) => obj.reverse())
  )
  const propertyTypeValues = Object.values(CALCULATION_OPTION_TYPES)
  const propertyTypeKeys = Object.keys(CALCULATION_OPTION_TYPES)
  const [isActive, setIsActive] = useState(initialValues?.IsActive)

  // *** add useState of minimun count
  // useEffect(() => {
  //   if (selectedPropertyType) {
  //     const labels = getLabel(selectedPropertyType.name_khmer ?? '')
  //     setMutatableLabels(labels)
  //   }
  // }, [selectedPropertyType])

  function getInputValues(nameList: string[]) {
    if (!form) return 0
    const valuesObj: object = form.getFieldsValue(
      nameList.map((name) => CALCULATION_OPTION_TYPES_INVERSE[name])
    )

    return Object.values(valuesObj).reduce((prevVal, curVal) => {
      return curVal ? prevVal + curVal : prevVal + 0
    }, 0)
  }

  function onPropertyTypeSelected(propertyType: IPropertyType) {
    // also need a way to calculate the current fee.
    const defaultFee = 0
    setSelectedPropertyType(propertyType)
    let requireCals: string[] = []
    for (const calOption of propertyType.calculationOptions) {
      if (calOption.name) requireCals.push(calOption.name)
    }
    setRequiredCalculationOption(requireCals)

    const totalInput = getInputValues(requireCals)
    if (totalInput > 0) {
      // there should be a better then passing propertyType here.
      onFeeCalculate(propertyType)
    } else {
      form?.setFieldsValue({
        DefaultFee: defaultFee,
        CollectionFee: defaultFee,
      })
    }
  }

  function onPropertyTypeLoad(propertyType: IPropertyType) {
    // also need a way to calculate the current fee.
    const fee =
      initialValues?.CollectionFee ?? propertyType.getTotalDefaultPrice
    setSelectedPropertyType(propertyType)
    let requireCals: string[] = []
    for (const calOption of propertyType.calculationOptions) {
      if (calOption.name) requireCals.push(calOption.name)
    }
    setRequiredCalculationOption(requireCals)

    const totalInput = getInputValues(requireCals)
    if (totalInput > 0 && !initialValues?.CollectionFee) {
      // there should be a better then passing propertyType here.
      onFeeCalculate(propertyType)
    } else {
      form?.setFieldsValue({
        DefaultFee: fee, // >>> incorrect, need use propertyType.getTotalDefaultPrice when it is fixed.
        CollectionFee: fee,
      })
    }
  }

  function isInputNeeded(input: string) {
    return requiredCalculationOption.includes(input)
  }

  function onFeeCalculate(propertyType?: IPropertyType) {
    // also bad coding.
    if (!(selectedPropertyType || propertyType)) return

    if (selectedPropertyType?.fixedFee) {
      form?.setFieldsValue({
        DefaultFee: selectedPropertyType?.fixedFee,
        CollectionFee: selectedPropertyType?.fixedFee,
      })

      return
    }

    // I know this bad but there must be a way to update useState in time.
    const calculationOptions = propertyType
      ? propertyType.calculationOptions
      : selectedPropertyType!.calculationOptions

    let total = 0

    for (const calculationOption of calculationOptions) {
      if (!calculationOption.name) continue
      const index = propertyTypeValues.indexOf(calculationOption.name)
      if (index === -1) continue
      let amount: number | undefined = form?.getFieldValue(
        propertyTypeKeys[index]
      )
      if (amount === undefined) continue

      // check the maximum requirement.
      if (
        calculationOption.maximumUnit !== undefined &&
        amount > calculationOption.maximumUnit
      ) {
        amount = calculationOption.maximumUnit
      }

      // update total.
      if (calculationOption.unitPrice)
        total +=
          amount *
          (calculationOption.unitPrice + (calculationOption.landfillPrice || 0))
      console.log(
        'calculationOption.landfillPrice',
        calculationOption?.landfillPrice
      )
    }

    // set the default fee.
    form?.setFieldsValue({
      DefaultFee: total,
      CollectionFee: total,
    })
  }

  const handleBusinessStatusSearch = (value: any) => {
    console.log('value', value)
  }

  async function handleClearStatus() {
    console.log('value')
  }

  console.log('initialValues -> ', initialValues)

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 4 }}
      initialValues={initialValues}
    >
      <Form.Item label="Photo">
        <ImageUpload
          onFileUploaded={onPhotoUploaded}
          uploadedPhotoUrl={uploadedPhotoUrl}
        ></ImageUpload>
      </Form.Item>

      <ImagesUpload
        actionUrl={FILE_SERVER_URL}
        name="AttachmentPhotos"
        label="Attachments"
      />

      <ImagesUpload
        actionUrl={FILE_SERVER_URL}
        name="ContractPhotos"
        label="Contract"
      />

      <Form.Item
        label="Property Name"
        name="Name"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Citizen Name"
        name="OwnerName"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Citizen Phone"
        name="OwnerPhone"
        rules={[
          { required: false, message: 'Required' },
          {
            validator: (_, value) => {
              if (value) {
                return !value.includes(' ')
                  ? Promise.resolve()
                  : Promise.reject(new Error('No spaces allowed'))
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input></Input>
      </Form.Item>

      {hasPermission(showAllPropertyField) && (
        <Form.Item
          label="Type"
          name="Type"
          rules={[{ required: true, message: 'Required' }]}
        >
          <PropertyTypeSelector
            onLoad={onPropertyTypeLoad}
            onPropertyTypeSelected={onPropertyTypeSelected}
          ></PropertyTypeSelector>
        </Form.Item>
      )}

      <Form.Item
        label="Status"
        name="IsActive"
        rules={[{ required: false, message: 'Required' }]}
      >
        <IsActiveSelector />
      </Form.Item>

      <Form.Item
        label="Business status"
        name="BusinessStatus"
        rules={[{ required: false, message: 'Required' }]}
      >
        <BusinessStatusSelector />
      </Form.Item>

      <Form.Item
        label="Collector"
        name="Collector"
        rules={[{ required: false, message: 'Required' }]}
      >
        <CollectorSelector />
      </Form.Item>

      {/* ================ */}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Bed) && (
          <Form.Item label="Bed" name="Bed" rules={[validateIsNumber]}>
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.BuildingCount) && (
          <Form.Item
            label="Buildings"
            name="BuildingCount"
            rules={[validateIsNumber]}
          >
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Businesses) && (
          <Form.Item
            label="Businesses"
            name="Businesses"
            rules={[validateIsNumber]}
          >
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.BuildingSize) && (
          <Form.Item
            label="Building Size"
            name="BuildingSize"
            rules={[validateIsNumber]}
          >
            <InputNumber
              type="number"
              // min={selectedPropertyType?.minimumUnit ?? 1}
              onChange={() => onFeeCalculate()}
            />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Cafes) && (
          <Form.Item label="Cafes" name="Cafes" rules={[validateIsNumber]}>
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Capacity) && (
          <Form.Item
            label="Capacity"
            name="Capacity"
            rules={[validateIsNumber]}
          >
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Chairs) && (
          <Form.Item label="Chairs" name="Chairs" rules={[validateIsNumber]}>
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Floors) && (
          <Form.Item label="Floors" name="Floors" rules={[validateIsNumber]}>
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Gym) && (
          <Form.Item label="Gym" name="Gym" rules={[validateIsNumber]}>
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Land_Size) && (
          <Form.Item
            label="Land Size"
            name="Land_Size"
            rules={[validateIsNumber]}
          >
            <InputNumber
              type="number"
              // min={selectedPropertyType?.minimumUnit ?? 1}
              onChange={() => onFeeCalculate()}
            />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Marts) && (
          <Form.Item label="Marts" name="Marts" rules={[validateIsNumber]}>
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Restaurants) && (
          <Form.Item
            label="Restaurants"
            name="Restaurants"
            rules={[validateIsNumber]}
          >
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.Room) && (
          <Form.Item label="Room" name="Room" rules={[validateIsNumber]}>
            <InputNumber type="number" onChange={() => onFeeCalculate()} />
          </Form.Item>
        )}

      {hasPermission(showAllPropertyField) &&
        isInputNeeded(CALCULATION_OPTION_TYPES.TableCount) && (
          <Form.Item
            label="Tables"
            name="TableCount"
            rules={[validateIsNumber]}
          >
            <InputNumber
              type="number"
              // min={selectedPropertyType?.minimumUnit ?? 1}
              onChange={() => onFeeCalculate()}
            />
          </Form.Item>
        )}

      {/* ================ */}

      <Form.Item
        label="Default Fee"
        name="DefaultFee"
        rules={[validateIsNumber]}
      >
        <InputNumber disabled type="number" min={0} />
      </Form.Item>

      <Form.Item label="Old Fee" name="OldFee" rules={[validateIsNumber]}>
        <InputNumber type="number" min={0} />
      </Form.Item>

      {hasPermission(showAllPropertyField) && (
        <>
          <Form.Item
            label="Fee"
            name="CollectionFee"
            rules={validateNumberInput}
          >
            <InputNumber type="number" min={0} />
          </Form.Item>
          <Form.Item
            label="Final Fee (Discounted fee)"
            name="DiscountedFee"
            rules={[validateIsNumber]}
          >
            <InputNumber type="number" min={0} />
          </Form.Item>
        </>
      )}

      {/* <Form.Item
        label="Active"
        name="IsActive"
        valuePropName="checked"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Switch></Switch>
      </Form.Item> */}

      {/* <Form.Item
        name="IsActive"
        label="Status"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Radio.Group
          onChange={(e) => {
            console.log('e.target.checked', e.target.checked)
            setIsActive(e.target.value)
          }}
        >
          <Radio value={0}>អសកម្ម</Radio>
          <Radio value={1}>សកម្ម</Radio>
          <Radio value={2}>កំពុងចរចារ</Radio>
        </Radio.Group>
      </Form.Item> */}

      {/* <Form.Item
        label="Suspense"
        name="IsSuspense"
        valuePropName="checked"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Switch></Switch>
      </Form.Item>
      <Form.Item
        label="Is Close"
        name="Isclosed"
        valuePropName="checked"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Switch></Switch>
      </Form.Item> */}

      {isActive === 2 && (
        <Form.Item name="IsActiveNote">
          <TextArea
            className="no-label"
            rows={4}
            placeholder="សំគាល់ស្ថានភាព"
          />
        </Form.Item>
      )}

      <Form.Item
        label="Start service at"
        name="ActivateDate"
        rules={[{ required: false, message: 'Required' }]}
      >
        <DatePicker></DatePicker>
      </Form.Item>

      <Form.Item
        label="Last Billed Date"
        name="LastBilledDate"
        rules={[{ required: false, message: 'Required' }]}
      >
        <DatePicker></DatePicker>
      </Form.Item>

      <Form.Item
        label="House NO"
        name="HouseNo"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Street"
        name="StreetNo"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Village"
        name="Village"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Sangkat"
        name="Sangkat"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Khan"
        name="Khan"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="City"
        name="City"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item label="VAT TIN" name="VATTIN" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item name="Note" label="ចំណាំ">
        <TextArea rows={4} placeholder="ចំណាំ" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
