import axios, { CancelTokenSource } from 'axios'

import { environment } from 'environment'
const { apiBaseUrl } = environment

export interface PropertyQuery {
  geometry?: string
  distant?: number
  calculateBalance?: boolean
}

export const getNearbyRecords = async (query: PropertyQuery) => {
  const url = `${apiBaseUrl}/api/kswm/query?geometryType=esriGeometryPoint&units=esriSRUnit_Meter&geometry=${query.geometry}&distance=${query.distant}`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

let searchRequest: CancelTokenSource
export const searchBusiness = async (query: string) => {
  if (searchRequest) {
    searchRequest.cancel()
  }

  searchRequest = axios.CancelToken.source()
  const url = `${apiBaseUrl}/api/kswm/query?${encodeURI(query)}`
  const result = await axios.get(url, { cancelToken: searchRequest.token })
  if (!result) {
    return []
  }
  if (result.data.error) {
    console.log('result.data.error: ', result.data.error)
    throw new Error('error' + result.data.error)
  }
  return result.data
}

export const edit = async (values: any) => {
  const url = `${apiBaseUrl}/api/kswm/patch`
  let data: any = {
    features: {
      attributes: values,
    },
  }

  if (values.geometry) {
    data.features.geometry = values.geometry
  }
  const response = await axios.patch(url, data)
  const { error } = response.data
  if (error) {
    throw new Error(error)
  }
  const success = response.data.updateResults[0].success

  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const deleteById = async (id: string) => {
  const url = `${apiBaseUrl}/api/kswm/delete/${id}`
  const response = await axios.delete(url)
  const success = response.data.deleteResults[0].success
  if (!success) {
    throw new Error('Failed to delete')
  }
  return response.data
}

export const add = async (data: any) => {
  const url = `${apiBaseUrl}/api/kswm/add`
  const response = await axios.post(url, data)
  const success = response.data.addResults[0].success
  if (!success) {
    throw new Error('Failed to add')
  }
  return response.data
}
