import { AddedBySearchInput } from 'Components/AddedBySearchInput/AddedBySearchInput'
import { Button, DatePicker, message, Modal, Space, Tag } from 'antd'
import { Spinner } from 'Components'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { handleApiError } from 'Utils'
import { userStore } from 'Models'
import { getReportDaily } from './api'
import moment from 'moment'
import { monthOnlyFormat } from 'Utils/Date'
import _ from 'lodash'
import { INVOICE_SERVER_URL } from 'Utils/Constants'
import InvoiceLogsTable from './Tables/InvoiceLogsTable'

const Container = styled.div`
  padding: 20px;
`

const getMonth = (date: any) => {
  return moment(date).add(1, 'd').format(monthOnlyFormat)
}

async function onDownloadReportClick(userId: string, date: string) {
  const url = `${INVOICE_SERVER_URL}/generate-daily-report?userId=${userId}&date=${date}`
  var link = document.createElement('a')
  link.setAttribute('download', `DailyReport-${userId}-${date}.pdf`)
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const renderTags = (arr?: any[]) => {
  return (
    <div style={{ marginTop: 10 }}>
      {arr?.map((p: any, index: any) => (
        <Tag style={{ margin: '5px 2px' }} key={index}>
          {p}
        </Tag>
      ))}
    </div>
  )
}

export const DailyReportPage = observer(() => {
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [agent, setAgent] = useState(null)
  const [date, setDate] = useState(moment())
  const [result, setResult] = useState<any>([])

  const orderedActions = _.reduce(
    result?.actions,
    (result, action) => {
      if (_.startsWith(action?._id, 'CREATE')) {
        action?._id === 'CREATE'
          ? result.create.unshift(action)
          : result.create.push(action)
      } else {
        action?._id === 'UPDATE'
          ? result.update.unshift(action)
          : result.update.push(action)
      }
      return result
    },
    { create: [] as any[], update: [] as any[] }
  )

  useEffect(() => {
    async function loadUsers() {
      setLoading(true)
      try {
        await userStore.loadUsers()
      } catch (e: any) {
        handleApiError(e)
      }
      setLoading(false)
    }

    loadUsers()
  }, [])

  const handleAgentSelected = async (value: any) => {
    setAgent(value)
    setFetching(true)
    const res = await getReportDaily(value, date.format('YYYY-MM-DD'))
    setFetching(false)
    setResult(res)
  }

  const renderAction = (action: any) => {
    if (action?._id === 'CREATE') {
      return (
        <div key={action?._id}>
          <div>
            [Create property] បានបង្កើតទីតាំងចំនួន ({action?.properties?.length}
            )
          </div>
          {renderTags(action?.properties)}
        </div>
      )
    } else if (action?._id === 'UPDATE') {
      return (
        <div key={action?._id}>
          <div>
            [Edit property] បានកែប្រែទិន្នន័យទីតាំងចំនួន (
            {action?.properties?.length})
          </div>
          {renderTags(action?.properties)}
        </div>
      )
    }

    return (
      <div key={action?._id}>
        <div style={{ marginTop: 15 }}>
          {_.startsWith(action?._id, 'CREATE')
            ? 'បង្កើតជាមួយស្ថានភាព '
            : 'បានប្ដូរស្ថានភាពទៅជា '}
          [{action?._id}] ចំនួន ({action?.properties?.length}) : PropertyID{' '}
        </div>
        {renderTags(action?.properties)}
      </div>
    )
  }

  const handleDateChange = async (value: any) => {
    setDate(value)
    setFetching(true)
    const res = await getReportDaily(agent, value.format('YYYY-MM-DD'))
    setFetching(false)
    setResult(res)
  }

  return (
    <Container>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <div>
          <Space direction="horizontal">
            <AddedBySearchInput onIdSelected={handleAgentSelected} />
            <DatePicker
              placeholder="Select date"
              onChange={handleDateChange}
              disabledDate={(current) => {
                let customDate = moment().add(1, 'd').format('DD-MM-YYYY')
                return current && current > moment(customDate, 'DD-MM-YYYY')
              }}
            />
            <Button
              type="primary"
              disabled={loading || fetching || !agent}
              onClick={() => {
                onDownloadReportClick(
                  agent!,
                  moment(date).format('YYYY-MM-DD')!
                )
              }}
            >
              Download Report
            </Button>
          </Space>
          {fetching ? (
            <div style={{ marginTop: 30 }}>
              <Spinner></Spinner>
            </div>
          ) : (
            <>
              <div style={{ marginTop: 30, display: 'block' }}>
                <InvoiceLogsTable title="" invoiceLogs={result?.invoiceLogs} />
              </div>
              <div style={{ marginTop: 30 }}>
                {orderedActions?.create?.map((action: any) => {
                  return renderAction(action)
                })}
              </div>

              <div style={{ marginTop: 30 }}>
                {orderedActions?.update?.map((action: any) => {
                  return renderAction(action)
                })}
              </div>
            </>
          )}
        </div>
      )}
    </Container>
  )
})
