// import * as api from './api'

import { ISchedule } from 'Models'
import { InfoWindow, Marker, Polyline } from '@react-google-maps/api'
import React, { useState } from 'react'

import { ControlPanel } from './ControlPanel'
import { ITruck } from 'Models/TruckModel'
import { LiveTruckLocationPage } from 'Pages/LiveTruckLocation/LiveTruckLocationPage'
import { Map } from 'Components/Map'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import carIcon from './car.png'
import redCarIcon from './red-car.png'
import greyCarIcon from './grey-car.png'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Left = styled.div`
  flex: 1;
  position: relative;
`

const Right = styled.section`
  flex: 0.3;
  height: 100%;
  padding: 20px;
  overflow: scroll;
  background: white;
`

// const Spinner = styled(Spin)`
//   position: absolute;
//   background: white;
//   border: 1px solid ${PrimaryColor};
//   padding: 18px;
//   border-radius: 50%;
//   width: 70px;
//   height: 70px;
//   left: 50%;
//   top: 50%;
// `

interface RotatableProps {
  rotation: number
}
// const Rotatable = styled.img<RotatableProps>`
//   width: 10px;
//   height: 40px;
//   background: orange;
//   transform: ${(props) => `rotate(${props.rotation}deg)`};
// `

const initialCenter = {
  lat: 10.6343527660001,
  lng: 103.503359524,
}

interface Props {}

export const RouteAndTruckPage = observer((props: Props) => {
  const [truck, setTruck] = useState<ITruck>()
  const [selectedSchedule, setSelectedSchedule] = useState<ISchedule>()
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location: any) => {},
  })
  const [showLiveLocation, setShowLiveLocation] = useState(false)
  const [center, setCenter] = useState(initialCenter)
  const [truckIcon, setTruckIcon] = useState<string>(greyCarIcon)

  function updateTruckIcon(truckEngineState?: boolean | null) {
    switch (truckEngineState) {
      case true : return carIcon
      case false : return redCarIcon
      case null: default: return greyCarIcon
    }
  }

  function onScheduleClick(schedule: any) {
    setSelectedSchedule(schedule)
  }

  function onTruckClick(truck: ITruck) {
    setTruck(truck)
  }

  function onTruckCurrentLocationLoaded(truck: ITruck) {
    map.panTo(truck.currentLocation)
    truck
      && truck.currentLocation
      && setTruckIcon(updateTruckIcon(truck.currentLocation.isEngineOn))
  }

  function onMapDragEnd() {
    const lat = map.center.lat()
    const lng = map.center.lng()
    setCenter({
      lat,
      lng,
    })
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  function onShowLiveLocationChange(isShow: boolean) {
    setShowLiveLocation(isShow)
  }

  return (
    <Container>
      <Left>
        {showLiveLocation ? (
          <LiveTruckLocationPage></LiveTruckLocationPage>
        ) : (
          <Map
            center={center}
            zoom={15}
            onDragEnd={onMapDragEnd}
            onLoad={onMapLoaded}
          >
            {truck &&
              truck.schedules &&
              truck.schedules.map((schedule) => {
                if (schedule.Route) {
                  const route = schedule.Route
                  const color =
                    selectedSchedule?.Route?.OBJECTID === route.OBJECTID
                      ? '#00cec9'
                      : 'black'

                  return route.Paths.map((Path) => {
                    return (
                      <Polyline
                        key={Path.id}
                        path={Path.coordinates}
                        options={{
                          strokeColor: color,
                          strokeWeight: 5,
                        }}
                        onClick={() => onScheduleClick(schedule)}
                      ></Polyline>
                    )
                  })
                } else {
                  return null
                }
              })}

            {truck && truck.currentLocation && truck.currentLocation.lat && truck.currentLocation.lng && (
              // @ts-ignore
              <Marker
                position={truck.currentLocation}
                animation={google.maps.Animation.DROP}
                icon={{
                  url: truckIcon,
                  // @ts-ignore
                  scaledSize: {
                    width: 20,
                    height: 40,
                  },
                }}
              ></Marker>
            )}

            {selectedSchedule?.Route?.Paths && (
              <InfoWindow
                position={selectedSchedule?.Route?.Paths[0].coordinates[0]}
              >
                <div>{selectedSchedule?.Route?.Name}</div>
              </InfoWindow>
            )}
          </Map>
        )}
      </Left>
      <Right>
        <ControlPanel
          onTruckClick={onTruckClick}
          onTruckCurrentLocationLoaded={onTruckCurrentLocationLoaded}
          onScheduleClick={onScheduleClick}
          selectedSchedule={selectedSchedule}
          selectedTruck={truck}
          onShowLiveLocationChange={onShowLiveLocationChange}
        ></ControlPanel>
      </Right>
    </Container>
  )
})
