import { IProperty, propertyStore } from 'Models/PropertyModel'
import { IRoute, routeStore } from 'Models/RouteModel'
import { InfoWindow, Marker, Polygon, Polyline } from '@react-google-maps/api'
import { Modal, Spin, message, Tabs } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'

import { CoverageArea } from './CoverageArea'
import { Map } from 'Components/Map'
import { PrimaryColor } from 'Utils/Color'
import { ControlPanel as PropertyControlPanel } from './ControlPanel'
import { PropertyDetail } from 'Pages'
import { ControlPanel as ScheduleControlPanel } from 'Pages/Schedule/ControlPanel'

import assignedAndSelectedMarkerIcon from './assets/AssignedAndSelectedMarker.png'
import assignedMarkerIcon from './assets/AssignedMarker.png'
import { observer } from 'mobx-react-lite'
import pendingMarkerIcon from './assets/PendingMarker.png'
import selectedMarkerIcon from './assets/SelectedMarker.png'
import { isUserMOE } from 'Auth/Authorization'

const NORMAL_ROUTE_COLOR = 'gray'
const { TabPane } = Tabs

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Left = styled.section`
  flex: 1;
  position: relative;
`

const Right = styled.section`
  flex: 0.7;
  height: 100%;
  padding: 30px;
  overflow: scroll;
  background: white;
`

const Spinner = styled(Spin)`
  position: absolute;
  background: white;
  border: 1px solid ${PrimaryColor};
  padding: 18px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
`

const _RouteMap: { [key: string]: IRoute } = {}

const DISTANT = 350

export const RouteAndProperty = observer(() => {
  const { routes } = routeStore
  const { properties } = propertyStore

  const [modal, contextHolder] = Modal.useModal()

  const [selectedRoute, setSelectedRoute] = useState<IRoute>()
  const [selectedProperty, setSelectedProperty] = useState<IProperty>()
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location: any) => {},
  })

  const [loading, setLoading] = useState(false)

  const [tabKey, setTabKey] = useState('Property')
  const [showPropertyDetailModal, setShowPropertyDetailModal] = useState(false)

  const initialCenter = {
    lat: 10.6343527660001,
    lng: 103.503359524,
  }
  const [center, setCenter] = useState(initialCenter)

  useEffect(() => {
    propertyStore.emptyProperties()
  }, [])

  async function loadRoutes() {
    const query = {
      geometry: `${center.lng},${center.lat}`,
      distant: DISTANT,
    }

    try {
      await routeStore.loadRoutes(query)
      routes.forEach((route) => {
        _RouteMap[route.OBJECTID] = route
      })
    } catch (e: any) {
      console.log('error', e)
      if (e.response) {
        if (e.response.status === 401) {
          console.log('unauthorized')
          message.error('Your session is expired, please login again')
        } else {
          message.error(e.message)
        }
      } else {
        message.error(e.message)
      }
    }
  }

  async function loadProperties() {
    const query = {
      geometry: `${center.lng},${center.lat}`,
      distant: DISTANT,
    }
    await propertyStore.loadNearbyProperties(query)
  }

  async function loadRouteAndProperties() {
    try {
      setLoading(true)

      await Promise.all([loadRoutes(), loadProperties()])
    } catch (error) {
      console.log('error')
      message.error('Something went wrong!')
    } finally {
      setLoading(false)
    }
  }

  function onRouteClick(route: IRoute) {
    setSelectedRoute(route)
  }

  async function onPropertyMarkerClick(property: IProperty) {
    const routeId = selectedRoute?.OBJECTID

    if (!routeId) {
      alert('Please select route!!!')
      return null
    }

    if (isUserUnauthorize())
      message.warning('You need to be an admin to perform this task!')

    modal.confirm({
      title: 'Are you sure you want to save property?',
      onOk: async () => {
        if (routeId) {
          property.setIsSaving(true)
          await property.setRouteId(routeId + '')
          property.setIsSaving(false)
        } else {
          message.warning('You need to select a route first to assign to it')
        }
      },
      okText: 'Yes',
      cancelText: 'No',
    })
  }

  function isUserUnauthorize() {
    return isUserMOE()
  }

  function onPropertySelected(property: IProperty) {
    setSelectedProperty(property)
    map.panTo(property.geometry)
    const route = _RouteMap[property.RouteID + '']
    setSelectedRoute(route)
  }

  function onMapDragEnd() {
    const lat = map.center.lat()
    const lng = map.center.lng()
    setCenter({
      lat,
      lng,
    })
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  function onLoadAllPropertyClick() {
    loadProperties()
  }

  async function onCitizenIdSelected(citizenId: string) {
    if (citizenId.length > 0) {
      setLoading(true)
      await propertyStore.loadPropertyByCitizenId(citizenId)
      setLoading(false)
    } else {
      propertyStore.emptyProperties()
    }
  }

  function onTabKeyChange(key: string) {
    setTabKey(key)
  }

  function onPropertyDetailButtonClick(property: IProperty) {
    setSelectedProperty(property)
    setShowPropertyDetailModal(true)
  }

  async function onMarkerDragEnd(property: IProperty, event: any) {
    const messageKey = 'update-location'
    message.loading({ content: 'Saving...', key: messageKey })
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    await property.edit({
      geometry: {
        x: lng,
        y: lat,
      },
    })
    message.success({ content: 'Success!', key: messageKey, duration: 2 })
  }

  return (
    <Container>
      <Left>
        <Map
          onPlaceSelected={(place: any) => setCenter(place.coordinate)}
          center={center}
          zoom={15}
          onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
        >
          {routes.map((route) => {
            if (route.OBJECTID === selectedRoute?.OBJECTID) {
              return route.Paths.map((Path) => {
                return (
                  <Polygon
                    key={Path.id}
                    path={Path.coordinates}
                    options={{
                      strokeWeight: 0,
                      fillColor: '#ff0000',
                      fillOpacity: 1,
                    }}
                    onClick={() => onRouteClick(route)}
                  ></Polygon>
                )
              })
            } else {
              return route.Paths.map((Path) => {
                return (
                  <Polygon
                    key={Path.id}
                    path={Path.coordinates}
                    options={{
                      strokeWeight: 0,
                      fillColor: NORMAL_ROUTE_COLOR,
                      fillOpacity: 1,
                    }}
                    onClick={() => onRouteClick(route)}
                  ></Polygon>
                )
              })
            }
          })}

          <CoverageArea center={center}></CoverageArea>

          {properties.reduce(
            (propertyMarkers: ReactElement<any, any>[], property) => {
              let markerIcon = property.RouteID
                ? assignedMarkerIcon
                : pendingMarkerIcon

              const assigned = property.RouteID
              if (property.isSelected) {
                markerIcon = selectedMarkerIcon
              }

              if (assigned && property.isSelected) {
                markerIcon = assignedAndSelectedMarkerIcon
              }

              const label = property.isSaving ? 'Saving...' : ''
              const iconSize = property.isSelected ? 16 : 8
              const { lat, lng }: any = property?.geometry
              if (lat && lng) {
                const position = new window.google.maps.LatLng(lat, lng)
                const scaledSize = new window.google.maps.Size(
                  iconSize,
                  iconSize
                )
                propertyMarkers.push(
                  <Marker
                    key={property.OBJECTID}
                    position={position}
                    onClick={() => onPropertyMarkerClick(property)}
                    label={label}
                    // draggable
                    // onDragEnd={(event) => onMarkerDragEnd(property, event)}
                    icon={{
                      url: markerIcon,
                      scaledSize: scaledSize,
                    }}
                  ></Marker>
                )
              }

              return propertyMarkers
            },
            []
          )}
          {selectedRoute && selectedRoute.Paths && (
            <InfoWindow
              key={selectedRoute.OBJECTID}
              position={selectedRoute.Paths[0].coordinates[0]}
            >
              <div>{selectedRoute.Name}</div>
            </InfoWindow>
          )}
        </Map>

        {loading && <Spinner size="large"></Spinner>}
      </Left>
      <Right>
        <Tabs defaultActiveKey="Property" onChange={onTabKeyChange}>
          <TabPane tab="Property" key="Property">
            <PropertyControlPanel
              onLoadAllPropertyClick={loadRouteAndProperties}
              onCitizenIdSelected={onCitizenIdSelected}
              onPropertySelected={onPropertySelected}
              onMoreButtonClick={onPropertyDetailButtonClick}
              loading={loading}
            ></PropertyControlPanel>
          </TabPane>
          <TabPane tab="Schedule" key="Schedule">
            {tabKey === 'Schedule' && selectedRoute && (
              <ScheduleControlPanel
                selectedRoute={selectedRoute}
              ></ScheduleControlPanel>
            )}
          </TabPane>
        </Tabs>
      </Right>
      {showPropertyDetailModal && selectedProperty && (
        <Modal
          title="ព័ត៌មានលម្អិតអំពីអចលនទ្រព្យ (Property Detail)"
          visible={showPropertyDetailModal}
          onCancel={() => setShowPropertyDetailModal(false)}
          footer={[]}
          width={600}
        >
          <PropertyDetail property={selectedProperty} />
        </Modal>
      )}
      {contextHolder}
    </Container>
  )
})
