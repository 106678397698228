import axios from 'axios'

import { environment } from 'environment'
const { apiBaseUrl } = environment

export const generateBill = async (
  month: string,
  collector: boolean | null
) => {
  let url = `${apiBaseUrl}/api/billings/generate-invoice`
  const postData = {
    collector,
    month,
  }

  const result = await axios.post(url, postData)

  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const getReport = async (query: string) => {
  let url = `${apiBaseUrl}/api/properties/distribute-report?${query}`

  const result = await axios.get(url)

  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}
