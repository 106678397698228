import { CheckCircleTwoTone, CheckOutlined } from '@ant-design/icons'
import { Table, Typography } from 'antd'
import { ColumnType } from 'antd/lib/table'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { monthOnlyFormat } from 'Utils/Date'
import { sumTotalAmount } from 'Utils/helper'

type Props = {
  title: string
  invoiceLogs?: any
}

const getMonth = (date: any) => {
  return moment(date).isValid()
    ? moment(date).add(1, 'd').format(monthOnlyFormat).toString()
    : ''
}

const toCurrency = (value?: number | string, symbol = '៛') => {
  if (!value) return ''

  const commaFormatted = String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  return `${commaFormatted}${symbol}`
}

const generateTotalText = (meta: any) => {
  const { total, ...rest } = meta || {}

  const footer = _.reduce(
    rest,
    (result, item, key) => {
      result.push(
        <p key={key}>
          - {_.get(tableUtil, key, key)}
          <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
            {` ${item?.totalRecord}`}
          </span>
          {key === 'PRINT_RECEIPT' || key === 'UPDATE_BILL_TO_UNPAID' ? (
            <span>
              {` គិតជាទឹកប្រាក់ចំនួន `}
              <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                {toCurrency(item?.totalAmount)}
              </span>
            </span>
          ) : (
            ''
          )}
        </p>
      )
      return result
    },
    [<p key="total">វិក្កយបត្រសរុបមានចំនួន {total || 0}</p>]
  )

  return footer
}

const tableUtil = {
  PRINT_INVOICE: 'បោះពុម្ពវិក្កយបត្រ [Print invoice] បានចំនួន',
  PRINT_RECEIPT: 'បោះពុម្ពបង្កាន់ដៃ [Print receipt/Cash Received] បានចំនួន',
  CREATE_INVOICE: 'បង្កើតវិក្កយបត្រ [Create invoice] បានចំនួន',
  DISTRIBUTE_BILL: 'ចែកវិក្កយបត្ររួចរាល់ [Distribute bill] បានចំនួន',
  UPDATE_BILL_TO_UNPAID:
    'ប្ដូរវិក្កយបត្រទៅជាមិនទាន់បង់ប្រាក់ [Update bill to unpaid] មានចំនួន',
  DELETE_BILL: 'លុបវិក្កយបត្រ [Delete Bill] មានចំនួន',
  // USER_VIEW_INVOICE: 'ប្រជាពលរដ្ឋមើល',
}

const InvoiceLogsTable = ({ title, invoiceLogs = {} }: Props) => {
  const { total, ...restMeta } = invoiceLogs?.meta || {}

  const columns: ColumnType<any>[] = [
    {
      title: 'លេខកូដទីតាំង',
      dataIndex: 'propertyId',
      key: 'propertyId',
    },
    {
      title: 'លេខវិក្កយប័ត្រ',
      dataIndex: 'invoiceId',
      key: 'invoiceId',
    },
    {
      title: 'តម្លៃសេវា',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: any) => {
        return toCurrency(value)
      },
    },
    {
      title: 'សម្រាប់ខែ',
      dataIndex: 'billForDate',
      key: 'billForDate',
      render: (value: any) => {
        return getMonth(value)
      },
    },
    {
      title: 'Activities',
      align: 'center',
      //@ts-ignore
      children: _.map(restMeta, (_, key) => {
        //@ts-ignore
        const footerString = tableUtil[key]
        return {
          title: footerString
            ? footerString.substring(0, footerString.indexOf(' '))
            : key,
          dataIndex: key,
          key,
          align: 'center',
          render: (value: any) => {
            return value ? <CheckCircleTwoTone /> : null
          },
          width: 100,
        }
      }),
    },
  ]

  return (
    <div>
      <Table
        size="small"
        columns={columns}
        rowKey="_id"
        dataSource={invoiceLogs?.data}
        title={() => (
          <Typography.Title level={4} style={{ fontWeight: 'bold' }}>
            របាយការណ៍វិក្កយបត្រ
          </Typography.Title>
        )}
        footer={() => generateTotalText(invoiceLogs?.meta)}
        // pagination={{ defaultPageSize: 500 }}
        scroll={{ y: 300 }}
      />
    </div>
  )
}

export default InvoiceLogsTable
