import { IProperty, propertyStore } from 'Models/PropertyModel'
import { IRoute, routeStore } from 'Models/RouteModel'
import { InfoWindow, Marker, Polygon, Polyline } from '@react-google-maps/api'
import {
  Spin,
  message,
  Modal,
  Button,
  DatePicker,
  DatePickerProps,
  Space,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'

import { CoverageArea } from './CoverageArea'
import { Map } from 'Components/Map'
import { PrimaryColor } from 'Utils/Color'

import assignedAndSelectedMarkerIcon from './assets/AssignedAndSelectedMarker.png'
import assignedMarkerIcon from './assets/AssignedMarker.png'
import { observer } from 'mobx-react-lite'
import pendingMarkerIcon from './assets/PendingMarker.png'
import selectedMarkerIcon from './assets/SelectedMarker.png'
import { isUserMOE } from 'Auth/Authorization'
import { PropertyList } from 'Pages/RoutePropertyAndSchedule/ControlPanel/PropertyList'
import { IBill } from 'Models'
import { getBillsByPropertyAndMonth } from 'Models/BillModel/api'
import { BillList } from './BillList'
import { INVOICE_SERVER_URL, getCenterPoint } from 'Utils/Constants'
import moment from 'moment'
import { IsActiveSelector } from 'Components/IsActiveSelector/IsActiveSelector'
import { getQueryByProvince } from 'Models/PropertyModel/api'

const NORMAL_ROUTE_COLOR = 'gray'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Left = styled.section`
  flex: 1;
  position: relative;
`

const Title = styled.h3`
  margin-top: 10px;
`

const Right = styled.section`
  flex: 0.7;
  height: 100%;
  padding: 30px;
  overflow: scroll;
  background: white;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: scroll;
`

const Spinner = styled(Spin)`
  position: absolute;
  background: white;
  border: 1px solid ${PrimaryColor};
  padding: 18px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
`

const DISTANT = 350

const _RouteMap: { [key: string]: IRoute } = {}

export const RouteAndPropertyAndBill = observer(() => {
  const { routes } = routeStore
  const { properties } = propertyStore
  // const { bills } = billStore
  const isMOE = isUserMOE()

  const [selectedRoute, setSelectedRoute] = useState<IRoute>()
  const [selectedProperty, setSelectedProperty] = useState<IProperty>()
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location: any) => {},
  })

  const [loadingRoute, setLoadingRoute] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<any>(null)

  const initialCenter = getCenterPoint()
  const [center, setCenter] = useState(initialCenter)
  const [selectedBill, setSelectedBill] = useState<IBill>()

  // Modals
  const [showBillModal, setShowBillModal] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState<string>()
  const [bills, setBills] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    propertyStore.emptyProperties()
  }, [])

  // useEffect(() => {
  //   async function loadProperty() {
  //     if (selectedRoute) {
  //       await propertyStore.loadPropertyByRouteId(selectedRoute?.OBJECTID)
  //     }
  //   }

  //   loadProperty()
  // }, [selectedRoute])

  async function loadRoutes() {
    setLoadingRoute(true)
    const query = {
      geometry: `${center.lng},${center.lat}`,
      distant: DISTANT,
    }

    try {
      await routeStore.loadRoutes(query)
      routes.forEach((route) => {
        _RouteMap[route.OBJECTID] = route
      })
    } catch (e: any) {
      console.log('error', e)
      if (e.response) {
        if (e.response.status === 401) {
          console.log('unauthorized')
          message.error('Your session is expired, please login again')
        } else {
          message.error(e.message)
        }
      } else {
        message.error(e.message)
      }
    }
    setLoadingRoute(false)
  }

  function onRouteClick(route: IRoute) {
    setSelectedRoute(route)
  }

  async function onPropertyMarkerClick(property: IProperty) {
    const routeId = selectedRoute?.OBJECTID
    // if (isUserUnauthorize()) message.warning('You need to be an admin to perform this task!')
    // if (routeId) {
    //   property.setIsSaving(true)
    //   await property.setRouteId(routeId + '')
    //   property.setIsSaving(false)
    // } else {
    //   message.warning('You need to select a route first to assign to it')
    // }
  }

  function onMapDragEnd() {
    const lat = map.center.lat()
    const lng = map.center.lng()
    setCenter({
      lat,
      lng,
    })
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  async function onDownloadClick(bill: IBill) {
    const url = `${INVOICE_SERVER_URL}/generate-invoice-by-billId/${bill.OBJECTID}`
    var link = document.createElement('a')
    link.setAttribute('download', `Invoice_${bill.OBJECTID}.pdf`)
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  async function onDownloadAllClick() {
    let url = `${INVOICE_SERVER_URL}/generate-invoice-by-route?routeId=${selectedRoute?.OBJECTID}&month=${selectedMonth}`
    if (selectedStatus || selectedStatus === 0) {
      url += `&status=${selectedStatus}`
    }

    var link = document.createElement('a')
    link.setAttribute(
      'download',
      `Route-${selectedRoute?.OBJECTID}-invoice.pdf`
    )
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const handleSearch = async () => {
    setLoading(true)
    let queryString = `where=((RouteID=${selectedRoute?.OBJECTID})`
    if (selectedStatus || selectedStatus === 0) {
      queryString += ` AND (IsActive=${selectedStatus})`
    }

    if (getQueryByProvince() !== '') {
      queryString += ` AND (${getQueryByProvince()})`
    }

    queryString += `)`
    await propertyStore.searchProperties(queryString)
    setLoading(false)
  }

  async function handleBillButtonClick(property: IProperty) {
    if (selectedMonth) {
      setLoading(true)
      const bills = await getBillsByPropertyAndMonth(
        property.OBJECTID,
        selectedMonth
      )

      if (bills.length > 0) {
        onDownloadClick(bills[0]?.attributes)
      } else {
        message.error(
          'ទីតាំងនេះមិនទាន់មានវិក័យបត្រសំរាប់ខែ ' +
            moment(selectedMonth).add(1, 'days').format('MMMM') +
            ' ទេ'
        )
      }

      setLoading(false)
    } else {
      message.info('សូមជ្រើសរើសខែដែលអ្នកចង់ទាញយកវិក័យបត្រ')
    }
  }

  function panMap(property: IProperty) {
    propertyStore.setSelectedProperty(property)
  }

  async function onMarkerDragEnd(property: IProperty, event: any) {
    const messageKey = 'update-location'
    message.loading({ content: 'Saving...', key: messageKey })
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    await property.edit({
      geometry: {
        x: lng,
        y: lat,
      },
    })
    message.success({ content: 'Success!', key: messageKey, duration: 2 })
  }

  const handleMonthChange: DatePickerProps['onChange'] = (date, dateString) => {
    setSelectedMonth(date?.startOf('month')?.format('YYYY-MM-DD'))
  }

  return (
    <Container>
      <Left>
        <Map
          onPlaceSelected={(place: any) => setCenter(place.coordinate)}
          center={center}
          zoom={15}
          onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
        >
          {routes.map((route) => {
            if (route.OBJECTID === selectedRoute?.OBJECTID) {
              return route.Paths.map((Path) => {
                return (
                  <Polygon
                    key={Path.id}
                    path={Path.coordinates}
                    options={{
                      strokeWeight: 0,
                      fillColor: '#ff0000',
                      fillOpacity: 1,
                    }}
                    onClick={() => onRouteClick(route)}
                  ></Polygon>
                )
              })
            } else {
              return route.Paths.map((Path) => {
                return (
                  <Polygon
                    key={Path.id}
                    path={Path.coordinates}
                    options={{
                      strokeWeight: 0,
                      fillColor: NORMAL_ROUTE_COLOR,
                      fillOpacity: 1,
                    }}
                    onClick={() => onRouteClick(route)}
                  ></Polygon>
                )
              })
            }
          })}

          <CoverageArea center={center}></CoverageArea>

          {properties.reduce(
            (propertyMarkers: ReactElement<any, any>[], property) => {
              let markerIcon = property.RouteID
                ? assignedMarkerIcon
                : pendingMarkerIcon

              const assigned = property.RouteID
              if (property.isSelected) {
                markerIcon = selectedMarkerIcon
              }

              if (assigned && property.isSelected) {
                markerIcon = assignedAndSelectedMarkerIcon
              }

              const label = property.isSaving ? 'Saving...' : ''
              const iconSize = property.isSelected ? 16 : 8
              if (
                property.geometry &&
                property.geometry.lat &&
                property.geometry.lng
              ) {
                propertyMarkers.push(
                  // @ts-ignore
                  <Marker
                    key={property.OBJECTID}
                    position={property.geometry}
                    onClick={() => onPropertyMarkerClick(property)}
                    label={label}
                    // draggable
                    // onDragEnd={(event) => onMarkerDragEnd(property, event)}
                    icon={{
                      url: markerIcon,
                      scaledSize: {
                        width: iconSize,
                        height: iconSize,
                      },
                    }}
                  ></Marker>
                )
              }

              return propertyMarkers
            },
            []
          )}
          {selectedRoute && selectedRoute.Paths && (
            <InfoWindow position={selectedRoute.Paths[0].coordinates[0]}>
              <div>{selectedRoute.Name}</div>
            </InfoWindow>
          )}

          <Button
            onClick={loadRoutes}
            loading={loadingRoute}
            disabled={loadingRoute}
            size="large"
            style={{ top: '10%', left: '1.2%' }}
          >
            Load Routes
          </Button>
        </Map>

        {loadingRoute && <Spinner size="large"></Spinner>}
      </Left>
      <Right>
        <Space>
          <div>
            <div>
              <label>ខែដែលត្រូវចេញវិក័យបត្រ : </label>
              <DatePicker
                onChange={handleMonthChange}
                picker="month"
                placeholder="ជ្រើសរើសខែ"
                disabledDate={(current) => {
                  let lastMonth = moment()
                    .subtract(1, 'months')
                    .format('DD-MM-YYYY')
                  return current && current < moment(lastMonth, 'DD-MM-YYYY')
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                <label>Status : </label>
                <IsActiveSelector
                  onChange={(value) => setSelectedStatus(value)}
                />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                disabled={loading || !selectedRoute || !selectedMonth}
                style={{ marginRight: 10 }}
                type="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                disabled={loading || !selectedMonth || !properties.length}
                type="primary"
                onClick={onDownloadAllClick}
              >
                Download Invoices{' '}
                {selectedMonth
                  ? `for (${moment(selectedMonth).format('MMMM')})`
                  : ''}
              </Button>
            </div>
          </div>
        </Space>
        <PropertyList
          showDistributeMonth={true}
          distributeMonthCheck={moment(selectedMonth).format('MM/YYYY')}
          loading={loading || propertyStore.loading}
          tableTitle="Property list"
          properties={propertyStore.properties}
          onPropertyClick={panMap}
          // onMoreButtonClick={onPropertyDetailButtonClick}
          // onRouteButtonClick={onRouteButtonClick}
          // onMapPinButtonClick={onMapPinButtonClick}
          onDownloadButtonClick={handleBillButtonClick}
        />
      </Right>
      {showBillModal && (
        <Modal
          title="Bill for property"
          visible={showBillModal}
          onCancel={() => setShowBillModal(false)}
          width={600}
          footer={[
            <Button key="back" onClick={() => setShowBillModal(false)}>
              Close
            </Button>,
          ]}
        >
          {propertyStore.selectedProperty && (
            <Title>
              Bills for Property{' '}
              <i>
                {`${propertyStore.selectedProperty?.Name + ' ' || ''}`}
                {`(id ${propertyStore.selectedProperty.OBJECTID})`} :
              </i>
            </Title>
          )}
          <BillList bills={bills}></BillList>
        </Modal>
      )}
    </Container>
  )
})
