import React, { useState } from 'react'

import {
  EditButton as BaseEditButton,
  DeleteConfirmButton as BaseDeleteConfirmButton,
} from 'Components'
import { IBill } from 'Models'
import { Space } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { BillEditContainer } from 'Pages/Billing/Edit'
import { Admin, BillingManager, IT } from 'Auth/Role'
import { formatDate, momentDate } from 'Utils/Date'
import { isActiveOptions } from 'Components/IsActiveSelector/IsActiveSelector'

const Container = styled.div``

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const EditButton = styled(BaseEditButton)`
  margin-left: auto;
`
const DeleteConfirmButton = styled(BaseDeleteConfirmButton)`
  margin-left: auto;
`

const Table = styled.table`
  width: 350px;
`

const Tr = styled.tr`
  height: 40px;
`

const UnpaidLabel = styled.span`
  background: #ffabab;
  width: fit-content;
  padding: 0px 8px;
  border-radius: 4px;
  /* margin-left: auto; */
`

const PaidLabel = styled.span`
  background: #50e3c2;
  width: fit-content;
  padding: 0px 6px;
  border-radius: 4px;
  /* margin-left: auto; */
`

interface Props {
  bill: IBill
  onCloseModal?: () => void
}

export const BillDetail = (props: Props) => {
  const { bill, onCloseModal } = props
  const [showEdit, setShowEdit] = useState(false)

  function onEditClick() {
    setShowEdit(true)
  }

  function onDeleteConfirm(bill: IBill) {
    bill.delete()
    onCloseModal && onCloseModal()
  }

  if (showEdit) {
    return (
      <Container>
        <BillEditContainer
          bill={bill}
          onSaveFinish={() => setShowEdit(false)}
        ></BillEditContainer>
      </Container>
    )
  }

  const isPaid = !!bill?.Paid

  return (
    <Container>
      <Row>
        <Space style={{ marginLeft: 'auto' }}>
          {!isPaid && (
            <DeleteConfirmButton
              danger={true}
              deleting={bill.isDeleting}
              onConfirmClick={() => onDeleteConfirm(bill)}
              roles={[Admin, BillingManager, IT]}
            />
          )}

          <EditButton type="primary" onClick={onEditClick}></EditButton>
        </Space>
      </Row>
      <Table>
        <tbody>
          <Tr>
            <td>For Month:</td>
            <td>{bill.BillForDate && formatDate(bill.BillForDate)}</td>
          </Tr>
          <Tr>
            <td>Amount:</td>
            <td>{bill.Amount}៛</td>
          </Tr>
          <Tr>
            <td>Paid:</td>
            <td>
              {bill.Paid ? (
                <PaidLabel>Yes</PaidLabel>
              ) : (
                <UnpaidLabel>No</UnpaidLabel>
              )}
            </td>
          </Tr>
          <Tr>
            <td>Paid Date:</td>
            <td>{bill.PaidDate && formatDate(bill.PaidDate)}</td>
          </Tr>
          <Tr>
            <td>Property status</td>
            <td>
              {
                isActiveOptions.find(
                  (item) => item?.value === bill?.PropertyStatus
                )?.label
              }
            </td>
          </Tr>
        </tbody>
      </Table>
    </Container>
  )
}
