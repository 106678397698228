import { Button } from 'antd'
import { IProperty } from 'Models'
import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
`

interface Props {
  property: IProperty
  onPayButtonClick?: (property: IProperty) => void
}

export const PropertyListItem = observer((props: Props) => {
  const { property } = props

  function onPayButtonClick() {
    props.onPayButtonClick && props.onPayButtonClick(property)
  }

  return (
    <Container>
      <span>{property.Name}</span>
      <Button onClick={onPayButtonClick}>Pay</Button>
    </Container>
  )
})
