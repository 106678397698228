import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment


export const getPhoneNumberNotNull = async () => {
  const url = `${apiBaseUrl}/api/citizens/query?resultRecordCount=100&where=Phone IS NOT NULL`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const getCitizenById = async (id: string) => {
  const url = `${apiBaseUrl}/api/citizens/query?where=OBJECTID=${id}`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const getCitizenProperties = async (citizenId: string) => {
  const query = `where=CitizenID='${citizenId}'`
  const url = `${apiBaseUrl}/api/properties/query?${query}`
  const result = await axios.get(url)
  return result.data
}

export const getCitizenBills = async (citizenId: string) => {
  const query = `where=CitizenID='${citizenId}'&orderByFields=BillForDate DESC`
  const url = `${apiBaseUrl}/api/billings/query?${query}`
  const result = await axios.get(url)
  return result.data
}

export const getCitizenByImportId = async (importId: string) => {
  const query = `where=ImportID='${importId}'`
  const url = `${apiBaseUrl}/api/citizens/query?${query}`
  const result = await axios.get(url)
  return result.data
}

export const getCitizenByPhoneNumber = async (phoneNumber: string) => {
  const query = `where=Phone='${phoneNumber}'`
  const url = `${apiBaseUrl}/api/citizens/query?${query}`
  const result = await axios.get(url)
  return result.data
}

export const getCitizenByLegacyId = async (legacyId: string) => {
  const query = `where=LegacyID='${legacyId}'`
  const url = `${apiBaseUrl}/api/citizens/query?${query}`
  const result = await axios.get(url)
  return result.data
}

// *** NEED VARIFICATION ***
export const add = async (values: any) => {
  const url = `${apiBaseUrl}/api/citizens/add`
  // const data: any = {
  //   features: {
  //     attributes: values,
  //   },
  // }
  // if (values.geometry) {
  //   data.features.geometry = values.geometry
  // }

  const response = await axios.post(url, values)
  const { error } = response.data
  if (error) {
    throw new Error(error)
  }
  const success = response.data.addResults[0].success
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const edit = async (values: any) => {
  const url = `${apiBaseUrl}/api/citizens/patch`
  const response = await axios.patch(url, values)
  const { error } = response.data
  if (error) {
    throw new Error(error)
  }
  const success = response.data.updateResults[0].success
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const deleteById = async (id: string) => {
  const url = `${apiBaseUrl}/api/citizens/delete/${id}`
  const response = await axios.delete(url)
  const success = response.data.deleteResults[0].success
  if (!success) {
    throw new Error('Failed to delete')
  }
  return response.data
}
