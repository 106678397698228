import { Button, Modal, Table, message } from 'antd'
import React, { useState } from 'react'

import { DeleteConfirmButton } from 'Components'
import moment from 'moment'
import styled from 'styled-components'
import { user } from 'Models'

const Container = styled.div``

interface Props {
  data: any[]
}
export const PendingDataTable = (props: Props) => {
  const { data } = props

  const columns = [
    {
      title: 'Branch',
      dataIndex: 'Branch',
      key: 'Branch',
    },
    {
      title: 'Customer Code',
      dataIndex: 'CustomerLocationCode',
      key: 'CustomerLocationCode',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Village',
      dataIndex: 'Village',
      key: 'Village',
    },
    {
      title: 'Commune',
      dataIndex: 'Commune',
      key: 'Commune',
    },
    {
      title: 'District',
      dataIndex: 'District',
      key: 'District',
    },

    {
      title: 'Province',
      dataIndex: 'Province',
      key: 'Province',
    },
    {
      title: 'Business Description',
      dataIndex: 'BusinessDescription',
      key: 'BusinessDescription',
    },
    {
      title: 'KhmerAddress',
      dataIndex: 'KhmerAddress',
      key: 'KhmerAddress',
    },
  ]

  return (
    <Container>
      <Table columns={columns} dataSource={data}></Table>
    </Container>
  )
}
