import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

export const TransactionHistory = types
  .model('TransactionHistory', {
    invoiceId: types.string,
    status: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
  })
  .actions((self) => ({}))

export interface ITransactionHistory
  extends Instance<typeof TransactionHistory> {}
