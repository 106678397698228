import { FILE_SERVER_URL, FILE_VIEW_URL } from 'Utils/Constants'
import { Progress, Upload, message } from 'antd'
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/lib/upload/interface'
import React, { useState } from 'react'

import styled from 'styled-components'

const UPLOAD_URL = FILE_SERVER_URL

const Preview = styled.img`
  width: 100%;
  height: 100%;
`

const UploadedBox = styled.div`
  width: 100%;
  height: 100%;
`

const DeleteButton = styled.div`
  color: red;
  cursor: pointer;
`

interface Props {
  uploadedPhotoUrl: string
  onFileUploaded: (url?: string) => void
}

export const ImageUpload = (props: Props) => {
  const { uploadedPhotoUrl } = props
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  function handleChange(info: UploadChangeParam<UploadFile<any>>) {
    if (info.file.status === 'uploading') {
      setProgress(info.file.percent ?? 0)
      setIsUploading(true)
    }

    if (info.file.status === 'done') {
      const fileName = info.file.response
      const uploadedFileUrl = `${FILE_VIEW_URL}/${fileName}`
      setIsUploading(false)
      props.onFileUploaded && props.onFileUploaded(uploadedFileUrl)
    }
  }

  function handleDelete(e: any) {
    e.stopPropagation()
    props.onFileUploaded && props.onFileUploaded()
  }

  return (
    <Upload
      name="avatar"
      multiple={false}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={UPLOAD_URL}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {isUploading && <Progress percent={progress} showInfo={false} />}
      {!isUploading && uploadedPhotoUrl ? (
        <UploadedBox>
          <Preview src={uploadedPhotoUrl + '?dim=100'} />
          <DeleteButton onClick={handleDelete}>លុប</DeleteButton>
        </UploadedBox>
      ) : (
        <div>ភ្ជាប់រូប</div>
      )}
    </Upload>
  )
}

function beforeUpload(file: RcFile) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 4
  if (!isLt2M) {
    message.error('Image must smaller than 4MB!')
  }
  return isJpgOrPng && isLt2M
}
