import { Form, message } from 'antd'
import { IBusiness, businessStore } from 'Models/BusinessModel/BusinessModel'
import React, { useState } from 'react'

import { BusinessForm } from '../Form/BusinessForm'
import { Coordinate } from 'Models/Types'
import styled from 'styled-components'

const Container = styled.section``

interface Props {
  coordinate: Coordinate
  onSaveFinish?: (business: IBusiness) => void
}

export const BusinessAdd = (props: Props) => {
  const { coordinate } = props
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  const initialValues = {}

  async function onSubmit(values: any) {
    const { lat: y, lng: x } = coordinate
    const data = {
      ...values,
      geometry: { x, y },
    }
    setSaving(true)
    try {
      const addedBusiness = await businessStore.addBusiness(data)
      setSaving(false)
      props.onSaveFinish && props.onSaveFinish(addedBusiness)
    } catch (e: any) {
      setSaving(false)
      message.error(e.message)
    }
  }

  return (
    <Container>
      <BusinessForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
      ></BusinessForm>
    </Container>
  )
}
