import { Button, DeleteConfirmButton, TruckSelector } from 'Components'
import { Checkbox, Form, Input, Space, TimePicker } from 'antd'
import { ISchedule, scheduleStore } from 'Models/ScheduleModel'
import { getTruckCurrentLocation } from 'Models/TruckModel/api'
import React, { useState } from 'react'

import mapPin from './map-pin.png'

import styled from 'styled-components'
import { isUserMOE } from 'Auth/Authorization'
import moment from 'moment'
import { ScheduleTimePicker } from 'Components/ScheduleTimePicker/ScheduleTimePicker'

const StyledForm = styled(Form)`
  margin-top: 30px;
  background: rgb(24 144 255 / 20%);
  padding: 20px;
  border-radius: 10px;
`

const Controls = styled(Space)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const EverydayButton = styled(Button)`
  margin-left: 40px;
  margin-bottom: 10px;
`

const TrackTruckControls = styled.div`
  display: flex;
  flex-wrap: nowrap;
`
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}

interface Props {
  initialValues: any
  schedule?: ISchedule
  isNew: boolean
  onDeleteClick: () => void
  onFinish: (values: any) => void
  saving?: boolean
  deleting?: boolean

  // trucks: ITruck[]
  // selectedTruck?: ITruck
  // onTruckClick?: (truck: ITruck) => void
  onTruckCurrentLocationLoaded?: (truck: any) => void
}

export const ScheduleForm = (props: Props) => {
  const { initialValues, isNew, onDeleteClick, saving, deleting, onFinish } =
    props

  const [form] = Form.useForm()
  const [unitId, setUnitId] = useState<string>()
  const isMOE = isUserMOE()

  function getUnitId(inputUnitId: string) {
    setUnitId(inputUnitId)
    form.setFieldsValue({
      TruckID: inputUnitId,
    })
  }

  function onFinishFailed() {
    console.log('on finish failed')
  }

  function onEverydayClick() {
    form.setFieldsValue({
      Monday: true,
      Tuesday: true,
      Wednesday: true,
      Thursday: true,
      Friday: true,
      Saturday: true,
      Sunday: true,
    })
  }

  async function onClickTruckLocation() {
    if (!unitId) return
    const coordinate = await getTruckCurrentLocation(unitId)
    props.onTruckCurrentLocationLoaded &&
      props.onTruckCurrentLocationLoaded(coordinate)
  }

  return (
    <StyledForm
      {...layout}
      name="basic"
      form={form}
      size="small"
      initialValues={initialValues}
      onFinishFailed={onFinishFailed}
      onFinish={onFinish}
    >
      <Form.Item
        label="From Hour"
        name="FromHour"
        rules={[{ required: true, message: 'Please input time' }]}
      >
        <ScheduleTimePicker
          disabled={isMOE}
          default={
            !!initialValues?.ToHour
              ? moment(initialValues?.FromHour, 'HH:mm')
              : undefined
          }
        />
      </Form.Item>

      <Form.Item
        label="To Hour"
        name="ToHour"
        rules={[{ required: true, message: 'Please input time' }]}
      >
        <ScheduleTimePicker
          disabled={isMOE}
          default={
            !!initialValues?.ToHour
              ? moment(initialValues?.ToHour, 'HH:mm')
              : undefined
          }
        />
      </Form.Item>

      <Form.Item
        label="Truck"
        name="TruckID"
        rules={[{ required: false, message: 'Please select truck' }]}
      >
        <TrackTruckControls>
          <TruckSelector
            default={initialValues?.TruckID}
            getUnitId={getUnitId}
          />
          <Button
            onClick={onClickTruckLocation}
            shape="circle"
            disabled={isMOE}
          >
            <img width="10" src={mapPin} alt="" />
          </Button>
        </TrackTruckControls>
      </Form.Item>
      <EverydayButton onClick={onEverydayClick} disabled={isMOE}>
        Everyday
      </EverydayButton>
      <Form.Item {...tailLayout} name="Monday" valuePropName="checked">
        <Checkbox disabled={isMOE}>Monday</Checkbox>
      </Form.Item>
      <Form.Item {...tailLayout} name="Tuesday" valuePropName="checked">
        <Checkbox disabled={isMOE}>Tuesday</Checkbox>
      </Form.Item>
      <Form.Item {...tailLayout} name="Wednesday" valuePropName="checked">
        <Checkbox disabled={isMOE}>Wednesday</Checkbox>
      </Form.Item>
      <Form.Item {...tailLayout} name="Thursday" valuePropName="checked">
        <Checkbox disabled={isMOE}>Thursday</Checkbox>
      </Form.Item>
      <Form.Item {...tailLayout} name="Friday" valuePropName="checked">
        <Checkbox disabled={isMOE}>Friday</Checkbox>
      </Form.Item>
      <Form.Item {...tailLayout} name="Saturday" valuePropName="checked">
        <Checkbox disabled={isMOE}>Saturday</Checkbox>
      </Form.Item>
      <Form.Item {...tailLayout} name="Sunday" valuePropName="checked">
        <Checkbox disabled={isMOE}>Sunday</Checkbox>
      </Form.Item>
      <Controls>
        <Button
          loading={saving}
          type="primary"
          htmlType="submit"
          disabled={isMOE}
        >
          {isNew ? 'Add' : 'Update'}
        </Button>
        {!isNew && !isMOE && (
          <DeleteConfirmButton
            deleting={deleting}
            danger
            onConfirmClick={onDeleteClick}
          />
        )}
      </Controls>
    </StyledForm>
  )
}
