import { environment } from 'environment'
import { user } from 'Models'

export const PAYMENT_RECORDS_SERVER_URL =
  'https://api-gateway-staging.samraam.com'

export const INVOICE_SERVER_URL = environment.invoiceUrl
export const WIALON_SERVER_URL = 'https://wialon.samraam.com'
export const FILE_SERVER_URL = 'https://files.samraam.com/upload/samraam'
export const FILE_VIEW_URL = 'https://files.samraam.com/get/samraam'
export const getCenterPoint = () => {
  switch (user?.province_code) {
    case '3':
      return {
        lat: 11.992446,
        lng: 105.464592,
      }
    case '18':
      return {
        lat: 10.6343527660001,
        lng: 103.503359524,
      }
    case '801':
      return {
        lat: 11.5074656,
        lng: 104.7160366,
      }
    default:
      return {
        lat: 10.6343527660001,
        lng: 103.503359524,
      }
  }
}

export const CALCULATION_OPTION_TYPES = {
  Bars: 'bars',
  Bed: 'beds',
  BuildingCount: 'buildings',
  Businesses: 'businesses',
  BuildingSize: 'buildingSize',
  Cafes: 'cafes',
  Capacity: 'capacity',
  Chairs: 'chairs',
  Floors: 'floors',
  Gym: 'gyms',
  Land_Size: 'landSize',
  Marts: 'marts',
  Restaurants: 'restaurants',
  Room: 'rooms',
  TableCount: 'tables',
}
