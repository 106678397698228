export const environment = {
  apiBaseUrl: 'http://localhost:3000',
  invoiceUrl: 'https://pdf-staging.samraam.com',
  encryptKey: 'qDRCxQ6VZ9q0qvbUiMejRxCmexUd96WQ',
}

const ENV = process.env.REACT_APP_ENV
console.log('ENV: ', ENV)
switch (ENV) {
  case 'production':
    environment.apiBaseUrl = 'https://api-gateway2.samraam.com'
    environment.invoiceUrl = 'https://pdf.samraam.com'
    break
  case 'staging':
    environment.apiBaseUrl = 'https://api-gateway-staging.samraam.com'
    break
  case 'development':
    environment.apiBaseUrl = 'http://localhost:3000'
    environment.invoiceUrl = 'http://localhost:3002'
    break
}
