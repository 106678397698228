import { Button, PropertyTypeSelector } from 'Components'
import { Form, Input, InputNumber as InputNumberBase, Rate, Switch } from 'antd'

import { FormInstance } from 'antd/lib/form'
import { ImageUpload } from 'Components'
import React from 'react'
import styled from 'styled-components'

const InputNumber = styled(InputNumberBase)`
  width: 135px;
`

interface Props {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
  initialValues?: any
  onFileUploaded: (url: string | undefined) => void
  uploadedPhotoUrl: string
}

export const KswmForm = (props: Props) => {
  const {
    onSubmit,
    saving,
    form,
    initialValues,
    onFileUploaded,
    uploadedPhotoUrl,
  } = props

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 9 }}
      initialValues={initialValues}
    >
      <Form.Item label="Photo">
        <ImageUpload
          onFileUploaded={onFileUploaded}
          uploadedPhotoUrl={uploadedPhotoUrl}
        ></ImageUpload>
      </Form.Item>

      <Form.Item
        label="Customer No"
        name="CustomerNo"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Customer Name"
        name="CustomerName"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Contact Person"
        name="ContactPerson"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="Sex" name="Sex" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Title" name="Title" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="PhoneNumber"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="Email" name="Email" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Business Name"
        name="BusinessName"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Business Type"
        name="BusinessType"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Location Code"
        name="LocationCode"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="Block" name="Block" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Sector" name="Sector" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Side of Street"
        name="SideofStreet"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="House Order"
        name="HouseOrder"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Devide Order"
        name="DevideOrder"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="Floort" name="Floor" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Position"
        name="Possition"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item label="Fee" name="Fee" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="VAT" name="VAT" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Total" name="Total" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Currency" name="Currency" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Payment Type"
        name="PaymentType"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Traffic Code"
        name="TrafficeCode"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Collector"
        name="Collector"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Collection Date"
        name="CollectionDate"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="VAT Number"
        name="VATINNumber"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Zone Name"
        name="ZoneName"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="House NO" name="HouseNo" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Street" name="Street" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Village" name="Village" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Commune" name="Commune" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="District" name="District" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item label="Province" name="Province" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Agreement No"
        name="AgreementNo"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Agreement Done By"
        name="AgreementDoneBy)"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Agreement Date"
        name="AgreementDate"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="Entry By" name="EntryBy" rules={[{ required: false }]}>
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Entry Date"
        name="EntryDate"
        rules={[{ required: false }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="Region" name="Region" rules={[{ required: false }]}>
        <Input value="KSWM"></Input>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
