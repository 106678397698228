import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

export type Truck = {
  attributes: {
    OBJECTID: number
    Name: string
    UnitID: string
  }
}

export const getTrucks = async (): Promise<Truck[]> => {
  const url = `${apiBaseUrl}/api/trucks/query`
  const result = await axios.get(url)
  return result.data
}
