import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

export const getAll = async () => {
  const url = `${apiBaseUrl}/api/CollectionCompanies/query`
  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const deleteById = async (id: string) => {
  const url = `${apiBaseUrl}/api/CollectionCompanies/delete/${id}`
  const response = await axios.delete(url)
  const success = response.data.deleteResults[0].success
  if (!success) {
    throw new Error('Failed to delete')
  }
  return response.data
}

export const edit = async (values: any) => {
  const url = `${apiBaseUrl}/api/CollectionCompanies/patch`
  const data = {
    features: {
      attributes: values,
    },
  }
  const response = await axios.patch(url, data)
  const success = response.data.updateResults[0].success
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const add = async (data: any) => {
  const url = `${apiBaseUrl}/api/CollectionCompanies/add`
  const response = await axios.post(url, data)
  const success = response.data.addResults[0].success
  if (!success) {
    throw new Error('Failed to add')
  }
  return response.data
}
