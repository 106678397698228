import {
  DeleteConfirmButton,
  EditButton,
} from 'Components'
import { Modal, Table } from 'antd'
import { Observer, observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import styled from 'styled-components'
import {  collectorStore, ICollector } from 'Models/CollectorModel'
import { CollectorEditContainer } from './Edit'
import { toJS } from 'mobx'

const Container = styled.div``

const UnpaidLabel = styled.div`
  background: #ffabab;
  width: fit-content;
  padding: 0px 8px;
  border-radius: 4px;
`

const PaidLabel = styled.div`
  background: #50e3c2;
  width: fit-content;
  padding: 0px 6px;
  border-radius: 4px;
`
const Amount = styled.div`
  text-align: right;
`

interface CollectorListProps {
  collectors: ICollector[]
  loading?: boolean
}


export const CollectorList = observer((props: CollectorListProps) => {
  const { collectors, loading } = props
  const [shouldShowEditModal, setShouldShowEditModal] = useState(false)
  const [editing, setEditing] = useState<ICollector>()

  function onDeleteClick(collector: ICollector) {
    collector.delete()
  }

  function onEditClick(collector: ICollector) {
    setEditing(collector)
    setShouldShowEditModal(true)
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // render: function render(text: string) { return <Amount>{thousandSeparator(text)}</Amount> },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Actions',
      key: 'action',
      render: function render(text: string, record: ICollector) {
        return (
          <Observer>
            {() => (
              <>
                <EditButton style={{marginRight: 10}} onClick={() => onEditClick(record)}></EditButton>
                <DeleteConfirmButton
                  onConfirmClick={() => onDeleteClick(record)}
                ></DeleteConfirmButton>
              </>
            )}
          </Observer>
        )
      },
    },
  ]

  const handleEditFinish = () => {
    setShouldShowEditModal(false)
  }

  return (
    <Container>
      <Table loading={loading} columns={columns} rowKey='_id' dataSource={collectors} />
      {(shouldShowEditModal && editing) && (
        <Modal
          visible={shouldShowEditModal}
          onCancel={() => setShouldShowEditModal(false)}
          footer={[]}
          width={600}
          title='កែសម្រួល'
        >
          <CollectorEditContainer
            collector={editing}
            onSaveFinish={handleEditFinish}
          ></CollectorEditContainer>
        </Modal>
      )}
    </Container>
  )
})
