import { ISchedule } from 'Models'
import React from 'react'
import { ScheduleForm } from '../ControlPanel/ScheduleForm'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { ITruck } from 'Models/TruckModel'

interface Props {
    schedule: ISchedule
    onScheduleDeleted?: () => void
    onScheduleSaved?: (schedule: ISchedule) => void
    onTruckCurrentLocationLoaded?: (truck: ITruck) => void
}
export const ScheduleItem = observer((props: Props) => {
    const { schedule } = props

    async function saveSchedule(values: any) {
        await schedule.edit(values)
        props.onScheduleSaved && props.onScheduleSaved(schedule)
    }

    async function deleteSchedule() {
        await schedule.delete()
        props.onScheduleDeleted && props.onScheduleDeleted()
    }

    return <ScheduleForm
            saving={schedule.isSaving}
            deleting={schedule.isDeleting}
            isNew={false}
            onDeleteClick={deleteSchedule}
            initialValues={schedule}
            onFinish={saveSchedule}
            onTruckCurrentLocationLoaded={props.onTruckCurrentLocationLoaded}
        ></ScheduleForm>
})
