export type Commune = {
  name: {
    km: string
    latin: string
  }
  id: string
}

const communes: Commune[] = [
  { name: { km: 'ឆ្លូង', latin: 'Chhloung' }, id: '100101' },
  { name: { km: 'ដំរីផុង', latin: 'Damrei Phong' }, id: '100102' },
  { name: { km: 'ហាន់ជ័យ', latin: 'Han Chey' }, id: '100103' },
  { name: { km: 'កំពង់ដំរី', latin: 'Kampong Damrei' }, id: '100104' },
  { name: { km: 'កញ្ជរ', latin: 'Kanhchor' }, id: '100105' },
  { name: { km: 'ខ្សាច់អណ្ដែត', latin: 'Khsach Andeth' }, id: '100106' },
  { name: { km: 'ពង្រ', latin: 'Pongro' }, id: '100107' },
  { name: { km: 'ព្រែកសាម៉ាន់', latin: 'Preaek Saman' }, id: '100108' },
  { name: { km: 'កោះទ្រង់', latin: 'Kaoh Trong' }, id: '100207' },
  { name: { km: 'ក្រគរ', latin: 'Krakor' }, id: '100208' },
  { name: { km: 'ក្រចេះ', latin: 'Kracheh' }, id: '100209' },
  { name: { km: 'អូរឫស្សី', latin: 'Ou Ruessei' }, id: '100210' },
  { name: { km: 'រកាកណ្ដាល', latin: 'Roka Kandal' }, id: '100211' },
  { name: { km: 'ចំបក់', latin: 'Chambâk' }, id: '100301' },
  { name: { km: 'ជ្រោយបន្ទាយ', latin: 'Chrouy Banteay' }, id: '100302' },
  { name: { km: 'កំពង់គរ', latin: 'Kampong Kor' }, id: '100303' },
  { name: { km: 'កោះតាស៊ុយ', latin: 'Koh Ta Suy' }, id: '100304' },
  { name: { km: 'ព្រែកប្រសព្វ', latin: 'Preaek Prasab' }, id: '100305' },
  { name: { km: 'ឫស្សីកែវ', latin: 'Russey Keo' }, id: '100306' },
  { name: { km: 'សោប', latin: 'Saob' }, id: '100307' },
  { name: { km: 'តាម៉ៅ', latin: 'Ta Mao' }, id: '100308' },
  { name: { km: 'បឹងចារ', latin: 'Boeng Char' }, id: '100401' },
  { name: { km: 'កំពង់ចាម', latin: 'Kampong Cham' }, id: '100402' },
  { name: { km: 'ក្បាលដំរី', latin: 'Kbal Damrei' }, id: '100403' },
  { name: { km: 'កោះខ្ញែរ', latin: 'Kaoh Khnhaer' }, id: '100404' },
  { name: { km: 'អូរគ្រៀង', latin: 'Ou Krieng' }, id: '100405' },
  { name: { km: 'រលួសមានជ័យ', latin: 'Roluos Mean Chey' }, id: '100406' },
  { name: { km: 'សំបូរ', latin: 'Sambour' }, id: '100407' },
  { name: { km: 'សណ្ដាន់', latin: 'Sandan' }, id: '100408' },
  { name: { km: 'ស្រែជិះ', latin: 'Srae Chis' }, id: '100409' },
  { name: { km: 'វឌ្ឍនៈ', latin: 'Voadthonak' }, id: '100410' },
  { name: { km: 'ឃ្សឹម', latin: 'Khsuem' }, id: '100501' },
  { name: { km: 'ពីរធ្នូ', latin: 'Pir Thnu' }, id: '100502' },
  { name: { km: 'ស្នួល', latin: 'Snuol' }, id: '100503' },
  { name: { km: 'ស្រែចារ', latin: 'Srae Char' }, id: '100504' },
  { name: { km: 'ស្វាយជ្រះ', latin: 'Svay Chreah' }, id: '100505' },
  { name: { km: 'គ្រញូងសែនជ័យ', latin: 'Kronhoung Saen Chey' }, id: '100506' },
  { name: { km: 'បុសលាវ', latin: 'Bos Leav' }, id: '100601' },
  { name: { km: 'ចង្ក្រង់', latin: 'Changkrang' }, id: '100602' },
  { name: { km: 'ដារ', latin: 'Dar' }, id: '100603' },
  { name: { km: 'កន្ទួត', latin: 'Kantuot' }, id: '100604' },
  { name: { km: 'គោលាប់', latin: 'Kou Loab' }, id: '100605' },
  { name: { km: 'កោះច្រែង', latin: 'Kaoh Chraeng' }, id: '100606' },
  { name: { km: 'សំបុក', latin: 'Sambok' }, id: '100607' },
  { name: { km: 'ថ្មអណ្ដើក', latin: 'Thma Andaeuk' }, id: '100608' },
  { name: { km: 'ថ្មគ្រែ', latin: 'Thma Kreae' }, id: '100609' },
  { name: { km: 'ថ្មី', latin: 'Thmei' }, id: '100610' },
  { name: { km: 'ចុងផ្លាស់', latin: 'Chong Phlah' }, id: '110101' },
  { name: { km: 'មេម៉ង់', latin: 'Memang' }, id: '110102' },
  { name: { km: 'ស្រែឈូក', latin: 'Srae Chhuk' }, id: '110103' },
  { name: { km: 'ស្រែខ្ទុម', latin: 'Srae Khtum' }, id: '110104' },
  { name: { km: 'ស្រែព្រះ', latin: 'Srae Preah' }, id: '110105' },
  { name: { km: 'ណងឃីលិក', latin: 'Nang Khi Lik' }, id: '110201' },
  { name: { km: 'អ បួនលើ', latin: 'A Buon Leu' }, id: '110202' },
  { name: { km: 'រយ៉', latin: 'Roya' }, id: '110203' },
  { name: { km: 'សុខសាន្ដ', latin: 'Sokh Sant' }, id: '110204' },
  { name: { km: 'ស្រែហ៊ុយ', latin: 'Srae Huy' }, id: '110205' },
  { name: { km: 'ស្រែសង្គម', latin: 'Srae Sangkum' }, id: '110206' },
  { name: { km: 'ដាក់ដាំ', latin: 'Dak Dam' }, id: '110301' },
  { name: { km: 'សែនមនោរម្យ', latin: 'Saen Monourom' }, id: '110302' },
  { name: { km: 'ក្រង់តេះ', latin: 'Krang Teh' }, id: '110401' },
  { name: { km: 'ពូជ្រៃ', latin: 'Pu Chrey' }, id: '110402' },
  { name: { km: 'ស្រែអំពូម', latin: 'Srae Ampum' }, id: '110403' },
  { name: { km: 'ប៊ូស្រា', latin: 'Bu Sra' }, id: '110404' },
  { name: { km: 'មនោរម្យ', latin: 'Monourom' }, id: '110501' },
  { name: { km: 'សុខដុម', latin: 'Sokh Dom' }, id: '110502' },
  { name: { km: 'ស្ពានមានជ័យ', latin: 'Spean Mean Chey' }, id: '110503' },
  { name: { km: 'រមនា', latin: 'Romonea' }, id: '110504' },
  { name: { km: 'ទន្លេបាសាក់', latin: 'Tonle Basak' }, id: '120101' },
  { name: { km: 'ទួលទំពូងទី ២', latin: 'Tuol Tumpung Ti Pir' }, id: '120109' },
  { name: { km: 'ទួលទំពូងទី ១', latin: 'Tuol Tumpung Ti Muoy' }, id: '120110' },
  { name: { km: 'បឹងត្របែក', latin: 'Boeng Trabaek' }, id: '120111' },
  { name: { km: 'ផ្សារដើមថ្កូវ', latin: 'Phsar Daeum Thkov' }, id: '120112' },
  { name: { km: 'ផ្សារថ្មីទី ១', latin: 'Phsar Thmei Ti Muoy' }, id: '120201' },
  { name: { km: 'ផ្សារថ្មីទី ២', latin: 'Phsar Thmei Ti Pir' }, id: '120202' },
  { name: { km: 'ផ្សារថ្មីទី ៣', latin: 'Phsar Thmei Ti Bei' }, id: '120203' },
  { name: { km: 'បឹងរាំង', latin: 'Boeng Reang' }, id: '120204' },
  {
    name: { km: 'ផ្សារកណ្ដាលទី១', latin: 'Phsar Kandal Ti Muoy' },
    id: '120205',
  },
  {
    name: { km: 'ផ្សារកណ្ដាលទី២', latin: 'Phsar Kandal Ti Pir' },
    id: '120206',
  },
  { name: { km: 'ចតុមុខ', latin: 'Chakto Mukh' }, id: '120207' },
  { name: { km: 'ជ័យជំនះ', latin: 'Chey Chummeah' }, id: '120208' },
  { name: { km: 'ផ្សារចាស់', latin: 'Phsar Chas' }, id: '120209' },
  { name: { km: 'ស្រះចក', latin: 'Srah Chak' }, id: '120210' },
  { name: { km: 'វត្ដភ្នំ', latin: 'Voat Phnum' }, id: '120211' },
  { name: { km: 'អូរឫស្សីទី ១', latin: 'Ou Ruessei Ti Muoy' }, id: '120301' },
  { name: { km: 'អូរឫស្សីទី ២', latin: 'Ou Ruessei Ti Pir' }, id: '120302' },
  { name: { km: 'អូរឫស្សីទី ៣', latin: 'Ou Ruessei Ti Bei' }, id: '120303' },
  { name: { km: 'អូរឫស្សីទី ៤', latin: 'Ou Ruessei Ti Buon' }, id: '120304' },
  { name: { km: 'មនោរម្យ', latin: 'Monourom' }, id: '120305' },
  { name: { km: 'មិត្ដភាព', latin: 'Mittapheap' }, id: '120306' },
  { name: { km: 'វាលវង់', latin: 'Veal Vong' }, id: '120307' },
  { name: { km: 'បឹងព្រលឹត', latin: 'Boeng Proluet' }, id: '120308' },
  {
    name: { km: 'ផ្សារដេប៉ូទី ១', latin: 'Phsar Depou Ti Muoy' },
    id: '120401',
  },
  { name: { km: 'ផ្សារដេប៉ូទី ២', latin: 'Phsar Depou Ti Pir' }, id: '120402' },
  { name: { km: 'ផ្សារដេប៉ូទី ៣', latin: 'Phsar Depou Ti Bei' }, id: '120403' },
  { name: { km: 'ទឹកល្អក់ទី ១', latin: "Tuek L'ak Ti Muoy" }, id: '120404' },
  { name: { km: 'ទឹកល្អក់ទី ២', latin: "Tuek L'ak Ti Pir" }, id: '120405' },
  { name: { km: 'ទឹកល្អក់ទី ៣', latin: "Tuek L'ak Ti Bei" }, id: '120406' },
  { name: { km: 'បឹងកក់ទី ១', latin: 'Boeng Kak Ti Muoy' }, id: '120407' },
  { name: { km: 'បឹងកក់ទី ២', latin: 'Boeng Kak Ti Pir' }, id: '120408' },
  { name: { km: 'ផ្សារដើមគរ', latin: 'Phsar Daeum Kor' }, id: '120409' },
  { name: { km: 'បឹងសាឡាង', latin: 'Boeng Salang' }, id: '120410' },
  { name: { km: 'ដង្កោ', latin: 'Dangkao' }, id: '120501' },
  { name: { km: 'ពងទឹក', latin: 'Pong Tuek' }, id: '120507' },
  { name: { km: 'ព្រៃវែង', latin: 'Prey Veaeng' }, id: '120508' },
  { name: { km: 'ព្រៃស', latin: 'Prey Sa' }, id: '120510' },
  { name: { km: 'ក្រាំងពង្រ', latin: 'Krang Pongro' }, id: '120512' },
  { name: { km: 'សាក់សំពៅ', latin: 'Sak Sampov' }, id: '120514' },
  { name: { km: 'ជើងឯក', latin: 'Cheung Aek' }, id: '120515' },
  { name: { km: 'គងនយ', latin: 'Kong Noy' }, id: '120516' },
  { name: { km: 'ព្រែកកំពឹស', latin: 'Preaek Kampues' }, id: '120517' },
  { name: { km: 'រលួស', latin: 'Roluos' }, id: '120518' },
  { name: { km: 'ស្ពានថ្ម', latin: 'Spean Thma' }, id: '120519' },
  { name: { km: 'ទៀន', latin: 'Tien' }, id: '120520' },
  { name: { km: 'ចាក់អង្រែលើ', latin: 'Chak Angrae Leu' }, id: '120606' },
  { name: { km: 'ចាក់អង្រែក្រោម', latin: 'Chak Angrae Kraom' }, id: '120607' },
  { name: { km: 'ស្ទឹងមានជ័យទី១', latin: 'Stueng Mean chey 1' }, id: '120608' },
  { name: { km: 'ស្ទឹងមានជ័យទី២', latin: 'Stueng Mean chey 2' }, id: '120609' },
  { name: { km: 'ស្ទឹងមានជ័យទី៣', latin: 'Stueng Mean chey 3' }, id: '120610' },
  { name: { km: 'បឹងទំពុនទី១', latin: 'Boeng Tumpun 1' }, id: '120611' },
  { name: { km: 'បឹងទំពុនទី២', latin: 'Boeng Tumpun 2' }, id: '120612' },
  { name: { km: 'ស្វាយប៉ាក', latin: 'Svay Pak' }, id: '120703' },
  {
    name: { km: 'គីឡូម៉ែត្រលេខ៦', latin: 'Kilomaetr Lekh Prammuoy' },
    id: '120704',
  },
  { name: { km: 'ឫស្សីកែវ', latin: 'Ruessei Kaev' }, id: '120706' },
  {
    name: { km: 'ច្រាំងចំរេះទី ១', latin: 'Chrang Chamreh Ti Muoy' },
    id: '120711',
  },
  {
    name: { km: 'ច្រាំងចំរេះទី ២', latin: 'Chrang Chamreh Ti Pir' },
    id: '120712',
  },
  { name: { km: 'ទួលសង្កែទី១', latin: 'Tuol Sangkae 1' }, id: '120713' },
  { name: { km: 'ទួលសង្កែទី២', latin: 'Tuol Sangkae 2' }, id: '120714' },
  { name: { km: 'ភ្នំពេញថ្មី', latin: 'Phnom Penh Thmei' }, id: '120801' },
  { name: { km: 'ទឹកថ្លា', latin: 'Tuek Thla' }, id: '120802' },
  { name: { km: 'ឃ្មួញ', latin: 'Khmuonh' }, id: '120803' },
  { name: { km: 'ក្រាំងធ្នង់', latin: 'Krang Thnong' }, id: '120807' },
  { name: { km: 'អូរបែកក្អម', latin: "Ou Baek K'am" }, id: '120808' },
  { name: { km: 'គោកឃ្លាង', latin: 'Kouk Khleang' }, id: '120809' },
  { name: { km: 'ត្រពាំងក្រសាំង', latin: 'Trapeang Krasang' }, id: '120901' },
  { name: { km: 'សំរោងក្រោម', latin: 'Samraong Kraom' }, id: '120906' },
  { name: { km: 'ចោមចៅទី១', latin: 'Chaom Chau 1' }, id: '120914' },
  { name: { km: 'ចោមចៅទី២', latin: 'Chaom Chau 2' }, id: '120915' },
  { name: { km: 'ចោមចៅទី៣', latin: 'Chaom Chau 3' }, id: '120916' },
  { name: { km: 'កាកាបទី១', latin: 'Kakab 1' }, id: '120917' },
  { name: { km: 'កាកាបទី២', latin: 'Kakab 2' }, id: '120918' },
  { name: { km: 'ជ្រោយចង្វារ', latin: 'Chrouy Changvar' }, id: '121001' },
  { name: { km: 'ព្រែកលៀប', latin: 'Preaek Lieb' }, id: '121002' },
  { name: { km: 'ព្រែកតាសេក', latin: 'Preaek Ta Sek' }, id: '121003' },
  { name: { km: 'កោះដាច់', latin: 'Kaoh Dach' }, id: '121004' },
  { name: { km: 'បាក់ខែង', latin: 'Bak Kaeng' }, id: '121005' },
  { name: { km: 'ព្រែកព្នៅ', latin: 'Preaek Phnov' }, id: '121101' },
  { name: { km: 'ពញាពន់', latin: 'Ponhea Pon' }, id: '121102' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '121103' },
  { name: { km: 'គោករកា', latin: 'Kouk Roka' }, id: '121104' },
  { name: { km: 'ពន្សាំង', latin: 'Ponsang' }, id: '121105' },
  {
    name: { km: 'ច្បារអំពៅទី ១', latin: 'Chhbar Ampov Ti Muoy' },
    id: '121201',
  },
  { name: { km: 'ច្បារអំពៅទី ២', latin: 'Chbar Ampov Ti Pir' }, id: '121202' },
  { name: { km: 'និរោធ', latin: 'Nirouth' }, id: '121203' },
  { name: { km: 'ព្រែកប្រា', latin: 'Preaek Pra' }, id: '121204' },
  { name: { km: 'វាលស្បូវ', latin: 'Veal Sbov' }, id: '121205' },
  { name: { km: 'ព្រែកឯង', latin: 'Preaek Aeng' }, id: '121206' },
  { name: { km: 'ក្បាលកោះ', latin: 'Kbal Kaoh' }, id: '121207' },
  { name: { km: 'ព្រែកថ្មី', latin: 'Preaek Thmei' }, id: '121208' },
  {
    name: { km: 'បឹងកេងកងទី ១', latin: 'Boeng Keng Kang Ti Muoy' },
    id: '121301',
  },
  {
    name: { km: 'បឹងកេងកងទី ២', latin: 'Boeng Keng Kang Ti Pir' },
    id: '121302',
  },
  {
    name: { km: 'បឹងកេងកងទី ៣', latin: 'Boeng Keng Kang Ti Bei' },
    id: '121303',
  },
  { name: { km: 'អូឡាំពិក', latin: 'Olympic' }, id: '121304' },
  { name: { km: 'ទំនប់ទឹក', latin: 'Tumnob Tuek' }, id: '121305' },
  {
    name: { km: 'ទួលស្វាយព្រៃទី ១', latin: 'Tuol Svay Prey Ti Muoy' },
    id: '121306',
  },
  {
    name: { km: 'ទួលស្វាយព្រៃទី ២', latin: 'Tuol Svay Prey Ti Pir' },
    id: '121307',
  },
  { name: { km: 'កំបូល', latin: 'Kamboul' }, id: '121401' },
  { name: { km: 'កន្ទោក', latin: 'Kantaok' }, id: '121402' },
  { name: { km: 'ឪឡោក', latin: 'Ovlaok' }, id: '121403' },
  { name: { km: 'ស្នោរ', latin: 'Snaor' }, id: '121404' },
  { name: { km: 'ភ្លើងឆេះរទេះ', latin: 'Phleung Chheh Roteh' }, id: '121405' },
  { name: { km: 'បឹងធំ', latin: 'Boeng Thum' }, id: '121406' },
  { name: { km: 'ប្រទះឡាង', latin: 'Prateah Lang' }, id: '121407' },
  { name: { km: 'ស្អាង', latin: "S'ang" }, id: '130101' },
  { name: { km: 'តស៊ូ', latin: 'Tasu' }, id: '130102' },
  { name: { km: 'ខ្យង', latin: 'Khyang' }, id: '130103' },
  { name: { km: 'ច្រាច់', latin: 'Chrach' }, id: '130104' },
  { name: { km: 'ធ្មា', latin: 'Thmea' }, id: '130105' },
  { name: { km: 'ពុទ្រា', latin: 'Putrea' }, id: '130106' },
  { name: { km: 'ឆែបមួយ', latin: 'Chhaeb Muoy' }, id: '130201' },
  { name: { km: 'ឆែបពីរ', latin: 'Chhaeb Pir' }, id: '130202' },
  { name: { km: 'សង្កែមួយ', latin: 'Sangkae Muoy' }, id: '130203' },
  { name: { km: 'សង្កែពីរ', latin: 'Sangkae Pir' }, id: '130204' },
  { name: { km: 'ម្លូព្រៃមួយ', latin: 'Mlu Prey Muoy' }, id: '130205' },
  { name: { km: 'ម្លូព្រៃពីរ', latin: 'Mlu Prey Pir' }, id: '130206' },
  { name: { km: 'កំពង់ស្រឡៅមួយ', latin: 'Kampong Sralau Muoy' }, id: '130207' },
  { name: { km: 'កំពង់ស្រឡៅពីរ', latin: 'Kampong Sralau Pir' }, id: '130208' },
  { name: { km: 'ជាំក្សាន្ដ', latin: 'Choam Ksant' }, id: '130301' },
  { name: { km: 'ទឹកក្រហម', latin: 'Tuek Kraham' }, id: '130302' },
  { name: { km: 'ព្រីងធំ', latin: 'Pring Thum' }, id: '130303' },
  { name: { km: 'រំដោះស្រែ', latin: 'Rumdaoh Srae' }, id: '130304' },
  { name: { km: 'យាង', latin: 'Yeang' }, id: '130305' },
  { name: { km: 'កន្ទួត', latin: 'Kantuot' }, id: '130306' },
  { name: { km: 'ស្រអែម', latin: 'Sror Aem' }, id: '130307' },
  { name: { km: 'មរកត', latin: 'Morokot' }, id: '130308' },
  { name: { km: 'គូលែនត្បូង', latin: 'Kuleaen Tboung' }, id: '130401' },
  { name: { km: 'គូលែនជើង', latin: 'Kuleaen Cheung' }, id: '130402' },
  { name: { km: 'ថ្មី', latin: 'Thmei' }, id: '130403' },
  { name: { km: 'ភ្នំពេញ', latin: 'Phnum Penh' }, id: '130404' },
  { name: { km: 'ភ្នំត្បែងពីរ', latin: 'Phnum Tbaeng Pir' }, id: '130405' },
  { name: { km: 'ស្រយង់', latin: 'Srayang' }, id: '130406' },
  { name: { km: 'របៀប', latin: 'Robieb' }, id: '130501' },
  { name: { km: 'រស្មី', latin: 'Reaksmei' }, id: '130502' },
  { name: { km: 'រហ័ស', latin: 'Rohas' }, id: '130503' },
  { name: { km: 'រុងរឿង', latin: 'Rung Roeang' }, id: '130504' },
  { name: { km: 'រីករាយ', latin: 'Rik Reay' }, id: '130505' },
  { name: { km: 'រួសរាន់', latin: 'Ruos Roan' }, id: '130506' },
  { name: { km: 'រតនៈ', latin: 'Rotanak' }, id: '130507' },
  { name: { km: 'រៀបរយ', latin: 'Rieb Roy' }, id: '130508' },
  { name: { km: 'រក្សា', latin: 'Reaksa' }, id: '130509' },
  { name: { km: 'រំដោះ', latin: 'Rumdaoh' }, id: '130510' },
  { name: { km: 'រមទម', latin: 'Romtum' }, id: '130511' },
  { name: { km: 'រមណីយ', latin: 'Romoneiy' }, id: '130512' },
  { name: { km: 'ចំរើន', latin: 'Chamraeun' }, id: '130601' },
  { name: { km: 'រអាង', latin: "Ro'ang" }, id: '130602' },
  { name: { km: 'ភ្នំត្បែងមួយ', latin: 'Phnum Tbaeng Muoy' }, id: '130603' },
  { name: { km: 'ស្ដៅ', latin: 'Sdau' }, id: '130604' },
  { name: { km: 'រណសិរ្ស', latin: 'Ronak Ser' }, id: '130605' },
  { name: { km: 'ឈានមុខ', latin: 'Chhean Mukh' }, id: '130703' },
  { name: { km: 'ពោធិ៍', latin: 'Pou' }, id: '130704' },
  { name: { km: 'ប្រមេរុ', latin: 'Prame' }, id: '130705' },
  { name: { km: 'ព្រះឃ្លាំង', latin: 'Preah Khleang' }, id: '130706' },
  { name: { km: 'កំពង់ប្រណាក', latin: 'Kampong Pranak' }, id: '130801' },
  { name: { km: 'ប៉ាលហាល', latin: 'Pal Hal' }, id: '130802' },
  { name: { km: 'បឹងព្រះ', latin: 'Boeng Preah' }, id: '140101' },
  { name: { km: 'ជើងភ្នំ', latin: 'Cheung Phnum' }, id: '140102' },
  { name: { km: 'ឈើកាច់', latin: 'Chheu Kach' }, id: '140103' },
  { name: { km: 'រក្សជ័យ', latin: 'Reaks Chey' }, id: '140104' },
  { name: { km: 'រោងដំរី', latin: 'Roung Damrei' }, id: '140105' },
  { name: { km: 'ស្ដៅកោង', latin: 'Sdau Kaong' }, id: '140106' },
  { name: { km: 'ស្ពឺ  ក', latin: 'Spueu Ka' }, id: '140107' },
  { name: { km: 'ស្ពឺ  ខ', latin: 'Spueu Kha' }, id: '140108' },
  { name: { km: 'ធាយ', latin: 'Theay' }, id: '140109' },
  { name: { km: 'ជាច', latin: 'Cheach' }, id: '140201' },
  { name: { km: 'ដូនកឹង', latin: 'Doun Koeng' }, id: '140202' },
  { name: { km: 'ក្រញូង', latin: 'Kranhung' }, id: '140203' },
  { name: { km: 'ក្របៅ', latin: 'Krabau' }, id: '140204' },
  { name: { km: 'ស៊ាងឃ្វាង', latin: 'Seang Khveang' }, id: '140205' },
  { name: { km: 'ស្មោងខាងជើង', latin: 'Smaong Khang Cheung' }, id: '140206' },
  { name: { km: 'ស្មោងខាងត្បូង', latin: 'Smaong Khang Tboung' }, id: '140207' },
  { name: { km: 'ត្របែក', latin: 'Trabaek' }, id: '140208' },
  { name: { km: 'អន្សោង', latin: 'Ansaong' }, id: '140301' },
  { name: { km: 'ចាម', latin: 'Cham' }, id: '140302' },
  { name: { km: 'ជាងដែក', latin: 'Cheang Daek' }, id: '140303' },
  { name: { km: 'ជ្រៃ', latin: 'Chrey' }, id: '140304' },
  { name: { km: 'កន្សោមអក', latin: 'Kansoam Ak' }, id: '140305' },
  { name: { km: 'គោខ្ចក', latin: 'Kou Khchak' }, id: '140306' },
  { name: { km: 'កំពង់ត្របែក', latin: 'Kampong Trabaek' }, id: '140307' },
  { name: { km: 'ពាមមន្ទារ', latin: 'Peam Montear' }, id: '140308' },
  { name: { km: 'ប្រាសាទ', latin: 'Prasat' }, id: '140309' },
  { name: { km: 'ប្រធាតុ', latin: 'Pratheat' }, id: '140310' },
  { name: { km: 'ព្រៃឈរ', latin: 'Prey Chhor' }, id: '140311' },
  { name: { km: 'ព្រៃពោន', latin: 'Prey Poun' }, id: '140312' },
  { name: { km: 'ថ្កូវ', latin: 'Thkov' }, id: '140313' },
  { name: { km: 'ចុងអំពិល', latin: 'Chong Ampil' }, id: '140401' },
  { name: { km: 'កញ្ជ្រៀច', latin: 'Kanhchriech' }, id: '140402' },
  { name: { km: 'ក្ដឿងរាយ', latin: 'Kdoeang Reay' }, id: '140403' },
  { name: { km: 'គោកគង់កើត', latin: 'Kouk Kong Kaeut' }, id: '140404' },
  { name: { km: 'គោកគង់លិច', latin: 'Kouk Kong Lech' }, id: '140405' },
  { name: { km: 'ព្រាល', latin: 'Preal' }, id: '140406' },
  { name: { km: 'ថ្មពូន', latin: 'Thma Pun' }, id: '140407' },
  { name: { km: 'ត្នោត', latin: 'Tnaot' }, id: '140408' },
  { name: { km: 'អង្គរសរ', latin: 'Angkor Sar' }, id: '140501' },
  { name: { km: 'ច្រេស', latin: 'Chres' }, id: '140502' },
  { name: { km: 'ជីផុច', latin: 'Chi Phoch' }, id: '140503' },
  { name: { km: 'ព្រៃឃ្នេស', latin: 'Prey Khnes' }, id: '140504' },
  { name: { km: 'ព្រៃរំដេង', latin: 'Prey Rumdeng' }, id: '140505' },
  { name: { km: 'ព្រៃទទឹង', latin: 'Prey Totueng' }, id: '140506' },
  { name: { km: 'ស្វាយជ្រុំ', latin: 'Svay Chrum' }, id: '140507' },
  { name: { km: 'ត្រពាំងស្រែ', latin: 'Trapeang Srae' }, id: '140508' },
  { name: { km: 'អង្គរអង្គ', latin: 'Angkor Angk' }, id: '140601' },
  { name: { km: 'កំពង់ប្រាសាទ', latin: 'Kampong Prasat' }, id: '140602' },
  { name: { km: 'កោះចេក', latin: 'Kaoh Chek' }, id: '140603' },
  { name: { km: 'កោះរកា', latin: 'Kaoh Roka' }, id: '140604' },
  { name: { km: 'កោះសំពៅ', latin: 'Kaoh Sampov' }, id: '140605' },
  { name: { km: 'ក្រាំងតាយ៉ង', latin: 'Krang Ta Yang' }, id: '140606' },
  { name: { km: 'ព្រែកក្របៅ', latin: 'Preaek Krabau' }, id: '140607' },
  { name: { km: 'ព្រែកសំបួរ', latin: 'Preaek Sambuor' }, id: '140608' },
  { name: { km: 'ឫស្សីស្រុក', latin: 'Ruessei Srok' }, id: '140609' },
  { name: { km: 'ស្វាយភ្លោះ', latin: 'Svay Phluoh' }, id: '140610' },
  { name: { km: 'បាបោង', latin: 'Ba Baong' }, id: '140701' },
  { name: { km: 'បន្លិចប្រាសាទ', latin: 'Banlich Prasat' }, id: '140702' },
  { name: { km: 'អ្នកលឿង', latin: 'Neak Loeang' }, id: '140703' },
  { name: { km: 'ពាមមានជ័យ', latin: 'Peam Mean Chey' }, id: '140704' },
  { name: { km: 'ពាមរក៍', latin: 'Peam Ro' }, id: '140705' },
  { name: { km: 'ព្រែកខ្សាយ ក', latin: 'Preaek Khsay Ka' }, id: '140706' },
  { name: { km: 'ព្រែកខ្សាយ ខ', latin: 'Preaek Khsay Kha' }, id: '140707' },
  { name: { km: 'ព្រៃកណ្ដៀង', latin: 'Prey Kandieng' }, id: '140708' },
  { name: { km: 'កំពង់ពពិល', latin: 'Kampong Popil' }, id: '140801' },
  { name: { km: 'កញ្ចំ', latin: 'Kanhcham' }, id: '140802' },
  { name: { km: 'កំពង់ប្រាំង', latin: 'Kampong Prang' }, id: '140803' },
  { name: { km: 'មេសរប្រចាន់', latin: 'Mesar Prachan' }, id: '140805' },
  { name: { km: 'ព្រៃព្នៅ', latin: 'Prey Pnov' }, id: '140807' },
  { name: { km: 'ព្រៃស្នៀត', latin: 'Prey Sniet' }, id: '140808' },
  { name: { km: 'ព្រៃស្រឡិត', latin: 'Prey Sralet' }, id: '140809' },
  { name: { km: 'រាប', latin: 'Reab' }, id: '140810' },
  { name: { km: 'រកា', latin: 'Roka' }, id: '140811' },
  { name: { km: 'អង្គររាជ្យ', latin: 'Angkor Reach' }, id: '140901' },
  { name: { km: 'បន្ទាយចក្រី', latin: 'Banteay Chakrei' }, id: '140902' },
  { name: { km: 'បឹងដោល', latin: 'Boeng Daol' }, id: '140903' },
  { name: { km: 'ជៃកំពក', latin: 'Chey Kampok' }, id: '140904' },
  { name: { km: 'កំពង់សឹង', latin: 'Kampong Soeng' }, id: '140905' },
  { name: { km: 'ក្រាំងស្វាយ', latin: 'Krang Svay' }, id: '140906' },
  { name: { km: 'ល្វា', latin: 'Lvea' }, id: '140907' },
  { name: { km: 'ព្រះស្ដេច', latin: 'Preah Sdach' }, id: '140908' },
  { name: { km: 'រាធរ', latin: 'Reathor' }, id: '140909' },
  { name: { km: 'រំចេក', latin: 'Rumchek' }, id: '140910' },
  { name: { km: 'សេនារាជឧត្ដម', latin: 'Sena Reach Otdam' }, id: '140911' },
  { name: { km: 'បារាយណ៍', latin: 'Baray' }, id: '141001' },
  { name: { km: 'ជើងទឹក', latin: 'Cheung Tuek' }, id: '141002' },
  { name: { km: 'កំពង់លាវ', latin: 'Kampong Leav' }, id: '141003' },
  { name: { km: 'តាកោ', latin: 'Ta Kao' }, id: '141004' },
  { name: { km: 'ពោធិ៍រៀង', latin: 'Pou Rieng' }, id: '141101' },
  { name: { km: 'ព្រែកអន្ទះ', latin: 'Preaek Anteah' }, id: '141102' },
  { name: { km: 'ព្រែកជ្រៃ', latin: 'Preaek Chrey' }, id: '141103' },
  { name: { km: 'ព្រៃកន្លោង', latin: 'Prey Kanlaong' }, id: '141104' },
  { name: { km: 'កំពង់ឫស្សី', latin: 'Kampong Ruessei' }, id: '141106' },
  { name: { km: 'ព្រែកតាសរ', latin: 'Preaek Ta Sar' }, id: '141107' },
  { name: { km: 'អំពិលក្រៅ', latin: 'Ampil Krau' }, id: '141201' },
  { name: { km: 'ជ្រៃឃ្មុំ', latin: 'Chrey Khmum' }, id: '141202' },
  { name: { km: 'ល្វេ', latin: 'Lve' }, id: '141203' },
  { name: { km: 'ព្នៅទី ១', latin: 'Pnov Ti Muoy' }, id: '141204' },
  { name: { km: 'ព្នៅទី ២', latin: 'Pnov Ti Pir' }, id: '141205' },
  { name: { km: 'ពោធិ៍ទី', latin: 'Pou Ti' }, id: '141206' },
  { name: { km: 'ព្រែកចង្ក្រាន', latin: 'Preaek Changkran' }, id: '141207' },
  { name: { km: 'ព្រៃដើមថ្នឹង', latin: 'Prey Daeum Thnoeng' }, id: '141208' },
  { name: { km: 'ព្រៃទឹង', latin: 'Prey Tueng' }, id: '141209' },
  { name: { km: 'រំលេច', latin: 'Rumlech' }, id: '141210' },
  { name: { km: 'ឫស្សីសាញ់', latin: 'Ruessei Sanh' }, id: '141211' },
  { name: { km: 'អង្គរទ្រេត', latin: 'Angkor Tret' }, id: '141301' },
  { name: { km: 'ជាខ្លាង', latin: 'Chea Khlang' }, id: '141302' },
  { name: { km: 'ជ្រៃ', latin: 'Chrey' }, id: '141303' },
  { name: { km: 'ដំរីពួន', latin: 'Damrei Puon' }, id: '141304' },
  { name: { km: 'មេបុណ្យ', latin: 'Me Bon' }, id: '141305' },
  { name: { km: 'ពានរោង', latin: 'Pean Roung' }, id: '141306' },
  { name: { km: 'ពពឺស', latin: 'Popueus' }, id: '141307' },
  { name: { km: 'ព្រៃខ្លា', latin: 'Prey Khla' }, id: '141308' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '141309' },
  { name: { km: 'ស្វាយអន្ទរ', latin: 'Svay Antor' }, id: '141310' },
  { name: { km: 'ទឹកថ្លា', latin: 'Tuek Thla' }, id: '141311' },
  { name: { km: 'បឹងបត់កណ្ដាល', latin: 'Boeng Bat Kandaol' }, id: '150101' },
  { name: { km: 'បឹងខ្នារ', latin: 'Boeng Khnar' }, id: '150102' },
  { name: { km: 'ខ្នារទទឹង', latin: 'Khnar Totueng' }, id: '150103' },
  { name: { km: 'មេទឹក', latin: 'Me Tuek' }, id: '150104' },
  { name: { km: 'អូរតាប៉ោង', latin: 'Ou Ta Paong' }, id: '150105' },
  { name: { km: 'រំលេច', latin: 'Rumlech' }, id: '150106' },
  { name: { km: 'ស្នាមព្រះ', latin: 'Snam Preah' }, id: '150107' },
  { name: { km: 'ស្វាយដូនកែវ', latin: 'Svay Doun Kaev' }, id: '150108' },
  { name: { km: 'ត្រពាំងជង', latin: 'Trapeang chorng' }, id: '150110' },
  { name: { km: 'អន្លង់វិល', latin: 'Anlong Vil' }, id: '150201' },
  { name: { km: 'កណ្ដៀង', latin: 'Kandieng' }, id: '150203' },
  { name: { km: 'កញ្ជរ', latin: 'Kanhchor' }, id: '150204' },
  { name: { km: 'រាំងទិល', latin: 'Reang Til' }, id: '150205' },
  { name: { km: 'ស្រែស្ដុក', latin: 'Srae Sdok' }, id: '150206' },
  { name: { km: 'ស្វាយលួង', latin: 'Svay Luong' }, id: '150207' },
  { name: { km: 'ស្យា', latin: 'Sya' }, id: '150208' },
  { name: { km: 'វាល', latin: 'Veal' }, id: '150209' },
  { name: { km: 'កោះជុំ', latin: 'Kaoh Chum' }, id: '150210' },
  { name: { km: 'អន្លង់ត្នោត', latin: 'Anlong Tnaot' }, id: '150301' },
  { name: { km: 'អន្សាចំបក់', latin: 'Ansa Chambak' }, id: '150302' },
  { name: { km: 'បឹងកន្ទួត', latin: 'Boeng Kantuot' }, id: '150303' },
  { name: { km: 'ឈើតុំ', latin: 'Chheu Tom' }, id: '150304' },
  { name: { km: 'កំពង់លួង', latin: 'Kampong Luong' }, id: '150305' },
  { name: { km: 'កំពង់ពោធិ៍', latin: 'Kampong Pou' }, id: '150306' },
  { name: { km: 'ក្បាលត្រាច', latin: 'Kbal Trach' }, id: '150307' },
  { name: { km: 'អូរសណ្ដាន់', latin: 'Ou Sandan' }, id: '150308' },
  { name: { km: 'ស្នាអន្សា', latin: 'Sna Ansa' }, id: '150309' },
  { name: { km: 'ស្វាយស', latin: 'Svay Sa' }, id: '150310' },
  { name: { km: 'ត្នោតជុំ', latin: 'Tnaot Chum' }, id: '150311' },
  { name: { km: 'បាក់ចិញ្ចៀន', latin: 'Bak Chenhchien' }, id: '150401' },
  { name: { km: 'លាច', latin: 'Leach' }, id: '150402' },
  { name: { km: 'ព្រងិល', latin: 'Prongil' }, id: '150404' },
  { name: { km: 'រកាត', latin: 'Rokat' }, id: '150405' },
  { name: { km: 'សន្ទ្រែ', latin: 'Santreae' }, id: '150406' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '150407' },
  { name: { km: 'ចំរើនផល', latin: 'Chamraeun Phal' }, id: '150501' },
  { name: { km: 'លលកស', latin: 'Lolok Sa' }, id: '150503' },
  { name: { km: 'ផ្ទះព្រៃ', latin: 'Phteah Prey' }, id: '150504' },
  { name: { km: 'ព្រៃញី', latin: 'Prey Nhi' }, id: '150505' },
  { name: { km: 'រលាប', latin: 'Roleab' }, id: '150506' },
  { name: { km: 'ស្វាយអាត់', latin: 'Svay At' }, id: '150507' },
  { name: { km: 'បន្ទាយដី', latin: 'Banteay Dei' }, id: '150508' },
  { name: { km: 'អូរសោម', latin: 'Ou Saom' }, id: '150601' },
  { name: { km: 'ក្រពើពីរ', latin: 'Krapeu Pir' }, id: '150602' },
  { name: { km: 'អន្លង់រាប', latin: 'Anlong Reab' }, id: '150603' },
  { name: { km: 'ប្រម៉ោយ', latin: 'Pramaoy' }, id: '150604' },
  { name: { km: 'ថ្មដា', latin: 'Thma Da' }, id: '150605' },
  { name: { km: 'តាលោ', latin: 'Ta Lou' }, id: '150701' },
  { name: { km: 'ផ្ទះរុង', latin: 'Phteah Rung' }, id: '150702' },
  { name: { km: 'ម៉ាលិក', latin: 'Malik' }, id: '160101' },
  { name: { km: 'ញ៉ាង', latin: 'Nhang' }, id: '160103' },
  { name: { km: 'តាឡាវ', latin: 'Ta Lav' }, id: '160104' },
  { name: { km: 'កាចាញ', latin: 'Kachanh' }, id: '160201' },
  { name: { km: 'ឡាបានសៀក', latin: 'Labansiek' }, id: '160202' },
  { name: { km: 'យក្ខឡោម', latin: 'Yeak Laom' }, id: '160203' },
  { name: { km: 'បឹងកន្សែង', latin: 'Boeng Kansaeng' }, id: '160204' },
  { name: { km: 'កក់', latin: 'Kak' }, id: '160301' },
  { name: { km: 'កិះចុង', latin: 'Keh Chong' }, id: '160302' },
  { name: { km: 'ឡាមីញ', latin: 'La Minh' }, id: '160303' },
  { name: { km: 'លុងឃុង', latin: 'Lung Khung' }, id: '160304' },
  { name: { km: 'ស៊ើង', latin: 'Saeung' }, id: '160305' },
  { name: { km: 'ទីងចាក់', latin: 'Ting Chak' }, id: '160306' },
  { name: { km: 'សិរីមង្គល', latin: 'Serei Mongkol' }, id: '160401' },
  { name: { km: 'ស្រែអង្គ្រង', latin: 'Srae Angkrorng' }, id: '160402' },
  { name: { km: 'តាអង', latin: 'Ta Ang' }, id: '160403' },
  { name: { km: 'តឺន', latin: 'Teun' }, id: '160404' },
  { name: { km: 'ត្រពាំងច្រេស', latin: 'Trapeang Chres' }, id: '160405' },
  { name: { km: 'ត្រពាំងក្រហម', latin: 'Trapeang Kraham' }, id: '160406' },
  { name: { km: 'ជ័យឧត្ដម', latin: 'Chey Otdam' }, id: '160501' },
  { name: { km: 'កាឡែង', latin: 'Ka Laeng' }, id: '160502' },
  { name: { km: 'ល្បាំង១', latin: 'Lbang Muoy' }, id: '160503' },
  { name: { km: 'ល្បាំង២', latin: 'Lbang Pir' }, id: '160504' },
  { name: { km: 'បាតាង', latin: 'Ba Tang' }, id: '160505' },
  { name: { km: 'សេដា', latin: 'Seda' }, id: '160506' },
  { name: { km: 'ចាអ៊ុង', latin: 'Cha Ung' }, id: '160601' },
  { name: { km: 'ប៉ូយ', latin: 'Pouy' }, id: '160602' },
  { name: { km: 'ឯកភាព', latin: 'Aekakpheap' }, id: '160603' },
  { name: { km: 'កាឡៃ', latin: 'Kalai' }, id: '160604' },
  { name: { km: 'អូរជុំ', latin: 'Ou Chum' }, id: '160605' },
  { name: { km: 'សាមគ្គី', latin: 'Sameakki' }, id: '160606' },
  { name: { km: 'ល្អក់', latin: "L'ak" }, id: '160607' },
  { name: { km: 'បរខាំ', latin: 'Bar Kham' }, id: '160701' },
  { name: { km: 'លំជ័រ', latin: 'Lum Choar' }, id: '160702' },
  { name: { km: 'ប៉ក់ញ៉ៃ', latin: 'Pak Nhai' }, id: '160703' },
  { name: { km: 'ប៉ាតេ', latin: 'Pa Te' }, id: '160704' },
  { name: { km: 'សេសាន', latin: 'Sesan' }, id: '160705' },
  { name: { km: 'សោមធំ', latin: 'Saom Thum' }, id: '160706' },
  { name: { km: 'យ៉ាទុង', latin: 'Ya Tung' }, id: '160707' },
  { name: { km: 'តាវែងលើ', latin: 'Ta Veaeng Leu' }, id: '160801' },
  { name: { km: 'តាវែងក្រោម', latin: 'Ta Veaeng Kraom' }, id: '160802' },
  { name: { km: 'ប៉ុង', latin: 'Pong' }, id: '160901' },
  { name: { km: 'ហាត់ប៉ក់', latin: 'Hat Pak' }, id: '160903' },
  { name: { km: 'កាចូន', latin: 'Ka Choun' }, id: '160904' },
  { name: { km: 'កោះប៉ង់', latin: 'Kaoh Pang' }, id: '160905' },
  { name: { km: 'កោះពាក្យ', latin: 'Kaoh Peak' }, id: '160906' },
  { name: { km: 'កុកឡាក់', latin: 'Kok Lak' }, id: '160907' },
  { name: { km: 'ប៉ាកាឡាន់', latin: 'Pa Kalan' }, id: '160908' },
  { name: { km: 'ភ្នំកុក', latin: 'Phnum Kok' }, id: '160909' },
  { name: { km: 'វើនសៃ', latin: 'Veun Sai' }, id: '160910' },
  { name: { km: 'ចារឈូក', latin: 'Char Chhuk' }, id: '170101' },
  { name: { km: 'ដូនពេង', latin: 'Doun Peng' }, id: '170102' },
  { name: { km: 'គោកដូង', latin: 'Kouk Doung' }, id: '170103' },
  { name: { km: 'គោល', latin: 'Koul' }, id: '170104' },
  { name: { km: 'នគរភាស', latin: 'Nokor Pheas' }, id: '170105' },
  { name: { km: 'ស្រែខ្វាវ', latin: 'Srae Khvav' }, id: '170106' },
  { name: { km: 'តាសោម', latin: 'Ta Saom' }, id: '170107' },
  { name: { km: 'ជប់តាត្រាវ', latin: 'Chob Ta Trav' }, id: '170201' },
  { name: { km: 'លាងដៃ', latin: 'Leang Dai' }, id: '170202' },
  { name: { km: 'ពាក់ស្នែង', latin: 'Peak Snaeng' }, id: '170203' },
  { name: { km: 'ស្វាយចេក', latin: 'Svay Chek' }, id: '170204' },
  { name: { km: 'ខ្នារសណ្ដាយ', latin: 'Khnar Sanday' }, id: '170301' },
  { name: { km: 'ឃុនរាម', latin: 'Khun Ream' }, id: '170302' },
  { name: { km: 'ព្រះដាក់', latin: 'Preah Dak' }, id: '170303' },
  { name: { km: 'រំចេក', latin: 'Rumchek' }, id: '170304' },
  { name: { km: 'រុនតាឯក', latin: 'Run Ta Aek' }, id: '170305' },
  { name: { km: 'ត្បែង', latin: 'Tbaeng' }, id: '170306' },
  { name: { km: 'អន្លង់សំណរ', latin: 'Anlong Samnar' }, id: '170401' },
  { name: { km: 'ជីក្រែង', latin: 'Chi Kraeng' }, id: '170402' },
  { name: { km: 'កំពង់ក្ដី', latin: 'Kampong Kdei' }, id: '170403' },
  { name: { km: 'ខ្វាវ', latin: 'Khvav' }, id: '170404' },
  { name: { km: 'គោកធ្លកក្រោម', latin: 'Kouk Thlok Kraom' }, id: '170405' },
  { name: { km: 'គោកធ្លកលើ', latin: 'Kouk Thlok Leu' }, id: '170406' },
  { name: { km: 'ល្វែងឫស្សី', latin: 'Lveaeng Ruessei' }, id: '170407' },
  { name: { km: 'ពង្រក្រោម', latin: 'Pongro Kraom' }, id: '170408' },
  { name: { km: 'ពង្រលើ', latin: 'Pongro Leu' }, id: '170409' },
  { name: { km: 'ឫស្សីលក', latin: 'Ruessei Lok' }, id: '170410' },
  { name: { km: 'សង្វើយ', latin: 'Sangvaeuy' }, id: '170411' },
  { name: { km: 'ស្ពានត្នោត', latin: 'Spean Tnaot' }, id: '170412' },
  { name: { km: 'ចន្លាសដៃ', latin: 'Chanleas Dai' }, id: '170601' },
  { name: { km: 'កំពង់ថ្កូវ', latin: 'Kampong Thkov' }, id: '170602' },
  { name: { km: 'ក្រឡាញ់', latin: 'Kralanh' }, id: '170603' },
  { name: { km: 'ក្រូចគរ', latin: 'Krouch Kor' }, id: '170604' },
  { name: { km: 'រោងគោ', latin: 'Roung Kou' }, id: '170605' },
  { name: { km: 'សំបួរ', latin: 'Sambuor' }, id: '170606' },
  { name: { km: 'សែនសុខ', latin: 'Saen Sokh' }, id: '170607' },
  { name: { km: 'ស្នួល', latin: 'Snuol' }, id: '170608' },
  { name: { km: 'ស្រណាល', latin: 'Sranal' }, id: '170609' },
  { name: { km: 'តាអាន', latin: 'Ta An' }, id: '170610' },
  { name: { km: 'សសរស្ដម្ភ', latin: 'Sasar Sdam' }, id: '170701' },
  { name: { km: 'ដូនកែវ', latin: 'Doun Kaev' }, id: '170702' },
  { name: { km: 'ក្ដីរុន', latin: 'Kdei Run' }, id: '170703' },
  { name: { km: 'កែវពណ៌', latin: 'Kaev Poar' }, id: '170704' },
  { name: { km: 'ខ្នាត', latin: 'Khnat' }, id: '170705' },
  { name: { km: 'ល្វា', latin: 'Lvea' }, id: '170707' },
  { name: { km: 'មុខប៉ែន', latin: 'Mukh Paen' }, id: '170708' },
  { name: { km: 'ពោធិ៍ទ្រាយ', latin: 'Pou Treay' }, id: '170709' },
  { name: { km: 'ពួក', latin: 'Puok' }, id: '170710' },
  { name: { km: 'ព្រៃជ្រូក', latin: 'Prey Chruk' }, id: '170711' },
  { name: { km: 'រើល', latin: 'Reul' }, id: '170712' },
  { name: { km: 'សំរោងយា', latin: 'Samraong Yea' }, id: '170713' },
  { name: { km: 'ត្រីញ័រ', latin: 'Trei Nhoar' }, id: '170715' },
  { name: { km: 'យាង', latin: 'Yeang' }, id: '170716' },
  { name: { km: 'បាគង', latin: 'Bakong' }, id: '170902' },
  { name: { km: 'បល្ល័ង្ក', latin: 'Ballangk' }, id: '170903' },
  { name: { km: 'កំពង់ភ្លុក', latin: 'Kampong Phluk' }, id: '170904' },
  { name: { km: 'កន្ទ្រាំង', latin: 'Kantreang' }, id: '170905' },
  { name: { km: 'កណ្ដែក', latin: 'Kandaek' }, id: '170906' },
  { name: { km: 'មានជ័យ', latin: 'Mean Chey' }, id: '170907' },
  { name: { km: 'រលួស', latin: 'Roluos' }, id: '170908' },
  { name: { km: 'ត្រពាំងធំ', latin: 'Trapeang Thum' }, id: '170909' },
  { name: { km: 'អំពិល', latin: 'Ampil' }, id: '170910' },
  { name: { km: 'ស្លក្រាម', latin: 'Sla Kram' }, id: '171001' },
  { name: { km: 'ស្វាយដង្គំ', latin: 'Svay Dankum' }, id: '171002' },
  { name: { km: 'គោកចក', latin: 'Kok Chak' }, id: '171003' },
  { name: { km: 'សាលាកំរើក', latin: 'Sala Kamreuk' }, id: '171004' },
  { name: { km: 'នគរធំ', latin: 'Nokor Thum' }, id: '171005' },
  { name: { km: 'ជ្រាវ', latin: 'Chreav' }, id: '171006' },
  { name: { km: 'ចុងឃ្នៀស', latin: 'Chong Khnies' }, id: '171007' },
  { name: { km: 'សំបួរ', latin: 'Sngkat Sambuor' }, id: '171008' },
  { name: { km: 'សៀមរាប', latin: 'Siem Reab' }, id: '171009' },
  { name: { km: 'ស្រង៉ែ', latin: 'Srangae' }, id: '171010' },
  { name: { km: 'ក្របីរៀល', latin: 'Krabei Riel' }, id: '171012' },
  { name: { km: 'ទឹកវិល', latin: 'Tuek Vil' }, id: '171013' },
  { name: { km: 'ចាន់ស', latin: 'Chan Sa' }, id: '171101' },
  { name: { km: 'ដំដែក', latin: 'Dam Daek' }, id: '171102' },
  { name: { km: 'ដានរុន', latin: 'Dan Run' }, id: '171103' },
  { name: { km: 'កំពង់ឃ្លាំង', latin: 'Kampong Khleang' }, id: '171104' },
  { name: { km: 'កៀនសង្កែ', latin: 'Kien Sangkae' }, id: '171105' },
  { name: { km: 'ខ្ចាស់', latin: 'Khchas' }, id: '171106' },
  { name: { km: 'ខ្នារពោធិ៍', latin: 'Khnar Pou' }, id: '171107' },
  { name: { km: 'ពពេល', latin: 'Popel' }, id: '171108' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '171109' },
  { name: { km: 'តាយ៉ែក', latin: 'Ta Yaek' }, id: '171110' },
  { name: { km: 'ជ្រោយនាងងួន', latin: 'Chrouy Neang Nguon' }, id: '171201' },
  { name: { km: 'ក្លាំងហាយ', latin: 'Klang Hay' }, id: '171202' },
  { name: { km: 'ត្រាំសសរ', latin: 'Tram Sasar' }, id: '171203' },
  { name: { km: 'មោង', latin: 'Moung' }, id: '171204' },
  { name: { km: 'ប្រីយ៍', latin: 'Prei' }, id: '171205' },
  { name: { km: 'ស្លែងស្ពាន', latin: 'Slaeng Spean' }, id: '171206' },
  { name: { km: 'បឹងមាលា', latin: 'Boeng Mealea' }, id: '171301' },
  { name: { km: 'កន្ទួត', latin: 'Kantuot' }, id: '171302' },
  { name: { km: 'ខ្នងភ្នំ', latin: 'Khnang Phnum' }, id: '171303' },
  { name: { km: 'ស្វាយលើ', latin: 'Svay Leu' }, id: '171304' },
  { name: { km: 'តាសៀម', latin: 'Ta Siem' }, id: '171305' },
  { name: { km: 'ប្រាសាទ', latin: 'Prasat' }, id: '171401' },
  { name: { km: 'ល្វាក្រាំង', latin: 'Lvea Krang' }, id: '171402' },
  { name: { km: 'ស្រែណូយ', latin: 'Srae Nouy' }, id: '171403' },
  { name: { km: 'ស្វាយ ស', latin: 'Svay Sa' }, id: '171404' },
  { name: { km: 'វ៉ារិន', latin: 'Varin' }, id: '171405' },
  { name: { km: 'លេខ១', latin: 'lek Muoy' }, id: '180101' },
  { name: { km: '២', latin: 'Pir' }, id: '180102' },
  { name: { km: '៣', latin: 'Bei' }, id: '180103' },
  { name: { km: '៤', latin: 'Buon' }, id: '180104' },
  { name: { km: 'អណ្ដូងថ្ម', latin: 'Andoung Thma' }, id: '180201' },
  { name: { km: 'បឹងតាព្រហ្ម', latin: 'Boeng Ta Prum' }, id: '180202' },
  { name: { km: 'បិតត្រាង', latin: 'Bet Trang' }, id: '180203' },
  { name: { km: 'ជើងគោ', latin: 'Cheung Kou' }, id: '180204' },
  { name: { km: 'អូរជ្រៅ', latin: 'Ou Chrov' }, id: '180205' },
  { name: { km: 'អូរឧកញ៉ាហេង', latin: 'Ou Oknha Heng' }, id: '180206' },
  { name: { km: 'ព្រៃនប់', latin: 'Prey Nob' }, id: '180207' },
  { name: { km: 'រាម', latin: 'Ream' }, id: '180208' },
  { name: { km: 'សាមគ្គី', latin: 'Sameakki' }, id: '180209' },
  { name: { km: 'សំរុង', latin: 'Samrong' }, id: '180210' },
  { name: { km: 'ទឹកល្អក់', latin: "Tuek L'ak" }, id: '180211' },
  { name: { km: 'ទឹកថ្លា', latin: 'Tuek Thla' }, id: '180212' },
  { name: { km: 'ទួលទទឹង', latin: 'Tuol Totueng' }, id: '180213' },
  { name: { km: 'វាលរេញ', latin: 'Veal Renh' }, id: '180214' },
  { name: { km: 'តានៃ', latin: 'Ta Ney' }, id: '180215' },
  { name: { km: 'កំពេញ', latin: 'Kampenh' }, id: '180301' },
  { name: { km: 'អូរត្រេះ', latin: 'Ou Treh' }, id: '180302' },
  { name: { km: 'ទំនប់រលក', latin: 'Tumnob Rolok' }, id: '180303' },
  { name: { km: 'កែវផុស', latin: 'Kaev Phos' }, id: '180304' },
  { name: { km: 'ចំការហ្លួង', latin: 'Chamkar Luong' }, id: '180401' },
  { name: { km: 'កំពង់សីលា', latin: 'Kampong Seila' }, id: '180402' },
  { name: { km: 'អូរបាក់រទេះ', latin: 'Ou Bak Roteh' }, id: '180403' },
  { name: { km: 'ស្ទឹងឆាយ', latin: 'Stueng Chhay' }, id: '180404' },
  { name: { km: 'កោះរ៉ុង', latin: 'Kaoh Rung' }, id: '180501' },
  { name: { km: 'កោះរ៉ុងសន្លឹម', latin: 'Koah Rung Sonlem' }, id: '180502' },
  { name: { km: 'កំភុន', latin: 'Kamphun' }, id: '190101' },
  { name: { km: 'ក្បាលរមាស', latin: 'Kbal Romeas' }, id: '190102' },
  { name: { km: 'ភ្លុក', latin: 'Phluk' }, id: '190103' },
  { name: { km: 'សាមឃួយ', latin: 'Samkhuoy' }, id: '190104' },
  { name: { km: 'ស្ដៅ', latin: 'Sdau' }, id: '190105' },
  { name: { km: 'ស្រែគរ', latin: 'Srae Kor' }, id: '190106' },
  { name: { km: 'តាឡាត', latin: 'Ta Lat' }, id: '190107' },
  { name: { km: 'កោះព្រះ', latin: 'Kaoh Preah' }, id: '190201' },
  { name: { km: 'កោះសំពាយ', latin: 'Kaoh Sampeay' }, id: '190202' },
  { name: { km: 'កោះស្រឡាយ', latin: 'Kaoh Sralay' }, id: '190203' },
  { name: { km: 'អូរម្រះ', latin: 'Ou Mreah' }, id: '190204' },
  { name: { km: 'អូរឫស្សីកណ្ដាល', latin: 'Ou Ruessei Kandal' }, id: '190205' },
  { name: { km: 'សៀមបូក', latin: 'Siem Bouk' }, id: '190206' },
  { name: { km: 'ស្រែក្រសាំង', latin: 'Srae Krasang' }, id: '190207' },
  { name: { km: 'ព្រែកមាស', latin: 'Preaek Meas' }, id: '190301' },
  { name: { km: 'សេកុង', latin: 'Sekong' }, id: '190302' },
  { name: { km: 'សន្ដិភាព', latin: 'Santepheap' }, id: '190303' },
  { name: { km: 'ស្រែសំបូរ', latin: 'Srae Sambour' }, id: '190304' },
  { name: { km: 'ថ្មកែវ', latin: 'Tma Kaev' }, id: '190305' },
  { name: { km: 'ស្ទឹងត្រែង', latin: 'Stueng Traeng' }, id: '190401' },
  { name: { km: 'ស្រះឫស្សី', latin: 'Srah Ruessei' }, id: '190402' },
  { name: { km: 'ព្រះបាទ', latin: 'Preah Bat' }, id: '190403' },
  { name: { km: 'សាមគ្គី', latin: 'Sameakki' }, id: '190404' },
  { name: { km: 'អន្លង់ភេ', latin: 'Anlong Phe' }, id: '190501' },
  { name: { km: 'ចំការលើ', latin: 'Chamkar Leu' }, id: '190502' },
  { name: { km: 'កាំងចាម', latin: 'Kang Cham' }, id: '190503' },
  { name: { km: 'អន្លង់ជ្រៃ', latin: 'Anlong Chrey' }, id: '190505' },
  { name: { km: 'អូររ៉ៃ', latin: 'Ou Rai' }, id: '190506' },
  { name: { km: 'សំអាង', latin: 'Sam Ang' }, id: '190509' },
  { name: { km: 'ស្រែឫស្សី', latin: 'Srae Ruessei' }, id: '190510' },
  { name: { km: 'ថាឡាបរិវ៉ាត់', latin: 'Thala Barivat' }, id: '190511' },
  { name: { km: 'អូរស្វាយ', latin: 'Ou Svay' }, id: '190601' },
  { name: { km: 'កោះស្នែង', latin: 'Kaoh Snaeng' }, id: '190602' },
  { name: { km: 'ព្រះរំកិល', latin: 'Preah Rumkel' }, id: '190603' },
  { name: { km: 'ចន្ទ្រា', latin: 'Chantrea' }, id: '200103' },
  { name: { km: 'ច្រេស', latin: 'Chres' }, id: '200104' },
  { name: { km: 'មេ សរថ្ងក', latin: 'Me Sar Thngak' }, id: '200105' },
  { name: { km: 'ព្រៃគគីរ', latin: 'Prey Kokir' }, id: '200108' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '200109' },
  { name: { km: 'ទួលស្ដី', latin: 'Tuol Sdei' }, id: '200110' },
  { name: { km: 'បន្ទាយក្រាំង', latin: 'Banteay Krang' }, id: '200201' },
  { name: { km: 'ញរ', latin: 'Nhor' }, id: '200202' },
  { name: { km: 'ខ្សែត្រ', latin: 'Khsaetr' }, id: '200203' },
  { name: { km: 'ព្រះពន្លា', latin: 'Preah Ponlea' }, id: '200204' },
  { name: { km: 'ព្រៃធំ', latin: 'Prey Thum' }, id: '200205' },
  { name: { km: 'រាជមន្ទីរ', latin: 'Reach Montir' }, id: '200206' },
  { name: { km: 'សំឡី', latin: 'Samlei' }, id: '200207' },
  { name: { km: 'សំយ៉ោង', latin: 'Samyaong' }, id: '200208' },
  { name: { km: 'ស្វាយតាយាន', latin: 'Svay Ta Yean' }, id: '200209' },
  { name: { km: 'ថ្មី', latin: 'Thmei' }, id: '200211' },
  { name: { km: 'ត្នោត', latin: 'Tnaot' }, id: '200212' },
  { name: { km: 'បុសមន', latin: 'Bos Mon' }, id: '200301' },
  { name: { km: 'ធ្មា', latin: 'Thmea' }, id: '200302' },
  { name: { km: 'កំពង់ចក', latin: 'Kampong Chak' }, id: '200303' },
  { name: { km: 'ជ្រុងពពេល', latin: 'Chrung Popel' }, id: '200304' },
  { name: { km: 'កំពង់អំពិល', latin: 'Kampong Ampil' }, id: '200305' },
  { name: { km: 'ម៉ឺនជ័យ', latin: 'Meun Chey' }, id: '200306' },
  { name: { km: 'ពងទឹក', latin: 'Pong Tuek' }, id: '200307' },
  { name: { km: 'សង្កែ', latin: 'Sangkae' }, id: '200308' },
  { name: { km: 'ស្វាយចេក', latin: 'Svay Chek' }, id: '200309' },
  { name: { km: 'ថ្នាធ្នង់', latin: 'Thna Thnong' }, id: '200310' },
  { name: { km: 'អំពិល', latin: 'Ampil' }, id: '200401' },
  { name: { km: 'អណ្ដូងពោធិ៍', latin: 'Andoung Pou' }, id: '200402' },
  { name: { km: 'អណ្ដូងត្របែក', latin: 'Andoung Trabaek' }, id: '200403' },
  { name: { km: 'អង្គប្រស្រែ', latin: 'Angk Prasrae' }, id: '200404' },
  { name: { km: 'ចន្ដ្រី', latin: 'Chantrei' }, id: '200405' },
  { name: { km: 'ជ្រៃធំ', latin: 'Chrey Thum' }, id: '200406' },
  { name: { km: 'ដូង', latin: 'Doung' }, id: '200407' },
  { name: { km: 'កំពង់ត្រាច', latin: 'Kampong Trach' }, id: '200408' },
  { name: { km: 'គគីរ', latin: 'Kokir' }, id: '200409' },
  { name: { km: 'ក្រសាំង', latin: 'Krasang' }, id: '200410' },
  { name: { km: 'មុខដា', latin: 'Mukh Da' }, id: '200411' },
  { name: { km: 'ម្រាម', latin: 'Mream' }, id: '200412' },
  { name: { km: 'សំបួរ', latin: 'Sambuor' }, id: '200413' },
  { name: { km: 'សម្បត្ដិមានជ័យ', latin: 'Sambatt Mean Chey' }, id: '200414' },
  { name: { km: 'ត្រពាំងស្ដៅ', latin: 'Trapeang Sdau' }, id: '200415' },
  { name: { km: 'ត្រស់', latin: 'Tras' }, id: '200416' },
  { name: { km: 'អង្គតាសូ', latin: 'Angk Ta Sou' }, id: '200501' },
  { name: { km: 'បាសាក់', latin: 'Basak' }, id: '200502' },
  { name: { km: 'ចំបក់', latin: 'Chambak' }, id: '200503' },
  { name: { km: 'កំពង់ចំឡង', latin: 'Kampong Chamlang' }, id: '200504' },
  { name: { km: 'តាសួស', latin: 'Ta Suos' }, id: '200505' },
  { name: { km: 'ឈើទាល', latin: 'Chheu Teal' }, id: '200507' },
  { name: { km: 'ដូនស', latin: 'Doun Sa' }, id: '200508' },
  { name: { km: 'គោកព្រីង', latin: 'Kouk Pring' }, id: '200509' },
  { name: { km: 'ក្រោលគោ', latin: 'Kraol Kou' }, id: '200510' },
  { name: { km: 'គ្រួស', latin: 'Kruos' }, id: '200511' },
  { name: { km: 'ពោធិរាជ', latin: 'Pouthi Reach' }, id: '200512' },
  { name: { km: 'ស្វាយអង្គ', latin: 'Svay Angk' }, id: '200513' },
  { name: { km: 'ស្វាយជ្រំ', latin: 'Svay Chrum' }, id: '200514' },
  { name: { km: 'ស្វាយធំ', latin: 'Svay Thum' }, id: '200515' },
  { name: { km: 'ស្វាយយា', latin: 'Svay Yea' }, id: '200516' },
  { name: { km: 'ធ្លក', latin: 'Thlok' }, id: '200517' },
  { name: { km: 'ស្វាយរៀង', latin: 'Svay Rieng' }, id: '200601' },
  { name: { km: 'ព្រៃឆ្លាក់', latin: 'Prey Chhlak' }, id: '200602' },
  { name: { km: 'គយត្របែក', latin: 'Koy Trabaek' }, id: '200603' },
  { name: { km: 'ពោធិ៍តាហោ', latin: 'Pou Ta Hao' }, id: '200604' },
  { name: { km: 'ចេក', latin: 'Chek' }, id: '200605' },
  { name: { km: 'ស្វាយតឿ', latin: 'Svay Toea' }, id: '200606' },
  { name: { km: 'សង្ឃរ័', latin: 'Sangkhoar' }, id: '200607' },
  { name: { km: 'គគីសោម', latin: 'Koki Saom' }, id: '200702' },
  { name: { km: 'កណ្ដៀងរាយ', latin: 'Kandieng Reay' }, id: '200703' },
  { name: { km: 'មនោរម្យ', latin: 'Monourom' }, id: '200704' },
  { name: { km: 'ពពែត', latin: 'Popeaet' }, id: '200705' },
  { name: { km: 'ព្រៃតាអី', latin: 'Prey Ta Ei' }, id: '200706' },
  { name: { km: 'ប្រសូត្រ', latin: 'Prasoutr' }, id: '200707' },
  { name: { km: 'រមាំងថ្កោល', latin: 'Romeang Thkaol' }, id: '200708' },
  { name: { km: 'សំបួរ', latin: 'Sambuor' }, id: '200709' },
  { name: { km: 'ស្វាយរំពារ', latin: 'Svay Rumpear' }, id: '200711' },
  { name: { km: 'បាទី', latin: 'Bati' }, id: '200801' },
  { name: { km: 'បាវិត', latin: 'Bavet' }, id: '200802' },
  { name: { km: 'ច្រកម្ទេស', latin: 'Chrak Mtes' }, id: '200803' },
  { name: { km: 'ប្រាសាទ', latin: 'Prasat' }, id: '200804' },
  { name: { km: 'ព្រៃអង្គុញ', latin: 'Prey Angkunh' }, id: '200805' },
  { name: { km: 'អង្គរបូរី', latin: 'Angkor Borei' }, id: '210101' },
  { name: { km: 'បាស្រែ', latin: 'Ba Srae' }, id: '210102' },
  { name: { km: 'គោកធ្លក', latin: 'Kouk Thlok' }, id: '210103' },
  { name: { km: 'ពន្លៃ', latin: 'Ponley' }, id: '210104' },
  { name: { km: 'ព្រែកផ្ទោល', latin: 'Preaek Phtoul' }, id: '210105' },
  { name: { km: 'ព្រៃផ្គាំ', latin: 'Prey Phkoam' }, id: '210106' },
  { name: { km: 'ចំបក់', latin: 'Chambak' }, id: '210201' },
  { name: { km: 'ចំប៉ី', latin: 'Champei' }, id: '210202' },
  { name: { km: 'ដូង', latin: 'Doung' }, id: '210203' },
  { name: { km: 'កណ្ដឹង', latin: 'Kandoeng' }, id: '210204' },
  { name: { km: 'កុមាររាជា', latin: 'Komar Reachea' }, id: '210205' },
  { name: { km: 'ក្រាំងលាវ', latin: 'Krang Leav' }, id: '210206' },
  { name: { km: 'ក្រាំងធ្នង់', latin: 'Krang Thnong' }, id: '210207' },
  { name: { km: 'លំពង់', latin: 'Lumpong' }, id: '210208' },
  { name: { km: 'ពារាម', latin: 'Pea Ream' }, id: '210209' },
  { name: { km: 'ពត់សរ', latin: 'Pot Sar' }, id: '210210' },
  { name: { km: 'សូរភី', latin: 'Sour Phi' }, id: '210211' },
  { name: { km: 'តាំងដូង', latin: 'Tang Doung' }, id: '210212' },
  { name: { km: 'ត្នោត', latin: 'Tnaot' }, id: '210213' },
  { name: { km: 'ត្រពាំងក្រសាំង', latin: 'Trapeang Krasang' }, id: '210214' },
  { name: { km: 'ត្រពាំងសាប', latin: 'Trapeang Sab' }, id: '210215' },
  { name: { km: 'បូរីជលសារ', latin: 'Borei Cholsar' }, id: '210301' },
  { name: { km: 'ជ័យជោគ', latin: 'Chey Chouk' }, id: '210302' },
  { name: { km: 'ដូងខ្ពស់', latin: 'Doung Khpos' }, id: '210303' },
  { name: { km: 'កំពង់ក្រសាំង', latin: 'Kampong Krasang' }, id: '210304' },
  { name: { km: 'គោកពោធិ៍', latin: 'Kouk Pou' }, id: '210305' },
  { name: { km: 'អង្គប្រាសាទ', latin: 'Angk Prasat' }, id: '210401' },
  {
    name: { km: 'ព្រះបាទជាន់ជុំ', latin: 'Preah Bat Choan Chum' },
    id: '210402',
  },
  { name: { km: 'កំណប់', latin: 'Kamnab' }, id: '210403' },
  { name: { km: 'កំពែង', latin: 'Kampeaeng' }, id: '210404' },
  { name: { km: 'គីរីចុងកោះ', latin: 'Kiri Chong Kaoh' }, id: '210405' },
  { name: { km: 'គោកព្រេច', latin: 'Kouk Prech' }, id: '210406' },
  { name: { km: 'ភ្នំដិន', latin: 'Phnum Den' }, id: '210407' },
  { name: { km: 'ព្រៃអំពក', latin: 'Prey Ampok' }, id: '210408' },
  { name: { km: 'ព្រៃរំដេង', latin: 'Prey Rumdeng' }, id: '210409' },
  { name: { km: 'រាមអណ្ដើក', latin: 'Ream Andaeuk' }, id: '210410' },
  { name: { km: 'សោម', latin: 'Saom' }, id: '210411' },
  { name: { km: 'តាអូរ', latin: 'Ta Ou' }, id: '210412' },
  { name: { km: 'ក្រពុំឈូក', latin: 'Krapum Chhuk' }, id: '210501' },
  { name: { km: 'ពេជសារ', latin: 'Pech Sar' }, id: '210502' },
  { name: { km: 'ព្រៃខ្លា', latin: 'Prey Khla' }, id: '210503' },
  { name: { km: 'ព្រៃយុថ្កា', latin: 'Prey Yuthka' }, id: '210504' },
  { name: { km: 'រមេញ', latin: 'Romenh' }, id: '210505' },
  { name: { km: 'ធ្លាប្រជុំ', latin: 'Thlea Prachum' }, id: '210506' },
  { name: { km: 'អង្កាញ់', latin: 'Angkanh' }, id: '210601' },
  { name: { km: 'បានកាម', latin: 'Ban Kam' }, id: '210602' },
  { name: { km: 'ចំប៉ា', latin: 'Champa' }, id: '210603' },
  { name: { km: 'ចារ', latin: 'Char' }, id: '210604' },
  { name: { km: 'កំពែង', latin: 'Kampeaeng' }, id: '210605' },
  { name: { km: 'កំពង់រាប', latin: 'Kampong Reab' }, id: '210606' },
  { name: { km: 'ក្ដាញ់', latin: 'Kdanh' }, id: '210607' },
  { name: { km: 'ពោធិ៍រំចាក', latin: 'Pou Rumchak' }, id: '210608' },
  { name: { km: 'ព្រៃកប្បាស', latin: 'Prey Kabbas' }, id: '210609' },
  { name: { km: 'ព្រៃល្វា', latin: 'Prey Lvea' }, id: '210610' },
  { name: { km: 'ព្រៃផ្ដៅ', latin: 'Prey Phdau' }, id: '210611' },
  { name: { km: 'ស្នោ', latin: 'Snao' }, id: '210612' },
  { name: { km: 'តាំងយ៉ាប', latin: 'Tang Yab' }, id: '210613' },
  {
    name: { km: 'បឹងត្រាញ់ខាងជើង', latin: 'Boeng Tranh Khang Cheung' },
    id: '210701',
  },
  {
    name: { km: 'បឹងត្រាញ់ខាងត្បូង', latin: 'Boeng Tranh Khang Tboung' },
    id: '210702',
  },
  { name: { km: 'ជើងគួន', latin: 'Cheung Kuon' }, id: '210703' },
  { name: { km: 'ជំរះពេន', latin: 'Chumreah Pen' }, id: '210704' },
  { name: { km: 'ខ្វាវ', latin: 'Khvav' }, id: '210705' },
  { name: { km: 'លំចង់', latin: 'Lumchang' }, id: '210706' },
  { name: { km: 'រវៀង', latin: 'Rovieng' }, id: '210707' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '210708' },
  { name: { km: 'សឹង្ហ', latin: 'Soengh' }, id: '210709' },
  { name: { km: 'ស្លា', latin: 'Sla' }, id: '210710' },
  { name: { km: 'ទ្រា', latin: 'Trea' }, id: '210711' },
  { name: { km: 'បារាយណ៍', latin: 'Baray' }, id: '210801' },
  { name: { km: 'រកាក្នុង', latin: 'Roka Knong' }, id: '210802' },
  { name: { km: 'រកាក្រៅ', latin: 'Roka Krau' }, id: '210803' },
  { name: { km: 'អង្គតាសោម', latin: 'Angk Ta Saom' }, id: '210901' },
  { name: { km: 'ជាងទង', latin: 'Cheang Tong' }, id: '210902' },
  { name: { km: 'គុស', latin: 'Kus' }, id: '210903' },
  { name: { km: 'លាយបូរ', latin: 'Leay Bour' }, id: '210904' },
  { name: { km: 'ញ៉ែងញ៉ង', latin: 'Nhaeng Nhang' }, id: '210905' },
  { name: { km: 'អូរសារាយ', latin: 'Ou Saray' }, id: '210906' },
  { name: { km: 'ត្រពាំងក្រញូង', latin: 'Trapeang Kranhoung' }, id: '210907' },
  { name: { km: 'ឧត្ដមសុរិយា', latin: 'Otdam Soriya' }, id: '210908' },
  { name: { km: 'ពពេល', latin: 'Popel' }, id: '210909' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '210910' },
  { name: { km: 'ស្រែរនោង', latin: 'Srae Ronoung' }, id: '210911' },
  { name: { km: 'តាភេម', latin: 'Ta Phem' }, id: '210912' },
  { name: { km: 'ត្រាំកក់', latin: 'Tram Kak' }, id: '210913' },
  {
    name: { km: 'ត្រពាំងធំខាងជើង', latin: 'Trapeang Thum Khang Cheung' },
    id: '210914',
  },
  {
    name: { km: 'ត្រពាំងធំខាងត្បូង', latin: 'Trapeang Thum Khang Tboung' },
    id: '210915',
  },
  { name: { km: 'អង្កាញ់', latin: 'Angkanh' }, id: '211001' },
  { name: { km: 'អង្គខ្នុរ', latin: 'Angk Khnor' }, id: '211002' },
  { name: { km: 'ជីខ្មា', latin: 'Chi Khma' }, id: '211003' },
  { name: { km: 'ខ្វាវ', latin: 'Khvav' }, id: '211004' },
  { name: { km: 'ប្រាំបីមុំ', latin: 'Prambei Mum' }, id: '211005' },
  { name: { km: 'អង្គកែវ', latin: 'Angk Kaev' }, id: '211006' },
  { name: { km: 'ព្រៃស្លឹក', latin: 'Prey Sloek' }, id: '211007' },
  { name: { km: 'រនាម', latin: 'Roneam' }, id: '211008' },
  { name: { km: 'សំបួរ', latin: 'Sambuor' }, id: '211009' },
  { name: { km: 'សន្លុង', latin: 'Sanlung' }, id: '211010' },
  { name: { km: 'ស្មោង', latin: 'Smaong' }, id: '211011' },
  { name: { km: 'ស្រង៉ែ', latin: 'Srangae' }, id: '211012' },
  { name: { km: 'ធ្លក', latin: 'Thlok' }, id: '211013' },
  { name: { km: 'ត្រឡាច', latin: 'Tralach' }, id: '211014' },
  { name: { km: 'អន្លង់វែង', latin: 'Anlong Veaeng' }, id: '220101' },
  { name: { km: 'ត្រពាំងតាវ', latin: 'Trapeang Tav' }, id: '220103' },
  { name: { km: 'ត្រពាំងប្រីយ៍', latin: 'Trapeang Prei' }, id: '220104' },
  { name: { km: 'ថ្លាត', latin: 'Thlat' }, id: '220105' },
  { name: { km: 'លំទង', latin: 'Lumtong' }, id: '220106' },
  { name: { km: 'អំពិល', latin: 'Ampil' }, id: '220201' },
  { name: { km: 'បេង', latin: 'Beng' }, id: '220202' },
  { name: { km: 'គោកខ្ពស់', latin: 'Kouk Khpos' }, id: '220203' },
  { name: { km: 'គោកមន', latin: 'Kouk Mon' }, id: '220204' },
  { name: { km: 'ជើងទៀន', latin: 'Cheung Tien' }, id: '220301' },
  { name: { km: 'ចុងកាល់', latin: 'Chong Kal' }, id: '220302' },
  { name: { km: 'ក្រសាំង', latin: 'Krasang' }, id: '220303' },
  { name: { km: 'ពង្រ', latin: 'Pongro' }, id: '220304' },
  { name: { km: 'បន្សាយរាក់', latin: 'Bansay Reak' }, id: '220401' },
  { name: { km: 'បុស្បូវ', latin: 'Bos Sbov' }, id: '220402' },
  { name: { km: 'កូនក្រៀល', latin: 'Koun Kriel' }, id: '220403' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '220404' },
  { name: { km: 'អូរស្មាច់', latin: 'Ou Smach' }, id: '220405' },
  { name: { km: 'បាក់អន្លូង', latin: 'Bak Anloung' }, id: '220501' },
  { name: { km: 'ផ្អាវ', latin: "Ph'av" }, id: '220502' },
  { name: { km: 'អូរស្វាយ', latin: 'Ou Svay' }, id: '220503' },
  { name: { km: 'ព្រះប្រឡាយ', latin: 'Preah Pralay' }, id: '220504' },
  { name: { km: 'ទំនប់ដាច់', latin: 'Tumnob Dach' }, id: '220505' },
  { name: { km: 'ត្រពាំងប្រាសាទ', latin: 'Trapeang Prasat' }, id: '220506' },
  { name: { km: 'អង្កោល', latin: 'Angkaol' }, id: '230101' },
  { name: { km: 'ពងទឹក', latin: 'Pong Tuek' }, id: '230103' },
  { name: { km: 'កែប', latin: 'Kaeb' }, id: '230201' },
  { name: { km: 'ព្រៃធំ', latin: 'Prey Thum' }, id: '230202' },
  { name: { km: 'អូរក្រសារ', latin: 'Ou Krasar' }, id: '230203' },
  { name: { km: 'ប៉ៃលិន', latin: 'Pailin' }, id: '240101' },
  { name: { km: 'អូរតាវ៉ៅ', latin: 'Ou Ta Vau' }, id: '240102' },
  { name: { km: 'ទួលល្វា', latin: 'Tuol Lvea' }, id: '240103' },
  { name: { km: 'បរយ៉ាខា', latin: 'Bar Yakha' }, id: '240104' },
  { name: { km: 'សាលាក្រៅ', latin: 'Sala Krau' }, id: '240201' },
  { name: { km: 'ស្ទឹងត្រង់', latin: 'Stueng Trang' }, id: '240202' },
  { name: { km: 'ស្ទឹងកាច់', latin: 'Stueng Kach' }, id: '240203' },
  { name: { km: 'អូរអណ្ដូង', latin: 'Ou Andoung' }, id: '240204' },
  { name: { km: 'ចុងជាច', latin: 'Chong Cheach' }, id: '250101' },
  { name: { km: 'តំបែរ', latin: 'Dambae' }, id: '250102' },
  { name: { km: 'គោកស្រុក', latin: 'Kouk Srok' }, id: '250103' },
  { name: { km: 'នាងទើត', latin: 'Neang Teut' }, id: '250104' },
  { name: { km: 'សេដា', latin: 'Seda' }, id: '250105' },
  { name: { km: 'ត្រពាំងព្រីង', latin: 'Trapeang Pring' }, id: '250106' },
  { name: { km: 'ទឹកជ្រៅ', latin: 'Tuek Chrov' }, id: '250107' },
  { name: { km: 'ឈូក', latin: 'Chhuk' }, id: '250201' },
  { name: { km: 'ជំនីក', latin: 'Chumnik' }, id: '250202' },
  { name: { km: 'កំពង់ទ្រាស', latin: 'Kampong Treas' }, id: '250203' },
  { name: { km: 'កោះពីរ', latin: 'Kaoh Pir' }, id: '250204' },
  { name: { km: 'ក្រូចឆ្មារ', latin: 'Krouch Chhmar' }, id: '250205' },
  { name: { km: 'ប៉ឺស១', latin: 'Peus Muoy' }, id: '250206' },
  { name: { km: 'ប៉ឺស២', latin: 'Peus Pir' }, id: '250207' },
  { name: { km: 'ព្រែកអាជី', latin: 'Preaek A chi' }, id: '250208' },
  { name: { km: 'រការខ្នុរ', latin: 'Roka Khnor' }, id: '250209' },
  { name: { km: 'ស្វាយឃ្លាំង', latin: 'Svay Khleang' }, id: '250210' },
  { name: { km: 'ទ្រា', latin: 'Trea' }, id: '250211' },
  { name: { km: 'ទួលស្នួល', latin: 'Tuol Snuol' }, id: '250212' },
  { name: { km: 'ចាន់មូល', latin: 'Chan Mul' }, id: '250301' },
  { name: { km: 'ជាំ', latin: 'Choam' }, id: '250302' },
  { name: { km: 'ជាំក្រវៀន', latin: 'Choam Kravien' }, id: '250303' },
  { name: { km: 'ជាំតាម៉ៅ', latin: 'Choam Ta Mau' }, id: '250304' },
  { name: { km: 'ដារ', latin: 'Dar' }, id: '250305' },
  { name: { km: 'កំពាន់', latin: 'Kampoan' }, id: '250306' },
  { name: { km: 'គគីរ', latin: 'Kokir' }, id: '250307' },
  { name: { km: 'មេមង', latin: 'Memong' }, id: '250308' },
  { name: { km: 'មេមត់', latin: 'Memot' }, id: '250309' },
  { name: { km: 'រំចេក', latin: 'Rumchek' }, id: '250310' },
  { name: { km: 'រូង', latin: 'Rung' }, id: '250311' },
  { name: { km: 'ទន្លូង', latin: 'Tonlung' }, id: '250312' },
  { name: { km: 'ត្រមូង', latin: 'Tramung' }, id: '250313' },
  { name: { km: 'ទ្រៀក', latin: 'Triek' }, id: '250314' },
  { name: { km: 'អំពិលតាពក', latin: 'Ampil Ta Pok' }, id: '250401' },
  { name: { km: 'ចក', latin: 'Chak' }, id: '250402' },
  { name: { km: 'ដំរិល', latin: 'Damril' }, id: '250403' },
  { name: { km: 'គងជ័យ', latin: 'Kong Chey' }, id: '250404' },
  { name: { km: 'មៀន', latin: 'Mien' }, id: '250405' },
  { name: { km: 'ព្រះធាតុ', latin: 'Preah Theat' }, id: '250406' },
  { name: { km: 'ទួលសូភី', latin: 'Tuol Souphi' }, id: '250407' },
  { name: { km: 'ដូនតី', latin: 'Dountei' }, id: '250501' },
  { name: { km: 'កក់', latin: 'Kak' }, id: '250502' },
  { name: { km: 'កណ្ដោលជ្រុំ', latin: 'Kandaol Chrum' }, id: '250503' },
  { name: { km: 'កោងកាង', latin: 'Kaong Kang' }, id: '250504' },
  { name: { km: 'ក្រែក', latin: 'Kraek' }, id: '250505' },
  { name: { km: 'ពពេល', latin: 'Popel' }, id: '250506' },
  { name: { km: 'ត្រពាំងផ្លុង', latin: 'Trapeang Phlong' }, id: '250507' },
  { name: { km: 'វាលម្លូរ', latin: 'Veal Mlu' }, id: '250508' },
  { name: { km: 'សួង', latin: 'Suong' }, id: '250601' },
  { name: { km: 'វិហារលួង', latin: 'Vihear Luong' }, id: '250602' },
  { name: { km: 'អញ្ចើម', latin: 'Anhchaeum' }, id: '250701' },
  { name: { km: 'បឹងព្រួល', latin: 'Boeng Pruol' }, id: '250702' },
  { name: { km: 'ជីគរ', latin: 'Chikor' }, id: '250703' },
  { name: { km: 'ជីរោទ៍ ទី១', latin: 'Chirou Ti Muoy' }, id: '250704' },
  { name: { km: 'ជីរោទ៍ ទី២', latin: 'Chirou Ti Pir' }, id: '250705' },
  { name: { km: 'ជប់', latin: 'Chob' }, id: '250706' },
  { name: { km: 'គរ', latin: 'Kor' }, id: '250707' },
  { name: { km: 'ល្ងៀង', latin: 'Lngieng' }, id: '250708' },
  { name: { km: 'មង់រៀវ', latin: 'Mong Riev' }, id: '250709' },
  { name: { km: 'ពាមជីលាំង', latin: 'Peam Chileang' }, id: '250710' },
  { name: { km: 'រកាពប្រាំ', latin: 'Roka Po Pram' }, id: '250711' },
  { name: { km: 'ស្រឡប់', latin: 'Sralab' }, id: '250712' },
  { name: { km: 'ថ្មពេជ្រ', latin: 'Thma Pech' }, id: '250713' },
  { name: { km: 'ទន្លេបិទ', latin: 'Tonle Bet' }, id: '250714' },
  { name: { km: 'បន្ទាយនាង', latin: 'Banteay Neang' }, id: '010201' },
  { name: { km: 'បត់ត្រង់', latin: 'Bat Trang' }, id: '010202' },
  { name: { km: 'ចំណោម', latin: 'Chamnaom' }, id: '010203' },
  { name: { km: 'គោកបល្ល័ង្គ', latin: 'Kouk Ballangk' }, id: '010204' },
  { name: { km: 'គយម៉ែង', latin: 'Koy Maeng' }, id: '010205' },
  { name: { km: 'អូរប្រាសាទ', latin: 'Ou Prasat' }, id: '010206' },
  { name: { km: 'ភ្នំតូច', latin: 'Phnum Touch' }, id: '010207' },
  { name: { km: 'រហាត់ទឹក', latin: 'Rohat Tuek' }, id: '010208' },
  { name: { km: 'ឫស្សីក្រោក', latin: 'Ruessei Kraok' }, id: '010209' },
  { name: { km: 'សំបួរ', latin: 'Sambuor' }, id: '010210' },
  { name: { km: 'សឿ', latin: 'Soea' }, id: '010211' },
  { name: { km: 'ស្រះរាំង', latin: 'Srah Reang' }, id: '010212' },
  { name: { km: 'តាឡំ', latin: 'Ta Lam' }, id: '010213' },
  { name: { km: 'ណាំតៅ', latin: 'Nam Tau' }, id: '010301' },
  { name: { km: 'ប៉ោយចារ', latin: 'Poy Char' }, id: '010302' },
  { name: { km: 'ពន្លៃ', latin: 'Ponley' }, id: '010303' },
  { name: { km: 'ស្ពានស្រែង', latin: 'Spean Sraeng' }, id: '010304' },
  { name: { km: 'ស្រះជីក', latin: 'Srah Chik' }, id: '010305' },
  { name: { km: 'ភ្នំដី', latin: 'Phnum Dei' }, id: '010306' },
  { name: { km: 'ឈ្នួរមានជ័យ', latin: 'Chnuor Mean Chey' }, id: '010401' },
  { name: { km: 'ជប់វារី', latin: 'Chob Vari' }, id: '010402' },
  { name: { km: 'ភ្នំលៀប', latin: 'Phnum Lieb' }, id: '010403' },
  { name: { km: 'ប្រាសាទ', latin: 'Prasat' }, id: '010404' },
  { name: { km: 'ព្រះនេត្រព្រះ', latin: 'Preak Netr Preah' }, id: '010405' },
  { name: { km: 'រហាល', latin: 'Rohal' }, id: '010406' },
  { name: { km: 'ទានកាំ', latin: 'Tean Kam' }, id: '010407' },
  { name: { km: 'ទឹកជោរ', latin: 'Tuek Chour' }, id: '010408' },
  { name: { km: 'បុស្បូវ', latin: 'Bos Sbov' }, id: '010409' },
  { name: { km: 'ចង្ហា', latin: 'Changha' }, id: '010501' },
  { name: { km: 'កូប', latin: 'Koub' }, id: '010502' },
  { name: { km: 'គុត្ដសត', latin: 'Kuttasat' }, id: '010503' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '010505' },
  { name: { km: 'សូភី', latin: 'Souphi' }, id: '010506' },
  { name: { km: 'សឹង្ហ', latin: 'Soengh' }, id: '010507' },
  { name: { km: 'អូរបីជាន់', latin: 'Ou Beichoan' }, id: '010509' },
  { name: { km: 'កំពង់ស្វាយ', latin: 'Kampong Svay' }, id: '010602' },
  { name: { km: 'កោះពងសត្វ', latin: 'Kaoh Pong Satv' }, id: '010603' },
  { name: { km: 'ម្កាក់', latin: 'Mkak' }, id: '010604' },
  { name: { km: 'អូរអំបិល', latin: 'Ou Ambel' }, id: '010605' },
  { name: { km: 'ភ្នៀត', latin: 'Phniet' }, id: '010606' },
  { name: { km: 'ព្រះពន្លា', latin: 'Preah Ponlea' }, id: '010607' },
  { name: { km: 'ទឹកថ្លា', latin: 'Tuek Thla' }, id: '010608' },
  { name: { km: 'បន្ទាយឆ្មារ', latin: 'Banteay Chhmar' }, id: '010701' },
  { name: { km: 'គោករមៀត', latin: 'Kouk Romiet' }, id: '010702' },
  { name: { km: 'ភូមិថ្មី', latin: 'Phum Thmei' }, id: '010703' },
  { name: { km: 'ថ្មពួក', latin: 'Thma Puok' }, id: '010704' },
  { name: { km: 'គោកកឋិន', latin: 'Kouk Kakthen' }, id: '010705' },
  { name: { km: 'គំរូ', latin: 'Kumru' }, id: '010706' },
  { name: { km: 'ផ្គាំ', latin: 'Phkoam' }, id: '010801' },
  { name: { km: 'សារង្គ', latin: 'Sarongk' }, id: '010802' },
  { name: { km: 'ស្លក្រាម', latin: 'Sla Kram' }, id: '010803' },
  { name: { km: 'ស្វាយចេក', latin: 'Svay Chek' }, id: '010804' },
  { name: { km: 'តាបែន', latin: 'Ta Baen' }, id: '010805' },
  { name: { km: 'តាផូ', latin: 'Ta Phou' }, id: '010806' },
  { name: { km: 'ទ្រាស', latin: 'Treas' }, id: '010807' },
  { name: { km: 'រលួស', latin: 'Roluos' }, id: '010808' },
  { name: { km: 'បឹងបេង', latin: 'Boeng Beng' }, id: '010901' },
  { name: { km: 'ម៉ាឡៃ', latin: 'Malai' }, id: '010902' },
  { name: { km: 'អូរសំព័រ', latin: 'Ou Sampoar' }, id: '010903' },
  { name: { km: 'អូរស្រឡៅ', latin: 'Ou Sralau' }, id: '010904' },
  { name: { km: 'ទួលពង្រ', latin: 'Tuol Pongro' }, id: '010905' },
  { name: { km: 'តាគង់', latin: 'Ta Kong' }, id: '010906' },
  { name: { km: 'និមិត្ដ', latin: 'Nimitt' }, id: '011001' },
  { name: { km: 'ប៉ោយប៉ែត', latin: 'Paoy Paet' }, id: '011002' },
  { name: { km: 'ផ្សារកណ្តាល', latin: 'Phsar Kandal' }, id: '011003' },
  { name: { km: 'កន្ទឺ ១', latin: 'Kantueu Muoy' }, id: '020101' },
  { name: { km: 'កន្ទឺ ២', latin: 'Kantueu Pir' }, id: '020102' },
  { name: { km: 'បាយដំរាំ', latin: 'Bay Damram' }, id: '020103' },
  { name: { km: 'ឈើទាល', latin: 'Chheu Teal' }, id: '020104' },
  { name: { km: 'ចែងមានជ័យ', latin: 'Chaeng Mean Chey' }, id: '020105' },
  { name: { km: 'ភ្នំសំពៅ', latin: 'Phnum Sampov' }, id: '020106' },
  { name: { km: 'ស្នឹង', latin: 'Snoeng' }, id: '020107' },
  { name: { km: 'តាគ្រាម', latin: 'Ta Kream' }, id: '020108' },
  { name: { km: 'តាពូង', latin: 'Ta Pung' }, id: '020201' },
  { name: { km: 'តាម៉ឺន', latin: 'Ta Meun' }, id: '020202' },
  { name: { km: 'អូរតាគី', latin: 'Ou Ta Ki' }, id: '020203' },
  { name: { km: 'ជ្រៃ', latin: 'Chrey' }, id: '020204' },
  { name: { km: 'អន្លង់រុន', latin: 'Anlong Run' }, id: '020205' },
  { name: { km: 'ជ្រោយស្ដៅ', latin: 'Chrouy Sdau' }, id: '020206' },
  { name: { km: 'បឹងព្រីង', latin: 'Boeng Pring' }, id: '020207' },
  { name: { km: 'គោកឃ្មុំ', latin: 'Kouk Khmum' }, id: '020208' },
  { name: { km: 'បន្សាយត្រែង', latin: 'Bansay Traeng' }, id: '020209' },
  { name: { km: 'រូងជ្រៃ', latin: 'Rung Chrey' }, id: '020210' },
  { name: { km: 'ទួលតាឯក', latin: 'Tuol Ta Ek' }, id: '020301' },
  { name: { km: 'ព្រែកព្រះស្ដេច', latin: 'Prek Preah Sdach' }, id: '020302' },
  { name: { km: 'រតនៈ', latin: 'Rottanak' }, id: '020303' },
  { name: { km: 'ចំការសំរោង', latin: 'Chomkar Somraong' }, id: '020304' },
  { name: { km: 'ស្លាកែត', latin: 'Sla Ket' }, id: '020305' },
  { name: { km: 'ក្ដុលដូនទាវ', latin: 'Kdol Doun Teav' }, id: '020306' },
  { name: { km: 'អូរម៉ាល់', latin: 'OMal' }, id: '020307' },
  { name: { km: 'វត្ដគរ', latin: 'wat Kor' }, id: '020308' },
  { name: { km: 'អូរចារ', latin: 'Ou Char' }, id: '020309' },
  { name: { km: 'ស្វាយប៉ោ', latin: 'Svay Por' }, id: '020310' },
  { name: { km: 'បវេល', latin: 'Bavel' }, id: '020401' },
  { name: { km: 'ខ្នាចរមាស', latin: 'Khnach Romeas' }, id: '020402' },
  { name: { km: 'ល្វា', latin: 'Lvea' }, id: '020403' },
  { name: { km: 'ព្រៃខ្ពស់', latin: 'Prey Khpos' }, id: '020404' },
  { name: { km: 'អំពិលប្រាំដើម', latin: 'Ampil Pram Daeum' }, id: '020405' },
  { name: { km: 'ក្ដុលតាហែន', latin: 'Kdol Ta Haen' }, id: '020406' },
  { name: { km: 'ឃ្លាំងមាស', latin: 'Khlaeng Meas' }, id: '020407' },
  { name: { km: 'បឹងប្រាំ', latin: 'Boeung Pram' }, id: '020408' },
  { name: { km: 'ព្រែកនរិន្ទ', latin: 'Preaek Norint' }, id: '020501' },
  { name: { km: 'សំរោងក្នុង', latin: 'Samraong Knong' }, id: '020502' },
  { name: { km: 'ព្រែកខ្ពប', latin: 'Preaek Khpob' }, id: '020503' },
  { name: { km: 'ព្រែកហ្លួង', latin: 'Preaek Luong' }, id: '020504' },
  { name: { km: 'ពាមឯក', latin: 'Peam Aek' }, id: '020505' },
  { name: { km: 'ព្រៃចាស់', latin: 'Prey Chas' }, id: '020506' },
  { name: { km: 'កោះជីវាំង', latin: 'Kaoh Chiveang' }, id: '020507' },
  { name: { km: 'មោង', latin: 'Moung' }, id: '020601' },
  { name: { km: 'គារ', latin: 'Kear' }, id: '020602' },
  { name: { km: 'ព្រៃស្វាយ', latin: 'Prey Svay' }, id: '020603' },
  { name: { km: 'ឫស្សីក្រាំង', latin: 'Ruessei Krang' }, id: '020604' },
  { name: { km: 'ជ្រៃ', latin: 'Chrey' }, id: '020605' },
  { name: { km: 'តាលាស់', latin: 'Ta Loas' }, id: '020606' },
  { name: { km: 'កកោះ', latin: 'Kakaoh' }, id: '020607' },
  { name: { km: 'ព្រៃតូច', latin: 'Prey Touch' }, id: '020608' },
  { name: { km: 'របស់មង្គល', latin: 'Robas Mongkol' }, id: '020609' },
  { name: { km: 'ស្ដៅ', latin: 'Sdau' }, id: '020701' },
  { name: { km: 'អណ្ដើកហែប', latin: 'Andaeuk Haeb' }, id: '020702' },
  { name: { km: 'ផ្លូវមាស', latin: 'Phlov Meas' }, id: '020703' },
  { name: { km: 'ត្រែង', latin: 'Traeng' }, id: '020704' },
  { name: { km: 'រស្មីសង្ហារ', latin: 'Reaksmei Songha' }, id: '020705' },
  { name: { km: 'អន្លង់វិល', latin: 'Anlong Vil' }, id: '020801' },
  { name: { km: 'នរា', latin: 'Norea' }, id: '020802' },
  { name: { km: 'តាប៉ុន', latin: 'Ta Pon' }, id: '020803' },
  { name: { km: 'រកា', latin: 'Roka' }, id: '020804' },
  { name: { km: 'កំពង់ព្រះ', latin: 'Kampong Preah' }, id: '020805' },
  { name: { km: 'កំពង់ព្រៀង', latin: 'Kampong Prieng' }, id: '020806' },
  { name: { km: 'រាំងកេសី', latin: 'Reang Kesei' }, id: '020807' },
  { name: { km: 'អូរដំបង ១', latin: 'Ou Dambang Muoy' }, id: '020808' },
  { name: { km: 'អូរដំបង ២', latin: 'Ou Dambang Pir' }, id: '020809' },
  { name: { km: 'វត្ដតាមិម', latin: 'Vaot Ta Muem' }, id: '020810' },
  { name: { km: 'តាតោក', latin: 'Ta Taok' }, id: '020901' },
  { name: { km: 'កំពង់ល្ពៅ', latin: 'Kampong Lpov' }, id: '020902' },
  { name: { km: 'អូរសំរិល', latin: 'Ou Samril' }, id: '020903' },
  { name: { km: 'ស៊ុង', latin: 'Sung' }, id: '020904' },
  { name: { km: 'សំឡូត', latin: 'Samlout' }, id: '020905' },
  { name: { km: 'មានជ័យ', latin: 'Mean Chey' }, id: '020906' },
  { name: { km: 'តាសាញ', latin: 'Ta Sanh' }, id: '020907' },
  { name: { km: 'សំពៅលូន', latin: 'Sampov Lun' }, id: '021001' },
  { name: { km: 'អង្គរបាន', latin: 'Angkor Ban' }, id: '021002' },
  { name: { km: 'តាស្ដា', latin: 'Ta Sda' }, id: '021003' },
  { name: { km: 'សន្ដិភាព', latin: 'Santepheap' }, id: '021004' },
  { name: { km: 'សេរីមានជ័យ', latin: 'Serei Mean Chey' }, id: '021005' },
  { name: { km: 'ជ្រៃសីមា', latin: 'Chrey Seima' }, id: '021006' },
  { name: { km: 'ភ្នំព្រឹក', latin: 'Phnum Proek' }, id: '021101' },
  { name: { km: 'ពេជ្រចិន្ដា', latin: 'Pech Chenda' }, id: '021102' },
  { name: { km: 'បួរ', latin: 'Bour' }, id: '021103' },
  { name: { km: 'បារាំងធ្លាក់', latin: 'Barang Thleak' }, id: '021104' },
  { name: { km: 'អូររំដួល', latin: 'Ou Rumduol' }, id: '021105' },
  { name: { km: 'កំរៀង', latin: 'Kamrieng' }, id: '021201' },
  { name: { km: 'បឹងរាំង', latin: 'Boeng Reang' }, id: '021202' },
  { name: { km: 'អូរដា', latin: 'Ou Da' }, id: '021203' },
  { name: { km: 'ត្រាង', latin: 'Trang' }, id: '021204' },
  { name: { km: 'តាសែន', latin: 'Ta Saen' }, id: '021205' },
  { name: { km: 'តាក្រី', latin: 'Ta Krei' }, id: '021206' },
  { name: { km: 'ធិបតី', latin: 'Thipakdei' }, id: '021301' },
  { name: { km: 'គាស់ក្រឡ', latin: 'Kaos Krala' }, id: '021302' },
  { name: { km: 'ហប់', latin: 'Hab' }, id: '021303' },
  { name: { km: 'ព្រះផុស', latin: 'Preah Phos' }, id: '021304' },
  { name: { km: 'ដូនបា', latin: 'Doun Ba' }, id: '021305' },
  { name: { km: 'ឆ្នាល់មាន់', latin: 'Chhnal Moan' }, id: '021306' },
  { name: { km: 'ព្រែកជីក', latin: 'Preaek Chik' }, id: '021401' },
  { name: { km: 'ព្រៃត្រឡាច', latin: 'Prey Tralach' }, id: '021402' },
  { name: { km: 'មុខរាហ៍', latin: 'Mukh Reah' }, id: '021403' },
  { name: { km: 'ស្តុកប្រវឹក', latin: 'Sdok Pravoek' }, id: '021404' },
  { name: { km: 'បាសាក់', latin: 'Basak' }, id: '021405' },
  { name: { km: 'បាធាយ', latin: 'Batheay' }, id: '030101' },
  { name: { km: 'ច្បារអំពៅ', latin: 'Chbar Ampov' }, id: '030102' },
  { name: { km: 'ជាលា', latin: 'Chealea' }, id: '030103' },
  { name: { km: 'ជើងព្រៃ', latin: 'Cheung Prey' }, id: '030104' },
  { name: { km: 'មេព្រីង', latin: 'Me Pring' }, id: '030105' },
  { name: { km: 'ផ្អាវ', latin: "Ph'av" }, id: '030106' },
  { name: { km: 'សំបូរ', latin: 'Sambour' }, id: '030107' },
  { name: { km: 'សណ្ដែក', latin: 'Sandaek' }, id: '030108' },
  { name: { km: 'តាំងក្រាំង', latin: 'Tang Krang' }, id: '030109' },
  { name: { km: 'តាំងក្រសាំង', latin: 'Tang Krasang' }, id: '030110' },
  { name: { km: 'ត្រប់', latin: 'Trab' }, id: '030111' },
  { name: { km: 'ទំនប់', latin: 'Tumnob' }, id: '030112' },
  { name: { km: 'បុសខ្នុរ', latin: 'Bos Khnor' }, id: '030201' },
  { name: { km: 'ចំការអណ្ដូង', latin: 'Chamkar Andoung' }, id: '030202' },
  { name: { km: 'ជយោ', latin: 'Cheyyou' }, id: '030203' },
  { name: { km: 'ល្វាលើ', latin: 'Lvea Leu' }, id: '030204' },
  { name: { km: 'ស្ពឺ', latin: 'Spueu' }, id: '030205' },
  { name: { km: 'ស្វាយទាប', latin: 'Svay Teab' }, id: '030206' },
  { name: { km: 'តាអុង', latin: 'Ta Ong' }, id: '030207' },
  { name: { km: 'តាប្រុក', latin: 'Ta Prok' }, id: '030208' },
  { name: { km: 'ខ្នុរដំបង', latin: 'Khnor Dambang' }, id: '030301' },
  { name: { km: 'គោករវៀង', latin: 'Kouk Rovieng' }, id: '030302' },
  { name: { km: 'ផ្ដៅជុំ', latin: 'Pdau Chum' }, id: '030303' },
  { name: { km: 'ព្រៃចារ', latin: 'Prey Char' }, id: '030304' },
  { name: { km: 'ព្រីងជ្រុំ', latin: 'Pring Chrum' }, id: '030305' },
  { name: { km: 'សំពងជ័យ', latin: 'Sampong Chey' }, id: '030306' },
  { name: { km: 'ស្ដើងជ័យ', latin: 'Sdaeung Chey' }, id: '030307' },
  { name: { km: 'សូទិព្វ', latin: 'Soutib' }, id: '030308' },
  { name: { km: 'ស្រម៉រ', latin: 'Sramar' }, id: '030309' },
  { name: { km: 'ត្រពាំងគរ', latin: 'Trapeang Kor' }, id: '030310' },
  { name: { km: 'បឹងកុក', latin: 'Boeng Kok' }, id: '030501' },
  { name: { km: 'កំពង់ចាម', latin: 'Kampong Cham' }, id: '030502' },
  { name: { km: 'សំបួរមាស', latin: 'Sambuor Meas' }, id: '030503' },
  { name: { km: 'វាលវង់', latin: 'Veal Vong' }, id: '030504' },
  { name: { km: 'អំពិល', latin: 'Ampil' }, id: '030601' },
  { name: { km: 'ហាន់ជ័យ', latin: 'Hanchey' }, id: '030602' },
  { name: { km: 'កៀនជ្រៃ', latin: 'Kien Chrey' }, id: '030603' },
  { name: { km: 'គគរ', latin: 'Kokor' }, id: '030604' },
  { name: { km: 'កោះមិត្ដ', latin: 'Kaoh Mitt' }, id: '030605' },
  { name: { km: 'កោះរកា', latin: 'Kaoh Roka' }, id: '030606' },
  { name: { km: 'កោះសំរោង', latin: 'Kaoh Samraong' }, id: '030607' },
  { name: { km: 'កោះទន្ទឹម', latin: 'Kaoh Tontuem' }, id: '030608' },
  { name: { km: 'ក្រឡា', latin: 'Krala' }, id: '030609' },
  { name: { km: 'អូរស្វាយ', latin: 'Ou Svay' }, id: '030610' },
  { name: { km: 'រអាង', latin: "Ro'ang" }, id: '030611' },
  { name: { km: 'រំចេក', latin: 'Rumchek' }, id: '030612' },
  { name: { km: 'ស្រក', latin: 'Srak' }, id: '030613' },
  { name: { km: 'ទ្រាន', latin: 'Trean' }, id: '030614' },
  { name: { km: 'វិហារធំ', latin: 'Vihear Thum' }, id: '030615' },
  { name: { km: 'អង្គរបាន', latin: 'Angkor Ban' }, id: '030701' },
  { name: { km: 'កងតាណឹង', latin: 'Kang Ta Noeng' }, id: '030702' },
  { name: { km: 'ខ្ចៅ', latin: 'Khchau' }, id: '030703' },
  { name: { km: 'ពាមជីកង', latin: 'Peam Chi Kang' }, id: '030704' },
  { name: { km: 'ព្រែកកុយ', latin: 'Preaek Koy' }, id: '030705' },
  { name: { km: 'ព្រែកក្របៅ', latin: 'Preaek Krabau' }, id: '030706' },
  { name: { km: 'រាយប៉ាយ', latin: 'Reay Pay' }, id: '030707' },
  { name: { km: 'រកាអារ', latin: 'Roka Ar' }, id: '030708' },
  { name: { km: 'រកាគយ', latin: 'Roka Koy' }, id: '030709' },
  { name: { km: 'ស្ដៅ', latin: 'Sdau' }, id: '030710' },
  { name: { km: 'សូរគង', latin: 'Sour Kong' }, id: '030711' },
  { name: { km: 'កំពង់រាប', latin: 'Kampong Reab' }, id: '030801' },
  { name: { km: 'កោះសូទិន', latin: 'Kaoh Sotin' }, id: '030802' },
  { name: { km: 'ល្វេ', latin: 'Lve' }, id: '030803' },
  { name: { km: 'មហាលាភ', latin: 'Moha Leaph' }, id: '030804' },
  { name: { km: 'មហាខ្ញូង', latin: 'Moha Khnhoung' }, id: '030805' },
  { name: { km: 'ពាមប្រធ្នោះ', latin: 'Peam Prathnuoh' }, id: '030806' },
  { name: { km: 'ពង្រ', latin: 'Pongro' }, id: '030807' },
  { name: { km: 'ព្រែកតានង់', latin: 'Preaek Ta Nong' }, id: '030808' },
  { name: { km: 'បារាយណ៍', latin: 'Baray' }, id: '031301' },
  { name: { km: 'បឹងណាយ', latin: 'Boeng Nay' }, id: '031302' },
  { name: { km: 'ជ្រៃវៀន', latin: 'Chrey Vien' }, id: '031303' },
  { name: { km: 'ខ្វិតធំ', latin: 'Khvet Thum' }, id: '031304' },
  { name: { km: 'គរ', latin: 'Kor' }, id: '031305' },
  { name: { km: 'ក្រូច', latin: 'Krouch' }, id: '031306' },
  { name: { km: 'ល្វា', latin: 'Lvea' }, id: '031307' },
  { name: { km: 'មៀន', latin: 'Mien' }, id: '031308' },
  { name: { km: 'ព្រៃឈរ', latin: 'Prey Chhor' }, id: '031309' },
  { name: { km: 'សូរ្យសែន', latin: 'Sour Saen' }, id: '031310' },
  { name: { km: 'សំរោង', latin: 'Samraong' }, id: '031311' },
  { name: { km: 'ស្រង៉ែ', latin: 'Sragnae' }, id: '031312' },
  { name: { km: 'ថ្មពូន', latin: 'Thma Pun' }, id: '031313' },
  { name: { km: 'តុងរ៉ុង', latin: 'Tong Rong' }, id: '031314' },
  { name: { km: 'ត្រពាំងព្រះ', latin: 'Trapeang Preah' }, id: '031315' },
  { name: { km: 'បារាយណ៍', latin: 'Baray' }, id: '031401' },
  { name: { km: 'ជីបាល', latin: 'Chi Bal' }, id: '031402' },
  { name: { km: 'ខ្នារស', latin: 'Khnar Sa' }, id: '031403' },
  { name: { km: 'កោះអណ្ដែត', latin: 'Kaoh Andaet' }, id: '031404' },
  { name: { km: 'មានជ័យ', latin: 'Mean Chey' }, id: '031405' },
  { name: { km: 'ផ្ទះកណ្ដាល', latin: 'Phteah Kandal' }, id: '031406' },
  { name: { km: 'ប្រាំយ៉ាម', latin: 'Pram Yam' }, id: '031407' },
  { name: { km: 'ព្រែកដំបូក', latin: 'Preaek Dambouk' }, id: '031408' },
  { name: { km: 'ព្រែកពោធិ', latin: 'Preaek Pou' }, id: '031409' },
  { name: { km: 'ព្រែករំដេង', latin: 'Preaek Rumdeng' }, id: '031410' },
  { name: { km: 'ឫស្សីស្រុក', latin: 'Ruessei Srok' }, id: '031411' },
  { name: { km: 'ស្វាយពោធិ', latin: 'Svay Pou' }, id: '031412' },
  { name: { km: 'ស្វាយខ្សាច់ភ្នំ', latin: 'Svay Khsach Phnum' }, id: '031413' },
  { name: { km: 'ទងត្រឡាច', latin: 'Tong Tralach' }, id: '031414' },
  { name: { km: 'អារក្សត្នោត', latin: 'Areaks Tnot' }, id: '031501' },
  { name: { km: 'ដងក្ដារ', latin: 'Dang Kdar' }, id: '031503' },
  { name: { km: 'ខ្ពបតាងួន', latin: 'Khpob Ta Nguon' }, id: '031504' },
  { name: { km: 'មេសរជ្រៃ', latin: 'Me Sar Chrey' }, id: '031505' },
  { name: { km: 'អូរម្លូ', latin: 'Ou Mlu' }, id: '031506' },
  { name: { km: 'ពាមកោះស្នា', latin: 'Peam Kaoh Snar' }, id: '031507' },
  { name: { km: 'ព្រះអណ្ដូង', latin: 'Preah Andoung' }, id: '031508' },
  { name: { km: 'ព្រែកបាក់', latin: 'Preaek Bak' }, id: '031509' },
  { name: { km: 'ព្រែកកក់', latin: 'Preak Kak' }, id: '031510' },
  { name: { km: 'សូភាស', latin: 'Soupheas' }, id: '031512' },
  { name: { km: 'ទួលព្រះឃ្លាំង', latin: 'Tuol Preah Khleang' }, id: '031513' },
  { name: { km: 'ទួលសំបួរ', latin: 'Tuol Sambuor' }, id: '031514' },
  { name: { km: 'អញ្ចាញរូង', latin: 'Anhchanh Rung' }, id: '040101' },
  { name: { km: 'ឆ្នុកទ្រូ', latin: 'Chhnok Tru' }, id: '040102' },
  { name: { km: 'ចក', latin: 'Chak' }, id: '040103' },
  { name: { km: 'ខុនរ៉ង', latin: 'Khon Rang' }, id: '040104' },
  { name: { km: 'កំពង់ព្រះគគីរ', latin: 'Kampong Preah Kokir' }, id: '040105' },
  { name: { km: 'មេលំ', latin: 'Melum' }, id: '040106' },
  { name: { km: 'ផ្សារ', latin: 'Phsar' }, id: '040107' },
  { name: { km: 'ពេជចង្វារ', latin: 'Pech Changvar' }, id: '040108' },
  { name: { km: 'ពពេល', latin: 'Popel' }, id: '040109' },
  { name: { km: 'ពន្លៃ', latin: 'Ponley' }, id: '040110' },
  { name: { km: 'ត្រពាំងចាន់', latin: 'Trapeang Chan' }, id: '040111' },
  { name: { km: 'ជលសា', latin: 'Chol Sar' }, id: '040201' },
  { name: { km: 'កោះថ្កូវ', latin: 'Kaoh Thkov' }, id: '040202' },
  { name: { km: 'កំពង់អុស', latin: 'Kampong Ous' }, id: '040203' },
  { name: { km: 'ពាមឆ្កោក', latin: 'Peam Chhkaok' }, id: '040204' },
  { name: { km: 'ព្រៃគ្រី', latin: 'Prey Kri' }, id: '040205' },
  { name: { km: 'ផ្សារឆ្នាំង', latin: 'Phsar Chhnang' }, id: '040301' },
  { name: { km: 'កំពង់ឆ្នាំង', latin: 'Kampong Chhnang' }, id: '040302' },
  { name: { km: 'ប្អេរ', latin: "B'er" }, id: '040303' },
  { name: { km: 'ខ្សាម', latin: 'Khsam' }, id: '040304' },
  { name: { km: 'ច្រណូក', latin: 'Chranouk' }, id: '040401' },
  { name: { km: 'ដារ', latin: 'Dar' }, id: '040402' },
  { name: { km: 'កំពង់ហៅ', latin: 'Kampong Hau' }, id: '040403' },
  { name: { km: 'ផ្លូវទូក', latin: 'Phlov Tuk' }, id: '040404' },
  { name: { km: 'ពោធិ៍', latin: 'Pou' }, id: '040405' },
  { name: { km: 'ប្រឡាយមាស', latin: 'Pralay Meas' }, id: '040406' },
  { name: { km: 'សំរោងសែន', latin: 'Samraong Saen' }, id: '040407' },
  { name: { km: 'ស្វាយរំពារ', latin: 'Svay Rumpear' }, id: '040408' },
  { name: { km: 'ត្រងិល', latin: 'Trangel' }, id: '040409' },
  { name: { km: 'អំពិលទឹក', latin: 'Ampil Tuek' }, id: '040501' },
  { name: { km: 'ឈូកស', latin: 'Chhuk Sa' }, id: '040502' },
  { name: { km: 'ច្រេស', latin: 'Chres' }, id: '040503' },
  { name: { km: 'កំពង់ត្រឡាច', latin: 'Kampong Tralach' }, id: '040504' },
  { name: { km: 'លង្វែក', latin: 'Longveaek' }, id: '040505' },
  { name: { km: 'អូរឫស្សី', latin: 'Ou Ruessei' }, id: '040506' },
  { name: { km: 'ពានី', latin: 'Peani' }, id: '040507' },
  { name: { km: 'សែប', latin: 'Saeb' }, id: '040508' },
  { name: { km: 'តាជេស', latin: 'Ta Ches' }, id: '040509' },
  { name: { km: 'ថ្មឥដ្ឋ', latin: 'Thma Edth' }, id: '040510' },
  { name: { km: 'អណ្ដូងស្នាយ', latin: 'Andoung Snay' }, id: '040601' },
  { name: { km: 'បន្ទាយព្រាល', latin: 'Banteay Preal' }, id: '040602' },
  { name: { km: 'ជើងគ្រាវ', latin: 'Cheung Kreav' }, id: '040603' },
  { name: { km: 'ជ្រៃបាក់', latin: 'Chrey Bak' }, id: '040604' },
  { name: { km: 'គោកបន្ទាយ', latin: 'Kouk Banteay' }, id: '040605' },
  { name: { km: 'ក្រាំងលាវ', latin: 'Krang Leav' }, id: '040606' },
  { name: { km: 'ពង្រ', latin: 'Pongro' }, id: '040607' },
  { name: { km: 'ប្រស្នឹប', latin: 'Prasnoeb' }, id: '040608' },
  { name: { km: 'ព្រៃមូល', latin: 'Prey Mul' }, id: '040609' },
  { name: { km: 'រលាប្អៀរ', latin: "Rolea B'ier" }, id: '040610' },
  { name: { km: 'ស្រែថ្មី', latin: 'Srae Thmei' }, id: '040611' },
  { name: { km: 'ស្វាយជ្រុំ', latin: 'Svay Chrum' }, id: '040612' },
  { name: { km: 'ទឹកហូត', latin: 'Tuek Hout' }, id: '040613' },
  { name: { km: 'ឈានឡើង', latin: 'Chhean Laeung' }, id: '040701' },
  { name: { km: 'ខ្នារឆ្មារ', latin: 'Khnar Chhmar' }, id: '040702' },
  { name: { km: 'ក្រាំងល្វា', latin: 'Krang Lvea' }, id: '040703' },
  { name: { km: 'ពាម', latin: 'Peam' }, id: '040704' },
  { name: { km: 'សេដ្ឋី', latin: 'Sedthei' }, id: '040705' },
  { name: { km: 'ស្វាយ', latin: 'Svay' }, id: '040706' },
  { name: { km: 'ស្វាយជុក', latin: 'Svay Chuk' }, id: '040707' },
  { name: { km: 'ត្បែងខ្ពស់', latin: 'Tbaeng Khpos' }, id: '040708' },
  { name: { km: 'ធ្លកវៀន', latin: 'Thlok Vien' }, id: '040709' },
  { name: { km: 'អភិវឌ្ឍន៍', latin: 'Akphivoadth' }, id: '040801' },
  { name: { km: 'ជៀប', latin: 'Chieb' }, id: '040802' },
  { name: { km: 'ចោងម៉ោង', latin: 'Chaong Maong' }, id: '040803' },
  { name: { km: 'ក្បាលទឹក', latin: 'Kbal Tuek' }, id: '040804' },
  { name: { km: 'ខ្លុងពពក', latin: 'Khlong Popok' }, id: '040805' },
  { name: { km: 'ក្រាំងស្គារ', latin: 'Krang Skear' }, id: '040806' },
  { name: { km: 'តាំងក្រសាំង', latin: 'Tang Krasang' }, id: '040807' },
  { name: { km: 'ទួលខ្ពស់', latin: 'Tuol Khpos' }, id: '040808' },
  { name: { km: 'ក្តុលសែនជ័យ', latin: 'Kdol Saen Chey' }, id: '040809' },
  { name: { km: 'បរសេដ្ឋ', latin: 'Basedth' }, id: '050101' },
  { name: { km: 'កាត់ភ្លុក', latin: 'Kat Phluk' }, id: '050102' },
  { name: { km: 'និទាន', latin: 'Nitean' }, id: '050103' },
  { name: { km: 'ភក្ដី', latin: 'Pheakdei' }, id: '050104' },
  { name: { km: 'ភារីមានជ័យ', latin: 'Pheari Mean Chey' }, id: '050105' },
  { name: { km: 'ផុង', latin: 'Phong' }, id: '050106' },
  { name: { km: 'ពោធិអង្ក្រង', latin: 'Pou Angkrang' }, id: '050107' },
  { name: { km: 'ពោធិ៍ចំរើន', latin: 'Pou Chamraeun' }, id: '050108' },
  { name: { km: 'ពោធិ៍ម្រាល', latin: 'Pou Mreal' }, id: '050109' },
  { name: { km: 'ស្វាយចចិប', latin: 'Svay Chacheb' }, id: '050110' },
  { name: { km: 'ទួលអំពិល', latin: 'Tuol Ampil' }, id: '050111' },
  { name: { km: 'ទួលសាលា', latin: 'Tuol Sala' }, id: '050112' },
  { name: { km: 'កក់', latin: 'Kak' }, id: '050113' },
  { name: { km: 'ស្វាយរំពារ', latin: 'Svay Rumpear' }, id: '050114' },
  { name: { km: 'ព្រះខែ', latin: 'Preah Khae' }, id: '050115' },
  { name: { km: 'ច្បារមន', latin: 'Chbar Mon' }, id: '050201' },
  { name: { km: 'កណ្ដោលដុំ', latin: 'Kandaol Dom' }, id: '050202' },
  { name: { km: 'រការធំ', latin: 'Rokar Thum' }, id: '050203' },
  { name: { km: 'សុព័រទេព', latin: 'Sopoar Tep' }, id: '050204' },
  { name: { km: 'ស្វាយក្រវ៉ាន់', latin: 'Svay Kravan' }, id: '050205' },
  { name: { km: 'អង្គពពេល', latin: 'Angk Popel' }, id: '050301' },
  { name: { km: 'ជង្រុក', latin: 'Chongruk' }, id: '050302' },
  { name: { km: 'មហាឫស្សី', latin: 'Moha Ruessei' }, id: '050303' },
  { name: { km: 'ពេជ្រមុនី', latin: 'Pechr Muni' }, id: '050304' },
  { name: { km: 'ព្រះនិព្វាន', latin: 'Preah Nipean' }, id: '050305' },
  { name: { km: 'ព្រៃញាតិ', latin: 'Prey Nheat' }, id: '050306' },
  { name: { km: 'ព្រៃវិហារ', latin: 'Prey Vihear' }, id: '050307' },
  { name: { km: 'រកាកោះ', latin: 'Roka Kaoh' }, id: '050308' },
  { name: { km: 'ស្ដុក', latin: 'Sdok' }, id: '050309' },
  { name: { km: 'ស្នំក្រពើ', latin: 'Snam Krapeu' }, id: '050310' },
  { name: { km: 'ស្រង់', latin: 'Srang' }, id: '050311' },
  { name: { km: 'ទឹកល្អក់', latin: "Tuek L'ak" }, id: '050312' },
  { name: { km: 'វាល', latin: 'Veal' }, id: '050313' },
  { name: { km: 'ហោងសំណំ', latin: 'Haong Samnam' }, id: '050401' },
  { name: { km: 'រស្មីសាមគ្គី', latin: 'Reaksmei Sameakki' }, id: '050402' },
  { name: { km: 'ត្រពាំងជោ', latin: 'Trapeang Chour' }, id: '050403' },
  { name: { km: 'សង្កែសាទប', latin: 'Sangkae Satob' }, id: '050404' },
  { name: { km: 'តាសាល', latin: 'Ta Sal' }, id: '050405' },
  { name: { km: 'ចាន់សែន', latin: 'Chan Saen' }, id: '050501' },
  { name: { km: 'ជើងរាស់', latin: 'Cheung Roas' }, id: '050502' },
  { name: { km: 'ជំពូព្រឹក្ស', latin: 'Chumpu Proeks' }, id: '050503' },
  { name: { km: 'ក្សេមក្សាន្ដ', latin: 'Khsem Khsant' }, id: '050504' },
  { name: { km: 'ក្រាំងចេក', latin: 'Krang Chek' }, id: '050505' },
  { name: { km: 'មានជ័យ', latin: 'Mean Chey' }, id: '050506' },
  { name: { km: 'ព្រះស្រែ', latin: 'Preah Srae' }, id: '050507' },
  { name: { km: 'ព្រៃក្រសាំង', latin: 'Prey Krasang' }, id: '050508' },
  { name: { km: 'ត្រាចទង', latin: 'Trach Tong' }, id: '050509' },
  { name: { km: 'វាលពង់', latin: 'Veal Pong' }, id: '050510' },
  { name: { km: 'វាំងចាស់', latin: 'Veang Chas' }, id: '050511' },
  { name: { km: 'យុទ្ធសាមគ្គី', latin: 'Yutth Sameakki' }, id: '050512' },
  { name: { km: 'ដំណាក់រាំង', latin: 'Damnak Reang' }, id: '050513' },
  { name: { km: 'ពាំងល្វា', latin: 'Peang Lvea' }, id: '050514' },
  { name: { km: 'ភ្នំតូច', latin: 'Phnom Touch' }, id: '050515' },
  { name: { km: 'ចំបក់', latin: 'Chambak' }, id: '050601' },
  { name: { km: 'ជាំសង្កែ', latin: 'Choam Sangkae' }, id: '050602' },
  { name: { km: 'ដំបូករូង', latin: 'Dambouk Rung' }, id: '050603' },
  { name: { km: 'គិរីវន្ដ', latin: 'Kiri Voan' }, id: '050604' },
  { name: { km: 'ក្រាំងដីវ៉ាយ', latin: 'Krang Dei Vay' }, id: '050605' },
  { name: { km: 'មហាសាំង', latin: 'Moha Sang' }, id: '050606' },
  { name: { km: 'អូរ', latin: 'Ou' }, id: '050607' },
  { name: { km: 'ព្រៃរំដួល', latin: 'Prey Rumduol' }, id: '050608' },
  { name: { km: 'ព្រៃក្មេង', latin: 'Prey Kmeng' }, id: '050609' },
  { name: { km: 'តាំងសំរោង', latin: 'Tang Samraong' }, id: '050610' },
  { name: { km: 'តាំងស្យា', latin: 'Tang Sya' }, id: '050611' },
  { name: { km: 'ត្រែងត្រយឹង', latin: 'Traeng Trayueng' }, id: '050613' },
  { name: { km: 'រលាំងចក', latin: 'Roleang Chak' }, id: '050701' },
  { name: { km: 'កាហែង', latin: 'Kahaeng' }, id: '050702' },
  { name: { km: 'ខ្ទុំក្រាំង', latin: 'Khtum Krang' }, id: '050703' },
  { name: { km: 'ក្រាំងអំពិល', latin: 'Krang Ampil' }, id: '050704' },
  { name: { km: 'ព្នាយ', latin: 'Pneay' }, id: '050705' },
  { name: { km: 'រលាំងគ្រើល', latin: 'Roleang Kreul' }, id: '050706' },
  { name: { km: 'សំរោងទង', latin: 'Samrong Tong' }, id: '050707' },
  { name: { km: 'សំបូរ', latin: 'Sambour' }, id: '050708' },
  { name: { km: 'សែនដី', latin: 'Saen Dei' }, id: '050709' },
  { name: { km: 'ស្គុះ', latin: 'Skuh' }, id: '050710' },
  { name: { km: 'តាំងក្រូច', latin: 'Tang Krouch' }, id: '050711' },
  { name: { km: 'ធម្មតាអរ', latin: 'Thummoda Ar' }, id: '050712' },
  { name: { km: 'ត្រពាំងគង', latin: 'Trapeang Kong' }, id: '050713' },
  { name: { km: 'ទំព័រមាស', latin: 'Tumpoar Meas' }, id: '050714' },
  { name: { km: 'វល្លិសរ', latin: 'Voa Sar' }, id: '050715' },
  { name: { km: 'អមលាំង', latin: 'Amleang' }, id: '050801' },
  { name: { km: 'មនោរម្យ', latin: 'Monourom' }, id: '050802' },
  { name: { km: 'ប្រាំបីមុម', latin: 'Prambei Mum' }, id: '050804' },
  { name: { km: 'រុងរឿង', latin: 'Rung Roeang' }, id: '050805' },
  { name: { km: 'ទ័ពមាន', latin: 'Toap Mean' }, id: '050806' },
  { name: { km: 'វាលពន់', latin: 'Veal Pon' }, id: '050807' },
  { name: { km: 'យាអង្គ', latin: 'Yea Angk' }, id: '050808' },
  { name: { km: 'បាក់ស្នា', latin: 'Bak Sna' }, id: '060101' },
  { name: { km: 'បល្ល័ង្គ', latin: 'Ballangk' }, id: '060102' },
  { name: { km: 'បារាយណ៍', latin: 'Baray' }, id: '060103' },
  { name: { km: 'បឹង', latin: 'Boeng' }, id: '060104' },
  { name: { km: 'ចើងដើង', latin: 'Chaeung Daeung' }, id: '060105' },
  { name: { km: 'ឈូកខ្សាច់', latin: 'Chhuk Khsach' }, id: '060107' },
  { name: { km: 'ចុងដូង', latin: 'Chong Doung' }, id: '060108' },
  { name: { km: 'គគីធំ', latin: 'Kokir Thum' }, id: '060110' },
  { name: { km: 'ក្រវ៉ា', latin: 'Krava' }, id: '060111' },
  { name: { km: 'ត្នោតជុំ', latin: 'Tnaot Chum' }, id: '060117' },
  { name: { km: 'ជ័យ', latin: 'Chey' }, id: '060201' },
  { name: { km: 'ដំរីស្លាប់', latin: 'Damrei Slab' }, id: '060202' },
  { name: { km: 'កំពង់គោ', latin: 'Kampong Kou' }, id: '060203' },
  { name: { km: 'កំពង់ស្វាយ', latin: 'Kampong Svay' }, id: '060204' },
  { name: { km: 'នីពេជ', latin: 'Nipech' }, id: '060205' },
  { name: { km: 'ផាត់សណ្ដាយ', latin: 'Phat Sanday' }, id: '060206' },
  { name: { km: 'សាន់គ', latin: 'San Kor' }, id: '060207' },
  { name: { km: 'ត្បែង', latin: 'Tbaeng' }, id: '060208' },
  { name: { km: 'ត្រពាំងឫស្សី', latin: 'Trapeang Ruessei' }, id: '060209' },
  { name: { km: 'ក្ដីដូង', latin: 'Kdei Doung' }, id: '060210' },
  { name: { km: 'ព្រៃគុយ', latin: 'Prey Kuy' }, id: '060211' },
  { name: { km: 'ដំរីជាន់ខ្លា', latin: 'Damrei Choan Khla' }, id: '060301' },
  { name: { km: 'កំពង់ធំ', latin: 'Kampong Thum' }, id: '060302' },
  { name: { km: 'កំពង់រទេះ', latin: 'Kampong Roteh' }, id: '060303' },
  { name: { km: 'អូរកន្ធរ', latin: 'Ou Kanthor' }, id: '060304' },
  { name: { km: 'កំពង់ក្របៅ', latin: 'Kampong Krabau' }, id: '060306' },
  { name: { km: 'ព្រៃតាហ៊ូ', latin: 'Prey Ta Hu' }, id: '060308' },
  { name: { km: 'អាចារ្យលាក់', latin: 'Achar Leak' }, id: '060309' },
  { name: { km: 'ស្រយ៉ូវ', latin: 'Srayov' }, id: '060310' },
  { name: { km: 'ដូង', latin: 'Doung' }, id: '060401' },
  { name: { km: 'ក្រយា', latin: 'Kraya' }, id: '060402' },
  { name: { km: 'ផាន់ញើម', latin: 'Phan Nheum' }, id: '060403' },
  { name: { km: 'សាគ្រាម', latin: 'Sakream' }, id: '060404' },
  { name: { km: 'សាលាវិស័យ', latin: 'Sala Visai' }, id: '060405' },
  { name: { km: 'សាមគ្គី', latin: 'Sameakki' }, id: '060406' },
  { name: { km: 'ទួលគ្រើល', latin: 'Tuol Kreul' }, id: '060407' },
  { name: { km: 'ឈូក', latin: 'Chhuk' }, id: '060501' },
  { name: { km: 'គោល', latin: 'Koul' }, id: '060502' },
  { name: { km: 'សំបូរណ៍', latin: 'Sambour' }, id: '060503' },
  { name: { km: 'ស្រើង', latin: 'Sraeung' }, id: '060504' },
  { name: { km: 'តាំងក្រសៅ', latin: 'Tang Krasau' }, id: '060505' },
  { name: { km: 'ឈើទាល', latin: 'Chheu Teal' }, id: '060601' },
  { name: { km: 'ដងកាំបិត', latin: 'Dang Kambet' }, id: '060602' },
  { name: { km: 'ក្លែង', latin: 'Klaeng' }, id: '060603' },
  { name: { km: 'មានរិទ្ធ', latin: 'Mean Rith' }, id: '060604' },
  { name: { km: 'មានជ័យ', latin: 'Mean Chey' }, id: '060605' },
  { name: { km: 'ងន', latin: 'Ngan' }, id: '060606' },
  { name: { km: 'សណ្ដាន់', latin: 'Sandan' }, id: '060607' },
  { name: { km: 'សុចិត្រ', latin: 'Sochet' }, id: '060608' },
  { name: { km: 'ទំរីង', latin: 'Tum Ring' }, id: '060609' },
  { name: { km: 'បឹងល្វា', latin: 'Boeng Lvea' }, id: '060701' },
  { name: { km: 'ជ្រាប់', latin: 'Chroab' }, id: '060702' },
  { name: { km: 'កំពង់ថ្ម', latin: 'Kampong Thma' }, id: '060703' },
  { name: { km: 'កកោះ', latin: 'Kakaoh' }, id: '060704' },
  { name: { km: 'ក្រយា', latin: 'Kraya' }, id: '060705' },
  { name: { km: 'ព្នៅ', latin: 'Pnov' }, id: '060706' },
  { name: { km: 'ប្រាសាទ', latin: 'Prasat' }, id: '060707' },
  { name: { km: 'តាំងក្រសាំង', latin: 'Tang Krasang' }, id: '060708' },
  { name: { km: 'ទីពោ', latin: 'Ti Pou' }, id: '060709' },
  { name: { km: 'ត្បូងក្រពើ', latin: 'Tboung Krapeu' }, id: '060710' },
  { name: { km: 'បន្ទាយស្ទោង', latin: 'Banteay Stoung' }, id: '060801' },
  { name: { km: 'ចំណាក្រោម', latin: 'Chamna Kraom' }, id: '060802' },
  { name: { km: 'ចំណាលើ', latin: 'Chamna Leu' }, id: '060803' },
  { name: { km: 'កំពង់ចិនជើង', latin: 'Kampong Chen Cheung' }, id: '060804' },
  { name: { km: 'កំពង់ចិនត្បូង', latin: 'Kampong Chen Tboung' }, id: '060805' },
  { name: { km: 'ម្សាក្រង', latin: 'Msa Krang' }, id: '060806' },
  { name: { km: 'ពាមបាង', latin: 'Peam Bang' }, id: '060807' },
  { name: { km: 'ពពក', latin: 'Popok' }, id: '060808' },
  { name: { km: 'ប្រឡាយ', latin: 'Pralay' }, id: '060809' },
  { name: { km: 'ព្រះដំរី', latin: 'Preah Damrei' }, id: '060810' },
  { name: { km: 'រុងរឿង', latin: 'Rung Roeang' }, id: '060811' },
  { name: { km: 'សំព្រោជ', latin: 'Samprouch' }, id: '060812' },
  { name: { km: 'ទ្រា', latin: 'Trea' }, id: '060813' },
  { name: { km: 'ពង្រ', latin: 'Pongro' }, id: '060901' },
  { name: { km: 'ច្រនាង', latin: 'Chraneang' }, id: '060902' },
  { name: { km: 'ជ្រលង', latin: 'Chrolong' }, id: '060903' },
  { name: { km: 'ទ្រៀល', latin: 'Triel' }, id: '060904' },
  { name: { km: 'សូយោង', latin: 'Sou Young' }, id: '060905' },
  { name: { km: 'ស្រឡៅ', latin: 'Sralau' }, id: '060906' },
  { name: { km: 'ស្វាយភ្លើង', latin: 'Svay Phleung' }, id: '060907' },
  { name: { km: 'អណ្ដូងពោធិ៍', latin: 'Andoung Pou' }, id: '060908' },
  { name: { km: 'អង្គភ្នំតូច', latin: 'Angk Phnum Touch' }, id: '070101' },
  { name: { km: 'អង្គរជ័យ', latin: 'Ankor Chey' }, id: '070102' },
  { name: { km: 'ចំប៉ី', latin: 'Champei' }, id: '070103' },
  { name: { km: 'ដំបូកខ្ពស់', latin: 'Dambouk Khpos' }, id: '070104' },
  { name: { km: 'ដានគោម', latin: 'Dan Koum' }, id: '070105' },
  { name: { km: 'ដើមដូង', latin: 'Daeum Doung' }, id: '070106' },
  { name: { km: 'ម្រោម', latin: 'Mroum' }, id: '070107' },
  { name: { km: 'ភ្នំកុង', latin: 'Phnum Kong' }, id: '070108' },
  { name: { km: 'ប្រភ្នំ', latin: 'Praphnum' }, id: '070109' },
  { name: { km: 'សំឡាញ', latin: 'Samlanh' }, id: '070110' },
  { name: { km: 'តានី', latin: 'Tani' }, id: '070111' },
  {
    name: { km: 'បន្ទាយមាសខាងកើត', latin: 'Banteay Meas Khang Kaeut' },
    id: '070201',
  },
  {
    name: { km: 'បន្ទាយមាសខាងលិច', latin: 'Banteay Meas Khang lech' },
    id: '070202',
  },
  { name: { km: 'ព្រៃទន្លេ', latin: 'Prey Tonle' }, id: '070203' },
  { name: { km: 'សំរោងក្រោម', latin: 'Samraong Kraom' }, id: '070204' },
  { name: { km: 'សំរោងលើ', latin: 'Samraong Leu' }, id: '070205' },
  {
    name: { km: 'ស្ដេចគង់ខាងជើង', latin: 'Sdach Kong Khang Cheung' },
    id: '070206',
  },
  {
    name: { km: 'ស្ដេចគង់ខាងលិច', latin: 'Sdach Kong Khang lech' },
    id: '070207',
  },
  {
    name: { km: 'ស្ដេចគង់ខាងត្បូង', latin: 'Sdach Kong Khang Tboung' },
    id: '070208',
  },
  { name: { km: 'ត្នោតចុងស្រង់', latin: 'Tnoat Chong Srang' }, id: '070209' },
  {
    name: { km: 'ត្រពាំងសាលាខាងកើត', latin: 'Trapeang Sala Khang Kaeut' },
    id: '070210',
  },
  {
    name: { km: 'ត្រពាំងសាលាខាងលិច', latin: 'Trapeang Sala Khang Lech' },
    id: '070211',
  },
  { name: { km: 'ទូកមាសខាងកើត', latin: 'Tuk Meas Khang Kaeut' }, id: '070212' },
  { name: { km: 'ទូកមាសខាងលិច', latin: 'Tuk Meas Khang Lech' }, id: '070213' },
  {
    name: { km: 'វត្ដអង្គខាងជើង', latin: 'Voat Angk Khang Cheung' },
    id: '070214',
  },
  {
    name: { km: 'វត្ដអង្គខាងត្បូង', latin: 'Voat Angk Khang Tboung' },
    id: '070215',
  },
  { name: { km: 'បានៀវ', latin: 'Baniev' }, id: '070301' },
  { name: { km: 'តាកែន', latin: 'Takaen' }, id: '070302' },
  { name: { km: 'បឹងនិមល', latin: 'Boeng Nimol' }, id: '070303' },
  { name: { km: 'ឈូក', latin: 'Chhuk' }, id: '070304' },
  { name: { km: 'ដូនយ៉យ', latin: 'Doun Yay' }, id: '070305' },
  { name: { km: 'ក្រាំងស្បូវ', latin: 'Krang Sbov' }, id: '070306' },
  { name: { km: 'ក្រាំងស្នាយ', latin: 'Krang Snay' }, id: '070307' },
  { name: { km: 'ល្បើក', latin: 'Lbaeuk' }, id: '070308' },
  { name: { km: 'ត្រពាំងភ្លាំង', latin: 'Trapeang Phleang' }, id: '070309' },
  { name: { km: 'មានជ័យ', latin: 'Mean Chey' }, id: '070310' },
  { name: { km: 'នារាយណ៍', latin: 'Neareay' }, id: '070311' },
  { name: { km: 'សត្វពង', latin: 'Satv Pong' }, id: '070312' },
  { name: { km: 'ត្រពាំងបី', latin: 'Trapeang Bei' }, id: '070313' },
  { name: { km: 'ត្រមែង', latin: 'Tramaeng' }, id: '070314' },
  { name: { km: 'តេជោអភិវឌ្ឍន៍', latin: 'Dechou Akphivoadth' }, id: '070315' },
  { name: { km: 'ច្រេស', latin: 'Chres' }, id: '070401' },
  { name: { km: 'ជំពូវន្ដ', latin: 'Chumpu Voan' }, id: '070402' },
  { name: { km: 'ស្នាយអញ្ជិត', latin: 'Snay Anhchit' }, id: '070403' },
  { name: { km: 'ស្រែចែង', latin: 'Srae Chaeng' }, id: '070404' },
  { name: { km: 'ស្រែក្នុង', latin: 'Srae Knong' }, id: '070405' },
  { name: { km: 'ស្រែសំរោង', latin: 'Srae Samraong' }, id: '070406' },
  { name: { km: 'ត្រពាំងរាំង', latin: 'Trapeang Reang' }, id: '070407' },
  { name: { km: 'ដំណាក់សុក្រំ', latin: 'Damnak Sokram' }, id: '070501' },
  { name: { km: 'ដងទង់', latin: 'Dang Tong' }, id: '070502' },
  { name: { km: 'ឃ្ជាយខាងជើង', latin: 'Khcheay Khang Cheung' }, id: '070503' },
  {
    name: { km: 'ខ្ជាយខាងត្បូង', latin: 'Khcheay Khang Tboung' },
    id: '070504',
  },
  { name: { km: 'មានរិទ្ធិ', latin: 'Mean Ritth' }, id: '070505' },
  {
    name: { km: 'ស្រែជាខាងជើង', latin: 'Srae Chea Khang Cheung' },
    id: '070506',
  },
  {
    name: { km: 'ស្រែជាខាងត្បូង', latin: 'Srae Chea Khang Tboung' },
    id: '070507',
  },
  { name: { km: 'ទទុង', latin: 'Totung' }, id: '070508' },
  { name: { km: 'អង្គ រមាស', latin: 'Angk  Romeas' }, id: '070509' },
  { name: { km: 'ល្អាង', latin: "L'ang" }, id: '070510' },
  {
    name: { km: 'បឹងសាលាខាងជើង', latin: 'Boeng Sala Khang Cheung' },
    id: '070601',
  },
  {
    name: { km: 'បឹងសាលាខាងត្បូង', latin: 'Boeng Sala Khang Tboung' },
    id: '070602',
  },
  {
    name: { km: 'ដំណាក់កន្ទួតខាងជើង', latin: 'Damnak Kantuot Khang Cheung' },
    id: '070603',
  },
  {
    name: { km: 'ដំណាក់កន្ទួតខាងត្បូង', latin: 'Damnak Kantuot Khang Tboung' },
    id: '070604',
  },
  {
    name: { km: 'កំពង់ត្រាចខាងកើត', latin: 'Kampong Trach Khang Kaeut' },
    id: '070605',
  },
  {
    name: { km: 'កំពង់ត្រាចខាងលិច', latin: 'Kampong Trach Khang Lech' },
    id: '070606',
  },
  {
    name: { km: 'ប្រាសាទភ្នំខ្យង', latin: 'Prasat Phnom Khyang' },
    id: '070607',
  },
  { name: { km: 'ភ្នំប្រាសាទ', latin: 'Phnom Prasat' }, id: '070608' },
  { name: { km: 'អង្គសុរភី', latin: 'Ang Sophy' }, id: '070609' },
  { name: { km: 'ព្រែកក្រឹស', latin: 'Preaek Kroes' }, id: '070612' },
  {
    name: { km: 'ឫស្សីស្រុកខាងកើត', latin: 'Ruessei Srok Khang Kaeut' },
    id: '070613',
  },
  {
    name: { km: 'ឫស្សីស្រុកខាងលិច', latin: 'Ruessei Srok Khang Lech' },
    id: '070614',
  },
  {
    name: { km: 'ស្វាយទងខាងជើង', latin: 'Svay Tong Khang Cheung' },
    id: '070615',
  },
  {
    name: { km: 'ស្វាយទងខាងត្បូង', latin: 'Svay Tong Khang Tboung' },
    id: '070616',
  },
  { name: { km: 'បឹងទូក', latin: 'Boeng Tuk' }, id: '070701' },
  { name: { km: 'ជុំគ្រៀល', latin: 'Chum Kriel' }, id: '070702' },
  { name: { km: 'កំពង់ក្រែង', latin: 'Kampong Kraeng' }, id: '070703' },
  { name: { km: 'កំពង់សំរោង', latin: 'Kampong Samraong' }, id: '070704' },
  { name: { km: 'កណ្ដោល', latin: 'Kandaol' }, id: '070705' },
  { name: { km: 'កោះតូច', latin: 'Kaoh Touch' }, id: '070707' },
  { name: { km: 'កូនសត្វ', latin: 'Koun Satv' }, id: '070708' },
  { name: { km: 'ម៉ាក់ប្រាង្គ', latin: 'Makprang' }, id: '070709' },
  { name: { km: 'ព្រែកត្នោត', latin: 'Preaek Tnoat' }, id: '070711' },
  { name: { km: 'ព្រៃឃ្មុំ', latin: 'Prey Khmum' }, id: '070712' },
  { name: { km: 'ព្រៃថ្នង', latin: 'Prey Thnang' }, id: '070713' },
  { name: { km: 'ស្ទឹងកែវ', latin: 'Stueng Kaev' }, id: '070715' },
  { name: { km: 'ថ្មី', latin: 'Thmei' }, id: '070716' },
  { name: { km: 'ត្រពាំងព្រីង', latin: 'Trapeang Pring' }, id: '070717' },
  { name: { km: 'ត្រពាំងសង្កែ', latin: 'Trapeang Sangkae' }, id: '070718' },
  { name: { km: 'ត្រពាំងធំ', latin: 'Trapeang Thum' }, id: '070719' },
  { name: { km: 'កំពង់កណ្ដាល', latin: 'Kampong Kandal' }, id: '070801' },
  { name: { km: 'ក្រាំងអំពិល', latin: 'Krang Ampil' }, id: '070802' },
  { name: { km: 'កំពង់បាយ', latin: 'Kampong Bay' }, id: '070803' },
  { name: { km: 'អណ្ដូងខ្មែរ', latin: 'Andoung Khmer' }, id: '070804' },
  { name: { km: 'ត្រើយកោះ', latin: 'Traeuy Kaoh' }, id: '070805' },
  { name: { km: 'អំពៅព្រៃ', latin: 'Ampov Prey' }, id: '080101' },
  { name: { km: 'អន្លង់រមៀត', latin: 'Anlong Romiet' }, id: '080102' },
  { name: { km: 'បារគូ', latin: 'Barku' }, id: '080103' },
  { name: { km: 'បឹងខ្យាង', latin: 'Boeng Khyang' }, id: '080104' },
  { name: { km: 'ជើងកើប', latin: 'Cheung Kaeub' }, id: '080105' },
  { name: { km: 'ដើមឫស', latin: 'Daeum Rues' }, id: '080106' },
  { name: { km: 'កណ្ដោក', latin: 'Kandaok' }, id: '080107' },
  { name: { km: 'ថ្មី', latin: 'Thmei' }, id: '080108' },
  { name: { km: 'គោកត្រប់', latin: 'Kouk Trab' }, id: '080109' },
  { name: { km: 'ព្រះពុទ្ធ', latin: 'Preah Putth' }, id: '080113' },
  { name: { km: 'ព្រែករកា', latin: 'Preaek Roka' }, id: '080115' },
  { name: { km: 'ព្រែកស្លែង', latin: 'Preaek Slaeng' }, id: '080116' },
  { name: { km: 'រកា', latin: 'Roka' }, id: '080117' },
  { name: { km: 'រលាំងកែន', latin: 'Roleang Kaen' }, id: '080118' },
  { name: { km: 'សៀមរាប', latin: 'Siem Reab' }, id: '080122' },
  { name: { km: 'ត្បែង', latin: 'Tbaeng' }, id: '080125' },
  { name: { km: 'ត្រពាំងវែង', latin: 'Trapeang Veaeng' }, id: '080127' },
  { name: { km: 'ទ្រា', latin: 'Trea' }, id: '080128' },
  { name: { km: 'បន្ទាយដែក', latin: 'Banteay Daek' }, id: '080201' },
  { name: { km: 'ឈើទាល', latin: 'Chheu Teal' }, id: '080202' },
  { name: { km: 'ដីឥដ្ឋ', latin: 'Dei Edth' }, id: '080203' },
  { name: { km: 'កំពង់ស្វាយ', latin: 'Kampong Svay' }, id: '080204' },
  { name: { km: 'គគីរ', latin: 'Kokir' }, id: '080206' },
  { name: { km: 'គគីរធំ', latin: 'Kokir Thum' }, id: '080207' },
  { name: { km: 'ភូមិធំ', latin: 'Phum Thum' }, id: '080208' },
  { name: { km: 'សំរោងធំ', latin: 'Samraong Thum' }, id: '080211' },
  { name: { km: 'បាក់ដាវ', latin: 'Bak Dav' }, id: '080301' },
  { name: { km: 'ជ័យធំ', latin: 'Chey Thum' }, id: '080302' },
  { name: { km: 'កំពង់ចំលង', latin: 'Kampong Chamlang' }, id: '080303' },
  { name: { km: 'កោះចូរ៉ាម', latin: 'Kaoh Chouram' }, id: '080304' },
  { name: { km: 'កោះឧកញ៉ាតី', latin: 'Kaoh Oknha Tei' }, id: '080305' },
  { name: { km: 'ព្រះប្រសប់', latin: 'Preah Prasab' }, id: '080306' },
  { name: { km: 'ព្រែកអំពិល', latin: 'Preaek Ampil' }, id: '080307' },
  { name: { km: 'ព្រែកលួង', latin: 'Preaek Luong' }, id: '080308' },
  { name: { km: 'ព្រែកតាកូវ', latin: 'Preaek Ta kov' }, id: '080309' },
  { name: { km: 'ព្រែកតាមាក់', latin: 'Preaek Ta Meak' }, id: '080310' },
  { name: { km: 'ពុកឫស្សី', latin: 'Puk Ruessei' }, id: '080311' },
  { name: { km: 'រកាជន្លឹង', latin: 'Roka Chonlueng' }, id: '080312' },
  { name: { km: 'សន្លុង', latin: 'Sanlung' }, id: '080313' },
  { name: { km: 'ស៊ីធរ', latin: 'Sithor' }, id: '080314' },
  { name: { km: 'ស្វាយជ្រំ', latin: 'Svay Chrum' }, id: '080315' },
  { name: { km: 'ស្វាយរមៀត', latin: 'Svay Romiet' }, id: '080316' },
  { name: { km: 'តាឯក', latin: 'Ta Aek' }, id: '080317' },
  { name: { km: 'វិហារសួគ៌', latin: 'Vihear Suork' }, id: '080318' },
  { name: { km: 'ឈើខ្មៅ', latin: 'Chheu Kmau' }, id: '080401' },
  { name: { km: 'ជ្រោយតាកែវ', latin: 'Chrouy Ta Kaev' }, id: '080402' },
  { name: { km: 'កំពង់កុង', latin: 'Kampong Kong' }, id: '080403' },
  { name: { km: 'កោះធំ ‹ក›', latin: 'Kaoh Thum Ka' }, id: '080404' },
  { name: { km: 'កោះធំ ‹ខ›', latin: 'Kaoh Thum Kha' }, id: '080405' },
  { name: { km: 'លើកដែក', latin: 'Leuk Daek' }, id: '080407' },
  { name: { km: 'ពោធិ៍បាន', latin: 'Pouthi Ban' }, id: '080408' },
  { name: { km: 'ព្រែកជ្រៃ', latin: 'Prea​ek Chrey' }, id: '080409' },
  { name: { km: 'ព្រែកស្ដី', latin: 'Preaek Sdei' }, id: '080410' },
  { name: { km: 'ព្រែកថ្មី', latin: 'Preaek Thmei' }, id: '080411' },
  { name: { km: 'សំពៅពូន', latin: 'Sampeou Poun' }, id: '080412' },
  { name: { km: 'កំពង់ភ្នំ', latin: 'Kampong Phnum' }, id: '080501' },
  { name: { km: 'ក្អមសំណរ', latin: "K'am Samnar" }, id: '080502' },
  { name: { km: 'ខ្ពបអាទាវ', latin: 'Khpob Ateav' }, id: '080503' },
  { name: { km: 'ពាមរាំង', latin: 'Peam Reang' }, id: '080504' },
  { name: { km: 'ព្រែកដាច់', latin: 'Preaek Dach' }, id: '080505' },
  { name: { km: 'ព្រែកទន្លាប់', latin: 'Preaek Tonloab' }, id: '080506' },
  { name: { km: 'សណ្ដារ', latin: 'Sandar' }, id: '080507' },
  { name: { km: 'អរិយក្សត្រ', latin: 'Akreiy Ksatr' }, id: '080601' },
  { name: { km: 'បារុង', latin: 'Barong' }, id: '080602' },
  { name: { km: 'បឹងគ្រំ', latin: 'Boeng Krum' }, id: '080603' },
  { name: { km: 'កោះកែវ', latin: 'Kaoh Kaev' }, id: '080604' },
  { name: { km: 'កោះរះ', latin: 'Kaoh Reah' }, id: '080605' },
  { name: { km: 'ល្វាសរ', latin: 'Lvea Sar' }, id: '080606' },
  { name: { km: 'ពាមឧកញ៉ាអុង', latin: 'Peam Oknha Ong' }, id: '080607' },
  { name: { km: 'ភូមិធំ', latin: 'Phum Thum' }, id: '080608' },
  { name: { km: 'ព្រែកក្មេង', latin: 'Preaek Kmeng' }, id: '080609' },
  { name: { km: 'ព្រែករៃ', latin: 'Preaek Rey' }, id: '080610' },
  { name: { km: 'ព្រែកឫស្សី', latin: 'Preaek Ruessei' }, id: '080611' },
  { name: { km: 'សំបួរ', latin: 'Sambuor' }, id: '080612' },
  { name: { km: 'សារិកាកែវ', latin: 'Sarikakaev' }, id: '080613' },
  { name: { km: 'ថ្មគរ', latin: 'Thma Kor' }, id: '080614' },
  { name: { km: 'ទឹកឃ្លាំង', latin: 'Tuek Khleang' }, id: '080615' },
  { name: { km: 'ព្រែកអញ្ចាញ', latin: 'Preaek Anhchanh' }, id: '080703' },
  { name: { km: 'ព្រែកដំបង', latin: 'Preaek Dambang' }, id: '080704' },
  { name: { km: 'រកាកោង ទី ១', latin: 'Roka Kong Ti Muoy' }, id: '080707' },
  { name: { km: 'រកាកោង ទី ២', latin: 'Roka Kong Ti Pir' }, id: '080708' },
  { name: { km: 'ឫស្សីជ្រោយ', latin: 'Ruessei Chrouy' }, id: '080709' },
  { name: { km: 'សំបួរមាស', latin: 'Sambuor Meas' }, id: '080710' },
  { name: { km: 'ស្វាយអំពារ', latin: 'Svay Ampear' }, id: '080711' },
  { name: { km: 'បែកចាន', latin: 'Baek Chan' }, id: '080801' },
  { name: { km: 'ឆក់ឈើនាង', latin: 'Chhak Chheu Neang' }, id: '080803' },
  { name: { km: 'ដំណាក់អំពិល', latin: 'Damnak Ampil' }, id: '080804' },
  { name: { km: 'ក្រាំងម្កាក់', latin: 'Krang Mkak' }, id: '080807' },
  { name: { km: 'លំហាច', latin: 'Lumhach' }, id: '080808' },
  { name: { km: 'ម្កាក់', latin: 'Mkak' }, id: '080809' },
  { name: { km: 'ពើក', latin: 'Peuk' }, id: '080811' },
  { name: { km: 'ព្រៃពួច', latin: 'Prey Puoch' }, id: '080813' },
  { name: { km: 'សំរោងលើ', latin: 'Samraong Leu' }, id: '080814' },
  { name: { km: 'ទួលព្រេជ', latin: 'Tuol Prech' }, id: '080816' },
  { name: { km: 'ឈ្វាំង', latin: 'Chhveang' }, id: '080901' },
  { name: { km: 'ជ្រៃលាស់', latin: 'Chrey Loas' }, id: '080902' },
  { name: { km: 'កំពង់ហ្លួង', latin: 'Kampong Luong' }, id: '080903' },
  { name: { km: 'កំពង់អុស', latin: 'Kampong Os' }, id: '080904' },
  { name: { km: 'កោះចិន', latin: 'Kaoh Chen' }, id: '080905' },
  { name: { km: 'ភ្នំបាត', latin: 'Phnum Bat' }, id: '080906' },
  { name: { km: 'ពញាឮ', latin: 'Ponhea Lueu' }, id: '080907' },
  { name: { km: 'ព្រែកតាទែន', latin: 'Preaek Ta Teaen' }, id: '080910' },
  { name: { km: 'ផ្សារដែក', latin: 'Phsar Daek' }, id: '080911' },
  { name: { km: 'ទំនប់ធំ', latin: 'Tumnob Thum' }, id: '080913' },
  { name: { km: 'វិហារហ្លួង', latin: 'Vihear Luong' }, id: '080914' },
  { name: { km: 'ខ្ពប', latin: 'Khpob' }, id: '081001' },
  { name: { km: 'កោះខែល', latin: 'Kaoh Khael' }, id: '081003' },
  { name: { km: 'កោះខ្សាច់ទន្លា', latin: 'Kaoh Khsach Tonlea' }, id: '081004' },
  { name: { km: 'ក្រាំងយ៉ូវ', latin: 'Krang Yov' }, id: '081005' },
  { name: { km: 'ប្រាសាទ', latin: 'Prasat' }, id: '081006' },
  { name: { km: 'ព្រែកអំបិល', latin: 'Preaek Ambel' }, id: '081007' },
  { name: { km: 'ព្រែកគយ', latin: 'Preaek Koy' }, id: '081008' },
  { name: { km: 'ស្អាងភ្នំ', latin: "S'ang Phnum" }, id: '081010' },
  { name: { km: 'ស្វាយប្រទាល', latin: 'Svay Prateal' }, id: '081012' },
  { name: { km: 'តាលន់', latin: 'Ta Lon' }, id: '081014' },
  { name: { km: 'ត្រើយស្លា', latin: 'Traeuy Sla' }, id: '081015' },
  { name: { km: 'ទឹកវិល', latin: 'Tuek Vil' }, id: '081016' },
  { name: { km: 'តាក្ដុល', latin: 'Ta Kdol' }, id: '081101' },
  { name: { km: 'ព្រែកឫស្សី', latin: 'Prek Ruessey' }, id: '081102' },
  { name: { km: 'ដើមមៀន', latin: 'Doeum Mien' }, id: '081103' },
  { name: { km: 'តាខ្មៅ', latin: 'Ta Khmao' }, id: '081104' },
  { name: { km: 'ព្រែកហូរ', latin: 'Prek Ho' }, id: '081105' },
  { name: { km: 'កំពង់សំណាញ់', latin: 'Kampong Samnanh' }, id: '081106' },
  { name: { km: 'ស្វាយរលំ', latin: 'Svay Rolum' }, id: '081107' },
  { name: { km: 'កោះអន្លង់ចិន', latin: 'Kaoh Anlong Chen' }, id: '081108' },
  { name: { km: 'សិត្បូ', latin: 'Setbou' }, id: '081109' },
  { name: { km: 'រកាខ្ពស់', latin: 'Roka Khpos' }, id: '081110' },
  { name: { km: 'អណ្ដូងទឹក', latin: 'Andoung Tuek' }, id: '090101' },
  { name: { km: 'កណ្ដោល', latin: 'Kandaol' }, id: '090102' },
  { name: { km: 'តានូន', latin: 'Ta Noun' }, id: '090103' },
  { name: { km: 'ថ្មស', latin: 'Thma Sa' }, id: '090104' },
  { name: { km: 'កោះស្ដេច', latin: 'Kaoh Sdach' }, id: '090201' },
  { name: { km: 'ភ្ញីមាស', latin: 'Phnhi Meas' }, id: '090202' },
  { name: { km: 'ព្រែកខ្សាច់', latin: 'Preaek Khsach' }, id: '090203' },
  { name: { km: 'ជ្រោយប្រស់', latin: 'Chrouy Pras' }, id: '090301' },
  { name: { km: 'កោះកាពិ', latin: 'Kaoh Kapi' }, id: '090302' },
  { name: { km: 'តាតៃក្រោម', latin: 'Ta Tai Kraom' }, id: '090303' },
  { name: { km: 'ត្រពាំងរូង', latin: 'Trapeang Rung' }, id: '090304' },
  { name: { km: 'ស្មាច់មានជ័យ', latin: 'Smach Mean Chey' }, id: '090401' },
  { name: { km: 'ដងទង់', latin: 'Dang Tong' }, id: '090402' },
  { name: { km: 'ស្ទឹងវែង', latin: 'Stueng Veaeng' }, id: '090403' },
  { name: { km: 'ប៉ាក់ខ្លង', latin: 'Pak Khlang' }, id: '090501' },
  { name: { km: 'ពាមក្រសោប', latin: 'Peam Krasaob' }, id: '090502' },
  { name: { km: 'ទួលគគីរ', latin: 'Tuol Kokir' }, id: '090503' },
  { name: { km: 'បឹងព្រាវ', latin: 'Boeng Preav' }, id: '090601' },
  { name: { km: 'ជី ខ ក្រោម', latin: 'Chi Kha Kraom' }, id: '090602' },
  { name: { km: 'ជី ខ លើ', latin: 'Chi kha Leu' }, id: '090603' },
  { name: { km: 'ជ្រោយស្វាយ', latin: 'Chrouy Svay' }, id: '090604' },
  { name: { km: 'ដងពែង', latin: 'Dang Peaeng' }, id: '090605' },
  { name: { km: 'ស្រែអំបិល', latin: 'Srae Ambel' }, id: '090606' },
  { name: { km: 'តាទៃលើ', latin: 'Ta Tey Leu' }, id: '090701' },
  { name: { km: 'ប្រឡាយ', latin: 'Pralay' }, id: '090702' },
  { name: { km: 'ជំនាប់', latin: 'Chumnoab' }, id: '090703' },
  { name: { km: 'ឫស្សីជ្រុំ', latin: 'Ruessei Chrum' }, id: '090704' },
  { name: { km: 'ជីផាត', latin: 'Chi Phat' }, id: '090705' },
  { name: { km: 'ថ្មដូនពៅ', latin: 'Thma Doun Pov' }, id: '090706' },
]

export default communes
