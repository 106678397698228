import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

export const getRoutes = async (query) => {
  const url = `${apiBaseUrl}/api/routes/with-route-color?includeAll=true&geometryType=esriGeometryPoint&units=esriSRUnit_Meter&geometry=${query.geometry}&distance=${query.distant}`
  const result = await axios.get(url)
  return result.data
}

export const getRouteById = async (id) => {
  const url = `${apiBaseUrl}/api/routes/query?where=OBJECTID=${id}`
  const result = await axios.get(url)
  return result.data[0]
}

export const patchRoute = async (values) => {
  const url = `${apiBaseUrl}/api/routes/patch`
  const data = {
    features: {
      attributes: values,
    },
  }
  const response = await axios.patch(url, data)
  const success = response.data.updateResults[0].success
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const changeRouteScheduleStatus = async (values) => {
  const url = `${apiBaseUrl}/api/route-history`
  return await axios.post(url, values)
}

export const getRouteHistory = async (routeID) => {
  const url = `${apiBaseUrl}/api/route-history?routeId=${routeID}`
  return await axios.get(url)
}

export const patchRouteHistory = async (id, status) => {
  const url = `${apiBaseUrl}/api/route-history/${id}`
  return await axios.patch(url, { status })
}
