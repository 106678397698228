import { Button, Form, Input } from 'antd'

import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { UserRoleSelector } from 'Components'
import styled from 'styled-components'
import { LocationSelector } from 'Components/LocationSelector/LocationSelector'
import FormItem from 'antd/lib/form/FormItem'

interface Props {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
  isAdd?: boolean
  initialValues?: any
}

export const UserForm = (props: Props) => {
  const { onSubmit, saving, form, initialValues, isAdd } = props

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 4 }}
      initialValues={initialValues}
    >
      {initialValues?.id && (
        <p style={{ padding: '1rem' }}>ID : &ensp;{initialValues?.id}</p>
      )}

      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Role"
        name="role"
        rules={[{ required: false, message: 'Required' }]}
      >
        <UserRoleSelector></UserRoleSelector>
      </Form.Item>

      <Form.Item
        label="Province"
        name="province_code"
        rules={[{ required: false, message: 'Required' }]}
      >
        <LocationSelector></LocationSelector>
      </Form.Item>

      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item label="Collector name" name="collector_code">
        <Input></Input>
      </Form.Item>

      {
        <Form.Item
          help={
            !isAdd ? "leave password blank if you don't want to change" : ''
          }
          label="Password"
          name="password"
          rules={[{ required: isAdd, message: 'Required' }]}
        >
          <Input></Input>
        </Form.Item>
      }

      <Form.Item wrapperCol={{ offset: 3 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
