import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  types,
  getParent,
} from 'mobx-state-tree'

import { Bill } from 'Models/BillModel'
import { Property } from 'Models/PropertyModel'
import moment from 'moment'
import { user } from 'Models/UserModel'

const Geometry = types.model('Geometry', {
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
})

export const Citizen = types
  .model('Citizen', {
    OBJECTID: types.identifier,
    // CustomerID: types.maybeNull(types.string),
    Name: types.maybeNull(types.string),
    Gender: types.maybeNull(
      types.union(types.literal('Male'), types.literal('Female'))
    ),
    DateOfBirth: types.maybeNull(types.number),
    Phone: types.maybeNull(types.string),
    HouseNo: types.maybeNull(types.string),
    StreetNo: types.maybeNull(types.string),
    Sangkat: types.maybeNull(types.string),
    Khan: types.maybeNull(types.string),
    City: types.maybeNull(types.string),
    // IsNewRegister: types.maybeNull(types.boolean),
    FullAdress: types.maybeNull(types.string),
    CreateDate: types.maybeNull(types.number),
    EditDate: types.maybeNull(types.number),
    // geometry: types.maybe(Geometry),
    properties: types.optional(types.array(Property), []),
    bills: types.optional(types.array(Bill), []),
    loading: false,
    isDeleting: false,
  })
  .actions((self) => ({
    loadProperties: flow(function* () {
      self.loading = true
      const properties = yield api.getCitizenProperties(self.OBJECTID)
      const mappedProperties = properties?.map((property: any) => {
        return {
          ...property.attributes,
          OBJECTID: property.attributes.OBJECTID + '',
          geometry: {
            lng: property.geometry?.x,
            lat: property.geometry?.y,
          },
        }
      })
      self.properties = mappedProperties
      self.loading = false
    }),
    loadBills: flow(function* () {
      self.loading = true
      const bills = yield api.getCitizenBills(self.OBJECTID)
      const mappedBills = bills.map((bill: any) => {
        return {
          ...bill.attributes,
          OBJECTID: bill.attributes.OBJECTID + '',
        }
      })
      console.log('mappedBills: ', mappedBills)
      self.bills = mappedBills
      self.loading = false
    }),
    edit: flow(function* (values) {
      const data: any = {
        features: {
          attributes: {
            ...values,
            OBJECTID: self.OBJECTID,
            Editor: user.name,
            EditDate: moment().valueOf(),
          },
        },
      }
      if (values.geometry && values.geometry.lng && values.geometry.lat) {
        data.features.geometry = {
          x: values.geometry.lng,
          y: values.geometry.lat,
        }
      }
      // *** NEED VARIFICATION ***
      // yield api.patchRoute(data)
      yield api.edit(data)
      applySnapshot(self, data)
    }),
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.OBJECTID + '')
      const store = getParent(self, 2) as Instance<typeof CitizenStore>
      store.remove(self)
    }),
    removeProperty(item: any) {
      self.properties.splice(self.properties.indexOf(item), 1)
    },
    removeBill(item: any) {
      self.bills.splice(self.bills.indexOf(item), 1)
    },
  }))
  .views((self) => ({
    get unpaidBills() {
      return self.bills.filter((bill) => bill.Paid !== 1)
    },
    get unpaidBillTotalAmount() {
      return self.bills
        .filter((bill) => bill.Paid !== 1)
        .reduce((total, bill) => {
          return total + (bill.Amount || 0)
        }, 0)
    },
  }))

// *** NEED VARIFICATION ***
const CitizenStore = types
  .model('CitizenStore', {
    // ===================
    // *** MIGHT NEED TO REMOVE THIS ***
    citizen: types.maybeNull(Citizen),
    // ===================
    citizens: types.optional(types.array(Citizen), []),
    selectedCitizen: types.safeReference(Citizen),
    loading: false,
  })
  .actions((self) => ({
    setCitizen(citizen: any) {
      self.citizen = citizen
    },
    loadCitizens: flow(function* () {
      applySnapshot(self.citizens, [])
      self.loading = true
      const citizens = yield api.getPhoneNumberNotNull()
      const mappedCitizens = citizens.map((citizen: any) => {
        return {
          ...citizen.attributes,
          OBJECTID: citizen.attributes.OBJECTID + '',
          geometry: {
            lng: citizen.geometry?.x,
            lat: citizen.geometry?.y,
          },
        }
      })

      applySnapshot(self.citizens, mappedCitizens)
      self.loading = false
    }),

    loadCitizensByImportId: flow(function* (importId: string) {
      applySnapshot(self.citizens, [])
      self.loading = true
      const citizens = yield api.getCitizenByImportId(importId)
      const mappedCitizens = citizens.map((citizen: any) => {
        return {
          ...citizen.attributes,
          OBJECTID: citizen.attributes.OBJECTID + '',
          geometry: {
            lng: citizen.geometry?.x,
            lat: citizen.geometry?.y,
          },
        }
      })

      applySnapshot(self.citizens, mappedCitizens)
      self.loading = false
    }),

    loadCitizensByPhoneNumber: flow(function* (phoneNumber: string) {
      applySnapshot(self.citizens, [])
      self.loading = true
      const citizens = yield api.getCitizenByPhoneNumber(phoneNumber)
      const mappedCitizens = citizens.map((citizen: any) => {
        return {
          ...citizen.attributes,
          OBJECTID: citizen.attributes.OBJECTID + '',
          geometry: {
            lng: citizen.geometry?.x,
            lat: citizen.geometry?.y,
          },
        }
      })

      applySnapshot(self.citizens, mappedCitizens)
      self.loading = false
    }),

    // async deleteAllCitizens() {
    //   if (self.citizens.length !== 0) {
    //     await Bluebird.map(
    //       self.citizens,
    //       (citizen: any) => {
    //         return citizen.delete()
    //       },
    //       {
    //         concurrency: 5,
    //       }
    //     )
    //     this.emptyCitizens()
    //   }
    // },

    emptyCitizens() {
      applySnapshot(self.citizens, [])
    },

    setSelectedCitizen(citizen: string | undefined) {
      // @ts-ignore:
      self.selectedCitizen = citizen
    },
    addCitizen: flow(function* (citizen: ICitizen) {
      const data: any = {
        features: {
          attributes: {
            ...citizen,
          },
        },
      }
      // if (citizen.geometry) {
      //   data.features.geometry = citizen.geometry
      // }
      const result = yield api.add(data)
      if (result.error) {
        alert('Cannot add member ' + result.error.details[0])
      } else {
        const OBJECTID = result?.addResults[0]?.objectId + ''
        const addedCitizen: ICitizen = { ...citizen, OBJECTID }
        self.citizens.push(addedCitizen)
        return addedCitizen
      }
    }),
    remove(item: any) {
      self.citizens.splice(self.citizens.indexOf(item), 1)
    },
  }))

export interface ICitizen extends Instance<typeof Citizen> {}
export const citizenStore = CitizenStore.create({
  citizens: [],
  selectedCitizen: '1',
})
