import * as api from './api'

import React, { useState } from 'react'
import { Select, message } from 'antd'

import { Citizen } from './api'
import styled from 'styled-components'

const { Option } = Select

console.log('CitizenSelector: ')

const StyledSelect = styled(Select)`
  width: 250px;
`

interface Props {
  onCitizenIdSelected?: (id: string) => void
  onCitizenSelected?: (citizen: Citizen) => void
}

export const CitizenSelector = (props: Props) => {
  const { onCitizenSelected, onCitizenIdSelected } = props
  const [citizens, setCitizens] = useState<Citizen[]>([])
  const [loading, setLoading] = useState(false)

  async function searchCitizen(query: string) {
    setLoading(true)
    const queryString = `where=Name LIKE '${query}%' OR Phone LIKE '${query}%' OR OBJECTID LIKE '${query}%'&resultRecordCount=15`
    // OR OBJECTID LIKE '%${query}%'
    try {
      const queryCitizens = await api.getCitizens(queryString)
      setCitizens(queryCitizens)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }

      setCitizens([])
    }
    setLoading(false)
  }

  function onChange(value: any) {
    const citizen = citizens.find((citizen) => {
      return citizen.attributes.OBJECTID + '' === value
    })
    if (citizen) {
      onCitizenSelected && onCitizenSelected(citizen)
    }
    onCitizenIdSelected && onCitizenIdSelected(value + '')
  }

  function onSearch(val: string) {
    if (val.length > 0) {
      searchCitizen(val)
    } else {
      setCitizens([])
    }
  }

  return (
    <StyledSelect
      {...props}
      showSearch
      loading={loading}
      placeholder="Select a citizen"
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option) => {
        if (option && option.children) {
          const match =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          return match
        } else {
          return false
        }
      }}
    >
      <Option key={'NONE'} value={''}>
        None
      </Option>
      {citizens.map((citizen) => {
        return (
          <Option
            key={citizen.attributes.OBJECTID}
            value={citizen.attributes.OBJECTID + ''}
          >
            {`${citizen.attributes.Name} (${citizen.attributes.Phone}) (id: ${citizen.attributes.OBJECTID})`}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
