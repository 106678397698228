import { IUser, propertyStore, userStore } from 'Models'
import React, { useState } from 'react'
import { Select, message } from 'antd'

import styled from 'styled-components'

const { Option } = Select

interface SelectProp {
  loading: boolean
}

const StyledSelect = styled(Select)<SelectProp>`
  width: 250px;
  color: ${(props) => (props.loading ? 'coral' : 'black')};
`

interface Props {
  onIdSelected?: (id: string, value: string) => void
  onSelected?: (user: IUser) => void
  disabled?: boolean
  ref?: any
}

export const AddedBySearchInput = (props: Props) => {
  const { onIdSelected, onSelected, disabled } = props
  const { users } = userStore
  const [loading, setLoading] = useState(false)

  function onChange(value: any, option: any) {
    onIdSelected && onIdSelected(value + '', option?.children)
  }

  return (
    <StyledSelect
      {...props}
      showSearch
      loading={loading}
      placeholder="Type to Search User"
      optionFilterProp="children"
      onChange={onChange}
      disabled={disabled}
      filterOption={(input, option) => {
        if (option && option.children) {
          const match =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          return match
        } else {
          return false
        }
      }}
    >
      <Option key="NONE" value="">
        None
      </Option>
      {users.map((obj) => {
        const id = obj.id ? obj.id : ''
        const name = obj.name ? `${obj.name}` : ''

        return (
          <Option key={id} value={id + ''}>
            {name}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
