import { Button } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import React from 'react'

interface Props {
  onClick: () => void
}

export const MoreButton = (props: Props) => {
  function onClick(e: any) {
    e.stopPropagation()
    props.onClick && props.onClick()
  }

  return (
    <Button
      {...props}
      onClick={onClick}
      shape="circle"
      icon={<MoreOutlined />}
    ></Button>
  )
}
