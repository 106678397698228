import { Popconfirm } from 'antd'
import React from 'react'

import { Button } from 'Components'
import { WarningFilled } from '@ant-design/icons'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, WasteCompany } from 'Auth/Role'

interface DeleteConfirmButtonProps {
  deleting?: boolean
  onConfirmClick: () => void
  danger?: boolean
  children?: any
}

export const DeleteAllConfirmButton = (props: DeleteConfirmButtonProps) => {
  const { deleting, onConfirmClick } = props

  return componentLevelAuthorization(
    (<Popconfirm
      title="Are you sure to delete?"
      onConfirm={onConfirmClick}
      okText="Delete"
      cancelText="Cancel"
      icon={<WarningFilled style={{ color: 'red' }} />}
    >
      <Button {...props} loading={deleting}>
        Delete All
      </Button>
    </Popconfirm>),
    [Admin, WasteCompany]
  )
}
