import {
  IPropertyType,
  propertyTypeStore,
} from 'Models/PropertyTypeModel/PropertyTypeModel'
import React, { useEffect, useState } from 'react'

import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { Spinner } from 'Components'
import styled from 'styled-components'
import { user } from 'Models'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 100%;
`

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

type Props = {
  onPropertyTypeSelected?: (propertyType: IPropertyType) => void
  onChange?: (value: SelectValue) => void
  onLoad?: (propertyType: IPropertyType) => void
  value?: string
}

export const PropertyTypeSelector = (props: Props) => {
  const { value, onLoad } = props
  const { propertyTypes } = propertyTypeStore

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const code = user?.province_code || '18'
    propertyTypeStore.setSelectedProvinceCode(code)
    if (propertyTypes.length === 0) {
      load(code).then(() => loadCalculation())
    } else {
      loadCalculation()
    }
  }, [user?.province_code])

  async function load(code?: string) {
    setLoading(true)
    await propertyTypeStore.loadAll(code)
    setLoading(false)
  }

  function loadCalculation() {
    if (onLoad && value) {
      const propertyType = propertyTypes.find(
        (type) => type.code === value.toString()
      )
      propertyType && onLoad(propertyType)
    }
  }

  function onChange(value: any) {
    props.onChange && props.onChange(value.toString())
    if (props.onPropertyTypeSelected) {
      const propertyType = propertyTypes.find(
        (type) => type.code === value.toString()
      )
      if (propertyType) {
        props.onPropertyTypeSelected(propertyType)
      }
    }
  }

  // if (loading) {
  //   return (
  //     <Center>
  //       <Spinner size="middle"></Spinner>
  //     </Center>
  //   )
  // }

  return (
    <StyledSelect
      value={value}
      onChange={onChange}
      placeholder="ជ្រើសរើសប្រភេទ"
      loading={loading}
    >
      {propertyTypes.map((type) => {
        return (
          <Option key={type.id} value={type.code}>
            {`${type?.name_khmer || type?.name_english}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
