export type Village = {
    name: {
        km: string
        latin: string
    },
    id: string
}

const villages: Village[] = [
    {
      name: {
        km: "ឈ្នៃ",
        latin: "Chhney"
      },
      id: "10010101"
    },
    {
      name: {
        km: "ជ្រោយថ្មក្រោម",
        latin: "Chrouy Thma Kraom"
      },
      id: "10010102"
    },
    {
      name: {
        km: "ជ្រោយថ្មលើ",
        latin: "Chrouy Thma Leu"
      },
      id: "10010103"
    },
    {
      name: {
        km: "កំពង់ស្រែ",
        latin: "Kampong Srae"
      },
      id: "10010104"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "10010105"
    },
    {
      name: {
        km: "កោះកណ្ដុរ",
        latin: "Kaoh Kandaor"
      },
      id: "10010106"
    },
    {
      name: {
        km: "បឹងកៀប",
        latin: "Boeng Kieb"
      },
      id: "10010201"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Bos"
      },
      id: "10010202"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "10010203"
    },
    {
      name: {
        km: "ព្រៃគោ",
        latin: "Prey Kou"
      },
      id: "10010204"
    },
    {
      name: {
        km: "ប្រហួត",
        latin: "Prahuot"
      },
      id: "10010205"
    },
    {
      name: {
        km: "ប្រឡាយទ្រៀក",
        latin: "Pralay Triek"
      },
      id: "10010206"
    },
    {
      name: {
        km: "ស្រែស្ដេច",
        latin: "Srae Sdach"
      },
      id: "10010207"
    },
    {
      name: {
        km: "ស្រែទ្រៀក",
        latin: "Srae Triek"
      },
      id: "10010208"
    },
    {
      name: {
        km: "២៧សែនជ័យ",
        latin: "27 Sen Chey"
      },
      id: "10010209"
    },
    {
      name: {
        km: "ហាន់ជ័យទី១",
        latin: "Hanchey Ti Muoy"
      },
      id: "10010301"
    },
    {
      name: {
        km: "ហាន់ជ័យទី២",
        latin: "Hanchey Ti Pi"
      },
      id: "10010302"
    },
    {
      name: {
        km: "ហាន់ជ័យទី៣",
        latin: "Hanchey Ti Bei"
      },
      id: "10010303"
    },
    {
      name: {
        km: "ហាន់ជ័យទី៤",
        latin: "Hanchey Ti Buon"
      },
      id: "10010304"
    },
    {
      name: {
        km: "ប្រម៉ា",
        latin: "Prama"
      },
      id: "10010401"
    },
    {
      name: {
        km: "រលៀក",
        latin: "Roliek"
      },
      id: "10010402"
    },
    {
      name: {
        km: "វាលកន្សែង",
        latin: "Veal Kansaeng"
      },
      id: "10010403"
    },
    {
      name: {
        km: "ឈើទាលភ្លោះលើ",
        latin: "Chheu Teal Phluoh Leu"
      },
      id: "10010501"
    },
    {
      name: {
        km: "ឈើទាលភ្លោះក្រោម",
        latin: "Chheu Teal Phluoh Kraom"
      },
      id: "10010502"
    },
    {
      name: {
        km: "កញ្ជរ",
        latin: "Kanhchor"
      },
      id: "10010503"
    },
    {
      name: {
        km: "ព្រែកចំឡាក់",
        latin: "Preaek Chamlak"
      },
      id: "10010504"
    },
    {
      name: {
        km: "ព្រែកសំរោងទី១",
        latin: "Preaek Samrong Ti Muoy"
      },
      id: "10010601"
    },
    {
      name: {
        km: "ព្រែកសំរោងទី២",
        latin: "Preaek Samraong Ti Pi"
      },
      id: "10010602"
    },
    {
      name: {
        km: "ព្រែកតាហុប",
        latin: "Prek Ta Hob"
      },
      id: "10010603"
    },
    {
      name: {
        km: "ថ្មីទី១",
        latin: "Thmei Ti Muoy"
      },
      id: "10010604"
    },
    {
      name: {
        km: "ថ្មីទី២",
        latin: "Thmei Ti Pi"
      },
      id: "10010605"
    },
    {
      name: {
        km: "ដងក្ដោង",
        latin: "Dang Kdaong"
      },
      id: "10010701"
    },
    {
      name: {
        km: "ពង្រ ១",
        latin: "Pongro Muoy"
      },
      id: "10010702"
    },
    {
      name: {
        km: "ពង្រ ២",
        latin: "Pongro Pir"
      },
      id: "10010703"
    },
    {
      name: {
        km: "ពង្រ ៣",
        latin: "Pongro Bei"
      },
      id: "10010704"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "10010705"
    },
    {
      name: {
        km: "ឈើទាលភ្លោះ",
        latin: "Chheu Teal Phluoh"
      },
      id: "10010801"
    },
    {
      name: {
        km: "ឆក់កន្ទោង",
        latin: "Chhak Kantoung"
      },
      id: "10010802"
    },
    {
      name: {
        km: "ដីថ្មី",
        latin: "Dei Thmei"
      },
      id: "10010803"
    },
    {
      name: {
        km: "ល្វាធំ",
        latin: "Lvea Thum"
      },
      id: "10010804"
    },
    {
      name: {
        km: "ព្រែកសាម៉ាន់",
        latin: "Preaek Saman"
      },
      id: "10010805"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "10020701"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "10020702"
    },
    {
      name: {
        km: "ក្រគរ",
        latin: "Krakor"
      },
      id: "10020801"
    },
    {
      name: {
        km: "ទួលមនោរម្យ",
        latin: "Tuol Monourom"
      },
      id: "10020802"
    },
    {
      name: {
        km: "ដូនជ្រាំ",
        latin: "Doun Chroam"
      },
      id: "10020901"
    },
    {
      name: {
        km: "ក្រចេះ",
        latin: "Kracheh"
      },
      id: "10020902"
    },
    {
      name: {
        km: "ផ្សារវែង",
        latin: "Phsar Veaeng"
      },
      id: "10020903"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "10020904"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "10020905"
    },
    {
      name: {
        km: "កន្ទ្រ្ទីង",
        latin: "Kantring"
      },
      id: "10021001"
    },
    {
      name: {
        km: "កាប៉ូ",
        latin: "Kapou"
      },
      id: "10021002"
    },
    {
      name: {
        km: "អូរឫស្សី ទី ១",
        latin: "Ou Ruessei Ti Muoy"
      },
      id: "10021003"
    },
    {
      name: {
        km: "អូរឫស្សី ទី ២",
        latin: "Ou Ruessei Ti Pir"
      },
      id: "10021004"
    },
    {
      name: {
        km: "ស្រែស្ដៅ",
        latin: "Srae Sdau"
      },
      id: "10021005"
    },
    {
      name: {
        km: "ទី១",
        latin: "Ti Muoy"
      },
      id: "10021101"
    },
    {
      name: {
        km: "ទី២",
        latin: "Ti Pir"
      },
      id: "10021102"
    },
    {
      name: {
        km: "ចំបក់ ១",
        latin: "Chambâk 1"
      },
      id: "10030101"
    },
    {
      name: {
        km: "ចំបក់ ២",
        latin: "Chambâk 2"
      },
      id: "10030102"
    },
    {
      name: {
        km: "ជ្រោយអំពិល ១",
        latin: "Chroy Ampil 1"
      },
      id: "10030103"
    },
    {
      name: {
        km: "ជ្រោយអំពិល ២",
        latin: "Chroy Ampil 2"
      },
      id: "10030104"
    },
    {
      name: {
        km: "ជ្រោយថ្ម",
        latin: "Chroy Thmor"
      },
      id: "10030105"
    },
    {
      name: {
        km: "ស្ទឹងធំ",
        latin: "Stoeung Thum"
      },
      id: "10030106"
    },
    {
      name: {
        km: "ជ្រោយបន្ទាយ",
        latin: "Chrouy Banteay"
      },
      id: "10030201"
    },
    {
      name: {
        km: "កំពង់ដ",
        latin: "Kampong Dar"
      },
      id: "10030202"
    },
    {
      name: {
        km: "កែង",
        latin: "Kaeng"
      },
      id: "10030203"
    },
    {
      name: {
        km: "ខ្សាច់ទប់",
        latin: "Khsach Tob"
      },
      id: "10030204"
    },
    {
      name: {
        km: "ល្អៀត",
        latin: "L'iet"
      },
      id: "10030205"
    },
    {
      name: {
        km: "រកាធំ",
        latin: "Roka Thum"
      },
      id: "10030206"
    },
    {
      name: {
        km: "ទួលព្រិច",
        latin: "Tuol Prich"
      },
      id: "10030207"
    },
    {
      name: {
        km: "ជ្រោយស្នែងក្របីក្រោម",
        latin: "Chrouy Snaeng Krabei Kraom"
      },
      id: "10030301"
    },
    {
      name: {
        km: "ជ្រោយស្នែងក្របីលើ",
        latin: "Chrouy Snaeng Krabei Leu"
      },
      id: "10030302"
    },
    {
      name: {
        km: "កំពង់គរ",
        latin: "Kampong Kor"
      },
      id: "10030303"
    },
    {
      name: {
        km: "តាម៉ៅលើ",
        latin: "Ta Mau Leu"
      },
      id: "10030304"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Koh"
      },
      id: "10030401"
    },
    {
      name: {
        km: "កណ្ដាលកោះ",
        latin: "Kandal Koh"
      },
      id: "10030402"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Koh"
      },
      id: "10030403"
    },
    {
      name: {
        km: "បឹងលាច",
        latin: "Boeng Leach"
      },
      id: "10030501"
    },
    {
      name: {
        km: "ដីដុះក្រោម",
        latin: "Dei Doh Kraom"
      },
      id: "10030502"
    },
    {
      name: {
        km: "ដីដុះលើ",
        latin: "Dei Doh Leu"
      },
      id: "10030503"
    },
    {
      name: {
        km: "អូរលុង",
        latin: "Ou Lung"
      },
      id: "10030504"
    },
    {
      name: {
        km: "ព្រែកប្រាំង",
        latin: "Preaek Prang"
      },
      id: "10030505"
    },
    {
      name: {
        km: "ព្រែកប្រសព្វកណ្ដាល",
        latin: "Preaek Prasab Kandal"
      },
      id: "10030506"
    },
    {
      name: {
        km: "ព្រែកប្រសព្វក្រោម",
        latin: "Preaek Prasab Kraom"
      },
      id: "10030507"
    },
    {
      name: {
        km: "ព្រែកប្រសព្វលើ",
        latin: "Preaek Prasab Leu"
      },
      id: "10030508"
    },
    {
      name: {
        km: "ព្រែកគូរ",
        latin: "Prey Kur"
      },
      id: "10030509"
    },
    {
      name: {
        km: "ថ្មរាប",
        latin: "Thma Reab"
      },
      id: "10030510"
    },
    {
      name: {
        km: "បឹងរៃ",
        latin: "Boeng Rey"
      },
      id: "10030601"
    },
    {
      name: {
        km: "ឫស្សីកែវ",
        latin: "Russey Keo"
      },
      id: "10030602"
    },
    {
      name: {
        km: "ស្រឡៅដំណាក់",
        latin: "Sralav Damnak"
      },
      id: "10030603"
    },
    {
      name: {
        km: "ស្វាយជុំ",
        latin: "Svay Chum"
      },
      id: "10030604"
    },
    {
      name: {
        km: "បឹងច្រែង",
        latin: "Boeng Chraeng"
      },
      id: "10030701"
    },
    {
      name: {
        km: "ក្ល",
        latin: "Kla"
      },
      id: "10030702"
    },
    {
      name: {
        km: "ព្រែកជីក",
        latin: "Preaek Chik"
      },
      id: "10030703"
    },
    {
      name: {
        km: "ព្រែកព្រលូង",
        latin: "Preaek Prolung"
      },
      id: "10030704"
    },
    {
      name: {
        km: "ព្រែករកា",
        latin: "Preaek Roka"
      },
      id: "10030705"
    },
    {
      name: {
        km: "សោបក្រោម",
        latin: "Saob Kraom"
      },
      id: "10030706"
    },
    {
      name: {
        km: "សោបលើ",
        latin: "Saob Leu"
      },
      id: "10030707"
    },
    {
      name: {
        km: "ក្រហមកលើ",
        latin: "Kraham Ka Leu"
      },
      id: "10030801"
    },
    {
      name: {
        km: "ក្រហមកក្រោម",
        latin: "Kraham Ka Kraom"
      },
      id: "10030802"
    },
    {
      name: {
        km: "ខ្សាត់",
        latin: "Khsat"
      },
      id: "10030803"
    },
    {
      name: {
        km: "ព្រែកស្វាយ",
        latin: "Prek Svay"
      },
      id: "10030804"
    },
    {
      name: {
        km: "ស្ទឹងទ្រ",
        latin: "Stueng Tro"
      },
      id: "10030805"
    },
    {
      name: {
        km: "តាម៉ៅកណ្ដាល",
        latin: "Ta Mau Kandal"
      },
      id: "10030806"
    },
    {
      name: {
        km: "តាម៉ៅក្រោម",
        latin: "Ta Mau Kraom"
      },
      id: "10030807"
    },
    {
      name: {
        km: "ដំរែ",
        latin: "Damrae"
      },
      id: "10040101"
    },
    {
      name: {
        km: "កំពង់រទេះ",
        latin: "Kampong Roteh"
      },
      id: "10040102"
    },
    {
      name: {
        km: "កោះដំបង",
        latin: "Kaoh Dambang"
      },
      id: "10040103"
    },
    {
      name: {
        km: "អាជេន",
        latin: "A Chen"
      },
      id: "10040201"
    },
    {
      name: {
        km: "អំពិលទឹក",
        latin: "Ampil Tuek"
      },
      id: "10040202"
    },
    {
      name: {
        km: "កំពង់ក្របី",
        latin: "Kampong Krabei"
      },
      id: "10040203"
    },
    {
      name: {
        km: "កោះផ្ដៅ",
        latin: "Kaoh Phdau"
      },
      id: "10040204"
    },
    {
      name: {
        km: "សំភិន",
        latin: "Samphin"
      },
      id: "10040206"
    },
    {
      name: {
        km: "ទន្សោងធ្លាក់",
        latin: "Tonsaong Thleak"
      },
      id: "10040207"
    },
    {
      name: {
        km: "យាវ",
        latin: "Yeav"
      },
      id: "10040208"
    },
    {
      name: {
        km: "ចង្ហប",
        latin: "Changhab"
      },
      id: "10040301"
    },
    {
      name: {
        km: "អូរពោធិ៍",
        latin: "Ou Pur"
      },
      id: "10040302"
    },
    {
      name: {
        km: "អូរតាណឹង",
        latin: "Ou Ta Noeng"
      },
      id: "10040303"
    },
    {
      name: {
        km: "ស្រែស្បូវ",
        latin: "Srae Sbov"
      },
      id: "10040304"
    },
    {
      name: {
        km: "ស្រែត្រែង",
        latin: "Srae Traeng"
      },
      id: "10040305"
    },
    {
      name: {
        km: "បាយសំណុំ",
        latin: "Bay Samnom"
      },
      id: "10040401"
    },
    {
      name: {
        km: "ជើងពាត",
        latin: "Cheung Peat"
      },
      id: "10040402"
    },
    {
      name: {
        km: "កំពង់ព្នៅ",
        latin: "Kampong Pnov"
      },
      id: "10040403"
    },
    {
      name: {
        km: "កោះច្បារ",
        latin: "Kaoh Chbar"
      },
      id: "10040404"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "10040405"
    },
    {
      name: {
        km: "កោះខ្ញែរ",
        latin: "Kaoh Khnhaer"
      },
      id: "10040501"
    },
    {
      name: {
        km: "ខ្សាច់លាវ",
        latin: "Khsach Leav"
      },
      id: "10040502"
    },
    {
      name: {
        km: "អូរកក់",
        latin: "Ou Kak"
      },
      id: "10040503"
    },
    {
      name: {
        km: "អូរគ្រៀង",
        latin: "Ou Krieng"
      },
      id: "10040504"
    },
    {
      name: {
        km: "អូរព្រះ",
        latin: "Ou Preah"
      },
      id: "10040505"
    },
    {
      name: {
        km: "ពន្ធជា",
        latin: "Pon Chea"
      },
      id: "10040506"
    },
    {
      name: {
        km: "ប៉ាក្លែ",
        latin: "Paklae"
      },
      id: "10040601"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "10040602"
    },
    {
      name: {
        km: "ស្រែឈូក",
        latin: "Srae Chhuk"
      },
      id: "10040603"
    },
    {
      name: {
        km: "ទោង",
        latin: "Toung"
      },
      id: "10040604"
    },
    {
      name: {
        km: "ចារថ្នោល",
        latin: "Char Thnaol"
      },
      id: "10040701"
    },
    {
      name: {
        km: "ដូនមាស",
        latin: "Doun Meas"
      },
      id: "10040702"
    },
    {
      name: {
        km: "កែងប្រាសាទ",
        latin: "Kaeng Prasat"
      },
      id: "10040703"
    },
    {
      name: {
        km: "កោះរាល",
        latin: "Kaoh Real"
      },
      id: "10040704"
    },
    {
      name: {
        km: "កោះសំ",
        latin: "Kaoh Sam"
      },
      id: "10040705"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "10040706"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "10040707"
    },
    {
      name: {
        km: "ស្រែខឿន",
        latin: "Srae Khoean"
      },
      id: "10040708"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "10040801"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "10040802"
    },
    {
      name: {
        km: "សណ្ដាន់",
        latin: "Sandan"
      },
      id: "10040803"
    },
    {
      name: {
        km: "សង្គម",
        latin: "Sangkum"
      },
      id: "10040804"
    },
    {
      name: {
        km: "អំពក",
        latin: "Ampok"
      },
      id: "10040901"
    },
    {
      name: {
        km: "កូនវ៉ា",
        latin: "Koun Va"
      },
      id: "10040902"
    },
    {
      name: {
        km: "ភ្នំពីរ",
        latin: "Phnum Pir"
      },
      id: "10040903"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "10040904"
    },
    {
      name: {
        km: "ស្រែជិះ",
        latin: "Srae Chis"
      },
      id: "10040905"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "10040906"
    },
    {
      name: {
        km: "អន្លង់ព្រះគោ",
        latin: "Anlong Preah Kou"
      },
      id: "10041001"
    },
    {
      name: {
        km: "ព្រែកគ្រៀង",
        latin: "Preaek Krieng"
      },
      id: "10041002"
    },
    {
      name: {
        km: "វឌ្ឍនៈ",
        latin: "Voadthonak"
      },
      id: "10041003"
    },
    {
      name: {
        km: "តាងួន",
        latin: "Ta Nguon"
      },
      id: "10041004"
    },
    {
      name: {
        km: "មិល",
        latin: "Mil"
      },
      id: "10050101"
    },
    {
      name: {
        km: "ចឹង",
        latin: "Choeng"
      },
      id: "10050102"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "10050103"
    },
    {
      name: {
        km: "ឃ្សឹមក្នុង",
        latin: "Khsuem Knong"
      },
      id: "10050104"
    },
    {
      name: {
        km: "ឃ្សឹមក្រៅ",
        latin: "Khsuem Krau"
      },
      id: "10050105"
    },
    {
      name: {
        km: "សំរ៉ង",
        latin: "Samrang"
      },
      id: "10050106"
    },
    {
      name: {
        km: "ស្រែរនាម",
        latin: "Srae Roneam"
      },
      id: "10050107"
    },
    {
      name: {
        km: "ស្រែថ្មី",
        latin: "Srae Thmei"
      },
      id: "10050108"
    },
    {
      name: {
        km: "ជើងឃ្លេ",
        latin: "Cheung Khle"
      },
      id: "10050201"
    },
    {
      name: {
        km: "ជើងឃ្លូ",
        latin: "Cheung Khlu"
      },
      id: "10050202"
    },
    {
      name: {
        km: "ថ្មហាលដីក្រហម",
        latin: "Thma Hal Dei Kraham"
      },
      id: "10050203"
    },
    {
      name: {
        km: "ប្រវាញ",
        latin: "Pravanh"
      },
      id: "10050204"
    },
    {
      name: {
        km: "ថ្មហាលវាល",
        latin: "Thma Hal Veal"
      },
      id: "10050205"
    },
    {
      name: {
        km: "ត្រពាំងស្រែ",
        latin: "Trapeang Srae"
      },
      id: "10050206"
    },
    {
      name: {
        km: "ច្រាប",
        latin: "Chrab"
      },
      id: "10050207"
    },
    {
      name: {
        km: "កាត់ដៃ",
        latin: "Kat Dai"
      },
      id: "10050301"
    },
    {
      name: {
        km: "ក្បាលស្នួល",
        latin: "Kbal Snuol"
      },
      id: "10050302"
    },
    {
      name: {
        km: "គ្រង",
        latin: "Krong"
      },
      id: "10050303"
    },
    {
      name: {
        km: "ព្រែកក្ដី",
        latin: "Preaek Kdei"
      },
      id: "10050304"
    },
    {
      name: {
        km: "ស្នួលកើត",
        latin: "Snuol Kaeut"
      },
      id: "10050305"
    },
    {
      name: {
        km: "ថ្ពង",
        latin: "Thpong"
      },
      id: "10050306"
    },
    {
      name: {
        km: "ស្នួលលិច",
        latin: "Snuol Lech"
      },
      id: "10050307"
    },
    {
      name: {
        km: "រហារិ៍",
        latin: "Roha"
      },
      id: "10050401"
    },
    {
      name: {
        km: "ក្បាលត្រាច",
        latin: "Kbal Trach"
      },
      id: "10050402"
    },
    {
      name: {
        km: "មាក់កណ្ដាល",
        latin: "Mak Kandal"
      },
      id: "10050403"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "10050404"
    },
    {
      name: {
        km: "ទ្រៀក",
        latin: "Treak"
      },
      id: "10050405"
    },
    {
      name: {
        km: "ស្អាត",
        latin: "S'at"
      },
      id: "10050406"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "10050501"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "10050502"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "10050503"
    },
    {
      name: {
        km: "តាសោម",
        latin: "Ta Saom"
      },
      id: "10050504"
    },
    {
      name: {
        km: "ស្រែចារ",
        latin: "Srae Char"
      },
      id: "10050505"
    },
    {
      name: {
        km: "តាពុំ",
        latin: "Ta Pum"
      },
      id: "10050506"
    },
    {
      name: {
        km: "ដូនមា",
        latin: "Doun Mea"
      },
      id: "10050507"
    },
    {
      name: {
        km: "រំពុក",
        latin: "Rumpuk"
      },
      id: "10050508"
    },
    {
      name: {
        km: "គ្រញូងសែនជ័យ",
        latin: "KroNhoung Sen Chey"
      },
      id: "10050601"
    },
    {
      name: {
        km: "វាលបីចក្រីហេង",
        latin: "VealBei Chakrey Heng"
      },
      id: "10050602"
    },
    {
      name: {
        km: "ជ្រោះជ្រៅពោធិ៍សាល",
        latin: "Chros Chrov Pousal"
      },
      id: "10050603"
    },
    {
      name: {
        km: "គ្រញូងសែនជ័យខាងជើង",
        latin: "KroNhoung Sen Chey Khang Cheoung"
      },
      id: "10050604"
    },
    {
      name: {
        km: "បុសលាវក្រោម",
        latin: "Bos Leav Kraom"
      },
      id: "10060101"
    },
    {
      name: {
        km: "បុសលាវលើ",
        latin: "Bos Leav Leu"
      },
      id: "10060102"
    },
    {
      name: {
        km: "ល្វាទង",
        latin: "Lvea Tong"
      },
      id: "10060103"
    },
    {
      name: {
        km: "ព្រះគន្លង",
        latin: "Preah Konlong"
      },
      id: "10060104"
    },
    {
      name: {
        km: "ព្រែកកូវ",
        latin: "Preaek Kov"
      },
      id: "10060105"
    },
    {
      name: {
        km: "ព្រែកតាអាំ",
        latin: "Preaek Ta Am"
      },
      id: "10060106"
    },
    {
      name: {
        km: "ព្រែកតាថឹង",
        latin: "Preaek Ta Thoeng"
      },
      id: "10060107"
    },
    {
      name: {
        km: "តាលុស",
        latin: "Ta Lus"
      },
      id: "10060108"
    },
    {
      name: {
        km: "ចង្ក្រង់",
        latin: "Changkrang"
      },
      id: "10060201"
    },
    {
      name: {
        km: "កសាង",
        latin: "Kasang"
      },
      id: "10060202"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "10060301"
    },
    {
      name: {
        km: "ជួរជ្រៃ",
        latin: "Chuor Chrey"
      },
      id: "10060302"
    },
    {
      name: {
        km: "ដារ",
        latin: "Dar"
      },
      id: "10060303"
    },
    {
      name: {
        km: "ខ្នងពស់",
        latin: "Khnang Pos"
      },
      id: "10060304"
    },
    {
      name: {
        km: "ខ្សារ",
        latin: "Khsar"
      },
      id: "10060305"
    },
    {
      name: {
        km: "ម្រ៉ើម",
        latin: "Mreum"
      },
      id: "10060306"
    },
    {
      name: {
        km: "សេរីភាព",
        latin: "Sereipheap"
      },
      id: "10060307"
    },
    {
      name: {
        km: "ស្ទឹងស្វាយ",
        latin: "Stueng Svay"
      },
      id: "10060308"
    },
    {
      name: {
        km: "តាងួន",
        latin: "Ta Nguon"
      },
      id: "10060309"
    },
    {
      name: {
        km: "អាលច",
        latin: "A Loch"
      },
      id: "10060401"
    },
    {
      name: {
        km: "អន្ទង់វៀន",
        latin: "Antong Vien"
      },
      id: "10060402"
    },
    {
      name: {
        km: "ច្រវ៉ា",
        latin: "Chrava"
      },
      id: "10060403"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "10060404"
    },
    {
      name: {
        km: "ស្រែនន",
        latin: "Srae Non"
      },
      id: "10060405"
    },
    {
      name: {
        km: "បន្ទាយ",
        latin: "Banteay"
      },
      id: "10060501"
    },
    {
      name: {
        km: "ច្រវ៉ា",
        latin: "Chrava"
      },
      id: "10060502"
    },
    {
      name: {
        km: "កំបោរ",
        latin: "Kambaor"
      },
      id: "10060503"
    },
    {
      name: {
        km: "គោលាប់",
        latin: "Kou Loab"
      },
      id: "10060504"
    },
    {
      name: {
        km: "សំរិទ្ធ",
        latin: "Samret"
      },
      id: "10060505"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "10060601"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "10060602"
    },
    {
      name: {
        km: "ព្រែក",
        latin: "Preaek"
      },
      id: "10060603"
    },
    {
      name: {
        km: "រកាខ្នុរ",
        latin: "Roka Khnor"
      },
      id: "10060604"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "10060605"
    },
    {
      name: {
        km: "បឹងរុន",
        latin: "Boeng Run"
      },
      id: "10060701"
    },
    {
      name: {
        km: "កាកុត",
        latin: "Kakot"
      },
      id: "10060702"
    },
    {
      name: {
        km: "ក្បាលជួរ",
        latin: "Kbal Chuor"
      },
      id: "10060703"
    },
    {
      name: {
        km: "សំបុក",
        latin: "Sambok"
      },
      id: "10060704"
    },
    {
      name: {
        km: "ស្រែតាហែន",
        latin: "SraeTa Haen"
      },
      id: "10060705"
    },
    {
      name: {
        km: "សម្បទានកាកុត",
        latin: "Sambaktean Kakot"
      },
      id: "10060706"
    },
    {
      name: {
        km: "សម្បទានកាំពី",
        latin: "Sambaktean Kampi"
      },
      id: "10060707"
    },
    {
      name: {
        km: "កាំពី",
        latin: "Kampi"
      },
      id: "10060708"
    },
    {
      name: {
        km: "ជូរក្រូច",
        latin: "Chour Krouch"
      },
      id: "10060801"
    },
    {
      name: {
        km: "ដំណាក់សសរ",
        latin: "Damnak Sasar"
      },
      id: "10060802"
    },
    {
      name: {
        km: "ល្អក់",
        latin: "L'ak"
      },
      id: "10060803"
    },
    {
      name: {
        km: "សំពុង",
        latin: "Sampung"
      },
      id: "10060804"
    },
    {
      name: {
        km: "សិរីសុខា",
        latin: "Serei Sokha"
      },
      id: "10060805"
    },
    {
      name: {
        km: "ស្រែដូង",
        latin: "Srae Doung"
      },
      id: "10060806"
    },
    {
      name: {
        km: "ឫស្សីចារ",
        latin: "Ruessei Char"
      },
      id: "10060901"
    },
    {
      name: {
        km: "ថ្មគ្រែកណ្ដាល",
        latin: "Thma Krae Kandal"
      },
      id: "10060902"
    },
    {
      name: {
        km: "ថ្មគ្រែលើ",
        latin: "Thma Krae Leu"
      },
      id: "10060903"
    },
    {
      name: {
        km: "ច្រណោល",
        latin: "Chranaol"
      },
      id: "10061001"
    },
    {
      name: {
        km: "ខ្នាច",
        latin: "Khnach"
      },
      id: "10061002"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "10061003"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "10061004"
    },
    {
      name: {
        km: "ប្អៀរ",
        latin: "B'ier"
      },
      id: "10061005"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "10061006"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "10061007"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "10061008"
    },
    {
      name: {
        km: "ទ្រាប",
        latin: "Treab"
      },
      id: "10061009"
    },
    {
      name: {
        km: "វាលសំបូរ",
        latin: "Veal Sambour"
      },
      id: "10061010"
    },
    {
      name: {
        km: "ពូទុង",
        latin: "Pu Tong"
      },
      id: "11010101"
    },
    {
      name: {
        km: "ពូហ៊ួង",
        latin: "Pu Huong"
      },
      id: "11010102"
    },
    {
      name: {
        km: "ខ្ញែង",
        latin: "Khnhaeng"
      },
      id: "11010103"
    },
    {
      name: {
        km: "ពូរង៉ោល",
        latin: "Pu Ngoal"
      },
      id: "11010201"
    },
    {
      name: {
        km: "ពូចា",
        latin: "Pu Cha"
      },
      id: "11010202"
    },
    {
      name: {
        km: "ពូញ៉ាវ",
        latin: "Pu Nhav"
      },
      id: "11010203"
    },
    {
      name: {
        km: "ពូកេះ",
        latin: "Pu Keh"
      },
      id: "11010204"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "11010205"
    },
    {
      name: {
        km: "ក្មូម",
        latin: "Kmoum"
      },
      id: "11010301"
    },
    {
      name: {
        km: "ចក់ចារ",
        latin: "Chak Char"
      },
      id: "11010302"
    },
    {
      name: {
        km: "រណែង",
        latin: "Ronaeng"
      },
      id: "11010303"
    },
    {
      name: {
        km: "ព្រះ",
        latin: "Preah"
      },
      id: "11010305"
    },
    {
      name: {
        km: "ខ្ទង់",
        latin: "Khtong"
      },
      id: "11010306"
    },
    {
      name: {
        km: "ស្រែអណ្ដោល",
        latin: "Srae Andoal"
      },
      id: "11010307"
    },
    {
      name: {
        km: "អូរអាម",
        latin: "Ou Am"
      },
      id: "11010401"
    },
    {
      name: {
        km: "អូររណា",
        latin: "Ou Rona"
      },
      id: "11010402"
    },
    {
      name: {
        km: "ស្រែអំពិល",
        latin: "Srae Ampil"
      },
      id: "11010403"
    },
    {
      name: {
        km: "ស្រែល្វី",
        latin: "Srae Lvi"
      },
      id: "11010405"
    },
    {
      name: {
        km: "ឆ្នែង",
        latin: "Chhnaeng"
      },
      id: "11010406"
    },
    {
      name: {
        km: "ស្រែខ្ទុម",
        latin: "Srae Khtum"
      },
      id: "11010407"
    },
    {
      name: {
        km: "ត្រពាំងផ្អេរ",
        latin: "Trapeang Pha er"
      },
      id: "11010408"
    },
    {
      name: {
        km: "ឡាប៉ាខេ",
        latin: "La Pa Khe"
      },
      id: "11010409"
    },
    {
      name: {
        km: "ហ្គាទី",
        latin: "Hkati"
      },
      id: "11010501"
    },
    {
      name: {
        km: "ពូចា",
        latin: "Pu Cha"
      },
      id: "11010502"
    },
    {
      name: {
        km: "ពូគង់",
        latin: "Pu Kong"
      },
      id: "11010503"
    },
    {
      name: {
        km: "អូរច្រា",
        latin: "Ou Chra"
      },
      id: "11010504"
    },
    {
      name: {
        km: "ស្រែព្រះ",
        latin: "Srae Preah"
      },
      id: "11010505"
    },
    {
      name: {
        km: "ជីមាត",
        latin: "Chimat"
      },
      id: "11020101"
    },
    {
      name: {
        km: "ណងប៊ួ",
        latin: "Nang Buo"
      },
      id: "11020102"
    },
    {
      name: {
        km: "កោះម្យើលលើ",
        latin: "Kaoh Moeal Leu"
      },
      id: "11020103"
    },
    {
      name: {
        km: "កោះម្យើលក្រោម",
        latin: "Kaoh Moeal Kraom"
      },
      id: "11020104"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "11020201"
    },
    {
      name: {
        km: "អ បួន",
        latin: "A Buon"
      },
      id: "11020202"
    },
    {
      name: {
        km: "អន្ដ្រេះ",
        latin: "Antreh"
      },
      id: "11020203"
    },
    {
      name: {
        km: "រយ៉",
        latin: "Roya"
      },
      id: "11020301"
    },
    {
      name: {
        km: "មេមុំ",
        latin: "Memom"
      },
      id: "11020303"
    },
    {
      name: {
        km: "ក្ដោយ",
        latin: "Kdaoy"
      },
      id: "11020304"
    },
    {
      name: {
        km: "រវ៉ាក់",
        latin: "Rovak"
      },
      id: "11020305"
    },
    {
      name: {
        km: "ក្លង់ឡែ",
        latin: "Klang Lae"
      },
      id: "11020401"
    },
    {
      name: {
        km: "អញ្ជ័រ",
        latin: "Anhchoar"
      },
      id: "11020402"
    },
    {
      name: {
        km: "ស្រែធំ",
        latin: "Srae Thum"
      },
      id: "11020403"
    },
    {
      name: {
        km: "ជីក្លប់",
        latin: "Chi Klab"
      },
      id: "11020404"
    },
    {
      name: {
        km: "ស្រែហ៊ុយ",
        latin: "Srae Huy"
      },
      id: "11020501"
    },
    {
      name: {
        km: "ឈូល",
        latin: "Chhul"
      },
      id: "11020502"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "11020601"
    },
    {
      name: {
        km: "សិរីមានរិទ្ធិ",
        latin: "Serei Mean Rith"
      },
      id: "11020602"
    },
    {
      name: {
        km: "សិរីមង្គល",
        latin: "Serei Mongkol"
      },
      id: "11020603"
    },
    {
      name: {
        km: "ចំរើន",
        latin: "Chamraeun"
      },
      id: "11020604"
    },
    {
      name: {
        km: "ក្បាលជ្រោយ",
        latin: "Kbal Chrouy"
      },
      id: "11020605"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "11020606"
    },
    {
      name: {
        km: "រង្សី",
        latin: "Reangsei"
      },
      id: "11020607"
    },
    {
      name: {
        km: "សិរីរដ្ឋ",
        latin: "Serei Rodth"
      },
      id: "11020608"
    },
    {
      name: {
        km: "អូរយ៉េះ",
        latin: "Ou Yeh"
      },
      id: "11020609"
    },
    {
      name: {
        km: "ពូត្រែង",
        latin: "Pu Traeng"
      },
      id: "11030101"
    },
    {
      name: {
        km: "ពូឡេះ",
        latin: "Pu Leh"
      },
      id: "11030102"
    },
    {
      name: {
        km: "ពូឆប",
        latin: "Pu Chhab"
      },
      id: "11030103"
    },
    {
      name: {
        km: "ពូហ្យាម",
        latin: "Pu Hoam"
      },
      id: "11030201"
    },
    {
      name: {
        km: "អណ្ដូងក្រឡឹង",
        latin: "Andoung Kraloeng"
      },
      id: "11030202"
    },
    {
      name: {
        km: "ពូទ្រូ",
        latin: "Pu Tru"
      },
      id: "11030203"
    },
    {
      name: {
        km: "ពូរ៉ាង",
        latin: "Pu Rang"
      },
      id: "11030204"
    },
    {
      name: {
        km: "ក្រង់តេះ",
        latin: "Krang Teh"
      },
      id: "11040101"
    },
    {
      name: {
        km: "ឡៅរមៀត",
        latin: "Lau Romiet"
      },
      id: "11040102"
    },
    {
      name: {
        km: "ពូរ៉ាប៉េត",
        latin: "Pu Rapet"
      },
      id: "11040103"
    },
    {
      name: {
        km: "ត្រាំកាច់",
        latin: "Tram Kach"
      },
      id: "11040104"
    },
    {
      name: {
        km: "មេប៉ៃ",
        latin: "Me Pai"
      },
      id: "11040201"
    },
    {
      name: {
        km: "ពូជ្រៃចាង",
        latin: "Pu Chrey Chang"
      },
      id: "11040202"
    },
    {
      name: {
        km: "ពូជ្រៃចុងផាង",
        latin: "Pu Chrey Chong Phang"
      },
      id: "11040203"
    },
    {
      name: {
        km: "ពូតាង",
        latin: "Pu Tang"
      },
      id: "11040204"
    },
    {
      name: {
        km: "ពូក្រូច",
        latin: "Pu Krouch"
      },
      id: "11040301"
    },
    {
      name: {
        km: "ពូរ៉ាដែត",
        latin: "Pu Radaet"
      },
      id: "11040302"
    },
    {
      name: {
        km: "ពូក្រេង",
        latin: "Pu Kraeng"
      },
      id: "11040303"
    },
    {
      name: {
        km: "ពូទឺត",
        latin: "Pu Tuet"
      },
      id: "11040401"
    },
    {
      name: {
        km: "ពូរាំង",
        latin: "Pu Reang"
      },
      id: "11040402"
    },
    {
      name: {
        km: "ប៊ូស្រា",
        latin: "Bu Sra"
      },
      id: "11040403"
    },
    {
      name: {
        km: "ពូទិល",
        latin: "Pu Til"
      },
      id: "11040404"
    },
    {
      name: {
        km: "ឡាំមេះ",
        latin: "Lam Meh"
      },
      id: "11040405"
    },
    {
      name: {
        km: "ពូចា",
        latin: "Pu Cha"
      },
      id: "11040406"
    },
    {
      name: {
        km: "ពូលុ",
        latin: "Pu Lu"
      },
      id: "11040407"
    },
    {
      name: {
        km: "ដើមស្រល់",
        latin: "Daeum Sral"
      },
      id: "11050101"
    },
    {
      name: {
        km: "ជ្រៃសែន",
        latin: "Chrey Saen"
      },
      id: "11050102"
    },
    {
      name: {
        km: "មានលាភ",
        latin: "Mean Leaph"
      },
      id: "11050201"
    },
    {
      name: {
        km: "ដោះក្រមុំ",
        latin: "Daoh Kramom"
      },
      id: "11050202"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "11050203"
    },
    {
      name: {
        km: "ឡៅកា",
        latin: "Lauka"
      },
      id: "11050204"
    },
    {
      name: {
        km: "អូរស្ពាន",
        latin: "Ou Spean"
      },
      id: "11050301"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "11050302"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "11050303"
    },
    {
      name: {
        km: "ចំការតែ",
        latin: "Chamkar Tae"
      },
      id: "11050304"
    },
    {
      name: {
        km: "ពូត្រុំ",
        latin: "Pu Trom"
      },
      id: "11050401"
    },
    {
      name: {
        km: "ពូតាំង",
        latin: "Pu Tang"
      },
      id: "11050402"
    },
    {
      name: {
        km: "ពូលូង",
        latin: "Pu Lung"
      },
      id: "11050403"
    },
    {
      name: {
        km: "ស្រែអ៊ី",
        latin: "Srae I"
      },
      id: "11050404"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12010101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12010102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12010103"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12010104"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12010105"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12010106"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12010107"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12010108"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12010109"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12010110"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12010111"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12010112"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12010113"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12010114"
    },
    {
      name: {
        km: "ភូមិ ១៥",
        latin: "Phum 15"
      },
      id: "12010115"
    },
    {
      name: {
        km: "ភូមិ ១៦",
        latin: "Phum 16"
      },
      id: "12010116"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12010901"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12010902"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12010903"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12010904"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12011001"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12011002"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12011003"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12011004"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12011005"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12011101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12011102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12011103"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12011104"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12011105"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12011106"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12011107"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12011108"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12011201"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12011202"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12011203"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12011204"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12011205"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12011206"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12011207"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum 1"
      },
      id: "12020101"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum 2"
      },
      id: "12020102"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum 3"
      },
      id: "12020103"
    },
    {
      name: {
        km: "ភូមិ៤",
        latin: "Phum 4"
      },
      id: "12020104"
    },
    {
      name: {
        km: "ភូមិ៥",
        latin: "Phum 5"
      },
      id: "12020105"
    },
    {
      name: {
        km: "ភូមិ៦",
        latin: "Phum 6"
      },
      id: "12020106"
    },
    {
      name: {
        km: "ភូមិ៧",
        latin: "Phum 7"
      },
      id: "12020107"
    },
    {
      name: {
        km: "ភូមិ៨",
        latin: "Phum 8"
      },
      id: "12020108"
    },
    {
      name: {
        km: "ភូមិ៩",
        latin: "Phum 9"
      },
      id: "12020109"
    },
    {
      name: {
        km: "ភូមិ១០",
        latin: "Phum 10"
      },
      id: "12020110"
    },
    {
      name: {
        km: "ភូមិ១១",
        latin: "Phum 11"
      },
      id: "12020111"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum 1"
      },
      id: "12020201"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum 2"
      },
      id: "12020202"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum 3"
      },
      id: "12020203"
    },
    {
      name: {
        km: "ភូមិ៤",
        latin: "Phum 4"
      },
      id: "12020204"
    },
    {
      name: {
        km: "ភូមិ៥",
        latin: "Phum 5"
      },
      id: "12020205"
    },
    {
      name: {
        km: "ភូមិ៦",
        latin: "Phum 6"
      },
      id: "12020206"
    },
    {
      name: {
        km: "ភូមិ៧",
        latin: "Phum 7"
      },
      id: "12020207"
    },
    {
      name: {
        km: "ភូមិ៨",
        latin: "Phum 8"
      },
      id: "12020208"
    },
    {
      name: {
        km: "ភូមិ៩",
        latin: "Phum 9"
      },
      id: "12020209"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12020301"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12020302"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12020303"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12020304"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12020305"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12020306"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12020307"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12020308"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12020309"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12020310"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12020311"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12020312"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12020313"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12020314"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12020401"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12020402"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12020403"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12020404"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12020405"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12020406"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12020407"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12020408"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12020409"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12020410"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12020501"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12020502"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12020503"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12020504"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12020505"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12020506"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12020507"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12020508"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12020509"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12020510"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12020511"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12020512"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12020513"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12020514"
    },
    {
      name: {
        km: "ភូមិ ១៥",
        latin: "Phum 15"
      },
      id: "12020515"
    },
    {
      name: {
        km: "ភូមិ ១៦",
        latin: "Phum 16"
      },
      id: "12020516"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12020601"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12020602"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12020603"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12020604"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12020605"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12020606"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12020607"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12020608"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12020609"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12020610"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12020611"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12020701"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12020702"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12020703"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12020704"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12020705"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12020706"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12020707"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12020708"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12020801"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12020802"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12020803"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12020804"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12020805"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12020806"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12020807"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12020808"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12020809"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12020810"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12020811"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12020901"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12020902"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12020903"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12020904"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12020905"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12020906"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12020907"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12020908"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12020909"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12020910"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12021001"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12021002"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12021003"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12021004"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12021005"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12021006"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12021007"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12021008"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12021009"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12021010"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12021011"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12021012"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12021013"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12021014"
    },
    {
      name: {
        km: "ភូមិ ១៥",
        latin: "Phum 15"
      },
      id: "12021015"
    },
    {
      name: {
        km: "ភូមិ ១៦",
        latin: "Phum 16"
      },
      id: "12021016"
    },
    {
      name: {
        km: "ភូមិ ១៧",
        latin: "Phum 17"
      },
      id: "12021017"
    },
    {
      name: {
        km: "ភូមិ ១៨",
        latin: "Phum 18"
      },
      id: "12021018"
    },
    {
      name: {
        km: "ភូមិ ១៩",
        latin: "Phum 19"
      },
      id: "12021019"
    },
    {
      name: {
        km: "ភូមិ ២០",
        latin: "Phum 20"
      },
      id: "12021020"
    },
    {
      name: {
        km: "ភូមិ ២១",
        latin: "Phum 21"
      },
      id: "12021021"
    },
    {
      name: {
        km: "ភូមិ ២២",
        latin: "Phum 22"
      },
      id: "12021022"
    },
    {
      name: {
        km: "ភូមិ ២៣",
        latin: "Phum 23"
      },
      id: "12021023"
    },
    {
      name: {
        km: "ភូមិ ២៤",
        latin: "Phum 24"
      },
      id: "12021024"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12021101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12021102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12021103"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12021104"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12021105"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12021106"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12021107"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12021108"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12021109"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12021110"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12030101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12030102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12030103"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12030104"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12030105"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12030106"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12030201"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12030202"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12030203"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12030204"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12030205"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12030206"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12030207"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12030208"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12030209"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12030301"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12030302"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12030303"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12030304"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12030305"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12030306"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12030401"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12030402"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12030403"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12030404"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12030405"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12030406"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12030407"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12030408"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12030501"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12030502"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12030503"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12030504"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12030505"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12030506"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12030507"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12030508"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12030509"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12030601"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12030602"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12030603"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12030604"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12030605"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12030606"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12030607"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12030608"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12030609"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12030610"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12030701"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12030702"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12030703"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12030704"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12030705"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12030706"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12030707"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12030708"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12030709"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12030710"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12030711"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12030712"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12030801"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12030802"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12030803"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12030804"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12030805"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12030806"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040103"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040104"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040105"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040106"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040107"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040108"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040109"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040110"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040201"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040202"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040203"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040204"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040205"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040206"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040207"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040208"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040209"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040210"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12040211"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040301"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040302"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040303"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040304"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040305"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040306"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040307"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040308"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040309"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040310"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040401"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040402"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040403"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040404"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040405"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040406"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040407"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040408"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040409"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040410"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12040411"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12040412"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12040413"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12040414"
    },
    {
      name: {
        km: "ភូមិ ១៥",
        latin: "Phum 15"
      },
      id: "12040415"
    },
    {
      name: {
        km: "ភូមិ ១៦",
        latin: "Phum 16"
      },
      id: "12040416"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040501"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040502"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040503"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040504"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040505"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040506"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040507"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040508"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040509"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040510"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12040511"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12040512"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12040513"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040601"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040602"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040603"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040604"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040605"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040606"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040607"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040608"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040609"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040610"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12040611"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12040612"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12040613"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040701"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040702"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040703"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040704"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040705"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040706"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040707"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040708"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040709"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040710"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12040711"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12040712"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12040713"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12040714"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040801"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040802"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040803"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040804"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040805"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040806"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040807"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040808"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040809"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040810"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12040811"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12040812"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12040813"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12040814"
    },
    {
      name: {
        km: "ភូមិ ១៥",
        latin: "Phum 15"
      },
      id: "12040815"
    },
    {
      name: {
        km: "ភូមិ ១៦",
        latin: "Phum 16"
      },
      id: "12040816"
    },
    {
      name: {
        km: "ភូមិ ១៧",
        latin: "Phum 17"
      },
      id: "12040817"
    },
    {
      name: {
        km: "ភូមិ ១៨",
        latin: "Phum 18"
      },
      id: "12040818"
    },
    {
      name: {
        km: "ភូមិ ១៩",
        latin: "Phum 19"
      },
      id: "12040819"
    },
    {
      name: {
        km: "ភូមិ ២០",
        latin: "Phum 20"
      },
      id: "12040820"
    },
    {
      name: {
        km: "ភូមិ ២១",
        latin: "Phum 21"
      },
      id: "12040821"
    },
    {
      name: {
        km: "ភូមិ ២២",
        latin: "Phum 22"
      },
      id: "12040822"
    },
    {
      name: {
        km: "ភូមិ ២៣",
        latin: "Phum 23"
      },
      id: "12040823"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12040901"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12040902"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12040903"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12040904"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12040905"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12040906"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12040907"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12040908"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12040909"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12040910"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12040911"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12040912"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12040913"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12040914"
    },
    {
      name: {
        km: "ភូមិ ១៥",
        latin: "Phum 15"
      },
      id: "12040915"
    },
    {
      name: {
        km: "ភូមិ ១៦",
        latin: "Phum 16"
      },
      id: "12040916"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12041001"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12041002"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12041003"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12041004"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12041005"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12041006"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12041007"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12041008"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12041009"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12041010"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12041011"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum 12"
      },
      id: "12041012"
    },
    {
      name: {
        km: "ភូមិ ១៣",
        latin: "Phum 13"
      },
      id: "12041013"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum 14"
      },
      id: "12041014"
    },
    {
      name: {
        km: "ភូមិ ១៥",
        latin: "Phum 15"
      },
      id: "12041015"
    },
    {
      name: {
        km: "ភូមិ ១៦",
        latin: "Phum 16"
      },
      id: "12041016"
    },
    {
      name: {
        km: "ភូមិ ១៧",
        latin: "Phum 17"
      },
      id: "12041017"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "12050101"
    },
    {
      name: {
        km: "បាគូ",
        latin: "Baku"
      },
      id: "12050102"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "12050103"
    },
    {
      name: {
        km: "តាឡី",
        latin: "Ta Lei"
      },
      id: "12050104"
    },
    {
      name: {
        km: "ម័ល",
        latin: "Mol"
      },
      id: "12050105"
    },
    {
      name: {
        km: "ខ្វា",
        latin: "Khva"
      },
      id: "12050106"
    },
    {
      name: {
        km: "ត្រពាំងទា",
        latin: "Trapeang Tea"
      },
      id: "12050701"
    },
    {
      name: {
        km: "ត្រពាំងគ",
        latin: "Trapeang Kor"
      },
      id: "12050702"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "12050703"
    },
    {
      name: {
        km: "វត្ដស្លែង",
        latin: "Voat Slaeng"
      },
      id: "12050704"
    },
    {
      name: {
        km: "ស្រែញ័រ",
        latin: "Srae Nhoar"
      },
      id: "12050705"
    },
    {
      name: {
        km: "ឃ្លះសណ្ដាយ",
        latin: "Khleah Sanday"
      },
      id: "12050706"
    },
    {
      name: {
        km: "ព្រៃកី ខ",
        latin: "Prey Kei Kha"
      },
      id: "12050707"
    },
    {
      name: {
        km: "ព្រៃកី ក",
        latin: "Prey Kei Ka"
      },
      id: "12050708"
    },
    {
      name: {
        km: "ត្រាំងដោក",
        latin: "Trang Daok"
      },
      id: "12050709"
    },
    {
      name: {
        km: "ត្រពាំងសាលា",
        latin: "Trapeang Sala"
      },
      id: "12050710"
    },
    {
      name: {
        km: "ព្រៃវែងខាងលិច",
        latin: "Prey Veaeng Khang Lech"
      },
      id: "12050801"
    },
    {
      name: {
        km: "ព្រៃវែងខាងកើត",
        latin: "Prey Veaeng Khang Kaeut"
      },
      id: "12050802"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "12050803"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "12050804"
    },
    {
      name: {
        km: "ទ័ពបោះ",
        latin: "Toap Baoh"
      },
      id: "12050805"
    },
    {
      name: {
        km: "កំរៀង",
        latin: "Kamrieng"
      },
      id: "12050806"
    },
    {
      name: {
        km: "រោលជ្រូក",
        latin: "Roul Chruk"
      },
      id: "12050807"
    },
    {
      name: {
        km: "សេរីដីដុះ",
        latin: "Serei Dei Doh"
      },
      id: "12050808"
    },
    {
      name: {
        km: "ទួលសំបូរ",
        latin: "Tuol Sambour"
      },
      id: "12050809"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "12051001"
    },
    {
      name: {
        km: "ព្រៃសខាងកើត",
        latin: "Prey Sa Khang Kaeut"
      },
      id: "12051002"
    },
    {
      name: {
        km: "ប្រការ",
        latin: "Prakar"
      },
      id: "12051003"
    },
    {
      name: {
        km: "ព្រៃសខាងលិច",
        latin: "Prey Sa Khang Lech"
      },
      id: "12051004"
    },
    {
      name: {
        km: "ព្រៃទីទុយ",
        latin: "Prey Tituy"
      },
      id: "12051005"
    },
    {
      name: {
        km: "អន្លង់គង",
        latin: "Anlong Kong"
      },
      id: "12051006"
    },
    {
      name: {
        km: "គោកបន្ទាយ",
        latin: "Kouk Banteay"
      },
      id: "12051007"
    },
    {
      name: {
        km: "ធម្មត្រ័យ",
        latin: "Thommeak Trai"
      },
      id: "12051008"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "12051009"
    },
    {
      name: {
        km: "ម្ភៃបួន",
        latin: "Mphey Buon"
      },
      id: "12051010"
    },
    {
      name: {
        km: "អន្លង់គងថ្មី",
        latin: "Anlong Kong Thmei"
      },
      id: "12051011"
    },
    {
      name: {
        km: "ទួលរកាកុះ",
        latin: "Tuol Roka Koh"
      },
      id: "12051012"
    },
    {
      name: {
        km: "ក្រាំងស្វាយ",
        latin: "Krang Svay"
      },
      id: "12051201"
    },
    {
      name: {
        km: "ក្រាំងពង្រ",
        latin: "Krang Pongro"
      },
      id: "12051202"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "12051203"
    },
    {
      name: {
        km: "ព្រៃសំព័រ",
        latin: "Prey Sampoar"
      },
      id: "12051204"
    },
    {
      name: {
        km: "ពោធី៍រលំ",
        latin: "Pou Rolum"
      },
      id: "12051401"
    },
    {
      name: {
        km: "ខ្វិត",
        latin: "Khvet"
      },
      id: "12051402"
    },
    {
      name: {
        km: "ក្រាំងតាផូ",
        latin: "Krang Ta Phou"
      },
      id: "12051403"
    },
    {
      name: {
        km: "កំរ៉ែង",
        latin: "Kamraeng"
      },
      id: "12051404"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "12051405"
    },
    {
      name: {
        km: "សាក់សំពៅ",
        latin: "Sak Sampov"
      },
      id: "12051406"
    },
    {
      name: {
        km: "ពារាម",
        latin: "Peaream"
      },
      id: "12051407"
    },
    {
      name: {
        km: "ជើងឯក",
        latin: "Cheung Aek"
      },
      id: "12051501"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "12051502"
    },
    {
      name: {
        km: "ស្រុកចេក",
        latin: "Srok Chek"
      },
      id: "12051503"
    },
    {
      name: {
        km: "ព្រែកប្រណាក",
        latin: "Preaek Pranak"
      },
      id: "12051504"
    },
    {
      name: {
        km: "ព្រែកថ្លឹង",
        latin: "Preaek Thloeng"
      },
      id: "12051505"
    },
    {
      name: {
        km: "បូរីកម្មករ",
        latin: "Bourei Kameakkar"
      },
      id: "12051506"
    },
    {
      name: {
        km: "គងនយ",
        latin: "Kong Noy"
      },
      id: "12051601"
    },
    {
      name: {
        km: "វាលថ្លាន់",
        latin: "Veal Thlan"
      },
      id: "12051602"
    },
    {
      name: {
        km: "សេរីសម្បត្ដិ",
        latin: "Serei Sambatt"
      },
      id: "12051603"
    },
    {
      name: {
        km: "ត្រពាំងសំរិត",
        latin: "Trapeang Samret"
      },
      id: "12051604"
    },
    {
      name: {
        km: "ព្រែកកំពឹស",
        latin: "Preaek Kampues"
      },
      id: "12051701"
    },
    {
      name: {
        km: "ព្រែករទាំង",
        latin: "Preaek Roteang"
      },
      id: "12051702"
    },
    {
      name: {
        km: "ព្រែកថ្លឹង",
        latin: "Preaek Thloeng"
      },
      id: "12051703"
    },
    {
      name: {
        km: "ដំណាក់សង្កែ",
        latin: "Damnak Sangkae"
      },
      id: "12051704"
    },
    {
      name: {
        km: "ស្រីស្នំ",
        latin: "Srei Snam"
      },
      id: "12051705"
    },
    {
      name: {
        km: "ក្រាំងស្វាយ",
        latin: "Krang Svay"
      },
      id: "12051706"
    },
    {
      name: {
        km: "ក្រពើទ្រោម",
        latin: "Krapeu Troum"
      },
      id: "12051801"
    },
    {
      name: {
        km: "ព្រះធាតុ",
        latin: "Preah Theat"
      },
      id: "12051802"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "12051803"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "12051901"
    },
    {
      name: {
        km: "គោកឪឡឹក",
        latin: "Kouk Ovloek"
      },
      id: "12051902"
    },
    {
      name: {
        km: "ម៉ឺន្ដ្រា",
        latin: "Meun Tra"
      },
      id: "12051903"
    },
    {
      name: {
        km: "ស្ពានថ្ម",
        latin: "Spean Thma"
      },
      id: "12051904"
    },
    {
      name: {
        km: "ស្វាយមានល័ក្ខណ៍",
        latin: "Svay Mean Leak"
      },
      id: "12051905"
    },
    {
      name: {
        km: "ភូមិហា",
        latin: "Phum Ha"
      },
      id: "12051906"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "12051907"
    },
    {
      name: {
        km: "ព្រែកជ្រៃ",
        latin: "Preaek Chrey"
      },
      id: "12051908"
    },
    {
      name: {
        km: "ក្រាំងក្រូច",
        latin: "Krang Krouch"
      },
      id: "12052001"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "12052002"
    },
    {
      name: {
        km: "ថ្ម",
        latin: "Thma"
      },
      id: "12052003"
    },
    {
      name: {
        km: "កន្ទុយទឹក",
        latin: "Kantuy Tuek"
      },
      id: "12052004"
    },
    {
      name: {
        km: "សាលា",
        latin: "Sala"
      },
      id: "12052005"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "12052006"
    },
    {
      name: {
        km: "ព្រែកតាគង់",
        latin: "Prek Ta Kong"
      },
      id: "12060601"
    },
    {
      name: {
        km: "ព្រែកតានូ",
        latin: "Prek Ta Nu"
      },
      id: "12060602"
    },
    {
      name: {
        km: "ព្រែកតាគង់១",
        latin: "Prek Ta Kong I"
      },
      id: "12060603"
    },
    {
      name: {
        km: "ព្រែកតាគង់២",
        latin: "Prek Ta Kong II"
      },
      id: "12060604"
    },
    {
      name: {
        km: "ព្រែកតាគង់៣",
        latin: "Prek Ta Kong III"
      },
      id: "12060605"
    },
    {
      name: {
        km: "ព្រែកតានូ១",
        latin: "Preaek Ta Nu I"
      },
      id: "12060606"
    },
    {
      name: {
        km: "ព្រែកតានូ២",
        latin: "Preaek Ta Nu II"
      },
      id: "12060607"
    },
    {
      name: {
        km: "ទួលរកា",
        latin: "Tuol Roka"
      },
      id: "12060701"
    },
    {
      name: {
        km: "ព្រែកតាឡុង",
        latin: "Preaek Ta Long"
      },
      id: "12060702"
    },
    {
      name: {
        km: "ទួលរកា១",
        latin: "Tuol Roka Muoy"
      },
      id: "12060703"
    },
    {
      name: {
        km: "ទួលរកា២",
        latin: "Tuol Roka Pir"
      },
      id: "12060704"
    },
    {
      name: {
        km: "ទួលរកា៣",
        latin: "Tuol Roka Bei"
      },
      id: "12060705"
    },
    {
      name: {
        km: "ព្រែកតាឡុង១",
        latin: "Preaek Ta Long Muoy"
      },
      id: "12060706"
    },
    {
      name: {
        km: "ព្រែកតាឡុង២",
        latin: "Preaek Ta Long II"
      },
      id: "12060707"
    },
    {
      name: {
        km: "ព្រែកតាឡុង៣",
        latin: "Preaek Ta Long III"
      },
      id: "12060708"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "12060801"
    },
    {
      name: {
        km: "មានជ័យ១",
        latin: "Mean Chey Muoy"
      },
      id: "12060802"
    },
    {
      name: {
        km: "មានជ័យ២",
        latin: "Mean Chey 2"
      },
      id: "12060803"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "12060804"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "12060805"
    },
    {
      name: {
        km: "ទ្រា១",
        latin: "Trea Muoy"
      },
      id: "12060806"
    },
    {
      name: {
        km: "ទ្រា២",
        latin: "Trea Pir"
      },
      id: "12060807"
    },
    {
      name: {
        km: "ទ្រា៣",
        latin: "Trea Bei"
      },
      id: "12060808"
    },
    {
      name: {
        km: "ភ្នាត",
        latin: "Phneat"
      },
      id: "12060901"
    },
    {
      name: {
        km: "ដំណាក់ធំ",
        latin: "Damnak Thum"
      },
      id: "12060902"
    },
    {
      name: {
        km: "ដំណាក់ធំ១",
        latin: "Damnak Thum Muoy"
      },
      id: "12060903"
    },
    {
      name: {
        km: "ដំណាក់ធំ៤",
        latin: "Damnak Thum 4"
      },
      id: "12060904"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "12060905"
    },
    {
      name: {
        km: "ឫស្សី ៣",
        latin: "Ruessei 3"
      },
      id: "12060906"
    },
    {
      name: {
        km: "ព្រែកទាល់",
        latin: "Prek Toal"
      },
      id: "12060907"
    },
    {
      name: {
        km: "ព្រែកទាល់១",
        latin: "Prek Toal 1"
      },
      id: "12060908"
    },
    {
      name: {
        km: "ទ្រា៤",
        latin: "Trea Buon"
      },
      id: "12061001"
    },
    {
      name: {
        km: "ឫស្សី១",
        latin: "Russey Muoy"
      },
      id: "12061002"
    },
    {
      name: {
        km: "ឫស្សី ២",
        latin: "Ruessei 2"
      },
      id: "12061003"
    },
    {
      name: {
        km: "ដំណាក់ធំ២",
        latin: "Damnak Thum Pir"
      },
      id: "12061004"
    },
    {
      name: {
        km: "ដំណាក់ធំ៣",
        latin: "Damnak Thum Bei"
      },
      id: "12061005"
    },
    {
      name: {
        km: "ដំណាក់ធំ៥",
        latin: "Damnak Thum 5"
      },
      id: "12061006"
    },
    {
      name: {
        km: "ចំរើនផល",
        latin: "Chamraeun Phal"
      },
      id: "12061101"
    },
    {
      name: {
        km: "ចំរើនផល១",
        latin: "Chamroeunphal Muoy"
      },
      id: "12061102"
    },
    {
      name: {
        km: "ចំរើនផល២",
        latin: "Chamroeunphal 2"
      },
      id: "12061103"
    },
    {
      name: {
        km: "ចំរើនផល៣",
        latin: "Chamroeunphal 3"
      },
      id: "12061104"
    },
    {
      name: {
        km: "ចំរើនផល៤",
        latin: "Chamroeunphal 4"
      },
      id: "12061105"
    },
    {
      name: {
        km: "សន្សំកុសល ១",
        latin: "Sansam Kosal Muoy"
      },
      id: "12061106"
    },
    {
      name: {
        km: "សន្សំកុសល ២",
        latin: "Sansam Kosal Pir"
      },
      id: "12061107"
    },
    {
      name: {
        km: "សន្សំកុសល ៣",
        latin: "Sansam Kosal 3"
      },
      id: "12061108"
    },
    {
      name: {
        km: "សន្សំកុសល ៤",
        latin: "Sansam Kosal 4"
      },
      id: "12061109"
    },
    {
      name: {
        km: "សន្សំកុសល ៥",
        latin: "Sansam Kosal 5"
      },
      id: "12061110"
    },
    {
      name: {
        km: "សន្សំកុសល៦",
        latin: "Sansam Kosal 6"
      },
      id: "12061111"
    },
    {
      name: {
        km: "ក្បាលទំនប់",
        latin: "Kbal Tumnob"
      },
      id: "12061201"
    },
    {
      name: {
        km: "ក្បាលទំនប់១",
        latin: "Kbal Tumnub Muoy"
      },
      id: "12061202"
    },
    {
      name: {
        km: "ក្បាលទំនប់២",
        latin: "Kbal Tumnub Pir"
      },
      id: "12061203"
    },
    {
      name: {
        km: "ក្បាលទំនប់៣",
        latin: "Kbal Tumnub 3"
      },
      id: "12061204"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ",
        latin: "Tnaot Chrum"
      },
      id: "12061205"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ១",
        latin: "Tnaot Chrum Muoy"
      },
      id: "12061206"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ២",
        latin: "Tnaot Chrum Pir"
      },
      id: "12061207"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ៣",
        latin: "Tnaot Chrum Bei"
      },
      id: "12061208"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ៤",
        latin: "Tnaot Chrum Boun"
      },
      id: "12061209"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ៥",
        latin: "Tnaot Chrum 5"
      },
      id: "12061210"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ៦",
        latin: "Tnaot Chrum 6"
      },
      id: "12061211"
    },
    {
      name: {
        km: "ឡកំបោរ",
        latin: "La Kambaor"
      },
      id: "12070301"
    },
    {
      name: {
        km: "លូ",
        latin: "Lu"
      },
      id: "12070302"
    },
    {
      name: {
        km: "ស្វាយប៉ាក",
        latin: "Svay Pak"
      },
      id: "12070303"
    },
    {
      name: {
        km: "ក្រោលគោ",
        latin: "Kraol Kou"
      },
      id: "12070401"
    },
    {
      name: {
        km: "ស្ពានខ្ពស់",
        latin: "Spean Khpos"
      },
      id: "12070402"
    },
    {
      name: {
        km: "បឹងឈូក",
        latin: "Boeng Chhuk"
      },
      id: "12070403"
    },
    {
      name: {
        km: "មិត្ដភាព",
        latin: "Mittakpheap"
      },
      id: "12070601"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "12070602"
    },
    {
      name: {
        km: "ឃ្លាំងសាំង",
        latin: "Khleang Sang"
      },
      id: "12070603"
    },
    {
      name: {
        km: "បឹងសាឡាង",
        latin: "Boeng Salang"
      },
      id: "12070604"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12071101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12071102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12071103"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12071104"
    },
    {
      name: {
        km: "ភូមិ ក",
        latin: "Phum Ka"
      },
      id: "12071201"
    },
    {
      name: {
        km: "ភូមិ ខ១",
        latin: "Phum Kha Muoy"
      },
      id: "12071202"
    },
    {
      name: {
        km: "ភូមិ ខ២",
        latin: "Phum Kha Pir"
      },
      id: "12071203"
    },
    {
      name: {
        km: "ភូមិឃ",
        latin: "Phum Kho"
      },
      id: "12071204"
    },
    {
      name: {
        km: "ផ្សារតូច",
        latin: "Phsar Toch"
      },
      id: "12071301"
    },
    {
      name: {
        km: "ទួលសង្កែ",
        latin: "Toul sangke"
      },
      id: "12071302"
    },
    {
      name: {
        km: "ទួលគោក",
        latin: "Tuol Kok"
      },
      id: "12071303"
    },
    {
      name: {
        km: "ចុងខ្សាច់",
        latin: "Chong Khsach"
      },
      id: "12071304"
    },
    {
      name: {
        km: "បាក់ទូក",
        latin: "Bak Touk"
      },
      id: "12071305"
    },
    {
      name: {
        km: "ទួលសំពៅ",
        latin: "Tuol Sampov"
      },
      id: "12071306"
    },
    {
      name: {
        km: "គង្គាផុស",
        latin: "Kongkea Phos"
      },
      id: "12071401"
    },
    {
      name: {
        km: "កោះអណ្តែត",
        latin: "Kos Andaet"
      },
      id: "12071402"
    },
    {
      name: {
        km: "ពោងពាយ",
        latin: "Pong Peav"
      },
      id: "12071403"
    },
    {
      name: {
        km: "បឹងរាំង",
        latin: "Boeung Rang"
      },
      id: "12071404"
    },
    {
      name: {
        km: "ទួលពពែ",
        latin: "Tuol Porpae"
      },
      id: "12071405"
    },
    {
      name: {
        km: "ទួលថ្ងាន់",
        latin: "Tuol Thgan"
      },
      id: "12071406"
    },
    {
      name: {
        km: "ពោងពាយ",
        latin: "Poung Peay"
      },
      id: "12080102"
    },
    {
      name: {
        km: "បាយ៉ាប",
        latin: "Bayab"
      },
      id: "12080104"
    },
    {
      name: {
        km: "ភ្នំពេញថ្មី",
        latin: "Phnom Penh Thmei"
      },
      id: "12080105"
    },
    {
      name: {
        km: "ទំនប់",
        latin: "Tumnob"
      },
      id: "12080107"
    },
    {
      name: {
        km: "ទំនប់១",
        latin: "Tumnob 1"
      },
      id: "12080111"
    },
    {
      name: {
        km: "ឧកញ៉ាវាំង",
        latin: "Ouknha Veang"
      },
      id: "12080113"
    },
    {
      name: {
        km: "ផ្សារទឹកថ្លា",
        latin: "Phsar Tuek Thla"
      },
      id: "12080204"
    },
    {
      name: {
        km: "សេប៉េសេ",
        latin: "Se Pe Se"
      },
      id: "12080205"
    },
    {
      name: {
        km: "បូរី១០០ខ្នង",
        latin: "Bourei Muoy Roy Khnang"
      },
      id: "12080206"
    },
    {
      name: {
        km: "ចុងថ្នល់ខាងកើត",
        latin: "Chong Thnal Khang Kaeut"
      },
      id: "12080207"
    },
    {
      name: {
        km: "ចុងថ្នល់ខាងលិច",
        latin: "Chong Thnal Khang Lech"
      },
      id: "12080208"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "12080209"
    },
    {
      name: {
        km: "បុរីកម្មករ",
        latin: "Borey Kamakar"
      },
      id: "12080211"
    },
    {
      name: {
        km: "ឃ្មួញ",
        latin: "Anlong Kngan"
      },
      id: "12080301"
    },
    {
      name: {
        km: "បន្លាស្អិត",
        latin: "Banla S'et"
      },
      id: "12080302"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "12080303"
    },
    {
      name: {
        km: "អន្លង់ក្ងាន",
        latin: "Anlong Kngan"
      },
      id: "12080304"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "12080305"
    },
    {
      name: {
        km: "សែនសុខទី១",
        latin: "Saen Sokh Ti Muoy"
      },
      id: "12080306"
    },
    {
      name: {
        km: "សែនសុខទី២",
        latin: "Saen Sokh Ti Pir"
      },
      id: "12080307"
    },
    {
      name: {
        km: "សែនសុខទី៣",
        latin: "Saen Sokh Ti Bei"
      },
      id: "12080308"
    },
    {
      name: {
        km: "សែនសុខទី៤",
        latin: "Saen Sokh Ti Buon"
      },
      id: "12080309"
    },
    {
      name: {
        km: "សែនសុខទី៥",
        latin: "Seaen Sokh Ti Pram"
      },
      id: "12080310"
    },
    {
      name: {
        km: "សែនសុខទី៦",
        latin: "Saen Sokh Ti Prammuoy"
      },
      id: "12080311"
    },
    {
      name: {
        km: "សែនសុខទី៧",
        latin: "Saen Sokh Ti Prampir"
      },
      id: "12080312"
    },
    {
      name: {
        km: "ត្រពាំងរាំងថ្មី",
        latin: "Trapeang Reang Thmei"
      },
      id: "12080313"
    },
    {
      name: {
        km: "ក្រាំងអង្ក្រង",
        latin: "Krang Angkrang"
      },
      id: "12080701"
    },
    {
      name: {
        km: "ត្រពាំងមាន",
        latin: "Trapeang Mean"
      },
      id: "12080702"
    },
    {
      name: {
        km: "ជាងទង",
        latin: "Cheang Tong"
      },
      id: "12080703"
    },
    {
      name: {
        km: "ត្រពាំងជើងស្រុក",
        latin: "Trapeang Cheung Srok"
      },
      id: "12080704"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "12080705"
    },
    {
      name: {
        km: "សំរោងទាវ",
        latin: "Samraong Teav"
      },
      id: "12080706"
    },
    {
      name: {
        km: "ព្រៃមូល",
        latin: "Prey Mul"
      },
      id: "12080707"
    },
    {
      name: {
        km: "វិមានទ្រង់",
        latin: "Vimean Trong"
      },
      id: "12080708"
    },
    {
      name: {
        km: "អូរបែកក្អម",
        latin: "Ou Baek K'am"
      },
      id: "12080801"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "12080802"
    },
    {
      name: {
        km: "អ័រគីដេ",
        latin: "Orchide"
      },
      id: "12080803"
    },
    {
      name: {
        km: "ស្លែងរលើង",
        latin: "Slaeng Roleung"
      },
      id: "12080804"
    },
    {
      name: {
        km: "ទ្រុងមាន់",
        latin: "Trong Moan"
      },
      id: "12080805"
    },
    {
      name: {
        km: "រោងចក្រ",
        latin: "Roung Chakr"
      },
      id: "12080901"
    },
    {
      name: {
        km: "រោងចក្រ១",
        latin: "Roung Chakr 1"
      },
      id: "12080902"
    },
    {
      name: {
        km: "ក្រុងថ្មី",
        latin: "Krong Thmey"
      },
      id: "12080903"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "12080904"
    },
    {
      name: {
        km: "ដីថ្មី",
        latin: "Dei Thmei"
      },
      id: "12080905"
    },
    {
      name: {
        km: "ដំណាក់",
        latin: "Damnak"
      },
      id: "12080906"
    },
    {
      name: {
        km: "គោកឃ្លាង",
        latin: "Kouk Khleang"
      },
      id: "12080907"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "12080908"
    },
    {
      name: {
        km: "អក្សរ",
        latin: "Aksar"
      },
      id: "12090101"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "12090102"
    },
    {
      name: {
        km: "ត្រពាំងអណ្ដូង",
        latin: "Trapaang Andoung"
      },
      id: "12090103"
    },
    {
      name: {
        km: "ព្រៃដូនអុក",
        latin: "Prey Doun Ok"
      },
      id: "12090104"
    },
    {
      name: {
        km: "ត្រពាំងទា",
        latin: "Trapeang Tea"
      },
      id: "12090105"
    },
    {
      name: {
        km: "ត្រពាំងអញ្ចាញ",
        latin: "Trapeang Anhchanh"
      },
      id: "12090106"
    },
    {
      name: {
        km: "ជង្រុក",
        latin: "Chongruk"
      },
      id: "12090107"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "12090108"
    },
    {
      name: {
        km: "ខ្វែ",
        latin: "Khvae"
      },
      id: "12090109"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "12090110"
    },
    {
      name: {
        km: "សាមគ្គី ១",
        latin: "Sameakki 1"
      },
      id: "12090111"
    },
    {
      name: {
        km: "សាមគ្គី ២",
        latin: "Sameakki 2"
      },
      id: "12090112"
    },
    {
      name: {
        km: "សាមគ្គី ៣",
        latin: "Sameakki 3"
      },
      id: "12090113"
    },
    {
      name: {
        km: "ចំការស្បែង",
        latin: "Chamkar Sbaeng"
      },
      id: "12090601"
    },
    {
      name: {
        km: "ត្រពាំងធ្នង់",
        latin: "Trapeang Thnong"
      },
      id: "12090602"
    },
    {
      name: {
        km: "គោកព្រេជ",
        latin: "Kouk Prich"
      },
      id: "12090603"
    },
    {
      name: {
        km: "តិកូបញ្ញេ",
        latin: "Tekapanhao"
      },
      id: "12090604"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "12090605"
    },
    {
      name: {
        km: "ចែងម៉ែង",
        latin: "Chaeng Maeng"
      },
      id: "12090606"
    },
    {
      name: {
        km: "ចាក់ជ្រូក",
        latin: "Chak Chruk"
      },
      id: "12090607"
    },
    {
      name: {
        km: "អករំដួល",
        latin: "Ak Rumduol"
      },
      id: "12090608"
    },
    {
      name: {
        km: "ស្រែរាជ្ជៈ",
        latin: "Srae Reacheak"
      },
      id: "12090609"
    },
    {
      name: {
        km: "អណ្ដូងតាអន",
        latin: "Andoung Ta An"
      },
      id: "12090610"
    },
    {
      name: {
        km: "ព្រៃពពេល",
        latin: "Prey Popel"
      },
      id: "12090611"
    },
    {
      name: {
        km: "គោកចំបក់",
        latin: "Kouk Chambak"
      },
      id: "12091401"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "12091402"
    },
    {
      name: {
        km: "ត្រពាំងរំចេក",
        latin: "Trapeang Rumchek"
      },
      id: "12091403"
    },
    {
      name: {
        km: "ចោមចៅ១",
        latin: "Chaom Chau 1"
      },
      id: "12091404"
    },
    {
      name: {
        km: "ត្រពាំងថ្លឹង១",
        latin: "Trapeang Thloeng 1"
      },
      id: "12091405"
    },
    {
      name: {
        km: "ត្រពាំងថ្លឹង២",
        latin: "Trapeang Thloeng 2"
      },
      id: "12091406"
    },
    {
      name: {
        km: "ត្រពាំងថ្លឹង៣",
        latin: "Trapeang Thloeng 3"
      },
      id: "12091407"
    },
    {
      name: {
        km: "ត្រពាំងថ្លឹង៤",
        latin: "Trapeang Thloeng 4"
      },
      id: "12091408"
    },
    {
      name: {
        km: "ថ្មគោល១",
        latin: "Thma Koul 1"
      },
      id: "12091501"
    },
    {
      name: {
        km: "ថ្មគោល២",
        latin: "Thma Koul 2"
      },
      id: "12091502"
    },
    {
      name: {
        km: "ថ្មគោល៣",
        latin: "Thma Koul 3"
      },
      id: "12091503"
    },
    {
      name: {
        km: "ចោមចៅ២",
        latin: "Chaom Chau 2"
      },
      id: "12091504"
    },
    {
      name: {
        km: "ចោមចៅ៣",
        latin: "Chaom Chau 3"
      },
      id: "12091505"
    },
    {
      name: {
        km: "ជ្រៃកោង",
        latin: "Chrey Kaong"
      },
      id: "12091506"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "12091507"
    },
    {
      name: {
        km: "ព្រៃល្ង",
        latin: "Prey Lngor"
      },
      id: "12091508"
    },
    {
      name: {
        km: "ព្រៃស្ពឺ",
        latin: "Prey Spueu"
      },
      id: "12091509"
    },
    {
      name: {
        km: "ព្រៃកំបុត",
        latin: "Prey Kambot"
      },
      id: "12091510"
    },
    {
      name: {
        km: "ដំណាក់ត្រយឹង",
        latin: "Damnak Trayueng"
      },
      id: "12091511"
    },
    {
      name: {
        km: "ស្រែជំរៅ",
        latin: "Srae Chumrov"
      },
      id: "12091512"
    },
    {
      name: {
        km: "កប់គង",
        latin: "Kab Kong"
      },
      id: "12091513"
    },
    {
      name: {
        km: "ក្រាំងដូនទៃ",
        latin: "Krang Doun Tey"
      },
      id: "12091514"
    },
    {
      name: {
        km: "ព្រៃសណ្ដែក",
        latin: "Prey Sandaek"
      },
      id: "12091515"
    },
    {
      name: {
        km: "ព្រៃព្រីងខាងត្បូង១",
        latin: "Prey Pring Khang Tboung 1"
      },
      id: "12091516"
    },
    {
      name: {
        km: "ព្រៃទា១",
        latin: "Prey Tea 1"
      },
      id: "12091601"
    },
    {
      name: {
        km: "ព្រៃទា២",
        latin: "Prey Tea 2"
      },
      id: "12091602"
    },
    {
      name: {
        km: "ព្រៃជីសាក់",
        latin: "Prey Chi Sak"
      },
      id: "12091603"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "12091604"
    },
    {
      name: {
        km: "ព្រៃព្រីងខាងជើង១",
        latin: "Prey Pring Khang Cheung 1"
      },
      id: "12091605"
    },
    {
      name: {
        km: "ព្រៃព្រីងខាងជើង២",
        latin: "Prey Pring Khang Cheung 2"
      },
      id: "12091606"
    },
    {
      name: {
        km: "ព្រៃព្រីងខាងត្បូង២",
        latin: "Prey Pring Khang Tboung 2"
      },
      id: "12091607"
    },
    {
      name: {
        km: "ថ្នល់បំបែក",
        latin: "Thnal Bambaek"
      },
      id: "12091608"
    },
    {
      name: {
        km: "ជម្ពូវ័ន១",
        latin: "Chumpu Voan 1"
      },
      id: "12091609"
    },
    {
      name: {
        km: "ជម្ពូវ័ន២",
        latin: "Chumpu Voan 2"
      },
      id: "12091610"
    },
    {
      name: {
        km: "ព្រៃស្វាយ",
        latin: "Prey Svay"
      },
      id: "12091611"
    },
    {
      name: {
        km: "អង្គកែវ",
        latin: "Angk  Kaev"
      },
      id: "12091612"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "12091613"
    },
    {
      name: {
        km: "អូរ ដឹម",
        latin: "Ou Dem"
      },
      id: "12091614"
    },
    {
      name: {
        km: "ក្បាលដំរី១",
        latin: "Kbal Damrei 1"
      },
      id: "12091701"
    },
    {
      name: {
        km: "ចំការឪឡឹក១",
        latin: "Chamkar Ovloek 1"
      },
      id: "12091702"
    },
    {
      name: {
        km: "តាងួន១",
        latin: "Ta Nguon 1"
      },
      id: "12091703"
    },
    {
      name: {
        km: "តាងួន២",
        latin: "Ta Nguon 2"
      },
      id: "12091704"
    },
    {
      name: {
        km: "ប៉ប្រកខាងត្បូង",
        latin: "Paprak Khang Tboung"
      },
      id: "12091705"
    },
    {
      name: {
        km: "ត្រពាំងល្វា១",
        latin: "Trapeang Lvea 1"
      },
      id: "12091706"
    },
    {
      name: {
        km: "ត្រពាំងល្វា២",
        latin: "Trapeang Lvea 2"
      },
      id: "12091707"
    },
    {
      name: {
        km: "ប៉ប្រកខាងជើង",
        latin: "Paprak Khang Cheung"
      },
      id: "12091708"
    },
    {
      name: {
        km: "ក្បាលដំរី២",
        latin: "Kbal Damrei 2"
      },
      id: "12091801"
    },
    {
      name: {
        km: "ចំការឪឡឹក២",
        latin: "Chamkar Ovloek 2"
      },
      id: "12091802"
    },
    {
      name: {
        km: "ព្រៃសាលា",
        latin: "Prey Sala"
      },
      id: "12091803"
    },
    {
      name: {
        km: "កាកាប",
        latin: "Kakab"
      },
      id: "12091804"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "12091805"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12100101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12100102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12100103"
    },
    {
      name: {
        km: "ដើមគរ",
        latin: "Daeum Kor"
      },
      id: "12100104"
    },
    {
      name: {
        km: "គៀនឃ្លាំង",
        latin: "Kien Khleang"
      },
      id: "12100105"
    },
    {
      name: {
        km: "គៀនឃ្លាំង",
        latin: "Kien Khleang"
      },
      id: "12100201"
    },
    {
      name: {
        km: "ព្រែកលៀប",
        latin: "Preaek Lieb"
      },
      id: "12100202"
    },
    {
      name: {
        km: "បាក់ខែង",
        latin: "Bak Khaeng"
      },
      id: "12100203"
    },
    {
      name: {
        km: "ខ្ទរ",
        latin: "Khtor"
      },
      id: "12100204"
    },
    {
      name: {
        km: "ព្រែកតារ័ត្ន",
        latin: "Preaek Ta Roatn"
      },
      id: "12100301"
    },
    {
      name: {
        km: "ព្រែកតាគង់",
        latin: "Preaek Ta Kong"
      },
      id: "12100302"
    },
    {
      name: {
        km: "ព្រែករាំង",
        latin: "Preaek Reang"
      },
      id: "12100303"
    },
    {
      name: {
        km: "ព្រែកតាសេក",
        latin: "Preaek Ta Sek"
      },
      id: "12100304"
    },
    {
      name: {
        km: "ដើមគរ",
        latin: "Daeum Kor"
      },
      id: "12100305"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "12100401"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "12100402"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "12100403"
    },
    {
      name: {
        km: "កោះដាច់",
        latin: "Kaoh Dach"
      },
      id: "12100404"
    },
    {
      name: {
        km: "រនះ",
        latin: "Roneah"
      },
      id: "12100405"
    },
    {
      name: {
        km: "បាក់ខែងលើ",
        latin: "Bak Khaeng Leu"
      },
      id: "12100501"
    },
    {
      name: {
        km: "ក្ដីចាស់",
        latin: "Kdei Chas"
      },
      id: "12100502"
    },
    {
      name: {
        km: "ចំបក់មាស",
        latin: "Chambak Meas"
      },
      id: "12100503"
    },
    {
      name: {
        km: "ដួង",
        latin: "Duong"
      },
      id: "12110101"
    },
    {
      name: {
        km: "ពោធិ៍មង្គល",
        latin: "Pou Mongkol"
      },
      id: "12110102"
    },
    {
      name: {
        km: "ព្រែកព្នៅ",
        latin: "Preaek Pnov"
      },
      id: "12110103"
    },
    {
      name: {
        km: "ផ្សារលិច",
        latin: "Phsar Lech"
      },
      id: "12110104"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "12110105"
    },
    {
      name: {
        km: "ធំត្បូង",
        latin: "Thum Tboung"
      },
      id: "12110201"
    },
    {
      name: {
        km: "ធំជើង",
        latin: "Thum Cheung"
      },
      id: "12110202"
    },
    {
      name: {
        km: "ត្រពាំងស្នោរ",
        latin: "Trapeang Snaor"
      },
      id: "12110203"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "12110204"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "12110205"
    },
    {
      name: {
        km: "ស្រែដូនតូច",
        latin: "Srae Doun Touch"
      },
      id: "12110206"
    },
    {
      name: {
        km: "ព្រៃពង្រ",
        latin: "Prey Pongro"
      },
      id: "12110207"
    },
    {
      name: {
        km: "វែង",
        latin: "Veaeng"
      },
      id: "12110208"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "12110301"
    },
    {
      name: {
        km: "សំរោងជើង",
        latin: "Samraong Cheung"
      },
      id: "12110302"
    },
    {
      name: {
        km: "សំរោងកណ្ដាល",
        latin: "Samraong Kandal"
      },
      id: "12110303"
    },
    {
      name: {
        km: "សំរោងត្បូង",
        latin: "Samraong Tboung"
      },
      id: "12110304"
    },
    {
      name: {
        km: "កប់ស្រូវតូច",
        latin: "Kab Srov Touch"
      },
      id: "12110401"
    },
    {
      name: {
        km: "កប់ស្រូវធំ",
        latin: "Kab Srov Thum"
      },
      id: "12110402"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "12110403"
    },
    {
      name: {
        km: "បែកបក",
        latin: "Baek Bak"
      },
      id: "12110404"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "12110405"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "12110406"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "12110407"
    },
    {
      name: {
        km: "ពុទ្រា",
        latin: "Putrea"
      },
      id: "12110408"
    },
    {
      name: {
        km: "ជ្រេស",
        latin: "Chres"
      },
      id: "12110409"
    },
    {
      name: {
        km: "ខ្មែរលើ",
        latin: "Khmer Leu"
      },
      id: "12110410"
    },
    {
      name: {
        km: "គោករកា",
        latin: "Kouk Roka"
      },
      id: "12110411"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "12110412"
    },
    {
      name: {
        km: "ជំរៅ",
        latin: "Chumrov"
      },
      id: "12110413"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "12110414"
    },
    {
      name: {
        km: "ត្រពាំងសំព័រ",
        latin: "Trapeang Sampoar"
      },
      id: "12110415"
    },
    {
      name: {
        km: "អង្គតាកូវ",
        latin: "Angk Ta Kov"
      },
      id: "12110416"
    },
    {
      name: {
        km: "ភ្លូផ្អែម",
        latin: "Phlu Ph'aem"
      },
      id: "12110417"
    },
    {
      name: {
        km: "ទួលសំពៅ",
        latin: "Tuol Sampov"
      },
      id: "12110418"
    },
    {
      name: {
        km: "បួនមុខ",
        latin: "Buon Mukh"
      },
      id: "12110501"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang  Ampil"
      },
      id: "12110502"
    },
    {
      name: {
        km: "ស្វាយឧត្ដម",
        latin: "Svay Otdam"
      },
      id: "12110503"
    },
    {
      name: {
        km: "ថ្នល់បន្ទាយ",
        latin: "Thnal Banteay"
      },
      id: "12110504"
    },
    {
      name: {
        km: "ត្រពាំងទទឹង",
        latin: "Trapeang Totueng"
      },
      id: "12110505"
    },
    {
      name: {
        km: "កាន់ទ្រង់",
        latin: "KanTrong"
      },
      id: "12110506"
    },
    {
      name: {
        km: "ត្រពាំងរនាម",
        latin: "Trapeang Roneam"
      },
      id: "12110507"
    },
    {
      name: {
        km: "ដើមចាន់",
        latin: "Daeum Chan"
      },
      id: "12110508"
    },
    {
      name: {
        km: "បឹងប៉ិច",
        latin: "Boeng Pech"
      },
      id: "12110509"
    },
    {
      name: {
        km: "ឧសភា",
        latin: "Osphea"
      },
      id: "12110510"
    },
    {
      name: {
        km: "តាគល់",
        latin: "Ta Kol"
      },
      id: "12110511"
    },
    {
      name: {
        km: "ព្រៃស្វាយ",
        latin: "Prey Svay"
      },
      id: "12110512"
    },
    {
      name: {
        km: "កោះរងៀង",
        latin: "Kaoh Rongieng"
      },
      id: "12110513"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "12110514"
    },
    {
      name: {
        km: "ត្នោតខ្ពស់",
        latin: "Tnaot Khpos"
      },
      id: "12110515"
    },
    {
      name: {
        km: "បឹងខ្នំ",
        latin: "Boeng Khnam"
      },
      id: "12110516"
    },
    {
      name: {
        km: "ចុងថ្នល់",
        latin: "Chong Thnal"
      },
      id: "12110517"
    },
    {
      name: {
        km: "កន្លែងគល់",
        latin: "Kanlaeng Kol"
      },
      id: "12110518"
    },
    {
      name: {
        km: "ចំបក់ធំ",
        latin: "Chambak Thum"
      },
      id: "12110519"
    },
    {
      name: {
        km: "ត្រពាំងថ្លាន់",
        latin: "Trapeang Thlan"
      },
      id: "12110520"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់",
        latin: "Trapeang Skon"
      },
      id: "12110521"
    },
    {
      name: {
        km: "ព្រៃស្នួល",
        latin: "Prey Snuol"
      },
      id: "12110522"
    },
    {
      name: {
        km: "តាស្គរ",
        latin: "Ta Skor"
      },
      id: "12110523"
    },
    {
      name: {
        km: "ទួលពន្សាំង",
        latin: "Tuol Ponsang"
      },
      id: "12110524"
    },
    {
      name: {
        km: "ព្រែក",
        latin: "Preaek"
      },
      id: "12120101"
    },
    {
      name: {
        km: "ដើមម៉ាក់ក្លឿ",
        latin: "Daeum Meakkloea"
      },
      id: "12120102"
    },
    {
      name: {
        km: "ដើមអំពិល",
        latin: "Daeum Ampil"
      },
      id: "12120103"
    },
    {
      name: {
        km: "ដើមចាន់",
        latin: "Deum Chan"
      },
      id: "12120201"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "12120202"
    },
    {
      name: {
        km: "ដើមស្លែង",
        latin: "Deum Slaeng"
      },
      id: "12120203"
    },
    {
      name: {
        km: "ដើមចាន់១",
        latin: "Deum Chan I"
      },
      id: "12120204"
    },
    {
      name: {
        km: "កណ្ដាល១",
        latin: "Kandal I"
      },
      id: "12120205"
    },
    {
      name: {
        km: "ដើមស្លែង១",
        latin: "Deum Slaeng I"
      },
      id: "12120206"
    },
    {
      name: {
        km: "តាងៅ",
        latin: "Ta Ngov"
      },
      id: "12120301"
    },
    {
      name: {
        km: "បឹងឈូក",
        latin: "Boeng Chhuk"
      },
      id: "12120302"
    },
    {
      name: {
        km: "ឫស្សីស្រស់",
        latin: "Ruessei Sras"
      },
      id: "12120303"
    },
    {
      name: {
        km: "កោះនរា",
        latin: "Kaoh Norea"
      },
      id: "12120304"
    },
    {
      name: {
        km: "តាងៅកណ្ដាល",
        latin: "Ta Ngov Kandal"
      },
      id: "12120305"
    },
    {
      name: {
        km: "តាងៅក្រោម",
        latin: "Ta Ngov Kraom"
      },
      id: "12120306"
    },
    {
      name: {
        km: "ជ្រោយបាសាក់",
        latin: "Chrouy Basak"
      },
      id: "12120401"
    },
    {
      name: {
        km: "ព្រះពន្លា",
        latin: "Preah Ponlea"
      },
      id: "12120402"
    },
    {
      name: {
        km: "ព្រែកតាពៅ",
        latin: "Preaek Ta Pov"
      },
      id: "12120403"
    },
    {
      name: {
        km: "អូរអណ្ដូង",
        latin: "Ou Andoung"
      },
      id: "12120404"
    },
    {
      name: {
        km: "ជ្រោយបាសាក់១",
        latin: "Chrouy Basak Muoy"
      },
      id: "12120405"
    },
    {
      name: {
        km: "អូរអណ្ដូង១",
        latin: "Ou Andoung Muoy"
      },
      id: "12120406"
    },
    {
      name: {
        km: "ក្ដីតាកុយ",
        latin: "Kdei Ta Koy"
      },
      id: "12120501"
    },
    {
      name: {
        km: "វាលស្បូវ",
        latin: "Veal Sbov"
      },
      id: "12120502"
    },
    {
      name: {
        km: "ស្វាយតាអុក",
        latin: "Svay Ta Ok"
      },
      id: "12120503"
    },
    {
      name: {
        km: "ព្រែកជាងព្រុំ",
        latin: "Preaek Cheang Prum"
      },
      id: "12120504"
    },
    {
      name: {
        km: "ស្វាយតាអុក ១",
        latin: "Svay Ta Ok 1"
      },
      id: "12120505"
    },
    {
      name: {
        km: "ក្បាលជ្រោយ",
        latin: "Kbal Chrouy"
      },
      id: "12120601"
    },
    {
      name: {
        km: "តាព្រហ្ម",
        latin: "Ta Prum"
      },
      id: "12120602"
    },
    {
      name: {
        km: "មិត្ដភាព",
        latin: "Mitakpheap"
      },
      id: "12120603"
    },
    {
      name: {
        km: "ទួលតាចាន់",
        latin: "Tuol Ta Chan"
      },
      id: "12120604"
    },
    {
      name: {
        km: "ចុងព្រែក",
        latin: "Chong Preaek"
      },
      id: "12120605"
    },
    {
      name: {
        km: "របោះអង្កាញ់",
        latin: "Roboah Angkanh"
      },
      id: "12120606"
    },
    {
      name: {
        km: "មិត្ដភាព ១",
        latin: "Mitakpheap 1"
      },
      id: "12120607"
    },
    {
      name: {
        km: "ចុងព្រែក ១",
        latin: "Chong Preaek 1"
      },
      id: "12120608"
    },
    {
      name: {
        km: "របោះអង្កាញ់ ១",
        latin: "Roboah Angkanh 1"
      },
      id: "12120609"
    },
    {
      name: {
        km: "ជ្រោយអំពិល",
        latin: "Chrouy Ampil"
      },
      id: "12120701"
    },
    {
      name: {
        km: "យកបាត្រ",
        latin: "Yok Bat"
      },
      id: "12120702"
    },
    {
      name: {
        km: "ព្រែកធំ",
        latin: "Preaek Thum"
      },
      id: "12120703"
    },
    {
      name: {
        km: "ជ្រោយអំពិល ១",
        latin: "Chrouy Ampil 1"
      },
      id: "12120704"
    },
    {
      name: {
        km: "ជ្រោយអំពិល ២",
        latin: "Chrouy Ampil 2"
      },
      id: "12120705"
    },
    {
      name: {
        km: "យកបាត្រ ១",
        latin: "Yok Bat 1"
      },
      id: "12120706"
    },
    {
      name: {
        km: "ព្រែកធំ ១",
        latin: "Preaek Thum 1"
      },
      id: "12120707"
    },
    {
      name: {
        km: "ព្រែកធំ ២",
        latin: "Preaek Thum 2"
      },
      id: "12120708"
    },
    {
      name: {
        km: "ចំពុះក្អែក",
        latin: "Champuh K'aek"
      },
      id: "12120801"
    },
    {
      name: {
        km: "កោះក្របី",
        latin: "Kaoh Krabei"
      },
      id: "12120802"
    },
    {
      name: {
        km: "ព្រែកថ្មី",
        latin: "Preaek Thmei"
      },
      id: "12120803"
    },
    {
      name: {
        km: "ចំពុះក្អែក ១",
        latin: "Champuh K'aek 1"
      },
      id: "12120804"
    },
    {
      name: {
        km: "កោះក្របី ១",
        latin: "Kaoh Krabei 1"
      },
      id: "12120805"
    },
    {
      name: {
        km: "ព្រែកថ្មី ១",
        latin: "Preaek Thmei 1"
      },
      id: "12120806"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12130101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12130102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12130103"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12130104"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12130105"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12130106"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12130107"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12130108"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12130109"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12130201"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12130202"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12130203"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12130204"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12130205"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12130206"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12130207"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12130208"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12130209"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12130301"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12130302"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12130303"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12130304"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12130305"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12130306"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12130307"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12130308"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12130309"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12130401"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12130402"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12130403"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12130404"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12130405"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12130501"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12130502"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12130503"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12130504"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12130505"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12130601"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12130602"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12130603"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12130604"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12130605"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12130606"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12130607"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum 1"
      },
      id: "12130701"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum 2"
      },
      id: "12130702"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum 3"
      },
      id: "12130703"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum 4"
      },
      id: "12130704"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum 5"
      },
      id: "12130705"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum 6"
      },
      id: "12130706"
    },
    {
      name: {
        km: "ភូមិ ៧",
        latin: "Phum 7"
      },
      id: "12130707"
    },
    {
      name: {
        km: "ភូមិ ៨",
        latin: "Phum 8"
      },
      id: "12130708"
    },
    {
      name: {
        km: "ភូមិ ៩",
        latin: "Phum 9"
      },
      id: "12130709"
    },
    {
      name: {
        km: "ភូមិ ១០",
        latin: "Phum 10"
      },
      id: "12130710"
    },
    {
      name: {
        km: "ភូមិ ១១",
        latin: "Phum 11"
      },
      id: "12130711"
    },
    {
      name: {
        km: "ផ្សារកំបូល",
        latin: "Phsar Kamboul"
      },
      id: "12140101"
    },
    {
      name: {
        km: "តាកេរិ៍្ដ",
        latin: "Ta Ke"
      },
      id: "12140102"
    },
    {
      name: {
        km: "ត្រពាំងទួល",
        latin: "Trapeang Tuol"
      },
      id: "12140103"
    },
    {
      name: {
        km: "កំបូល",
        latin: "Kamboul"
      },
      id: "12140104"
    },
    {
      name: {
        km: "ទួលតាឡាត់",
        latin: "Tuol Ta Lat"
      },
      id: "12140105"
    },
    {
      name: {
        km: "តាភេម",
        latin: "Ta Phem"
      },
      id: "12140106"
    },
    {
      name: {
        km: "ព្រៃកុដិ",
        latin: "Prey Kod"
      },
      id: "12140107"
    },
    {
      name: {
        km: "តាសេក",
        latin: "Ta Sek"
      },
      id: "12140108"
    },
    {
      name: {
        km: "ទួលសាម៉",
        latin: "Tuol Sama"
      },
      id: "12140109"
    },
    {
      name: {
        km: "តានួន",
        latin: "Ta Nuon"
      },
      id: "12140110"
    },
    {
      name: {
        km: "កប់អំបិល",
        latin: "Kab Ambel"
      },
      id: "12140111"
    },
    {
      name: {
        km: "ស្នួលខ្ពស់",
        latin: "Snuol Khpos"
      },
      id: "12140112"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "12140113"
    },
    {
      name: {
        km: "ត្រិប",
        latin: "Treb"
      },
      id: "12140114"
    },
    {
      name: {
        km: "សាឡូង",
        latin: "Saloung"
      },
      id: "12140115"
    },
    {
      name: {
        km: "ជំទាវម៉ៅ",
        latin: "Chumteav Mau"
      },
      id: "12140116"
    },
    {
      name: {
        km: "អង្គបឹងចក",
        latin: "Angk Boeng Chak"
      },
      id: "12140117"
    },
    {
      name: {
        km: "លិចវត្ដ",
        latin: "Lech Voat"
      },
      id: "12140118"
    },
    {
      name: {
        km: "ថ្មដា",
        latin: "Thma Da"
      },
      id: "12140201"
    },
    {
      name: {
        km: "ត្រពាំងគល់",
        latin: "Trapeang Kol"
      },
      id: "12140202"
    },
    {
      name: {
        km: "ស្ងួនពេជ្រ",
        latin: "Snguon Pech"
      },
      id: "12140203"
    },
    {
      name: {
        km: "កន្ទោកត្បូង",
        latin: "Kantaok Tboung"
      },
      id: "12140204"
    },
    {
      name: {
        km: "គល់",
        latin: "Kol"
      },
      id: "12140205"
    },
    {
      name: {
        km: "អង្គត្រគៀត",
        latin: "Angk Trakiet"
      },
      id: "12140206"
    },
    {
      name: {
        km: "កន្ទោកជើង",
        latin: "Kantaok Cheung"
      },
      id: "12140207"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "12140208"
    },
    {
      name: {
        km: "ស្រែក្នុង",
        latin: "Srae Knong"
      },
      id: "12140209"
    },
    {
      name: {
        km: "ព្រៃបឹង",
        latin: "Prey Boeng"
      },
      id: "12140210"
    },
    {
      name: {
        km: "កំរៀង",
        latin: "Kamrieng"
      },
      id: "12140211"
    },
    {
      name: {
        km: "ដំបូកខ្ពស់",
        latin: "Dambouk Khpos"
      },
      id: "12140212"
    },
    {
      name: {
        km: "ត្រាំស្លឹក",
        latin: "Tram Sloek"
      },
      id: "12140213"
    },
    {
      name: {
        km: "ព្រៃតាប៉ុក",
        latin: "Prey Ta Pok"
      },
      id: "12140301"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "12140302"
    },
    {
      name: {
        km: "អង្គស្លែង",
        latin: "Angk Slaeng"
      },
      id: "12140303"
    },
    {
      name: {
        km: "ដូនរ័ត្ន",
        latin: "Doun Roatn"
      },
      id: "12140304"
    },
    {
      name: {
        km: "ត្រពាំងអារ័ក្ស",
        latin: "Trapeang Areaks"
      },
      id: "12140305"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "12140306"
    },
    {
      name: {
        km: "បឹងគ្រើល",
        latin: "Boeng Kreul"
      },
      id: "12140307"
    },
    {
      name: {
        km: "ជ័យជំនះ",
        latin: "Chey Chumneah"
      },
      id: "12140308"
    },
    {
      name: {
        km: "បឹងកំបោរ",
        latin: "Boeng Kambaor"
      },
      id: "12140309"
    },
    {
      name: {
        km: "ត្បែងមានជ័យ",
        latin: "Tbaeng Mean Chey"
      },
      id: "12140310"
    },
    {
      name: {
        km: "ត្រង់នំ",
        latin: "Trang Num"
      },
      id: "12140311"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "12140312"
    },
    {
      name: {
        km: "ថ្ម",
        latin: "Thma"
      },
      id: "12140313"
    },
    {
      name: {
        km: "សាមគ្គីប្រយុទ្ធ",
        latin: "Sameakki Prayutth"
      },
      id: "12140314"
    },
    {
      name: {
        km: "សាក់ប្រយុទ្ធ",
        latin: "Sak Prayutth"
      },
      id: "12140401"
    },
    {
      name: {
        km: "អង្គរជ័យ",
        latin: "Angkor Chey"
      },
      id: "12140402"
    },
    {
      name: {
        km: "ស្រែអំពិល",
        latin: "Srae Ampil"
      },
      id: "12140403"
    },
    {
      name: {
        km: "តាអិន",
        latin: "Ta En"
      },
      id: "12140404"
    },
    {
      name: {
        km: "កុលក្រស្នារ",
        latin: "Kol Krasna"
      },
      id: "12140405"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "12140406"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "12140407"
    },
    {
      name: {
        km: "ស្នោរកើត",
        latin: "Snaor Kaeut"
      },
      id: "12140408"
    },
    {
      name: {
        km: "តាពូង",
        latin: "Ta Pung"
      },
      id: "12140409"
    },
    {
      name: {
        km: "ផ្លាំង",
        latin: "Phlang"
      },
      id: "12140410"
    },
    {
      name: {
        km: "ស្នោរលិច",
        latin: "Snaor Lech"
      },
      id: "12140411"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "12140412"
    },
    {
      name: {
        km: "ព្រៃរឹង",
        latin: "Prey Rueng"
      },
      id: "12140413"
    },
    {
      name: {
        km: "រំដួលជើង",
        latin: "Rumduol Cheung"
      },
      id: "12140414"
    },
    {
      name: {
        km: "រំដួលត្បូង",
        latin: "Rumduol Tboung"
      },
      id: "12140415"
    },
    {
      name: {
        km: "ទួលលៀប",
        latin: "Tuol Lieb"
      },
      id: "12140416"
    },
    {
      name: {
        km: "ព្រៃក្រាយ",
        latin: "Prey Kray"
      },
      id: "12140417"
    },
    {
      name: {
        km: "ព្រៃឈូក",
        latin: "Prey Chhuk"
      },
      id: "12140418"
    },
    {
      name: {
        km: "ទួលកី",
        latin: "Tuol Kei"
      },
      id: "12140501"
    },
    {
      name: {
        km: "កប់ភ្លុក",
        latin: "Kab Phluk"
      },
      id: "12140502"
    },
    {
      name: {
        km: "ព្រៃរងៀង",
        latin: "Prey Rongieng"
      },
      id: "12140503"
    },
    {
      name: {
        km: "ត្រាញ់តាបាញ់",
        latin: "Tranh Tabanh"
      },
      id: "12140504"
    },
    {
      name: {
        km: "ភ្លើងឆេះរទេះលិច",
        latin: "Phleung Chheh Roteh Lech"
      },
      id: "12140505"
    },
    {
      name: {
        km: "ភ្លើងឆេះរទេះកើត",
        latin: "Phleung Chheh Roteh  Keut"
      },
      id: "12140506"
    },
    {
      name: {
        km: "គោកខ្សាច់",
        latin: "Kouk Khsach"
      },
      id: "12140507"
    },
    {
      name: {
        km: "ព្រៃកី",
        latin: "Prey Kei"
      },
      id: "12140508"
    },
    {
      name: {
        km: "ត្មាតពង",
        latin: "Tmat Pong"
      },
      id: "12140601"
    },
    {
      name: {
        km: "ព្រៃរមាសទី២",
        latin: "Prey Romeas Ti Pir"
      },
      id: "12140602"
    },
    {
      name: {
        km: "ព្រៃរមាសទី១",
        latin: "Prey Romeas Ti Muoy"
      },
      id: "12140603"
    },
    {
      name: {
        km: "តាជេត",
        latin: "Ta Chet"
      },
      id: "12140604"
    },
    {
      name: {
        km: "បឹងធំទី១",
        latin: "Boeng Thum Ti Muoy"
      },
      id: "12140605"
    },
    {
      name: {
        km: "បឹងធំទី២",
        latin: "Boeng Thum Ti Pir"
      },
      id: "12140606"
    },
    {
      name: {
        km: "បឹងធំទី៣",
        latin: "Boeng Thum Ti Bei"
      },
      id: "12140607"
    },
    {
      name: {
        km: "ដូនកុក",
        latin: "Doun Kok"
      },
      id: "12140608"
    },
    {
      name: {
        km: "ខ្ចៅ",
        latin: "Khchau"
      },
      id: "12140609"
    },
    {
      name: {
        km: "ក្ងោក",
        latin: "Kngaok"
      },
      id: "12140610"
    },
    {
      name: {
        km: "ទួលស្វាយ",
        latin: "Tuol Svay"
      },
      id: "12140611"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "12140612"
    },
    {
      name: {
        km: "ចំបក់ពណ្ណរាយ",
        latin: "Chambak Ponnoreay"
      },
      id: "12140613"
    },
    {
      name: {
        km: "ត្រពាំងត្រប់",
        latin: "Trapeang Trab"
      },
      id: "12140614"
    },
    {
      name: {
        km: "អន្លុងស្វាយ",
        latin: "Anlong Svay"
      },
      id: "12140615"
    },
    {
      name: {
        km: "ព្រៃតាកែ",
        latin: "Prey Ta Kae"
      },
      id: "12140616"
    },
    {
      name: {
        km: "ប្រទះឡាង",
        latin: "Prateah Lang"
      },
      id: "12140701"
    },
    {
      name: {
        km: "ភា",
        latin: "Phea"
      },
      id: "12140702"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "12140703"
    },
    {
      name: {
        km: "តាំងរនាម",
        latin: "Tang Roneam"
      },
      id: "12140704"
    },
    {
      name: {
        km: "គោកខ្សាច់",
        latin: "Kouk Khsach"
      },
      id: "12140705"
    },
    {
      name: {
        km: "គោកមាស",
        latin: "Kouk Meas"
      },
      id: "12140706"
    },
    {
      name: {
        km: "ស្អាង",
        latin: "S'ang"
      },
      id: "13010101"
    },
    {
      name: {
        km: "ទឹកលិច",
        latin: "Tuek Lich"
      },
      id: "13010102"
    },
    {
      name: {
        km: "គោក",
        latin: "Kouk"
      },
      id: "13010103"
    },
    {
      name: {
        km: "តស៊ូ",
        latin: "Tasu"
      },
      id: "13010201"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "13010202"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "13010203"
    },
    {
      name: {
        km: "ខ្យង",
        latin: "Khyang"
      },
      id: "13010301"
    },
    {
      name: {
        km: "ម៉ឺនរាជ",
        latin: "Meun Reach"
      },
      id: "13010302"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "13010303"
    },
    {
      name: {
        km: "បដិវត្ដន៍",
        latin: "Pakdevoat"
      },
      id: "13010401"
    },
    {
      name: {
        km: "ច្រាច់",
        latin: "Chrach"
      },
      id: "13010402"
    },
    {
      name: {
        km: "ចំរើន",
        latin: "Chamraeun"
      },
      id: "13010403"
    },
    {
      name: {
        km: "ផ្លោច",
        latin: "Phlaoch"
      },
      id: "13010404"
    },
    {
      name: {
        km: "ប្រមូលផ្ដុំ",
        latin: "Pramoul Phdom"
      },
      id: "13010405"
    },
    {
      name: {
        km: "ដំណាក់ត្រាច",
        latin: "Damnak Trach"
      },
      id: "13010406"
    },
    {
      name: {
        km: "ធ្មា",
        latin: "Thmea"
      },
      id: "13010501"
    },
    {
      name: {
        km: "ស្រែវាល",
        latin: "Srae Veal"
      },
      id: "13010502"
    },
    {
      name: {
        km: "ភ្ញាក់រលឹក",
        latin: "Phneak Roluek"
      },
      id: "13010503"
    },
    {
      name: {
        km: "កំពង់ពុទ្រា",
        latin: "Kampong Putrea"
      },
      id: "13010601"
    },
    {
      name: {
        km: "ពើក",
        latin: "Peuk"
      },
      id: "13010602"
    },
    {
      name: {
        km: "វាលបរ",
        latin: "Veal Bar"
      },
      id: "13010603"
    },
    {
      name: {
        km: "ឆែបលិច",
        latin: "Chhaeb Lech"
      },
      id: "13020101"
    },
    {
      name: {
        km: "ឆែបកើត",
        latin: "Chhaeb Kaeut"
      },
      id: "13020102"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "13020103"
    },
    {
      name: {
        km: "គុណភាពមួយ",
        latin: "Kunakpheap Muoy"
      },
      id: "13020201"
    },
    {
      name: {
        km: "គុណភាពពីរ",
        latin: "Kunakpheap Pir"
      },
      id: "13020202"
    },
    {
      name: {
        km: "ណារ៉ុង",
        latin: "Narong"
      },
      id: "13020203"
    },
    {
      name: {
        km: "ដងផ្លិត",
        latin: "Dang Phlet"
      },
      id: "13020204"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "13020301"
    },
    {
      name: {
        km: "សាអែម",
        latin: "Sra'aem"
      },
      id: "13020302"
    },
    {
      name: {
        km: "កោឡោត",
        latin: "Kalaot"
      },
      id: "13020401"
    },
    {
      name: {
        km: "សម្បូរ",
        latin: "Sambour"
      },
      id: "13020402"
    },
    {
      name: {
        km: "ជោគជ័យ",
        latin: "Chouk Chey"
      },
      id: "13020403"
    },
    {
      name: {
        km: "ម្លូព្រៃ",
        latin: "Mlu Prey"
      },
      id: "13020501"
    },
    {
      name: {
        km: "តុល",
        latin: "Tul"
      },
      id: "13020502"
    },
    {
      name: {
        km: "ពោធិទាប",
        latin: "Pou Teab"
      },
      id: "13020503"
    },
    {
      name: {
        km: "បុះ",
        latin: "Boh"
      },
      id: "13020601"
    },
    {
      name: {
        km: "ប្រើសក្អក",
        latin: "Praeus K'ak"
      },
      id: "13020602"
    },
    {
      name: {
        km: "កំពង់ស្រឡៅ",
        latin: "Kampong Sralau"
      },
      id: "13020701"
    },
    {
      name: {
        km: "កំពង់ពោធិ",
        latin: "Kampong Pou"
      },
      id: "13020702"
    },
    {
      name: {
        km: "កំពង់សង្កែ",
        latin: "Kampong Sangkae"
      },
      id: "13020703"
    },
    {
      name: {
        km: "ខំាកឺត",
        latin: "Kham  Keut"
      },
      id: "13020704"
    },
    {
      name: {
        km: "សួង",
        latin: "Suong"
      },
      id: "13020705"
    },
    {
      name: {
        km: "កំពង់ជ្រៃ",
        latin: "Kampong Chrey"
      },
      id: "13020801"
    },
    {
      name: {
        km: "កំពង់ក្រសាំង",
        latin: "Kampong Krasang"
      },
      id: "13020802"
    },
    {
      name: {
        km: "កំពង់សាមី",
        latin: "Kampong Sami"
      },
      id: "13020803"
    },
    {
      name: {
        km: "កំពង់ព្រះឥន្ទ",
        latin: "Kampong Preah Ent"
      },
      id: "13020804"
    },
    {
      name: {
        km: "ជាំក្សាន្ដ",
        latin: "Choam Ksant"
      },
      id: "13030101"
    },
    {
      name: {
        km: "គោកស្រឡៅ",
        latin: "Kouk Sralau"
      },
      id: "13030102"
    },
    {
      name: {
        km: "វាលពោធិ៍",
        latin: "Veal Pou"
      },
      id: "13030104"
    },
    {
      name: {
        km: "វាលធំ",
        latin: "Veal Thum"
      },
      id: "13030105"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែខាងកើត",
        latin: "Trapaeng Sangkae Khang Kaeut"
      },
      id: "13030106"
    },
    {
      name: {
        km: "ឈើទាលគង",
        latin: "Chheu Teal Kong"
      },
      id: "13030107"
    },
    {
      name: {
        km: "អានសេះ",
        latin: "An Ses"
      },
      id: "13030108"
    },
    {
      name: {
        km: "ទឹកក្រហម",
        latin: "Tuek Kraham"
      },
      id: "13030201"
    },
    {
      name: {
        km: "ចាត់តាំង",
        latin: "Chat Tang"
      },
      id: "13030202"
    },
    {
      name: {
        km: "សង្គមថ្មី",
        latin: "Sangkom Thmei"
      },
      id: "13030203"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "13030204"
    },
    {
      name: {
        km: "អូរក្សាន្ដ",
        latin: "Ou Khsan"
      },
      id: "13030205"
    },
    {
      name: {
        km: "សែនរុងរឿង១",
        latin: "Sen Rung Roeung 1"
      },
      id: "13030209"
    },
    {
      name: {
        km: "សែនរុងរឿង៥",
        latin: "Sen Rung Roeung 5"
      },
      id: "13030213"
    },
    {
      name: {
        km: "ក្រឡាពាស",
        latin: "Krala Peas"
      },
      id: "13030301"
    },
    {
      name: {
        km: "ត្មាតប៉ើយ",
        latin: "Tmat Paeuy"
      },
      id: "13030302"
    },
    {
      name: {
        km: "ស្រែ",
        latin: "Srae"
      },
      id: "13030401"
    },
    {
      name: {
        km: "គោក",
        latin: "Kouk"
      },
      id: "13030402"
    },
    {
      name: {
        km: "រលំថ្ម",
        latin: "Rolum Thma"
      },
      id: "13030403"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "13030404"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែខាងត្បូង",
        latin: "Tapeang Sangkae Khang Tboung"
      },
      id: "13030405"
    },
    {
      name: {
        km: "ពាក់ស្បែក",
        latin: "Peak Sbaek"
      },
      id: "13030406"
    },
    {
      name: {
        km: "ដំណាក់កណ្តោល",
        latin: "Damnak Kandol"
      },
      id: "13030407"
    },
    {
      name: {
        km: "យាង",
        latin: "Yeang"
      },
      id: "13030501"
    },
    {
      name: {
        km: "គង់យ៉ោង",
        latin: "Kong Yaong"
      },
      id: "13030502"
    },
    {
      name: {
        km: "រស្មី",
        latin: "Reaksmei"
      },
      id: "13030503"
    },
    {
      name: {
        km: "កំពាញ",
        latin: "Kampeanh"
      },
      id: "13030504"
    },
    {
      name: {
        km: "ជាំអន្ទឹល",
        latin: "Choam Antuel"
      },
      id: "13030505"
    },
    {
      name: {
        km: "ជាំស្រែ",
        latin: "Choam Srae"
      },
      id: "13030506"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "13030601"
    },
    {
      name: {
        km: "ស្រអែមខាងត្បូង",
        latin: "Sra aem Khang Tboung"
      },
      id: "13030602"
    },
    {
      name: {
        km: "អន្លង់វែង",
        latin: "Anlong Veaeng"
      },
      id: "13030603"
    },
    {
      name: {
        km: "ចារ្យ",
        latin: "Char"
      },
      id: "13030604"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែខាងលិច",
        latin: "Trapaeng Sangkae Khang Lech"
      },
      id: "13030612"
    },
    {
      name: {
        km: "មរកតតេជោ",
        latin: "Morokot Te Chour"
      },
      id: "13030613"
    },
    {
      name: {
        km: "សុខសែនជ័យ",
        latin: "Sok Sen Chey"
      },
      id: "13030614"
    },
    {
      name: {
        km: "ធម្មជាតិសម្ដេចតេជោ ហ៊ុន សែន",
        latin: "Thomacheat Samdach Te Chhor Hun Sen"
      },
      id: "13030701"
    },
    {
      name: {
        km: "ស្រអែមខាងជើង",
        latin: "Sra aem Khang Cheung"
      },
      id: "13030702"
    },
    {
      name: {
        km: "សែនជ័យ",
        latin: "Sen Chey"
      },
      id: "13030703"
    },
    {
      name: {
        km: "តេជោបុស្សស្បូវ",
        latin: "Te Chour Bosbov"
      },
      id: "13030704"
    },
    {
      name: {
        km: "ចំបក់សែនជ័យ",
        latin: "Chambok Sen Chey"
      },
      id: "13030705"
    },
    {
      name: {
        km: "ស្ទឹងខៀវតេជោ",
        latin: "Steung Kheav Te Chour"
      },
      id: "13030706"
    },
    {
      name: {
        km: "បង្គោល៨",
        latin: "Bongkol 8"
      },
      id: "13030707"
    },
    {
      name: {
        km: "របុញ",
        latin: "Robonh"
      },
      id: "13030801"
    },
    {
      name: {
        km: "សែនតេជៈ",
        latin: "Sen Techas"
      },
      id: "13030802"
    },
    {
      name: {
        km: "តេជោមរកត",
        latin: "Te Chour Morokot"
      },
      id: "13030803"
    },
    {
      name: {
        km: "សែនរុងរឿង២",
        latin: "Sen Rung Roeung 2"
      },
      id: "13030804"
    },
    {
      name: {
        km: "សែនរុងរឿង៣",
        latin: "Sen Rung Roeung 3"
      },
      id: "13030805"
    },
    {
      name: {
        km: "សែនរុងរឿង៤",
        latin: "Sen Rung Roeung 4"
      },
      id: "13030806"
    },
    {
      name: {
        km: "គូលែនត្បូង",
        latin: "Kuleaen Tboung"
      },
      id: "13040101"
    },
    {
      name: {
        km: "ក្របៅ",
        latin: "Krabau"
      },
      id: "13040102"
    },
    {
      name: {
        km: "គូលែនជើង",
        latin: "Kuleaen Cheung"
      },
      id: "13040201"
    },
    {
      name: {
        km: "ព្យួរជ្រូក",
        latin: "Pyuor Chruk"
      },
      id: "13040202"
    },
    {
      name: {
        km: "ថ្នល់បែក",
        latin: "Thnal Baek"
      },
      id: "13040301"
    },
    {
      name: {
        km: "ត្រាវគៀត",
        latin: "Trav Kiet"
      },
      id: "13040302"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "13040303"
    },
    {
      name: {
        km: "ដាន",
        latin: "Dan"
      },
      id: "13040304"
    },
    {
      name: {
        km: "ដំណាក់កន្ទួត",
        latin: "Damnak Kantuot"
      },
      id: "13040305"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "13040401"
    },
    {
      name: {
        km: "បុះ",
        latin: "Bos"
      },
      id: "13040402"
    },
    {
      name: {
        km: "ស្របាល",
        latin: "Srabal"
      },
      id: "13040403"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "13040501"
    },
    {
      name: {
        km: "ស្រឡៃ",
        latin: "Sralay"
      },
      id: "13040502"
    },
    {
      name: {
        km: "បរិបូណ៌",
        latin: "Baribour"
      },
      id: "13040503"
    },
    {
      name: {
        km: "ក្ដាក់",
        latin: "Kdak"
      },
      id: "13040504"
    },
    {
      name: {
        km: "ស្រយង់ជើង",
        latin: "Srayang Cheung"
      },
      id: "13040601"
    },
    {
      name: {
        km: "ស្រយង់ត្បូង",
        latin: "Srayang Tboung"
      },
      id: "13040602"
    },
    {
      name: {
        km: "កោះកេរ",
        latin: "Kaoh Ker"
      },
      id: "13040603"
    },
    {
      name: {
        km: "ម្រេច",
        latin: "Mrech"
      },
      id: "13040604"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "13040605"
    },
    {
      name: {
        km: "សម្បូរ",
        latin: "Sambour"
      },
      id: "13040606"
    },
    {
      name: {
        km: "ស្នាផ្អែក",
        latin: "Sna Pa'ek"
      },
      id: "13040607"
    },
    {
      name: {
        km: "រវៀងជើង",
        latin: "Rovieng Cheung"
      },
      id: "13050101"
    },
    {
      name: {
        km: "ត្នោតម្លូ",
        latin: "Tnaot Mlu"
      },
      id: "13050102"
    },
    {
      name: {
        km: "តាំងត្រក",
        latin: "Tang Trak"
      },
      id: "13050103"
    },
    {
      name: {
        km: "បាក់ក្ដោង",
        latin: "Bak Kdaong"
      },
      id: "13050104"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "13050105"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "13050106"
    },
    {
      name: {
        km: "ដំណាក់ចិន",
        latin: "Damnak Chen"
      },
      id: "13050201"
    },
    {
      name: {
        km: "តាតុង",
        latin: "Ta Tong"
      },
      id: "13050202"
    },
    {
      name: {
        km: "ចំបក់ផ្អែម",
        latin: "Chambak Ph'aem"
      },
      id: "13050203"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "13050204"
    },
    {
      name: {
        km: "ថ្កែង",
        latin: "Thkaeng"
      },
      id: "13050301"
    },
    {
      name: {
        km: "កំពត",
        latin: "Kampot"
      },
      id: "13050302"
    },
    {
      name: {
        km: "សង្កែរូង",
        latin: "Sangkae Rung"
      },
      id: "13050303"
    },
    {
      name: {
        km: "កក់ពោន",
        latin: "Kak Poun"
      },
      id: "13050304"
    },
    {
      name: {
        km: "ចំឡង",
        latin: "Chamlang"
      },
      id: "13050305"
    },
    {
      name: {
        km: "អន្លង់ស្វាយ",
        latin: "Anlong Svay"
      },
      id: "13050306"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "13050401"
    },
    {
      name: {
        km: "រវៀងត្បូង",
        latin: "Rovieng Tboung"
      },
      id: "13050402"
    },
    {
      name: {
        km: "បុះពៃ",
        latin: "Boh Pey"
      },
      id: "13050403"
    },
    {
      name: {
        km: "ស្រែធំ",
        latin: "Srae Thum"
      },
      id: "13050404"
    },
    {
      name: {
        km: "ប៉ាលហាល",
        latin: "Pal Hal"
      },
      id: "13050501"
    },
    {
      name: {
        km: "បុះ",
        latin: "Boh"
      },
      id: "13050502"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "13050503"
    },
    {
      name: {
        km: "ឈ្នួន",
        latin: "Chhnuon"
      },
      id: "13050601"
    },
    {
      name: {
        km: "ទន្លាប់",
        latin: "Tonloab"
      },
      id: "13050602"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "13050603"
    },
    {
      name: {
        km: "កេរ្ដ",
        latin: "Ker"
      },
      id: "13050701"
    },
    {
      name: {
        km: "ខ្នា",
        latin: "Khnar"
      },
      id: "13050702"
    },
    {
      name: {
        km: "សំរិទ្ធិ",
        latin: "Samreth"
      },
      id: "13050703"
    },
    {
      name: {
        km: "សំព្រៀង",
        latin: "Samprieng"
      },
      id: "13050704"
    },
    {
      name: {
        km: "ព្រៃស្នួល",
        latin: "Prey Snuol"
      },
      id: "13050705"
    },
    {
      name: {
        km: "ស្លែងទោល",
        latin: "Slaeng Toul"
      },
      id: "13050801"
    },
    {
      name: {
        km: "ស្រែ",
        latin: "Srae"
      },
      id: "13050802"
    },
    {
      name: {
        km: "បង្កន",
        latin: "Bangkan"
      },
      id: "13050803"
    },
    {
      name: {
        km: "ដូនម៉ា",
        latin: "Doun Ma"
      },
      id: "13050901"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "13050902"
    },
    {
      name: {
        km: "ព្រាល",
        latin: "Preal"
      },
      id: "13050903"
    },
    {
      name: {
        km: "កក់ពោន",
        latin: "Kak Poun"
      },
      id: "13050904"
    },
    {
      name: {
        km: "រំដោះ",
        latin: "Rumdaoh"
      },
      id: "13050905"
    },
    {
      name: {
        km: "សន្លុងជ័យ",
        latin: "Sanlung Chey"
      },
      id: "13050906"
    },
    {
      name: {
        km: "ពលកម្ម",
        latin: "Poleakkam"
      },
      id: "13050907"
    },
    {
      name: {
        km: "ស្រឡៅស្រោង",
        latin: "Sralau Sraong"
      },
      id: "13050908"
    },
    {
      name: {
        km: "ថ្នល់កោង",
        latin: "Thnal Kaong"
      },
      id: "13051001"
    },
    {
      name: {
        km: "ស្វាយប៉ាត",
        latin: "Svay Pat"
      },
      id: "13051002"
    },
    {
      name: {
        km: "គោកអំពិល",
        latin: "Kouk Ampil"
      },
      id: "13051003"
    },
    {
      name: {
        km: "ឪឡឹក",
        latin: "Ovloek"
      },
      id: "13051004"
    },
    {
      name: {
        km: "ត្រពាំងទទឹម",
        latin: "Trapeang Totuem"
      },
      id: "13051101"
    },
    {
      name: {
        km: "អូរទ្រលោក",
        latin: "Ou Talaok"
      },
      id: "13051102"
    },
    {
      name: {
        km: "ទួលរវៀង",
        latin: "Tuol Rovieng"
      },
      id: "13051103"
    },
    {
      name: {
        km: "បង្កើនផល",
        latin: "Bangkaeun Phal"
      },
      id: "13051104"
    },
    {
      name: {
        km: "ស្វាយដំណាក់ចាស់",
        latin: "Svay Damnak Chas"
      },
      id: "13051105"
    },
    {
      name: {
        km: "ស្វាយដំណាក់ថ្មី",
        latin: "Svay Damnak Thmei"
      },
      id: "13051106"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "13051201"
    },
    {
      name: {
        km: "អូរពោរ",
        latin: "Ou Pour"
      },
      id: "13051202"
    },
    {
      name: {
        km: "ជីឱក",
        latin: "Chi Aok"
      },
      id: "13051203"
    },
    {
      name: {
        km: "ភ្នំដែក",
        latin: "Phnum Daek"
      },
      id: "13051204"
    },
    {
      name: {
        km: "ស្រែធ្នង់",
        latin: "Srae Thnong"
      },
      id: "13051205"
    },
    {
      name: {
        km: "ប្រធាតុ",
        latin: "Pratheat"
      },
      id: "13060101"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "13060102"
    },
    {
      name: {
        km: "ដា",
        latin: "Da"
      },
      id: "13060103"
    },
    {
      name: {
        km: "សែនគង់",
        latin: "Saen Kong"
      },
      id: "13060104"
    },
    {
      name: {
        km: "ស្រីស្រណោះ",
        latin: "Srei Sranaoh"
      },
      id: "13060105"
    },
    {
      name: {
        km: "អណ្ដូងភ្លូ",
        latin: "Andoung Phlu"
      },
      id: "13060201"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "13060202"
    },
    {
      name: {
        km: "ស្ទឺង",
        latin: "Stueng"
      },
      id: "13060203"
    },
    {
      name: {
        km: "ក្ដី",
        latin: "Kdei"
      },
      id: "13060204"
    },
    {
      name: {
        km: "បឺង",
        latin: "Boeng"
      },
      id: "13060205"
    },
    {
      name: {
        km: "ខ្នុរ",
        latin: "Knor"
      },
      id: "13060206"
    },
    {
      name: {
        km: "សំឡាញ",
        latin: "Samlanh"
      },
      id: "13060301"
    },
    {
      name: {
        km: "ក្បាលខ្លា",
        latin: "Kbal Khla"
      },
      id: "13060302"
    },
    {
      name: {
        km: "តាបស",
        latin: "Ta Bas"
      },
      id: "13060401"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "13060402"
    },
    {
      name: {
        km: "សុច",
        latin: "Soch"
      },
      id: "13060403"
    },
    {
      name: {
        km: "ត្រពាំងធ្លក",
        latin: "Trapeang Thlok"
      },
      id: "13060404"
    },
    {
      name: {
        km: "ត្រពាំងខ្ចែង",
        latin: "Trapeang  Khchaeng"
      },
      id: "13060405"
    },
    {
      name: {
        km: "តាសែងកណ្ដាល",
        latin: "Ta Saeng Kandal"
      },
      id: "13060501"
    },
    {
      name: {
        km: "តាសែងជើង",
        latin: "Ta Saeng Khang Cheung"
      },
      id: "13060502"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "13060503"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "13060504"
    },
    {
      name: {
        km: "គោកថ្កូវ",
        latin: "koukThkov"
      },
      id: "13060505"
    },
    {
      name: {
        km: "រលំស្លែង",
        latin: "Rolum Slaeng"
      },
      id: "13060506"
    },
    {
      name: {
        km: "បាក់កាំ",
        latin: "Bak Kam"
      },
      id: "13070301"
    },
    {
      name: {
        km: "សេដ្ឋកិច្ច",
        latin: "Sedthakkech"
      },
      id: "13070302"
    },
    {
      name: {
        km: "មហាផល",
        latin: "Moha Phal"
      },
      id: "13070303"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "13070401"
    },
    {
      name: {
        km: "ស្រឡៅទូង",
        latin: "Sralau Tung"
      },
      id: "13070402"
    },
    {
      name: {
        km: "ពោធិ៍ខឿន",
        latin: "Pou Khoean"
      },
      id: "13070403"
    },
    {
      name: {
        km: "អូរកក់",
        latin: "Ou Kak"
      },
      id: "13070404"
    },
    {
      name: {
        km: "បុស្សធំ",
        latin: "Bos Thum"
      },
      id: "13070501"
    },
    {
      name: {
        km: "ប្រមេរុ",
        latin: "Prame"
      },
      id: "13070502"
    },
    {
      name: {
        km: "ស្រែព្រាង",
        latin: "Srae Preang"
      },
      id: "13070503"
    },
    {
      name: {
        km: "អន្លង់ស្វាយ",
        latin: "Anlong Svay"
      },
      id: "13070601"
    },
    {
      name: {
        km: "ក្រាំងដូង",
        latin: "Krang Doung"
      },
      id: "13070602"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "13080101"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "13080102"
    },
    {
      name: {
        km: "គោកបេង",
        latin: "Kouk Beng"
      },
      id: "13080103"
    },
    {
      name: {
        km: "អណ្ដូងពោធិ",
        latin: "Andoung Pou"
      },
      id: "13080104"
    },
    {
      name: {
        km: "ឡឥដ្ឋ",
        latin: "La Edth"
      },
      id: "13080105"
    },
    {
      name: {
        km: "ដំណាក់",
        latin: "Damnak"
      },
      id: "13080106"
    },
    {
      name: {
        km: "ស្រះឈូក",
        latin: "Srah Chhuk"
      },
      id: "13080107"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "13080108"
    },
    {
      name: {
        km: "កំពង់ចម្លង",
        latin: "Kampong Chamlang"
      },
      id: "13080109"
    },
    {
      name: {
        km: "ទួលទំនប់",
        latin: "Tuol Tumnob"
      },
      id: "13080110"
    },
    {
      name: {
        km: "ហ្មសែត",
        latin: "Ma Saet"
      },
      id: "13080201"
    },
    {
      name: {
        km: "អូរខ្លែងពណ៌",
        latin: "Ou Khlaeng Poar"
      },
      id: "13080202"
    },
    {
      name: {
        km: "ទំនប់",
        latin: "Tumnob"
      },
      id: "13080203"
    },
    {
      name: {
        km: "ប៉ាលហាល",
        latin: "Pal Hal"
      },
      id: "13080204"
    },
    {
      name: {
        km: "ភារកិច្ច",
        latin: "Peareakkech"
      },
      id: "13080205"
    },
    {
      name: {
        km: "ស្ថាពរ",
        latin: "Sthapor"
      },
      id: "13080206"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "13080207"
    },
    {
      name: {
        km: "ឯកភាព",
        latin: "Aekakpheap"
      },
      id: "13080208"
    },
    {
      name: {
        km: "អភិវឌ្ឍន៍",
        latin: "Akphivoat"
      },
      id: "13080209"
    },
    {
      name: {
        km: "ចំការស្រម៉ូវ",
        latin: "Chamkar Sramov"
      },
      id: "13080210"
    },
    {
      name: {
        km: "អង្រ្កង",
        latin: "Angkrong"
      },
      id: "14010101"
    },
    {
      name: {
        km: "ឫស្សីក្អែក",
        latin: "Ruessei K'aek"
      },
      id: "14010102"
    },
    {
      name: {
        km: "ស្វាយតានី",
        latin: "Svay Ta Ni"
      },
      id: "14010103"
    },
    {
      name: {
        km: "ប្រស្រែ",
        latin: "Prasrae"
      },
      id: "14010104"
    },
    {
      name: {
        km: "តាម៉ា",
        latin: "Ta Ma"
      },
      id: "14010105"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "14010106"
    },
    {
      name: {
        km: "ល្ពាច",
        latin: "Lpeach"
      },
      id: "14010107"
    },
    {
      name: {
        km: "ដីថុយ",
        latin: "Dei Thoy"
      },
      id: "14010108"
    },
    {
      name: {
        km: "ឫស្សីជួរ",
        latin: "Ruessei Chuor"
      },
      id: "14010109"
    },
    {
      name: {
        km: "តាជៃ",
        latin: "Ta Chey"
      },
      id: "14010110"
    },
    {
      name: {
        km: "តាម៉ៅ",
        latin: "Ta Mau"
      },
      id: "14010111"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "14010112"
    },
    {
      name: {
        km: "បឹងព្រះ",
        latin: "Boeng Preah"
      },
      id: "14010113"
    },
    {
      name: {
        km: "ស្នែរាន",
        latin: "Snae Rean"
      },
      id: "14010114"
    },
    {
      name: {
        km: "មាត់ព្រៃ",
        latin: "Moat Prey"
      },
      id: "14010201"
    },
    {
      name: {
        km: "ពោធិ៍អណ្ដោត",
        latin: "Pou Andaot"
      },
      id: "14010202"
    },
    {
      name: {
        km: "ផ្លូវរលួស",
        latin: "Phlov Roluos"
      },
      id: "14010203"
    },
    {
      name: {
        km: "បឹងរកា",
        latin: "Boeng Roka"
      },
      id: "14010204"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "14010205"
    },
    {
      name: {
        km: "ស្វាយសាមសិប",
        latin: "Svay Samseb"
      },
      id: "14010206"
    },
    {
      name: {
        km: "រោងដំរី",
        latin: "Roung Damrei"
      },
      id: "14010207"
    },
    {
      name: {
        km: "ឈើកាច់",
        latin: "Chheu Kach"
      },
      id: "14010301"
    },
    {
      name: {
        km: "កំហែងរាជ្យ",
        latin: "Kamhaeng Reach"
      },
      id: "14010302"
    },
    {
      name: {
        km: "ក្រោលគោ",
        latin: "Kraol Kou"
      },
      id: "14010303"
    },
    {
      name: {
        km: "ព្រៃក្ដួច",
        latin: "Prey Kduoch"
      },
      id: "14010304"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "14010305"
    },
    {
      name: {
        km: "ទួលខ្ពស់",
        latin: "Tuol Khpos"
      },
      id: "14010306"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "14010307"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "14010308"
    },
    {
      name: {
        km: "អង្កាញ់",
        latin: "Angkanh"
      },
      id: "14010309"
    },
    {
      name: {
        km: "ត្រពាំងសាលា",
        latin: "Trapeang Sala"
      },
      id: "14010310"
    },
    {
      name: {
        km: "ឃ្លាំង",
        latin: "Khleang"
      },
      id: "14010311"
    },
    {
      name: {
        km: "ស្វាយប្រក្រាល",
        latin: "Svay Prakal"
      },
      id: "14010312"
    },
    {
      name: {
        km: "បឹងប្រស្រែ",
        latin: "Boeng Prasrae"
      },
      id: "14010313"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "14010314"
    },
    {
      name: {
        km: "គោកសណ្ដែក",
        latin: "Kouk Sandaek"
      },
      id: "14010401"
    },
    {
      name: {
        km: "ខ្សាច់ស",
        latin: "Khsach Sa"
      },
      id: "14010402"
    },
    {
      name: {
        km: "ត្រើយងរ៍",
        latin: "Traeuy Ngor"
      },
      id: "14010403"
    },
    {
      name: {
        km: "ថ្មទ្រាំង",
        latin: "Thma Treang"
      },
      id: "14010404"
    },
    {
      name: {
        km: "ថ្មីក្រៅ",
        latin: "Thmei Krau"
      },
      id: "14010405"
    },
    {
      name: {
        km: "តាពេជខាងជើង",
        latin: "Ta Pech Khang Cheung"
      },
      id: "14010406"
    },
    {
      name: {
        km: "តាពេជខាងត្បូង",
        latin: "Ta Pech Khang Tboung"
      },
      id: "14010407"
    },
    {
      name: {
        km: "រក្សជ័យ",
        latin: "Reaks Chey"
      },
      id: "14010408"
    },
    {
      name: {
        km: "តាអ៊ួក",
        latin: "Ta Uok"
      },
      id: "14010409"
    },
    {
      name: {
        km: "ជ្រួលធំ",
        latin: "Chruol Thum"
      },
      id: "14010410"
    },
    {
      name: {
        km: "ជ្រួលថ្មី",
        latin: "Chruol Thmei"
      },
      id: "14010411"
    },
    {
      name: {
        km: "ឈើត្រែង",
        latin: "Chheu Traeng"
      },
      id: "14010412"
    },
    {
      name: {
        km: "ក្ដីដូង",
        latin: "Kdei Doung"
      },
      id: "14010501"
    },
    {
      name: {
        km: "ពងពស់",
        latin: "Pong Pos"
      },
      id: "14010502"
    },
    {
      name: {
        km: "ទីតាង៉ើយ",
        latin: "Ti Ta Ngaeuy"
      },
      id: "14010503"
    },
    {
      name: {
        km: "ស្វាយខ្នី",
        latin: "Svay Khnei"
      },
      id: "14010504"
    },
    {
      name: {
        km: "ព្រៃភ្ងាម",
        latin: "Prey Phngeam"
      },
      id: "14010505"
    },
    {
      name: {
        km: "ជីរោង",
        latin: "Chi Roung"
      },
      id: "14010506"
    },
    {
      name: {
        km: "រវាំងជុំ",
        latin: "Roveang Chum"
      },
      id: "14010507"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "14010508"
    },
    {
      name: {
        km: "វល្លិយាវ",
        latin: "Voa Yeav"
      },
      id: "14010509"
    },
    {
      name: {
        km: "ត្នោតស្វាយ",
        latin: "Tnaot Svay"
      },
      id: "14010510"
    },
    {
      name: {
        km: "កន្ទ្រាន",
        latin: "Kantrean"
      },
      id: "14010511"
    },
    {
      name: {
        km: "ជើងទឹក",
        latin: "Cheung Tuek"
      },
      id: "14010512"
    },
    {
      name: {
        km: "តាគោក",
        latin: "Ta Kouk"
      },
      id: "14010601"
    },
    {
      name: {
        km: "ត្រពាំងសិការ",
        latin: "Trapeang Sekar"
      },
      id: "14010602"
    },
    {
      name: {
        km: "សៀម",
        latin: "Siem"
      },
      id: "14010603"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14010604"
    },
    {
      name: {
        km: "ក្រាំងចិន",
        latin: "Krang Chen"
      },
      id: "14010605"
    },
    {
      name: {
        km: "សិម្ពលី",
        latin: "Sempoli"
      },
      id: "14010606"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "14010607"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "14010608"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "14010609"
    },
    {
      name: {
        km: "ដូនមា",
        latin: "Doun Mea"
      },
      id: "14010610"
    },
    {
      name: {
        km: "ច្រកស្វាយ",
        latin: "Chrak Svay"
      },
      id: "14010611"
    },
    {
      name: {
        km: "ព្រៃកន្ទ្រង់",
        latin: "Prey Kantrong"
      },
      id: "14010612"
    },
    {
      name: {
        km: "បឹងត្របរ",
        latin: "Boeng Trabar"
      },
      id: "14010613"
    },
    {
      name: {
        km: "ទង់នាគ",
        latin: "Tong Neak"
      },
      id: "14010614"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "14010615"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "14010701"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "14010702"
    },
    {
      name: {
        km: "ស្វាយពក",
        latin: "Svay Pok"
      },
      id: "14010703"
    },
    {
      name: {
        km: "កាឡីខាងជើង",
        latin: "Kalei Khang Cheung"
      },
      id: "14010704"
    },
    {
      name: {
        km: "កាឡីខាងត្បូង",
        latin: "Kalei Khang Tboung"
      },
      id: "14010705"
    },
    {
      name: {
        km: "តាឡោន",
        latin: "Ta Laon"
      },
      id: "14010706"
    },
    {
      name: {
        km: "ព្រេច",
        latin: "Prech"
      },
      id: "14010707"
    },
    {
      name: {
        km: "ស្ដៅកោង",
        latin: "Sdau Kaong"
      },
      id: "14010708"
    },
    {
      name: {
        km: "ស្ពឺខាងកើត",
        latin: "Spueu Khang Kaeut"
      },
      id: "14010709"
    },
    {
      name: {
        km: "ស្ពឺខាងលិច",
        latin: "Spueu Khang Lech"
      },
      id: "14010710"
    },
    {
      name: {
        km: "ព្រៃចំការ",
        latin: "Prey Chamkar"
      },
      id: "14010801"
    },
    {
      name: {
        km: "ចាន់រ៉ា   ក",
        latin: "Chan Ra Ka"
      },
      id: "14010802"
    },
    {
      name: {
        km: "ចាន់រ៉ា   ខ",
        latin: "Chan Ra Kha"
      },
      id: "14010803"
    },
    {
      name: {
        km: "ព្រៃប្រង់",
        latin: "Prey Prang"
      },
      id: "14010804"
    },
    {
      name: {
        km: "ព្រៃស្វា",
        latin: "Prey Sva"
      },
      id: "14010805"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "14010806"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "14010901"
    },
    {
      name: {
        km: "ពោធិ៍ភ្លុក",
        latin: "Pou Phluk"
      },
      id: "14010902"
    },
    {
      name: {
        km: "កំរែង",
        latin: "Kamraeng"
      },
      id: "14010903"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "14010904"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "14010905"
    },
    {
      name: {
        km: "ព្រៃផ្ចិត",
        latin: "Prey Phchet"
      },
      id: "14010906"
    },
    {
      name: {
        km: "តាសូ",
        latin: "Ta Sou"
      },
      id: "14010907"
    },
    {
      name: {
        km: "អង្កាល់",
        latin: "Angkal"
      },
      id: "14010908"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "14010909"
    },
    {
      name: {
        km: "កំពង់ស្នែ",
        latin: "Kampong Snae"
      },
      id: "14010910"
    },
    {
      name: {
        km: "កំពង់ស្លែង",
        latin: "Kampong Slaeng"
      },
      id: "14010911"
    },
    {
      name: {
        km: "ធាយ",
        latin: "Theay"
      },
      id: "14010912"
    },
    {
      name: {
        km: "ទ័ពស្ដេច",
        latin: "Toap Sdach"
      },
      id: "14010913"
    },
    {
      name: {
        km: "ខ្វិត",
        latin: "Khvet"
      },
      id: "14010914"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "14010915"
    },
    {
      name: {
        km: "ប្រញ៉ង",
        latin: "Pranhang"
      },
      id: "14010916"
    },
    {
      name: {
        km: "ស្វាយកាប",
        latin: "Svay Kab"
      },
      id: "14010917"
    },
    {
      name: {
        km: "ទីលេង",
        latin: "Ti Leng"
      },
      id: "14010918"
    },
    {
      name: {
        km: "ទួលសង្កែទទឹង",
        latin: "Tuol Sangkae Totueng"
      },
      id: "14020101"
    },
    {
      name: {
        km: "ទួលសង្កែបណ្ដោយ",
        latin: "Tuol Sangkae Bandaoy"
      },
      id: "14020102"
    },
    {
      name: {
        km: "ត្រពាំងរមាស",
        latin: "Trapeang Romeas"
      },
      id: "14020103"
    },
    {
      name: {
        km: "រហាល",
        latin: "Rohal"
      },
      id: "14020104"
    },
    {
      name: {
        km: "កោះខ្ជាយ",
        latin: "Kaoh Khcheay"
      },
      id: "14020105"
    },
    {
      name: {
        km: "ដូនដោក",
        latin: "Doun Daok"
      },
      id: "14020106"
    },
    {
      name: {
        km: "ស្វាយត្រៃ",
        latin: "Svay Trey"
      },
      id: "14020107"
    },
    {
      name: {
        km: "ជួរថ្នល់",
        latin: "Chuor Thnal"
      },
      id: "14020108"
    },
    {
      name: {
        km: "កោះតារ័ត្ន",
        latin: "Kaoh Ta Roatn"
      },
      id: "14020109"
    },
    {
      name: {
        km: "ជាចជួរ",
        latin: "Cheach Chuor"
      },
      id: "14020110"
    },
    {
      name: {
        km: "ជាចខាងលិច",
        latin: "Cheach Khang Lech"
      },
      id: "14020111"
    },
    {
      name: {
        km: "ជាចខាងជើង",
        latin: "Cheach Khang Cheung"
      },
      id: "14020112"
    },
    {
      name: {
        km: "ជាចកណ្ដាល",
        latin: "Cheach Kandal"
      },
      id: "14020113"
    },
    {
      name: {
        km: "ជាចខាងត្បូង",
        latin: "Cheach Khang Tboung"
      },
      id: "14020114"
    },
    {
      name: {
        km: "ពោនខាងលិច",
        latin: "Poun Khang Lech"
      },
      id: "14020115"
    },
    {
      name: {
        km: "ពោនខាងកើត",
        latin: "Poun Khang Kaeut"
      },
      id: "14020116"
    },
    {
      name: {
        km: "លាក់គោ",
        latin: "Leak Kou"
      },
      id: "14020117"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "14020118"
    },
    {
      name: {
        km: "ពោនខាងជើង",
        latin: "Poun Khang Cheung"
      },
      id: "14020119"
    },
    {
      name: {
        km: "ត្រាចជ្រុំ",
        latin: "Trach Chrum"
      },
      id: "14020120"
    },
    {
      name: {
        km: "ទួលសង្កែខាងកើត",
        latin: "Tuol Sangkae Khang Kaeut"
      },
      id: "14020121"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "14020122"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "14020123"
    },
    {
      name: {
        km: "ត្រពាំងធ្លក",
        latin: "Trapeang Thlok"
      },
      id: "14020124"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "14020125"
    },
    {
      name: {
        km: "ទួលអង្គ្រង",
        latin: "Tuol Angkrong"
      },
      id: "14020126"
    },
    {
      name: {
        km: "ទន្សាយជល់ហិប",
        latin: "Tonsay Chol Heb"
      },
      id: "14020201"
    },
    {
      name: {
        km: "ដូនកឹង",
        latin: "Doun Koeng"
      },
      id: "14020202"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "14020203"
    },
    {
      name: {
        km: "ពោធិ៍ពីរ",
        latin: "Pou Pir"
      },
      id: "14020204"
    },
    {
      name: {
        km: "បឹងកក់",
        latin: "Boeng Kak"
      },
      id: "14020205"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14020206"
    },
    {
      name: {
        km: "ជួរផ្អាវ",
        latin: "Chuor Ph'av"
      },
      id: "14020207"
    },
    {
      name: {
        km: "ត្រពាំងដង្ហិត",
        latin: "Trapeang Danghet"
      },
      id: "14020208"
    },
    {
      name: {
        km: "ស្វាយសុខុម",
        latin: "Svay Sokhom"
      },
      id: "14020209"
    },
    {
      name: {
        km: "តាដុក",
        latin: "Ta Dok"
      },
      id: "14020210"
    },
    {
      name: {
        km: "ជោរទី ១",
        latin: "Chour Ti Muoy"
      },
      id: "14020301"
    },
    {
      name: {
        km: "ជោរទី ២",
        latin: "Chour Ti Pir"
      },
      id: "14020302"
    },
    {
      name: {
        km: "ជោរទី ៣",
        latin: "Chour Ti Bei"
      },
      id: "14020303"
    },
    {
      name: {
        km: "តាសៅ",
        latin: "Ta Sau"
      },
      id: "14020304"
    },
    {
      name: {
        km: "ក្រវ៉ាន់",
        latin: "Kravan"
      },
      id: "14020305"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "14020306"
    },
    {
      name: {
        km: "ខ្លុងកោង",
        latin: "Khlong Kaong"
      },
      id: "14020307"
    },
    {
      name: {
        km: "ចំការចេកទី ២",
        latin: "Chamkar Chek Ti Pir"
      },
      id: "14020308"
    },
    {
      name: {
        km: "ចំការចេកទី ១",
        latin: "Chamkar Chek Ti Muoy"
      },
      id: "14020309"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "14020310"
    },
    {
      name: {
        km: "អង្គ្រងទី ១",
        latin: "Angkrong Ti Muoy"
      },
      id: "14020311"
    },
    {
      name: {
        km: "អង្គ្រងទី ២",
        latin: "Angkrong Ti Pir"
      },
      id: "14020312"
    },
    {
      name: {
        km: "កំចាយមារខាងត្បូង",
        latin: "Kamchay Mear Khang Tboung"
      },
      id: "14020313"
    },
    {
      name: {
        km: "កំចាយមារខាងជើង",
        latin: "Kamchay Mear Khang Cheung"
      },
      id: "14020314"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "14020315"
    },
    {
      name: {
        km: "ឡឥដ្ឋ",
        latin: "La Edth"
      },
      id: "14020316"
    },
    {
      name: {
        km: "ថ្លុកកណ្តាល",
        latin: "Thlok Kandal"
      },
      id: "14020317"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "14020318"
    },
    {
      name: {
        km: "បឹងព្រោះ",
        latin: "Boeng Pruoh"
      },
      id: "14020319"
    },
    {
      name: {
        km: "តាអី",
        latin: "Ta Ei"
      },
      id: "14020401"
    },
    {
      name: {
        km: "ព្រៃត្រេស",
        latin: "Prey Tres"
      },
      id: "14020402"
    },
    {
      name: {
        km: "ពពេល",
        latin: "Popel"
      },
      id: "14020403"
    },
    {
      name: {
        km: "ក្របៅជួរ",
        latin: "Krabau Chuor"
      },
      id: "14020404"
    },
    {
      name: {
        km: "អណ្ដូងស្អាត",
        latin: "Andoung S'at"
      },
      id: "14020405"
    },
    {
      name: {
        km: "កន្លែងជ្រៅ",
        latin: "Kanlaeng Chrov"
      },
      id: "14020406"
    },
    {
      name: {
        km: "ត្បូងវត្ដ",
        latin: "Tboung Voat"
      },
      id: "14020407"
    },
    {
      name: {
        km: "វាលស្មាច់",
        latin: "Veal Smach"
      },
      id: "14020408"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "14020409"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "14020410"
    },
    {
      name: {
        km: "ព្រៃទួលថ្មី",
        latin: "Prey Tuol Thmei"
      },
      id: "14020411"
    },
    {
      name: {
        km: "ឫស្សីជុកទី ១",
        latin: "Ruessei Chuk Ti Muoy"
      },
      id: "14020501"
    },
    {
      name: {
        km: "ឫស្សីជុកទី ២",
        latin: "Ruessei Chuk Ti Pir"
      },
      id: "14020502"
    },
    {
      name: {
        km: "ទួលសុភី",
        latin: "Tuol Sophi"
      },
      id: "14020503"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "14020504"
    },
    {
      name: {
        km: "លាក់នឹម",
        latin: "Leak Nuem"
      },
      id: "14020505"
    },
    {
      name: {
        km: "ឧបមា",
        latin: "Opakma"
      },
      id: "14020506"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Bos"
      },
      id: "14020507"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "14020508"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "14020509"
    },
    {
      name: {
        km: "បាយ៉ាប",
        latin: "Bayab"
      },
      id: "14020510"
    },
    {
      name: {
        km: "ចុងបឹង",
        latin: "Chong Boeng"
      },
      id: "14020511"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "14020512"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "14020513"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "14020601"
    },
    {
      name: {
        km: "ទានភ្លើង",
        latin: "Tean Phleung"
      },
      id: "14020602"
    },
    {
      name: {
        km: "ព្រៃស្ទរ",
        latin: "Prey Stor"
      },
      id: "14020603"
    },
    {
      name: {
        km: "ស្មោង",
        latin: "Smaong"
      },
      id: "14020604"
    },
    {
      name: {
        km: "ប្រមូលដុំ",
        latin: "Pramoul Dom"
      },
      id: "14020605"
    },
    {
      name: {
        km: "តាកែវ",
        latin: "Ta Kaev"
      },
      id: "14020606"
    },
    {
      name: {
        km: "បើកទូក",
        latin: "Baeuk Tuk"
      },
      id: "14020607"
    },
    {
      name: {
        km: "គោកសុក្រំ",
        latin: "Kouk Sokram"
      },
      id: "14020608"
    },
    {
      name: {
        km: "គោកព្រាល",
        latin: "Kouk Preal"
      },
      id: "14020609"
    },
    {
      name: {
        km: "ព្រៃលំពែង",
        latin: "Prey Lumpeaeng"
      },
      id: "14020610"
    },
    {
      name: {
        km: "ធ្នង់ខាងកើត",
        latin: "Thnong Khang Kaeut"
      },
      id: "14020611"
    },
    {
      name: {
        km: "ធ្នង់ខាងលិច",
        latin: "Thnonh Khang Lech"
      },
      id: "14020612"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់",
        latin: "Trapeang Skon"
      },
      id: "14020613"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "14020614"
    },
    {
      name: {
        km: "ល្អក់",
        latin: "L'ak"
      },
      id: "14020615"
    },
    {
      name: {
        km: "តាម៉ិញ",
        latin: "Ta Menh"
      },
      id: "14020616"
    },
    {
      name: {
        km: "ទួលក្រឡាញ់",
        latin: "Toul Kralanh"
      },
      id: "14020617"
    },
    {
      name: {
        km: "ស្រឡូងស្រឡី",
        latin: "Sraloung Sralei"
      },
      id: "14020701"
    },
    {
      name: {
        km: "កូនឫស្សី",
        latin: "Koun Ruessei"
      },
      id: "14020702"
    },
    {
      name: {
        km: "ព្រហ៊ារ",
        latin: "Prahear"
      },
      id: "14020703"
    },
    {
      name: {
        km: "ជំពូ",
        latin: "Chumpu"
      },
      id: "14020704"
    },
    {
      name: {
        km: "ព្រៃភ្លង",
        latin: "Prey Phlorng"
      },
      id: "14020705"
    },
    {
      name: {
        km: "ព្រៃចំការ",
        latin: "Prey Chamkar"
      },
      id: "14020706"
    },
    {
      name: {
        km: "ឆ្មាលោត",
        latin: "Chhma Lout"
      },
      id: "14020707"
    },
    {
      name: {
        km: "ព្រៃខ្លូត",
        latin: "Prey Khlout"
      },
      id: "14020708"
    },
    {
      name: {
        km: "គង់",
        latin: "Kong"
      },
      id: "14020709"
    },
    {
      name: {
        km: "គំនុំ",
        latin: "Kumnum"
      },
      id: "14020710"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "14020711"
    },
    {
      name: {
        km: "អូរកន្ទុយ",
        latin: "Ou Kantuy"
      },
      id: "14020712"
    },
    {
      name: {
        km: "ប្រឹក្សាទី១",
        latin: "Proeksa Ti Muoy"
      },
      id: "14020713"
    },
    {
      name: {
        km: "ប្រឹក្សាទី​២",
        latin: "Proeksa Ti Pir"
      },
      id: "14020714"
    },
    {
      name: {
        km: "រំដួល",
        latin: "Rumduol"
      },
      id: "14020715"
    },
    {
      name: {
        km: "ព្រៃស្លឹក",
        latin: "Prey Sloek"
      },
      id: "14020716"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "14020717"
    },
    {
      name: {
        km: "បឹងពោធិ៍",
        latin: "Boeng Pou"
      },
      id: "14020718"
    },
    {
      name: {
        km: "អង្គជា",
        latin: "Angk Chea"
      },
      id: "14020719"
    },
    {
      name: {
        km: "ដំណាក់សេរី",
        latin: "Damnak Serei"
      },
      id: "14020720"
    },
    {
      name: {
        km: "ច្រេសលិច",
        latin: "Chres Lech"
      },
      id: "14020801"
    },
    {
      name: {
        km: "ច្រេសកណ្ដាល",
        latin: "Chres Kandal"
      },
      id: "14020802"
    },
    {
      name: {
        km: "ច្រេសខាងកើត",
        latin: "Chres Khang Kaeut"
      },
      id: "14020803"
    },
    {
      name: {
        km: "ត្រពាំងកំពឹស",
        latin: "Trapeang Kampoes"
      },
      id: "14020804"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "14020805"
    },
    {
      name: {
        km: "ទួលតាមាក់",
        latin: "Tuol Ta Meak"
      },
      id: "14020806"
    },
    {
      name: {
        km: "ត្រពាំងប្រីយ៍",
        latin: "Trapeang Prei"
      },
      id: "14020807"
    },
    {
      name: {
        km: "ចំបក់កោង",
        latin: "Chambak Kaong"
      },
      id: "14020808"
    },
    {
      name: {
        km: "ត្រពាំងព្រិច",
        latin: "Trapeang Prich"
      },
      id: "14020809"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "14020810"
    },
    {
      name: {
        km: "ដូនដៀវ",
        latin: "Doun Diev"
      },
      id: "14020811"
    },
    {
      name: {
        km: "ត្រឡាចស",
        latin: "Tralach Sa"
      },
      id: "14020812"
    },
    {
      name: {
        km: "ព្រៃត្បែង",
        latin: "Prey Tbaeng"
      },
      id: "14020813"
    },
    {
      name: {
        km: "គ្រើល",
        latin: "Kreul"
      },
      id: "14030101"
    },
    {
      name: {
        km: "អង្វះមួយរយ",
        latin: "Angveah Muoy Roy"
      },
      id: "14030102"
    },
    {
      name: {
        km: "អន្សោង",
        latin: "Ansaong"
      },
      id: "14030103"
    },
    {
      name: {
        km: "តាពូង",
        latin: "Ta Pung"
      },
      id: "14030104"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "14030105"
    },
    {
      name: {
        km: "តាអោង",
        latin: "Ta Aong"
      },
      id: "14030106"
    },
    {
      name: {
        km: "បន្ទាបុស្ស",
        latin: "Bantea Bos"
      },
      id: "14030107"
    },
    {
      name: {
        km: "ត្រពាំងរកា",
        latin: "Trapeang Roka"
      },
      id: "14030108"
    },
    {
      name: {
        km: "រោង",
        latin: "Roung"
      },
      id: "14030201"
    },
    {
      name: {
        km: "ចាម",
        latin: "Cham"
      },
      id: "14030202"
    },
    {
      name: {
        km: "កាធំ",
        latin: "Ka Thum"
      },
      id: "14030203"
    },
    {
      name: {
        km: "ប្រស្ដេច",
        latin: "Prasdach"
      },
      id: "14030204"
    },
    {
      name: {
        km: "ព្រៃកន្សា",
        latin: "Prey Kansa"
      },
      id: "14030205"
    },
    {
      name: {
        km: "ពេជ្ជីរតន៍",
        latin: "Pechi Roatn"
      },
      id: "14030206"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "14030207"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "14030208"
    },
    {
      name: {
        km: "ជីពាយ",
        latin: "Chipeay"
      },
      id: "14030209"
    },
    {
      name: {
        km: "ក្រាំលាវ",
        latin: "Kram Leav"
      },
      id: "14030210"
    },
    {
      name: {
        km: "បឹងក្រនឹប",
        latin: "Boeng Kranueb"
      },
      id: "14030301"
    },
    {
      name: {
        km: "តាមាស",
        latin: "Ta Meas"
      },
      id: "14030302"
    },
    {
      name: {
        km: "ជាងដែក",
        latin: "Cheang Daek"
      },
      id: "14030303"
    },
    {
      name: {
        km: "ពញាកើត",
        latin: "Ponhea Kaeut"
      },
      id: "14030304"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "14030305"
    },
    {
      name: {
        km: "ព្រៃម្លុង",
        latin: "Prey Mlung"
      },
      id: "14030306"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "14030307"
    },
    {
      name: {
        km: "ក្រាំងវែង",
        latin: "Krang Veaeng"
      },
      id: "14030308"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "14030309"
    },
    {
      name: {
        km: "ឧកញ៉ាសឹង",
        latin: "Oknha Soeng"
      },
      id: "14030310"
    },
    {
      name: {
        km: "ថ្នឹង",
        latin: "Thnoeng"
      },
      id: "14030311"
    },
    {
      name: {
        km: "តាបុល",
        latin: "Ta Bol"
      },
      id: "14030312"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "14030401"
    },
    {
      name: {
        km: "ពៅ្ន",
        latin: "Pnov"
      },
      id: "14030402"
    },
    {
      name: {
        km: "ត្រពាំងរេ",
        latin: "Trapeang Re"
      },
      id: "14030403"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "14030404"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "14030405"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "14030406"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "14030407"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "14030408"
    },
    {
      name: {
        km: "ស្វាយប៉ាក",
        latin: "Svay Pak"
      },
      id: "14030409"
    },
    {
      name: {
        km: "កន្សោមអក",
        latin: "Kansaom Ak"
      },
      id: "14030501"
    },
    {
      name: {
        km: "បល្ល័ង្គ",
        latin: "Ballangk"
      },
      id: "14030502"
    },
    {
      name: {
        km: "ជ្រួល",
        latin: "Chruol"
      },
      id: "14030503"
    },
    {
      name: {
        km: "តាទាំង",
        latin: "Ta Teang"
      },
      id: "14030504"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "14030505"
    },
    {
      name: {
        km: "ព្រៃអណ្ដូង",
        latin: "Prey Andoung"
      },
      id: "14030506"
    },
    {
      name: {
        km: "ព្រៃខ្មៅ",
        latin: "Prey Khmau"
      },
      id: "14030507"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "14030508"
    },
    {
      name: {
        km: "ទ័ពសៀម",
        latin: "Toap Siem"
      },
      id: "14030509"
    },
    {
      name: {
        km: "គោខ្ចក",
        latin: "Kou Khchak"
      },
      id: "14030601"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "14030602"
    },
    {
      name: {
        km: "គោក្រោក",
        latin: "Kou Kraok"
      },
      id: "14030603"
    },
    {
      name: {
        km: "ស្គា",
        latin: "Skea"
      },
      id: "14030604"
    },
    {
      name: {
        km: "ព្រៃគុយ",
        latin: "Prey Kuy"
      },
      id: "14030605"
    },
    {
      name: {
        km: "ហប់",
        latin: "Hab"
      },
      id: "14030606"
    },
    {
      name: {
        km: "ចំរ៉េះ",
        latin: "Chamreh"
      },
      id: "14030607"
    },
    {
      name: {
        km: "ត្រពាំងត្រាវ",
        latin: "Trapeang Trav"
      },
      id: "14030608"
    },
    {
      name: {
        km: "រមាសឈរ",
        latin: "Romeas Chhor"
      },
      id: "14030609"
    },
    {
      name: {
        km: "ក្រោល",
        latin: "Kraol"
      },
      id: "14030610"
    },
    {
      name: {
        km: "ព្រៃស្នួល",
        latin: "Prey Snuol"
      },
      id: "14030611"
    },
    {
      name: {
        km: "ត្រាវ",
        latin: "Trav"
      },
      id: "14030612"
    },
    {
      name: {
        km: "ទួលរកា",
        latin: "Tuol Roka"
      },
      id: "14030701"
    },
    {
      name: {
        km: "ខ្មែរឥស្លាម",
        latin: "Khmer Eslam"
      },
      id: "14030702"
    },
    {
      name: {
        km: "ព្រែកផ្ដៅ",
        latin: "Preaek Phdau"
      },
      id: "14030703"
    },
    {
      name: {
        km: "អន្លង់ចក",
        latin: "Anlong Chak"
      },
      id: "14030704"
    },
    {
      name: {
        km: "រកាធំ",
        latin: "Roka Thum"
      },
      id: "14030705"
    },
    {
      name: {
        km: "កំពង់ស្វាយខាងកើត",
        latin: "Kampong Svay Khang Kaeut"
      },
      id: "14030706"
    },
    {
      name: {
        km: "កំពង់ស្វាយខាងលិច",
        latin: "Kampong Svay Khang Lech"
      },
      id: "14030707"
    },
    {
      name: {
        km: "កំពង់ត្របែក",
        latin: "Kampong Trabaek"
      },
      id: "14030708"
    },
    {
      name: {
        km: "អន្លង់រាជ្យ",
        latin: "Anlong Reach"
      },
      id: "14030709"
    },
    {
      name: {
        km: "បឹងខ្យង",
        latin: "Boeng Khyang"
      },
      id: "14030710"
    },
    {
      name: {
        km: "អង្គរអង្គ",
        latin: "Angkor Angk"
      },
      id: "14030801"
    },
    {
      name: {
        km: "ពាមមន្ទារ",
        latin: "Peam Montear"
      },
      id: "14030802"
    },
    {
      name: {
        km: "សិតក្រមួន",
        latin: "Set Kramuon"
      },
      id: "14030803"
    },
    {
      name: {
        km: "ចំណងទាក",
        latin: "Chamnang Teak"
      },
      id: "14030804"
    },
    {
      name: {
        km: "ក្រចាប់ក្រោម",
        latin: "Krachab Kraom"
      },
      id: "14030805"
    },
    {
      name: {
        km: "ក្រចាប់លើ",
        latin: "Krachab Leu"
      },
      id: "14030806"
    },
    {
      name: {
        km: "សហករណ៍",
        latin: "Sakhakkar"
      },
      id: "14030807"
    },
    {
      name: {
        km: "តាកែវ",
        latin: "Ta Kaev"
      },
      id: "14030808"
    },
    {
      name: {
        km: "ដង្កៀបក្ដាម",
        latin: "Dangkieb Kdam"
      },
      id: "14030809"
    },
    {
      name: {
        km: "ព្រែកតា",
        latin: "Preaek Ta"
      },
      id: "14030810"
    },
    {
      name: {
        km: "ខ្លំ",
        latin: "Khlam"
      },
      id: "14030811"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "14030901"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "14030902"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "14030903"
    },
    {
      name: {
        km: "ពាន់វត្ដ",
        latin: "Poan Voat"
      },
      id: "14030904"
    },
    {
      name: {
        km: "ជ្រលង",
        latin: "Chrolong"
      },
      id: "14030905"
    },
    {
      name: {
        km: "ដូងទុង",
        latin: "Doung Tung"
      },
      id: "14030906"
    },
    {
      name: {
        km: "បី",
        latin: "Bei"
      },
      id: "14030907"
    },
    {
      name: {
        km: "ភ្លង",
        latin: "Phlorng"
      },
      id: "14030908"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "14030909"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "14031001"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "14031002"
    },
    {
      name: {
        km: "ឆ្វាង",
        latin: "Chhvang"
      },
      id: "14031003"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "14031004"
    },
    {
      name: {
        km: "ត្រម៉ុត",
        latin: "Tramot"
      },
      id: "14031005"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "14031006"
    },
    {
      name: {
        km: "អង្កាញ់",
        latin: "Angkanh"
      },
      id: "14031007"
    },
    {
      name: {
        km: "ប្រធាតុ",
        latin: "Pratheat"
      },
      id: "14031008"
    },
    {
      name: {
        km: "ព្រៃញាតិ",
        latin: "Prey Nheat"
      },
      id: "14031009"
    },
    {
      name: {
        km: "ព្រៃតានេន",
        latin: "Prey Ta Nen"
      },
      id: "14031101"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "14031102"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "14031103"
    },
    {
      name: {
        km: "ពៅ្ន",
        latin: "Pnov"
      },
      id: "14031104"
    },
    {
      name: {
        km: "អាគ្រាជ",
        latin: "A Kreach"
      },
      id: "14031105"
    },
    {
      name: {
        km: "ព្រៃដំរី",
        latin: "Prey Damrei"
      },
      id: "14031106"
    },
    {
      name: {
        km: "ព្រៃម្នាស់់",
        latin: "Prey Mnoas"
      },
      id: "14031107"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "14031108"
    },
    {
      name: {
        km: "ជីពាយ",
        latin: "Chi Peay"
      },
      id: "14031201"
    },
    {
      name: {
        km: "ជីអក",
        latin: "Chi Ak"
      },
      id: "14031202"
    },
    {
      name: {
        km: "ព្រៃសំរោង",
        latin: "Prey Samraong"
      },
      id: "14031203"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "14031204"
    },
    {
      name: {
        km: "ព្រៃក្រាំង",
        latin: "Prey Krang"
      },
      id: "14031205"
    },
    {
      name: {
        km: "ព្រៃពោន",
        latin: "Prey Poun"
      },
      id: "14031206"
    },
    {
      name: {
        km: "តាប្រុយ",
        latin: "Ta Proy"
      },
      id: "14031207"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "14031208"
    },
    {
      name: {
        km: "តាហ៊",
        latin: "Ta Ho"
      },
      id: "14031301"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "14031302"
    },
    {
      name: {
        km: "ពោធិវង្ស",
        latin: "Pouthi Vongs"
      },
      id: "14031303"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "14031304"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "14031305"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "14031306"
    },
    {
      name: {
        km: "តាមួង",
        latin: "Ta Muong"
      },
      id: "14031307"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "14040101"
    },
    {
      name: {
        km: "ព្រៃត្បាល់",
        latin: "Prey Tbal"
      },
      id: "14040102"
    },
    {
      name: {
        km: "កណ្ដាច់",
        latin: "Kandach"
      },
      id: "14040103"
    },
    {
      name: {
        km: "ចុងអំពិល",
        latin: "Chong Ampil"
      },
      id: "14040104"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "14040105"
    },
    {
      name: {
        km: "ព្រៃស្វាយ",
        latin: "Prey Svay"
      },
      id: "14040106"
    },
    {
      name: {
        km: "ពារោង",
        latin: "Pea Roung"
      },
      id: "14040107"
    },
    {
      name: {
        km: "ប្រស្រែលិច",
        latin: "Prasrae Lech"
      },
      id: "14040201"
    },
    {
      name: {
        km: "ប្រស្រែមុខ",
        latin: "Prasrae Mukh"
      },
      id: "14040202"
    },
    {
      name: {
        km: "ជើងទឹក",
        latin: "Cheung Tuek"
      },
      id: "14040203"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "14040204"
    },
    {
      name: {
        km: "ធម្មកេរ្ដិ៍",
        latin: "Thommeak Ker"
      },
      id: "14040205"
    },
    {
      name: {
        km: "ឆ្អឹងជំនីរ",
        latin: "Chh'oeng Chumnir"
      },
      id: "14040206"
    },
    {
      name: {
        km: "អូរតាថុក",
        latin: "Ou Ta Thok"
      },
      id: "14040207"
    },
    {
      name: {
        km: "កញ្ជ្រៀច",
        latin: "Kanhchriech"
      },
      id: "14040208"
    },
    {
      name: {
        km: "ព្រៃភូមិ",
        latin: "Prey Phum"
      },
      id: "14040209"
    },
    {
      name: {
        km: "ពោធិ៍ទង",
        latin: "Pou Tong"
      },
      id: "14040210"
    },
    {
      name: {
        km: "ពោធិ៍ម្រិញ",
        latin: "Pou Mrenh"
      },
      id: "14040211"
    },
    {
      name: {
        km: "ដូនវាល",
        latin: "Doun Veal"
      },
      id: "14040301"
    },
    {
      name: {
        km: "ក្ដឿងរាយ",
        latin: "Kdoeang Reay"
      },
      id: "14040302"
    },
    {
      name: {
        km: "ចារជ្រុំ",
        latin: "Char Chrum"
      },
      id: "14040303"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "14040304"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "14040305"
    },
    {
      name: {
        km: "ពោធិ៍ធំ",
        latin: "Pou Thum"
      },
      id: "14040306"
    },
    {
      name: {
        km: "ពោធិ៍តូច",
        latin: "Pou Touch"
      },
      id: "14040307"
    },
    {
      name: {
        km: "តាណាល",
        latin: "Ta Nal"
      },
      id: "14040308"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "14040309"
    },
    {
      name: {
        km: "ល្ងើន",
        latin: "Lngeun"
      },
      id: "14040310"
    },
    {
      name: {
        km: "សាឡូង",
        latin: "Saloung"
      },
      id: "14040311"
    },
    {
      name: {
        km: "ពានាផ្សារ",
        latin: "Peanea Phsar"
      },
      id: "14040312"
    },
    {
      name: {
        km: "ពានាកណ្ដាល",
        latin: "Peanea Kandal"
      },
      id: "14040313"
    },
    {
      name: {
        km: "ពានាជួរ",
        latin: "Peanea Chuor"
      },
      id: "14040314"
    },
    {
      name: {
        km: "អំព្នាំង",
        latin: "Ampeang"
      },
      id: "14040315"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "14040316"
    },
    {
      name: {
        km: "បឹងអញ្ចាញ",
        latin: "Boeng Anhchanh"
      },
      id: "14040317"
    },
    {
      name: {
        km: "ថ្មទឹកដាច់",
        latin: "Thma Tuek Dach"
      },
      id: "14040401"
    },
    {
      name: {
        km: "គោកក្រសាំង",
        latin: "Kouk Krasang"
      },
      id: "14040402"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "14040403"
    },
    {
      name: {
        km: "បុស្សត្នោត",
        latin: "Bos Tnaot"
      },
      id: "14040404"
    },
    {
      name: {
        km: "បែកក្អម",
        latin: "Baek K'am"
      },
      id: "14040405"
    },
    {
      name: {
        km: "ឫស្សីទន្លេ",
        latin: "Ruessei Tonle"
      },
      id: "14040406"
    },
    {
      name: {
        km: "សេរីចរចារ",
        latin: "Serei Charchar"
      },
      id: "14040407"
    },
    {
      name: {
        km: "ជ្រៃម្រាក់",
        latin: "Chrey Mreak"
      },
      id: "14040408"
    },
    {
      name: {
        km: "ទុកស្រូវ",
        latin: "Tuk Srov"
      },
      id: "14040409"
    },
    {
      name: {
        km: "ស្រះស្រង់",
        latin: "Srah Srang"
      },
      id: "14040410"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "14040411"
    },
    {
      name: {
        km: "ជ័យមុនី",
        latin: "Chey Muni"
      },
      id: "14040412"
    },
    {
      name: {
        km: "អ្នកតាជ័យ",
        latin: "Neak Ta Chey"
      },
      id: "14040501"
    },
    {
      name: {
        km: "ដំណាក់ស្នួល",
        latin: "Damnak Snuol"
      },
      id: "14040502"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "14040503"
    },
    {
      name: {
        km: "ត្រពាំងប្រស្រែលិច",
        latin: "Trapeang Prasrae Lech"
      },
      id: "14040504"
    },
    {
      name: {
        km: "ត្រពាំងប្រស្រែកើត",
        latin: "Trapeang Prasrae Kaeut"
      },
      id: "14040505"
    },
    {
      name: {
        km: "ត្រពាំងពី",
        latin: "Trapeang Pir"
      },
      id: "14040506"
    },
    {
      name: {
        km: "ព្រៃចេក",
        latin: "Prey Chek"
      },
      id: "14040507"
    },
    {
      name: {
        km: "ខ្ទមលាវ",
        latin: "Khtom Leav"
      },
      id: "14040508"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "14040509"
    },
    {
      name: {
        km: "គោកគង់កើត",
        latin: "Kouk Kong Kaeut"
      },
      id: "14040510"
    },
    {
      name: {
        km: "គោកគង់លិច",
        latin: "Kouk Kong Lech"
      },
      id: "14040511"
    },
    {
      name: {
        km: "គោកគង់កណ្ដាល",
        latin: "Kouk Kong Kandal"
      },
      id: "14040512"
    },
    {
      name: {
        km: "គោកគង់ត្បូង",
        latin: "Kouk Kong Tboung"
      },
      id: "14040513"
    },
    {
      name: {
        km: "ស្វាយរុន",
        latin: "Svay Run"
      },
      id: "14040514"
    },
    {
      name: {
        km: "ទួលពពេល",
        latin: "Tuol Popel"
      },
      id: "14040515"
    },
    {
      name: {
        km: "រកាទ្រេត",
        latin: "Roka Tret"
      },
      id: "14040516"
    },
    {
      name: {
        km: "ស្វាយទោល",
        latin: "Svay Toul"
      },
      id: "14040517"
    },
    {
      name: {
        km: "ត្រពាំងទឹម",
        latin: "Trapeang Tuem"
      },
      id: "14040601"
    },
    {
      name: {
        km: "ត្រពាំងសេះ",
        latin: "Trapeang Seh"
      },
      id: "14040602"
    },
    {
      name: {
        km: "ពោធិ៍ថ្មី",
        latin: "Pou Thmei"
      },
      id: "14040603"
    },
    {
      name: {
        km: "អណ្ដូងសាលា",
        latin: "Andoung Sala"
      },
      id: "14040604"
    },
    {
      name: {
        km: "ត្រពាំងថ្លាន់ត្បូង",
        latin: "Trapeang Thlan Tboung"
      },
      id: "14040605"
    },
    {
      name: {
        km: "ត្រពាំងថ្លាន់ជើង",
        latin: "Trapeang Thlan Cheung"
      },
      id: "14040606"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14040607"
    },
    {
      name: {
        km: "ទួលត្នោត",
        latin: "Tuol Tnaot"
      },
      id: "14040608"
    },
    {
      name: {
        km: "គោករកា",
        latin: "Kouk Roka"
      },
      id: "14040609"
    },
    {
      name: {
        km: "ព្រៃតាព្រុំ",
        latin: "Prey Ta Prum"
      },
      id: "14040610"
    },
    {
      name: {
        km: "ត្រពាំងក្រាញ់",
        latin: "Trapeang Kranh"
      },
      id: "14040611"
    },
    {
      name: {
        km: "ល្ហើយ",
        latin: "Lhaeuy"
      },
      id: "14040612"
    },
    {
      name: {
        km: "ពោធិព្រឹក្សា",
        latin: "Pouthi Proeksa"
      },
      id: "14040613"
    },
    {
      name: {
        km: "ទួលត្រាច",
        latin: "Tuol Trach"
      },
      id: "14040614"
    },
    {
      name: {
        km: "ត្រពាំងកកោះ",
        latin: "Trapeang Kakaoh"
      },
      id: "14040615"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "14040616"
    },
    {
      name: {
        km: "ឆ្ពឹស",
        latin: "Chhpoes"
      },
      id: "14040701"
    },
    {
      name: {
        km: "ព្រាលទី២",
        latin: "Preal Ti Pir"
      },
      id: "14040702"
    },
    {
      name: {
        km: "ព្រាលទី១",
        latin: "Preal Ti Muoy"
      },
      id: "14040703"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "14040704"
    },
    {
      name: {
        km: "ព្រះឥន្ទស្មត់",
        latin: "Preah Ent Smat"
      },
      id: "14040705"
    },
    {
      name: {
        km: "ថ្មពូន",
        latin: "Thma Pun"
      },
      id: "14040706"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "14040707"
    },
    {
      name: {
        km: "ព្រៃចារ",
        latin: "Prey Char"
      },
      id: "14040708"
    },
    {
      name: {
        km: "ព្រងើយទី១",
        latin: "Prongeuy Ti Muoy"
      },
      id: "14040709"
    },
    {
      name: {
        km: "តាប៉ោង",
        latin: "Ta Paong"
      },
      id: "14040710"
    },
    {
      name: {
        km: "ព្រងើយទី២",
        latin: "Prongeuy Ti Pir"
      },
      id: "14040711"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "14040801"
    },
    {
      name: {
        km: "ក្រឡាមាឃ",
        latin: "Krala Meakh"
      },
      id: "14040802"
    },
    {
      name: {
        km: "ជម្ពូវ័ន្ដ",
        latin: "Chumpu Voan"
      },
      id: "14040803"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "14040804"
    },
    {
      name: {
        km: "តារាជ",
        latin: "Ta Reach"
      },
      id: "14040805"
    },
    {
      name: {
        km: "ថ្នល់បែក",
        latin: "Thnal Baek"
      },
      id: "14040806"
    },
    {
      name: {
        km: "ខ្នាយកូវ",
        latin: "Khnay Kov"
      },
      id: "14040807"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "14040808"
    },
    {
      name: {
        km: "ស្រះតាអឹម",
        latin: "Srah Ta Oem"
      },
      id: "14050101"
    },
    {
      name: {
        km: "គោកច្រេស",
        latin: "Kouk Chres"
      },
      id: "14050102"
    },
    {
      name: {
        km: "សូរ",
        latin: "Sour"
      },
      id: "14050103"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "14050104"
    },
    {
      name: {
        km: "អង្គរសរ",
        latin: "Angkor Sar"
      },
      id: "14050105"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "14050106"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "14050107"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "14050108"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "14050109"
    },
    {
      name: {
        km: "អង្គព្រះ",
        latin: "Angk Preah"
      },
      id: "14050201"
    },
    {
      name: {
        km: "អណ្ដូងត្រាច",
        latin: "Andoung Trach"
      },
      id: "14050202"
    },
    {
      name: {
        km: "ចំបក់ជ្រុំ",
        latin: "Chambak Chrum"
      },
      id: "14050203"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់",
        latin: "Trapeang Skon"
      },
      id: "14050204"
    },
    {
      name: {
        km: "អណ្ដូងតាស្រែន",
        latin: "Andoung Ta Sraen"
      },
      id: "14050205"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "14050206"
    },
    {
      name: {
        km: "ក្រសាំងលៃ",
        latin: "Krasang Ley"
      },
      id: "14050207"
    },
    {
      name: {
        km: "ព្រេច",
        latin: "Prech"
      },
      id: "14050208"
    },
    {
      name: {
        km: "ទ័ពស្ដេច",
        latin: "Toap Sdach"
      },
      id: "14050209"
    },
    {
      name: {
        km: "ចន្ទ័គ្រឹស្នា",
        latin: "Chant Kruesna"
      },
      id: "14050210"
    },
    {
      name: {
        km: "ព្រហ៊ារ",
        latin: "Prohear"
      },
      id: "14050211"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "14050212"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "14050213"
    },
    {
      name: {
        km: "បឹងអន្ទង់",
        latin: "Boeng Antong"
      },
      id: "14050214"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "14050215"
    },
    {
      name: {
        km: "ទួលតារី",
        latin: "Tuol Ta Ri"
      },
      id: "14050216"
    },
    {
      name: {
        km: "ព្រៃសង្គម",
        latin: "Prey Sangkum"
      },
      id: "14050217"
    },
    {
      name: {
        km: "ព្រៃរកា",
        latin: "Prey Roka"
      },
      id: "14050218"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "14050219"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់",
        latin: "Trapeang Skon"
      },
      id: "14050301"
    },
    {
      name: {
        km: "ពោធិ៍ពាត់",
        latin: "Pou Poat"
      },
      id: "14050302"
    },
    {
      name: {
        km: "ស្នាយបុណ្យ",
        latin: "Snay Bon"
      },
      id: "14050303"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "14050304"
    },
    {
      name: {
        km: "ជីផុច",
        latin: "Chi Phoch"
      },
      id: "14050305"
    },
    {
      name: {
        km: "ពោធិ៍រោង",
        latin: "Pou Roung"
      },
      id: "14050306"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "14050307"
    },
    {
      name: {
        km: "ថ្មស",
        latin: "Thma Sa"
      },
      id: "14050308"
    },
    {
      name: {
        km: "ព្រៃត្រខុប",
        latin: "Prey Trakhob"
      },
      id: "14050309"
    },
    {
      name: {
        km: "សំរោងវាល",
        latin: "Samraong Veal"
      },
      id: "14050310"
    },
    {
      name: {
        km: "សំរោងវត្ដ",
        latin: "Samraong Voat"
      },
      id: "14050311"
    },
    {
      name: {
        km: "ឫស្សីសាញ់",
        latin: "Ruessei Sanh"
      },
      id: "14050312"
    },
    {
      name: {
        km: "វាំង",
        latin: "Veang"
      },
      id: "14050313"
    },
    {
      name: {
        km: "ស្វាយអណ្ដូង",
        latin: "Svay Andoung"
      },
      id: "14050314"
    },
    {
      name: {
        km: "យាងតូច",
        latin: "Yeang Touch"
      },
      id: "14050315"
    },
    {
      name: {
        km: "យាងធំ",
        latin: "Yeang Thum"
      },
      id: "14050316"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14050317"
    },
    {
      name: {
        km: "ក្រសាំងកន្ទ្រែ",
        latin: "Krasang Kantrae"
      },
      id: "14050318"
    },
    {
      name: {
        km: "ពោធិ៍ស្នាយ",
        latin: "Pou Snay"
      },
      id: "14050401"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "14050402"
    },
    {
      name: {
        km: "ឫស្សីស្បាត",
        latin: "Ruessei Sbat"
      },
      id: "14050403"
    },
    {
      name: {
        km: "ត្រពាំងព្រះទី១",
        latin: "Trapeang Preah Ti Muoy"
      },
      id: "14050404"
    },
    {
      name: {
        km: "ត្រពាំងព្រះទី២",
        latin: "Trapeang Preah Ti Pir"
      },
      id: "14050405"
    },
    {
      name: {
        km: "តាម៉ៅ",
        latin: "Ta Mau"
      },
      id: "14050406"
    },
    {
      name: {
        km: "សន្លូងលៀប",
        latin: "Sanloung Lieb"
      },
      id: "14050407"
    },
    {
      name: {
        km: "អូរសង្កែ",
        latin: "Ou Sangkae"
      },
      id: "14050408"
    },
    {
      name: {
        km: "ស្វាយជោ",
        latin: "Svay Chou"
      },
      id: "14050409"
    },
    {
      name: {
        km: "ឫស្សីទា្វរ",
        latin: "Ruessei Tvear"
      },
      id: "14050410"
    },
    {
      name: {
        km: "ព្រៃឃ្នេស  ក",
        latin: "Prey Khnes Ka"
      },
      id: "14050411"
    },
    {
      name: {
        km: "ព្រៃឃ្នេស ខ",
        latin: "Prey Khnes Kha"
      },
      id: "14050412"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "14050413"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "14050414"
    },
    {
      name: {
        km: "ឫស្សីចចក",
        latin: "Ruessei Chachak"
      },
      id: "14050415"
    },
    {
      name: {
        km: "សន្លុង",
        latin: "Sanlung"
      },
      id: "14050416"
    },
    {
      name: {
        km: "ពៅ្ន",
        latin: "Pnov"
      },
      id: "14050417"
    },
    {
      name: {
        km: "បារាជ",
        latin: "Ba Reach"
      },
      id: "14050418"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14050419"
    },
    {
      name: {
        km: "របស់ផ្ចឹក",
        latin: "Robas Phchoek"
      },
      id: "14050420"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "14050421"
    },
    {
      name: {
        km: "កែវស្នា",
        latin: "Kaev Sna"
      },
      id: "14050422"
    },
    {
      name: {
        km: "មេសាង",
        latin: "Me Sang"
      },
      id: "14050501"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "14050502"
    },
    {
      name: {
        km: "គ្រើល",
        latin: "Kreul"
      },
      id: "14050503"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "14050504"
    },
    {
      name: {
        km: "ព្រៃចេក",
        latin: "Prey Chek"
      },
      id: "14050505"
    },
    {
      name: {
        km: "ព្រៃរំដេង",
        latin: "Prey Rumdeng"
      },
      id: "14050506"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "14050507"
    },
    {
      name: {
        km: "ព្រៃខ្នុរ",
        latin: "Prey Khnor"
      },
      id: "14050508"
    },
    {
      name: {
        km: "ព្រៃអំពៅ",
        latin: "Prey Ampov"
      },
      id: "14050601"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "14050602"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "14050603"
    },
    {
      name: {
        km: "ព្រៃមូល",
        latin: "Prey Mul"
      },
      id: "14050604"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "14050605"
    },
    {
      name: {
        km: "អំពិលទោល",
        latin: "Ampil Toul"
      },
      id: "14050606"
    },
    {
      name: {
        km: "សំព័រ",
        latin: "Sampoar"
      },
      id: "14050607"
    },
    {
      name: {
        km: "ព្រៃជ្រុំ",
        latin: "Prey Chrum"
      },
      id: "14050608"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "14050609"
    },
    {
      name: {
        km: "ត្រពាំងក្ដាម",
        latin: "Trapeang Kdam"
      },
      id: "14050610"
    },
    {
      name: {
        km: "អង្គក្អោត",
        latin: "Angk K'aot"
      },
      id: "14050611"
    },
    {
      name: {
        km: "ផ្អាវ",
        latin: "Ph'av"
      },
      id: "14050612"
    },
    {
      name: {
        km: "ព្រៃព្រះអណ្ដូង",
        latin: "Prey Preah Andoung"
      },
      id: "14050701"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "14050702"
    },
    {
      name: {
        km: "សាវាំង",
        latin: "Sa Veang"
      },
      id: "14050703"
    },
    {
      name: {
        km: "អង្គាសដី",
        latin: "Angkeas Dei"
      },
      id: "14050704"
    },
    {
      name: {
        km: "ស្វាយទាប",
        latin: "Svay Teab"
      },
      id: "14050705"
    },
    {
      name: {
        km: "ស្វាយទង",
        latin: "Svay Tong"
      },
      id: "14050706"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "14050707"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "14050708"
    },
    {
      name: {
        km: "ពោធិ៍តាមុំ",
        latin: "Pou Ta Mom"
      },
      id: "14050709"
    },
    {
      name: {
        km: "ព្រៃចំការខាងត្បូង",
        latin: "Prey Chamkar Khang Tboung"
      },
      id: "14050710"
    },
    {
      name: {
        km: "ព្រៃចំការខាងជើង",
        latin: "Prey Chamkar Khang Cheung"
      },
      id: "14050711"
    },
    {
      name: {
        km: "ក្បាលខ្វែក",
        latin: "Kbal Khvaek"
      },
      id: "14050712"
    },
    {
      name: {
        km: "ចុងទួល",
        latin: "Chong Tuol"
      },
      id: "14050713"
    },
    {
      name: {
        km: "ស្រែរាន",
        latin: "Srae Rean"
      },
      id: "14050714"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14050715"
    },
    {
      name: {
        km: "បឹងអន្ទង់",
        latin: "Boeng Antong"
      },
      id: "14050716"
    },
    {
      name: {
        km: "ស្វាយឧត្ដម",
        latin: "Svay Otdam"
      },
      id: "14050717"
    },
    {
      name: {
        km: "ភ្នៀត",
        latin: "Phniet"
      },
      id: "14050718"
    },
    {
      name: {
        km: "ស្រម៉",
        latin: "Srama"
      },
      id: "14050801"
    },
    {
      name: {
        km: "ប្រហ៊ូត",
        latin: "Brohut"
      },
      id: "14050802"
    },
    {
      name: {
        km: "ប្រស្វា",
        latin: "Brasva"
      },
      id: "14050803"
    },
    {
      name: {
        km: "ស្វាយរកៈ",
        latin: "Svay Rokeah"
      },
      id: "14050804"
    },
    {
      name: {
        km: "ត្រពាំងស្រែ",
        latin: "Trapeang Srae"
      },
      id: "14050805"
    },
    {
      name: {
        km: "ចេកធ្លក",
        latin: "Chek Thlok"
      },
      id: "14050806"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "14050807"
    },
    {
      name: {
        km: "ត្រគៀត",
        latin: "Trakiet"
      },
      id: "14050808"
    },
    {
      name: {
        km: "ម៉ឺនពាន់",
        latin: "Meun Poan"
      },
      id: "14050809"
    },
    {
      name: {
        km: "ផ្លាំង",
        latin: "Phlang"
      },
      id: "14050810"
    },
    {
      name: {
        km: "ព្រៃគុយ",
        latin: "Prey Kuy"
      },
      id: "14050811"
    },
    {
      name: {
        km: "ម្រេញ",
        latin: "Mrenh"
      },
      id: "14050812"
    },
    {
      name: {
        km: "អង្គរអង្គ",
        latin: "Angkor Angk"
      },
      id: "14060101"
    },
    {
      name: {
        km: "ព្រែកត្រែង",
        latin: "Preaek Traeng"
      },
      id: "14060102"
    },
    {
      name: {
        km: "វាលរបងក្រោម",
        latin: "Veal Robang Kraom"
      },
      id: "14060103"
    },
    {
      name: {
        km: "វាលរបងលើ",
        latin: "Veal Robang Leu"
      },
      id: "14060104"
    },
    {
      name: {
        km: "តាហ៊ុយ",
        latin: "Ta Huy"
      },
      id: "14060201"
    },
    {
      name: {
        km: "តាប៉ើ",
        latin: "Ta Paeu"
      },
      id: "14060202"
    },
    {
      name: {
        km: "ក្រាំងក្រូច",
        latin: "Krang Krouch"
      },
      id: "14060203"
    },
    {
      name: {
        km: "ទឹកវិល",
        latin: "Tuek Vil"
      },
      id: "14060204"
    },
    {
      name: {
        km: "កំពង់ប្រាសាទ",
        latin: "Kampong Prasat"
      },
      id: "14060205"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "14060301"
    },
    {
      name: {
        km: "កោះចេក",
        latin: "Kaoh Chek"
      },
      id: "14060302"
    },
    {
      name: {
        km: "កោះពាមរាំង",
        latin: "Kaoh Peam Reang"
      },
      id: "14060303"
    },
    {
      name: {
        km: "ស្ពាន",
        latin: "Spean"
      },
      id: "14060304"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "14060401"
    },
    {
      name: {
        km: "ដីស្រុត",
        latin: "Dei Srot"
      },
      id: "14060402"
    },
    {
      name: {
        km: "ជ្រៃថ្មី",
        latin: "Chrey Thmei"
      },
      id: "14060403"
    },
    {
      name: {
        km: "កោះរកា",
        latin: "Kaoh Roka"
      },
      id: "14060404"
    },
    {
      name: {
        km: "ពោធិ៍ថ្មី",
        latin: "Pou Thmei"
      },
      id: "14060501"
    },
    {
      name: {
        km: "អំពៅព្រៃ",
        latin: "Ampov Prey"
      },
      id: "14060502"
    },
    {
      name: {
        km: "ស្វាយអណ្ដូង",
        latin: "Svay Andoung"
      },
      id: "14060503"
    },
    {
      name: {
        km: "ទួលសាំង",
        latin: "Tuol Sang"
      },
      id: "14060504"
    },
    {
      name: {
        km: "ខ្សាច់",
        latin: "Khsach"
      },
      id: "14060505"
    },
    {
      name: {
        km: "ជ្រៃឧត្ដម",
        latin: "Chrey Otdam"
      },
      id: "14060601"
    },
    {
      name: {
        km: "ក្រាំងចិន",
        latin: "Krang Chen"
      },
      id: "14060602"
    },
    {
      name: {
        km: "ក្រាំងព្រៃផ្ដៅ",
        latin: "Krang Prey Phdau"
      },
      id: "14060603"
    },
    {
      name: {
        km: "ក្រាំងតាយ៉ង",
        latin: "Krang Ta Yang"
      },
      id: "14060604"
    },
    {
      name: {
        km: "ក្រាំងស្ករ",
        latin: "Krang Skar"
      },
      id: "14060605"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "14060606"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "14060607"
    },
    {
      name: {
        km: "រកាដុះ",
        latin: "Roka Doh"
      },
      id: "14060608"
    },
    {
      name: {
        km: "ឧត្ដម",
        latin: "Otdam"
      },
      id: "14060701"
    },
    {
      name: {
        km: "ឧត្ដុង្គ",
        latin: "Otdong"
      },
      id: "14060702"
    },
    {
      name: {
        km: "ព្រែកក្របៅ",
        latin: "Preaek Krabau"
      },
      id: "14060703"
    },
    {
      name: {
        km: "ខ្ពប",
        latin: "Khpob"
      },
      id: "14060801"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "14060802"
    },
    {
      name: {
        km: "ព្រែកជ្រៅ",
        latin: "Preaek Chrov"
      },
      id: "14060803"
    },
    {
      name: {
        km: "ពែន",
        latin: "Peaen"
      },
      id: "14060901"
    },
    {
      name: {
        km: "តាសូ",
        latin: "Ta Sou"
      },
      id: "14060902"
    },
    {
      name: {
        km: "តាពូង",
        latin: "Ta Pung"
      },
      id: "14060903"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "14060904"
    },
    {
      name: {
        km: "ឥដ្ឋ",
        latin: "Edth"
      },
      id: "14060905"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "14060906"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "14060907"
    },
    {
      name: {
        km: "ព្រៃមាន់",
        latin: "Prey Moan"
      },
      id: "14060908"
    },
    {
      name: {
        km: "បឹងអក្សរ",
        latin: "Boeng Aksar"
      },
      id: "14060909"
    },
    {
      name: {
        km: "ពោធិ៍ខ្ពស់",
        latin: "Pou Khpos"
      },
      id: "14060910"
    },
    {
      name: {
        km: "ក្រាំងពោន",
        latin: "Krang Poun"
      },
      id: "14060911"
    },
    {
      name: {
        km: "សង្គ្រោះ",
        latin: "Sangkruoh"
      },
      id: "14061001"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "14061002"
    },
    {
      name: {
        km: "បង្អែក",
        latin: "Bang'aek"
      },
      id: "14061003"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "14070101"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "14070102"
    },
    {
      name: {
        km: "ជោគជ័យ",
        latin: "Chouk Chey"
      },
      id: "14070103"
    },
    {
      name: {
        km: "បាបោង",
        latin: "Ba Baong"
      },
      id: "14070104"
    },
    {
      name: {
        km: "ប្រាសាទលិច",
        latin: "Prasat Lech"
      },
      id: "14070201"
    },
    {
      name: {
        km: "ប្រាសាទជើង",
        latin: "Prasat Cheung"
      },
      id: "14070202"
    },
    {
      name: {
        km: "ប្រាសាទកើត",
        latin: "Prasat Kaeut"
      },
      id: "14070203"
    },
    {
      name: {
        km: "ព្រែកចាម",
        latin: "Preaek Cham"
      },
      id: "14070204"
    },
    {
      name: {
        km: "ព្រែករាំង",
        latin: "Preaek Reang"
      },
      id: "14070205"
    },
    {
      name: {
        km: "ដឹកដា",
        latin: "Doek Da"
      },
      id: "14070206"
    },
    {
      name: {
        km: "អ្នកលឿង",
        latin: "Neak Loeang"
      },
      id: "14070301"
    },
    {
      name: {
        km: "ព្រែកតាសរ",
        latin: "Preaek Ta Sar"
      },
      id: "14070302"
    },
    {
      name: {
        km: "ព្រែកធំ",
        latin: "Preaek Thum"
      },
      id: "14070303"
    },
    {
      name: {
        km: "ស្ទឹងស្លូត",
        latin: "Stueng Slout"
      },
      id: "14070304"
    },
    {
      name: {
        km: "ស្ទឹងសន្ដិភាព",
        latin: "Stueng Santepheap"
      },
      id: "14070305"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14070401"
    },
    {
      name: {
        km: "បឹងក្អែក",
        latin: "Boeng K'aek"
      },
      id: "14070402"
    },
    {
      name: {
        km: "បឹងផ្សោត",
        latin: "Boeng Phsaot"
      },
      id: "14070403"
    },
    {
      name: {
        km: "ចំការវែង",
        latin: "Chamkar Veaeng"
      },
      id: "14070404"
    },
    {
      name: {
        km: "បន្លេច",
        latin: "Banlech"
      },
      id: "14070405"
    },
    {
      name: {
        km: "ពាមកោះ",
        latin: "Peam Kaoh"
      },
      id: "14070501"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "14070502"
    },
    {
      name: {
        km: "បាទី",
        latin: "Bati"
      },
      id: "14070503"
    },
    {
      name: {
        km: "ចំបក់ប្រាំង",
        latin: "Chambak Prang"
      },
      id: "14070504"
    },
    {
      name: {
        km: "ចាក់ខ្លាញ់",
        latin: "Chak Khlanh"
      },
      id: "14070505"
    },
    {
      name: {
        km: "ព្រែកខ្សាយ",
        latin: "Preaek Khsay"
      },
      id: "14070601"
    },
    {
      name: {
        km: "ឧត្ដម",
        latin: "Otdam"
      },
      id: "14070602"
    },
    {
      name: {
        km: "ជ័យឧត្តម",
        latin: "Chey Oudom"
      },
      id: "14070603"
    },
    {
      name: {
        km: "ជ័យឧត្តមបឹងខ្ទុម",
        latin: "Chey Oudom Boeung Ktom"
      },
      id: "14070604"
    },
    {
      name: {
        km: "ព្រែកខ្សាយខាងជើង",
        latin: "Preaek Khsay Khang Choeung"
      },
      id: "14070605"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum Muoy"
      },
      id: "14070701"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum Pir"
      },
      id: "14070702"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum Bei"
      },
      id: "14070703"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum Buon"
      },
      id: "14070704"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum Pram"
      },
      id: "14070705"
    },
    {
      name: {
        km: "ភូមិ ៦",
        latin: "Phum Prammuoy"
      },
      id: "14070706"
    },
    {
      name: {
        km: "ព្រៃកណ្ដៀង",
        latin: "Prey Kandieng"
      },
      id: "14070801"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "14070802"
    },
    {
      name: {
        km: "ព្រៃអង្គុញ",
        latin: "Prey Angkunh"
      },
      id: "14070803"
    },
    {
      name: {
        km: "ព្រៃកំពែង",
        latin: "Prey Kampeaeng"
      },
      id: "14070804"
    },
    {
      name: {
        km: "ចាន់",
        latin: "Chan"
      },
      id: "14070805"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "14070806"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "14070807"
    },
    {
      name: {
        km: "បទសន្ទ្រា",
        latin: "Bat Santrea"
      },
      id: "14080101"
    },
    {
      name: {
        km: "កំពង់ពពិល",
        latin: "Kampong Popil"
      },
      id: "14080102"
    },
    {
      name: {
        km: "ព្រែកកាំភ្លើង",
        latin: "Preaek Kamphleung"
      },
      id: "14080103"
    },
    {
      name: {
        km: "ជំនីក",
        latin: "Chumnik"
      },
      id: "14080104"
    },
    {
      name: {
        km: "ត្រាល",
        latin: "Tral"
      },
      id: "14080105"
    },
    {
      name: {
        km: "គគរ",
        latin: "Kokor"
      },
      id: "14080106"
    },
    {
      name: {
        km: "ខ្សុំជើង",
        latin: "Khsom Cheung"
      },
      id: "14080107"
    },
    {
      name: {
        km: "ខ្សុំត្បូង",
        latin: "Khsom Tboung"
      },
      id: "14080108"
    },
    {
      name: {
        km: "ពហ៊ារ",
        latin: "Pohear"
      },
      id: "14080109"
    },
    {
      name: {
        km: "តាំងរលាង",
        latin: "Tang Roleang"
      },
      id: "14080110"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "14080202"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "14080203"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "14080204"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "14080206"
    },
    {
      name: {
        km: "ដំបូកធំ",
        latin: "Dambouk Thum"
      },
      id: "14080207"
    },
    {
      name: {
        km: "តាំងរមាំង",
        latin: "Tang Romeang"
      },
      id: "14080209"
    },
    {
      name: {
        km: "កញ្ចំ ក",
        latin: "Kanhcham Kor"
      },
      id: "14080210"
    },
    {
      name: {
        km: "កញ្ចំ ខ",
        latin: "Kanhcham Khor"
      },
      id: "14080211"
    },
    {
      name: {
        km: "ព្រៃទំពូង ក",
        latin: "Prey Tumpung Kor"
      },
      id: "14080212"
    },
    {
      name: {
        km: "ព្រៃទំពូង ខ",
        latin: "Prey Tumpung Khor"
      },
      id: "14080213"
    },
    {
      name: {
        km: "ទន្លេជ្រៃ ក",
        latin: "Tonle Chrey Kor"
      },
      id: "14080214"
    },
    {
      name: {
        km: "ទន្លេជ្រៃ ខ",
        latin: "Tonle Chrey Khor"
      },
      id: "14080215"
    },
    {
      name: {
        km: "ព្រែកជ្រូក",
        latin: "Preaek Chruk"
      },
      id: "14080301"
    },
    {
      name: {
        km: "ព្រែកតារ័ត្ន",
        latin: "Preaek Ka Roatn"
      },
      id: "14080302"
    },
    {
      name: {
        km: "កំពង់ប្រាំង",
        latin: "Kampong Prang"
      },
      id: "14080303"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "14080304"
    },
    {
      name: {
        km: "ល្វាកោង",
        latin: "Lvea Kaong"
      },
      id: "14080305"
    },
    {
      name: {
        km: "ពាក់ស្បៃ",
        latin: "Peak Sbai"
      },
      id: "14080306"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "14080501"
    },
    {
      name: {
        km: "មេសរប្រចាន់លើ",
        latin: "Mesar Prachan Leu"
      },
      id: "14080502"
    },
    {
      name: {
        km: "មេសរប្រចាន់ក្រោម",
        latin: "Mesar Prachan Kraom"
      },
      id: "14080503"
    },
    {
      name: {
        km: "កំពង់ទ្រា",
        latin: "Kampong trea"
      },
      id: "14080504"
    },
    {
      name: {
        km: "ព្រែកចំប៉ា",
        latin: "Preaek Champa"
      },
      id: "14080505"
    },
    {
      name: {
        km: "មេមល់",
        latin: "Me Mol"
      },
      id: "14080506"
    },
    {
      name: {
        km: "យោត",
        latin: "Yout"
      },
      id: "14080507"
    },
    {
      name: {
        km: "អង្គាសដី",
        latin: "Angkeas Dei"
      },
      id: "14080701"
    },
    {
      name: {
        km: "ព្រហ្ម",
        latin: "Prum"
      },
      id: "14080702"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "14080703"
    },
    {
      name: {
        km: "ស្នាយភ្លើង",
        latin: "Snay Phleung"
      },
      id: "14080704"
    },
    {
      name: {
        km: "ព្រៃស្លា",
        latin: "Prey Sla"
      },
      id: "14080705"
    },
    {
      name: {
        km: "ព្រៃព្នៅ",
        latin: "Prey Pnov"
      },
      id: "14080706"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "14080707"
    },
    {
      name: {
        km: "សំរាប",
        latin: "Samreab"
      },
      id: "14080708"
    },
    {
      name: {
        km: "កគោ",
        latin: "Ka Kou"
      },
      id: "14080709"
    },
    {
      name: {
        km: "ស្រម៉",
        latin: "Srama"
      },
      id: "14080710"
    },
    {
      name: {
        km: "កំពង់ពោធិ៍",
        latin: "Kampong Pou"
      },
      id: "14080802"
    },
    {
      name: {
        km: "ទំញាំងជ្រើស",
        latin: "Tumpeang Chreus"
      },
      id: "14080803"
    },
    {
      name: {
        km: "ព្រៃស្នៀតខាងជើង",
        latin: "Prey Sniet Khang Choeung"
      },
      id: "14080805"
    },
    {
      name: {
        km: "ព្រៃស្នៀតខាងលិច",
        latin: "Prey Sniet Khang Lech"
      },
      id: "14080806"
    },
    {
      name: {
        km: "ព្រៃស្នៀតខាងកើត",
        latin: "Prey Sniet Khang Kaeut"
      },
      id: "14080807"
    },
    {
      name: {
        km: "មេលោងខាងជើង",
        latin: "Me Loung Khang Choeung"
      },
      id: "14080808"
    },
    {
      name: {
        km: "មេលោងខាងត្បូង",
        latin: "Me Loung Khang Tboung"
      },
      id: "14080809"
    },
    {
      name: {
        km: "ព្រះម្លូរ",
        latin: "Preah Mlu"
      },
      id: "14080901"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "14080902"
    },
    {
      name: {
        km: "ព្រហ៊ូត",
        latin: "Prohut"
      },
      id: "14080904"
    },
    {
      name: {
        km: "គកតូច",
        latin: "Kok Touch"
      },
      id: "14080907"
    },
    {
      name: {
        km: "គកធំ",
        latin: "Kok Thum"
      },
      id: "14080908"
    },
    {
      name: {
        km: "ព្រៃស្រឡិត",
        latin: "Prey Sralet"
      },
      id: "14080909"
    },
    {
      name: {
        km: "ព្រៃក្រឡាញ់តូច",
        latin: "Prey Kralanh Touch"
      },
      id: "14080910"
    },
    {
      name: {
        km: "ព្រៃក្រឡាញ់ធំ",
        latin: "Prey Kralanh Thum"
      },
      id: "14080911"
    },
    {
      name: {
        km: "ចុងព្រៃ",
        latin: "Chong Prey"
      },
      id: "14080912"
    },
    {
      name: {
        km: "ក្រាំង ក",
        latin: "Krang Kor"
      },
      id: "14080913"
    },
    {
      name: {
        km: "ក្រាំង ខ",
        latin: "Krang Khor"
      },
      id: "14080914"
    },
    {
      name: {
        km: "ជ្រៃ ក",
        latin: "Chrey Kor"
      },
      id: "14080915"
    },
    {
      name: {
        km: "ជ្រៃ ខ",
        latin: "Chrey Khor"
      },
      id: "14080916"
    },
    {
      name: {
        km: "ស្វាយ ក",
        latin: "Svay Kor"
      },
      id: "14080917"
    },
    {
      name: {
        km: "ស្វាយ ខ",
        latin: "Svay Khor"
      },
      id: "14080918"
    },
    {
      name: {
        km: "ពារាំង",
        latin: "Peareang"
      },
      id: "14081001"
    },
    {
      name: {
        km: "ចុងក្រូច",
        latin: "Chong Krouch"
      },
      id: "14081002"
    },
    {
      name: {
        km: "វៃវេទ",
        latin: "Vey Vet"
      },
      id: "14081003"
    },
    {
      name: {
        km: "ពាមអំពិល",
        latin: "Peam Ampil"
      },
      id: "14081004"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "14081005"
    },
    {
      name: {
        km: "ពោធិ៍សាបាង",
        latin: "Pou Sabang"
      },
      id: "14081006"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "14081007"
    },
    {
      name: {
        km: "ស្នាយពល",
        latin: "Snay Pol"
      },
      id: "14081101"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "14081102"
    },
    {
      name: {
        km: "ត្រកៀត",
        latin: "Trakiet"
      },
      id: "14081103"
    },
    {
      name: {
        km: "ឫស្សីជុក",
        latin: "Ruessei Chuk"
      },
      id: "14081104"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "14081105"
    },
    {
      name: {
        km: "ព្រៃសម្លាញ",
        latin: "Prey Samlanh"
      },
      id: "14081106"
    },
    {
      name: {
        km: "សន្លុង",
        latin: "Sanlung"
      },
      id: "14081107"
    },
    {
      name: {
        km: "តាំងស្នាយ",
        latin: "Tang Snay"
      },
      id: "14081108"
    },
    {
      name: {
        km: "មេលប់",
        latin: "Me Lob"
      },
      id: "14081109"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "14090101"
    },
    {
      name: {
        km: "លឹង្គ",
        latin: "Luengk"
      },
      id: "14090102"
    },
    {
      name: {
        km: "ពញាលៀង",
        latin: "Ponhea Lieng"
      },
      id: "14090103"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "14090104"
    },
    {
      name: {
        km: "ព្រៃអាស្ទាំង",
        latin: "Prey A Steang"
      },
      id: "14090105"
    },
    {
      name: {
        km: "ទឹកជូរ",
        latin: "Tuek Chur"
      },
      id: "14090106"
    },
    {
      name: {
        km: "ចំការតាម៉ូយ",
        latin: "Chamkar Ta Mouy"
      },
      id: "14090107"
    },
    {
      name: {
        km: "ឧកញ៉ាឯម",
        latin: "Oknha Aem"
      },
      id: "14090108"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "14090109"
    },
    {
      name: {
        km: "ព្រៃស្រម៉ោច",
        latin: "Prey Sramaoch"
      },
      id: "14090110"
    },
    {
      name: {
        km: "ស្វាយចារ",
        latin: "Svay Char"
      },
      id: "14090111"
    },
    {
      name: {
        km: "ហារ",
        latin: "Har"
      },
      id: "14090112"
    },
    {
      name: {
        km: "បឹងឥដ្ឋ",
        latin: "Boeng Edth"
      },
      id: "14090113"
    },
    {
      name: {
        km: "ព្រៃរំចាន់",
        latin: "Prey Rumchan"
      },
      id: "14090114"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "14090115"
    },
    {
      name: {
        km: "ព្រៃមាស",
        latin: "Prey Meas"
      },
      id: "14090116"
    },
    {
      name: {
        km: "ក្រសាំងទង",
        latin: "Krasang Tong"
      },
      id: "14090117"
    },
    {
      name: {
        km: "រកាជួរទី ១",
        latin: "Roka Chuor Ti Muoy"
      },
      id: "14090201"
    },
    {
      name: {
        km: "រកាជួរទី ២",
        latin: "Roka Chuor Ti Pir"
      },
      id: "14090202"
    },
    {
      name: {
        km: "រកាជួរទី ៣",
        latin: "Roka Chuor Ti Bei"
      },
      id: "14090203"
    },
    {
      name: {
        km: "ជៃតា",
        latin: "Chey Ta"
      },
      id: "14090204"
    },
    {
      name: {
        km: "ម្រេញ",
        latin: "Mrenh"
      },
      id: "14090205"
    },
    {
      name: {
        km: "ពោធិ៍រិយំ",
        latin: "Pou Riyum"
      },
      id: "14090206"
    },
    {
      name: {
        km: "កគោ",
        latin: "Ka Kou"
      },
      id: "14090207"
    },
    {
      name: {
        km: "ងែកងក",
        latin: "Ngeaek Ngok"
      },
      id: "14090208"
    },
    {
      name: {
        km: "ប្រោះស្វា",
        latin: "Praoh Sva"
      },
      id: "14090209"
    },
    {
      name: {
        km: "ដំរីស្លាប់",
        latin: "Damrei Slab"
      },
      id: "14090210"
    },
    {
      name: {
        km: "សំពោង",
        latin: "Sampoung"
      },
      id: "14090211"
    },
    {
      name: {
        km: "តាអឹម",
        latin: "Ta Oem"
      },
      id: "14090212"
    },
    {
      name: {
        km: "បឹងកំពត",
        latin: "Boeng Kampot"
      },
      id: "14090213"
    },
    {
      name: {
        km: "ប្របុសរលួយ",
        latin: "Prabos Roluoy"
      },
      id: "14090214"
    },
    {
      name: {
        km: "មុន្នីសិលា",
        latin: "Muni Sela"
      },
      id: "14090215"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "14090216"
    },
    {
      name: {
        km: "ពីងពង់",
        latin: "Ping Pong"
      },
      id: "14090217"
    },
    {
      name: {
        km: "ឫស្សីជុក",
        latin: "Ruessei Chuk"
      },
      id: "14090218"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "14090219"
    },
    {
      name: {
        km: "ពោធិ៍ជ្រែក",
        latin: "Pou Chreaek"
      },
      id: "14090220"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "14090301"
    },
    {
      name: {
        km: "តាអូក",
        latin: "Ta Ouk"
      },
      id: "14090302"
    },
    {
      name: {
        km: "សូរិយា",
        latin: "Souriya"
      },
      id: "14090303"
    },
    {
      name: {
        km: "បឹងដោល",
        latin: "Boeng Daol"
      },
      id: "14090304"
    },
    {
      name: {
        km: "ប៉ាក់ព្រី",
        latin: "Pak Pri"
      },
      id: "14090305"
    },
    {
      name: {
        km: "ក្លែងគង់",
        latin: "Klaeng Kong"
      },
      id: "14090306"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "14090307"
    },
    {
      name: {
        km: "ទឹកសិន",
        latin: "Tuek Sen"
      },
      id: "14090308"
    },
    {
      name: {
        km: "ថ្កោល",
        latin: "Thkaol"
      },
      id: "14090309"
    },
    {
      name: {
        km: "រការកូនសាត់",
        latin: "Roka Koun Sat"
      },
      id: "14090310"
    },
    {
      name: {
        km: "តាហៀវ",
        latin: "Ta Hiev"
      },
      id: "14090311"
    },
    {
      name: {
        km: "ក្ដីស្គា",
        latin: "Kdei Skea"
      },
      id: "14090401"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "14090402"
    },
    {
      name: {
        km: "ប្រថ្ម",
        latin: "Pra Thma"
      },
      id: "14090403"
    },
    {
      name: {
        km: "ជៃកំពក",
        latin: "Chey Kampok"
      },
      id: "14090404"
    },
    {
      name: {
        km: "ស្នោរ",
        latin: "Snaor"
      },
      id: "14090405"
    },
    {
      name: {
        km: "ត្រស់",
        latin: "Tras"
      },
      id: "14090406"
    },
    {
      name: {
        km: "ស្វាយទោល",
        latin: "Svay Toul"
      },
      id: "14090407"
    },
    {
      name: {
        km: "អង្គស្វាយទូ",
        latin: "Angk Svay Tu"
      },
      id: "14090408"
    },
    {
      name: {
        km: "កំពង់បាស្រី",
        latin: "Kampong Ba Srei"
      },
      id: "14090409"
    },
    {
      name: {
        km: "តាពូង",
        latin: "Ta Pung"
      },
      id: "14090410"
    },
    {
      name: {
        km: "ទួលលាន",
        latin: "Tuol Lean"
      },
      id: "14090411"
    },
    {
      name: {
        km: "ព្រៃបាស្រី",
        latin: "Prey Ba Srei"
      },
      id: "14090412"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "14090501"
    },
    {
      name: {
        km: "ពិលា",
        latin: "Pilea"
      },
      id: "14090502"
    },
    {
      name: {
        km: "ស្រះថ្កូវ",
        latin: "Srah Thkov"
      },
      id: "14090503"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "14090504"
    },
    {
      name: {
        km: "ភ្នៀត",
        latin: "Phniet"
      },
      id: "14090505"
    },
    {
      name: {
        km: "ស្វាយបាង",
        latin: "Svay Bang"
      },
      id: "14090506"
    },
    {
      name: {
        km: "តាម៉ៅ",
        latin: "Ta Mau"
      },
      id: "14090507"
    },
    {
      name: {
        km: "សឹង",
        latin: "Soeng"
      },
      id: "14090508"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "14090509"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "14090510"
    },
    {
      name: {
        km: "ក្រាំងក្អុក",
        latin: "Krang K'ok"
      },
      id: "14090511"
    },
    {
      name: {
        km: "ជំរៅ",
        latin: "Chumrov"
      },
      id: "14090512"
    },
    {
      name: {
        km: "សង្កែចុង",
        latin: "Sangkae Chong"
      },
      id: "14090601"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "14090602"
    },
    {
      name: {
        km: "ក្រាំងស្វាយ",
        latin: "Krang Svay"
      },
      id: "14090603"
    },
    {
      name: {
        km: "ទួលមានគុណ",
        latin: "Tuol Mean Kun"
      },
      id: "14090604"
    },
    {
      name: {
        km: "សុណនចៃ",
        latin: "Sonan Chai"
      },
      id: "14090605"
    },
    {
      name: {
        km: "ពាយនាយ",
        latin: "Peay Neay"
      },
      id: "14090606"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "14090607"
    },
    {
      name: {
        km: "គោកសំពៅ",
        latin: "Kouk Sampov"
      },
      id: "14090608"
    },
    {
      name: {
        km: "ស្រះកែវ",
        latin: "Srah Kaev"
      },
      id: "14090609"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "14090610"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "14090701"
    },
    {
      name: {
        km: "ត្នោតច្រុះ",
        latin: "Tnaot Chroh"
      },
      id: "14090702"
    },
    {
      name: {
        km: "ពានី",
        latin: "Peani"
      },
      id: "14090703"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "14090704"
    },
    {
      name: {
        km: "បឹងស្នោរ",
        latin: "Boeng Snaor"
      },
      id: "14090705"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "14090706"
    },
    {
      name: {
        km: "ស្វាយក្ដៀប",
        latin: "Svay Kdieb"
      },
      id: "14090707"
    },
    {
      name: {
        km: "ព្រៃក្ដួច",
        latin: "Prey Kduoch"
      },
      id: "14090708"
    },
    {
      name: {
        km: "កំពង់ថ្នល់",
        latin: "Kampong Thnal"
      },
      id: "14090709"
    },
    {
      name: {
        km: "តាគោក",
        latin: "Ta Kouk"
      },
      id: "14090710"
    },
    {
      name: {
        km: "ព្រំខ្សាច់",
        latin: "Prum Khsach"
      },
      id: "14090711"
    },
    {
      name: {
        km: "កងនាង",
        latin: "Kang Neang"
      },
      id: "14090801"
    },
    {
      name: {
        km: "ផុត",
        latin: "Phot"
      },
      id: "14090802"
    },
    {
      name: {
        km: "ព្រៃរុន",
        latin: "Prey Run"
      },
      id: "14090803"
    },
    {
      name: {
        km: "ឫស្សីទន្លេ",
        latin: "Ruessei Tonle"
      },
      id: "14090804"
    },
    {
      name: {
        km: "ព្រៃទ័ព",
        latin: "Prey Toap"
      },
      id: "14090805"
    },
    {
      name: {
        km: "ផ្លាំង",
        latin: "Phlang"
      },
      id: "14090806"
    },
    {
      name: {
        km: "ឫស្សីទទឹង",
        latin: "Ruessei Totueng"
      },
      id: "14090807"
    },
    {
      name: {
        km: "ត្នោតទោល",
        latin: "Tnaot Toul"
      },
      id: "14090808"
    },
    {
      name: {
        km: "តាកេត",
        latin: "Ta Ket"
      },
      id: "14090809"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "14090810"
    },
    {
      name: {
        km: "អន្លង់ចារ",
        latin: "Anlong Char"
      },
      id: "14090811"
    },
    {
      name: {
        km: "អន្ទ្រាក",
        latin: "Antreak"
      },
      id: "14090812"
    },
    {
      name: {
        km: "ទួលជៃ",
        latin: "Tuol Chey"
      },
      id: "14090813"
    },
    {
      name: {
        km: "ឈ្នះជៃលើ",
        latin: "Chhneah Chey Leu"
      },
      id: "14090901"
    },
    {
      name: {
        km: "ឈ្នះជៃក្រោម",
        latin: "Chhneah Chey Kraom"
      },
      id: "14090902"
    },
    {
      name: {
        km: "តាម៉ៅ",
        latin: "Ta Mau"
      },
      id: "14090903"
    },
    {
      name: {
        km: "តាហ៊េល ក",
        latin: "Ta Hel Ka"
      },
      id: "14090904"
    },
    {
      name: {
        km: "តាហ៊េល ខ",
        latin: "Ta Hel Kha"
      },
      id: "14090905"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "14091001"
    },
    {
      name: {
        km: "តាង៉ក",
        latin: "Ta Ngak"
      },
      id: "14091002"
    },
    {
      name: {
        km: "ទួលទ្រា",
        latin: "Tuol Trea"
      },
      id: "14091003"
    },
    {
      name: {
        km: "ជីពុត",
        latin: "Chi Put"
      },
      id: "14091004"
    },
    {
      name: {
        km: "ស្រះ",
        latin: "Srah"
      },
      id: "14091005"
    },
    {
      name: {
        km: "ជង្រុះ",
        latin: "Chongruh"
      },
      id: "14091006"
    },
    {
      name: {
        km: "សទ្ធា",
        latin: "Satthea"
      },
      id: "14091007"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "14091008"
    },
    {
      name: {
        km: "តាណាល",
        latin: "Ta Nal"
      },
      id: "14091009"
    },
    {
      name: {
        km: "ព្រៃផ្ញា",
        latin: "Prey Phnha"
      },
      id: "14091010"
    },
    {
      name: {
        km: "គោក",
        latin: "Kouk"
      },
      id: "14091011"
    },
    {
      name: {
        km: "ជើងទឹក",
        latin: "Cheung Tuek"
      },
      id: "14091012"
    },
    {
      name: {
        km: "សាលា",
        latin: "Sala"
      },
      id: "14091013"
    },
    {
      name: {
        km: "ក្រសារជេត",
        latin: "Krasar Chet"
      },
      id: "14091014"
    },
    {
      name: {
        km: "ឡឹក",
        latin: "Loek"
      },
      id: "14091015"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "14091016"
    },
    {
      name: {
        km: "ក្លែងគង់",
        latin: "Klaeng Kong"
      },
      id: "14091101"
    },
    {
      name: {
        km: "ក្ដាមពុក",
        latin: "Kdam Puk"
      },
      id: "14091102"
    },
    {
      name: {
        km: "ក្រសាំងចារ",
        latin: "Krasang Char"
      },
      id: "14091103"
    },
    {
      name: {
        km: "គំរាង",
        latin: "Kumreang"
      },
      id: "14091104"
    },
    {
      name: {
        km: "ស្នាយព្រឹម",
        latin: "Snay Pruem"
      },
      id: "14091105"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "14091106"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "14091107"
    },
    {
      name: {
        km: "ស្ពានជៃ",
        latin: "Spean Chey"
      },
      id: "14091108"
    },
    {
      name: {
        km: "ប្រស្នាធំ",
        latin: "Prasna Thum"
      },
      id: "14091109"
    },
    {
      name: {
        km: "ប្រស្នាតូច",
        latin: "Prasna Touch"
      },
      id: "14091110"
    },
    {
      name: {
        km: "ត្រពាំងប្របុស",
        latin: "Trapeang Brabos"
      },
      id: "14091111"
    },
    {
      name: {
        km: "ជៃអាខោល",
        latin: "Chey A Khaol"
      },
      id: "14091112"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "14091113"
    },
    {
      name: {
        km: "ខ្លាខាំ",
        latin: "Khla Kham"
      },
      id: "14091114"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "14091115"
    },
    {
      name: {
        km: "ស្នោរ",
        latin: "Snaor"
      },
      id: "14091116"
    },
    {
      name: {
        km: "សំនយ",
        latin: "Samnoy"
      },
      id: "14091117"
    },
    {
      name: {
        km: "កំរោល",
        latin: "Kamraol"
      },
      id: "14091118"
    },
    {
      name: {
        km: "បារាយណ៍កើត",
        latin: "Baray Kaeut"
      },
      id: "14100101"
    },
    {
      name: {
        km: "បារាយណ៍លិច",
        latin: "Baray Lech"
      },
      id: "14100102"
    },
    {
      name: {
        km: "ជើងទឹក ក",
        latin: "Cheung Tuek Ka"
      },
      id: "14100201"
    },
    {
      name: {
        km: "ជើងទឹក ខ",
        latin: "Cheung Tuek Kha"
      },
      id: "14100202"
    },
    {
      name: {
        km: "កំពង់អាទង់",
        latin: "Kampong A Tong"
      },
      id: "14100203"
    },
    {
      name: {
        km: "ស្វាយសុខោ",
        latin: "Svay Sokhao"
      },
      id: "14100204"
    },
    {
      name: {
        km: "ឯករាម",
        latin: "Aek Ream"
      },
      id: "14100205"
    },
    {
      name: {
        km: "លេខ១",
        latin: "Lekh Muoy"
      },
      id: "14100301"
    },
    {
      name: {
        km: "លេខ២",
        latin: "Lekh Pir"
      },
      id: "14100302"
    },
    {
      name: {
        km: "លេខ៣",
        latin: "Lekh Bei"
      },
      id: "14100303"
    },
    {
      name: {
        km: "លេខ៤",
        latin: "Lekh Buon"
      },
      id: "14100304"
    },
    {
      name: {
        km: "លេខ៥",
        latin: "Lekh Pram"
      },
      id: "14100305"
    },
    {
      name: {
        km: "លេខ៦",
        latin: "Lekh Prammuoy"
      },
      id: "14100306"
    },
    {
      name: {
        km: "លេខ៧",
        latin: "Lekh Prampir"
      },
      id: "14100307"
    },
    {
      name: {
        km: "លេខ៨",
        latin: "Lekh Prambei"
      },
      id: "14100308"
    },
    {
      name: {
        km: "កុកត្រុំ ក",
        latin: "Kok Trom Ka"
      },
      id: "14100401"
    },
    {
      name: {
        km: "កុកត្រុំ ខ",
        latin: "Kok Trom Kha"
      },
      id: "14100402"
    },
    {
      name: {
        km: "ខ្នយ",
        latin: "Khnoy"
      },
      id: "14100403"
    },
    {
      name: {
        km: "ភ្លយ",
        latin: "Phloy"
      },
      id: "14100404"
    },
    {
      name: {
        km: "ស្គដាច",
        latin: "Skor Dach"
      },
      id: "14100405"
    },
    {
      name: {
        km: "ត្នោតទ្រេត",
        latin: "Tnaot Tret"
      },
      id: "14100406"
    },
    {
      name: {
        km: "តាកោ",
        latin: "Ta Kao"
      },
      id: "14100407"
    },
    {
      name: {
        km: "ភគ្គនេស្ស",
        latin: "Pheakkeaknes"
      },
      id: "14100408"
    },
    {
      name: {
        km: "ពោធិ៍រៀងជើង",
        latin: "Pou Rieng Cheung"
      },
      id: "14110101"
    },
    {
      name: {
        km: "ពោធិ៍រៀងត្បូង",
        latin: "Pou Rieng Tboung"
      },
      id: "14110102"
    },
    {
      name: {
        km: "វាលព្រៅ",
        latin: "Veal Prov"
      },
      id: "14110103"
    },
    {
      name: {
        km: "យាយសល់",
        latin: "Yeay Sal"
      },
      id: "14110104"
    },
    {
      name: {
        km: "ព្រែកអន្ទះ",
        latin: "Preaek Anteah"
      },
      id: "14110201"
    },
    {
      name: {
        km: "ព្រែកផ្គាំ",
        latin: "Preaek Phkoam"
      },
      id: "14110202"
    },
    {
      name: {
        km: "ច្រាំងទទឹង",
        latin: "Chrang Totueng"
      },
      id: "14110203"
    },
    {
      name: {
        km: "តាម៉ោ",
        latin: "Ta Mao"
      },
      id: "14110204"
    },
    {
      name: {
        km: "តាមែង",
        latin: "Ta Meaeng"
      },
      id: "14110205"
    },
    {
      name: {
        km: "អង្គរយស",
        latin: "Angkor Yos"
      },
      id: "14110206"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14110207"
    },
    {
      name: {
        km: "ព្រែកជ្រៃលើ",
        latin: "Preaek Chrey Leu"
      },
      id: "14110301"
    },
    {
      name: {
        km: "ព្រែកជ្រៃក្រោម",
        latin: "Preaek Chrey Kraom"
      },
      id: "14110302"
    },
    {
      name: {
        km: "អន្លង់ទ្រា",
        latin: "Anlong Trea"
      },
      id: "14110303"
    },
    {
      name: {
        km: "បាក់ដោក",
        latin: "Bak Daok"
      },
      id: "14110304"
    },
    {
      name: {
        km: "ពាមស្ដី",
        latin: "Peam Sdei"
      },
      id: "14110305"
    },
    {
      name: {
        km: "ស្វាយកំពីងពួយ",
        latin: "Svay Kamping Puoy"
      },
      id: "14110401"
    },
    {
      name: {
        km: "កំពង់ក្រមួន",
        latin: "Kampong Kra muon"
      },
      id: "14110402"
    },
    {
      name: {
        km: "ជ្រាវ",
        latin: "Chreav"
      },
      id: "14110403"
    },
    {
      name: {
        km: "ពពឹស",
        latin: "Popueus"
      },
      id: "14110404"
    },
    {
      name: {
        km: "ព្រៃកន្លោង",
        latin: "Prey Kanlaong"
      },
      id: "14110405"
    },
    {
      name: {
        km: "កំពង់ឫស្សី",
        latin: "Kampong Ruessei"
      },
      id: "14110601"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "14110602"
    },
    {
      name: {
        km: "ព្រៃមាស",
        latin: "Prey Meas"
      },
      id: "14110603"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "14110604"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "14110605"
    },
    {
      name: {
        km: "ជ្រៃគ្រហ៊ឹម",
        latin: "Chrey Krohuem"
      },
      id: "14110606"
    },
    {
      name: {
        km: "ព្រែកក្រូច",
        latin: "Preaek Krouch"
      },
      id: "14110701"
    },
    {
      name: {
        km: "ព្រែកឈ្មោះ",
        latin: "Preaek Chhmuoh"
      },
      id: "14110702"
    },
    {
      name: {
        km: "ព្រែកតាសរ",
        latin: "Preaek Ta Sar"
      },
      id: "14110703"
    },
    {
      name: {
        km: "តាព្រះ",
        latin: "Ta Preah"
      },
      id: "14110704"
    },
    {
      name: {
        km: "ស្វាយទាប",
        latin: "Svay Teab"
      },
      id: "14120101"
    },
    {
      name: {
        km: "ពានា",
        latin: "Peanea"
      },
      id: "14120102"
    },
    {
      name: {
        km: "ក្បាលបឹង",
        latin: "Kbal Boeng"
      },
      id: "14120103"
    },
    {
      name: {
        km: "ទួលជ្រៃ",
        latin: "Tuol Chrey"
      },
      id: "14120104"
    },
    {
      name: {
        km: "អំពិលក្រៅ",
        latin: "Ampil Krau"
      },
      id: "14120105"
    },
    {
      name: {
        km: "ព្រៃស្វា",
        latin: "Prey Sva"
      },
      id: "14120201"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "14120202"
    },
    {
      name: {
        km: "កន្ទ្រោញ",
        latin: "Kantrounh"
      },
      id: "14120203"
    },
    {
      name: {
        km: "បឹងឡែង",
        latin: "Boeng Laeng"
      },
      id: "14120204"
    },
    {
      name: {
        km: "ជ្រៃឃ្មុំ",
        latin: "Chrey Khmum"
      },
      id: "14120205"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "14120206"
    },
    {
      name: {
        km: "តាណាល",
        latin: "Ta Nal"
      },
      id: "14120207"
    },
    {
      name: {
        km: "ឡាំឡូង",
        latin: "Lam Loung"
      },
      id: "14120208"
    },
    {
      name: {
        km: "ល្វេ",
        latin: "Lve"
      },
      id: "14120301"
    },
    {
      name: {
        km: "អំបែងចេះ",
        latin: "Ambaeng Cheh"
      },
      id: "14120302"
    },
    {
      name: {
        km: "កោងទន្លេលើ",
        latin: "Kaong Tonle Leu"
      },
      id: "14120303"
    },
    {
      name: {
        km: "កោងទន្លេក្រោម",
        latin: "Kaong Tonle Kraom"
      },
      id: "14120304"
    },
    {
      name: {
        km: "ព្រុំម្នី",
        latin: "Prum Mni"
      },
      id: "14120305"
    },
    {
      name: {
        km: "កោងស្រែ",
        latin: "Kaong Srae"
      },
      id: "14120306"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "14120401"
    },
    {
      name: {
        km: "តាគ្រាប",
        latin: "Ta Kreab"
      },
      id: "14120402"
    },
    {
      name: {
        km: "កំព្រៅ",
        latin: "Kamprov"
      },
      id: "14120403"
    },
    {
      name: {
        km: "ផាត់សណ្ដោង",
        latin: "Phat Sandaong"
      },
      id: "14120404"
    },
    {
      name: {
        km: "ទួលស្រឹង",
        latin: "Tuol Sroeng"
      },
      id: "14120405"
    },
    {
      name: {
        km: "ទួលអង្គុញ",
        latin: "Tuol Angkunh"
      },
      id: "14120406"
    },
    {
      name: {
        km: "បន្លិចស្វាយ",
        latin: "Banlich Svay"
      },
      id: "14120407"
    },
    {
      name: {
        km: "តាង៉កស្រែ",
        latin: "Ta Ngak Srae"
      },
      id: "14120501"
    },
    {
      name: {
        km: "ស្គាស្រុក",
        latin: "Skea Srok"
      },
      id: "14120502"
    },
    {
      name: {
        km: "ស្គាក្ដី",
        latin: "Skea  Kdei"
      },
      id: "14120503"
    },
    {
      name: {
        km: "តាង៉កទន្លេ",
        latin: "Ta Ngak Tonle"
      },
      id: "14120504"
    },
    {
      name: {
        km: "ចុង",
        latin: "Chong"
      },
      id: "14120601"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "14120602"
    },
    {
      name: {
        km: "ពោធិ៍ទី",
        latin: "Pou Ti"
      },
      id: "14120603"
    },
    {
      name: {
        km: "ស្វាយសាក់",
        latin: "Svay Sak"
      },
      id: "14120604"
    },
    {
      name: {
        km: "ព្រៃព្រហ្ម",
        latin: "Prey Prum"
      },
      id: "14120605"
    },
    {
      name: {
        km: "ត្រពាំងចាម",
        latin: "Trapeang Cham"
      },
      id: "14120606"
    },
    {
      name: {
        km: "ព្រែកចង្ក្រានលើ",
        latin: "Preaek Changkran Leu"
      },
      id: "14120701"
    },
    {
      name: {
        km: "ព្រែកចង្ក្រានក្រោម",
        latin: "Preaek Changkran Kraom"
      },
      id: "14120702"
    },
    {
      name: {
        km: "បាប្រីយ៍",
        latin: "Ba Prei"
      },
      id: "14120703"
    },
    {
      name: {
        km: "ព្រែកព្នៅ",
        latin: "Preaek Pnov"
      },
      id: "14120704"
    },
    {
      name: {
        km: "ព្រែកសណ្ដែក",
        latin: "Preaek Sandaek"
      },
      id: "14120705"
    },
    {
      name: {
        km: "ព្រៃដើមថ្នឹងទី ១",
        latin: "Prey Daeum Thnoeng Ti Muoy"
      },
      id: "14120801"
    },
    {
      name: {
        km: "ព្រៃដើមថ្នឹងទី ២",
        latin: "Prey Daeum Thnoeng Ti Pir"
      },
      id: "14120802"
    },
    {
      name: {
        km: "ព្រៃដើមថ្នឹងទី ៣",
        latin: "Prey Daeum Thnoeng Ti Bei"
      },
      id: "14120803"
    },
    {
      name: {
        km: "ព្រៃដើមថ្នឹងទី ៤",
        latin: "Prey Daeum Thnoeng Ti Buon"
      },
      id: "14120804"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "14120901"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "14120902"
    },
    {
      name: {
        km: "ព្រៃទឹង",
        latin: "Prey Tueng"
      },
      id: "14120903"
    },
    {
      name: {
        km: "បឹងជ័រ",
        latin: "Boeng Choar"
      },
      id: "14120904"
    },
    {
      name: {
        km: "គោករការ",
        latin: "Kouk Rokar"
      },
      id: "14120905"
    },
    {
      name: {
        km: "រលាំង",
        latin: "Roleang"
      },
      id: "14121001"
    },
    {
      name: {
        km: "ព្រៃលាន",
        latin: "Prey Lean"
      },
      id: "14121002"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "14121003"
    },
    {
      name: {
        km: "ព្រៃពោធិ៍",
        latin: "Prey Pou"
      },
      id: "14121004"
    },
    {
      name: {
        km: "អន្លង់សរ",
        latin: "Anlong Sar"
      },
      id: "14121005"
    },
    {
      name: {
        km: "កំភារ",
        latin: "Kamphear"
      },
      id: "14121006"
    },
    {
      name: {
        km: "ស្វាយជីក្រាយ",
        latin: "Svay Chi Kray"
      },
      id: "14121101"
    },
    {
      name: {
        km: "ឫស្សីសាញ់",
        latin: "Ruessei Sanh"
      },
      id: "14121102"
    },
    {
      name: {
        km: "យស់",
        latin: "Yos"
      },
      id: "14121103"
    },
    {
      name: {
        km: "ព្រៃឈៀង",
        latin: "Prey Chhieng"
      },
      id: "14121104"
    },
    {
      name: {
        km: "អង្គរទ្រេត",
        latin: "Angkor Tret"
      },
      id: "14130101"
    },
    {
      name: {
        km: "ត្របែកលិច",
        latin: "Trabaek Lech"
      },
      id: "14130102"
    },
    {
      name: {
        km: "ត្របែកកើត",
        latin: "Trabaek Kaeut"
      },
      id: "14130103"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "14130104"
    },
    {
      name: {
        km: "តារាមលិច",
        latin: "Ta Ream Lech"
      },
      id: "14130105"
    },
    {
      name: {
        km: "តារាមកើត",
        latin: "Ta Ream Kaeut"
      },
      id: "14130106"
    },
    {
      name: {
        km: "វត្ដត្រាច",
        latin: "Voat Trach"
      },
      id: "14130107"
    },
    {
      name: {
        km: "ឫស្សីធ្លក",
        latin: "Ruessei Thlok"
      },
      id: "14130108"
    },
    {
      name: {
        km: "ស្រឡូងទី១",
        latin: "Sraloung Ti Muoy"
      },
      id: "14130109"
    },
    {
      name: {
        km: "ស្រឡូងទី២",
        latin: "Sraloung Ti Pir"
      },
      id: "14130110"
    },
    {
      name: {
        km: "ស្រឡូងទី៣",
        latin: "Sraloung Ti Bei"
      },
      id: "14130111"
    },
    {
      name: {
        km: "តាឡើក",
        latin: "Tra Laeuk"
      },
      id: "14130112"
    },
    {
      name: {
        km: "បឹងរ៉ាច់",
        latin: "Boeng Rach"
      },
      id: "14130113"
    },
    {
      name: {
        km: "ជ្រៃសីម៉ា",
        latin: "Chrey Seima"
      },
      id: "14130201"
    },
    {
      name: {
        km: "ព្រៃឫស្សី",
        latin: "Prey Ruessei"
      },
      id: "14130202"
    },
    {
      name: {
        km: "ខ្លាពារ",
        latin: "Khla Pear"
      },
      id: "14130203"
    },
    {
      name: {
        km: "ឆ្កែកូន",
        latin: "Chhkae Koun"
      },
      id: "14130204"
    },
    {
      name: {
        km: "ដូនយូ",
        latin: "Doun Yu"
      },
      id: "14130205"
    },
    {
      name: {
        km: "ជាខ្លាង",
        latin: "Chea Khlang"
      },
      id: "14130206"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "14130207"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "14130208"
    },
    {
      name: {
        km: "គោករវៀង",
        latin: "Kouk Rovieng"
      },
      id: "14130301"
    },
    {
      name: {
        km: "ព្រៃតាណាន់",
        latin: "Prey Ta Nan"
      },
      id: "14130302"
    },
    {
      name: {
        km: "ស្វាយគុណ",
        latin: "Svay Kun"
      },
      id: "14130303"
    },
    {
      name: {
        km: "ព្រៃតាម៉ុក",
        latin: "Prey Ta Mok"
      },
      id: "14130304"
    },
    {
      name: {
        km: "ក្រសាំងគយ",
        latin: "Krasang Koy"
      },
      id: "14130305"
    },
    {
      name: {
        km: "មេនងក្រោម",
        latin: "Menong Kraom"
      },
      id: "14130306"
    },
    {
      name: {
        km: "ឫស្សីស្លាប់",
        latin: "Ruessei Slab"
      },
      id: "14130307"
    },
    {
      name: {
        km: "ស្វាយអាត់",
        latin: "Svay At"
      },
      id: "14130308"
    },
    {
      name: {
        km: "មេនងលើ",
        latin: "Menong Leu"
      },
      id: "14130309"
    },
    {
      name: {
        km: "ម៉ឺនពួក",
        latin: "Meun Puok"
      },
      id: "14130310"
    },
    {
      name: {
        km: "ព្រៃមហាចាន់",
        latin: "Prey Moha Chan"
      },
      id: "14130311"
    },
    {
      name: {
        km: "ជ្រៃវាល",
        latin: "Chrey Veal"
      },
      id: "14130312"
    },
    {
      name: {
        km: "ជ្រៃផ្សារ",
        latin: "Chrey Phsar"
      },
      id: "14130313"
    },
    {
      name: {
        km: "ព្រៃចារ",
        latin: "Prey Char"
      },
      id: "14130314"
    },
    {
      name: {
        km: "ព្រហ៊ារ",
        latin: "Prohear"
      },
      id: "14130315"
    },
    {
      name: {
        km: "ដូនយុគ",
        latin: "Doun Yuk"
      },
      id: "14130316"
    },
    {
      name: {
        km: "ចំការគួយលិច",
        latin: "Chamkar Kuoy Lech"
      },
      id: "14130401"
    },
    {
      name: {
        km: "ចំការគួយកើត",
        latin: "Chamkar Kuoy Kaeut"
      },
      id: "14130402"
    },
    {
      name: {
        km: "អូរកណ្ដោលត្បូង",
        latin: "Ou Kandaol Tboung"
      },
      id: "14130403"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "14130404"
    },
    {
      name: {
        km: "គាំប្រដឺស",
        latin: "Koam Pradeus"
      },
      id: "14130405"
    },
    {
      name: {
        km: "បន្ទាយស្រែ",
        latin: "Banteay Srae"
      },
      id: "14130406"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "14130407"
    },
    {
      name: {
        km: "វាលតូច",
        latin: "Veal Touch"
      },
      id: "14130408"
    },
    {
      name: {
        km: "ព្រៃភ្លាំង",
        latin: "Prey Phleang"
      },
      id: "14130409"
    },
    {
      name: {
        km: "កូនត្នោត",
        latin: "Koun Tnaot"
      },
      id: "14130410"
    },
    {
      name: {
        km: "បិតមាស",
        latin: "Bet Meas"
      },
      id: "14130411"
    },
    {
      name: {
        km: "បឹងវែង",
        latin: "Boeng Veaeng"
      },
      id: "14130412"
    },
    {
      name: {
        km: "ត្បូងក្ដី",
        latin: "Tboung Kdei"
      },
      id: "14130413"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Bos"
      },
      id: "14130414"
    },
    {
      name: {
        km: "ព្រៃរូង",
        latin: "Prey Rung"
      },
      id: "14130415"
    },
    {
      name: {
        km: "ដំរីពួន",
        latin: "Damrei Puon"
      },
      id: "14130416"
    },
    {
      name: {
        km: "អូរកណ្ដោលជើង",
        latin: "Ou Kandaol Cheung"
      },
      id: "14130417"
    },
    {
      name: {
        km: "ចចកទី ១",
        latin: "Chachak Ti Muoy"
      },
      id: "14130501"
    },
    {
      name: {
        km: "ចចកទី ២",
        latin: "Chachak Ti Pir"
      },
      id: "14130502"
    },
    {
      name: {
        km: "មេបុណ្យ",
        latin: "Me Bon"
      },
      id: "14130503"
    },
    {
      name: {
        km: "រកាខ្សុក",
        latin: "Roka Khsok"
      },
      id: "14130504"
    },
    {
      name: {
        km: "ភ្នំគង់",
        latin: "Phnum Kong"
      },
      id: "14130505"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "14130506"
    },
    {
      name: {
        km: "ហាបូរ",
        latin: "Ha Bour"
      },
      id: "14130507"
    },
    {
      name: {
        km: "ព្រៃជ្រាំង",
        latin: "Prey Chreang"
      },
      id: "14130601"
    },
    {
      name: {
        km: "ត្រពាំងតាមោក",
        latin: "Trapeang Ta Mouk"
      },
      id: "14130602"
    },
    {
      name: {
        km: "ត្រពាំងស្មៅ",
        latin: "Trapeang Smau"
      },
      id: "14130603"
    },
    {
      name: {
        km: "ក្រាំងចំបក់",
        latin: "Krang Chambak"
      },
      id: "14130604"
    },
    {
      name: {
        km: "អណ្ដូងថ្ម",
        latin: "Andoung Thma"
      },
      id: "14130605"
    },
    {
      name: {
        km: "ជ្រៃរលើង",
        latin: "Chrey Roleung"
      },
      id: "14130606"
    },
    {
      name: {
        km: "ព្រៃរំពាក់",
        latin: "Prey Rumpeak"
      },
      id: "14130607"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "14130608"
    },
    {
      name: {
        km: "ព្រៃរូង",
        latin: "Prey Rung"
      },
      id: "14130609"
    },
    {
      name: {
        km: "ពានរោង",
        latin: "Pean Roung"
      },
      id: "14130610"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "14130611"
    },
    {
      name: {
        km: "ឆ្អឹងពស់",
        latin: "Chh'oeng Pos"
      },
      id: "14130612"
    },
    {
      name: {
        km: "ព្រៃនគរក្នុង",
        latin: "Prey Nokor Knong"
      },
      id: "14130613"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "14130614"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "14130615"
    },
    {
      name: {
        km: "កូនពោធិ៍",
        latin: "Koun Pou"
      },
      id: "14130616"
    },
    {
      name: {
        km: "ព្រៃវល្លិ",
        latin: "Prey Voa"
      },
      id: "14130617"
    },
    {
      name: {
        km: "ព្រៃឆ្លុង",
        latin: "Prey Chhlong"
      },
      id: "14130618"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "14130619"
    },
    {
      name: {
        km: "ដុំ",
        latin: "Dom"
      },
      id: "14130620"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "14130621"
    },
    {
      name: {
        km: "ខ្សោកជើង",
        latin: "Khyaok Cheung"
      },
      id: "14130701"
    },
    {
      name: {
        km: "ខ្សោកកណ្ដាល",
        latin: "Khyaok Kandal"
      },
      id: "14130702"
    },
    {
      name: {
        km: "ខ្សោកត្បូង",
        latin: "Khyaok Tboung"
      },
      id: "14130703"
    },
    {
      name: {
        km: "ថ្នល់ជ័យ",
        latin: "Thnal Chey"
      },
      id: "14130704"
    },
    {
      name: {
        km: "ព្រៃសុធន់",
        latin: "Prey Sothon"
      },
      id: "14130801"
    },
    {
      name: {
        km: "តងឡងទី ១",
        latin: "Tanglang Ti Muoy"
      },
      id: "14130802"
    },
    {
      name: {
        km: "តងឡងទី ២",
        latin: "Tanglang Ti Pir"
      },
      id: "14130803"
    },
    {
      name: {
        km: "តងឡងទី ៣",
        latin: "Tanglang Ti Bei"
      },
      id: "14130804"
    },
    {
      name: {
        km: "ព្រៃខ្លាទី ១",
        latin: "Prey Khla Ti Muoy"
      },
      id: "14130805"
    },
    {
      name: {
        km: "ព្រៃខ្លាទី ២",
        latin: "Prey Khla Ti Pir"
      },
      id: "14130806"
    },
    {
      name: {
        km: "ក្រចាបទី ១",
        latin: "Krachab Ti Muoy"
      },
      id: "14130807"
    },
    {
      name: {
        km: "ក្រចាបទី ២",
        latin: "Krachab Ti Pir"
      },
      id: "14130808"
    },
    {
      name: {
        km: "ក្រចាបទី ៣",
        latin: "Krachab Ti Bei"
      },
      id: "14130809"
    },
    {
      name: {
        km: "បាក់តាង",
        latin: "Bak Tang"
      },
      id: "14130810"
    },
    {
      name: {
        km: "សុបិន",
        latin: "Soben"
      },
      id: "14130811"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "14130812"
    },
    {
      name: {
        km: "សង្វាន",
        latin: "Sangvan"
      },
      id: "14130813"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "14130901"
    },
    {
      name: {
        km: "ព្រលឹងមាស",
        latin: "Prolueng Meas"
      },
      id: "14130902"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "14130903"
    },
    {
      name: {
        km: "ត្រពាំងឥដ្ឋ",
        latin: "Trapeang Edth"
      },
      id: "14130904"
    },
    {
      name: {
        km: "ព្រៃសំឡាញ",
        latin: "Prey Samlanh"
      },
      id: "14130905"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "14130906"
    },
    {
      name: {
        km: "ព្រៃឃ្នង",
        latin: "Prey Khnong"
      },
      id: "14130907"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "14130908"
    },
    {
      name: {
        km: "ព្រហូត",
        latin: "Prohut"
      },
      id: "14130909"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "14130910"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "14130911"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "14130912"
    },
    {
      name: {
        km: "តាគាត់",
        latin: "Ta Koat"
      },
      id: "14130913"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "14130914"
    },
    {
      name: {
        km: "ស្វាយអន្ទរទី ១",
        latin: "Svay Antor Ti Muoy"
      },
      id: "14131001"
    },
    {
      name: {
        km: "ស្វាយអន្ទរទី ២",
        latin: "Svay Antor Ti Pir"
      },
      id: "14131002"
    },
    {
      name: {
        km: "ពោធិ៍ចិន្ដាំ",
        latin: "Pou Chentam"
      },
      id: "14131003"
    },
    {
      name: {
        km: "ពោធិ៍ជ្រៃ",
        latin: "Pou Chrey"
      },
      id: "14131004"
    },
    {
      name: {
        km: "ព្រៃខ្វែក",
        latin: "Prey Khvaek"
      },
      id: "14131005"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "14131006"
    },
    {
      name: {
        km: "ថ្លាវ",
        latin: "Thlav"
      },
      id: "14131007"
    },
    {
      name: {
        km: "ត្រោកតាប៉ាង",
        latin: "Traok Ta Pang"
      },
      id: "14131101"
    },
    {
      name: {
        km: "ព្រៃគាវ",
        latin: "Prey Keav"
      },
      id: "14131102"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "14131103"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "14131104"
    },
    {
      name: {
        km: "ឫស្សីទ្វា",
        latin: "Ruessei Tvea"
      },
      id: "14131105"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "14131106"
    },
    {
      name: {
        km: "សង្កែជ្រុំ",
        latin: "Sangkae Chrum"
      },
      id: "14131107"
    },
    {
      name: {
        km: "ទួលអំពិល",
        latin: "Tuol Ampil"
      },
      id: "14131108"
    },
    {
      name: {
        km: "ប្រអួលទឹក",
        latin: "Pra'uol Tuek"
      },
      id: "14131109"
    },
    {
      name: {
        km: "ព្រៃល្វាយ",
        latin: "Prey Lveay"
      },
      id: "14131110"
    },
    {
      name: {
        km: "បូតគង",
        latin: "Bout Kong"
      },
      id: "14131111"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "14131112"
    },
    {
      name: {
        km: "កន្លោង",
        latin: "Kanlaong"
      },
      id: "14131113"
    },
    {
      name: {
        km: "បន្ទាយ",
        latin: "Banteay"
      },
      id: "14131114"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "14131115"
    },
    {
      name: {
        km: "ព្រិច",
        latin: "Prich"
      },
      id: "14131116"
    },
    {
      name: {
        km: "គ្រង់",
        latin: "Krong"
      },
      id: "14131117"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "14131118"
    },
    {
      name: {
        km: "ដូងជ្រុំ",
        latin: "Doung Chrum"
      },
      id: "15010101"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "15010102"
    },
    {
      name: {
        km: "ត្រាំំសេះ",
        latin: "Tram Seh"
      },
      id: "15010103"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "15010104"
    },
    {
      name: {
        km: "បត់ត្រាច",
        latin: "Bat Trach"
      },
      id: "15010105"
    },
    {
      name: {
        km: "របងរមាស",
        latin: "Robang Romeas"
      },
      id: "15010106"
    },
    {
      name: {
        km: "បត់កណ្ដោល",
        latin: "Bat Kandaol"
      },
      id: "15010107"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "15010108"
    },
    {
      name: {
        km: "ព្រះអំពិល",
        latin: "Preah Ampil"
      },
      id: "15010109"
    },
    {
      name: {
        km: "បឹងឈូក",
        latin: "Boeng Chhuk"
      },
      id: "15010110"
    },
    {
      name: {
        km: "ទួលធ្មា",
        latin: "Tuol Thmea"
      },
      id: "15010111"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "15010112"
    },
    {
      name: {
        km: "ឬស្សីតាម៉ាន់",
        latin: "Ruessei Ta Man"
      },
      id: "15010114"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "15010201"
    },
    {
      name: {
        km: "បឹងខ្នារ",
        latin: "Boeng Khnar"
      },
      id: "15010202"
    },
    {
      name: {
        km: "ក្រសាំងគ្រួ",
        latin: "Krasang Kruo"
      },
      id: "15010203"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "15010204"
    },
    {
      name: {
        km: "វត្ដជ្រែ",
        latin: "Voat Chreae"
      },
      id: "15010205"
    },
    {
      name: {
        km: "ព្រះម្លូ",
        latin: "Preah Mlu"
      },
      id: "15010206"
    },
    {
      name: {
        km: "ព្រៃស្វាយ",
        latin: "Prey Svay"
      },
      id: "15010207"
    },
    {
      name: {
        km: "ចំការលើ",
        latin: "Chamkar Leu"
      },
      id: "15010208"
    },
    {
      name: {
        km: "ព្រៃដំរី",
        latin: "Prey Damrei"
      },
      id: "15010209"
    },
    {
      name: {
        km: "ខ្នាចរមាស",
        latin: "Khnach Romeas"
      },
      id: "15010210"
    },
    {
      name: {
        km: "ស្រករ",
        latin: "Srakar"
      },
      id: "15010211"
    },
    {
      name: {
        km: "ដើមច្រេស",
        latin: "Daeum Chres"
      },
      id: "15010212"
    },
    {
      name: {
        km: "ត្រាចក្រោល",
        latin: "Trach Kraol"
      },
      id: "15010215"
    },
    {
      name: {
        km: "ដើមរកា",
        latin: "Daeum Roka"
      },
      id: "15010301"
    },
    {
      name: {
        km: "កំប្រាក់កូន",
        latin: "Kamprak Koun"
      },
      id: "15010303"
    },
    {
      name: {
        km: "ទួលអង្គ្រង",
        latin: "Tuol Angkrong"
      },
      id: "15010304"
    },
    {
      name: {
        km: "ក្រូចសើច",
        latin: "Krouch Saeuch"
      },
      id: "15010305"
    },
    {
      name: {
        km: "ខ្នារទទឹង",
        latin: "Khnar Totueng"
      },
      id: "15010306"
    },
    {
      name: {
        km: "បឹងឈូក",
        latin: "Boeng Chhuk"
      },
      id: "15010307"
    },
    {
      name: {
        km: "ផ្ទះស្លា",
        latin: "Phteah Sla"
      },
      id: "15010308"
    },
    {
      name: {
        km: "កោះក្របី",
        latin: "Kaoh Krabei"
      },
      id: "15010309"
    },
    {
      name: {
        km: "ដំណាក់ធ្នង់",
        latin: "Damnak Thnong"
      },
      id: "15010310"
    },
    {
      name: {
        km: "បាក់មែក",
        latin: "Bak Meaek"
      },
      id: "15010311"
    },
    {
      name: {
        km: "អង្កាញ់",
        latin: "Angkanh"
      },
      id: "15010401"
    },
    {
      name: {
        km: "អូរព្រាល",
        latin: "Ou Preal"
      },
      id: "15010402"
    },
    {
      name: {
        km: "ចាយ៉ូវ",
        latin: "Cha Yov"
      },
      id: "15010403"
    },
    {
      name: {
        km: "ក្ដាត",
        latin: "Kdat"
      },
      id: "15010404"
    },
    {
      name: {
        km: "ប៉ោឡោ",
        latin: "Paolao"
      },
      id: "15010405"
    },
    {
      name: {
        km: "តាមុំ",
        latin: "Ta Mom"
      },
      id: "15010406"
    },
    {
      name: {
        km: "ចិនតាយ",
        latin: "Chen Tay"
      },
      id: "15010407"
    },
    {
      name: {
        km: "មេទឹក",
        latin: "Me Tuek"
      },
      id: "15010408"
    },
    {
      name: {
        km: "កោះខ្សាច់",
        latin: "Kaoh Khsach"
      },
      id: "15010409"
    },
    {
      name: {
        km: "ម៉",
        latin: "Ma"
      },
      id: "15010410"
    },
    {
      name: {
        km: "ដីរនាត",
        latin: "Dei Roneat"
      },
      id: "15010411"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Trang"
      },
      id: "15010412"
    },
    {
      name: {
        km: "សំរោងព្រៃខៀវ",
        latin: "Samraong Prey Khiev"
      },
      id: "15010414"
    },
    {
      name: {
        km: "ក្អមសំណ",
        latin: "K'amsamna"
      },
      id: "15010415"
    },
    {
      name: {
        km: "ព្រែកក្រ",
        latin: "Preaek Kra"
      },
      id: "15010416"
    },
    {
      name: {
        km: "ព្រៃយាង",
        latin: "Prey Yeang"
      },
      id: "15010501"
    },
    {
      name: {
        km: "របោះរាំង",
        latin: "Robaoh Reang"
      },
      id: "15010502"
    },
    {
      name: {
        km: "ចំការអូរ",
        latin: "Chamkar Ou"
      },
      id: "15010503"
    },
    {
      name: {
        km: "ចំការខ្លុយ",
        latin: "Chamkar Khloy"
      },
      id: "15010504"
    },
    {
      name: {
        km: "អូរតាប៉ោង",
        latin: "Ou Ta Paong"
      },
      id: "15010505"
    },
    {
      name: {
        km: "អន្លង់ក្រាយ",
        latin: "Anlong Kray"
      },
      id: "15010506"
    },
    {
      name: {
        km: "ស្រះម្កាក់",
        latin: "Srah Mkak"
      },
      id: "15010507"
    },
    {
      name: {
        km: "បត់គគីរចាស់",
        latin: "Bat Kokir Chas"
      },
      id: "15010509"
    },
    {
      name: {
        km: "ស្ដុកឃ្លោក",
        latin: "Sdok Khlouk"
      },
      id: "15010510"
    },
    {
      name: {
        km: "ទួលរគាំង",
        latin: "Tuol Rokeang"
      },
      id: "15010511"
    },
    {
      name: {
        km: "សំរោងពក",
        latin: "Samraong Pok"
      },
      id: "15010512"
    },
    {
      name: {
        km: "ផ្សារអណ្ដែត",
        latin: "Phsar Andaet"
      },
      id: "15010513"
    },
    {
      name: {
        km: "ឧកញ្ញ៉ាមាន់",
        latin: "Oknha Moan"
      },
      id: "15010514"
    },
    {
      name: {
        km: "ស្រះរុន",
        latin: "Srah Run"
      },
      id: "15010515"
    },
    {
      name: {
        km: "បត់គគីរថ្មី",
        latin: "Bat Kokir Thmei"
      },
      id: "15010516"
    },
    {
      name: {
        km: "តាណៃ",
        latin: "Ta Nai"
      },
      id: "15010517"
    },
    {
      name: {
        km: "អូរបត់",
        latin: "Ou Bat"
      },
      id: "15010519"
    },
    {
      name: {
        km: "ព្រៃក្របៅ",
        latin: "Prey Krabau"
      },
      id: "15010521"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "15010601"
    },
    {
      name: {
        km: "រូងតាកុក",
        latin: "Roung Ta Kok"
      },
      id: "15010602"
    },
    {
      name: {
        km: "កូនត្នោត",
        latin: "Koun Tnaot"
      },
      id: "15010603"
    },
    {
      name: {
        km: "ប្រឡាយរំដេង",
        latin: "Pralay Rumdeng"
      },
      id: "15010604"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "15010605"
    },
    {
      name: {
        km: "កំពង់ក្ដី",
        latin: "Kampong Kdei"
      },
      id: "15010606"
    },
    {
      name: {
        km: "រំលេច",
        latin: "Rumlech"
      },
      id: "15010607"
    },
    {
      name: {
        km: "កោះខ្ជាយ",
        latin: "Kaoh Khcheay"
      },
      id: "15010608"
    },
    {
      name: {
        km: "ស្ដុកខ្លា",
        latin: "Sdok Khla"
      },
      id: "15010609"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "15010610"
    },
    {
      name: {
        km: "ដំណាក់ត្រាច",
        latin: "Damnak Trach"
      },
      id: "15010611"
    },
    {
      name: {
        km: "ល្ហុង",
        latin: "Lhong"
      },
      id: "15010612"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "15010613"
    },
    {
      name: {
        km: "ស្នាមព្រះ",
        latin: "Snam Preah"
      },
      id: "15010701"
    },
    {
      name: {
        km: "ក្រពើរោទិ៍",
        latin: "Krapeu Rou"
      },
      id: "15010702"
    },
    {
      name: {
        km: "អណ្ដូងសំបូរ",
        latin: "Andoung Sambuor"
      },
      id: "15010703"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "15010704"
    },
    {
      name: {
        km: "ចំបក់មាស",
        latin: "Chambak Meas"
      },
      id: "15010705"
    },
    {
      name: {
        km: "ទួលខ្មែរ",
        latin: "Tuol Khmer"
      },
      id: "15010706"
    },
    {
      name: {
        km: "ស្ដុកស្វាយ",
        latin: "Sdok Svay"
      },
      id: "15010707"
    },
    {
      name: {
        km: "កោះក្រសាំង",
        latin: "Kaoh Krasang"
      },
      id: "15010708"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "15010709"
    },
    {
      name: {
        km: "ដង្កៀបក្ដាម",
        latin: "Dangkieb Kdam"
      },
      id: "15010710"
    },
    {
      name: {
        km: "អន្លង់មាន",
        latin: "Anlong Mean"
      },
      id: "15010711"
    },
    {
      name: {
        km: "កំពែងស្វាយ",
        latin: "Kampeaeng Svay"
      },
      id: "15010712"
    },
    {
      name: {
        km: "ខ្មារ",
        latin: "Khmar"
      },
      id: "15010713"
    },
    {
      name: {
        km: "ធ្នោះតាចាប",
        latin: "Thnuoh Ta Chab"
      },
      id: "15010714"
    },
    {
      name: {
        km: "ស្វាយអាត់",
        latin: "Svay At"
      },
      id: "15010715"
    },
    {
      name: {
        km: "អារ៉ែន",
        latin: "Araen"
      },
      id: "15010716"
    },
    {
      name: {
        km: "អណ្ដូងក្រសាំង",
        latin: "Andoung Krasang"
      },
      id: "15010717"
    },
    {
      name: {
        km: "បាក់ព្រីង",
        latin: "Bak Pring"
      },
      id: "15010718"
    },
    {
      name: {
        km: "ជើងភ្លើង",
        latin: "Cheung Phleung"
      },
      id: "15010719"
    },
    {
      name: {
        km: "ត្រាំពែរ",
        latin: "Tram Peaer"
      },
      id: "15010720"
    },
    {
      name: {
        km: "ទួលសំរោង",
        latin: "Tuol Samraong"
      },
      id: "15010801"
    },
    {
      name: {
        km: "ស្វាយដូនកែវទី១",
        latin: "Svay Doun Kaev Ti Muoy"
      },
      id: "15010802"
    },
    {
      name: {
        km: "ស្វាយដូនកែវទី២",
        latin: "Svay Doun Kaev Ti Pir"
      },
      id: "15010803"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "15010804"
    },
    {
      name: {
        km: "កំប៉ាង",
        latin: "Kampang"
      },
      id: "15010805"
    },
    {
      name: {
        km: "ច្រប់",
        latin: "Chrab"
      },
      id: "15010806"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "15010807"
    },
    {
      name: {
        km: "កំពោតអាង",
        latin: "Kampout Ang"
      },
      id: "15010808"
    },
    {
      name: {
        km: "និគមន៍លើ",
        latin: "Nikom Leu"
      },
      id: "15010809"
    },
    {
      name: {
        km: "បាកាន",
        latin: "Bakan"
      },
      id: "15011001"
    },
    {
      name: {
        km: "កាប់ក្រឡាញ់",
        latin: "Kab Kralanh"
      },
      id: "15011002"
    },
    {
      name: {
        km: "ពោធិ៍លំ្យុ",
        latin: "Poulyum"
      },
      id: "15011003"
    },
    {
      name: {
        km: "ស្ទឹងកំបុត",
        latin: "Stueng Kambot"
      },
      id: "15011004"
    },
    {
      name: {
        km: "បឹងប្រីយ៍",
        latin: "Boeng Prei"
      },
      id: "15011005"
    },
    {
      name: {
        km: "ស្រែល្វា",
        latin: "Srae Lvea"
      },
      id: "15011006"
    },
    {
      name: {
        km: "ចំការអូរ",
        latin: "Chamkar Ou"
      },
      id: "15011007"
    },
    {
      name: {
        km: "កណ្ដឹងមាស",
        latin: "Kandoeng Meas"
      },
      id: "15011008"
    },
    {
      name: {
        km: "ត្រពាំងជង",
        latin: "Trapeang Chorng"
      },
      id: "15011009"
    },
    {
      name: {
        km: "កោះអណ្ដែត",
        latin: "Kaoh Andaet"
      },
      id: "15011010"
    },
    {
      name: {
        km: "កោះស្វាយ",
        latin: "Kaoh Svay"
      },
      id: "15011011"
    },
    {
      name: {
        km: "ក្រោលក្របី",
        latin: "Kraol Krabei"
      },
      id: "15011012"
    },
    {
      name: {
        km: "ពិតត្រង់",
        latin: "Pit Trang"
      },
      id: "15011013"
    },
    {
      name: {
        km: "កោះកែវ",
        latin: "Kaoh Kaev"
      },
      id: "15011014"
    },
    {
      name: {
        km: "បួស្រង៉ែ",
        latin: "Buo Srangae"
      },
      id: "15011015"
    },
    {
      name: {
        km: "ស្នាយទោល",
        latin: "Snay Toul"
      },
      id: "15011016"
    },
    {
      name: {
        km: "អូររំចេក",
        latin: "Ou Rumchek"
      },
      id: "15011017"
    },
    {
      name: {
        km: "ក្ដីឈ្នួល",
        latin: "Kdei Chhnuol"
      },
      id: "15011018"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "15011019"
    },
    {
      name: {
        km: "ព្រះចំបក់",
        latin: "Preah Chambak"
      },
      id: "15011020"
    },
    {
      name: {
        km: "ទួលចារ",
        latin: "Tuol Char"
      },
      id: "15020101"
    },
    {
      name: {
        km: "អូរបាក្រង",
        latin: "Ou Ba Krang"
      },
      id: "15020102"
    },
    {
      name: {
        km: "វត្ដពោធិ៍ ទី១",
        latin: "Voat Pou Ti Muoy"
      },
      id: "15020103"
    },
    {
      name: {
        km: "វត្ដពោធិ៍ ទី២",
        latin: "Voat Pou Ti Pir"
      },
      id: "15020104"
    },
    {
      name: {
        km: "កំពង់ក្របី",
        latin: "Kampong Krabei"
      },
      id: "15020105"
    },
    {
      name: {
        km: "ផ្លូវក្របី",
        latin: "Phlov Krabei"
      },
      id: "15020106"
    },
    {
      name: {
        km: "កញ្ជើបាយដាច",
        latin: "Kanhcheu Bay Dach"
      },
      id: "15020107"
    },
    {
      name: {
        km: "អន្លង់វិល",
        latin: "Anlong Vil"
      },
      id: "15020108"
    },
    {
      name: {
        km: "ព្រែកតាវង",
        latin: "Preaek Ta Vong"
      },
      id: "15020109"
    },
    {
      name: {
        km: "ព្រែកតាគង់",
        latin: "Preaek Ta Kong"
      },
      id: "15020110"
    },
    {
      name: {
        km: "កោះក្រសាំង",
        latin: "Kaoh Krasang"
      },
      id: "15020111"
    },
    {
      name: {
        km: "ព្រែកឈើត្រាវ",
        latin: "Preaek Chheu Trav"
      },
      id: "15020112"
    },
    {
      name: {
        km: "ជ័យជំនះ",
        latin: "Chey Chumneah"
      },
      id: "15020113"
    },
    {
      name: {
        km: "បឹងឈូក",
        latin: "Boeng Chhuk"
      },
      id: "15020114"
    },
    {
      name: {
        km: "ផ្ទះគរ",
        latin: "Phteah Kor"
      },
      id: "15020115"
    },
    {
      name: {
        km: "ក្បាលរមាស",
        latin: "Kbal Romeas"
      },
      id: "15020116"
    },
    {
      name: {
        km: "កំពង់រកា",
        latin: "Kampong Roka"
      },
      id: "15020301"
    },
    {
      name: {
        km: "កែវជ័យ",
        latin: "Kaev Chey"
      },
      id: "15020302"
    },
    {
      name: {
        km: "ស្វាយយាង",
        latin: "Svay Yeang"
      },
      id: "15020303"
    },
    {
      name: {
        km: "កណ្ដៀងក្នុង",
        latin: "Kandieng Knong"
      },
      id: "15020304"
    },
    {
      name: {
        km: "កណ្ដៀង",
        latin: "Kandieng"
      },
      id: "15020305"
    },
    {
      name: {
        km: "ស្ថានីយ៍",
        latin: "Sthani"
      },
      id: "15020306"
    },
    {
      name: {
        km: "យស",
        latin: "Yos"
      },
      id: "15020307"
    },
    {
      name: {
        km: "ព្រៃក្ដីលើ",
        latin: "Prey Kdei Leu"
      },
      id: "15020308"
    },
    {
      name: {
        km: "ព្រៃក្ដីកណ្ដាល",
        latin: "Prey Kdei Kandal"
      },
      id: "15020309"
    },
    {
      name: {
        km: "ព្រៃក្ដីក្រោម",
        latin: "Prey Kdei Kraom"
      },
      id: "15020310"
    },
    {
      name: {
        km: "ស្យា",
        latin: "Sya"
      },
      id: "15020311"
    },
    {
      name: {
        km: "បង្គោល",
        latin: "Bangkoul"
      },
      id: "15020312"
    },
    {
      name: {
        km: "ស្ទឹងលើ",
        latin: "Stueng Leu"
      },
      id: "15020313"
    },
    {
      name: {
        km: "ស្ទឹងក្រោម",
        latin: "Stueng Kraom"
      },
      id: "15020314"
    },
    {
      name: {
        km: "កំពង់ក្រសាំងលើ",
        latin: "Kampong Krasang Leu"
      },
      id: "15020315"
    },
    {
      name: {
        km: "កំពង់ក្រសាំងក្រោម",
        latin: "Kampong Krasang Kraom"
      },
      id: "15020316"
    },
    {
      name: {
        km: "បឹងឈូក",
        latin: "Boeng Chhuk"
      },
      id: "15020317"
    },
    {
      name: {
        km: "កញ្ជរ",
        latin: "Kanhchor"
      },
      id: "15020401"
    },
    {
      name: {
        km: "បឹងក្រាញ់",
        latin: "Boeng Kranh"
      },
      id: "15020402"
    },
    {
      name: {
        km: "ព្រែកត្របែក",
        latin: "Preaek Trabaek"
      },
      id: "15020403"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "15020404"
    },
    {
      name: {
        km: "រលាំង",
        latin: "Roleang"
      },
      id: "15020405"
    },
    {
      name: {
        km: "ទួលទទឹង",
        latin: "Tuol Totueng"
      },
      id: "15020406"
    },
    {
      name: {
        km: "ពោធិ៍អណ្ដែត",
        latin: "Pou Andaet"
      },
      id: "15020407"
    },
    {
      name: {
        km: "កូនក្រាយ",
        latin: "Koun Kray"
      },
      id: "15020408"
    },
    {
      name: {
        km: "ដងអូរ",
        latin: "Dang Ou"
      },
      id: "15020409"
    },
    {
      name: {
        km: "ផ្លូវលួង",
        latin: "Phlov Luong"
      },
      id: "15020410"
    },
    {
      name: {
        km: "ត្បែងបង្គាប",
        latin: "Tbaeng Bangkeab"
      },
      id: "15020411"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15020412"
    },
    {
      name: {
        km: "រាំងទិល",
        latin: "Reang Til"
      },
      id: "15020501"
    },
    {
      name: {
        km: "ព្រែក",
        latin: "Preaek"
      },
      id: "15020502"
    },
    {
      name: {
        km: "ចារុះ",
        latin: "Charaoh"
      },
      id: "15020503"
    },
    {
      name: {
        km: "កោះកែវ",
        latin: "Kaoh  Kaev"
      },
      id: "15020504"
    },
    {
      name: {
        km: "កោះក្អែក",
        latin: "Kaoh K'aek"
      },
      id: "15020505"
    },
    {
      name: {
        km: "បន្ទាយត្រោក",
        latin: "Banteay Traok"
      },
      id: "15020601"
    },
    {
      name: {
        km: "ចំការតាប៉ូ",
        latin: "Chamkar Ta Pou"
      },
      id: "15020602"
    },
    {
      name: {
        km: "ផ្ទះកោះ",
        latin: "Phteah Kaoh"
      },
      id: "15020603"
    },
    {
      name: {
        km: "សែនជ័យ",
        latin: "Saen Chey"
      },
      id: "15020604"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "15020605"
    },
    {
      name: {
        km: "សារៀង",
        latin: "Sarieng"
      },
      id: "15020606"
    },
    {
      name: {
        km: "ក្ដីឈ្វិត",
        latin: "Kdei Chhvit"
      },
      id: "15020607"
    },
    {
      name: {
        km: "តាម៉ោលី",
        latin: "Ta Mao Li"
      },
      id: "15020608"
    },
    {
      name: {
        km: "ព្រីងខ្ពស់",
        latin: "Pring Khpos"
      },
      id: "15020609"
    },
    {
      name: {
        km: "ត្រង់",
        latin: "Trang"
      },
      id: "15020610"
    },
    {
      name: {
        km: "ធ្លាអំពិល",
        latin: "Thlea Ampil"
      },
      id: "15020611"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "15020612"
    },
    {
      name: {
        km: "ចំរ៉េះ",
        latin: "Chamreh"
      },
      id: "15020613"
    },
    {
      name: {
        km: "បាគូរ",
        latin: "Bakur"
      },
      id: "15020614"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "15020615"
    },
    {
      name: {
        km: "ពោពីរ",
        latin: "Pou Pir"
      },
      id: "15020616"
    },
    {
      name: {
        km: "ឥន្ធធ្យា",
        latin: "Entheakthyea"
      },
      id: "15020617"
    },
    {
      name: {
        km: "បឹងក្រាញ់",
        latin: "Boeng Kranh"
      },
      id: "15020701"
    },
    {
      name: {
        km: "រោងម៉ាស៊ីន",
        latin: "Roung Masin"
      },
      id: "15020702"
    },
    {
      name: {
        km: "ស្វាយលួង",
        latin: "Svay Luong"
      },
      id: "15020703"
    },
    {
      name: {
        km: "ស្វាយចាន់",
        latin: "Svay Chan"
      },
      id: "15020704"
    },
    {
      name: {
        km: "ផ្លូវពោធិវង្ស",
        latin: "Phlov Pouthi Vongs"
      },
      id: "15020705"
    },
    {
      name: {
        km: "ស្វាយចំបក់",
        latin: "Svay Chambak"
      },
      id: "15020706"
    },
    {
      name: {
        km: "ពោធិឡើង",
        latin: "Pou Laeung"
      },
      id: "15020707"
    },
    {
      name: {
        km: "កគោ",
        latin: "Ka Kou"
      },
      id: "15020708"
    },
    {
      name: {
        km: "សន្លុង",
        latin: "Sanlung"
      },
      id: "15020709"
    },
    {
      name: {
        km: "ស្វាយយាង",
        latin: "Svay Yeang"
      },
      id: "15020710"
    },
    {
      name: {
        km: "ផ្ទះស្រែ",
        latin: "Phteah Srae"
      },
      id: "15020711"
    },
    {
      name: {
        km: "ជ្រែង",
        latin: "Chreaeng"
      },
      id: "15020712"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "15020713"
    },
    {
      name: {
        km: "កំពង់សំបួរ",
        latin: "Kampong Sambuor"
      },
      id: "15020801"
    },
    {
      name: {
        km: "អន្លង់វិល",
        latin: "Anlong Vil"
      },
      id: "15020802"
    },
    {
      name: {
        km: "ចារឹក",
        latin: "Charuek"
      },
      id: "15020803"
    },
    {
      name: {
        km: "បឹងចក",
        latin: "Boeng Chak"
      },
      id: "15020804"
    },
    {
      name: {
        km: "ក្បាលពាម",
        latin: "Kbal Peam"
      },
      id: "15020805"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "15020806"
    },
    {
      name: {
        km: "ក្បាលឈើពុក",
        latin: "Kbal Chheu Puk"
      },
      id: "15020807"
    },
    {
      name: {
        km: "ស្យា",
        latin: "Sya"
      },
      id: "15020808"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "15020809"
    },
    {
      name: {
        km: "ព្រៃស្រគុំ",
        latin: "Prey Srakum"
      },
      id: "15020810"
    },
    {
      name: {
        km: "វត្ដលៀប",
        latin: "Voat Lieb"
      },
      id: "15020812"
    },
    {
      name: {
        km: "ក្បាលហុង",
        latin: "Kbal Hong"
      },
      id: "15020901"
    },
    {
      name: {
        km: "ប្រឡាយធំ",
        latin: "Pralay Thum"
      },
      id: "15020902"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "15020903"
    },
    {
      name: {
        km: "ពោធិ៍កំបោរ",
        latin: "Pou Kambaor"
      },
      id: "15020904"
    },
    {
      name: {
        km: "កញ្ជើបាយដាច",
        latin: "Kanhcheu Bay Dach"
      },
      id: "15020905"
    },
    {
      name: {
        km: "ពោធិ៍ដំណាក់",
        latin: "Pou Damnak"
      },
      id: "15020906"
    },
    {
      name: {
        km: "បឹងយា",
        latin: "Boeng Yea"
      },
      id: "15020907"
    },
    {
      name: {
        km: "តាស្ដី",
        latin: "Ta Sdei"
      },
      id: "15020908"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "15020909"
    },
    {
      name: {
        km: "អន្លង់ហាប",
        latin: "Anlong Hab"
      },
      id: "15021001"
    },
    {
      name: {
        km: "ស្ពាន",
        latin: "Spean"
      },
      id: "15021002"
    },
    {
      name: {
        km: "ដងរុង",
        latin: "Dang Rung"
      },
      id: "15021003"
    },
    {
      name: {
        km: "ដងឡង",
        latin: "Dang Lang"
      },
      id: "15021004"
    },
    {
      name: {
        km: "ស្ដុកជុំ",
        latin: "Sdok Chum"
      },
      id: "15021005"
    },
    {
      name: {
        km: "ដំរីស",
        latin: "Damrei Sa"
      },
      id: "15021006"
    },
    {
      name: {
        km: "ផ្ទះព្រីង",
        latin: "Phteah Pring"
      },
      id: "15021007"
    },
    {
      name: {
        km: "គៀន",
        latin: "Kien"
      },
      id: "15021008"
    },
    {
      name: {
        km: "អំពិលកញ្ច្រិញ",
        latin: "Ampil Kanhchrinh"
      },
      id: "15021009"
    },
    {
      name: {
        km: "ទួលគូ",
        latin: "Tuol Ku"
      },
      id: "15021010"
    },
    {
      name: {
        km: "ជ្រលង",
        latin: "Chrolorng"
      },
      id: "15030101"
    },
    {
      name: {
        km: "ថ្គោលធំ",
        latin: "Thkoul Thum"
      },
      id: "15030102"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15030103"
    },
    {
      name: {
        km: "ផ្សារ",
        latin: "Phsar"
      },
      id: "15030104"
    },
    {
      name: {
        km: "ទួលម្កាក់",
        latin: "Tuol Mkak"
      },
      id: "15030105"
    },
    {
      name: {
        km: "ឡបាក់",
        latin: "La Bak"
      },
      id: "15030106"
    },
    {
      name: {
        km: "ខ្លាក្រពើ",
        latin: "Khla Krapeu"
      },
      id: "15030107"
    },
    {
      name: {
        km: "ទន្សាយគល់",
        latin: "Tonsay Kol"
      },
      id: "15030108"
    },
    {
      name: {
        km: "ឃ្លាំងមឿង",
        latin: "Khleang Moeung"
      },
      id: "15030109"
    },
    {
      name: {
        km: "បន្ទាយក្រង",
        latin: "Banteay Krang"
      },
      id: "15030110"
    },
    {
      name: {
        km: "ប៉ប៉ិត",
        latin: "Papet"
      },
      id: "15030111"
    },
    {
      name: {
        km: "ទទឹង",
        latin: "Totueng"
      },
      id: "15030112"
    },
    {
      name: {
        km: "ខ្សាច់ល្អិត",
        latin: "Khsach L'et"
      },
      id: "15030201"
    },
    {
      name: {
        km: "ថ្គោលធំ",
        latin: "Thkoul Thum"
      },
      id: "15030202"
    },
    {
      name: {
        km: "ថ្គោលតូច",
        latin: "Thkoul Touch"
      },
      id: "15030203"
    },
    {
      name: {
        km: "អារ៉ងព្រួច",
        latin: "Arang Pruoch"
      },
      id: "15030204"
    },
    {
      name: {
        km: "កំពង់ថ្គោល",
        latin: "Kampong Thkoul"
      },
      id: "15030205"
    },
    {
      name: {
        km: "សានសរ",
        latin: "San Sar"
      },
      id: "15030206"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "15030207"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "15030301"
    },
    {
      name: {
        km: "កណ្ដុរស",
        latin: "Kandor Sa"
      },
      id: "15030302"
    },
    {
      name: {
        km: "ត្រពាំងឃ្លៃ",
        latin: "Trapeang Khley"
      },
      id: "15030303"
    },
    {
      name: {
        km: "ជជក",
        latin: "Chochork"
      },
      id: "15030304"
    },
    {
      name: {
        km: "ត្រពាំងកន្ទួត",
        latin: "Trapeang Kantuot"
      },
      id: "15030305"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "15030306"
    },
    {
      name: {
        km: "អូរអញ្ចាញ",
        latin: "Ou Anhchanh"
      },
      id: "15030307"
    },
    {
      name: {
        km: "ពោធិ៍ឃឿន",
        latin: "Pou Khoeun"
      },
      id: "15030308"
    },
    {
      name: {
        km: "ពោធិ៍អង្ក្រង",
        latin: "Pou Angkrang"
      },
      id: "15030309"
    },
    {
      name: {
        km: "ធ្លាម្អម",
        latin: "Thlea M'am"
      },
      id: "15030310"
    },
    {
      name: {
        km: "តាកែវក្រោម",
        latin: "Ta Kaev Kraom"
      },
      id: "15030311"
    },
    {
      name: {
        km: "ទួលត្បែង",
        latin: "Tuol Tbaeng"
      },
      id: "15030401"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15030402"
    },
    {
      name: {
        km: "ផ្ទះចេក",
        latin: "Phteah Chek"
      },
      id: "15030403"
    },
    {
      name: {
        km: "ឈើទែប",
        latin: "Chheu Teaeb"
      },
      id: "15030404"
    },
    {
      name: {
        km: "ចាមចាស់",
        latin: "Cham Chas"
      },
      id: "15030405"
    },
    {
      name: {
        km: "ចាមថ្មី",
        latin: "Cham Thmei"
      },
      id: "15030406"
    },
    {
      name: {
        km: "កប៉ាស់",
        latin: "Kapas"
      },
      id: "15030407"
    },
    {
      name: {
        km: "ដង្កៀបក្ដាម",
        latin: "Dangkieb Kdam"
      },
      id: "15030408"
    },
    {
      name: {
        km: "ក្បាលទាហាន",
        latin: "Kbal Teahean"
      },
      id: "15030409"
    },
    {
      name: {
        km: "បំណក់",
        latin: "Bamnak"
      },
      id: "15030410"
    },
    {
      name: {
        km: "ទានព្រៃ",
        latin: "Tean Prey"
      },
      id: "15030411"
    },
    {
      name: {
        km: "កប៉ាស់លើ",
        latin: "Kapas Leu"
      },
      id: "15030412"
    },
    {
      name: {
        km: "ភូមិទី១",
        latin: "Phum Ti Muoy"
      },
      id: "15030501"
    },
    {
      name: {
        km: "ភូមិទី២",
        latin: "Phum Ti Pir"
      },
      id: "15030502"
    },
    {
      name: {
        km: "ភូមិទី៣",
        latin: "Phum Ti Bei"
      },
      id: "15030503"
    },
    {
      name: {
        km: "ភូមិទី៤",
        latin: "Phum Ti Buon"
      },
      id: "15030504"
    },
    {
      name: {
        km: "ភូមិទី៥",
        latin: "Phum Ti Pram"
      },
      id: "15030505"
    },
    {
      name: {
        km: "ចេកចៅ",
        latin: "Chek Chau"
      },
      id: "15030601"
    },
    {
      name: {
        km: "ពោធិកុដិ",
        latin: "Pou Kod"
      },
      id: "15030602"
    },
    {
      name: {
        km: "កំពង់ឡ",
        latin: "Kampong La"
      },
      id: "15030603"
    },
    {
      name: {
        km: "មាត់ព្រៃ",
        latin: "Moat Prey"
      },
      id: "15030604"
    },
    {
      name: {
        km: "ពោធិរបង",
        latin: "Pou Robang"
      },
      id: "15030605"
    },
    {
      name: {
        km: "រលួសកណ្ដាល",
        latin: "Roluos Kandal"
      },
      id: "15030606"
    },
    {
      name: {
        km: "រលួសខាងកើត",
        latin: "Roluos Khang Kaeut"
      },
      id: "15030607"
    },
    {
      name: {
        km: "ស្នារាជ",
        latin: "Sna Reach"
      },
      id: "15030608"
    },
    {
      name: {
        km: "ទទឹង",
        latin: "Totueng"
      },
      id: "15030701"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "15030702"
    },
    {
      name: {
        km: "ផ្សារ",
        latin: "Phsar"
      },
      id: "15030703"
    },
    {
      name: {
        km: "ត្រពាំងរំដេញ",
        latin: "Trapeang Rumdenh"
      },
      id: "15030704"
    },
    {
      name: {
        km: "ត្រពាំងស្មាច់",
        latin: "Trapeang Smach"
      },
      id: "15030705"
    },
    {
      name: {
        km: "ឈើទាលខ្ពស់",
        latin: "Chheu Teal Khpos"
      },
      id: "15030706"
    },
    {
      name: {
        km: "កំពង់លើ",
        latin: "Kampong Leu"
      },
      id: "15030707"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "15030708"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15030709"
    },
    {
      name: {
        km: "ស្រែឫស្សី",
        latin: "Srae Ruessei"
      },
      id: "15030710"
    },
    {
      name: {
        km: "ក្រឡាញ់",
        latin: "Kralanh"
      },
      id: "15030711"
    },
    {
      name: {
        km: "អូរអាចម៍កុក",
        latin: "Ou Ach Kok"
      },
      id: "15030801"
    },
    {
      name: {
        km: "ក្រាំងធំ",
        latin: "Krang Thum"
      },
      id: "15030802"
    },
    {
      name: {
        km: "ថ្នឹង",
        latin: "Thnoeng"
      },
      id: "15030803"
    },
    {
      name: {
        km: "ពុទ្ទ្រាម",
        latin: "Puttream"
      },
      id: "15030804"
    },
    {
      name: {
        km: "ដូងជួរ",
        latin: "Doung Chuor"
      },
      id: "15030805"
    },
    {
      name: {
        km: "អូរតាប្រុក",
        latin: "Ou Ta Prok"
      },
      id: "15030806"
    },
    {
      name: {
        km: "ចុងខ្លុង",
        latin: "Chong Khlong"
      },
      id: "15030807"
    },
    {
      name: {
        km: "អូរសណ្ដាន់",
        latin: "Ou Sandan"
      },
      id: "15030808"
    },
    {
      name: {
        km: "ស្នាអន្សា",
        latin: "Sna Ansa"
      },
      id: "15030901"
    },
    {
      name: {
        km: "ក្រាំងវែង",
        latin: "Krang Veaeng"
      },
      id: "15030902"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "15030903"
    },
    {
      name: {
        km: "ជីចេះ",
        latin: "Chi Cheh"
      },
      id: "15030904"
    },
    {
      name: {
        km: "វាលវង់",
        latin: "Veal Vong"
      },
      id: "15030905"
    },
    {
      name: {
        km: "សារវ័ន",
        latin: "Sarovoan"
      },
      id: "15030906"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "15030907"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "15030908"
    },
    {
      name: {
        km: "អន្សក្ដាម",
        latin: "Ansa Kdam"
      },
      id: "15030910"
    },
    {
      name: {
        km: "កំពង់ប្រាក់",
        latin: "Kampong Prak"
      },
      id: "15030911"
    },
    {
      name: {
        km: "កំរែង",
        latin: "Kamraeng"
      },
      id: "15031001"
    },
    {
      name: {
        km: "ត្រពាំងស្នួល",
        latin: "Trapeang Snuol"
      },
      id: "15031002"
    },
    {
      name: {
        km: "អន្សាចំបក់",
        latin: "Ansa Chambak"
      },
      id: "15031003"
    },
    {
      name: {
        km: "ទួលអណ្ដែត",
        latin: "Tuol Andaet"
      },
      id: "15031004"
    },
    {
      name: {
        km: "បឹងស្មុក",
        latin: "Boeng Smok"
      },
      id: "15031005"
    },
    {
      name: {
        km: "អូរចាន់",
        latin: "Ou Chan"
      },
      id: "15031006"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "15031101"
    },
    {
      name: {
        km: "ក្របី ស",
        latin: "Krabei Sa"
      },
      id: "15031102"
    },
    {
      name: {
        km: "បង្គងឃ្មុំុ",
        latin: "Bangkong Khmum"
      },
      id: "15031103"
    },
    {
      name: {
        km: "ដងទឹកលាច",
        latin: "Dang Tuek Leach"
      },
      id: "15031104"
    },
    {
      name: {
        km: "ចំបក់ធំ",
        latin: "Chambak Thum"
      },
      id: "15031105"
    },
    {
      name: {
        km: "ត្បែងជ្រុំ",
        latin: "Tbaeng Chrum"
      },
      id: "15031106"
    },
    {
      name: {
        km: "ត្រាំ",
        latin: "Tram"
      },
      id: "15031107"
    },
    {
      name: {
        km: "ជ័រម្គាន",
        latin: "Choar Mkean"
      },
      id: "15031108"
    },
    {
      name: {
        km: "បឹងវាល",
        latin: "Boeng Veal"
      },
      id: "15031109"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "15031110"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15031111"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "15031112"
    },
    {
      name: {
        km: "តាកែវលើ",
        latin: "Ta Kaev Leu"
      },
      id: "15031113"
    },
    {
      name: {
        km: "សែនពេន",
        latin: "Saen Pen"
      },
      id: "15031114"
    },
    {
      name: {
        km: "ដំបូក១០០",
        latin: "Dambouk 100"
      },
      id: "15031115"
    },
    {
      name: {
        km: "អូររំចង់",
        latin: "Ou Rumchang"
      },
      id: "15040101"
    },
    {
      name: {
        km: "បាក់ចិញ្ចៀន",
        latin: "Bak Chenhchien"
      },
      id: "15040102"
    },
    {
      name: {
        km: "ក្របៅជ្រុំ",
        latin: "Krabau Chrum"
      },
      id: "15040103"
    },
    {
      name: {
        km: "ចាន់សេរី",
        latin: "Chan Serei"
      },
      id: "15040104"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "15040105"
    },
    {
      name: {
        km: "អូរឬស្សី",
        latin: "Ou Ruessei"
      },
      id: "15040106"
    },
    {
      name: {
        km: "កំពង់ខ្ទុម",
        latin: "Kampong Khtom"
      },
      id: "15040107"
    },
    {
      name: {
        km: "ស្រែជង",
        latin: "Srae Chong"
      },
      id: "15040108"
    },
    {
      name: {
        km: "ដំណាក់អំពិល",
        latin: "Damnak Ampil"
      },
      id: "15040109"
    },
    {
      name: {
        km: "លាច",
        latin: "Leach"
      },
      id: "15040201"
    },
    {
      name: {
        km: "ពេជបាន",
        latin: "Pech Ban"
      },
      id: "15040202"
    },
    {
      name: {
        km: "បោះពួយ",
        latin: "Baoh Puoy"
      },
      id: "15040203"
    },
    {
      name: {
        km: "ស្បូវរីក",
        latin: "Sbov Rik"
      },
      id: "15040204"
    },
    {
      name: {
        km: "ប៉ែន",
        latin: "Paen"
      },
      id: "15040205"
    },
    {
      name: {
        km: "បាក់ត្រកួន",
        latin: "Bak Trakuon"
      },
      id: "15040206"
    },
    {
      name: {
        km: "ក្រូចឆ្មារ",
        latin: "Krouch Chhmar"
      },
      id: "15040207"
    },
    {
      name: {
        km: "តានុក",
        latin: "Ta Nuk"
      },
      id: "15040208"
    },
    {
      name: {
        km: "សាយ",
        latin: "Say"
      },
      id: "15040401"
    },
    {
      name: {
        km: "អូរស្រាវ",
        latin: "Ou Srav"
      },
      id: "15040402"
    },
    {
      name: {
        km: "កំពែង",
        latin: "Kampeaeng"
      },
      id: "15040403"
    },
    {
      name: {
        km: "ព្រងិល",
        latin: "Prongil"
      },
      id: "15040404"
    },
    {
      name: {
        km: "អូរបាក់ត្រា",
        latin: "Ou Bak Tra"
      },
      id: "15040405"
    },
    {
      name: {
        km: "សំរោងយា",
        latin: "Samraong Yea"
      },
      id: "15040407"
    },
    {
      name: {
        km: "ស្វាយប៉ាក",
        latin: "Svay Pak"
      },
      id: "15040408"
    },
    {
      name: {
        km: "ព្រៃស្មាច់",
        latin: "Prey Smach"
      },
      id: "15040501"
    },
    {
      name: {
        km: "ព្រៃខ្លុង",
        latin: "Prey Khlong"
      },
      id: "15040502"
    },
    {
      name: {
        km: "មល់",
        latin: "Mol"
      },
      id: "15040503"
    },
    {
      name: {
        km: "វាលវង់",
        latin: "Veal Vong"
      },
      id: "15040504"
    },
    {
      name: {
        km: "គល់ទទឹង",
        latin: "Kol Totueng"
      },
      id: "15040601"
    },
    {
      name: {
        km: "សន្ទ្រែ",
        latin: "Santreae"
      },
      id: "15040602"
    },
    {
      name: {
        km: "ក្សេត្របូរី",
        latin: "Ksetr Bourei"
      },
      id: "15040603"
    },
    {
      name: {
        km: "ស្រែពពាយ",
        latin: "Srae Popeay"
      },
      id: "15040604"
    },
    {
      name: {
        km: "ព្រែក ១",
        latin: "Preaek Muoy"
      },
      id: "15040701"
    },
    {
      name: {
        km: "ព្រែក ២",
        latin: "Preaek Pir"
      },
      id: "15040702"
    },
    {
      name: {
        km: "ព្រែក ៣",
        latin: "Preaek Bei"
      },
      id: "15040703"
    },
    {
      name: {
        km: "តាដេះ",
        latin: "Ta Deh"
      },
      id: "15040704"
    },
    {
      name: {
        km: "អូរហេង",
        latin: "Ou Heng"
      },
      id: "15040705"
    },
    {
      name: {
        km: "សំរោង ១",
        latin: "Samraong Muoy"
      },
      id: "15040706"
    },
    {
      name: {
        km: "សំរោង ២",
        latin: "Samraong Pir"
      },
      id: "15040707"
    },
    {
      name: {
        km: "អូរព្រាល",
        latin: "Ou Preal"
      },
      id: "15040708"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "15040709"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "15040710"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "15040711"
    },
    {
      name: {
        km: "អន្លង់ប៉ែន",
        latin: "Anglong Paen"
      },
      id: "15040712"
    },
    {
      name: {
        km: "លាវ",
        latin: "Leav"
      },
      id: "15050101"
    },
    {
      name: {
        km: "អូររកា",
        latin: "Ou Roka"
      },
      id: "15050102"
    },
    {
      name: {
        km: "អូរតូង",
        latin: "Ou Tong"
      },
      id: "15050103"
    },
    {
      name: {
        km: "ដូនអី",
        latin: "Doun Ei"
      },
      id: "15050104"
    },
    {
      name: {
        km: "ក្ដីខ្វាវ",
        latin: "Kdei Khvav"
      },
      id: "15050105"
    },
    {
      name: {
        km: "កំពង់ស្ទោង",
        latin: "Kampong Stoung"
      },
      id: "15050106"
    },
    {
      name: {
        km: "ស្វាយមាស",
        latin: "Svay Meas"
      },
      id: "15050107"
    },
    {
      name: {
        km: "ទួលគ្រួស",
        latin: "Tuol Kruos"
      },
      id: "15050109"
    },
    {
      name: {
        km: "ពោធិ៍តាគួយ",
        latin: "Pou Ta Kuoy"
      },
      id: "15050301"
    },
    {
      name: {
        km: "ព្រែកស្ដី",
        latin: "Preaek Sdei"
      },
      id: "15050302"
    },
    {
      name: {
        km: "លលកស",
        latin: "Lolok Sa"
      },
      id: "15050303"
    },
    {
      name: {
        km: "កោះ",
        latin: "Kaoh"
      },
      id: "15050304"
    },
    {
      name: {
        km: "ផ្សារលើ",
        latin: "Phsar Leu"
      },
      id: "15050305"
    },
    {
      name: {
        km: "វត្ដលួង",
        latin: "Voat Luong"
      },
      id: "15050306"
    },
    {
      name: {
        km: "ជំរុំសៀម",
        latin: "Chumrum Siem"
      },
      id: "15050307"
    },
    {
      name: {
        km: "ដប់បាត",
        latin: "Dab Bat"
      },
      id: "15050308"
    },
    {
      name: {
        km: "ដំណាក់អំពិល",
        latin: "Damnak Ampil"
      },
      id: "15050309"
    },
    {
      name: {
        km: "ខ្មារ",
        latin: "Khmar"
      },
      id: "15050310"
    },
    {
      name: {
        km: "ពាលញែក ១",
        latin: "Peal Nheaek Muoy"
      },
      id: "15050401"
    },
    {
      name: {
        km: "ពាលញែក ២",
        latin: "Peal Nheaek Pir"
      },
      id: "15050402"
    },
    {
      name: {
        km: "ក្បាលហុង",
        latin: "Kbal Hong"
      },
      id: "15050403"
    },
    {
      name: {
        km: "ដង្គារ",
        latin: "Dangkear"
      },
      id: "15050404"
    },
    {
      name: {
        km: "ចំការចេកខាងជើង",
        latin: "Chamkar Chek Khang Cheung"
      },
      id: "15050405"
    },
    {
      name: {
        km: "ចំការចេកខាងត្បូង",
        latin: "Chamkar Chek Khang Tboung"
      },
      id: "15050406"
    },
    {
      name: {
        km: "អូរស្ដៅ",
        latin: "Ou Sdau"
      },
      id: "15050407"
    },
    {
      name: {
        km: "ត្នោតទ្រេត",
        latin: "Tnaot Tret"
      },
      id: "15050408"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "15050409"
    },
    {
      name: {
        km: "រ៉ា",
        latin: "Ra"
      },
      id: "15050410"
    },
    {
      name: {
        km: "បាក់រទេះ",
        latin: "Bak Roteh"
      },
      id: "15050501"
    },
    {
      name: {
        km: "ដូងជ្រុំ",
        latin: "Doung Chrum"
      },
      id: "15050502"
    },
    {
      name: {
        km: "ប្រឡាយធំ",
        latin: "Pralay Thum"
      },
      id: "15050503"
    },
    {
      name: {
        km: "ស្ពានថ្ម",
        latin: "Spean Thma"
      },
      id: "15050504"
    },
    {
      name: {
        km: "មាន់ចែ",
        latin: "Moan Chae"
      },
      id: "15050505"
    },
    {
      name: {
        km: "សាលាគំរូ",
        latin: "Sala Kumru"
      },
      id: "15050506"
    },
    {
      name: {
        km: "ក្រាំងតាសែន",
        latin: "Krang Ta Saen"
      },
      id: "15050507"
    },
    {
      name: {
        km: "ស្រះស្រង់",
        latin: "Srah Srang"
      },
      id: "15050508"
    },
    {
      name: {
        km: "ពោធិ៍អណ្ដោត",
        latin: "Pou Andaot"
      },
      id: "15050601"
    },
    {
      name: {
        km: "ព្រៃឱម៉ាល់",
        latin: "Prey Aomal"
      },
      id: "15050602"
    },
    {
      name: {
        km: "ទួលម្កាក់",
        latin: "Tuol Mkak"
      },
      id: "15050603"
    },
    {
      name: {
        km: "ថ្នល់បំបែក",
        latin: "Thnal Bambaek"
      },
      id: "15050604"
    },
    {
      name: {
        km: "ស្ពានថ្ម",
        latin: "Spean Thma"
      },
      id: "15050605"
    },
    {
      name: {
        km: "ឆ្លងកាត់",
        latin: "Chhlang Kat"
      },
      id: "15050606"
    },
    {
      name: {
        km: "ស្ទឹងតូច",
        latin: "Stueng Touch"
      },
      id: "15050607"
    },
    {
      name: {
        km: "រលាប",
        latin: "Roleab"
      },
      id: "15050608"
    },
    {
      name: {
        km: "សូរិយាលើ",
        latin: "Souriya Leu"
      },
      id: "15050609"
    },
    {
      name: {
        km: "សូរិយាក្រោម",
        latin: "Souriya Kraom"
      },
      id: "15050610"
    },
    {
      name: {
        km: "ថ្នល់ជប៉ុន",
        latin: "Thnal Chopon"
      },
      id: "15050611"
    },
    {
      name: {
        km: "ព្រែកត្នោត",
        latin: "Preaek Tnaot"
      },
      id: "15050612"
    },
    {
      name: {
        km: "អូរថ្កូវ",
        latin: "Ou Thkov"
      },
      id: "15050613"
    },
    {
      name: {
        km: "ស្ថានីយ៍",
        latin: "Sthani"
      },
      id: "15050701"
    },
    {
      name: {
        km: "ក្រាំងពភ្លាក់",
        latin: "Krang Pophleak"
      },
      id: "15050702"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Trang"
      },
      id: "15050703"
    },
    {
      name: {
        km: "ស្វាយអាត់",
        latin: "Svay At"
      },
      id: "15050704"
    },
    {
      name: {
        km: "អូរស្ដៅ",
        latin: "Ou Sdau"
      },
      id: "15050705"
    },
    {
      name: {
        km: "អូរបាក្រងលើ",
        latin: "Ou Ba Krang Leu"
      },
      id: "15050801"
    },
    {
      name: {
        km: "អូរបាក្រងក្រោម",
        latin: "Ou Ba Krang Kraom"
      },
      id: "15050802"
    },
    {
      name: {
        km: "អូរបាក្រងកណ្ដាល",
        latin: "Ou Ba Krang Kandal"
      },
      id: "15050803"
    },
    {
      name: {
        km: "កែវសុវណ្ណលើ",
        latin: "Kaev Sovann Leu"
      },
      id: "15050804"
    },
    {
      name: {
        km: "កែវសុវណ្ណក្រោម",
        latin: "Kaev Sovann Kraom"
      },
      id: "15050805"
    },
    {
      name: {
        km: "តាកុយ",
        latin: "Ta Koy"
      },
      id: "15050806"
    },
    {
      name: {
        km: "ក្បាលហុង",
        latin: "Kbal Hong"
      },
      id: "15050807"
    },
    {
      name: {
        km: "បណ្ដុះសណ្ដែក",
        latin: "Bandoh Sandaek"
      },
      id: "15050808"
    },
    {
      name: {
        km: "ឥស្លាម",
        latin: "Eslam"
      },
      id: "15050809"
    },
    {
      name: {
        km: "បន្ទាយដីលើ",
        latin: "Banteay Dei Leu"
      },
      id: "15050810"
    },
    {
      name: {
        km: "បន្ទាយដីក្រោម",
        latin: "Banteay Dei Kraom"
      },
      id: "15050811"
    },
    {
      name: {
        km: "កែវមុនី",
        latin: "Kaev Muni"
      },
      id: "15050812"
    },
    {
      name: {
        km: "អូរសោម",
        latin: "Ou Saom"
      },
      id: "15060101"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15060102"
    },
    {
      name: {
        km: "ឆាយលូក",
        latin: "Chhay Louk"
      },
      id: "15060103"
    },
    {
      name: {
        km: "គៀនជង្រុក",
        latin: "Kien Chongruk"
      },
      id: "15060104"
    },
    {
      name: {
        km: "ក្រពើពីរលើ",
        latin: "Krapeu Pir Leu"
      },
      id: "15060201"
    },
    {
      name: {
        km: "ក្រពើពីរក្រោម",
        latin: "Krapeu Pir Kraom"
      },
      id: "15060202"
    },
    {
      name: {
        km: "សំឡាញ",
        latin: "Samlanh"
      },
      id: "15060203"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15060301"
    },
    {
      name: {
        km: "ក្រាំងរងៀង",
        latin: "Krang Rongieng"
      },
      id: "15060302"
    },
    {
      name: {
        km: "ចំការជ្រៃខាងជើង",
        latin: "Chamka Chrey Khang Cheung"
      },
      id: "15060303"
    },
    {
      name: {
        km: "ចំការជ្រៃខាងត្បូង",
        latin: "Chamka Chrey Khang Tbong"
      },
      id: "15060304"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "15060305"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "15060401"
    },
    {
      name: {
        km: "ផ្ចឹកជ្រុំ",
        latin: "Pchoek Chrum"
      },
      id: "15060402"
    },
    {
      name: {
        km: "ស្ទឹងថ្មី",
        latin: "Stueng Thmei"
      },
      id: "15060403"
    },
    {
      name: {
        km: "ប្រម៉ោយ",
        latin: "Pramaoy"
      },
      id: "15060404"
    },
    {
      name: {
        km: "ទំព័រ",
        latin: "Tumpoar"
      },
      id: "15060405"
    },
    {
      name: {
        km: "ឯកភាព",
        latin: "Aekakpheap"
      },
      id: "15060501"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15060502"
    },
    {
      name: {
        km: "សង្គមថ្មី",
        latin: "Sangkum Thmei"
      },
      id: "15060503"
    },
    {
      name: {
        km: "គោករំល",
        latin: "Kouk Rumlo"
      },
      id: "15070101"
    },
    {
      name: {
        km: "ទួលទទឹង",
        latin: "Tuol Totueng"
      },
      id: "15070102"
    },
    {
      name: {
        km: "ទួលថ្ម",
        latin: "Tuol Thma"
      },
      id: "15070103"
    },
    {
      name: {
        km: "បួច្រេស",
        latin: "Buo Chres"
      },
      id: "15070104"
    },
    {
      name: {
        km: "ព្រៃរោង",
        latin: "Prey Roung"
      },
      id: "15070105"
    },
    {
      name: {
        km: "តាលោ",
        latin: "Ta Lou"
      },
      id: "15070106"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "15070107"
    },
    {
      name: {
        km: "ទួលជ្រាវ",
        latin: "Tuol Chreav"
      },
      id: "15070108"
    },
    {
      name: {
        km: "ព្រៃតោ",
        latin: "Prey Tao"
      },
      id: "15070109"
    },
    {
      name: {
        km: "បឹងកក់",
        latin: "Boeng Kak"
      },
      id: "15070110"
    },
    {
      name: {
        km: "ព្រៃវាំង",
        latin: "Prey Veang"
      },
      id: "15070111"
    },
    {
      name: {
        km: "សិរីគន្ធា",
        latin: "Serei Kunthea"
      },
      id: "15070112"
    },
    {
      name: {
        km: "ត្រយ៉ងស",
        latin: "Trayang Sa"
      },
      id: "15070113"
    },
    {
      name: {
        km: "ឆ្នាល់មាន់",
        latin: "Chhnal Moan"
      },
      id: "15070114"
    },
    {
      name: {
        km: "ព្រៃកន្ទួត",
        latin: "Prey Kantuot"
      },
      id: "15070115"
    },
    {
      name: {
        km: "តាំងគោក",
        latin: "Tang Kouk"
      },
      id: "15070116"
    },
    {
      name: {
        km: "បោសគរ",
        latin: "Baos Kor"
      },
      id: "15070117"
    },
    {
      name: {
        km: "ប្រហាល",
        latin: "Prahal"
      },
      id: "15070118"
    },
    {
      name: {
        km: "រហាលទិល",
        latin: "Rohal Til"
      },
      id: "15070119"
    },
    {
      name: {
        km: "សំសាន្ត",
        latin: "Som Sant"
      },
      id: "15070120"
    },
    {
      name: {
        km: "កោះស្វាយ",
        latin: "Kaoh Svay"
      },
      id: "15070121"
    },
    {
      name: {
        km: "កោះវត្ដ",
        latin: "Kaoh Voat"
      },
      id: "15070122"
    },
    {
      name: {
        km: "កោះស្វាយ",
        latin: "Kaoh Svay"
      },
      id: "15070201"
    },
    {
      name: {
        km: "តាសាស់",
        latin: "Ta Sas"
      },
      id: "15070202"
    },
    {
      name: {
        km: "ក្រញាំ",
        latin: "Kranham"
      },
      id: "15070203"
    },
    {
      name: {
        km: "ព្រៃកន្លង់",
        latin: "Prey Kanlang"
      },
      id: "15070204"
    },
    {
      name: {
        km: "ថ្លុកដង្កោ",
        latin: "Thlok Dangkao"
      },
      id: "15070205"
    },
    {
      name: {
        km: "បត់រំដួល",
        latin: "Bat Rumduol"
      },
      id: "15070206"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "15070207"
    },
    {
      name: {
        km: "ផ្ទះរុង",
        latin: "Phteah Rung"
      },
      id: "15070208"
    },
    {
      name: {
        km: "ដំណាក់កន្សែង",
        latin: "Damnak Kansaeng"
      },
      id: "15070209"
    },
    {
      name: {
        km: "ស្ដុកខ្ទុំ",
        latin: "Sdok Khtum"
      },
      id: "15070210"
    },
    {
      name: {
        km: "ព្រហស្បតិ៍ក្បាល",
        latin: "Prohoas Kbal"
      },
      id: "15070211"
    },
    {
      name: {
        km: "ជង្រុក",
        latin: "Chongruk"
      },
      id: "15070212"
    },
    {
      name: {
        km: "ជ្រៃក្រឹម",
        latin: "Chrey Kroem"
      },
      id: "15070213"
    },
    {
      name: {
        km: "ម៉ាលិក",
        latin: "Malik"
      },
      id: "16010101"
    },
    {
      name: {
        km: "កាតែ",
        latin: "Katae"
      },
      id: "16010102"
    },
    {
      name: {
        km: "កាហល",
        latin: "Ka Hal"
      },
      id: "16010103"
    },
    {
      name: {
        km: "លោម",
        latin: "Laom"
      },
      id: "16010104"
    },
    {
      name: {
        km: "កាជូត",
        latin: "Ka Chut"
      },
      id: "16010301"
    },
    {
      name: {
        km: "ណាយ",
        latin: "Nay"
      },
      id: "16010302"
    },
    {
      name: {
        km: "មុយ",
        latin: "Muy"
      },
      id: "16010303"
    },
    {
      name: {
        km: "ពែង",
        latin: "Peaeng"
      },
      id: "16010304"
    },
    {
      name: {
        km: "ចង",
        latin: "Chang"
      },
      id: "16010305"
    },
    {
      name: {
        km: "តាង៉ា",
        latin: "Ta Nga"
      },
      id: "16010306"
    },
    {
      name: {
        km: "ចាយ",
        latin: "Chay"
      },
      id: "16010307"
    },
    {
      name: {
        km: "កែត",
        latin: "Kaet"
      },
      id: "16010308"
    },
    {
      name: {
        km: "តាំងជិ",
        latin: "Tang Chea"
      },
      id: "16010309"
    },
    {
      name: {
        km: "ដាល",
        latin: "Dal"
      },
      id: "16010310"
    },
    {
      name: {
        km: "តាំងសេ",
        latin: "Tang Se"
      },
      id: "16010311"
    },
    {
      name: {
        km: "ញ៉ាង",
        latin: "Nhang"
      },
      id: "16010312"
    },
    {
      name: {
        km: "តាឡាវ",
        latin: "Ta Lav"
      },
      id: "16010401"
    },
    {
      name: {
        km: "អ៊ីន",
        latin: "Inn"
      },
      id: "16010402"
    },
    {
      name: {
        km: "កាណាត",
        latin: "Ka Nat"
      },
      id: "16010403"
    },
    {
      name: {
        km: "កាក់",
        latin: "Kak"
      },
      id: "16010404"
    },
    {
      name: {
        km: "តាណង",
        latin: "Ta Nang"
      },
      id: "16010405"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum Muoy"
      },
      id: "16020101"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum Pir"
      },
      id: "16020102"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum Bei"
      },
      id: "16020103"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum Buon"
      },
      id: "16020104"
    },
    {
      name: {
        km: "ជ័យជំនះ",
        latin: "Chey Chumnas"
      },
      id: "16020201"
    },
    {
      name: {
        km: "៧មករា",
        latin: "7 Makara"
      },
      id: "16020203"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "16020208"
    },
    {
      name: {
        km: "អភិវឌ្ឍន៍",
        latin: "Akpiwat"
      },
      id: "16020209"
    },
    {
      name: {
        km: "អូរមៀត",
        latin: "Ou Romeat"
      },
      id: "16020210"
    },
    {
      name: {
        km: "ឡូន",
        latin: "Loun"
      },
      id: "16020301"
    },
    {
      name: {
        km: "ភ្នំ",
        latin: "Phnum"
      },
      id: "16020302"
    },
    {
      name: {
        km: "សិល",
        latin: "Sel"
      },
      id: "16020303"
    },
    {
      name: {
        km: "ឡាប៉ូ",
        latin: "La Pou"
      },
      id: "16020304"
    },
    {
      name: {
        km: "ជ្រី",
        latin: "Chri"
      },
      id: "16020305"
    },
    {
      name: {
        km: "ទេសអន្លុង",
        latin: "Tes Anlung"
      },
      id: "16020401"
    },
    {
      name: {
        km: "អូរកន្សែង",
        latin: "Ou Konsaeng"
      },
      id: "16020402"
    },
    {
      name: {
        km: "ភ្នំស្វាយ",
        latin: "Phnom Svay"
      },
      id: "16020403"
    },
    {
      name: {
        km: "អូរកន្តិល",
        latin: "Ou Kontil"
      },
      id: "16020404"
    },
    {
      name: {
        km: "ថ្មដា",
        latin: "Tmar Da"
      },
      id: "16020405"
    },
    {
      name: {
        km: "លើតូច",
        latin: "Leu Touch"
      },
      id: "16030101"
    },
    {
      name: {
        km: "សាលា",
        latin: "Sala"
      },
      id: "16030102"
    },
    {
      name: {
        km: "កាចក់",
        latin: "Ka Chak"
      },
      id: "16030103"
    },
    {
      name: {
        km: "កក់",
        latin: "Kak"
      },
      id: "16030104"
    },
    {
      name: {
        km: "យឺន",
        latin: "Yeun"
      },
      id: "16030105"
    },
    {
      name: {
        km: "ជ្រុង",
        latin: "Chrung"
      },
      id: "16030106"
    },
    {
      name: {
        km: "លើហន់",
        latin: "Leu Han"
      },
      id: "16030201"
    },
    {
      name: {
        km: "លើឃួន",
        latin: "Leu Kuon"
      },
      id: "16030202"
    },
    {
      name: {
        km: "គ្រៀង",
        latin: "Krieng"
      },
      id: "16030203"
    },
    {
      name: {
        km: "ទៀន",
        latin: "Tien"
      },
      id: "16030204"
    },
    {
      name: {
        km: "ប៉ាអរ",
        latin: "Pa Ar"
      },
      id: "16030205"
    },
    {
      name: {
        km: "ប៉ាឡែ",
        latin: "Pa Lae"
      },
      id: "16030206"
    },
    {
      name: {
        km: "រ៉យ",
        latin: "Ray"
      },
      id: "16030207"
    },
    {
      name: {
        km: "ជ្រុង",
        latin: "Chrung"
      },
      id: "16030208"
    },
    {
      name: {
        km: "សាលាវ",
        latin: "Sa Leav"
      },
      id: "16030209"
    },
    {
      name: {
        km: "ត្រុំ",
        latin: "Trom"
      },
      id: "16030301"
    },
    {
      name: {
        km: "ស៊ូ",
        latin: "Su"
      },
      id: "16030302"
    },
    {
      name: {
        km: "ញ៉ល",
        latin: "Nhol"
      },
      id: "16030303"
    },
    {
      name: {
        km: "ខ្មាំង",
        latin: "Khmang"
      },
      id: "16030304"
    },
    {
      name: {
        km: "ភូមិមួយ",
        latin: "Phum Muoy"
      },
      id: "16030305"
    },
    {
      name: {
        km: "លុងឃុង",
        latin: "Lung Khung"
      },
      id: "16030401"
    },
    {
      name: {
        km: "ប៉ាអរ",
        latin: "Pa Ar"
      },
      id: "16030402"
    },
    {
      name: {
        km: "ប៉ាយ៉ាង",
        latin: "Pa Yang"
      },
      id: "16030403"
    },
    {
      name: {
        km: "ជ្រាក",
        latin: "Chreak"
      },
      id: "16030404"
    },
    {
      name: {
        km: "យ៉ាសោម",
        latin: "Ya Som"
      },
      id: "16030501"
    },
    {
      name: {
        km: "ស៊ើង",
        latin: "Saeung"
      },
      id: "16030502"
    },
    {
      name: {
        km: "ចែត",
        latin: "Chaet"
      },
      id: "16030503"
    },
    {
      name: {
        km: "គ្លិ",
        latin: "Kli"
      },
      id: "16030504"
    },
    {
      name: {
        km: "យ៉ែម",
        latin: "Yaem"
      },
      id: "16030505"
    },
    {
      name: {
        km: "ស្មាច់",
        latin: "Smach"
      },
      id: "16030506"
    },
    {
      name: {
        km: "ទូយ",
        latin: "Tuy"
      },
      id: "16030601"
    },
    {
      name: {
        km: "កប",
        latin: "Kab"
      },
      id: "16030602"
    },
    {
      name: {
        km: "លូត",
        latin: "Lut"
      },
      id: "16030603"
    },
    {
      name: {
        km: "ប៉ាណល",
        latin: "Pa Nal"
      },
      id: "16030604"
    },
    {
      name: {
        km: "ស្រែពកធំ",
        latin: "Srae Pok Thum"
      },
      id: "16040101"
    },
    {
      name: {
        km: "ស្រែពកតូច",
        latin: "Srae Pok Touch"
      },
      id: "16040102"
    },
    {
      name: {
        km: "នាងដី",
        latin: "Neang Dei"
      },
      id: "16040103"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum Muoy"
      },
      id: "16040201"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum Pir"
      },
      id: "16040202"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum Bei"
      },
      id: "16040203"
    },
    {
      name: {
        km: "តាអងកាតេ",
        latin: "Ta Ang Ka Te"
      },
      id: "16040301"
    },
    {
      name: {
        km: "តាអងប៉ុក",
        latin: "Ta Ang Pok"
      },
      id: "16040302"
    },
    {
      name: {
        km: "ទុស",
        latin: "Tus"
      },
      id: "16040303"
    },
    {
      name: {
        km: "សិក",
        latin: "Sec"
      },
      id: "16040304"
    },
    {
      name: {
        km: "តាកាប់",
        latin: "Ta Kab"
      },
      id: "16040305"
    },
    {
      name: {
        km: "តឺន",
        latin: "Teun"
      },
      id: "16040401"
    },
    {
      name: {
        km: "ឡាអិន",
        latin: "La En"
      },
      id: "16040402"
    },
    {
      name: {
        km: "តាហឺយ",
        latin: "Ta Heuy"
      },
      id: "16040403"
    },
    {
      name: {
        km: "កាំបាក់",
        latin: "Kam Bak"
      },
      id: "16040404"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum Muoy"
      },
      id: "16040501"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum Pir"
      },
      id: "16040502"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum Bei"
      },
      id: "16040503"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum Buon"
      },
      id: "16040504"
    },
    {
      name: {
        km: "ភូមិ ៥",
        latin: "Phum Pram"
      },
      id: "16040505"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum Muoy"
      },
      id: "16040601"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum Pir"
      },
      id: "16040602"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum Bei"
      },
      id: "16040603"
    },
    {
      name: {
        km: "អូរកាន",
        latin: "Ou Kan"
      },
      id: "16050101"
    },
    {
      name: {
        km: "ស្រែឈូក",
        latin: "Srae Chhuk"
      },
      id: "16050102"
    },
    {
      name: {
        km: "សាមខា",
        latin: "Sam Kha"
      },
      id: "16050103"
    },
    {
      name: {
        km: "ដីឡូតិ៍",
        latin: "Dei Lou"
      },
      id: "16050104"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "16050105"
    },
    {
      name: {
        km: "លំផាត់",
        latin: "Lumphat"
      },
      id: "16050106"
    },
    {
      name: {
        km: "សាយ៉ស់",
        latin: "Sayas"
      },
      id: "16050201"
    },
    {
      name: {
        km: "កាឡែង",
        latin: "Ka Laeng"
      },
      id: "16050202"
    },
    {
      name: {
        km: "កាណាងកិត",
        latin: "Ka Nang Ket"
      },
      id: "16050203"
    },
    {
      name: {
        km: "កាំផ្លិញ",
        latin: "Kam Phlenh"
      },
      id: "16050301"
    },
    {
      name: {
        km: "កាទឹង",
        latin: "Ka Tueng"
      },
      id: "16050302"
    },
    {
      name: {
        km: "កាទៀង",
        latin: "Ka Tieng"
      },
      id: "16050303"
    },
    {
      name: {
        km: "កាលង",
        latin: "Ka Lorng"
      },
      id: "16050304"
    },
    {
      name: {
        km: "កាទៀង",
        latin: "Ka Tieng"
      },
      id: "16050401"
    },
    {
      name: {
        km: "កាចាញ",
        latin: "Ka Chanh"
      },
      id: "16050402"
    },
    {
      name: {
        km: "អ៊ុល",
        latin: "Ul"
      },
      id: "16050501"
    },
    {
      name: {
        km: "ព្រួក",
        latin: "Pruok"
      },
      id: "16050502"
    },
    {
      name: {
        km: "បាតាង",
        latin: "Ba Tang"
      },
      id: "16050503"
    },
    {
      name: {
        km: "ចាងរ៉ា",
        latin: "Chang Ra"
      },
      id: "16050504"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "16050601"
    },
    {
      name: {
        km: "ប៉ា តត់",
        latin: "Pa Tat"
      },
      id: "16050602"
    },
    {
      name: {
        km: "កែងសាន់",
        latin: "Kaeng Sann"
      },
      id: "16050603"
    },
    {
      name: {
        km: "សាមុត្រលើ",
        latin: "Samot Leu"
      },
      id: "16050604"
    },
    {
      name: {
        km: "ពម",
        latin: "Pom"
      },
      id: "16050605"
    },
    {
      name: {
        km: "ណងហៃ",
        latin: "Nang Hai"
      },
      id: "16050606"
    },
    {
      name: {
        km: "សាមុត្រក្រោម",
        latin: "Samot Kraom"
      },
      id: "16050607"
    },
    {
      name: {
        km: "ចាអ៊ុងកេត",
        latin: "Cha Ung Ket"
      },
      id: "16060101"
    },
    {
      name: {
        km: "ចាអ៊ុងចាន់",
        latin: "Char Ung Chan"
      },
      id: "16060102"
    },
    {
      name: {
        km: "ធួយអំពិល",
        latin: "Thuoy Ampil"
      },
      id: "16060103"
    },
    {
      name: {
        km: "ធួយទុំ",
        latin: "Thuoy Tum"
      },
      id: "16060104"
    },
    {
      name: {
        km: "ចាអ៊ុងកៅ",
        latin: "Cha Ung Kau"
      },
      id: "16060105"
    },
    {
      name: {
        km: "ម៉ាស",
        latin: "Mas"
      },
      id: "16060201"
    },
    {
      name: {
        km: "កាន់ឈឺង",
        latin: "Kan Chheung"
      },
      id: "16060202"
    },
    {
      name: {
        km: "ក្រេះ",
        latin: "Kreh"
      },
      id: "16060203"
    },
    {
      name: {
        km: "តាង៉ាច",
        latin: "Ta Ngeach"
      },
      id: "16060204"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "16060205"
    },
    {
      name: {
        km: "ខ្មែង",
        latin: "Khmaeng"
      },
      id: "16060206"
    },
    {
      name: {
        km: "ក្រឡា",
        latin: "Krala"
      },
      id: "16060207"
    },
    {
      name: {
        km: "កងកុយ",
        latin: "Kang Koy"
      },
      id: "16060208"
    },
    {
      name: {
        km: "ប៉ាជន់ធំ",
        latin: "Pa Chon Thum"
      },
      id: "16060301"
    },
    {
      name: {
        km: "ប៉ាអរ",
        latin: "Pa Ar"
      },
      id: "16060302"
    },
    {
      name: {
        km: "អូម",
        latin: "Oum"
      },
      id: "16060303"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "16060304"
    },
    {
      name: {
        km: "កាឡៃ ១",
        latin: "Kalai Muoy"
      },
      id: "16060401"
    },
    {
      name: {
        km: "កាឡៃ ២",
        latin: "Kalai Pir"
      },
      id: "16060402"
    },
    {
      name: {
        km: "កាឡៃ ៣",
        latin: "Kalai Bei"
      },
      id: "16060403"
    },
    {
      name: {
        km: "អូរជុំ",
        latin: "Ou Chum"
      },
      id: "16060501"
    },
    {
      name: {
        km: "ថារ៉ងជង",
        latin: "Tharang Chong"
      },
      id: "16060502"
    },
    {
      name: {
        km: "ថារ៉ងស្វាយ",
        latin: "Tharang Svay"
      },
      id: "16060503"
    },
    {
      name: {
        km: "ល្អឺនក្រែន",
        latin: "L'eun Kraen"
      },
      id: "16060504"
    },
    {
      name: {
        km: "ល្អឺនជង",
        latin: "L'eun Chong"
      },
      id: "16060505"
    },
    {
      name: {
        km: "តងប្លេង",
        latin: "Tang Pleng"
      },
      id: "16060506"
    },
    {
      name: {
        km: "តាកាម៉ាល់",
        latin: "Ta Kamal"
      },
      id: "16060507"
    },
    {
      name: {
        km: "ល្អឺនកាំងមីស",
        latin: "L'eun Kang Mis"
      },
      id: "16060508"
    },
    {
      name: {
        km: "កាម៉ែន",
        latin: "Ka Meaen"
      },
      id: "16060601"
    },
    {
      name: {
        km: "ប្រាក់",
        latin: "Prak"
      },
      id: "16060603"
    },
    {
      name: {
        km: "បរញ៉ុក",
        latin: "Bar Nhu"
      },
      id: "16060604"
    },
    {
      name: {
        km: "ពីង",
        latin: "Ping"
      },
      id: "16060605"
    },
    {
      name: {
        km: "ល្អក់",
        latin: "L'ak"
      },
      id: "16060701"
    },
    {
      name: {
        km: "ក្រលង",
        latin: "Kralong"
      },
      id: "16060702"
    },
    {
      name: {
        km: "គោក",
        latin: "Kouk"
      },
      id: "16060703"
    },
    {
      name: {
        km: "កាំ",
        latin: "Kam"
      },
      id: "16060704"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum Pir"
      },
      id: "16060705"
    },
    {
      name: {
        km: "ទុង",
        latin: "Tung"
      },
      id: "16070101"
    },
    {
      name: {
        km: "ដេះ",
        latin: "Des"
      },
      id: "16070102"
    },
    {
      name: {
        km: "ប្លរ",
        latin: "Plor"
      },
      id: "16070103"
    },
    {
      name: {
        km: "តាកុកព្នង",
        latin: "Ta Kok Pnong"
      },
      id: "16070104"
    },
    {
      name: {
        km: "តាកុកច្រាយ",
        latin: "Ta Kok Chray"
      },
      id: "16070105"
    },
    {
      name: {
        km: "ព្រិល",
        latin: "Pril"
      },
      id: "16070106"
    },
    {
      name: {
        km: "ឡែ",
        latin: "Le"
      },
      id: "16070201"
    },
    {
      name: {
        km: "កាទេ",
        latin: "Ka Te"
      },
      id: "16070202"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Trang"
      },
      id: "16070203"
    },
    {
      name: {
        km: "អ៊ុន",
        latin: "Un"
      },
      id: "16070204"
    },
    {
      name: {
        km: "ប៉ក់ធំ",
        latin: "Pak Thum"
      },
      id: "16070301"
    },
    {
      name: {
        km: "ប៉់ក់តូច",
        latin: "Pak Touch"
      },
      id: "16070302"
    },
    {
      name: {
        km: "ប៉ក់ពោរ",
        latin: "Pak Por"
      },
      id: "16070303"
    },
    {
      name: {
        km: "ឡុំ",
        latin: "Lom"
      },
      id: "16070304"
    },
    {
      name: {
        km: "ប្លង់",
        latin: "Plang"
      },
      id: "16070401"
    },
    {
      name: {
        km: "គងធំ",
        latin: "Kong Thum"
      },
      id: "16070402"
    },
    {
      name: {
        km: "ប៉ាអរ",
        latin: "Pa Ar"
      },
      id: "16070403"
    },
    {
      name: {
        km: "គងយុ",
        latin: "Kong Yu"
      },
      id: "16070404"
    },
    {
      name: {
        km: "ប៉ាតាង",
        latin: "Pa Tang"
      },
      id: "16070501"
    },
    {
      name: {
        km: "ភិ",
        latin: "Phi"
      },
      id: "16070502"
    },
    {
      name: {
        km: "ប៉ាដល",
        latin: "Pa Dal"
      },
      id: "16070503"
    },
    {
      name: {
        km: "សោមកានិញ",
        latin: "Saom Ka nihn"
      },
      id: "16070601"
    },
    {
      name: {
        km: "សោមត្រក",
        latin: "Saom Trak"
      },
      id: "16070602"
    },
    {
      name: {
        km: "សោមគល់",
        latin: "Saom Kol"
      },
      id: "16070603"
    },
    {
      name: {
        km: "តេនង៉ល",
        latin: "Ten Ngol"
      },
      id: "16070701"
    },
    {
      name: {
        km: "ពាក់",
        latin: "Peak"
      },
      id: "16070702"
    },
    {
      name: {
        km: "ទេនសុស",
        latin: "Ten Soh"
      },
      id: "16070703"
    },
    {
      name: {
        km: "ដរ",
        latin: "Dar"
      },
      id: "16070704"
    },
    {
      name: {
        km: "សំ",
        latin: "Sam"
      },
      id: "16070705"
    },
    {
      name: {
        km: "ចាន់",
        latin: "Chan"
      },
      id: "16080102"
    },
    {
      name: {
        km: "ជួយ",
        latin: "Chuoy"
      },
      id: "16080103"
    },
    {
      name: {
        km: "តាបូក",
        latin: "Ta Bouk"
      },
      id: "16080104"
    },
    {
      name: {
        km: "ប៉ាងគិត",
        latin: "Pangkit"
      },
      id: "16080105"
    },
    {
      name: {
        km: "សញ",
        latin: "Sanh"
      },
      id: "16080106"
    },
    {
      name: {
        km: "កិគួង",
        latin: "Ki Kuong"
      },
      id: "16080107"
    },
    {
      name: {
        km: "រៀងវិញ",
        latin: "Rieng Vinh"
      },
      id: "16080108"
    },
    {
      name: {
        km: "ភ្លឺធំ",
        latin: "Phlueu Thum"
      },
      id: "16080109"
    },
    {
      name: {
        km: "ភ្លឺតូច",
        latin: "Phlueu Touch"
      },
      id: "16080110"
    },
    {
      name: {
        km: "តាវែង",
        latin: "Ta Veaeng"
      },
      id: "16080111"
    },
    {
      name: {
        km: "ទំពួនរឺងធំ",
        latin: "Tumpuon Reung Thum"
      },
      id: "16080201"
    },
    {
      name: {
        km: "ទុន",
        latin: "Tun"
      },
      id: "16080202"
    },
    {
      name: {
        km: "កោះប៉ុង",
        latin: "Kaoh Pong"
      },
      id: "16080203"
    },
    {
      name: {
        km: "សៀងសាយ",
        latin: "Sieng Say"
      },
      id: "16080204"
    },
    {
      name: {
        km: "ផ្យ៉ាង",
        latin: "Phyang"
      },
      id: "16080205"
    },
    {
      name: {
        km: "កិគួង",
        latin: "Ke Kuong"
      },
      id: "16080206"
    },
    {
      name: {
        km: "តាង៉ាច",
        latin: "Ta Ngach"
      },
      id: "16080207"
    },
    {
      name: {
        km: "ផាវ",
        latin: "Phav"
      },
      id: "16080208"
    },
    {
      name: {
        km: "ទំពួនរឺងតូច",
        latin: "Tumpuon Reung Touch"
      },
      id: "16080209"
    },
    {
      name: {
        km: "វៀងចាន់",
        latin: "Vieng Chan"
      },
      id: "16080210"
    },
    {
      name: {
        km: "បានប៉ុង",
        latin: "Ban Pong"
      },
      id: "16090101"
    },
    {
      name: {
        km: "បានហ្វាំង",
        latin: "Ban Phang"
      },
      id: "16090102"
    },
    {
      name: {
        km: "ហាត់ប៉ក់",
        latin: "Hat Pak"
      },
      id: "16090301"
    },
    {
      name: {
        km: "វើនហយ",
        latin: "Veun Hay"
      },
      id: "16090302"
    },
    {
      name: {
        km: "ឡាំប៉ាត់",
        latin: "Lam Pat"
      },
      id: "16090303"
    },
    {
      name: {
        km: "កាចូនលើ",
        latin: "Ka Choun Leu"
      },
      id: "16090401"
    },
    {
      name: {
        km: "កាចូនក្រោម",
        latin: "Ka Choun Kraom"
      },
      id: "16090402"
    },
    {
      name: {
        km: "វ៉ង",
        latin: "Vorng"
      },
      id: "16090403"
    },
    {
      name: {
        km: "វ៉ាយ",
        latin: "Vay"
      },
      id: "16090404"
    },
    {
      name: {
        km: "ទៀមលើ",
        latin: "Tiem Leu"
      },
      id: "16090405"
    },
    {
      name: {
        km: "កាឡឹម",
        latin: "Ka Loem"
      },
      id: "16090406"
    },
    {
      name: {
        km: "ប៉ាតឹង",
        latin: "Pa Toeng"
      },
      id: "16090501"
    },
    {
      name: {
        km: "ឡាំងអាវ",
        latin: "Lang Av"
      },
      id: "16090502"
    },
    {
      name: {
        km: "ប៉ាហយ",
        latin: "Pa Hay"
      },
      id: "16090503"
    },
    {
      name: {
        km: "កោះពាក្យ",
        latin: "Kaoh Peak"
      },
      id: "16090601"
    },
    {
      name: {
        km: "ផាក់ណាម",
        latin: "Phak Nam"
      },
      id: "16090602"
    },
    {
      name: {
        km: "ឃួន",
        latin: "Khuon"
      },
      id: "16090603"
    },
    {
      name: {
        km: "ឡាឡៃ",
        latin: "La Lai"
      },
      id: "16090701"
    },
    {
      name: {
        km: "រ៉ក",
        latin: "Rak"
      },
      id: "16090702"
    },
    {
      name: {
        km: "ឡាម៉ឺយ",
        latin: "La Meuy"
      },
      id: "16090703"
    },
    {
      name: {
        km: "ត្រាក់",
        latin: "Trak"
      },
      id: "16090704"
    },
    {
      name: {
        km: "ប៉ាកាឡាន់",
        latin: "Pa Kalan"
      },
      id: "16090801"
    },
    {
      name: {
        km: "កំពង់ចាម",
        latin: "Kampong Cham"
      },
      id: "16090802"
    },
    {
      name: {
        km: "ភ្នំកុកឡាវ",
        latin: "Phnum Kok Lav"
      },
      id: "16090901"
    },
    {
      name: {
        km: "ភ្នំកុកព្រៅ",
        latin: "Phnum Kok Prov"
      },
      id: "16090902"
    },
    {
      name: {
        km: "កាឡៃតាវ៉ង",
        latin: "Kalai Ta Vorng"
      },
      id: "16090903"
    },
    {
      name: {
        km: "កាឡៃសាពូន",
        latin: "Kalai Sapun"
      },
      id: "16090904"
    },
    {
      name: {
        km: "ទៀមក្រោម",
        latin: "Tiem Kraom"
      },
      id: "16090905"
    },
    {
      name: {
        km: "វើនសៃ",
        latin: "Veun Sai"
      },
      id: "16091001"
    },
    {
      name: {
        km: "បាក់កែ",
        latin: "Bak Kae"
      },
      id: "16091002"
    },
    {
      name: {
        km: "អ៊ីទូប",
        latin: "I Tub"
      },
      id: "16091003"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "16091004"
    },
    {
      name: {
        km: "កាឡាន់",
        latin: "Ka Lan"
      },
      id: "16091005"
    },
    {
      name: {
        km: "កងណក",
        latin: "Kang Nak"
      },
      id: "16091006"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "17010101"
    },
    {
      name: {
        km: "យាង",
        latin: "Yeang"
      },
      id: "17010102"
    },
    {
      name: {
        km: "អានូក",
        latin: "Anuk"
      },
      id: "17010103"
    },
    {
      name: {
        km: "ព្រៃល្វៃ",
        latin: "Prey Lvey"
      },
      id: "17010104"
    },
    {
      name: {
        km: "ព្រៃចេង",
        latin: "Prey Cheng"
      },
      id: "17010105"
    },
    {
      name: {
        km: "ក្បាលចាម",
        latin: "Kbal Cham"
      },
      id: "17010106"
    },
    {
      name: {
        km: "គោកល្វា",
        latin: "Kouk Lvea"
      },
      id: "17010107"
    },
    {
      name: {
        km: "ចាររកា",
        latin: "Char Roka"
      },
      id: "17010108"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "17010109"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "17010110"
    },
    {
      name: {
        km: "គោកក្បាត់",
        latin: "Kouk Kbat"
      },
      id: "17010111"
    },
    {
      name: {
        km: "តាទយ",
        latin: "Ta Toy"
      },
      id: "17010112"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "17010113"
    },
    {
      name: {
        km: "ដូនស្វា",
        latin: "Doun Sva"
      },
      id: "17010114"
    },
    {
      name: {
        km: "មាជា",
        latin: "Mea Chea"
      },
      id: "17010115"
    },
    {
      name: {
        km: "គោកថ្នល់",
        latin: "Kouk Thnal"
      },
      id: "17010116"
    },
    {
      name: {
        km: "ខ្លុង",
        latin: "Khlong"
      },
      id: "17010117"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "17010118"
    },
    {
      name: {
        km: "គោកធ្នង់",
        latin: "Kouk Thnong"
      },
      id: "17010119"
    },
    {
      name: {
        km: "កត្រកៀត",
        latin: "Ka Trakiet"
      },
      id: "17010120"
    },
    {
      name: {
        km: "រការ",
        latin: "Rokar"
      },
      id: "17010201"
    },
    {
      name: {
        km: "បត់",
        latin: "Bat"
      },
      id: "17010202"
    },
    {
      name: {
        km: "គោកយាង",
        latin: "Kouk Yeang"
      },
      id: "17010203"
    },
    {
      name: {
        km: "បុស្សល្ហុង",
        latin: "Bos Lhong"
      },
      id: "17010204"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "17010205"
    },
    {
      name: {
        km: "ខ្ចាស់",
        latin: "Khchas"
      },
      id: "17010206"
    },
    {
      name: {
        km: "ដូនពេង",
        latin: "Doun Peng"
      },
      id: "17010207"
    },
    {
      name: {
        km: "រំដួលថ្មី",
        latin: "Rumduol Thmei"
      },
      id: "17010208"
    },
    {
      name: {
        km: "ទំលាប់",
        latin: "Tumloab"
      },
      id: "17010301"
    },
    {
      name: {
        km: "ខ្ចារ",
        latin: "Khchar"
      },
      id: "17010302"
    },
    {
      name: {
        km: "គោកដូងថ្មី",
        latin: "Kouk Doung Thmei"
      },
      id: "17010303"
    },
    {
      name: {
        km: "គោកភ្នៅ",
        latin: "Kouk Pnov"
      },
      id: "17010304"
    },
    {
      name: {
        km: "គោកត្របែក",
        latin: "Kouk Trabaek"
      },
      id: "17010305"
    },
    {
      name: {
        km: "អន្ទិតសុខ",
        latin: "Antit Sokh"
      },
      id: "17010306"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "17010307"
    },
    {
      name: {
        km: "គោកក្រោល",
        latin: "Kouk Kraol"
      },
      id: "17010308"
    },
    {
      name: {
        km: "កប្ដៀក",
        latin: "Ka Bdiek"
      },
      id: "17010309"
    },
    {
      name: {
        km: "ចេកក្បូរ",
        latin: "Chek Kbour"
      },
      id: "17010310"
    },
    {
      name: {
        km: "គោកស្នួល",
        latin: "Kouk Snuol"
      },
      id: "17010311"
    },
    {
      name: {
        km: "ប្រាសាទត្រាវ",
        latin: "Prasat Trav"
      },
      id: "17010312"
    },
    {
      name: {
        km: "កំប្លើប",
        latin: "Kamblaeub"
      },
      id: "17010313"
    },
    {
      name: {
        km: "ដូនអែម",
        latin: "Doun Aem"
      },
      id: "17010314"
    },
    {
      name: {
        km: "គោកដូងចាស់",
        latin: "Kouk Doung Chas"
      },
      id: "17010315"
    },
    {
      name: {
        km: "បុះក្រឡុក",
        latin: "Bos Kralok"
      },
      id: "17010316"
    },
    {
      name: {
        km: "អូរគោល",
        latin: "Ou Koal"
      },
      id: "17010317"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "17010401"
    },
    {
      name: {
        km: "ខាន់សរ",
        latin: "Khan Sa"
      },
      id: "17010402"
    },
    {
      name: {
        km: "គោល",
        latin: "Koul"
      },
      id: "17010403"
    },
    {
      name: {
        km: "អំពិលធ្នង់",
        latin: "Ampil Thnong"
      },
      id: "17010404"
    },
    {
      name: {
        km: "ដូនមៀវ",
        latin: "Doun Miev"
      },
      id: "17010405"
    },
    {
      name: {
        km: "តាគួយ",
        latin: "Ta Kuoy"
      },
      id: "17010406"
    },
    {
      name: {
        km: "ព្រៃអារ",
        latin: "Prey Ar"
      },
      id: "17010407"
    },
    {
      name: {
        km: "រមៀត",
        latin: "Romiet"
      },
      id: "17010501"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "17010502"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "17010503"
    },
    {
      name: {
        km: "គោកថ្មី",
        latin: "Kouk Thmei"
      },
      id: "17010504"
    },
    {
      name: {
        km: "ទន្លេស",
        latin: "Tonle Sa"
      },
      id: "17010505"
    },
    {
      name: {
        km: "កុក",
        latin: "Kok"
      },
      id: "17010506"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "17010507"
    },
    {
      name: {
        km: "នគរភាស១",
        latin: "Nokor Pheas Muoy"
      },
      id: "17010508"
    },
    {
      name: {
        km: "នគរភាស២",
        latin: "Nokor Pheas Pir"
      },
      id: "17010509"
    },
    {
      name: {
        km: "ជំពូង",
        latin: "Tumpung"
      },
      id: "17010510"
    },
    {
      name: {
        km: "រាជជន្ទល់",
        latin: "Reach Chontol"
      },
      id: "17010601"
    },
    {
      name: {
        km: "រវៀងថ្មី",
        latin: "Rovieng Thmei"
      },
      id: "17010602"
    },
    {
      name: {
        km: "ស្រែខ្វាវ",
        latin: "Srae Khvav"
      },
      id: "17010603"
    },
    {
      name: {
        km: "គោកក្នាំង",
        latin: "Kouk Knang"
      },
      id: "17010604"
    },
    {
      name: {
        km: "គោកចាស់",
        latin: "Kouk Chas"
      },
      id: "17010605"
    },
    {
      name: {
        km: "ជំនុំរាជ្យ",
        latin: "Chumnum Reach"
      },
      id: "17010606"
    },
    {
      name: {
        km: "ស្លាត",
        latin: "Slat"
      },
      id: "17010607"
    },
    {
      name: {
        km: "ស្រែប្រាំង",
        latin: "Srae Prang"
      },
      id: "17010608"
    },
    {
      name: {
        km: "រណ្ដាស",
        latin: "Rundas"
      },
      id: "17010609"
    },
    {
      name: {
        km: "រលំ",
        latin: "Rolum"
      },
      id: "17010610"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "17010611"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "17010701"
    },
    {
      name: {
        km: "ភ្នៅ",
        latin: "Pnov"
      },
      id: "17010702"
    },
    {
      name: {
        km: "ម្កាក់",
        latin: "Mkak"
      },
      id: "17010703"
    },
    {
      name: {
        km: "គោកចាន់",
        latin: "Kouk Chan"
      },
      id: "17010704"
    },
    {
      name: {
        km: "ក រលំ",
        latin: "Ka Rolum"
      },
      id: "17010705"
    },
    {
      name: {
        km: "ទំពូង",
        latin: "Tumpung"
      },
      id: "17010706"
    },
    {
      name: {
        km: "ប្រាំដំឡឹង",
        latin: "Pram Damloeng"
      },
      id: "17010707"
    },
    {
      name: {
        km: "តាលាវ",
        latin: "Ta Leav"
      },
      id: "17010708"
    },
    {
      name: {
        km: "បាយម៉ាត",
        latin: "Bay Mat"
      },
      id: "17010709"
    },
    {
      name: {
        km: "ត្រពាំងភ្លោះ",
        latin: "Trapeang Phluoh"
      },
      id: "17010710"
    },
    {
      name: {
        km: "តាសោម",
        latin: "Ta Saom"
      },
      id: "17010711"
    },
    {
      name: {
        km: "គោកថ្មី",
        latin: "Kouk Thmei"
      },
      id: "17010712"
    },
    {
      name: {
        km: "ស្វាយជុំ",
        latin: "Svay Chum"
      },
      id: "17010713"
    },
    {
      name: {
        km: "ត្រពាំងទូក",
        latin: "Trapeang Touk"
      },
      id: "17020101"
    },
    {
      name: {
        km: "ជប់",
        latin: "Chub"
      },
      id: "17020102"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "17020103"
    },
    {
      name: {
        km: "ទ័ពស្វាយ",
        latin: "Toap Svay"
      },
      id: "17020104"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "17020105"
    },
    {
      name: {
        km: "លាងដៃ",
        latin: "Leang Dai"
      },
      id: "17020201"
    },
    {
      name: {
        km: "ដូនឪ",
        latin: "Daun Ov"
      },
      id: "17020202"
    },
    {
      name: {
        km: "ភ្លង់",
        latin: "Phlong"
      },
      id: "17020203"
    },
    {
      name: {
        km: "តាប្រុក",
        latin: "Ta Prok"
      },
      id: "17020204"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "17020205"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "17020206"
    },
    {
      name: {
        km: "បំពេញរាជ្យ",
        latin: "Bampenh Reach"
      },
      id: "17020207"
    },
    {
      name: {
        km: "ស្ពានថ្មី",
        latin: "Spean Thmei"
      },
      id: "17020208"
    },
    {
      name: {
        km: "ពាក់ស្នែងថ្មី",
        latin: "Peak Sneng Thmei"
      },
      id: "17020301"
    },
    {
      name: {
        km: "ពាក់ស្នែងចាស់",
        latin: "Peak Sneng Chas"
      },
      id: "17020302"
    },
    {
      name: {
        km: "លៀប",
        latin: "Leab"
      },
      id: "17020303"
    },
    {
      name: {
        km: "ខ្ទីង",
        latin: "Khting"
      },
      id: "17020304"
    },
    {
      name: {
        km: "សណ្ដាន់",
        latin: "Sandan"
      },
      id: "17020305"
    },
    {
      name: {
        km: "ជប់សោម",
        latin: "Chub Saom"
      },
      id: "17020306"
    },
    {
      name: {
        km: "គោកកក់",
        latin: "Kok Kak"
      },
      id: "17020401"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "17020402"
    },
    {
      name: {
        km: "កណ្តោល",
        latin: "Kandol"
      },
      id: "17020403"
    },
    {
      name: {
        km: "តាត្រាវ",
        latin: "Ta Trav"
      },
      id: "17020404"
    },
    {
      name: {
        km: "បុស្សតាត្រាវ",
        latin: "Bos Ta Trav"
      },
      id: "17020405"
    },
    {
      name: {
        km: "ព្រះគោថ្មី",
        latin: "Preah Ko Thmei"
      },
      id: "17020406"
    },
    {
      name: {
        km: "បន្ទាយស្រី",
        latin: "Banteay Srei"
      },
      id: "17030101"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "17030102"
    },
    {
      name: {
        km: "ប្រីយ៍",
        latin: "Prei"
      },
      id: "17030103"
    },
    {
      name: {
        km: "សណ្ដាយ",
        latin: "Sanday"
      },
      id: "17030104"
    },
    {
      name: {
        km: "កកោះជ្រុំ",
        latin: "Karkoh Chrum"
      },
      id: "17030105"
    },
    {
      name: {
        km: "ទួលក្រឡាញ់",
        latin: "Tuol Kralanh"
      },
      id: "17030106"
    },
    {
      name: {
        km: "ខ្នារង្វាស",
        latin: "Khnar Rongveas"
      },
      id: "17030201"
    },
    {
      name: {
        km: "កំព្រហ្ម",
        latin: "Kom Prum"
      },
      id: "17030202"
    },
    {
      name: {
        km: "ឃុនរាម",
        latin: "Khun Ream"
      },
      id: "17030203"
    },
    {
      name: {
        km: "ឈូកស",
        latin: "Chhouk Sar"
      },
      id: "17030204"
    },
    {
      name: {
        km: "ទួលគ្រួស",
        latin: "Toul Krus"
      },
      id: "17030205"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thmar"
      },
      id: "17030206"
    },
    {
      name: {
        km: "ពើងឆ័ត្រ",
        latin: "Poeung Chhat"
      },
      id: "17030207"
    },
    {
      name: {
        km: "ព្រះដាក់",
        latin: "Preah Dak"
      },
      id: "17030301"
    },
    {
      name: {
        km: "ថ្នល់បណ្ដោយ",
        latin: "Thnal Bandaoy"
      },
      id: "17030302"
    },
    {
      name: {
        km: "តាត្រៃ",
        latin: "Ta Trai"
      },
      id: "17030303"
    },
    {
      name: {
        km: "ថ្នល់ទទឹង",
        latin: "Thnal Totueng"
      },
      id: "17030304"
    },
    {
      name: {
        km: "តាកុះ",
        latin: "Ta Koh"
      },
      id: "17030305"
    },
    {
      name: {
        km: "អូរទទឹង",
        latin: "Ou Totueng"
      },
      id: "17030306"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "17030401"
    },
    {
      name: {
        km: "សាលាក្រវ៉ាន់",
        latin: "Sala Kravan"
      },
      id: "17030402"
    },
    {
      name: {
        km: "រវៀងតាទុំ",
        latin: "Rorveang Tatum"
      },
      id: "17030403"
    },
    {
      name: {
        km: "តាឯក",
        latin: "Ta Aek"
      },
      id: "17030501"
    },
    {
      name: {
        km: "ត្មាតពង",
        latin: "Tmat Pong"
      },
      id: "17030502"
    },
    {
      name: {
        km: "តានី",
        latin: "Ta Ni"
      },
      id: "17030503"
    },
    {
      name: {
        km: "រុន",
        latin: "Run"
      },
      id: "17030504"
    },
    {
      name: {
        km: "ជ័យ",
        latin: "Chey"
      },
      id: "17030505"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "17030506"
    },
    {
      name: {
        km: "ស្រែចង្ហូត",
        latin: "Srae Changhout"
      },
      id: "17030507"
    },
    {
      name: {
        km: "ត្បែងកើត",
        latin: "Tbaeng Kaeut"
      },
      id: "17030601"
    },
    {
      name: {
        km: "ត្បែងលិច",
        latin: "Tbaeng Lech"
      },
      id: "17030602"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "17030603"
    },
    {
      name: {
        km: "ស្រះខ្វាវ",
        latin: "Srah Khvav"
      },
      id: "17030604"
    },
    {
      name: {
        km: "គូលែនថ្មី",
        latin: "Kulen Thmey"
      },
      id: "17030605"
    },
    {
      name: {
        km: "ស្គន់",
        latin: "Skon"
      },
      id: "17030606"
    },
    {
      name: {
        km: "ថ្មជល់",
        latin: "Thmar Chul"
      },
      id: "17030607"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "17040101"
    },
    {
      name: {
        km: "អន្លង់ព្រីងក្រោម",
        latin: "Anlong Pring Kraom"
      },
      id: "17040102"
    },
    {
      name: {
        km: "អន្លង់ព្រីងលើ",
        latin: "Anlong Pring Leu"
      },
      id: "17040103"
    },
    {
      name: {
        km: "តាគឹមក្រោម",
        latin: "Ta Kuem Kraom"
      },
      id: "17040104"
    },
    {
      name: {
        km: "តាគឹមលើ",
        latin: "Ta Kuem Leu"
      },
      id: "17040105"
    },
    {
      name: {
        km: "ត្រពាំងត្រស់",
        latin: "Trapeang Tras"
      },
      id: "17040106"
    },
    {
      name: {
        km: "អន្លង់សំណរ",
        latin: "Anlong Samnar"
      },
      id: "17040107"
    },
    {
      name: {
        km: "អន្លង់ឫស្សី",
        latin: "Anlong Ruessei"
      },
      id: "17040108"
    },
    {
      name: {
        km: "ស្វាយតាដោក",
        latin: "Svay Ta Daok"
      },
      id: "17040109"
    },
    {
      name: {
        km: "សំរោងធំ",
        latin: "Samraong Thum"
      },
      id: "17040110"
    },
    {
      name: {
        km: "ចែកខ្សាច់លើ",
        latin: "Chaek Khsach Leu"
      },
      id: "17040111"
    },
    {
      name: {
        km: "ចែកខ្សាច់ក្រោម",
        latin: "Chaek Khsach Kraom"
      },
      id: "17040112"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "17040113"
    },
    {
      name: {
        km: "ព្រែកអង្គរថ្មី",
        latin: "Preaek Angkor Thmei"
      },
      id: "17040114"
    },
    {
      name: {
        km: "មាត់ខ្លា",
        latin: "Moat Kla"
      },
      id: "17040115"
    },
    {
      name: {
        km: "ស្ទឹងជ្រៅ",
        latin: "Steung Chrov"
      },
      id: "17040116"
    },
    {
      name: {
        km: "កំពង់ស្នោលិច",
        latin: "Kampong Snao Lech"
      },
      id: "17040201"
    },
    {
      name: {
        km: "សណ្ដាន់",
        latin: "Sandan"
      },
      id: "17040202"
    },
    {
      name: {
        km: "ជីក្រែង",
        latin: "Chi Kraeng"
      },
      id: "17040203"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "17040204"
    },
    {
      name: {
        km: "ស្រមរ",
        latin: "Sramar"
      },
      id: "17040205"
    },
    {
      name: {
        km: "កំពង់",
        latin: "Kampong"
      },
      id: "17040206"
    },
    {
      name: {
        km: "អន្លង់ត្នោត",
        latin: "Anlong Tnaot"
      },
      id: "17040207"
    },
    {
      name: {
        km: "អន្លង់ចំបក់",
        latin: "Anlong Chambak"
      },
      id: "17040208"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "17040209"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "17040210"
    },
    {
      name: {
        km: "តារាម",
        latin: "Ta Ream"
      },
      id: "17040211"
    },
    {
      name: {
        km: "ភ្នៀត",
        latin: "Phniet"
      },
      id: "17040212"
    },
    {
      name: {
        km: "បុសពក",
        latin: "Bos Pok"
      },
      id: "17040213"
    },
    {
      name: {
        km: "កំពង់ស្នោកើត",
        latin: "Kampong Snao Kaeut"
      },
      id: "17040214"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "17040301"
    },
    {
      name: {
        km: "សាលា",
        latin: "Sala"
      },
      id: "17040302"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "17040303"
    },
    {
      name: {
        km: "ចំបក់ធំ",
        latin: "Chambak Thum"
      },
      id: "17040304"
    },
    {
      name: {
        km: "ច្រាំងខ្ពស់",
        latin: "Chrang Khpos"
      },
      id: "17040305"
    },
    {
      name: {
        km: "អន្សងពង",
        latin: "Ansang Pong"
      },
      id: "17040306"
    },
    {
      name: {
        km: "ត្រាចថ្មី",
        latin: "Trach Thmei"
      },
      id: "17040307"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "17040308"
    },
    {
      name: {
        km: "រំលោង",
        latin: "Rumloung"
      },
      id: "17040309"
    },
    {
      name: {
        km: "កំពង់ក្ដី១",
        latin: "Kampong Kdei Muoy"
      },
      id: "17040310"
    },
    {
      name: {
        km: "កំពង់ក្ដី២",
        latin: "Kampong Kdei Pir"
      },
      id: "17040311"
    },
    {
      name: {
        km: "ពោធិ៍សេរី",
        latin: "Pou Serei"
      },
      id: "17040312"
    },
    {
      name: {
        km: "ពន្លឺព្រះផុស",
        latin: "Ponlueu Preah Phos"
      },
      id: "17040313"
    },
    {
      name: {
        km: "សង្កែមានជ័យ",
        latin: "Sangkae Mean Chey"
      },
      id: "17040314"
    },
    {
      name: {
        km: "តាពៀម",
        latin: "Ta Piem"
      },
      id: "17040315"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "17040316"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "17040401"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "17040402"
    },
    {
      name: {
        km: "ព្រះធាតុ",
        latin: "Preah Theat"
      },
      id: "17040403"
    },
    {
      name: {
        km: "ស្រោង",
        latin: "Sraong"
      },
      id: "17040404"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "17040405"
    },
    {
      name: {
        km: "ពោធិ៍រីង",
        latin: "Pou Ring"
      },
      id: "17040406"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "17040407"
    },
    {
      name: {
        km: "កំបោអរ",
        latin: "Kambor Or"
      },
      id: "17040408"
    },
    {
      name: {
        km: "ចុងស្ពាន",
        latin: "Chong Spean"
      },
      id: "17040409"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "17040410"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "17040501"
    },
    {
      name: {
        km: "ក្របីរៀល",
        latin: "Krabei Riel"
      },
      id: "17040502"
    },
    {
      name: {
        km: "ប៉ាតត",
        latin: "Patat"
      },
      id: "17040503"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "17040504"
    },
    {
      name: {
        km: "អន្លង់វិល",
        latin: "Anlong Vil"
      },
      id: "17040505"
    },
    {
      name: {
        km: "កំពង់ម្កាក់",
        latin: "Kampong Mkak"
      },
      id: "17040506"
    },
    {
      name: {
        km: "ត្រពាំងត្រាវ",
        latin: "Trapeang Trav"
      },
      id: "17040507"
    },
    {
      name: {
        km: "ដូនរាជ្យ",
        latin: "Doun Reach"
      },
      id: "17040508"
    },
    {
      name: {
        km: "តាទរ",
        latin: "Ta Tor"
      },
      id: "17040509"
    },
    {
      name: {
        km: "ថ្នល់តាសិត",
        latin: "Thnal Ta Set"
      },
      id: "17040510"
    },
    {
      name: {
        km: "ដូនសុខ",
        latin: "Doun Sokh"
      },
      id: "17040511"
    },
    {
      name: {
        km: "គីឡូតាឈឹម",
        latin: "Kilou Ta Chhuem"
      },
      id: "17040512"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "17040513"
    },
    {
      name: {
        km: "បាក់អង្រុត",
        latin: "Bak Angrut"
      },
      id: "17040514"
    },
    {
      name: {
        km: "បុស្សគរ",
        latin: "Bos Kor"
      },
      id: "17040515"
    },
    {
      name: {
        km: "គោករមាស",
        latin: "Kouk Romeas"
      },
      id: "17040516"
    },
    {
      name: {
        km: "បឹងធំ",
        latin: "Boeng Thum"
      },
      id: "17040517"
    },
    {
      name: {
        km: "ទ័ពសៀម",
        latin: "Toap Siem"
      },
      id: "17040518"
    },
    {
      name: {
        km: "ខ្លាឃ្មុំ",
        latin: "Khla khmum"
      },
      id: "17040601"
    },
    {
      name: {
        km: "សាលា",
        latin: "Sala"
      },
      id: "17040602"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "17040603"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "17040604"
    },
    {
      name: {
        km: "គោកធ្លក",
        latin: "Kouk Thlok"
      },
      id: "17040605"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "17040606"
    },
    {
      name: {
        km: "ថ្លុកស្មាច់",
        latin: "Thlok Smach"
      },
      id: "17040607"
    },
    {
      name: {
        km: "ស្លែងកោង",
        latin: "Slaeng Kaong"
      },
      id: "17040608"
    },
    {
      name: {
        km: "ស្វាយពក",
        latin: "Svay Pok"
      },
      id: "17040609"
    },
    {
      name: {
        km: "តាភ្ញា",
        latin: "Ta Phnhea"
      },
      id: "17040610"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "17040611"
    },
    {
      name: {
        km: "តាលៀន",
        latin: "Ta Lien"
      },
      id: "17040612"
    },
    {
      name: {
        km: "ម្កាក់",
        latin: "Mkak"
      },
      id: "17040613"
    },
    {
      name: {
        km: "តាអុង",
        latin: "Ta Ong"
      },
      id: "17040701"
    },
    {
      name: {
        km: "គោកអំពិល",
        latin: "Kouk Ampil"
      },
      id: "17040702"
    },
    {
      name: {
        km: "រំជៃច្រុះ",
        latin: "Rumchey Chroh"
      },
      id: "17040703"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "17040704"
    },
    {
      name: {
        km: "ក្នុង",
        latin: "Knong"
      },
      id: "17040705"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "17040706"
    },
    {
      name: {
        km: "ក្បាលក្ដួច",
        latin: "Kbal Kduoch"
      },
      id: "17040707"
    },
    {
      name: {
        km: "បាឡាំង",
        latin: "Ballangk"
      },
      id: "17040708"
    },
    {
      name: {
        km: "ចំបក់ខ្ពស់",
        latin: "Chambak Khpos"
      },
      id: "17040709"
    },
    {
      name: {
        km: "រូងថ្មី",
        latin: "Rung Thmei"
      },
      id: "17040710"
    },
    {
      name: {
        km: "ល្វែងឫស្សី",
        latin: "Lveaeng Ruessei"
      },
      id: "17040711"
    },
    {
      name: {
        km: "តាងួន",
        latin: "Ta Nguon"
      },
      id: "17040712"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "17040713"
    },
    {
      name: {
        km: "ពពេល",
        latin: "Popel"
      },
      id: "17040801"
    },
    {
      name: {
        km: "ពង្រ១",
        latin: "Pongro Muoy"
      },
      id: "17040802"
    },
    {
      name: {
        km: "ពង្រ២",
        latin: "Pongro Pir"
      },
      id: "17040803"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "17040804"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "17040805"
    },
    {
      name: {
        km: "អូររូង",
        latin: "Ou Rung"
      },
      id: "17040806"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "17040807"
    },
    {
      name: {
        km: "ជ័យបូរ",
        latin: "Chey Bour"
      },
      id: "17040808"
    },
    {
      name: {
        km: "ផ្លុង",
        latin: "Phlong"
      },
      id: "17040809"
    },
    {
      name: {
        km: "តាព័រ១",
        latin: "Ta Poar Muoy"
      },
      id: "17040810"
    },
    {
      name: {
        km: "តាព័រ២",
        latin: "Ta Poar Pir"
      },
      id: "17040811"
    },
    {
      name: {
        km: "អូរខ្លុង",
        latin: "Ou Khlong"
      },
      id: "17040812"
    },
    {
      name: {
        km: "ដងផ្អាវ",
        latin: "Dang Ph'av"
      },
      id: "17040813"
    },
    {
      name: {
        km: "ត្រពាំងភ្លោះ",
        latin: "Trapeang Phluoh"
      },
      id: "17040901"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "17040902"
    },
    {
      name: {
        km: "គ្រាំង",
        latin: "Kreang"
      },
      id: "17040903"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "17040904"
    },
    {
      name: {
        km: "គំរូ",
        latin: "Kumru"
      },
      id: "17040905"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "17040906"
    },
    {
      name: {
        km: "ដុបត្នោត",
        latin: "Dob Tnaot"
      },
      id: "17040907"
    },
    {
      name: {
        km: "ស្រឡៅស្រោង",
        latin: "Sralau Sraong"
      },
      id: "17040908"
    },
    {
      name: {
        km: "ព្រៃឆ្ការ",
        latin: "Prey Chhkar"
      },
      id: "17040909"
    },
    {
      name: {
        km: "ល្អក់",
        latin: "LaOk"
      },
      id: "17040910"
    },
    {
      name: {
        km: "សំរោងកញ្ចោច",
        latin: "Samraong Kanhchaoch"
      },
      id: "17041001"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "17041002"
    },
    {
      name: {
        km: "ស្ពានតូច",
        latin: "Spean Touch"
      },
      id: "17041003"
    },
    {
      name: {
        km: "យាង",
        latin: "Yeang"
      },
      id: "17041004"
    },
    {
      name: {
        km: "ឫស្សីលក",
        latin: "Ruessei Lok"
      },
      id: "17041005"
    },
    {
      name: {
        km: "ទទឹងថ្ងៃ",
        latin: "Totueng Thngai"
      },
      id: "17041006"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Kroch"
      },
      id: "17041007"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "17041008"
    },
    {
      name: {
        km: "ពាក់ស្ពា",
        latin: "Peak Spea"
      },
      id: "17041101"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "17041102"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "17041103"
    },
    {
      name: {
        km: "តាព្រុំ",
        latin: "Ta Prum"
      },
      id: "17041104"
    },
    {
      name: {
        km: "ថ្នល់ដាច់",
        latin: "Thnal Dach"
      },
      id: "17041105"
    },
    {
      name: {
        km: "ដំរីឆ្លង",
        latin: "Damrei Chhlang"
      },
      id: "17041106"
    },
    {
      name: {
        km: "កន្សែង",
        latin: "Kansaeng"
      },
      id: "17041107"
    },
    {
      name: {
        km: "ព្រៃប្រស់",
        latin: "Prey Prors"
      },
      id: "17041108"
    },
    {
      name: {
        km: "ក្របៅ",
        latin: "Kra Bao"
      },
      id: "17041109"
    },
    {
      name: {
        km: "ស្ពានត្នោត១",
        latin: "Spean Tnaot Muoy"
      },
      id: "17041201"
    },
    {
      name: {
        km: "ស្ពានត្នោត២",
        latin: "Spean Tnaot Pir"
      },
      id: "17041202"
    },
    {
      name: {
        km: "ចំរេះ",
        latin: "Chamreh"
      },
      id: "17041203"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "17041204"
    },
    {
      name: {
        km: "ថ្នល់លោក",
        latin: "Thnal Louk"
      },
      id: "17041205"
    },
    {
      name: {
        km: "ក្ងានពង",
        latin: "Kngan Pong"
      },
      id: "17041206"
    },
    {
      name: {
        km: "រំដេង",
        latin: "Rumdeng"
      },
      id: "17041207"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "17041208"
    },
    {
      name: {
        km: "តាយ៉ុន",
        latin: "Ta Yon"
      },
      id: "17041209"
    },
    {
      name: {
        km: "ទទា",
        latin: "Totea"
      },
      id: "17041210"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "17041211"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "17041212"
    },
    {
      name: {
        km: "អូរត្រាច",
        latin: "Ou Trach"
      },
      id: "17041213"
    },
    {
      name: {
        km: "អូរក្រោម",
        latin: "Ou Kraom"
      },
      id: "17041214"
    },
    {
      name: {
        km: "អូរលើ",
        latin: "Ou Leu"
      },
      id: "17041215"
    },
    {
      name: {
        km: "ត្រាំកង់",
        latin: "Tram Kang"
      },
      id: "17060101"
    },
    {
      name: {
        km: "ឈូករ័ត្ន",
        latin: "Chhuk Roat"
      },
      id: "17060102"
    },
    {
      name: {
        km: "រុន",
        latin: "Run"
      },
      id: "17060103"
    },
    {
      name: {
        km: "គោកត្នោត",
        latin: "Kouk Tnaot"
      },
      id: "17060104"
    },
    {
      name: {
        km: "ខ្នាជោ",
        latin: "Khnar Chour"
      },
      id: "17060105"
    },
    {
      name: {
        km: "ដំរីស្លាប់",
        latin: "Damrei Slab"
      },
      id: "17060106"
    },
    {
      name: {
        km: "តាមាឃ",
        latin: "Ta Meakh"
      },
      id: "17060107"
    },
    {
      name: {
        km: "ចន្លាស់ដៃ",
        latin: "Chanlas Dai"
      },
      id: "17060108"
    },
    {
      name: {
        km: "ព្រះលាន",
        latin: "Preah Lean"
      },
      id: "17060109"
    },
    {
      name: {
        km: "រលំស្វាយ",
        latin: "Rolum Svay"
      },
      id: "17060110"
    },
    {
      name: {
        km: "កំបោរ",
        latin: "Kambaor"
      },
      id: "17060111"
    },
    {
      name: {
        km: "ដូនកាយ",
        latin: "Doun Kay"
      },
      id: "17060112"
    },
    {
      name: {
        km: "កំពង់ថ្កូវ១",
        latin: "Kampong Thkov Muoy"
      },
      id: "17060201"
    },
    {
      name: {
        km: "កំពង់ថ្កូវ២",
        latin: "Kampong Thkov Pir"
      },
      id: "17060202"
    },
    {
      name: {
        km: "ខ្សាច់",
        latin: "Khsach"
      },
      id: "17060203"
    },
    {
      name: {
        km: "អូរថ្កូវ",
        latin: "Ou Thkov"
      },
      id: "17060204"
    },
    {
      name: {
        km: "គោកដូង",
        latin: "Kouk Doung"
      },
      id: "17060205"
    },
    {
      name: {
        km: "ដូរ្យដន្ដ្រី",
        latin: "Dour Dantrei"
      },
      id: "17060206"
    },
    {
      name: {
        km: "ចំបក់ហែរ",
        latin: "Chambak Haer"
      },
      id: "17060207"
    },
    {
      name: {
        km: "ភ្នំទ្រង់បាត",
        latin: "Phnum Trong Bat"
      },
      id: "17060208"
    },
    {
      name: {
        km: "តាច្រែង",
        latin: "Ta Chraeng"
      },
      id: "17060301"
    },
    {
      name: {
        km: "ត្រពាំងច្រាំង",
        latin: "Trapeang Chrang"
      },
      id: "17060302"
    },
    {
      name: {
        km: "អូរក្រឡាញ់",
        latin: "Ou Kralanh"
      },
      id: "17060303"
    },
    {
      name: {
        km: "កោះក្របៅ",
        latin: "Kaoh Krabau"
      },
      id: "17060304"
    },
    {
      name: {
        km: "ក្រឡាញ់",
        latin: "Kralanh"
      },
      id: "17060305"
    },
    {
      name: {
        km: "ពេជ្ជោរ",
        latin: "Pechchour"
      },
      id: "17060306"
    },
    {
      name: {
        km: "សំពៅលូន",
        latin: "Sampov Lun"
      },
      id: "17060307"
    },
    {
      name: {
        km: "គោកចំបក់",
        latin: "Kouk Chambak"
      },
      id: "17060401"
    },
    {
      name: {
        km: "ព្រៃខ្យង",
        latin: "Prey Khyang"
      },
      id: "17060402"
    },
    {
      name: {
        km: "រើល",
        latin: "Reul"
      },
      id: "17060403"
    },
    {
      name: {
        km: "គោកថ្មី",
        latin: "Kouk Thmei"
      },
      id: "17060404"
    },
    {
      name: {
        km: "ខ្នារជើង",
        latin: "Khnar Cheung"
      },
      id: "17060405"
    },
    {
      name: {
        km: "ខ្នារត្បូង",
        latin: "Khnar Tboung"
      },
      id: "17060406"
    },
    {
      name: {
        km: "រោងគោ",
        latin: "Roung Kou"
      },
      id: "17060501"
    },
    {
      name: {
        km: "ព្រៃគាប",
        latin: "Prey Keab"
      },
      id: "17060502"
    },
    {
      name: {
        km: "បុស្សធំ",
        latin: "Bos Thum"
      },
      id: "17060503"
    },
    {
      name: {
        km: "តានី",
        latin: "Ta Ni"
      },
      id: "17060504"
    },
    {
      name: {
        km: "កញ្ជន់ជ្រៅ",
        latin: "Kanhchon Chrov"
      },
      id: "17060505"
    },
    {
      name: {
        km: "ខ្ជាយ",
        latin: "Khcheay"
      },
      id: "17060506"
    },
    {
      name: {
        km: "ល្បើកប្រីយ៍",
        latin: "Lbaeuk Prei"
      },
      id: "17060507"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "17060508"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "17060509"
    },
    {
      name: {
        km: "គោកក្រូច",
        latin: "Kouk Krouch"
      },
      id: "17060601"
    },
    {
      name: {
        km: "អន្លង់សារ",
        latin: "Anlong Sar"
      },
      id: "17060602"
    },
    {
      name: {
        km: "ដំណាក់ខ្ចាស់",
        latin: "Damnak Khchas"
      },
      id: "17060603"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "17060604"
    },
    {
      name: {
        km: "ឪម៉ាល់",
        latin: "Ovmal"
      },
      id: "17060605"
    },
    {
      name: {
        km: "គោកចាស់",
        latin: "Kouk Chas"
      },
      id: "17060606"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "17060607"
    },
    {
      name: {
        km: "សន្ថន",
        latin: "Santhan"
      },
      id: "17060608"
    },
    {
      name: {
        km: "ច្រនៀង",
        latin: "Chranieng"
      },
      id: "17060701"
    },
    {
      name: {
        km: "ព្រៃក្រឡាញ់",
        latin: "Prey Kralanh"
      },
      id: "17060702"
    },
    {
      name: {
        km: "គោកកី",
        latin: "Kouk Kei"
      },
      id: "17060703"
    },
    {
      name: {
        km: "គោកភ្ងាស",
        latin: "Kouk Phngeas"
      },
      id: "17060704"
    },
    {
      name: {
        km: "គោកយាង",
        latin: "Kouk Yeang"
      },
      id: "17060705"
    },
    {
      name: {
        km: "ស្មាច់",
        latin: "Smach"
      },
      id: "17060706"
    },
    {
      name: {
        km: "អង្កោល",
        latin: "Angkaol"
      },
      id: "17060707"
    },
    {
      name: {
        km: "ដំរីស្លាប់",
        latin: "Damrei Slab"
      },
      id: "17060708"
    },
    {
      name: {
        km: "តាសុខ",
        latin: "Ta Sokh"
      },
      id: "17060709"
    },
    {
      name: {
        km: "តាស្រី",
        latin: "Ta Srei"
      },
      id: "17060710"
    },
    {
      name: {
        km: "ខ្សី",
        latin: "Khsei"
      },
      id: "17060711"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "17060712"
    },
    {
      name: {
        km: "ទ្រាស",
        latin: "Treas"
      },
      id: "17060713"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "17060714"
    },
    {
      name: {
        km: "ព្រៃថ្កូវ",
        latin: "Prey Thkov"
      },
      id: "17060715"
    },
    {
      name: {
        km: "តាប៉ាង",
        latin: "Ta Pang"
      },
      id: "17060716"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "17060801"
    },
    {
      name: {
        km: "តាឡឹង",
        latin: "Ta Loeng"
      },
      id: "17060802"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "17060803"
    },
    {
      name: {
        km: "តាពេជ",
        latin: "Ta Pech"
      },
      id: "17060804"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "17060805"
    },
    {
      name: {
        km: "ព្រៃល្ងៀង",
        latin: "Prey Lngieng"
      },
      id: "17060806"
    },
    {
      name: {
        km: "តាសែង",
        latin: "Ta Saeng"
      },
      id: "17060807"
    },
    {
      name: {
        km: "ធាស្នា",
        latin: "Thea Sna"
      },
      id: "17060808"
    },
    {
      name: {
        km: "តាយិន",
        latin: "Ta Yin"
      },
      id: "17060809"
    },
    {
      name: {
        km: "ល្ហុង",
        latin: "Lhong"
      },
      id: "17060901"
    },
    {
      name: {
        km: "ស្រណាល",
        latin: "Sranal"
      },
      id: "17060902"
    },
    {
      name: {
        km: "គោកត្រុំ",
        latin: "Kouk Trom"
      },
      id: "17060903"
    },
    {
      name: {
        km: "រំដេង",
        latin: "Rumdeng"
      },
      id: "17060904"
    },
    {
      name: {
        km: "គំរូ",
        latin: "Kumru"
      },
      id: "17060905"
    },
    {
      name: {
        km: "តាំងយូ",
        latin: "Tang Yu"
      },
      id: "17060906"
    },
    {
      name: {
        km: "ទន្លាប់",
        latin: "Tonloab"
      },
      id: "17060907"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "17060908"
    },
    {
      name: {
        km: "គោកចាស់",
        latin: "Kouk Chas"
      },
      id: "17060909"
    },
    {
      name: {
        km: "ផ្លាំង",
        latin: "Phlang"
      },
      id: "17060910"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "17060911"
    },
    {
      name: {
        km: "គោកត្នោត",
        latin: "Kouk Tnaot"
      },
      id: "17060912"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "17060913"
    },
    {
      name: {
        km: "ក្ដុល",
        latin: "Kdol"
      },
      id: "17061001"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "17061002"
    },
    {
      name: {
        km: "តាអាន",
        latin: "Ta An"
      },
      id: "17061003"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "17061004"
    },
    {
      name: {
        km: "តាឡឹង",
        latin: "Ta Loeng"
      },
      id: "17061005"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "17061006"
    },
    {
      name: {
        km: "ទឹកជុំ",
        latin: "Tuek Chum"
      },
      id: "17061007"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "17061008"
    },
    {
      name: {
        km: "អន្លង់",
        latin: "Anlong"
      },
      id: "17061009"
    },
    {
      name: {
        km: "សសៃពង",
        latin: "Sarsai Pong"
      },
      id: "17061010"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "17070101"
    },
    {
      name: {
        km: "សសរស្ដម្ភ",
        latin: "Sasar Sdam"
      },
      id: "17070102"
    },
    {
      name: {
        km: "គោកកណ្ដាល",
        latin: "Kouk Kandal"
      },
      id: "17070103"
    },
    {
      name: {
        km: "គោករុន",
        latin: "Kouk Run"
      },
      id: "17070104"
    },
    {
      name: {
        km: "គោកចាស់",
        latin: "Kouk Chas"
      },
      id: "17070105"
    },
    {
      name: {
        km: "ពង្រថ្មី",
        latin: "Pongro Thmei"
      },
      id: "17070106"
    },
    {
      name: {
        km: "គោកព្នៅ",
        latin: "Kouk Pnov"
      },
      id: "17070107"
    },
    {
      name: {
        km: "គោកជ្រៃ",
        latin: "Kouk Chrey"
      },
      id: "17070108"
    },
    {
      name: {
        km: "ខ្ជាយ",
        latin: "Khcheay"
      },
      id: "17070109"
    },
    {
      name: {
        km: "គោកត្នាត",
        latin: "Kouk Tnat"
      },
      id: "17070110"
    },
    {
      name: {
        km: "ដំណាក់ស្លាញ",
        latin: "Damnak Slanh"
      },
      id: "17070111"
    },
    {
      name: {
        km: "ប៉ាក់ប៉ាន់",
        latin: "Pak Pan"
      },
      id: "17070112"
    },
    {
      name: {
        km: "ចាន់តាសាយ",
        latin: "Chan Ta Say"
      },
      id: "17070113"
    },
    {
      name: {
        km: "ពង្រចាស់",
        latin: "Pongro Chas"
      },
      id: "17070114"
    },
    {
      name: {
        km: "គោកព្នៅ",
        latin: "Kouk Pnov"
      },
      id: "17070201"
    },
    {
      name: {
        km: "អន្ដង្គន់",
        latin: "Antangkon"
      },
      id: "17070202"
    },
    {
      name: {
        km: "តាកាំ",
        latin: "Ta Kam"
      },
      id: "17070203"
    },
    {
      name: {
        km: "ដូនកែវ",
        latin: "Doun Kaev"
      },
      id: "17070204"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "17070205"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ",
        latin: "Tnaot Chrum"
      },
      id: "17070206"
    },
    {
      name: {
        km: "ប្រាសាទចារ្យ",
        latin: "Prasat Char"
      },
      id: "17070207"
    },
    {
      name: {
        km: "គោកពោធិ៍",
        latin: "Kouk Pou"
      },
      id: "17070208"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "17070209"
    },
    {
      name: {
        km: "តាស្នេហ៍",
        latin: "Ta Snae"
      },
      id: "17070210"
    },
    {
      name: {
        km: "គោកថ្មី",
        latin: "Kouk Thmei"
      },
      id: "17070211"
    },
    {
      name: {
        km: "រហាល",
        latin: "Rohal"
      },
      id: "17070212"
    },
    {
      name: {
        km: "ដូនអុន",
        latin: "Doun On"
      },
      id: "17070213"
    },
    {
      name: {
        km: "បង្កោង",
        latin: "Bangkaong"
      },
      id: "17070301"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "17070302"
    },
    {
      name: {
        km: "ព្រៃយាង",
        latin: "Prey Yeang"
      },
      id: "17070303"
    },
    {
      name: {
        km: "តាប៉ាង",
        latin: "Ta Pang"
      },
      id: "17070304"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "17070305"
    },
    {
      name: {
        km: "គោកពោធិ",
        latin: "Kouk Pou"
      },
      id: "17070306"
    },
    {
      name: {
        km: "ក្ដីរុន",
        latin: "Kdei Run"
      },
      id: "17070307"
    },
    {
      name: {
        km: "កំភេម",
        latin: "Kamphem"
      },
      id: "17070401"
    },
    {
      name: {
        km: "ព្រៃដង្ហើម",
        latin: "Prey Danghaeum"
      },
      id: "17070402"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "17070403"
    },
    {
      name: {
        km: "រកាយា",
        latin: "Roka Yea"
      },
      id: "17070404"
    },
    {
      name: {
        km: "គោកឫស្សី",
        latin: "Kouk Ruessei"
      },
      id: "17070405"
    },
    {
      name: {
        km: "គោកពោធិ",
        latin: "Kouk Pou"
      },
      id: "17070406"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "17070407"
    },
    {
      name: {
        km: "ពាមតាអួរ",
        latin: "Peam Ta Uor"
      },
      id: "17070408"
    },
    {
      name: {
        km: "ខ្នាត",
        latin: "Khnat"
      },
      id: "17070501"
    },
    {
      name: {
        km: "ប្រឡាយ",
        latin: "Pralay"
      },
      id: "17070502"
    },
    {
      name: {
        km: "គោកស្នួល",
        latin: "Kouk Snuol"
      },
      id: "17070503"
    },
    {
      name: {
        km: "ត្រមេង",
        latin: "Trameng"
      },
      id: "17070504"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "17070505"
    },
    {
      name: {
        km: "ព្រៃធ្លក",
        latin: "Prey Thlok"
      },
      id: "17070506"
    },
    {
      name: {
        km: "ជ្រលង",
        latin: "Chrolong"
      },
      id: "17070507"
    },
    {
      name: {
        km: "គោកត្រាច",
        latin: "Kouk Trach"
      },
      id: "17070508"
    },
    {
      name: {
        km: "អំពិលពាម",
        latin: "Ampil Peam"
      },
      id: "17070509"
    },
    {
      name: {
        km: "បឹងខ្នារ",
        latin: "Boeng Khnar"
      },
      id: "17070510"
    },
    {
      name: {
        km: "ព្រៃក្មេង",
        latin: "Prey Kmeng"
      },
      id: "17070511"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "17070512"
    },
    {
      name: {
        km: "គំរូ",
        latin: "Kumru"
      },
      id: "17070701"
    },
    {
      name: {
        km: "ដូនទ្រ",
        latin: "Doun Tro"
      },
      id: "17070702"
    },
    {
      name: {
        km: "ស្ទឹងព្រះស្រុក",
        latin: "Stueng Preah Srok"
      },
      id: "17070703"
    },
    {
      name: {
        km: "គោកស្រម៉",
        latin: "Kouk Srama"
      },
      id: "17070704"
    },
    {
      name: {
        km: "ទួលរវៀង",
        latin: "Tuol Rovieng"
      },
      id: "17070705"
    },
    {
      name: {
        km: "គោកថ្មី",
        latin: "Kouk Thmei"
      },
      id: "17070706"
    },
    {
      name: {
        km: "ប្រហូត",
        latin: "Prohut"
      },
      id: "17070707"
    },
    {
      name: {
        km: "ជ្រាស់",
        latin: "Chreas"
      },
      id: "17070708"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "17070709"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "17070710"
    },
    {
      name: {
        km: "ព្រះអង្គទ្រង់",
        latin: "Preah Angk Trong"
      },
      id: "17070711"
    },
    {
      name: {
        km: "ស្នោ",
        latin: "Snao"
      },
      id: "17070712"
    },
    {
      name: {
        km: "តាត្រាវ",
        latin: "Ta Trav"
      },
      id: "17070801"
    },
    {
      name: {
        km: "មុខប៉ែន",
        latin: "Mukh Paen"
      },
      id: "17070802"
    },
    {
      name: {
        km: "ត្រកៀត",
        latin: "Trakiet"
      },
      id: "17070803"
    },
    {
      name: {
        km: "គោករាំង",
        latin: "Kouk Reang"
      },
      id: "17070804"
    },
    {
      name: {
        km: "គោករុន",
        latin: "Kouk Run"
      },
      id: "17070805"
    },
    {
      name: {
        km: "ស្វាហួល",
        latin: "Sva Huol"
      },
      id: "17070806"
    },
    {
      name: {
        km: "ពោធិ",
        latin: "Pou"
      },
      id: "17070901"
    },
    {
      name: {
        km: "ទ្រាយ",
        latin: "Treay"
      },
      id: "17070902"
    },
    {
      name: {
        km: "ពួកចាស់",
        latin: "Puok Chas"
      },
      id: "17071001"
    },
    {
      name: {
        km: "ប្រយុទ្ធ",
        latin: "Prayut"
      },
      id: "17071002"
    },
    {
      name: {
        km: "គោកជួន",
        latin: "Kouk Chuon"
      },
      id: "17071003"
    },
    {
      name: {
        km: "កំពង់តាយ៉ង",
        latin: "Kampong Ta Yang"
      },
      id: "17071004"
    },
    {
      name: {
        km: "គោកស្រុក",
        latin: "Kouk Srok"
      },
      id: "17071005"
    },
    {
      name: {
        km: "តាទក",
        latin: "Ta Tok"
      },
      id: "17071006"
    },
    {
      name: {
        km: "គោកដូង",
        latin: "Kouk Doung"
      },
      id: "17071007"
    },
    {
      name: {
        km: "គោកថ្មី",
        latin: "Kouk Thmei"
      },
      id: "17071008"
    },
    {
      name: {
        km: "ពួកថ្មី",
        latin: "Puok Thmei"
      },
      id: "17071009"
    },
    {
      name: {
        km: "ចំបក់ហែរ",
        latin: "Chambak Haer"
      },
      id: "17071010"
    },
    {
      name: {
        km: "អូរតាប្រាក់",
        latin: "Ou Ta Prak"
      },
      id: "17071011"
    },
    {
      name: {
        km: "ព្រៃជ្រូក",
        latin: "Prey Chruk"
      },
      id: "17071101"
    },
    {
      name: {
        km: "កិត្ដិយស",
        latin: "Ketteyos"
      },
      id: "17071102"
    },
    {
      name: {
        km: "ដូនតុក",
        latin: "Doun Tok"
      },
      id: "17071103"
    },
    {
      name: {
        km: "ស្វាយចន្ទរ",
        latin: "Svay Chantor"
      },
      id: "17071104"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "17071105"
    },
    {
      name: {
        km: "ផ្លាំង",
        latin: "Phlang"
      },
      id: "17071106"
    },
    {
      name: {
        km: "ប្របម៉ៃ",
        latin: "Prab Mai"
      },
      id: "17071107"
    },
    {
      name: {
        km: "ជ្រេស",
        latin: "Chres"
      },
      id: "17071108"
    },
    {
      name: {
        km: "ប្រដាក់",
        latin: "Pradak"
      },
      id: "17071109"
    },
    {
      name: {
        km: "ច្រនៀង",
        latin: "Chranieng"
      },
      id: "17071110"
    },
    {
      name: {
        km: "តាមោក",
        latin: "Ta Mouk"
      },
      id: "17071111"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "17071112"
    },
    {
      name: {
        km: "គោកវាល",
        latin: "Kouk Veal"
      },
      id: "17071201"
    },
    {
      name: {
        km: "ទំរឹង",
        latin: "Tumrueng"
      },
      id: "17071202"
    },
    {
      name: {
        km: "រើល",
        latin: "Reul"
      },
      id: "17071203"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "17071204"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "17071205"
    },
    {
      name: {
        km: "ព្រលឹត",
        latin: "Prolit"
      },
      id: "17071206"
    },
    {
      name: {
        km: "ក្អែកទំ",
        latin: "K'aek Tum"
      },
      id: "17071207"
    },
    {
      name: {
        km: "រំដួល",
        latin: "Rumduol"
      },
      id: "17071208"
    },
    {
      name: {
        km: "ក្បាលក្រពើ",
        latin: "Kbal Krapeu"
      },
      id: "17071209"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "17071210"
    },
    {
      name: {
        km: "គោកខ្នាំង",
        latin: "Kouk Khnang"
      },
      id: "17071211"
    },
    {
      name: {
        km: "គោកត្រាច",
        latin: "Kouk Trach"
      },
      id: "17071212"
    },
    {
      name: {
        km: "ស្រះ",
        latin: "Srah"
      },
      id: "17071213"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "17071214"
    },
    {
      name: {
        km: "តាជេត",
        latin: "Ta Chet"
      },
      id: "17071301"
    },
    {
      name: {
        km: "សំរោងយា",
        latin: "Samraong Yea"
      },
      id: "17071302"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "17071303"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "17071304"
    },
    {
      name: {
        km: "ដូនស្វា",
        latin: "Doun Sva"
      },
      id: "17071305"
    },
    {
      name: {
        km: "ព្រៃវែង",
        latin: "Prey Veaeng"
      },
      id: "17071306"
    },
    {
      name: {
        km: "ចំបក់ស",
        latin: "Chambak Sa"
      },
      id: "17071501"
    },
    {
      name: {
        km: "គោកដូង",
        latin: "Kouk Doung"
      },
      id: "17071502"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "17071503"
    },
    {
      name: {
        km: "តាហុក",
        latin: "Ta Hok"
      },
      id: "17071504"
    },
    {
      name: {
        km: "ធិបតី",
        latin: "Thipakdei"
      },
      id: "17071505"
    },
    {
      name: {
        km: "ជួយចក្រី",
        latin: "Chuoy Chakkrei"
      },
      id: "17071506"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "17071507"
    },
    {
      name: {
        km: "ត្រីញ័រ",
        latin: "Trei Nhoar"
      },
      id: "17071508"
    },
    {
      name: {
        km: "ធ្វាស",
        latin: "Thveas"
      },
      id: "17071509"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "17071510"
    },
    {
      name: {
        km: "សួនសា",
        latin: "Suon Sa"
      },
      id: "17071601"
    },
    {
      name: {
        km: "ចុងថ្នល់",
        latin: "Chong Thnal"
      },
      id: "17071602"
    },
    {
      name: {
        km: "យាង",
        latin: "Yeang"
      },
      id: "17071603"
    },
    {
      name: {
        km: "សុខសាន្ដ",
        latin: "Sokh San"
      },
      id: "17071604"
    },
    {
      name: {
        km: "កញ្ចន់គុយ",
        latin: "Kanhchan Kuy"
      },
      id: "17071605"
    },
    {
      name: {
        km: "ថ្នល់ត្រង់",
        latin: "Thnal Trang"
      },
      id: "17090201"
    },
    {
      name: {
        km: "ឱឡោក",
        latin: "Aolaok"
      },
      id: "17090202"
    },
    {
      name: {
        km: "លលៃ",
        latin: "Loley"
      },
      id: "17090203"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "17090204"
    },
    {
      name: {
        km: "គោកត្រាច",
        latin: "Kouk Trach"
      },
      id: "17090205"
    },
    {
      name: {
        km: "តាភោគ",
        latin: "Ta Phouk"
      },
      id: "17090206"
    },
    {
      name: {
        km: "ធ្លកកំបុត",
        latin: "Thlok Kambot"
      },
      id: "17090301"
    },
    {
      name: {
        km: "គោកឫស្សី",
        latin: "Kouk Ruessei"
      },
      id: "17090302"
    },
    {
      name: {
        km: "ស្នារសង្គ្រាម",
        latin: "Snar Sangkream"
      },
      id: "17090303"
    },
    {
      name: {
        km: "ក្រពើ",
        latin: "Krapeu"
      },
      id: "17090304"
    },
    {
      name: {
        km: "តាកុយ",
        latin: "Ta Koy"
      },
      id: "17090305"
    },
    {
      name: {
        km: "ព្រំកុដ្ឋ",
        latin: "Prum Kod"
      },
      id: "17090306"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "17090307"
    },
    {
      name: {
        km: "ពពេល",
        latin: "Popel"
      },
      id: "17090308"
    },
    {
      name: {
        km: "គោកក្ដុល",
        latin: "Kouk Kdol"
      },
      id: "17090401"
    },
    {
      name: {
        km: "ត្នោតកំបុត",
        latin: "Tnaot Kambot"
      },
      id: "17090402"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "17090403"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "17090501"
    },
    {
      name: {
        km: "កន្ទ្រាំង",
        latin: "Kantreang"
      },
      id: "17090502"
    },
    {
      name: {
        km: "ស្រិតខាងលិច",
        latin: "Sret Khang Lech"
      },
      id: "17090503"
    },
    {
      name: {
        km: "ស្រិតខាងកើត",
        latin: "Sret Khang Kaeut"
      },
      id: "17090504"
    },
    {
      name: {
        km: "សូភី",
        latin: "Souphi"
      },
      id: "17090505"
    },
    {
      name: {
        km: "ត្រពាំងថ្នល់",
        latin: "Trapeang Thnal"
      },
      id: "17090506"
    },
    {
      name: {
        km: "តាត្រាវ",
        latin: "Ta Trav"
      },
      id: "17090507"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "17090508"
    },
    {
      name: {
        km: "គោកធ្លក",
        latin: "Kouk Thlok"
      },
      id: "17090601"
    },
    {
      name: {
        km: "ត្រពាំងទឹម",
        latin: "Trapeang Tuem"
      },
      id: "17090602"
    },
    {
      name: {
        km: "ឃុនមោឃ",
        latin: "Khun Moukh"
      },
      id: "17090603"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "17090604"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "17090605"
    },
    {
      name: {
        km: "ស្ពានក្អែក",
        latin: "Spean K'aek"
      },
      id: "17090606"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Trang"
      },
      id: "17090607"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "17090608"
    },
    {
      name: {
        km: "គោកត្នោត",
        latin: "Kouk Tnaot"
      },
      id: "17090609"
    },
    {
      name: {
        km: "ល្អក់",
        latin: "L'ak"
      },
      id: "17090610"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "17090701"
    },
    {
      name: {
        km: "តាប្រាក់",
        latin: "Ta Prak"
      },
      id: "17090702"
    },
    {
      name: {
        km: "ដូននំ",
        latin: "Doun Num"
      },
      id: "17090703"
    },
    {
      name: {
        km: "ជាស្មន់",
        latin: "Chea Sman"
      },
      id: "17090704"
    },
    {
      name: {
        km: "បន្ទាយឫស្សី",
        latin: "Banteay Ruessei"
      },
      id: "17090705"
    },
    {
      name: {
        km: "កំពង់ថ្កូវ",
        latin: "Kampong Thkov"
      },
      id: "17090706"
    },
    {
      name: {
        km: "មមាញ",
        latin: "Momeanh"
      },
      id: "17090801"
    },
    {
      name: {
        km: "គោកស្រុក",
        latin: "Kouk Srok"
      },
      id: "17090802"
    },
    {
      name: {
        km: "កញ្ជរ",
        latin: "Kanhchor"
      },
      id: "17090803"
    },
    {
      name: {
        km: "រលួសខាងកើត",
        latin: "Roluos Khang Kaeut"
      },
      id: "17090804"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "17090805"
    },
    {
      name: {
        km: "ដូនទាវ",
        latin: "Doun Teav"
      },
      id: "17090806"
    },
    {
      name: {
        km: "រលួសខាងលិច",
        latin: "Roluos Khang Lech"
      },
      id: "17090807"
    },
    {
      name: {
        km: "កូនសត្វ",
        latin: "Koun Satv"
      },
      id: "17090901"
    },
    {
      name: {
        km: "បឹងជុំ",
        latin: "Boeng Chum"
      },
      id: "17090902"
    },
    {
      name: {
        km: "តាអី",
        latin: "Ta Ei"
      },
      id: "17090903"
    },
    {
      name: {
        km: "រកាកំបុត",
        latin: "Roka Kambot"
      },
      id: "17090904"
    },
    {
      name: {
        km: "សួង",
        latin: "Suong"
      },
      id: "17090905"
    },
    {
      name: {
        km: "ភ្នំដី",
        latin: "Phnum Dei"
      },
      id: "17090906"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "17090907"
    },
    {
      name: {
        km: "ស្វាយជ័យ",
        latin: "Svay Chey"
      },
      id: "17090908"
    },
    {
      name: {
        km: "អន្លង់ពីរ",
        latin: "Anlong Pir"
      },
      id: "17090909"
    },
    {
      name: {
        km: "គោកចាន់",
        latin: "Kouk Chan"
      },
      id: "17091001"
    },
    {
      name: {
        km: "ថ្នល់បាក់",
        latin: "Thnal Bak"
      },
      id: "17091002"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "17091003"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "17091004"
    },
    {
      name: {
        km: "តាប៉ាង",
        latin: "Ta Pang"
      },
      id: "17091005"
    },
    {
      name: {
        km: "ព្រៃគុយ",
        latin: "Prey Kuy"
      },
      id: "17091006"
    },
    {
      name: {
        km: "បង្កោង",
        latin: "Bangkaong"
      },
      id: "17091007"
    },
    {
      name: {
        km: "គីរីមានន្ទ",
        latin: "Kiri Meanon"
      },
      id: "17091008"
    },
    {
      name: {
        km: "បុស្សធំ",
        latin: "Bos Thum"
      },
      id: "17091009"
    },
    {
      name: {
        km: "ត្រាចជ្រុំ",
        latin: "Trach Chrum"
      },
      id: "17091010"
    },
    {
      name: {
        km: "ស្លក្រាម",
        latin: "Sla Kram"
      },
      id: "17100101"
    },
    {
      name: {
        km: "បឹងដូនប៉ា",
        latin: "Boeng Donpa"
      },
      id: "17100102"
    },
    {
      name: {
        km: "ចុងកៅស៊ូ",
        latin: "Chongkaosou"
      },
      id: "17100103"
    },
    {
      name: {
        km: "ដកពោធិ៍",
        latin: "Dak Pou"
      },
      id: "17100104"
    },
    {
      name: {
        km: "បន្ទាយចាស់",
        latin: "Banteay Chas"
      },
      id: "17100105"
    },
    {
      name: {
        km: "ទ្រាំង",
        latin: "Treang"
      },
      id: "17100106"
    },
    {
      name: {
        km: "មណ្ឌល៣",
        latin: "Mondol Bei"
      },
      id: "17100107"
    },
    {
      name: {
        km: "ធ្លកអណ្តូង",
        latin: "Thlok Angdoung"
      },
      id: "17100108"
    },
    {
      name: {
        km: "ភ្ញាជ័យ",
        latin: "Phnhea Chey"
      },
      id: "17100201"
    },
    {
      name: {
        km: "កន្ដ្រក",
        latin: "Kantrork"
      },
      id: "17100202"
    },
    {
      name: {
        km: "គោកក្រសាំង",
        latin: "Kok Krorsang"
      },
      id: "17100203"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Preae"
      },
      id: "17100204"
    },
    {
      name: {
        km: "ពោធិ៍បុស្ស",
        latin: "Po Bos"
      },
      id: "17100205"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "17100206"
    },
    {
      name: {
        km: "ស្វាយដង្គំ",
        latin: "Svay Dangkum"
      },
      id: "17100207"
    },
    {
      name: {
        km: "សាលាកន្សែង",
        latin: "Sala Kanseng"
      },
      id: "17100208"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "17100209"
    },
    {
      name: {
        km: "វិហារចិន",
        latin: "Vihear Chen"
      },
      id: "17100210"
    },
    {
      name: {
        km: "ស្ទឹងថ្មី",
        latin: "Stueng Thmei"
      },
      id: "17100211"
    },
    {
      name: {
        km: "មណ្ឌល១",
        latin: "Mondol 1"
      },
      id: "17100212"
    },
    {
      name: {
        km: "មណ្ឌល២",
        latin: "Mondol 2"
      },
      id: "17100213"
    },
    {
      name: {
        km: "តាភុល",
        latin: "Ta Phul"
      },
      id: "17100214"
    },
    {
      name: {
        km: "ត្រពាំងសេះ",
        latin: "Trapeang Ses"
      },
      id: "17100301"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "17100302"
    },
    {
      name: {
        km: "ទក្សិណខាងត្បូង",
        latin: "Teaksen Khang Tbong"
      },
      id: "17100303"
    },
    {
      name: {
        km: "គោកតាចាន់",
        latin: "Kok Ta Chan"
      },
      id: "17100304"
    },
    {
      name: {
        km: "ខ្វៀន",
        latin: "Khvien"
      },
      id: "17100305"
    },
    {
      name: {
        km: "គោកបេង",
        latin: "Kok Beng"
      },
      id: "17100306"
    },
    {
      name: {
        km: "គោកត្នោត",
        latin: "Kok Tnot"
      },
      id: "17100307"
    },
    {
      name: {
        km: "នគរក្រៅ",
        latin: "Nokor Krau"
      },
      id: "17100308"
    },
    {
      name: {
        km: "វត្ដបូព៌",
        latin: "Wat Bo"
      },
      id: "17100401"
    },
    {
      name: {
        km: "វត្ដស្វាយ",
        latin: "Wat Svay"
      },
      id: "17100402"
    },
    {
      name: {
        km: "វត្ដដំណាក់",
        latin: "Wat Damnak"
      },
      id: "17100403"
    },
    {
      name: {
        km: "សាលាកំរើក",
        latin: "Sala Kamreuk"
      },
      id: "17100404"
    },
    {
      name: {
        km: "ជន្លង់",
        latin: "Chunlong"
      },
      id: "17100405"
    },
    {
      name: {
        km: "តាវៀន",
        latin: "Ta Vien"
      },
      id: "17100406"
    },
    {
      name: {
        km: "ត្រពាំងត្រែង",
        latin: "Trapeang Treng"
      },
      id: "17100407"
    },
    {
      name: {
        km: "រហាល",
        latin: "Rohal"
      },
      id: "17100501"
    },
    {
      name: {
        km: "ស្រះស្រង់ខាងជើង",
        latin: "Sras Srang Khang Cheung"
      },
      id: "17100502"
    },
    {
      name: {
        km: "ស្រះស្រង់ខាងត្បូង",
        latin: "Sras Srang Khang Tbaung"
      },
      id: "17100503"
    },
    {
      name: {
        km: "ក្រវ៉ាន់",
        latin: "Kravan"
      },
      id: "17100504"
    },
    {
      name: {
        km: "អារក្សស្វាយ",
        latin: "Areaks Svay"
      },
      id: "17100505"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "17100506"
    },
    {
      name: {
        km: "ជ្រាវ",
        latin: "Chreav"
      },
      id: "17100601"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "17100602"
    },
    {
      name: {
        km: "បុស្សក្រឡាញ់",
        latin: "Bos Kralanh"
      },
      id: "17100603"
    },
    {
      name: {
        km: "តាចេក",
        latin: "Ta Chek"
      },
      id: "17100604"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "17100605"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "17100606"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "17100607"
    },
    {
      name: {
        km: "ភូមិទី១",
        latin: "Phum Ti Muoy"
      },
      id: "17100701"
    },
    {
      name: {
        km: "ភូមិទី២",
        latin: "Phum Ti Pir"
      },
      id: "17100702"
    },
    {
      name: {
        km: "ភូមិទី៣",
        latin: "Phum Ti Bei"
      },
      id: "17100703"
    },
    {
      name: {
        km: "ភូមិទី៤",
        latin: "Phum Ti Buon"
      },
      id: "17100704"
    },
    {
      name: {
        km: "ភូមិទី៥",
        latin: "Phum Ti Pram"
      },
      id: "17100705"
    },
    {
      name: {
        km: "ភូមិទី៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "17100706"
    },
    {
      name: {
        km: "ភូមិទី៧",
        latin: "Phum Ti Prampir"
      },
      id: "17100707"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Phnov"
      },
      id: "17100801"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "17100802"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "17100803"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "17100804"
    },
    {
      name: {
        km: "តាគង់",
        latin: "Ta Kong"
      },
      id: "17100805"
    },
    {
      name: {
        km: "ពោធិបន្ទាយជ័យ",
        latin: "Pou Banteay Chey"
      },
      id: "17100901"
    },
    {
      name: {
        km: "ភ្នំក្រោម",
        latin: "Phnom Krom"
      },
      id: "17100902"
    },
    {
      name: {
        km: "ប្រឡាយ",
        latin: "Pralay"
      },
      id: "17100903"
    },
    {
      name: {
        km: "កក្រាញ់",
        latin: "Kakranh"
      },
      id: "17100904"
    },
    {
      name: {
        km: "ក្រសាំងរលើង",
        latin: "Krasang Roleung"
      },
      id: "17100905"
    },
    {
      name: {
        km: "ស្ពានជ្រាវ",
        latin: "Spean Chreav"
      },
      id: "17100906"
    },
    {
      name: {
        km: "អារញ្ញ",
        latin: "Aranh"
      },
      id: "17100907"
    },
    {
      name: {
        km: "ត្រៀក",
        latin: "Triek"
      },
      id: "17100908"
    },
    {
      name: {
        km: "កសិកម្ម",
        latin: "Kaksekam"
      },
      id: "17101001"
    },
    {
      name: {
        km: "ថ្នល់់",
        latin: "Thnal"
      },
      id: "17101002"
    },
    {
      name: {
        km: "រការធំ",
        latin: "Roka Thum"
      },
      id: "17101003"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "17101004"
    },
    {
      name: {
        km: "ស្រង៉ែ",
        latin: "Srongae"
      },
      id: "17101005"
    },
    {
      name: {
        km: "ចន្លោង",
        latin: "Chanlaong"
      },
      id: "17101006"
    },
    {
      name: {
        km: "តាចក",
        latin: "Ta Chork"
      },
      id: "17101007"
    },
    {
      name: {
        km: "តារស់",
        latin: "Ta Ros"
      },
      id: "17101201"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "17101202"
    },
    {
      name: {
        km: "ព្រៃពោធិ៍",
        latin: "Prey Pou"
      },
      id: "17101203"
    },
    {
      name: {
        km: "ទទា",
        latin: "Totea"
      },
      id: "17101204"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "17101205"
    },
    {
      name: {
        km: "ពពិស",
        latin: "Popis"
      },
      id: "17101206"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veng"
      },
      id: "17101207"
    },
    {
      name: {
        km: "គោកដូង",
        latin: "Kouk Doung"
      },
      id: "17101208"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "17101209"
    },
    {
      name: {
        km: "ប្រម៉ា",
        latin: "Prama"
      },
      id: "17101210"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "17101211"
    },
    {
      name: {
        km: "ព្រៃក្រូច",
        latin: "Prey Krouch"
      },
      id: "17101212"
    },
    {
      name: {
        km: "គោកដូង",
        latin: "Kok Dong"
      },
      id: "17101301"
    },
    {
      name: {
        km: "សណ្ដាន់",
        latin: "Sandan"
      },
      id: "17101302"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "17101303"
    },
    {
      name: {
        km: "ប្រយុទ្ធ",
        latin: "Prayut"
      },
      id: "17101304"
    },
    {
      name: {
        km: "បន្ទាយឈើ",
        latin: "Banteay Chheu"
      },
      id: "17101305"
    },
    {
      name: {
        km: "ទឹកវិល",
        latin: "Tuek Vil"
      },
      id: "17101306"
    },
    {
      name: {
        km: "ប្រីយ៍ចាស់",
        latin: "Prei Chas"
      },
      id: "17101307"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "17101308"
    },
    {
      name: {
        km: "ប្រីយ៍ថ្មី",
        latin: "Prei Thmei"
      },
      id: "17101309"
    },
    {
      name: {
        km: "ជ័យ",
        latin: "Chey"
      },
      id: "17101310"
    },
    {
      name: {
        km: "ជាំ",
        latin: "Choam"
      },
      id: "17110101"
    },
    {
      name: {
        km: "គោកតឺង",
        latin: "Kouk Toeng"
      },
      id: "17110102"
    },
    {
      name: {
        km: "ស្រែប្រីយ៍",
        latin: "Srae Prei"
      },
      id: "17110103"
    },
    {
      name: {
        km: "ត្រពាំងទូក",
        latin: "Trapeang Tuk"
      },
      id: "17110104"
    },
    {
      name: {
        km: "ជប់",
        latin: "Chub"
      },
      id: "17110105"
    },
    {
      name: {
        km: "តាតោកខាងកើត",
        latin: "Ta Taok Khang Kaeut"
      },
      id: "17110106"
    },
    {
      name: {
        km: "កន្សែងក្រោម",
        latin: "Kansaeng Kraom"
      },
      id: "17110107"
    },
    {
      name: {
        km: "កន្សែងលើ",
        latin: "Kansaeng Leu"
      },
      id: "17110108"
    },
    {
      name: {
        km: "តាតោកខាងលិច",
        latin: "Ta Taok Khang Lech"
      },
      id: "17110109"
    },
    {
      name: {
        km: "តាតោកកណ្ដាល",
        latin: "Ta Taok Kandal"
      },
      id: "17110110"
    },
    {
      name: {
        km: "សន្លោង",
        latin: "Sanlaong"
      },
      id: "17110111"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "17110112"
    },
    {
      name: {
        km: "ដូនដៀវ",
        latin: "Doun Diev"
      },
      id: "17110113"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "17110114"
    },
    {
      name: {
        km: "បែកកាំភ្លើង",
        latin: "Baek Kamphleung"
      },
      id: "17110115"
    },
    {
      name: {
        km: "គោកចិន",
        latin: "Kouk Chen"
      },
      id: "17110116"
    },
    {
      name: {
        km: "ចាន់សខាងជើង",
        latin: "Chan Sa Khang  Cheung"
      },
      id: "17110117"
    },
    {
      name: {
        km: "ចាន់សខាងត្បូង",
        latin: "Chan Sa Khang Tboung"
      },
      id: "17110118"
    },
    {
      name: {
        km: "ច្បារលើ",
        latin: "Chbar Leu"
      },
      id: "17110119"
    },
    {
      name: {
        km: "ដូនហុង",
        latin: "Doun Hong"
      },
      id: "17110201"
    },
    {
      name: {
        km: "ដំដែកលើ",
        latin: "Dam Daek Leu"
      },
      id: "17110202"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "17110203"
    },
    {
      name: {
        km: "ព្រះត្រពាំង",
        latin: "Preah Trapeang"
      },
      id: "17110204"
    },
    {
      name: {
        km: "អូររលុះ",
        latin: "Ou Roluh"
      },
      id: "17110205"
    },
    {
      name: {
        km: "គោករលួស",
        latin: "Kouk Roluos"
      },
      id: "17110206"
    },
    {
      name: {
        km: "ក្រសាទុំ",
        latin: "Krasar Tum"
      },
      id: "17110207"
    },
    {
      name: {
        km: "គោកមន",
        latin: "Kouk Mon"
      },
      id: "17110208"
    },
    {
      name: {
        km: "ដំដែកថ្មី",
        latin: "Dam Daek Thmei"
      },
      id: "17110209"
    },
    {
      name: {
        km: "បន្ទាយស្រី",
        latin: "Banteay Srei"
      },
      id: "17110210"
    },
    {
      name: {
        km: "ស្រែធ្នង់",
        latin: "Srae Thnong"
      },
      id: "17110211"
    },
    {
      name: {
        km: "ដំដែកផ្សារ",
        latin: "Dam Daek Phsar"
      },
      id: "17110212"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Bos"
      },
      id: "17110213"
    },
    {
      name: {
        km: "ថ្នល់ដាច់ខាងលិច",
        latin: "Thnal Dach Khang Lech"
      },
      id: "17110301"
    },
    {
      name: {
        km: "ត្រាវកៀត",
        latin: "Trav Kiet"
      },
      id: "17110302"
    },
    {
      name: {
        km: "គោកឫស្សីខាងត្បូង",
        latin: "Kouk Ruessei Khang Tboung"
      },
      id: "17110303"
    },
    {
      name: {
        km: "គោកឫស្សីខាងជើង",
        latin: "Kouk Ruessei Khang Cheung"
      },
      id: "17110304"
    },
    {
      name: {
        km: "រុនខាងត្បូង",
        latin: "Run Khang Tboung"
      },
      id: "17110305"
    },
    {
      name: {
        km: "រុនខាងជើង",
        latin: "Run Khang Cheung"
      },
      id: "17110306"
    },
    {
      name: {
        km: "ស្រម៉ធំ",
        latin: "Srama Thum"
      },
      id: "17110307"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "17110308"
    },
    {
      name: {
        km: "បន្ទាត់បោះ",
        latin: "Bantoat Baoh"
      },
      id: "17110309"
    },
    {
      name: {
        km: "សន្ទៃ",
        latin: "Santey"
      },
      id: "17110310"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "17110311"
    },
    {
      name: {
        km: "គោកចាន់",
        latin: "Kouk Chan"
      },
      id: "17110312"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "17110313"
    },
    {
      name: {
        km: "ព្រែកស្រមោច",
        latin: "Preaek Sramaoch"
      },
      id: "17110401"
    },
    {
      name: {
        km: "ស្ពានវែង",
        latin: "Spean Veaeng"
      },
      id: "17110402"
    },
    {
      name: {
        km: "តាអួរស",
        latin: "Ta Uor Sa"
      },
      id: "17110403"
    },
    {
      name: {
        km: "ផ្សារឃ្លាំង",
        latin: "Phsar Khleang"
      },
      id: "17110404"
    },
    {
      name: {
        km: "ចំការយួន",
        latin: "Chamkar Yuon"
      },
      id: "17110405"
    },
    {
      name: {
        km: "តាច្រនៀង",
        latin: "Ta Chranieng"
      },
      id: "17110406"
    },
    {
      name: {
        km: "អូរតាពុក",
        latin: "Ou Ta Puk"
      },
      id: "17110407"
    },
    {
      name: {
        km: "ជ័យជេត",
        latin: "Chey Chet"
      },
      id: "17110408"
    },
    {
      name: {
        km: "មុខវត្ដ",
        latin: "Mukh Voat"
      },
      id: "17110409"
    },
    {
      name: {
        km: "រទាំង",
        latin: "Roteang"
      },
      id: "17110410"
    },
    {
      name: {
        km: "កំពង់គ២",
        latin: "Kampong Ko Pir"
      },
      id: "17110501"
    },
    {
      name: {
        km: "កំពង់គ១",
        latin: "Kampong Ko Muoy"
      },
      id: "17110502"
    },
    {
      name: {
        km: "សាលាកកោះ",
        latin: "Sala Kakaoh"
      },
      id: "17110503"
    },
    {
      name: {
        km: "គោកដឺ",
        latin: "Kouk Deu"
      },
      id: "17110504"
    },
    {
      name: {
        km: "ជ្រៃខាងជើង",
        latin: "Chrey Khang Cheung"
      },
      id: "17110505"
    },
    {
      name: {
        km: "ជ្រៃខាងត្បូង",
        latin: "Chrey Khang Tboung"
      },
      id: "17110506"
    },
    {
      name: {
        km: "ដូនឡី",
        latin: "Doun Lei"
      },
      id: "17110507"
    },
    {
      name: {
        km: "ត្រពាំងព្រៃ",
        latin: "Trapeang Prey"
      },
      id: "17110508"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "17110509"
    },
    {
      name: {
        km: "ថ្នល់ដាច់ខាងកើត",
        latin: "Thnal Dach Khang Kaeut"
      },
      id: "17110510"
    },
    {
      name: {
        km: "ថ្នល់ចែក",
        latin: "Thnal Chaek"
      },
      id: "17110511"
    },
    {
      name: {
        km: "ជីគាក",
        latin: "Chikeak"
      },
      id: "17110512"
    },
    {
      name: {
        km: "ថ្លាត",
        latin: "Thlat"
      },
      id: "17110601"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "17110602"
    },
    {
      name: {
        km: "ក្បូន",
        latin: "Kboun"
      },
      id: "17110603"
    },
    {
      name: {
        km: "គោកសង្កែ",
        latin: "Kouk Sangkae"
      },
      id: "17110604"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "17110605"
    },
    {
      name: {
        km: "យាងទេស",
        latin: "Yeang Tes"
      },
      id: "17110606"
    },
    {
      name: {
        km: "ខ្ចាស់",
        latin: "Khchas"
      },
      id: "17110607"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "17110701"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Bos"
      },
      id: "17110702"
    },
    {
      name: {
        km: "បុស្សធំ",
        latin: "Bos Thum"
      },
      id: "17110703"
    },
    {
      name: {
        km: "ដំរីឆ្លង",
        latin: "Damrei Chhlang"
      },
      id: "17110704"
    },
    {
      name: {
        km: "សំបាត",
        latin: "Sambat"
      },
      id: "17110705"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "17110706"
    },
    {
      name: {
        km: "រំដេង",
        latin: "Rumdeng"
      },
      id: "17110707"
    },
    {
      name: {
        km: "ជប់",
        latin: "Chob"
      },
      id: "17110708"
    },
    {
      name: {
        km: "ព្រៃលាន",
        latin: "Prey Lean"
      },
      id: "17110801"
    },
    {
      name: {
        km: "ត្រពាំងត្រាវ",
        latin: "Trapeang Trav"
      },
      id: "17110802"
    },
    {
      name: {
        km: "គោល",
        latin: "Koul"
      },
      id: "17110803"
    },
    {
      name: {
        km: "ថ្នល់ត្រង់",
        latin: "Thnal Trang"
      },
      id: "17110804"
    },
    {
      name: {
        km: "ត្រពាំងត្រុំ",
        latin: "Trapeang Trom"
      },
      id: "17110805"
    },
    {
      name: {
        km: "ត្រាចពក",
        latin: "Trach Pok"
      },
      id: "17110806"
    },
    {
      name: {
        km: "ពពេលកណ្ដាល",
        latin: "Popel Kandal"
      },
      id: "17110807"
    },
    {
      name: {
        km: "ពពេលលិច",
        latin: "Popel Lech"
      },
      id: "17110808"
    },
    {
      name: {
        km: "ក្រៀលពង",
        latin: "Kriel Pong"
      },
      id: "17110809"
    },
    {
      name: {
        km: "ដំរីកូន",
        latin: "Damrei Koun"
      },
      id: "17110810"
    },
    {
      name: {
        km: "ត្រពាំងផុង",
        latin: "Trapeang Phong"
      },
      id: "17110811"
    },
    {
      name: {
        km: "ត្រពាំងប្រីយ៍",
        latin: "Trapeang Prei"
      },
      id: "17110812"
    },
    {
      name: {
        km: "គោលថ្មី",
        latin: "Koul Thmei"
      },
      id: "17110813"
    },
    {
      name: {
        km: "សំរោងជើង",
        latin: "Samraong Cheung"
      },
      id: "17110901"
    },
    {
      name: {
        km: "ថ្នល់ចែក",
        latin: "Thnal Chaek"
      },
      id: "17110902"
    },
    {
      name: {
        km: "បិតមាស",
        latin: "Bet Meas"
      },
      id: "17110903"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "17110904"
    },
    {
      name: {
        km: "អង្គុញ",
        latin: "Angkunh"
      },
      id: "17110905"
    },
    {
      name: {
        km: "បត់ដង្កោ",
        latin: "Bat Dangkao"
      },
      id: "17110906"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "17110907"
    },
    {
      name: {
        km: "ក្រាំងខ្ជាយ",
        latin: "Krang Khcheay"
      },
      id: "17110908"
    },
    {
      name: {
        km: "សំរោងត្បូង",
        latin: "Samraong Tboung"
      },
      id: "17110909"
    },
    {
      name: {
        km: "បឹងង៉ត",
        latin: "Boeng Ngat"
      },
      id: "17111001"
    },
    {
      name: {
        km: "ដាក់ផ្កា",
        latin: "Dak Phka"
      },
      id: "17111002"
    },
    {
      name: {
        km: "ត្រាវបាក់",
        latin: "Trav Bak"
      },
      id: "17111003"
    },
    {
      name: {
        km: "ផ្ការំចេក",
        latin: "Phka Rumchek"
      },
      id: "17111004"
    },
    {
      name: {
        km: "ចំប៉ី",
        latin: "Champei"
      },
      id: "17111005"
    },
    {
      name: {
        km: "ប៉ោយស្មាច់",
        latin: "Paoy Smach"
      },
      id: "17111006"
    },
    {
      name: {
        km: "ប្រវ៉ាល",
        latin: "Braval"
      },
      id: "17111007"
    },
    {
      name: {
        km: "បឹងវៀន",
        latin: "Boeng Vien"
      },
      id: "17111008"
    },
    {
      name: {
        km: "តាយ៉ែក",
        latin: "Ta Yaek"
      },
      id: "17111009"
    },
    {
      name: {
        km: "ត្រុំខាងជើង",
        latin: "Trom Khang Cheung"
      },
      id: "17120101"
    },
    {
      name: {
        km: "ឫស្សីសាញ់",
        latin: "Ruessei Sanh"
      },
      id: "17120102"
    },
    {
      name: {
        km: "ត្រុំខាងត្បូង",
        latin: "Trom Khang Tboung"
      },
      id: "17120103"
    },
    {
      name: {
        km: "យាយម៉ី",
        latin: "Yeay Mei"
      },
      id: "17120104"
    },
    {
      name: {
        km: "ស្ដៅពក",
        latin: "Sdau Pok"
      },
      id: "17120105"
    },
    {
      name: {
        km: "អំពៅដៀប",
        latin: "Ampov Dieb"
      },
      id: "17120106"
    },
    {
      name: {
        km: "ជ្រោយនាងងួន",
        latin: "Chrouy Neang Nguon"
      },
      id: "17120107"
    },
    {
      name: {
        km: "ស្លែងតាវេត",
        latin: "Slaeng Ta Vet"
      },
      id: "17120201"
    },
    {
      name: {
        km: "ពង្របត់ចាន់",
        latin: "Pongro Bat Chan"
      },
      id: "17120202"
    },
    {
      name: {
        km: "ស្លែងគង់",
        latin: "Slaeng Kong"
      },
      id: "17120203"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "17120204"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "17120205"
    },
    {
      name: {
        km: "ក្លាំងហាយ",
        latin: "Klang Hay"
      },
      id: "17120206"
    },
    {
      name: {
        km: "គោកថ្កូវ",
        latin: "Kouk Thkov"
      },
      id: "17120207"
    },
    {
      name: {
        km: "នាងស្រោង",
        latin: "Neang Sraong"
      },
      id: "17120301"
    },
    {
      name: {
        km: "រំដេង",
        latin: "Rumdeng"
      },
      id: "17120302"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "17120303"
    },
    {
      name: {
        km: "ស្មាច់",
        latin: "Smach"
      },
      id: "17120304"
    },
    {
      name: {
        km: "ព្រេច",
        latin: "Prech"
      },
      id: "17120305"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "17120306"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "17120307"
    },
    {
      name: {
        km: "មោងខាងត្បូង",
        latin: "Moung Khang Tboung"
      },
      id: "17120401"
    },
    {
      name: {
        km: "មោងខាងជើង",
        latin: "Moung Khang Cheung"
      },
      id: "17120402"
    },
    {
      name: {
        km: "ខ្វែក",
        latin: "Khvaek"
      },
      id: "17120403"
    },
    {
      name: {
        km: "កំបោរ",
        latin: "Kambaor"
      },
      id: "17120404"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "17120405"
    },
    {
      name: {
        km: "ប្រីយ៍១",
        latin: "Prei Muoy"
      },
      id: "17120501"
    },
    {
      name: {
        km: "ប្រីយ៍២",
        latin: "Prei Pir"
      },
      id: "17120502"
    },
    {
      name: {
        km: "ត្រាំសសរ",
        latin: "Tram Sasar"
      },
      id: "17120503"
    },
    {
      name: {
        km: "ក្របៅ",
        latin: "Krabau"
      },
      id: "17120504"
    },
    {
      name: {
        km: "ក្រូចចារ",
        latin: "Krouch Char"
      },
      id: "17120505"
    },
    {
      name: {
        km: "ស្លែងស្ពាន",
        latin: "Slaeng Spean"
      },
      id: "17120601"
    },
    {
      name: {
        km: "ច្រនៀង",
        latin: "Chranieng"
      },
      id: "17120602"
    },
    {
      name: {
        km: "ភ្នំដី",
        latin: "Phnum Dei"
      },
      id: "17120603"
    },
    {
      name: {
        km: "ដំណាក់ដំរី",
        latin: "Damnak Damrei"
      },
      id: "17120604"
    },
    {
      name: {
        km: "ចំការចេក",
        latin: "Chamkar Chek"
      },
      id: "17120605"
    },
    {
      name: {
        km: "ព្រះខ្សែត",
        latin: "Preah Khsaet"
      },
      id: "17120606"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "17120607"
    },
    {
      name: {
        km: "ត្រាំកង់",
        latin: "Tram Kang"
      },
      id: "17120608"
    },
    {
      name: {
        km: "បឹងមាលា",
        latin: "Boeng Mealea"
      },
      id: "17130101"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "17130102"
    },
    {
      name: {
        km: "ទឹកលិច",
        latin: "Tuek Lich"
      },
      id: "17130103"
    },
    {
      name: {
        km: "សក្ដា",
        latin: "Sak Kda"
      },
      id: "17130104"
    },
    {
      name: {
        km: "ចាន់ហៀរ",
        latin: "Chan Hear"
      },
      id: "17130105"
    },
    {
      name: {
        km: "ទ័ពជ័យ",
        latin: "Torb Chey"
      },
      id: "17130106"
    },
    {
      name: {
        km: "ស្រែរបង",
        latin: "Srae Robong"
      },
      id: "17130107"
    },
    {
      name: {
        km: "កន្ទ្ទួត",
        latin: "Kantuot"
      },
      id: "17130201"
    },
    {
      name: {
        km: "ខ្នារក្រៅ",
        latin: "Khnar Krau"
      },
      id: "17130202"
    },
    {
      name: {
        km: "អភិវឌ្ឍន៌",
        latin: "Akpiwat"
      },
      id: "17130203"
    },
    {
      name: {
        km: "រុងរឿង",
        latin: "Rong Roeung"
      },
      id: "17130204"
    },
    {
      name: {
        km: "សែនជ័យ",
        latin: "Senchey"
      },
      id: "17130205"
    },
    {
      name: {
        km: "ជប់រំដេង",
        latin: "Chub Rumdeng"
      },
      id: "17130206"
    },
    {
      name: {
        km: "តាពេញ",
        latin: "Ta Penh"
      },
      id: "17130301"
    },
    {
      name: {
        km: "ខ្លាឃ្មុំ",
        latin: "Khla Khmum"
      },
      id: "17130302"
    },
    {
      name: {
        km: "ថ្មជ្រួញ",
        latin: "Thma Chruonh"
      },
      id: "17130303"
    },
    {
      name: {
        km: "សង្កែឡាក់",
        latin: "Sangkae Lak"
      },
      id: "17130304"
    },
    {
      name: {
        km: "អន្លង់ធំ",
        latin: "Anlong Thum"
      },
      id: "17130305"
    },
    {
      name: {
        km: "ជប់លើ",
        latin: "Chob Leu"
      },
      id: "17130401"
    },
    {
      name: {
        km: "ជប់ក្រោម",
        latin: "Chob Kraom"
      },
      id: "17130402"
    },
    {
      name: {
        km: "ត្រពាំងខ្នារ",
        latin: "Trapeang Khnar"
      },
      id: "17130403"
    },
    {
      name: {
        km: "បិតផ្កា",
        latin: "Bet Phka"
      },
      id: "17130404"
    },
    {
      name: {
        km: "ឆេះចាន",
        latin: "Chheh Chan"
      },
      id: "17130405"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "17130406"
    },
    {
      name: {
        km: "អង្កាញ់",
        latin: "Angkanh"
      },
      id: "17130407"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "17130408"
    },
    {
      name: {
        km: "អូរមានជ័យ",
        latin: "Ou Meanchey"
      },
      id: "17130409"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmey"
      },
      id: "17130410"
    },
    {
      name: {
        km: "តាសៀម",
        latin: "Ta Siem"
      },
      id: "17130501"
    },
    {
      name: {
        km: "ដំបូកខ្ពស់",
        latin: "Dambouk Khpos"
      },
      id: "17130502"
    },
    {
      name: {
        km: "រហាល",
        latin: "Rohal"
      },
      id: "17130503"
    },
    {
      name: {
        km: "ត្រពាំងទឹម",
        latin: "Trapeang Tuem"
      },
      id: "17130504"
    },
    {
      name: {
        km: "ត្រពាំងពពេល",
        latin: "Trapeang Popel"
      },
      id: "17130505"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thmar"
      },
      id: "17130506"
    },
    {
      name: {
        km: "កញ្ចន់រុន",
        latin: "Kanhchan Run"
      },
      id: "17140101"
    },
    {
      name: {
        km: "ទំនាបស្វាយ",
        latin: "Tumneab Svay"
      },
      id: "17140102"
    },
    {
      name: {
        km: "កាប់ដៃ",
        latin: "Kab Dai"
      },
      id: "17140103"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "17140104"
    },
    {
      name: {
        km: "វៀន",
        latin: "Vien"
      },
      id: "17140105"
    },
    {
      name: {
        km: "គោកចាន់",
        latin: "Kouk Chan"
      },
      id: "17140201"
    },
    {
      name: {
        km: "ឧទ័យ",
        latin: "U Tey"
      },
      id: "17140202"
    },
    {
      name: {
        km: "គោកកណ្ដាល",
        latin: "Kouk Kandal"
      },
      id: "17140203"
    },
    {
      name: {
        km: "ស្រែណូយ",
        latin: "Srae Nouy"
      },
      id: "17140301"
    },
    {
      name: {
        km: "ស្រែពោធិ៍",
        latin: "Srae Pou"
      },
      id: "17140302"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "17140303"
    },
    {
      name: {
        km: "គោកវត្ដ",
        latin: "Kouk Voat"
      },
      id: "17140304"
    },
    {
      name: {
        km: "ស្រែសមុទ្រ",
        latin: "Srae Sakmot"
      },
      id: "17140305"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "17140306"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "17140401"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "17140402"
    },
    {
      name: {
        km: "ឫស្សីធំ",
        latin: "Ruessei Thum"
      },
      id: "17140403"
    },
    {
      name: {
        km: "ឫស្សីតូច",
        latin: "Ruessei Touch"
      },
      id: "17140404"
    },
    {
      name: {
        km: "កំបោរ",
        latin: "Kambaor"
      },
      id: "17140405"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "17140406"
    },
    {
      name: {
        km: "នេល",
        latin: "Nel"
      },
      id: "17140501"
    },
    {
      name: {
        km: "រំដួល",
        latin: "Rumduol"
      },
      id: "17140502"
    },
    {
      name: {
        km: "វ៉ារិន",
        latin: "Varin"
      },
      id: "17140503"
    },
    {
      name: {
        km: "គោកភ្នំ",
        latin: "Kouk Phnum"
      },
      id: "17140504"
    },
    {
      name: {
        km: "គោកស្រុក",
        latin: "Kouk Srok"
      },
      id: "17140505"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "18010101"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "18010102"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum Bei"
      },
      id: "18010103"
    },
    {
      name: {
        km: "ភូមិ៤",
        latin: "Phum Buon"
      },
      id: "18010104"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "18010201"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "18010202"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum Bei"
      },
      id: "18010203"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "18010301"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "18010302"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum Bei"
      },
      id: "18010303"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "18010401"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "18010402"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum Bei"
      },
      id: "18010403"
    },
    {
      name: {
        km: "ភូមិ៤",
        latin: "Phum Buon"
      },
      id: "18010404"
    },
    {
      name: {
        km: "ភូមិ៥",
        latin: "Phum Pram"
      },
      id: "18010405"
    },
    {
      name: {
        km: "ភូមិ៦",
        latin: "Phum Prammuoy"
      },
      id: "18010406"
    },
    {
      name: {
        km: "អណ្ដូងថ្ម",
        latin: "Andoung Thma"
      },
      id: "18020101"
    },
    {
      name: {
        km: "បន្ទាយប្រីយ៍",
        latin: "Banteay Prei"
      },
      id: "18020102"
    },
    {
      name: {
        km: "អូរត្រាវ",
        latin: "Ou Trav"
      },
      id: "18020103"
    },
    {
      name: {
        km: "ត្រពាំងស្អុយទី១",
        latin: "Trapeang S'oy Ti Muoy"
      },
      id: "18020104"
    },
    {
      name: {
        km: "ត្រពាំងស្អុយទី២",
        latin: "Trapeang S'oy Ti Pir"
      },
      id: "18020105"
    },
    {
      name: {
        km: "បឹងជុំ",
        latin: "Boeng Chum"
      },
      id: "18020201"
    },
    {
      name: {
        km: "បឹងតាព្រហ្ម",
        latin: "Boeng Ta Prum"
      },
      id: "18020202"
    },
    {
      name: {
        km: "បឹងតាស្រី",
        latin: "Boeng Ta Srei"
      },
      id: "18020203"
    },
    {
      name: {
        km: "ដូនលយ",
        latin: "Doun Loy"
      },
      id: "18020204"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "18020205"
    },
    {
      name: {
        km: "ទឹកចេញ",
        latin: "Tuek Chenh"
      },
      id: "18020206"
    },
    {
      name: {
        km: "ចំណោតរាម",
        latin: "Chamnaot Ream"
      },
      id: "18020301"
    },
    {
      name: {
        km: "គគីរ",
        latin: "Kokir"
      },
      id: "18020302"
    },
    {
      name: {
        km: "ពូធឿង",
        latin: "Pu Thoeang"
      },
      id: "18020303"
    },
    {
      name: {
        km: "ចំការកៅស៊ូ",
        latin: "Chamkar Kausu"
      },
      id: "18020401"
    },
    {
      name: {
        km: "ជើងគោ",
        latin: "Cheung Kou"
      },
      id: "18020402"
    },
    {
      name: {
        km: "ត្រពាំងគា",
        latin: "Trapeang Kea"
      },
      id: "18020404"
    },
    {
      name: {
        km: "ត្រពាំងមូល",
        latin: "Trapeang Mul"
      },
      id: "18020405"
    },
    {
      name: {
        km: "កោះខ្យង",
        latin: "Kaoh Khyang"
      },
      id: "18020501"
    },
    {
      name: {
        km: "អូរជ្រៅ",
        latin: "Ou Chrov"
      },
      id: "18020502"
    },
    {
      name: {
        km: "ស្រែចាមក្រោម",
        latin: "Srae Cham Kraom"
      },
      id: "18020503"
    },
    {
      name: {
        km: "ស្រែចាមលើ",
        latin: "Srae Cham Leu"
      },
      id: "18020504"
    },
    {
      name: {
        km: "ស្រែក្នុង",
        latin: "Srae Knong"
      },
      id: "18020505"
    },
    {
      name: {
        km: "បត់គគីរ",
        latin: "Bat Kokir"
      },
      id: "18020601"
    },
    {
      name: {
        km: "អូរចំណារ",
        latin: "Ou Chamnar"
      },
      id: "18020602"
    },
    {
      name: {
        km: "អូរតាប៉ាង",
        latin: "Ou Ta Pang"
      },
      id: "18020603"
    },
    {
      name: {
        km: "អូរតាសេក",
        latin: "Ou Ta Sek"
      },
      id: "18020604"
    },
    {
      name: {
        km: "អូរឧកញ៉ាហេង",
        latin: "Ou Oknha Heng"
      },
      id: "18020605"
    },
    {
      name: {
        km: "បត់សិរមាន់",
        latin: "Bat Ser Moan"
      },
      id: "18020701"
    },
    {
      name: {
        km: "ព្រៃនប់១",
        latin: "Prey Nob Muoy"
      },
      id: "18020702"
    },
    {
      name: {
        km: "ព្រៃនប់២",
        latin: "Prey Nob Pir"
      },
      id: "18020703"
    },
    {
      name: {
        km: "ព្រៃនប់៣",
        latin: "Prey Nob Bei"
      },
      id: "18020704"
    },
    {
      name: {
        km: "បែកក្រង់",
        latin: "Baek Krang"
      },
      id: "18020705"
    },
    {
      name: {
        km: "អុង",
        latin: "Ong"
      },
      id: "18020801"
    },
    {
      name: {
        km: "រាម",
        latin: "Ream"
      },
      id: "18020802"
    },
    {
      name: {
        km: "ស្មាច់ដែង",
        latin: "Smach Daeng"
      },
      id: "18020804"
    },
    {
      name: {
        km: "ថ្មធំ",
        latin: "Thma Thum"
      },
      id: "18020805"
    },
    {
      name: {
        km: "តាអោងធំ",
        latin: "Ta Aong Thum"
      },
      id: "18020901"
    },
    {
      name: {
        km: "បឹងរាំង",
        latin: "Boeng Reang"
      },
      id: "18020902"
    },
    {
      name: {
        km: "ព្រែកក្រាញ់",
        latin: "Preaek Kranh"
      },
      id: "18020903"
    },
    {
      name: {
        km: "ចុងអូរ",
        latin: "Chong Ou"
      },
      id: "18021001"
    },
    {
      name: {
        km: "សំរុងកណ្ដាល",
        latin: "Samrong Kandal"
      },
      id: "18021002"
    },
    {
      name: {
        km: "សំរុងក្រោម",
        latin: "Samrong Kraom"
      },
      id: "18021003"
    },
    {
      name: {
        km: "សំរុងលើ",
        latin: "Samrong Leu"
      },
      id: "18021004"
    },
    {
      name: {
        km: "អូត្រជាក់ចិត្ដ",
        latin: "Ou Tracheak Chet"
      },
      id: "18021005"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "18021101"
    },
    {
      name: {
        km: "ព្រែកផ្អាវ",
        latin: "Preaek Ph'av"
      },
      id: "18021102"
    },
    {
      name: {
        km: "កំពង់ស្មាច់តូច",
        latin: "Kampong Smach Touch"
      },
      id: "18021103"
    },
    {
      name: {
        km: "ជ្រលង",
        latin: "Chrolong"
      },
      id: "18021104"
    },
    {
      name: {
        km: "ព្រែកប្រស់",
        latin: "Preaek Pras"
      },
      id: "18021201"
    },
    {
      name: {
        km: "ព្រែកទាល់",
        latin: "Preaek Toal"
      },
      id: "18021202"
    },
    {
      name: {
        km: "ព្រែកសង្កែ",
        latin: "Preaek Sangkae"
      },
      id: "18021203"
    },
    {
      name: {
        km: "កំពង់ចិន",
        latin: "Kampong Chen"
      },
      id: "18021204"
    },
    {
      name: {
        km: "ទួលទទឹងទី១",
        latin: "Tuol Totueng Ti Muoy"
      },
      id: "18021301"
    },
    {
      name: {
        km: "ទួលទទឹងទី២",
        latin: "Tuol Totueng Ti Pir"
      },
      id: "18021302"
    },
    {
      name: {
        km: "ទួលទទឹងទី៣",
        latin: "Tuol Totueng Ti Bei"
      },
      id: "18021303"
    },
    {
      name: {
        km: "អំពូខ្មៅ",
        latin: "Ampu Khmau"
      },
      id: "18021304"
    },
    {
      name: {
        km: "បឹងវែង",
        latin: "Boeng Veaeng"
      },
      id: "18021401"
    },
    {
      name: {
        km: "វាលមាស",
        latin: "Veal Meas"
      },
      id: "18021402"
    },
    {
      name: {
        km: "វាលធំ",
        latin: "Veal Thum"
      },
      id: "18021403"
    },
    {
      name: {
        km: "តាពៅ",
        latin: "Ta Pov"
      },
      id: "18021501"
    },
    {
      name: {
        km: "តានៃ",
        latin: "Ta Ney"
      },
      id: "18021502"
    },
    {
      name: {
        km: "មនោរម្យ",
        latin: "Monorom"
      },
      id: "18021503"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "18021504"
    },
    {
      name: {
        km: "អន្លង់ក្រពើ",
        latin: "Anlong Krapeu"
      },
      id: "18021505"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "18030101"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "18030102"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "18030201"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "18030202"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum Bei"
      },
      id: "18030203"
    },
    {
      name: {
        km: "ភូមិ៤",
        latin: "Phum Buon"
      },
      id: "18030204"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "18030301"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "18030302"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum Bei"
      },
      id: "18030303"
    },
    {
      name: {
        km: "ភូមិ៤",
        latin: "Phum Buon"
      },
      id: "18030304"
    },
    {
      name: {
        km: "កែវផុស",
        latin: "Kaev Phos"
      },
      id: "18030401"
    },
    {
      name: {
        km: "ឫទ្ធី១",
        latin: "Rithy 1"
      },
      id: "18030402"
    },
    {
      name: {
        km: "ឫទ្ធី២",
        latin: "Rithy 2"
      },
      id: "18030403"
    },
    {
      name: {
        km: "ចំការហ្លូង",
        latin: "Chamkar Luong"
      },
      id: "18040101"
    },
    {
      name: {
        km: "បឹងត្រាច",
        latin: "Boeng Trach"
      },
      id: "18040102"
    },
    {
      name: {
        km: "សម្ដេចតា",
        latin: "Samdech Ta"
      },
      id: "18040103"
    },
    {
      name: {
        km: "ចាំស្រី",
        latin: "Cham Srei"
      },
      id: "18040201"
    },
    {
      name: {
        km: "ក្រាំងអាត់",
        latin: "Krang At"
      },
      id: "18040202"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "18040203"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "18040204"
    },
    {
      name: {
        km: "ព្រៃប្រសិទ្ធ",
        latin: "Prey Praseth"
      },
      id: "18040301"
    },
    {
      name: {
        km: "ស្ទឹងច្រាល",
        latin: "Stueng Chral"
      },
      id: "18040302"
    },
    {
      name: {
        km: "ស្ទឹងសំរោង",
        latin: "Stueng Samraong"
      },
      id: "18040303"
    },
    {
      name: {
        km: "គីរីវន្ដ",
        latin: "Kirivoan"
      },
      id: "18040401"
    },
    {
      name: {
        km: "អូរតាហយ",
        latin: "Ou Ta Hoay"
      },
      id: "18040402"
    },
    {
      name: {
        km: "ស្ទឹងឆាយជើង",
        latin: "Stueng Chhay Cheung"
      },
      id: "18040403"
    },
    {
      name: {
        km: "ស្ទឹងឆាយត្បូង",
        latin: "Stueng Chhay Tbong"
      },
      id: "18040404"
    },
    {
      name: {
        km: "ដើមថ្កូវ",
        latin: "Daem Thkov"
      },
      id: "18050101"
    },
    {
      name: {
        km: "ព្រែកស្វាយ",
        latin: "Preaek Svay"
      },
      id: "18050102"
    },
    {
      name: {
        km: "កោះតូច",
        latin: "Kaoh Touch"
      },
      id: "18050201"
    },
    {
      name: {
        km: "កោះរ៉ុងសន្លឹម",
        latin: "Kaoh Rung Sanloem"
      },
      id: "18050202"
    },
    {
      name: {
        km: "កំភុន",
        latin: "Kamphun"
      },
      id: "19010101"
    },
    {
      name: {
        km: "បានម៉ៃ",
        latin: "Ban Mai"
      },
      id: "19010102"
    },
    {
      name: {
        km: "កាតូត",
        latin: "Katout"
      },
      id: "19010103"
    },
    {
      name: {
        km: "សេសាន",
        latin: "Sesan"
      },
      id: "19010104"
    },
    {
      name: {
        km: "ក្បាលរមាស",
        latin: "Kbal Romeas"
      },
      id: "19010201"
    },
    {
      name: {
        km: "ស្រែស្រណុក",
        latin: "Srae Sranok"
      },
      id: "19010202"
    },
    {
      name: {
        km: "ក្របីជ្រុំ",
        latin: "Krabei Chrum"
      },
      id: "19010203"
    },
    {
      name: {
        km: "ច្រប់",
        latin: "Chrab"
      },
      id: "19010204"
    },
    {
      name: {
        km: "ភ្លុក",
        latin: "Phluk"
      },
      id: "19010301"
    },
    {
      name: {
        km: "បានប៊ុង",
        latin: "Ban Bung"
      },
      id: "19010302"
    },
    {
      name: {
        km: "បាដើម",
        latin: "Ba Daeum"
      },
      id: "19010401"
    },
    {
      name: {
        km: "សាមឃួយ",
        latin: "Samkhuoy"
      },
      id: "19010402"
    },
    {
      name: {
        km: "ស្រែតាប៉ាន",
        latin: "Srae Ta Pan"
      },
      id: "19010403"
    },
    {
      name: {
        km: "ហាងសាវ៉ាត",
        latin: "Hang Savat"
      },
      id: "19010404"
    },
    {
      name: {
        km: "ស្ដៅ១",
        latin: "Sdau Muoy"
      },
      id: "19010501"
    },
    {
      name: {
        km: "ស្ដៅ២",
        latin: "Sdau Pir"
      },
      id: "19010502"
    },
    {
      name: {
        km: "ស្រែគរ១",
        latin: "Srae Kor Muoy"
      },
      id: "19010601"
    },
    {
      name: {
        km: "ស្រែគរ២",
        latin: "Srae Kor Pir"
      },
      id: "19010602"
    },
    {
      name: {
        km: "ស្វាយរៀង",
        latin: "Svay Rieng"
      },
      id: "19010701"
    },
    {
      name: {
        km: "ខ្សាច់ថ្មី",
        latin: "Khsach Thmei"
      },
      id: "19010702"
    },
    {
      name: {
        km: "រំពាត់",
        latin: "Rumpoat"
      },
      id: "19010703"
    },
    {
      name: {
        km: "តាឡាត",
        latin: "Ta Lat"
      },
      id: "19010704"
    },
    {
      name: {
        km: "កោះព្រះ",
        latin: "Kaoh Preah"
      },
      id: "19020101"
    },
    {
      name: {
        km: "កោះសំពាយ",
        latin: "Kaoh Sampeay"
      },
      id: "19020201"
    },
    {
      name: {
        km: "ដំរីផុង",
        latin: "Damrei Phong"
      },
      id: "19020202"
    },
    {
      name: {
        km: "ស្មាកោះ",
        latin: "Sma Kaoh"
      },
      id: "19020301"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "19020302"
    },
    {
      name: {
        km: "ភ្ជុល",
        latin: "Phchul"
      },
      id: "19020303"
    },
    {
      name: {
        km: "កាំងដែក",
        latin: "Kang Daek"
      },
      id: "19020304"
    },
    {
      name: {
        km: "អូរម្រះ",
        latin: "Ou Mreah"
      },
      id: "19020401"
    },
    {
      name: {
        km: "ត្បូងខ្លា",
        latin: "Tboung Khla"
      },
      id: "19020402"
    },
    {
      name: {
        km: "កោះជ្រឹម",
        latin: "Kaoh Chruem"
      },
      id: "19020403"
    },
    {
      name: {
        km: "អូរច្រឡង់",
        latin: "Ou Chralang"
      },
      id: "19020404"
    },
    {
      name: {
        km: "អូរឫស្សីកណ្ដាល",
        latin: "Ou Ruessei Kandal"
      },
      id: "19020501"
    },
    {
      name: {
        km: "សៀមបូក",
        latin: "Siem Bouk"
      },
      id: "19020601"
    },
    {
      name: {
        km: "អូឡង់",
        latin: "Ou Lang"
      },
      id: "19020602"
    },
    {
      name: {
        km: "ទន្សោង",
        latin: "Tonsang"
      },
      id: "19020603"
    },
    {
      name: {
        km: "ស្រែក្រសាំង",
        latin: "Srae Krasang"
      },
      id: "19020701"
    },
    {
      name: {
        km: "កោះក្រូច",
        latin: "Kaoh Krouch"
      },
      id: "19020702"
    },
    {
      name: {
        km: "ខិះស្វាយ",
        latin: "Khes Svay"
      },
      id: "19030101"
    },
    {
      name: {
        km: "ខិះក្រោម",
        latin: "Khes Kraom"
      },
      id: "19030102"
    },
    {
      name: {
        km: "ពងក្រៀល",
        latin: "Pong Kriel"
      },
      id: "19030103"
    },
    {
      name: {
        km: "ខាំភោគ",
        latin: "Kham Phouk"
      },
      id: "19030104"
    },
    {
      name: {
        km: "សៀមប៉ាង",
        latin: "Siem Pang"
      },
      id: "19030201"
    },
    {
      name: {
        km: "កែងញ៉ៃ",
        latin: "Kaeng Nhai"
      },
      id: "19030202"
    },
    {
      name: {
        km: "ចន្ទុ",
        latin: "Chantu"
      },
      id: "19030203"
    },
    {
      name: {
        km: "សាម៉",
        latin: "Samma"
      },
      id: "19030204"
    },
    {
      name: {
        km: "បានមួង",
        latin: "Ban Muong"
      },
      id: "19030205"
    },
    {
      name: {
        km: "បានហ៊ួយ",
        latin: "Ban Huoy"
      },
      id: "19030206"
    },
    {
      name: {
        km: "ដនឡូង",
        latin: "Dan Loung"
      },
      id: "19030207"
    },
    {
      name: {
        km: "លុន",
        latin: "Lun"
      },
      id: "19030208"
    },
    {
      name: {
        km: "គីរីវង្សាលើ",
        latin: "Kirivongsa Leu"
      },
      id: "19030301"
    },
    {
      name: {
        km: "គីរីវង្សាក្រោម",
        latin: "Kirivongsa Kraom"
      },
      id: "19030302"
    },
    {
      name: {
        km: "អូចាយ",
        latin: "Ou Chay"
      },
      id: "19030303"
    },
    {
      name: {
        km: "ទាក់ទាម",
        latin: "Tak team"
      },
      id: "19030304"
    },
    {
      name: {
        km: "គីរីបាសលើ",
        latin: "Kiri Bas Leu"
      },
      id: "19030305"
    },
    {
      name: {
        km: "គីរីបាសក្រោម",
        latin: "Kiri Bas Kraom"
      },
      id: "19030306"
    },
    {
      name: {
        km: "កញ្ចញគោក",
        latin: "Kanhchanh Kouk"
      },
      id: "19030401"
    },
    {
      name: {
        km: "កញ្ចញទឹក",
        latin: "Kanhchanh Tuek"
      },
      id: "19030402"
    },
    {
      name: {
        km: "ស្រែឫស្សី",
        latin: "Srae Ruessei"
      },
      id: "19030403"
    },
    {
      name: {
        km: "ពាមខិះ",
        latin: "Peam Khes"
      },
      id: "19030404"
    },
    {
      name: {
        km: "កេតមឿង",
        latin: "Ket Moeang"
      },
      id: "19030405"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "19030406"
    },
    {
      name: {
        km: "ញ៉ាងស៊ុម",
        latin: "Nhang Sum"
      },
      id: "19030501"
    },
    {
      name: {
        km: "ផាបាំង",
        latin: "Pha Bang"
      },
      id: "19030502"
    },
    {
      name: {
        km: "ឡាកាយ",
        latin: "Lakay"
      },
      id: "19030503"
    },
    {
      name: {
        km: "ភូមិព្រែក",
        latin: "Pum Preaek"
      },
      id: "19040101"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "19040102"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "19040103"
    },
    {
      name: {
        km: "ស្ពានថ្ម",
        latin: "Spean Thma"
      },
      id: "19040104"
    },
    {
      name: {
        km: "រាជានុកូល",
        latin: "Reacheanukoul"
      },
      id: "19040105"
    },
    {
      name: {
        km: "ថ្មលាភ",
        latin: "Thma Leaph"
      },
      id: "19040201"
    },
    {
      name: {
        km: "លើ",
        latin: "Leu"
      },
      id: "19040202"
    },
    {
      name: {
        km: "ស្រែពោធិ",
        latin: "Srae Pou"
      },
      id: "19040203"
    },
    {
      name: {
        km: "បាចុង",
        latin: "Ba Chong"
      },
      id: "19040301"
    },
    {
      name: {
        km: "កាំងម៉េម៉ាយ",
        latin: "Kang Memay"
      },
      id: "19040302"
    },
    {
      name: {
        km: "កាំងដីស",
        latin: "Kang Dei Sa"
      },
      id: "19040303"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "19040401"
    },
    {
      name: {
        km: "ហាងខូសួន",
        latin: "Hang Khou Suon"
      },
      id: "19040402"
    },
    {
      name: {
        km: "ហាងខូបាន",
        latin: "Hang Khou Ban"
      },
      id: "19040403"
    },
    {
      name: {
        km: "កោះខនឌិន",
        latin: "Kaoh Khan Din"
      },
      id: "19040404"
    },
    {
      name: {
        km: "ខាំផាន់",
        latin: "Kham Phan"
      },
      id: "19040405"
    },
    {
      name: {
        km: "គីឡូប្រាំបី",
        latin: "Kilou  Prambei"
      },
      id: "19040406"
    },
    {
      name: {
        km: "ផ្អាវ",
        latin: "Ph'av"
      },
      id: "19050101"
    },
    {
      name: {
        km: "អន្លង់ភេ",
        latin: "Anlong Phe"
      },
      id: "19050102"
    },
    {
      name: {
        km: "ទាល់",
        latin: "Toal"
      },
      id: "19050103"
    },
    {
      name: {
        km: "វាលពោធិ",
        latin: "Veal Pou"
      },
      id: "19050104"
    },
    {
      name: {
        km: "ស្ពង់",
        latin: "Spong"
      },
      id: "19050105"
    },
    {
      name: {
        km: "រំដេង",
        latin: "Rumdeng"
      },
      id: "19050201"
    },
    {
      name: {
        km: "ចំការលើ",
        latin: "Chamkar Leu"
      },
      id: "19050202"
    },
    {
      name: {
        km: "រុន",
        latin: "Run"
      },
      id: "19050203"
    },
    {
      name: {
        km: "កេះ",
        latin: "Kaes"
      },
      id: "19050301"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "19050302"
    },
    {
      name: {
        km: "កាំងចាម",
        latin: "Kang Cham"
      },
      id: "19050303"
    },
    {
      name: {
        km: "កាំងក្ងោក",
        latin: "Kang Kngaok"
      },
      id: "19050304"
    },
    {
      name: {
        km: "កំពង់ប៉ាង",
        latin: "Kampong Pang"
      },
      id: "19050305"
    },
    {
      name: {
        km: "អន្លង់ជ្រៃ",
        latin: "Anlong Chrey"
      },
      id: "19050501"
    },
    {
      name: {
        km: "មន",
        latin: "Mon"
      },
      id: "19050502"
    },
    {
      name: {
        km: "ស្រឡៅ",
        latin: "Sralau"
      },
      id: "19050503"
    },
    {
      name: {
        km: "អូរ៉ៃ",
        latin: "Ou Rai"
      },
      id: "19050601"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "19050602"
    },
    {
      name: {
        km: "អន្លង់ស្វាយ",
        latin: "Anlong Svay"
      },
      id: "19050603"
    },
    {
      name: {
        km: "សំអាង",
        latin: "Sam Ang"
      },
      id: "19050901"
    },
    {
      name: {
        km: "ឆ្វាំង",
        latin: "Chhvang"
      },
      id: "19050902"
    },
    {
      name: {
        km: "ស្រែឫស្សី",
        latin: "Srae Ruessei"
      },
      id: "19051001"
    },
    {
      name: {
        km: "អន្លង់ក្រមួន",
        latin: "Anlong Kramuon"
      },
      id: "19051002"
    },
    {
      name: {
        km: "ថាឡាបរិវ៉ាត់",
        latin: "Thala Barivat"
      },
      id: "19051101"
    },
    {
      name: {
        km: "អូរត្រែល",
        latin: "Ou Trael"
      },
      id: "19051102"
    },
    {
      name: {
        km: "កាំងតេជោ",
        latin: "Kang Dechou"
      },
      id: "19051103"
    },
    {
      name: {
        km: "វាលខ្សាច់",
        latin: "Veal Khsach"
      },
      id: "19051104"
    },
    {
      name: {
        km: "កោះព្នៅ",
        latin: "Kaoh Pnov"
      },
      id: "19060101"
    },
    {
      name: {
        km: "អូរស្វាយ",
        latin: "Ou Svay"
      },
      id: "19060102"
    },
    {
      name: {
        km: "អូររុន",
        latin: "Ou Run"
      },
      id: "19060103"
    },
    {
      name: {
        km: "វើនសៀន",
        latin: "Veun Sien"
      },
      id: "19060104"
    },
    {
      name: {
        km: "កោះហិប",
        latin: "Kaoh Hib"
      },
      id: "19060105"
    },
    {
      name: {
        km: "ណាអូង",
        latin: "Na Oung"
      },
      id: "19060106"
    },
    {
      name: {
        km: "កោះស្នែង",
        latin: "Kaoh Snaeng"
      },
      id: "19060201"
    },
    {
      name: {
        km: "កោះស្រឡៅ",
        latin: "Kaoh Sralau"
      },
      id: "19060202"
    },
    {
      name: {
        km: "កោះកី",
        latin: "Kaoh Kei"
      },
      id: "19060203"
    },
    {
      name: {
        km: "ជាំធំ",
        latin: "Choam Thum"
      },
      id: "19060204"
    },
    {
      name: {
        km: "លើ",
        latin: "Leu"
      },
      id: "19060301"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "19060302"
    },
    {
      name: {
        km: "កោះឈើទាលតូច",
        latin: "Kaoh Chheu Teal Touch"
      },
      id: "19060303"
    },
    {
      name: {
        km: "កោះឈើទាលធំ",
        latin: "Kaoh Chheu Teal Thum"
      },
      id: "19060304"
    },
    {
      name: {
        km: "កោះល្ង",
        latin: "Kaoh Lngo"
      },
      id: "19060305"
    },
    {
      name: {
        km: "ក្រឡាពាស",
        latin: "Krala Peas"
      },
      id: "19060306"
    },
    {
      name: {
        km: "ក្រោម",
        latin: "Kraom"
      },
      id: "19060307"
    },
    {
      name: {
        km: "អន្លង់ស្វាយ",
        latin: "Anlong Svay"
      },
      id: "19060308"
    },
    {
      name: {
        km: "ចន្ទ្រា",
        latin: "Chantrea"
      },
      id: "20010301"
    },
    {
      name: {
        km: "គោកតែក",
        latin: "Kouk Taek"
      },
      id: "20010302"
    },
    {
      name: {
        km: "តែងម៉ៅ",
        latin: "Taeng Mau"
      },
      id: "20010303"
    },
    {
      name: {
        km: "សែនត",
        latin: "Saenta"
      },
      id: "20010304"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "20010401"
    },
    {
      name: {
        km: "ត្រពាំងធ្លក",
        latin: "Trapeang Thlok"
      },
      id: "20010402"
    },
    {
      name: {
        km: "ដូនណូយ",
        latin: "Doun Nouy"
      },
      id: "20010403"
    },
    {
      name: {
        km: "ត្រពាំងដៀលើ",
        latin: "Trapeang Die Leu"
      },
      id: "20010404"
    },
    {
      name: {
        km: "បន្លាស្អិត",
        latin: "Banla S'et"
      },
      id: "20010405"
    },
    {
      name: {
        km: "ទួលស្ពាន",
        latin: "Tuol Spean"
      },
      id: "20010406"
    },
    {
      name: {
        km: "ត្រពាំងដៀក្រោម",
        latin: "Trapeang Die Kraom"
      },
      id: "20010407"
    },
    {
      name: {
        km: "ទួលអំពិល",
        latin: "Tuol Ampil"
      },
      id: "20010408"
    },
    {
      name: {
        km: "ស្វាយគុយ",
        latin: "Svay Kuy"
      },
      id: "20010501"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "20010502"
    },
    {
      name: {
        km: "តាដេវ",
        latin: "Ta Dev"
      },
      id: "20010503"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Bos"
      },
      id: "20010504"
    },
    {
      name: {
        km: "បារាយណ៍",
        latin: "Baray"
      },
      id: "20010505"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "20010506"
    },
    {
      name: {
        km: "អង្គក្ដួច",
        latin: "Angk Kduoch"
      },
      id: "20010801"
    },
    {
      name: {
        km: "ត្រពាំងបុណ្យ",
        latin: "Trapeang Bon"
      },
      id: "20010802"
    },
    {
      name: {
        km: "ចំការលាវ",
        latin: "Chamkar Leav"
      },
      id: "20010803"
    },
    {
      name: {
        km: "ព្រៃគគីរ",
        latin: "Prey Kokir"
      },
      id: "20010804"
    },
    {
      name: {
        km: "កោះគោ",
        latin: "Kaoh Kou"
      },
      id: "20010805"
    },
    {
      name: {
        km: "កោះក្បានខាងជើង",
        latin: "Kaoh Kban Khang Cheung"
      },
      id: "20010901"
    },
    {
      name: {
        km: "កោះក្បានកណ្ដាល",
        latin: "Kaoh Kban Kandal"
      },
      id: "20010902"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "20010903"
    },
    {
      name: {
        km: "ទួលស្ដី",
        latin: "Tuol Sdei"
      },
      id: "20011001"
    },
    {
      name: {
        km: "ដូនទៃ",
        latin: "Doun Tey"
      },
      id: "20011002"
    },
    {
      name: {
        km: "កោះឫស្សី",
        latin: "Kaoh Ruessei"
      },
      id: "20011003"
    },
    {
      name: {
        km: "ពោធិក្រូច",
        latin: "Pou Krouch"
      },
      id: "20020101"
    },
    {
      name: {
        km: "កែវជះ",
        latin: "Kaev Cheah"
      },
      id: "20020102"
    },
    {
      name: {
        km: "ប្រចន្ទ្រា",
        latin: "Pra Chantrea"
      },
      id: "20020103"
    },
    {
      name: {
        km: "បន្ទាយក្រាំង",
        latin: "Banteay Krang"
      },
      id: "20020104"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "20020105"
    },
    {
      name: {
        km: "រោទិ៍",
        latin: "Rou"
      },
      id: "20020201"
    },
    {
      name: {
        km: "ត្រពាំងឆ្លូញ",
        latin: "Trapeang Chhlounh"
      },
      id: "20020202"
    },
    {
      name: {
        km: "ត្រពាំងត្រាវ",
        latin: "Trapeang Trav"
      },
      id: "20020203"
    },
    {
      name: {
        km: "ញរ",
        latin: "Nhor"
      },
      id: "20020204"
    },
    {
      name: {
        km: "ព្រៃត្រុំ",
        latin: "Prey Trom"
      },
      id: "20020205"
    },
    {
      name: {
        km: "ស្វាយអាណាត",
        latin: "Svay Anat"
      },
      id: "20020206"
    },
    {
      name: {
        km: "ពោធិ",
        latin: "Pou"
      },
      id: "20020301"
    },
    {
      name: {
        km: "ខ្សែត្រ",
        latin: "Khsaetr"
      },
      id: "20020302"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20020303"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20020304"
    },
    {
      name: {
        km: "ព្រៃតាញ៉យ",
        latin: "Prey Ta Nhoy"
      },
      id: "20020305"
    },
    {
      name: {
        km: "ព្រៃសង្កែ",
        latin: "Prey Sangkae"
      },
      id: "20020306"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20020307"
    },
    {
      name: {
        km: "សាម៉",
        latin: "Sama"
      },
      id: "20020308"
    },
    {
      name: {
        km: "ត្រពាំងកំពិស",
        latin: "Trapeang Kampis"
      },
      id: "20020309"
    },
    {
      name: {
        km: "ព្រៃចារ",
        latin: "Prey Char"
      },
      id: "20020310"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "20020311"
    },
    {
      name: {
        km: "ត្រពាំងស្មាច់",
        latin: "Trapeang Smach"
      },
      id: "20020312"
    },
    {
      name: {
        km: "ព្រៃចំណារ",
        latin: "Prey Chamnar"
      },
      id: "20020313"
    },
    {
      name: {
        km: "ឫស្សីអម",
        latin: "Ruessei Am"
      },
      id: "20020314"
    },
    {
      name: {
        km: "ត្រដែត",
        latin: "Tradaet"
      },
      id: "20020401"
    },
    {
      name: {
        km: "ព្រះពន្លា",
        latin: "Preah Ponlea"
      },
      id: "20020402"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20020403"
    },
    {
      name: {
        km: "អូរដូនអាម",
        latin: "Ou Doun Am"
      },
      id: "20020404"
    },
    {
      name: {
        km: "ត្រពាំងលាជ",
        latin: "Trapeang Leach"
      },
      id: "20020405"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "20020406"
    },
    {
      name: {
        km: "អង្គក្ដួច",
        latin: "Angk Kduoch"
      },
      id: "20020407"
    },
    {
      name: {
        km: "ព្រីងជ្រុំ",
        latin: "Pring Chrum"
      },
      id: "20020408"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "20020501"
    },
    {
      name: {
        km: "គគ្រួស",
        latin: "Kakruos"
      },
      id: "20020502"
    },
    {
      name: {
        km: "តាកឹង",
        latin: "Ta Koeng"
      },
      id: "20020503"
    },
    {
      name: {
        km: "ព្រះបាក់ក",
        latin: "Preah Bak Ka"
      },
      id: "20020504"
    },
    {
      name: {
        km: "ព្រៃសាគុំ",
        latin: "Prey Sakum"
      },
      id: "20020505"
    },
    {
      name: {
        km: "ត្រពាំងថ្នា",
        latin: "Trapeang Thna"
      },
      id: "20020601"
    },
    {
      name: {
        km: "ដែកភ្លើង",
        latin: "Daek Phleung"
      },
      id: "20020602"
    },
    {
      name: {
        km: "ឫស្សីដោច",
        latin: "Ruessei Daoch"
      },
      id: "20020603"
    },
    {
      name: {
        km: "ភ្នំស្រូវ",
        latin: "Phnum Srov"
      },
      id: "20020604"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "20020701"
    },
    {
      name: {
        km: "ព្រៃម្នាស់",
        latin: "Prey Mnoas"
      },
      id: "20020702"
    },
    {
      name: {
        km: "សំឡីខាងត្បូង",
        latin: "Samlei Khang Tboung"
      },
      id: "20020703"
    },
    {
      name: {
        km: "សំឡីខាងជើង",
        latin: "Samlei Khang Cheung"
      },
      id: "20020704"
    },
    {
      name: {
        km: "តាប៉ោ",
        latin: "Ta Pao"
      },
      id: "20020705"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "20020706"
    },
    {
      name: {
        km: "ព្រៃភ្នៀត",
        latin: "Prey Phniet"
      },
      id: "20020707"
    },
    {
      name: {
        km: "ព្រៃផ្អាវ",
        latin: "Prey Ph'av"
      },
      id: "20020708"
    },
    {
      name: {
        km: "ឫស្សីលៀប",
        latin: "Ruessei Lieb"
      },
      id: "20020801"
    },
    {
      name: {
        km: "ស្វាយគន្ធ្រែ",
        latin: "Svay Kuntrae"
      },
      id: "20020802"
    },
    {
      name: {
        km: "សំយ៉ោង",
        latin: "Samyaong"
      },
      id: "20020803"
    },
    {
      name: {
        km: "ព្រៃស្ទៀង",
        latin: "Prey Stieng"
      },
      id: "20020901"
    },
    {
      name: {
        km: "ត្រពាំងត្រាច",
        latin: "Trapeang Trach"
      },
      id: "20020902"
    },
    {
      name: {
        km: "សេកជ្រុំ",
        latin: "Sek Chrum"
      },
      id: "20020903"
    },
    {
      name: {
        km: "រាមជោរ",
        latin: "Ream Chour"
      },
      id: "20020904"
    },
    {
      name: {
        km: "ព្រៃប្រើស",
        latin: "Prey Praeus"
      },
      id: "20020905"
    },
    {
      name: {
        km: "ព្រៃធ្លក",
        latin: "Prey Thlok"
      },
      id: "20020906"
    },
    {
      name: {
        km: "ភូមិបុស្ស",
        latin: "Phum Bos"
      },
      id: "20020907"
    },
    {
      name: {
        km: "ពោធិ៍ថ្មី",
        latin: "Pou Thmei"
      },
      id: "20020908"
    },
    {
      name: {
        km: "ដើមពោធិ៍",
        latin: "Daeum Pou"
      },
      id: "20020909"
    },
    {
      name: {
        km: "ស្វាយតាយាន",
        latin: "Svay Ta Yean"
      },
      id: "20020910"
    },
    {
      name: {
        km: "ព្រៃវល្លិ៍",
        latin: "Prey Voa"
      },
      id: "20021101"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "20021102"
    },
    {
      name: {
        km: "កោះត្រាច",
        latin: "Kaoh Trach"
      },
      id: "20021103"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20021104"
    },
    {
      name: {
        km: "វាលម្អម",
        latin: "Veal M'am"
      },
      id: "20021105"
    },
    {
      name: {
        km: "ជប់ព្រីង",
        latin: "Chob Pring"
      },
      id: "20021106"
    },
    {
      name: {
        km: "ខាងកើតវត្ដ",
        latin: "Khang Kaeut Voat"
      },
      id: "20021201"
    },
    {
      name: {
        km: "ខាងលិចវត្ដ",
        latin: "Khang Lech Voat"
      },
      id: "20021202"
    },
    {
      name: {
        km: "បុណ្យ",
        latin: "Bon"
      },
      id: "20021203"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "20021204"
    },
    {
      name: {
        km: "ធ្លកថ្មី",
        latin: "Thlok Thmei"
      },
      id: "20021205"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "20021206"
    },
    {
      name: {
        km: "ព្រៃរបឹស",
        latin: "Prey Roboes"
      },
      id: "20021207"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "20021208"
    },
    {
      name: {
        km: "ពោធិ៍ម្អម",
        latin: "Pou M'am"
      },
      id: "20021209"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20021210"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20021211"
    },
    {
      name: {
        km: "បុសមនលើ",
        latin: "Bos Mon Leu"
      },
      id: "20030101"
    },
    {
      name: {
        km: "បុសមនក្រោម",
        latin: "Bos Mon Kraom"
      },
      id: "20030102"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "20030103"
    },
    {
      name: {
        km: "បុសស្វាយ",
        latin: "Bos Svay"
      },
      id: "20030104"
    },
    {
      name: {
        km: "បុសផ្លាំង",
        latin: "Bos Phlang"
      },
      id: "20030105"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20030106"
    },
    {
      name: {
        km: "ស្រម៉",
        latin: "Srama"
      },
      id: "20030107"
    },
    {
      name: {
        km: "ធ្មា",
        latin: "Thmea"
      },
      id: "20030201"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "20030202"
    },
    {
      name: {
        km: "ព្រៃត្នោត",
        latin: "Prey Tnaot"
      },
      id: "20030203"
    },
    {
      name: {
        km: "បឹងកែក",
        latin: "Boeng Kaek"
      },
      id: "20030204"
    },
    {
      name: {
        km: "ត្រពាំងពោន",
        latin: "Trapeang Poun"
      },
      id: "20030205"
    },
    {
      name: {
        km: "ព្រៃពោធិ៍",
        latin: "Prey Pou"
      },
      id: "20030206"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "20030207"
    },
    {
      name: {
        km: "ព្រៃចំការ",
        latin: "Prey Chamkar"
      },
      id: "20030208"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "20030301"
    },
    {
      name: {
        km: "ស្វាយរូង",
        latin: "Svay Rung"
      },
      id: "20030302"
    },
    {
      name: {
        km: "ព្រៃគាវ",
        latin: "Prey Keav"
      },
      id: "20030303"
    },
    {
      name: {
        km: "ឆ្អឹងពស់",
        latin: "Chh'oeng Pos"
      },
      id: "20030304"
    },
    {
      name: {
        km: "លាក់រាជា",
        latin: "Leak Reach Chea"
      },
      id: "20030305"
    },
    {
      name: {
        km: "ចំបក់កោង",
        latin: "Chambak Kaong"
      },
      id: "20030306"
    },
    {
      name: {
        km: "ទួលសាលា",
        latin: "Tuol Sala"
      },
      id: "20030401"
    },
    {
      name: {
        km: "ទួលទ្រា",
        latin: "Tuol Trea"
      },
      id: "20030402"
    },
    {
      name: {
        km: "នាងចាន់",
        latin: "Neang Chan"
      },
      id: "20030403"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "20030404"
    },
    {
      name: {
        km: "ព្រះអង្គកែវ",
        latin: "Preah Angk Kaev"
      },
      id: "20030405"
    },
    {
      name: {
        km: "ត្រពាំងគ្រួស",
        latin: "Trapeang Kruos"
      },
      id: "20030406"
    },
    {
      name: {
        km: "ត្រពាំងក្រែត",
        latin: "Trapeang Kraet"
      },
      id: "20030407"
    },
    {
      name: {
        km: "ត្រពាំងដំរី",
        latin: "Trapeang Damrei"
      },
      id: "20030501"
    },
    {
      name: {
        km: "ត្រសក់",
        latin: "Trasak"
      },
      id: "20030502"
    },
    {
      name: {
        km: "កំពង់អំពិល",
        latin: "Kampong Ampil"
      },
      id: "20030503"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "20030504"
    },
    {
      name: {
        km: "ទួលច្រេស",
        latin: "Tuol Chres"
      },
      id: "20030505"
    },
    {
      name: {
        km: "ស្វាយរលំ",
        latin: "Svay Rolum"
      },
      id: "20030506"
    },
    {
      name: {
        km: "តាតែ",
        latin: "Ta Tae"
      },
      id: "20030507"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "20030601"
    },
    {
      name: {
        km: "អង្គពក",
        latin: "Angk Pok"
      },
      id: "20030602"
    },
    {
      name: {
        km: "ចុងព្រែក",
        latin: "Chong Preaek"
      },
      id: "20030603"
    },
    {
      name: {
        km: "ហែកសំណាញ់",
        latin: "Haek Samnanh"
      },
      id: "20030604"
    },
    {
      name: {
        km: "ម៉ឺនជ័យ",
        latin: "Meun Chey"
      },
      id: "20030605"
    },
    {
      name: {
        km: "ព្រៃបឹង",
        latin: "Prey Boeng"
      },
      id: "20030606"
    },
    {
      name: {
        km: "តាខេង",
        latin: "Ta Kheng"
      },
      id: "20030607"
    },
    {
      name: {
        km: "ត្រាចទទឹង",
        latin: "Trach Totueng"
      },
      id: "20030608"
    },
    {
      name: {
        km: "ត្រពាំងកណ្តាល",
        latin: "Trapeang Kandaol"
      },
      id: "20030609"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "20030610"
    },
    {
      name: {
        km: "អណ្ដូងក្រសាំង",
        latin: "Andoung Krasang"
      },
      id: "20030701"
    },
    {
      name: {
        km: "បុសតូច",
        latin: "Bos Touch"
      },
      id: "20030702"
    },
    {
      name: {
        km: "ព្រៃស្រគុំ",
        latin: "Prey Srakum"
      },
      id: "20030703"
    },
    {
      name: {
        km: "ព្រៃតាយ័ន្ដ",
        latin: "Prey Ta Yoan"
      },
      id: "20030704"
    },
    {
      name: {
        km: "ត្រពាំងផ្អាវ",
        latin: "Trapeang Ph'av"
      },
      id: "20030705"
    },
    {
      name: {
        km: "ត្រពាំងថ្កូវ",
        latin: "Trapeang Thkov"
      },
      id: "20030706"
    },
    {
      name: {
        km: "សេកយំ",
        latin: "Sek Yum"
      },
      id: "20030707"
    },
    {
      name: {
        km: "ទួលតាកែវ",
        latin: "Tuol Ta Kaev"
      },
      id: "20030708"
    },
    {
      name: {
        km: "ទួលតាឃន",
        latin: "Tuol Ta Khoan"
      },
      id: "20030709"
    },
    {
      name: {
        km: "ស្វាយរំពារ",
        latin: "Svay Rumpea"
      },
      id: "20030801"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "20030802"
    },
    {
      name: {
        km: "តាជោរ",
        latin: "Ta Chou"
      },
      id: "20030803"
    },
    {
      name: {
        km: "ទួលចំបក់",
        latin: "Tuol Chambak"
      },
      id: "20030804"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "20030805"
    },
    {
      name: {
        km: "តាប៉ោង",
        latin: "Ta Paong"
      },
      id: "20030806"
    },
    {
      name: {
        km: "គោកស្រម៉",
        latin: "Kouk Srama"
      },
      id: "20030807"
    },
    {
      name: {
        km: "តាណែង",
        latin: "Ta Naeng"
      },
      id: "20030808"
    },
    {
      name: {
        km: "អន្លង់ស្ពាន",
        latin: "Anlong Spean"
      },
      id: "20030901"
    },
    {
      name: {
        km: "បាក្រុង",
        latin: "Ba Krong"
      },
      id: "20030902"
    },
    {
      name: {
        km: "ច្រកស្គរ",
        latin: "Chrak Skor"
      },
      id: "20030903"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20030904"
    },
    {
      name: {
        km: "ពពូល",
        latin: "Popul"
      },
      id: "20030905"
    },
    {
      name: {
        km: "រោងដំរី",
        latin: "Roung Damrei"
      },
      id: "20030906"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "20030907"
    },
    {
      name: {
        km: "ត្រពាំងចំបក់",
        latin: "Trapeang Chambak"
      },
      id: "20030908"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20030909"
    },
    {
      name: {
        km: "ត្រពាំងក្នុង",
        latin: "Trapeang Knong"
      },
      id: "20031001"
    },
    {
      name: {
        km: "ចំណតត្រាច",
        latin: "Chamnat Trach"
      },
      id: "20031002"
    },
    {
      name: {
        km: "ថ្នា",
        latin: "Thna"
      },
      id: "20031003"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "20031004"
    },
    {
      name: {
        km: "ព្រែកពក",
        latin: "Preaek Pok"
      },
      id: "20031005"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "20031006"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20031007"
    },
    {
      name: {
        km: "ទឹកវិល",
        latin: "Tuek Vil"
      },
      id: "20040101"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "20040102"
    },
    {
      name: {
        km: "តាម៉ៅ",
        latin: "Ta Mau"
      },
      id: "20040103"
    },
    {
      name: {
        km: "ត្រពាំងប្រីយ៍",
        latin: "Trapeang Prei"
      },
      id: "20040104"
    },
    {
      name: {
        km: "ត្រពាំងពពេល",
        latin: "Trapeang Popel"
      },
      id: "20040105"
    },
    {
      name: {
        km: "ត្រពាំងពិការ",
        latin: "Trapeang Pikar"
      },
      id: "20040106"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "20040107"
    },
    {
      name: {
        km: "រមាសហែក",
        latin: "Romeas Haek"
      },
      id: "20040108"
    },
    {
      name: {
        km: "ស្រែឫស្សី",
        latin: "Srae Ruessei"
      },
      id: "20040109"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20040110"
    },
    {
      name: {
        km: "អារក្សស្វាយ",
        latin: "Areaks Svay"
      },
      id: "20040201"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20040202"
    },
    {
      name: {
        km: "ត្រពាំងជ័រ",
        latin: "Trapeang Choar"
      },
      id: "20040203"
    },
    {
      name: {
        km: "ត្រពាំងត្បាល់",
        latin: "Trapeang Tbal"
      },
      id: "20040204"
    },
    {
      name: {
        km: "បុស្សគគីរ",
        latin: "Bos Kokir"
      },
      id: "20040205"
    },
    {
      name: {
        km: "ត្រពាំងបន្ទាយ",
        latin: "Trapeang Banteay"
      },
      id: "20040206"
    },
    {
      name: {
        km: "ត្រពាំងបុស្ស",
        latin: "Trapeang Bos"
      },
      id: "20040207"
    },
    {
      name: {
        km: "ចុងព្រែក",
        latin: "Chong Preaek"
      },
      id: "20040208"
    },
    {
      name: {
        km: "រាមសេនា",
        latin: "Ream Sena"
      },
      id: "20040209"
    },
    {
      name: {
        km: "រោងស្នោ",
        latin: "Roung Snao"
      },
      id: "20040210"
    },
    {
      name: {
        km: "ទួលច្រេស",
        latin: "Tuol Chres"
      },
      id: "20040301"
    },
    {
      name: {
        km: "កំរ៉ែង",
        latin: "Kamraeng"
      },
      id: "20040302"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "20040303"
    },
    {
      name: {
        km: "ជីកដី",
        latin: "Chik Dei"
      },
      id: "20040304"
    },
    {
      name: {
        km: "អណ្ដូងត្របែក",
        latin: "Andoung Trabaek"
      },
      id: "20040305"
    },
    {
      name: {
        km: "ត្រពាំងស្មាច់",
        latin: "Trapeang Smach"
      },
      id: "20040306"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "20040401"
    },
    {
      name: {
        km: "ក្បាលក្រពើ",
        latin: "Kbal Krapeu"
      },
      id: "20040402"
    },
    {
      name: {
        km: "ត្រពាំងរំដេញ",
        latin: "Trapeang Rumdenh"
      },
      id: "20040403"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "20040404"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "20040405"
    },
    {
      name: {
        km: "ក្អាម",
        latin: "K'am"
      },
      id: "20040406"
    },
    {
      name: {
        km: "អង្គប្រស្រែ",
        latin: "Angk Prasrae"
      },
      id: "20040407"
    },
    {
      name: {
        km: "អង្គក្នុង",
        latin: "Angk Knong"
      },
      id: "20040408"
    },
    {
      name: {
        km: "ច្រកម្រាក់",
        latin: "Chrak Mreak"
      },
      id: "20040409"
    },
    {
      name: {
        km: "បែកចាន",
        latin: "Baek Chan"
      },
      id: "20040410"
    },
    {
      name: {
        km: "ថ្នល់ពាម",
        latin: "Thnal Peam"
      },
      id: "20040411"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "20040412"
    },
    {
      name: {
        km: "បល្ល័ង្គជា",
        latin: "Ballangk Chea"
      },
      id: "20040501"
    },
    {
      name: {
        km: "សម្លរឆៅ",
        latin: "Samlar Chhau"
      },
      id: "20040502"
    },
    {
      name: {
        km: "ត្រពាំងធ្លកខាងជើង",
        latin: "Trapeang Thlok Khang Cheung"
      },
      id: "20040503"
    },
    {
      name: {
        km: "ត្រពាំងធ្លកខាងត្បូង",
        latin: "Trapeang Thlok Khang Tboung"
      },
      id: "20040504"
    },
    {
      name: {
        km: "ព្រៃកប្បាស",
        latin: "Prey Kabbas"
      },
      id: "20040505"
    },
    {
      name: {
        km: "ត្រពាំងពោន",
        latin: "Trapeang Poun"
      },
      id: "20040506"
    },
    {
      name: {
        km: "តាភរ",
        latin: "Ta Phor"
      },
      id: "20040507"
    },
    {
      name: {
        km: "ចន្ដ្រី",
        latin: "Chantrei"
      },
      id: "20040508"
    },
    {
      name: {
        km: "តាខិន",
        latin: "Ta Khen"
      },
      id: "20040509"
    },
    {
      name: {
        km: "ក្រហមក",
        latin: "Kraham Ka"
      },
      id: "20040510"
    },
    {
      name: {
        km: "ត្រពាំងតុំ",
        latin: "Trapeang Tom"
      },
      id: "20040511"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "20040512"
    },
    {
      name: {
        km: "ត្រពាំងរុនទី ១",
        latin: "Trapeang Run Ti Muoy"
      },
      id: "20040513"
    },
    {
      name: {
        km: "ត្រពាំងរុនទី ២",
        latin: "Trapeang Run Ti Pir"
      },
      id: "20040514"
    },
    {
      name: {
        km: "ត្រពាំងរុនទី ៣",
        latin: "Trapeang Run Ti Bei"
      },
      id: "20040515"
    },
    {
      name: {
        km: "ចុងព្រែក",
        latin: "Chong Preaek"
      },
      id: "20040516"
    },
    {
      name: {
        km: "ព្រៃរលួស",
        latin: "Prey Roluos"
      },
      id: "20040517"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "20040518"
    },
    {
      name: {
        km: "ត្រពាំងតាកែវ",
        latin: "Trapeang Ta Kaev"
      },
      id: "20040601"
    },
    {
      name: {
        km: "ត្រពាំងភ្លោះ",
        latin: "Trapeang Phluoh"
      },
      id: "20040602"
    },
    {
      name: {
        km: "ស្រះ",
        latin: "Srah"
      },
      id: "20040603"
    },
    {
      name: {
        km: "អន្សោង",
        latin: "Ansaong"
      },
      id: "20040604"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20040605"
    },
    {
      name: {
        km: "ព្រៃរំដេង",
        latin: "Prey Rumdeng"
      },
      id: "20040606"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "20040607"
    },
    {
      name: {
        km: "ចុងព្រៃ",
        latin: "Chong Prey"
      },
      id: "20040608"
    },
    {
      name: {
        km: "ដងក្ដារ",
        latin: "Dang Kdar"
      },
      id: "20040609"
    },
    {
      name: {
        km: "សណ្ដោ",
        latin: "Sandao"
      },
      id: "20040610"
    },
    {
      name: {
        km: "ត្រពាំងស្មាច់",
        latin: "Trapeang Smach"
      },
      id: "20040611"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "20040612"
    },
    {
      name: {
        km: "ព្រែករមៀត",
        latin: "Preaek Romiet"
      },
      id: "20040613"
    },
    {
      name: {
        km: "ខ្ពបត្រាច",
        latin: "Khpob Trach"
      },
      id: "20040614"
    },
    {
      name: {
        km: "តាសួស",
        latin: "Ta Suos"
      },
      id: "20040701"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "20040702"
    },
    {
      name: {
        km: "ត្រពាំងស្លា",
        latin: "Trapeang Sla"
      },
      id: "20040703"
    },
    {
      name: {
        km: "បុស្សសង្ឃ័រ",
        latin: "Bos Sangkhor"
      },
      id: "20040704"
    },
    {
      name: {
        km: "កំពង់ថ្នា",
        latin: "Kampong Thna"
      },
      id: "20040705"
    },
    {
      name: {
        km: "ព្រិច",
        latin: "Prich"
      },
      id: "20040706"
    },
    {
      name: {
        km: "ត្រពាំងធ្លក",
        latin: "Trapeang Thlok"
      },
      id: "20040707"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "20040708"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "20040709"
    },
    {
      name: {
        km: "ព្រៃទួល",
        latin: "Prey Tuol"
      },
      id: "20040710"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20040711"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "20040712"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡាញ់",
        latin: "Trapeang Kralanh"
      },
      id: "20040713"
    },
    {
      name: {
        km: "អង្គញ់",
        latin: "Angkunh"
      },
      id: "20040714"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "20040715"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "20040716"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "20040717"
    },
    {
      name: {
        km: "ក្រញូង",
        latin: "Kranhung"
      },
      id: "20040718"
    },
    {
      name: {
        km: "ជាធាជ",
        latin: "Chea Theach"
      },
      id: "20040719"
    },
    {
      name: {
        km: "ត្រពាំងរំពាក់",
        latin: "Trapeang Rumpeak"
      },
      id: "20040720"
    },
    {
      name: {
        km: "ស្វាយពក",
        latin: "Svay Pok"
      },
      id: "20040721"
    },
    {
      name: {
        km: "ថ្លុកព្រីង",
        latin: "Thlok Pring"
      },
      id: "20040722"
    },
    {
      name: {
        km: "ខ្លាលោត",
        latin: "Khla Lout"
      },
      id: "20040801"
    },
    {
      name: {
        km: "ព្រៃខ្ជាយ",
        latin: "Prey Khcheay"
      },
      id: "20040802"
    },
    {
      name: {
        km: "កំពង់ត្រាច",
        latin: "Kampong Trach"
      },
      id: "20040803"
    },
    {
      name: {
        km: "ចុងអូរ",
        latin: "Chong Ou"
      },
      id: "20040804"
    },
    {
      name: {
        km: "ឈើភ្លើង",
        latin: "Chheu Phleung"
      },
      id: "20040805"
    },
    {
      name: {
        km: "តាហង់",
        latin: "Ta Hang"
      },
      id: "20040806"
    },
    {
      name: {
        km: "ប្រមាត់ប្រាំ",
        latin: "Pramat Pram"
      },
      id: "20040807"
    },
    {
      name: {
        km: "ព្រៃក្រឡាញ់",
        latin: "Prey Kralanh"
      },
      id: "20040808"
    },
    {
      name: {
        km: "តាត្រាវ",
        latin: "Ta Trav"
      },
      id: "20040809"
    },
    {
      name: {
        km: "តាខុប",
        latin: "Ta Khob"
      },
      id: "20040810"
    },
    {
      name: {
        km: "តាសេក",
        latin: "Ta Sek"
      },
      id: "20040901"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "20040902"
    },
    {
      name: {
        km: "តាវាំង",
        latin: "Ta Veang"
      },
      id: "20040903"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់",
        latin: "Trapeang Skon"
      },
      id: "20040904"
    },
    {
      name: {
        km: "គគីរ",
        latin: "Kokir"
      },
      id: "20040905"
    },
    {
      name: {
        km: "ព្រៃផ្ដៀក",
        latin: "Prey Phdiek"
      },
      id: "20041001"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20041002"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "20041003"
    },
    {
      name: {
        km: "ស្មាច់",
        latin: "Smach"
      },
      id: "20041004"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "20041005"
    },
    {
      name: {
        km: "តាម៉ិញ",
        latin: "Ta Menh"
      },
      id: "20041006"
    },
    {
      name: {
        km: "ថ្លុកវាយទី ១",
        latin: "Thlok Veay Ti Muoy"
      },
      id: "20041007"
    },
    {
      name: {
        km: "ថ្លុកវាយទី ២",
        latin: "Thlok Veay Ti Pir"
      },
      id: "20041008"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "20041009"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "20041010"
    },
    {
      name: {
        km: "មុខដា",
        latin: "Mukh Da"
      },
      id: "20041101"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "20041102"
    },
    {
      name: {
        km: "ក្រញូង",
        latin: "Kranhung"
      },
      id: "20041103"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅទី ១",
        latin: "Prey Phdau Ti Muoy"
      },
      id: "20041104"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅទី ២",
        latin: "Prey Phdau Ti Pir"
      },
      id: "20041105"
    },
    {
      name: {
        km: "ត្រពាំងត្បាល់",
        latin: "Trapeang Tbal"
      },
      id: "20041106"
    },
    {
      name: {
        km: "រមៀត",
        latin: "Romiet"
      },
      id: "20041107"
    },
    {
      name: {
        km: "ថ្នល់ទទឹង",
        latin: "Thnal Totueng"
      },
      id: "20041108"
    },
    {
      name: {
        km: "ថ្នល់គ្រួស",
        latin: "Thnal Kruos"
      },
      id: "20041109"
    },
    {
      name: {
        km: "បុស្សកប្បាស",
        latin: "Bos Kabbas"
      },
      id: "20041110"
    },
    {
      name: {
        km: "អកមួល",
        latin: "Ak Muol"
      },
      id: "20041201"
    },
    {
      name: {
        km: "បឹងភេ",
        latin: "Boeng Phe"
      },
      id: "20041202"
    },
    {
      name: {
        km: "ម្រាមខាងត្បូង",
        latin: "Mream Khang Tboung"
      },
      id: "20041203"
    },
    {
      name: {
        km: "បឹងម្នង",
        latin: "Boeng Mnong"
      },
      id: "20041204"
    },
    {
      name: {
        km: "ទួលស្រង៉ាំ",
        latin: "Tuol Srangam"
      },
      id: "20041205"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "20041206"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "20041207"
    },
    {
      name: {
        km: "ព្រៃព្រែក",
        latin: "Prey Preaek"
      },
      id: "20041208"
    },
    {
      name: {
        km: "ព្រៃជ័រ",
        latin: "Prey Choar"
      },
      id: "20041209"
    },
    {
      name: {
        km: "ឆ្មា",
        latin: "Chhma"
      },
      id: "20041210"
    },
    {
      name: {
        km: "ត្រឡាច",
        latin: "Tralach"
      },
      id: "20041211"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "20041212"
    },
    {
      name: {
        km: "ត្រពាំងត្រសក់",
        latin: "Trapeang Trasak"
      },
      id: "20041213"
    },
    {
      name: {
        km: "ពពាយ",
        latin: "Popeay"
      },
      id: "20041214"
    },
    {
      name: {
        km: "ជ្រងពពេល",
        latin: "Chrong Popel"
      },
      id: "20041215"
    },
    {
      name: {
        km: "ត្រពាំងជ័រ",
        latin: "Trapeang Choar"
      },
      id: "20041216"
    },
    {
      name: {
        km: "ម្រាមខាងជើង",
        latin: "Mream Khang Cheung"
      },
      id: "20041217"
    },
    {
      name: {
        km: "តាទ្រូ",
        latin: "Ta Tru"
      },
      id: "20041218"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "20041219"
    },
    {
      name: {
        km: "តាប៉ាង",
        latin: "Ta Pang"
      },
      id: "20041220"
    },
    {
      name: {
        km: "ព្រែកត្នោត",
        latin: "Preaek Tnaot"
      },
      id: "20041301"
    },
    {
      name: {
        km: "បិទមាស",
        latin: "Bet Meas"
      },
      id: "20041302"
    },
    {
      name: {
        km: "រមាំងទ្រេត",
        latin: "Romeang Tret"
      },
      id: "20041303"
    },
    {
      name: {
        km: "ប្រសាទ",
        latin: "Prasat"
      },
      id: "20041304"
    },
    {
      name: {
        km: "ត្មាតពង",
        latin: "Tmat Pong"
      },
      id: "20041305"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "20041306"
    },
    {
      name: {
        km: "សូភី",
        latin: "Souphi"
      },
      id: "20041307"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20041308"
    },
    {
      name: {
        km: "ព្រៃគូរ",
        latin: "Prey Kur"
      },
      id: "20041309"
    },
    {
      name: {
        km: "អណ្ដូងខ្មត់",
        latin: "Andoung Khmat"
      },
      id: "20041310"
    },
    {
      name: {
        km: "ព្រិច",
        latin: "Prich"
      },
      id: "20041311"
    },
    {
      name: {
        km: "ព្រៃស្រះ",
        latin: "Prey Srah"
      },
      id: "20041401"
    },
    {
      name: {
        km: "ចំការកោះ",
        latin: "Chamkar Kaoh"
      },
      id: "20041402"
    },
    {
      name: {
        km: "ទួលស្រម៉",
        latin: "Tuol Srama"
      },
      id: "20041403"
    },
    {
      name: {
        km: "បិតទឹង",
        latin: "Bet Tueng"
      },
      id: "20041404"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "20041405"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "20041406"
    },
    {
      name: {
        km: "ទួលជ័យ",
        latin: "Tuol Chey"
      },
      id: "20041407"
    },
    {
      name: {
        km: "ព្រៃតាមូ",
        latin: "Prey Ta Mu"
      },
      id: "20041408"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់",
        latin: "Trapeang Skon"
      },
      id: "20041409"
    },
    {
      name: {
        km: "ក្រឡាញ់ទី ១",
        latin: "Kralanh Ti Muoy"
      },
      id: "20041410"
    },
    {
      name: {
        km: "ក្រឡាញ់ទី ២",
        latin: "Kralanh Ti Pir"
      },
      id: "20041411"
    },
    {
      name: {
        km: "ព្រៃក្រញូង",
        latin: "Prey Kranhung"
      },
      id: "20041412"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "20041413"
    },
    {
      name: {
        km: "ទួលជ័រ",
        latin: "Tuol Choar"
      },
      id: "20041501"
    },
    {
      name: {
        km: "វត្ដចាស់",
        latin: "Voat Chas"
      },
      id: "20041502"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់ថ្មី",
        latin: "Trapeang Skon Thmei"
      },
      id: "20041503"
    },
    {
      name: {
        km: "ស្វាយប៉ោ",
        latin: "Svay Pao"
      },
      id: "20041504"
    },
    {
      name: {
        km: "តាកុត",
        latin: "Ta Kot"
      },
      id: "20041505"
    },
    {
      name: {
        km: "ស្វាយទន្ទឹម",
        latin: "Svay Tontuem"
      },
      id: "20041506"
    },
    {
      name: {
        km: "ទួលក្រូច",
        latin: "Toul  Krouch"
      },
      id: "20041507"
    },
    {
      name: {
        km: "ម្រះព្រៅ",
        latin: "Mreah Prov"
      },
      id: "20041508"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20041509"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់ចាស់",
        latin: "Trapeang Skon Chas"
      },
      id: "20041510"
    },
    {
      name: {
        km: "ត្រពាំងស្ដៅ",
        latin: "Trapeang Sdau"
      },
      id: "20041511"
    },
    {
      name: {
        km: "បន្លាស្អិត",
        latin: "Banla S'et"
      },
      id: "20041512"
    },
    {
      name: {
        km: "ខ្នងជ្រូកខាងកើត",
        latin: "Khnang Chruk Khang Kaeut"
      },
      id: "20041513"
    },
    {
      name: {
        km: "ទួលសុក្រំ",
        latin: "Tuol Sokram"
      },
      id: "20041514"
    },
    {
      name: {
        km: "ខ្នងជ្រូកខាងលិច",
        latin: "Khnang Chruk Khang Lech"
      },
      id: "20041515"
    },
    {
      name: {
        km: "ត្រពាំងឈ្នាង",
        latin: "Trapeang Chhneang"
      },
      id: "20041516"
    },
    {
      name: {
        km: "ត្រពាំងរំដេញ",
        latin: "Trapeang Rumdenh"
      },
      id: "20041517"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "20041518"
    },
    {
      name: {
        km: "ទួលរមៀត",
        latin: "Tuol Romiet"
      },
      id: "20041519"
    },
    {
      name: {
        km: "ត្រកៀបក្ដាម",
        latin: "Trakieb Kdam"
      },
      id: "20041520"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Bos"
      },
      id: "20041601"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "20041602"
    },
    {
      name: {
        km: "មុន្នីប្រឹក្សា",
        latin: "Muni Proeksa"
      },
      id: "20041603"
    },
    {
      name: {
        km: "ម្រាក់ទាប",
        latin: "Mreak Teab"
      },
      id: "20041604"
    },
    {
      name: {
        km: "ត្រស់",
        latin: "Tras"
      },
      id: "20041605"
    },
    {
      name: {
        km: "តាសួស",
        latin: "Ta Suos"
      },
      id: "20041606"
    },
    {
      name: {
        km: "ពពេល",
        latin: "Popel"
      },
      id: "20041607"
    },
    {
      name: {
        km: "ពពូល",
        latin: "Popul"
      },
      id: "20041608"
    },
    {
      name: {
        km: "វាលវែង",
        latin: "Veal Veaeng"
      },
      id: "20041609"
    },
    {
      name: {
        km: "វាលត្មាត",
        latin: "Veal Tmat"
      },
      id: "20041610"
    },
    {
      name: {
        km: "សេរីអោ",
        latin: "Serei Ao"
      },
      id: "20041611"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "20041612"
    },
    {
      name: {
        km: "ត្រពាំងបន្ទាយ",
        latin: "Trapeang Banteay"
      },
      id: "20041613"
    },
    {
      name: {
        km: "ចែងម៉ែង",
        latin: "Chaeng Maeng"
      },
      id: "20050101"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "20050102"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "20050103"
    },
    {
      name: {
        km: "ម្អម",
        latin: "M'am"
      },
      id: "20050104"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "20050105"
    },
    {
      name: {
        km: "ស្វាយមីង",
        latin: "Svay Ming"
      },
      id: "20050106"
    },
    {
      name: {
        km: "សណ្ដោត",
        latin: "Sandaot"
      },
      id: "20050107"
    },
    {
      name: {
        km: "ថ្មពាន",
        latin: "Thma Pean"
      },
      id: "20050108"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "20050109"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "20050110"
    },
    {
      name: {
        km: "បឹងក្រៀល",
        latin: "Boeng Kriel"
      },
      id: "20050111"
    },
    {
      name: {
        km: "ម៉ឺនសាយ",
        latin: "Meun Say"
      },
      id: "20050112"
    },
    {
      name: {
        km: "សាលារៀន",
        latin: "Sala Rien"
      },
      id: "20050201"
    },
    {
      name: {
        km: "ស្វាយតាភ្ល",
        latin: "Svay Ta Phlo"
      },
      id: "20050202"
    },
    {
      name: {
        km: "ប៉ាយ៉ាប",
        latin: "Payab"
      },
      id: "20050203"
    },
    {
      name: {
        km: "ពោធិ៍តារស់",
        latin: "Pou Ta Ros"
      },
      id: "20050204"
    },
    {
      name: {
        km: "បាសាក់",
        latin: "Basak"
      },
      id: "20050205"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20050301"
    },
    {
      name: {
        km: "តានូ",
        latin: "Ta Nu"
      },
      id: "20050302"
    },
    {
      name: {
        km: "ទួលខ្ពស់",
        latin: "Tuol Khpos"
      },
      id: "20050303"
    },
    {
      name: {
        km: "អំពៅព្រៃ",
        latin: "Ampov Prey"
      },
      id: "20050304"
    },
    {
      name: {
        km: "ព្រៃតាពៅ",
        latin: "Prey Ta Pov"
      },
      id: "20050305"
    },
    {
      name: {
        km: "ទួលច្រេស",
        latin: "Tuol Chres"
      },
      id: "20050306"
    },
    {
      name: {
        km: "អង្គសាលា",
        latin: "Angk Sala"
      },
      id: "20050307"
    },
    {
      name: {
        km: "ជៀសឫស្សី",
        latin: "Chies Ruessei"
      },
      id: "20050401"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20050402"
    },
    {
      name: {
        km: "ចំបក់គុយ",
        latin: "Chambak Kuy"
      },
      id: "20050403"
    },
    {
      name: {
        km: "តាជៃ",
        latin: "Ta Chey"
      },
      id: "20050404"
    },
    {
      name: {
        km: "វាលល្ងើត",
        latin: "Veal Lngeut"
      },
      id: "20050405"
    },
    {
      name: {
        km: "ឫស្សីព្រៃ",
        latin: "Ruessei Prey"
      },
      id: "20050406"
    },
    {
      name: {
        km: "តាស្អាង",
        latin: "Ta S'ang"
      },
      id: "20050407"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "20050501"
    },
    {
      name: {
        km: "អង្គាសដី",
        latin: "Angkeas Dei"
      },
      id: "20050502"
    },
    {
      name: {
        km: "ស្វាយក្អែ",
        latin: "Svay K'ae"
      },
      id: "20050503"
    },
    {
      name: {
        km: "ចំបក់កោង",
        latin: "Chambak Kaong"
      },
      id: "20050504"
    },
    {
      name: {
        km: "បឹងអណ្ដែង",
        latin: "Boeng Andaeng"
      },
      id: "20050505"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20050506"
    },
    {
      name: {
        km: "ត្របែកប្រហោង",
        latin: "Trabaek Prahaong"
      },
      id: "20050507"
    },
    {
      name: {
        km: "តាប៉",
        latin: "Ta Pa"
      },
      id: "20050508"
    },
    {
      name: {
        km: "រំដេញ",
        latin: "Rumdenh"
      },
      id: "20050701"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20050702"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "20050703"
    },
    {
      name: {
        km: "ពោនកក",
        latin: "Poun Kak"
      },
      id: "20050704"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "20050705"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "20050706"
    },
    {
      name: {
        km: "តារាក់",
        latin: "Ta Reak"
      },
      id: "20050707"
    },
    {
      name: {
        km: "ព្រៃរកា",
        latin: "Prey Roka"
      },
      id: "20050708"
    },
    {
      name: {
        km: "ឈូកស",
        latin: "Chhuk Sa"
      },
      id: "20050709"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "20050710"
    },
    {
      name: {
        km: "ព្រៃចំបក់",
        latin: "Prey Chambak"
      },
      id: "20050711"
    },
    {
      name: {
        km: "នារាទេន",
        latin: "Nearea Ten"
      },
      id: "20050712"
    },
    {
      name: {
        km: "បឹងកែក",
        latin: "Boeng Kaek"
      },
      id: "20050801"
    },
    {
      name: {
        km: "ព្រៃរកា",
        latin: "Prey Roka"
      },
      id: "20050802"
    },
    {
      name: {
        km: "រំពាត់ជ្រូក",
        latin: "Rumpoat Chruk"
      },
      id: "20050803"
    },
    {
      name: {
        km: "ព្រៃពោធិ",
        latin: "Prey Pou"
      },
      id: "20050804"
    },
    {
      name: {
        km: "អូរស្រងាំ",
        latin: "Ou Srangam"
      },
      id: "20050805"
    },
    {
      name: {
        km: "ចំន្យា",
        latin: "Chamsa"
      },
      id: "20050806"
    },
    {
      name: {
        km: "ដូនស",
        latin: "Doun Sa"
      },
      id: "20050807"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "20050808"
    },
    {
      name: {
        km: "ដូងព្រះ",
        latin: "Doung Preah"
      },
      id: "20050809"
    },
    {
      name: {
        km: "សូ",
        latin: "Sou"
      },
      id: "20050810"
    },
    {
      name: {
        km: "គួយ",
        latin: "Kuoy"
      },
      id: "20050811"
    },
    {
      name: {
        km: "ក្ដុម្ពី",
        latin: "Kdompi"
      },
      id: "20050901"
    },
    {
      name: {
        km: "កន្ទួតប្រោង",
        latin: "Kantuot Praong"
      },
      id: "20050902"
    },
    {
      name: {
        km: "ថ្នា",
        latin: "Thna"
      },
      id: "20050903"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20050904"
    },
    {
      name: {
        km: "អង្គកប្បាស់",
        latin: "Angk Kabbas"
      },
      id: "20050905"
    },
    {
      name: {
        km: "ស្វាយព្រហូត",
        latin: "Svay Prahut"
      },
      id: "20050906"
    },
    {
      name: {
        km: "លាចឈូក",
        latin: "Leach Chhuk"
      },
      id: "20050907"
    },
    {
      name: {
        km: "ចំបក់ថ្លឹង",
        latin: "Chambak Thloeng"
      },
      id: "20050908"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "20050909"
    },
    {
      name: {
        km: "ដូនលែប",
        latin: "Doun Leaeb"
      },
      id: "20050910"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "20051001"
    },
    {
      name: {
        km: "ព្រៃញ៉យ",
        latin: "Prey Nhay"
      },
      id: "20051002"
    },
    {
      name: {
        km: "ក្រោលគោ",
        latin: "Kraol Kou"
      },
      id: "20051003"
    },
    {
      name: {
        km: "គោកកណ្ដោល",
        latin: "Kouk Kandaol"
      },
      id: "20051004"
    },
    {
      name: {
        km: "បន្លាស្អិត",
        latin: "Banla S'et"
      },
      id: "20051005"
    },
    {
      name: {
        km: "ឃួច",
        latin: "Khuoch"
      },
      id: "20051006"
    },
    {
      name: {
        km: "បឹងរ៉ៃខាងត្បូង",
        latin: "Boeng Rai Khang Tboung"
      },
      id: "20051007"
    },
    {
      name: {
        km: "បឹងរ៉ៃខាងជើង",
        latin: "Boeng Rai Khang Cheung"
      },
      id: "20051008"
    },
    {
      name: {
        km: "ឫស្សីជួរខាងជីង",
        latin: "Ruessei Chuor Khang Cheung"
      },
      id: "20051009"
    },
    {
      name: {
        km: "ឫស្សីជួរខាងត្បូង",
        latin: "Ruessei Chuor Khang Tboung"
      },
      id: "20051010"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "20051011"
    },
    {
      name: {
        km: "ព្រៃធ្នង់",
        latin: "Prey Thnong"
      },
      id: "20051012"
    },
    {
      name: {
        km: "ឫស្សីទទឹង",
        latin: "Ruessei Totueng"
      },
      id: "20051013"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "20051101"
    },
    {
      name: {
        km: "ថ្នា",
        latin: "Thna"
      },
      id: "20051102"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "20051103"
    },
    {
      name: {
        km: "ក្រៅ",
        latin: "Krau"
      },
      id: "20051104"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "20051105"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "20051106"
    },
    {
      name: {
        km: "ក្រសាំងជ្រំ",
        latin: "Krasang Chrum"
      },
      id: "20051107"
    },
    {
      name: {
        km: "ភ្លោះ",
        latin: "Phluoh"
      },
      id: "20051108"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20051109"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "20051110"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "20051111"
    },
    {
      name: {
        km: "ចិន្សា",
        latin: "Chensa"
      },
      id: "20051201"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "20051202"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "20051203"
    },
    {
      name: {
        km: "ឃ្លាំង",
        latin: "Khleang"
      },
      id: "20051204"
    },
    {
      name: {
        km: "តាមុំ",
        latin: "Ta Mom"
      },
      id: "20051205"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "20051206"
    },
    {
      name: {
        km: "ពោធិ៍រាជ",
        latin: "Pouthi Reach"
      },
      id: "20051207"
    },
    {
      name: {
        km: "ព្រៃដំឡូង",
        latin: "Prey Damloung"
      },
      id: "20051208"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "20051209"
    },
    {
      name: {
        km: "អូរសំដី",
        latin: "Ou Samdei"
      },
      id: "20051210"
    },
    {
      name: {
        km: "ត្រពាំងធ្លក",
        latin: "Trapeang Thlok"
      },
      id: "20051211"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "20051301"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20051302"
    },
    {
      name: {
        km: "សំព័រ",
        latin: "Sampoar"
      },
      id: "20051303"
    },
    {
      name: {
        km: "ស្វាយអង្គ",
        latin: "Svay Angk"
      },
      id: "20051304"
    },
    {
      name: {
        km: "គោល",
        latin: "Koul"
      },
      id: "20051305"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "20051306"
    },
    {
      name: {
        km: "ខ្នុរខាងជើង",
        latin: "Khnor Khang Cheung"
      },
      id: "20051307"
    },
    {
      name: {
        km: "ខ្នុរខាងត្បូង",
        latin: "Khnor Khang Tbpoung"
      },
      id: "20051308"
    },
    {
      name: {
        km: "ស្វាយផ្អែម",
        latin: "Svay Ph'aem"
      },
      id: "20051309"
    },
    {
      name: {
        km: "ថ្មស",
        latin: "Thma Sa"
      },
      id: "20051401"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "20051402"
    },
    {
      name: {
        km: "ឃ្មត់",
        latin: "Khmot"
      },
      id: "20051403"
    },
    {
      name: {
        km: "ស្វាយជ្រំ",
        latin: "Svay Chrum"
      },
      id: "20051404"
    },
    {
      name: {
        km: "បឹងវែង",
        latin: "Boeng Veaeng"
      },
      id: "20051405"
    },
    {
      name: {
        km: "ស្វាយក្ងោ",
        latin: "Svay Kngao"
      },
      id: "20051406"
    },
    {
      name: {
        km: "ព្រែកទប់",
        latin: "Preaek Tob"
      },
      id: "20051407"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "20051501"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "20051502"
    },
    {
      name: {
        km: "អង្គឫស្សី",
        latin: "Angk Ruessei"
      },
      id: "20051503"
    },
    {
      name: {
        km: "ត្រោកធំ",
        latin: "Traok Thum"
      },
      id: "20051504"
    },
    {
      name: {
        km: "ដក់ព",
        latin: "Dak Por"
      },
      id: "20051505"
    },
    {
      name: {
        km: "ទួលទ្រា",
        latin: "Tuol Trea"
      },
      id: "20051506"
    },
    {
      name: {
        km: "ស្វាយដូនអី",
        latin: "Svay Doun Ei"
      },
      id: "20051507"
    },
    {
      name: {
        km: "ចំរេះ",
        latin: "Chamreh"
      },
      id: "20051508"
    },
    {
      name: {
        km: "ត្រោកតូច",
        latin: "Traok Touch"
      },
      id: "20051509"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "20051510"
    },
    {
      name: {
        km: "ពពារ",
        latin: "Popear"
      },
      id: "20051511"
    },
    {
      name: {
        km: "ក្រាំងលាវ",
        latin: "Krang Leav"
      },
      id: "20051512"
    },
    {
      name: {
        km: "មហាសួន",
        latin: "Moha Suon"
      },
      id: "20051601"
    },
    {
      name: {
        km: "ស្វាយជំរៅ",
        latin: "Svay Chumrov"
      },
      id: "20051602"
    },
    {
      name: {
        km: "ព្រៃបន្ទាយ",
        latin: "Prey Banteay"
      },
      id: "20051603"
    },
    {
      name: {
        km: "របោះព្រីង",
        latin: "Robaoh Pring"
      },
      id: "20051604"
    },
    {
      name: {
        km: "ដំណាក់កន្ទួត",
        latin: "Damnak Kantuot"
      },
      id: "20051605"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "20051606"
    },
    {
      name: {
        km: "ទួលវិហារ",
        latin: "Tuol Vihear"
      },
      id: "20051607"
    },
    {
      name: {
        km: "ក្ដីស្លា",
        latin: "Kdei Sla"
      },
      id: "20051608"
    },
    {
      name: {
        km: "ប្រម៉ា",
        latin: "Prama"
      },
      id: "20051609"
    },
    {
      name: {
        km: "ស៊ីការ",
        latin: "Sikar"
      },
      id: "20051610"
    },
    {
      name: {
        km: "កៀនតាស៊ីវ",
        latin: "Kien Ta Siv"
      },
      id: "20051611"
    },
    {
      name: {
        km: "ចំការចេក",
        latin: "Chamkar Chek"
      },
      id: "20051612"
    },
    {
      name: {
        km: "ស្វាយយា",
        latin: "Svay Yea"
      },
      id: "20051613"
    },
    {
      name: {
        km: "ព្រៃផ្អុង",
        latin: "Prey Ph'ong"
      },
      id: "20051614"
    },
    {
      name: {
        km: "តាកោ",
        latin: "Ta Kao"
      },
      id: "20051615"
    },
    {
      name: {
        km: "ដូនទង",
        latin: "Doun Tong"
      },
      id: "20051701"
    },
    {
      name: {
        km: "ស្នាយគ្រាង",
        latin: "Snay Kreang"
      },
      id: "20051702"
    },
    {
      name: {
        km: "អំពៅព្រៃ",
        latin: "Ampov Prey"
      },
      id: "20051703"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "20051704"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20051705"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "20051706"
    },
    {
      name: {
        km: "ទៃយា",
        latin: "Tey Yea"
      },
      id: "20051707"
    },
    {
      name: {
        km: "សំដី",
        latin: "Samdei"
      },
      id: "20051708"
    },
    {
      name: {
        km: "ស្វាយរៀង",
        latin: "Svay Rieng"
      },
      id: "20060101"
    },
    {
      name: {
        km: "វាលយន្ដ",
        latin: "Veal Yon"
      },
      id: "20060102"
    },
    {
      name: {
        km: "រោងបន្លែ",
        latin: "Roung Banlae"
      },
      id: "20060103"
    },
    {
      name: {
        km: "មេភ្លើង",
        latin: "Me Phleung"
      },
      id: "20060104"
    },
    {
      name: {
        km: "ស្រះវង់",
        latin: "Srah Vong"
      },
      id: "20060105"
    },
    {
      name: {
        km: "កៀនសាំង",
        latin: "Kien Sang"
      },
      id: "20060106"
    },
    {
      name: {
        km: "ចុងព្រែក",
        latin: "Chong Preaek"
      },
      id: "20060107"
    },
    {
      name: {
        km: "សួនថ្មី",
        latin: "Suon Thmei"
      },
      id: "20060201"
    },
    {
      name: {
        km: "រូបគោ",
        latin: "Rub Kou"
      },
      id: "20060202"
    },
    {
      name: {
        km: "សាលាស្រុកចាស់",
        latin: "Sala Srok Chas"
      },
      id: "20060203"
    },
    {
      name: {
        km: "ព្រៃឆ្លាក់",
        latin: "Prey Chhlak"
      },
      id: "20060204"
    },
    {
      name: {
        km: "អណ្ដូងតាសី",
        latin: "Andoung Ta Sei"
      },
      id: "20060205"
    },
    {
      name: {
        km: "គយត្របែក",
        latin: "Koy Trabaek"
      },
      id: "20060301"
    },
    {
      name: {
        km: "តារាងបាល់",
        latin: "Tarang Bal"
      },
      id: "20060302"
    },
    {
      name: {
        km: "ក្បាលស្ពាន",
        latin: "Kbal Spean"
      },
      id: "20060401"
    },
    {
      name: {
        km: "ឡ",
        latin: "La"
      },
      id: "20060402"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "20060403"
    },
    {
      name: {
        km: "ពោធិតាហោ",
        latin: "Pou Ta Hao"
      },
      id: "20060404"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "20060501"
    },
    {
      name: {
        km: "ធ្មល់",
        latin: "Thmol"
      },
      id: "20060502"
    },
    {
      name: {
        km: "ស្វាត",
        latin: "Svat"
      },
      id: "20060503"
    },
    {
      name: {
        km: "ទទា",
        latin: "Totea"
      },
      id: "20060504"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "20060505"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "20060506"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20060507"
    },
    {
      name: {
        km: "មេលោង",
        latin: "Meloung"
      },
      id: "20060508"
    },
    {
      name: {
        km: "ឃ្លាំង",
        latin: "Khleang"
      },
      id: "20060509"
    },
    {
      name: {
        km: "គក់ប៉ែ",
        latin: "Kok Pae"
      },
      id: "20060510"
    },
    {
      name: {
        km: "តាជោរ",
        latin: "Ta Chour"
      },
      id: "20060601"
    },
    {
      name: {
        km: "តាណរ",
        latin: "Ta Nar"
      },
      id: "20060602"
    },
    {
      name: {
        km: "ឃោសាង",
        latin: "Khousang"
      },
      id: "20060603"
    },
    {
      name: {
        km: "ទាហានក្រោម",
        latin: "Teahean Kraom"
      },
      id: "20060604"
    },
    {
      name: {
        km: "ទាហានលើ",
        latin: "Teahean Leu"
      },
      id: "20060605"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "20060606"
    },
    {
      name: {
        km: "ស្វាយតឿ",
        latin: "Svay Toea"
      },
      id: "20060607"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20060701"
    },
    {
      name: {
        km: "បាក់រនាស់",
        latin: "Bak Ronoas"
      },
      id: "20060702"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "20060703"
    },
    {
      name: {
        km: "ស្រម៉ជ្រុំ",
        latin: "Srama Chrum"
      },
      id: "20060704"
    },
    {
      name: {
        km: "ចំបក់ពាម",
        latin: "Chambak Peam"
      },
      id: "20060705"
    },
    {
      name: {
        km: "ព្រះទន្លេ",
        latin: "Preah Tonle"
      },
      id: "20060706"
    },
    {
      name: {
        km: "ពភ្លា",
        latin: "Pophlea"
      },
      id: "20060707"
    },
    {
      name: {
        km: "អកនាគ",
        latin: "Ak Neak"
      },
      id: "20060708"
    },
    {
      name: {
        km: "សង្គម",
        latin: "Sangkum"
      },
      id: "20070201"
    },
    {
      name: {
        km: "កំពោតទូក",
        latin: "Kampot Tuk"
      },
      id: "20070202"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "20070203"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "20070204"
    },
    {
      name: {
        km: "ព្រៃតាធឹក",
        latin: "Prey Ta Thuek"
      },
      id: "20070205"
    },
    {
      name: {
        km: "ទួលអង្គ",
        latin: "Tuol Angk"
      },
      id: "20070206"
    },
    {
      name: {
        km: "វាយ",
        latin: "Veay"
      },
      id: "20070207"
    },
    {
      name: {
        km: "បាត់ថង់",
        latin: "Bat Thang"
      },
      id: "20070208"
    },
    {
      name: {
        km: "ប្រសូតិ ទី ១",
        latin: "Prasout Ti Muoy"
      },
      id: "20070301"
    },
    {
      name: {
        km: "ប្រសូតិ ទី ២",
        latin: "Prasout Ti Pir"
      },
      id: "20070302"
    },
    {
      name: {
        km: "បន្ទាយ",
        latin: "Banteay"
      },
      id: "20070303"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "20070304"
    },
    {
      name: {
        km: "លៀប",
        latin: "Lieb"
      },
      id: "20070305"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "20070306"
    },
    {
      name: {
        km: "ដើមពោធិ៍",
        latin: "Daeum Pou"
      },
      id: "20070307"
    },
    {
      name: {
        km: "កណ្ដៀងរាយ",
        latin: "Kandieng Reay"
      },
      id: "20070308"
    },
    {
      name: {
        km: "ជប់",
        latin: "Chob"
      },
      id: "20070309"
    },
    {
      name: {
        km: "អូរតាមោ",
        latin: "Ou Tamou"
      },
      id: "20070401"
    },
    {
      name: {
        km: "មនោរម្យ",
        latin: "Monourom"
      },
      id: "20070402"
    },
    {
      name: {
        km: "ថ្លុកព្រីង",
        latin: "Thlok Pring"
      },
      id: "20070501"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "20070502"
    },
    {
      name: {
        km: "កំពង់រទេះ",
        latin: "Kompong Roteh"
      },
      id: "20070503"
    },
    {
      name: {
        km: "ទួលច្រេស",
        latin: "Tuol Chres"
      },
      id: "20070504"
    },
    {
      name: {
        km: "កំពោតម្រាក់",
        latin: "kampout Mreak"
      },
      id: "20070505"
    },
    {
      name: {
        km: "ស្វាយគយ",
        latin: "Svay Koy"
      },
      id: "20070506"
    },
    {
      name: {
        km: "តាប៉ោញ",
        latin: "Ta Paonh"
      },
      id: "20070601"
    },
    {
      name: {
        km: "ត្រពាំងតាអី",
        latin: "Trapeang Ta Ei"
      },
      id: "20070602"
    },
    {
      name: {
        km: "ត្រពាំងផ្លាំង",
        latin: "Trapeang Phlang"
      },
      id: "20070603"
    },
    {
      name: {
        km: "ស្វាយផ្អែម",
        latin: "Svay Ph'aem"
      },
      id: "20070604"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "20070605"
    },
    {
      name: {
        km: "ត្រពាំងស្បូវ",
        latin: "Trapeang Sbov"
      },
      id: "20070606"
    },
    {
      name: {
        km: "ព្រៃរំដួល",
        latin: "Prey Rumduol"
      },
      id: "20070701"
    },
    {
      name: {
        km: "ពោធិវង្ស",
        latin: "Pou Vong"
      },
      id: "20070702"
    },
    {
      name: {
        km: "អង្គស្វាយ",
        latin: "Angk Svay"
      },
      id: "20070703"
    },
    {
      name: {
        km: "ទួលត្របែក",
        latin: "Tuol Trabaek"
      },
      id: "20070704"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "20070705"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "20070706"
    },
    {
      name: {
        km: "ព្រៃតាយឹង",
        latin: "Prey Ta Yueng"
      },
      id: "20070707"
    },
    {
      name: {
        km: "ព្រៃទួល",
        latin: "Prey Tuol"
      },
      id: "20070708"
    },
    {
      name: {
        km: "អង្គតាមោក",
        latin: "Angk Ta Mouk"
      },
      id: "20070709"
    },
    {
      name: {
        km: "ទន្លៀង",
        latin: "Tonlieng"
      },
      id: "20070801"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "20070802"
    },
    {
      name: {
        km: "ខ្នុរ",
        latin: "Khnor"
      },
      id: "20070803"
    },
    {
      name: {
        km: "ខ្ជាយ",
        latin: "Khcheay"
      },
      id: "20070804"
    },
    {
      name: {
        km: "ឫស្សីជង្រុក",
        latin: "Ruessei Chongruk"
      },
      id: "20070805"
    },
    {
      name: {
        km: "ក្រញូង",
        latin: "Kranhung"
      },
      id: "20070806"
    },
    {
      name: {
        km: "ព្រៃធ្នង់",
        latin: "Prey Thnong"
      },
      id: "20070807"
    },
    {
      name: {
        km: "បែកចាន",
        latin: "Baek Chan"
      },
      id: "20070808"
    },
    {
      name: {
        km: "រមាំងថ្កោល",
        latin: "Romeang Thkaol"
      },
      id: "20070809"
    },
    {
      name: {
        km: "ទួលអង្គប់",
        latin: "Tuol Angkob"
      },
      id: "20070901"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20070902"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "20070903"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "20070904"
    },
    {
      name: {
        km: "អូរក្អាម",
        latin: "O Kaam"
      },
      id: "20070905"
    },
    {
      name: {
        km: "សង្គ្រោះ",
        latin: "Sangkruoh"
      },
      id: "20070906"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "20070907"
    },
    {
      name: {
        km: "ព្រៃសម្ភ័រ",
        latin: "Prey Samphoar"
      },
      id: "20070908"
    },
    {
      name: {
        km: "អង្ក្រង",
        latin: "Angkrong"
      },
      id: "20071101"
    },
    {
      name: {
        km: "ទួលអំពិល",
        latin: "Tuol Ampil"
      },
      id: "20071102"
    },
    {
      name: {
        km: "ស្រម៉",
        latin: "Srama"
      },
      id: "20071103"
    },
    {
      name: {
        km: "កញ្ឆែត",
        latin: "Kanhchhaet"
      },
      id: "20071104"
    },
    {
      name: {
        km: "កក់",
        latin: "Kak"
      },
      id: "20071105"
    },
    {
      name: {
        km: "ស្វាយធំ",
        latin: "Svay Thum"
      },
      id: "20071106"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "20080101"
    },
    {
      name: {
        km: "ថ្នាញ",
        latin: "Thnanh"
      },
      id: "20080102"
    },
    {
      name: {
        km: "ប្រសប់លាក់",
        latin: "Prasab Leak"
      },
      id: "20080103"
    },
    {
      name: {
        km: "ច្រកឫស្សី",
        latin: "Chrak Ruessei"
      },
      id: "20080104"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "20080105"
    },
    {
      name: {
        km: "តាបឹប",
        latin: "Ta Boeb"
      },
      id: "20080201"
    },
    {
      name: {
        km: "តាពៅ",
        latin: "Ta Pov"
      },
      id: "20080202"
    },
    {
      name: {
        km: "បាវិតលើ",
        latin: "Bavet Leu"
      },
      id: "20080203"
    },
    {
      name: {
        km: "បាវិតកណ្ដាល",
        latin: "Bavet Kandal"
      },
      id: "20080204"
    },
    {
      name: {
        km: "ច្រកលាវ",
        latin: "Chrak Leav"
      },
      id: "20080205"
    },
    {
      name: {
        km: "កំពោតស្គារ",
        latin: "Kampout Skear"
      },
      id: "20080301"
    },
    {
      name: {
        km: "ថ្នល់ជាតិ",
        latin: "Thnal Cheat"
      },
      id: "20080302"
    },
    {
      name: {
        km: "ច្រកម្ទេស",
        latin: "Chrak Mtes"
      },
      id: "20080303"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "20080304"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "20080305"
    },
    {
      name: {
        km: "ថ្នល់ទទឹង",
        latin: "Thnal Totueng"
      },
      id: "20080306"
    },
    {
      name: {
        km: "ដំបូកជួរ",
        latin: "Dambouk Chuor"
      },
      id: "20080307"
    },
    {
      name: {
        km: "ទួលអំពិល",
        latin: "Tuol Ampil"
      },
      id: "20080308"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "20080309"
    },
    {
      name: {
        km: "កំពោតប្រស់",
        latin: "Kampout Pras"
      },
      id: "20080310"
    },
    {
      name: {
        km: "សាលាទាន",
        latin: "Sala Tean"
      },
      id: "20080311"
    },
    {
      name: {
        km: "ព្រៃទប់",
        latin: "Prey Tob"
      },
      id: "20080312"
    },
    {
      name: {
        km: "បត់ស្លឹក",
        latin: "Bat Sloek"
      },
      id: "20080313"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "20080314"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "20080315"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "20080401"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "20080402"
    },
    {
      name: {
        km: "គោកល្វៀង",
        latin: "Kouk Lvieng"
      },
      id: "20080501"
    },
    {
      name: {
        km: "កំពោតជ្រូក",
        latin: "Kampout Chruk"
      },
      id: "20080502"
    },
    {
      name: {
        km: "ជ្រៃធំ",
        latin: "Chrey Thum"
      },
      id: "20080503"
    },
    {
      name: {
        km: "ព្រៃទប់",
        latin: "Prey Tob"
      },
      id: "20080504"
    },
    {
      name: {
        km: "អង្គ្កសាលា",
        latin: "Angk Sala"
      },
      id: "20080505"
    },
    {
      name: {
        km: "កំពោតលៀប",
        latin: "Kampout Liep"
      },
      id: "20080506"
    },
    {
      name: {
        km: "ព្រៃអង្គុញ",
        latin: "Prey Angkunh"
      },
      id: "20080507"
    },
    {
      name: {
        km: "បុស្សក្រសាំង",
        latin: "Bos Krasang"
      },
      id: "20080508"
    },
    {
      name: {
        km: "កំពង់ហ្លួង",
        latin: "Kampong Luong"
      },
      id: "21010101"
    },
    {
      name: {
        km: "ស្ទឹងកំបុត",
        latin: "Stueng Kambot"
      },
      id: "21010102"
    },
    {
      name: {
        km: "ព្រៃសំបួរ",
        latin: "Prey Sambuor"
      },
      id: "21010103"
    },
    {
      name: {
        km: "ទួលសាំង ក",
        latin: "Tuol Sang Ka"
      },
      id: "21010104"
    },
    {
      name: {
        km: "ទួលសាំង ខ",
        latin: "Tuol Sang Kha"
      },
      id: "21010105"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "21010106"
    },
    {
      name: {
        km: "ស្វាយខាងត្បូង",
        latin: "Svay Khang Tboung"
      },
      id: "21010201"
    },
    {
      name: {
        km: "ស្វាយខាងជើង",
        latin: "Svay Khang Cheung"
      },
      id: "21010202"
    },
    {
      name: {
        km: "បាស្រែ",
        latin: "Ba Srae"
      },
      id: "21010203"
    },
    {
      name: {
        km: "រំលក",
        latin: "Rumlok"
      },
      id: "21010204"
    },
    {
      name: {
        km: "ព្រៃបាសឹង",
        latin: "Prey Ba Soeng"
      },
      id: "21010205"
    },
    {
      name: {
        km: "តាអី",
        latin: "Ta Ei"
      },
      id: "21010206"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "21010207"
    },
    {
      name: {
        km: "ពួនកក",
        latin: "Puon Kak"
      },
      id: "21010208"
    },
    {
      name: {
        km: "ព្រែកតាផ",
        latin: "Preaek Ta Pha"
      },
      id: "21010301"
    },
    {
      name: {
        km: "ព្រែកដា",
        latin: "Preaek Da"
      },
      id: "21010302"
    },
    {
      name: {
        km: "ទួលពុទ្រា",
        latin: "Tuol Puttrea"
      },
      id: "21010303"
    },
    {
      name: {
        km: "បាក់ដៃ",
        latin: "Bak Dai"
      },
      id: "21010304"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "21010401"
    },
    {
      name: {
        km: "ស្រម៉ុក",
        latin: "Sramok"
      },
      id: "21010402"
    },
    {
      name: {
        km: "ពន្លៃខាងជើង",
        latin: "Ponley Khang Cheung"
      },
      id: "21010403"
    },
    {
      name: {
        km: "ពន្លៃខាងត្បូង",
        latin: "Ponley Khang Tboung"
      },
      id: "21010404"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "21010405"
    },
    {
      name: {
        km: "ធ្លកយុល",
        latin: "Thlok Yul"
      },
      id: "21010406"
    },
    {
      name: {
        km: "អង្គរ",
        latin: "Angkor"
      },
      id: "21010501"
    },
    {
      name: {
        km: "កំពង់ពោធិ៍",
        latin: "Kampong Pou"
      },
      id: "21010502"
    },
    {
      name: {
        km: "ភ្នំបូរី",
        latin: "Phnum Bourei"
      },
      id: "21010503"
    },
    {
      name: {
        km: "ភ្នំបាទេព",
        latin: "Phnum Ba Tep"
      },
      id: "21010504"
    },
    {
      name: {
        km: "ព្រៃផ្គាំ ក",
        latin: "Prey Phkoam Ka"
      },
      id: "21010601"
    },
    {
      name: {
        km: "ព្រៃផ្គាំ ខ",
        latin: "Prey Phkoam Kha"
      },
      id: "21010602"
    },
    {
      name: {
        km: "ព្រៃផ្គាំ គ",
        latin: "Prey Phkoam Ko"
      },
      id: "21010603"
    },
    {
      name: {
        km: "ទ្រង់់ភូមិ",
        latin: "Trong Phum"
      },
      id: "21010604"
    },
    {
      name: {
        km: "យាផ្អើ",
        latin: "Yea Ph'aeur"
      },
      id: "21010605"
    },
    {
      name: {
        km: "តាមូង",
        latin: "Ta Mung"
      },
      id: "21010606"
    },
    {
      name: {
        km: "តានប់",
        latin: "Ta Nob"
      },
      id: "21020101"
    },
    {
      name: {
        km: "បចាម",
        latin: "Bacham"
      },
      id: "21020102"
    },
    {
      name: {
        km: "ម្រះព្រៅ",
        latin: "Mreah Prov"
      },
      id: "21020103"
    },
    {
      name: {
        km: "ត្រពាំងលាន",
        latin: "Trapeang Lean"
      },
      id: "21020104"
    },
    {
      name: {
        km: "រុន",
        latin: "Run"
      },
      id: "21020105"
    },
    {
      name: {
        km: "ស្ដៅឯម",
        latin: "Sdau Aem"
      },
      id: "21020106"
    },
    {
      name: {
        km: "កន្លែងខ្លា",
        latin: "Kanlaeng Khla"
      },
      id: "21020107"
    },
    {
      name: {
        km: "សីហា",
        latin: "Seiha"
      },
      id: "21020108"
    },
    {
      name: {
        km: "បឹងលាច",
        latin: "Boeng Leach"
      },
      id: "21020109"
    },
    {
      name: {
        km: "វាលប្រីយ៍",
        latin: "Veal Prei"
      },
      id: "21020110"
    },
    {
      name: {
        km: "ស្រមោចហែ",
        latin: "Sramaoch Hae"
      },
      id: "21020111"
    },
    {
      name: {
        km: "ត្រពាំងត្រយឹង",
        latin: "Trapeang Trayueng"
      },
      id: "21020112"
    },
    {
      name: {
        km: "ដើមដូង",
        latin: "Daeum Doung"
      },
      id: "21020201"
    },
    {
      name: {
        km: "ម្កាក់",
        latin: "Mkak"
      },
      id: "21020202"
    },
    {
      name: {
        km: "ត្រកៀត",
        latin: "Trakiet"
      },
      id: "21020203"
    },
    {
      name: {
        km: "ព្រែក",
        latin: "Preaek"
      },
      id: "21020204"
    },
    {
      name: {
        km: "មឿងប្រចិន",
        latin: "Moeang Prachen"
      },
      id: "21020205"
    },
    {
      name: {
        km: "ព្រៃមូល",
        latin: "Prey Mul"
      },
      id: "21020206"
    },
    {
      name: {
        km: "ជើងលោង",
        latin: "Cheung Loung"
      },
      id: "21020207"
    },
    {
      name: {
        km: "ប្រមូលសុខ",
        latin: "Pramoul Sokh"
      },
      id: "21020301"
    },
    {
      name: {
        km: "ក្រាំងប្រទាល",
        latin: "Krang Prateal"
      },
      id: "21020302"
    },
    {
      name: {
        km: "ស្វាយខម",
        latin: "Svay Kham"
      },
      id: "21020303"
    },
    {
      name: {
        km: "យុថ្កា",
        latin: "Yuthka"
      },
      id: "21020304"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "21020305"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "21020306"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "21020307"
    },
    {
      name: {
        km: "តានន",
        latin: "Ta Non"
      },
      id: "21020308"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "21020401"
    },
    {
      name: {
        km: "ក្រាំងអំពិល",
        latin: "Krang Ampil"
      },
      id: "21020402"
    },
    {
      name: {
        km: "ឱភាសាំង",
        latin: "Aopheasang"
      },
      id: "21020403"
    },
    {
      name: {
        km: "ព្រះម្លប់",
        latin: "Preah Mlob"
      },
      id: "21020404"
    },
    {
      name: {
        km: "ត្រពាំងលើក",
        latin: "Trapeang Leuk"
      },
      id: "21020405"
    },
    {
      name: {
        km: "កណ្ដឹងធំ",
        latin: "Kandoeng Thum"
      },
      id: "21020406"
    },
    {
      name: {
        km: "ហនុមាន",
        latin: "Haknuman"
      },
      id: "21020407"
    },
    {
      name: {
        km: "កណ្ដឹងតូច",
        latin: "Kandoeng Touch"
      },
      id: "21020408"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "21020501"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "21020502"
    },
    {
      name: {
        km: "សិរីមានជោគ",
        latin: "Serei Mean Chouk"
      },
      id: "21020503"
    },
    {
      name: {
        km: "ត្រមូងជ្រុំ",
        latin: "Tramung Chrum"
      },
      id: "21020504"
    },
    {
      name: {
        km: "ក្រាំងពង្រ",
        latin: "Krang Pongro"
      },
      id: "21020505"
    },
    {
      name: {
        km: "រយ៉ក",
        latin: "Royak"
      },
      id: "21020506"
    },
    {
      name: {
        km: "ស្ដុក",
        latin: "Sdok"
      },
      id: "21020507"
    },
    {
      name: {
        km: "ច្បារមន",
        latin: "Chbar Mon"
      },
      id: "21020508"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21020509"
    },
    {
      name: {
        km: "ខ្នារទង់",
        latin: "Khnar Tong"
      },
      id: "21020510"
    },
    {
      name: {
        km: "ព្រៃស្លែង",
        latin: "Prey Slaeng"
      },
      id: "21020511"
    },
    {
      name: {
        km: "កញ្ចាង",
        latin: "Kanhchang"
      },
      id: "21020512"
    },
    {
      name: {
        km: "កញ្ជុំ",
        latin: "Kanhchum"
      },
      id: "21020513"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ",
        latin: "Trapeang Pou"
      },
      id: "21020601"
    },
    {
      name: {
        km: "ស្រីគ្រងរាជ្យ",
        latin: "Srei Krong Reach"
      },
      id: "21020602"
    },
    {
      name: {
        km: "ព្រៃត្រាច",
        latin: "Prey Trach"
      },
      id: "21020603"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "21020604"
    },
    {
      name: {
        km: "តាប៉ែន",
        latin: "Ta Paen"
      },
      id: "21020605"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "21020606"
    },
    {
      name: {
        km: "មានជោគ",
        latin: "Mean Chouk"
      },
      id: "21020607"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "21020608"
    },
    {
      name: {
        km: "ត្រាចកុះ",
        latin: "Trach Koh"
      },
      id: "21020609"
    },
    {
      name: {
        km: "ត្រាំក្បាល់",
        latin: "Tram Kbal"
      },
      id: "21020610"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "21020611"
    },
    {
      name: {
        km: "ត្រពាំងអង្គ",
        latin: "Trapeang Angk"
      },
      id: "21020612"
    },
    {
      name: {
        km: "ដើមស្វាយ",
        latin: "Daeum Svay"
      },
      id: "21020613"
    },
    {
      name: {
        km: "ព្រហូត",
        latin: "Prohut"
      },
      id: "21020614"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21020615"
    },
    {
      name: {
        km: "ទួលស្លែង",
        latin: "Tuol Slaeng"
      },
      id: "21020616"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "21020617"
    },
    {
      name: {
        km: "ក្រាំងលាវ",
        latin: "Krang Leav"
      },
      id: "21020618"
    },
    {
      name: {
        km: "សៀមដេក",
        latin: "Siem Dek"
      },
      id: "21020619"
    },
    {
      name: {
        km: "ព្រៃជន្លួញ",
        latin: "Prey Chonluonh"
      },
      id: "21020620"
    },
    {
      name: {
        km: "ត្រាំសសរ",
        latin: "Tram Sasar"
      },
      id: "21020621"
    },
    {
      name: {
        km: "ថ្នល់ដាច់",
        latin: "Thnal Dach"
      },
      id: "21020622"
    },
    {
      name: {
        km: "បាញ់ខ្លា",
        latin: "Banh Khla"
      },
      id: "21020623"
    },
    {
      name: {
        km: "ហនុមាន",
        latin: "Haknuman"
      },
      id: "21020701"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "21020702"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "21020703"
    },
    {
      name: {
        km: "ថ្នល់ទក្សិណ",
        latin: "Thnal Teaksen"
      },
      id: "21020704"
    },
    {
      name: {
        km: "ជ្រោងស្ដៅ",
        latin: "Chroung Sdau"
      },
      id: "21020705"
    },
    {
      name: {
        km: "ត្បូងដំរី",
        latin: "Tboung Damrei"
      },
      id: "21020706"
    },
    {
      name: {
        km: "ក្រាំងធ្នង់",
        latin: "Krang Thnong"
      },
      id: "21020707"
    },
    {
      name: {
        km: "ទន្លេបាទី",
        latin: "Tonle Bati"
      },
      id: "21020708"
    },
    {
      name: {
        km: "ក្រាំងធំ",
        latin: "Krang Thum"
      },
      id: "21020801"
    },
    {
      name: {
        km: "ត្រយឹងខ្ពស់",
        latin: "Trayueng Khpos"
      },
      id: "21020802"
    },
    {
      name: {
        km: "បាក់រនាស់",
        latin: "Bak Ronoas"
      },
      id: "21020803"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡាញ់",
        latin: "Trapeang Kralanh"
      },
      id: "21020804"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21020805"
    },
    {
      name: {
        km: "ពូនភ្នំ",
        latin: "Pun Phnum"
      },
      id: "21020806"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "21020807"
    },
    {
      name: {
        km: "ពានមាសខាងកើត",
        latin: "Pean Meas Khang Kaeut"
      },
      id: "21020808"
    },
    {
      name: {
        km: "ថ្មស",
        latin: "Thma Sa"
      },
      id: "21020809"
    },
    {
      name: {
        km: "ត្រពាំងគ្រួស",
        latin: "Trapeang Kruos"
      },
      id: "21020810"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡោង",
        latin: "Trapeang Kralaong"
      },
      id: "21020811"
    },
    {
      name: {
        km: "ពានមាសខាងលិច",
        latin: "Pean Meas Khang Lech"
      },
      id: "21020812"
    },
    {
      name: {
        km: "តាំងញាតិ",
        latin: "Tang Nheat"
      },
      id: "21020901"
    },
    {
      name: {
        km: "ពារាម",
        latin: "Pea Ream"
      },
      id: "21020902"
    },
    {
      name: {
        km: "ខ្នារធំ",
        latin: "Khnar Thum"
      },
      id: "21020903"
    },
    {
      name: {
        km: "ខ្នាររោង",
        latin: "Khnar Roung"
      },
      id: "21020904"
    },
    {
      name: {
        km: "ត្រពាំងយោង",
        latin: "Trapeang Young"
      },
      id: "21020905"
    },
    {
      name: {
        km: "ជំរៅ",
        latin: "Chumrov"
      },
      id: "21020906"
    },
    {
      name: {
        km: "ក្រាំងក្រចាង",
        latin: "Krang Krachang"
      },
      id: "21020907"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "21020908"
    },
    {
      name: {
        km: "ព្រៃស្វា",
        latin: "Prey Sva"
      },
      id: "21021001"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "21021002"
    },
    {
      name: {
        km: "ក្រាំងពោធិ៍",
        latin: "Krang Pou"
      },
      id: "21021003"
    },
    {
      name: {
        km: "ត្រពាំងត្រាវ",
        latin: "Trapeang Trav"
      },
      id: "21021004"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "21021005"
    },
    {
      name: {
        km: "ពត់សរ",
        latin: "Pot Sar"
      },
      id: "21021006"
    },
    {
      name: {
        km: "តាំងឫស្សី",
        latin: "Tang Ruessei"
      },
      id: "21021007"
    },
    {
      name: {
        km: "ឃ្លាំងសម្បត្ដិ",
        latin: "Kleang Sambatt"
      },
      id: "21021008"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "21021009"
    },
    {
      name: {
        km: "ខ្វាន់មាស",
        latin: "Khvan Meas"
      },
      id: "21021010"
    },
    {
      name: {
        km: "ខ្លាកូន",
        latin: "Khla Koun"
      },
      id: "21021011"
    },
    {
      name: {
        km: "ចំប៉ា",
        latin: "Champa"
      },
      id: "21021101"
    },
    {
      name: {
        km: "ត្រាំខ្នារ",
        latin: "Tram Khnar"
      },
      id: "21021102"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "21021103"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "21021104"
    },
    {
      name: {
        km: "ព្រហែក",
        latin: "Prohaek"
      },
      id: "21021105"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21021106"
    },
    {
      name: {
        km: "ជើងកូត",
        latin: "Cheung Kout"
      },
      id: "21021107"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "21021108"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "21021201"
    },
    {
      name: {
        km: "អង្គក្រឡាញ់",
        latin: "Angk Kralanh"
      },
      id: "21021202"
    },
    {
      name: {
        km: "អំពែទុំ",
        latin: "Ampeae Tum"
      },
      id: "21021203"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "21021204"
    },
    {
      name: {
        km: "ព្រៃក្រាយ",
        latin: "Prey Kray"
      },
      id: "21021205"
    },
    {
      name: {
        km: "ផ្សារគុម្ពឫស្សី",
        latin: "Phsar Kump Ruessei"
      },
      id: "21021206"
    },
    {
      name: {
        km: "សំរោងជ្រៃ",
        latin: "Samraong Chrey"
      },
      id: "21021207"
    },
    {
      name: {
        km: "ទួលល្ហុង",
        latin: "Tuol Lhong"
      },
      id: "21021208"
    },
    {
      name: {
        km: "នាល",
        latin: "Neal"
      },
      id: "21021209"
    },
    {
      name: {
        km: "តាំងដូង",
        latin: "Tang Doung"
      },
      id: "21021210"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "21021301"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "21021302"
    },
    {
      name: {
        km: "តានប់",
        latin: "Ta Nob"
      },
      id: "21021303"
    },
    {
      name: {
        km: "តានន",
        latin: "Ta Non"
      },
      id: "21021304"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21021305"
    },
    {
      name: {
        km: "ពៃ",
        latin: "Pey"
      },
      id: "21021306"
    },
    {
      name: {
        km: "ព្រៃជប់",
        latin: "Prey Chob"
      },
      id: "21021307"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "21021308"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "21021401"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "21021402"
    },
    {
      name: {
        km: "រលាំង",
        latin: "Roleang"
      },
      id: "21021403"
    },
    {
      name: {
        km: "ត្រពាំងព្រិច",
        latin: "Trapeang Prich"
      },
      id: "21021404"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "21021405"
    },
    {
      name: {
        km: "ត្រពាំងគៀប",
        latin: "Trapeang Kieb"
      },
      id: "21021406"
    },
    {
      name: {
        km: "ត្នោតជ្រុំ",
        latin: "Tnaot Chrum"
      },
      id: "21021407"
    },
    {
      name: {
        km: "គោដួល",
        latin: "Kou Duol"
      },
      id: "21021408"
    },
    {
      name: {
        km: "រំដួល",
        latin: "Rumduol"
      },
      id: "21021409"
    },
    {
      name: {
        km: "ម្រះព្រៅ",
        latin: "Mreah Prov"
      },
      id: "21021410"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "21021411"
    },
    {
      name: {
        km: "យាមខៅ",
        latin: "Yeam Khau"
      },
      id: "21021412"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "21021413"
    },
    {
      name: {
        km: "រកាពក",
        latin: "Roka Pok"
      },
      id: "21021414"
    },
    {
      name: {
        km: "ត្រាំសសរ",
        latin: "Tram Sasar"
      },
      id: "21021415"
    },
    {
      name: {
        km: "បឹងពញ្ញាគុក",
        latin: "Boeng Ponhea Kuk"
      },
      id: "21021416"
    },
    {
      name: {
        km: "ត្រពាំងចំការ",
        latin: "Trapeang Chamkar"
      },
      id: "21021417"
    },
    {
      name: {
        km: "ពូនភ្នំ",
        latin: "Pun Phnum"
      },
      id: "21021501"
    },
    {
      name: {
        km: "ត្រពាំងទឹម",
        latin: "Trapeang Tuem"
      },
      id: "21021503"
    },
    {
      name: {
        km: "រកាខ្ពស់",
        latin: "Roka Khpos"
      },
      id: "21021504"
    },
    {
      name: {
        km: "ខ្សាច់លប់",
        latin: "Khsach Lob"
      },
      id: "21021505"
    },
    {
      name: {
        km: "រលាំងគ្រើល",
        latin: "Roleang Kreul"
      },
      id: "21021506"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "21021507"
    },
    {
      name: {
        km: "ត្រពាំងសាប",
        latin: "Trapeang Sab"
      },
      id: "21021508"
    },
    {
      name: {
        km: "តាស៊ូ",
        latin: "Ta Su"
      },
      id: "21021509"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "21021510"
    },
    {
      name: {
        km: "ស្មៅខ្ញី",
        latin: "Smau Khnhei"
      },
      id: "21021511"
    },
    {
      name: {
        km: "អាជាំង",
        latin: "A Cheang"
      },
      id: "21021512"
    },
    {
      name: {
        km: "ព្រេច",
        latin: "Prech"
      },
      id: "21021513"
    },
    {
      name: {
        km: "ស្ដុកប្រីយ៍",
        latin: "Sdok Prei"
      },
      id: "21021514"
    },
    {
      name: {
        km: "ដើមក្រាយ",
        latin: "Daeum Kray"
      },
      id: "21021515"
    },
    {
      name: {
        km: "ត្រកៀត",
        latin: "Trakiet"
      },
      id: "21021516"
    },
    {
      name: {
        km: "អង្គក្រូច",
        latin: "Angk Krouch"
      },
      id: "21030101"
    },
    {
      name: {
        km: "ស្នាយដួច",
        latin: "Snay Duoch"
      },
      id: "21030102"
    },
    {
      name: {
        km: "កំពង់អំពិល",
        latin: "Kampong Ampil"
      },
      id: "21030103"
    },
    {
      name: {
        km: "អន្លង់នៀន",
        latin: "Anlong Nien"
      },
      id: "21030104"
    },
    {
      name: {
        km: "ក្អែកយំ",
        latin: "k'aek yum"
      },
      id: "21030105"
    },
    {
      name: {
        km: "ដើមគរ",
        latin: "Daeum Kor"
      },
      id: "21030106"
    },
    {
      name: {
        km: "ព្រែកខ្សាច់",
        latin: "Preaek Khsach"
      },
      id: "21030107"
    },
    {
      name: {
        km: "សង្កែជួរ",
        latin: "Sangkae Chuor"
      },
      id: "21030201"
    },
    {
      name: {
        km: "ជ័យជោគ",
        latin: "Chey Chouk"
      },
      id: "21030202"
    },
    {
      name: {
        km: "គោកបញ្ចា",
        latin: "Kouk Panhcha"
      },
      id: "21030203"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "21030204"
    },
    {
      name: {
        km: "ដីលើក",
        latin: "Dei Leuk"
      },
      id: "21030205"
    },
    {
      name: {
        km: "តារាគមន៍",
        latin: "Tara Kom"
      },
      id: "21030206"
    },
    {
      name: {
        km: "បន្ទាយស្លឹក",
        latin: "Banteay Sloek"
      },
      id: "21030207"
    },
    {
      name: {
        km: "តាសៃ",
        latin: "Ta Sai"
      },
      id: "21030301"
    },
    {
      name: {
        km: "សូភី",
        latin: "Souphi"
      },
      id: "21030302"
    },
    {
      name: {
        km: "រទេះភ្លូក",
        latin: "Roteh Phluk"
      },
      id: "21030303"
    },
    {
      name: {
        km: "ត្រពាំងទន្លេ",
        latin: "Trapeang Tonle"
      },
      id: "21030304"
    },
    {
      name: {
        km: "អាចម៍ទន្សាយ",
        latin: "Ach Tonsay"
      },
      id: "21030305"
    },
    {
      name: {
        km: "ដូងខ្ពស់",
        latin: "Doung Khpos"
      },
      id: "21030306"
    },
    {
      name: {
        km: "តារស់",
        latin: "Ta Ros"
      },
      id: "21030307"
    },
    {
      name: {
        km: "តាយឹង",
        latin: "Ta Yueng"
      },
      id: "21030308"
    },
    {
      name: {
        km: "ត្រើយឃ្លោក",
        latin: "Traeuy Khlouk"
      },
      id: "21030309"
    },
    {
      name: {
        km: "អង្កាញ់",
        latin: "Angkanh"
      },
      id: "21030310"
    },
    {
      name: {
        km: "ជ្រៃងោក",
        latin: "Chrey Ngouk"
      },
      id: "21030311"
    },
    {
      name: {
        km: "ព្រៃម្លប់",
        latin: "Prey Mlob"
      },
      id: "21030312"
    },
    {
      name: {
        km: "បូរីជលសារ",
        latin: "Borei Cholsar"
      },
      id: "21030401"
    },
    {
      name: {
        km: "កំពង់ក្រសាំង",
        latin: "Kampong Krasang"
      },
      id: "21030402"
    },
    {
      name: {
        km: "ក្ដុលជ្រុំ",
        latin: "Kdol Chrum"
      },
      id: "21030403"
    },
    {
      name: {
        km: "សង្គមមានជ័យ",
        latin: "Sangkom Mean chey"
      },
      id: "21030404"
    },
    {
      name: {
        km: "ថ្មបីដុំ",
        latin: "Thma Bei Dom"
      },
      id: "21030405"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "21030501"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "21030502"
    },
    {
      name: {
        km: "ជ្រៃគោកពោធិ៍",
        latin: "Chrey Kouk Pou"
      },
      id: "21030503"
    },
    {
      name: {
        km: "ព្រៃមូល",
        latin: "Prey Mul"
      },
      id: "21030504"
    },
    {
      name: {
        km: "ព្រៃហៀវ",
        latin: "Prey Hiev"
      },
      id: "21030505"
    },
    {
      name: {
        km: "អ្នកតាត្បាល់",
        latin: "Neak Ta Tbal"
      },
      id: "21030506"
    },
    {
      name: {
        km: "កំពង់យោល",
        latin: "Kampong Youl"
      },
      id: "21030507"
    },
    {
      name: {
        km: "ថ្មស",
        latin: "Thma Sa"
      },
      id: "21030508"
    },
    {
      name: {
        km: "ពោធិ៍តាម៉ុក",
        latin: "Pou Ta Mok"
      },
      id: "21040101"
    },
    {
      name: {
        km: "រនាមត្នោត",
        latin: "Roneam Tnaot"
      },
      id: "21040102"
    },
    {
      name: {
        km: "ប្រជ្រាយ",
        latin: "Prachreay"
      },
      id: "21040103"
    },
    {
      name: {
        km: "អង្គប្រាសាទ",
        latin: "Angk Prasat"
      },
      id: "21040104"
    },
    {
      name: {
        km: "ភ្ងាស",
        latin: "Phngeas"
      },
      id: "21040105"
    },
    {
      name: {
        km: "វត្ដស្វាយ",
        latin: "Voat Svay"
      },
      id: "21040106"
    },
    {
      name: {
        km: "ភ្នំលន្ទះ",
        latin: "Phnum Lonteah"
      },
      id: "21040107"
    },
    {
      name: {
        km: "ទួលស្វាយ",
        latin: "Tuol Svay"
      },
      id: "21040108"
    },
    {
      name: {
        km: "ស្វាយធំ",
        latin: "Svay Thum"
      },
      id: "21040109"
    },
    {
      name: {
        km: "ក្របាក់",
        latin: "Kra Bak"
      },
      id: "21040110"
    },
    {
      name: {
        km: "ពោធិ៍ខ្វិត",
        latin: "Pou Khvet"
      },
      id: "21040201"
    },
    {
      name: {
        km: "កំពង់",
        latin: "Kampong"
      },
      id: "21040202"
    },
    {
      name: {
        km: "ត្រពាំងស្រង់",
        latin: "Trapeang Srang"
      },
      id: "21040203"
    },
    {
      name: {
        km: "ត្រើយទន្លាប់",
        latin: "Traeuy Tonloab"
      },
      id: "21040204"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "21040205"
    },
    {
      name: {
        km: "ពោធិ៍រោង",
        latin: "Pou Roung"
      },
      id: "21040206"
    },
    {
      name: {
        km: "ជ្រោយ",
        latin: "Chrouy"
      },
      id: "21040207"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "21040208"
    },
    {
      name: {
        km: "កំពង់ថ្មី",
        latin: "Kampong Thmey"
      },
      id: "21040209"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "21040301"
    },
    {
      name: {
        km: "ក្រងុល",
        latin: "Krangol"
      },
      id: "21040302"
    },
    {
      name: {
        km: "ដើមស្លែង",
        latin: "Daeum Slaeng"
      },
      id: "21040303"
    },
    {
      name: {
        km: "ខ្មល់",
        latin: "Khmal"
      },
      id: "21040304"
    },
    {
      name: {
        km: "ពោធិ៍សង្កែ",
        latin: "Pou Sangkae"
      },
      id: "21040305"
    },
    {
      name: {
        km: "ចំការទៀប",
        latin: "Chamkar Tieb"
      },
      id: "21040306"
    },
    {
      name: {
        km: "តាពៅ",
        latin: "Ta Pov"
      },
      id: "21040401"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "21040402"
    },
    {
      name: {
        km: "វត្ដភ្នំ",
        latin: "Voat Phnum"
      },
      id: "21040403"
    },
    {
      name: {
        km: "អាងខ្ចៅ",
        latin: "Ang Khchau"
      },
      id: "21040404"
    },
    {
      name: {
        km: "ជីម្រាក់",
        latin: "Chi Mreak"
      },
      id: "21040405"
    },
    {
      name: {
        km: "ហាន់ទា",
        latin: "Han Tea"
      },
      id: "21040406"
    },
    {
      name: {
        km: "អណ្ដូងជ្រុង",
        latin: "Andoung Chrung"
      },
      id: "21040407"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "21040408"
    },
    {
      name: {
        km: "តាមែង",
        latin: "Ta Meaeng"
      },
      id: "21040409"
    },
    {
      name: {
        km: "ស្វាយវល្លិ",
        latin: "Svay Voa"
      },
      id: "21040410"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "21040411"
    },
    {
      name: {
        km: "ពោធិ៍បាយ",
        latin: "Pou Bay"
      },
      id: "21040412"
    },
    {
      name: {
        km: "ច្រកជើគាំ",
        latin: "Chrak Cheu Koam"
      },
      id: "21040413"
    },
    {
      name: {
        km: "ចំបក់ទឹម",
        latin: "Chambak Tuem"
      },
      id: "21040501"
    },
    {
      name: {
        km: "ព្រៃតាម៉ៅ",
        latin: "Prey Ta Mau"
      },
      id: "21040502"
    },
    {
      name: {
        km: "ដើមបេង",
        latin: "Daeum Beng"
      },
      id: "21040503"
    },
    {
      name: {
        km: "ព្រាល",
        latin: "Preal"
      },
      id: "21040504"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "21040505"
    },
    {
      name: {
        km: "ជ្រោយស្លែង",
        latin: "Chrouy Slaeng"
      },
      id: "21040506"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "21040601"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "21040602"
    },
    {
      name: {
        km: "គោកព្រេច",
        latin: "Kouk Prech"
      },
      id: "21040603"
    },
    {
      name: {
        km: "ជីឃ្មល់",
        latin: "Chi Khmol"
      },
      id: "21040604"
    },
    {
      name: {
        km: "ឈើនៀងខ្ពស់",
        latin: "Chheu Nieng Khpos"
      },
      id: "21040605"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "21040606"
    },
    {
      name: {
        km: "សំរោងខាងកើត",
        latin: "Samraong Khang Kaeut"
      },
      id: "21040607"
    },
    {
      name: {
        km: "សំរោងខាងលិច",
        latin: "Samraong Khang Lech"
      },
      id: "21040608"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "21040609"
    },
    {
      name: {
        km: "អណ្ដូងធំ",
        latin: "Andoung Thum"
      },
      id: "21040610"
    },
    {
      name: {
        km: "គោកគ្រួស",
        latin: "Kouk Kruos"
      },
      id: "21040611"
    },
    {
      name: {
        km: "ព្រៃជើង",
        latin: "Prey Cheung"
      },
      id: "21040612"
    },
    {
      name: {
        km: "បាម",
        latin: "Bam"
      },
      id: "21040613"
    },
    {
      name: {
        km: "ផ្សារ",
        latin: "Phsar"
      },
      id: "21040701"
    },
    {
      name: {
        km: "តាឡឹង",
        latin: "Ta Loeng"
      },
      id: "21040702"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "21040703"
    },
    {
      name: {
        km: "តារុង",
        latin: "Ta Rung"
      },
      id: "21040704"
    },
    {
      name: {
        km: "ជ្វា",
        latin: "Chvea"
      },
      id: "21040705"
    },
    {
      name: {
        km: "អណ្ដូងគៀន",
        latin: "Andoung Kien"
      },
      id: "21040706"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "21040707"
    },
    {
      name: {
        km: "ទទឹង",
        latin: "Totueng"
      },
      id: "21040708"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21040709"
    },
    {
      name: {
        km: "ព្រៃលៀប",
        latin: "Prey Lieb"
      },
      id: "21040801"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "21040802"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "21040803"
    },
    {
      name: {
        km: "អំរ៉ែ",
        latin: "Amrae"
      },
      id: "21040804"
    },
    {
      name: {
        km: "សុបិណ",
        latin: "Soben"
      },
      id: "21040805"
    },
    {
      name: {
        km: "ព្រៃអំពក",
        latin: "Prey Ampok"
      },
      id: "21040806"
    },
    {
      name: {
        km: "ថ្នល់លោក",
        latin: "Thnal Louk"
      },
      id: "21040807"
    },
    {
      name: {
        km: "ពុម្ពអិដ្ឋ",
        latin: "Pump Edth"
      },
      id: "21040808"
    },
    {
      name: {
        km: "ឈើទាលភ្លោះ",
        latin: "Chheu Teal Phluoh"
      },
      id: "21040809"
    },
    {
      name: {
        km: "ភ្នំក្រពើ",
        latin: "Phnum Krapeu"
      },
      id: "21040901"
    },
    {
      name: {
        km: "បួរ",
        latin: "Buor"
      },
      id: "21040902"
    },
    {
      name: {
        km: "ចំរេះ",
        latin: "Chamreh"
      },
      id: "21040903"
    },
    {
      name: {
        km: "ដំណាក់ថ្ងាន់",
        latin: "Damnak Thngan"
      },
      id: "21040904"
    },
    {
      name: {
        km: "បពល",
        latin: "Bapol"
      },
      id: "21040905"
    },
    {
      name: {
        km: "ត្រពាំងជ័យ",
        latin: "Trapeang Chey"
      },
      id: "21040906"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "21040907"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "21040908"
    },
    {
      name: {
        km: "ត្រពាំងពិដោរ",
        latin: "Trapeang Pidaor"
      },
      id: "21040909"
    },
    {
      name: {
        km: "ព្រៃរំដេង",
        latin: "Prey Rumdeng"
      },
      id: "21040910"
    },
    {
      name: {
        km: "បឹងទំនប់",
        latin: "Boeng Tumnob"
      },
      id: "21040911"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "21041001"
    },
    {
      name: {
        km: "ត្រយឹង",
        latin: "Trayueng"
      },
      id: "21041002"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "21041003"
    },
    {
      name: {
        km: "រាមអណ្ដើក",
        latin: "Ream Andaeuk"
      },
      id: "21041004"
    },
    {
      name: {
        km: "ត្រពាំងខ្ចៅ",
        latin: "Trapeang Khchau"
      },
      id: "21041005"
    },
    {
      name: {
        km: "កោះកុសល",
        latin: "Kaoh Kosal"
      },
      id: "21041006"
    },
    {
      name: {
        km: "គោករកា",
        latin: "Kouk Roka"
      },
      id: "21041007"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "21041008"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "21041101"
    },
    {
      name: {
        km: "ស្រែឃ្មួញ",
        latin: "Srae Khmuonh"
      },
      id: "21041102"
    },
    {
      name: {
        km: "ស្រែកែស",
        latin: "Srae Kaes"
      },
      id: "21041103"
    },
    {
      name: {
        km: "ព្រាល",
        latin: "Preal"
      },
      id: "21041104"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21041105"
    },
    {
      name: {
        km: "ពីងពង់",
        latin: "Ping Pong"
      },
      id: "21041106"
    },
    {
      name: {
        km: "សោម",
        latin: "Saom"
      },
      id: "21041107"
    },
    {
      name: {
        km: "ដើមរំដែល",
        latin: "Daeum Rumdael"
      },
      id: "21041108"
    },
    {
      name: {
        km: "ដើមអង្កោល",
        latin: "Daeum Angkaol"
      },
      id: "21041109"
    },
    {
      name: {
        km: "នាសារគិរី",
        latin: "Neasar Kiri"
      },
      id: "21041110"
    },
    {
      name: {
        km: "ទន្លាប់",
        latin: "Tonloab"
      },
      id: "21041111"
    },
    {
      name: {
        km: "ត្រពាំងពងទឹក",
        latin: "Trapeang Pong Tuek"
      },
      id: "21041112"
    },
    {
      name: {
        km: "ក្រសាំងពុល",
        latin: "Krasang Pul"
      },
      id: "21041201"
    },
    {
      name: {
        km: "រលៀក",
        latin: "Roliek"
      },
      id: "21041202"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "21041203"
    },
    {
      name: {
        km: "ដោម",
        latin: "Daom"
      },
      id: "21041204"
    },
    {
      name: {
        km: "ជាវប្ដីខាងកើត",
        latin: "Cheav Bdei Khang Kaeut"
      },
      id: "21041205"
    },
    {
      name: {
        km: "ជាវប្ដីខាងលិច",
        latin: "Cheav Bdei Khang Lech"
      },
      id: "21041206"
    },
    {
      name: {
        km: "គ្រាំងទ្រមូង",
        latin: "Krang Tro Mung"
      },
      id: "21041207"
    },
    {
      name: {
        km: "តាអូរខាងជើង",
        latin: "Ta Ou Khang Cheung"
      },
      id: "21041208"
    },
    {
      name: {
        km: "តាអូរខាងត្បូង",
        latin: "Ta Ou Khang Tboung"
      },
      id: "21041209"
    },
    {
      name: {
        km: "ដើមដូង",
        latin: "Daeum Doung"
      },
      id: "21050101"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "21050102"
    },
    {
      name: {
        km: "ក្រពុំឈូក",
        latin: "Krapum Chhuk"
      },
      id: "21050103"
    },
    {
      name: {
        km: "ព្រៃម្លូ",
        latin: "Prey Mlu"
      },
      id: "21050104"
    },
    {
      name: {
        km: "ពាន្នី",
        latin: "Peani"
      },
      id: "21050105"
    },
    {
      name: {
        km: "តាពរ",
        latin: "Ta Por"
      },
      id: "21050106"
    },
    {
      name: {
        km: "ត្រពាំងតាអ៊ុយ",
        latin: "Trapeang Ta Uy"
      },
      id: "21050107"
    },
    {
      name: {
        km: "កោះមាន់",
        latin: "Kaoh Moan"
      },
      id: "21050108"
    },
    {
      name: {
        km: "វត្ដស្លា",
        latin: "Voat Sla"
      },
      id: "21050109"
    },
    {
      name: {
        km: "ជ្រោយពោន",
        latin: "Chrouy Poun"
      },
      id: "21050110"
    },
    {
      name: {
        km: "ខ្លាគ្រហឹម ក",
        latin: "Khla Krohuem Ka"
      },
      id: "21050111"
    },
    {
      name: {
        km: "ខ្លាគ្រហឹម ខ",
        latin: "Khla Krohuem Kha"
      },
      id: "21050112"
    },
    {
      name: {
        km: "ត្រពាំងទន្លេ",
        latin: "Trapeang Tonle"
      },
      id: "21050113"
    },
    {
      name: {
        km: "តាបួ",
        latin: "Ta Buo"
      },
      id: "21050201"
    },
    {
      name: {
        km: "ជន្ទល់មេឃ",
        latin: "Chontol Mekh"
      },
      id: "21050202"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "21050203"
    },
    {
      name: {
        km: "ដំណាក់",
        latin: "Damnak"
      },
      id: "21050204"
    },
    {
      name: {
        km: "ព្រៃបាយ",
        latin: "Prey Bay"
      },
      id: "21050205"
    },
    {
      name: {
        km: "គោកដូង",
        latin: "Kouk Doung"
      },
      id: "21050206"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "21050207"
    },
    {
      name: {
        km: "តាមោក",
        latin: "Ta Mouk"
      },
      id: "21050208"
    },
    {
      name: {
        km: "ពោធិ",
        latin: "Pou"
      },
      id: "21050209"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "21050210"
    },
    {
      name: {
        km: "ពេជសារ",
        latin: "Pech Sar"
      },
      id: "21050211"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "21050212"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "21050213"
    },
    {
      name: {
        km: "ចុងអង្ករ",
        latin: "Chong Angkar"
      },
      id: "21050214"
    },
    {
      name: {
        km: "អង្គុញ",
        latin: "Angkunh"
      },
      id: "21050215"
    },
    {
      name: {
        km: "តាបស",
        latin: "Ta Baoh"
      },
      id: "21050216"
    },
    {
      name: {
        km: "គោកខ្ពស់",
        latin: "Kouk Khpos"
      },
      id: "21050217"
    },
    {
      name: {
        km: "ស្រម៉",
        latin: "Srama"
      },
      id: "21050301"
    },
    {
      name: {
        km: "ស្រែបឹង",
        latin: "Srae Boeng"
      },
      id: "21050302"
    },
    {
      name: {
        km: "ព្រៃមេលងខាងត្បូង",
        latin: "Prey Melong Khang Tboung"
      },
      id: "21050303"
    },
    {
      name: {
        km: "ព្រៃមេលងខាងជើង",
        latin: "Prey Melong Khang Cheung"
      },
      id: "21050304"
    },
    {
      name: {
        km: "កន្សោមអក",
        latin: "Kansaom Ak"
      },
      id: "21050305"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "21050306"
    },
    {
      name: {
        km: "សំភ្លី",
        latin: "Samphli"
      },
      id: "21050307"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "21050308"
    },
    {
      name: {
        km: "តាំងរាសី",
        latin: "Tang Reasei"
      },
      id: "21050309"
    },
    {
      name: {
        km: "ព្រៃខ្លាខាងជើង",
        latin: "Prey Khla Khang Cheung"
      },
      id: "21050310"
    },
    {
      name: {
        km: "ព្រៃខ្លាខាងត្បូង",
        latin: "Prey Khla Khang Tboung"
      },
      id: "21050311"
    },
    {
      name: {
        km: "បន្ទាយធ្លាយ",
        latin: "Banteay Thleay"
      },
      id: "21050312"
    },
    {
      name: {
        km: "កែវកាំភ្លើង",
        latin: "Kaev Kamphleung"
      },
      id: "21050313"
    },
    {
      name: {
        km: "ទួលកណ្ដាល",
        latin: "Tuol Kandal"
      },
      id: "21050314"
    },
    {
      name: {
        km: "ជំរំ",
        latin: "Chumrum"
      },
      id: "21050315"
    },
    {
      name: {
        km: "តាញឹម",
        latin: "Ta Nhuem"
      },
      id: "21050401"
    },
    {
      name: {
        km: "ព្រៃបាយ",
        latin: "Prey Bay"
      },
      id: "21050402"
    },
    {
      name: {
        km: "តាភិន",
        latin: "Ta Phin"
      },
      id: "21050403"
    },
    {
      name: {
        km: "តាផាន់",
        latin: "Ta Phan"
      },
      id: "21050404"
    },
    {
      name: {
        km: "តាហៀន",
        latin: "Ta Hien"
      },
      id: "21050405"
    },
    {
      name: {
        km: "ពងអណ្ដើក",
        latin: "Pong Andaeuk"
      },
      id: "21050406"
    },
    {
      name: {
        km: "រមេញខាងត្បូង",
        latin: "Romenh Khang Tboung"
      },
      id: "21050501"
    },
    {
      name: {
        km: "រមេញខាងជើង",
        latin: "Romenh Khang Cheung"
      },
      id: "21050502"
    },
    {
      name: {
        km: "ដើមចាន់",
        latin: "Daeum Chan"
      },
      id: "21050503"
    },
    {
      name: {
        km: "មានាគ",
        latin: "Mea Neak"
      },
      id: "21050504"
    },
    {
      name: {
        km: "ដើមក្រូច",
        latin: "Daeum Krouch"
      },
      id: "21050505"
    },
    {
      name: {
        km: "ប្រឡាយមាស",
        latin: "Pralay Meas"
      },
      id: "21050506"
    },
    {
      name: {
        km: "ចំបក់ឯម",
        latin: "Chambak Aem"
      },
      id: "21050507"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "21050508"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "21050509"
    },
    {
      name: {
        km: "ដើមពោធិ",
        latin: "Daeum Pou"
      },
      id: "21050510"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "21050601"
    },
    {
      name: {
        km: "លៀប",
        latin: "Lieb"
      },
      id: "21050602"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "21050603"
    },
    {
      name: {
        km: "អណ្ដូងសំរិទ្ធិ",
        latin: "Andoung Samretth"
      },
      id: "21050604"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "21050605"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "21050606"
    },
    {
      name: {
        km: "ស៊ីស្លា",
        latin: "Si Sla"
      },
      id: "21050607"
    },
    {
      name: {
        km: "ត្រពាំងរកា",
        latin: "Trapeang Roka"
      },
      id: "21060101"
    },
    {
      name: {
        km: "បា នយ",
        latin: "Ba Noy"
      },
      id: "21060102"
    },
    {
      name: {
        km: "ផ្សារជ្រែ",
        latin: "Phsar Chreae"
      },
      id: "21060103"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "21060104"
    },
    {
      name: {
        km: "ស្វាយពារ",
        latin: "Svay Pear"
      },
      id: "21060105"
    },
    {
      name: {
        km: "អង្កាញ់",
        latin: "Angkanh"
      },
      id: "21060106"
    },
    {
      name: {
        km: "សំរោងម្រះ",
        latin: "Samraong Mreah"
      },
      id: "21060201"
    },
    {
      name: {
        km: "ដូងខ្ពស់",
        latin: "Doung Khpos"
      },
      id: "21060202"
    },
    {
      name: {
        km: "ថ្លុកដូនទុំ",
        latin: "Thlok Doun Tum"
      },
      id: "21060203"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "21060204"
    },
    {
      name: {
        km: "សេដ្ឋី",
        latin: "Sedthei"
      },
      id: "21060205"
    },
    {
      name: {
        km: "ពន្ទង",
        latin: "Pontong"
      },
      id: "21060206"
    },
    {
      name: {
        km: "តាវង់",
        latin: "Ta Vong"
      },
      id: "21060207"
    },
    {
      name: {
        km: "ពន្សាំង",
        latin: "Ponsang"
      },
      id: "21060301"
    },
    {
      name: {
        km: "ឫស្សីថ្មី",
        latin: "Ruessei Thmei"
      },
      id: "21060302"
    },
    {
      name: {
        km: "ជ្រយ",
        latin: "Chroy"
      },
      id: "21060303"
    },
    {
      name: {
        km: "ជំពូព្រឹក្ស",
        latin: "Chumpu Proeks"
      },
      id: "21060304"
    },
    {
      name: {
        km: "ចំប៉ា",
        latin: "Champa"
      },
      id: "21060305"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "21060306"
    },
    {
      name: {
        km: "ដង្ហឹត",
        latin: "Danghoet"
      },
      id: "21060307"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "21060308"
    },
    {
      name: {
        km: "រនាមពេជ្រ",
        latin: "Roneam Pechr"
      },
      id: "21060309"
    },
    {
      name: {
        km: "អំពិលលិច",
        latin: "Ampil Lech"
      },
      id: "21060401"
    },
    {
      name: {
        km: "អំពិលកើត",
        latin: "Ampil Kaeut"
      },
      id: "21060402"
    },
    {
      name: {
        km: "ស្រែពោធិ",
        latin: "Srae Pou"
      },
      id: "21060403"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "21060404"
    },
    {
      name: {
        km: "ច័ន្ទមង្គល",
        latin: "Chant Mongkol"
      },
      id: "21060405"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "21060406"
    },
    {
      name: {
        km: "បាំងបាត់",
        latin: "Bang Bat"
      },
      id: "21060407"
    },
    {
      name: {
        km: "ស្វាយចាល់",
        latin: "Svay Chal"
      },
      id: "21060408"
    },
    {
      name: {
        km: "អង្គស្វាយចេក",
        latin: "Angk Svay Chek"
      },
      id: "21060409"
    },
    {
      name: {
        km: "តាមូង",
        latin: "Ta Mung"
      },
      id: "21060501"
    },
    {
      name: {
        km: "ចង្កើប",
        latin: "Changkaeub"
      },
      id: "21060502"
    },
    {
      name: {
        km: "តាឡូង",
        latin: "Ta Loung"
      },
      id: "21060503"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21060504"
    },
    {
      name: {
        km: "កំពែងធំ",
        latin: "Kampeaeng Thum"
      },
      id: "21060505"
    },
    {
      name: {
        km: "កំពែងត្បូង",
        latin: "Kampeaeng Tboung"
      },
      id: "21060506"
    },
    {
      name: {
        km: "អង្គជ្រូក",
        latin: "Angk Chruk"
      },
      id: "21060507"
    },
    {
      name: {
        km: "ចំណោម",
        latin: "Chamnaom"
      },
      id: "21060508"
    },
    {
      name: {
        km: "ត្រពាំងអង្គ",
        latin: "Trapeang Angk"
      },
      id: "21060509"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "21060601"
    },
    {
      name: {
        km: "កញ្ចិល",
        latin: "Kanhchel"
      },
      id: "21060603"
    },
    {
      name: {
        km: "កំពង់លាវ",
        latin: "Kampong Leav"
      },
      id: "21060604"
    },
    {
      name: {
        km: "កំពង់រាប",
        latin: "Kampong Reab"
      },
      id: "21060605"
    },
    {
      name: {
        km: "កំពង់សាម៉",
        latin: "Kampong Sama"
      },
      id: "21060606"
    },
    {
      name: {
        km: "ក្លែងគង់",
        latin: "Klaeng Kong"
      },
      id: "21060607"
    },
    {
      name: {
        km: "ជំនីកខាងជើង",
        latin: "Chumnik Khang Cheung"
      },
      id: "21060608"
    },
    {
      name: {
        km: "ជំនីកខាងត្បូង",
        latin: "Chumnik Khang Tboung"
      },
      id: "21060609"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "21060701"
    },
    {
      name: {
        km: "សម្ដេចពាន់",
        latin: "Samdach Poan"
      },
      id: "21060702"
    },
    {
      name: {
        km: "ក្ដាញ់",
        latin: "Kdanh"
      },
      id: "21060703"
    },
    {
      name: {
        km: "ក្ប៉ម",
        latin: "Kpam"
      },
      id: "21060704"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "21060705"
    },
    {
      name: {
        km: "ក្រាំងវិច",
        latin: "Krang Vich"
      },
      id: "21060706"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "21060707"
    },
    {
      name: {
        km: "ក្ដីតាហុក",
        latin: "Kdei Ta Hok"
      },
      id: "21060801"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "21060802"
    },
    {
      name: {
        km: "ស្វាយសំរោង",
        latin: "Svay Samraong"
      },
      id: "21060803"
    },
    {
      name: {
        km: "អង្គសង្កែ",
        latin: "Angk Sangkae"
      },
      id: "21060804"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "21060805"
    },
    {
      name: {
        km: "ព្រៃតាពង",
        latin: "Prey Ta Pong"
      },
      id: "21060806"
    },
    {
      name: {
        km: "គោកអង្គង់",
        latin: "Kouk Angkong"
      },
      id: "21060807"
    },
    {
      name: {
        km: "សម៉លាវ",
        latin: "Sama Leav"
      },
      id: "21060808"
    },
    {
      name: {
        km: "សម៉ខ្មែរ",
        latin: "Sama Khmer"
      },
      id: "21060809"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "21060810"
    },
    {
      name: {
        km: "គោកកញ្ចាប",
        latin: "Kouk Kanhchab"
      },
      id: "21060811"
    },
    {
      name: {
        km: "ពេជចង្វា",
        latin: "Pech Changva"
      },
      id: "21060901"
    },
    {
      name: {
        km: "ត្រពាំងក្រូច",
        latin: "Trapeang Krouch"
      },
      id: "21060902"
    },
    {
      name: {
        km: "ព្រៃកប្បាស ក",
        latin: "Prey Kabbas Ka"
      },
      id: "21060903"
    },
    {
      name: {
        km: "ព្រៃកប្បាស ខ",
        latin: "Prey Kabbas Kha"
      },
      id: "21060904"
    },
    {
      name: {
        km: "ព្រៃកប្បាស គ",
        latin: "Prey Kabbas Ko"
      },
      id: "21060905"
    },
    {
      name: {
        km: "សំចត",
        latin: "Samchat"
      },
      id: "21060906"
    },
    {
      name: {
        km: "ព្រៃព្រុំ",
        latin: "Prey Prum"
      },
      id: "21060907"
    },
    {
      name: {
        km: "ដើមពោធិ",
        latin: "Daeum Pou"
      },
      id: "21060908"
    },
    {
      name: {
        km: "អំពិលរៀង",
        latin: "Ampil Rieng"
      },
      id: "21060909"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "21060910"
    },
    {
      name: {
        km: "ព្រៃល្វាកើត",
        latin: "Prey Lvea Kaeut"
      },
      id: "21061001"
    },
    {
      name: {
        km: "ព្រៃល្វាលិច",
        latin: "Prey Lvea Lech"
      },
      id: "21061002"
    },
    {
      name: {
        km: "អន្លុងមាស",
        latin: "Anlong Meas"
      },
      id: "21061003"
    },
    {
      name: {
        km: "តាខុន",
        latin: "Ta Khon"
      },
      id: "21061004"
    },
    {
      name: {
        km: "អង្គក្រសាំង",
        latin: "Angk Krasang"
      },
      id: "21061005"
    },
    {
      name: {
        km: "ល្វាត្នោត",
        latin: "Lvea Tnaot"
      },
      id: "21061006"
    },
    {
      name: {
        km: "ព្រៃល្វាត្បួង",
        latin: "Prey Lvea Tboung"
      },
      id: "21061007"
    },
    {
      name: {
        km: "ព្រៃរបង",
        latin: "Prey Robang"
      },
      id: "21061101"
    },
    {
      name: {
        km: "គោកទ្រា",
        latin: "Kouk Trea"
      },
      id: "21061102"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅត្បូង",
        latin: "Prey Phdau Tboung"
      },
      id: "21061103"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅជើង",
        latin: "Prey Phdau Cheung"
      },
      id: "21061104"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "21061105"
    },
    {
      name: {
        km: "ជុំរំ",
        latin: "Chumrum"
      },
      id: "21061106"
    },
    {
      name: {
        km: "សៃវ៉ា",
        latin: "Saiva"
      },
      id: "21061107"
    },
    {
      name: {
        km: "ព្រៃភ្ញី",
        latin: "Prey Phnhi"
      },
      id: "21061108"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "21061109"
    },
    {
      name: {
        km: "ដុង",
        latin: "Dong"
      },
      id: "21061110"
    },
    {
      name: {
        km: "ស្មន់មុនី",
        latin: "Sman Muni"
      },
      id: "21061111"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "21061201"
    },
    {
      name: {
        km: "ទង្គែ",
        latin: "Tongkeae"
      },
      id: "21061202"
    },
    {
      name: {
        km: "ស្នោ",
        latin: "Snao"
      },
      id: "21061203"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "21061204"
    },
    {
      name: {
        km: "ធម្មវិន័យ",
        latin: "Thomm Viney"
      },
      id: "21061205"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "21061206"
    },
    {
      name: {
        km: "ត្រពាំងទា",
        latin: "Trapeang Tea"
      },
      id: "21061301"
    },
    {
      name: {
        km: "ព្រៃចំបក់",
        latin: "Prey Chambak"
      },
      id: "21061302"
    },
    {
      name: {
        km: "ត្រពាំងស្ដុក",
        latin: "Trapeang Sdok"
      },
      id: "21061303"
    },
    {
      name: {
        km: "ស្នូលខ្ពស់",
        latin: "Snoul Khpos"
      },
      id: "21061304"
    },
    {
      name: {
        km: "ក្រាំងអំពិល",
        latin: "Krang Ampil"
      },
      id: "21061305"
    },
    {
      name: {
        km: "ក្រាំងចំរើន",
        latin: "Krang Chamraeun"
      },
      id: "21061306"
    },
    {
      name: {
        km: "ជំរៅ",
        latin: "Chumrov"
      },
      id: "21061307"
    },
    {
      name: {
        km: "កែវចំរើន",
        latin: "Kaev Chamraeun"
      },
      id: "21061308"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "21061309"
    },
    {
      name: {
        km: "ច្រនៀងខ្ពស់",
        latin: "Chranieng Khpos"
      },
      id: "21061310"
    },
    {
      name: {
        km: "អង្គ រវាយ",
        latin: "Angk Roveay"
      },
      id: "21061311"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "21061312"
    },
    {
      name: {
        km: "ខ្នាចខាងជើង",
        latin: "Khnach Khang Cheung"
      },
      id: "21070101"
    },
    {
      name: {
        km: "ខ្នាចខាងត្បូង",
        latin: "Khnach Khang Tboung"
      },
      id: "21070102"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "21070103"
    },
    {
      name: {
        km: "ពេជឥន្ទ្រា",
        latin: "Pech Entrea"
      },
      id: "21070104"
    },
    {
      name: {
        km: "រមន់",
        latin: "Romon"
      },
      id: "21070105"
    },
    {
      name: {
        km: "បីពៃ",
        latin: "Bei Pey"
      },
      id: "21070106"
    },
    {
      name: {
        km: "ពេជចង្វា",
        latin: "Pech Changva"
      },
      id: "21070107"
    },
    {
      name: {
        km: "អង្គរាំង",
        latin: "Angk Reang"
      },
      id: "21070108"
    },
    {
      name: {
        km: "កូនរមាស",
        latin: "Koun Romeas"
      },
      id: "21070109"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "21070201"
    },
    {
      name: {
        km: "ស្រីជ័យ",
        latin: "Srei Chey"
      },
      id: "21070202"
    },
    {
      name: {
        km: "ខ្នាររុង",
        latin: "Khnar Rung"
      },
      id: "21070203"
    },
    {
      name: {
        km: "បឹងត្រាញ់",
        latin: "Boeng Tranh"
      },
      id: "21070204"
    },
    {
      name: {
        km: "តាសំ",
        latin: "Ta Sam"
      },
      id: "21070205"
    },
    {
      name: {
        km: "មហារាជ",
        latin: "Moha Reach"
      },
      id: "21070206"
    },
    {
      name: {
        km: "ហង់ហេង",
        latin: "Hang Heng"
      },
      id: "21070207"
    },
    {
      name: {
        km: "កំប៉ោរ",
        latin: "Kampaor"
      },
      id: "21070208"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "21070209"
    },
    {
      name: {
        km: "តាដក់ពង",
        latin: "Tadak Pong"
      },
      id: "21070210"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "21070301"
    },
    {
      name: {
        km: "តាមៅ",
        latin: "Ta Mau"
      },
      id: "21070302"
    },
    {
      name: {
        km: "ត្រពាំងវិហារ",
        latin: "Trapeang Vihear"
      },
      id: "21070303"
    },
    {
      name: {
        km: "ត្បាច",
        latin: "Tbach"
      },
      id: "21070304"
    },
    {
      name: {
        km: "តាខូយ",
        latin: "Ta Khouy"
      },
      id: "21070305"
    },
    {
      name: {
        km: "ជើងគួន",
        latin: "Cheung Kuon"
      },
      id: "21070306"
    },
    {
      name: {
        km: "ពន្សាំង",
        latin: "Ponsang"
      },
      id: "21070307"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "21070308"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "21070309"
    },
    {
      name: {
        km: "ក្រាំងឡង",
        latin: "Krang Lang"
      },
      id: "21070310"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "21070311"
    },
    {
      name: {
        km: "ស្រែតាសុខ",
        latin: "Srae Ta Sokh"
      },
      id: "21070401"
    },
    {
      name: {
        km: "ស្វាយរន្ធ",
        latin: "Svay Ron"
      },
      id: "21070402"
    },
    {
      name: {
        km: "ធ្លកដំណាក់ហ្លួង",
        latin: "Thlok Damnak Luong"
      },
      id: "21070403"
    },
    {
      name: {
        km: "ត្រមែង",
        latin: "Tramaeng"
      },
      id: "21070404"
    },
    {
      name: {
        km: "ដំណាក់ត្រយឹង",
        latin: "Damnak Trayueng"
      },
      id: "21070405"
    },
    {
      name: {
        km: "តាយឹង",
        latin: "Ta Yueng"
      },
      id: "21070406"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "21070407"
    },
    {
      name: {
        km: "ពន្លឺ",
        latin: "Ponlueu"
      },
      id: "21070408"
    },
    {
      name: {
        km: "កន្សោមក្លែង",
        latin: "Kansaom Khlaeng"
      },
      id: "21070409"
    },
    {
      name: {
        km: "ត្រពាំងខ្នារ",
        latin: "Trapeang Khnar"
      },
      id: "21070410"
    },
    {
      name: {
        km: "អង្គរផ្ដៀក",
        latin: "Angkor Phdiek"
      },
      id: "21070411"
    },
    {
      name: {
        km: "ជំរះពេន",
        latin: "Chumreah Pen"
      },
      id: "21070412"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "21070413"
    },
    {
      name: {
        km: "ត្រពាំងរំដួល",
        latin: "Trapeang Rumduol"
      },
      id: "21070414"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "21070415"
    },
    {
      name: {
        km: "ត្រពាំងចំបក់",
        latin: "Trapeang Chambak"
      },
      id: "21070416"
    },
    {
      name: {
        km: "ព្រៃនាងពួន",
        latin: "Prey Neang Puon"
      },
      id: "21070417"
    },
    {
      name: {
        km: "ស្នែងរមាំង",
        latin: "Snaeng Romeang"
      },
      id: "21070418"
    },
    {
      name: {
        km: "ព្រៃស្នួល",
        latin: "Prey Snuol"
      },
      id: "21070419"
    },
    {
      name: {
        km: "លាក់រទេះ",
        latin: "Leak Roteh"
      },
      id: "21070501"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "21070502"
    },
    {
      name: {
        km: "ព្រៃជ័រ",
        latin: "Prey Choar"
      },
      id: "21070503"
    },
    {
      name: {
        km: "វះពោះ",
        latin: "Veah Puoh"
      },
      id: "21070504"
    },
    {
      name: {
        km: "ត្រពាំងឃ្លោក",
        latin: "Trapeang Khlouk"
      },
      id: "21070505"
    },
    {
      name: {
        km: "អង្គុញ",
        latin: "Angkunh"
      },
      id: "21070506"
    },
    {
      name: {
        km: "ត្រាំគល់",
        latin: "Tram Kol"
      },
      id: "21070507"
    },
    {
      name: {
        km: "កាប់នឹម",
        latin: "Kab Nuem"
      },
      id: "21070508"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "21070509"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "21070510"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "21070511"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "21070512"
    },
    {
      name: {
        km: "ត្រពាំងធ្នុង",
        latin: "Trapeang Thnong"
      },
      id: "21070513"
    },
    {
      name: {
        km: "ត្រពាំងពួន",
        latin: "Trapeang Puon"
      },
      id: "21070514"
    },
    {
      name: {
        km: "ព្រៃញឹក",
        latin: "Prey Nhuek"
      },
      id: "21070515"
    },
    {
      name: {
        km: "ស្វាយទង",
        latin: "Svay Tong"
      },
      id: "21070516"
    },
    {
      name: {
        km: "ទួលតាចិន",
        latin: "Tuol Ta Chen"
      },
      id: "21070517"
    },
    {
      name: {
        km: "ត្រពាំងត្របែក",
        latin: "Trapeang Trabaek"
      },
      id: "21070518"
    },
    {
      name: {
        km: "លំចង់",
        latin: "Lumchang"
      },
      id: "21070601"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "21070602"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "21070603"
    },
    {
      name: {
        km: "ក្ដុល",
        latin: "Kdol"
      },
      id: "21070604"
    },
    {
      name: {
        km: "ធម្មន័យ",
        latin: "Thommoney"
      },
      id: "21070605"
    },
    {
      name: {
        km: "ទួលទ្រា",
        latin: "Tuol Trea"
      },
      id: "21070606"
    },
    {
      name: {
        km: "តាមូង",
        latin: "Ta Muong"
      },
      id: "21070607"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "21070608"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "21070609"
    },
    {
      name: {
        km: "ប្រសៀត",
        latin: "Prasiet"
      },
      id: "21070610"
    },
    {
      name: {
        km: "ត្រពាំងខ្នារ",
        latin: "Trapeang Khnar"
      },
      id: "21070701"
    },
    {
      name: {
        km: "កុកតារៀ",
        latin: "Kok Ta Rie"
      },
      id: "21070702"
    },
    {
      name: {
        km: "ត្រពាំងទ្រា",
        latin: "Trapeang Trea"
      },
      id: "21070703"
    },
    {
      name: {
        km: "ទឹកអំបិល",
        latin: "Tuek Ambel"
      },
      id: "21070704"
    },
    {
      name: {
        km: "ព្រៃខ្ជាយ",
        latin: "Prey Khcheay"
      },
      id: "21070705"
    },
    {
      name: {
        km: "តោល",
        latin: "Taol"
      },
      id: "21070706"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21070707"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "21070708"
    },
    {
      name: {
        km: "ក្រាំងលាវ",
        latin: "Krang Leav"
      },
      id: "21070709"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21070710"
    },
    {
      name: {
        km: "ត្រពាំងស្ទង",
        latin: "Trapeang Storng"
      },
      id: "21070711"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "21070712"
    },
    {
      name: {
        km: "ចន្លាត់ដៃ",
        latin: "Chanloat Dai"
      },
      id: "21070713"
    },
    {
      name: {
        km: "ស្រមោចហែរ",
        latin: "Sramaoch Haer"
      },
      id: "21070714"
    },
    {
      name: {
        km: "ត្រពាំងស្ដុក",
        latin: "Trapeang Sdok"
      },
      id: "21070715"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "21070716"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "21070717"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "21070718"
    },
    {
      name: {
        km: "ក្រាំងធ្នង់",
        latin: "Krang Thnong"
      },
      id: "21070719"
    },
    {
      name: {
        km: "ព្រៃស្នួល",
        latin: "Prey Snuol"
      },
      id: "21070720"
    },
    {
      name: {
        km: "វាយឈ្នះ",
        latin: "Veay Chhneah"
      },
      id: "21070721"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "21070722"
    },
    {
      name: {
        km: "ដើមធ្លក",
        latin: "Daeum Thlok"
      },
      id: "21070723"
    },
    {
      name: {
        km: "កន្សោមអក",
        latin: "Kansaom Ak"
      },
      id: "21070801"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "21070802"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "21070803"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "21070804"
    },
    {
      name: {
        km: "ទួលចារ",
        latin: "Tuol Char"
      },
      id: "21070805"
    },
    {
      name: {
        km: "តាពោនខាងកើត",
        latin: "Ta Poun Khang Kaeut"
      },
      id: "21070806"
    },
    {
      name: {
        km: "តាពោនខាងលិច",
        latin: "Ta Poun Khang Lech"
      },
      id: "21070807"
    },
    {
      name: {
        km: "ក្រាំងរអូត",
        latin: "Krang Roout"
      },
      id: "21070808"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "21070809"
    },
    {
      name: {
        km: "ឈូកសខាងជើង",
        latin: "Chhuk Sa Khang Cheung"
      },
      id: "21070901"
    },
    {
      name: {
        km: "ឈូកសខាងត្បូង",
        latin: "Chhuk Sa Khang Tboung"
      },
      id: "21070902"
    },
    {
      name: {
        km: "ក្ដី",
        latin: "Kdei"
      },
      id: "21070903"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "21070904"
    },
    {
      name: {
        km: "ត្រពាំងកី",
        latin: "Trapeang Kei"
      },
      id: "21070905"
    },
    {
      name: {
        km: "វែង",
        latin: "Veaeng"
      },
      id: "21070906"
    },
    {
      name: {
        km: "ទន្លាប់",
        latin: "Tonloab"
      },
      id: "21070907"
    },
    {
      name: {
        km: "ប៉ាណា",
        latin: "Pa Na"
      },
      id: "21070908"
    },
    {
      name: {
        km: "កាយបាំង",
        latin: "Kay Bang"
      },
      id: "21070909"
    },
    {
      name: {
        km: "ក្រាំងត្រែង",
        latin: "Krang Traeng"
      },
      id: "21070910"
    },
    {
      name: {
        km: "សឹង្ហ",
        latin: "Soengh"
      },
      id: "21070911"
    },
    {
      name: {
        km: "ត្រពាំងប្រីយ៍",
        latin: "Trapeang Prei"
      },
      id: "21070912"
    },
    {
      name: {
        km: "អង្គក្ដី",
        latin: "Angk Kdei"
      },
      id: "21070913"
    },
    {
      name: {
        km: "ក្រាំងស្ដៅ",
        latin: "Krang Sdau"
      },
      id: "21070914"
    },
    {
      name: {
        km: "ស្លាខាងលិច",
        latin: "Sla Khang Lech"
      },
      id: "21071001"
    },
    {
      name: {
        km: "ស្លាខាងកើត",
        latin: "Sla Khang Kaeut"
      },
      id: "21071002"
    },
    {
      name: {
        km: "អង្គចង្អេរ",
        latin: "Angk Chang'er"
      },
      id: "21071003"
    },
    {
      name: {
        km: "ត្រពាំងត្រាវ",
        latin: "Trapeang Trav"
      },
      id: "21071004"
    },
    {
      name: {
        km: "កន្ទ្រង់ព្រិច",
        latin: "Kantrong Prech"
      },
      id: "21071005"
    },
    {
      name: {
        km: "បឹងកន្ទ្រន់",
        latin: "Boeng Kantron"
      },
      id: "21071006"
    },
    {
      name: {
        km: "ត្រពាំងស្រង់",
        latin: "Trapeang Srang"
      },
      id: "21071007"
    },
    {
      name: {
        km: "ពោធិ",
        latin: "Pou"
      },
      id: "21071008"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "21071009"
    },
    {
      name: {
        km: "កញ្ចាង",
        latin: "Kanhchang"
      },
      id: "21071010"
    },
    {
      name: {
        km: "ស្រីបណ្ឌិត",
        latin: "Srei Bandit"
      },
      id: "21071011"
    },
    {
      name: {
        km: "ស្រីប្រសើរ",
        latin: "Srei Prasaeur"
      },
      id: "21071012"
    },
    {
      name: {
        km: "អារោង",
        latin: "A Roung"
      },
      id: "21071013"
    },
    {
      name: {
        km: "វែង",
        latin: "Veaeng"
      },
      id: "21071101"
    },
    {
      name: {
        km: "ក្បាលសំរោង",
        latin: "Kbal Samraong"
      },
      id: "21071102"
    },
    {
      name: {
        km: "ឫស្សីជុំ",
        latin: "Ruessei Chum"
      },
      id: "21071103"
    },
    {
      name: {
        km: "សែនភាស",
        latin: "Saen Pheas"
      },
      id: "21071104"
    },
    {
      name: {
        km: "ត្នោតទេរ",
        latin: "Tnaot Ter"
      },
      id: "21071105"
    },
    {
      name: {
        km: "ដូនតី",
        latin: "Dountei"
      },
      id: "21071106"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "21071107"
    },
    {
      name: {
        km: "ទ្រាលើ",
        latin: "Trea Leu"
      },
      id: "21071108"
    },
    {
      name: {
        km: "កំពង់ទ្រា",
        latin: "Kampong Trea"
      },
      id: "21071109"
    },
    {
      name: {
        km: "ធ្មា",
        latin: "Thmea"
      },
      id: "21071110"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "21071111"
    },
    {
      name: {
        km: "ធន់មន់ខាងជើង",
        latin: "Thon Mon Khang Cheung"
      },
      id: "21080101"
    },
    {
      name: {
        km: "ធន់មន់ខាងត្បូង",
        latin: "Thon Mon Khang Tboung"
      },
      id: "21080102"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "21080103"
    },
    {
      name: {
        km: "ជ្រោយប្រឃរ",
        latin: "Chrouy Prakhor"
      },
      id: "21080104"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "21080105"
    },
    {
      name: {
        km: "ដូនពែង",
        latin: "Doun Peaeng"
      },
      id: "21080106"
    },
    {
      name: {
        km: "ស្វាយឫស្សី",
        latin: "Svay Ruessei"
      },
      id: "21080107"
    },
    {
      name: {
        km: "ខាន់ខាវ",
        latin: "Khan Khav"
      },
      id: "21080108"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "21080109"
    },
    {
      name: {
        km: "ក្រចាប់",
        latin: "Krachab"
      },
      id: "21080110"
    },
    {
      name: {
        km: "ត្រពាំងលាក់",
        latin: "Trapeang Leak"
      },
      id: "21080111"
    },
    {
      name: {
        km: "ចុងថ្នល់",
        latin: "Chong Thnal"
      },
      id: "21080112"
    },
    {
      name: {
        km: "ក្រាំងតាពូង",
        latin: "Krang Ta Pung"
      },
      id: "21080113"
    },
    {
      name: {
        km: "ជ្រោយសំរោង",
        latin: "Chrouy Samraong"
      },
      id: "21080114"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "21080201"
    },
    {
      name: {
        km: "ផ្សារតាកោ",
        latin: "Phsar Ta Kao"
      },
      id: "21080202"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "21080203"
    },
    {
      name: {
        km: "ខ្សឹង",
        latin: "Khsoeng"
      },
      id: "21080204"
    },
    {
      name: {
        km: "អូរស្វាយចេក",
        latin: "Ou Svay Chek"
      },
      id: "21080205"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "21080206"
    },
    {
      name: {
        km: "ព្រៃព្រហ្ម",
        latin: "Prey Prum"
      },
      id: "21080207"
    },
    {
      name: {
        km: "ឡូរី",
        latin: "Louri"
      },
      id: "21080208"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "21080209"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "21080210"
    },
    {
      name: {
        km: "ភូមិ៣",
        latin: "Phum Bei"
      },
      id: "21080211"
    },
    {
      name: {
        km: "ស្នោរ",
        latin: "Snaor"
      },
      id: "21080212"
    },
    {
      name: {
        km: "ទំនប់",
        latin: "Tumnob"
      },
      id: "21080301"
    },
    {
      name: {
        km: "ត្រាំ",
        latin: "Tram"
      },
      id: "21080302"
    },
    {
      name: {
        km: "ព្រហូត",
        latin: "Prohut"
      },
      id: "21080303"
    },
    {
      name: {
        km: "តាឌូ",
        latin: "Ta Du"
      },
      id: "21080304"
    },
    {
      name: {
        km: "ថ្នល់បែក",
        latin: "Thnal Baek"
      },
      id: "21080305"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21080306"
    },
    {
      name: {
        km: "ត្រពាំងសាលា",
        latin: "Trapeang Sala"
      },
      id: "21080307"
    },
    {
      name: {
        km: "បិនម៉ៅ",
        latin: "Ben Mau"
      },
      id: "21080308"
    },
    {
      name: {
        km: "សូរ្យច័ន្ទ",
        latin: "Souchan"
      },
      id: "21080309"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "21080310"
    },
    {
      name: {
        km: "ព្រេច",
        latin: "Prech"
      },
      id: "21080311"
    },
    {
      name: {
        km: "តុំ",
        latin: "Tom"
      },
      id: "21080312"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "21080313"
    },
    {
      name: {
        km: "ត្រពាំងអង្គ",
        latin: "Trapeang Angk"
      },
      id: "21080314"
    },
    {
      name: {
        km: "ស្រុកចេក",
        latin: "Srok Chek"
      },
      id: "21090101"
    },
    {
      name: {
        km: "ព្រៃដំរី",
        latin: "Prey Damrei"
      },
      id: "21090102"
    },
    {
      name: {
        km: "ព្រៃស្រោង",
        latin: "Prey Sraong"
      },
      id: "21090103"
    },
    {
      name: {
        km: "ព្រៃឈើទាលលើ",
        latin: "Prey Chheu Teal Leu"
      },
      id: "21090104"
    },
    {
      name: {
        km: "ឈើទាលប្រគាប",
        latin: "Chheu Teal Prakeab"
      },
      id: "21090105"
    },
    {
      name: {
        km: "ព្រៃឈើទាលក្រោម",
        latin: "Prey Chheu Teal Kraom"
      },
      id: "21090106"
    },
    {
      name: {
        km: "អង្គតាសោម",
        latin: "Angk Ta Saom"
      },
      id: "21090107"
    },
    {
      name: {
        km: "ត្រពាំងខ្នារ",
        latin: "Trapeang Khnar"
      },
      id: "21090108"
    },
    {
      name: {
        km: "សេកយា",
        latin: "Sek Yea"
      },
      id: "21090109"
    },
    {
      name: {
        km: "អូរផុត",
        latin: "Ou Phot"
      },
      id: "21090110"
    },
    {
      name: {
        km: "ខ្នាទៃ",
        latin: "Khna Tey"
      },
      id: "21090111"
    },
    {
      name: {
        km: "ព្រៃរំដេង",
        latin: "Prey Rumdeng"
      },
      id: "21090112"
    },
    {
      name: {
        km: "ត្រពាំងចែង",
        latin: "Trapeang Chaeng"
      },
      id: "21090113"
    },
    {
      name: {
        km: "អង្គត្នោតលិច",
        latin: "Angk Tnaot Khang Lech"
      },
      id: "21090114"
    },
    {
      name: {
        km: "ត្រពាំងស្រងែ",
        latin: "Trapeang Srangae"
      },
      id: "21090115"
    },
    {
      name: {
        km: "អង្គត្នោតកើត",
        latin: "Angk Tnaot Khang Kaeut"
      },
      id: "21090116"
    },
    {
      name: {
        km: "ចំការដៀប",
        latin: "Chamkar Dieb"
      },
      id: "21090117"
    },
    {
      name: {
        km: "ស្មន់ព្រាម",
        latin: "Sman Pream"
      },
      id: "21090118"
    },
    {
      name: {
        km: "ត្រពាំងត្របែក",
        latin: "Trapeang Trabaek"
      },
      id: "21090119"
    },
    {
      name: {
        km: "ព្រៃព្រាយ",
        latin: "Prey Peay"
      },
      id: "21090120"
    },
    {
      name: {
        km: "ត្រពាំងខ្លូត",
        latin: "Trapeang Khlout"
      },
      id: "21090121"
    },
    {
      name: {
        km: "អង្គរមឿង",
        latin: "Angkor Moeang"
      },
      id: "21090122"
    },
    {
      name: {
        km: "ស្រែខ្វាវ",
        latin: "Srae Khvav"
      },
      id: "21090201"
    },
    {
      name: {
        km: "តារាប",
        latin: "Ta Reab"
      },
      id: "21090202"
    },
    {
      name: {
        km: "អង្គក្រឡាញ់",
        latin: "Angk Kralanh"
      },
      id: "21090203"
    },
    {
      name: {
        km: "អង្គបក្សី",
        latin: "Angk Baksei"
      },
      id: "21090204"
    },
    {
      name: {
        km: "ទួលរកា",
        latin: "Tuol Roka"
      },
      id: "21090205"
    },
    {
      name: {
        km: "ត្រពាំងស្រង៉ែ",
        latin: "Trapeang Srangae"
      },
      id: "21090206"
    },
    {
      name: {
        km: "ទទឹងថ្ងៃ",
        latin: "Totueng Thngai"
      },
      id: "21090207"
    },
    {
      name: {
        km: "ត្រពាំងទឹក",
        latin: "Trapeang Tuek"
      },
      id: "21090208"
    },
    {
      name: {
        km: "តាតឹម",
        latin: "Ta Toem"
      },
      id: "21090209"
    },
    {
      name: {
        km: "មឿងចារ",
        latin: "Moeang Char"
      },
      id: "21090210"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "21090211"
    },
    {
      name: {
        km: "សណ្ដោ",
        latin: "Sandao"
      },
      id: "21090212"
    },
    {
      name: {
        km: "ទីប៉ាត់",
        latin: "Ti Pat"
      },
      id: "21090213"
    },
    {
      name: {
        km: "ស្រែគ្រួ",
        latin: "Srae Kruo"
      },
      id: "21090214"
    },
    {
      name: {
        km: "ទួលត្បែង",
        latin: "Tuol Tbaeng"
      },
      id: "21090215"
    },
    {
      name: {
        km: "នមោ",
        latin: "Nomou"
      },
      id: "21090216"
    },
    {
      name: {
        km: "គុសថ្មី",
        latin: "Kus Thmei"
      },
      id: "21090301"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "21090302"
    },
    {
      name: {
        km: "ទំនប់ជ្រៃ",
        latin: "Tumnob Chrey"
      },
      id: "21090303"
    },
    {
      name: {
        km: "ខ្នាចចោរ",
        latin: "Khnach Chaor"
      },
      id: "21090304"
    },
    {
      name: {
        km: "អកពង",
        latin: "Ak Pong"
      },
      id: "21090305"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "21090306"
    },
    {
      name: {
        km: "អង្គក្រឡាញ់",
        latin: "Angk Kralanh"
      },
      id: "21090307"
    },
    {
      name: {
        km: "អង្គតាង៉ិល",
        latin: "Angk Ta Ngel"
      },
      id: "21090308"
    },
    {
      name: {
        km: "ឈើទាលថ្គោល",
        latin: "Chheu Teal Thkoul"
      },
      id: "21090309"
    },
    {
      name: {
        km: "ពងទឹកជើង",
        latin: "Pong Tuek Khang Cheung"
      },
      id: "21090310"
    },
    {
      name: {
        km: "ក្រាំងតាចាន់",
        latin: "Krang Ta Chan"
      },
      id: "21090311"
    },
    {
      name: {
        km: "នៀល",
        latin: "Niel"
      },
      id: "21090312"
    },
    {
      name: {
        km: "ត្រពាំងលាន",
        latin: "Trapeang Lean"
      },
      id: "21090313"
    },
    {
      name: {
        km: "ត្មាតពង",
        latin: "Tmat Pong"
      },
      id: "21090314"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "21090315"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "21090316"
    },
    {
      name: {
        km: "តាលាក់ខាងជើង",
        latin: "Ta Leak Khang Cheung"
      },
      id: "21090317"
    },
    {
      name: {
        km: "តាលាក់ខាងត្បូង",
        latin: "Ta Leak Khang Tboung"
      },
      id: "21090318"
    },
    {
      name: {
        km: "ត្រពាំងតាសុខ",
        latin: "Trapeang Ta Sokh"
      },
      id: "21090319"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "21090320"
    },
    {
      name: {
        km: "ចំការទៀង",
        latin: "Chamkar Tieng"
      },
      id: "21090321"
    },
    {
      name: {
        km: "សែនអោក",
        latin: "Saen Aok"
      },
      id: "21090322"
    },
    {
      name: {
        km: "ទន្សោងរោទ៍",
        latin: "Tonsaong Rou"
      },
      id: "21090323"
    },
    {
      name: {
        km: "ត្រពាំងឈើទាល",
        latin: "Trapeang Chheu Teal"
      },
      id: "21090324"
    },
    {
      name: {
        km: "ព្រៃស្នួល",
        latin: "Prey Snuol"
      },
      id: "21090325"
    },
    {
      name: {
        km: "ចំការអង្គខាងជើង",
        latin: "Chamkar Angk Khang Cheung"
      },
      id: "21090326"
    },
    {
      name: {
        km: "ចំការអង្គខាងត្បូង",
        latin: "Chamkar Angk Khang Tboung"
      },
      id: "21090327"
    },
    {
      name: {
        km: "ពងទឹកខាងត្បូង",
        latin: "Pong Tuek Khang Tboung"
      },
      id: "21090328"
    },
    {
      name: {
        km: "អណ្ដូងថ្ម",
        latin: "Andoung Thma"
      },
      id: "21090329"
    },
    {
      name: {
        km: "ព្រៃតាខាប",
        latin: "Prey Ta Khab"
      },
      id: "21090330"
    },
    {
      name: {
        km: "ជ្រែ",
        latin: "Chreae"
      },
      id: "21090401"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "21090402"
    },
    {
      name: {
        km: "ព្រៃធាតុ",
        latin: "Prey Theat"
      },
      id: "21090403"
    },
    {
      name: {
        km: "ត្រពាំងគូរ",
        latin: "Trapeang Kur"
      },
      id: "21090404"
    },
    {
      name: {
        km: "ទួលត្បែង",
        latin: "Tuol Tbaeng"
      },
      id: "21090405"
    },
    {
      name: {
        km: "អង្គគគីរ",
        latin: "Angk Kokir"
      },
      id: "21090406"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21090407"
    },
    {
      name: {
        km: "ព្រៃគុយ",
        latin: "Prey Kuy"
      },
      id: "21090408"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "21090409"
    },
    {
      name: {
        km: "អង្គតាកុប",
        latin: "Angk Ta Kob"
      },
      id: "21090410"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "21090411"
    },
    {
      name: {
        km: "កាច់ត្រក",
        latin: "Kach Trak"
      },
      id: "21090412"
    },
    {
      name: {
        km: "ត្រពាំងត្រាច",
        latin: "Trapeang Trach"
      },
      id: "21090413"
    },
    {
      name: {
        km: "ធ្យា",
        latin: "Thyea"
      },
      id: "21090414"
    },
    {
      name: {
        km: "ធ្នង់រលើង",
        latin: "Thnong Roleung"
      },
      id: "21090415"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "21090416"
    },
    {
      name: {
        km: "អង្គតាចាន់",
        latin: "Angk Ta Chan"
      },
      id: "21090417"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "21090418"
    },
    {
      name: {
        km: "អង្គតានូ",
        latin: "Angk Ta Nu"
      },
      id: "21090419"
    },
    {
      name: {
        km: "បាក់កូត",
        latin: "Bak Kout"
      },
      id: "21090420"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "21090421"
    },
    {
      name: {
        km: "អង្គនារាយណ៍",
        latin: "Angk Neareay"
      },
      id: "21090422"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "21090423"
    },
    {
      name: {
        km: "សីមា",
        latin: "Seima"
      },
      id: "21090424"
    },
    {
      name: {
        km: "សែនបន",
        latin: "Saen Ban"
      },
      id: "21090425"
    },
    {
      name: {
        km: "ដូនទួត",
        latin: "Doun Tuot"
      },
      id: "21090501"
    },
    {
      name: {
        km: "សូទៃ",
        latin: "Soutey"
      },
      id: "21090502"
    },
    {
      name: {
        km: "ក្រញូង",
        latin: "Kranhung"
      },
      id: "21090503"
    },
    {
      name: {
        km: "ត្រពាំងស្នោ",
        latin: "Trapeang Snao"
      },
      id: "21090504"
    },
    {
      name: {
        km: "គុស",
        latin: "Kus"
      },
      id: "21090505"
    },
    {
      name: {
        km: "ស្លែងកោង",
        latin: "Slaeng Kaong"
      },
      id: "21090506"
    },
    {
      name: {
        km: "តាតៃ",
        latin: "Ta Tai"
      },
      id: "21090507"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "21090508"
    },
    {
      name: {
        km: "អង្គតាសោម",
        latin: "Angk Ta Saom"
      },
      id: "21090509"
    },
    {
      name: {
        km: "អូរស្ងើន",
        latin: "Ou Sngaeun"
      },
      id: "21090510"
    },
    {
      name: {
        km: "កំសី",
        latin: "Kamsei"
      },
      id: "21090511"
    },
    {
      name: {
        km: "ត្រពាំងដងទឹក",
        latin: "Trapeang Dang Tuek"
      },
      id: "21090601"
    },
    {
      name: {
        km: "ទួលខ្លុង",
        latin: "Tuol Khlong"
      },
      id: "21090602"
    },
    {
      name: {
        km: "សុក្រំ",
        latin: "Sokram"
      },
      id: "21090603"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "21090604"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡាញ់",
        latin: "Trapeang Kralanh"
      },
      id: "21090605"
    },
    {
      name: {
        km: "បឹងសាទង",
        latin: "Boeng Satong"
      },
      id: "21090606"
    },
    {
      name: {
        km: "ត្រពាំងភ្លូ",
        latin: "Trapeang Phlu"
      },
      id: "21090607"
    },
    {
      name: {
        km: "ត្រពាំងខ្ចៅ",
        latin: "Trapeang Khchau"
      },
      id: "21090608"
    },
    {
      name: {
        km: "ដំណាក់ខ្លុង",
        latin: "Damnak Khlong"
      },
      id: "21090609"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "21090610"
    },
    {
      name: {
        km: "ឫស្សីមួយគុម្ព",
        latin: "Ruessei Muoy Kump"
      },
      id: "21090611"
    },
    {
      name: {
        km: "ត្នោតជុំ",
        latin: "Tnaot Chum"
      },
      id: "21090615"
    },
    {
      name: {
        km: "ខ្ពបស្វាយ",
        latin: "Khpob Svay"
      },
      id: "21090701"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "21090702"
    },
    {
      name: {
        km: "ត្រពាំងស្គា",
        latin: "Trapeang Skea"
      },
      id: "21090703"
    },
    {
      name: {
        km: "ត្រពាំងរបង",
        latin: "Trapeang Robang"
      },
      id: "21090704"
    },
    {
      name: {
        km: "ត្រពាំងក្រញូង",
        latin: "Trapeang Kranhoung"
      },
      id: "21090705"
    },
    {
      name: {
        km: "ព្រៃក្ដួច",
        latin: "Prey Kduoch"
      },
      id: "21090706"
    },
    {
      name: {
        km: "ផ្លូវលោក",
        latin: "Phlov Louk"
      },
      id: "21090707"
    },
    {
      name: {
        km: "ព្រៃតាឡូយ",
        latin: "Prey Talouy"
      },
      id: "21090708"
    },
    {
      name: {
        km: "បុស្សតាផង់",
        latin: "Boss Taphong"
      },
      id: "21090709"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "21090801"
    },
    {
      name: {
        km: "ចុងអាង",
        latin: "Chong Ang"
      },
      id: "21090802"
    },
    {
      name: {
        km: "ត្រពាំងត្រកៀត",
        latin: "Trapeang Trakiet"
      },
      id: "21090803"
    },
    {
      name: {
        km: "អង្គរំពាក់",
        latin: "Angk Rumpeak"
      },
      id: "21090804"
    },
    {
      name: {
        km: "ដកវ៉ាន",
        latin: "Dak Van"
      },
      id: "21090805"
    },
    {
      name: {
        km: "តាសូរ",
        latin: "Ta Sour"
      },
      id: "21090806"
    },
    {
      name: {
        km: "តាលឿ",
        latin: "Ta Loea"
      },
      id: "21090807"
    },
    {
      name: {
        km: "រំពាក់ពេន",
        latin: "Rumpeak Pen"
      },
      id: "21090808"
    },
    {
      name: {
        km: "ត្រពាំងថ្លាន់",
        latin: "Trapeang Thlan"
      },
      id: "21090809"
    },
    {
      name: {
        km: "ប្របសៀម",
        latin: "Prab Siem"
      },
      id: "21090810"
    },
    {
      name: {
        km: "រំលេចស្វាយ",
        latin: "Rumlich Svay"
      },
      id: "21090811"
    },
    {
      name: {
        km: "ស្រង៉ែ",
        latin: "Srangae"
      },
      id: "21090812"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "21090813"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21090814"
    },
    {
      name: {
        km: "អង្គគគីរ",
        latin: "Angk Kokir"
      },
      id: "21090901"
    },
    {
      name: {
        km: "ត្រាវអែម",
        latin: "Trav Aem"
      },
      id: "21090902"
    },
    {
      name: {
        km: "តាសិត",
        latin: "Ta Set"
      },
      id: "21090903"
    },
    {
      name: {
        km: "ព្រៃជួរ",
        latin: "Prey Chuor"
      },
      id: "21090904"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "21090905"
    },
    {
      name: {
        km: "ក្ងោកពង",
        latin: "Kngaok Pong"
      },
      id: "21090906"
    },
    {
      name: {
        km: "ដំណាក់រវាង",
        latin: "Damnak Roveang"
      },
      id: "21090907"
    },
    {
      name: {
        km: "ចមពល",
        latin: "Cham Pol"
      },
      id: "21090908"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "21090909"
    },
    {
      name: {
        km: "រមៀត",
        latin: "Romiet"
      },
      id: "21090910"
    },
    {
      name: {
        km: "ព្រៃមាន",
        latin: "Prey Mean"
      },
      id: "21090911"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "21090912"
    },
    {
      name: {
        km: "ត្រពាំងចែង",
        latin: "Trapeang Chaeng"
      },
      id: "21091001"
    },
    {
      name: {
        km: "កោះញ៉ៃ",
        latin: "Kaoh Nhai"
      },
      id: "21091002"
    },
    {
      name: {
        km: "ក្របីព្រៃ",
        latin: "Krabei Prey"
      },
      id: "21091003"
    },
    {
      name: {
        km: "ចានទាប",
        latin: "Chan Teab"
      },
      id: "21091004"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21091005"
    },
    {
      name: {
        km: "ក្រាំងបន្ទាយ",
        latin: "Krang Banteay"
      },
      id: "21091006"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "21091007"
    },
    {
      name: {
        km: "ប៉ែនមាស",
        latin: "Paen Meas"
      },
      id: "21091008"
    },
    {
      name: {
        km: "តាស្មន់",
        latin: "Ta Sman"
      },
      id: "21091009"
    },
    {
      name: {
        km: "ព្រៃតាដុក",
        latin: "Prey Ta Dok"
      },
      id: "21091010"
    },
    {
      name: {
        km: "ប្រសូត្រថ្មី",
        latin: "Prasoutr Thmei"
      },
      id: "21091011"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "21091012"
    },
    {
      name: {
        km: "តាសោម",
        latin: "Ta Saom"
      },
      id: "21091013"
    },
    {
      name: {
        km: "ព្រៃគគីរ",
        latin: "Prey Kokir"
      },
      id: "21091014"
    },
    {
      name: {
        km: "តាប៉ែន",
        latin: "Ta Paen"
      },
      id: "21091015"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "21091016"
    },
    {
      name: {
        km: "ប្រវន័យ",
        latin: "Pravoney"
      },
      id: "21091101"
    },
    {
      name: {
        km: "ត្រពាំងថ្នល់",
        latin: "Trapeang Thnal"
      },
      id: "21091102"
    },
    {
      name: {
        km: "ជ្រៃវែង",
        latin: "Chrey Veaeng"
      },
      id: "21091103"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "21091104"
    },
    {
      name: {
        km: "ត្រពាំងរនោង",
        latin: "Trapeang Ronoung"
      },
      id: "21091105"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "21091106"
    },
    {
      name: {
        km: "គោករវៀង",
        latin: "Kouk Rovieng"
      },
      id: "21091107"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "21091108"
    },
    {
      name: {
        km: "តាកែវ",
        latin: "Ta Kaev"
      },
      id: "21091109"
    },
    {
      name: {
        km: "ក្រាំងស្វាយ",
        latin: "Krang Svay"
      },
      id: "21091110"
    },
    {
      name: {
        km: "ត្រពាំងរបើម",
        latin: "Trapeang Robaeum"
      },
      id: "21091111"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21091112"
    },
    {
      name: {
        km: "ប្រជុំ",
        latin: "Prachum"
      },
      id: "21091113"
    },
    {
      name: {
        km: "ស្រែឈើនៀង",
        latin: "Srae Chheu Nieng"
      },
      id: "21091114"
    },
    {
      name: {
        km: "ព្រៃមោក",
        latin: "Prey Mouk"
      },
      id: "21091115"
    },
    {
      name: {
        km: "ស្រែថ្លុក",
        latin: "Srae Thlok"
      },
      id: "21091116"
    },
    {
      name: {
        km: "ត្រពាំងទម្លាប់",
        latin: "Trapeang Tomloab"
      },
      id: "21091117"
    },
    {
      name: {
        km: "ម្រុំ",
        latin: "Mrum"
      },
      id: "21091201"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "21091202"
    },
    {
      name: {
        km: "តាម៉ុច",
        latin: "Ta Moch"
      },
      id: "21091203"
    },
    {
      name: {
        km: "អង្គគគីរ",
        latin: "Angk Kokir"
      },
      id: "21091204"
    },
    {
      name: {
        km: "តាភេម",
        latin: "Ta Phem"
      },
      id: "21091205"
    },
    {
      name: {
        km: "តាសូ",
        latin: "Ta Sou"
      },
      id: "21091206"
    },
    {
      name: {
        km: "ខ្លាគ្រហឹម",
        latin: "Khla Krohuem"
      },
      id: "21091207"
    },
    {
      name: {
        km: "លីញ៉ា",
        latin: "Li Nha"
      },
      id: "21091208"
    },
    {
      name: {
        km: "ប្រស៊ុង",
        latin: "Prasung"
      },
      id: "21091209"
    },
    {
      name: {
        km: "ត្បែងទទឹង",
        latin: "Tbaeng Totueng"
      },
      id: "21091210"
    },
    {
      name: {
        km: "អូរផុត",
        latin: "Ou Phot"
      },
      id: "21091211"
    },
    {
      name: {
        km: "មហាសេនា",
        latin: "Moha Sena"
      },
      id: "21091212"
    },
    {
      name: {
        km: "លាងស្រាយ",
        latin: "Leang Sray"
      },
      id: "21091213"
    },
    {
      name: {
        km: "តាគាំ",
        latin: "Ta Koam"
      },
      id: "21091214"
    },
    {
      name: {
        km: "ពោធិ៍ព្រះសង្ឃ",
        latin: "Pou Preah Sang"
      },
      id: "21091215"
    },
    {
      name: {
        km: "ត្រពាំងកប្បាស",
        latin: "Trapeang Kabbas"
      },
      id: "21091216"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "21091217"
    },
    {
      name: {
        km: "បាខុងខាងកើត",
        latin: "Ba Khong Khang Kaeut"
      },
      id: "21091218"
    },
    {
      name: {
        km: "បាខុងខាងលិច",
        latin: "Ba Khong Khang Lech"
      },
      id: "21091219"
    },
    {
      name: {
        km: "តាមុំ",
        latin: "Ta Mom"
      },
      id: "21091220"
    },
    {
      name: {
        km: "ប្រវង់",
        latin: "Pravong"
      },
      id: "21091221"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "21091222"
    },
    {
      name: {
        km: "តាកឹម",
        latin: "Ta Koem"
      },
      id: "21091223"
    },
    {
      name: {
        km: "យាយឡ",
        latin: "Yeay La"
      },
      id: "21091301"
    },
    {
      name: {
        km: "ជ្រៃត្នោត",
        latin: "Chrey Tnaot"
      },
      id: "21091302"
    },
    {
      name: {
        km: "ត្រពាំងរំពាក់",
        latin: "Trapeang Rumpeak"
      },
      id: "21091303"
    },
    {
      name: {
        km: "នៀល",
        latin: "Niel"
      },
      id: "21091304"
    },
    {
      name: {
        km: "ត្រពាំងកែស",
        latin: "Trapeang Kaes"
      },
      id: "21091305"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "21091306"
    },
    {
      name: {
        km: "កល់គម",
        latin: "Kol Kom"
      },
      id: "21091307"
    },
    {
      name: {
        km: "អង្គរនាប",
        latin: "Angk Roneab"
      },
      id: "21091308"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "21091309"
    },
    {
      name: {
        km: "ត្រពាំងខ្លូត",
        latin: "Trapeang Khlout"
      },
      id: "21091310"
    },
    {
      name: {
        km: "បឹងម្កាក់",
        latin: "Boeng Mkak"
      },
      id: "21091311"
    },
    {
      name: {
        km: "ក្រាំងគរ",
        latin: "Krang Kor"
      },
      id: "21091312"
    },
    {
      name: {
        km: "ថ្មកែវ",
        latin: "Thma Kaev"
      },
      id: "21091313"
    },
    {
      name: {
        km: "ពាក់បង្អោង",
        latin: "Peak Bang'aong"
      },
      id: "21091401"
    },
    {
      name: {
        km: "ព្រៃខ្វាវ",
        latin: "Prey Khvav"
      },
      id: "21091402"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "21091403"
    },
    {
      name: {
        km: "តាសួន",
        latin: "Ta Suon"
      },
      id: "21091404"
    },
    {
      name: {
        km: "ព្រៃក្ដួច",
        latin: "Prey Kduoch"
      },
      id: "21091405"
    },
    {
      name: {
        km: "ព្រៃតាឡី",
        latin: "Prey Ta Lei"
      },
      id: "21091406"
    },
    {
      name: {
        km: "សំរ៉ង",
        latin: "Samrang"
      },
      id: "21091407"
    },
    {
      name: {
        km: "អង្គត្រាវ",
        latin: "Angk Trav"
      },
      id: "21091408"
    },
    {
      name: {
        km: "ពោធិដុះ",
        latin: "Pou Doh"
      },
      id: "21091409"
    },
    {
      name: {
        km: "ព្រៃស្បាត",
        latin: "Prey Sbat"
      },
      id: "21091410"
    },
    {
      name: {
        km: "ព្រៃដក់ពរ",
        latin: "Prey Dak Por"
      },
      id: "21091411"
    },
    {
      name: {
        km: "ត្រពាំងកោះ",
        latin: "Trapeang Kaoh"
      },
      id: "21091501"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "21091502"
    },
    {
      name: {
        km: "ព្រៃរំដួល",
        latin: "Prey Rumduol"
      },
      id: "21091503"
    },
    {
      name: {
        km: "ប្រគៀប",
        latin: "Prakieb"
      },
      id: "21091504"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "21091505"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "21091506"
    },
    {
      name: {
        km: "គោចិនលែង",
        latin: "Kou Chen Leaeng"
      },
      id: "21091507"
    },
    {
      name: {
        km: "ត្រពាំងតាសោម",
        latin: "Trapeang Ta Saom"
      },
      id: "21091508"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21091509"
    },
    {
      name: {
        km: "ត្រពាំងត្នោត",
        latin: "Trapeang Tnaot"
      },
      id: "21091510"
    },
    {
      name: {
        km: "ត្រពាំងប្រីយ៍",
        latin: "Trapeang Prei"
      },
      id: "21091511"
    },
    {
      name: {
        km: "ត្រពាំងខន",
        latin: "Trapeang Khan"
      },
      id: "21091512"
    },
    {
      name: {
        km: "ព្រៃព្រាល",
        latin: "Prey Preal"
      },
      id: "21091513"
    },
    {
      name: {
        km: "ព្រៃទូក",
        latin: "Prey Tuk"
      },
      id: "21100101"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "21100102"
    },
    {
      name: {
        km: "ត្រពាំងខ្លូត",
        latin: "Trapeang Khlout"
      },
      id: "21100103"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21100104"
    },
    {
      name: {
        km: "តារេនជោ",
        latin: "Ta Ren Chou"
      },
      id: "21100105"
    },
    {
      name: {
        km: "ក្រាំងឈើនៀង",
        latin: "Krang Chheu Nieng"
      },
      id: "21100106"
    },
    {
      name: {
        km: "ដឹកម៉ៃ",
        latin: "Doek Mai"
      },
      id: "21100107"
    },
    {
      name: {
        km: "អង្គរការ",
        latin: "Angk Rokar"
      },
      id: "21100108"
    },
    {
      name: {
        km: "ស្ពឺ",
        latin: "Spueu"
      },
      id: "21100201"
    },
    {
      name: {
        km: "គ",
        latin: "Kor"
      },
      id: "21100202"
    },
    {
      name: {
        km: "ត្រពាំងប្រី",
        latin: "Trapeang Prei"
      },
      id: "21100203"
    },
    {
      name: {
        km: "ត្រពាំងរកា",
        latin: "Trapeang Roka"
      },
      id: "21100204"
    },
    {
      name: {
        km: "ព្រៃមាស",
        latin: "Prey Meas"
      },
      id: "21100205"
    },
    {
      name: {
        km: "ស្រែវង្ស",
        latin: "Srae Vong"
      },
      id: "21100206"
    },
    {
      name: {
        km: "តាភាគ",
        latin: "Ta Pheak"
      },
      id: "21100207"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "21100301"
    },
    {
      name: {
        km: "ជួស",
        latin: "Chuos"
      },
      id: "21100302"
    },
    {
      name: {
        km: "ឆ្កែស្លាប់",
        latin: "Chhkae Slab"
      },
      id: "21100303"
    },
    {
      name: {
        km: "អណ្ដែងសាំងជ្វា",
        latin: "Andaeng Sang Chvea"
      },
      id: "21100304"
    },
    {
      name: {
        km: "អណ្ដែងសាំងខ្មែរ",
        latin: "Andaeng Sang Khmer"
      },
      id: "21100305"
    },
    {
      name: {
        km: "ជ្រែ",
        latin: "Chreae"
      },
      id: "21100306"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "21100307"
    },
    {
      name: {
        km: "យុលចេក",
        latin: "Yul Chek"
      },
      id: "21100308"
    },
    {
      name: {
        km: "បន្ទាយ",
        latin: "Banteay"
      },
      id: "21100309"
    },
    {
      name: {
        km: "ជីខ្មា",
        latin: "Chi Khma"
      },
      id: "21100310"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "21100401"
    },
    {
      name: {
        km: "តាសឹង",
        latin: "Ta Soeng"
      },
      id: "21100402"
    },
    {
      name: {
        km: "ក្ដី្ករុន",
        latin: "Kdei Run"
      },
      id: "21100403"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "21100404"
    },
    {
      name: {
        km: "ស្រម៉លើ",
        latin: "Srama Leu"
      },
      id: "21100405"
    },
    {
      name: {
        km: "ធម្មតា",
        latin: "Thommoda"
      },
      id: "21100406"
    },
    {
      name: {
        km: "ស្រម៉ក្រោម",
        latin: "Srama Kraom"
      },
      id: "21100407"
    },
    {
      name: {
        km: "តាស្រែន",
        latin: "Ta Sraen"
      },
      id: "21100408"
    },
    {
      name: {
        km: "ដូនភើ",
        latin: "Doun Pheu"
      },
      id: "21100409"
    },
    {
      name: {
        km: "ព្រឹសលើ",
        latin: "Prues Leu"
      },
      id: "21100410"
    },
    {
      name: {
        km: "ព្រឹសក្រោម",
        latin: "Prues Kraom"
      },
      id: "21100411"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "21100501"
    },
    {
      name: {
        km: "ដំណាក់រាជា",
        latin: "Damnak Reachea"
      },
      id: "21100502"
    },
    {
      name: {
        km: "ដង្កោ",
        latin: "Dangkao"
      },
      id: "21100503"
    },
    {
      name: {
        km: "ឈើទាលភ្លោះ",
        latin: "Chheu Teal Phluoh"
      },
      id: "21100504"
    },
    {
      name: {
        km: "ប្រាំបីមុំ",
        latin: "Prambei Mum"
      },
      id: "21100505"
    },
    {
      name: {
        km: "ក្រាំងកណ្ដាល",
        latin: "Krang Kandal"
      },
      id: "21100506"
    },
    {
      name: {
        km: "ត្រពាំងលើក",
        latin: "Trapeang Leuk"
      },
      id: "21100507"
    },
    {
      name: {
        km: "ក្រាំងស្បែក",
        latin: "Krang Sbaek"
      },
      id: "21100508"
    },
    {
      name: {
        km: "ពញ្ញាឮ",
        latin: "Ponhea Lueu"
      },
      id: "21100509"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "21100510"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "21100511"
    },
    {
      name: {
        km: "ល្វេថ្មី",
        latin: "Lve Thmei"
      },
      id: "21100512"
    },
    {
      name: {
        km: "អូរក្រឡង់ដួល",
        latin: "Ou Kralang Duol"
      },
      id: "21100601"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "21100602"
    },
    {
      name: {
        km: "អង្គកែវ",
        latin: "Angk Kaev"
      },
      id: "21100603"
    },
    {
      name: {
        km: "ព្រៃរំពាក់",
        latin: "Prey Rumpeak"
      },
      id: "21100604"
    },
    {
      name: {
        km: "ធ្នោះ",
        latin: "Thnuoh"
      },
      id: "21100605"
    },
    {
      name: {
        km: "ត្រពាំងស្នោ",
        latin: "Trapeang Snao"
      },
      id: "21100606"
    },
    {
      name: {
        km: "សេរីដួច",
        latin: "Serei Duoch"
      },
      id: "21100607"
    },
    {
      name: {
        km: "អូរតាសេក",
        latin: "Ou Ta Sek"
      },
      id: "21100608"
    },
    {
      name: {
        km: "ព្រៃសណ្ដែក",
        latin: "Prey Sandaek"
      },
      id: "21100701"
    },
    {
      name: {
        km: "ស្រែច្រក",
        latin: "Srae Chrak"
      },
      id: "21100702"
    },
    {
      name: {
        km: "គោកញ័រ",
        latin: "Kouk Nhoar"
      },
      id: "21100703"
    },
    {
      name: {
        km: "នៀល",
        latin: "Niel"
      },
      id: "21100704"
    },
    {
      name: {
        km: "ព្រៃមៀន",
        latin: "Prey Mien"
      },
      id: "21100705"
    },
    {
      name: {
        km: "ព្រៃស្លឹក",
        latin: "Prey Sloek"
      },
      id: "21100706"
    },
    {
      name: {
        km: "ភ្នំថ្នក់",
        latin: "Phnum Thnak"
      },
      id: "21100707"
    },
    {
      name: {
        km: "ដើមផ្ដៀក",
        latin: "Daeum Phdiek"
      },
      id: "21100708"
    },
    {
      name: {
        km: "ត្រពាំងតាមូង",
        latin: "Trapeang Ta Moung"
      },
      id: "21100709"
    },
    {
      name: {
        km: "បារាយណ៍",
        latin: "Baray"
      },
      id: "21100710"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "21100711"
    },
    {
      name: {
        km: "ស្វាយរំដេង",
        latin: "Svay Rumdeng"
      },
      id: "21100712"
    },
    {
      name: {
        km: "ភ្នំខ្លែង",
        latin: "Phnum Khlaeng"
      },
      id: "21100713"
    },
    {
      name: {
        km: "ត្រពាំងអង្គ",
        latin: "Trapeang Angk"
      },
      id: "21100714"
    },
    {
      name: {
        km: "សង្ហា",
        latin: "Sangha"
      },
      id: "21100715"
    },
    {
      name: {
        km: "សំរោងមានជ័យ",
        latin: "Samraong Mean Chey"
      },
      id: "21100716"
    },
    {
      name: {
        km: "សូរ្យច័ន្ទ",
        latin: "Sour Chant"
      },
      id: "21100717"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "21100801"
    },
    {
      name: {
        km: "ត្រពាំងរំពាក់",
        latin: "Trapeang Rumpeak"
      },
      id: "21100802"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "21100803"
    },
    {
      name: {
        km: "ធម្មតា",
        latin: "Thommoda"
      },
      id: "21100804"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "21100805"
    },
    {
      name: {
        km: "ព្រៃផ្អាវ",
        latin: "Prey Ph'av"
      },
      id: "21100806"
    },
    {
      name: {
        km: "ខ្លោងទ្វារ",
        latin: "Khlaong Tvear"
      },
      id: "21100807"
    },
    {
      name: {
        km: "ត្រពាំងខ្នុរ",
        latin: "Trapeang Khnor"
      },
      id: "21100808"
    },
    {
      name: {
        km: "ព្រៃផ្អេរ",
        latin: "Prey Ph'er"
      },
      id: "21100809"
    },
    {
      name: {
        km: "គោកឃ្មោង",
        latin: "Kouk Khmoung"
      },
      id: "21100810"
    },
    {
      name: {
        km: "សូភី",
        latin: "Souphi"
      },
      id: "21100811"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "21100901"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "21100902"
    },
    {
      name: {
        km: "ព្រៃដកពរ",
        latin: "Prey Dak Por"
      },
      id: "21100903"
    },
    {
      name: {
        km: "ព្រៃភ្លង",
        latin: "Prey Phlong"
      },
      id: "21100904"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "21100905"
    },
    {
      name: {
        km: "តាប្រឹម",
        latin: "Ta Proem"
      },
      id: "21100906"
    },
    {
      name: {
        km: "ត្រពាំងពន្លុះ",
        latin: "Trapeang Ponluh"
      },
      id: "21100907"
    },
    {
      name: {
        km: "ក្បាលពោធិ៍",
        latin: "Kbal Pou"
      },
      id: "21100908"
    },
    {
      name: {
        km: "ត្នោតជុំ",
        latin: "Tnaot Chum"
      },
      id: "21100909"
    },
    {
      name: {
        km: "អូរពោធិ៍",
        latin: "Ou Pou"
      },
      id: "21100910"
    },
    {
      name: {
        km: "អង្គតាភោគ",
        latin: "Angk Ta Phouk"
      },
      id: "21101001"
    },
    {
      name: {
        km: "អង្គប្រាង្គរ",
        latin: "Angk Prangkor"
      },
      id: "21101002"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "21101003"
    },
    {
      name: {
        km: "តាព្រះ",
        latin: "Ta Preah"
      },
      id: "21101004"
    },
    {
      name: {
        km: "ក្រាំងត្នោត",
        latin: "Krang Tnaot"
      },
      id: "21101005"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "21101006"
    },
    {
      name: {
        km: "ចិន",
        latin: "Chen"
      },
      id: "21101007"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "21101008"
    },
    {
      name: {
        km: "ស្រះតាកួន",
        latin: "Srah Ta Kuon"
      },
      id: "21101009"
    },
    {
      name: {
        km: "ជីច្រាប",
        latin: "Chi Chrab"
      },
      id: "21101010"
    },
    {
      name: {
        km: "លោក",
        latin: "Louk"
      },
      id: "21101011"
    },
    {
      name: {
        km: "ក្រោម",
        latin: "Kraom"
      },
      id: "21101012"
    },
    {
      name: {
        km: "ស្មោង",
        latin: "Smaong"
      },
      id: "21101101"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "21101102"
    },
    {
      name: {
        km: "កំពង់ជ្រៃ",
        latin: "Kampong Chrey"
      },
      id: "21101103"
    },
    {
      name: {
        km: "ស្គុល",
        latin: "Skul"
      },
      id: "21101104"
    },
    {
      name: {
        km: "ត្រពាំងលើក",
        latin: "Trapeang Leuk"
      },
      id: "21101105"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "21101201"
    },
    {
      name: {
        km: "ព្រៃនប់",
        latin: "Prey Nob"
      },
      id: "21101202"
    },
    {
      name: {
        km: "ច្រាំង",
        latin: "Chrang"
      },
      id: "21101203"
    },
    {
      name: {
        km: "ត្រពាំងបបុស្ស",
        latin: "Trapeang Babos"
      },
      id: "21101204"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "21101205"
    },
    {
      name: {
        km: "ម៉ឺនទំរង់",
        latin: "Meun Tumrong"
      },
      id: "21101206"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "21101207"
    },
    {
      name: {
        km: "ស្វាយអំពារ",
        latin: "Svay Ampear"
      },
      id: "21101208"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "21101209"
    },
    {
      name: {
        km: "ព្រៃរុន្ធ",
        latin: "Prey Run"
      },
      id: "21101210"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "21101211"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "21101212"
    },
    {
      name: {
        km: "ព្រៃចង្រៀក",
        latin: "Prey Changriek"
      },
      id: "21101213"
    },
    {
      name: {
        km: "គោកអំពៅ",
        latin: "Kouk Ampov"
      },
      id: "21101214"
    },
    {
      name: {
        km: "ពុទ្ធសាំ",
        latin: "Putth Sam"
      },
      id: "21101215"
    },
    {
      name: {
        km: "ត្រពាំងស្លា",
        latin: "Trapeang Sla"
      },
      id: "21101301"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "21101302"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "21101303"
    },
    {
      name: {
        km: "ក្រាំងធំ",
        latin: "Krang Thum"
      },
      id: "21101304"
    },
    {
      name: {
        km: "ក្រាំងដៃ",
        latin: "Krang Dai"
      },
      id: "21101305"
    },
    {
      name: {
        km: "ចិន",
        latin: "Chen"
      },
      id: "21101306"
    },
    {
      name: {
        km: "ព្រៃវែង",
        latin: "Prey Veaeng"
      },
      id: "21101307"
    },
    {
      name: {
        km: "ឈើទាលបាក់",
        latin: "Chheu Teal Bak"
      },
      id: "21101308"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "21101309"
    },
    {
      name: {
        km: "ក្រាំងតូច",
        latin: "Krang Touch"
      },
      id: "21101310"
    },
    {
      name: {
        km: "ក្ដីត្នោត",
        latin: "Kdei Tnaot"
      },
      id: "21101311"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "21101312"
    },
    {
      name: {
        km: "សំណខ្មៅ",
        latin: "Samna Khmau"
      },
      id: "21101313"
    },
    {
      name: {
        km: "ក្រាំងធ្នង់",
        latin: "Krang Thnong"
      },
      id: "21101314"
    },
    {
      name: {
        km: "ក្រាំងរោង",
        latin: "Krang Roung"
      },
      id: "21101315"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "21101316"
    },
    {
      name: {
        km: "ល្វេ",
        latin: "Lve"
      },
      id: "21101317"
    },
    {
      name: {
        km: "ជ្រុងគ្រីស",
        latin: "Chrung Kris"
      },
      id: "21101318"
    },
    {
      name: {
        km: "ទន្លេ",
        latin: "Tonle"
      },
      id: "21101401"
    },
    {
      name: {
        km: "ឆឹស",
        latin: "Choes"
      },
      id: "21101402"
    },
    {
      name: {
        km: "ត្រឡាច",
        latin: "Tralach"
      },
      id: "21101403"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "21101404"
    },
    {
      name: {
        km: "ជីងោ",
        latin: "Chingou"
      },
      id: "21101405"
    },
    {
      name: {
        km: "អង្គុញ",
        latin: "Angkunh"
      },
      id: "21101406"
    },
    {
      name: {
        km: "កន្ទួតធំ",
        latin: "Kantuot Thum"
      },
      id: "21101407"
    },
    {
      name: {
        km: "កន្ទួតតូច",
        latin: "Kantuot Touch"
      },
      id: "21101408"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "21101409"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "21101410"
    },
    {
      name: {
        km: "កោះថ្មី",
        latin: "Kaoh Thmei"
      },
      id: "22010101"
    },
    {
      name: {
        km: "អូរជញ្ជៀន",
        latin: "Ou Chenhchien"
      },
      id: "22010102"
    },
    {
      name: {
        km: "ប្រលាន",
        latin: "Prolean"
      },
      id: "22010103"
    },
    {
      name: {
        km: "យាងខាងត្បូង",
        latin: "Yeang Khang Tbong"
      },
      id: "22010104"
    },
    {
      name: {
        km: "កណ្ដាលក្រោម",
        latin: "Thnal Kandal Kraom"
      },
      id: "22010105"
    },
    {
      name: {
        km: "កណ្ដាលលើ",
        latin: "Thnal Kandal Leu"
      },
      id: "22010106"
    },
    {
      name: {
        km: "ថ្នល់ទទឹង",
        latin: "Thnal Totueng"
      },
      id: "22010107"
    },
    {
      name: {
        km: "ថ្នល់បំបែក",
        latin: "Thnal Bambaek"
      },
      id: "22010108"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "22010109"
    },
    {
      name: {
        km: "អភិវឌ្ឍន៏",
        latin: "Akphivoad"
      },
      id: "22010110"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "22010111"
    },
    {
      name: {
        km: "យាងខាងជើង",
        latin: "Yeang Khang Chheung"
      },
      id: "22010112"
    },
    {
      name: {
        km: "ថ្នល់ថ្មី",
        latin: "Thnal Thmey"
      },
      id: "22010113"
    },
    {
      name: {
        km: "រំចេកខាងកើត",
        latin: "Rumchek Khang Kert"
      },
      id: "22010114"
    },
    {
      name: {
        km: "អូរតាមែង",
        latin: "Ou Ta Meng"
      },
      id: "22010115"
    },
    {
      name: {
        km: "រំចេកខាងលិច",
        latin: "Rumchek Khang Lech"
      },
      id: "22010116"
    },
    {
      name: {
        km: "ត្រពាំងតាវ",
        latin: "Trapeang Tav"
      },
      id: "22010301"
    },
    {
      name: {
        km: "តាដេវ",
        latin: "Ta Dev"
      },
      id: "22010302"
    },
    {
      name: {
        km: "អូរអង្រែ",
        latin: "Ou Angrae"
      },
      id: "22010303"
    },
    {
      name: {
        km: "ស្លែងពណ៌",
        latin: "Slaeng Por"
      },
      id: "22010304"
    },
    {
      name: {
        km: "ទួលប្រាសាទ",
        latin: "Tuol Prasat"
      },
      id: "22010305"
    },
    {
      name: {
        km: "ទួលស្វាយ",
        latin: "Tuol Svay"
      },
      id: "22010306"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "22010307"
    },
    {
      name: {
        km: "ទំនប់",
        latin: "Tumnob"
      },
      id: "22010308"
    },
    {
      name: {
        km: "អូរស្រម៉",
        latin: "Ou SrorMor"
      },
      id: "22010309"
    },
    {
      name: {
        km: "ទួលស្វាយសែនជ័យ",
        latin: "Tuol Svay Saen Chey"
      },
      id: "22010310"
    },
    {
      name: {
        km: "ទួលកណ្ដាល",
        latin: "Tuol Kandal"
      },
      id: "22010401"
    },
    {
      name: {
        km: "ទួលសាលា",
        latin: "Tuol Sala"
      },
      id: "22010402"
    },
    {
      name: {
        km: "ឯកភាព",
        latin: "Aekakpheap"
      },
      id: "22010403"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "22010404"
    },
    {
      name: {
        km: "ជើងភ្នំ",
        latin: "Cheung Phnum"
      },
      id: "22010405"
    },
    {
      name: {
        km: "ឃ្លាំងកណ្ដាល",
        latin: "Khleang Kandal"
      },
      id: "22010406"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "22010407"
    },
    {
      name: {
        km: "សន្ដិភាព",
        latin: "Santepheap"
      },
      id: "22010408"
    },
    {
      name: {
        km: "ស្រះឈូក",
        latin: "Srah Chhuk"
      },
      id: "22010409"
    },
    {
      name: {
        km: "ទួលត្បែង",
        latin: "Tuol Tbaeng"
      },
      id: "22010410"
    },
    {
      name: {
        km: "ទំនប់លើ",
        latin: "Tumnob Leu"
      },
      id: "22010411"
    },
    {
      name: {
        km: "ទឹកជប់",
        latin: "Tuek Chob"
      },
      id: "22010412"
    },
    {
      name: {
        km: "ទឹកជុំ",
        latin: "Tuek Chum"
      },
      id: "22010413"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "22010501"
    },
    {
      name: {
        km: "អូររុន",
        latin: "Ou Run"
      },
      id: "22010502"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "22010503"
    },
    {
      name: {
        km: "ថ្លាត",
        latin: "Thlat"
      },
      id: "22010504"
    },
    {
      name: {
        km: "ទួលគ្រួស",
        latin: "Tuol Kruos"
      },
      id: "22010505"
    },
    {
      name: {
        km: "ទួលក្រឡាញ់",
        latin: "Tuol Kralanh"
      },
      id: "22010506"
    },
    {
      name: {
        km: "ទួលពេ្រច",
        latin: "Tuol Prich"
      },
      id: "22010507"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "22010508"
    },
    {
      name: {
        km: "លំទង",
        latin: "Lumtong"
      },
      id: "22010601"
    },
    {
      name: {
        km: "ទ្រាស",
        latin: "Treas"
      },
      id: "22010602"
    },
    {
      name: {
        km: "អូរគគីរកណ្ដាល",
        latin: "Ou Kokir Kandal"
      },
      id: "22010603"
    },
    {
      name: {
        km: "អូរគគីរក្រោម",
        latin: "Ou Kokir Kraom"
      },
      id: "22010604"
    },
    {
      name: {
        km: "អូរគគរីលើ",
        latin: "Ou Kokir Leu"
      },
      id: "22010605"
    },
    {
      name: {
        km: "លំទងថ្មី",
        latin: "Lumtong Thmei"
      },
      id: "22010606"
    },
    {
      name: {
        km: "ស្រឡៅស្រោង",
        latin: "Sror LaoSroang"
      },
      id: "22010607"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapaeng Thom"
      },
      id: "22010608"
    },
    {
      name: {
        km: "ជប់តាមោក",
        latin: "Chub Ta Mok"
      },
      id: "22010609"
    },
    {
      name: {
        km: "គោកសំព័រ",
        latin: "Kork Samphor"
      },
      id: "22010610"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "22010611"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "22020101"
    },
    {
      name: {
        km: "អំពិលចាស់",
        latin: "Ampil Chas"
      },
      id: "22020102"
    },
    {
      name: {
        km: "ត្រុំ",
        latin: "Trom"
      },
      id: "22020103"
    },
    {
      name: {
        km: "រុងរឿង",
        latin: "Rung Roeang"
      },
      id: "22020104"
    },
    {
      name: {
        km: "រំដួលចាស់",
        latin: "Rumduol Chas"
      },
      id: "22020105"
    },
    {
      name: {
        km: "ក្ដុល",
        latin: "Kdol"
      },
      id: "22020106"
    },
    {
      name: {
        km: "បារាយណ៍",
        latin: "Baray"
      },
      id: "22020107"
    },
    {
      name: {
        km: "យ៉ក",
        latin: "Yak"
      },
      id: "22020108"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "22020109"
    },
    {
      name: {
        km: "ព្រៃវល្លិ",
        latin: "Prey Voa"
      },
      id: "22020110"
    },
    {
      name: {
        km: "គោករិទ្ធ",
        latin: "Kouk Ritth"
      },
      id: "22020111"
    },
    {
      name: {
        km: "អំពិលថ្មី",
        latin: "Ampil Thmei"
      },
      id: "22020112"
    },
    {
      name: {
        km: "ជប់គគីរខាងកើត",
        latin: "Chob Kokir Khang Kaeut"
      },
      id: "22020113"
    },
    {
      name: {
        km: "ជប់គគីរខាងលិច",
        latin: "Chob Kokir Khang Lech"
      },
      id: "22020114"
    },
    {
      name: {
        km: "គោកធំ",
        latin: "Kouk Thum"
      },
      id: "22020115"
    },
    {
      name: {
        km: "ល្បើករិទ្ធ",
        latin: "Lbaeuk Rith"
      },
      id: "22020116"
    },
    {
      name: {
        km: "រំដួលថ្មី",
        latin: "Rumduol Thmei"
      },
      id: "22020117"
    },
    {
      name: {
        km: "ប្រាសាទរំដួល",
        latin: "Prasat Rumduol"
      },
      id: "22020118"
    },
    {
      name: {
        km: "ឪឡោក",
        latin: "Ovlaok"
      },
      id: "22020119"
    },
    {
      name: {
        km: "ត្បែងចាស់",
        latin: "Tbaeng Chas"
      },
      id: "22020120"
    },
    {
      name: {
        km: "ត្បែងថ្មី",
        latin: "Tbaeng Thmei"
      },
      id: "22020121"
    },
    {
      name: {
        km: "គោកព្រិច",
        latin: "Kouk Prech"
      },
      id: "22020122"
    },
    {
      name: {
        km: "ដូនទា",
        latin: "Doun Tea"
      },
      id: "22020123"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "22020124"
    },
    {
      name: {
        km: "ត្រប់",
        latin: "Trab"
      },
      id: "22020125"
    },
    {
      name: {
        km: "សុភាព",
        latin: "Sopheap"
      },
      id: "22020126"
    },
    {
      name: {
        km: "ពង្រតាឡី",
        latin: "Pongro Ta Lei"
      },
      id: "22020127"
    },
    {
      name: {
        km: "ហាលៀមសែនជ័យ",
        latin: "Ha Leam Saenchey"
      },
      id: "22020128"
    },
    {
      name: {
        km: "ច័ន្ទក្រហមសែនជ័យ",
        latin: "Chan Krohorm Saenchey"
      },
      id: "22020129"
    },
    {
      name: {
        km: "តេជោអូរដា",
        latin: "Decho Ou Da"
      },
      id: "22020130"
    },
    {
      name: {
        km: "រំដួលសែនជ័យ",
        latin: "Rumdul Saenchey"
      },
      id: "22020131"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "22020201"
    },
    {
      name: {
        km: "ទទឹងថ្ងៃ",
        latin: "Totueng Thngai"
      },
      id: "22020202"
    },
    {
      name: {
        km: "ទំនប់ថ្មី",
        latin: "Tumnob Thmei"
      },
      id: "22020203"
    },
    {
      name: {
        km: "ប្រាសាទល្បើក",
        latin: "Prasat Lbaeuk"
      },
      id: "22020204"
    },
    {
      name: {
        km: "ពោធិថ្មី",
        latin: "Pou Thmei"
      },
      id: "22020205"
    },
    {
      name: {
        km: "ប្រាសាទបី",
        latin: "Prasat Bei"
      },
      id: "22020206"
    },
    {
      name: {
        km: "វល្លិយាវ",
        latin: "Voa Yeav"
      },
      id: "22020207"
    },
    {
      name: {
        km: "ពោធិចាស់",
        latin: "Pou Chas"
      },
      id: "22020208"
    },
    {
      name: {
        km: "រង្សី",
        latin: "Reangsei"
      },
      id: "22020210"
    },
    {
      name: {
        km: "គោកកី",
        latin: "Kouk Kei"
      },
      id: "22020211"
    },
    {
      name: {
        km: "គោកកំប៉ុល",
        latin: "Kouk Kampol"
      },
      id: "22020212"
    },
    {
      name: {
        km: "គោកកប្បាស",
        latin: "Kouk Kabbas"
      },
      id: "22020213"
    },
    {
      name: {
        km: "កន្ទុយជូន",
        latin: "Kantuy Chun"
      },
      id: "22020214"
    },
    {
      name: {
        km: "អូររំដួល",
        latin: "Ou Rumduol"
      },
      id: "22020215"
    },
    {
      name: {
        km: "រស្មីសូភី",
        latin: "Reaksmei Souphi"
      },
      id: "22020216"
    },
    {
      name: {
        km: "សំរោងទាប",
        latin: "Samraong Teab"
      },
      id: "22020217"
    },
    {
      name: {
        km: "តាម៉ា",
        latin: "Ta Ma"
      },
      id: "22020218"
    },
    {
      name: {
        km: "ទំនប់ចាស់",
        latin: "Tumnob Chas"
      },
      id: "22020219"
    },
    {
      name: {
        km: "យាយទេព",
        latin: "Yeay Tep"
      },
      id: "22020220"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapaeng Svay"
      },
      id: "22020221"
    },
    {
      name: {
        km: "ពោធិ៍សែនជ័យ",
        latin: "Pur Sen Chey"
      },
      id: "22020222"
    },
    {
      name: {
        km: "ម៉ាលីស្រោង",
        latin: "Maly Stroang"
      },
      id: "22020223"
    },
    {
      name: {
        km: "ក្រឡសែនជ័យ",
        latin: "Kralor Sen Chey"
      },
      id: "22020224"
    },
    {
      name: {
        km: "គោកខ្ពស់",
        latin: "Kouk Khpos"
      },
      id: "22020301"
    },
    {
      name: {
        km: "ប្រីយ៍",
        latin: "Prei"
      },
      id: "22020302"
    },
    {
      name: {
        km: "គាប",
        latin: "Keab"
      },
      id: "22020303"
    },
    {
      name: {
        km: "ឈើស្លាប់",
        latin: "Chheu Slab"
      },
      id: "22020304"
    },
    {
      name: {
        km: "ទន្លេស",
        latin: "Tonle Sa"
      },
      id: "22020305"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "22020306"
    },
    {
      name: {
        km: "មនោរម្យ",
        latin: "Monorum"
      },
      id: "22020307"
    },
    {
      name: {
        km: "ប្រាសាទអូរទង",
        latin: "Prasat Ou Tong"
      },
      id: "22020308"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "22020309"
    },
    {
      name: {
        km: "ស្រះស្រង់",
        latin: "Srah Srang"
      },
      id: "22020310"
    },
    {
      name: {
        km: "បុស្សធំ",
        latin: "Bos Thom"
      },
      id: "22020311"
    },
    {
      name: {
        km: "សែនមនោរម្យ១",
        latin: "Saen Monorom 1"
      },
      id: "22020312"
    },
    {
      name: {
        km: "សែនមនោរម្យ២",
        latin: "Saen Monorom 2"
      },
      id: "22020313"
    },
    {
      name: {
        km: "សឹង្ហ",
        latin: "Soengh"
      },
      id: "22020401"
    },
    {
      name: {
        km: "ស៊ីលៀម",
        latin: "Siliem"
      },
      id: "22020402"
    },
    {
      name: {
        km: "គោកមន",
        latin: "Kouk Mon"
      },
      id: "22020403"
    },
    {
      name: {
        km: "រនាមធំ",
        latin: "Roneam Thum"
      },
      id: "22020404"
    },
    {
      name: {
        km: "សាលារ៉ាង",
        latin: "Sala Rang"
      },
      id: "22020405"
    },
    {
      name: {
        km: "កញ្រ្ជៀប",
        latin: "Kanhchrieb"
      },
      id: "22020406"
    },
    {
      name: {
        km: "គោកស្វាយ",
        latin: "Kouk Svay"
      },
      id: "22020407"
    },
    {
      name: {
        km: "គូរ",
        latin: "Kur"
      },
      id: "22020408"
    },
    {
      name: {
        km: "ព្រៃវែង",
        latin: "Prey Veaeng"
      },
      id: "22020409"
    },
    {
      name: {
        km: "សិទ្ធិសេរី",
        latin: "Sith Serei"
      },
      id: "22020410"
    },
    {
      name: {
        km: "តានេស",
        latin: "Ta Nes"
      },
      id: "22020411"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "22020412"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "22020413"
    },
    {
      name: {
        km: "ថ្នល់ដាច់",
        latin: "Thnal Dach"
      },
      id: "22020414"
    },
    {
      name: {
        km: "គោកសង្គើច",
        latin: "Kouk Sangkaeuch"
      },
      id: "22020415"
    },
    {
      name: {
        km: "តាហាំ",
        latin: "Ta Ham"
      },
      id: "22020416"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "22020417"
    },
    {
      name: {
        km: "ថ្មដូន",
        latin: "Thma Doun"
      },
      id: "22020418"
    },
    {
      name: {
        km: "តាមាន់សែនជ័យ",
        latin: "Tamoan Saenchey"
      },
      id: "22020419"
    },
    {
      name: {
        km: "ជើងទៀន",
        latin: "Cheung Tien"
      },
      id: "22030101"
    },
    {
      name: {
        km: "ស្រែប្រាំង",
        latin: "Srae Prang"
      },
      id: "22030102"
    },
    {
      name: {
        km: "គោកពង្រ",
        latin: "Kouk Pongro"
      },
      id: "22030103"
    },
    {
      name: {
        km: "តាណូក",
        latin: "Ta Nouk"
      },
      id: "22030104"
    },
    {
      name: {
        km: "គោកត្រាង",
        latin: "Kouk Trang"
      },
      id: "22030105"
    },
    {
      name: {
        km: "ឆ្កែស្រែង",
        latin: "Chhkae Sraeng"
      },
      id: "22030106"
    },
    {
      name: {
        km: "គោករាំង",
        latin: "Kouk Reang"
      },
      id: "22030107"
    },
    {
      name: {
        km: "ចុងកាល់",
        latin: "Chong Kal"
      },
      id: "22030201"
    },
    {
      name: {
        km: "គោកវត្ដ",
        latin: "Kouk Voat"
      },
      id: "22030202"
    },
    {
      name: {
        km: "ក",
        latin: "Ka"
      },
      id: "22030203"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "22030204"
    },
    {
      name: {
        km: "ឈក",
        latin: "Chhork"
      },
      id: "22030205"
    },
    {
      name: {
        km: "ស្រម៉",
        latin: "Srama"
      },
      id: "22030206"
    },
    {
      name: {
        km: "បន្ទាយចាស់",
        latin: "Banteay Chas"
      },
      id: "22030207"
    },
    {
      name: {
        km: "អាតោ",
        latin: "Ah Toa"
      },
      id: "22030208"
    },
    {
      name: {
        km: "បាត់ថ្កៅ",
        latin: "Bat Thkao"
      },
      id: "22030209"
    },
    {
      name: {
        km: "បន្ទាយថ្មី",
        latin: "Banteay Thmey"
      },
      id: "22030210"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "22030301"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "22030302"
    },
    {
      name: {
        km: "គោកស្ពាន",
        latin: "Kouk Spean"
      },
      id: "22030304"
    },
    {
      name: {
        km: "គោល",
        latin: "Koul"
      },
      id: "22030305"
    },
    {
      name: {
        km: "ចេកក្បូរ",
        latin: "Chek Kbour"
      },
      id: "22030306"
    },
    {
      name: {
        km: "គោកសំរេច",
        latin: "Kouk Samrech"
      },
      id: "22030307"
    },
    {
      name: {
        km: "គោកធ្នង់",
        latin: "Kouk Thnong"
      },
      id: "22030308"
    },
    {
      name: {
        km: "រលំវែង",
        latin: "Rolum Veaeng"
      },
      id: "22030309"
    },
    {
      name: {
        km: "គោកបន្ទាត់បោះ",
        latin: "Kouk Bantoat Baoh"
      },
      id: "22030310"
    },
    {
      name: {
        km: "គោកធំ",
        latin: "Kouk Thom"
      },
      id: "22030311"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "22030401"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "22030402"
    },
    {
      name: {
        km: "តាប៉ែន",
        latin: "Ta Paen"
      },
      id: "22030403"
    },
    {
      name: {
        km: "កណ្តោលដុំ",
        latin: "Kandaol Dom"
      },
      id: "22030404"
    },
    {
      name: {
        km: "បន្ទាយជ័រ",
        latin: "Banteay Choar"
      },
      id: "22030405"
    },
    {
      name: {
        km: "ស្រះកែវ",
        latin: "Srah Kaev"
      },
      id: "22030406"
    },
    {
      name: {
        km: "គោកសង្កែ",
        latin: "Kouk Sangkae"
      },
      id: "22030407"
    },
    {
      name: {
        km: "ព្រៃនគរ",
        latin: "Prey Nokor"
      },
      id: "22030408"
    },
    {
      name: {
        km: "បន្សាយរាក់",
        latin: "Bansay Reak"
      },
      id: "22040101"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "22040102"
    },
    {
      name: {
        km: "រំដួលវាសនា",
        latin: "Rumduol Veasna"
      },
      id: "22040103"
    },
    {
      name: {
        km: "សំបួរមាស",
        latin: "Sambuor Meas"
      },
      id: "22040104"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "22040105"
    },
    {
      name: {
        km: "កសិ-ទេពពោធិវង្ស",
        latin: "Kakse-Tepporthivong"
      },
      id: "22040106"
    },
    {
      name: {
        km: "ខ្នាចឫស្សី",
        latin: "Khnach  Ruessei"
      },
      id: "22040107"
    },
    {
      name: {
        km: "ត្រពាំងម្អមសែនជ័យ",
        latin: "Trapeang Maom Saen Chey"
      },
      id: "22040108"
    },
    {
      name: {
        km: "បុស្បូវ",
        latin: "Bos Sbov"
      },
      id: "22040201"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "22040202"
    },
    {
      name: {
        km: "ភ្លង់ចាស់",
        latin: "Phlong Chas"
      },
      id: "22040203"
    },
    {
      name: {
        km: "អូរព្រាល",
        latin: "Ou Preal"
      },
      id: "22040204"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "22040205"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "22040206"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "22040207"
    },
    {
      name: {
        km: "ភ្លង់ថ្មី",
        latin: "Phlong Thmei"
      },
      id: "22040208"
    },
    {
      name: {
        km: "ខ្ទុំ",
        latin: "Khtum"
      },
      id: "22040301"
    },
    {
      name: {
        km: "តាម៉ាន",
        latin: "Ta Man"
      },
      id: "22040302"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "22040303"
    },
    {
      name: {
        km: "អន្លង់វែង",
        latin: "Anlong Veaeng"
      },
      id: "22040304"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "22040305"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "22040306"
    },
    {
      name: {
        km: "ត្រពាំងស្លែង",
        latin: "Trapeang Slaeng"
      },
      id: "22040307"
    },
    {
      name: {
        km: "គោកប្រាសាទ",
        latin: "Kouk Prasat"
      },
      id: "22040308"
    },
    {
      name: {
        km: "ក្ដុល",
        latin: "Kdol"
      },
      id: "22040309"
    },
    {
      name: {
        km: "គីរីវ័័ន្ដ",
        latin: "Kirivoant"
      },
      id: "22040310"
    },
    {
      name: {
        km: "ចំប៉ាសុខ",
        latin: "Champa Sokh"
      },
      id: "22040311"
    },
    {
      name: {
        km: "ផ្អុង",
        latin: "Ph'ong"
      },
      id: "22040312"
    },
    {
      name: {
        km: "កូនក្រៀល",
        latin: "Koun Kriel"
      },
      id: "22040314"
    },
    {
      name: {
        km: "ឈើក្រំ",
        latin: "Chheu Kram"
      },
      id: "22040315"
    },
    {
      name: {
        km: "អូរពក",
        latin: "Ou Pok"
      },
      id: "22040316"
    },
    {
      name: {
        km: "ដងទង់",
        latin: "Dorng Tong"
      },
      id: "22040317"
    },
    {
      name: {
        km: "រំដួលជើងភ្នំ",
        latin: "Romdoul Cheungphnom"
      },
      id: "22040318"
    },
    {
      name: {
        km: "ឈូកមាស",
        latin: "Chhouk meas"
      },
      id: "22040319"
    },
    {
      name: {
        km: "គោកឈូក",
        latin: "Kouk Chhuk"
      },
      id: "22040320"
    },
    {
      name: {
        km: "គោកភ្លុក",
        latin: "Kouk Phluk"
      },
      id: "22040321"
    },
    {
      name: {
        km: "ត្រពាំងទូង",
        latin: "Trapeang Toung"
      },
      id: "22040322"
    },
    {
      name: {
        km: "គោកអម្ពិល",
        latin: "Kouk Ampil"
      },
      id: "22040323"
    },
    {
      name: {
        km: "អូរឫស្សី",
        latin: "Ou Ruessei"
      },
      id: "22040324"
    },
    {
      name: {
        km: "ស្រះព្រិច",
        latin: "Sras Prich"
      },
      id: "22040325"
    },
    {
      name: {
        km: "ជ្រឹងខាងកើត",
        latin: "Chrueng Khang Kaeut"
      },
      id: "22040326"
    },
    {
      name: {
        km: "ជ្រឹងខាងលិច",
        latin: "Chrueng Khang Lech"
      },
      id: "22040327"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Boss"
      },
      id: "22040328"
    },
    {
      name: {
        km: "គោកចាន់រី",
        latin: "Kouk Chanry"
      },
      id: "22040329"
    },
    {
      name: {
        km: "សំរោងសែនជ័យ១",
        latin: "Samroang Saenchey 1"
      },
      id: "22040330"
    },
    {
      name: {
        km: "សំរោងសែនជ័យ២",
        latin: "Samroang Saenchey 2"
      },
      id: "22040331"
    },
    {
      name: {
        km: "ដូនកែន",
        latin: "Doun Kaen"
      },
      id: "22040401"
    },
    {
      name: {
        km: "អូរឫស្សី",
        latin: "Ou Ruessei"
      },
      id: "22040402"
    },
    {
      name: {
        km: "ពុល",
        latin: "Pul"
      },
      id: "22040403"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "22040404"
    },
    {
      name: {
        km: "អូរក្រវ៉ាន់",
        latin: "Ou Kravan"
      },
      id: "22040405"
    },
    {
      name: {
        km: "ភ្នៀត",
        latin: "Phniet"
      },
      id: "22040406"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "22040407"
    },
    {
      name: {
        km: "កណ្ដែក",
        latin: "Kandaek"
      },
      id: "22040408"
    },
    {
      name: {
        km: "គោកចំបក់",
        latin: "Kouk Chambak"
      },
      id: "22040409"
    },
    {
      name: {
        km: "គោកច្រេស",
        latin: "Kouk Chres"
      },
      id: "22040410"
    },
    {
      name: {
        km: "គោកគរ",
        latin: "Kouk Kor"
      },
      id: "22040411"
    },
    {
      name: {
        km: "គោករំដួល",
        latin: "Kouk Rumduol"
      },
      id: "22040412"
    },
    {
      name: {
        km: "ឆ្អើប",
        latin: "Chh'aeub"
      },
      id: "22040413"
    },
    {
      name: {
        km: "អូរកន្សែង",
        latin: "Ou Kansaeng"
      },
      id: "22040414"
    },
    {
      name: {
        km: "កូនដំរី",
        latin: "Koun Damrei"
      },
      id: "22040415"
    },
    {
      name: {
        km: "បាក់នឹម",
        latin: "Bak Nuem"
      },
      id: "22040416"
    },
    {
      name: {
        km: "បុរីរដ្ឋបាល",
        latin: "Borei Rothbal"
      },
      id: "22040417"
    },
    {
      name: {
        km: "ដូនកែវសែនជ័យ",
        latin: "Doun Keosenchey "
      },
      id: "22040418"
    },
    {
      name: {
        km: "អភិវឌ្ឍន៍",
        latin: "Akphivodth"
      },
      id: "22040501"
    },
    {
      name: {
        km: "ចំការចេក",
        latin: "Chamkar Chek"
      },
      id: "22040502"
    },
    {
      name: {
        km: "ដើមជ្រៃ",
        latin: "Derm Chrey"
      },
      id: "22040503"
    },
    {
      name: {
        km: "គីរីមង្គល",
        latin: "Kiri Mongkol"
      },
      id: "22040504"
    },
    {
      name: {
        km: "រួតចំប៉ី",
        latin: "Ruot Champei"
      },
      id: "22040505"
    },
    {
      name: {
        km: "អូរស្មាច់",
        latin: "Ou Smach"
      },
      id: "22040506"
    },
    {
      name: {
        km: "ស្រះទឹក",
        latin: "Sras Teuk"
      },
      id: "22040507"
    },
    {
      name: {
        km: "អូរខ្លាឃ្មុំ",
        latin: "Ou Khla khmum"
      },
      id: "22040508"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "22050101"
    },
    {
      name: {
        km: "សុខសិរី",
        latin: "Sokh Serei"
      },
      id: "22050102"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "22050103"
    },
    {
      name: {
        km: "ស្រែល្អ",
        latin: "Srae L'a"
      },
      id: "22050104"
    },
    {
      name: {
        km: "ទួលប្រសើរ",
        latin: "Tuol Prasaeur"
      },
      id: "22050105"
    },
    {
      name: {
        km: "ត្រពាំងប្រិយ៍",
        latin: "Trapeang Prey"
      },
      id: "22050106"
    },
    {
      name: {
        km: "ច្រោក",
        latin: "Chraok"
      },
      id: "22050201"
    },
    {
      name: {
        km: "អូរជីក",
        latin: "Ou Chik"
      },
      id: "22050202"
    },
    {
      name: {
        km: "ផ្អាវ",
        latin: "Ph'av"
      },
      id: "22050203"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "22050204"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "22050206"
    },
    {
      name: {
        km: "ផ្តៀកជ្រុំ",
        latin: "Phdeak Chrum"
      },
      id: "22050207"
    },
    {
      name: {
        km: "អូរបេង",
        latin: "Ou Beng"
      },
      id: "22050208"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnol Keng"
      },
      id: "22050209"
    },
    {
      name: {
        km: "ពពេល",
        latin: "Por Pel"
      },
      id: "22050210"
    },
    {
      name: {
        km: "ខ្នងទួលមានជ័យ",
        latin: "Knong Tuol Mean Chey"
      },
      id: "22050211"
    },
    {
      name: {
        km: "តាម៉ូតមានជ័យ",
        latin: "Ta Mod Mean Chey"
      },
      id: "22050212"
    },
    {
      name: {
        km: "ពពេលសែនជ័យ",
        latin: "Popel Saenchey"
      },
      id: "22050213"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "22050301"
    },
    {
      name: {
        km: "អូរល្ហុង",
        latin: "Ou Lhong"
      },
      id: "22050302"
    },
    {
      name: {
        km: "អូរស្វាយ",
        latin: "Ou svay"
      },
      id: "22050303"
    },
    {
      name: {
        km: "ពាមក្នុង",
        latin: "Peam Knong"
      },
      id: "22050304"
    },
    {
      name: {
        km: "តាសំ",
        latin: "Ta Sam"
      },
      id: "22050306"
    },
    {
      name: {
        km: "សែនសំ",
        latin: "Sen Sam"
      },
      id: "22050307"
    },
    {
      name: {
        km: "ទំនប់អភិវឌ្ឍន៍",
        latin: "Toumnoub Akphivorth"
      },
      id: "22050308"
    },
    {
      name: {
        km: "បន្ទាយចាស់",
        latin: "Banteay Chas"
      },
      id: "22050402"
    },
    {
      name: {
        km: "អូររំដួល",
        latin: "Ou Rumduol"
      },
      id: "22050404"
    },
    {
      name: {
        km: "ព្រះប្រឡាយ",
        latin: "Preah Pralay"
      },
      id: "22050405"
    },
    {
      name: {
        km: "ត្រាំប៉ោង",
        latin: "Tram Paong"
      },
      id: "22050406"
    },
    {
      name: {
        km: "ត្រាំចាន",
        latin: "Tram Chan"
      },
      id: "22050407"
    },
    {
      name: {
        km: "ត្រាំប៉ោងខាងត្បូង",
        latin: "Tram Paong Khang Tboung"
      },
      id: "22050408"
    },
    {
      name: {
        km: "ប្រាសាទក្រហមសែនជ័យ",
        latin: "Prasat Krohom Saenchey"
      },
      id: "22050409"
    },
    {
      name: {
        km: "សែនសុខ",
        latin: "Saen Sokh"
      },
      id: "22050504"
    },
    {
      name: {
        km: "ទំនប់ដាច់",
        latin: "Tumnob Dach"
      },
      id: "22050505"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "22050506"
    },
    {
      name: {
        km: "ទួលចារ",
        latin: "Tuol Char"
      },
      id: "22050507"
    },
    {
      name: {
        km: "ទួលប្រាសាទ",
        latin: "Tuol Prasat"
      },
      id: "22050508"
    },
    {
      name: {
        km: "ជ័យនិវត្ដន៍",
        latin: "Chey Nivoat"
      },
      id: "22050509"
    },
    {
      name: {
        km: "ឈូកស",
        latin: "Chhuk Sa"
      },
      id: "22050601"
    },
    {
      name: {
        km: "អូរក្រូច",
        latin: "Ou Krouch"
      },
      id: "22050602"
    },
    {
      name: {
        km: "ព្រៃស្អាក",
        latin: "Prey S'ak"
      },
      id: "22050603"
    },
    {
      name: {
        km: "ស្រះជ្រៃ",
        latin: "Srah Chrey"
      },
      id: "22050604"
    },
    {
      name: {
        km: "ទំនប់ថ្មី",
        latin: "Tumnob Thmei"
      },
      id: "22050605"
    },
    {
      name: {
        km: "ទួលតាសេក",
        latin: "Tuol Ta Sek"
      },
      id: "22050606"
    },
    {
      name: {
        km: "ត្រពាំងប្រាសាទ",
        latin: "Trapeang Prasat"
      },
      id: "22050607"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "22050608"
    },
    {
      name: {
        km: "អូរសោម",
        latin: "O Saom"
      },
      id: "22050609"
    },
    {
      name: {
        km: "ស្រែកណ្តាល",
        latin: "Sre Korndal"
      },
      id: "22050610"
    },
    {
      name: {
        km: "ពានមាស",
        latin: "Pean Meas"
      },
      id: "22050611"
    },
    {
      name: {
        km: "អូរត្រាច",
        latin: "Ou Trach"
      },
      id: "22050612"
    },
    {
      name: {
        km: "ស្រែក្រសាំង",
        latin: "Sre Krorsang"
      },
      id: "22050613"
    },
    {
      name: {
        km: "អូររំដេង",
        latin: "Ou Romdeng"
      },
      id: "22050614"
    },
    {
      name: {
        km: "តេជោអភិវឌ្ឍន៌",
        latin: "De Chor Akpiwat"
      },
      id: "22050615"
    },
    {
      name: {
        km: "អំពេង",
        latin: "Ampeng"
      },
      id: "23010101"
    },
    {
      name: {
        km: "ទួលសាងាំ",
        latin: "Tuol Sangam"
      },
      id: "23010102"
    },
    {
      name: {
        km: "កោះសោម",
        latin: "Kaoh Saom"
      },
      id: "23010103"
    },
    {
      name: {
        km: "អង្កោល",
        latin: "Angkaol"
      },
      id: "23010104"
    },
    {
      name: {
        km: "អូរដូង",
        latin: "Ou Doung"
      },
      id: "23010301"
    },
    {
      name: {
        km: "ព្រៃតាកុយ",
        latin: "Prey Ta Koy"
      },
      id: "23010302"
    },
    {
      name: {
        km: "ភ្នំលាវ",
        latin: "Phnum Leav"
      },
      id: "23010303"
    },
    {
      name: {
        km: "រនេស",
        latin: "Rones"
      },
      id: "23010304"
    },
    {
      name: {
        km: "ចំការបី",
        latin: "Chamkar Bei"
      },
      id: "23010305"
    },
    {
      name: {
        km: "ចំការចេក",
        latin: "Chamka Chek"
      },
      id: "23010306"
    },
    {
      name: {
        km: "អន្ទង់ស",
        latin: "Antung Sar"
      },
      id: "23010307"
    },
    {
      name: {
        km: "កែប",
        latin: "Kaeb"
      },
      id: "23020101"
    },
    {
      name: {
        km: "កែវក្រសាំង",
        latin: "Kaev Krasang"
      },
      id: "23020102"
    },
    {
      name: {
        km: "ដំណាក់ចង្អើរ",
        latin: "Damnak Chang'aeur"
      },
      id: "23020201"
    },
    {
      name: {
        km: "កំពង់ត្រឡាច",
        latin: "Kampong Tralach"
      },
      id: "23020202"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "23020203"
    },
    {
      name: {
        km: "អូរក្រសា",
        latin: "Ou Krasar"
      },
      id: "23020301"
    },
    {
      name: {
        km: "ដំណាក់ចំបក់",
        latin: "Damnak Chambak"
      },
      id: "23020302"
    },
    {
      name: {
        km: "ប៉ាហ៊ីត្បូង",
        latin: "Pahi Tboung"
      },
      id: "24010101"
    },
    {
      name: {
        km: "អូរតាពុកលើ",
        latin: "Ou Ta Puk Leu"
      },
      id: "24010102"
    },
    {
      name: {
        km: "អូរតាប្រាង",
        latin: "Ou Ta Prang"
      },
      id: "24010103"
    },
    {
      name: {
        km: "សួនអំពៅលិច",
        latin: "Suon Ampov Lech"
      },
      id: "24010104"
    },
    {
      name: {
        km: "សួនអំំពៅកើត",
        latin: "Suon Ampov kaeut"
      },
      id: "24010105"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "24010106"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "24010107"
    },
    {
      name: {
        km: "តាង៉ែនលើ",
        latin: "Ta Ngaen Leu"
      },
      id: "24010108"
    },
    {
      name: {
        km: "បឌិននៀវ",
        latin: "Badin Niev"
      },
      id: "24010109"
    },
    {
      name: {
        km: "ទួលខៀវ",
        latin: "Tuol Khiev"
      },
      id: "24010110"
    },
    {
      name: {
        km: "ប៉ាហ៊ីជើង",
        latin: "Pahi Cheung"
      },
      id: "24010111"
    },
    {
      name: {
        km: "ពេជ្យគិរី",
        latin: "Pech Kiri"
      },
      id: "24010112"
    },
    {
      name: {
        km: "ទឹកផុស",
        latin: "Teok Phos"
      },
      id: "24010113"
    },
    {
      name: {
        km: "ខ្លុង",
        latin: "Khlong"
      },
      id: "24010201"
    },
    {
      name: {
        km: "កូនភ្នំ",
        latin: "Koun Phnum"
      },
      id: "24010202"
    },
    {
      name: {
        km: "អូរតាវ៉ៅ",
        latin: "Ou Ta Vau"
      },
      id: "24010203"
    },
    {
      name: {
        km: "ក្បាលព្រលាន",
        latin: "Kbal Pralean"
      },
      id: "24010204"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "24010205"
    },
    {
      name: {
        km: "អូរប្រើស",
        latin: "Ou Preus"
      },
      id: "24010206"
    },
    {
      name: {
        km: "ក្រចាប់",
        latin: "Krachab"
      },
      id: "24010207"
    },
    {
      name: {
        km: "អូរខ្ទីង",
        latin: "Ou Kting"
      },
      id: "24010208"
    },
    {
      name: {
        km: "ព្រៃមង្គល",
        latin: "Prey Mongkul"
      },
      id: "24010209"
    },
    {
      name: {
        km: "ប៉ាងរលឹម",
        latin: "Pang Rolem"
      },
      id: "24010210"
    },
    {
      name: {
        km: "ទួលល្វា",
        latin: "Tuol Lvea"
      },
      id: "24010301"
    },
    {
      name: {
        km: "ចំការកាហ្វេ",
        latin: "Chamkar Kaphe"
      },
      id: "24010302"
    },
    {
      name: {
        km: "អូរច្រាកណ្ដាល",
        latin: "Ou Chra Kandal"
      },
      id: "24010303"
    },
    {
      name: {
        km: "អូរច្រាកើត",
        latin: "Ou Chra Kaeut"
      },
      id: "24010304"
    },
    {
      name: {
        km: "អូរច្រាលើ",
        latin: "Ou Chra Leu"
      },
      id: "24010305"
    },
    {
      name: {
        km: "អូរតាពុកក្រោម",
        latin: "Ou Ta Puk Kraom"
      },
      id: "24010306"
    },
    {
      name: {
        km: "អូរពឺត",
        latin: "Ou Peut"
      },
      id: "24010307"
    },
    {
      name: {
        km: "ទួលស្រឡៅ",
        latin: "Tuol Sralau"
      },
      id: "24010308"
    },
    {
      name: {
        km: "ទួលនិមិត្ដ",
        latin: "Tuol Nimitt"
      },
      id: "24010309"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "24010310"
    },
    {
      name: {
        km: "វាលវង់",
        latin: "Veal Vong"
      },
      id: "24010311"
    },
    {
      name: {
        km: "អូរច្រារលិច",
        latin: "Ou Chra Lech"
      },
      id: "24010401"
    },
    {
      name: {
        km: "អូរស្ងួត",
        latin: "Ou Snguot"
      },
      id: "24010402"
    },
    {
      name: {
        km: "រោងចក្រ",
        latin: "Roungchak"
      },
      id: "24010403"
    },
    {
      name: {
        km: "បរយ៉ាខា",
        latin: "Bar Yakha"
      },
      id: "24010404"
    },
    {
      name: {
        km: "បរតាំងស៊ូ",
        latin: "Bar Tangsu"
      },
      id: "24010405"
    },
    {
      name: {
        km: "បរហ៊ុយខ្មែរជើង",
        latin: "Bar Huy Khmer Cheung"
      },
      id: "24010406"
    },
    {
      name: {
        km: "បរហ៊ុយខ្មែរត្បូង",
        latin: "Bar Huy Khmer Tboung"
      },
      id: "24010407"
    },
    {
      name: {
        km: "ភ្នំស្ពង់",
        latin: "Phnum Spong"
      },
      id: "24020102"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "24020103"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "24020104"
    },
    {
      name: {
        km: "លាវ",
        latin: "Leav"
      },
      id: "24020105"
    },
    {
      name: {
        km: "ភ្នំកុយ",
        latin: "Phnum Koy"
      },
      id: "24020106"
    },
    {
      name: {
        km: "កោះកែវ",
        latin: "Kaoh Kaev"
      },
      id: "24020108"
    },
    {
      name: {
        km: "ស្រែអន្ទាក់",
        latin: "Srae Anteak"
      },
      id: "24020109"
    },
    {
      name: {
        km: "អូរឬស្សីក្រោម",
        latin: "Ou Ruessei Kraom"
      },
      id: "24020113"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "24020204"
    },
    {
      name: {
        km: "ស្ទឹងត្រង់",
        latin: "Stueng Trang"
      },
      id: "24020205"
    },
    {
      name: {
        km: "ភ្នំក្រិញ",
        latin: "Phnum Krenh"
      },
      id: "24020207"
    },
    {
      name: {
        km: "អូរកន្ឋៀងវ៉ា",
        latin: "Ou Kanthieng Va"
      },
      id: "24020208"
    },
    {
      name: {
        km: "បាយសី",
        latin: "Bay Sei"
      },
      id: "24020209"
    },
    {
      name: {
        km: "ភ្នំព្រាល",
        latin: "Phnum Preal"
      },
      id: "24020210"
    },
    {
      name: {
        km: "ដីស្អិត",
        latin: "Dei S'et"
      },
      id: "24020211"
    },
    {
      name: {
        km: "អូរដូនតាក្រោម",
        latin: "Ou Dounta Kraom"
      },
      id: "24020212"
    },
    {
      name: {
        km: "ព្រៃសន្ទះ",
        latin: "Prey Santeah"
      },
      id: "24020213"
    },
    {
      name: {
        km: "អូរដូនតាលើ",
        latin: "Ou Dounta Leu"
      },
      id: "24020214"
    },
    {
      name: {
        km: "ផ្ទះស្បូវ",
        latin: "Phteah Sbov"
      },
      id: "24020215"
    },
    {
      name: {
        km: "ទំនប់",
        latin: "Tumnob"
      },
      id: "24020216"
    },
    {
      name: {
        km: "កូនដំរី",
        latin: "Koun Damrei"
      },
      id: "24020217"
    },
    {
      name: {
        km: "ទួលខ្ពស់",
        latin: "Tuol Khpos"
      },
      id: "24020218"
    },
    {
      name: {
        km: "ដីសថ្មី",
        latin: "Dei Sa Thmei"
      },
      id: "24020219"
    },
    {
      name: {
        km: "អន្លង់រក្សា",
        latin: "Anlong Reaksar"
      },
      id: "24020220"
    },
    {
      name: {
        km: "ក្ងោក",
        latin: "Kngaok"
      },
      id: "24020301"
    },
    {
      name: {
        km: "តាង៉ែនក្រោម",
        latin: "Ta Ngaen Kraom"
      },
      id: "24020302"
    },
    {
      name: {
        km: "អូរបេង",
        latin: "Ou Beng"
      },
      id: "24020303"
    },
    {
      name: {
        km: "បុសស្អំ",
        latin: "Bos S'am"
      },
      id: "24020304"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "24020305"
    },
    {
      name: {
        km: "ស្ទឹងកាច់",
        latin: "Stueng Kach"
      },
      id: "24020306"
    },
    {
      name: {
        km: "បឹងព្រលិត",
        latin: "Boeng Prolit"
      },
      id: "24020307"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "24020308"
    },
    {
      name: {
        km: "រថក្រោះឆេះ",
        latin: "Rathkraoh Chheh"
      },
      id: "24020309"
    },
    {
      name: {
        km: "ទឹកចេញ",
        latin: "Tuek Chenh"
      },
      id: "24020310"
    },
    {
      name: {
        km: "អូររអិល",
        latin: "Ou Ro'el"
      },
      id: "24020311"
    },
    {
      name: {
        km: "ផ្សារព្រំ",
        latin: "Phsar Prum"
      },
      id: "24020312"
    },
    {
      name: {
        km: "អូរឈើក្រំ",
        latin: "Ou Chheu Kram"
      },
      id: "24020313"
    },
    {
      name: {
        km: "ស្រង់មានជ័យ",
        latin: "Srong Meanchey"
      },
      id: "24020314"
    },
    {
      name: {
        km: "អណ្តូងថ្ម",
        latin: "Andoung Thmar"
      },
      id: "24020315"
    },
    {
      name: {
        km: "ផ្សារព្រំជើង",
        latin: "Phsar Prum Choeung"
      },
      id: "24020316"
    },
    {
      name: {
        km: "ថ្នល់ទទឹង",
        latin: "Thnal Totueng"
      },
      id: "24020401"
    },
    {
      name: {
        km: "កូនភ្នំ",
        latin: "Koun Phnum"
      },
      id: "24020402"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "24020403"
    },
    {
      name: {
        km: "បឹងត្រកួន",
        latin: "Boeng Trakuon"
      },
      id: "24020404"
    },
    {
      name: {
        km: "អូរឫស្សីលើ",
        latin: "Ou Ruessei Leu"
      },
      id: "24020405"
    },
    {
      name: {
        km: "អូរអណ្ដូង",
        latin: "Ou Andoung"
      },
      id: "24020406"
    },
    {
      name: {
        km: "អូរទឹកភ្លាវ",
        latin: "Ou Teuk Pleav"
      },
      id: "24020407"
    },
    {
      name: {
        km: "អូរចិតប្រាំ",
        latin: "Ou Chet Pram"
      },
      id: "24020408"
    },
    {
      name: {
        km: "ស្រះពីរ",
        latin: "Sras Pir"
      },
      id: "24020409"
    },
    {
      name: {
        km: "គោកស្រឡៅ",
        latin: "Kouk Sralau"
      },
      id: "25010101"
    },
    {
      name: {
        km: "តារាម",
        latin: "Ta Ream"
      },
      id: "25010102"
    },
    {
      name: {
        km: "តាមាឃចាស់",
        latin: "Ta Meakh Chas"
      },
      id: "25010103"
    },
    {
      name: {
        km: "តាមាឃថ្មី",
        latin: "Ta Meakh Thmei"
      },
      id: "25010104"
    },
    {
      name: {
        km: "ជាចកើត",
        latin: "Cheach Kaeut"
      },
      id: "25010105"
    },
    {
      name: {
        km: "ជាចជើង",
        latin: "Cheach Cheung"
      },
      id: "25010106"
    },
    {
      name: {
        km: "ជាចធំ",
        latin: "Cheach Thum"
      },
      id: "25010107"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "25010108"
    },
    {
      name: {
        km: "កូនត្រុំ",
        latin: "Koun Trom"
      },
      id: "25010109"
    },
    {
      name: {
        km: "ចារស្ទឹងតាថុក",
        latin: "Char Stueng Ta Thok"
      },
      id: "25010110"
    },
    {
      name: {
        km: "ចារធំ",
        latin: "Char Thum"
      },
      id: "25010111"
    },
    {
      name: {
        km: "ចារស្វាយប៉ាក់",
        latin: "Char Svay Pak"
      },
      id: "25010112"
    },
    {
      name: {
        km: "ពន្លាក",
        latin: "Ponleak"
      },
      id: "25010113"
    },
    {
      name: {
        km: "តំបែរ",
        latin: "Dambae"
      },
      id: "25010201"
    },
    {
      name: {
        km: "ជ្រៃភ្លូក",
        latin: "Chrey Phluk"
      },
      id: "25010202"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "25010203"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "25010204"
    },
    {
      name: {
        km: "ខ្ជាយ",
        latin: "Khcheay"
      },
      id: "25010205"
    },
    {
      name: {
        km: "ស្វាយពពាល",
        latin: "Svay Popeal"
      },
      id: "25010206"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "25010207"
    },
    {
      name: {
        km: "ជ័យសម្បត្ដិ",
        latin: "Chey Sambatt"
      },
      id: "25010208"
    },
    {
      name: {
        km: "សញ្ជ័យសែន",
        latin: "Sach Chey Sen"
      },
      id: "25010209"
    },
    {
      name: {
        km: "ត្រពាំងរំដួល",
        latin: "Trapaeng Rumdul"
      },
      id: "25010210"
    },
    {
      name: {
        km: "ស៊ងឃ្វាង",
        latin: "Sorng Khveang"
      },
      id: "25010301"
    },
    {
      name: {
        km: "គោកចារ",
        latin: "Kouk Char"
      },
      id: "25010302"
    },
    {
      name: {
        km: "ត្រពាំងស្រង៉ែ",
        latin: "Trapeang Srangae"
      },
      id: "25010303"
    },
    {
      name: {
        km: "គោកស្រុក",
        latin: "Kouk Srok"
      },
      id: "25010304"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "25010305"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "25010306"
    },
    {
      name: {
        km: "វាលអណ្ដើក",
        latin: "Veal Andaeuk"
      },
      id: "25010307"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "25010308"
    },
    {
      name: {
        km: "ដូនមាស",
        latin: "Doun Meas"
      },
      id: "25010309"
    },
    {
      name: {
        km: "ខ្នុរ",
        latin: "Khnor"
      },
      id: "25010401"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "25010402"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "25010403"
    },
    {
      name: {
        km: "នាងទើត",
        latin: "Neang Teut"
      },
      id: "25010404"
    },
    {
      name: {
        km: "សង្គម",
        latin: "Sangkum"
      },
      id: "25010405"
    },
    {
      name: {
        km: "កំពង់រាំង",
        latin: "Kampong Reang"
      },
      id: "25010501"
    },
    {
      name: {
        km: "វាលតូច",
        latin: "Veal Touch"
      },
      id: "25010502"
    },
    {
      name: {
        km: "ស្វាយកាំបិត",
        latin: "Svay Kambet"
      },
      id: "25010503"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "25010504"
    },
    {
      name: {
        km: "សំព័រ",
        latin: "Sampoar"
      },
      id: "25010505"
    },
    {
      name: {
        km: "អណ្ដូងល្ងៀង",
        latin: "Andoung Lngieng"
      },
      id: "25010506"
    },
    {
      name: {
        km: "តាកែវ",
        latin: "Ta Kaev"
      },
      id: "25010507"
    },
    {
      name: {
        km: "ទួលប្រោះ",
        latin: "Tuol Praoh"
      },
      id: "25010508"
    },
    {
      name: {
        km: "ជីធាង",
        latin: "Chi Theang"
      },
      id: "25010509"
    },
    {
      name: {
        km: "ឈូងតាសៅ",
        latin: "Chhung Ta Sau"
      },
      id: "25010510"
    },
    {
      name: {
        km: "ស្រែខ្សាច់",
        latin: "Srae Khsach"
      },
      id: "25010511"
    },
    {
      name: {
        km: "សេដាសែនជ័យ",
        latin: "Seda Sen Chey"
      },
      id: "25010512"
    },
    {
      name: {
        km: "រពាក់ ១",
        latin: "Rum Peak 1"
      },
      id: "25010513"
    },
    {
      name: {
        km: "រពាក់ ២",
        latin: "Rum Peak 2"
      },
      id: "25010514"
    },
    {
      name: {
        km: "រពាក់ ៣",
        latin: "Rum Peak 3"
      },
      id: "25010515"
    },
    {
      name: {
        km: "ខ្នាចជុំ",
        latin: "Khnach Chum"
      },
      id: "25010516"
    },
    {
      name: {
        km: "ដង្ហា",
        latin: "Dangha"
      },
      id: "25010517"
    },
    {
      name: {
        km: "អណ្តូងចំបក់",
        latin: "Andoung Cham Bok"
      },
      id: "25010518"
    },
    {
      name: {
        km: "សែនជុំ",
        latin: "Sen Chum"
      },
      id: "25010519"
    },
    {
      name: {
        km: "សែនចម្រើន",
        latin: "Sen Chamroeun"
      },
      id: "25010520"
    },
    {
      name: {
        km: "បេងថ្មី",
        latin: "Beng Thmei"
      },
      id: "25010521"
    },
    {
      name: {
        km: "ព្រែកជ័រ",
        latin: "Praek Chor"
      },
      id: "25010522"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Tnoal Kaeng"
      },
      id: "25010523"
    },
    {
      name: {
        km: "អណ្តូងពេជ្រ",
        latin: "Andoung Pech"
      },
      id: "25010524"
    },
    {
      name: {
        km: "បង្ហើខ្លែង",
        latin: "Banghaeu Khlaeng"
      },
      id: "25010601"
    },
    {
      name: {
        km: "ស្រែកក់",
        latin: "Srae Kak"
      },
      id: "25010602"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "25010603"
    },
    {
      name: {
        km: "ប្រឡោះ",
        latin: "Pralaoh"
      },
      id: "25010604"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "25010605"
    },
    {
      name: {
        km: "កំប្រើស",
        latin: "Kampraeus"
      },
      id: "25010606"
    },
    {
      name: {
        km: "ស្រែប្រាំង",
        latin: "Srae Prang"
      },
      id: "25010607"
    },
    {
      name: {
        km: "បុស្នោរ",
        latin: "Bos Snaor"
      },
      id: "25010608"
    },
    {
      name: {
        km: "ជីទ្រុន",
        latin: "Chi Trun"
      },
      id: "25010609"
    },
    {
      name: {
        km: "ទួលសំបូរណ៍",
        latin: "Tuol Sambour"
      },
      id: "25010610"
    },
    {
      name: {
        km: "ជ័យសុខសាន្ត",
        latin: "Chey Sok San"
      },
      id: "25010611"
    },
    {
      name: {
        km: "សែនមនោរ័ត្ន",
        latin: "Sen Monorath"
      },
      id: "25010612"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "25010701"
    },
    {
      name: {
        km: "ទឹកជ្រៅ",
        latin: "Tuek Chrov"
      },
      id: "25010702"
    },
    {
      name: {
        km: "មេសរ",
        latin: "Me Sar"
      },
      id: "25010703"
    },
    {
      name: {
        km: "ឃ្លៃ",
        latin: "Khley"
      },
      id: "25010704"
    },
    {
      name: {
        km: "ផ្អាវ",
        latin: "Ph'av"
      },
      id: "25010705"
    },
    {
      name: {
        km: "ស្រម៉រ",
        latin: "Sramar"
      },
      id: "25010706"
    },
    {
      name: {
        km: "ជាំត្រកួន",
        latin: "Choam Trakuon"
      },
      id: "25010707"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "25010708"
    },
    {
      name: {
        km: "ស្រែវែង",
        latin: "Srae Veaeng"
      },
      id: "25010709"
    },
    {
      name: {
        km: "ត្របែកថ្មី",
        latin: "Trabaek Thmei"
      },
      id: "25010710"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "25020101"
    },
    {
      name: {
        km: "ផ្កាដូង",
        latin: "Phka Doung"
      },
      id: "25020102"
    },
    {
      name: {
        km: "ក្របីក្រៀក",
        latin: "Krabei Kriek"
      },
      id: "25020103"
    },
    {
      name: {
        km: "ច្រវ៉ាក់ដែក",
        latin: "Chravak Daek"
      },
      id: "25020104"
    },
    {
      name: {
        km: "បារ៉ាយ",
        latin: "Baray"
      },
      id: "25020105"
    },
    {
      name: {
        km: "រួមវិញ",
        latin: "Ruom Vinh"
      },
      id: "25020106"
    },
    {
      name: {
        km: "ស្រះត្រកួន",
        latin: "Srah Trakuon"
      },
      id: "25020107"
    },
    {
      name: {
        km: "បុសស្វាយ",
        latin: "Bos Svay"
      },
      id: "25020108"
    },
    {
      name: {
        km: "ព្រែកតាហុប",
        latin: "Preaek Ta Hob"
      },
      id: "25020201"
    },
    {
      name: {
        km: "ជំនីក",
        latin: "Chumnik"
      },
      id: "25020202"
    },
    {
      name: {
        km: "ស្វាយតំណាក់",
        latin: "Svay Damnak"
      },
      id: "25020203"
    },
    {
      name: {
        km: "បន្ទាយ",
        latin: "Banteay"
      },
      id: "25020204"
    },
    {
      name: {
        km: "ភូមិទី១",
        latin: "Phum Ti Muoy"
      },
      id: "25020301"
    },
    {
      name: {
        km: "ភូមិទី២",
        latin: "Phum Ti Pir"
      },
      id: "25020302"
    },
    {
      name: {
        km: "ភូមិទី៣",
        latin: "Phum Ti Bei"
      },
      id: "25020303"
    },
    {
      name: {
        km: "ភូមិទី៤",
        latin: "Phum Ti Buon"
      },
      id: "25020304"
    },
    {
      name: {
        km: "ភូមិទី៥",
        latin: "Phum Ti Pram"
      },
      id: "25020305"
    },
    {
      name: {
        km: "ភូមិទី៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "25020306"
    },
    {
      name: {
        km: "កោះបីពៃ",
        latin: "Kaoh Bei Pey"
      },
      id: "25020401"
    },
    {
      name: {
        km: "កោះត្រែង",
        latin: "Kaoh Traeng"
      },
      id: "25020402"
    },
    {
      name: {
        km: "កោះម៉ឺននង់",
        latin: "Kaoh Meun Nong"
      },
      id: "25020403"
    },
    {
      name: {
        km: "ជួរកណ្ដាល",
        latin: "Chuor Kandal"
      },
      id: "25020404"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "25020501"
    },
    {
      name: {
        km: "ដើមជ្រៃ",
        latin: "Daeum Chrey"
      },
      id: "25020502"
    },
    {
      name: {
        km: "ក្រូចឆ្មារលើ",
        latin: "Krouch Chhmar Leu"
      },
      id: "25020503"
    },
    {
      name: {
        km: "ក្រូចឆ្មារក្រោម",
        latin: "Krouch Chhmar Kraom"
      },
      id: "25020504"
    },
    {
      name: {
        km: "ខ្សាច់ប្រឆេះលើ",
        latin: "Khsach Prachheh Leu"
      },
      id: "25020505"
    },
    {
      name: {
        km: "ខ្សាច់ប្រឆេះកណ្ដាល",
        latin: "Khsach Prachheh Kandal"
      },
      id: "25020506"
    },
    {
      name: {
        km: "ខ្សាច់ប្រឆេះក្រោម",
        latin: "Khsach Prachheh Kraom"
      },
      id: "25020507"
    },
    {
      name: {
        km: "សោយ ១",
        latin: "Saoy Muoy"
      },
      id: "25020601"
    },
    {
      name: {
        km: "សោយ ២",
        latin: "Saoy Pir"
      },
      id: "25020602"
    },
    {
      name: {
        km: "ព្រែកក្រូច",
        latin: "Preaek Krouch"
      },
      id: "25020603"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "25020604"
    },
    {
      name: {
        km: "កោះផល់",
        latin: "Kaoh Phal"
      },
      id: "25020605"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "25020701"
    },
    {
      name: {
        km: "ប៉ើស",
        latin: "Peus"
      },
      id: "25020702"
    },
    {
      name: {
        km: "ដីដុះ",
        latin: "Dei Doh"
      },
      id: "25020703"
    },
    {
      name: {
        km: "ទួលសម្បត្ដិ",
        latin: "Tuol Sambatt"
      },
      id: "25020704"
    },
    {
      name: {
        km: "ភូមិទី ១",
        latin: "Phum Ti Muoy"
      },
      id: "25020801"
    },
    {
      name: {
        km: "ភូមិទី ២",
        latin: "Phum Ti Pir"
      },
      id: "25020802"
    },
    {
      name: {
        km: "ភូមិទី ៣",
        latin: "Phum Ti Bei"
      },
      id: "25020803"
    },
    {
      name: {
        km: "ភូមិទី ៤",
        latin: "Phum Ti Buon"
      },
      id: "25020804"
    },
    {
      name: {
        km: "ភូមិទី ៥",
        latin: "Phum Ti Pram"
      },
      id: "25020805"
    },
    {
      name: {
        km: "ភូមិទី ៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "25020806"
    },
    {
      name: {
        km: "ភូមិទី ១",
        latin: "Phum Ti Muoy"
      },
      id: "25020901"
    },
    {
      name: {
        km: "ភូមិទី ២",
        latin: "Phum Ti Pir"
      },
      id: "25020902"
    },
    {
      name: {
        km: "ភូមិទី ៣",
        latin: "Phum Ti Bei"
      },
      id: "25020903"
    },
    {
      name: {
        km: "ភូមិទី ៤",
        latin: "Phum Ti Buon"
      },
      id: "25020904"
    },
    {
      name: {
        km: "ភូមិទី ៥",
        latin: "Phum Ti Pram"
      },
      id: "25020905"
    },
    {
      name: {
        km: "ភូមិទី ៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "25020906"
    },
    {
      name: {
        km: "ភូមិទី ៧",
        latin: "Phum Ti Prampir"
      },
      id: "25020907"
    },
    {
      name: {
        km: "ភូមិទី ១",
        latin: "Phum Ti Muoy"
      },
      id: "25021001"
    },
    {
      name: {
        km: "ភូមិទី ២",
        latin: "Phum Ti Pir"
      },
      id: "25021002"
    },
    {
      name: {
        km: "ភូមិទី ៣",
        latin: "Phum Ti Bei"
      },
      id: "25021003"
    },
    {
      name: {
        km: "ភូមិទី ៤",
        latin: "Phum Ti Buon"
      },
      id: "25021004"
    },
    {
      name: {
        km: "ភូមិទី ៥",
        latin: "Phum Ti Pram"
      },
      id: "25021005"
    },
    {
      name: {
        km: "ភូមិទី ៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "25021006"
    },
    {
      name: {
        km: "ក្ដុលលើ",
        latin: "Kdol Leu"
      },
      id: "25021101"
    },
    {
      name: {
        km: "ក្ដុលកណ្ដាល",
        latin: "Kdol Kandal"
      },
      id: "25021102"
    },
    {
      name: {
        km: "ទ្រាទី ១",
        latin: "TreaTi Muoy"
      },
      id: "25021103"
    },
    {
      name: {
        km: "ទ្រាទី ២",
        latin: "Trea Ti Pir"
      },
      id: "25021104"
    },
    {
      name: {
        km: "ទ្រាទី ៣",
        latin: "Trea Ti Bei"
      },
      id: "25021105"
    },
    {
      name: {
        km: "ទ្រាទី ៤",
        latin: "Trea Ti Buon"
      },
      id: "25021106"
    },
    {
      name: {
        km: "ទ្រាទី ៥",
        latin: "Trea Ti Pram"
      },
      id: "25021107"
    },
    {
      name: {
        km: "ក្ដុលក្រោម",
        latin: "Kdol Kraom"
      },
      id: "25021108"
    },
    {
      name: {
        km: "ទួលអុក",
        latin: "Tuol Ok"
      },
      id: "25021201"
    },
    {
      name: {
        km: "មុខភ្នំ",
        latin: "Mukh Phnum"
      },
      id: "25021202"
    },
    {
      name: {
        km: "ទួលរកា",
        latin: "Tuol Roka"
      },
      id: "25021203"
    },
    {
      name: {
        km: "ទូលតាកោ",
        latin: "Tuol Ta Kao"
      },
      id: "25021204"
    },
    {
      name: {
        km: "ទួលស្នួល",
        latin: "Tuol Snuol"
      },
      id: "25021205"
    },
    {
      name: {
        km: "ទួលត្រាច",
        latin: "Tuol Trach"
      },
      id: "25021206"
    },
    {
      name: {
        km: "សង្គមមានជ័យ",
        latin: "Sangkum Mean Chey"
      },
      id: "25021207"
    },
    {
      name: {
        km: "ជើងច្រង់",
        latin: "Cheung Chrang"
      },
      id: "25021208"
    },
    {
      name: {
        km: "តាប៉ាវ",
        latin: "Ta Pav"
      },
      id: "25021209"
    },
    {
      name: {
        km: "បីម៉ែត្រ",
        latin: "Bei Maetr"
      },
      id: "25021210"
    },
    {
      name: {
        km: "ចំរើន",
        latin: "Chamraeun"
      },
      id: "25021211"
    },
    {
      name: {
        km: "ភពថ្មី",
        latin: "Phop Thmei"
      },
      id: "25021212"
    },
    {
      name: {
        km: "ស្រែតានងលិច",
        latin: "Srae Ta Nong Lech"
      },
      id: "25030101"
    },
    {
      name: {
        km: "ស្រែតានងកើត",
        latin: "Srae Ta Nong Kaeut"
      },
      id: "25030102"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "25030103"
    },
    {
      name: {
        km: "ស្អំ",
        latin: "S'am"
      },
      id: "25030104"
    },
    {
      name: {
        km: "ចាន់មូល",
        latin: "Chan Mul"
      },
      id: "25030105"
    },
    {
      name: {
        km: "តាកែវ",
        latin: "Ta Kaev"
      },
      id: "25030106"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "25030107"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "25030108"
    },
    {
      name: {
        km: "តាឡូ",
        latin: "Ta Lou"
      },
      id: "25030109"
    },
    {
      name: {
        km: "កន្ដ្រើយ",
        latin: "Kantraeuy"
      },
      id: "25030110"
    },
    {
      name: {
        km: "អំផុល",
        latin: "Amphol"
      },
      id: "25030111"
    },
    {
      name: {
        km: "ខ្លុងត្បូង",
        latin: "Khlong Tboung"
      },
      id: "25030112"
    },
    {
      name: {
        km: "ងៀវ",
        latin: "Ngiev"
      },
      id: "25030201"
    },
    {
      name: {
        km: "លាចក្រោម",
        latin: "Leach Kraom"
      },
      id: "25030202"
    },
    {
      name: {
        km: "លាចលើ",
        latin: "Leach Leu"
      },
      id: "25030203"
    },
    {
      name: {
        km: "បឹងជ្រោង",
        latin: "Boeng Chroung"
      },
      id: "25030204"
    },
    {
      name: {
        km: "ជាំអំពិល",
        latin: "Choam Ampil"
      },
      id: "25030205"
    },
    {
      name: {
        km: "ជាំ",
        latin: "Choam"
      },
      id: "25030206"
    },
    {
      name: {
        km: "ជើង",
        latin: "Cheung"
      },
      id: "25030207"
    },
    {
      name: {
        km: "មង់",
        latin: "Mong"
      },
      id: "25030208"
    },
    {
      name: {
        km: "ពព្លាំ",
        latin: "Poploam"
      },
      id: "25030209"
    },
    {
      name: {
        km: "ស្ទឹងអង្កាម",
        latin: "Stueng Angkam"
      },
      id: "25030210"
    },
    {
      name: {
        km: "ក្រវៀនធំ",
        latin: "Kravien Thum"
      },
      id: "25030301"
    },
    {
      name: {
        km: "ក្រវៀនជើង",
        latin: "Kravien Cheung"
      },
      id: "25030302"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "25030303"
    },
    {
      name: {
        km: "សាទុំ",
        latin: "Satum"
      },
      id: "25030304"
    },
    {
      name: {
        km: "ថ្មតាដោក",
        latin: "Thma Ta Daok"
      },
      id: "25030305"
    },
    {
      name: {
        km: "ក្បាលស្លែង",
        latin: "Kbal Slaeng"
      },
      id: "25030306"
    },
    {
      name: {
        km: "ម្ខោះ",
        latin: "Mkhaoh"
      },
      id: "25030307"
    },
    {
      name: {
        km: "ម្រាន់",
        latin: "Mroan"
      },
      id: "25030308"
    },
    {
      name: {
        km: "ថ្មដារ",
        latin: "Thma Dar"
      },
      id: "25030309"
    },
    {
      name: {
        km: "ដង្ហិត",
        latin: "Danghet"
      },
      id: "25030310"
    },
    {
      name: {
        km: "ឃ្មួរ",
        latin: "Khmuor"
      },
      id: "25030311"
    },
    {
      name: {
        km: "ប្រីយ៍",
        latin: "Prei"
      },
      id: "25030312"
    },
    {
      name: {
        km: "បង្ហើយហួស",
        latin: "Banghaeuy Huos"
      },
      id: "25030313"
    },
    {
      name: {
        km: "របងច្រុះ",
        latin: "Robang Chroh"
      },
      id: "25030314"
    },
    {
      name: {
        km: "ជីប្លុក",
        latin: "Chi Plok"
      },
      id: "25030315"
    },
    {
      name: {
        km: "ជ្រៃឡើង",
        latin: "Chrey Laeung"
      },
      id: "25030316"
    },
    {
      name: {
        km: "ខ្ជាយ",
        latin: "Khcheay"
      },
      id: "25030317"
    },
    {
      name: {
        km: "ស្រែលើសែនជ័យ",
        latin: "Sre Leu Meanchey"
      },
      id: "25030318"
    },
    {
      name: {
        km: "តាម៉ៅជើង",
        latin: "Ta Mau  Cheung"
      },
      id: "25030401"
    },
    {
      name: {
        km: "តាម៉ៅកើត",
        latin: "Ta Mau Kaeut"
      },
      id: "25030402"
    },
    {
      name: {
        km: "ជំនុំំពល",
        latin: "Chumnum Pol"
      },
      id: "25030403"
    },
    {
      name: {
        km: "សំពៅលូន",
        latin: "Sampov Loun"
      },
      id: "25030404"
    },
    {
      name: {
        km: "ទួលគ្រួស",
        latin: "Tuol Kruos"
      },
      id: "25030405"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "25030406"
    },
    {
      name: {
        km: "អង្កាំ",
        latin: "Angkam"
      },
      id: "25030407"
    },
    {
      name: {
        km: "កន្ទូត",
        latin: "Kantuot"
      },
      id: "25030408"
    },
    {
      name: {
        km: "ថ្មទទឹងជើង",
        latin: "Thma Totueng Cheung"
      },
      id: "25030409"
    },
    {
      name: {
        km: "ស្រែតាពេជ",
        latin: "Srae Ta Pich"
      },
      id: "25030410"
    },
    {
      name: {
        km: "កូនក្រពើ",
        latin: "Koun Krapeu"
      },
      id: "25030411"
    },
    {
      name: {
        km: "បុស្សតាអឹម",
        latin: "Bos Ta Oem"
      },
      id: "25030412"
    },
    {
      name: {
        km: "ថ្មទទឹងត្បូង",
        latin: "Thma Totueng Tboung"
      },
      id: "25030413"
    },
    {
      name: {
        km: "ទ្បាំបោ",
        latin: "Lam Baor"
      },
      id: "25030414"
    },
    {
      name: {
        km: "ដារកណ្ដាល",
        latin: "Dar Kandal"
      },
      id: "25030501"
    },
    {
      name: {
        km: "ដារលិច",
        latin: "Dar Lech"
      },
      id: "25030502"
    },
    {
      name: {
        km: "៧ មករា",
        latin: "Prampir Meakkakra"
      },
      id: "25030503"
    },
    {
      name: {
        km: "ស្ពាន",
        latin: "Spean"
      },
      id: "25030504"
    },
    {
      name: {
        km: "ដារផ្សារ",
        latin: "Dar Phsar"
      },
      id: "25030505"
    },
    {
      name: {
        km: "ស្រែជាំ",
        latin: "Srae Choam"
      },
      id: "25030506"
    },
    {
      name: {
        km: "មែកពុក",
        latin: "Meaek Puk"
      },
      id: "25030507"
    },
    {
      name: {
        km: "ដារត្បូង",
        latin: "Dar Tboung"
      },
      id: "25030508"
    },
    {
      name: {
        km: "ដារកណ្ដាល",
        latin: "Dar Kandal"
      },
      id: "25030509"
    },
    {
      name: {
        km: "ទ្រៀក",
        latin: "Triek"
      },
      id: "25030510"
    },
    {
      name: {
        km: "ឆ្ងរជើង",
        latin: "Chhngar Cheung"
      },
      id: "25030511"
    },
    {
      name: {
        km: "សំរោងជើង",
        latin: "Samraong Cheung"
      },
      id: "25030512"
    },
    {
      name: {
        km: "ដារជើង",
        latin: "Dar Cheung"
      },
      id: "25030513"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "25030514"
    },
    {
      name: {
        km: "ចំការគរ",
        latin: "Chamkar Kor"
      },
      id: "25030515"
    },
    {
      name: {
        km: "ឆ្ងរកណ្ដាល",
        latin: "Chhngar Kandal"
      },
      id: "25030516"
    },
    {
      name: {
        km: "សាឡង់ ទី១",
        latin: "Salang Ti Mouy"
      },
      id: "25030517"
    },
    {
      name: {
        km: "សាឡង់ ទី២",
        latin: "Salang Ti Pir"
      },
      id: "25030518"
    },
    {
      name: {
        km: "កងកេង",
        latin: "Kang Keng"
      },
      id: "25030519"
    },
    {
      name: {
        km: "លោ",
        latin: "Lou"
      },
      id: "25030601"
    },
    {
      name: {
        km: "កំពាន់",
        latin: "Kampoan"
      },
      id: "25030602"
    },
    {
      name: {
        km: "ទឹកទុំ",
        latin: "Tuek Tum"
      },
      id: "25030603"
    },
    {
      name: {
        km: "ស្រែសោមថ្មី",
        latin: "Srae Saom Thmei"
      },
      id: "25030604"
    },
    {
      name: {
        km: "ស្រែសោមចាស់",
        latin: "Srae Saom Chas"
      },
      id: "25030605"
    },
    {
      name: {
        km: "ស្រែកណ្ដាល",
        latin: "Srae Kandal"
      },
      id: "25030606"
    },
    {
      name: {
        km: "ឆ្លូង ១",
        latin: "Chhloung Muoy"
      },
      id: "25030607"
    },
    {
      name: {
        km: "ឆ្លូង ២",
        latin: "Chhloung Pir"
      },
      id: "25030608"
    },
    {
      name: {
        km: "ឆ្លូង ៣",
        latin: "Chhloung Bei"
      },
      id: "25030609"
    },
    {
      name: {
        km: "ព្រែកពួយ",
        latin: "Preaek Puoy"
      },
      id: "25030701"
    },
    {
      name: {
        km: "ក្ងោក",
        latin: "Kngaok"
      },
      id: "25030702"
    },
    {
      name: {
        km: "ស្រែពោល",
        latin: "Srae Poul"
      },
      id: "25030703"
    },
    {
      name: {
        km: "ទួលថ្ម",
        latin: "Tuol Thma"
      },
      id: "25030704"
    },
    {
      name: {
        km: "គគីរជើង",
        latin: "Kokir Cheung"
      },
      id: "25030705"
    },
    {
      name: {
        km: "គគីរត្បូង",
        latin: "Kokir Tboung"
      },
      id: "25030706"
    },
    {
      name: {
        km: "ចំការថ្មី",
        latin: "Chamkar Thmei"
      },
      id: "25030707"
    },
    {
      name: {
        km: "សាឡង់ ៣",
        latin: "Salang Bei"
      },
      id: "25030708"
    },
    {
      name: {
        km: "មេមង",
        latin: "Memong"
      },
      id: "25030801"
    },
    {
      name: {
        km: "សង្កែចាស់",
        latin: "Sangkae Chas"
      },
      id: "25030802"
    },
    {
      name: {
        km: "សង្កែថ្មី",
        latin: "Sangkae Thmei"
      },
      id: "25030803"
    },
    {
      name: {
        km: "ពើក",
        latin: "Peuk"
      },
      id: "25030804"
    },
    {
      name: {
        km: "ជាំខ្យង",
        latin: "Choam Khyang"
      },
      id: "25030805"
    },
    {
      name: {
        km: "ទ្រៀក",
        latin: "Triek"
      },
      id: "25030806"
    },
    {
      name: {
        km: "កំបាស់",
        latin: "Kambas"
      },
      id: "25030807"
    },
    {
      name: {
        km: "ជាច",
        latin: "Cheach"
      },
      id: "25030808"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "25030809"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "25030901"
    },
    {
      name: {
        km: "មុសក្រាស់",
        latin: "Mus Kras"
      },
      id: "25030902"
    },
    {
      name: {
        km: "ឆ្ងរសាលា",
        latin: "Chhngar Sala"
      },
      id: "25030903"
    },
    {
      name: {
        km: "ជីប៉េះ",
        latin: "Chi Peh"
      },
      id: "25030904"
    },
    {
      name: {
        km: "សង្គមមានជ័យថ្មី",
        latin: "Sangkom Mean Chey Thmei"
      },
      id: "25030905"
    },
    {
      name: {
        km: "ជាម្អោរ",
        latin: "Cheam'aor"
      },
      id: "25030906"
    },
    {
      name: {
        km: "ណង់ក្រពើ",
        latin: "Nang Krapeu"
      },
      id: "25030907"
    },
    {
      name: {
        km: "មេមត់កណ្ដាល",
        latin: "Memot Kandal"
      },
      id: "25030908"
    },
    {
      name: {
        km: "ម៉ាសីនទឹក",
        latin: "Masin Tuek"
      },
      id: "25030909"
    },
    {
      name: {
        km: "ត្បូងវត្ដ",
        latin: "Tboung Voat"
      },
      id: "25030910"
    },
    {
      name: {
        km: "មេមត់ផ្សារ",
        latin: "Memot Phsar"
      },
      id: "25030911"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "25030912"
    },
    {
      name: {
        km: "សង្គមមានជ័យចាស់",
        latin: "Sangkom Mean Chey Chas"
      },
      id: "25030913"
    },
    {
      name: {
        km: "ឆ្ងរកើត",
        latin: "Chhngar Kaeut"
      },
      id: "25030914"
    },
    {
      name: {
        km: "មេមត់ថ្មី",
        latin: "Memot Thmei"
      },
      id: "25030915"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "25031001"
    },
    {
      name: {
        km: "ឈើខ្លឹម",
        latin: "Chheu Khloem"
      },
      id: "25031002"
    },
    {
      name: {
        km: "ខ្ពប",
        latin: "Khpob"
      },
      id: "25031003"
    },
    {
      name: {
        km: "ថ្មដាប់",
        latin: "Thma Dab"
      },
      id: "25031004"
    },
    {
      name: {
        km: "កំពៃ",
        latin: "Kampey"
      },
      id: "25031005"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "25031006"
    },
    {
      name: {
        km: "ស្រែពង្រ",
        latin: "Srae Pongro"
      },
      id: "25031007"
    },
    {
      name: {
        km: "ឃ្លៀច",
        latin: "Khliech"
      },
      id: "25031008"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "25031009"
    },
    {
      name: {
        km: "សុខសាន្ត",
        latin: "Sok San"
      },
      id: "25031010"
    },
    {
      name: {
        km: "សែនជ័យ",
        latin: "Sen Chey"
      },
      id: "25031011"
    },
    {
      name: {
        km: "សែនផល",
        latin: "Sen Phal"
      },
      id: "25031012"
    },
    {
      name: {
        km: "សែនប្រពៃ",
        latin: "Sen Prapey"
      },
      id: "25031013"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "25031101"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "25031102"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "25031103"
    },
    {
      name: {
        km: "ជាំទូក",
        latin: "Choam Tuk"
      },
      id: "25031104"
    },
    {
      name: {
        km: "តោញ",
        latin: "Taonh"
      },
      id: "25031105"
    },
    {
      name: {
        km: "អណ្ដូងតាជោ",
        latin: "Andoung Ta Chou"
      },
      id: "25031106"
    },
    {
      name: {
        km: "ម៉ាស៊ីន",
        latin: "Masin"
      },
      id: "25031107"
    },
    {
      name: {
        km: "បុស្ស",
        latin: "Bos"
      },
      id: "25031108"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "25031109"
    },
    {
      name: {
        km: "សូទៃ",
        latin: "Soutey"
      },
      id: "25031110"
    },
    {
      name: {
        km: "ដូនរ័ត្នទី ១",
        latin: "Doun Roath Ti Muoy"
      },
      id: "25031111"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "25031112"
    },
    {
      name: {
        km: "ដូនរ័ត្នទី ២",
        latin: "Doun Roath Ti Pir"
      },
      id: "25031113"
    },
    {
      name: {
        km: "ក្ដុលលើ",
        latin: "Kdol Leu"
      },
      id: "25031201"
    },
    {
      name: {
        km: "ក្ដុលក្រោម",
        latin: "Kdol Kraom"
      },
      id: "25031202"
    },
    {
      name: {
        km: "ក្ដុលផ្សារ",
        latin: "Kdol Phsar"
      },
      id: "25031203"
    },
    {
      name: {
        km: "ចង្គំទី ១",
        latin: "Changkum Ti Muoy"
      },
      id: "25031204"
    },
    {
      name: {
        km: "ចង្គំកណ្ដាល",
        latin: "Changkum Kandal"
      },
      id: "25031205"
    },
    {
      name: {
        km: "ស្ពានចង្គំ",
        latin: "Spean Changkum"
      },
      id: "25031206"
    },
    {
      name: {
        km: "កោះថ្ម",
        latin: "Kaoh Thma"
      },
      id: "25031207"
    },
    {
      name: {
        km: "ស្លាភ្នំ",
        latin: "Sla Phnum"
      },
      id: "25031208"
    },
    {
      name: {
        km: "ម្កោរ",
        latin: "Mkaor"
      },
      id: "25031209"
    },
    {
      name: {
        km: "បេងកោង",
        latin: "Beng Kaong"
      },
      id: "25031210"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "25031211"
    },
    {
      name: {
        km: "ល្វាលើ",
        latin: "Lvea Leu"
      },
      id: "25031212"
    },
    {
      name: {
        km: "ស្លាគីឡូ",
        latin: "Sla Kilou"
      },
      id: "25031213"
    },
    {
      name: {
        km: "អូរខ្លូត",
        latin: "Ou Khlout"
      },
      id: "25031301"
    },
    {
      name: {
        km: "ត្រមែងលើ",
        latin: "Tramaeng Leu"
      },
      id: "25031302"
    },
    {
      name: {
        km: "ត្រមូង",
        latin: "Tramung"
      },
      id: "25031303"
    },
    {
      name: {
        km: "ជាំទ្រៀក",
        latin: "Choam Triek"
      },
      id: "25031304"
    },
    {
      name: {
        km: "អណ្ដូងថ្មលើ",
        latin: "Andoung Thma Leu"
      },
      id: "25031305"
    },
    {
      name: {
        km: "អណ្ដូងថ្មក្រោម",
        latin: "Andoung Thma Kraom"
      },
      id: "25031306"
    },
    {
      name: {
        km: "រោងចក្រស្ករ",
        latin: "Roung Chakr Skar"
      },
      id: "25031307"
    },
    {
      name: {
        km: "ត្រមែងក្រោម",
        latin: "Tramaeng Kraom"
      },
      id: "25031308"
    },
    {
      name: {
        km: "ដូងទី១",
        latin: "Doung Ti Muoy"
      },
      id: "25031309"
    },
    {
      name: {
        km: "ជាំត្រាវ",
        latin: "Choam Trav"
      },
      id: "25031310"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "25031311"
    },
    {
      name: {
        km: "ងើថ្មី",
        latin: "Ngeu Thmei"
      },
      id: "25031312"
    },
    {
      name: {
        km: "ងើធំ",
        latin: "Ngeu Thum"
      },
      id: "25031313"
    },
    {
      name: {
        km: "ត្រពាំងងើ",
        latin: "Trapeang Ngeu"
      },
      id: "25031314"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "25031315"
    },
    {
      name: {
        km: "ខ្នងក្រពើលិច",
        latin: "Khnoang Kra Peu Lech"
      },
      id: "25031316"
    },
    {
      name: {
        km: "ខ្នងក្រពើកើត",
        latin: "Khnoang Kra Peu Kaeut"
      },
      id: "25031317"
    },
    {
      name: {
        km: "ដូងទី២",
        latin: "Doung Ti Pir"
      },
      id: "25031318"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "25031319"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "25031320"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "25031401"
    },
    {
      name: {
        km: "បង្គៅ",
        latin: "Bangkov"
      },
      id: "25031402"
    },
    {
      name: {
        km: "ប្រីយ៍",
        latin: "Prei"
      },
      id: "25031403"
    },
    {
      name: {
        km: "ឃ្លៃ",
        latin: "Khley"
      },
      id: "25031404"
    },
    {
      name: {
        km: "រមាសចូល",
        latin: "Romeas Choul"
      },
      id: "25031405"
    },
    {
      name: {
        km: "ព្រះពន្លា",
        latin: "Preah Ponlea"
      },
      id: "25031406"
    },
    {
      name: {
        km: "សំរោងត្បូង",
        latin: "Samraong Tboung"
      },
      id: "25031407"
    },
    {
      name: {
        km: "ទំពាំងបង្កង់",
        latin: "Tumpeang Bangkang"
      },
      id: "25040101"
    },
    {
      name: {
        km: "ព្រៃស្រឡៅ",
        latin: "Prey Sralau"
      },
      id: "25040102"
    },
    {
      name: {
        km: "លេខ ៥",
        latin: "Lekh Pram"
      },
      id: "25040103"
    },
    {
      name: {
        km: "លេខ ៤លិច",
        latin: "Lekh Buon Lech"
      },
      id: "25040104"
    },
    {
      name: {
        km: "ទួលទន្សោង",
        latin: "Tuol Tonsaong"
      },
      id: "25040105"
    },
    {
      name: {
        km: "ស្វាយតាធម្ម",
        latin: "Svay Ta Thoam"
      },
      id: "25040106"
    },
    {
      name: {
        km: "ពោធិ៍មាស",
        latin: "Pou Meas"
      },
      id: "25040107"
    },
    {
      name: {
        km: "មិត្ដរ៉ាច",
        latin: "Mitt Rach"
      },
      id: "25040108"
    },
    {
      name: {
        km: "ទួលតាលាប់",
        latin: "Tuol Ta  Lorb"
      },
      id: "25040109"
    },
    {
      name: {
        km: "ដំណាក់បេង",
        latin: "Damnak Beng"
      },
      id: "25040110"
    },
    {
      name: {
        km: "ពោធិ៍ស្វាយមីង",
        latin: "Pou Svay Ming"
      },
      id: "25040111"
    },
    {
      name: {
        km: "ស្វាយមីង",
        latin: "Svay Ming"
      },
      id: "25040112"
    },
    {
      name: {
        km: "មាសស្នេហ៍",
        latin: "Meas Snae"
      },
      id: "25040113"
    },
    {
      name: {
        km: "ស្វាយរលួស",
        latin: "Svay Roluos"
      },
      id: "25040114"
    },
    {
      name: {
        km: "ជ្រៃតាសូ",
        latin: "Chrey Ta Sou"
      },
      id: "25040115"
    },
    {
      name: {
        km: "លេខ ៣",
        latin: "Lekh Bei"
      },
      id: "25040116"
    },
    {
      name: {
        km: "លេខ ៤កើត",
        latin: "Lekh Buon Kaeut"
      },
      id: "25040117"
    },
    {
      name: {
        km: "ចង្វា",
        latin: "Changva"
      },
      id: "25040118"
    },
    {
      name: {
        km: "បុសល្ហុង",
        latin: "Bos Lhong"
      },
      id: "25040119"
    },
    {
      name: {
        km: "អំពិលជើង",
        latin: "Ampil  Cheung"
      },
      id: "25040120"
    },
    {
      name: {
        km: "អំពិលត្បូង",
        latin: "Ampil  Tboung"
      },
      id: "25040121"
    },
    {
      name: {
        km: "ទំពាំងឫស្សី",
        latin: "Tumpeang Ruessei"
      },
      id: "25040122"
    },
    {
      name: {
        km: "ស្វាយតាឡក",
        latin: "Svay Ta Lak"
      },
      id: "25040123"
    },
    {
      name: {
        km: "អំពិលជុំ",
        latin: "Ampil Chum"
      },
      id: "25040124"
    },
    {
      name: {
        km: "ជ្រួល",
        latin: "Chruol"
      },
      id: "25040201"
    },
    {
      name: {
        km: "ចំឡាក់",
        latin: "Chamlak"
      },
      id: "25040202"
    },
    {
      name: {
        km: "ត្រពាំងកណ្ដាល",
        latin: "Trapeang Kandal"
      },
      id: "25040203"
    },
    {
      name: {
        km: "ចំការគ",
        latin: "Chamkar Kor"
      },
      id: "25040204"
    },
    {
      name: {
        km: "អូរឡោក",
        latin: "Ou Laok"
      },
      id: "25040205"
    },
    {
      name: {
        km: "ជ្រោយផ្អុង",
        latin: "Chrouy Ph'ong"
      },
      id: "25040206"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "25040207"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "25040208"
    },
    {
      name: {
        km: "ត្រាចជ្រុំ",
        latin: "Trach Chrum"
      },
      id: "25040209"
    },
    {
      name: {
        km: "ដើមចង្ក្រាន",
        latin: "Daeum Changkran"
      },
      id: "25040210"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "25040211"
    },
    {
      name: {
        km: "ខ្ទមលាវ",
        latin: "Khtom Leav"
      },
      id: "25040212"
    },
    {
      name: {
        km: "ពុទ្ធា",
        latin: "Putthea"
      },
      id: "25040213"
    },
    {
      name: {
        km: "ត្រពាំងទា",
        latin: "Trapeang Tea"
      },
      id: "25040214"
    },
    {
      name: {
        km: "គោកទី",
        latin: "Kouk Ti"
      },
      id: "25040215"
    },
    {
      name: {
        km: "ជំពូ",
        latin: "Chumpou"
      },
      id: "25040216"
    },
    {
      name: {
        km: "ស្រឡុង",
        latin: "Sralong"
      },
      id: "25040217"
    },
    {
      name: {
        km: "ដំរិលទី ១",
        latin: "Damril Ti Muoy"
      },
      id: "25040301"
    },
    {
      name: {
        km: "ដំរិលទី ២",
        latin: "Damril Ti Pir"
      },
      id: "25040302"
    },
    {
      name: {
        km: "ដំរិលទី ៣",
        latin: "Damril Ti Bei"
      },
      id: "25040303"
    },
    {
      name: {
        km: "ដំរិលទី ៤",
        latin: "Damril Ti Buon"
      },
      id: "25040304"
    },
    {
      name: {
        km: "យាកជើង",
        latin: "Yeak Cheung"
      },
      id: "25040305"
    },
    {
      name: {
        km: "យាកត្បូង",
        latin: "Yeak Tboung"
      },
      id: "25040306"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "25040307"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "25040308"
    },
    {
      name: {
        km: "ពើក",
        latin: "Peuk"
      },
      id: "25040309"
    },
    {
      name: {
        km: "ជ្រៃសុខុម",
        latin: "Chrey Sokhom"
      },
      id: "25040310"
    },
    {
      name: {
        km: "ក្បាលអូរ",
        latin: "Kbal Ou"
      },
      id: "25040311"
    },
    {
      name: {
        km: "ក្រពើសារ",
        latin: "Krapeu Sar"
      },
      id: "25040312"
    },
    {
      name: {
        km: "សំស្នែរ",
        latin: "Sam Snae"
      },
      id: "25040313"
    },
    {
      name: {
        km: "ស្រែស្រួច",
        latin: "Srae Sruoch"
      },
      id: "25040314"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "25040315"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "25040316"
    },
    {
      name: {
        km: "ចន្លោង",
        latin: "Chanlaong"
      },
      id: "25040317"
    },
    {
      name: {
        km: "ខ្នប់ដំរីជើង",
        latin: "Khnab Damrei Cheung"
      },
      id: "25040318"
    },
    {
      name: {
        km: "ខ្នប់ដំរីត្បូង",
        latin: "Khnab Damrei Tboung"
      },
      id: "25040319"
    },
    {
      name: {
        km: "ប្រឡាយ",
        latin: "Pralay"
      },
      id: "25040320"
    },
    {
      name: {
        km: "ស្ទឹងរោង",
        latin: "Stueng Roung"
      },
      id: "25040401"
    },
    {
      name: {
        km: "សុក្រំជ្រុំ",
        latin: "Sokram Chrum"
      },
      id: "25040402"
    },
    {
      name: {
        km: "ទំនាប",
        latin: "Tumneab"
      },
      id: "25040403"
    },
    {
      name: {
        km: "លេខ ២",
        latin: "Lekh Pir"
      },
      id: "25040404"
    },
    {
      name: {
        km: "គងជ័យ",
        latin: "Kong Chey"
      },
      id: "25040405"
    },
    {
      name: {
        km: "អូរដំរ៉ាយ",
        latin: "Ou Damray"
      },
      id: "25040406"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "25040407"
    },
    {
      name: {
        km: "បង្គានស",
        latin: "Bangkean Sar"
      },
      id: "25040408"
    },
    {
      name: {
        km: "ទួលស្រឡៅ",
        latin: "Tuol Sralau"
      },
      id: "25040409"
    },
    {
      name: {
        km: "ស្ទឹងជ័យ",
        latin: "Stueng Chey"
      },
      id: "25040410"
    },
    {
      name: {
        km: "ទួលត្រាច",
        latin: "Tuol Trach"
      },
      id: "25040411"
    },
    {
      name: {
        km: "ព្រំខេត្ដ",
        latin: "Prum Khet"
      },
      id: "25040412"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "25040413"
    },
    {
      name: {
        km: "ភូមិលេខ ១",
        latin: "Phum Lekh Muoy"
      },
      id: "25040414"
    },
    {
      name: {
        km: "ទួលតាហោ",
        latin: "Tuol Ta Hao"
      },
      id: "25040415"
    },
    {
      name: {
        km: "ទួលសាម៉",
        latin: "Tuol Sama"
      },
      id: "25040416"
    },
    {
      name: {
        km: "ចង្វា",
        latin: "Changva"
      },
      id: "25040417"
    },
    {
      name: {
        km: "សឹង",
        latin: "Soeng"
      },
      id: "25040418"
    },
    {
      name: {
        km: "ជើងវត្ដ",
        latin: "Cheung Voat"
      },
      id: "25040419"
    },
    {
      name: {
        km: "អូពពូល",
        latin: "Ou Popul"
      },
      id: "25040420"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "25040421"
    },
    {
      name: {
        km: "ស្រែស្ពៃ",
        latin: "Srae Spey"
      },
      id: "25040422"
    },
    {
      name: {
        km: "បន្ទាយមៀន",
        latin: "Banteay Mien"
      },
      id: "25040501"
    },
    {
      name: {
        km: "សោយ",
        latin: "Saoy"
      },
      id: "25040502"
    },
    {
      name: {
        km: "ស្វាយពក",
        latin: "Svay Pok"
      },
      id: "25040503"
    },
    {
      name: {
        km: "មៀន",
        latin: "Mien"
      },
      id: "25040504"
    },
    {
      name: {
        km: "ព្រៃសំបួរកើត",
        latin: "Prey Sambuor Kaeut"
      },
      id: "25040505"
    },
    {
      name: {
        km: "ព្រៃសំបួរលិច",
        latin: "Prey Sambuor Lech"
      },
      id: "25040506"
    },
    {
      name: {
        km: "បឹងជើង",
        latin: "Boeng Cheung"
      },
      id: "25040507"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "25040508"
    },
    {
      name: {
        km: "កន្លែងចក",
        latin: "Kanlaeng Chak"
      },
      id: "25040509"
    },
    {
      name: {
        km: "បឹងកណ្ដាល",
        latin: "Boeng Kandal"
      },
      id: "25040510"
    },
    {
      name: {
        km: "មេលោង",
        latin: "Me Loung"
      },
      id: "25040511"
    },
    {
      name: {
        km: "កំពូលសេរី",
        latin: "Kampul Serei"
      },
      id: "25040512"
    },
    {
      name: {
        km: "ថ្មសំលៀង",
        latin: "Thma Samlieng"
      },
      id: "25040513"
    },
    {
      name: {
        km: "ថ្មប្រជុំ",
        latin: "Thma Prachum"
      },
      id: "25040514"
    },
    {
      name: {
        km: "បាស្រី",
        latin: "Ba Srei"
      },
      id: "25040601"
    },
    {
      name: {
        km: "ក្បាលទឹក",
        latin: "Kbal Tuek"
      },
      id: "25040602"
    },
    {
      name: {
        km: "ភ្នំ",
        latin: "Phnum"
      },
      id: "25040603"
    },
    {
      name: {
        km: "ទួលថ្កូវ",
        latin: "Tuol Thkov"
      },
      id: "25040604"
    },
    {
      name: {
        km: "ទួលមានជ័យ",
        latin: "Tuol Mean Chey"
      },
      id: "25040605"
    },
    {
      name: {
        km: "ទួលព្នៅ",
        latin: "Tuol Pnov"
      },
      id: "25040606"
    },
    {
      name: {
        km: "ទួលឃ្លាំង",
        latin: "Tuol Khleang"
      },
      id: "25040607"
    },
    {
      name: {
        km: "ព្រះធាតុកណ្ដាល",
        latin: "Preah Theat Kandal"
      },
      id: "25040608"
    },
    {
      name: {
        km: "ទួលសំបូរ",
        latin: "Tuol Sambour"
      },
      id: "25040609"
    },
    {
      name: {
        km: "ព្រះធាតុថ្មដា",
        latin: "Preah Theat Thma Da"
      },
      id: "25040610"
    },
    {
      name: {
        km: "ត្រពាំងនាង",
        latin: "Trapeang Neang"
      },
      id: "25040611"
    },
    {
      name: {
        km: "បឹងកាង",
        latin: "Boeng Kang"
      },
      id: "25040612"
    },
    {
      name: {
        km: "ជ័យសោភ័ណ្ឌ",
        latin: "Chey Saophoan"
      },
      id: "25040613"
    },
    {
      name: {
        km: "ថ្មីកណ្ដាល",
        latin: "Thmei Kandal"
      },
      id: "25040614"
    },
    {
      name: {
        km: "អ្នកតាទ្វារ",
        latin: "Neak Ta Tvear"
      },
      id: "25040615"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "25040616"
    },
    {
      name: {
        km: "ថ្មីលើ",
        latin: "Thmei Leu"
      },
      id: "25040617"
    },
    {
      name: {
        km: "ស្រែមៀន",
        latin: "Srae Mien"
      },
      id: "25040618"
    },
    {
      name: {
        km: "ភូមិ ១២",
        latin: "Phum Dabpir"
      },
      id: "25040619"
    },
    {
      name: {
        km: "ភូមិ ២៥",
        latin: "Phum Mpheypram"
      },
      id: "25040620"
    },
    {
      name: {
        km: "ភូមិ ២៧",
        latin: "Phum Mphey Prampir"
      },
      id: "25040621"
    },
    {
      name: {
        km: "ភូមិ ៤៤",
        latin: "Phum Saesebbuon"
      },
      id: "25040622"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "25040701"
    },
    {
      name: {
        km: "ក្បាលពែ",
        latin: "Kbal Peae"
      },
      id: "25040702"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "25040703"
    },
    {
      name: {
        km: "បឹងផ្ទិល",
        latin: "Boeng Phtil"
      },
      id: "25040704"
    },
    {
      name: {
        km: "តាង៉ិន",
        latin: "Ta Ngen"
      },
      id: "25040705"
    },
    {
      name: {
        km: "ទួលសូព័រ",
        latin: "Tuol Sopoar"
      },
      id: "25040706"
    },
    {
      name: {
        km: "ត្រពាំងផ្អាវ",
        latin: "Trapeang Ph'av"
      },
      id: "25040707"
    },
    {
      name: {
        km: "ពោង",
        latin: "Poung"
      },
      id: "25040708"
    },
    {
      name: {
        km: "ចាន់អណ្ដែត",
        latin: "Chan Andaet"
      },
      id: "25040709"
    },
    {
      name: {
        km: "ត្រពាំងល្វាត្បូង",
        latin: "Trapeang Lvea Tboung"
      },
      id: "25040710"
    },
    {
      name: {
        km: "ត្រពាំងល្វាជើង",
        latin: "Trapeang Lvea Cheung"
      },
      id: "25040711"
    },
    {
      name: {
        km: "បឹងកំពឹស",
        latin: "Boeng Kampues"
      },
      id: "25040712"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "25040713"
    },
    {
      name: {
        km: "ថ្មដាលិច",
        latin: "Thma Da Lech"
      },
      id: "25040714"
    },
    {
      name: {
        km: "ទួលសូភី",
        latin: "Tuol Souphi"
      },
      id: "25040715"
    },
    {
      name: {
        km: "ដំណាក់កែវ",
        latin: "Damnak Kaev"
      },
      id: "25040716"
    },
    {
      name: {
        km: "ដូនទេស",
        latin: "Doun Tes"
      },
      id: "25040717"
    },
    {
      name: {
        km: "ថ្មដាកើត",
        latin: "Thma Da Kaeut"
      },
      id: "25040718"
    },
    {
      name: {
        km: "ក្បាលអូរ",
        latin: "Kbal Ou"
      },
      id: "25040719"
    },
    {
      name: {
        km: "ភូមិ ៧៦",
        latin: "Phum Chetseb Prammuoy"
      },
      id: "25040720"
    },
    {
      name: {
        km: "ភូមិ ៥៤",
        latin: "Phum Hasebbuon"
      },
      id: "25040721"
    },
    {
      name: {
        km: "ភូមិ ៧៣",
        latin: "Phum Chetsebbei"
      },
      id: "25040722"
    },
    {
      name: {
        km: "ថ្មីសែនជ័យ",
        latin: "Thmey Senchey"
      },
      id: "25040723"
    },
    {
      name: {
        km: "បុសរកា",
        latin: "Bos Roka"
      },
      id: "25050101"
    },
    {
      name: {
        km: "ព្រៃទំនប់",
        latin: "Prey Tumnob"
      },
      id: "25050102"
    },
    {
      name: {
        km: "រោលផ្អែម",
        latin: "Roul Ph'aem"
      },
      id: "25050103"
    },
    {
      name: {
        km: "តានី",
        latin: "Ta Ni"
      },
      id: "25050104"
    },
    {
      name: {
        km: "ស្ពានជ្រៃ",
        latin: "Spean Chrey"
      },
      id: "25050105"
    },
    {
      name: {
        km: "ស្វាយសុខុម",
        latin: "Svay Sokhom"
      },
      id: "25050106"
    },
    {
      name: {
        km: "បុសឫស្សី",
        latin: "Bos Ruessei"
      },
      id: "25050107"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "25050108"
    },
    {
      name: {
        km: "ដូនតី",
        latin: "Dountei"
      },
      id: "25050109"
    },
    {
      name: {
        km: "ពោធិព្រឹក្សលិច",
        latin: "Pouthi Proeks Lech"
      },
      id: "25050110"
    },
    {
      name: {
        km: "ពោធិព្រឹក្សកើត",
        latin: "Pouthi Proeks Kaeut"
      },
      id: "25050111"
    },
    {
      name: {
        km: "សុវណ្ណមាលា",
        latin: "Sovann Mealea"
      },
      id: "25050112"
    },
    {
      name: {
        km: "រើលលើ",
        latin: "Reul Leu"
      },
      id: "25050113"
    },
    {
      name: {
        km: "រើលក្រោម",
        latin: "Reul Kraom"
      },
      id: "25050114"
    },
    {
      name: {
        km: "ស្នាកកណ្ដាល",
        latin: "Snak Kandal"
      },
      id: "25050115"
    },
    {
      name: {
        km: "ឈូកស",
        latin: "Chhuk Sa"
      },
      id: "25050116"
    },
    {
      name: {
        km: "អង្គរក្រៅ",
        latin: "Angkor Krau"
      },
      id: "25050117"
    },
    {
      name: {
        km: "អង្គរក្នុង",
        latin: "Angkor Knong"
      },
      id: "25050118"
    },
    {
      name: {
        km: "ពោធិធំ",
        latin: "Pou Thum"
      },
      id: "25050119"
    },
    {
      name: {
        km: "គោកនាវា",
        latin: "Kouk Neavea"
      },
      id: "25050120"
    },
    {
      name: {
        km: "អង្គរលើ",
        latin: "Angkor Leu"
      },
      id: "25050121"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "25050201"
    },
    {
      name: {
        km: "កញ្ចែ",
        latin: "Kanhchae"
      },
      id: "25050202"
    },
    {
      name: {
        km: "បុសទី",
        latin: "Bos Ti"
      },
      id: "25050203"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "25050204"
    },
    {
      name: {
        km: "ត្រពាំងស្ទៀង",
        latin: "Trapeang Stieng"
      },
      id: "25050205"
    },
    {
      name: {
        km: "ទឹកយង់",
        latin: "Tuek Yong"
      },
      id: "25050206"
    },
    {
      name: {
        km: "អង្កែង",
        latin: "Angkaeng"
      },
      id: "25050207"
    },
    {
      name: {
        km: "តាអាំ",
        latin: "Ta Am"
      },
      id: "25050208"
    },
    {
      name: {
        km: "សន្ទៃទី ២",
        latin: "Santey Ti Pir"
      },
      id: "25050209"
    },
    {
      name: {
        km: "សំរើយ",
        latin: "Samraeuy"
      },
      id: "25050210"
    },
    {
      name: {
        km: "ត្រពាំងសន្ទៃ",
        latin: "Trapeang Santey"
      },
      id: "25050211"
    },
    {
      name: {
        km: "ថ្លុកត្រាច",
        latin: "Thlok Trach"
      },
      id: "25050212"
    },
    {
      name: {
        km: "ឫស្សីជួរ",
        latin: "Ruessei Chuor"
      },
      id: "25050213"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "25050214"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "25050215"
    },
    {
      name: {
        km: "សន្ទៃ ទី ១",
        latin: "Santey Ti Muoy"
      },
      id: "25050216"
    },
    {
      name: {
        km: "ក្រសោមសត្វ",
        latin: "Krasaom Satv"
      },
      id: "25050217"
    },
    {
      name: {
        km: "គគីរ",
        latin: "Kokir"
      },
      id: "25050218"
    },
    {
      name: {
        km: "អន្លង់ជ្រៃ",
        latin: "Anlong Chrey"
      },
      id: "25050219"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "25050220"
    },
    {
      name: {
        km: "ស្ទឹងតូច",
        latin: "Stueng Touch"
      },
      id: "25050221"
    },
    {
      name: {
        km: "អូរព្រិច",
        latin: "Ou Prich"
      },
      id: "25050222"
    },
    {
      name: {
        km: "ដងក្ដោង",
        latin: "Dang Kdaong"
      },
      id: "25050223"
    },
    {
      name: {
        km: "កណ្ដោលជ្រុំ",
        latin: "Kandaol Chrum"
      },
      id: "25050301"
    },
    {
      name: {
        km: "បុសខ្នុរ",
        latin: "Bos Khnor"
      },
      id: "25050302"
    },
    {
      name: {
        km: "មនោប៊ូ",
        latin: "Monou Bu"
      },
      id: "25050303"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "25050304"
    },
    {
      name: {
        km: "ជើងអង",
        latin: "Cheung Ang"
      },
      id: "25050305"
    },
    {
      name: {
        km: "ដឺកពរ",
        latin: "Doek Por"
      },
      id: "25050306"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "25050307"
    },
    {
      name: {
        km: "ជ័យនិគម",
        latin: "Chey Nikom"
      },
      id: "25050308"
    },
    {
      name: {
        km: "អំពុក",
        latin: "Ampuk"
      },
      id: "25050309"
    },
    {
      name: {
        km: "អណ្ដោត",
        latin: "Andaot"
      },
      id: "25050310"
    },
    {
      name: {
        km: "ស្វាយមាស",
        latin: "Svay Meas"
      },
      id: "25050311"
    },
    {
      name: {
        km: "ត្រពាំងប្រិយ៍",
        latin: "Trapeang Prei"
      },
      id: "25050312"
    },
    {
      name: {
        km: "ទួលចំការ",
        latin: "Tuol Chamkar"
      },
      id: "25050313"
    },
    {
      name: {
        km: "ត្រពាំងន្សោង",
        latin: "Trapeang Tonsaong"
      },
      id: "25050314"
    },
    {
      name: {
        km: "ព្រះអណ្ដូង",
        latin: "Preah Andoung"
      },
      id: "25050315"
    },
    {
      name: {
        km: "ម្កាក់",
        latin: "Mkak"
      },
      id: "25050316"
    },
    {
      name: {
        km: "ទួលពោធិ",
        latin: "Tuol Pou"
      },
      id: "25050317"
    },
    {
      name: {
        km: "ទួលជ័យ",
        latin: "Tuol Chey"
      },
      id: "25050318"
    },
    {
      name: {
        km: "សុវណ្ណគម",
        latin: "Sovann Kom"
      },
      id: "25050319"
    },
    {
      name: {
        km: "គោកល្វៀង",
        latin: "Kouk Lvieng"
      },
      id: "25050320"
    },
    {
      name: {
        km: "ស្បែកគឺ",
        latin: "Sbaek Kueu"
      },
      id: "25050321"
    },
    {
      name: {
        km: "បត់ទន្លា",
        latin: "Bat Tonlea"
      },
      id: "25050322"
    },
    {
      name: {
        km: "ល្ងៀង",
        latin: "Lngieng"
      },
      id: "25050323"
    },
    {
      name: {
        km: "ស្ដុកសម្បត្ដិ",
        latin: "Sdok Sambat"
      },
      id: "25050324"
    },
    {
      name: {
        km: "ផ្សារកណ្ដោលជ្រុំ",
        latin: "Phsar Kandaol Chrum"
      },
      id: "25050325"
    },
    {
      name: {
        km: "ត្រពាំងខ្យង",
        latin: "Trapeang Khyang"
      },
      id: "25050401"
    },
    {
      name: {
        km: "តាហៀវលើ",
        latin: "Ta Hiev Leu"
      },
      id: "25050402"
    },
    {
      name: {
        km: "តាហៀវក្រោម",
        latin: "Ta Hiev Kraom"
      },
      id: "25050403"
    },
    {
      name: {
        km: "កណ្ដោលកោង",
        latin: "Kandaol Kaong"
      },
      id: "25050404"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "25050405"
    },
    {
      name: {
        km: "ពោធិរោងលិច",
        latin: "Pou Roung Lech"
      },
      id: "25050406"
    },
    {
      name: {
        km: "ពោធិរោងលើ",
        latin: "Pou Roung Leu"
      },
      id: "25050407"
    },
    {
      name: {
        km: "ពោធិឥន្ទ ទី ១",
        latin: "Pou Ent Ti Muoy"
      },
      id: "25050408"
    },
    {
      name: {
        km: "ពោធិឥន្ទ ទី ២",
        latin: "Pou Ent Ti Pir"
      },
      id: "25050409"
    },
    {
      name: {
        km: "កោងកាង ទី ១",
        latin: "Kaong Kang Ti Muoy"
      },
      id: "25050410"
    },
    {
      name: {
        km: "កោងកាង ទី ២",
        latin: "Kaong Kang Ti Pir"
      },
      id: "25050411"
    },
    {
      name: {
        km: "កោងកាង ទី ៣",
        latin: "Kaong Kang Ti Bei"
      },
      id: "25050412"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "25050413"
    },
    {
      name: {
        km: "ឡ",
        latin: "La"
      },
      id: "25050414"
    },
    {
      name: {
        km: "ពោធិស្រុក",
        latin: "Pou Srok"
      },
      id: "25050415"
    },
    {
      name: {
        km: "ថ្នល់ថ្មី",
        latin: "Thnal Thmei"
      },
      id: "25050416"
    },
    {
      name: {
        km: "ក្រែកត្បូង",
        latin: "Kraek Tboung"
      },
      id: "25050501"
    },
    {
      name: {
        km: "ក្រែកជើង",
        latin: "Kraek Cheung"
      },
      id: "25050502"
    },
    {
      name: {
        km: "ជីមាន់ត្បូង",
        latin: "Chimoan Tboung"
      },
      id: "25050503"
    },
    {
      name: {
        km: "ជីមាន់ជើង",
        latin: "Chimoan Cheung"
      },
      id: "25050504"
    },
    {
      name: {
        km: "ជីមាន់កណ្ដាល",
        latin: "Chimoan Kandal"
      },
      id: "25050505"
    },
    {
      name: {
        km: "ជីមាន់លិច",
        latin: "Chimoan Lech"
      },
      id: "25050506"
    },
    {
      name: {
        km: "ពើក",
        latin: "Peuk"
      },
      id: "25050507"
    },
    {
      name: {
        km: "ហួចលិច",
        latin: "Huoch Lech"
      },
      id: "25050508"
    },
    {
      name: {
        km: "ហួចកើត",
        latin: "Huoch Kaeut"
      },
      id: "25050509"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "25050510"
    },
    {
      name: {
        km: "ទួលអង្គ្រង",
        latin: "Tuol Angkrong"
      },
      id: "25050511"
    },
    {
      name: {
        km: "នាងនយ",
        latin: "Neang Noy"
      },
      id: "25050512"
    },
    {
      name: {
        km: "ត្រសិត",
        latin: "Traset"
      },
      id: "25050513"
    },
    {
      name: {
        km: "កូវ",
        latin: "Kov"
      },
      id: "25050514"
    },
    {
      name: {
        km: "ល្អក់",
        latin: "L'ak"
      },
      id: "25050515"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "25050516"
    },
    {
      name: {
        km: "អំពុក",
        latin: "Ampuk"
      },
      id: "25050517"
    },
    {
      name: {
        km: "ជីពាំង",
        latin: "Chi Peang"
      },
      id: "25050518"
    },
    {
      name: {
        km: "បុសល្វេ",
        latin: "Bos Lveae"
      },
      id: "25050519"
    },
    {
      name: {
        km: "សមាគម",
        latin: "Sakmakom"
      },
      id: "25050520"
    },
    {
      name: {
        km: "ត្រពាំងសុខា",
        latin: "Trapeang Sokha"
      },
      id: "25050521"
    },
    {
      name: {
        km: "អណ្ដូងជ័យ",
        latin: "Andoung Chey"
      },
      id: "25050522"
    },
    {
      name: {
        km: "សេរីសុខា",
        latin: "Serei Sokha"
      },
      id: "25050523"
    },
    {
      name: {
        km: "ជីតុក",
        latin: "Chi Tok"
      },
      id: "25050524"
    },
    {
      name: {
        km: "មេម៉ៃ",
        latin: "Memae"
      },
      id: "25050525"
    },
    {
      name: {
        km: "ស្រែទឹក",
        latin: "Srae Tuek"
      },
      id: "25050526"
    },
    {
      name: {
        km: "ស្អំ",
        latin: "S'am"
      },
      id: "25050527"
    },
    {
      name: {
        km: "រោងចក្រ",
        latin: "Roung Chakr"
      },
      id: "25050528"
    },
    {
      name: {
        km: "សំបូរផល",
        latin: "Sambour Phal"
      },
      id: "25050529"
    },
    {
      name: {
        km: "ភូមិ ៥៥",
        latin: "Phum Hapram"
      },
      id: "25050530"
    },
    {
      name: {
        km: "ភូមិ ៤៥",
        latin: "Phum Saepram"
      },
      id: "25050531"
    },
    {
      name: {
        km: "ភូមិ ៣៧",
        latin: "Phum Samprampir"
      },
      id: "25050532"
    },
    {
      name: {
        km: "ព្រឹត",
        latin: "Pruet"
      },
      id: "25050533"
    },
    {
      name: {
        km: "ពពេល",
        latin: "Popel"
      },
      id: "25050601"
    },
    {
      name: {
        km: "ទួលកណ្ដាល",
        latin: "Tuol Kandal"
      },
      id: "25050602"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "25050603"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "25050604"
    },
    {
      name: {
        km: "ខ្សាក",
        latin: "Khsak"
      },
      id: "25050605"
    },
    {
      name: {
        km: "ត្រពាំងឈ្លើង",
        latin: "Trapeang Chhleung"
      },
      id: "25050606"
    },
    {
      name: {
        km: "ស្រះ",
        latin: "Srah"
      },
      id: "25050607"
    },
    {
      name: {
        km: "ជាំធ្លក",
        latin: "Choam Thlok"
      },
      id: "25050608"
    },
    {
      name: {
        km: "ស្ទឹងជើង",
        latin: "Stueng Cheung"
      },
      id: "25050609"
    },
    {
      name: {
        km: "ទួលចាន់",
        latin: "Tuol Chan"
      },
      id: "25050610"
    },
    {
      name: {
        km: "ទួលសង្កែ",
        latin: "Tuol Sangkae"
      },
      id: "25050701"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "25050702"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង ទី ១",
        latin: "Trapeang Pring Ti Muoy"
      },
      id: "25050703"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង ទី ២",
        latin: "Trapeang Pring Ti Pir"
      },
      id: "25050704"
    },
    {
      name: {
        km: "ច្រករំដេង",
        latin: "Chrak Rumdeng"
      },
      id: "25050705"
    },
    {
      name: {
        km: "សេរីសុខុម",
        latin: "Serei Sokhom"
      },
      id: "25050706"
    },
    {
      name: {
        km: "ត្រពាំងរំសែង",
        latin: "Trapeang Rumsaeng"
      },
      id: "25050707"
    },
    {
      name: {
        km: "ព្រះផ្ដៅ",
        latin: "Preah Phdau"
      },
      id: "25050708"
    },
    {
      name: {
        km: "បុសចេក",
        latin: "Bos Chek"
      },
      id: "25050709"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "25050710"
    },
    {
      name: {
        km: "ត្រាចខោល",
        latin: "Trach Khaol"
      },
      id: "25050711"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង ទី ២",
        latin: "Trapeang Phlong Ti Pir"
      },
      id: "25050712"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង ទី ១",
        latin: "Trapeang Phlong Ti Muoy"
      },
      id: "25050713"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "25050714"
    },
    {
      name: {
        km: "ច្រាប",
        latin: "Chrab"
      },
      id: "25050801"
    },
    {
      name: {
        km: "ស្រឡៅច្រឹង",
        latin: "Sralau Chroeng"
      },
      id: "25050802"
    },
    {
      name: {
        km: "វាលម្លូរ",
        latin: "Veal Mlu"
      },
      id: "25050803"
    },
    {
      name: {
        km: "ដំបងអំពាក់",
        latin: "Dambang Ampeak"
      },
      id: "25050804"
    },
    {
      name: {
        km: "កប្បាស",
        latin: "Kabbas"
      },
      id: "25050805"
    },
    {
      name: {
        km: "ក្រញូង",
        latin: "Kranhung"
      },
      id: "25050806"
    },
    {
      name: {
        km: "សុខចំរើន",
        latin: "Sokh Chamraeun"
      },
      id: "25050807"
    },
    {
      name: {
        km: "ភូមិ១០.៨",
        latin: "Phum Dab - Prambei"
      },
      id: "25050808"
    },
    {
      name: {
        km: "ពោធិកិល",
        latin: "Pou Kel"
      },
      id: "25060101"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "25060102"
    },
    {
      name: {
        km: "ជ្រៃបិទមាស",
        latin: "Chrey Bet Meas"
      },
      id: "25060103"
    },
    {
      name: {
        km: "វិហារខ្ពស់",
        latin: "Vihear Khpos"
      },
      id: "25060104"
    },
    {
      name: {
        km: "សួងលិច",
        latin: "Suong Lech"
      },
      id: "25060105"
    },
    {
      name: {
        km: "ជើងឡង",
        latin: "Cheung Lang"
      },
      id: "25060106"
    },
    {
      name: {
        km: "សួងកើត",
        latin: "Suong Kaeut"
      },
      id: "25060107"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "25060108"
    },
    {
      name: {
        km: "ច្រកពោន",
        latin: "Chrak Poun"
      },
      id: "25060109"
    },
    {
      name: {
        km: "ពណ្ណរាយ",
        latin: "Ponnareay"
      },
      id: "25060110"
    },
    {
      name: {
        km: "ចុងអង្គ្រង",
        latin: "Chong Angkrong"
      },
      id: "25060111"
    },
    {
      name: {
        km: "តូង",
        latin: "Toung"
      },
      id: "25060112"
    },
    {
      name: {
        km: "ភូមិ ៤.៨",
        latin: "Phum Buon Prambei"
      },
      id: "25060113"
    },
    {
      name: {
        km: "ភូមិ ១៨",
        latin: "Phum Dabprambei"
      },
      id: "25060114"
    },
    {
      name: {
        km: "ភូមិ ៣.១០",
        latin: "Phum Bei Dab"
      },
      id: "25060115"
    },
    {
      name: {
        km: "ជ្រួយ",
        latin: "Chruoy"
      },
      id: "25060201"
    },
    {
      name: {
        km: "ជីកែ",
        latin: "Chi Kae"
      },
      id: "25060202"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "25060203"
    },
    {
      name: {
        km: "ជើងវត្ដ",
        latin: "Cheung Voat"
      },
      id: "25060204"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "25060205"
    },
    {
      name: {
        km: "ក្រឆាន",
        latin: "Krachhan"
      },
      id: "25060206"
    },
    {
      name: {
        km: "ប្រស្រែក្រោម",
        latin: "Prasrae Kraom"
      },
      id: "25060207"
    },
    {
      name: {
        km: "ប្រស្រែលើ",
        latin: "Prasrae Leu"
      },
      id: "25060208"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "25060209"
    },
    {
      name: {
        km: "ថ្នល់ថ្មី",
        latin: "Thnal Thmei"
      },
      id: "25060210"
    },
    {
      name: {
        km: "ភូមិ ៦.១០",
        latin: "Phum Prammuoy Dab"
      },
      id: "25060211"
    },
    {
      name: {
        km: "ភូមិ ៦.១២",
        latin: "Phum Prammuoy Dabpir"
      },
      id: "25060212"
    },
    {
      name: {
        km: "ភូមិ ៥.១០",
        latin: "Phum Pram Dab"
      },
      id: "25060213"
    },
    {
      name: {
        km: "វាលចារ",
        latin: "Veal Char"
      },
      id: "25060214"
    },
    {
      name: {
        km: "គៀនរូង",
        latin: "Kien Rung"
      },
      id: "25060215"
    },
    {
      name: {
        km: "ពន្លៃជួរ",
        latin: "Ponley Chuor"
      },
      id: "25070101"
    },
    {
      name: {
        km: "មជ្ឈឹមភាគ",
        latin: "Mochchhuem Pheak"
      },
      id: "25070102"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "25070103"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "25070104"
    },
    {
      name: {
        km: "កូនត្មាត",
        latin: "Koun Tmat"
      },
      id: "25070105"
    },
    {
      name: {
        km: "ក្រោយវត្ដ",
        latin: "Kraoy Voat"
      },
      id: "25070106"
    },
    {
      name: {
        km: "តាកែប",
        latin: "Ta Kaeb"
      },
      id: "25070107"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "25070108"
    },
    {
      name: {
        km: "ឯអូត",
        latin: "Ae Out"
      },
      id: "25070109"
    },
    {
      name: {
        km: "ពោធិរោង",
        latin: "Pou Roung"
      },
      id: "25070110"
    },
    {
      name: {
        km: "ពន្លៃផ្សា",
        latin: "Ponley Phsar"
      },
      id: "25070111"
    },
    {
      name: {
        km: "អង្កែវ",
        latin: "Angkaev"
      },
      id: "25070112"
    },
    {
      name: {
        km: "តាត្រាវ",
        latin: "Ta Trav"
      },
      id: "25070113"
    },
    {
      name: {
        km: "ដំណាក់ចារ",
        latin: "Damnak Char"
      },
      id: "25070114"
    },
    {
      name: {
        km: "ព្រៃកំពែង",
        latin: "Prey Kampeaeng"
      },
      id: "25070115"
    },
    {
      name: {
        km: "ឥសាន្ដមានជ័យ",
        latin: "Eisant Mean Chey"
      },
      id: "25070116"
    },
    {
      name: {
        km: "ព្រៃជង្រុក",
        latin: "Prey Chongruk"
      },
      id: "25070117"
    },
    {
      name: {
        km: "ដូងព្រះ",
        latin: "Doung Preah"
      },
      id: "25070118"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "25070119"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "25070120"
    },
    {
      name: {
        km: "អញ្ចើម",
        latin: "Anhchaeum"
      },
      id: "25070121"
    },
    {
      name: {
        km: "នាហ្មឹនខឹង",
        latin: "Neameun Khoeng"
      },
      id: "25070122"
    },
    {
      name: {
        km: "បឹងព្រួលលើ",
        latin: "Boeng Pruol Leu"
      },
      id: "25070201"
    },
    {
      name: {
        km: "ទួលកំពតទី ១",
        latin: "Tuol Kampot Ti Muoy"
      },
      id: "25070202"
    },
    {
      name: {
        km: "ទួលកំពតទី ៣",
        latin: "Tuol Kampot Ti Bei"
      },
      id: "25070203"
    },
    {
      name: {
        km: "ម៉ាស៊ីនស្រូវ",
        latin: "Masin Srov"
      },
      id: "25070204"
    },
    {
      name: {
        km: "វិហារក្រោម",
        latin: "Vihear Kraom"
      },
      id: "25070205"
    },
    {
      name: {
        km: "បាតដីក្រោម",
        latin: "Bat Dei Kraom"
      },
      id: "25070206"
    },
    {
      name: {
        km: "បឹងកំបោរ",
        latin: "Boeng Kambaor"
      },
      id: "25070207"
    },
    {
      name: {
        km: "ទួលដំបង",
        latin: "Tuol Dambang"
      },
      id: "25070208"
    },
    {
      name: {
        km: "ស្ពានឈើ",
        latin: "Spean Chheu"
      },
      id: "25070209"
    },
    {
      name: {
        km: "បឹងកក់",
        latin: "Boeng kak"
      },
      id: "25070210"
    },
    {
      name: {
        km: "បឹងព្រះ",
        latin: "Boeng Preah"
      },
      id: "25070211"
    },
    {
      name: {
        km: "វាលលិច",
        latin: "Veal Lech"
      },
      id: "25070301"
    },
    {
      name: {
        km: "ខ្ទុំកណ្ដាល",
        latin: "Khtum Kandal"
      },
      id: "25070302"
    },
    {
      name: {
        km: "រកាឃ្មួចត្បូង",
        latin: "Roka Khmuoch Tboung"
      },
      id: "25070303"
    },
    {
      name: {
        km: "រកាឃ្មួចជើង",
        latin: "Roka Khmuoch Cheung"
      },
      id: "25070304"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "25070305"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "25070306"
    },
    {
      name: {
        km: "ខ្នាចក្រសាំង",
        latin: "Khnach Krasang"
      },
      id: "25070307"
    },
    {
      name: {
        km: "ចន្ទុំ",
        latin: "Chantum"
      },
      id: "25070308"
    },
    {
      name: {
        km: "ទួលកណ្ដាលជើង",
        latin: "Tuol Kandal Cheung"
      },
      id: "25070309"
    },
    {
      name: {
        km: "ជីគរ",
        latin: "Chi Kor"
      },
      id: "25070310"
    },
    {
      name: {
        km: "កំរ៉ែង",
        latin: "Kamraeng"
      },
      id: "25070311"
    },
    {
      name: {
        km: "ស្ទឹងពេញ",
        latin: "Stueng Penh"
      },
      id: "25070312"
    },
    {
      name: {
        km: "ស្វាយទិព្វ",
        latin: "Svay Tipv"
      },
      id: "25070313"
    },
    {
      name: {
        km: "ខ្ទុំកើត",
        latin: "Khtum Kaeut"
      },
      id: "25070314"
    },
    {
      name: {
        km: "ខ្ទុំលិច",
        latin: "Khtum Lech"
      },
      id: "25070315"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "25070316"
    },
    {
      name: {
        km: "ទួលកណ្ដាលត្បូង",
        latin: "Tuol Kandal Tboung"
      },
      id: "25070317"
    },
    {
      name: {
        km: "ទួលថ្មី",
        latin: "Tuol Thmei"
      },
      id: "25070318"
    },
    {
      name: {
        km: "វះ (ជាសារ)",
        latin: "Veah (Cheang Sar )"
      },
      id: "25070319"
    },
    {
      name: {
        km: "ព្រែកតូច",
        latin: "Preaek Touch"
      },
      id: "25070401"
    },
    {
      name: {
        km: "រកាធំ",
        latin: "Roka Thum"
      },
      id: "25070402"
    },
    {
      name: {
        km: "ជីរោទ៍ក្រោម ទី ១",
        latin: "Chirou Kraom Ti Muoy"
      },
      id: "25070403"
    },
    {
      name: {
        km: "ជីរោទ៍ក្រោម ទី ២",
        latin: "Chirou Kraom Ti Pir"
      },
      id: "25070404"
    },
    {
      name: {
        km: "ជីរោទ៍លើ",
        latin: "Chirou Leu"
      },
      id: "25070405"
    },
    {
      name: {
        km: "ជីរោទ៍កណ្ដាល",
        latin: "Chirou Kandal"
      },
      id: "25070406"
    },
    {
      name: {
        km: "ជួរកណ្ដាល",
        latin: "Chuor Kandal"
      },
      id: "25070407"
    },
    {
      name: {
        km: "កំពង់ឫស្សី",
        latin: "Kampong Ruessei"
      },
      id: "25070408"
    },
    {
      name: {
        km: "ទួលកែវ",
        latin: "Tuol Kaev"
      },
      id: "25070409"
    },
    {
      name: {
        km: "ទួលវិហារ",
        latin: "Tuol Vihear"
      },
      id: "25070501"
    },
    {
      name: {
        km: "ស្រែសៀម",
        latin: "Srae Siem"
      },
      id: "25070502"
    },
    {
      name: {
        km: "ទឹកចេញ",
        latin: "Tuek Chenh"
      },
      id: "25070503"
    },
    {
      name: {
        km: "ឯកភាព",
        latin: "Aekkapheap"
      },
      id: "25070504"
    },
    {
      name: {
        km: "ទួលពន្លៃ",
        latin: "Tuol Ponley"
      },
      id: "25070505"
    },
    {
      name: {
        km: "ម្រាមទាក",
        latin: "Mream Teak"
      },
      id: "25070506"
    },
    {
      name: {
        km: "អណ្ដូងជា",
        latin: "Andoung Chea"
      },
      id: "25070507"
    },
    {
      name: {
        km: "តាត្រាវ",
        latin: "Ta Trav"
      },
      id: "25070508"
    },
    {
      name: {
        km: "ជ្រោយគរ",
        latin: "Chrouy Kor"
      },
      id: "25070509"
    },
    {
      name: {
        km: "បឹងត្រស់",
        latin: "Boeng Traoh"
      },
      id: "25070510"
    },
    {
      name: {
        km: "សាមពីរ",
        latin: "Sampir"
      },
      id: "25070511"
    },
    {
      name: {
        km: "សំពៅផុសថ្មី",
        latin: "Sampov Phos Thmei"
      },
      id: "25070512"
    },
    {
      name: {
        km: "ជប់ទី ១",
        latin: "Chob Ti Muoy"
      },
      id: "25070601"
    },
    {
      name: {
        km: "ជប់ទី ២",
        latin: "Chob Ti Pir"
      },
      id: "25070602"
    },
    {
      name: {
        km: "ជប់ក្រៅ",
        latin: "Chob Krau"
      },
      id: "25070603"
    },
    {
      name: {
        km: "ច្រកចំបក់",
        latin: "Chrak Chambak"
      },
      id: "25070604"
    },
    {
      name: {
        km: "វាលកណ្ដៀង",
        latin: "Veal Kandieng"
      },
      id: "25070605"
    },
    {
      name: {
        km: "ស្លាបក្ដោង",
        latin: "Slab Kdaong"
      },
      id: "25070606"
    },
    {
      name: {
        km: "អណ្ដូងល្វេ",
        latin: "Andoung Lve"
      },
      id: "25070607"
    },
    {
      name: {
        km: "ទួលទ្រាត្បូង",
        latin: "Tuol Trea Tboung"
      },
      id: "25070608"
    },
    {
      name: {
        km: "ទួលទ្រាជើង",
        latin: "Tuol Trea Cheung"
      },
      id: "25070609"
    },
    {
      name: {
        km: "ទួលសំបូរ",
        latin: "Tuol Sambour"
      },
      id: "25070610"
    },
    {
      name: {
        km: "ភូមិ ១៤",
        latin: "Phum Dabbuon"
      },
      id: "25070611"
    },
    {
      name: {
        km: "ភូមិ ១៥",
        latin: "Phum Dabpram"
      },
      id: "25070612"
    },
    {
      name: {
        km: "ភូមិ ៤៦",
        latin: "Phum Saeprammuoy"
      },
      id: "25070613"
    },
    {
      name: {
        km: "ភូមិ ៣៦",
        latin: "Phum Samprammuoy"
      },
      id: "25070614"
    },
    {
      name: {
        km: "ភូមិ ០៤.០៥",
        latin: "Phum Sounbuon Sounpram"
      },
      id: "25070615"
    },
    {
      name: {
        km: "ភូមិ ០៦",
        latin: "Phum Sounprammuoy"
      },
      id: "25070616"
    },
    {
      name: {
        km: "ល្វាតូច",
        latin: "Lvea Touch"
      },
      id: "25070701"
    },
    {
      name: {
        km: "សេះ",
        latin: "Seh"
      },
      id: "25070702"
    },
    {
      name: {
        km: "ក្បាលបឹងសេះ",
        latin: "Kbal Boeng Seh"
      },
      id: "25070703"
    },
    {
      name: {
        km: "ដំណាក់ពពេល",
        latin: "Damnak Popel"
      },
      id: "25070704"
    },
    {
      name: {
        km: "ក្បាលអូរ",
        latin: "Kbal Ou"
      },
      id: "25070705"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "25070706"
    },
    {
      name: {
        km: "ទួលថ្មី",
        latin: "Tuol Thmei"
      },
      id: "25070707"
    },
    {
      name: {
        km: "ទេព្វនិមិត្ដ",
        latin: "Tep Nimitt"
      },
      id: "25070708"
    },
    {
      name: {
        km: "វាលឃ្មុំ",
        latin: "Veal Khmum"
      },
      id: "25070709"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "25070710"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "25070711"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "sambuor"
      },
      id: "25070712"
    },
    {
      name: {
        km: "ភូមិ ៨.១៥",
        latin: "Phum Prambei Dabpram"
      },
      id: "25070713"
    },
    {
      name: {
        km: "ភូមិ ៦.១៦",
        latin: "Phum Prammuoy Dabprammuoy"
      },
      id: "25070714"
    },
    {
      name: {
        km: "ទួលវិហារមានជ័យ",
        latin: "Tuol Vihear Mean Chey"
      },
      id: "25070715"
    },
    {
      name: {
        km: "ទំនប់សែនជ័យ",
        latin: "Tumnob Saen Chey"
      },
      id: "25070716"
    },
    {
      name: {
        km: "ត្រពាំងបី",
        latin: "Trapeang Bei"
      },
      id: "25070717"
    },
    {
      name: {
        km: "វាលទឹកចេញ",
        latin: "Veal Tuek Chenh"
      },
      id: "25070718"
    },
    {
      name: {
        km: "ក្បាលបឹងសេះកើត",
        latin: "Kbal Boeng Seh Kaeut"
      },
      id: "25070719"
    },
    {
      name: {
        km: "សុខសាន្ដ",
        latin: "Sokh Sant"
      },
      id: "25070720"
    },
    {
      name: {
        km: "ល្ងៀង",
        latin: "Lngieng"
      },
      id: "25070801"
    },
    {
      name: {
        km: "គគីរ",
        latin: "Kokir"
      },
      id: "25070802"
    },
    {
      name: {
        km: "ច្រាប",
        latin: "Chrab"
      },
      id: "25070803"
    },
    {
      name: {
        km: "ស្មោញ",
        latin: "Smaonh"
      },
      id: "25070804"
    },
    {
      name: {
        km: "ល្វាធំ",
        latin: "Lvea Thum"
      },
      id: "25070805"
    },
    {
      name: {
        km: "ភូមិ ៤.១៦",
        latin: "Phum Buon Dabprammuoy"
      },
      id: "25070806"
    },
    {
      name: {
        km: "ភូមិ ២.១៧",
        latin: "Phum Pir Dabprampir"
      },
      id: "25070807"
    },
    {
      name: {
        km: "ថ្លុងពង្រ",
        latin: "Thlok Pongro"
      },
      id: "25070808"
    },
    {
      name: {
        km: "គៀនចេក",
        latin: "Kien Chek"
      },
      id: "25070809"
    },
    {
      name: {
        km: "ត្រពាំងស្នោ",
        latin: "Trapeang Snao"
      },
      id: "25070901"
    },
    {
      name: {
        km: "ជីពាង",
        latin: "Chi Peang"
      },
      id: "25070902"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "25070903"
    },
    {
      name: {
        km: "រៀវ",
        latin: "Riev"
      },
      id: "25070904"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "25070905"
    },
    {
      name: {
        km: "មង់ទី ៧",
        latin: "Mong Ti Prampir"
      },
      id: "25070906"
    },
    {
      name: {
        km: "មង់ទី ៦",
        latin: "Mong Ti Prammuoy"
      },
      id: "25070907"
    },
    {
      name: {
        km: "ជើងខាល",
        latin: "Cheung Khal"
      },
      id: "25070908"
    },
    {
      name: {
        km: "ត្រពាំងក្រពើ",
        latin: "Trapeang Krapeu"
      },
      id: "25070909"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "25070910"
    },
    {
      name: {
        km: "ជ្រោយចង្ហា",
        latin: "Chrouy Changha"
      },
      id: "25070911"
    },
    {
      name: {
        km: "តាប៉ាង",
        latin: "Ta Pang"
      },
      id: "25071001"
    },
    {
      name: {
        km: "ព្រែកផ្ដៅ",
        latin: "Preaek Phdau"
      },
      id: "25071002"
    },
    {
      name: {
        km: "កំពង់ចន្លុះលិច",
        latin: "Kampong Chanloh Lech"
      },
      id: "25071003"
    },
    {
      name: {
        km: "ឈើទាលទី ២",
        latin: "Chheu Teal Ti Pir"
      },
      id: "25071004"
    },
    {
      name: {
        km: "កំពង់ចន្លុះកើត",
        latin: "Kampong Chanloh Kaeut"
      },
      id: "25071005"
    },
    {
      name: {
        km: "ឈើទាលតូច",
        latin: "Chheu Teal Touch"
      },
      id: "25071006"
    },
    {
      name: {
        km: "ឈើទាល ទី ១",
        latin: "Chheu Teal Ti Muoy"
      },
      id: "25071007"
    },
    {
      name: {
        km: "ពាមក្នុង",
        latin: "Peam Knong"
      },
      id: "25071008"
    },
    {
      name: {
        km: "បទស្លាស្នាម",
        latin: "Bat Sla Snam"
      },
      id: "25071009"
    },
    {
      name: {
        km: "ប្រាំដំឡឹង",
        latin: "Pram Damloeng"
      },
      id: "25071010"
    },
    {
      name: {
        km: "ព្រែកពាម",
        latin: "Preaek Peam"
      },
      id: "25071011"
    },
    {
      name: {
        km: "ចុងអូរ",
        latin: "Chong Ou"
      },
      id: "25071101"
    },
    {
      name: {
        km: "វាលវង់",
        latin: "Veal Vong"
      },
      id: "25071102"
    },
    {
      name: {
        km: "រកាពប្រាំទី ១",
        latin: "Roka Po Pram Ti Muoy"
      },
      id: "25071103"
    },
    {
      name: {
        km: "ព្រះអង្គ",
        latin: "Preah Angk"
      },
      id: "25071104"
    },
    {
      name: {
        km: "ច័ន្ទនិមិត្ដ",
        latin: "Chant Nimitt"
      },
      id: "25071105"
    },
    {
      name: {
        km: "វិហារសំបូរ",
        latin: "Vihear Sambour"
      },
      id: "25071106"
    },
    {
      name: {
        km: "រកាប្រាំទី ២",
        latin: "Roka Po Pram Ti Pir"
      },
      id: "25071107"
    },
    {
      name: {
        km: "ឈូកសណ្ដល់",
        latin: "Chhuk Sandal"
      },
      id: "25071108"
    },
    {
      name: {
        km: "ត្រពាំងខ្លា",
        latin: "Trapeang Khla"
      },
      id: "25071109"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "25071110"
    },
    {
      name: {
        km: "តាប៉ាវបំពេញទេស",
        latin: "Ta Pav Bampenh Tes"
      },
      id: "25071111"
    },
    {
      name: {
        km: "ប្រផាត់",
        latin: "Praphat"
      },
      id: "25071112"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "25071113"
    },
    {
      name: {
        km: "គោល",
        latin: "Koul"
      },
      id: "25071114"
    },
    {
      name: {
        km: "សមរម្យ",
        latin: "Samrum"
      },
      id: "25071115"
    },
    {
      name: {
        km: "ភ្នំលក",
        latin: "Phnum Lok"
      },
      id: "25071116"
    },
    {
      name: {
        km: "១០.១១",
        latin: "Dab Dabmuoy"
      },
      id: "25071117"
    },
    {
      name: {
        km: "៨.១១",
        latin: "Prambei Dabmuoy"
      },
      id: "25071118"
    },
    {
      name: {
        km: "ជាំម្លូរ",
        latin: "Choam Mlur"
      },
      id: "25071119"
    },
    {
      name: {
        km: "តាពេញ",
        latin: "Ta Penh"
      },
      id: "25071120"
    },
    {
      name: {
        km: "ត្រពាំងព្រលិត",
        latin: "Trapeang Pralit"
      },
      id: "25071121"
    },
    {
      name: {
        km: "ចាន់ទូង",
        latin: "Chan Toung"
      },
      id: "25071201"
    },
    {
      name: {
        km: "ត្របុស្ស",
        latin: "Trabos"
      },
      id: "25071202"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "25071203"
    },
    {
      name: {
        km: "ប្រយ៉ាប",
        latin: "Prayab"
      },
      id: "25071204"
    },
    {
      name: {
        km: "សង្គមថ្មី",
        latin: "Sangkom Thmei"
      },
      id: "25071205"
    },
    {
      name: {
        km: "អង្គរជា",
        latin: "Angkor Chea"
      },
      id: "25071206"
    },
    {
      name: {
        km: "ស្រឡប់",
        latin: "Sralab"
      },
      id: "25071207"
    },
    {
      name: {
        km: "ដងកាំបិត",
        latin: "Dang Kambet"
      },
      id: "25071208"
    },
    {
      name: {
        km: "ខ្លោង",
        latin: "Khlaong"
      },
      id: "25071209"
    },
    {
      name: {
        km: "ត្រពាំងដុំ",
        latin: "Trapeang Dom"
      },
      id: "25071210"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "25071211"
    },
    {
      name: {
        km: "អង្គរជ័យ",
        latin: "Angkor Chey"
      },
      id: "25071212"
    },
    {
      name: {
        km: "វាលខ្នាច",
        latin: "Veal Khnach"
      },
      id: "25071213"
    },
    {
      name: {
        km: "និគមលើ",
        latin: "Nikom Leu"
      },
      id: "25071214"
    },
    {
      name: {
        km: "ប្រធាតុ",
        latin: "Pratheat"
      },
      id: "25071215"
    },
    {
      name: {
        km: "ត្រពាំងគូ",
        latin: "Trapeang Ku"
      },
      id: "25071216"
    },
    {
      name: {
        km: "និគមក្រោម",
        latin: "Nikom Kraom"
      },
      id: "25071217"
    },
    {
      name: {
        km: "អណ្ដូងពក",
        latin: "Andoung Pok"
      },
      id: "25071218"
    },
    {
      name: {
        km: "កៀនរមៀត",
        latin: "Kien Romiet"
      },
      id: "25071219"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "25071220"
    },
    {
      name: {
        km: "ថ្មពេជ្រទី១",
        latin: "Thma Pech Ti Muoy"
      },
      id: "25071301"
    },
    {
      name: {
        km: "ថ្មពេជ្រទី២",
        latin: "Thma Pech Ti Pir"
      },
      id: "25071302"
    },
    {
      name: {
        km: "ថ្មពេជ្រទី៣",
        latin: "Thma Pech Ti Bei"
      },
      id: "25071303"
    },
    {
      name: {
        km: "ពើក",
        latin: "Peuk"
      },
      id: "25071304"
    },
    {
      name: {
        km: "រោងគោ",
        latin: "Roung Kou"
      },
      id: "25071305"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "25071306"
    },
    {
      name: {
        km: "ជៀសទី១",
        latin: "Chies Ti Muoy"
      },
      id: "25071307"
    },
    {
      name: {
        km: "ជៀសទី២",
        latin: "Chies Ti Pir"
      },
      id: "25071308"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "25071309"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "25071310"
    },
    {
      name: {
        km: "ភូមិ ៦៧",
        latin: "Phum Hokprampir"
      },
      id: "25071311"
    },
    {
      name: {
        km: "ភូមិ ៨/១០",
        latin: "Phum Prambei Dab"
      },
      id: "25071312"
    },
    {
      name: {
        km: "ភូមិ ៨៧",
        latin: "Phum Paetprampir"
      },
      id: "25071313"
    },
    {
      name: {
        km: "ភូមិ ១០/៨",
        latin: "Phum Dab - Prambei"
      },
      id: "25071314"
    },
    {
      name: {
        km: "ទួលអូរពីរ",
        latin: "Tuol Ou Pir"
      },
      id: "25071315"
    },
    {
      name: {
        km: "អន្លង់ពោង",
        latin: "Anlung Poung"
      },
      id: "25071401"
    },
    {
      name: {
        km: "ជ្រោយស្រឡៅ",
        latin: "Chrouy Sralau"
      },
      id: "25071402"
    },
    {
      name: {
        km: "ភ្នំ",
        latin: "Phnum"
      },
      id: "25071403"
    },
    {
      name: {
        km: "អំពិលជ្រុំ",
        latin: "Ampil Chrum"
      },
      id: "25071404"
    },
    {
      name: {
        km: "ទួលគរ",
        latin: "Tuol Kor"
      },
      id: "25071405"
    },
    {
      name: {
        km: "ព្រែកតូច",
        latin: "Preaek Touch"
      },
      id: "25071406"
    },
    {
      name: {
        km: "ព្រែកជីក",
        latin: "Preaek Chik"
      },
      id: "25071407"
    },
    {
      name: {
        km: "ទួលខ្សាច់",
        latin: "Tuol Khsach"
      },
      id: "25071408"
    },
    {
      name: {
        km: "ដូនម៉ៅក្រោម",
        latin: "Doun Mau Kraom"
      },
      id: "25071409"
    },
    {
      name: {
        km: "ដូនម៉ៅលើ",
        latin: "Doun Mau Leu"
      },
      id: "25071410"
    },
    {
      name: {
        km: "ទន្លេបិទក្រោម",
        latin: "Tonle Bet Kraom"
      },
      id: "25071411"
    },
    {
      name: {
        km: "ទន្លេបិទលើ",
        latin: "Tonle Bet Leu"
      },
      id: "25071412"
    },
    {
      name: {
        km: "យាយស",
        latin: "Yeay Sar"
      },
      id: "25071413"
    },
    {
      name: {
        km: "ទន្លេបិទ",
        latin: "Tonle Bet"
      },
      id: "25071414"
    },
    {
      name: {
        km: "តាអ៊ុយ",
        latin: "Ta Uy"
      },
      id: "25071415"
    },
    {
      name: {
        km: "អូរធំ",
        latin: "Ou Thum"
      },
      id: "01020101"
    },
    {
      name: {
        km: "ភ្នំ",
        latin: "Phnum"
      },
      id: "01020102"
    },
    {
      name: {
        km: "បន្ទាយនាង",
        latin: "Banteay Neang"
      },
      id: "01020103"
    },
    {
      name: {
        km: "គោកព្នៅ",
        latin: "Kouk Pnov"
      },
      id: "01020104"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Trang"
      },
      id: "01020105"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "01020106"
    },
    {
      name: {
        km: "គោកទន្លាប់",
        latin: "Kouk Tonloab"
      },
      id: "01020107"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "01020108"
    },
    {
      name: {
        km: "ឃីឡិក",
        latin: "Khilek"
      },
      id: "01020109"
    },
    {
      name: {
        km: "សំរោងពេន",
        latin: "Samraong Pen"
      },
      id: "01020110"
    },
    {
      name: {
        km: "ដងរុនលិច",
        latin: "Dang Run Lech"
      },
      id: "01020111"
    },
    {
      name: {
        km: "ដងរុនកើត",
        latin: "Dang Run Kaeut"
      },
      id: "01020112"
    },
    {
      name: {
        km: "អូរស្ងួត",
        latin: "Ou Snguot"
      },
      id: "01020113"
    },
    {
      name: {
        km: "ព្រៃចង្ហាលិច",
        latin: "Prey Changha Lech"
      },
      id: "01020114"
    },
    {
      name: {
        km: "ព្រៃចង្ហាកើត",
        latin: "Prey Changha Kaeut"
      },
      id: "01020115"
    },
    {
      name: {
        km: "អូរអណ្ដូងលិច",
        latin: "Ou Andoung Lech"
      },
      id: "01020116"
    },
    {
      name: {
        km: "អូរអណ្ដូងកណ្ដាល",
        latin: "Ou Andoung Kandal"
      },
      id: "01020117"
    },
    {
      name: {
        km: "អូរអណ្ដូងកើត",
        latin: "Ou Andoung Kaeut"
      },
      id: "01020118"
    },
    {
      name: {
        km: "គោកក្ដួច",
        latin: "Kouk Kduoch"
      },
      id: "01020119"
    },
    {
      name: {
        km: "ខ្ទុម្ពរាយលិច",
        latin: "Khtum Reay Lech"
      },
      id: "01020201"
    },
    {
      name: {
        km: "ខ្ទុម្ពរាយកើត",
        latin: "Khtum Reay Kaeut"
      },
      id: "01020202"
    },
    {
      name: {
        km: "អន្លង់ថ្ងាន់កើត",
        latin: "Anlong Thngan Kaeut"
      },
      id: "01020203"
    },
    {
      name: {
        km: "អន្លង់ថ្ងាន់លិច",
        latin: "Anlong Thngan Lech"
      },
      id: "01020204"
    },
    {
      name: {
        km: "បង់បត់លិច",
        latin: "Bang Bat Lech"
      },
      id: "01020205"
    },
    {
      name: {
        km: "បង់បត់កើត",
        latin: "Bang Bat Kaeut"
      },
      id: "01020206"
    },
    {
      name: {
        km: "បត់ត្រង់",
        latin: "Bat Trang"
      },
      id: "01020207"
    },
    {
      name: {
        km: "បត់ត្រង់ធំលិច",
        latin: "Bat Trang Thum Lech"
      },
      id: "01020208"
    },
    {
      name: {
        km: "បត់ត្រង់ធំកើត",
        latin: "Bat Trang Thum Kaeut"
      },
      id: "01020209"
    },
    {
      name: {
        km: "បត់ត្រង់តូច",
        latin: "Bat Trang Touch"
      },
      id: "01020210"
    },
    {
      name: {
        km: "ព្រែកជីក",
        latin: "Preaek Chik"
      },
      id: "01020211"
    },
    {
      name: {
        km: "ប្រឡាយចារ៍",
        latin: "Pralay Char"
      },
      id: "01020301"
    },
    {
      name: {
        km: "រង្វានលិច",
        latin: "Rongvean Lech"
      },
      id: "01020302"
    },
    {
      name: {
        km: "រង្វានកើត",
        latin: "Rongvean Kaeut"
      },
      id: "01020303"
    },
    {
      name: {
        km: "ចំណោមលិច",
        latin: "Chamnaom Lech"
      },
      id: "01020304"
    },
    {
      name: {
        km: "ចំណោមកើត",
        latin: "Chamnaom Kaeut"
      },
      id: "01020305"
    },
    {
      name: {
        km: "រោងគោដើម",
        latin: "Roung Kou Daeum"
      },
      id: "01020306"
    },
    {
      name: {
        km: "រោងគោកណ្ដាល",
        latin: "Roung Kou Kandal"
      },
      id: "01020307"
    },
    {
      name: {
        km: "រោងគោចុង",
        latin: "Roung Kou Chong"
      },
      id: "01020308"
    },
    {
      name: {
        km: "ពាមរោងគោ",
        latin: "Peam Roung Kou"
      },
      id: "01020309"
    },
    {
      name: {
        km: "តាសល់",
        latin: "Ta Sal"
      },
      id: "01020310"
    },
    {
      name: {
        km: "ជួរខ្ចាស់",
        latin: "Chuor Khchas"
      },
      id: "01020311"
    },
    {
      name: {
        km: "បឹងត្រស់",
        latin: "Boeng Tras"
      },
      id: "01020312"
    },
    {
      name: {
        km: "ដងត្រាង",
        latin: "Dang Trang"
      },
      id: "01020313"
    },
    {
      name: {
        km: "ស្រែព្រៃ",
        latin: "Srae Prey"
      },
      id: "01020314"
    },
    {
      name: {
        km: "បុស្បទន្លាប់",
        latin: "Bos Tonloab"
      },
      id: "01020315"
    },
    {
      name: {
        km: "តាប៊ុន",
        latin: "Ta  Bun"
      },
      id: "01020316"
    },
    {
      name: {
        km: "គោកពន្លៃ",
        latin: "Kouk Ponley"
      },
      id: "01020317"
    },
    {
      name: {
        km: "សាយសាម៉ន",
        latin: "Say Samon"
      },
      id: "01020318"
    },
    {
      name: {
        km: "គោកបល្ល័ង្ក",
        latin: "Kouk Ballangk"
      },
      id: "01020401"
    },
    {
      name: {
        km: "តាអន",
        latin: "Ta An"
      },
      id: "01020402"
    },
    {
      name: {
        km: "ប្រឡាយជ្រៃ",
        latin: "Pralay Chrey"
      },
      id: "01020403"
    },
    {
      name: {
        km: "ជើងចាប",
        latin: "Cheung Chab"
      },
      id: "01020404"
    },
    {
      name: {
        km: "ផាត់សណ្ដោង",
        latin: "Phat Sandaong"
      },
      id: "01020405"
    },
    {
      name: {
        km: "ចារថ្មី",
        latin: "Char Thmei"
      },
      id: "01020406"
    },
    {
      name: {
        km: "ផ្អាវថ្មី",
        latin: "Ph'av Thmei"
      },
      id: "01020407"
    },
    {
      name: {
        km: "តាសល់",
        latin: "Ta Sal"
      },
      id: "01020408"
    },
    {
      name: {
        km: "គយម៉ែង",
        latin: "Koy Maeng"
      },
      id: "01020501"
    },
    {
      name: {
        km: "ស្ដីលើ",
        latin: "Sdei Leu"
      },
      id: "01020502"
    },
    {
      name: {
        km: "ផ្លូវសៀម",
        latin: "Phlov Siem"
      },
      id: "01020503"
    },
    {
      name: {
        km: "តានង",
        latin: "Ta Nong"
      },
      id: "01020504"
    },
    {
      name: {
        km: "អង្ករខ្មៅ",
        latin: "Angkar Khmau"
      },
      id: "01020505"
    },
    {
      name: {
        km: "កសាងថ្មី",
        latin: "Kasang Thmei"
      },
      id: "01020506"
    },
    {
      name: {
        km: "ស្ទឹងចាស់",
        latin: "Stueng Chas"
      },
      id: "01020507"
    },
    {
      name: {
        km: "ស្ដីក្រោម",
        latin: "Sdei Kraom"
      },
      id: "01020508"
    },
    {
      name: {
        km: "ភ្នំធំត្បូង",
        latin: "Phnum Thum Tboung"
      },
      id: "01020601"
    },
    {
      name: {
        km: "ភ្នំប្រាសាទ",
        latin: "Phnum Prasat"
      },
      id: "01020602"
    },
    {
      name: {
        km: "ភ្នំធំជើង",
        latin: "Phnum Thum Cheung"
      },
      id: "01020603"
    },
    {
      name: {
        km: "ចំការលោក",
        latin: "Chamkar Louk"
      },
      id: "01020604"
    },
    {
      name: {
        km: "ភ្នំធំថ្មី",
        latin: "Phnum Thum Thmei"
      },
      id: "01020605"
    },
    {
      name: {
        km: "អន្លង់ស្ដី",
        latin: "Anlong Sdei"
      },
      id: "01020606"
    },
    {
      name: {
        km: "គោកធ្លង់កើត",
        latin: "Kouk Thlong Kaeut"
      },
      id: "01020607"
    },
    {
      name: {
        km: "គោកធ្លង់កណ្ដាល",
        latin: "Kouk Thlong Kandal"
      },
      id: "01020608"
    },
    {
      name: {
        km: "អូរស្ងួត",
        latin: "Ou Snguot"
      },
      id: "01020609"
    },
    {
      name: {
        km: "អូរប្រាសាទ",
        latin: "Ou Prasat"
      },
      id: "01020610"
    },
    {
      name: {
        km: "គោកអំពិល",
        latin: "Kouk Ampil"
      },
      id: "01020611"
    },
    {
      name: {
        km: "រ៉ាចំការចេក",
        latin: "Ra Chamkar Chek"
      },
      id: "01020612"
    },
    {
      name: {
        km: "ពោធិ៍រៀង",
        latin: "Pou Rieng"
      },
      id: "01020613"
    },
    {
      name: {
        km: "រូងក្របៅ",
        latin: "Rung Krabau"
      },
      id: "01020614"
    },
    {
      name: {
        km: "ភ្នំតូចត្បូង",
        latin: "Phnum Touch Tboung"
      },
      id: "01020701"
    },
    {
      name: {
        km: "ភ្នំតូចជើង",
        latin: "Phnum Touch Cheung"
      },
      id: "01020702"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "01020703"
    },
    {
      name: {
        km: "អូរញរ",
        latin: "Ou Nhor"
      },
      id: "01020704"
    },
    {
      name: {
        km: "បឹងត្រស់",
        latin: "Boeng Tras"
      },
      id: "01020705"
    },
    {
      name: {
        km: "មនោរម្យ",
        latin: "Monourom"
      },
      id: "01020706"
    },
    {
      name: {
        km: "ប៉ោយតាសេក",
        latin: "Paoy Ta Sek"
      },
      id: "01020707"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "01020708"
    },
    {
      name: {
        km: "បឹងរាំង",
        latin: "Boeng Reang"
      },
      id: "01020709"
    },
    {
      name: {
        km: "វត្ដថ្មី",
        latin: "Voat Thmei"
      },
      id: "01020710"
    },
    {
      name: {
        km: "ពោធិ៍ពីរដើម",
        latin: "Pou Pir Daeum"
      },
      id: "01020801"
    },
    {
      name: {
        km: "រហាត់ទឹក",
        latin: "Rohat Tuek"
      },
      id: "01020802"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "01020803"
    },
    {
      name: {
        km: "ក្រមល់",
        latin: "Kramol"
      },
      id: "01020804"
    },
    {
      name: {
        km: "ខ្ទុំជ្រុំ",
        latin: "Khtum Chrum"
      },
      id: "01020805"
    },
    {
      name: {
        km: "ចកលិច",
        latin: "Chak Lech"
      },
      id: "01020806"
    },
    {
      name: {
        km: "ដូនមូល",
        latin: "Doun Mul"
      },
      id: "01020807"
    },
    {
      name: {
        km: "ព្រែកសំរោង",
        latin: "Preaek Samraong"
      },
      id: "01020808"
    },
    {
      name: {
        km: "អូរដង្កោ",
        latin: "Ou Dangkao"
      },
      id: "01020809"
    },
    {
      name: {
        km: "ចំការចេក",
        latin: "Chamkar Chek"
      },
      id: "01020810"
    },
    {
      name: {
        km: "អូរជួប",
        latin: "Ou Chuob"
      },
      id: "01020811"
    },
    {
      name: {
        km: "កស្វាយ",
        latin: "Ka Svay"
      },
      id: "01020812"
    },
    {
      name: {
        km: "ចកកើត",
        latin: "Chak Kaeut"
      },
      id: "01020813"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "01020901"
    },
    {
      name: {
        km: "នាងកេត",
        latin: "Neang Ket"
      },
      id: "01020902"
    },
    {
      name: {
        km: "ព្រែករពៅ",
        latin: "Praek Ropov"
      },
      id: "01020903"
    },
    {
      name: {
        km: "សាលាដែង",
        latin: "Sala Daeng"
      },
      id: "01020904"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "01020905"
    },
    {
      name: {
        km: "អន្លង់មានទ្រព្យ",
        latin: "Anlong Mean Troap"
      },
      id: "01020906"
    },
    {
      name: {
        km: "ចំការតាដោក",
        latin: "Chamkar Ta Daok"
      },
      id: "01020907"
    },
    {
      name: {
        km: "ប្រទ្បាយហ្លួងក្រោម",
        latin: "Pralay Luong Kraom"
      },
      id: "01020908"
    },
    {
      name: {
        km: "ហ្លួង",
        latin: "Luong"
      },
      id: "01020909"
    },
    {
      name: {
        km: "អូរតាគល់",
        latin: "Ou Ta Kol"
      },
      id: "01020910"
    },
    {
      name: {
        km: "ប្រទ្បាយហ្លួងលើ",
        latin: "Pralay Luong Leu"
      },
      id: "01020911"
    },
    {
      name: {
        km: "គោកស្វាយ",
        latin: "Kouk Svay"
      },
      id: "01020912"
    },
    {
      name: {
        km: "អូរតាម៉ា",
        latin: "Ou Ta Ma"
      },
      id: "01020913"
    },
    {
      name: {
        km: "កោះកែវ",
        latin: "Kaoh Kaev"
      },
      id: "01020914"
    },
    {
      name: {
        km: "ផាស៊ីស្រា",
        latin: "Phasi Sra"
      },
      id: "01020915"
    },
    {
      name: {
        km: "ឫស្សីក្រោក",
        latin: "Ruessei Kraok"
      },
      id: "01020916"
    },
    {
      name: {
        km: "ជំទាវ",
        latin: "Chumteav"
      },
      id: "01020917"
    },
    {
      name: {
        km: "ឆ្នើមមាស",
        latin: "Chhnaeum Meas"
      },
      id: "01021001"
    },
    {
      name: {
        km: "ស្រណាល",
        latin: "Sranal"
      },
      id: "01021002"
    },
    {
      name: {
        km: "ឡ",
        latin: "La"
      },
      id: "01021003"
    },
    {
      name: {
        km: "តាមែងពក",
        latin: "Ta Meaeng Pok"
      },
      id: "01021004"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "01021005"
    },
    {
      name: {
        km: "ដូនឡឹក",
        latin: "Doun Loek"
      },
      id: "01021006"
    },
    {
      name: {
        km: "ក្បាលក្របី",
        latin: "Kbal Krabei"
      },
      id: "01021007"
    },
    {
      name: {
        km: "ស្រះឈូក",
        latin: "Srah Chhuk"
      },
      id: "01021008"
    },
    {
      name: {
        km: "ស្រែព្រៃ",
        latin: "Srae Prey"
      },
      id: "01021009"
    },
    {
      name: {
        km: "ចែកអង្ករ",
        latin: "Chaek Angkar"
      },
      id: "01021010"
    },
    {
      name: {
        km: "ថ្មដប់",
        latin: "Thma Dab"
      },
      id: "01021011"
    },
    {
      name: {
        km: "តាម៉ៅ",
        latin: "Ta Mau"
      },
      id: "01021101"
    },
    {
      name: {
        km: "អន្សមចេក",
        latin: "Ansam Chek"
      },
      id: "01021102"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "01021103"
    },
    {
      name: {
        km: "បួរ",
        latin: "Buor"
      },
      id: "01021104"
    },
    {
      name: {
        km: "បុស្សឡោក",
        latin: "Bos Laok"
      },
      id: "01021105"
    },
    {
      name: {
        km: "សឿ",
        latin: "Soea"
      },
      id: "01021106"
    },
    {
      name: {
        km: "បឹងតូច",
        latin: "Boeng Touch"
      },
      id: "01021107"
    },
    {
      name: {
        km: "ផ្លូវដំរីលើ",
        latin: "Phlov Damrei Leu"
      },
      id: "01021108"
    },
    {
      name: {
        km: "ផ្លូវដំរីក្រោម",
        latin: "Phlov Damrei Kraom"
      },
      id: "01021109"
    },
    {
      name: {
        km: "អូរសឿ",
        latin: "Ou Soea"
      },
      id: "01021110"
    },
    {
      name: {
        km: "គោកសំរោង",
        latin: "Kouk Samraong"
      },
      id: "01021111"
    },
    {
      name: {
        km: "បល្ល័ង្គជ្រៃ",
        latin: "Balang Chrey"
      },
      id: "01021112"
    },
    {
      name: {
        km: "អូរជួបថ្មី",
        latin: "Ou Choub Thmey"
      },
      id: "01021113"
    },
    {
      name: {
        km: "តាអ៊ិន១",
        latin: "Ta In Muoy"
      },
      id: "01021201"
    },
    {
      name: {
        km: "តាអ៊ិន២",
        latin: "Ta In Pir"
      },
      id: "01021202"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "01021203"
    },
    {
      name: {
        km: "ចំការចេក",
        latin: "Chamkar Chek"
      },
      id: "01021204"
    },
    {
      name: {
        km: "ស្រះរាំង",
        latin: "Srah Reang"
      },
      id: "01021205"
    },
    {
      name: {
        km: "តាចាន់",
        latin: "Ta Chan"
      },
      id: "01021206"
    },
    {
      name: {
        km: "គោកស្រុក",
        latin: "Kouk Srok"
      },
      id: "01021207"
    },
    {
      name: {
        km: "គោកច្រាប",
        latin: "Kouk Chrab"
      },
      id: "01021208"
    },
    {
      name: {
        km: "គោកក្រសាំង",
        latin: "Kouk Krasang"
      },
      id: "01021209"
    },
    {
      name: {
        km: "ព្រះស្រែ",
        latin: "Preah Srae"
      },
      id: "01021301"
    },
    {
      name: {
        km: "តាឡំកណ្ដាល",
        latin: "Ta Lam Kandal"
      },
      id: "01021302"
    },
    {
      name: {
        km: "តាឡំចុង",
        latin: "Ta Lam Chong"
      },
      id: "01021303"
    },
    {
      name: {
        km: "បឹងឃ្លាំងលិច",
        latin: "Boeng Khleang Lech"
      },
      id: "01021304"
    },
    {
      name: {
        km: "ចុងគោក",
        latin: "Chong Kouk"
      },
      id: "01021305"
    },
    {
      name: {
        km: "បឹងឃ្លាំងកើត",
        latin: "Boeng Khleang Kaeut"
      },
      id: "01021306"
    },
    {
      name: {
        km: "ខ្លាខាំឆ្កែ",
        latin: "Khla Kham Chhkae"
      },
      id: "01021307"
    },
    {
      name: {
        km: "បឹងវែង",
        latin: "Boeng Veaeng"
      },
      id: "01021308"
    },
    {
      name: {
        km: "រង្វាន",
        latin: "Rongvean"
      },
      id: "01030101"
    },
    {
      name: {
        km: "ថ្មីខាងត្បូង",
        latin: "Thmei Khang Tboung"
      },
      id: "01030102"
    },
    {
      name: {
        km: "ថ្មីខាងជើង",
        latin: "Thmei Khang Cheung"
      },
      id: "01030103"
    },
    {
      name: {
        km: "គោកយាង",
        latin: "Kouk Yeang"
      },
      id: "01030104"
    },
    {
      name: {
        km: "គោកចាស់",
        latin: "Kouk Chas"
      },
      id: "01030105"
    },
    {
      name: {
        km: "ច្រាប",
        latin: "Chrab"
      },
      id: "01030106"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "01030107"
    },
    {
      name: {
        km: "ណាំតៅ",
        latin: "Nam Tau"
      },
      id: "01030108"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "01030109"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "01030110"
    },
    {
      name: {
        km: "ក្នាំង",
        latin: "Khnang"
      },
      id: "01030111"
    },
    {
      name: {
        km: "ធ្នង់ខាងត្បូង",
        latin: "Thnong Khang Tboung"
      },
      id: "01030112"
    },
    {
      name: {
        km: "ធ្នង់ខាងជើង",
        latin: "Thnong Khang Cheung"
      },
      id: "01030113"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "01030114"
    },
    {
      name: {
        km: "តាគង់",
        latin: "Takong"
      },
      id: "01030115"
    },
    {
      name: {
        km: "យាងឧត្ដម",
        latin: "Yeang Otdam"
      },
      id: "01030116"
    },
    {
      name: {
        km: "អំពិលកោង",
        latin: "Ampil Kaong"
      },
      id: "01030117"
    },
    {
      name: {
        km: "កុងសៀម",
        latin: "Kong Siem"
      },
      id: "01030118"
    },
    {
      name: {
        km: "ប៉ោយស្នួល",
        latin: "Paoy Snuol"
      },
      id: "01030201"
    },
    {
      name: {
        km: "ប៉ោយចារ",
        latin: "Paoy Char"
      },
      id: "01030202"
    },
    {
      name: {
        km: "ត្រពាំងថ្មត្បូង",
        latin: "Trapeang Thma Tboung"
      },
      id: "01030203"
    },
    {
      name: {
        km: "ត្រពាំងថ្មជើង",
        latin: "Trapeang Thma Cheung"
      },
      id: "01030204"
    },
    {
      name: {
        km: "ត្រពាំងថ្មកណ្ដាល",
        latin: "Trapeang Thma Kandal"
      },
      id: "01030205"
    },
    {
      name: {
        km: "ប៉ោយតាអុង",
        latin: "Paoy Ta Ong"
      },
      id: "01030206"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "01030207"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "01030208"
    },
    {
      name: {
        km: "កូនខ្លែង",
        latin: "Koun Klaeng"
      },
      id: "01030209"
    },
    {
      name: {
        km: "ព្រះអណ្តូង",
        latin: "Pras Andoung"
      },
      id: "01030210"
    },
    {
      name: {
        km: "តាវង",
        latin: "Ta Vong"
      },
      id: "01030301"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "01030302"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "01030303"
    },
    {
      name: {
        km: "ស្វាយខ្មៅ",
        latin: "Svay Khmau"
      },
      id: "01030304"
    },
    {
      name: {
        km: "គោគតាសុខ",
        latin: "Kouk Ta Sokh"
      },
      id: "01030305"
    },
    {
      name: {
        km: "ពោធិ៍រាំបុណ្យ",
        latin: "Pou Roam Bon"
      },
      id: "01030306"
    },
    {
      name: {
        km: "រោគ",
        latin: "Rouk"
      },
      id: "01030401"
    },
    {
      name: {
        km: "មុខឈ្នាង",
        latin: "Mukh Chhneang"
      },
      id: "01030402"
    },
    {
      name: {
        km: "ស្ពាន",
        latin: "Spean"
      },
      id: "01030403"
    },
    {
      name: {
        km: "គោកចារ",
        latin: "Kouk Char"
      },
      id: "01030404"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "01030405"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "01030406"
    },
    {
      name: {
        km: "មាត់ស្រះ",
        latin: "Moat Srah"
      },
      id: "01030501"
    },
    {
      name: {
        km: "ស្រះឈូកខាងលិច",
        latin: "Srah Chhuk Khang Lech"
      },
      id: "01030502"
    },
    {
      name: {
        km: "ស្រះជីក",
        latin: "Srah Chik"
      },
      id: "01030503"
    },
    {
      name: {
        km: "គោកក្រោល",
        latin: "Kouk Kral"
      },
      id: "01030504"
    },
    {
      name: {
        km: "គោករំចេក",
        latin: "Kouk Rumchek"
      },
      id: "01030505"
    },
    {
      name: {
        km: "គោកតារាជ្យ",
        latin: "Kouk Ta Reach"
      },
      id: "01030506"
    },
    {
      name: {
        km: "កណ្ដាលខាងលិច",
        latin: "Kandal Khang Lech"
      },
      id: "01030507"
    },
    {
      name: {
        km: "កណ្ដាលខាងកើត",
        latin: "Kandal Khang Kaeut"
      },
      id: "01030508"
    },
    {
      name: {
        km: "ស្រះឈូកខាងកើត",
        latin: "Srah Chhuk Khang Kaeut"
      },
      id: "01030509"
    },
    {
      name: {
        km: "ភ្នំដី",
        latin: "Phnum Dei"
      },
      id: "01030601"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "01030602"
    },
    {
      name: {
        km: "គោកសេះ",
        latin: "Kouk Seh"
      },
      id: "01030603"
    },
    {
      name: {
        km: "ថ្នល់ដាច់",
        latin: "Thnal Dach"
      },
      id: "01030604"
    },
    {
      name: {
        km: "បុស្បូវ",
        latin: "Bos Sbov"
      },
      id: "01030605"
    },
    {
      name: {
        km: "ត្រពាំងប្រីយ៍",
        latin: "Trapeang Prei"
      },
      id: "01030606"
    },
    {
      name: {
        km: "កំពីងពួយ",
        latin: "Kamping Puoy"
      },
      id: "01030607"
    },
    {
      name: {
        km: "ស្ពានក្មេង",
        latin: "Spean Khmeng"
      },
      id: "01030608"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Trang"
      },
      id: "01030609"
    },
    {
      name: {
        km: "សំរោងតូច",
        latin: "Samraong Touch"
      },
      id: "01040101"
    },
    {
      name: {
        km: "គោកពង្រ",
        latin: "Kouk Pongro"
      },
      id: "01040102"
    },
    {
      name: {
        km: "ថ្មគោល",
        latin: "Thma Koul"
      },
      id: "01040103"
    },
    {
      name: {
        km: "ប្រពុត",
        latin: "Proput"
      },
      id: "01040104"
    },
    {
      name: {
        km: "បន្ទាត់បោះ",
        latin: "Bantoat Baoh"
      },
      id: "01040105"
    },
    {
      name: {
        km: "គោកទ្រាស",
        latin: "Kouk Treas"
      },
      id: "01040106"
    },
    {
      name: {
        km: "ឈ្នួរ",
        latin: "Chhnuor"
      },
      id: "01040107"
    },
    {
      name: {
        km: "សំរោងធំ",
        latin: "Samraong Thum"
      },
      id: "01040108"
    },
    {
      name: {
        km: "ឫស្សីខាង",
        latin: "Ruessei Khang"
      },
      id: "01040109"
    },
    {
      name: {
        km: "ឫស្សីកណ្ដាល",
        latin: "Ruessei Kandal"
      },
      id: "01040110"
    },
    {
      name: {
        km: "រពាត់",
        latin: "Ropeatt"
      },
      id: "01040111"
    },
    {
      name: {
        km: "គោកត្រាច",
        latin: "Kouk Trach"
      },
      id: "01040112"
    },
    {
      name: {
        km: "ជប់",
        latin: "Chob"
      },
      id: "01040201"
    },
    {
      name: {
        km: "រោលជ្រូក",
        latin: "Roul Chruk"
      },
      id: "01040202"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "01040203"
    },
    {
      name: {
        km: "ក្រសាំងថ្មី",
        latin: "Krasang Thmei"
      },
      id: "01040204"
    },
    {
      name: {
        km: "ប្រដាក",
        latin: "Pradak"
      },
      id: "01040205"
    },
    {
      name: {
        km: "ជ្រាប់ថ្មី",
        latin: "Chroab Thmei"
      },
      id: "01040206"
    },
    {
      name: {
        km: "ជ្រាប់ចាស់",
        latin: "Chroab Chas"
      },
      id: "01040207"
    },
    {
      name: {
        km: "កក់",
        latin: "Kak"
      },
      id: "01040208"
    },
    {
      name: {
        km: "គោកលុន",
        latin: "Kouk Lun"
      },
      id: "01040209"
    },
    {
      name: {
        km: "ភ្នំជញ្ជាំង",
        latin: "Phnum Chonhcheang"
      },
      id: "01040210"
    },
    {
      name: {
        km: "ចក្រី",
        latin: "Chakkrei"
      },
      id: "01040211"
    },
    {
      name: {
        km: "ភ្នំលៀបកើត",
        latin: "Phnum Lieb Kaeut"
      },
      id: "01040301"
    },
    {
      name: {
        km: "ភ្នំលៀបលិច",
        latin: "Phnum Lieb Lech"
      },
      id: "01040302"
    },
    {
      name: {
        km: "ទ្រយោង",
        latin: "Troyoung"
      },
      id: "01040303"
    },
    {
      name: {
        km: "ទ្រលោកជើង",
        latin: "Tro Louk Cheung"
      },
      id: "01040304"
    },
    {
      name: {
        km: "ទ្រលោកលិច",
        latin: "Tro Louk Lech"
      },
      id: "01040305"
    },
    {
      name: {
        km: "ទ្រលោកត្បូង",
        latin: "Tro Louk Tboung"
      },
      id: "01040306"
    },
    {
      name: {
        km: "ឡោទេ",
        latin: "Laote"
      },
      id: "01040307"
    },
    {
      name: {
        km: "រំដួល",
        latin: "Rumduol"
      },
      id: "01040308"
    },
    {
      name: {
        km: "ព្រីងជួរ",
        latin: "Pring Chuor"
      },
      id: "01040309"
    },
    {
      name: {
        km: "កន្ដ្រប់",
        latin: "Kantrab"
      },
      id: "01040310"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "01040311"
    },
    {
      name: {
        km: "អន្លង់សា",
        latin: "Anlong Sa"
      },
      id: "01040312"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "01040313"
    },
    {
      name: {
        km: "កំបោរ",
        latin: "Kambaor"
      },
      id: "01040314"
    },
    {
      name: {
        km: "កាបៅ",
        latin: "Kabau"
      },
      id: "01040315"
    },
    {
      name: {
        km: "កំពង់ក្រសាំង",
        latin: "Kampong Krasang"
      },
      id: "01040316"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "01040401"
    },
    {
      name: {
        km: "បត់ត្រង់",
        latin: "Bat Trang"
      },
      id: "01040402"
    },
    {
      name: {
        km: "សំពៅលូន",
        latin: "Sampov Lun"
      },
      id: "01040403"
    },
    {
      name: {
        km: "ផ្លូវលាវ",
        latin: "Phlov Leav"
      },
      id: "01040404"
    },
    {
      name: {
        km: "តាអំ",
        latin: "Ta Am"
      },
      id: "01040405"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "01040406"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "01040407"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "01040408"
    },
    {
      name: {
        km: "ឱម៉ាល់",
        latin: "Aomal"
      },
      id: "01040409"
    },
    {
      name: {
        km: "ទម្លាប់",
        latin: "Tumloab"
      },
      id: "01040410"
    },
    {
      name: {
        km: "កៀនបន្ទាយ",
        latin: "Kien Banteay"
      },
      id: "01040411"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "01040412"
    },
    {
      name: {
        km: "អន្លង់សា",
        latin: "Anlong Sa"
      },
      id: "01040413"
    },
    {
      name: {
        km: "កំព្រាម",
        latin: "Kampream"
      },
      id: "01040414"
    },
    {
      name: {
        km: "ប៉ោយក្ដឿង",
        latin: "Paoy Kdoeang"
      },
      id: "01040501"
    },
    {
      name: {
        km: "ជើងវត្ដ",
        latin: "Cheung Voat"
      },
      id: "01040502"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "01040503"
    },
    {
      name: {
        km: "ប៉ុស្ដិចាស់",
        latin: "Post Chas"
      },
      id: "01040504"
    },
    {
      name: {
        km: "ប៉ោយសំរោង",
        latin: "Paoy Samraong"
      },
      id: "01040505"
    },
    {
      name: {
        km: "ប៉ោយព្រីង",
        latin: "Paoy Pring"
      },
      id: "01040506"
    },
    {
      name: {
        km: "ប៉ោយតាប៉ែន",
        latin: "Paoy Ta Paen"
      },
      id: "01040507"
    },
    {
      name: {
        km: "ស្រេះកើត",
        latin: "Sreh Kaeut"
      },
      id: "01040508"
    },
    {
      name: {
        km: "ស្រេះលិច",
        latin: "Sreh Lech"
      },
      id: "01040509"
    },
    {
      name: {
        km: "គោកស្រុក",
        latin: "Kouk Srok"
      },
      id: "01040510"
    },
    {
      name: {
        km: "ស្រមោច",
        latin: "Sramaoch"
      },
      id: "01040511"
    },
    {
      name: {
        km: "ពាមស្រេះ",
        latin: "Peam Sreh"
      },
      id: "01040512"
    },
    {
      name: {
        km: "ដូនច្រែង",
        latin: "Doun Chraeng"
      },
      id: "01040513"
    },
    {
      name: {
        km: "រហាល",
        latin: "Rohal"
      },
      id: "01040601"
    },
    {
      name: {
        km: "សាលាឆេះ",
        latin: "Sala Chheh"
      },
      id: "01040602"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "01040603"
    },
    {
      name: {
        km: "ទេពកោសា",
        latin: "Tep Kaosa"
      },
      id: "01040604"
    },
    {
      name: {
        km: "ស្នាយ",
        latin: "Snay"
      },
      id: "01040605"
    },
    {
      name: {
        km: "អន្លង់ថ្ម",
        latin: "Anlong Thma"
      },
      id: "01040606"
    },
    {
      name: {
        km: "ពពេល",
        latin: "Popel"
      },
      id: "01040607"
    },
    {
      name: {
        km: "ប៉ោយស្វាយ",
        latin: "Paoy Svay"
      },
      id: "01040608"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "01040609"
    },
    {
      name: {
        km: "ព្រៃមាន់",
        latin: "Prey Moan"
      },
      id: "01040610"
    },
    {
      name: {
        km: "ស្ទឹងកំបុត",
        latin: "Stueng Kambot"
      },
      id: "01040611"
    },
    {
      name: {
        km: "បន្ទាត់បោះ",
        latin: "Bantoat Baoh"
      },
      id: "01040701"
    },
    {
      name: {
        km: "ទានកាំលិច",
        latin: "Tean Kam Lech"
      },
      id: "01040702"
    },
    {
      name: {
        km: "ទានកាំជើង",
        latin: "Tean Kam Cheung"
      },
      id: "01040703"
    },
    {
      name: {
        km: "ទានកាំត្បូង",
        latin: "Tean Kam Tboung"
      },
      id: "01040704"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "01040705"
    },
    {
      name: {
        km: "តាអ៊ុន",
        latin: "Ta Un"
      },
      id: "01040706"
    },
    {
      name: {
        km: "ស្មាច់",
        latin: "Smach"
      },
      id: "01040801"
    },
    {
      name: {
        km: "ប៉ោយចារ",
        latin: "Paoy Char"
      },
      id: "01040802"
    },
    {
      name: {
        km: "តាខែក",
        latin: "Ta Khaek"
      },
      id: "01040803"
    },
    {
      name: {
        km: "គោកកី",
        latin: "Kouk kei"
      },
      id: "01040804"
    },
    {
      name: {
        km: "ទន្លាប់",
        latin: "Tonloab"
      },
      id: "01040805"
    },
    {
      name: {
        km: "គោកទៀម",
        latin: "Kouk Tiem"
      },
      id: "01040806"
    },
    {
      name: {
        km: "ចារលើ",
        latin: "Char Leu"
      },
      id: "01040807"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "01040808"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "01040809"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "01040810"
    },
    {
      name: {
        km: "ទឹកជោរ",
        latin: "Tuek Chour"
      },
      id: "01040811"
    },
    {
      name: {
        km: "តាសៀវ",
        latin: "Ta Siev"
      },
      id: "01040812"
    },
    {
      name: {
        km: "កំពង់ថ្កូវ",
        latin: "Kampong Thkov"
      },
      id: "01040813"
    },
    {
      name: {
        km: "តាប៉ុន",
        latin: "Ta Pon"
      },
      id: "01040814"
    },
    {
      name: {
        km: "ស្នាយល្អ",
        latin: "Snay L'a"
      },
      id: "01040815"
    },
    {
      name: {
        km: "តាដែក",
        latin: "Ta Daek"
      },
      id: "01040816"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "01040817"
    },
    {
      name: {
        km: "អន្លង់វិល",
        latin: "Anlong Vil"
      },
      id: "01040818"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "01040901"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "01040902"
    },
    {
      name: {
        km: "គោកធំ",
        latin: "Kouk Thum"
      },
      id: "01040903"
    },
    {
      name: {
        km: "បុស្បូវ",
        latin: "Bos Sbov"
      },
      id: "01040904"
    },
    {
      name: {
        km: "សូត្រមន្ដ",
        latin: "Soutr Mont"
      },
      id: "01040905"
    },
    {
      name: {
        km: "ស្រះខ្ទំ",
        latin: "Srah Khtum"
      },
      id: "01040906"
    },
    {
      name: {
        km: "តាបៅ",
        latin: "Tabau"
      },
      id: "01040907"
    },
    {
      name: {
        km: "ក្បាលខ្ទីង",
        latin: "Kbal Khting"
      },
      id: "01040908"
    },
    {
      name: {
        km: "ស្មាច់",
        latin: "Smach"
      },
      id: "01040909"
    },
    {
      name: {
        km: "បឹងវែង",
        latin: "Boeng Veaeng"
      },
      id: "01040910"
    },
    {
      name: {
        km: "បន្ទាត់បោះ",
        latin: "Bantoat Baoh"
      },
      id: "01040911"
    },
    {
      name: {
        km: "ព្រីងកោង",
        latin: "Pring Kaong"
      },
      id: "01040912"
    },
    {
      name: {
        km: "ខ្ចាស់",
        latin: "Khchas"
      },
      id: "01040913"
    },
    {
      name: {
        km: "ខ្ញែរ",
        latin: "Khnhaer"
      },
      id: "01040914"
    },
    {
      name: {
        km: "ឃូស្វាយ",
        latin: "Khu Svay"
      },
      id: "01040915"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "01040916"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "01040917"
    },
    {
      name: {
        km: "បឹងសិលា",
        latin: "Boeng Seila"
      },
      id: "01050101"
    },
    {
      name: {
        km: "តាជ្រឹង",
        latin: "Ta Chrueng"
      },
      id: "01050102"
    },
    {
      name: {
        km: "តាផោក",
        latin: "Ta Phaok"
      },
      id: "01050103"
    },
    {
      name: {
        km: "ប៉ោយវត្ដ",
        latin: "Paoy Voat"
      },
      id: "01050104"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "01050105"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "01050106"
    },
    {
      name: {
        km: "យាងថ្មី",
        latin: "Yeang thmei"
      },
      id: "01050201"
    },
    {
      name: {
        km: "ម៉ក់ហឺន",
        latin: "Mak Heun"
      },
      id: "01050202"
    },
    {
      name: {
        km: "វាំងមួង",
        latin: "Veang Muong"
      },
      id: "01050203"
    },
    {
      name: {
        km: "កូបកើត",
        latin: "Koub Kaeut"
      },
      id: "01050204"
    },
    {
      name: {
        km: "ខៃដន",
        latin: "Khai Dan"
      },
      id: "01050205"
    },
    {
      name: {
        km: "ណាកាឆាយ",
        latin: "Naka Chhay"
      },
      id: "01050206"
    },
    {
      name: {
        km: "កូបលិច",
        latin: "Koub Lech"
      },
      id: "01050207"
    },
    {
      name: {
        km: "កូបជើង",
        latin: "Koub Cheung"
      },
      id: "01050208"
    },
    {
      name: {
        km: "អូរជ្រៅ",
        latin: "Ou Chrov"
      },
      id: "01050209"
    },
    {
      name: {
        km: "សូរិយាថ្មី",
        latin: "Souriya Thmei"
      },
      id: "01050210"
    },
    {
      name: {
        km: "កូនត្រី",
        latin: "Koun Trei"
      },
      id: "01050211"
    },
    {
      name: {
        km: "កូបតូច",
        latin: "Koub Touch"
      },
      id: "01050301"
    },
    {
      name: {
        km: "កោះចារ",
        latin: "Kaoh Char"
      },
      id: "01050302"
    },
    {
      name: {
        km: "គុត្ដសត",
        latin: "Kuttaksat"
      },
      id: "01050303"
    },
    {
      name: {
        km: "យាយអត",
        latin: "Yeay At"
      },
      id: "01050304"
    },
    {
      name: {
        km: "បន្លិច",
        latin: "Banlech"
      },
      id: "01050501"
    },
    {
      name: {
        km: "អ្នកតាឈរ",
        latin: "Neak Ta Chhor"
      },
      id: "01050502"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "01050503"
    },
    {
      name: {
        km: "កំពង់រាប",
        latin: "Kampong Reab"
      },
      id: "01050504"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "01050505"
    },
    {
      name: {
        km: "ធ្មេញត្រី",
        latin: "Thmenh Trei"
      },
      id: "01050506"
    },
    {
      name: {
        km: "បត់ត្រង់",
        latin: "Bat Trang"
      },
      id: "01050507"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "01050508"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "01050509"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "01050510"
    },
    {
      name: {
        km: "សូភីជើង",
        latin: "Souphi Cheung"
      },
      id: "01050601"
    },
    {
      name: {
        km: "សូភីកណ្ដាល",
        latin: "Souphi Kandal"
      },
      id: "01050602"
    },
    {
      name: {
        km: "សូភីត្បូង",
        latin: "Souphi Tboung"
      },
      id: "01050603"
    },
    {
      name: {
        km: "គោកចក",
        latin: "Kouk Chak"
      },
      id: "01050604"
    },
    {
      name: {
        km: "គោកព្រិច",
        latin: "Kouk Prich"
      },
      id: "01050605"
    },
    {
      name: {
        km: "សឹង្ហលិច",
        latin: "Soengh Lech"
      },
      id: "01050701"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "01050702"
    },
    {
      name: {
        km: "អន្លង់ស្វាយ",
        latin: "Anlong Svay"
      },
      id: "01050703"
    },
    {
      name: {
        km: "សឹង្ហត្បូង",
        latin: "Soengh Tboung"
      },
      id: "01050704"
    },
    {
      name: {
        km: "ផ្គាំ",
        latin: "Phkoam"
      },
      id: "01050705"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "01050706"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "01050707"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "01050708"
    },
    {
      name: {
        km: "រុន",
        latin: "Run"
      },
      id: "01050709"
    },
    {
      name: {
        km: "បន្ទាយថ្មី",
        latin: "Banteay Thmei"
      },
      id: "01050901"
    },
    {
      name: {
        km: "ជោគជ័យ",
        latin: "Chouk Chey"
      },
      id: "01050902"
    },
    {
      name: {
        km: "អូរបីជាន់",
        latin: "Ou Beichoan"
      },
      id: "01050903"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "01050904"
    },
    {
      name: {
        km: "ព្រៃចាន់",
        latin: "Prey Chan"
      },
      id: "01050905"
    },
    {
      name: {
        km: "ព្រាវចាស់",
        latin: "Preav Chas"
      },
      id: "01050906"
    },
    {
      name: {
        km: "សិលាខ្មែរ",
        latin: "Seila Khmer"
      },
      id: "01050907"
    },
    {
      name: {
        km: "ស្នួលទ្រេត",
        latin: "Snuol Tret"
      },
      id: "01050908"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "01050909"
    },
    {
      name: {
        km: "ទំនប់ដាច់",
        latin: "Tomnob Dach"
      },
      id: "01050910"
    },
    {
      name: {
        km: "យាងដង្គំ",
        latin: "Yeang Dangkum"
      },
      id: "01050911"
    },
    {
      name: {
        km: "កំពង់ស្វាយ",
        latin: "Kampong Svay"
      },
      id: "01060201"
    },
    {
      name: {
        km: "កងវ៉ា",
        latin: "Kangva"
      },
      id: "01060202"
    },
    {
      name: {
        km: "ភូមិ ២",
        latin: "Phum Pir"
      },
      id: "01060203"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "01060204"
    },
    {
      name: {
        km: "សូភី",
        latin: "Souphi"
      },
      id: "01060205"
    },
    {
      name: {
        km: "តារាងបាល់",
        latin: "Tarang Bal"
      },
      id: "01060206"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "01060207"
    },
    {
      name: {
        km: "ខ្លាកូនចាស់",
        latin: "Kla Koun Chas"
      },
      id: "01060208"
    },
    {
      name: {
        km: "ខ្លាកូនថ្មី",
        latin: "Kla Koun Thmey"
      },
      id: "01060209"
    },
    {
      name: {
        km: "កោះពងសត្វ",
        latin: "Kaoh Pong Satv"
      },
      id: "01060301"
    },
    {
      name: {
        km: "តាសុខ",
        latin: "Ta Sok"
      },
      id: "01060302"
    },
    {
      name: {
        km: "ព្រះអង្គ",
        latin: "Preah Angk"
      },
      id: "01060303"
    },
    {
      name: {
        km: "ស្នាយដង្គត់",
        latin: "Snay Dangkot"
      },
      id: "01060304"
    },
    {
      name: {
        km: "អង្គាបុស្ប",
        latin: "Angkear Bos"
      },
      id: "01060305"
    },
    {
      name: {
        km: "ម្កាក់",
        latin: "Mkak"
      },
      id: "01060401"
    },
    {
      name: {
        km: "ក្បាលស្ពាន",
        latin: "Kbal Spean"
      },
      id: "01060402"
    },
    {
      name: {
        km: "តាម៉ា",
        latin: "Ta Ma"
      },
      id: "01060403"
    },
    {
      name: {
        km: "គោកលៀប",
        latin: "Kouk Lieb"
      },
      id: "01060404"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "01060405"
    },
    {
      name: {
        km: "ដូនឡី",
        latin: "Doun Lei"
      },
      id: "01060406"
    },
    {
      name: {
        km: "បែកចាន",
        latin: "Baek Chan"
      },
      id: "01060407"
    },
    {
      name: {
        km: "សែសិន",
        latin: "Saesen"
      },
      id: "01060501"
    },
    {
      name: {
        km: "គោរដ្ឋាន",
        latin: "Kourothan"
      },
      id: "01060502"
    },
    {
      name: {
        km: "រោងម៉ាស៊ីន",
        latin: "Roung Masin"
      },
      id: "01060503"
    },
    {
      name: {
        km: "ព្រហូត",
        latin: "Prohut"
      },
      id: "01060504"
    },
    {
      name: {
        km: "អូរអំបិល",
        latin: "Ou Ambel"
      },
      id: "01060505"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "01060601"
    },
    {
      name: {
        km: "កំព្រីង",
        latin: "Kampring"
      },
      id: "01060602"
    },
    {
      name: {
        km: "ភ្នៀត",
        latin: "Phniet"
      },
      id: "01060603"
    },
    {
      name: {
        km: "អ្នកតា",
        latin: "Neak Ta"
      },
      id: "01060604"
    },
    {
      name: {
        km: "ភូមិថ្មី",
        latin: "Phum Thmei"
      },
      id: "01060605"
    },
    {
      name: {
        km: "បង្រុះ",
        latin: "Bangruh"
      },
      id: "01060606"
    },
    {
      name: {
        km: "សាលាក្រៅ",
        latin: "Sala Krau"
      },
      id: "01060607"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "01060701"
    },
    {
      name: {
        km: "ភូមិ ១",
        latin: "Phum Muoy"
      },
      id: "01060702"
    },
    {
      name: {
        km: "ភូមិ ៣",
        latin: "Phum Bei"
      },
      id: "01060703"
    },
    {
      name: {
        km: "ភូមិ ៤",
        latin: "Phum Buon"
      },
      id: "01060704"
    },
    {
      name: {
        km: "ព្រៃឫស្សី",
        latin: "Prey Ruessei"
      },
      id: "01060705"
    },
    {
      name: {
        km: "ព្រះពន្លា",
        latin: "Preah Ponlea"
      },
      id: "01060706"
    },
    {
      name: {
        km: "ក្បាលស្ពាន",
        latin: "Kbal Spean"
      },
      id: "01060707"
    },
    {
      name: {
        km: "គាប",
        latin: "Keab"
      },
      id: "01060801"
    },
    {
      name: {
        km: "ទំនប់ជ្រៃ",
        latin: "Tumnob Chrey"
      },
      id: "01060802"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "01060803"
    },
    {
      name: {
        km: "ភ្នំបាក់",
        latin: "Phnum Bak"
      },
      id: "01060805"
    },
    {
      name: {
        km: "ដីឡូតិ៍",
        latin: "Dei Lou"
      },
      id: "01060806"
    },
    {
      name: {
        km: "បាណយ",
        latin: "Banoy"
      },
      id: "01060807"
    },
    {
      name: {
        km: "គោកសំរោង",
        latin: "Kouk Samraong"
      },
      id: "01070101"
    },
    {
      name: {
        km: "ក្បាលទន្សោង",
        latin: "Kbal Tonsaong"
      },
      id: "01070102"
    },
    {
      name: {
        km: "បន្ទាយឆ្មារជើង",
        latin: "Banteay Chhmar Cheung"
      },
      id: "01070103"
    },
    {
      name: {
        km: "ក្បាលក្របី",
        latin: "Kbal Krabei"
      },
      id: "01070105"
    },
    {
      name: {
        km: "បន្ទាយឆ្មារត្បូង",
        latin: "Banteay Chhmar Tboung"
      },
      id: "01070106"
    },
    {
      name: {
        km: "បន្ទាយឆ្មារលិច",
        latin: "Banteay Chhmar Lech"
      },
      id: "01070107"
    },
    {
      name: {
        km: "ថ្មដែកកេះ",
        latin: "Thma Daekkeh"
      },
      id: "01070108"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "01070109"
    },
    {
      name: {
        km: "គោកសំរោងកើត",
        latin: "Kouk Samraong Kaeut"
      },
      id: "01070110"
    },
    {
      name: {
        km: "ស្រះជ្រៃ",
        latin: "Srah Chrey"
      },
      id: "01070111"
    },
    {
      name: {
        km: "ដងរ៉ែក",
        latin: "Dang Reaek"
      },
      id: "01070112"
    },
    {
      name: {
        km: "ប្រាសាទត្បែង",
        latin: "Prasat Tbaeng"
      },
      id: "01070113"
    },
    {
      name: {
        km: "ព្រៃសង្ហា",
        latin: "Prey Sangha"
      },
      id: "01070114"
    },
    {
      name: {
        km: "ត្រពាំងធ្លក",
        latin: "Trapeang Thlok"
      },
      id: "01070115"
    },
    {
      name: {
        km: "គោកព្រេច",
        latin: "Kouk Prech"
      },
      id: "01070201"
    },
    {
      name: {
        km: "ស្រែល្អ",
        latin: "Srae L'a"
      },
      id: "01070202"
    },
    {
      name: {
        km: "គោករមៀត",
        latin: "Kouk Romiet"
      },
      id: "01070203"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "01070204"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "01070205"
    },
    {
      name: {
        km: "តាឡី",
        latin: "Ta Lei"
      },
      id: "01070206"
    },
    {
      name: {
        km: "សេរីកា",
        latin: "Sereika"
      },
      id: "01070207"
    },
    {
      name: {
        km: "តាសុង",
        latin: "Ta Song"
      },
      id: "01070208"
    },
    {
      name: {
        km: "ត្រពាំងសំរោង",
        latin: "Trapeang Samraong"
      },
      id: "01070209"
    },
    {
      name: {
        km: "ថ្នល់បំបែក",
        latin: "Phlov Bambaek"
      },
      id: "01070210"
    },
    {
      name: {
        km: "ថ្មឆ័ត្រ",
        latin: "Thma Chhatr"
      },
      id: "01070211"
    },
    {
      name: {
        km: "វល្លិប្រេង",
        latin: "Voa Preng"
      },
      id: "01070212"
    },
    {
      name: {
        km: "ប្រាំមីនា",
        latin: "Pram Minea"
      },
      id: "01070213"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "01070214"
    },
    {
      name: {
        km: "បន្ទាយមានឬទ្ធិ",
        latin: "Banteay Mean Rit"
      },
      id: "01070215"
    },
    {
      name: {
        km: "ស្ពាន",
        latin: "Spean"
      },
      id: "01070217"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "01070218"
    },
    {
      name: {
        km: "ក្ដិបថ្ម",
        latin: "Kdeb Thma"
      },
      id: "01070219"
    },
    {
      name: {
        km: "បឹងតាស្រី",
        latin: "Boeng Ta Srei"
      },
      id: "01070220"
    },
    {
      name: {
        km: "បឹងសុក្រម",
        latin: "Boeng Sokram"
      },
      id: "01070221"
    },
    {
      name: {
        km: "តាម៉ាំង",
        latin: "Ta Mang"
      },
      id: "01070222"
    },
    {
      name: {
        km: "កាប់ចោរ",
        latin: "Kab Chaor"
      },
      id: "01070301"
    },
    {
      name: {
        km: "គោកស្វាយ",
        latin: "Kouk Svay"
      },
      id: "01070302"
    },
    {
      name: {
        km: "រលំជ្រៃ",
        latin: "Rolum Chrey"
      },
      id: "01070303"
    },
    {
      name: {
        km: "ថ្មីខាងលិច",
        latin: "Thmei Khang Lech"
      },
      id: "01070304"
    },
    {
      name: {
        km: "ថ្មីកណ្ដាល",
        latin: "Thmei Kandal"
      },
      id: "01070305"
    },
    {
      name: {
        km: "ថ្មីខាងត្បូង",
        latin: "Thmei Khang Tboung"
      },
      id: "01070306"
    },
    {
      name: {
        km: "ទទា",
        latin: "Totea"
      },
      id: "01070307"
    },
    {
      name: {
        km: "ថ្មពួក",
        latin: "Thma Puok"
      },
      id: "01070401"
    },
    {
      name: {
        km: "អ្នកតា",
        latin: "Neak Ta"
      },
      id: "01070402"
    },
    {
      name: {
        km: "វត្ដចាស់",
        latin: "Voat Chas"
      },
      id: "01070403"
    },
    {
      name: {
        km: "កសិណ",
        latin: "Kasen"
      },
      id: "01070404"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "01070405"
    },
    {
      name: {
        km: "ថ្នល់ដាច់",
        latin: "Thnal Dach"
      },
      id: "01070406"
    },
    {
      name: {
        km: "អន្លង់ត្រាច",
        latin: "Anlong Trach"
      },
      id: "01070407"
    },
    {
      name: {
        km: "ដឺ",
        latin: "Deu"
      },
      id: "01070501"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "01070502"
    },
    {
      name: {
        km: "ទ្រាស",
        latin: "Treas"
      },
      id: "01070503"
    },
    {
      name: {
        km: "គោកកឋិន",
        latin: "Kouk Kakthen"
      },
      id: "01070504"
    },
    {
      name: {
        km: "តាសៀវ",
        latin: "Ta Siev"
      },
      id: "01070505"
    },
    {
      name: {
        km: "ជន្លាសដៃ",
        latin: "Chonloas Dai"
      },
      id: "01070506"
    },
    {
      name: {
        km: "តាត្រៃ",
        latin: "Ta Trai"
      },
      id: "01070507"
    },
    {
      name: {
        km: "ព្រះឈរ",
        latin: "Preah Chhor"
      },
      id: "01070508"
    },
    {
      name: {
        km: "គោកខ្ពស់",
        latin: "Kouk Kpos"
      },
      id: "01070509"
    },
    {
      name: {
        km: "អណ្ដូងខ្លុង",
        latin: "Andoung Khlong"
      },
      id: "01070601"
    },
    {
      name: {
        km: "គំរូ",
        latin: "Kumru"
      },
      id: "01070602"
    },
    {
      name: {
        km: "តាយឹង",
        latin: "Ta Yueng"
      },
      id: "01070603"
    },
    {
      name: {
        km: "ឯកភាព",
        latin: "Aekakpheap"
      },
      id: "01070605"
    },
    {
      name: {
        km: "ផ្សារថ្មី",
        latin: "Phsar Thmei"
      },
      id: "01070606"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "01070607"
    },
    {
      name: {
        km: "ព្រៃវែង",
        latin: "Prey Veaeng"
      },
      id: "01070608"
    },
    {
      name: {
        km: "ផ្គាំ",
        latin: "Phkoam"
      },
      id: "01080101"
    },
    {
      name: {
        km: "យាងវៀន",
        latin: "Yeang Vien"
      },
      id: "01080102"
    },
    {
      name: {
        km: "យាង",
        latin: "Yeang"
      },
      id: "01080103"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "01080104"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "01080105"
    },
    {
      name: {
        km: "ប្រាសាទវៀន",
        latin: "Prasat Vien"
      },
      id: "01080106"
    },
    {
      name: {
        km: "តាដួល",
        latin: "Ta Duol"
      },
      id: "01080107"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "01080108"
    },
    {
      name: {
        km: "ម៉ៅ",
        latin: "Mau"
      },
      id: "01080109"
    },
    {
      name: {
        km: "ថ្មគោល",
        latin: "Thma Koul"
      },
      id: "01080110"
    },
    {
      name: {
        km: "តាគល់",
        latin: "Ta Koal"
      },
      id: "01080111"
    },
    {
      name: {
        km: "ភាសត្បូង",
        latin: "Pheas Tboung"
      },
      id: "01080201"
    },
    {
      name: {
        km: "ភាសជើង",
        latin: "Pheas Cheung"
      },
      id: "01080202"
    },
    {
      name: {
        km: "ជ្រុង",
        latin: "Chrung"
      },
      id: "01080203"
    },
    {
      name: {
        km: "ផ្លាស់កង់",
        latin: "Phlas Kang"
      },
      id: "01080204"
    },
    {
      name: {
        km: "គោកភ្លូ",
        latin: "Kouk Phlu"
      },
      id: "01080205"
    },
    {
      name: {
        km: "កន្ទ្រង់",
        latin: "Kantrong"
      },
      id: "01080206"
    },
    {
      name: {
        km: "ស្លក្រាម",
        latin: "Sla Kram"
      },
      id: "01080301"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "01080302"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "01080303"
    },
    {
      name: {
        km: "ទ័ពសៀម",
        latin: "Toap Siem"
      },
      id: "01080304"
    },
    {
      name: {
        km: "ខ្លែងពណ៌ជើង",
        latin: "Khlaeng Poar Cheung"
      },
      id: "01080305"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "01080306"
    },
    {
      name: {
        km: "គោកអំពិល",
        latin: "Kouk Ampil"
      },
      id: "01080307"
    },
    {
      name: {
        km: "ខ្លែងពណ៌ត្បូង",
        latin: "Khlaeng Poar Tboung"
      },
      id: "01080308"
    },
    {
      name: {
        km: "បឹងស្នោ",
        latin: "Boeng Snao"
      },
      id: "01080309"
    },
    {
      name: {
        km: "ចក់ ពួក",
        latin: "Chak Puork"
      },
      id: "01080310"
    },
    {
      name: {
        km: "គោកខ្វាវ",
        latin: "Kouk Khvav"
      },
      id: "01080401"
    },
    {
      name: {
        km: "ពន្សាយជើង",
        latin: "Ponsay Cheung"
      },
      id: "01080402"
    },
    {
      name: {
        km: "ក្លឹង",
        latin: "Kloeng"
      },
      id: "01080403"
    },
    {
      name: {
        km: "បែកចានចាស់",
        latin: "Baek Chan Chas"
      },
      id: "01080404"
    },
    {
      name: {
        km: "ពន្សាយត្បូង",
        latin: "Ponsay Tboung"
      },
      id: "01080405"
    },
    {
      name: {
        km: "រកាថ្មី",
        latin: "Roka Thmei"
      },
      id: "01080406"
    },
    {
      name: {
        km: "តាអុងលិច",
        latin: "Ta Ong Lech"
      },
      id: "01080407"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "01080408"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "01080409"
    },
    {
      name: {
        km: "ខ្វាវលិច",
        latin: "Khvav Lech"
      },
      id: "01080410"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "01080411"
    },
    {
      name: {
        km: "ចំការគ",
        latin: "Chamkar Ko"
      },
      id: "01080412"
    },
    {
      name: {
        km: "គោកតាឯក",
        latin: "Kouk Ta Aek"
      },
      id: "01080501"
    },
    {
      name: {
        km: "អូរវែងជើង",
        latin: "Ou Veaeng Cheung"
      },
      id: "01080502"
    },
    {
      name: {
        km: "តាបែន",
        latin: "Ta Baen"
      },
      id: "01080503"
    },
    {
      name: {
        km: "គោករកា",
        latin: "Kouk Roka"
      },
      id: "01080504"
    },
    {
      name: {
        km: "អូរវែងត្បូង",
        latin: "Ou Veaeng Tboung"
      },
      id: "01080505"
    },
    {
      name: {
        km: "តាផូ",
        latin: "Ta Phou"
      },
      id: "01080601"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "01080602"
    },
    {
      name: {
        km: "តាស្រី",
        latin: "Ta Srei"
      },
      id: "01080603"
    },
    {
      name: {
        km: "ព្រេចកី",
        latin: "Prech Kei"
      },
      id: "01080604"
    },
    {
      name: {
        km: "គោកកី",
        latin: "Kouk Kei"
      },
      id: "01080605"
    },
    {
      name: {
        km: "ឃ្មាស់",
        latin: "Khmoas"
      },
      id: "01080606"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "01080607"
    },
    {
      name: {
        km: "បារាយណ៍",
        latin: "Baray"
      },
      id: "01080608"
    },
    {
      name: {
        km: "ផ្ចឹក",
        latin: "Phchoek"
      },
      id: "01080609"
    },
    {
      name: {
        km: "ព្រេចត្បូង",
        latin: "Prech Tboung"
      },
      id: "01080610"
    },
    {
      name: {
        km: "បន្ទាត់បោះ",
        latin: "Bantoat Baoh"
      },
      id: "01080611"
    },
    {
      name: {
        km: "ពន្លៃថ្មី",
        latin: "Ponley Thmei"
      },
      id: "01080701"
    },
    {
      name: {
        km: "ចែង",
        latin: "Chaeng"
      },
      id: "01080702"
    },
    {
      name: {
        km: "ដូនណូយ",
        latin: "Doun Nouy"
      },
      id: "01080703"
    },
    {
      name: {
        km: "ប្រីយ៍",
        latin: "Prei"
      },
      id: "01080704"
    },
    {
      name: {
        km: "ពន្លៃចាស់",
        latin: "Ponley Chas"
      },
      id: "01080705"
    },
    {
      name: {
        km: "ទ្រាស",
        latin: "Treas"
      },
      id: "01080706"
    },
    {
      name: {
        km: "អំពិលព្រង់",
        latin: "Ampil Prong"
      },
      id: "01080707"
    },
    {
      name: {
        km: "អូរកកោះ",
        latin: "Ou Kakaoh"
      },
      id: "01080708"
    },
    {
      name: {
        km: "តាវឹក",
        latin: "Ta Voek"
      },
      id: "01080709"
    },
    {
      name: {
        km: "បែកចានថ្មី",
        latin: "Baek Chan Thmei"
      },
      id: "01080801"
    },
    {
      name: {
        km: "ខ្វាវកើត",
        latin: "Khvav Kaeut"
      },
      id: "01080802"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "01080803"
    },
    {
      name: {
        km: "តាអុងកើត",
        latin: "Ta Ong Kaeut"
      },
      id: "01080804"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "01080805"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "01080806"
    },
    {
      name: {
        km: "តាស្មន់",
        latin: "Ta Sman"
      },
      id: "01080807"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "01090101"
    },
    {
      name: {
        km: "ភ្នំរូង",
        latin: "Pnum Roung"
      },
      id: "01090102"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "01090103"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "01090104"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "01090105"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "01090201"
    },
    {
      name: {
        km: "វាលហាត់",
        latin: "Veal Hat"
      },
      id: "01090202"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "01090203"
    },
    {
      name: {
        km: "ក្បាលស្ពាន",
        latin: "Kbal Spean"
      },
      id: "01090204"
    },
    {
      name: {
        km: "ត្រសេកជ្រុំ",
        latin: "Trasek Chrum"
      },
      id: "01090205"
    },
    {
      name: {
        km: "ដំបូកវិល",
        latin: "Dambouk Vil"
      },
      id: "01090206"
    },
    {
      name: {
        km: "វត្ដចាស់",
        latin: "Voat Chas"
      },
      id: "01090207"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "01090208"
    },
    {
      name: {
        km: "អូរសំព័រ",
        latin: "Ou Sampoar"
      },
      id: "01090301"
    },
    {
      name: {
        km: "អូរស្អំ",
        latin: "Ou S'am"
      },
      id: "01090302"
    },
    {
      name: {
        km: "ក្បាលទំនប់",
        latin: "Kbal Tumnob"
      },
      id: "01090303"
    },
    {
      name: {
        km: "បន្ទាយទី២",
        latin: "Banteay Ti Pir"
      },
      id: "01090304"
    },
    {
      name: {
        km: "អូរស្រឡៅ",
        latin: "Ou Sralau"
      },
      id: "01090401"
    },
    {
      name: {
        km: "ភ្នំកៅបី",
        latin: "Phnum Kaubei"
      },
      id: "01090402"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "01090403"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "01090404"
    },
    {
      name: {
        km: "បឹងរាំង",
        latin: "Bueng Reang"
      },
      id: "01090405"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "01090406"
    },
    {
      name: {
        km: "ចាន់គីរី",
        latin: "Chan Kiri"
      },
      id: "01090407"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "01090408"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "01090501"
    },
    {
      name: {
        km: "កោះស្នួល",
        latin: "Kaoh Snuol"
      },
      id: "01090502"
    },
    {
      name: {
        km: "ខ្លាងាប់",
        latin: "Khla Ngoab"
      },
      id: "01090503"
    },
    {
      name: {
        km: "បន្ទាយទី១",
        latin: "Banteay Ti Muoy"
      },
      id: "01090504"
    },
    {
      name: {
        km: "សន្ដិភាព",
        latin: "Santepheap"
      },
      id: "01090505"
    },
    {
      name: {
        km: "អូរអំពិល",
        latin: "Ou Ampil"
      },
      id: "01090506"
    },
    {
      name: {
        km: "រស្មីមានជ័យ",
        latin: "Reaksmei Meanchey"
      },
      id: "01090507"
    },
    {
      name: {
        km: "អភិវឌ្ឍន៌",
        latin: "Akphivat"
      },
      id: "01090508"
    },
    {
      name: {
        km: "ទួលប្រាសាទ",
        latin: "Toul Prasat"
      },
      id: "01090509"
    },
    {
      name: {
        km: "ស្រឡៅជ្រុំ",
        latin: "Sralau Chrum"
      },
      id: "01090510"
    },
    {
      name: {
        km: "បឹងឈូក",
        latin: "Boeng Chhouk"
      },
      id: "01090511"
    },
    {
      name: {
        km: "អូរកេស",
        latin: "Ou Kes"
      },
      id: "01090512"
    },
    {
      name: {
        km: "ចែងម៉ែង",
        latin: "Chaeng Maeng"
      },
      id: "01090601"
    },
    {
      name: {
        km: "បល្ល័ង្គ",
        latin: "Ballangk"
      },
      id: "01090602"
    },
    {
      name: {
        km: "ប៉ោយអង្គរ",
        latin: "Paoy Angkor"
      },
      id: "01090603"
    },
    {
      name: {
        km: "ស្រះភ្លោះ",
        latin: "Srah Phluoh"
      },
      id: "01090604"
    },
    {
      name: {
        km: "ខ្ជាយ",
        latin: "Kcheay"
      },
      id: "01090605"
    },
    {
      name: {
        km: "តាគង់",
        latin: "Ta Kong"
      },
      id: "01090606"
    },
    {
      name: {
        km: "និមិត្ដ ១",
        latin: "Nimitt Muoy"
      },
      id: "01100101"
    },
    {
      name: {
        km: "និមិត្ដ ២",
        latin: "Nimitt Pir"
      },
      id: "01100102"
    },
    {
      name: {
        km: "និមិត្ដ ៣",
        latin: "Nimitt Bei"
      },
      id: "01100103"
    },
    {
      name: {
        km: "និមិត្ដ ៤",
        latin: "Nimitt Buon"
      },
      id: "01100104"
    },
    {
      name: {
        km: "អូរជ្រៅ",
        latin: "Ou Chrov"
      },
      id: "01100105"
    },
    {
      name: {
        km: "ដុងអារញ្ញ",
        latin: "Dong Aranh"
      },
      id: "01100106"
    },
    {
      name: {
        km: "សូរិយា",
        latin: "Souriya"
      },
      id: "01100107"
    },
    {
      name: {
        km: "និមិត្ដថ្មី",
        latin: "Nimitt Thmei"
      },
      id: "01100108"
    },
    {
      name: {
        km: "ថ្មសិន",
        latin: "Thma Sen"
      },
      id: "01100110"
    },
    {
      name: {
        km: "អន្លង់ស្វាយ",
        latin: "Anlong Svay"
      },
      id: "01100111"
    },
    {
      name: {
        km: "កូនដំរី",
        latin: "Koun Damrei"
      },
      id: "01100112"
    },
    {
      name: {
        km: "កូបធំ",
        latin: "Koub Thum"
      },
      id: "01100113"
    },
    {
      name: {
        km: "រស្មីសាមគ្គី",
        latin: "Reaksmei Sameakki"
      },
      id: "01100114"
    },
    {
      name: {
        km: "រស្មីសេរីភាព",
        latin: "Reaksmei Serei Pheap"
      },
      id: "01100115"
    },
    {
      name: {
        km: "សុខសាន្ដ្ដ",
        latin: "Sokhsan"
      },
      id: "01100116"
    },
    {
      name: {
        km: "ក្បាលស្ពាន១",
        latin: "Kbal Spean​ 1"
      },
      id: "01100201"
    },
    {
      name: {
        km: "បាលិលេយ្យ១",
        latin: "Baliley 1"
      },
      id: "01100202"
    },
    {
      name: {
        km: "ទួលប្រាសាទ",
        latin: "Tuol Prasat"
      },
      id: "01100209"
    },
    {
      name: {
        km: "ក្បាលស្ពាន២",
        latin: "Kbal Spean 2"
      },
      id: "01100212"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Koh"
      },
      id: "01100213"
    },
    {
      name: {
        km: "សាមគ្គីមានជ័យ",
        latin: "Samaki Mean Chey"
      },
      id: "01100214"
    },
    {
      name: {
        km: "ប៉ោយប៉ែត",
        latin: "Poay Pet"
      },
      id: "01100215"
    },
    {
      name: {
        km: "ព្រៃព្រិច",
        latin: "Prey Prech"
      },
      id: "01100216"
    },
    {
      name: {
        km: "បាលិលេយ្យ២",
        latin: "Baliley 2"
      },
      id: "01100218"
    },
    {
      name: {
        km: "មិត្តភាព",
        latin: "Mittapheap"
      },
      id: "01100219"
    },
    {
      name: {
        km: "ចាន់គីរី",
        latin: "Chan Kiri"
      },
      id: "01100220"
    },
    {
      name: {
        km: "អូរជ្រៅ",
        latin: "Ou Chrov"
      },
      id: "01100221"
    },
    {
      name: {
        km: "ទំនប់ក៥",
        latin: "Tumnob Kor 5"
      },
      id: "01100223"
    },
    {
      name: {
        km: "ផ្សារកណ្តាល",
        latin: "Phsar Kandal"
      },
      id: "01100301"
    },
    {
      name: {
        km: "គីឡូលេខបួន",
        latin: "Kilou Lekh Buon"
      },
      id: "01100302"
    },
    {
      name: {
        km: "ប្រជាធម្មលិច",
        latin: "Prachear Thoam Lech"
      },
      id: "01100303"
    },
    {
      name: {
        km: "ប្រជាធម្មកើត",
        latin: "Prachea Thorm Kaeut"
      },
      id: "01100304"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "01100305"
    },
    {
      name: {
        km: "អូរឬស្សី",
        latin: "Ou Ruessei"
      },
      id: "01100306"
    },
    {
      name: {
        km: "ព្រៃគុប",
        latin: "Prey Kub"
      },
      id: "01100307"
    },
    {
      name: {
        km: "អូរនាង",
        latin: "Ou Neang"
      },
      id: "01100308"
    },
    {
      name: {
        km: "អណ្ដូងថ្មមាស",
        latin: "Andoung Thma Meas"
      },
      id: "01100309"
    },
    {
      name: {
        km: "ស្ទឹងបត់",
        latin: "Stueng Bat"
      },
      id: "01100310"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02010101"
    },
    {
      name: {
        km: "ទួលធ្នង់",
        latin: "Tuol Thnong"
      },
      id: "02010102"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "02010103"
    },
    {
      name: {
        km: "ស្វាយបីដើម",
        latin: "Svay Bei Daeum"
      },
      id: "02010104"
    },
    {
      name: {
        km: "កំពង់អំពិល",
        latin: "Kampong Ampil"
      },
      id: "02010105"
    },
    {
      name: {
        km: "សសរពក",
        latin: "Sasar Pok"
      },
      id: "02010106"
    },
    {
      name: {
        km: "វត្ដកន្ទឺ",
        latin: "Voat Kantueu"
      },
      id: "02010107"
    },
    {
      name: {
        km: "ប៉ុស្ដិកន្ទឺ",
        latin: "Post Kantueu"
      },
      id: "02010201"
    },
    {
      name: {
        km: "ចំការអូរ",
        latin: "Chamkar Ou"
      },
      id: "02010202"
    },
    {
      name: {
        km: "បាណន់",
        latin: "Banan"
      },
      id: "02010203"
    },
    {
      name: {
        km: "កំប៉ង់លិច",
        latin: "Kampang Lech"
      },
      id: "02010204"
    },
    {
      name: {
        km: "កំប៉់ង់កើត",
        latin: "Kampang Kaeut"
      },
      id: "02010205"
    },
    {
      name: {
        km: "ឆាយរំពាត់",
        latin: "Chhay Rumpoat"
      },
      id: "02010206"
    },
    {
      name: {
        km: "ភ្នំគល់",
        latin: "Phnum Kol"
      },
      id: "02010207"
    },
    {
      name: {
        km: "ទួលច្រនៀង",
        latin: "Tuol Chranieng"
      },
      id: "02010301"
    },
    {
      name: {
        km: "កំពង់ចែង",
        latin: "Kampong Chaeng"
      },
      id: "02010302"
    },
    {
      name: {
        km: "កញ្ច្រោង",
        latin: "Kanhchroung"
      },
      id: "02010303"
    },
    {
      name: {
        km: "ក្រទ្បាពាស",
        latin: "Krala Peas"
      },
      id: "02010304"
    },
    {
      name: {
        km: "បាយដំរាំ",
        latin: "Bay Damram"
      },
      id: "02010305"
    },
    {
      name: {
        km: "តាស៊ង",
        latin: "Ta Song"
      },
      id: "02010306"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "02010307"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "02010308"
    },
    {
      name: {
        km: "កំពង់ឆ្លង",
        latin: "Kampong Chhlang"
      },
      id: "02010401"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "02010402"
    },
    {
      name: {
        km: "កំពង់ស្រមរ",
        latin: "Kampong Srama"
      },
      id: "02010403"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "02010404"
    },
    {
      name: {
        km: "ឥន្ទជិត",
        latin: "Enteak Chit"
      },
      id: "02010405"
    },
    {
      name: {
        km: "បត់សាលា",
        latin: "Bat Sala"
      },
      id: "02010406"
    },
    {
      name: {
        km: "បាយដំរាំ",
        latin: "Bay Damram"
      },
      id: "02010407"
    },
    {
      name: {
        km: "ស្វាយប្រគាប",
        latin: "Svay Prakeab"
      },
      id: "02010408"
    },
    {
      name: {
        km: "ឆកពោធិ៍៍",
        latin: "Chhak Pou"
      },
      id: "02010409"
    },
    {
      name: {
        km: "អន្លង់តាម៉ី",
        latin: "Anlong Ta Mei"
      },
      id: "02010410"
    },
    {
      name: {
        km: "ចំការស្វាយ",
        latin: "Chamkar Svay"
      },
      id: "02010411"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "02010412"
    },
    {
      name: {
        km: "បបុះ",
        latin: "Baboh"
      },
      id: "02010413"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "02010414"
    },
    {
      name: {
        km: "អន្លក់កោង",
        latin: "Anlok Kaong"
      },
      id: "02010415"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "02010501"
    },
    {
      name: {
        km: "ចែង",
        latin: "Chaeng"
      },
      id: "02010502"
    },
    {
      name: {
        km: "កំពង់គល់",
        latin: "Kampong Kol"
      },
      id: "02010503"
    },
    {
      name: {
        km: "ធ្ង័រ",
        latin: "Thngoar"
      },
      id: "02010504"
    },
    {
      name: {
        km: "បុះខ្នុរ",
        latin: "Boh Khnor"
      },
      id: "02010505"
    },
    {
      name: {
        km: "ចង្ហូរស្វាយ",
        latin: "Changhour Svay"
      },
      id: "02010506"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "02010507"
    },
    {
      name: {
        km: "ចែងក្ដារ",
        latin: "Chaeng Kdar"
      },
      id: "02010601"
    },
    {
      name: {
        km: "កំប៉ូវ",
        latin: "Kampouv"
      },
      id: "02010602"
    },
    {
      name: {
        km: "គោកអំពិល",
        latin: "Kouk Ampil"
      },
      id: "02010603"
    },
    {
      name: {
        km: "ភ្នំសំពៅលិច",
        latin: "Phnum Sampov Lech"
      },
      id: "02010604"
    },
    {
      name: {
        km: "ភ្នំសំពៅកើត",
        latin: "Phnum Sampov Kaeut"
      },
      id: "02010605"
    },
    {
      name: {
        km: "សំណាញ់",
        latin: "Samnanh"
      },
      id: "02010606"
    },
    {
      name: {
        km: "ក្ដោង",
        latin: "Kdaong"
      },
      id: "02010607"
    },
    {
      name: {
        km: "ក្រពើជើង",
        latin: "Krapeu Cheung"
      },
      id: "02010608"
    },
    {
      name: {
        km: "ក្រពើត្បូង",
        latin: "Krapeu Tboung"
      },
      id: "02010609"
    },
    {
      name: {
        km: "ក្រពើកើត",
        latin: "Krapeu Kaeut"
      },
      id: "02010610"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "02010701"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "02010702"
    },
    {
      name: {
        km: "ស្នឹងលិច",
        latin: "Snoeng Lech"
      },
      id: "02010703"
    },
    {
      name: {
        km: "ស្នឹងកើត",
        latin: "Snoeng Kaeut"
      },
      id: "02010704"
    },
    {
      name: {
        km: "បឹងចែង",
        latin: "Boeng Chaeng"
      },
      id: "02010705"
    },
    {
      name: {
        km: "បឹងប្រី",
        latin: "Boeng Prei"
      },
      id: "02010706"
    },
    {
      name: {
        km: "ពាក់ស្បែក",
        latin: "Peak Sbaek"
      },
      id: "02010707"
    },
    {
      name: {
        km: "ព្រះស្រែ",
        latin: "Preah Srae"
      },
      id: "02010708"
    },
    {
      name: {
        km: "រំជៃ",
        latin: "Rumchey"
      },
      id: "02010709"
    },
    {
      name: {
        km: "សំបួរមាស",
        latin: "Sambuor Meas"
      },
      id: "02010710"
    },
    {
      name: {
        km: "បឹងក្រសាល",
        latin: "Boeng Krasal"
      },
      id: "02010711"
    },
    {
      name: {
        km: "ប៉ោយស្វាយ",
        latin: "Paoy Svay"
      },
      id: "02010801"
    },
    {
      name: {
        km: "តាគ្រាម",
        latin: "Ta Kream"
      },
      id: "02010802"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02010803"
    },
    {
      name: {
        km: "អូរពងមាន់",
        latin: "Ou Pong Moan"
      },
      id: "02010804"
    },
    {
      name: {
        km: "តាង៉ែន",
        latin: "Ta Ngaen"
      },
      id: "02010805"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "02010806"
    },
    {
      name: {
        km: "អូរតាញា",
        latin: "Ou Ta Nhea"
      },
      id: "02010807"
    },
    {
      name: {
        km: "ដង្គត់ធ្នង់",
        latin: "Dangkot Thnong"
      },
      id: "02010808"
    },
    {
      name: {
        km: "អណ្ដូងនាង",
        latin: "Andoung Neang"
      },
      id: "02010809"
    },
    {
      name: {
        km: "អន្លង់ស្វាយ",
        latin: "Anlong Svay"
      },
      id: "02010810"
    },
    {
      name: {
        km: "ស្លាបប៉ាង",
        latin: "Slab Pang"
      },
      id: "02010811"
    },
    {
      name: {
        km: "ថ្មគោលត្បូង",
        latin: "Thma Koul Tboung"
      },
      id: "02020101"
    },
    {
      name: {
        km: "ប៉ោយយង់",
        latin: "Paoy Yong"
      },
      id: "02020102"
    },
    {
      name: {
        km: "កសិកម្ម",
        latin: "Kaksekam"
      },
      id: "02020103"
    },
    {
      name: {
        km: "ប៉ោយសំរោង",
        latin: "Paoy Samraong"
      },
      id: "02020104"
    },
    {
      name: {
        km: "គោកក្ដួច",
        latin: "Kouk Kduoch"
      },
      id: "02020105"
    },
    {
      name: {
        km: "អង់ត្បូង",
        latin: "Ang Tboung"
      },
      id: "02020106"
    },
    {
      name: {
        km: "ទំពូងត្បូង",
        latin: "Tumpung Tboung"
      },
      id: "02020107"
    },
    {
      name: {
        km: "ថ្មគោលជើង",
        latin: "Thma Koul Cheung"
      },
      id: "02020201"
    },
    {
      name: {
        km: "គោកត្រប់",
        latin: "Kouk Trab"
      },
      id: "02020202"
    },
    {
      name: {
        km: "ទំនាប",
        latin: "Tumneab"
      },
      id: "02020203"
    },
    {
      name: {
        km: "តាសី",
        latin: "Ta Sei"
      },
      id: "02020204"
    },
    {
      name: {
        km: "ជ្រោយម្ទេស",
        latin: "Chrouy Mtes"
      },
      id: "02020205"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "02020206"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "02020207"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02020208"
    },
    {
      name: {
        km: "អង់ជើង",
        latin: "Ang Cheung"
      },
      id: "02020209"
    },
    {
      name: {
        km: "ទំពូងជើង",
        latin: "Tumpung Cheung"
      },
      id: "02020210"
    },
    {
      name: {
        km: "អូរតាគី",
        latin: "Ou Ta Ki"
      },
      id: "02020301"
    },
    {
      name: {
        km: "ពពាលខែ",
        latin: "Popeal Khae"
      },
      id: "02020302"
    },
    {
      name: {
        km: "វាលទ្រា",
        latin: "Veal Trea"
      },
      id: "02020303"
    },
    {
      name: {
        km: "ត្រស់",
        latin: "Tras"
      },
      id: "02020304"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "02020305"
    },
    {
      name: {
        km: "ព្រៃដាច់",
        latin: "Prey Dach"
      },
      id: "02020306"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Trang"
      },
      id: "02020307"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "02020308"
    },
    {
      name: {
        km: "ជ្រៃថ្មី",
        latin: "Chrey Thmei"
      },
      id: "02020401"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "02020402"
    },
    {
      name: {
        km: "កគោ",
        latin: "Ka Kou"
      },
      id: "02020403"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "02020404"
    },
    {
      name: {
        km: "ក្បាលខ្មោច",
        latin: "Kbal Khmaoch"
      },
      id: "02020405"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "02020406"
    },
    {
      name: {
        km: "ហៃសាន",
        latin: "Hai San"
      },
      id: "02020407"
    },
    {
      name: {
        km: "ពពាលខែ",
        latin: "Popeal Khae"
      },
      id: "02020408"
    },
    {
      name: {
        km: "អន្លង់រុន",
        latin: "Anlong Run"
      },
      id: "02020409"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "02020410"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "02020501"
    },
    {
      name: {
        km: "ស្លាស្លាក់",
        latin: "Sla Slak"
      },
      id: "02020502"
    },
    {
      name: {
        km: "ចបកាប់",
        latin: "Chab Kab"
      },
      id: "02020503"
    },
    {
      name: {
        km: "សូភី",
        latin: "Souphi"
      },
      id: "02020504"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "02020505"
    },
    {
      name: {
        km: "ជ្រោយស្ដៅ",
        latin: "Chrouy Sdau"
      },
      id: "02020601"
    },
    {
      name: {
        km: "និគមក្រៅ",
        latin: "Nikom Krau"
      },
      id: "02020602"
    },
    {
      name: {
        km: "និគមក្នុង",
        latin: "Nikom Knong"
      },
      id: "02020603"
    },
    {
      name: {
        km: "និគមកណ្តាល",
        latin: "Nikom Kandal"
      },
      id: "02020604"
    },
    {
      name: {
        km: "បឹងព្រីង",
        latin: "Boeng Pring"
      },
      id: "02020701"
    },
    {
      name: {
        km: "អូរញរ",
        latin: "Ou Nhor"
      },
      id: "02020702"
    },
    {
      name: {
        km: "ស្នួលកោង",
        latin: "Snuol Kaong"
      },
      id: "02020703"
    },
    {
      name: {
        km: "ប៉ោយតាសេក",
        latin: "Paoy Ta Sek"
      },
      id: "02020704"
    },
    {
      name: {
        km: "កៀនកែស ១",
        latin: "Kien Kaes Muoy"
      },
      id: "02020801"
    },
    {
      name: {
        km: "កៀនកែស ២",
        latin: "Kien Kaes Pir"
      },
      id: "02020802"
    },
    {
      name: {
        km: "តាមាឃ",
        latin: "Ta Meakh"
      },
      id: "02020803"
    },
    {
      name: {
        km: "ច្រនៀង",
        latin: "Chranieng"
      },
      id: "02020804"
    },
    {
      name: {
        km: "គោកឃ្មុំ",
        latin: "Kouk Khmum"
      },
      id: "02020805"
    },
    {
      name: {
        km: "កណ្ដាលត្បូង",
        latin: "Kandal Tboung"
      },
      id: "02020806"
    },
    {
      name: {
        km: "កណ្ដាលជើង",
        latin: "Kandal Cheung"
      },
      id: "02020807"
    },
    {
      name: {
        km: "ឆ្កែកូន",
        latin: "Chhkae Koun"
      },
      id: "02020808"
    },
    {
      name: {
        km: "បន្សាយត្រែង",
        latin: "Bansay Traeng"
      },
      id: "02020901"
    },
    {
      name: {
        km: "តាកយ",
        latin: "Ta Kay"
      },
      id: "02020902"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02020903"
    },
    {
      name: {
        km: "ព្រៃលាវ",
        latin: "Prey Leav"
      },
      id: "02020904"
    },
    {
      name: {
        km: "កោងកាង",
        latin: "Kaong Kang"
      },
      id: "02020905"
    },
    {
      name: {
        km: "ធ្មា",
        latin: "Thmea"
      },
      id: "02020906"
    },
    {
      name: {
        km: "ស្ពាន",
        latin: "Spean"
      },
      id: "02020907"
    },
    {
      name: {
        km: "ទួលតាសុខ",
        latin: "Tuol Ta Sokh"
      },
      id: "02020908"
    },
    {
      name: {
        km: "បល្ល័ង្កក្រោម",
        latin: "Ballangk Kraom"
      },
      id: "02021001"
    },
    {
      name: {
        km: "ប្រគាប",
        latin: "Prakeab"
      },
      id: "02021002"
    },
    {
      name: {
        km: "គោកខ្ពស់",
        latin: "Kouk Khpos"
      },
      id: "02021003"
    },
    {
      name: {
        km: "ប៉ោយរំជៃ",
        latin: "Paoy Rumchey"
      },
      id: "02021004"
    },
    {
      name: {
        km: "ព្រះពន្លា",
        latin: "Preah Ponlea"
      },
      id: "02021005"
    },
    {
      name: {
        km: "រូងជ្រៃ",
        latin: "Rung Chrey"
      },
      id: "02021006"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "02021007"
    },
    {
      name: {
        km: "អូរតាគាំ ១",
        latin: "O Ta Kam I"
      },
      id: "02030101"
    },
    {
      name: {
        km: "អូរតាគាំ ២",
        latin: "O Ta Kam II"
      },
      id: "02030102"
    },
    {
      name: {
        km: "អូរតាគាំ ៣",
        latin: "O Ta Kam III"
      },
      id: "02030103"
    },
    {
      name: {
        km: "ទួលតាឯក",
        latin: "Tuol Ta Ek"
      },
      id: "02030104"
    },
    {
      name: {
        km: "ដង្កោទាប",
        latin: "Dangkor Teab"
      },
      id: "02030105"
    },
    {
      name: {
        km: "ព្រែកព្រះស្ដេច",
        latin: "Prek Preah Sdach"
      },
      id: "02030201"
    },
    {
      name: {
        km: "ព្រែកតាតន់",
        latin: "Prek Ta Tan"
      },
      id: "02030202"
    },
    {
      name: {
        km: "១៣ មករា",
        latin: "13 Meakkakra"
      },
      id: "02030203"
    },
    {
      name: {
        km: "អូរខ្ជាយ",
        latin: "O Khcheay"
      },
      id: "02030204"
    },
    {
      name: {
        km: "ទ្បឥដ្ឋ",
        latin: "Lor Ith"
      },
      id: "02030205"
    },
    {
      name: {
        km: "នំក្រៀប",
        latin: "Nom Kriab"
      },
      id: "02030206"
    },
    {
      name: {
        km: "បែកចានថ្មី",
        latin: "Bek Chan Thmey"
      },
      id: "02030207"
    },
    {
      name: {
        km: "ចំការឫស្សី",
        latin: "Cham kaRuessey"
      },
      id: "02030208"
    },
    {
      name: {
        km: "រំចេក ១",
        latin: "Rumchek 1"
      },
      id: "02030301"
    },
    {
      name: {
        km: "រំចេក ២",
        latin: "Rumchek 2"
      },
      id: "02030302"
    },
    {
      name: {
        km: "រំចេក ៣",
        latin: "Rumchek 3"
      },
      id: "02030303"
    },
    {
      name: {
        km: "រំចេក ៤",
        latin: "Rumchek 4"
      },
      id: "02030304"
    },
    {
      name: {
        km: "រំចេក ៥",
        latin: "Rumchek 5"
      },
      id: "02030305"
    },
    {
      name: {
        km: "សូភី ១",
        latin: "Souphy 1"
      },
      id: "02030306"
    },
    {
      name: {
        km: "សូភី ២",
        latin: "Souphy 2"
      },
      id: "02030307"
    },
    {
      name: {
        km: "រតនៈ",
        latin: "Rottanak"
      },
      id: "02030308"
    },
    {
      name: {
        km: "ចំការសំរោង១",
        latin: "Chomkar Somraong 1"
      },
      id: "02030401"
    },
    {
      name: {
        km: "ចំការសំរោង២",
        latin: "Chomkar Somraong 2"
      },
      id: "02030402"
    },
    {
      name: {
        km: "វត្ដលៀប",
        latin: "Vat Leeb"
      },
      id: "02030403"
    },
    {
      name: {
        km: "វត្ដរំដួល",
        latin: "Vat Romdol"
      },
      id: "02030404"
    },
    {
      name: {
        km: "ផ្កាស្លា",
        latin: "Phkar Sla"
      },
      id: "02030405"
    },
    {
      name: {
        km: "ស្លាកែត",
        latin: "Sla Ket"
      },
      id: "02030501"
    },
    {
      name: {
        km: "ដាំស្ពៃ",
        latin: "Dam Spey"
      },
      id: "02030502"
    },
    {
      name: {
        km: "ជ្រៃកោង",
        latin: "Chrey Kaong"
      },
      id: "02030503"
    },
    {
      name: {
        km: "ចុងព្រែក",
        latin: "Chong Preak"
      },
      id: "02030601"
    },
    {
      name: {
        km: "ក្ដុល",
        latin: "Kdol"
      },
      id: "02030602"
    },
    {
      name: {
        km: "អូរតានប់",
        latin: "Ou Ta Nob"
      },
      id: "02030603"
    },
    {
      name: {
        km: "តាព្រួច",
        latin: "Ta Pruoch"
      },
      id: "02030604"
    },
    {
      name: {
        km: "តាកុយ",
        latin: "Ta Koy"
      },
      id: "02030605"
    },
    {
      name: {
        km: "កន្ទ្ទួត",
        latin: "Kantuot"
      },
      id: "02030606"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "02030607"
    },
    {
      name: {
        km: "អូរម៉ាល់",
        latin: "O Mal"
      },
      id: "02030701"
    },
    {
      name: {
        km: "ដាក់សសរ",
        latin: "Dak Sasar"
      },
      id: "02030702"
    },
    {
      name: {
        km: "សាលាបាទ្បាត់",
        latin: "Sala Balat"
      },
      id: "02030703"
    },
    {
      name: {
        km: "ព្រៃដាច់",
        latin: "Prey Dach"
      },
      id: "02030704"
    },
    {
      name: {
        km: "គោកពន្លៃ",
        latin: "Kouk Ponley"
      },
      id: "02030705"
    },
    {
      name: {
        km: "វត្ដរកា",
        latin: "Wath Roka"
      },
      id: "02030706"
    },
    {
      name: {
        km: "កូនសេក",
        latin: "Koun Sek"
      },
      id: "02030707"
    },
    {
      name: {
        km: "អណ្ដូងព្រីង",
        latin: "Andoung Pring"
      },
      id: "02030708"
    },
    {
      name: {
        km: "បឹងរាំង",
        latin: "Boeng Reing"
      },
      id: "02030709"
    },
    {
      name: {
        km: "ព្រៃរកា",
        latin: "Prey Roka"
      },
      id: "02030710"
    },
    {
      name: {
        km: "វត្ដគរ",
        latin: "Wat Kor"
      },
      id: "02030801"
    },
    {
      name: {
        km: "ច្រាបក្រសាំង",
        latin: "Chrap Krasaing"
      },
      id: "02030802"
    },
    {
      name: {
        km: "បល្ល័ង្គ",
        latin: "Balaingk"
      },
      id: "02030803"
    },
    {
      name: {
        km: "ខ្សាច់ពោយ",
        latin: "Khsach Poy"
      },
      id: "02030804"
    },
    {
      name: {
        km: "ដំណាក់ហ្លួង",
        latin: "Damnak Luong"
      },
      id: "02030805"
    },
    {
      name: {
        km: "កំពង់សីមា",
        latin: "Kampong Seima"
      },
      id: "02030806"
    },
    {
      name: {
        km: "អូរចារ",
        latin: "Ou Char"
      },
      id: "02030901"
    },
    {
      name: {
        km: "ព្រៃកូនសេក",
        latin: "Prey Koun Sek"
      },
      id: "02030902"
    },
    {
      name: {
        km: "កាប់គោថ្មី",
        latin: "Kab Kou Thmei"
      },
      id: "02030903"
    },
    {
      name: {
        km: "អណ្ដូងចេញ",
        latin: "Andoung Chenh"
      },
      id: "02030904"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "02030905"
    },
    {
      name: {
        km: "អង់",
        latin: "Ang"
      },
      id: "02030906"
    },
    {
      name: {
        km: "ព្រែកមហាទេព",
        latin: "Prek MohaTep"
      },
      id: "02031001"
    },
    {
      name: {
        km: "កំពង់ក្របី",
        latin: "Kampong Krabei"
      },
      id: "02031002"
    },
    {
      name: {
        km: "២០ ឧសភា",
        latin: "Maphey  Osakphea"
      },
      id: "02031003"
    },
    {
      name: {
        km: "កម្មករ",
        latin: "Kammeakor"
      },
      id: "02031004"
    },
    {
      name: {
        km: "បវេល ១",
        latin: "Bavel Muoy"
      },
      id: "02040101"
    },
    {
      name: {
        km: "បវេល ២",
        latin: "Bavel Pir"
      },
      id: "02040102"
    },
    {
      name: {
        km: "ទំនប់ទឹក",
        latin: "Tumnob Tuek"
      },
      id: "02040103"
    },
    {
      name: {
        km: "ដាច់ព្រ័ត្រ",
        latin: "Dach Proat"
      },
      id: "02040104"
    },
    {
      name: {
        km: "សង្កែវារ",
        latin: "Sangkae Vear"
      },
      id: "02040105"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "02040106"
    },
    {
      name: {
        km: "កំពង់ព្នៅ",
        latin: "Kampong Phnov"
      },
      id: "02040107"
    },
    {
      name: {
        km: "ស្ទឹងដាច់",
        latin: "Stueng Dach"
      },
      id: "02040108"
    },
    {
      name: {
        km: "ស្ពានកណ្ដោល",
        latin: "Spean Kandaol"
      },
      id: "02040109"
    },
    {
      name: {
        km: "សាំងរាំង",
        latin: "Sang Reang"
      },
      id: "02040110"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "02040111"
    },
    {
      name: {
        km: "ដូនអាវ",
        latin: "Doun av"
      },
      id: "02040112"
    },
    {
      name: {
        km: "ព្រៃទទឹង ១",
        latin: "Prey Totueng Muoy"
      },
      id: "02040113"
    },
    {
      name: {
        km: "ព្រៃទទឺង ២",
        latin: "Prey Totueng Pir"
      },
      id: "02040114"
    },
    {
      name: {
        km: "គោក",
        latin: "Kouk"
      },
      id: "02040115"
    },
    {
      name: {
        km: "ស្លខ្លាញ់",
        latin: "Sla Khlanh"
      },
      id: "02040116"
    },
    {
      name: {
        km: "កំពង់ឆ្នាំង ១",
        latin: "Kampong Chhnang Muoy"
      },
      id: "02040117"
    },
    {
      name: {
        km: "កំពង់ឆ្នាំង ២",
        latin: "Kampong Chhnang Pir"
      },
      id: "02040118"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "02040119"
    },
    {
      name: {
        km: "សំរោងជ័យ",
        latin: "Samraong Chey"
      },
      id: "02040120"
    },
    {
      name: {
        km: "ព្រៃសង្ហា",
        latin: "Prey Sangha"
      },
      id: "02040201"
    },
    {
      name: {
        km: "កោះរាម",
        latin: "Kaoh Ream"
      },
      id: "02040202"
    },
    {
      name: {
        km: "រូងអំពិល",
        latin: "Rung Ampil"
      },
      id: "02040203"
    },
    {
      name: {
        km: "បល្ល័ង្កលើ",
        latin: "Ballangk Leu"
      },
      id: "02040204"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "02040205"
    },
    {
      name: {
        km: "ខ្នាចរមាស",
        latin: "Khnach Romeas"
      },
      id: "02040206"
    },
    {
      name: {
        km: "បល្ល័ង្កមានជ័យ",
        latin: "Ballangk Mean Chey"
      },
      id: "02040207"
    },
    {
      name: {
        km: "ជ្រោយស្នា",
        latin: "Chrouy Sna"
      },
      id: "02040208"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "02040301"
    },
    {
      name: {
        km: "ដូនញ៉ែម",
        latin: "Doun Nhaem"
      },
      id: "02040302"
    },
    {
      name: {
        km: "ចំការ",
        latin: "Chamkar"
      },
      id: "02040303"
    },
    {
      name: {
        km: "ដង្កោ",
        latin: "Dangkao"
      },
      id: "02040304"
    },
    {
      name: {
        km: "រាមសេនា",
        latin: "Ream Sena"
      },
      id: "02040305"
    },
    {
      name: {
        km: "ដូនអោក",
        latin: "Doun Aok"
      },
      id: "02040306"
    },
    {
      name: {
        km: "ពីងពង់",
        latin: "Ping Pong"
      },
      id: "02040307"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "02040308"
    },
    {
      name: {
        km: "បឹងសំរោង",
        latin: "Boeng Samraong"
      },
      id: "02040309"
    },
    {
      name: {
        km: "ក្បាលស្ពាន",
        latin: "Kbal Spean"
      },
      id: "02040310"
    },
    {
      name: {
        km: "ល្វាចាស់",
        latin: "Lvea Chas"
      },
      id: "02040311"
    },
    {
      name: {
        km: "តានី",
        latin: "Ta Ni"
      },
      id: "02040312"
    },
    {
      name: {
        km: "តាហ៊ី",
        latin: "Ta Hi"
      },
      id: "02040401"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "02040402"
    },
    {
      name: {
        km: "តាម៉ាត",
        latin: "Ta Mat"
      },
      id: "02040403"
    },
    {
      name: {
        km: "មក្លឿ",
        latin: "Makkloea"
      },
      id: "02040404"
    },
    {
      name: {
        km: "ព្រៃខ្ពស់",
        latin: "Prey Khpos"
      },
      id: "02040405"
    },
    {
      name: {
        km: "ស្រណាល",
        latin: "Sranal"
      },
      id: "02040406"
    },
    {
      name: {
        km: "ដង្កោពេន",
        latin: "Dangkao Pen"
      },
      id: "02040407"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "02040408"
    },
    {
      name: {
        km: "បឹងជំនាង",
        latin: "Boeng Chumnieng"
      },
      id: "02040409"
    },
    {
      name: {
        km: "ក្បាលអាង",
        latin: "Kbal Ang"
      },
      id: "02040410"
    },
    {
      name: {
        km: "ដង្កោក្រម៉ាង",
        latin: "Dangkao Kramang"
      },
      id: "02040501"
    },
    {
      name: {
        km: "សៀម",
        latin: "Siem"
      },
      id: "02040502"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "02040503"
    },
    {
      name: {
        km: "ស្ថាពរ1",
        latin: "Sthapor Muoy"
      },
      id: "02040504"
    },
    {
      name: {
        km: "តាខៀវ",
        latin: "Ta Khiev"
      },
      id: "02040505"
    },
    {
      name: {
        km: "បួរុន",
        latin: "Buo Run"
      },
      id: "02040506"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "02040507"
    },
    {
      name: {
        km: "ស្ថាពរ២",
        latin: "Sthapor Pir"
      },
      id: "02040508"
    },
    {
      name: {
        km: "បឹងសង្កែ",
        latin: "Boeng Sangkae"
      },
      id: "02040509"
    },
    {
      name: {
        km: "បួជុំ",
        latin: "Bu Chum"
      },
      id: "02040510"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmey"
      },
      id: "02040511"
    },
    {
      name: {
        km: "បឹងអារ័ក្ស",
        latin: "Boeung Ahrak"
      },
      id: "02040512"
    },
    {
      name: {
        km: "កូប",
        latin: "Koub"
      },
      id: "02040513"
    },
    {
      name: {
        km: "បឹងស្នួល",
        latin: "Boeung Snul"
      },
      id: "02040514"
    },
    {
      name: {
        km: "បឹងពពូល",
        latin: "Boeung Popul"
      },
      id: "02040515"
    },
    {
      name: {
        km: "សួនស្លា",
        latin: "Suon Sla"
      },
      id: "02040601"
    },
    {
      name: {
        km: "ក្ដុលក្រោម",
        latin: "Kdol Kraom"
      },
      id: "02040602"
    },
    {
      name: {
        km: "សាន",
        latin: "San"
      },
      id: "02040603"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "02040604"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "02040605"
    },
    {
      name: {
        km: "បួ",
        latin: "Buo"
      },
      id: "02040606"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02040607"
    },
    {
      name: {
        km: "ទួលក្រសាំង",
        latin: "Tuol Krasang"
      },
      id: "02040608"
    },
    {
      name: {
        km: "ក្ដុលលើ",
        latin: "Kdol Leu"
      },
      id: "02040609"
    },
    {
      name: {
        km: "តាហែន",
        latin: "Ta Haen"
      },
      id: "02040610"
    },
    {
      name: {
        km: "អន្លង់រៃ",
        latin: "Anlong Rey"
      },
      id: "02040612"
    },
    {
      name: {
        km: "បឹងអន្លក់",
        latin: "Boeng Anlok"
      },
      id: "02040614"
    },
    {
      name: {
        km: "ដំណាក់់ដង្កោ",
        latin: "Damnak Dangkao"
      },
      id: "02040618"
    },
    {
      name: {
        km: "ស្រះទឹក",
        latin: "Srah Tuek"
      },
      id: "02040623"
    },
    {
      name: {
        km: "តាគត់",
        latin: "Ta Kot"
      },
      id: "02040626"
    },
    {
      name: {
        km: "ត្រពាំងក្បាលស្វា",
        latin: "Trapeang Kbal Sva"
      },
      id: "02040701"
    },
    {
      name: {
        km: "ប្របហឹប",
        latin: "Prab Hoeb"
      },
      id: "02040702"
    },
    {
      name: {
        km: "អូរដូនពៅ",
        latin: "Ou Doun Pov"
      },
      id: "02040703"
    },
    {
      name: {
        km: "ឃ្លាំង",
        latin: "Khleang"
      },
      id: "02040704"
    },
    {
      name: {
        km: "ច្រាំងបាក់",
        latin: "Chrang Bak"
      },
      id: "02040705"
    },
    {
      name: {
        km: "អន្លង់រាំង",
        latin: "Anlong Reang"
      },
      id: "02040706"
    },
    {
      name: {
        km: "បឹងសង្កែ",
        latin: "Boeng Sangkae"
      },
      id: "02040707"
    },
    {
      name: {
        km: "ទំនប់តាកួន",
        latin: "Tumnob Ta Kuon"
      },
      id: "02040708"
    },
    {
      name: {
        km: "ទួលស្នួល",
        latin: "Tuol Snuol"
      },
      id: "02040709"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "02040710"
    },
    {
      name: {
        km: "កំពង់ម្កាក់",
        latin: "Kampong Mkak"
      },
      id: "02040711"
    },
    {
      name: {
        km: "បួសង្គ្រាជ",
        latin: "Buo Sangkreach"
      },
      id: "02040712"
    },
    {
      name: {
        km: "ព្រៃព្រាល",
        latin: "Prey Preal"
      },
      id: "02040713"
    },
    {
      name: {
        km: "ក្រពើសែសិប",
        latin: "Krapeu Saeseb"
      },
      id: "02040801"
    },
    {
      name: {
        km: "បឹងធុង",
        latin: "Boeung Thung"
      },
      id: "02040802"
    },
    {
      name: {
        km: "សំណាងព្រះស្រី",
        latin: "Samnang Pras Srey"
      },
      id: "02040803"
    },
    {
      name: {
        km: "យុត្តិធម៌",
        latin: "Yut Te Thhor"
      },
      id: "02040804"
    },
    {
      name: {
        km: "បឹងក្រឡ",
        latin: "Boeung Krola"
      },
      id: "02040805"
    },
    {
      name: {
        km: "អូរឫស្សី",
        latin: "Oureusey"
      },
      id: "02040806"
    },
    {
      name: {
        km: "បឹងប្រាំ",
        latin: "Boeng Pram"
      },
      id: "02040807"
    },
    {
      name: {
        km: "ជ័យជំនះ",
        latin: "Chey Chumnas"
      },
      id: "02040808"
    },
    {
      name: {
        km: "ព្រែកតាច្រែង",
        latin: "Preaek Ta Chraeng"
      },
      id: "02050101"
    },
    {
      name: {
        km: "ព្រែកក្រូច",
        latin: "Preaek Krouch"
      },
      id: "02050102"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "02050103"
    },
    {
      name: {
        km: "ព្រែកនរិន្ទ",
        latin: "Preaek Norint"
      },
      id: "02050104"
    },
    {
      name: {
        km: "ស្ដី",
        latin: "Sdei"
      },
      id: "02050105"
    },
    {
      name: {
        km: "រហាលសួង",
        latin: "Rohal Suong"
      },
      id: "02050106"
    },
    {
      name: {
        km: "ដួងមា",
        latin: "Duong Mea"
      },
      id: "02050107"
    },
    {
      name: {
        km: "រាជដូនកែវ",
        latin: "Reach Doun Kaev"
      },
      id: "02050108"
    },
    {
      name: {
        km: "អន្សងសក",
        latin: "Ansang Sak"
      },
      id: "02050109"
    },
    {
      name: {
        km: "ព្រែកត្រប់",
        latin: "Preaek Trab"
      },
      id: "02050110"
    },
    {
      name: {
        km: "សំរោងក្នុង",
        latin: "Samraong Knong"
      },
      id: "02050201"
    },
    {
      name: {
        km: "កំពង់សំបួរ",
        latin: "Kampong Sambuor"
      },
      id: "02050202"
    },
    {
      name: {
        km: "សំរោងស្នោ",
        latin: "Samraong Snao"
      },
      id: "02050203"
    },
    {
      name: {
        km: "សំរោងអូរទ្រា",
        latin: "Samraong Ou Trea"
      },
      id: "02050204"
    },
    {
      name: {
        km: "សំរោងតាកុក",
        latin: "Samraong Ta Kok"
      },
      id: "02050205"
    },
    {
      name: {
        km: "ព្រែកស្នោ",
        latin: "Preaek Snao"
      },
      id: "02050301"
    },
    {
      name: {
        km: "ព្រែកខ្ពប",
        latin: "Preaek Khpob"
      },
      id: "02050302"
    },
    {
      name: {
        km: "ស្នាពីមុខ",
        latin: "Sna Pi Mukh"
      },
      id: "02050303"
    },
    {
      name: {
        km: "ខ្វិត",
        latin: "Khvet"
      },
      id: "02050304"
    },
    {
      name: {
        km: "អូរកំបុត",
        latin: "Ou Kambot"
      },
      id: "02050305"
    },
    {
      name: {
        km: "ព្រែកហ្លួង",
        latin: "Preaek Luong"
      },
      id: "02050401"
    },
    {
      name: {
        km: "ស្ដីលើ",
        latin: "Sdei Leu"
      },
      id: "02050402"
    },
    {
      name: {
        km: "ស្ដីក្រោម",
        latin: "Sdei Kraom"
      },
      id: "02050403"
    },
    {
      name: {
        km: "រហាលសួង",
        latin: "Rohal Suong"
      },
      id: "02050404"
    },
    {
      name: {
        km: "បាក់អំរែក",
        latin: "Bak Amraek"
      },
      id: "02050405"
    },
    {
      name: {
        km: "ដូនឥន្ទ",
        latin: "Doun Ent"
      },
      id: "02050406"
    },
    {
      name: {
        km: "បាក់រទេះ",
        latin: "Bak Roteh"
      },
      id: "02050407"
    },
    {
      name: {
        km: "ដូនទាវ",
        latin: "Doun Teav"
      },
      id: "02050501"
    },
    {
      name: {
        km: "សួសអី",
        latin: "Suos Ei"
      },
      id: "02050502"
    },
    {
      name: {
        km: "ពាមឯក",
        latin: "Peam Aek"
      },
      id: "02050503"
    },
    {
      name: {
        km: "គង់ទុំ",
        latin: "Kong Tum"
      },
      id: "02050504"
    },
    {
      name: {
        km: "ករហាល",
        latin: "Ka Rohal"
      },
      id: "02050505"
    },
    {
      name: {
        km: "ព្រែកឆ្ដោរ",
        latin: "Preaek Chdaor"
      },
      id: "02050506"
    },
    {
      name: {
        km: "តាគម",
        latin: "Ta Kom"
      },
      id: "02050507"
    },
    {
      name: {
        km: "គោកដូង",
        latin: "Kouk Doung"
      },
      id: "02050508"
    },
    {
      name: {
        km: "ព្រៃចាស់",
        latin: "Prey Chas"
      },
      id: "02050601"
    },
    {
      name: {
        km: "ពាមសីមា",
        latin: "Peam Seima"
      },
      id: "02050602"
    },
    {
      name: {
        km: "អន្លង់សណ្ដាន់",
        latin: "Anlong Sandan"
      },
      id: "02050603"
    },
    {
      name: {
        km: "កោះជីវាំង",
        latin: "Kaoh Chiveang"
      },
      id: "02050604"
    },
    {
      name: {
        km: "បាក់ព្រា",
        latin: "Bak Prea"
      },
      id: "02050605"
    },
    {
      name: {
        km: "ថ្វាង",
        latin: "Thvang"
      },
      id: "02050701"
    },
    {
      name: {
        km: "កំពង់ប្រហុក",
        latin: "Kampong Prahok"
      },
      id: "02050702"
    },
    {
      name: {
        km: "អន្លង់តាអួរ",
        latin: "Anlong Ta Uor"
      },
      id: "02050703"
    },
    {
      name: {
        km: "ព្រែកទាល់",
        latin: "Preaek Toal"
      },
      id: "02050704"
    },
    {
      name: {
        km: "ក្បាលតោល",
        latin: "Kbal Taol"
      },
      id: "02050705"
    },
    {
      name: {
        km: "ប៉ែន",
        latin: "Paen"
      },
      id: "02060101"
    },
    {
      name: {
        km: "អូរក្របៅ",
        latin: "Ou Krabau"
      },
      id: "02060102"
    },
    {
      name: {
        km: "កោះចារ",
        latin: "Kaoh Char"
      },
      id: "02060103"
    },
    {
      name: {
        km: "ឫស្សី ១",
        latin: "Ruessei Muoy"
      },
      id: "02060104"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "02060105"
    },
    {
      name: {
        km: "ឫស្សី ២",
        latin: "Ruessei Pir"
      },
      id: "02060106"
    },
    {
      name: {
        km: "កន្សៃបន្ទាយ",
        latin: "Kansai Banteay"
      },
      id: "02060107"
    },
    {
      name: {
        km: "រ៉ា",
        latin: "Ra"
      },
      id: "02060108"
    },
    {
      name: {
        km: "ដើមដូង",
        latin: "Daeum Doung"
      },
      id: "02060109"
    },
    {
      name: {
        km: "មោង",
        latin: "Moung"
      },
      id: "02060110"
    },
    {
      name: {
        km: "ប្រទ្បាយ",
        latin: "Pralay"
      },
      id: "02060111"
    },
    {
      name: {
        km: "តាតុក ១",
        latin: "Ta Tok Muoy"
      },
      id: "02060112"
    },
    {
      name: {
        km: "តាតុក ២",
        latin: "Ta Tok Pir"
      },
      id: "02060113"
    },
    {
      name: {
        km: "ក្បាលមូស",
        latin: "Kbal Mus"
      },
      id: "02060114"
    },
    {
      name: {
        km: "បោះពួយ",
        latin: "Baoh Puoy"
      },
      id: "02060116"
    },
    {
      name: {
        km: "រុន",
        latin: "Run"
      },
      id: "02060201"
    },
    {
      name: {
        km: "រកាឈ្មោល",
        latin: "Roka Chhmoul"
      },
      id: "02060202"
    },
    {
      name: {
        km: "អន្លង់ស្ដៅ",
        latin: "Anlong Sdau"
      },
      id: "02060203"
    },
    {
      name: {
        km: "ពោធិ៍ ១",
        latin: "Pou Muoy"
      },
      id: "02060204"
    },
    {
      name: {
        km: "ពោធិ៍ ២",
        latin: "Pou Pir"
      },
      id: "02060205"
    },
    {
      name: {
        km: "គារ ១",
        latin: "Kear Muoy"
      },
      id: "02060206"
    },
    {
      name: {
        km: "គារ ២",
        latin: "Kear Pir"
      },
      id: "02060207"
    },
    {
      name: {
        km: "គារ ៣",
        latin: "Kear Bei"
      },
      id: "02060208"
    },
    {
      name: {
        km: "អូរគ្រៀត",
        latin: "Ou Kriet"
      },
      id: "02060209"
    },
    {
      name: {
        km: "រាមគន់",
        latin: "Ream Kon"
      },
      id: "02060210"
    },
    {
      name: {
        km: "តាណាក",
        latin: "Ta Nak"
      },
      id: "02060211"
    },
    {
      name: {
        km: "កោះថ្កូវ",
        latin: "Kaoh Thkov"
      },
      id: "02060212"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "02060301"
    },
    {
      name: {
        km: "ចាមរអា",
        latin: "Cham Ro'a"
      },
      id: "02060302"
    },
    {
      name: {
        km: "ផ្លូវបំបែក",
        latin: "Phlov Bambaek"
      },
      id: "02060303"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "02060304"
    },
    {
      name: {
        km: "ទួលធ្នង់",
        latin: "Tuol Thnong"
      },
      id: "02060305"
    },
    {
      name: {
        km: "កាទ្បោមភ្លុក",
        latin: "Kalaom Phluk"
      },
      id: "02060306"
    },
    {
      name: {
        km: "ស្រម៉មាស",
        latin: "Srama Meas"
      },
      id: "02060307"
    },
    {
      name: {
        km: "ព្រៃស្វាយ",
        latin: "Prey Svay"
      },
      id: "02060308"
    },
    {
      name: {
        km: "ព្រៃព្រាល",
        latin: "Prey Preal"
      },
      id: "02060309"
    },
    {
      name: {
        km: "អ្នកតាទ្វារ",
        latin: "Neak Ta Tvear"
      },
      id: "02060401"
    },
    {
      name: {
        km: "យឺនមាន",
        latin: "Yeun Mean"
      },
      id: "02060402"
    },
    {
      name: {
        km: "ទួលស្នួល",
        latin: "Tuol Snuol"
      },
      id: "02060403"
    },
    {
      name: {
        km: "ជ្រៃរុន",
        latin: "Chrey Run"
      },
      id: "02060404"
    },
    {
      name: {
        km: "ទួលរកា",
        latin: "Tuol Roka"
      },
      id: "02060405"
    },
    {
      name: {
        km: "និគមក្រោម",
        latin: "Nikom Kraom"
      },
      id: "02060406"
    },
    {
      name: {
        km: "ស្រះជីនាង",
        latin: "Srah Chineang"
      },
      id: "02060407"
    },
    {
      name: {
        km: "ពេជចង្វា",
        latin: "Pech Changva"
      },
      id: "02060408"
    },
    {
      name: {
        km: "អំពិលឈូង",
        latin: "Ampil Chhung"
      },
      id: "02060409"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "02060410"
    },
    {
      name: {
        km: "បឹងប្រិយ៍មានជ័យ",
        latin: "Boeng Prei Mean Chey"
      },
      id: "02060411"
    },
    {
      name: {
        km: "ដូនទ្រី",
        latin: "Doun Tri"
      },
      id: "02060501"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "02060502"
    },
    {
      name: {
        km: "ទួលតាថុន",
        latin: "Tuol Ta Thon"
      },
      id: "02060503"
    },
    {
      name: {
        km: "ម្រះព្រៅ",
        latin: "Mreah Prov"
      },
      id: "02060504"
    },
    {
      name: {
        km: "ជ្រៃ ១",
        latin: "Chrey Muoy"
      },
      id: "02060505"
    },
    {
      name: {
        km: "ជ្រៃ ២",
        latin: "Chrey Pir"
      },
      id: "02060506"
    },
    {
      name: {
        km: "ជ្រៃជើង",
        latin: "Chrey Cheung"
      },
      id: "02060507"
    },
    {
      name: {
        km: "ចុងសំណាយ",
        latin: "Chong Samnay"
      },
      id: "02060508"
    },
    {
      name: {
        km: "ម៉ាណោក",
        latin: "Ma Naok"
      },
      id: "02060601"
    },
    {
      name: {
        km: "សួស្ដី",
        latin: "Suosdei"
      },
      id: "02060602"
    },
    {
      name: {
        km: "ស្ដីស្ទឹង",
        latin: "Sdei Stueng"
      },
      id: "02060603"
    },
    {
      name: {
        km: "ស្ទឹងថ្មី",
        latin: "Stueng Thmei"
      },
      id: "02060604"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "02060605"
    },
    {
      name: {
        km: "វត្ដចាស់",
        latin: "Voat Chas"
      },
      id: "02060606"
    },
    {
      name: {
        km: "ចុងប្រឡាយ",
        latin: "Chong Pralay"
      },
      id: "02060607"
    },
    {
      name: {
        km: "ប្រឡាយស្ដៅ",
        latin: "Pralay Sdau"
      },
      id: "02060608"
    },
    {
      name: {
        km: "ត្រស់",
        latin: "Tras"
      },
      id: "02060609"
    },
    {
      name: {
        km: "ទួលព្រុំ ១",
        latin: "Tuol Prum Muoy"
      },
      id: "02060701"
    },
    {
      name: {
        km: "ទួលព្រុំ ២",
        latin: "Tuol Prum Pir"
      },
      id: "02060702"
    },
    {
      name: {
        km: "ចកតូច",
        latin: "Chak Touch"
      },
      id: "02060703"
    },
    {
      name: {
        km: "ចកធំ",
        latin: "Chak Thum"
      },
      id: "02060704"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "02060705"
    },
    {
      name: {
        km: "ស្រែអូរ",
        latin: "Srae Ou"
      },
      id: "02060706"
    },
    {
      name: {
        km: "ផ្អៀង",
        latin: "Ph'ieng"
      },
      id: "02060707"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "02060708"
    },
    {
      name: {
        km: "កូនខ្លុង",
        latin: "Koun Khlong"
      },
      id: "02060801"
    },
    {
      name: {
        km: "ដុបក្រសាំង",
        latin: "Dob Krasang"
      },
      id: "02060802"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02060803"
    },
    {
      name: {
        km: "ព្រៃតូច",
        latin: "Prey Touch"
      },
      id: "02060804"
    },
    {
      name: {
        km: "ព្រាននិល",
        latin: "Prean Nil"
      },
      id: "02060805"
    },
    {
      name: {
        km: "ស្ទឹងចក",
        latin: "Stueng Chak"
      },
      id: "02060806"
    },
    {
      name: {
        km: "បឹងព្រីង",
        latin: "Boeng Pring"
      },
      id: "02060807"
    },
    {
      name: {
        km: "ព្រៃដំរី",
        latin: "Prey Damrei"
      },
      id: "02060808"
    },
    {
      name: {
        km: "បឹងបី",
        latin: "Boeng Bei"
      },
      id: "02060901"
    },
    {
      name: {
        km: "គួយជីកដី",
        latin: "Kuoy Chik Dei"
      },
      id: "02060902"
    },
    {
      name: {
        km: "ព្រែកអាំ",
        latin: "Preaek Am"
      },
      id: "02060903"
    },
    {
      name: {
        km: "កូនក្អែក ១",
        latin: "Koun K'aek Muoy"
      },
      id: "02060904"
    },
    {
      name: {
        km: "កូនក្អែក ២",
        latin: "Koun K'aek Pir"
      },
      id: "02060905"
    },
    {
      name: {
        km: "របស់មង្គល",
        latin: "Robas Mongkol"
      },
      id: "02060906"
    },
    {
      name: {
        km: "អន្លង់កូប",
        latin: "Anlong Koub"
      },
      id: "02060907"
    },
    {
      name: {
        km: "ព្រៃព្រុំ ១",
        latin: "Prey Prum Muoy"
      },
      id: "02060908"
    },
    {
      name: {
        km: "ព្រៃព្រុំ ២",
        latin: "Prey Prum Pir"
      },
      id: "02060909"
    },
    {
      name: {
        km: "ព្រះធាតុ",
        latin: "Preah Theat"
      },
      id: "02060910"
    },
    {
      name: {
        km: "អន្លង់តាម៉ុក",
        latin: "Anlong Ta Mok"
      },
      id: "02060911"
    },
    {
      name: {
        km: "អន្លង់ត្រាច",
        latin: "Anlong Trach"
      },
      id: "02060912"
    },
    {
      name: {
        km: "បាណង់",
        latin: "Banang"
      },
      id: "02070101"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "02070102"
    },
    {
      name: {
        km: "ចំការល្មុត",
        latin: "Chamkar Lmut"
      },
      id: "02070103"
    },
    {
      name: {
        km: "បឹងអំពិល",
        latin: "Boeng Ampil"
      },
      id: "02070104"
    },
    {
      name: {
        km: "ដង្គត់",
        latin: "Dangkot"
      },
      id: "02070105"
    },
    {
      name: {
        km: "ដូនមាយ",
        latin: "Doun Meay"
      },
      id: "02070106"
    },
    {
      name: {
        km: "បរិបូរណ៌",
        latin: "Baribour"
      },
      id: "02070107"
    },
    {
      name: {
        km: "គោកជ័រ",
        latin: "Kouk Choar"
      },
      id: "02070113"
    },
    {
      name: {
        km: "អណ្ដើកហែប",
        latin: "Andaeuk Haeb"
      },
      id: "02070201"
    },
    {
      name: {
        km: "ស្វាយជួរ",
        latin: "Svay Chuor"
      },
      id: "02070202"
    },
    {
      name: {
        km: "ថ្មព្រូស",
        latin: "Thma Prous"
      },
      id: "02070203"
    },
    {
      name: {
        km: "សេរីវ័ន្ដ",
        latin: "Serei Voan"
      },
      id: "02070204"
    },
    {
      name: {
        km: "ព្រៃអំពរ",
        latin: "Prey Ampor"
      },
      id: "02070205"
    },
    {
      name: {
        km: "កណ្ដាលស្ទឹង",
        latin: "Kandal Stueng"
      },
      id: "02070206"
    },
    {
      name: {
        km: "ថ្វាក",
        latin: "Thvak"
      },
      id: "02070207"
    },
    {
      name: {
        km: "ផ្លូវមាស",
        latin: "Phlov Meas"
      },
      id: "02070301"
    },
    {
      name: {
        km: "សេកសក",
        latin: "Sek Sak"
      },
      id: "02070302"
    },
    {
      name: {
        km: "ទឹកសាប",
        latin: "Tuek Sab"
      },
      id: "02070303"
    },
    {
      name: {
        km: "ជីប៉ាង",
        latin: "Chi Pang"
      },
      id: "02070304"
    },
    {
      name: {
        km: "អូរត្រែង",
        latin: "Ou Traeng"
      },
      id: "02070305"
    },
    {
      name: {
        km: "អូរដា",
        latin: "Ou Da"
      },
      id: "02070306"
    },
    {
      name: {
        km: "អូរល្មូន",
        latin: "Ou Lmun"
      },
      id: "02070307"
    },
    {
      name: {
        km: "គីឡូ",
        latin: "Kilou"
      },
      id: "02070401"
    },
    {
      name: {
        km: "ភ្ជាវ",
        latin: "Phcheav"
      },
      id: "02070402"
    },
    {
      name: {
        km: "ជាមន្ដ្រី",
        latin: "Chea Montrei"
      },
      id: "02070403"
    },
    {
      name: {
        km: "ជីសាង",
        latin: "Chi Sang"
      },
      id: "02070404"
    },
    {
      name: {
        km: "គីឡូ ៣៨",
        latin: "Kilou Samprambei"
      },
      id: "02070405"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "02070406"
    },
    {
      name: {
        km: "តាគ្រក់",
        latin: "Ta Krok"
      },
      id: "02070407"
    },
    {
      name: {
        km: "ប៊ូរុន",
        latin: "Bu Run"
      },
      id: "02070409"
    },
    {
      name: {
        km: "រស្មីសង្ហា",
        latin: "Reaksmei Sangha"
      },
      id: "02070501"
    },
    {
      name: {
        km: "នាងលេម",
        latin: "Neang Lem"
      },
      id: "02070502"
    },
    {
      name: {
        km: "អណ្ដើកដប់មួយ",
        latin: "Andaeuk Dobmuoy"
      },
      id: "02070503"
    },
    {
      name: {
        km: "ពេជ្រចង្វា",
        latin: "Pech Changva"
      },
      id: "02070504"
    },
    {
      name: {
        km: "បាដាកត្បូង",
        latin: "Badak Tboung"
      },
      id: "02070505"
    },
    {
      name: {
        km: "បាដាកជើង",
        latin: "Badak Cheung"
      },
      id: "02070506"
    },
    {
      name: {
        km: "អូរដៃខ្លា",
        latin: "Ou Dai Khla"
      },
      id: "02070507"
    },
    {
      name: {
        km: "អូរឃ្មុំ",
        latin: "Ou Khmum"
      },
      id: "02070508"
    },
    {
      name: {
        km: "ច្រាបវាល",
        latin: "Chrab Veal"
      },
      id: "02080101"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "02080102"
    },
    {
      name: {
        km: "អន្លង់វិល",
        latin: "Anlong Vil"
      },
      id: "02080103"
    },
    {
      name: {
        km: "អូរមុនី ១",
        latin: "Ou Muni Muoy"
      },
      id: "02080104"
    },
    {
      name: {
        km: "អូរមុនី ២",
        latin: "Ou Muni Pir"
      },
      id: "02080105"
    },
    {
      name: {
        km: "ជំនីក",
        latin: "Chumnik"
      },
      id: "02080106"
    },
    {
      name: {
        km: "ពុកឆ្មា",
        latin: "Puk Chhma"
      },
      id: "02080107"
    },
    {
      name: {
        km: "ស្ពង់",
        latin: "Spong"
      },
      id: "02080108"
    },
    {
      name: {
        km: "ស្វាយកង់",
        latin: "Svay Kang"
      },
      id: "02080109"
    },
    {
      name: {
        km: "ដំបូកបុណ្យ",
        latin: "Dambouk Bon"
      },
      id: "02080110"
    },
    {
      name: {
        km: "នរា ១",
        latin: "Norea Muoy"
      },
      id: "02080201"
    },
    {
      name: {
        km: "នរា ២",
        latin: "Norea Pir"
      },
      id: "02080202"
    },
    {
      name: {
        km: "បាឡាត់",
        latin: "Balat"
      },
      id: "02080203"
    },
    {
      name: {
        km: "តាកុក",
        latin: "Ta Kok"
      },
      id: "02080204"
    },
    {
      name: {
        km: "បឹងទឹម",
        latin: "Boeng Tuem"
      },
      id: "02080301"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "02080302"
    },
    {
      name: {
        km: "សម្ដេច",
        latin: "Samdach"
      },
      id: "02080303"
    },
    {
      name: {
        km: "បាសែត",
        latin: "Basaet"
      },
      id: "02080304"
    },
    {
      name: {
        km: "តាប៉ុន",
        latin: "Ta Pon"
      },
      id: "02080305"
    },
    {
      name: {
        km: "ពោធិ៍បាត់ដំបង",
        latin: "Pou Batdambang"
      },
      id: "02080402"
    },
    {
      name: {
        km: "អំបែងថ្ងែ",
        latin: "Ambaeng Thngae"
      },
      id: "02080403"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "02080404"
    },
    {
      name: {
        km: "តាហែន ១",
        latin: "Ta Haen Muoy"
      },
      id: "02080405"
    },
    {
      name: {
        km: "តាហែន ២",
        latin: "Ta Haen Pir"
      },
      id: "02080406"
    },
    {
      name: {
        km: "ឈូងត្រដក់",
        latin: "Choung Trodok"
      },
      id: "02080407"
    },
    {
      name: {
        km: "ព្រៃចែក",
        latin: "Prey Chaek"
      },
      id: "02080501"
    },
    {
      name: {
        km: "បញ្ញា",
        latin: "Panhnha"
      },
      id: "02080502"
    },
    {
      name: {
        km: "ក្រឡាញ់",
        latin: "Kralanh"
      },
      id: "02080503"
    },
    {
      name: {
        km: "កំពង់ព្រះ",
        latin: "Kampong Preah"
      },
      id: "02080504"
    },
    {
      name: {
        km: "អណ្ដូងត្រាច",
        latin: "Andoung Trach"
      },
      id: "02080505"
    },
    {
      name: {
        km: "ស្រះកែវ",
        latin: "Srah Kaev"
      },
      id: "02080506"
    },
    {
      name: {
        km: "សំបុកអក",
        latin: "Sambok Ak"
      },
      id: "02080601"
    },
    {
      name: {
        km: "សាលាត្រាវ",
        latin: "Sala Trav"
      },
      id: "02080602"
    },
    {
      name: {
        km: "កាច់រទេះ",
        latin: "Kach Roteh"
      },
      id: "02080603"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02080604"
    },
    {
      name: {
        km: "អូសទូក",
        latin: "Os Tuk"
      },
      id: "02080605"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "02080606"
    },
    {
      name: {
        km: "ទួលស្នួល",
        latin: "Tuol Snuol"
      },
      id: "02080701"
    },
    {
      name: {
        km: "វត្ដកណ្ដាល",
        latin: "Voat Kandal"
      },
      id: "02080702"
    },
    {
      name: {
        km: "រាំងកេសី",
        latin: "Reang Kesei"
      },
      id: "02080703"
    },
    {
      name: {
        km: "រាំងក្រោល",
        latin: "Reang Kraol"
      },
      id: "02080704"
    },
    {
      name: {
        km: "ព្រៃស្វាយ",
        latin: "Prey Svay"
      },
      id: "02080705"
    },
    {
      name: {
        km: "ស្វាយជាតិ",
        latin: "Svay Cheat"
      },
      id: "02080706"
    },
    {
      name: {
        km: "បឹងវែង",
        latin: "Boeng Veaeng"
      },
      id: "02080707"
    },
    {
      name: {
        km: "ដំណាក់ដង្កោ",
        latin: "Damnak Dangkao"
      },
      id: "02080708"
    },
    {
      name: {
        km: "កកោះកំបុត",
        latin: "Kakaoh Kambot"
      },
      id: "02080709"
    },
    {
      name: {
        km: "វត្ដតាមិម",
        latin: "Vaot Ta Muem"
      },
      id: "02080801"
    },
    {
      name: {
        km: "បោះពោធិ៍",
        latin: "Baoh Pou"
      },
      id: "02080802"
    },
    {
      name: {
        km: "អូរខ្ជាយ",
        latin: "Ou Khcheay"
      },
      id: "02080803"
    },
    {
      name: {
        km: "អូរស្រឡៅ",
        latin: "Ou Sralau"
      },
      id: "02080804"
    },
    {
      name: {
        km: "វត្ដចែង",
        latin: "Voat Chaeng"
      },
      id: "02080805"
    },
    {
      name: {
        km: "សំរោងកោង",
        latin: "Samraong Kaong"
      },
      id: "02080806"
    },
    {
      name: {
        km: "អូរដំបង",
        latin: "Ou Dambang"
      },
      id: "02080901"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "02080902"
    },
    {
      name: {
        km: "កំពង់ម្ដោក",
        latin: "Kampong Mdaok"
      },
      id: "02080903"
    },
    {
      name: {
        km: "ស្វាយធំ",
        latin: "Svay Thum"
      },
      id: "02080904"
    },
    {
      name: {
        km: "ដំបូកខ្ពស់",
        latin: "Dambouk Khpos"
      },
      id: "02080905"
    },
    {
      name: {
        km: "ទួលល្វៀង",
        latin: "Tuol Lvieng"
      },
      id: "02080906"
    },
    {
      name: {
        km: "កំពង់អំពិល",
        latin: "Kampong pil"
      },
      id: "02081001"
    },
    {
      name: {
        km: "កំពង់ឆ្លង",
        latin: "Kampong Chlang"
      },
      id: "02081002"
    },
    {
      name: {
        km: "អូរស្រឡៅ",
        latin: "Ou Sralau"
      },
      id: "02081003"
    },
    {
      name: {
        km: "អូរខ្ជាយ",
        latin: "Ou Khcheay"
      },
      id: "02081004"
    },
    {
      name: {
        km: "ស្លក្រាម",
        latin: "Sla Kram"
      },
      id: "02081005"
    },
    {
      name: {
        km: "អន្លង់ល្វា",
        latin: "Anlong Lvea"
      },
      id: "02081006"
    },
    {
      name: {
        km: "ពាមតា",
        latin: "Peam Ta"
      },
      id: "02090101"
    },
    {
      name: {
        km: "អូរត្រែង",
        latin: "Ou Traeng"
      },
      id: "02090102"
    },
    {
      name: {
        km: "វាលរលឹម",
        latin: "Veal Roluem"
      },
      id: "02090103"
    },
    {
      name: {
        km: "តាតោក",
        latin: "Ta Taok"
      },
      id: "02090104"
    },
    {
      name: {
        km: "អូរននោង",
        latin: "Ou Nonoung"
      },
      id: "02090105"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "02090106"
    },
    {
      name: {
        km: "អូរតាទៀក",
        latin: "Ou Tatiek"
      },
      id: "02090107"
    },
    {
      name: {
        km: "អូរក្រូច",
        latin: "Ou Krouch"
      },
      id: "02090108"
    },
    {
      name: {
        km: "ភ្នំរ៉ៃ",
        latin: "Phnum Rai"
      },
      id: "02090109"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "02090201"
    },
    {
      name: {
        km: "កំពង់ល្ពៅ",
        latin: "Kampong Lpov"
      },
      id: "02090202"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "02090203"
    },
    {
      name: {
        km: "អូរដើមចេក",
        latin: "Ou Daeum Chek"
      },
      id: "02090204"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "02090205"
    },
    {
      name: {
        km: "ស្ទឹងតូច",
        latin: "Stueng Touch"
      },
      id: "02090206"
    },
    {
      name: {
        km: "អូរជាំលើ",
        latin: "Ou Choam Leu"
      },
      id: "02090207"
    },
    {
      name: {
        km: "អូរជាំកណ្ដាល",
        latin: "Ou Choam Kandal"
      },
      id: "02090208"
    },
    {
      name: {
        km: "អូរជាំក្រោម",
        latin: "Ou Choam Kraom"
      },
      id: "02090209"
    },
    {
      name: {
        km: "អូរសំរិលលើ",
        latin: "Ou Samril Leu"
      },
      id: "02090301"
    },
    {
      name: {
        km: "អូរសំរិលក្រោម",
        latin: "Ou Samril Kraom"
      },
      id: "02090302"
    },
    {
      name: {
        km: "អូររំចេកលើ",
        latin: "Ou Rumchek Leu"
      },
      id: "02090303"
    },
    {
      name: {
        km: "អូររំចេកក្រោម",
        latin: "Ou Rumchek Kraom"
      },
      id: "02090304"
    },
    {
      name: {
        km: "ចម្លងរមាំងលើ",
        latin: "Chamlang Romeang Leu"
      },
      id: "02090305"
    },
    {
      name: {
        km: "ចម្លងរមាំងក្រោម",
        latin: "Chamlang Romeang Kraom"
      },
      id: "02090306"
    },
    {
      name: {
        km: "ស៊ុង១",
        latin: "Sung Muoy"
      },
      id: "02090401"
    },
    {
      name: {
        km: "ស៊ុង ២",
        latin: "Sung Pir"
      },
      id: "02090402"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "02090403"
    },
    {
      name: {
        km: "ស្រែរាជ",
        latin: "Srae Reach"
      },
      id: "02090404"
    },
    {
      name: {
        km: "ចំការចេក",
        latin: "Chamkar Chek"
      },
      id: "02090405"
    },
    {
      name: {
        km: "កញ្ចាំង",
        latin: "Kanhchang"
      },
      id: "02090406"
    },
    {
      name: {
        km: "ស្រែអណ្ដូង",
        latin: "Srae Andoung"
      },
      id: "02090501"
    },
    {
      name: {
        km: "ឆករកា",
        latin: "Chhak Roka"
      },
      id: "02090502"
    },
    {
      name: {
        km: "សំឡូត",
        latin: "Samlout"
      },
      id: "02090503"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "02090504"
    },
    {
      name: {
        km: "អូរច្រាប",
        latin: "Ou Chrab"
      },
      id: "02090505"
    },
    {
      name: {
        km: "បឹងរុន",
        latin: "Boeng Run"
      },
      id: "02090506"
    },
    {
      name: {
        km: "កំពង់់ទូក",
        latin: "Kampong Touk"
      },
      id: "02090601"
    },
    {
      name: {
        km: "កំចាត់",
        latin: "Kamchat"
      },
      id: "02090602"
    },
    {
      name: {
        km: "ស្រែស្ដៅ",
        latin: "Srae Sdau"
      },
      id: "02090603"
    },
    {
      name: {
        km: "អំពឹប",
        latin: "Ampuep"
      },
      id: "02090604"
    },
    {
      name: {
        km: "ស្រែជីពៅ",
        latin: "Srae Chipov"
      },
      id: "02090605"
    },
    {
      name: {
        km: "តានន",
        latin: "Ta Non"
      },
      id: "02090606"
    },
    {
      name: {
        km: "អូរស្ងួត",
        latin: "Ou Snguot"
      },
      id: "02090701"
    },
    {
      name: {
        km: "ព្រៃរំចេក",
        latin: "Prey Rumchek"
      },
      id: "02090702"
    },
    {
      name: {
        km: "អូរទន្ទឹម",
        latin: "Ou Tontuem"
      },
      id: "02090703"
    },
    {
      name: {
        km: "តាសាញខាងជើង",
        latin: "Ta Sanh Khang Chheung"
      },
      id: "02090704"
    },
    {
      name: {
        km: "តាសាញខាងត្បូង",
        latin: "Ta Sanh Khang Tboung"
      },
      id: "02090705"
    },
    {
      name: {
        km: "អន្លង់ពួក",
        latin: "Anlong Puok"
      },
      id: "02090706"
    },
    {
      name: {
        km: "ដូនត្រិត",
        latin: "Doun Tret"
      },
      id: "02090707"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "02100101"
    },
    {
      name: {
        km: "ថ្នល់បំបែក",
        latin: "Thnal Bambaek"
      },
      id: "02100102"
    },
    {
      name: {
        km: "កោះតូច",
        latin: "Kaoh Touch"
      },
      id: "02100103"
    },
    {
      name: {
        km: "ទួលជ្រៃ",
        latin: "Tuol Chrey"
      },
      id: "02100104"
    },
    {
      name: {
        km: "ក្បាលហុង",
        latin: "Kbal Hong"
      },
      id: "02100201"
    },
    {
      name: {
        km: "ប្រឡាយប្រាក់",
        latin: "Pralay Prak"
      },
      id: "02100202"
    },
    {
      name: {
        km: "អណ្ដូង ពីរ",
        latin: "Andoung Pir"
      },
      id: "02100203"
    },
    {
      name: {
        km: "ទឹកផុស",
        latin: "Tuek Phos"
      },
      id: "02100204"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "02100205"
    },
    {
      name: {
        km: "វាលវង់",
        latin: "Veal Vong"
      },
      id: "02100301"
    },
    {
      name: {
        km: "តាស្ដា",
        latin: "Ta Sda"
      },
      id: "02100302"
    },
    {
      name: {
        km: "ចំការល្ហុង",
        latin: "Chamkar Lhong"
      },
      id: "02100303"
    },
    {
      name: {
        km: "កូនភ្នំខាងជើង",
        latin: "Koun Phnum Khang Cheung"
      },
      id: "02100304"
    },
    {
      name: {
        km: "កូនភ្នំខាងត្បូង",
        latin: "Koun Phnum Khang Tboung"
      },
      id: "02100305"
    },
    {
      name: {
        km: "អូរចំណិប",
        latin: "Ou Chamnib"
      },
      id: "02100306"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "02100401"
    },
    {
      name: {
        km: "គីឡូលេខ១៣",
        latin: "Kilou Lekh Dabbei"
      },
      id: "02100402"
    },
    {
      name: {
        km: "ត្រពាំងព្រលិត",
        latin: "Trapeang Prolit"
      },
      id: "02100403"
    },
    {
      name: {
        km: "អូរកណ្ដោល",
        latin: "Ou Kandaol"
      },
      id: "02100404"
    },
    {
      name: {
        km: "អូរថ្ម",
        latin: "Ou Thmor"
      },
      id: "02100405"
    },
    {
      name: {
        km: "អូរស្ងាត់",
        latin: "Ou Sngat"
      },
      id: "02100406"
    },
    {
      name: {
        km: "ស្រះចក",
        latin: "Sras Chak"
      },
      id: "02100407"
    },
    {
      name: {
        km: "ស្រឡៅជ្រុំ",
        latin: "Sralau Chrum"
      },
      id: "02100501"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "02100502"
    },
    {
      name: {
        km: "ពោធិ៍ជ្រៃ",
        latin: "Pou Chrey"
      },
      id: "02100503"
    },
    {
      name: {
        km: "អូរត្រាវជូរ",
        latin: "Ou Trav Chur"
      },
      id: "02100504"
    },
    {
      name: {
        km: "គំនរបេង",
        latin: "Kumnor Beng"
      },
      id: "02100505"
    },
    {
      name: {
        km: "អូរគ្រួស",
        latin: "Ou Kruos"
      },
      id: "02100506"
    },
    {
      name: {
        km: "អូរគគីរ",
        latin: "Ou Kokir"
      },
      id: "02100507"
    },
    {
      name: {
        km: "ទួលក្រសាំង",
        latin: "Tuol Krasang"
      },
      id: "02100508"
    },
    {
      name: {
        km: "អូរប្រហ៊ូត",
        latin: "Ou Prahout"
      },
      id: "02100509"
    },
    {
      name: {
        km: "ថ្លុកសង្កែ",
        latin: "Thlok Sangkae"
      },
      id: "02100510"
    },
    {
      name: {
        km: "បឹងត្រកួន",
        latin: "Boeng Trakoun"
      },
      id: "02100511"
    },
    {
      name: {
        km: "បឹងក្ទម្ព",
        latin: "Boeng Ktoum"
      },
      id: "02100512"
    },
    {
      name: {
        km: "អូរល្វា",
        latin: "Ou Lvea"
      },
      id: "02100601"
    },
    {
      name: {
        km: "ស្ពានយោល",
        latin: "Spean Youl"
      },
      id: "02100602"
    },
    {
      name: {
        km: "រស្មី",
        latin: "Reaksmei"
      },
      id: "02100603"
    },
    {
      name: {
        km: "គីឡូ៩",
        latin: "Kilou  Prambuon"
      },
      id: "02100604"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "02100605"
    },
    {
      name: {
        km: "ចម្ការតាប៊ុន",
        latin: "Chamkar Ta Bun"
      },
      id: "02100606"
    },
    {
      name: {
        km: "អូរកាច់",
        latin: "Ou Kach"
      },
      id: "02100607"
    },
    {
      name: {
        km: "ស្រឡៅជ្រុំ",
        latin: "Sralau Chrum"
      },
      id: "02100608"
    },
    {
      name: {
        km: "ទួលខ្ពស់",
        latin: "Tuol Khpos"
      },
      id: "02110101"
    },
    {
      name: {
        km: "បេងស្អាត",
        latin: "Beng S'at"
      },
      id: "02110102"
    },
    {
      name: {
        km: "ភ្នំព្រឹក",
        latin: "Phnum Proek"
      },
      id: "02110103"
    },
    {
      name: {
        km: "ស្រឡៅ",
        latin: "Sralau"
      },
      id: "02110104"
    },
    {
      name: {
        km: "គគីរ",
        latin: "Kokir"
      },
      id: "02110105"
    },
    {
      name: {
        km: "អូរធំ",
        latin: "Ou Thom"
      },
      id: "02110106"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "02110201"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnom Touch"
      },
      id: "02110202"
    },
    {
      name: {
        km: "ពេជ្រចិន្ដា",
        latin: "Pech Chenda"
      },
      id: "02110203"
    },
    {
      name: {
        km: "អូរតាសុខ",
        latin: "Ou Ta Sokh"
      },
      id: "02110204"
    },
    {
      name: {
        km: "អូរតាប៉ុន",
        latin: "Ou Ta Pon"
      },
      id: "02110205"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "02110206"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "02110207"
    },
    {
      name: {
        km: "អន្លង់មៀន",
        latin: "Anlong Mean"
      },
      id: "02110208"
    },
    {
      name: {
        km: "អូរថ្មី",
        latin: "Ou Thmey"
      },
      id: "02110209"
    },
    {
      name: {
        km: "ដំណាក់បេង",
        latin: "Damnak Beng"
      },
      id: "02110305"
    },
    {
      name: {
        km: "ដំណាក់ក្សាន្ត",
        latin: "Dom Nakksan"
      },
      id: "02110306"
    },
    {
      name: {
        km: "អូរដា",
        latin: "Oda"
      },
      id: "02110307"
    },
    {
      name: {
        km: "ភ្នំប្រាំពីរ",
        latin: "Phnom 7"
      },
      id: "02110308"
    },
    {
      name: {
        km: "បួរ",
        latin: "Bou"
      },
      id: "02110309"
    },
    {
      name: {
        km: "អន្លង់ក្រូច",
        latin: "Anlung Kroch"
      },
      id: "02110310"
    },
    {
      name: {
        km: "អន្លង់ស្តី",
        latin: "Anlung Sdei"
      },
      id: "02110311"
    },
    {
      name: {
        km: "ស្ពានទំនាប",
        latin: "Spean Tomneab"
      },
      id: "02110312"
    },
    {
      name: {
        km: "បុស្សស្អំ",
        latin: "Bos SaOm"
      },
      id: "02110313"
    },
    {
      name: {
        km: "ទួលជ្រៃ",
        latin: "Tuol Chrey"
      },
      id: "02110401"
    },
    {
      name: {
        km: "អូរចោទ",
        latin: "Ou Chaot"
      },
      id: "02110402"
    },
    {
      name: {
        km: "ទួលខ្វាវ",
        latin: "Tuol Khvav"
      },
      id: "02110403"
    },
    {
      name: {
        km: "បារាំងធ្លាក់",
        latin: "Barang Thleak"
      },
      id: "02110404"
    },
    {
      name: {
        km: "ចំការស្រូវ",
        latin: "Chamkar Srov"
      },
      id: "02110405"
    },
    {
      name: {
        km: "ដំណាក់អំពិល",
        latin: "Damnak Ampil"
      },
      id: "02110406"
    },
    {
      name: {
        km: "ទួលកកោះ",
        latin: "Tuol Kakaoh"
      },
      id: "02110407"
    },
    {
      name: {
        km: "អូរគល់សំយ៉ុង",
        latin: "Ou Kol Samyong"
      },
      id: "02110408"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "02110409"
    },
    {
      name: {
        km: "ចំការត្រប់",
        latin: "Chamkar Trab"
      },
      id: "02110410"
    },
    {
      name: {
        km: "ហុងទឹក",
        latin: "Hong Tuek"
      },
      id: "02110411"
    },
    {
      name: {
        km: "ចក្រី",
        latin: "Chakrei"
      },
      id: "02110412"
    },
    {
      name: {
        km: "អូរជ្រៃ",
        latin: "Ou Chrey"
      },
      id: "02110413"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "02110501"
    },
    {
      name: {
        km: "អូររំដួល",
        latin: "Ou Rumduol"
      },
      id: "02110502"
    },
    {
      name: {
        km: "អូរប្រយុទ្ធ",
        latin: "Ou Prayut"
      },
      id: "02110503"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "02110504"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bot"
      },
      id: "02110505"
    },
    {
      name: {
        km: "អូរល្ហុង",
        latin: "Ou Lhong"
      },
      id: "02110506"
    },
    {
      name: {
        km: "គ្រួសក្រហម",
        latin: "Krus Kraham"
      },
      id: "02110507"
    },
    {
      name: {
        km: "ដីល្អ",
        latin: "Dei Laor"
      },
      id: "02110508"
    },
    {
      name: {
        km: "កំរៀង",
        latin: "Kamrieng"
      },
      id: "02120101"
    },
    {
      name: {
        km: "ស្វាយវែង",
        latin: "Svay Veaeng"
      },
      id: "02120102"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "02120103"
    },
    {
      name: {
        km: "ស្រឡៅទង",
        latin: "Sralau Tong"
      },
      id: "02120104"
    },
    {
      name: {
        km: "អូរជ្រៃ",
        latin: "Ou Chrey"
      },
      id: "02120105"
    },
    {
      name: {
        km: "រកាបុស",
        latin: "Roka Bos"
      },
      id: "02120106"
    },
    {
      name: {
        km: "ឡាក់ហុកពីរ",
        latin: "Lak Hokpir"
      },
      id: "02120107"
    },
    {
      name: {
        km: "បឹងអូរជាង",
        latin: "Boeng Ou Cheang"
      },
      id: "02120108"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "02120201"
    },
    {
      name: {
        km: "អូរដាលើ",
        latin: "Ou Da Leu"
      },
      id: "02120202"
    },
    {
      name: {
        km: "អូរក្រូច",
        latin: "Ou Krouch"
      },
      id: "02120203"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "02120204"
    },
    {
      name: {
        km: "ស្វាយធំ",
        latin: "Svay Thum"
      },
      id: "02120205"
    },
    {
      name: {
        km: "បឹងរាំង",
        latin: "Boeng Reang"
      },
      id: "02120206"
    },
    {
      name: {
        km: "ព្រះពុទ្ធ",
        latin: "Preah Puth"
      },
      id: "02120207"
    },
    {
      name: {
        km: "ភ្នំចាប",
        latin: "Phnom Chap"
      },
      id: "02120208"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "02120301"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "02120302"
    },
    {
      name: {
        km: "អូរគគី",
        latin: "Ou Kokir"
      },
      id: "02120303"
    },
    {
      name: {
        km: "អូរដា",
        latin: "Ou Da"
      },
      id: "02120304"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02120305"
    },
    {
      name: {
        km: "កំពង់ឡី",
        latin: "Kampong Lei"
      },
      id: "02120306"
    },
    {
      name: {
        km: "លំផាត់",
        latin: "Lumphat"
      },
      id: "02120307"
    },
    {
      name: {
        km: "ម្នាស់កាល",
        latin: "Mnoas Kal"
      },
      id: "02120308"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "02120309"
    },
    {
      name: {
        km: "តាំងយូ",
        latin: "Tang Yu"
      },
      id: "02120310"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Trang"
      },
      id: "02120401"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "02120402"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "02120403"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02120404"
    },
    {
      name: {
        km: "ល្វាទេ",
        latin: "Lvea Te"
      },
      id: "02120405"
    },
    {
      name: {
        km: "តាសែន",
        latin: "Ta Saen"
      },
      id: "02120406"
    },
    {
      name: {
        km: "អូរគគី",
        latin: "Ou Kokir"
      },
      id: "02120407"
    },
    {
      name: {
        km: "អូរចំបក់់",
        latin: "Ou Chambak"
      },
      id: "02120408"
    },
    {
      name: {
        km: "ភ្នំមួយរយ",
        latin: "Phnum Muoyrouy"
      },
      id: "02120409"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "02120501"
    },
    {
      name: {
        km: "អូរចម្លង",
        latin: "Ou Chamlang"
      },
      id: "02120502"
    },
    {
      name: {
        km: "អូរអន្លក់",
        latin: "Ou Anlok"
      },
      id: "02120503"
    },
    {
      name: {
        km: "អូរទឹកថ្លា",
        latin: "Ou Tuek Thla"
      },
      id: "02120504"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Samaki"
      },
      id: "02120505"
    },
    {
      name: {
        km: "អូរត្រជាក់ចិត្ត",
        latin: "Ou TraCheakChet"
      },
      id: "02120506"
    },
    {
      name: {
        km: "ដំណាក់សាលា",
        latin: "Damnak Sala"
      },
      id: "02120601"
    },
    {
      name: {
        km: "កំពង់ចម្លងលើ",
        latin: "Kampong Chamlang Leu"
      },
      id: "02120602"
    },
    {
      name: {
        km: "កំពង់ចម្លងក្រោម",
        latin: "Kampong Chamlang Kraom"
      },
      id: "02120603"
    },
    {
      name: {
        km: "កំប្រង់",
        latin: "Kamprang"
      },
      id: "02120604"
    },
    {
      name: {
        km: "ស្រះទឹកថ្មី",
        latin: "Srah Tuek Thmei"
      },
      id: "02120605"
    },
    {
      name: {
        km: "សាមសិប",
        latin: "Samseb"
      },
      id: "02120606"
    },
    {
      name: {
        km: "ស្រះកំប៉ោក",
        latin: "Srah Kampaok"
      },
      id: "02120607"
    },
    {
      name: {
        km: "តាក្រី",
        latin: "Ta Krei"
      },
      id: "02120608"
    },
    {
      name: {
        km: "ទួលទិល",
        latin: "Tuol Til"
      },
      id: "02120609"
    },
    {
      name: {
        km: "ផ្លូវប្រាំមួយ",
        latin: "Phlov Pram Muoy"
      },
      id: "02120610"
    },
    {
      name: {
        km: "រ៉ា",
        latin: "Ra"
      },
      id: "02130101"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "02130102"
    },
    {
      name: {
        km: "ឆាយបល្ល័ង្គ",
        latin: "Chhay Ballangk"
      },
      id: "02130103"
    },
    {
      name: {
        km: "ជើងទិញ",
        latin: "Cheung Tinh"
      },
      id: "02130104"
    },
    {
      name: {
        km: "តាថុក",
        latin: "Ta Thok"
      },
      id: "02130105"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "02130106"
    },
    {
      name: {
        km: "គោកពោន",
        latin: "Kouk Poun"
      },
      id: "02130107"
    },
    {
      name: {
        km: "បឹងស្នោ",
        latin: "Boeng Snao"
      },
      id: "02130108"
    },
    {
      name: {
        km: "ទួលម្ទេស",
        latin: "Tuol Mtes"
      },
      id: "02130109"
    },
    {
      name: {
        km: "កូនព្រហ្ម",
        latin: "Koun Prum"
      },
      id: "02130110"
    },
    {
      name: {
        km: "បឹងរាំង",
        latin: "Boeng Reang"
      },
      id: "02130111"
    },
    {
      name: {
        km: "គាស់ក្រឡ",
        latin: "Koas Krala"
      },
      id: "02130201"
    },
    {
      name: {
        km: "ស្ពាន",
        latin: "Spean"
      },
      id: "02130202"
    },
    {
      name: {
        km: "មុខវត្ដ",
        latin: "Muk Voat"
      },
      id: "02130203"
    },
    {
      name: {
        km: "ទួលបល្ល័ង្គ",
        latin: "Tuol Ballangk"
      },
      id: "02130204"
    },
    {
      name: {
        km: "ទួលតាមឹម",
        latin: "Tuol Ta Muem"
      },
      id: "02130205"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "02130206"
    },
    {
      name: {
        km: "ព្រៃពពេល",
        latin: "Prey Popeael"
      },
      id: "02130207"
    },
    {
      name: {
        km: "បឹងឈ្នះ",
        latin: "Boeng Chhneah"
      },
      id: "02130208"
    },
    {
      name: {
        km: "ដំណាក់កកោះ",
        latin: "Damnak KoKaoh"
      },
      id: "02130209"
    },
    {
      name: {
        km: "ហប់",
        latin: "Hab"
      },
      id: "02130301"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "02130302"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "02130303"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "02130304"
    },
    {
      name: {
        km: "ត្រពាំងដងទឹក",
        latin: "Trapeang Dang Tuek"
      },
      id: "02130305"
    },
    {
      name: {
        km: "គោកត្រុំ",
        latin: "Kouk Trom"
      },
      id: "02130306"
    },
    {
      name: {
        km: "ស្លែងជួរ",
        latin: "Slaeng Chuor"
      },
      id: "02130307"
    },
    {
      name: {
        km: "សាច់ហាប់",
        latin: "Sach Hab"
      },
      id: "02130401"
    },
    {
      name: {
        km: "បឹងព្រះ",
        latin: "Boeng Preah"
      },
      id: "02130402"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "02130403"
    },
    {
      name: {
        km: "កាប់ព្រិច",
        latin: "Kab Prich"
      },
      id: "02130404"
    },
    {
      name: {
        km: "តាខោ",
        latin: "Ta Khao"
      },
      id: "02130405"
    },
    {
      name: {
        km: "កុយវែង",
        latin: "Koy Veaeng"
      },
      id: "02130406"
    },
    {
      name: {
        km: "ព្រៃចក",
        latin: "Prey Chak"
      },
      id: "02130407"
    },
    {
      name: {
        km: "តានួត",
        latin: "Ta Nuot"
      },
      id: "02130408"
    },
    {
      name: {
        km: "បឹងព្រះក្រឡាញ់",
        latin: "Boeng Preah Kralanh"
      },
      id: "02130409"
    },
    {
      name: {
        km: "បាស្រែ",
        latin: "Ba Srae"
      },
      id: "02130501"
    },
    {
      name: {
        km: "ដូនបា",
        latin: "Doun Ba"
      },
      id: "02130502"
    },
    {
      name: {
        km: "ព្រៃភ្ញាស់",
        latin: "Prey Phneas"
      },
      id: "02130503"
    },
    {
      name: {
        km: "ទួលលៀប",
        latin: "Tuol Lieb"
      },
      id: "02130504"
    },
    {
      name: {
        km: "គោករកា",
        latin: "Kouk Roka"
      },
      id: "02130505"
    },
    {
      name: {
        km: "ខ្លែងគង់",
        latin: "Klaeng Kong"
      },
      id: "02130506"
    },
    {
      name: {
        km: "ខ្វែង",
        latin: "Khvaeng"
      },
      id: "02130507"
    },
    {
      name: {
        km: "ព្រៃប៉ែន",
        latin: "Prey Paen"
      },
      id: "02130508"
    },
    {
      name: {
        km: "ក្រាំងស្វាត",
        latin: "Krang Svat"
      },
      id: "02130601"
    },
    {
      name: {
        km: "បន្ទាយចារ",
        latin: "Banteay Char"
      },
      id: "02130602"
    },
    {
      name: {
        km: "ព្រៃសិន",
        latin: "Prey Sen"
      },
      id: "02130603"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "02130604"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "02130605"
    },
    {
      name: {
        km: "ឫស្សីព្រះ",
        latin: "Ruessei Preah"
      },
      id: "02130606"
    },
    {
      name: {
        km: "ឆ្នាល់មាន់់",
        latin: "Chhnal Moan"
      },
      id: "02130607"
    },
    {
      name: {
        km: "សៀម",
        latin: "Siem"
      },
      id: "02140101"
    },
    {
      name: {
        km: "ខ្នាចអំពរ",
        latin: "Khnach Ampor"
      },
      id: "02140102"
    },
    {
      name: {
        km: "ឆ្កែខាំប្រើស",
        latin: "Chhkae Kham Praeus"
      },
      id: "02140103"
    },
    {
      name: {
        km: "ព្រែកតាវេន",
        latin: "Preaek Ta Ven"
      },
      id: "02140104"
    },
    {
      name: {
        km: "ព្រែកជីក",
        latin: "Preaek Chik"
      },
      id: "02140105"
    },
    {
      name: {
        km: "អូររំចេក",
        latin: "Ou Rumcheck"
      },
      id: "02140106"
    },
    {
      name: {
        km: "ថ្នំ",
        latin: "Thnam"
      },
      id: "02140107"
    },
    {
      name: {
        km: "បឹងអំពិល",
        latin: "Boeng Ampil"
      },
      id: "02140108"
    },
    {
      name: {
        km: "បឹងធ្មួយ",
        latin: "Boeng Thmouy"
      },
      id: "02140109"
    },
    {
      name: {
        km: "ចុងពរ",
        latin: "Chong Por"
      },
      id: "02140201"
    },
    {
      name: {
        km: "ប៉ែន",
        latin: "Paen"
      },
      id: "02140204"
    },
    {
      name: {
        km: "ព្រៃខ្លូត",
        latin: "Prey Khlout"
      },
      id: "02140206"
    },
    {
      name: {
        km: "ព្រៃត្រឡាច",
        latin: "Prey Tralach"
      },
      id: "02140207"
    },
    {
      name: {
        km: "រូង",
        latin: "Roung"
      },
      id: "02140208"
    },
    {
      name: {
        km: "ស្រះថត",
        latin: "Srah That"
      },
      id: "02140219"
    },
    {
      name: {
        km: "រូង២",
        latin: "Roung Pir"
      },
      id: "02140220"
    },
    {
      name: {
        km: "បឹងចក",
        latin: "Boeng Chork"
      },
      id: "02140221"
    },
    {
      name: {
        km: "ស្រះគុយ",
        latin: "Srah Kuy"
      },
      id: "02140301"
    },
    {
      name: {
        km: "មុខរាហ៍ ២",
        latin: "Mukh Rea Pir"
      },
      id: "02140302"
    },
    {
      name: {
        km: "ស្វាយយ៉",
        latin: "Svay Ya"
      },
      id: "02140303"
    },
    {
      name: {
        km: "ទួលស្វាយ",
        latin: "Tuol Svay"
      },
      id: "02140304"
    },
    {
      name: {
        km: "តាព្រាត",
        latin: "Ta Preat"
      },
      id: "02140305"
    },
    {
      name: {
        km: "មុខរាហ៍១",
        latin: "Mukh Rea Muoy"
      },
      id: "02140306"
    },
    {
      name: {
        km: "ដង្ហោ",
        latin: "Danghao"
      },
      id: "02140307"
    },
    {
      name: {
        km: "ស្ដុកប្រវឹក",
        latin: "Sdok Pravuek"
      },
      id: "02140401"
    },
    {
      name: {
        km: "កោះធំ",
        latin: "Kaoh Thum"
      },
      id: "02140402"
    },
    {
      name: {
        km: "ព្រះអណ្ដូង",
        latin: "Preah Andoung"
      },
      id: "02140403"
    },
    {
      name: {
        km: "ទួលគគីរ",
        latin: "Tuol Kokir"
      },
      id: "02140404"
    },
    {
      name: {
        km: "ព្រៃអម្ពាន់",
        latin: "Prey Ampoan"
      },
      id: "02140405"
    },
    {
      name: {
        km: "ប្រឡាយ១៨",
        latin: "Pralay Dabprambei"
      },
      id: "02140406"
    },
    {
      name: {
        km: "ជំនាប់ស្បូវភ្លាំង",
        latin: "Chumnoab Sbov Plaeng"
      },
      id: "02140407"
    },
    {
      name: {
        km: "ជំនាប់គគីរ",
        latin: "Chumnoab Kokir"
      },
      id: "02140408"
    },
    {
      name: {
        km: "បឹងត្របែក",
        latin: "Boeng Trabaek"
      },
      id: "02140409"
    },
    {
      name: {
        km: "អណ្តូងកន្លែង",
        latin: "Andoung Konlaeng"
      },
      id: "02140410"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "02140501"
    },
    {
      name: {
        km: "បាសាក់",
        latin: "Basak"
      },
      id: "02140502"
    },
    {
      name: {
        km: "កំរ៉ែង",
        latin: "Kamraeng"
      },
      id: "02140503"
    },
    {
      name: {
        km: "តាព្រាល",
        latin: "Ta Preal"
      },
      id: "02140504"
    },
    {
      name: {
        km: "ច្រាំងខ្ពស់",
        latin: "Chrang Khpos"
      },
      id: "02140505"
    },
    {
      name: {
        km: "បុស្សដូង",
        latin: "Bos Doung"
      },
      id: "02140506"
    },
    {
      name: {
        km: "អូររំចេក",
        latin: "Ou Romchek"
      },
      id: "02140507"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Toek"
      },
      id: "02140508"
    },
    {
      name: {
        km: "កែវមុន្នី",
        latin: "Keo Mony"
      },
      id: "02140509"
    },
    {
      name: {
        km: "ស្វាយពក",
        latin: "Svay Pok"
      },
      id: "03010101"
    },
    {
      name: {
        km: "បាធាយ",
        latin: "Batheay"
      },
      id: "03010102"
    },
    {
      name: {
        km: "ស្រះព្រីង",
        latin: "Srah Pring"
      },
      id: "03010103"
    },
    {
      name: {
        km: "ជ្រែក",
        latin: "Chreaek"
      },
      id: "03010104"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "03010105"
    },
    {
      name: {
        km: "អូរម៉ាល់",
        latin: "Ou Mal"
      },
      id: "03010106"
    },
    {
      name: {
        km: "ច្បារអំពៅ",
        latin: "Chbar Ampov"
      },
      id: "03010201"
    },
    {
      name: {
        km: "ទួលចាន់",
        latin: "Tuol Chan"
      },
      id: "03010202"
    },
    {
      name: {
        km: "អន្លង់ជ្រៃ",
        latin: "Anlong Chrey"
      },
      id: "03010203"
    },
    {
      name: {
        km: "ស្ទឹងឆ្វេង",
        latin: "Stueng Chveng"
      },
      id: "03010204"
    },
    {
      name: {
        km: "ជាលា",
        latin: "Chea Lea"
      },
      id: "03010301"
    },
    {
      name: {
        km: "តាង៉ិល",
        latin: "Ta Ngil"
      },
      id: "03010302"
    },
    {
      name: {
        km: "តាំងក្រាំង",
        latin: "Tang Krang"
      },
      id: "03010303"
    },
    {
      name: {
        km: "បែកពាង",
        latin: "Baek Peang"
      },
      id: "03010304"
    },
    {
      name: {
        km: "ភ្នំធំ",
        latin: "Phnum Thum"
      },
      id: "03010305"
    },
    {
      name: {
        km: "ជើងព្រៃ",
        latin: "Cheung Prey"
      },
      id: "03010401"
    },
    {
      name: {
        km: "អណ្ដូងស្នាយ",
        latin: "Andoung Snay"
      },
      id: "03010402"
    },
    {
      name: {
        km: "ប្រសូត្រ ក",
        latin: "Prasoutr Ka"
      },
      id: "03010403"
    },
    {
      name: {
        km: "ប្រសូត្រ ខ",
        latin: "Prasoutr Kha"
      },
      id: "03010404"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "03010405"
    },
    {
      name: {
        km: "ត្រយ៉ងពង",
        latin: "Trayang Pong"
      },
      id: "03010406"
    },
    {
      name: {
        km: "មេព្រីង",
        latin: "Me Pring"
      },
      id: "03010501"
    },
    {
      name: {
        km: "តាំងថ្លើង",
        latin: "Tang Thlaeung"
      },
      id: "03010502"
    },
    {
      name: {
        km: "តាំងស្រី",
        latin: "Tang Srei"
      },
      id: "03010503"
    },
    {
      name: {
        km: "តាំងរលាង",
        latin: "Tang Roleang"
      },
      id: "03010504"
    },
    {
      name: {
        km: "ព្រៃកោ",
        latin: "Prey Kao"
      },
      id: "03010505"
    },
    {
      name: {
        km: "ផ្អាវ",
        latin: "Ph'av"
      },
      id: "03010601"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "03010602"
    },
    {
      name: {
        km: "តាំងបឹង",
        latin: "Tang Boeng"
      },
      id: "03010603"
    },
    {
      name: {
        km: "បាកាល",
        latin: "Ba Kal"
      },
      id: "03010604"
    },
    {
      name: {
        km: "ព្រៃញា",
        latin: "Prey Nhea"
      },
      id: "03010605"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "03010606"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "03010701"
    },
    {
      name: {
        km: "បាលាំង",
        latin: "Balang"
      },
      id: "03010702"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "03010703"
    },
    {
      name: {
        km: "សង្កើប",
        latin: "Sangkaeub"
      },
      id: "03010704"
    },
    {
      name: {
        km: "តាពយ",
        latin: "Ta Poy"
      },
      id: "03010705"
    },
    {
      name: {
        km: "ចុង",
        latin: "Chong"
      },
      id: "03010706"
    },
    {
      name: {
        km: "តាបែក",
        latin: "Ta Baek"
      },
      id: "03010707"
    },
    {
      name: {
        km: "កប៉ាល់",
        latin: "Kakpal"
      },
      id: "03010801"
    },
    {
      name: {
        km: "ពោធិស្ទៀង",
        latin: "Pou Stieng"
      },
      id: "03010802"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "03010803"
    },
    {
      name: {
        km: "តាំងជ្រៃ",
        latin: "Tang Chrey"
      },
      id: "03010804"
    },
    {
      name: {
        km: "ស្រឹង្គ",
        latin: "Sroengk"
      },
      id: "03010805"
    },
    {
      name: {
        km: "ភ្នំដិល",
        latin: "Phnum Del"
      },
      id: "03010901"
    },
    {
      name: {
        km: "ជើងឆ្នុក",
        latin: "Cheung Chhnok"
      },
      id: "03010902"
    },
    {
      name: {
        km: "ត្បូងភ្នំ",
        latin: "Tboung Phnum"
      },
      id: "03010903"
    },
    {
      name: {
        km: "ពពិត",
        latin: "Popit"
      },
      id: "03010904"
    },
    {
      name: {
        km: "អកទៀង",
        latin: "Ak Tieng"
      },
      id: "03010905"
    },
    {
      name: {
        km: "កំពង់ព្រះ",
        latin: "Kampong Preah"
      },
      id: "03010906"
    },
    {
      name: {
        km: "តាំងគោក",
        latin: "Tang Kouk"
      },
      id: "03010907"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "03010908"
    },
    {
      name: {
        km: "បឹងវែង",
        latin: "Boeng Veaeng"
      },
      id: "03011001"
    },
    {
      name: {
        km: "ក្រដាស ក",
        latin: "Kradas Ka"
      },
      id: "03011002"
    },
    {
      name: {
        km: "ក្រដាស ខ",
        latin: "Kradas Kha"
      },
      id: "03011003"
    },
    {
      name: {
        km: "ស្ដុកធំ",
        latin: "Sdok Thum"
      },
      id: "03011004"
    },
    {
      name: {
        km: "ត្រាវភ្នី",
        latin: "Trav Phni"
      },
      id: "03011005"
    },
    {
      name: {
        km: "ខ្វិត",
        latin: "Khvet"
      },
      id: "03011006"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "03011007"
    },
    {
      name: {
        km: "ខ្ទុំ",
        latin: "Khtum"
      },
      id: "03011008"
    },
    {
      name: {
        km: "ចាន់",
        latin: "Chan"
      },
      id: "03011009"
    },
    {
      name: {
        km: "ជីនាង",
        latin: "Chi Neang"
      },
      id: "03011010"
    },
    {
      name: {
        km: "រោងដំរី",
        latin: "Roung Damrei"
      },
      id: "03011101"
    },
    {
      name: {
        km: "កំពោក",
        latin: "Kampouk"
      },
      id: "03011102"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "03011103"
    },
    {
      name: {
        km: "ទំព្រង",
        latin: "Tum Prong"
      },
      id: "03011104"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03011105"
    },
    {
      name: {
        km: "ពោធិឫស្សី",
        latin: "Pou Ruessei"
      },
      id: "03011106"
    },
    {
      name: {
        km: "ត្រប់",
        latin: "Trab"
      },
      id: "03011107"
    },
    {
      name: {
        km: "ចាន់គង់",
        latin: "Chan Kong"
      },
      id: "03011108"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "03011109"
    },
    {
      name: {
        km: "ថ្មកែវ",
        latin: "Thma Kaev"
      },
      id: "03011110"
    },
    {
      name: {
        km: "រោធ",
        latin: "Rout"
      },
      id: "03011111"
    },
    {
      name: {
        km: "ទំនប់លើ",
        latin: "Tumnob Leu"
      },
      id: "03011201"
    },
    {
      name: {
        km: "ប្រយុក",
        latin: "Prayuk"
      },
      id: "03011202"
    },
    {
      name: {
        km: "ដូនប៉ែន",
        latin: "Doun Paen"
      },
      id: "03011203"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "03011204"
    },
    {
      name: {
        km: "ប្រសាំ",
        latin: "Prasam"
      },
      id: "03011205"
    },
    {
      name: {
        km: "ស្រឹង្គ",
        latin: "Sroengk"
      },
      id: "03011206"
    },
    {
      name: {
        km: "ត្រពាំងស្នោ",
        latin: "Trapeang Snao"
      },
      id: "03011207"
    },
    {
      name: {
        km: "សារ៉ាយ",
        latin: "Saray"
      },
      id: "03020101"
    },
    {
      name: {
        km: "ដូនធី",
        latin: "Doun Thi"
      },
      id: "03020102"
    },
    {
      name: {
        km: "ថ្លុកក្រវ៉ាន់",
        latin: "Thlok Kravan"
      },
      id: "03020103"
    },
    {
      name: {
        km: "វាលធ្នង់",
        latin: "Veal Thnong"
      },
      id: "03020104"
    },
    {
      name: {
        km: "បុសខ្នុរ",
        latin: "Bos Khnor"
      },
      id: "03020105"
    },
    {
      name: {
        km: "ច្រណោម",
        latin: "Chranaom"
      },
      id: "03020106"
    },
    {
      name: {
        km: "ប្រសើរ",
        latin: "Prasaeur"
      },
      id: "03020107"
    },
    {
      name: {
        km: "១០មករា",
        latin: "Dab Meakkakra"
      },
      id: "03020108"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "03020109"
    },
    {
      name: {
        km: "ចំការកប្បាស",
        latin: "Chamkar Kabbas"
      },
      id: "03020110"
    },
    {
      name: {
        km: "៣៥",
        latin: "Samsebpram"
      },
      id: "03020111"
    },
    {
      name: {
        km: "ចំការអណ្ដូង",
        latin: "Chamkar Andoung"
      },
      id: "03020201"
    },
    {
      name: {
        km: "សូរជៃ",
        latin: "Souchey"
      },
      id: "03020202"
    },
    {
      name: {
        km: "ស្វាយជួរ",
        latin: "Svay Chuor"
      },
      id: "03020203"
    },
    {
      name: {
        km: "អូរក្រវ៉ាន់",
        latin: "Ou Kravan"
      },
      id: "03020204"
    },
    {
      name: {
        km: "ដូនបុស្ស",
        latin: "Doun Bos"
      },
      id: "03020205"
    },
    {
      name: {
        km: "ជាំជ្រៃ",
        latin: "Choam Chrey"
      },
      id: "03020206"
    },
    {
      name: {
        km: "ភូមិលេខ ២",
        latin: "Phum Lekh Pir"
      },
      id: "03020208"
    },
    {
      name: {
        km: "ភូមិ១១",
        latin: "Phum Dab Muoy"
      },
      id: "03020209"
    },
    {
      name: {
        km: "ភូមិ៣៣",
        latin: "Phum Sam Bei"
      },
      id: "03020210"
    },
    {
      name: {
        km: "ភូមិ២២",
        latin: "Phum Mphey Pir"
      },
      id: "03020211"
    },
    {
      name: {
        km: "តាប្រ៉ុក",
        latin: "Ta Prok"
      },
      id: "03020212"
    },
    {
      name: {
        km: "ភូមិលេខ ១",
        latin: "Phum Lekh Muoy"
      },
      id: "03020213"
    },
    {
      name: {
        km: "សហគ្រាស",
        latin: "Sahak Kreas"
      },
      id: "03020214"
    },
    {
      name: {
        km: "សហគ្រាន់",
        latin: "Sahak Kroan"
      },
      id: "03020215"
    },
    {
      name: {
        km: "ស្រោងចាន់",
        latin: "Sraong Chan"
      },
      id: "03020216"
    },
    {
      name: {
        km: "រោងចក្រ",
        latin: "Roung Chakr"
      },
      id: "03020217"
    },
    {
      name: {
        km: "ក្រុមហ៊ុន",
        latin: "Kromhun"
      },
      id: "03020218"
    },
    {
      name: {
        km: "ស្ពឺ ក",
        latin: "Spueu Ka"
      },
      id: "03020301"
    },
    {
      name: {
        km: "ជយោ",
        latin: "Cheyyou"
      },
      id: "03020302"
    },
    {
      name: {
        km: "អូរប៉ិះ",
        latin: "Ou Peh"
      },
      id: "03020303"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "03020304"
    },
    {
      name: {
        km: "ត្រពាំងល្ពៅ",
        latin: "Trapeang Lpov"
      },
      id: "03020305"
    },
    {
      name: {
        km: "ក្បាលហុងថ្មី",
        latin: "Kbal Hong Thmei"
      },
      id: "03020401"
    },
    {
      name: {
        km: "ក្បាលហុងចាស់",
        latin: "Kbal Hong Chas"
      },
      id: "03020402"
    },
    {
      name: {
        km: "ក្រឡែងកើត",
        latin: "Kralaeng Kaeut"
      },
      id: "03020403"
    },
    {
      name: {
        km: "ក្រឡែងលិច",
        latin: "Kralaeng Lech"
      },
      id: "03020404"
    },
    {
      name: {
        km: "ល្វាជើង",
        latin: "Lvea Cheung"
      },
      id: "03020405"
    },
    {
      name: {
        km: "ល្វាត្បូង",
        latin: "Lvea Tboung"
      },
      id: "03020406"
    },
    {
      name: {
        km: "ភូមិ បី",
        latin: "Phum Bei"
      },
      id: "03020407"
    },
    {
      name: {
        km: "បន្ទាយជ័យ",
        latin: "Banteay Chey"
      },
      id: "03020501"
    },
    {
      name: {
        km: "ពព្រេង",
        latin: "Popreng"
      },
      id: "03020502"
    },
    {
      name: {
        km: "អូរវាយ",
        latin: "Ou Veay"
      },
      id: "03020503"
    },
    {
      name: {
        km: "ពែងមាសជើង",
        latin: "Peaeng Meas Cheung"
      },
      id: "03020504"
    },
    {
      name: {
        km: "ពែងមាសត្បូង",
        latin: "Peaeng Meas Tboung"
      },
      id: "03020505"
    },
    {
      name: {
        km: "ស្ពឺលិច",
        latin: "Spueu Lech"
      },
      id: "03020506"
    },
    {
      name: {
        km: "ស្ពឺកើត",
        latin: "Spueu Kaeut"
      },
      id: "03020507"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "03020508"
    },
    {
      name: {
        km: "ត្រពាំងបេង",
        latin: "Trapeang Beng"
      },
      id: "03020601"
    },
    {
      name: {
        km: "ប្រម៉ាត់ដី",
        latin: "Pramat Dei"
      },
      id: "03020602"
    },
    {
      name: {
        km: "វាលរីលិច",
        latin: "Veal Ri Lech"
      },
      id: "03020603"
    },
    {
      name: {
        km: "ស្វាយទាប",
        latin: "Svay Teab"
      },
      id: "03020604"
    },
    {
      name: {
        km: "ព្រឹក្ស",
        latin: "Proeks"
      },
      id: "03020605"
    },
    {
      name: {
        km: "ថ្នល់បែកលិច",
        latin: "Thnal Baek Lech"
      },
      id: "03020606"
    },
    {
      name: {
        km: "តាំងក្រង់",
        latin: "Tang Krang"
      },
      id: "03020607"
    },
    {
      name: {
        km: "មហា",
        latin: "Moha"
      },
      id: "03020608"
    },
    {
      name: {
        km: "បុសថ្លាន់",
        latin: "Bos Thlan"
      },
      id: "03020609"
    },
    {
      name: {
        km: "ស្រែព្រាល",
        latin: "Srae Preal"
      },
      id: "03020610"
    },
    {
      name: {
        km: "អូរដា",
        latin: "Ou Da"
      },
      id: "03020611"
    },
    {
      name: {
        km: "ថ្នល់បែកកើត",
        latin: "Thnal Baek Kaeut"
      },
      id: "03020612"
    },
    {
      name: {
        km: "វាលរីកើត",
        latin: "Veal Ri Kaeut"
      },
      id: "03020613"
    },
    {
      name: {
        km: "ភូមិ៧៧",
        latin: "Phum Chetseb Prampir"
      },
      id: "03020614"
    },
    {
      name: {
        km: "តាអុង",
        latin: "Ta Ong"
      },
      id: "03020701"
    },
    {
      name: {
        km: "សំពរ័",
        latin: "Sampoar"
      },
      id: "03020702"
    },
    {
      name: {
        km: "ទួលប្រាក់",
        latin: "Tuol Prak"
      },
      id: "03020703"
    },
    {
      name: {
        km: "ទួលមាស",
        latin: "Tuol Meas"
      },
      id: "03020704"
    },
    {
      name: {
        km: "ទួលប៉ែន",
        latin: "Tuol Paen"
      },
      id: "03020705"
    },
    {
      name: {
        km: "ចំរើនផល",
        latin: "Chamraeun Phal"
      },
      id: "03020706"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "03020707"
    },
    {
      name: {
        km: "ទួលស្រូវ",
        latin: "Tuol Srov"
      },
      id: "03020708"
    },
    {
      name: {
        km: "ភូមិ ៣០",
        latin: "Phum Samseb"
      },
      id: "03020709"
    },
    {
      name: {
        km: "ភូមិ ៣១",
        latin: "Phum Sammuoy"
      },
      id: "03020710"
    },
    {
      name: {
        km: "ភូមិ ៣២",
        latin: "Phum Sampir"
      },
      id: "03020711"
    },
    {
      name: {
        km: "ភូមិ ៣៣",
        latin: "Phum Sambei"
      },
      id: "03020712"
    },
    {
      name: {
        km: "ភូមិ ៣៤",
        latin: "Phum Sambuon"
      },
      id: "03020713"
    },
    {
      name: {
        km: "ស្រែប្រាំង",
        latin: "Srae Prang"
      },
      id: "03020801"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "03020802"
    },
    {
      name: {
        km: "នាងលើង",
        latin: "Neang Leung"
      },
      id: "03020803"
    },
    {
      name: {
        km: "ស្វាយទាប",
        latin: "Svay Teab"
      },
      id: "03020804"
    },
    {
      name: {
        km: "អូរតាសែង",
        latin: "Ou Ta Saeng"
      },
      id: "03020805"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "03020806"
    },
    {
      name: {
        km: "ផ្លាក",
        latin: "Phlak"
      },
      id: "03020807"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "03030101"
    },
    {
      name: {
        km: "ខ្នុរដំបង",
        latin: "Khnor Dambang"
      },
      id: "03030102"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "03030103"
    },
    {
      name: {
        km: "គោករវៀង",
        latin: "Kouk Rovieng"
      },
      id: "03030201"
    },
    {
      name: {
        km: "ទទោល",
        latin: "Totoul"
      },
      id: "03030202"
    },
    {
      name: {
        km: "បាគ្រង",
        latin: "Ba Krong"
      },
      id: "03030203"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "03030204"
    },
    {
      name: {
        km: "ផ្ដៅជុំលិច",
        latin: "Phdau Chum Lech"
      },
      id: "03030301"
    },
    {
      name: {
        km: "ផ្ដៅជុំកើត",
        latin: "Phdau Chum Kaeut"
      },
      id: "03030302"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "03030303"
    },
    {
      name: {
        km: "ចាំនាង",
        latin: "Cham Neang"
      },
      id: "03030304"
    },
    {
      name: {
        km: "ពៅ្នលិច",
        latin: "Pnov Lech"
      },
      id: "03030401"
    },
    {
      name: {
        km: "ពៅ្នកើត",
        latin: "Pnov Kaeut"
      },
      id: "03030402"
    },
    {
      name: {
        km: "ព្រៃចារក្រៅ",
        latin: "Prey Char Krau"
      },
      id: "03030403"
    },
    {
      name: {
        km: "សៀមបោយ",
        latin: "Siem Baoy"
      },
      id: "03030404"
    },
    {
      name: {
        km: "បាទី",
        latin: "Bati"
      },
      id: "03030405"
    },
    {
      name: {
        km: "ព្រៃចារក្នុង",
        latin: "Prey Char Knong"
      },
      id: "03030406"
    },
    {
      name: {
        km: "ព្រីងជ្រុំ",
        latin: "Pring Chrum"
      },
      id: "03030501"
    },
    {
      name: {
        km: "ត្រពាំងទឹម",
        latin: "Trapeang Tuem"
      },
      id: "03030502"
    },
    {
      name: {
        km: "ត្រពាំងផ្អាវ",
        latin: "Trapeang Ph'av"
      },
      id: "03030503"
    },
    {
      name: {
        km: "តានី",
        latin: "Ta Ni"
      },
      id: "03030504"
    },
    {
      name: {
        km: "កោះចំប៉ា",
        latin: "Kaoh Champa"
      },
      id: "03030505"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "03030601"
    },
    {
      name: {
        km: "ត្រពាំងស្លា",
        latin: "Trapeang Sla"
      },
      id: "03030602"
    },
    {
      name: {
        km: "ត្រពាំងត្រុំ",
        latin: "Trapeang Trom"
      },
      id: "03030603"
    },
    {
      name: {
        km: "អំពិលទ្វារ",
        latin: "Ampil Tvear"
      },
      id: "03030604"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "03030605"
    },
    {
      name: {
        km: "បុស្សតាមុំ",
        latin: "Bos Ta Mom"
      },
      id: "03030606"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "03030607"
    },
    {
      name: {
        km: "ពោធិ",
        latin: "Pou"
      },
      id: "03030608"
    },
    {
      name: {
        km: "បាខម",
        latin: "Bakham"
      },
      id: "03030609"
    },
    {
      name: {
        km: "សំពងជ័យ",
        latin: "Sampong Chey"
      },
      id: "03030610"
    },
    {
      name: {
        km: "ស្វាយមាស",
        latin: "Svay Meas"
      },
      id: "03030611"
    },
    {
      name: {
        km: "កុមារ",
        latin: "Komar"
      },
      id: "03030612"
    },
    {
      name: {
        km: "ដូន្ដោ",
        latin: "Doun Tao"
      },
      id: "03030613"
    },
    {
      name: {
        km: "សណ្ដែក",
        latin: "Sandaek"
      },
      id: "03030614"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "03030701"
    },
    {
      name: {
        km: "ស្ដើងជ័យ",
        latin: "Sdaeung Chey"
      },
      id: "03030702"
    },
    {
      name: {
        km: "ប្របឹង",
        latin: "Pra Boeng"
      },
      id: "03030703"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "03030704"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "03030705"
    },
    {
      name: {
        km: "ក្ដុយ",
        latin: "Kdoy"
      },
      id: "03030706"
    },
    {
      name: {
        km: "ដំណាក់អំពិល",
        latin: "Damnak Ampil"
      },
      id: "03030707"
    },
    {
      name: {
        km: "ប៉ាណា",
        latin: "Pana"
      },
      id: "03030801"
    },
    {
      name: {
        km: "ស្គន់",
        latin: "Skon"
      },
      id: "03030802"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03030803"
    },
    {
      name: {
        km: "តាសែន",
        latin: "Ta Saen"
      },
      id: "03030804"
    },
    {
      name: {
        km: "ង៉ូង",
        latin: "Ngoung"
      },
      id: "03030805"
    },
    {
      name: {
        km: "ដូនដុំ",
        latin: "Doun Dom"
      },
      id: "03030806"
    },
    {
      name: {
        km: "បឹងជ្រោយ",
        latin: "Boeng Chrouy"
      },
      id: "03030807"
    },
    {
      name: {
        km: "សូទិព្វ",
        latin: "Soutib"
      },
      id: "03030808"
    },
    {
      name: {
        km: "ស្រាមលិច",
        latin: "Sram Lech"
      },
      id: "03030901"
    },
    {
      name: {
        km: "ស្រាមកើត",
        latin: "Sram Kaeut"
      },
      id: "03030902"
    },
    {
      name: {
        km: "ស្រាមជើង",
        latin: "Sram Cheung"
      },
      id: "03030903"
    },
    {
      name: {
        km: "ភូមិថ្មី",
        latin: "PhumThmei"
      },
      id: "03030904"
    },
    {
      name: {
        km: "ស្រម៉រ",
        latin: "Sramar"
      },
      id: "03030905"
    },
    {
      name: {
        km: "សង្កែពង",
        latin: "Sangkae Pong"
      },
      id: "03030906"
    },
    {
      name: {
        km: "ប្រខ្នោរ",
        latin: "Pra Khnaor"
      },
      id: "03030907"
    },
    {
      name: {
        km: "ក្រឡាញ់",
        latin: "Kralanh"
      },
      id: "03030908"
    },
    {
      name: {
        km: "ប្រធាតុ",
        latin: "Pratheat"
      },
      id: "03030909"
    },
    {
      name: {
        km: "ត្រពាំងត្មាត",
        latin: "Trapeang Tmat"
      },
      id: "03030910"
    },
    {
      name: {
        km: "ស្រាមអង្កាម",
        latin: "Sram Angkam"
      },
      id: "03030911"
    },
    {
      name: {
        km: "អណ្ដូងត្រាំង",
        latin: "Andoung Treang"
      },
      id: "03030912"
    },
    {
      name: {
        km: "ត្រពាំងស្នោ",
        latin: "Trapeang Snao"
      },
      id: "03030913"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "03031001"
    },
    {
      name: {
        km: "ស្អាង",
        latin: "S'ang"
      },
      id: "03031002"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "03031003"
    },
    {
      name: {
        km: "ប្រទេន",
        latin: "Praten"
      },
      id: "03031004"
    },
    {
      name: {
        km: "ទម្ពរ",
        latin: "Tumpor"
      },
      id: "03031005"
    },
    {
      name: {
        km: "ត្នោតបាក់",
        latin: "Tnaot Bak"
      },
      id: "03031006"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "03031007"
    },
    {
      name: {
        km: "អារក្ស",
        latin: "Areaks"
      },
      id: "03031008"
    },
    {
      name: {
        km: "ត្រពាំងគរ",
        latin: "Trapeang Kor"
      },
      id: "03031009"
    },
    {
      name: {
        km: "ក្ងោក",
        latin: "Kngaok"
      },
      id: "03031010"
    },
    {
      name: {
        km: "បឹងកុកទី ១",
        latin: "Boeng Kok Ti Muoy"
      },
      id: "03050101"
    },
    {
      name: {
        km: "បឹងកុកទី ២",
        latin: "Boeng Kok Ti Pir"
      },
      id: "03050102"
    },
    {
      name: {
        km: "ចុងថ្នល់ ទី១",
        latin: "Chong Thnal Ti Muoy"
      },
      id: "03050103"
    },
    {
      name: {
        km: "ចុងថ្នល់ ទី២",
        latin: "Chong Thnal Ti Pir"
      },
      id: "03050104"
    },
    {
      name: {
        km: "ឡឥដ្ឋ",
        latin: "La Edth"
      },
      id: "03050105"
    },
    {
      name: {
        km: "ជ្រោយថ្ម",
        latin: "Chrouy Thma"
      },
      id: "03050106"
    },
    {
      name: {
        km: "មេម៉ាយ",
        latin: "Memay"
      },
      id: "03050107"
    },
    {
      name: {
        km: "ភូមិ ទី ៧",
        latin: "Phum Ti Prampir"
      },
      id: "03050201"
    },
    {
      name: {
        km: "ភូមិ ទី ៨",
        latin: "Phum Ti Prambei"
      },
      id: "03050202"
    },
    {
      name: {
        km: "ភូមិ ទី ៩",
        latin: "Phum Ti Prambuon"
      },
      id: "03050203"
    },
    {
      name: {
        km: "ភូមិ ទី ១០",
        latin: "Phum Ti Dab"
      },
      id: "03050204"
    },
    {
      name: {
        km: "ភូមិ ទី ១១",
        latin: "Phum Ti Dabmuoy"
      },
      id: "03050205"
    },
    {
      name: {
        km: "ភូមិ ទី ១២",
        latin: "Phum Ti Dabpir"
      },
      id: "03050206"
    },
    {
      name: {
        km: "ភូមិ ទី ១៣",
        latin: "Phum Ti Dabbei"
      },
      id: "03050207"
    },
    {
      name: {
        km: "ភូមិ ទី ១៤",
        latin: "Phum Ti Dabbuon"
      },
      id: "03050208"
    },
    {
      name: {
        km: "ភូមិ ទី ១៥",
        latin: "Phum Ti Dabpram"
      },
      id: "03050209"
    },
    {
      name: {
        km: "រកាលើ",
        latin: "Roka Leu"
      },
      id: "03050301"
    },
    {
      name: {
        km: "រកាក្រោម",
        latin: "Roka Kraom"
      },
      id: "03050302"
    },
    {
      name: {
        km: "នាងគង្ហីង",
        latin: "Neang Konghing"
      },
      id: "03050303"
    },
    {
      name: {
        km: "បឹងបាសាក់",
        latin: "Boeng Basak"
      },
      id: "03050304"
    },
    {
      name: {
        km: "ចំការខ្ពប",
        latin: "Chamkar Khpob"
      },
      id: "03050305"
    },
    {
      name: {
        km: "បឹងស្នាយ",
        latin: "Boeng Snay"
      },
      id: "03050306"
    },
    {
      name: {
        km: "ព្រែកដើមចាន់",
        latin: "Preaek Daeum Chan"
      },
      id: "03050307"
    },
    {
      name: {
        km: "ព្រែកជីក",
        latin: "Preaek Chik"
      },
      id: "03050308"
    },
    {
      name: {
        km: "កំពង់រលីង",
        latin: "Kampong Roling"
      },
      id: "03050309"
    },
    {
      name: {
        km: "តាណេង",
        latin: "Ta Neng"
      },
      id: "03050310"
    },
    {
      name: {
        km: "ភូមិ ទី ១",
        latin: "Phum Ti Muoy"
      },
      id: "03050401"
    },
    {
      name: {
        km: "ភូមិ ទី ២",
        latin: "Phum Ti Pir"
      },
      id: "03050402"
    },
    {
      name: {
        km: "ភូមិ ទី ៣",
        latin: "Phum Ti Bei"
      },
      id: "03050403"
    },
    {
      name: {
        km: "ភូមិ ទី ៤",
        latin: "Phum Ti Buon"
      },
      id: "03050404"
    },
    {
      name: {
        km: "ភូមិ ទី ៥",
        latin: "Phum Ti Pram"
      },
      id: "03050405"
    },
    {
      name: {
        km: "ភូមិ ទី ៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "03050406"
    },
    {
      name: {
        km: "អណ្ដូងច្រុះ",
        latin: "Andoung Chroh"
      },
      id: "03060101"
    },
    {
      name: {
        km: "អំពិលលើ",
        latin: "Ampil Leu"
      },
      id: "03060102"
    },
    {
      name: {
        km: "អំពិលក្រោម",
        latin: "Ampil Kraom"
      },
      id: "03060103"
    },
    {
      name: {
        km: "ជើងគោក",
        latin: "Cheung Kouk"
      },
      id: "03060104"
    },
    {
      name: {
        km: "រលៀក",
        latin: "Roliek"
      },
      id: "03060105"
    },
    {
      name: {
        km: "រមាស",
        latin: "Romeas"
      },
      id: "03060106"
    },
    {
      name: {
        km: "បន្ទាយថ្ម",
        latin: "Banteay Thma"
      },
      id: "03060107"
    },
    {
      name: {
        km: "ស្រឡៅ",
        latin: "Sralau"
      },
      id: "03060108"
    },
    {
      name: {
        km: "ក្រឡា",
        latin: "Krala"
      },
      id: "03060109"
    },
    {
      name: {
        km: "ជង្ហុក",
        latin: "Chonghuk"
      },
      id: "03060110"
    },
    {
      name: {
        km: "វាលស្បូវ",
        latin: "Veal Sbov"
      },
      id: "03060111"
    },
    {
      name: {
        km: "ស្យា",
        latin: "Sya"
      },
      id: "03060112"
    },
    {
      name: {
        km: "ហាន់ជ័យ",
        latin: "Hanchey"
      },
      id: "03060201"
    },
    {
      name: {
        km: "ក្រូចសើច",
        latin: "Krouch Saeuch"
      },
      id: "03060202"
    },
    {
      name: {
        km: "ល្វាទេ",
        latin: "Lvea Te"
      },
      id: "03060203"
    },
    {
      name: {
        km: "មាន់ហើរ",
        latin: "Moan Haeur"
      },
      id: "03060204"
    },
    {
      name: {
        km: "ព្រែកយួន",
        latin: "Preaek Yuon"
      },
      id: "03060301"
    },
    {
      name: {
        km: "ឥន្ទនេល",
        latin: "Enteak Nel"
      },
      id: "03060302"
    },
    {
      name: {
        km: "ស្ពានថ្មី",
        latin: "Spean Thmei"
      },
      id: "03060303"
    },
    {
      name: {
        km: "បឹងបបុះ",
        latin: "Boeng Baboh"
      },
      id: "03060304"
    },
    {
      name: {
        km: "កៀនជ្រៃក្រៅ",
        latin: "Kien Chrey Krau"
      },
      id: "03060305"
    },
    {
      name: {
        km: "កៀនជ្រៃក្នុង",
        latin: "Kien Chrey Knong"
      },
      id: "03060306"
    },
    {
      name: {
        km: "គគរ១",
        latin: "Kokor Muoy"
      },
      id: "03060401"
    },
    {
      name: {
        km: "គគរ២",
        latin: "Kokor Pir"
      },
      id: "03060402"
    },
    {
      name: {
        km: "ចំការសាមសិប",
        latin: "Chamkar Samseb"
      },
      id: "03060403"
    },
    {
      name: {
        km: "កំពង់ក្របី",
        latin: "Kampong Krabei"
      },
      id: "03060404"
    },
    {
      name: {
        km: "កោះប៉ែន ក",
        latin: "Kaoh Paen Ka"
      },
      id: "03060501"
    },
    {
      name: {
        km: "កោះប៉ែន ខ",
        latin: "Kaoh Paen Kha"
      },
      id: "03060502"
    },
    {
      name: {
        km: "កោះប៉ែន គ",
        latin: "Kaoh Paen Kho"
      },
      id: "03060503"
    },
    {
      name: {
        km: "កោះដាច់",
        latin: "Kaoh Dach"
      },
      id: "03060505"
    },
    {
      name: {
        km: "កោះព្រលូង",
        latin: "Kaoh Prolung"
      },
      id: "03060506"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "03060507"
    },
    {
      name: {
        km: "កោះចាស់",
        latin: "Kaoh Chas"
      },
      id: "03060508"
    },
    {
      name: {
        km: "កំពង់ត្រុំក្រោម",
        latin: "Kampong Trom Kraom"
      },
      id: "03060509"
    },
    {
      name: {
        km: "កំពង់ត្រុំលើ",
        latin: "Kampong Trom Leu"
      },
      id: "03060510"
    },
    {
      name: {
        km: "កោះរកាក្រៅ",
        latin: "Kaoh Roka Krau"
      },
      id: "03060601"
    },
    {
      name: {
        km: "កោះរកាក្នុង",
        latin: "Kaoh Roka Knong"
      },
      id: "03060602"
    },
    {
      name: {
        km: "ដំបងដែក",
        latin: "Dambang Daek"
      },
      id: "03060603"
    },
    {
      name: {
        km: "អូរឈ្លើង",
        latin: "Ou Chhleung"
      },
      id: "03060604"
    },
    {
      name: {
        km: "តាមាង",
        latin: "Ta Meang"
      },
      id: "03060605"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03060606"
    },
    {
      name: {
        km: "កោះគល់",
        latin: "Kaoh Kol"
      },
      id: "03060607"
    },
    {
      name: {
        km: "ភូមិ ទី ១",
        latin: "Phum Ti Muoy"
      },
      id: "03060701"
    },
    {
      name: {
        km: "ភូមិ ទី ២",
        latin: "Phum Ti Pir"
      },
      id: "03060702"
    },
    {
      name: {
        km: "ភូមិ ទី ៣",
        latin: "Phum Ti Bei"
      },
      id: "03060703"
    },
    {
      name: {
        km: "ភូមិ ទី ៤",
        latin: "Phum Ti Buon"
      },
      id: "03060704"
    },
    {
      name: {
        km: "ភូមិ ទី ៥",
        latin: "Phum Ti Pram"
      },
      id: "03060705"
    },
    {
      name: {
        km: "ភូមិ ទី ៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "03060706"
    },
    {
      name: {
        km: "ភូមិ ទី ៧",
        latin: "Phum Ti Prampir"
      },
      id: "03060707"
    },
    {
      name: {
        km: "ភូមិ ទី ៨",
        latin: "Phum Ti Prambei"
      },
      id: "03060708"
    },
    {
      name: {
        km: "កោះកុក ក",
        latin: "Kaoh Kok Ka"
      },
      id: "03060801"
    },
    {
      name: {
        km: "កោះកុក ខ",
        latin: "Kaoh Kok Kha"
      },
      id: "03060802"
    },
    {
      name: {
        km: "កោះប្រាក់ក្រោម",
        latin: "Kaoh Prak Kraom"
      },
      id: "03060803"
    },
    {
      name: {
        km: "កោះប្រាក់ក្នុង",
        latin: "Kaoh Prak Knong"
      },
      id: "03060804"
    },
    {
      name: {
        km: "កោះប្រាក់លើ",
        latin: "Kaoh Prak Leu"
      },
      id: "03060805"
    },
    {
      name: {
        km: "ស្ដេចនន",
        latin: "Sdach Non"
      },
      id: "03060901"
    },
    {
      name: {
        km: "ទួលបេង",
        latin: "Tuol Beng"
      },
      id: "03060902"
    },
    {
      name: {
        km: "អណ្ដូងពោធិ៍",
        latin: "Andoung Pou"
      },
      id: "03060903"
    },
    {
      name: {
        km: "ទួលពពេល",
        latin: "Tuol Popel"
      },
      id: "03060904"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "03060905"
    },
    {
      name: {
        km: "ត្រពាំងត្រស់",
        latin: "Trapeang Tras"
      },
      id: "03060906"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "03060907"
    },
    {
      name: {
        km: "អង្គួញដី",
        latin: "Angkuonh Dei"
      },
      id: "03060908"
    },
    {
      name: {
        km: "អំពិលជ្រុំ",
        latin: "Ampil Chrum"
      },
      id: "03060909"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03060910"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "03060911"
    },
    {
      name: {
        km: "ត្រពាំងចារ",
        latin: "Trapeang Char"
      },
      id: "03060912"
    },
    {
      name: {
        km: "ត្រកួន",
        latin: "Trakuon"
      },
      id: "03060913"
    },
    {
      name: {
        km: "ព្រៃចក្រី",
        latin: "Prey Chakkrei"
      },
      id: "03061001"
    },
    {
      name: {
        km: "អន្លង់ស្នូក",
        latin: "Anlong Snouk"
      },
      id: "03061002"
    },
    {
      name: {
        km: "អំពិលក្រញ៉ាញ់",
        latin: "Ampil Kranhanh"
      },
      id: "03061003"
    },
    {
      name: {
        km: "អូរស្វាយ",
        latin: "Ou Svay"
      },
      id: "03061004"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "03061005"
    },
    {
      name: {
        km: "អូរធ្នង់",
        latin: "Ou Thnong"
      },
      id: "03061006"
    },
    {
      name: {
        km: "ខែលជ័យ",
        latin: "Khael Chey"
      },
      id: "03061007"
    },
    {
      name: {
        km: "ថ្មគោល",
        latin: "Thma Koul"
      },
      id: "03061101"
    },
    {
      name: {
        km: "វាលខ្សាច់",
        latin: "Veal Khsach"
      },
      id: "03061102"
    },
    {
      name: {
        km: "ប្រសំ",
        latin: "Prasam"
      },
      id: "03061103"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "03061104"
    },
    {
      name: {
        km: "រមូល",
        latin: "Romul"
      },
      id: "03061105"
    },
    {
      name: {
        km: "ទួលរកា",
        latin: "Tuol Roka"
      },
      id: "03061106"
    },
    {
      name: {
        km: "រអាងលើ",
        latin: "Ro'ang Leu"
      },
      id: "03061107"
    },
    {
      name: {
        km: "រអាងក្រោម",
        latin: "Ro'ang Kraom"
      },
      id: "03061108"
    },
    {
      name: {
        km: "ឈើទាលស្រុតលើ",
        latin: "Chheu Teal Srot Leu"
      },
      id: "03061201"
    },
    {
      name: {
        km: "ឈើទាលស្រុតក្រោម",
        latin: "Chheu Teal Srot Kraom"
      },
      id: "03061202"
    },
    {
      name: {
        km: "រំលេច",
        latin: "Rumlech"
      },
      id: "03061203"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03061204"
    },
    {
      name: {
        km: "ល្ពាក",
        latin: "Lpeak"
      },
      id: "03061301"
    },
    {
      name: {
        km: "ព្រៃគុយ",
        latin: "Prey Kuy"
      },
      id: "03061302"
    },
    {
      name: {
        km: "ស្រក",
        latin: "Srak"
      },
      id: "03061303"
    },
    {
      name: {
        km: "តាម៉ៅ",
        latin: "Ta Mau"
      },
      id: "03061304"
    },
    {
      name: {
        km: "ច្រកស្ដៅ",
        latin: "Chrak Sdau"
      },
      id: "03061401"
    },
    {
      name: {
        km: "វាលក្រៀល",
        latin: "Veal Kriel"
      },
      id: "03061402"
    },
    {
      name: {
        km: "ទ្រាន",
        latin: "Trean"
      },
      id: "03061403"
    },
    {
      name: {
        km: "ត្រពាំងតាសុខ",
        latin: "Trapeang Ta Sokh"
      },
      id: "03061404"
    },
    {
      name: {
        km: "ប្រថង",
        latin: "Prathang"
      },
      id: "03061405"
    },
    {
      name: {
        km: "ប៉ែន",
        latin: "Paen"
      },
      id: "03061406"
    },
    {
      name: {
        km: "ទួលចំបក់",
        latin: "Tuol Chambak"
      },
      id: "03061407"
    },
    {
      name: {
        km: "ច្រនៀង",
        latin: "Chranieng"
      },
      id: "03061408"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "03061409"
    },
    {
      name: {
        km: "តាខុង",
        latin: "Ta Khong"
      },
      id: "03061410"
    },
    {
      name: {
        km: "ទួលត្រាច",
        latin: "Tuol Trach"
      },
      id: "03061411"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "03061412"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "03061501"
    },
    {
      name: {
        km: "អណ្ដូងស្វាយ",
        latin: "Andoung Svay"
      },
      id: "03061502"
    },
    {
      name: {
        km: "គោកគ្រាម",
        latin: "Kouk Kream"
      },
      id: "03061503"
    },
    {
      name: {
        km: "ក្ដីបឹង",
        latin: "Kdei Boeng"
      },
      id: "03061504"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "03061505"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "03061506"
    },
    {
      name: {
        km: "គោកទទា",
        latin: "Kouk Totea"
      },
      id: "03061507"
    },
    {
      name: {
        km: "វិហារ",
        latin: "Vihear"
      },
      id: "03061508"
    },
    {
      name: {
        km: "គងមហា",
        latin: "Kong Moha"
      },
      id: "03061509"
    },
    {
      name: {
        km: "អង្គរបានទី ១",
        latin: "Angkor Ban Ti Muoy"
      },
      id: "03070101"
    },
    {
      name: {
        km: "អង្គរបានទី ២",
        latin: "Angkor Ban Ti Pir"
      },
      id: "03070102"
    },
    {
      name: {
        km: "អង្គរបានទី ៣",
        latin: "Angkor Ban Ti Bei"
      },
      id: "03070103"
    },
    {
      name: {
        km: "អង្គរបានទី ៤",
        latin: "Angkor Ban Ti Buon"
      },
      id: "03070104"
    },
    {
      name: {
        km: "អង្គរបានទី ៥",
        latin: "Angkor Ban Ti Pram"
      },
      id: "03070105"
    },
    {
      name: {
        km: "អង្គរបានទី ៦",
        latin: "Angkor Ban Ti Prammuoy"
      },
      id: "03070106"
    },
    {
      name: {
        km: "អង្គរបានទី ៧",
        latin: "Angkor Ban Ti Prampir"
      },
      id: "03070107"
    },
    {
      name: {
        km: "អង្គរបានទី ៨",
        latin: "Angkor Ban Ti Prambei"
      },
      id: "03070108"
    },
    {
      name: {
        km: "អង្គរបានទី ៩",
        latin: "Angkor Ban Ti Prambuon"
      },
      id: "03070109"
    },
    {
      name: {
        km: "កងតាណឹងទី ១",
        latin: "Kang Ta Noeng Ti Muoy"
      },
      id: "03070201"
    },
    {
      name: {
        km: "កងតាណឹងទី ២",
        latin: "Kang Ta Noeng Ti Pir"
      },
      id: "03070202"
    },
    {
      name: {
        km: "កងតាណឹងទី ៣",
        latin: "Kang Ta Noeng Ti Bei"
      },
      id: "03070203"
    },
    {
      name: {
        km: "កងតាណឹងទី ៤",
        latin: "Kang Ta Noeng Ti Buon"
      },
      id: "03070204"
    },
    {
      name: {
        km: "កងតាណឹងទី ៥",
        latin: "Kang Ta Noeng Ti Pram"
      },
      id: "03070205"
    },
    {
      name: {
        km: "កងតាណឹងទី ៦",
        latin: "Kang Ta Noeng Ti Prammuoy"
      },
      id: "03070206"
    },
    {
      name: {
        km: "កងតាណឹងទី ៧",
        latin: "Kang Ta Noeng Ti Prampir"
      },
      id: "03070207"
    },
    {
      name: {
        km: "កងតាណឹងទី ៨",
        latin: "Kang Ta Noeng Ti Prambei"
      },
      id: "03070208"
    },
    {
      name: {
        km: "កងតាណឹងទី ៩",
        latin: "Kang Ta Noeng Ti Prambuon"
      },
      id: "03070209"
    },
    {
      name: {
        km: "ថ្លុកជ្រៅ",
        latin: "Thlok Chrov"
      },
      id: "03070301"
    },
    {
      name: {
        km: "ស្វាយពាន់ទី ១",
        latin: "Svay Poan Ti Muoy"
      },
      id: "03070302"
    },
    {
      name: {
        km: "ស្វាយពាន់ទី ២",
        latin: "Svay Poan Ti Pir"
      },
      id: "03070303"
    },
    {
      name: {
        km: "អូពពេល",
        latin: "Ou Popel"
      },
      id: "03070304"
    },
    {
      name: {
        km: "ខ្ចៅទី ១",
        latin: "Khchau Ti Muoy"
      },
      id: "03070305"
    },
    {
      name: {
        km: "ខ្ចៅទី ២",
        latin: "Khchau Ti Pir"
      },
      id: "03070306"
    },
    {
      name: {
        km: "ខ្ចៅទី ៣",
        latin: "Khchau Ti Bei"
      },
      id: "03070307"
    },
    {
      name: {
        km: "វ៉ារិន្ទទី ១",
        latin: "Varint Ti Muoy"
      },
      id: "03070308"
    },
    {
      name: {
        km: "វ៉ារិន្ទទី ២",
        latin: "Varint Ti Pir"
      },
      id: "03070309"
    },
    {
      name: {
        km: "វ៉ារិន្ទទី ៣",
        latin: "Varint Ti Bei"
      },
      id: "03070310"
    },
    {
      name: {
        km: "ដំណាក់ជ្រៃ",
        latin: "Damnak Chrey"
      },
      id: "03070401"
    },
    {
      name: {
        km: "ដំណាក់ស្នាយ",
        latin: "Damnak Snay"
      },
      id: "03070402"
    },
    {
      name: {
        km: "ពាមជីកង",
        latin: "Peam Chi Kang"
      },
      id: "03070403"
    },
    {
      name: {
        km: "សាច់សូរ",
        latin: "Sach Sour"
      },
      id: "03070404"
    },
    {
      name: {
        km: "សំបួរមាស ក",
        latin: "Sambuor Meas Ka"
      },
      id: "03070405"
    },
    {
      name: {
        km: "សំបួរមាស ខ",
        latin: "Sambuor Meas Kha"
      },
      id: "03070406"
    },
    {
      name: {
        km: "កោះតូច",
        latin: "Kaoh Touch"
      },
      id: "03070407"
    },
    {
      name: {
        km: "ព្រែកកុយ",
        latin: "Preaek Koy"
      },
      id: "03070501"
    },
    {
      name: {
        km: "អន្លង់គគី",
        latin: "Anlong Kokir"
      },
      id: "03070502"
    },
    {
      name: {
        km: "គងជ័យ",
        latin: "Kong Chey"
      },
      id: "03070503"
    },
    {
      name: {
        km: "គហ៊េរ",
        latin: "Koher"
      },
      id: "03070504"
    },
    {
      name: {
        km: "មេសរ",
        latin: "Me Sar"
      },
      id: "03070505"
    },
    {
      name: {
        km: "អូស្វាយលិច",
        latin: "Ou Svay Lech"
      },
      id: "03070506"
    },
    {
      name: {
        km: "អូស្វាយកើត",
        latin: "Ou Svay Kaeut"
      },
      id: "03070507"
    },
    {
      name: {
        km: "ពោធិសាលាទី ១",
        latin: "Pou Sala Ti Muoy"
      },
      id: "03070601"
    },
    {
      name: {
        km: "ពោធិសាលាទី ២",
        latin: "Pou Sala Ti Pir"
      },
      id: "03070602"
    },
    {
      name: {
        km: "ពាមក្នុង",
        latin: "Peam Knong"
      },
      id: "03070603"
    },
    {
      name: {
        km: "ព្រែកអណ្ដូង",
        latin: "Preaek Andoung"
      },
      id: "03070604"
    },
    {
      name: {
        km: "ព្រែកក្របៅ",
        latin: "Preaek Krabau"
      },
      id: "03070605"
    },
    {
      name: {
        km: "អូរកណ្ដោល",
        latin: "Ou Kandaol"
      },
      id: "03070606"
    },
    {
      name: {
        km: "អណ្ដូងតាអុង",
        latin: "Andoung Ta Ong"
      },
      id: "03070607"
    },
    {
      name: {
        km: "អណ្ដូងដៃ",
        latin: "Andoung Dai"
      },
      id: "03070608"
    },
    {
      name: {
        km: "ទឹកចេញ",
        latin: "Tuek Chenh"
      },
      id: "03070609"
    },
    {
      name: {
        km: "ចំការឪឡឹក",
        latin: "Chamkar Ovloek"
      },
      id: "03070610"
    },
    {
      name: {
        km: "កន្លែងរុន",
        latin: "Kanlaeng Run"
      },
      id: "03070701"
    },
    {
      name: {
        km: "បឹងទទា",
        latin: "Boeng Totea"
      },
      id: "03070702"
    },
    {
      name: {
        km: "ទួលវិហារ",
        latin: "Tuol Vihear"
      },
      id: "03070703"
    },
    {
      name: {
        km: "ទួលបី",
        latin: "Tuol Bei"
      },
      id: "03070704"
    },
    {
      name: {
        km: "រាយប៉ាយលើ",
        latin: "Reay Pay Leu"
      },
      id: "03070705"
    },
    {
      name: {
        km: "រាយប៉ាយក្រោម",
        latin: "Reay Pay Kraom"
      },
      id: "03070706"
    },
    {
      name: {
        km: "ព្រែកប្រណាក",
        latin: "Preaek Pranak"
      },
      id: "03070707"
    },
    {
      name: {
        km: "គកក្របី",
        latin: "Kok Krabei"
      },
      id: "03070708"
    },
    {
      name: {
        km: "ព្រែកលីវទី ១",
        latin: "Preaek Liv Ti Muoy"
      },
      id: "03070801"
    },
    {
      name: {
        km: "ព្រែកលីវទី ២",
        latin: "Preaek Liv Ti Pir"
      },
      id: "03070802"
    },
    {
      name: {
        km: "ព្រែកលីវទី  ៣",
        latin: "Preaek Liv Ti Bei"
      },
      id: "03070803"
    },
    {
      name: {
        km: "ព្រែកលីវទី ៤",
        latin: "Preaek Liv Ti Buon"
      },
      id: "03070804"
    },
    {
      name: {
        km: "ជ្រោយក្របៅទី ១",
        latin: "Chrouy Krabau Ti Muoy"
      },
      id: "03070805"
    },
    {
      name: {
        km: "ជ្រោយក្របៅទី ២",
        latin: "Chrouy Krabau Ti Pir"
      },
      id: "03070806"
    },
    {
      name: {
        km: "ស្វាយស្រណោះទី ១",
        latin: "Svay Sranaoh Ti Muoy"
      },
      id: "03070807"
    },
    {
      name: {
        km: "ស្វាយស្រណោះទី ២",
        latin: "Svay Sranaoh Ti Pir"
      },
      id: "03070808"
    },
    {
      name: {
        km: "រកាអារ",
        latin: "Roka Ar"
      },
      id: "03070809"
    },
    {
      name: {
        km: "រកាគយ ក",
        latin: "Roka Koy Ka"
      },
      id: "03070901"
    },
    {
      name: {
        km: "រកាគយ ខ",
        latin: "Roka Koy Kha"
      },
      id: "03070902"
    },
    {
      name: {
        km: "ភូមិថ្មី ក",
        latin: "Phum Thmei Ka"
      },
      id: "03070903"
    },
    {
      name: {
        km: "ភូមិថ្មី ខ",
        latin: "PhumThmei Kha"
      },
      id: "03070904"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "03070905"
    },
    {
      name: {
        km: "ស្វាយតាហែន",
        latin: "Svay Ta Haen"
      },
      id: "03070906"
    },
    {
      name: {
        km: "ដំណាក់ល្អិត",
        latin: "Damnak L'et"
      },
      id: "03070907"
    },
    {
      name: {
        km: "ខ្ពបលើ",
        latin: "Khpob Leu"
      },
      id: "03071001"
    },
    {
      name: {
        km: "ខ្ពបក្រោម",
        latin: "Khpob Kraom"
      },
      id: "03071002"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "03071003"
    },
    {
      name: {
        km: "ល្វាលើ",
        latin: "Lvea Leu"
      },
      id: "03071004"
    },
    {
      name: {
        km: "ល្វាក្រោម",
        latin: "Lvea Kraom"
      },
      id: "03071005"
    },
    {
      name: {
        km: "អន្លង់គគីរ",
        latin: "Anlong Kokir"
      },
      id: "03071006"
    },
    {
      name: {
        km: "កោះតាង៉ោទី ១",
        latin: "Kaoh Ta Ngao Ti Muoy"
      },
      id: "03071101"
    },
    {
      name: {
        km: "កោះតាង៉ោទី ២",
        latin: "Kaoh Ta Ngao Ti Pir"
      },
      id: "03071102"
    },
    {
      name: {
        km: "កោះតាង៉ោទី ៣",
        latin: "Kaoh Ta Ngao Ti Bei"
      },
      id: "03071103"
    },
    {
      name: {
        km: "បឹងសាងកើត",
        latin: "Boeng Sang Kaeut"
      },
      id: "03071104"
    },
    {
      name: {
        km: "បឹងសាងលិច",
        latin: "Boeng Sang Lech"
      },
      id: "03071105"
    },
    {
      name: {
        km: "ក្ដី",
        latin: "Kdei"
      },
      id: "03071106"
    },
    {
      name: {
        km: "សូរគេន",
        latin: "Sourken"
      },
      id: "03071107"
    },
    {
      name: {
        km: "បឹងត្រាវ",
        latin: "Boeng Trav"
      },
      id: "03071108"
    },
    {
      name: {
        km: "ព្រែកគ្រួស",
        latin: "Preaek Kruos"
      },
      id: "03071109"
    },
    {
      name: {
        km: "អន្លង់អកលិច",
        latin: "Anlong Ak Lech"
      },
      id: "03071110"
    },
    {
      name: {
        km: "អន្លង់អកកើត",
        latin: "Anlong Ak Kaeut"
      },
      id: "03071111"
    },
    {
      name: {
        km: "កោះចិនក្រោម",
        latin: "Kaoh Chen Kraom"
      },
      id: "03080101"
    },
    {
      name: {
        km: "កោះចិនលើ",
        latin: "Kaoh Chen Leu"
      },
      id: "03080102"
    },
    {
      name: {
        km: "កំពង់រាបលើ",
        latin: "Kampong Reab Leu"
      },
      id: "03080103"
    },
    {
      name: {
        km: "កំពង់រាបក្រោម",
        latin: "Kampong Reab Kraom"
      },
      id: "03080104"
    },
    {
      name: {
        km: "កំពង់ស្ដីលើ",
        latin: "Kampong Sdei Leu"
      },
      id: "03080105"
    },
    {
      name: {
        km: "កំពង់ស្ដីក្រោម",
        latin: "Kampong Sdei Kraom"
      },
      id: "03080106"
    },
    {
      name: {
        km: "កំពង់ស្ដីកណ្ដាល",
        latin: "Kampong Sdei Kandal"
      },
      id: "03080107"
    },
    {
      name: {
        km: "កំពង់ស្ដីក្នុង",
        latin: "Kampong Sdei Knong"
      },
      id: "03080108"
    },
    {
      name: {
        km: "ភូមិទី១",
        latin: "Phum Ti Muoy"
      },
      id: "03080201"
    },
    {
      name: {
        km: "ភូមិទី២",
        latin: "Phum Ti Pir"
      },
      id: "03080202"
    },
    {
      name: {
        km: "ភូមិទី៣",
        latin: "Phum Ti Bei"
      },
      id: "03080203"
    },
    {
      name: {
        km: "ភូមិទី៤",
        latin: "Phum Ti Buon"
      },
      id: "03080204"
    },
    {
      name: {
        km: "ភូមិទី៥",
        latin: "Phum Ti Pram"
      },
      id: "03080205"
    },
    {
      name: {
        km: "ភូមិទី៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "03080206"
    },
    {
      name: {
        km: "ភូមិទី៧",
        latin: "Phum Ti Prampir"
      },
      id: "03080207"
    },
    {
      name: {
        km: "ភូមិទី៨",
        latin: "Phum Ti Prambei"
      },
      id: "03080208"
    },
    {
      name: {
        km: "ភូមិទី៩",
        latin: "Phum Ti Prambuon"
      },
      id: "03080209"
    },
    {
      name: {
        km: "ភូមិទី១០",
        latin: "Phum Ti Dab"
      },
      id: "03080210"
    },
    {
      name: {
        km: "ភូមិទី១១",
        latin: "Phum Ti Dabmuoy"
      },
      id: "03080211"
    },
    {
      name: {
        km: "ភូមិទី១២",
        latin: "Phum Ti Dabpir"
      },
      id: "03080212"
    },
    {
      name: {
        km: "ភូមិទី១៣",
        latin: "Phum Ti Dabbei"
      },
      id: "03080213"
    },
    {
      name: {
        km: "ភូមិទី៎១៤",
        latin: "Phum Ti Dabbuon"
      },
      id: "03080214"
    },
    {
      name: {
        km: "ដំណាក់ស្វាយ",
        latin: "Damnak Svay"
      },
      id: "03080301"
    },
    {
      name: {
        km: "រកាកោង",
        latin: "Roka Kaong"
      },
      id: "03080302"
    },
    {
      name: {
        km: "ព្រែកគល់",
        latin: "Preaek Kol"
      },
      id: "03080303"
    },
    {
      name: {
        km: "បទស្រីទន្ទឹង",
        latin: "Bat Srei Tontueng"
      },
      id: "03080304"
    },
    {
      name: {
        km: "អំបែងចេះ",
        latin: "Ambaeng Cheh"
      },
      id: "03080305"
    },
    {
      name: {
        km: "ល្វេលើ",
        latin: "Lve Leu"
      },
      id: "03080306"
    },
    {
      name: {
        km: "ល្វេក្រោម",
        latin: "Lve Kraom"
      },
      id: "03080307"
    },
    {
      name: {
        km: "ទំពូង",
        latin: "Tumpoung"
      },
      id: "03080308"
    },
    {
      name: {
        km: "ព្រែកតាកែ",
        latin: "Preaek Ta Kae"
      },
      id: "03080309"
    },
    {
      name: {
        km: "ព្រែកចង្ក្រាន",
        latin: "Preaek Changkran"
      },
      id: "03080310"
    },
    {
      name: {
        km: "ចុងព្រែក",
        latin: "Chong Preaek"
      },
      id: "03080401"
    },
    {
      name: {
        km: "មហាលាភជើង",
        latin: "Moha Leaph Cheung"
      },
      id: "03080402"
    },
    {
      name: {
        km: "មហាលាភត្បូង",
        latin: "Moha Leaph Tboung"
      },
      id: "03080403"
    },
    {
      name: {
        km: "រកាកោងត្បូង",
        latin: "Roka Kaong Tboung"
      },
      id: "03080404"
    },
    {
      name: {
        km: "រកាកោងជើង",
        latin: "Roka Kaong Cheung"
      },
      id: "03080405"
    },
    {
      name: {
        km: "ដំណាក់ព្រីងកើត",
        latin: "Damnak Pring Kaeut"
      },
      id: "03080406"
    },
    {
      name: {
        km: "ដំណាក់ព្រីងលិច",
        latin: "Damnak Pring Lech"
      },
      id: "03080407"
    },
    {
      name: {
        km: "រត្នមុនី",
        latin: "Roat Muni"
      },
      id: "03080408"
    },
    {
      name: {
        km: "ជ្រោយសសិត",
        latin: "Chrouy Saset"
      },
      id: "03080409"
    },
    {
      name: {
        km: "ព្រែក",
        latin: "Preaek"
      },
      id: "03080410"
    },
    {
      name: {
        km: "ខ្ពប",
        latin: "Khpob"
      },
      id: "03080501"
    },
    {
      name: {
        km: "មហាសៀកលើ",
        latin: "Mohasiek Leu"
      },
      id: "03080502"
    },
    {
      name: {
        km: "មហាសៀកក្រោម",
        latin: "Mohasiek Kraom"
      },
      id: "03080503"
    },
    {
      name: {
        km: "កំពង់ចម្លង",
        latin: "Kampong Chamlang"
      },
      id: "03080504"
    },
    {
      name: {
        km: "ខ្ញូងលើ",
        latin: "Khnhoung Leu"
      },
      id: "03080505"
    },
    {
      name: {
        km: "កណ្ដាលខ្ញូង",
        latin: "Kandal Khnhoung"
      },
      id: "03080506"
    },
    {
      name: {
        km: "ចុងខ្ញូង",
        latin: "Chong Khnhoung"
      },
      id: "03080507"
    },
    {
      name: {
        km: "អង្គរជ័យលើ",
        latin: "Angkor Chey Leu"
      },
      id: "03080508"
    },
    {
      name: {
        km: "អង្គរជ័យក្រោម",
        latin: "Angkor Chey Kraom"
      },
      id: "03080509"
    },
    {
      name: {
        km: "ផ្សារថ្មី",
        latin: "Phsar Thmei"
      },
      id: "03080601"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "03080602"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03080603"
    },
    {
      name: {
        km: "ក្រពើគម",
        latin: "Krapeu Korm"
      },
      id: "03080604"
    },
    {
      name: {
        km: "ប៉ាក់ណាម",
        latin: "Pak Nam"
      },
      id: "03080605"
    },
    {
      name: {
        km: "ជីហែ",
        latin: "Chihae"
      },
      id: "03080606"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "03080607"
    },
    {
      name: {
        km: "មហាលាភ",
        latin: "Moha Leaph"
      },
      id: "03080608"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "03080609"
    },
    {
      name: {
        km: "ទួលក្ដុល",
        latin: "Tuol Kdol"
      },
      id: "03080610"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "03080611"
    },
    {
      name: {
        km: "ទួលកំពត",
        latin: "Tuol Kampot"
      },
      id: "03080612"
    },
    {
      name: {
        km: "ទួលធាតុ",
        latin: "Tuol Theat"
      },
      id: "03080613"
    },
    {
      name: {
        km: "កំពង់ឪជ្រឹង",
        latin: "Kampong Ov Chrueng"
      },
      id: "03080701"
    },
    {
      name: {
        km: "ព្រែករំដេងកើត",
        latin: "Preaek Rumdeng Kaeut"
      },
      id: "03080702"
    },
    {
      name: {
        km: "ព្រែករំដេងលិច",
        latin: "Preaek Rumdeng Lech"
      },
      id: "03080703"
    },
    {
      name: {
        km: "ពង្រកើត",
        latin: "Pongro Kaeut"
      },
      id: "03080704"
    },
    {
      name: {
        km: "ពង្រលិច",
        latin: "Pongro Lech"
      },
      id: "03080705"
    },
    {
      name: {
        km: "ដើមស្ដៅ",
        latin: "Daeum Sdau"
      },
      id: "03080706"
    },
    {
      name: {
        km: "ប៉ាក់ណាម",
        latin: "Pak Nam"
      },
      id: "03080707"
    },
    {
      name: {
        km: "អន្លង់ដូង",
        latin: "Anlong Doung"
      },
      id: "03080708"
    },
    {
      name: {
        km: "ភូមិ ទី១",
        latin: "Phum Ti Muoy"
      },
      id: "03080801"
    },
    {
      name: {
        km: "ភូមិ ទី២",
        latin: "Phum Ti Pir"
      },
      id: "03080802"
    },
    {
      name: {
        km: "ភូមិ ទី៣",
        latin: "Phum Ti Bei"
      },
      id: "03080803"
    },
    {
      name: {
        km: "ភូមិ ទី៤",
        latin: "Phum Ti Buon"
      },
      id: "03080804"
    },
    {
      name: {
        km: "ភូមិ ទី៥",
        latin: "Phum Ti Pram"
      },
      id: "03080805"
    },
    {
      name: {
        km: "ភូមិ ទី៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "03080806"
    },
    {
      name: {
        km: "ភូមិ ទី៧",
        latin: "Phum Ti Prampir"
      },
      id: "03080807"
    },
    {
      name: {
        km: "ភូមិ ទី៨",
        latin: "Phum Ti Prambei"
      },
      id: "03080808"
    },
    {
      name: {
        km: "ភូមិ ទី៩",
        latin: "Phum Ti Prambuon"
      },
      id: "03080809"
    },
    {
      name: {
        km: "ភូមិ ទី១០",
        latin: "Phum Ti Dab"
      },
      id: "03080810"
    },
    {
      name: {
        km: "ភូមិ ទី១១",
        latin: "Phum Ti Dabmuoy"
      },
      id: "03080811"
    },
    {
      name: {
        km: "ភូមិ ទី១២",
        latin: "Phum Ti Dabpir"
      },
      id: "03080812"
    },
    {
      name: {
        km: "ភូមិ ទី១៣",
        latin: "Phum Ti Dabbei"
      },
      id: "03080813"
    },
    {
      name: {
        km: "ព្រៃខ្ជាយ",
        latin: "Prey Khchay"
      },
      id: "03130101"
    },
    {
      name: {
        km: "ទួលចំបក់",
        latin: "Tuol Chambak"
      },
      id: "03130102"
    },
    {
      name: {
        km: "ត្រពាំងបេង",
        latin: "Trapeang Beng"
      },
      id: "03130103"
    },
    {
      name: {
        km: "លាងខ្សាច់",
        latin: "Leang Khsach"
      },
      id: "03130104"
    },
    {
      name: {
        km: "ត្រពាំងបី",
        latin: "Trapeang Bei"
      },
      id: "03130105"
    },
    {
      name: {
        km: "អូរកំបោរ",
        latin: "Ou Kambaor"
      },
      id: "03130106"
    },
    {
      name: {
        km: "គោកស្រឡៅ",
        latin: "Kouk Sralau"
      },
      id: "03130107"
    },
    {
      name: {
        km: "រោងគោ",
        latin: "Roung Kou"
      },
      id: "03130108"
    },
    {
      name: {
        km: "វត្ដចាស់",
        latin: "Voat Chas"
      },
      id: "03130109"
    },
    {
      name: {
        km: "រោលជ្រូក",
        latin: "Roul Chruk"
      },
      id: "03130110"
    },
    {
      name: {
        km: "ព្រៃរំដេង",
        latin: "Prey Rumdeng"
      },
      id: "03130111"
    },
    {
      name: {
        km: "សំណាក់ជើង",
        latin: "Samnak Cheung"
      },
      id: "03130112"
    },
    {
      name: {
        km: "សំណាក់ត្បូង",
        latin: "Samnak Tboung"
      },
      id: "03130113"
    },
    {
      name: {
        km: "កុមារាជ្យ",
        latin: "Komar Reach"
      },
      id: "03130201"
    },
    {
      name: {
        km: "ត្រពាំងអញ្ចញ",
        latin: "Trapeang Anhchanh"
      },
      id: "03130202"
    },
    {
      name: {
        km: "ថ្មដា",
        latin: "Thma Da"
      },
      id: "03130203"
    },
    {
      name: {
        km: "ថ្មគោល",
        latin: "Thma Koul"
      },
      id: "03130204"
    },
    {
      name: {
        km: "ត្រពាំងបិត",
        latin: "Trapeang Bet"
      },
      id: "03130205"
    },
    {
      name: {
        km: "បឹងណាយ",
        latin: "Boeng Nay"
      },
      id: "03130206"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "03130207"
    },
    {
      name: {
        km: "ប្រវ៉ាស់",
        latin: "Pravas"
      },
      id: "03130208"
    },
    {
      name: {
        km: "អ្នកតាស្នឹង",
        latin: "Neak Ta Snoeng"
      },
      id: "03130209"
    },
    {
      name: {
        km: "តាអុក",
        latin: "Ta Ok"
      },
      id: "03130210"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "03130211"
    },
    {
      name: {
        km: "ទួលខ្វាវ",
        latin: "Tuol Khvav"
      },
      id: "03130212"
    },
    {
      name: {
        km: "ឈូកស",
        latin: "Chhuk Sa"
      },
      id: "03130213"
    },
    {
      name: {
        km: "ឈើបាក់",
        latin: "Chheu Bak"
      },
      id: "03130214"
    },
    {
      name: {
        km: "ត្រើង",
        latin: "Traeung"
      },
      id: "03130215"
    },
    {
      name: {
        km: "ជន្លាត់ដៃ",
        latin: "Chonloat Dai"
      },
      id: "03130216"
    },
    {
      name: {
        km: "វត្ដចាស់",
        latin: "Voat Chas"
      },
      id: "03130217"
    },
    {
      name: {
        km: "ដៃបួន",
        latin: "Dai Buon"
      },
      id: "03130301"
    },
    {
      name: {
        km: "ដូនដី",
        latin: "Doun Dei"
      },
      id: "03130302"
    },
    {
      name: {
        km: "ត្រពាំងទូក",
        latin: "Trapeang Tuk"
      },
      id: "03130303"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "03130304"
    },
    {
      name: {
        km: "បន្ទាយរឹង",
        latin: "Banteay Rueng"
      },
      id: "03130305"
    },
    {
      name: {
        km: "ទួលតាកោ",
        latin: "Tuol Ta Kao"
      },
      id: "03130306"
    },
    {
      name: {
        km: "ក្រឡោង",
        latin: "Kralaong"
      },
      id: "03130307"
    },
    {
      name: {
        km: "អូរកំបុត",
        latin: "Ou Kambot"
      },
      id: "03130308"
    },
    {
      name: {
        km: "ខ្វិតតូច",
        latin: "Khvet Touch"
      },
      id: "03130309"
    },
    {
      name: {
        km: "ជ្រៃវៀន",
        latin: "Chrey Vien"
      },
      id: "03130310"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "03130311"
    },
    {
      name: {
        km: "តារាម",
        latin: "Ta Ream"
      },
      id: "03130312"
    },
    {
      name: {
        km: "ក្លែងពណ៌",
        latin: "Klaeng Poar"
      },
      id: "03130313"
    },
    {
      name: {
        km: "ទឹកនឹម",
        latin: "Tuek Nuem"
      },
      id: "03130314"
    },
    {
      name: {
        km: "ទួលបាក់គាំ",
        latin: "Tuol Bak Koam"
      },
      id: "03130315"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "03130316"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "03130317"
    },
    {
      name: {
        km: "ត្រពាំងព្នៅ",
        latin: "Trapeang Pnov"
      },
      id: "03130318"
    },
    {
      name: {
        km: "ខ្វិតធំ",
        latin: "Khvet Thum"
      },
      id: "03130401"
    },
    {
      name: {
        km: "បារាយណ៍",
        latin: "Baray"
      },
      id: "03130402"
    },
    {
      name: {
        km: "អង្ក្រង",
        latin: "Angkrang"
      },
      id: "03130403"
    },
    {
      name: {
        km: "តាង៉ាល",
        latin: "Ta Ngal"
      },
      id: "03130404"
    },
    {
      name: {
        km: "អំពិលធំ",
        latin: "Ampil Thum"
      },
      id: "03130405"
    },
    {
      name: {
        km: "ដង្កោ",
        latin: "Dangkao"
      },
      id: "03130406"
    },
    {
      name: {
        km: "ប្រធាតុ",
        latin: "Pratheat"
      },
      id: "03130407"
    },
    {
      name: {
        km: "កប្បាស",
        latin: "Kabbas"
      },
      id: "03130408"
    },
    {
      name: {
        km: "ដូនឡី",
        latin: "Doun Lei"
      },
      id: "03130501"
    },
    {
      name: {
        km: "ម្រេញ",
        latin: "Mrenh"
      },
      id: "03130502"
    },
    {
      name: {
        km: "តាមាស",
        latin: "Ta Meas"
      },
      id: "03130503"
    },
    {
      name: {
        km: "តាកែវ",
        latin: "Ta Kaev"
      },
      id: "03130504"
    },
    {
      name: {
        km: "តាលៃ",
        latin: "Ta Ley"
      },
      id: "03130505"
    },
    {
      name: {
        km: "តាម៉ូត",
        latin: "Ta Mout"
      },
      id: "03130506"
    },
    {
      name: {
        km: "រំដួល",
        latin: "Rumduol"
      },
      id: "03130507"
    },
    {
      name: {
        km: "ស្វាយប៉ិន",
        latin: "Svay Pen"
      },
      id: "03130508"
    },
    {
      name: {
        km: "ក្រោយវត្ដ",
        latin: "Kraoy Voat"
      },
      id: "03130509"
    },
    {
      name: {
        km: "ត្រពាំងពោន",
        latin: "Trapeang Poun"
      },
      id: "03130510"
    },
    {
      name: {
        km: "អូរជ្រក",
        latin: "Ou Chrok"
      },
      id: "03130601"
    },
    {
      name: {
        km: "ព្រៃសាក់",
        latin: "Prey Sak"
      },
      id: "03130602"
    },
    {
      name: {
        km: "ទួលខ្ពស់",
        latin: "Tuol Khpos"
      },
      id: "03130603"
    },
    {
      name: {
        km: "ក្រសាំងតាម៉ង់",
        latin: "Krasang Ta Mang"
      },
      id: "03130604"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "03130605"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03130606"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "03130607"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "03130701"
    },
    {
      name: {
        km: "ត្រពាំងជីនាង",
        latin: "Trapeang Chi Neang"
      },
      id: "03130702"
    },
    {
      name: {
        km: "គោកទ្រាកើត",
        latin: "Kouk Trea Kaeut"
      },
      id: "03130703"
    },
    {
      name: {
        km: "គោកទ្រាលិច",
        latin: "Kouk Trea Lech"
      },
      id: "03130704"
    },
    {
      name: {
        km: "ស្ដុកអន្ទង់",
        latin: "Sdok Antong"
      },
      id: "03130705"
    },
    {
      name: {
        km: "តាចាក់",
        latin: "Ta Chak"
      },
      id: "03130706"
    },
    {
      name: {
        km: "មេមាំង",
        latin: "Me Meang"
      },
      id: "03130707"
    },
    {
      name: {
        km: "តាំងគោក",
        latin: "Tang Kouk"
      },
      id: "03130708"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "03130709"
    },
    {
      name: {
        km: "តាំងត្រពាំង",
        latin: "Tang Trapeang"
      },
      id: "03130710"
    },
    {
      name: {
        km: "ទួលព្រិច",
        latin: "Tuol Prich"
      },
      id: "03130801"
    },
    {
      name: {
        km: "អូរសង្កែ",
        latin: "Ou Sangkae"
      },
      id: "03130802"
    },
    {
      name: {
        km: "កេះ",
        latin: "Keh"
      },
      id: "03130803"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "03130804"
    },
    {
      name: {
        km: "ណាំកិន",
        latin: "Nam Ken"
      },
      id: "03130805"
    },
    {
      name: {
        km: "មៀន",
        latin: "Mien"
      },
      id: "03130806"
    },
    {
      name: {
        km: "ទួលពោន",
        latin: "Tuol Poun"
      },
      id: "03130807"
    },
    {
      name: {
        km: "ផ្កាយព្រឹក",
        latin: "Phkay Proek"
      },
      id: "03130808"
    },
    {
      name: {
        km: "កំពង់សំរិត",
        latin: "Kampong Samret"
      },
      id: "03130809"
    },
    {
      name: {
        km: "ក្រសាំងពុល",
        latin: "Krasang Pul"
      },
      id: "03130810"
    },
    {
      name: {
        km: "ដំណាក់ពង្រ",
        latin: "Damnak Pongro"
      },
      id: "03130811"
    },
    {
      name: {
        km: "កំពង់សំណាញ់",
        latin: "Kampong Samnanh"
      },
      id: "03130812"
    },
    {
      name: {
        km: "អូរតានៅ",
        latin: "Ou Ta Nov"
      },
      id: "03130813"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "03130814"
    },
    {
      name: {
        km: "ឃ្លោយទី ១",
        latin: "Khlouy Ti Muoy"
      },
      id: "03130815"
    },
    {
      name: {
        km: "ឃ្លោយទី ២",
        latin: "Khlouy Ti Pir"
      },
      id: "03130816"
    },
    {
      name: {
        km: "ឃ្លោយទី ៣",
        latin: "Khlouy Ti Bei"
      },
      id: "03130817"
    },
    {
      name: {
        km: "ឃ្លោយទី ៤",
        latin: "Khlouy Ti Buon"
      },
      id: "03130818"
    },
    {
      name: {
        km: "ត្រើង",
        latin: "Traeung"
      },
      id: "03130819"
    },
    {
      name: {
        km: "ព្រៃឈរ",
        latin: "Prey Chhor"
      },
      id: "03130901"
    },
    {
      name: {
        km: "សេកយំ",
        latin: "Sek Yum"
      },
      id: "03130902"
    },
    {
      name: {
        km: "ជ្រេស",
        latin: "Chres"
      },
      id: "03130903"
    },
    {
      name: {
        km: "សង្កែ",
        latin: "Sangkae"
      },
      id: "03130904"
    },
    {
      name: {
        km: "សូរ្យសែន",
        latin: "Sour Saen"
      },
      id: "03131001"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "03131002"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "03131003"
    },
    {
      name: {
        km: "ត្រពាំងត្នោត",
        latin: "Trapeang Tnaot"
      },
      id: "03131004"
    },
    {
      name: {
        km: "ត្រើយអូរ",
        latin: "Traeuy Ou"
      },
      id: "03131005"
    },
    {
      name: {
        km: "ត្រពាំងត្បាល់",
        latin: "Trapeang Tbal"
      },
      id: "03131006"
    },
    {
      name: {
        km: "ចំបក់ថ្ម",
        latin: "Chambak Thma"
      },
      id: "03131007"
    },
    {
      name: {
        km: "ស្វាយរ័ក្ស",
        latin: "Svay Reaks"
      },
      id: "03131008"
    },
    {
      name: {
        km: "បន្ទាយថ្មី",
        latin: "Banteay Thmei"
      },
      id: "03131101"
    },
    {
      name: {
        km: "តាក្រិត",
        latin: "Ta Kret"
      },
      id: "03131102"
    },
    {
      name: {
        km: "កណ្ដោលកោង",
        latin: "Kandaol Kaong"
      },
      id: "03131103"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "03131104"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "03131105"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "03131106"
    },
    {
      name: {
        km: "សូដី",
        latin: "Soudei"
      },
      id: "03131107"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03131108"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "03131109"
    },
    {
      name: {
        km: "ស្មេរ",
        latin: "Smer"
      },
      id: "03131110"
    },
    {
      name: {
        km: "ព្រៃខ្ជាយ",
        latin: "Prey Khchay"
      },
      id: "03131111"
    },
    {
      name: {
        km: "ស្រង៉ែជើង",
        latin: "Srangae Cheung"
      },
      id: "03131201"
    },
    {
      name: {
        km: "ស្រង៉ែត្បូង",
        latin: "Srangae Tboung"
      },
      id: "03131202"
    },
    {
      name: {
        km: "សេនសុនត្បូង",
        latin: "Senson Tboung"
      },
      id: "03131203"
    },
    {
      name: {
        km: "សេនសុនជើង",
        latin: "Senson Cheung"
      },
      id: "03131204"
    },
    {
      name: {
        km: "តាសរ",
        latin: "Ta Sar"
      },
      id: "03131205"
    },
    {
      name: {
        km: "តាកុច",
        latin: "Ta Koch"
      },
      id: "03131206"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "03131207"
    },
    {
      name: {
        km: "ត្រពាំងរូង",
        latin: "Trapeang Rung"
      },
      id: "03131208"
    },
    {
      name: {
        km: "ត្រាំង",
        latin: "Trang"
      },
      id: "03131301"
    },
    {
      name: {
        km: "អណ្ដូងតាពេជ្រ",
        latin: "Andoung Ta Pech"
      },
      id: "03131302"
    },
    {
      name: {
        km: "ត្រពាំងបឹង",
        latin: "Trapeang Boeng"
      },
      id: "03131303"
    },
    {
      name: {
        km: "ទួលថ្ម",
        latin: "Tuol Thma"
      },
      id: "03131304"
    },
    {
      name: {
        km: "លិចវត្ដ",
        latin: "Lech Voat"
      },
      id: "03131305"
    },
    {
      name: {
        km: "អណ្ដូងផ្ដៅ",
        latin: "Andoung Phdau"
      },
      id: "03131306"
    },
    {
      name: {
        km: "ថ្មពូនកណ្ដាល",
        latin: "Thma Pun Kandal"
      },
      id: "03131307"
    },
    {
      name: {
        km: "អណ្ដូងតាឡឹង",
        latin: "Andoung Ta Loeng"
      },
      id: "03131308"
    },
    {
      name: {
        km: "អូរតាថុក",
        latin: "Ou Ta Thok"
      },
      id: "03131309"
    },
    {
      name: {
        km: "តុងរ៉ុង",
        latin: "Tong Rong"
      },
      id: "03131401"
    },
    {
      name: {
        km: "ផ្ទះខ្ពស់",
        latin: "Phteah Khpos"
      },
      id: "03131402"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "03131403"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "03131404"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "03131405"
    },
    {
      name: {
        km: "ព្រះស្រុក",
        latin: "Preah Srok"
      },
      id: "03131406"
    },
    {
      name: {
        km: "គកកណ្ដាល",
        latin: "Kok Kandal"
      },
      id: "03131407"
    },
    {
      name: {
        km: "ទ្រមុខទី ១",
        latin: "Tro Mukh Ti Muoy"
      },
      id: "03131408"
    },
    {
      name: {
        km: "ទ្រមុខទី ២",
        latin: "Tro Mukh Ti Pir"
      },
      id: "03131409"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "03131410"
    },
    {
      name: {
        km: "កោះស្វាយ",
        latin: "Kaoh Svay"
      },
      id: "03131501"
    },
    {
      name: {
        km: "ព្រីងបីដើម",
        latin: "Pring Bei Daeum"
      },
      id: "03131502"
    },
    {
      name: {
        km: "ចចក",
        latin: "Chachak"
      },
      id: "03131503"
    },
    {
      name: {
        km: "ព្រៃស្រឡៅ",
        latin: "Prey Sralau"
      },
      id: "03131504"
    },
    {
      name: {
        km: "ពួនប្រម៉ាត់",
        latin: "Puon Pramat"
      },
      id: "03131505"
    },
    {
      name: {
        km: "គូរ",
        latin: "Kur"
      },
      id: "03131506"
    },
    {
      name: {
        km: "ស្បែង",
        latin: "Sbaeng"
      },
      id: "03131507"
    },
    {
      name: {
        km: "ព្រៃស្រឡាញ",
        latin: "Prey Sralanh"
      },
      id: "03131508"
    },
    {
      name: {
        km: "ត្រពាំងល័ក្ខ",
        latin: "Trapeang Leak"
      },
      id: "03131509"
    },
    {
      name: {
        km: "អូរដា",
        latin: "Ou Da"
      },
      id: "03131510"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "03131511"
    },
    {
      name: {
        km: "ទួលអំពិល",
        latin: "Tuol Ampil"
      },
      id: "03131512"
    },
    {
      name: {
        km: "តាលន",
        latin: "Ta Lon"
      },
      id: "03131513"
    },
    {
      name: {
        km: "កោះកាភេម",
        latin: "Kaoh Kaphem"
      },
      id: "03131514"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang svay"
      },
      id: "03131515"
    },
    {
      name: {
        km: "អង",
        latin: "Ang"
      },
      id: "03131516"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "03131517"
    },
    {
      name: {
        km: "ទន្លេស",
        latin: "Tonle Sa"
      },
      id: "03131518"
    },
    {
      name: {
        km: "កកោះ",
        latin: "Kakaoh"
      },
      id: "03131519"
    },
    {
      name: {
        km: "អូរដូនញា",
        latin: "Ou Doun Nhea"
      },
      id: "03131520"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "03131521"
    },
    {
      name: {
        km: "ដីឡូតិ៍",
        latin: "Dei Lou"
      },
      id: "03131522"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "03131523"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "03131524"
    },
    {
      name: {
        km: "ស្យាបឹងវែង",
        latin: "Sya Boeng Veaeng"
      },
      id: "03140101"
    },
    {
      name: {
        km: "ស្យាអំពិល",
        latin: "Sya Ampil"
      },
      id: "03140102"
    },
    {
      name: {
        km: "បន្ទាយ",
        latin: "Banteay"
      },
      id: "03140103"
    },
    {
      name: {
        km: "កំផ្លាក",
        latin: "Kamphlak"
      },
      id: "03140104"
    },
    {
      name: {
        km: "ខ្នុរដូង",
        latin: "Khnor Doung"
      },
      id: "03140201"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "03140202"
    },
    {
      name: {
        km: "ជីបាល",
        latin: "Chi Bal"
      },
      id: "03140203"
    },
    {
      name: {
        km: "តាំងក្រាំង",
        latin: "Tang Krang"
      },
      id: "03140204"
    },
    {
      name: {
        km: "ខ្យោង",
        latin: "Khyaong"
      },
      id: "03140205"
    },
    {
      name: {
        km: "អង់",
        latin: "Ang"
      },
      id: "03140301"
    },
    {
      name: {
        km: "ក្ងោក",
        latin: "Kngaok"
      },
      id: "03140302"
    },
    {
      name: {
        km: "ទ្រារស",
        latin: "Trea Sa"
      },
      id: "03140303"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "03140304"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "03140305"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "03140401"
    },
    {
      name: {
        km: "គគីរ",
        latin: "Kokir"
      },
      id: "03140402"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "03140403"
    },
    {
      name: {
        km: "ក្រូចសើច",
        latin: "Krouch Saeuch"
      },
      id: "03140404"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "03140501"
    },
    {
      name: {
        km: "មាន់ដប់លើ",
        latin: "Moan Dab Leu"
      },
      id: "03140502"
    },
    {
      name: {
        km: "មាន់ដប់ក្រោម",
        latin: "Moan Dab Kraom"
      },
      id: "03140503"
    },
    {
      name: {
        km: "កោះគោ",
        latin: "Kaoh Kou"
      },
      id: "03140504"
    },
    {
      name: {
        km: "ជ័យ",
        latin: "Chey"
      },
      id: "03140505"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "03140506"
    },
    {
      name: {
        km: "ប៉ុកប៉ែន",
        latin: "Pok Paen"
      },
      id: "03140507"
    },
    {
      name: {
        km: "អូលាវ",
        latin: "Ou Leav"
      },
      id: "03140601"
    },
    {
      name: {
        km: "ផ្ទះកណ្ដាលលើ",
        latin: "Phteah Kandal Leu"
      },
      id: "03140602"
    },
    {
      name: {
        km: "ផ្ទះកណ្ដាលក្រោម",
        latin: "Phteah Kandal Kraom"
      },
      id: "03140603"
    },
    {
      name: {
        km: "ចុងបឹងក្រៅ",
        latin: "Chong Boeng Krau"
      },
      id: "03140604"
    },
    {
      name: {
        km: "ប្រាំយ៉ាម",
        latin: "Pram Yam"
      },
      id: "03140701"
    },
    {
      name: {
        km: "ក្ដីថ្ក",
        latin: "Kdei Thka"
      },
      id: "03140702"
    },
    {
      name: {
        km: "ជើងដឹក",
        latin: "Cheung Doek"
      },
      id: "03140703"
    },
    {
      name: {
        km: "ជីប្រាយ",
        latin: "Chi Pray"
      },
      id: "03140704"
    },
    {
      name: {
        km: "តាកយ",
        latin: "Ta Kay"
      },
      id: "03140801"
    },
    {
      name: {
        km: "តាម៉ឺន",
        latin: "Ta Meun"
      },
      id: "03140802"
    },
    {
      name: {
        km: "តាមល់",
        latin: "Ta Mol"
      },
      id: "03140803"
    },
    {
      name: {
        km: "ចុងបឹងក្នុង",
        latin: "Chong Boeng Knong"
      },
      id: "03140804"
    },
    {
      name: {
        km: "ព្រែកដំបូកក្រោម",
        latin: "Preaek Dambouk Kraom"
      },
      id: "03140805"
    },
    {
      name: {
        km: "ព្រែកដំបូកលើ",
        latin: "Preaek Dambouk Leu"
      },
      id: "03140806"
    },
    {
      name: {
        km: "ស្វាយម៉ូ",
        latin: "Svay Mou"
      },
      id: "03140807"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "03140808"
    },
    {
      name: {
        km: "ផ្ទះវាល",
        latin: "Phteah Veal"
      },
      id: "03140809"
    },
    {
      name: {
        km: "រកាទ្វារ",
        latin: "Roka Tvear"
      },
      id: "03140901"
    },
    {
      name: {
        km: "ព្រែកពោធិ៍លើ",
        latin: "Preaek Pou Leu"
      },
      id: "03140902"
    },
    {
      name: {
        km: "ព្រែកពោធិ៍ក្រោម",
        latin: "Preaek Pou Kraom"
      },
      id: "03140903"
    },
    {
      name: {
        km: "ច្រស់",
        latin: "Chras"
      },
      id: "03140904"
    },
    {
      name: {
        km: "ប្រថ្នល់",
        latin: "Prathnal"
      },
      id: "03140905"
    },
    {
      name: {
        km: "គោកចារ",
        latin: "Kouk Char"
      },
      id: "03140906"
    },
    {
      name: {
        km: "សន្ទៃ",
        latin: "Santey"
      },
      id: "03140907"
    },
    {
      name: {
        km: "ថ្មដា",
        latin: "Thma Da"
      },
      id: "03140908"
    },
    {
      name: {
        km: "ទូរីលើ",
        latin: "Turi Leu"
      },
      id: "03140909"
    },
    {
      name: {
        km: "ទូរីកណ្ដាល",
        latin: "Turi Kandal"
      },
      id: "03140910"
    },
    {
      name: {
        km: "ទូរីក្រោម",
        latin: "Turi Kraom"
      },
      id: "03140911"
    },
    {
      name: {
        km: "ព្រៃត្បេះ",
        latin: "Prey Tbeh"
      },
      id: "03140912"
    },
    {
      name: {
        km: "ក្សេរ",
        latin: "Kser"
      },
      id: "03141001"
    },
    {
      name: {
        km: "អូឡេង",
        latin: "Ou Leng"
      },
      id: "03141002"
    },
    {
      name: {
        km: "ត្នោត ក",
        latin: "Tnaot Ka"
      },
      id: "03141003"
    },
    {
      name: {
        km: "ត្នោត ខ",
        latin: "Tnaot Kha"
      },
      id: "03141004"
    },
    {
      name: {
        km: "ព្រែករំដេង ក",
        latin: "Preaek Rumdeng Ka"
      },
      id: "03141005"
    },
    {
      name: {
        km: "ព្រែករំដេង ខ",
        latin: "Preaek Rumdeng Kha"
      },
      id: "03141006"
    },
    {
      name: {
        km: "ព្រែករំដេង គ",
        latin: "Preaek Rumdeng Kho"
      },
      id: "03141007"
    },
    {
      name: {
        km: "ព្រែកឪជ្រឹង ក",
        latin: "Preaek Ouv Chrueng Ka"
      },
      id: "03141008"
    },
    {
      name: {
        km: "ព្រែកឪជ្រឹង ខ",
        latin: "Preaek Ouv Chrueng Kha"
      },
      id: "03141009"
    },
    {
      name: {
        km: "ស្វាយតានាន់ ក",
        latin: "Svay Ta Noan Ka"
      },
      id: "03141010"
    },
    {
      name: {
        km: "ស្វាយតានាន់ ខ",
        latin: "Svay Ta Noan Kha"
      },
      id: "03141011"
    },
    {
      name: {
        km: "កំពង់ព្នៅ",
        latin: "Kampong Pnov"
      },
      id: "03141012"
    },
    {
      name: {
        km: "តាកុច",
        latin: "Ta Koch"
      },
      id: "03141013"
    },
    {
      name: {
        km: "តាង៉កថ្មី",
        latin: "Ta Ngak Thmei"
      },
      id: "03141014"
    },
    {
      name: {
        km: "ត្នោតក្រោម",
        latin: "Tnaot Kraom"
      },
      id: "03141101"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "03141102"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "03141103"
    },
    {
      name: {
        km: "ត្នោតលើ",
        latin: "Tnaot Leu"
      },
      id: "03141104"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "03141201"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "03141202"
    },
    {
      name: {
        km: "ជីប៉ោ",
        latin: "Chi Pao"
      },
      id: "03141203"
    },
    {
      name: {
        km: "ទាហ៊ា",
        latin: "Tea Hea"
      },
      id: "03141204"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "03141205"
    },
    {
      name: {
        km: "ខ្វិត",
        latin: "Khvet"
      },
      id: "03141206"
    },
    {
      name: {
        km: "ស្វាយលើ",
        latin: "Svay Leu"
      },
      id: "03141301"
    },
    {
      name: {
        km: "ស្វាយកណ្ដាល",
        latin: "Svay Kandal"
      },
      id: "03141302"
    },
    {
      name: {
        km: "ស្វាយត្បូង",
        latin: "Svay Tboung"
      },
      id: "03141303"
    },
    {
      name: {
        km: "ស្វាយក្រោម",
        latin: "Svay Kraom"
      },
      id: "03141304"
    },
    {
      name: {
        km: "ទងត្រឡាច",
        latin: "Tong Tralach"
      },
      id: "03141401"
    },
    {
      name: {
        km: "បឹងទីង",
        latin: "Boeng Ting"
      },
      id: "03141402"
    },
    {
      name: {
        km: "ខ្ទឹង",
        latin: "Khtueng"
      },
      id: "03141403"
    },
    {
      name: {
        km: "ចន្លាត់ដៃ",
        latin: "Chonloat Dai"
      },
      id: "03141404"
    },
    {
      name: {
        km: "អារក្សត្នោត",
        latin: "Areaks Tnaot"
      },
      id: "03150101"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "03150102"
    },
    {
      name: {
        km: "គីឡូ​ ៧",
        latin: "Kilou Prampir"
      },
      id: "03150103"
    },
    {
      name: {
        km: "គីឡូ ១០",
        latin: "Kilou Dab"
      },
      id: "03150104"
    },
    {
      name: {
        km: "បែកអន្លូង",
        latin: "Baek Anlung"
      },
      id: "03150105"
    },
    {
      name: {
        km: "សន្ទិចកើត",
        latin: "Santich Kaeut"
      },
      id: "03150301"
    },
    {
      name: {
        km: "សន្ទិចលិច",
        latin: "Santich Lech"
      },
      id: "03150302"
    },
    {
      name: {
        km: "សន្ទិចកណ្ដាល",
        latin: "Santich Kandal"
      },
      id: "03150303"
    },
    {
      name: {
        km: "ហុងប្រម៉ា",
        latin: "Hong Prama"
      },
      id: "03150304"
    },
    {
      name: {
        km: "ជ្រៃហាយ",
        latin: "Chrey Hay"
      },
      id: "03150305"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03150306"
    },
    {
      name: {
        km: "ស្រែរំដួល",
        latin: "Srae Rumduol"
      },
      id: "03150307"
    },
    {
      name: {
        km: "តារៀម",
        latin: "Ta Riem"
      },
      id: "03150308"
    },
    {
      name: {
        km: "ស្ដៅ",
        latin: "Sdau"
      },
      id: "03150309"
    },
    {
      name: {
        km: "អូរពីរ",
        latin: "Ou Pir"
      },
      id: "03150310"
    },
    {
      name: {
        km: "អូររុន",
        latin: "Ou Run"
      },
      id: "03150401"
    },
    {
      name: {
        km: "អន្លង់សំឡី",
        latin: "Anlong Samlei"
      },
      id: "03150402"
    },
    {
      name: {
        km: "ព្រែកទក់",
        latin: "Preaek Tok"
      },
      id: "03150403"
    },
    {
      name: {
        km: "ខ្ពបតាងួន",
        latin: "Khpob Ta Nguon"
      },
      id: "03150404"
    },
    {
      name: {
        km: "ឈើទើ",
        latin: "Chheu Teu"
      },
      id: "03150405"
    },
    {
      name: {
        km: "វាលបំពង់",
        latin: "Veal Bampong"
      },
      id: "03150406"
    },
    {
      name: {
        km: "អូរលើ",
        latin: "Ou Leu"
      },
      id: "03150501"
    },
    {
      name: {
        km: "ក្បាលអូរ",
        latin: "Kbal Ou"
      },
      id: "03150502"
    },
    {
      name: {
        km: "បុសពោធិ",
        latin: "Bos Pou"
      },
      id: "03150503"
    },
    {
      name: {
        km: "អូរបេង",
        latin: "Ou Beng"
      },
      id: "03150504"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "03150505"
    },
    {
      name: {
        km: "ខ្ទួយទី១",
        latin: "Khtuoy Ti Muoy"
      },
      id: "03150601"
    },
    {
      name: {
        km: "ខ្ទួយទី២",
        latin: "Khtuoy Ti Pir"
      },
      id: "03150602"
    },
    {
      name: {
        km: "ខ្ទួយទី ៣",
        latin: "Khtuoy Ti Bei"
      },
      id: "03150603"
    },
    {
      name: {
        km: "ខ្ទួយទី ៤",
        latin: "Khtuoy Ti Buon"
      },
      id: "03150604"
    },
    {
      name: {
        km: "អូរប្រឡោះ",
        latin: "Ou Pralaoh"
      },
      id: "03150605"
    },
    {
      name: {
        km: "អូរកាប់មាន់",
        latin: "Ou Kab Moan"
      },
      id: "03150606"
    },
    {
      name: {
        km: "អូរឫស្សី",
        latin: "Ou Ruessei"
      },
      id: "03150607"
    },
    {
      name: {
        km: "អូរតាសេក",
        latin: "Ou Ta Sek"
      },
      id: "03150608"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "03150609"
    },
    {
      name: {
        km: "ប្រទង",
        latin: "Pratong"
      },
      id: "03150610"
    },
    {
      name: {
        km: "បិទធ្នូ",
        latin: "Bet Thnu"
      },
      id: "03150611"
    },
    {
      name: {
        km: "ស្ពង់សាខាថ្មី",
        latin: "Spong Sakha Thmei"
      },
      id: "03150612"
    },
    {
      name: {
        km: "ស្ពង់សាខាចាស់",
        latin: "Spong Sakha Chas"
      },
      id: "03150613"
    },
    {
      name: {
        km: "ពាមក្រៅ",
        latin: "Peam Krau"
      },
      id: "03150701"
    },
    {
      name: {
        km: "ពាមក្នុង",
        latin: "Peam Knong"
      },
      id: "03150702"
    },
    {
      name: {
        km: "ដីលើ",
        latin: "Dei Leu"
      },
      id: "03150703"
    },
    {
      name: {
        km: "ទួលរការ",
        latin: "Tuol Rokar"
      },
      id: "03150704"
    },
    {
      name: {
        km: "ដីដុះ",
        latin: "Dei Doh"
      },
      id: "03150705"
    },
    {
      name: {
        km: "ព្រែកសង្កែកើត",
        latin: "Preaek Sangkae Kaeut"
      },
      id: "03150706"
    },
    {
      name: {
        km: "ព្រែកសង្កែលិច",
        latin: "Preaek Sangkae Lech"
      },
      id: "03150707"
    },
    {
      name: {
        km: "កោះកណ្ដាល",
        latin: "Kaoh Kandal"
      },
      id: "03150708"
    },
    {
      name: {
        km: "ស្រែសង្កែ",
        latin: "Srae Sangkae"
      },
      id: "03150709"
    },
    {
      name: {
        km: "ព្រែកស្ដី",
        latin: "Preaek Sdei"
      },
      id: "03150801"
    },
    {
      name: {
        km: "ព្រះអណ្ដូង ទី ១",
        latin: "Preah Andoung Ti Muoy"
      },
      id: "03150802"
    },
    {
      name: {
        km: "ព្រះអណ្ដូង ទី ២",
        latin: "Preah Andoung Ti Pir"
      },
      id: "03150803"
    },
    {
      name: {
        km: "ព្រែកបាក់",
        latin: "Preaek Bak"
      },
      id: "03150901"
    },
    {
      name: {
        km: "ព្រែកកក់",
        latin: "Preaek Kak"
      },
      id: "03150902"
    },
    {
      name: {
        km: "ព្រែករលួស",
        latin: "Preaek Roluos"
      },
      id: "03150903"
    },
    {
      name: {
        km: "ព្រែកព្រះអង្គ",
        latin: "Preaek Preah Angk"
      },
      id: "03150904"
    },
    {
      name: {
        km: "អណ្ដូងពេជ្រ",
        latin: "Andoung Pech"
      },
      id: "03151001"
    },
    {
      name: {
        km: "មាឃ ១",
        latin: "Meakh Muoy"
      },
      id: "03151002"
    },
    {
      name: {
        km: "មាឃ ២",
        latin: "Meakh Pir"
      },
      id: "03151003"
    },
    {
      name: {
        km: "ត្នោតតាសាយ",
        latin: "Tnaot Ta Say"
      },
      id: "03151004"
    },
    {
      name: {
        km: "បឹងកាចូត",
        latin: "Boeng Kachout"
      },
      id: "03151005"
    },
    {
      name: {
        km: "អូរប្រាំទី ២",
        latin: "Ou Pram Ti Pir"
      },
      id: "03151006"
    },
    {
      name: {
        km: "ទួលពោធិ៍",
        latin: "Tuol Pou"
      },
      id: "03151007"
    },
    {
      name: {
        km: "ព្រះ",
        latin: "Preah"
      },
      id: "03151008"
    },
    {
      name: {
        km: "ភ្នំមន្ទីរ",
        latin: "Phnum Montir"
      },
      id: "03151009"
    },
    {
      name: {
        km: "ព្រែកបារាំង",
        latin: "Preaek Barang"
      },
      id: "03151010"
    },
    {
      name: {
        km: "បឹងដែង",
        latin: "Boeng Daeng"
      },
      id: "03151011"
    },
    {
      name: {
        km: "ភ្នំអំពិល",
        latin: "Phnum Ampil"
      },
      id: "03151012"
    },
    {
      name: {
        km: "បឹងកេតលើ",
        latin: "Boeng Ket Leu"
      },
      id: "03151013"
    },
    {
      name: {
        km: "បឹងកេតក្រោម",
        latin: "Boeng Ket Kraom"
      },
      id: "03151014"
    },
    {
      name: {
        km: "ភូមិ ៥៦",
        latin: "Phum Haprammuoy"
      },
      id: "03151015"
    },
    {
      name: {
        km: "ចេកជ្វា",
        latin: "Chek Chvea"
      },
      id: "03151016"
    },
    {
      name: {
        km: "អូរចក",
        latin: "Ou Chak"
      },
      id: "03151017"
    },
    {
      name: {
        km: "ភូមិ គីឡូ ៣",
        latin: "Phum Kilou Bei"
      },
      id: "03151018"
    },
    {
      name: {
        km: "ភូមិ ៣៥",
        latin: "Phum Sampram"
      },
      id: "03151019"
    },
    {
      name: {
        km: "ភូមិ ៧០",
        latin: "Phum Chetseb"
      },
      id: "03151020"
    },
    {
      name: {
        km: "អូរប្រាំទី១",
        latin: "Ou Pram Ti Muoy"
      },
      id: "03151021"
    },
    {
      name: {
        km: "មាឃ ៣",
        latin: "Meakh Bei"
      },
      id: "03151022"
    },
    {
      name: {
        km: "អណ្ដូងស្វាយ",
        latin: "Andoung Svay"
      },
      id: "03151023"
    },
    {
      name: {
        km: "សូភាស",
        latin: "Soupheas"
      },
      id: "03151201"
    },
    {
      name: {
        km: "អង្កោល",
        latin: "Angkaol"
      },
      id: "03151202"
    },
    {
      name: {
        km: "ស្រប",
        latin: "Srab"
      },
      id: "03151203"
    },
    {
      name: {
        km: "ប៉ប្រក",
        latin: "Paprak"
      },
      id: "03151204"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "03151205"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "03151206"
    },
    {
      name: {
        km: "ទ័ព",
        latin: "Toap"
      },
      id: "03151207"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "03151301"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "03151302"
    },
    {
      name: {
        km: "តាមើង",
        latin: "Ta Meung"
      },
      id: "03151303"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "03151304"
    },
    {
      name: {
        km: "ដូនទរ",
        latin: "Doun Tor"
      },
      id: "03151305"
    },
    {
      name: {
        km: "សំពៀងលើ",
        latin: "Sampieng Leu"
      },
      id: "03151306"
    },
    {
      name: {
        km: "សំពៀងក្រោម",
        latin: "Sampieng Kraom"
      },
      id: "03151307"
    },
    {
      name: {
        km: "ទួលសំបួរ",
        latin: "Tuol Sambuor"
      },
      id: "03151401"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "03151402"
    },
    {
      name: {
        km: "ស្រែអំពៅ",
        latin: "Srae Ampov"
      },
      id: "03151403"
    },
    {
      name: {
        km: "វាលព្រះ",
        latin: "Veal Preah"
      },
      id: "03151404"
    },
    {
      name: {
        km: "អញ្ចាញរូង",
        latin: "Anhchanh Rung"
      },
      id: "04010101"
    },
    {
      name: {
        km: "អណ្ដូងរវៀង",
        latin: "Andoung Rovieng"
      },
      id: "04010102"
    },
    {
      name: {
        km: "ដំរីកូន",
        latin: "Damrei Koun"
      },
      id: "04010103"
    },
    {
      name: {
        km: "ព្រៃព្រាល",
        latin: "Prey Preal"
      },
      id: "04010104"
    },
    {
      name: {
        km: "ស្ទឹងថ្មី",
        latin: "Stueng Thmei"
      },
      id: "04010105"
    },
    {
      name: {
        km: "ថ្លុកជ្រៅ",
        latin: "Thlok Chrov"
      },
      id: "04010106"
    },
    {
      name: {
        km: "ឆ្នុកទ្រូ",
        latin: "Chhnok Tru"
      },
      id: "04010201"
    },
    {
      name: {
        km: "កំពង់ព្រះ",
        latin: "Kampong Preah"
      },
      id: "04010202"
    },
    {
      name: {
        km: "សេះស្លាប់",
        latin: "Seh Slab"
      },
      id: "04010203"
    },
    {
      name: {
        km: "តាប៉ាង",
        latin: "Ta Pang"
      },
      id: "04010301"
    },
    {
      name: {
        km: "ពោម្រះ",
        latin: "Pou Mreah"
      },
      id: "04010302"
    },
    {
      name: {
        km: "អូររំចេក",
        latin: "Ou Rumchek"
      },
      id: "04010303"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "04010304"
    },
    {
      name: {
        km: "ដង្ខៅម៉ៅ",
        latin: "Dangkhau Mau"
      },
      id: "04010305"
    },
    {
      name: {
        km: "ស្នោ",
        latin: "Snao"
      },
      id: "04010401"
    },
    {
      name: {
        km: "ដើមជ្រែ",
        latin: "Daeum Chreae"
      },
      id: "04010402"
    },
    {
      name: {
        km: "សិរី",
        latin: "Serei"
      },
      id: "04010403"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "04010404"
    },
    {
      name: {
        km: "កន្សែង",
        latin: "Kansaeng"
      },
      id: "04010405"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "04010406"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "04010407"
    },
    {
      name: {
        km: "ពពេល",
        latin: "Popel"
      },
      id: "04010408"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "04010409"
    },
    {
      name: {
        km: "កំពង់អួរ",
        latin: "Kampong Uor"
      },
      id: "04010410"
    },
    {
      name: {
        km: "ព្រែកស្ពាន",
        latin: "Preaek Spean"
      },
      id: "04010501"
    },
    {
      name: {
        km: "កោះតាម៉ូវ",
        latin: "Kaoh Ta Mouv"
      },
      id: "04010502"
    },
    {
      name: {
        km: "ស្ទឺងជ្រៅ",
        latin: "Stueng Chrov"
      },
      id: "04010503"
    },
    {
      name: {
        km: "ដំណាក់រាជ្យ",
        latin: "Damnak Reach"
      },
      id: "04010504"
    },
    {
      name: {
        km: "ស្រះកែវ",
        latin: "Srah Kaev"
      },
      id: "04010601"
    },
    {
      name: {
        km: "ទួលធ្លក",
        latin: "Tuol Thlok"
      },
      id: "04010602"
    },
    {
      name: {
        km: "មេលំ",
        latin: "Melum"
      },
      id: "04010603"
    },
    {
      name: {
        km: "កាន់យួរ",
        latin: "Kan Yuor"
      },
      id: "04010604"
    },
    {
      name: {
        km: "ទួលរការ",
        latin: "Tuol Rokar"
      },
      id: "04010605"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "04010701"
    },
    {
      name: {
        km: "កំព្រង",
        latin: "Kamprong"
      },
      id: "04010702"
    },
    {
      name: {
        km: "ផ្សារ",
        latin: "Phsar"
      },
      id: "04010703"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04010704"
    },
    {
      name: {
        km: "ជំទាវបុត្រី",
        latin: "Chumteav Botrei"
      },
      id: "04010705"
    },
    {
      name: {
        km: "ព្រៃតាមូង",
        latin: "Prey Ta Mung"
      },
      id: "04010706"
    },
    {
      name: {
        km: "ភ្នៀត",
        latin: "Phniet"
      },
      id: "04010707"
    },
    {
      name: {
        km: "តាំងធ្នឹម",
        latin: "Tang Thnuem"
      },
      id: "04010801"
    },
    {
      name: {
        km: "តាំងត្រពាំង",
        latin: "Tang Trapeang"
      },
      id: "04010802"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "04010803"
    },
    {
      name: {
        km: "ក្រាំងកកោះ",
        latin: "Krang Kakaoh"
      },
      id: "04010804"
    },
    {
      name: {
        km: "ទឹកជ្រាប់",
        latin: "Tuek Chroab"
      },
      id: "04010805"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "04010806"
    },
    {
      name: {
        km: "ជាងហ្លួង",
        latin: "Cheang Luong"
      },
      id: "04010901"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "04010902"
    },
    {
      name: {
        km: "ទួលពោធិ៍",
        latin: "Tuol Pou"
      },
      id: "04010903"
    },
    {
      name: {
        km: "ក្រោលជី",
        latin: "Kraol Chi"
      },
      id: "04010904"
    },
    {
      name: {
        km: "បុសមាស",
        latin: "Bos Meas"
      },
      id: "04010905"
    },
    {
      name: {
        km: "សាលាឃុំ",
        latin: "Sala Khum"
      },
      id: "04010906"
    },
    {
      name: {
        km: "ក្រាំងខ្មែរ",
        latin: "Krang Khmer"
      },
      id: "04010907"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "04010908"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "04011001"
    },
    {
      name: {
        km: "ជើងខ្នារ",
        latin: "Cheung Khnar"
      },
      id: "04011002"
    },
    {
      name: {
        km: "កែវឡាត",
        latin: "Kaev Lat"
      },
      id: "04011003"
    },
    {
      name: {
        km: "ស្វាយគយ",
        latin: "Svay Koy"
      },
      id: "04011004"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "04011005"
    },
    {
      name: {
        km: "ជាងហ្លួង",
        latin: "Cheang Luong"
      },
      id: "04011006"
    },
    {
      name: {
        km: "ត្រពាំងចាន់",
        latin: "Trapeang Chan"
      },
      id: "04011101"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "04011102"
    },
    {
      name: {
        km: "សន្លង់",
        latin: "Sanlang"
      },
      id: "04011103"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "04011104"
    },
    {
      name: {
        km: "កំពង់ខ្លាញ់",
        latin: "Kampong Khlanh"
      },
      id: "04020101"
    },
    {
      name: {
        km: "ទន្លេក្រៅ",
        latin: "Tonle Krau"
      },
      id: "04020102"
    },
    {
      name: {
        km: "ឫស្សីដង្គួច",
        latin: "Ruessei Dangkuoch"
      },
      id: "04020103"
    },
    {
      name: {
        km: "ព្រែកទ្រព្យ",
        latin: "Preaek Trop"
      },
      id: "04020104"
    },
    {
      name: {
        km: "កំពង់បាស្រូវ",
        latin: "Kampong Ba Srov"
      },
      id: "04020105"
    },
    {
      name: {
        km: "កំពង់បាស្រូវត្បូង",
        latin: "Kampong Ba Srov Tboung"
      },
      id: "04020106"
    },
    {
      name: {
        km: "បត់ត្រង់",
        latin: "Bat Trang"
      },
      id: "04020201"
    },
    {
      name: {
        km: "កោះលត",
        latin: "Kaoh Lot"
      },
      id: "04020202"
    },
    {
      name: {
        km: "កោះស្លែង",
        latin: "Kaoh Slaeng"
      },
      id: "04020203"
    },
    {
      name: {
        km: "ពាមពពេច",
        latin: "Peam Popech"
      },
      id: "04020204"
    },
    {
      name: {
        km: "តាមលលើ",
        latin: "Ta Mol Leu"
      },
      id: "04020205"
    },
    {
      name: {
        km: "កោះថ្កូវ",
        latin: "Kaoh Thkov"
      },
      id: "04020206"
    },
    {
      name: {
        km: "តាមលក្រោម",
        latin: "Ta Mol Kraom"
      },
      id: "04020207"
    },
    {
      name: {
        km: "គជ្រុំ",
        latin: "Ko Chrum"
      },
      id: "04020208"
    },
    {
      name: {
        km: "ដងទង់",
        latin: "Dang Tong"
      },
      id: "04020209"
    },
    {
      name: {
        km: "ពាមជ្រៃ",
        latin: "Peam Chrey"
      },
      id: "04020210"
    },
    {
      name: {
        km: "កំពង់អុស",
        latin: "Kampong Os"
      },
      id: "04020301"
    },
    {
      name: {
        km: "កៀនតាម៉ា",
        latin: "Kien Ta Ma"
      },
      id: "04020302"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04020303"
    },
    {
      name: {
        km: "ព្រែកឆ្ដោ",
        latin: "Preaek Chhdaor"
      },
      id: "04020304"
    },
    {
      name: {
        km: "ពាមឆ្កោក",
        latin: "Peam Chhkaok"
      },
      id: "04020401"
    },
    {
      name: {
        km: "អន្លង់មេត្រី",
        latin: "Anlong Metrei"
      },
      id: "04020402"
    },
    {
      name: {
        km: "ភ្លង",
        latin: "Phlong"
      },
      id: "04020403"
    },
    {
      name: {
        km: "ក្បាលកន្លង់",
        latin: "Kbal Kanlang"
      },
      id: "04020404"
    },
    {
      name: {
        km: "ព្រៃគ្រីត្បូង",
        latin: "Prey Kri Tboung"
      },
      id: "04020501"
    },
    {
      name: {
        km: "កោះធំ",
        latin: "Kaoh Thum"
      },
      id: "04020502"
    },
    {
      name: {
        km: "ព្រៃគ្រីជើង",
        latin: "Prey Kri Cheung"
      },
      id: "04020503"
    },
    {
      name: {
        km: "ជំពារ",
        latin: "Chum Pear"
      },
      id: "04020504"
    },
    {
      name: {
        km: "តាកាន់",
        latin: "Ta Khan"
      },
      id: "04020505"
    },
    {
      name: {
        km: "ផ្សារលើ",
        latin: "Phsar Leu"
      },
      id: "04030101"
    },
    {
      name: {
        km: "ផ្សារឆ្នាំង",
        latin: "Phsar Chhnang"
      },
      id: "04030102"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "04030103"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "04030104"
    },
    {
      name: {
        km: "កោះក្របី",
        latin: "Kaoh Krabei"
      },
      id: "04030105"
    },
    {
      name: {
        km: "ត្រពាំងបី",
        latin: "Trapeang Bei"
      },
      id: "04030106"
    },
    {
      name: {
        km: "កំពង់អុស",
        latin: "Kampong Os"
      },
      id: "04030107"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "04030108"
    },
    {
      name: {
        km: "ស្រែព្រីង",
        latin: "Srae Pring"
      },
      id: "04030201"
    },
    {
      name: {
        km: "ដំណាក់ពពូល",
        latin: "Damnak Popul"
      },
      id: "04030202"
    },
    {
      name: {
        km: "ឡទឹកត្រី",
        latin: "La Tuek Trei"
      },
      id: "04030203"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "04030204"
    },
    {
      name: {
        km: "ត្រពាំងចឹកសា",
        latin: "Trapeang Choek Sa"
      },
      id: "04030205"
    },
    {
      name: {
        km: "ទួលក្រឡាញ់",
        latin: "Tuol Kralanh"
      },
      id: "04030206"
    },
    {
      name: {
        km: "ប្អេរ",
        latin: "B'er"
      },
      id: "04030301"
    },
    {
      name: {
        km: "ធម្មយុត្ដិ",
        latin: "Thommeak Yutt"
      },
      id: "04030302"
    },
    {
      name: {
        km: "ម៉ុងបារាំង",
        latin: "Mong Barang"
      },
      id: "04030303"
    },
    {
      name: {
        km: "ឃ្លាំងប្រាក់",
        latin: "Khleang Prak"
      },
      id: "04030304"
    },
    {
      name: {
        km: "ទី១",
        latin: "Ti Muoy"
      },
      id: "04030401"
    },
    {
      name: {
        km: "ទី២",
        latin: "Ti Pir"
      },
      id: "04030402"
    },
    {
      name: {
        km: "ទី៣",
        latin: "Ti Bei"
      },
      id: "04030403"
    },
    {
      name: {
        km: "ទី៤",
        latin: "Ti Buon"
      },
      id: "04030404"
    },
    {
      name: {
        km: "ទី៥",
        latin: "Ti Pram"
      },
      id: "04030405"
    },
    {
      name: {
        km: "ទី៦",
        latin: "Ti Prammuoy"
      },
      id: "04030406"
    },
    {
      name: {
        km: "ទី៧",
        latin: "Ti Prampir"
      },
      id: "04030407"
    },
    {
      name: {
        km: "ទី៨",
        latin: "Ti Prambei"
      },
      id: "04030408"
    },
    {
      name: {
        km: "កង្កែប",
        latin: "Kangkaeb"
      },
      id: "04040101"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "04040102"
    },
    {
      name: {
        km: "ឯលិច",
        latin: "Ae Lech"
      },
      id: "04040103"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "04040104"
    },
    {
      name: {
        km: "ដារ",
        latin: "Dar"
      },
      id: "04040201"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "04040202"
    },
    {
      name: {
        km: "ជ្រលង",
        latin: "Chrolong"
      },
      id: "04040203"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "04040204"
    },
    {
      name: {
        km: "គុយ",
        latin: "Kuy"
      },
      id: "04040205"
    },
    {
      name: {
        km: "កំពង់បឹង",
        latin: "Kampong Boeng"
      },
      id: "04040301"
    },
    {
      name: {
        km: "កែងតាសុខ",
        latin: "Kaeng Ta Sokh"
      },
      id: "04040302"
    },
    {
      name: {
        km: "ត្អួរលំ",
        latin: "T'uor lum"
      },
      id: "04040303"
    },
    {
      name: {
        km: "ស្ទឹងសណ្ដែក",
        latin: "Stueng Sandaek"
      },
      id: "04040304"
    },
    {
      name: {
        km: "កោះក្អែក",
        latin: "Kaoh K'aek"
      },
      id: "04040305"
    },
    {
      name: {
        km: "ដូនវៀត",
        latin: "Doun Viet"
      },
      id: "04040306"
    },
    {
      name: {
        km: "ថ្នល់ឈើទាល",
        latin: "Thnal Chheu Teal"
      },
      id: "04040401"
    },
    {
      name: {
        km: "ពាមខ្នង",
        latin: "Peam Khnang"
      },
      id: "04040402"
    },
    {
      name: {
        km: "ស្លត",
        latin: "Slat"
      },
      id: "04040403"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "04040501"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "04040502"
    },
    {
      name: {
        km: "ពាមទន្លា",
        latin: "Peam Tonlea"
      },
      id: "04040503"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04040504"
    },
    {
      name: {
        km: "ដំណាក់កកោះ",
        latin: "Damnak Kakaoh"
      },
      id: "04040505"
    },
    {
      name: {
        km: "កំពង់បាចិន",
        latin: "Kampong Ba Chen"
      },
      id: "04040506"
    },
    {
      name: {
        km: "ប្រឡាយមាស",
        latin: "Pralay Meas"
      },
      id: "04040601"
    },
    {
      name: {
        km: "ក្រងផ្ដិល",
        latin: "Krang Phtel"
      },
      id: "04040602"
    },
    {
      name: {
        km: "ក្រម៉ាល់",
        latin: "Kramal"
      },
      id: "04040603"
    },
    {
      name: {
        km: "អន្លង់កញ្ចុះ",
        latin: "Anlong Kanhchoh"
      },
      id: "04040604"
    },
    {
      name: {
        km: "តាដោក",
        latin: "Ta Daok"
      },
      id: "04040605"
    },
    {
      name: {
        km: "កោះឫស្សី",
        latin: "Kaoh Ruessei"
      },
      id: "04040606"
    },
    {
      name: {
        km: "សំរោងសែន",
        latin: "Samraong Saen"
      },
      id: "04040701"
    },
    {
      name: {
        km: "ប៉ប្រក",
        latin: "Paprak"
      },
      id: "04040702"
    },
    {
      name: {
        km: "ចំបក់ខ្ពស់",
        latin: "Chambak Khpos"
      },
      id: "04040801"
    },
    {
      name: {
        km: "ក្នុង",
        latin: "Knong"
      },
      id: "04040802"
    },
    {
      name: {
        km: "ជើងគ្រួស",
        latin: "Cheung Kruos"
      },
      id: "04040803"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "04040804"
    },
    {
      name: {
        km: "តាឡាត់",
        latin: "Ta Lat"
      },
      id: "04040805"
    },
    {
      name: {
        km: "ត្រងិល",
        latin: "Trangel"
      },
      id: "04040901"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "04040902"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "04040903"
    },
    {
      name: {
        km: "ទំនប់",
        latin: "Tumnob"
      },
      id: "04040904"
    },
    {
      name: {
        km: "ត្រពាំងមាស",
        latin: "Trapeang Meas"
      },
      id: "04040905"
    },
    {
      name: {
        km: "អណ្ដូងរនុក",
        latin: "Andoung Ronuk"
      },
      id: "04040906"
    },
    {
      name: {
        km: "ខ្លែងពណ៌",
        latin: "Khlaeng Poar"
      },
      id: "04040907"
    },
    {
      name: {
        km: "ស្ទឹងស្ងួត",
        latin: "Stueng Snguot"
      },
      id: "04050101"
    },
    {
      name: {
        km: "គៀនឃ្លាំង",
        latin: "Kien Khleang"
      },
      id: "04050102"
    },
    {
      name: {
        km: "វាលស្បូវ",
        latin: "Veal Sbov"
      },
      id: "04050103"
    },
    {
      name: {
        km: "ក្អែកពង",
        latin: "K'aek Pong"
      },
      id: "04050104"
    },
    {
      name: {
        km: "អំពិលទឹក",
        latin: "Ampil Tuek"
      },
      id: "04050105"
    },
    {
      name: {
        km: "បែកចាន",
        latin: "Baek Chan"
      },
      id: "04050106"
    },
    {
      name: {
        km: "អូរម៉ាល់",
        latin: "Ou Mal"
      },
      id: "04050107"
    },
    {
      name: {
        km: "បាក់ភ្នំ",
        latin: "Bak Phnum"
      },
      id: "04050108"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "04050109"
    },
    {
      name: {
        km: "ខ្លាគ្រហឹម",
        latin: "Khla Krohuem"
      },
      id: "04050110"
    },
    {
      name: {
        km: "ស្ដីបន្លិច",
        latin: "Sdei Banlich"
      },
      id: "04050111"
    },
    {
      name: {
        km: "អណ្ដូងត្រមូង",
        latin: "Andoung Tramung"
      },
      id: "04050201"
    },
    {
      name: {
        km: "ច្រករមៀត",
        latin: "Chrak Romiet"
      },
      id: "04050202"
    },
    {
      name: {
        km: "ព្រៃពិស",
        latin: "Prey Pis"
      },
      id: "04050203"
    },
    {
      name: {
        km: "ព្រៃពារ",
        latin: "Prey Pear"
      },
      id: "04050204"
    },
    {
      name: {
        km: "ស្នាពេជ្រ",
        latin: "Snar Pechr"
      },
      id: "04050205"
    },
    {
      name: {
        km: "ឈូកក្រញ៉ាស់",
        latin: "Chhuk Kranhas"
      },
      id: "04050206"
    },
    {
      name: {
        km: "ក្រសះថ្មី",
        latin: "Krasah Thmei"
      },
      id: "04050207"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៅ",
        latin: "Trapeang Chrov"
      },
      id: "04050208"
    },
    {
      name: {
        km: "ត្រពាំងខ្ទុំ",
        latin: "Trapeang Khtum"
      },
      id: "04050209"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "04050210"
    },
    {
      name: {
        km: "អូររូង",
        latin: "Ou Rung"
      },
      id: "04050211"
    },
    {
      name: {
        km: "ស្ដុកលិច",
        latin: "Sdok Lech"
      },
      id: "04050212"
    },
    {
      name: {
        km: "ស្រែសារ",
        latin: "Srae Sar"
      },
      id: "04050213"
    },
    {
      name: {
        km: "ជ្រលងកៃស្នា",
        latin: "Chrolong Kaisna"
      },
      id: "04050214"
    },
    {
      name: {
        km: "ត្រពាំងភ្នៅ",
        latin: "Trapeang Pnov"
      },
      id: "04050301"
    },
    {
      name: {
        km: "សេរីឆោម",
        latin: "Serei Chhaom"
      },
      id: "04050302"
    },
    {
      name: {
        km: "វាលល្វៀង",
        latin: "Veal Lvieng"
      },
      id: "04050303"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "04050304"
    },
    {
      name: {
        km: "ជំទាវសុខ",
        latin: "Chumteav Sokh"
      },
      id: "04050305"
    },
    {
      name: {
        km: "សារាយអណ្ដែត",
        latin: "Saray Andaet"
      },
      id: "04050306"
    },
    {
      name: {
        km: "កញ្ជោង",
        latin: "Kanhchoung"
      },
      id: "04050307"
    },
    {
      name: {
        km: "ព្រៃពិស",
        latin: "Prey Pis"
      },
      id: "04050308"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "04050309"
    },
    {
      name: {
        km: "ប្របផ្ទះ",
        latin: "Prab Phteah"
      },
      id: "04050310"
    },
    {
      name: {
        km: "ច្រមុះជ្រូក",
        latin: "Chramoh Chruk"
      },
      id: "04050311"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "04050312"
    },
    {
      name: {
        km: "ដកស្និត",
        latin: "Dak Snet"
      },
      id: "04050313"
    },
    {
      name: {
        km: "ជំទាវ",
        latin: "Chumteav"
      },
      id: "04050314"
    },
    {
      name: {
        km: "កំពង់ត្រឡាចលើ",
        latin: "Kampong Tralach Leu"
      },
      id: "04050401"
    },
    {
      name: {
        km: "កំពង់ត្រឡាចក្រោម",
        latin: "Kampong Tralach Kraom"
      },
      id: "04050402"
    },
    {
      name: {
        km: "អ្នកតាហាង",
        latin: "Neak Ta Hang"
      },
      id: "04050403"
    },
    {
      name: {
        km: "សំរិទ្ធិជ័យ",
        latin: "Samretthi Chey"
      },
      id: "04050404"
    },
    {
      name: {
        km: "ព្រែកគន្លង",
        latin: "Preaek Kanlang"
      },
      id: "04050405"
    },
    {
      name: {
        km: "កំពង់ក្ដារ",
        latin: "Kampong Kdar"
      },
      id: "04050406"
    },
    {
      name: {
        km: "គៀនរការ",
        latin: "Kien Roka"
      },
      id: "04050407"
    },
    {
      name: {
        km: "ឧកញ៉ាប៉ាង",
        latin: "Oknha Pang"
      },
      id: "04050501"
    },
    {
      name: {
        km: "ត្រពាំងចំបក់",
        latin: "Trapeang Chambak"
      },
      id: "04050502"
    },
    {
      name: {
        km: "ផ្សារត្រាច",
        latin: "Phsar Trach"
      },
      id: "04050503"
    },
    {
      name: {
        km: "អន្លង់ត្នោត",
        latin: "Anlong Tnaot"
      },
      id: "04050504"
    },
    {
      name: {
        km: "ស្រះចក",
        latin: "Srah Chak"
      },
      id: "04050505"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "04050506"
    },
    {
      name: {
        km: "ត្រពាំងសំរោង",
        latin: "Trapeang Samraong"
      },
      id: "04050507"
    },
    {
      name: {
        km: "បឹងកក់",
        latin: "Boeng Kak"
      },
      id: "04050508"
    },
    {
      name: {
        km: "ច្រករមៀត",
        latin: "Chrak Romiet"
      },
      id: "04050601"
    },
    {
      name: {
        km: "សាលាលេខប្រាំ",
        latin: "Sala Lekh Pram"
      },
      id: "04050602"
    },
    {
      name: {
        km: "អូរឫស្សី",
        latin: "Ou Ruessei"
      },
      id: "04050603"
    },
    {
      name: {
        km: "ស្រែប្រិយ៍",
        latin: "Srae Prei"
      },
      id: "04050604"
    },
    {
      name: {
        km: "ចាន់កៀក",
        latin: "Chan Kiek"
      },
      id: "04050605"
    },
    {
      name: {
        km: "ក្រឡាញ់",
        latin: "Kralanh"
      },
      id: "04050606"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "04050607"
    },
    {
      name: {
        km: "លាជ",
        latin: "Leach"
      },
      id: "04050608"
    },
    {
      name: {
        km: "កាអត",
        latin: "Ka At"
      },
      id: "04050701"
    },
    {
      name: {
        km: "សុបិន",
        latin: "Soben"
      },
      id: "04050702"
    },
    {
      name: {
        km: "តាកុល",
        latin: "Ta Kol"
      },
      id: "04050703"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "04050704"
    },
    {
      name: {
        km: "ព្រៃសាក់",
        latin: "Prey Sak"
      },
      id: "04050705"
    },
    {
      name: {
        km: "ពានី",
        latin: "Peani"
      },
      id: "04050706"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "04050707"
    },
    {
      name: {
        km: "ទួលសេរី",
        latin: "Tuol Serei"
      },
      id: "04050708"
    },
    {
      name: {
        km: "ក្រាំងតាឯក",
        latin: "Krang Ta Aek"
      },
      id: "04050709"
    },
    {
      name: {
        km: "ខ្នាយកកោះ",
        latin: "Khnay Kakaoh"
      },
      id: "04050801"
    },
    {
      name: {
        km: "ទឹកល្អក់",
        latin: "Tuek L'ak"
      },
      id: "04050802"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "04050803"
    },
    {
      name: {
        km: "តាសុខ",
        latin: "Ta Sokh"
      },
      id: "04050804"
    },
    {
      name: {
        km: "តាសូ",
        latin: "Ta Sou"
      },
      id: "04050805"
    },
    {
      name: {
        km: "ដូនទយ",
        latin: "Doun Toy"
      },
      id: "04050806"
    },
    {
      name: {
        km: "តាណុប",
        latin: "Ta Nob"
      },
      id: "04050807"
    },
    {
      name: {
        km: "ចំបក់ផ្អែម",
        latin: "Chambak Ph'aem"
      },
      id: "04050808"
    },
    {
      name: {
        km: "ក្រឡាញ់",
        latin: "Kralanh"
      },
      id: "04050809"
    },
    {
      name: {
        km: "កំពង់ប្រាសាទ",
        latin: "Kampong Prasat"
      },
      id: "04050810"
    },
    {
      name: {
        km: "ប្រវឹកពង",
        latin: "Pravoek Pong"
      },
      id: "04050811"
    },
    {
      name: {
        km: "ខ្នាយកកោះថ្មី",
        latin: "Khnay Kakaoh Thmei"
      },
      id: "04050812"
    },
    {
      name: {
        km: "បឹងកក់",
        latin: "Boeng Kak"
      },
      id: "04050901"
    },
    {
      name: {
        km: "ឡពាង",
        latin: "La Peang"
      },
      id: "04050902"
    },
    {
      name: {
        km: "អូររូង",
        latin: "Ou Rung"
      },
      id: "04050903"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "04050904"
    },
    {
      name: {
        km: "ស្វាយក្រោម",
        latin: "Svay Kraom"
      },
      id: "04050905"
    },
    {
      name: {
        km: "សូវង្ស",
        latin: "Souvong"
      },
      id: "04050906"
    },
    {
      name: {
        km: "ស្នាយ",
        latin: "Snay"
      },
      id: "04050907"
    },
    {
      name: {
        km: "ស្វាយបាកាវ",
        latin: "Svay Bakav"
      },
      id: "04050908"
    },
    {
      name: {
        km: "វត្ដថ្មី",
        latin: "Voat Thmei"
      },
      id: "04050909"
    },
    {
      name: {
        km: "ធ្លកយល់",
        latin: "Thlok Yol"
      },
      id: "04050910"
    },
    {
      name: {
        km: "តាកោះ",
        latin: "Ta Kaoh"
      },
      id: "04050911"
    },
    {
      name: {
        km: "ត្រពាំងព្រាល",
        latin: "Trapeang Preal"
      },
      id: "04050912"
    },
    {
      name: {
        km: "បន្ទាយមាស",
        latin: "Banteay Meas"
      },
      id: "04050913"
    },
    {
      name: {
        km: "សំព័រ",
        latin: "Sampoar"
      },
      id: "04050914"
    },
    {
      name: {
        km: "តាជេស",
        latin: "Ta Ches"
      },
      id: "04050915"
    },
    {
      name: {
        km: "ដើមពពេល",
        latin: "Daeum Popel"
      },
      id: "04051001"
    },
    {
      name: {
        km: "ស្នងមុំ",
        latin: "Snang Mom"
      },
      id: "04051002"
    },
    {
      name: {
        km: "ថ្មឥដ្ឋ",
        latin: "Thma Edth"
      },
      id: "04051003"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "04051004"
    },
    {
      name: {
        km: "ត្រពាំងក្ដារ",
        latin: "Trapeang Kdar"
      },
      id: "04051005"
    },
    {
      name: {
        km: "ស្ពករាជ្យ",
        latin: "Spok Reach"
      },
      id: "04060101"
    },
    {
      name: {
        km: "អណ្ដូងស្នាយ",
        latin: "Andoung Snay"
      },
      id: "04060102"
    },
    {
      name: {
        km: "អណ្ដូងជ្រៃ",
        latin: "Andoung Chrey"
      },
      id: "04060103"
    },
    {
      name: {
        km: "ចារថ្មី",
        latin: "Char Thmei"
      },
      id: "04060104"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "04060105"
    },
    {
      name: {
        km: "ប៉ាហ៊ី",
        latin: "Pahi"
      },
      id: "04060106"
    },
    {
      name: {
        km: "បំពង់ផ្ចឹក",
        latin: "Bampong Phchoek"
      },
      id: "04060107"
    },
    {
      name: {
        km: "ព្រាល",
        latin: "Preal"
      },
      id: "04060201"
    },
    {
      name: {
        km: "ថ្មរាប",
        latin: "Thma Reab"
      },
      id: "04060202"
    },
    {
      name: {
        km: "ផ្លូវគោ",
        latin: "Phlov Kou"
      },
      id: "04060203"
    },
    {
      name: {
        km: "ទ័ពស្រូវ",
        latin: "Toap Srov"
      },
      id: "04060204"
    },
    {
      name: {
        km: "ទ័ពត្បែង",
        latin: "Toap Tbaeng"
      },
      id: "04060205"
    },
    {
      name: {
        km: "ឈើនាគ",
        latin: "Chheu Neak"
      },
      id: "04060206"
    },
    {
      name: {
        km: "ស្ដុកកប្បាស",
        latin: "Sdok Kabbas"
      },
      id: "04060207"
    },
    {
      name: {
        km: "ត្រពាំងផ្គាំ",
        latin: "Trapeang Phkoam"
      },
      id: "04060208"
    },
    {
      name: {
        km: "អូរលាជ",
        latin: "Ou Leach"
      },
      id: "04060209"
    },
    {
      name: {
        km: "ឫស្សីដួច",
        latin: "Ruessei Duoch"
      },
      id: "04060210"
    },
    {
      name: {
        km: "តាលោ",
        latin: "Ta Lou"
      },
      id: "04060301"
    },
    {
      name: {
        km: "ត្រពាំងពពេល",
        latin: "Trapeang Popel"
      },
      id: "04060302"
    },
    {
      name: {
        km: "ព្រីងកោង",
        latin: "Pring Kaong"
      },
      id: "04060303"
    },
    {
      name: {
        km: "ហ្លួង",
        latin: "Luong"
      },
      id: "04060304"
    },
    {
      name: {
        km: "អណ្ដូងចេក",
        latin: "Andoung Chek"
      },
      id: "04060305"
    },
    {
      name: {
        km: "ទឹកចេញ",
        latin: "Tuek Chenh"
      },
      id: "04060306"
    },
    {
      name: {
        km: "សូភី",
        latin: "Souphi"
      },
      id: "04060307"
    },
    {
      name: {
        km: "តាំងបំពង់",
        latin: "Tang Bampong"
      },
      id: "04060308"
    },
    {
      name: {
        km: "ដំណាក់កី",
        latin: "Damnak Kei"
      },
      id: "04060309"
    },
    {
      name: {
        km: "ខ្នាចកកោះ",
        latin: "Khnach Kakaoh"
      },
      id: "04060310"
    },
    {
      name: {
        km: "អូរលយ",
        latin: "Ou Loy"
      },
      id: "04060311"
    },
    {
      name: {
        km: "ទួលខ្សាច់",
        latin: "Tuol Khsach"
      },
      id: "04060401"
    },
    {
      name: {
        km: "ព្រៃកោះ",
        latin: "Prey Kaoh"
      },
      id: "04060402"
    },
    {
      name: {
        km: "ព្រៃពួច",
        latin: "Prey Puoch"
      },
      id: "04060403"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04060404"
    },
    {
      name: {
        km: "ថ្នល់ថ្មី",
        latin: "Thnal Thmei"
      },
      id: "04060405"
    },
    {
      name: {
        km: "ជ្រៃបាក់",
        latin: "Chrey Bak"
      },
      id: "04060406"
    },
    {
      name: {
        km: "អូរកំណប់",
        latin: "Ou Kamnab"
      },
      id: "04060407"
    },
    {
      name: {
        km: "អូរកណ្ដោល",
        latin: "Ou Kandaol"
      },
      id: "04060408"
    },
    {
      name: {
        km: "ព្រៃលាក់នាង",
        latin: "Prey Leak Neang"
      },
      id: "04060409"
    },
    {
      name: {
        km: "បាញ់ឆ្គោល",
        latin: "Banh Chhkoul"
      },
      id: "04060410"
    },
    {
      name: {
        km: "ត្រពាំងអន",
        latin: "Trapeang An"
      },
      id: "04060411"
    },
    {
      name: {
        km: "ឃ្លៃ",
        latin: "Khley"
      },
      id: "04060412"
    },
    {
      name: {
        km: "អាឡែង",
        latin: "Alaeng"
      },
      id: "04060413"
    },
    {
      name: {
        km: "ត្រពាំងគរ",
        latin: "Trapeang Kor"
      },
      id: "04060414"
    },
    {
      name: {
        km: "ព្រះរាមរង្សី",
        latin: "Preah Ream Reangsei"
      },
      id: "04060415"
    },
    {
      name: {
        km: "អូររោង",
        latin: "Ou Roung"
      },
      id: "04060416"
    },
    {
      name: {
        km: "គោកបន្ទាយ",
        latin: "Kouk Banteay"
      },
      id: "04060501"
    },
    {
      name: {
        km: "ពពេលពក",
        latin: "Popel Pok"
      },
      id: "04060502"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "04060503"
    },
    {
      name: {
        km: "ទ្រនាមពេជ្រ",
        latin: "Troneam Pech"
      },
      id: "04060504"
    },
    {
      name: {
        km: "អូរតាសេក",
        latin: "Ou Ta Sek"
      },
      id: "04060505"
    },
    {
      name: {
        km: "ឈើត្រាច",
        latin: "Chheu Trach"
      },
      id: "04060506"
    },
    {
      name: {
        km: "កងមាស",
        latin: "Kang Meas"
      },
      id: "04060507"
    },
    {
      name: {
        km: "កន្លែងភេ",
        latin: "Kanlaeng Phe"
      },
      id: "04060508"
    },
    {
      name: {
        km: "ក្រាំងលាវ",
        latin: "Krang Leav"
      },
      id: "04060601"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04060602"
    },
    {
      name: {
        km: "ទឹកល្អក់",
        latin: "Tuek L'ak"
      },
      id: "04060603"
    },
    {
      name: {
        km: "ប៉ាតឡាង",
        latin: "Pat Lang"
      },
      id: "04060604"
    },
    {
      name: {
        km: "បឹងវែង",
        latin: "Boeng Veaeng"
      },
      id: "04060605"
    },
    {
      name: {
        km: "ស្រែវាល",
        latin: "Srae Veal"
      },
      id: "04060606"
    },
    {
      name: {
        km: "ជ្រលងកក់",
        latin: "Chrolong Kak"
      },
      id: "04060607"
    },
    {
      name: {
        km: "អណ្ដូងប្រេង",
        latin: "Andoung Preng"
      },
      id: "04060608"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "04060701"
    },
    {
      name: {
        km: "ខ្វិត",
        latin: "Khvet"
      },
      id: "04060702"
    },
    {
      name: {
        km: "ស្រាងខ្ពស់",
        latin: "Srang Khpos"
      },
      id: "04060703"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "04060704"
    },
    {
      name: {
        km: "សន្ទៃ",
        latin: "Santey"
      },
      id: "04060705"
    },
    {
      name: {
        km: "ដក់គ្រង",
        latin: "Dak Krong"
      },
      id: "04060706"
    },
    {
      name: {
        km: "អណ្ដូងពោធិ៍",
        latin: "Andoung Pou"
      },
      id: "04060707"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04060708"
    },
    {
      name: {
        km: "ប្រាំបីឆោម",
        latin: "Prambei Chhaom"
      },
      id: "04060709"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "04060710"
    },
    {
      name: {
        km: "ថ្មរាប",
        latin: "Thma Reab"
      },
      id: "04060711"
    },
    {
      name: {
        km: "ទ័ពត្បែង",
        latin: "Toap Tbaeng"
      },
      id: "04060712"
    },
    {
      name: {
        km: "ព្រៃសំពៅ",
        latin: "Prey Sampov"
      },
      id: "04060801"
    },
    {
      name: {
        km: "ជន្លាវ",
        latin: "Chonleav"
      },
      id: "04060802"
    },
    {
      name: {
        km: "សាអង",
        latin: "S​a ang"
      },
      id: "04060803"
    },
    {
      name: {
        km: "ស្រងាំទេរ",
        latin: "Srangam Ter"
      },
      id: "04060804"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "04060805"
    },
    {
      name: {
        km: "ប្រស្នឹប",
        latin: "Prasnoeb"
      },
      id: "04060806"
    },
    {
      name: {
        km: "ជរ",
        latin: "Chor"
      },
      id: "04060807"
    },
    {
      name: {
        km: "ព្រៃក្រោល",
        latin: "Prey Kraol"
      },
      id: "04060901"
    },
    {
      name: {
        km: "ព្រៃមូល",
        latin: "Prey Mul"
      },
      id: "04060902"
    },
    {
      name: {
        km: "ប្រច័ក្ស",
        latin: "Prachak"
      },
      id: "04060903"
    },
    {
      name: {
        km: "ត្រពាំងក្រវ៉ាន់",
        latin: "Trapeang Kravan"
      },
      id: "04060904"
    },
    {
      name: {
        km: "ត្រពាំងតាសុខ",
        latin: "Trapeang Ta Sokh"
      },
      id: "04060905"
    },
    {
      name: {
        km: "តាវ៉ាក",
        latin: "Ta Vak"
      },
      id: "04060906"
    },
    {
      name: {
        km: "ក្លែងពណ៌",
        latin: "Klaeng Poar"
      },
      id: "04060907"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "04061001"
    },
    {
      name: {
        km: "ព្រៃខ្មែរ",
        latin: "Prey Khmer"
      },
      id: "04061002"
    },
    {
      name: {
        km: "អណ្ដូងច្រុះ",
        latin: "Andoung Chroh"
      },
      id: "04061003"
    },
    {
      name: {
        km: "ជារៅ",
        latin: "Chea Rov"
      },
      id: "04061004"
    },
    {
      name: {
        km: "ត្រពាំងត្រាច",
        latin: "Trapeang Trach"
      },
      id: "04061005"
    },
    {
      name: {
        km: "អូរតានេស",
        latin: "Ou Ta Nes"
      },
      id: "04061006"
    },
    {
      name: {
        km: "ក្ដីត្នោត",
        latin: "Kdei Tnaot"
      },
      id: "04061101"
    },
    {
      name: {
        km: "ចំការតាម៉ៅ",
        latin: "Chamkar Ta Mau"
      },
      id: "04061102"
    },
    {
      name: {
        km: "សន្ទូច",
        latin: "Santuch"
      },
      id: "04061103"
    },
    {
      name: {
        km: "ត្រោកកើត",
        latin: "Traok Kaeut"
      },
      id: "04061104"
    },
    {
      name: {
        km: "ត្រោកកណ្ដាល",
        latin: "Traok Kandal"
      },
      id: "04061105"
    },
    {
      name: {
        km: "ត្រោកលិច",
        latin: "Traok Lech"
      },
      id: "04061106"
    },
    {
      name: {
        km: "គល់គប់",
        latin: "Kol Kob"
      },
      id: "04061107"
    },
    {
      name: {
        km: "ត្រពាំងស្បូវ",
        latin: "Trapeang Sbov"
      },
      id: "04061108"
    },
    {
      name: {
        km: "ព្រៃមាន់",
        latin: "Prey Moan"
      },
      id: "04061109"
    },
    {
      name: {
        km: "អណ្ដូងឫស្សី",
        latin: "Andoung Ruessei"
      },
      id: "04061110"
    },
    {
      name: {
        km: "ទ្រាត្បូង",
        latin: "Trea Tboung"
      },
      id: "04061111"
    },
    {
      name: {
        km: "ទ្រាជើង",
        latin: "Trea Cheung"
      },
      id: "04061112"
    },
    {
      name: {
        km: "ថ្មកែវ",
        latin: "Thma Kaev"
      },
      id: "04061201"
    },
    {
      name: {
        km: "ក្នុង",
        latin: "Knong"
      },
      id: "04061202"
    },
    {
      name: {
        km: "គ្រាំងប្រស្វាយ",
        latin: "Krang Prasvay"
      },
      id: "04061203"
    },
    {
      name: {
        km: "ឧទុម្ពរ",
        latin: "Utumpor"
      },
      id: "04061204"
    },
    {
      name: {
        km: "សិឡាង",
        latin: "Selang"
      },
      id: "04061205"
    },
    {
      name: {
        km: "ស្វាយជ្រុំថ្មី",
        latin: "Svay Chrum Thmei"
      },
      id: "04061206"
    },
    {
      name: {
        km: "ចំការឃ្លៃ",
        latin: "Chamkar Khley"
      },
      id: "04061207"
    },
    {
      name: {
        km: "ចិន",
        latin: "Chen"
      },
      id: "04061208"
    },
    {
      name: {
        km: "ផ្លូវវាយ",
        latin: "Phlov Veay"
      },
      id: "04061209"
    },
    {
      name: {
        km: "អូរទទឹង",
        latin: "Ou Totueng"
      },
      id: "04061210"
    },
    {
      name: {
        km: "ទួលទ្រា",
        latin: "Tuol Trea"
      },
      id: "04061211"
    },
    {
      name: {
        km: "ស្វាយជ្រុំចាស់",
        latin: "Svay Chrum Chas"
      },
      id: "04061212"
    },
    {
      name: {
        km: "ស្មែត",
        latin: "Smaet"
      },
      id: "04061213"
    },
    {
      name: {
        km: "ធ្នង់កំបុត",
        latin: "Thnong Kambot"
      },
      id: "04061214"
    },
    {
      name: {
        km: "ចន្លោះរេន",
        latin: "Chanlaoh Ren"
      },
      id: "04061215"
    },
    {
      name: {
        km: "ដំបូកកកោះ",
        latin: "Dambouk Kakaoh"
      },
      id: "04061216"
    },
    {
      name: {
        km: "ត្រពាំងអញា្ចញ",
        latin: "Trapeang Anhchanh"
      },
      id: "04061217"
    },
    {
      name: {
        km: "ថ្នល់តាសែង",
        latin: "Thnal Ta Saeng"
      },
      id: "04061218"
    },
    {
      name: {
        km: "កោះកែវ",
        latin: "Kaoh Kaev"
      },
      id: "04061219"
    },
    {
      name: {
        km: "ក្រពើពល់",
        latin: "Krapeu Pul"
      },
      id: "04061220"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "04061221"
    },
    {
      name: {
        km: "កំពង់រាំង",
        latin: "Kampong Reang"
      },
      id: "04061222"
    },
    {
      name: {
        km: "អូរសណ្ដាន់",
        latin: "Ou Sandan"
      },
      id: "04061301"
    },
    {
      name: {
        km: "ទីកហូត",
        latin: "Tik Hout"
      },
      id: "04061302"
    },
    {
      name: {
        km: "ព្រែកសាលា",
        latin: "Preaek Sala"
      },
      id: "04061303"
    },
    {
      name: {
        km: "ននាមទទឹង",
        latin: "Noneam Totueng"
      },
      id: "04061304"
    },
    {
      name: {
        km: "ព្រែករាំង",
        latin: "Preaek Reang"
      },
      id: "04061305"
    },
    {
      name: {
        km: "គោកស្ដៅ",
        latin: "Kouk Sdau"
      },
      id: "04061306"
    },
    {
      name: {
        km: "ត្រពាំងក្រពើ",
        latin: "Trapeang Krapeu"
      },
      id: "04061307"
    },
    {
      name: {
        km: "ទីកល្អក់",
        latin: "Tik L'ak"
      },
      id: "04061308"
    },
    {
      name: {
        km: "អូរសណ្ដាន់ថ្មី",
        latin: "Ou Sandan Thmei"
      },
      id: "04061309"
    },
    {
      name: {
        km: "ច្រកត្នោត",
        latin: "Chrak Tnaot"
      },
      id: "04070101"
    },
    {
      name: {
        km: "ត្រែង",
        latin: "Traeng"
      },
      id: "04070102"
    },
    {
      name: {
        km: "រយាស",
        latin: "Royeas"
      },
      id: "04070103"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "04070104"
    },
    {
      name: {
        km: "ស្រែឫស្សី",
        latin: "Srae Ruessei"
      },
      id: "04070105"
    },
    {
      name: {
        km: "ក្រាំងសំរោង",
        latin: "Krang Samraong"
      },
      id: "04070106"
    },
    {
      name: {
        km: "អន្លង់ព្រីង",
        latin: "Anlong Pring"
      },
      id: "04070107"
    },
    {
      name: {
        km: "ជួញជិត",
        latin: "Chuonh Chit"
      },
      id: "04070108"
    },
    {
      name: {
        km: "គិរីអភិវឌ្ឍន៌",
        latin: "Kiri Akpiwat"
      },
      id: "04070109"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "04070201"
    },
    {
      name: {
        km: "ត្រពាំងស្រងែ",
        latin: "Trapeang Srangae"
      },
      id: "04070202"
    },
    {
      name: {
        km: "ជ្រៃកោងលិច",
        latin: "Chrey Kaong Lech"
      },
      id: "04070203"
    },
    {
      name: {
        km: "ជ្រៃកោងកើត",
        latin: "Chrey Kaong Kaeut"
      },
      id: "04070204"
    },
    {
      name: {
        km: "ថ្មស",
        latin: "Thma Sa"
      },
      id: "04070205"
    },
    {
      name: {
        km: "ខ្នារកណ្ដាល",
        latin: "Khna Kandal"
      },
      id: "04070206"
    },
    {
      name: {
        km: "ត្រដក់ពង",
        latin: "Tradak Pong"
      },
      id: "04070207"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "04070301"
    },
    {
      name: {
        km: "ក្រាំងល្វា",
        latin: "Krang Lvea"
      },
      id: "04070302"
    },
    {
      name: {
        km: "អូរកាខុប",
        latin: "Ou Kakhob"
      },
      id: "04070303"
    },
    {
      name: {
        km: "ជ្រេស",
        latin: "Chres"
      },
      id: "04070304"
    },
    {
      name: {
        km: "ខ្នាទៃមោក",
        latin: "KhnaTey Mouk"
      },
      id: "04070305"
    },
    {
      name: {
        km: "តាំងគ្រង",
        latin: "Tang Krong"
      },
      id: "04070306"
    },
    {
      name: {
        km: "ធ្លករលើង",
        latin: "Thlok Roleung"
      },
      id: "04070307"
    },
    {
      name: {
        km: "ជំទាវច្រែង",
        latin: "Chumteav Chraeng"
      },
      id: "04070308"
    },
    {
      name: {
        km: "តាំងគ្រួសលិច",
        latin: "Tang Kruos Lech"
      },
      id: "04070309"
    },
    {
      name: {
        km: "តាំងគ្រួសកើត",
        latin: "Tang Kruos Kaeut"
      },
      id: "04070310"
    },
    {
      name: {
        km: "សំបុកគ្រៀល",
        latin: "Sambok Kreal"
      },
      id: "04070311"
    },
    {
      name: {
        km: "ខ្សាច់ស",
        latin: "Ksach Sar"
      },
      id: "04070312"
    },
    {
      name: {
        km: "តាំងពោន",
        latin: "Tang Poun"
      },
      id: "04070401"
    },
    {
      name: {
        km: "ក្រាំងដូង",
        latin: "Krang Doung"
      },
      id: "04070402"
    },
    {
      name: {
        km: "ចង្វារៀល",
        latin: "Chanva Riel"
      },
      id: "04070403"
    },
    {
      name: {
        km: "ស្រែអណ្ដូង",
        latin: "Srae Andoung"
      },
      id: "04070404"
    },
    {
      name: {
        km: "តាកែវ",
        latin: "Ta Kaev"
      },
      id: "04070405"
    },
    {
      name: {
        km: "ក្រាំងបេង",
        latin: "Krang Beng"
      },
      id: "04070406"
    },
    {
      name: {
        km: "ស្វាយកាំបិត",
        latin: "Svay Kambet"
      },
      id: "04070407"
    },
    {
      name: {
        km: "ច្រាបកន្ទួត",
        latin: "Chrab Kantuot"
      },
      id: "04070408"
    },
    {
      name: {
        km: "ក្រាំងកន្ទ្រោល",
        latin: "Krang Kantroul"
      },
      id: "04070409"
    },
    {
      name: {
        km: "ច្រកស្ដេច",
        latin: "Chrak Sdach"
      },
      id: "04070410"
    },
    {
      name: {
        km: "ច្រកកូវ",
        latin: "Chrak Kov"
      },
      id: "04070411"
    },
    {
      name: {
        km: "សេរីវង្ស",
        latin: "Serei Vong"
      },
      id: "04070412"
    },
    {
      name: {
        km: "សុខសែនជ័យ",
        latin: "Sok Saenchey"
      },
      id: "04070413"
    },
    {
      name: {
        km: "ចំការស្វាយ",
        latin: "Chamkar Svay"
      },
      id: "04070501"
    },
    {
      name: {
        km: "ស្ពានពោធិ៍",
        latin: "Spean Pou"
      },
      id: "04070502"
    },
    {
      name: {
        km: "ថ្លុកឫស្សី",
        latin: "Thlok Ruessei"
      },
      id: "04070503"
    },
    {
      name: {
        km: "បឹងលាជ",
        latin: "Boeng Leach"
      },
      id: "04070504"
    },
    {
      name: {
        km: "ខ្នាច",
        latin: "Khnach"
      },
      id: "04070505"
    },
    {
      name: {
        km: "ក្រាំងសៀម",
        latin: "Krang Siem"
      },
      id: "04070506"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "04070507"
    },
    {
      name: {
        km: "វត្ដសេដ្ធី",
        latin: "Voat Sedthei"
      },
      id: "04070508"
    },
    {
      name: {
        km: "ពារាជ្យ",
        latin: "Peareach"
      },
      id: "04070509"
    },
    {
      name: {
        km: "ត្រពាំងបួន",
        latin: "Trapeang Buon"
      },
      id: "04070601"
    },
    {
      name: {
        km: "ចំការ",
        latin: "Chamkar"
      },
      id: "04070602"
    },
    {
      name: {
        km: "ហង្សឈូក",
        latin: "Hangs Chhuk"
      },
      id: "04070603"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "04070604"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "04070605"
    },
    {
      name: {
        km: "ស្វាយពក",
        latin: "Svay Pok"
      },
      id: "04070606"
    },
    {
      name: {
        km: "ខ្យាងត្បូង",
        latin: "Kyang Tboung"
      },
      id: "04070607"
    },
    {
      name: {
        km: "ខ្យាងជើង",
        latin: "Khyang Cheung"
      },
      id: "04070608"
    },
    {
      name: {
        km: "ក្រសាំងពុល",
        latin: "Krasang Pul"
      },
      id: "04070609"
    },
    {
      name: {
        km: "ពោធិ៍រិទ្ធិក្រៃ",
        latin: "Pou Ritthi Krai"
      },
      id: "04070610"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04070611"
    },
    {
      name: {
        km: "ជន្លាត់ដៃ",
        latin: "Chonloat Dai"
      },
      id: "04070612"
    },
    {
      name: {
        km: "ស្វាយផ្អែម",
        latin: "Svay Ph'aem"
      },
      id: "04070613"
    },
    {
      name: {
        km: "ក្ងោកពង់",
        latin: "Kngaok Pong"
      },
      id: "04070701"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "04070702"
    },
    {
      name: {
        km: "ដំណាក់ព្រីង",
        latin: "Damnak Pring"
      },
      id: "04070703"
    },
    {
      name: {
        km: "ត្រពាំងម្ទេស",
        latin: "Trapeang Mtes"
      },
      id: "04070704"
    },
    {
      name: {
        km: "តាំងក្រង",
        latin: "Tang Krang"
      },
      id: "04070705"
    },
    {
      name: {
        km: "ក្រាំងស្រម៉",
        latin: "Krang Srama"
      },
      id: "04070706"
    },
    {
      name: {
        km: "ត្រពាំងទន្លាប់",
        latin: "Trapeang Tunloab"
      },
      id: "04070707"
    },
    {
      name: {
        km: "ព្រៃញាម",
        latin: "Prey Nheam"
      },
      id: "04070708"
    },
    {
      name: {
        km: "តាំងឃ្លៃ",
        latin: "Tang Khley"
      },
      id: "04070709"
    },
    {
      name: {
        km: "ច្រកសង្កែ",
        latin: "Chrak Sangkae"
      },
      id: "04070710"
    },
    {
      name: {
        km: "ក្តុលអភិវឌ្ឍន៌",
        latin: "Kdol Akpiwat"
      },
      id: "04070711"
    },
    {
      name: {
        km: "រលាំង",
        latin: "Roleang"
      },
      id: "04070801"
    },
    {
      name: {
        km: "នាងមាលា",
        latin: "Neang Mealea"
      },
      id: "04070802"
    },
    {
      name: {
        km: "វាលតាគីង",
        latin: "Veal Ta King"
      },
      id: "04070803"
    },
    {
      name: {
        km: "ថ្មីខ្មែរ",
        latin: "Thmei Khmer"
      },
      id: "04070804"
    },
    {
      name: {
        km: "ត្បែងខ្ពស់",
        latin: "Tbaeng Khpos"
      },
      id: "04070805"
    },
    {
      name: {
        km: "ស្រែសារ",
        latin: "Srae Sar"
      },
      id: "04070806"
    },
    {
      name: {
        km: "មានក លិច",
        latin: "Meanok Lech"
      },
      id: "04070807"
    },
    {
      name: {
        km: "មានក កើត",
        latin: "Meanok Kaeut"
      },
      id: "04070808"
    },
    {
      name: {
        km: "រ៉ា",
        latin: "Ra"
      },
      id: "04070809"
    },
    {
      name: {
        km: "ធ្លកវៀន",
        latin: "Thlok Vien"
      },
      id: "04070901"
    },
    {
      name: {
        km: "ស្រែក្រៅ",
        latin: "Srae Krau"
      },
      id: "04070902"
    },
    {
      name: {
        km: "តាំងត្បែង",
        latin: "Tang Tbaeng"
      },
      id: "04070903"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "04070904"
    },
    {
      name: {
        km: "ស្ពានដែក",
        latin: "Spean Daek"
      },
      id: "04070905"
    },
    {
      name: {
        km: "ទ័ពបោះ",
        latin: "Toap Baoh"
      },
      id: "04070906"
    },
    {
      name: {
        km: "ប្រក្លូត",
        latin: "Praklout"
      },
      id: "04070907"
    },
    {
      name: {
        km: "ស្រែតាជៃ",
        latin: "Srae Ta Chey"
      },
      id: "04080101"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "04080102"
    },
    {
      name: {
        km: "ស្រែព្រិច",
        latin: "Srae Prich"
      },
      id: "04080103"
    },
    {
      name: {
        km: "ស្រែខ្ទុម្ព",
        latin: "Srae Khtum"
      },
      id: "04080104"
    },
    {
      name: {
        km: "ទឹកជុំ",
        latin: "Tuek Chum"
      },
      id: "04080105"
    },
    {
      name: {
        km: "រពាក់",
        latin: "Ropeak"
      },
      id: "04080106"
    },
    {
      name: {
        km: "ដំរិប",
        latin: "Damreb"
      },
      id: "04080107"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "04080108"
    },
    {
      name: {
        km: "បែកចក",
        latin: "Baek Chak"
      },
      id: "04080109"
    },
    {
      name: {
        km: "ស្រែតាជៃខាងលិច",
        latin: "Srae Ta Chey Khang Lech"
      },
      id: "04080110"
    },
    {
      name: {
        km: "ជីប្រង",
        latin: "Chi Prang"
      },
      id: "04080201"
    },
    {
      name: {
        km: "ទំនប់ថ្មី",
        latin: "Tumnob Thmei"
      },
      id: "04080202"
    },
    {
      name: {
        km: "បឹងស្ទេង",
        latin: "Boeng Steng"
      },
      id: "04080203"
    },
    {
      name: {
        km: "ព្រៃតាំងធ្នង់",
        latin: "Prey Tang Thnong"
      },
      id: "04080204"
    },
    {
      name: {
        km: "កោះកណ្ដាល",
        latin: "Kaoh Kandal"
      },
      id: "04080205"
    },
    {
      name: {
        km: "ឆកកណ្ដោល",
        latin: "Chhak Kandaol"
      },
      id: "04080206"
    },
    {
      name: {
        km: "គោកពេញ",
        latin: "Kouk Penh"
      },
      id: "04080207"
    },
    {
      name: {
        km: "ទ័ពតាឡាត់",
        latin: "Toap Ta Lat"
      },
      id: "04080208"
    },
    {
      name: {
        km: "ស្រែរបង",
        latin: "Sae Robang"
      },
      id: "04080209"
    },
    {
      name: {
        km: "តានៃ",
        latin: "Ta Ney"
      },
      id: "04080210"
    },
    {
      name: {
        km: "កោះខ្ទុម្ព",
        latin: "Kaoh Khtum"
      },
      id: "04080211"
    },
    {
      name: {
        km: "ចោងម៉ោង",
        latin: "Chaong Maong"
      },
      id: "04080301"
    },
    {
      name: {
        km: "ដូនម៉ៅ",
        latin: "Doun Mau"
      },
      id: "04080302"
    },
    {
      name: {
        km: "ខ្សែត",
        latin: "Khsaet"
      },
      id: "04080303"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04080304"
    },
    {
      name: {
        km: "ពារាំង",
        latin: "Peareang"
      },
      id: "04080305"
    },
    {
      name: {
        km: "ស្វាយចេក",
        latin: "Svay Chek"
      },
      id: "04080306"
    },
    {
      name: {
        km: "អលង្កែរ",
        latin: "Akleangkaer"
      },
      id: "04080307"
    },
    {
      name: {
        km: "ត្រពាំងជុំ",
        latin: "Trapeang Chum"
      },
      id: "04080308"
    },
    {
      name: {
        km: "ក្រសាំងដុះឡើង",
        latin: "Krasang Doh Laeung"
      },
      id: "04080401"
    },
    {
      name: {
        km: "ដូងស្លា",
        latin: "Doung Sla"
      },
      id: "04080402"
    },
    {
      name: {
        km: "តាំងខ្សាច់",
        latin: "Tang Khsach"
      },
      id: "04080403"
    },
    {
      name: {
        km: "តាំងស្យា",
        latin: "Tang Sya"
      },
      id: "04080404"
    },
    {
      name: {
        km: "ងយ",
        latin: "Ngoy"
      },
      id: "04080405"
    },
    {
      name: {
        km: "ជីពូក",
        latin: "Chipuk"
      },
      id: "04080406"
    },
    {
      name: {
        km: "មោង",
        latin: "Moung"
      },
      id: "04080407"
    },
    {
      name: {
        km: "ព្រៃជ្រៅ",
        latin: "Prey Chrov"
      },
      id: "04080408"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "04080409"
    },
    {
      name: {
        km: "ខ្វិតទួលឃ្លាំង",
        latin: "Khvit Tuol Khleang"
      },
      id: "04080410"
    },
    {
      name: {
        km: "ត្រពាំងក្របៅ",
        latin: "Trapeang Krabau"
      },
      id: "04080501"
    },
    {
      name: {
        km: "យោត",
        latin: "Yout"
      },
      id: "04080502"
    },
    {
      name: {
        km: "ខ្លុងពពក",
        latin: "Khlong Popok"
      },
      id: "04080503"
    },
    {
      name: {
        km: "តាកាប",
        latin: "Ta Kab"
      },
      id: "04080504"
    },
    {
      name: {
        km: "ក្រោយវត្ដ",
        latin: "Kraoy Voat"
      },
      id: "04080505"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "04080506"
    },
    {
      name: {
        km: "បឹងស្ទេង",
        latin: "Boeng Steng"
      },
      id: "04080507"
    },
    {
      name: {
        km: "ក្រាំងស្គារ",
        latin: "Krang Skear"
      },
      id: "04080601"
    },
    {
      name: {
        km: "ទួលសំរោង",
        latin: "Tuol Samraong"
      },
      id: "04080602"
    },
    {
      name: {
        km: "ភ្នំតាសាំ",
        latin: "Phnum Ta Sam"
      },
      id: "04080603"
    },
    {
      name: {
        km: "ចាន់ត្រក",
        latin: "Chan Trak"
      },
      id: "04080604"
    },
    {
      name: {
        km: "ត្រពាំងម្លូ",
        latin: "Trapeang Mlu"
      },
      id: "04080605"
    },
    {
      name: {
        km: "ចំបក់ប្រាសាទ",
        latin: "Chambak Prasat"
      },
      id: "04080606"
    },
    {
      name: {
        km: "ក្រាំងស្គារខាងត្បូង",
        latin: "Krang Skear Khang Tboung"
      },
      id: "04080611"
    },
    {
      name: {
        km: "ចំបក់កន្ទ្រាញ",
        latin: "Chambak Kantreanh"
      },
      id: "04080701"
    },
    {
      name: {
        km: "ត្បែងខ្ពស់",
        latin: "Tbaeng Khpos"
      },
      id: "04080702"
    },
    {
      name: {
        km: "ចាស់",
        latin: "Chas"
      },
      id: "04080703"
    },
    {
      name: {
        km: "គោកណាំង",
        latin: "Kouk Nang"
      },
      id: "04080704"
    },
    {
      name: {
        km: "ក្រាំងតាមុំ",
        latin: "Krang Ta Mom"
      },
      id: "04080705"
    },
    {
      name: {
        km: "ស្រែអ៊ុក",
        latin: "Srae Uk"
      },
      id: "04080706"
    },
    {
      name: {
        km: "រមាស",
        latin: "Romeas"
      },
      id: "04080707"
    },
    {
      name: {
        km: "គោកពួច",
        latin: "Kouk Puoch"
      },
      id: "04080708"
    },
    {
      name: {
        km: "វាលស្បូវ",
        latin: "Veal Sbov"
      },
      id: "04080709"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "04080710"
    },
    {
      name: {
        km: "តាំងក្រសាំង",
        latin: "Tang Krasang"
      },
      id: "04080711"
    },
    {
      name: {
        km: "ក្រាំងម៉",
        latin: "Krang Ma"
      },
      id: "04080712"
    },
    {
      name: {
        km: "រកាទង",
        latin: "Roka Tong"
      },
      id: "04080801"
    },
    {
      name: {
        km: "ឡ",
        latin: "La"
      },
      id: "04080802"
    },
    {
      name: {
        km: "វត្ដ",
        latin: "Voat"
      },
      id: "04080803"
    },
    {
      name: {
        km: "ត្រពាំងស្មាច់",
        latin: "Trapeang Smach"
      },
      id: "04080804"
    },
    {
      name: {
        km: "ស្រែចាន",
        latin: "Srae Chan"
      },
      id: "04080805"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "04080806"
    },
    {
      name: {
        km: "រលុង",
        latin: "Rolung"
      },
      id: "04080807"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "04080901"
    },
    {
      name: {
        km: "ដំណាក់អំពិល",
        latin: "Damnak Ampil"
      },
      id: "04080902"
    },
    {
      name: {
        km: "ដំណាក់ខ្លុង",
        latin: "Damnak Khlong"
      },
      id: "04080903"
    },
    {
      name: {
        km: "ក្ដុល",
        latin: "Kdol"
      },
      id: "04080904"
    },
    {
      name: {
        km: "អូរល្ពៅ",
        latin: "Ou Lpov"
      },
      id: "04080905"
    },
    {
      name: {
        km: "ភ្នំតាឧស",
        latin: "Phnum Ta Os"
      },
      id: "04080906"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "05010101"
    },
    {
      name: {
        km: "ព្រៃគោកត្រប់",
        latin: "Prey Kouk Trab"
      },
      id: "05010102"
    },
    {
      name: {
        km: "ព្រៃឃ្លៃ",
        latin: "Prey Khley"
      },
      id: "05010103"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "05010104"
    },
    {
      name: {
        km: "បឹងស្ដុក",
        latin: "Boeng Sdok"
      },
      id: "05010105"
    },
    {
      name: {
        km: "បឹងសង្កែ",
        latin: "Boeng Sangkae"
      },
      id: "05010106"
    },
    {
      name: {
        km: "ស្រែត្រោក",
        latin: "Srae Traok"
      },
      id: "05010107"
    },
    {
      name: {
        km: "តា្មតលេង",
        latin: "Tmat Leng"
      },
      id: "05010108"
    },
    {
      name: {
        km: "សំព័រ",
        latin: "Sampoar"
      },
      id: "05010109"
    },
    {
      name: {
        km: "ចំការទួល",
        latin: "Chamkar Tuol"
      },
      id: "05010110"
    },
    {
      name: {
        km: "ព្រៃរំដួលខាងកើត",
        latin: "Prey Rumduol  khang Kaeut"
      },
      id: "05010111"
    },
    {
      name: {
        km: "ព្រៃរំដួលខាងត្បូង",
        latin: "Prey Rumduol khang Tboung"
      },
      id: "05010112"
    },
    {
      name: {
        km: "បឹងធ្នង់",
        latin: "Boeng Thnong"
      },
      id: "05010113"
    },
    {
      name: {
        km: "ព្រៃរំដួលខាងលិច",
        latin: "Prey Rumduol khang Lech"
      },
      id: "05010114"
    },
    {
      name: {
        km: "ព្រៃរំដួលខាងជើង",
        latin: "Prey Rumduol khang Cheung"
      },
      id: "05010115"
    },
    {
      name: {
        km: "ទួលខ្ជាយ",
        latin: "Tuol Khcheay"
      },
      id: "05010116"
    },
    {
      name: {
        km: "ខ្ពបវែង",
        latin: "Khpob Veaeng"
      },
      id: "05010117"
    },
    {
      name: {
        km: "កន្លង់",
        latin: "Kanlang"
      },
      id: "05010118"
    },
    {
      name: {
        km: "ចាស់",
        latin: "Chas"
      },
      id: "05010119"
    },
    {
      name: {
        km: "ត្រពាំងផុង",
        latin: "Trapeang Phong"
      },
      id: "05010120"
    },
    {
      name: {
        km: "ក្រុមហ៊ុន",
        latin: "Kromhun"
      },
      id: "05010121"
    },
    {
      name: {
        km: "តាប្រាជ្ញ",
        latin: "Ta Prach"
      },
      id: "05010122"
    },
    {
      name: {
        km: "ក្រោលក្រសាំង",
        latin: "Kraol Krasang"
      },
      id: "05010201"
    },
    {
      name: {
        km: "រកាកោង",
        latin: "Roka Kaong"
      },
      id: "05010202"
    },
    {
      name: {
        km: "ព្រៃសំព័រ",
        latin: "Prey Sampoar"
      },
      id: "05010203"
    },
    {
      name: {
        km: "ថ្លុកបី",
        latin: "Thlok Bei"
      },
      id: "05010204"
    },
    {
      name: {
        km: "វាលល្វាង",
        latin: "Veal Lveang"
      },
      id: "05010205"
    },
    {
      name: {
        km: "យោលទោង",
        latin: "Youl Toung"
      },
      id: "05010206"
    },
    {
      name: {
        km: "រកាធំ",
        latin: "Roka Thum"
      },
      id: "05010207"
    },
    {
      name: {
        km: "ភ្នំកូប",
        latin: "Phnum Kob"
      },
      id: "05010208"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "05010209"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "05010210"
    },
    {
      name: {
        km: "ត្រពាំងពើក",
        latin: "Trapeang Peuk"
      },
      id: "05010211"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "05010301"
    },
    {
      name: {
        km: "ត្រពាំងទូក",
        latin: "Trapeang Tuk"
      },
      id: "05010302"
    },
    {
      name: {
        km: "ត្រាំកង់",
        latin: "Tram Kang"
      },
      id: "05010303"
    },
    {
      name: {
        km: "ហង្ស",
        latin: "Hangs"
      },
      id: "05010304"
    },
    {
      name: {
        km: "ត្រពាំងស្ដៅ",
        latin: "Trapeang Sdau"
      },
      id: "05010305"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "05010306"
    },
    {
      name: {
        km: "នរាយណ៍",
        latin: "Noreay"
      },
      id: "05010307"
    },
    {
      name: {
        km: "ត្រពាំងខ្យង",
        latin: "Trapeang Khyang"
      },
      id: "05010308"
    },
    {
      name: {
        km: "ត្រពាំងខ្នារ",
        latin: "Trapeang Khnar"
      },
      id: "05010309"
    },
    {
      name: {
        km: "ត្រពាំងសាលា",
        latin: "Trapeang Sala"
      },
      id: "05010310"
    },
    {
      name: {
        km: "ពោធិ៍ត្បែង",
        latin: "Pou Tbaeng"
      },
      id: "05010311"
    },
    {
      name: {
        km: "ក្រសាំងតាគង់",
        latin: "Krasang Ta Kong"
      },
      id: "05010312"
    },
    {
      name: {
        km: "ត្រពាំងអណ្ដូង",
        latin: "Trapeang Andoung"
      },
      id: "05010313"
    },
    {
      name: {
        km: "ត្រពាំងរំដេញ",
        latin: "Trapeang Rumdenh"
      },
      id: "05010314"
    },
    {
      name: {
        km: "សេរីអណ្ដែត",
        latin: "Serei Andaet"
      },
      id: "05010315"
    },
    {
      name: {
        km: "ច្រកព្រាល",
        latin: "Chrak Preal"
      },
      id: "05010401"
    },
    {
      name: {
        km: "ព្រៃរំដែល",
        latin: "Prey Rumdael"
      },
      id: "05010402"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "05010403"
    },
    {
      name: {
        km: "ឈូករដ្ឋ",
        latin: "Chhuk Roadth"
      },
      id: "05010404"
    },
    {
      name: {
        km: "គោកកណ្ដាល",
        latin: "Kouk Kandal"
      },
      id: "05010405"
    },
    {
      name: {
        km: "សហគមន៍ខាងកើត",
        latin: "Sahakkom Khang Kaeut"
      },
      id: "05010406"
    },
    {
      name: {
        km: "សហគមន៍ខាងលិច",
        latin: "Sahakkom Khang Lech"
      },
      id: "05010407"
    },
    {
      name: {
        km: "អូរតាពូង",
        latin: "Ou Ta Pung"
      },
      id: "05010408"
    },
    {
      name: {
        km: "តានក",
        latin: "Ta Nok"
      },
      id: "05010409"
    },
    {
      name: {
        km: "ព្រះម្លប់",
        latin: "Preah Mlob"
      },
      id: "05010501"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05010502"
    },
    {
      name: {
        km: "ព្រៃកញ្ចាន់",
        latin: "Prey Kanhchan"
      },
      id: "05010503"
    },
    {
      name: {
        km: "ដាសស្គរ",
        latin: "Das Skor"
      },
      id: "05010504"
    },
    {
      name: {
        km: "ព្រៃងោង",
        latin: "Prey Ngoung"
      },
      id: "05010505"
    },
    {
      name: {
        km: "ពីរី",
        latin: "Piri"
      },
      id: "05010506"
    },
    {
      name: {
        km: "តាធម្ម",
        latin: "Ta Thomm"
      },
      id: "05010507"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "05010508"
    },
    {
      name: {
        km: "ព្រៃរោង",
        latin: "Prey Roung"
      },
      id: "05010509"
    },
    {
      name: {
        km: "តាសោមអក",
        latin: "Ta Saom Ak"
      },
      id: "05010510"
    },
    {
      name: {
        km: "សំរោងពងទឹក",
        latin: "Samraong Pong Tuek"
      },
      id: "05010511"
    },
    {
      name: {
        km: "សាច់ត្រី",
        latin: "Sach Trei"
      },
      id: "05010512"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Tuek Thla"
      },
      id: "05010513"
    },
    {
      name: {
        km: "បឹងតាមុំ",
        latin: "Boeng Ta Mom"
      },
      id: "05010601"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "05010602"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05010603"
    },
    {
      name: {
        km: "សំបូរមាស",
        latin: "Sambuor Meas"
      },
      id: "05010604"
    },
    {
      name: {
        km: "កន្លង់ជុំ",
        latin: "Kanlang Chum"
      },
      id: "05010605"
    },
    {
      name: {
        km: "ព្រៃម្នោ",
        latin: "Prey Mnou"
      },
      id: "05010606"
    },
    {
      name: {
        km: "ត្រពាំងទឹកជ្រៅ",
        latin: "Trapeang Tuek Chrov"
      },
      id: "05010607"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "05010608"
    },
    {
      name: {
        km: "ឈូកកៀប",
        latin: "Chhuk Kieb"
      },
      id: "05010609"
    },
    {
      name: {
        km: "ត្រពាំងលាប",
        latin: "Trapeang Leab"
      },
      id: "05010610"
    },
    {
      name: {
        km: "ដំបូកខ្ពស់",
        latin: "Dambouk Khpos"
      },
      id: "05010611"
    },
    {
      name: {
        km: "ទួលភ្ងាស",
        latin: "Tuol Phngeas"
      },
      id: "05010612"
    },
    {
      name: {
        km: "ស្រែរលួស",
        latin: "Srae Roluos"
      },
      id: "05010613"
    },
    {
      name: {
        km: "ត្រពាំងប្រិយ៍",
        latin: "Trapeang Prei"
      },
      id: "05010701"
    },
    {
      name: {
        km: "សង្គមមានជ័យ",
        latin: "Sangkom Mean chey"
      },
      id: "05010702"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "05010703"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "05010704"
    },
    {
      name: {
        km: "ពោធិធំ",
        latin: "Pou Thum"
      },
      id: "05010705"
    },
    {
      name: {
        km: "ពោធិកណ្ដាល",
        latin: "Pou Kandal"
      },
      id: "05010706"
    },
    {
      name: {
        km: "ព្រៃអិដ្ឋ",
        latin: "Prey Edth"
      },
      id: "05010707"
    },
    {
      name: {
        km: "ច្រនាងចាស់",
        latin: "Chranieng Chas"
      },
      id: "05010708"
    },
    {
      name: {
        km: "ច្រនាងថ្មី",
        latin: "Chranieng Thmei"
      },
      id: "05010709"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "05010710"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "05010711"
    },
    {
      name: {
        km: "សេរីជួបជុំ",
        latin: "Serei Chuob Chum"
      },
      id: "05010712"
    },
    {
      name: {
        km: "ត្រកួន",
        latin: "Trakuon"
      },
      id: "05010713"
    },
    {
      name: {
        km: "ត្រពាំងត្រគៀត",
        latin: "Trapeang Trokiet"
      },
      id: "05010714"
    },
    {
      name: {
        km: "ក្រញូង",
        latin: "Kranhung"
      },
      id: "05010715"
    },
    {
      name: {
        km: "សេរីភ័ត្ដ",
        latin: "Serei Phoat"
      },
      id: "05010716"
    },
    {
      name: {
        km: "ព្រៃស្រូល",
        latin: "Prey Sroul"
      },
      id: "05010717"
    },
    {
      name: {
        km: "នរាយណ៍",
        latin: "Noreay"
      },
      id: "05010718"
    },
    {
      name: {
        km: "ព្រៃតាភេម",
        latin: "Prey Ta Phem"
      },
      id: "05010719"
    },
    {
      name: {
        km: "ឃ្លៃចាស់",
        latin: "Khley Chas"
      },
      id: "05010801"
    },
    {
      name: {
        km: "ឃ្លៃថ្មី",
        latin: "Khley Thmei"
      },
      id: "05010802"
    },
    {
      name: {
        km: "ត្រពាំងសំរោង",
        latin: "Trapeang Samraong"
      },
      id: "05010803"
    },
    {
      name: {
        km: "សក្ការៈ",
        latin: "Sakkarak"
      },
      id: "05010804"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "05010805"
    },
    {
      name: {
        km: "អង្គតាអាំ",
        latin: "Angk Ta Am"
      },
      id: "05010806"
    },
    {
      name: {
        km: "ស្រែគុយ",
        latin: "Srae Kuy"
      },
      id: "05010807"
    },
    {
      name: {
        km: "ត្រពាំងត្រយឹង",
        latin: "Trapeang Trayueng"
      },
      id: "05010808"
    },
    {
      name: {
        km: "លាំងជៃថ្មី",
        latin: "Leang Chey Thmei"
      },
      id: "05010809"
    },
    {
      name: {
        km: "ត្រពាំងទទ្ទឹម",
        latin: "Trapeang Tuntuem"
      },
      id: "05010810"
    },
    {
      name: {
        km: "លាំងជៃចាស់",
        latin: "Leang Chey Chas"
      },
      id: "05010811"
    },
    {
      name: {
        km: "ព្រៃត្បែង",
        latin: "Prey Tbaeng"
      },
      id: "05010812"
    },
    {
      name: {
        km: "ព្រៃខ្លុង",
        latin: "Prey Khlong"
      },
      id: "05010813"
    },
    {
      name: {
        km: "ខ្ពបរុន",
        latin: "Khpob Run"
      },
      id: "05010814"
    },
    {
      name: {
        km: "អង្គដែកកណ្ដាល",
        latin: "Angk Daek Kandal"
      },
      id: "05010901"
    },
    {
      name: {
        km: "ចំបក់រុន្ធត្បូង",
        latin: "Chambak Run Tboung"
      },
      id: "05010902"
    },
    {
      name: {
        km: "ស្រែខ្ញែរ",
        latin: "Srae Khnher"
      },
      id: "05010903"
    },
    {
      name: {
        km: "ចំបក់រុន្ធជើង",
        latin: "Chambak Run Cheung"
      },
      id: "05010904"
    },
    {
      name: {
        km: "ម្រាលធំ",
        latin: "Mreal Thum"
      },
      id: "05010905"
    },
    {
      name: {
        km: "ម្រាលត្នោតជើង",
        latin: "Mreal Tnaot  Cheung"
      },
      id: "05010906"
    },
    {
      name: {
        km: "ម្រាលត្នោតត្បូង",
        latin: "Mreal Tnaot  Tboung"
      },
      id: "05010907"
    },
    {
      name: {
        km: "អូចារ្យ",
        latin: "Ou Char"
      },
      id: "05010908"
    },
    {
      name: {
        km: "ពោធិ",
        latin: "Pou"
      },
      id: "05010909"
    },
    {
      name: {
        km: "សាឡាំ",
        latin: "Salam"
      },
      id: "05010910"
    },
    {
      name: {
        km: "ត្រពាំងខ្នារ",
        latin: "Trapeang Khnar"
      },
      id: "05010911"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05010912"
    },
    {
      name: {
        km: "ចំរើនផល",
        latin: "Chamraeun Phal"
      },
      id: "05010913"
    },
    {
      name: {
        km: "តាដែងថ្មី",
        latin: "Ta Daeng Thmei"
      },
      id: "05010914"
    },
    {
      name: {
        km: "តានួន",
        latin: "Ta Nuon"
      },
      id: "05010915"
    },
    {
      name: {
        km: "តាដែងចាស់",
        latin: "Ta Daeng Chas"
      },
      id: "05010916"
    },
    {
      name: {
        km: "ព្រៃត្បែង",
        latin: "Prey Tbaeng"
      },
      id: "05010917"
    },
    {
      name: {
        km: "ព្រៃឃ្លេ",
        latin: "Prey Khle"
      },
      id: "05010918"
    },
    {
      name: {
        km: "ស្ដុក",
        latin: "Sdok"
      },
      id: "05011001"
    },
    {
      name: {
        km: "ពពូល",
        latin: "Popul"
      },
      id: "05011002"
    },
    {
      name: {
        km: "ចចិប",
        latin: "Chacheb"
      },
      id: "05011003"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "05011004"
    },
    {
      name: {
        km: "អន្លង់លាក់",
        latin: "Anlong Leak"
      },
      id: "05011005"
    },
    {
      name: {
        km: "ឫស្សីយុល",
        latin: "Ruessei Yul"
      },
      id: "05011006"
    },
    {
      name: {
        km: "អណ្ដូងល្វា",
        latin: "Andoung Lvea"
      },
      id: "05011007"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "05011008"
    },
    {
      name: {
        km: "ត្រពាំងខ្ទុំ",
        latin: "Trapeang Khtum"
      },
      id: "05011009"
    },
    {
      name: {
        km: "ត្រពាំងធ្លក",
        latin: "Trapeang Thlok"
      },
      id: "05011010"
    },
    {
      name: {
        km: "ខ្នាចកន្ទួត",
        latin: "Khnach Kantuot"
      },
      id: "05011011"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "05011012"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "05011013"
    },
    {
      name: {
        km: "ទ្រយឹងទេ",
        latin: "Troyueng Te"
      },
      id: "05011014"
    },
    {
      name: {
        km: "សង្កែលាក់",
        latin: "Sangkae Leak"
      },
      id: "05011015"
    },
    {
      name: {
        km: "សង្គ្រាមបូរណ៍",
        latin: "Sangkream Bour"
      },
      id: "05011101"
    },
    {
      name: {
        km: "រកាពក",
        latin: "Roka Pok"
      },
      id: "05011102"
    },
    {
      name: {
        km: "ត្រពាំងជំរៅ",
        latin: "Trapeang Chumrov"
      },
      id: "05011103"
    },
    {
      name: {
        km: "ត្រពាំងខ្យង",
        latin: "Trapeang Khyang"
      },
      id: "05011104"
    },
    {
      name: {
        km: "ព្រៃពាយ",
        latin: "Prey Peay"
      },
      id: "05011105"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "05011106"
    },
    {
      name: {
        km: "អង្គក្ដី",
        latin: "Angk Kdei"
      },
      id: "05011107"
    },
    {
      name: {
        km: "ត្រពាំងទន្លាប់",
        latin: "Trapeang Tonloab"
      },
      id: "05011108"
    },
    {
      name: {
        km: "ដំណាក់ត្រាច",
        latin: "Damnak Trach"
      },
      id: "05011109"
    },
    {
      name: {
        km: "ព្រៃស្រឡែង",
        latin: "Prey Sralaeng"
      },
      id: "05011110"
    },
    {
      name: {
        km: "អង្គរងាង",
        latin: "Angk Rongeang"
      },
      id: "05011111"
    },
    {
      name: {
        km: "មីលាវ",
        latin: "Mi Leav"
      },
      id: "05011112"
    },
    {
      name: {
        km: "កែស្រែង",
        latin: "Kae Sraeng"
      },
      id: "05011113"
    },
    {
      name: {
        km: "ផាន",
        latin: "Phan"
      },
      id: "05011114"
    },
    {
      name: {
        km: "តាម៉ឺន",
        latin: "Ta Meun"
      },
      id: "05011115"
    },
    {
      name: {
        km: "ទួលសាលា",
        latin: "Tuol Sala"
      },
      id: "05011201"
    },
    {
      name: {
        km: "តាអាំ",
        latin: "Ta Am"
      },
      id: "05011202"
    },
    {
      name: {
        km: "កោះឈើទាល",
        latin: "Kaoh Chheu Teal"
      },
      id: "05011203"
    },
    {
      name: {
        km: "ស្វាយរំពា",
        latin: "Svay Rumpea"
      },
      id: "05011204"
    },
    {
      name: {
        km: "ដូនពេញ",
        latin: "Doun Penh"
      },
      id: "05011205"
    },
    {
      name: {
        km: "រំលឹក",
        latin: "Rumluek"
      },
      id: "05011206"
    },
    {
      name: {
        km: "ព្រៃរងាង",
        latin: "Prey Rongeang"
      },
      id: "05011207"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "05011208"
    },
    {
      name: {
        km: "តាតូវ",
        latin: "Ta Tov"
      },
      id: "05011209"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "05011210"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "05011211"
    },
    {
      name: {
        km: "ស្រែឃ្លេ",
        latin: "Srae Khle"
      },
      id: "05011212"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "05011213"
    },
    {
      name: {
        km: "ចង្រៀក",
        latin: "Changriek"
      },
      id: "05011214"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "05011215"
    },
    {
      name: {
        km: "ខ្លាជល់",
        latin: "Khla Chol"
      },
      id: "05011216"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "05011217"
    },
    {
      name: {
        km: "តារាជ",
        latin: "Ta Reach"
      },
      id: "05011301"
    },
    {
      name: {
        km: "ទ័ពម្រាក់",
        latin: "Toap Mreak"
      },
      id: "05011302"
    },
    {
      name: {
        km: "ក្រាំងត្រោក",
        latin: "Krang Traok"
      },
      id: "05011303"
    },
    {
      name: {
        km: "ត្រពាំងទាប",
        latin: "Trapeang Teab"
      },
      id: "05011304"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "05011305"
    },
    {
      name: {
        km: "ព្រៃឈ្នួល",
        latin: "Prey Snuol"
      },
      id: "05011306"
    },
    {
      name: {
        km: "ឫស្សីវាល",
        latin: "Ruessei Veal"
      },
      id: "05011307"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "05011308"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "05011309"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "05011310"
    },
    {
      name: {
        km: "ខ្នា",
        latin: "Khna"
      },
      id: "05011311"
    },
    {
      name: {
        km: "ជើងភ្នំ",
        latin: "Cheung Phnum"
      },
      id: "05011312"
    },
    {
      name: {
        km: "ព្រេច",
        latin: "Prech"
      },
      id: "05011313"
    },
    {
      name: {
        km: "ផ្ចឹក",
        latin: "Phchoek"
      },
      id: "05011314"
    },
    {
      name: {
        km: "ត្រាំសសរ",
        latin: "Tram Sasar"
      },
      id: "05011401"
    },
    {
      name: {
        km: "ក្បៀរ",
        latin: "Kbier"
      },
      id: "05011402"
    },
    {
      name: {
        km: "ផ្សារស្លាប់លែង",
        latin: "Phsar Slab; Leaeng"
      },
      id: "05011403"
    },
    {
      name: {
        km: "អូរព្រាល",
        latin: "Ou Preal"
      },
      id: "05011404"
    },
    {
      name: {
        km: "ព្រៃដុប",
        latin: "Prey Dob"
      },
      id: "05011405"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "05011406"
    },
    {
      name: {
        km: "ត្រពាំងស្យា",
        latin: "Trapeang Sya"
      },
      id: "05011407"
    },
    {
      name: {
        km: "ស្រែព្រីង",
        latin: "Srae Pring"
      },
      id: "05011408"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "05011409"
    },
    {
      name: {
        km: "ស្លាប់លែង",
        latin: "Slab Leaeng"
      },
      id: "05011410"
    },
    {
      name: {
        km: "គ្រើល",
        latin: "Kreul"
      },
      id: "05011411"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "05011412"
    },
    {
      name: {
        km: "ខ្នាតានង់",
        latin: "Khna Ta Nong"
      },
      id: "05011413"
    },
    {
      name: {
        km: "កណ្ដៀង",
        latin: "Kandieng"
      },
      id: "05011414"
    },
    {
      name: {
        km: "ត្នោតម្ដើម",
        latin: "Tnaot Mdaeum"
      },
      id: "05011501"
    },
    {
      name: {
        km: "ព្រៃបាក្រុង",
        latin: "Prey Ba Krong"
      },
      id: "05011502"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "05011503"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "05011504"
    },
    {
      name: {
        km: "ខ្នងភូមិ",
        latin: "Khnang Phum"
      },
      id: "05011505"
    },
    {
      name: {
        km: "ថ្នល់ដាច់",
        latin: "Thnal Dach"
      },
      id: "05011506"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "05011507"
    },
    {
      name: {
        km: "ឃ្លោក",
        latin: "Khlouk"
      },
      id: "05011508"
    },
    {
      name: {
        km: "ត្រពាំងប្រិយ៍",
        latin: "Trapeang Prei"
      },
      id: "05011509"
    },
    {
      name: {
        km: "តាម៉ុល",
        latin: "Ta Mol"
      },
      id: "05020101"
    },
    {
      name: {
        km: "ផ្គង់",
        latin: "Phkong"
      },
      id: "05020102"
    },
    {
      name: {
        km: "ត្រពាំងលើក",
        latin: "Trapeang Leuk"
      },
      id: "05020103"
    },
    {
      name: {
        km: "ពេជសង្វា",
        latin: "Pech Sangvar"
      },
      id: "05020104"
    },
    {
      name: {
        km: "អង្គប្រាសាទ",
        latin: "Angk Prasat"
      },
      id: "05020105"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "05020106"
    },
    {
      name: {
        km: "បូរីកម្មករ",
        latin: "Borei Kammeakkar"
      },
      id: "05020108"
    },
    {
      name: {
        km: "ចំការដូង",
        latin: "Chamkar Doung"
      },
      id: "05020109"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "05020110"
    },
    {
      name: {
        km: "ភ្នំដី",
        latin: "Phnum Dei"
      },
      id: "05020111"
    },
    {
      name: {
        km: "ព្រៃស្លឹក",
        latin: "Prey Sloek"
      },
      id: "05020112"
    },
    {
      name: {
        km: "សំពៅ",
        latin: "Sampov"
      },
      id: "05020113"
    },
    {
      name: {
        km: "ខ្ទុំក្រាំង",
        latin: "Khtum Krang"
      },
      id: "05020114"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05020201"
    },
    {
      name: {
        km: "កោះវៀន",
        latin: "Kaoh Vien"
      },
      id: "05020202"
    },
    {
      name: {
        km: "រំលោង",
        latin: "Rumloung"
      },
      id: "05020203"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "05020204"
    },
    {
      name: {
        km: "ផ្សារសាមគ្គី",
        latin: "Phsar Sameakki"
      },
      id: "05020205"
    },
    {
      name: {
        km: "ញរ",
        latin: "Nhor"
      },
      id: "05020206"
    },
    {
      name: {
        km: "កណ្ដោលដុំ",
        latin: "Kandaol Dom"
      },
      id: "05020207"
    },
    {
      name: {
        km: "ត្រពាំងព្រះ",
        latin: "Trapeang Preah"
      },
      id: "05020208"
    },
    {
      name: {
        km: "កាប់ទូក",
        latin: "Kab Tuk"
      },
      id: "05020209"
    },
    {
      name: {
        km: "ស្រែថ្នល់",
        latin: "Srae Thnal"
      },
      id: "05020210"
    },
    {
      name: {
        km: "ក្រាំងពលទេព",
        latin: "Krang Pol Tep"
      },
      id: "05020301"
    },
    {
      name: {
        km: "ពាណិជ្ជកម្ម",
        latin: "Peanicheakkam"
      },
      id: "05020302"
    },
    {
      name: {
        km: "បូរីកម្មករ",
        latin: "Borei Kammeakkar"
      },
      id: "05020303"
    },
    {
      name: {
        km: "សំណង់",
        latin: "Samnang"
      },
      id: "05020304"
    },
    {
      name: {
        km: "មុខខេត្ដ",
        latin: "Mukh Khett"
      },
      id: "05020305"
    },
    {
      name: {
        km: "ស្នោទី១",
        latin: "Snao Ti Muoy"
      },
      id: "05020306"
    },
    {
      name: {
        km: "អង្គសេរី",
        latin: "Angk Serei"
      },
      id: "05020307"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05020308"
    },
    {
      name: {
        km: "ទួលធ្នង់",
        latin: "Tuol Thnong"
      },
      id: "05020309"
    },
    {
      name: {
        km: "ខោប",
        latin: "Khaob"
      },
      id: "05020310"
    },
    {
      name: {
        km: "រកាធំ",
        latin: "Rokar Thum"
      },
      id: "05020311"
    },
    {
      name: {
        km: "ត្រពាំងលើក",
        latin: "Trapeang Leuk"
      },
      id: "05020312"
    },
    {
      name: {
        km: "តាំងទន្លេ",
        latin: "Tang Tonle"
      },
      id: "05020401"
    },
    {
      name: {
        km: "រំលោង",
        latin: "Rumloung"
      },
      id: "05020402"
    },
    {
      name: {
        km: "ថ្លុកឈើទាល",
        latin: "Thlok Chheu Teal"
      },
      id: "05020403"
    },
    {
      name: {
        km: "ត្រាចជ្រុំ",
        latin: "Trach Chrum"
      },
      id: "05020404"
    },
    {
      name: {
        km: "ប៉ែលហែល",
        latin: "Pael Hael"
      },
      id: "05020405"
    },
    {
      name: {
        km: "ក្រងផ្កា",
        latin: "Krang Phka"
      },
      id: "05020406"
    },
    {
      name: {
        km: "រំលោងប្រឃ្លះ",
        latin: "Runloung Prakhleah"
      },
      id: "05020407"
    },
    {
      name: {
        km: "ព្រៃកន្ទាច",
        latin: "Prey Kanteach"
      },
      id: "05020408"
    },
    {
      name: {
        km: "ស្នោទី២",
        latin: "Snao Ti Pir"
      },
      id: "05020409"
    },
    {
      name: {
        km: "រលាំងសង្កែ",
        latin: "Roleang Sangkae"
      },
      id: "05020410"
    },
    {
      name: {
        km: "ស្គុះ",
        latin: "Skuh"
      },
      id: "05020501"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "05020502"
    },
    {
      name: {
        km: "ស្វាយក្រវ៉ាន់",
        latin: "Svay Kravan"
      },
      id: "05020503"
    },
    {
      name: {
        km: "ទួលគក",
        latin: "Tuol Kouk"
      },
      id: "05020504"
    },
    {
      name: {
        km: "ផ្សារចាស់",
        latin: "Phsar Chas"
      },
      id: "05020505"
    },
    {
      name: {
        km: "ថ្នល់បំបែក",
        latin: "Thnal Bambaek"
      },
      id: "05020506"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "05020507"
    },
    {
      name: {
        km: "ត្រស់",
        latin: "Tras"
      },
      id: "05020508"
    },
    {
      name: {
        km: "អំពែភ្នំ",
        latin: "Ampeae Phnum"
      },
      id: "05020509"
    },
    {
      name: {
        km: "ត្រស់សាលា",
        latin: "Tras Sala"
      },
      id: "05020510"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "05020511"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "05030101"
    },
    {
      name: {
        km: "សោកម៉ោក",
        latin: "Saok Maok"
      },
      id: "05030102"
    },
    {
      name: {
        km: "ក្រសាំងផ្អែម",
        latin: "Krasang Ph'aem"
      },
      id: "05030103"
    },
    {
      name: {
        km: "អង្គពពេល",
        latin: "Angk Popel"
      },
      id: "05030104"
    },
    {
      name: {
        km: "សំរោងចាស់",
        latin: "Samraong Chas"
      },
      id: "05030105"
    },
    {
      name: {
        km: "សំពៅលូន",
        latin: "Sampov Lun"
      },
      id: "05030106"
    },
    {
      name: {
        km: "ធ្លកយល់",
        latin: "Thlok Yol"
      },
      id: "05030107"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "05030108"
    },
    {
      name: {
        km: "ត្រាំរនាប",
        latin: "Tram Roneab"
      },
      id: "05030109"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "05030110"
    },
    {
      name: {
        km: "ទូលសុទិន",
        latin: "Tuol Sotin"
      },
      id: "05030111"
    },
    {
      name: {
        km: "សំរោងរស្មី",
        latin: "Samraong Reaksmei"
      },
      id: "05030112"
    },
    {
      name: {
        km: "ស្វាយទាប",
        latin: "Svay Teab"
      },
      id: "05030113"
    },
    {
      name: {
        km: "អង្គក្រសាំង",
        latin: "Angk Krasang"
      },
      id: "05030201"
    },
    {
      name: {
        km: "ព្រៃតាមាន",
        latin: "Prey Ta Mean"
      },
      id: "05030202"
    },
    {
      name: {
        km: "អង្គសង្គ្រាម",
        latin: "Angk Sangkream"
      },
      id: "05030203"
    },
    {
      name: {
        km: "ថ្មក្ដារ",
        latin: "Thma Kdar"
      },
      id: "05030204"
    },
    {
      name: {
        km: "បាំងណា",
        latin: "Bang Na"
      },
      id: "05030205"
    },
    {
      name: {
        km: "អង្គតាឡី",
        latin: "Angk Ta Lei"
      },
      id: "05030206"
    },
    {
      name: {
        km: "កាន់តំរ៉ា",
        latin: "Kan Damra"
      },
      id: "05030207"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "05030208"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "05030209"
    },
    {
      name: {
        km: "កាយៀវ",
        latin: "Kayiev"
      },
      id: "05030210"
    },
    {
      name: {
        km: "រាំង",
        latin: "Reang"
      },
      id: "05030211"
    },
    {
      name: {
        km: "ព្រៃស្បូវ",
        latin: "Prey Sbov"
      },
      id: "05030212"
    },
    {
      name: {
        km: "ព្រៃតាលឹម",
        latin: "Prey Ta Luem"
      },
      id: "05030213"
    },
    {
      name: {
        km: "ក្រាំងឡង",
        latin: "Krang Lang"
      },
      id: "05030214"
    },
    {
      name: {
        km: "អង្គ្ករមាស",
        latin: "Angk Romeas"
      },
      id: "05030215"
    },
    {
      name: {
        km: "សណ្ដុល",
        latin: "Sandol"
      },
      id: "05030216"
    },
    {
      name: {
        km: "តាមេម",
        latin: "Ta Mem"
      },
      id: "05030217"
    },
    {
      name: {
        km: "ជុំស្រុក",
        latin: "Chum Srok"
      },
      id: "05030218"
    },
    {
      name: {
        km: "ជង្រុក",
        latin: "Chongruk"
      },
      id: "05030219"
    },
    {
      name: {
        km: "ព្រៃរោង",
        latin: "Prey Roung"
      },
      id: "05030220"
    },
    {
      name: {
        km: "បឹងទ័ពព្យុះ",
        latin: "Boeng Toap Pyuh"
      },
      id: "05030221"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "05030222"
    },
    {
      name: {
        km: "ត្រពាំងរនះ",
        latin: "Trapeang Roneah"
      },
      id: "05030223"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "05030224"
    },
    {
      name: {
        km: "ក្របីត្រាំ",
        latin: "Krabei Tram"
      },
      id: "05030225"
    },
    {
      name: {
        km: "ទួលវាសនា",
        latin: "Tuol Veasna"
      },
      id: "05030301"
    },
    {
      name: {
        km: "អង្គសុគន្ធា",
        latin: "Angk Sokonthea"
      },
      id: "05030302"
    },
    {
      name: {
        km: "តារាជ្យ",
        latin: "Ta Reach"
      },
      id: "05030303"
    },
    {
      name: {
        km: "អង្គ្កសខ្នាយ",
        latin: "Angk Sa Khnay"
      },
      id: "05030304"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "05030305"
    },
    {
      name: {
        km: "ព្រៃឃ្លោយ",
        latin: "Prey Khlouy"
      },
      id: "05030306"
    },
    {
      name: {
        km: "កក់ធំ",
        latin: "Kak Thum"
      },
      id: "05030307"
    },
    {
      name: {
        km: "ឈើលំ",
        latin: "Chheu Lum"
      },
      id: "05030308"
    },
    {
      name: {
        km: "ក្រាំងស្បូវ",
        latin: "Krang Sbov"
      },
      id: "05030309"
    },
    {
      name: {
        km: "ក្បាលរមាស",
        latin: "Kbal Romeas"
      },
      id: "05030310"
    },
    {
      name: {
        km: "ក្រាំងឫស្សី",
        latin: "Krang Ruessei"
      },
      id: "05030311"
    },
    {
      name: {
        km: "ទួលព្រិច",
        latin: "Tuol Prich"
      },
      id: "05030312"
    },
    {
      name: {
        km: "ត្រពាំងទាប",
        latin: "Trapeang Teab"
      },
      id: "05030313"
    },
    {
      name: {
        km: "ក្រាំងតាឡាត់",
        latin: "Krang Ta Lat"
      },
      id: "05030314"
    },
    {
      name: {
        km: "ព្រៃទំនប់",
        latin: "Prey Tumnob"
      },
      id: "05030315"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "05030316"
    },
    {
      name: {
        km: "ត្រាចកុះ",
        latin: "Trach Koh"
      },
      id: "05030317"
    },
    {
      name: {
        km: "អង្គរោង",
        latin: "Angk Roung"
      },
      id: "05030318"
    },
    {
      name: {
        km: "សំរោងខ្ពស់",
        latin: "Samraong Khpos"
      },
      id: "05030319"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "05030320"
    },
    {
      name: {
        km: "ក្រាំងឈើនាង",
        latin: "Krang Chheu Neang"
      },
      id: "05030321"
    },
    {
      name: {
        km: "អង្គរ.មាស",
        latin: "Angkor meas"
      },
      id: "05030322"
    },
    {
      name: {
        km: "ពន្លឺខែ",
        latin: "Ponlueu Khae"
      },
      id: "05030401"
    },
    {
      name: {
        km: "កន្លែងរមាស",
        latin: "Kanlaeng Romeas"
      },
      id: "05030402"
    },
    {
      name: {
        km: "ត្រពាំងស្នួល",
        latin: "Trapeang Snuol"
      },
      id: "05030403"
    },
    {
      name: {
        km: "ត្រពាំងថ្នល់",
        latin: "Trapeang Thnal"
      },
      id: "05030404"
    },
    {
      name: {
        km: "ពេជ្រមុនី",
        latin: "Pechr Muni"
      },
      id: "05030405"
    },
    {
      name: {
        km: "ត្រពាំងរកា",
        latin: "Trapeang Roka"
      },
      id: "05030406"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "05030407"
    },
    {
      name: {
        km: "ទួលព្រីង",
        latin: "Tuol Pring"
      },
      id: "05030408"
    },
    {
      name: {
        km: "ទួលម្អម",
        latin: "Tuol M'am"
      },
      id: "05030409"
    },
    {
      name: {
        km: "គោកដង្កោ",
        latin: "Kouk Dangkao"
      },
      id: "05030410"
    },
    {
      name: {
        km: "គគ្រួស",
        latin: "Kokruos"
      },
      id: "05030411"
    },
    {
      name: {
        km: "ចាស់",
        latin: "Chas"
      },
      id: "05030412"
    },
    {
      name: {
        km: "ផ្ចឹកជ្រុំ",
        latin: "Phchoek Chrum"
      },
      id: "05030413"
    },
    {
      name: {
        km: "ដុតកំបោរ",
        latin: "Dot Kambaor"
      },
      id: "05030501"
    },
    {
      name: {
        km: "កញ្ច្រប់",
        latin: "Kanhchrab"
      },
      id: "05030502"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "05030503"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "05030504"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "05030505"
    },
    {
      name: {
        km: "ដំណាក់មានជ័យ",
        latin: "Damnak Mean chey"
      },
      id: "05030506"
    },
    {
      name: {
        km: "ចំការស្បូវ",
        latin: "Chamkar Sbov"
      },
      id: "05030507"
    },
    {
      name: {
        km: "ច្រាមត្បូង",
        latin: "Chrab Tboung"
      },
      id: "05030508"
    },
    {
      name: {
        km: "ច្រាមជើង",
        latin: "Chrab Cheung"
      },
      id: "05030509"
    },
    {
      name: {
        km: "ព្រៃល្វា",
        latin: "Prey Lvea"
      },
      id: "05030510"
    },
    {
      name: {
        km: "ត្រពាំងស្លា",
        latin: "Trapeang Sla"
      },
      id: "05030511"
    },
    {
      name: {
        km: "បឹងកក់",
        latin: "Boeng Kak"
      },
      id: "05030512"
    },
    {
      name: {
        km: "ទួលធ្នង់",
        latin: "Tuol Thnong"
      },
      id: "05030513"
    },
    {
      name: {
        km: "សាលាគ្រួស",
        latin: "Sala Kruos"
      },
      id: "05030514"
    },
    {
      name: {
        km: "សាយ៉ាវ",
        latin: "Sayav"
      },
      id: "05030515"
    },
    {
      name: {
        km: "ក្រាំងទ្រា",
        latin: "Krang Trea"
      },
      id: "05030516"
    },
    {
      name: {
        km: "លៀក",
        latin: "Liek"
      },
      id: "05030517"
    },
    {
      name: {
        km: "ភានសា",
        latin: "Pheansa"
      },
      id: "05030518"
    },
    {
      name: {
        km: "ព្រៃថ្កូវ",
        latin: "Prey Thkov"
      },
      id: "05030519"
    },
    {
      name: {
        km: "ក្របៅ",
        latin: "Krabau"
      },
      id: "05030520"
    },
    {
      name: {
        km: "ថ្លុកព្រាល",
        latin: "Thlok Preal"
      },
      id: "05030521"
    },
    {
      name: {
        km: "ត្រពាំងចំបក់",
        latin: "Trapeang Chambak"
      },
      id: "05030522"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "05030523"
    },
    {
      name: {
        km: "តាអោង",
        latin: "Ta Aong"
      },
      id: "05030524"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "05030525"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "05030526"
    },
    {
      name: {
        km: "ព្រៃដងទឹក",
        latin: "Prey Dang Tuek"
      },
      id: "05030527"
    },
    {
      name: {
        km: "ខ្លែងពណ៌ត្បូង",
        latin: "Khlaeng Poar Tboung"
      },
      id: "05030601"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "05030602"
    },
    {
      name: {
        km: "ស្រែចរ",
        latin: "Srae Char"
      },
      id: "05030603"
    },
    {
      name: {
        km: "ត្រពាំងកន្ថោ",
        latin: "Trapeang Kanthao"
      },
      id: "05030604"
    },
    {
      name: {
        km: "ដំដែក",
        latin: "Dam Daek"
      },
      id: "05030605"
    },
    {
      name: {
        km: "ហោង",
        latin: "Haong"
      },
      id: "05030606"
    },
    {
      name: {
        km: "ព្រៃញាតិ",
        latin: "Prey Nheat"
      },
      id: "05030607"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "05030608"
    },
    {
      name: {
        km: "តានៃ",
        latin: "Ta Ney"
      },
      id: "05030609"
    },
    {
      name: {
        km: "ស្វាយចារ្យ",
        latin: "Svay Char"
      },
      id: "05030610"
    },
    {
      name: {
        km: "បាក់ក",
        latin: "Bak Kar"
      },
      id: "05030611"
    },
    {
      name: {
        km: "បឹងប្រស្រែ",
        latin: "Boeng Prasrae"
      },
      id: "05030612"
    },
    {
      name: {
        km: "អង្គសំរោង",
        latin: "Angk Samraong"
      },
      id: "05030613"
    },
    {
      name: {
        km: "ព្រៃជរ",
        latin: "Prey Chor"
      },
      id: "05030614"
    },
    {
      name: {
        km: "ត្បូងអង្គ",
        latin: "Tboung Angk"
      },
      id: "05030615"
    },
    {
      name: {
        km: "ព្រៃគុក",
        latin: "Prey Kuk"
      },
      id: "05030616"
    },
    {
      name: {
        km: "ឈូកស",
        latin: "Chhuk Sa"
      },
      id: "05030617"
    },
    {
      name: {
        km: "ផ្លូវដំរី",
        latin: "Phlov Damrei"
      },
      id: "05030618"
    },
    {
      name: {
        km: "ខ្លែងពណ៌ជើង",
        latin: "Khlaeng Poar Cheung"
      },
      id: "05030619"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "05030620"
    },
    {
      name: {
        km: "ព្រៃរងៀង",
        latin: "Prey Rongieng"
      },
      id: "05030621"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡាញ់",
        latin: "Trapeang Kralanh"
      },
      id: "05030622"
    },
    {
      name: {
        km: "សម្ដេចឪ",
        latin: "Samdach Ov"
      },
      id: "05030623"
    },
    {
      name: {
        km: "ចាស់",
        latin: "Chas"
      },
      id: "05030624"
    },
    {
      name: {
        km: "ព្រៃទើ",
        latin: "Prey Teu"
      },
      id: "05030625"
    },
    {
      name: {
        km: "ត្រពាំងធ្នង់",
        latin: "Trapeang Thnong"
      },
      id: "05030701"
    },
    {
      name: {
        km: "ដំណាក់ពង្រ",
        latin: "Damnak Pongro"
      },
      id: "05030702"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05030703"
    },
    {
      name: {
        km: "អង្គខ្ជាយ",
        latin: "Angk Khcheay"
      },
      id: "05030704"
    },
    {
      name: {
        km: "បឹងរនាល",
        latin: "Boeng Roneal"
      },
      id: "05030705"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "05030706"
    },
    {
      name: {
        km: "ឯកភាព",
        latin: "Aekak Pheap"
      },
      id: "05030707"
    },
    {
      name: {
        km: "ដំរីស្លាប់",
        latin: "Damrei Slab"
      },
      id: "05030708"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "05030709"
    },
    {
      name: {
        km: "ទួលត្បែង",
        latin: "Tuol Tbaeng"
      },
      id: "05030710"
    },
    {
      name: {
        km: "ព្រៃស្មាច់",
        latin: "Prey Smach"
      },
      id: "05030711"
    },
    {
      name: {
        km: "តាភោគ",
        latin: "Ta Phouk"
      },
      id: "05030712"
    },
    {
      name: {
        km: "ព្រៃកែស",
        latin: "Prey Kaes"
      },
      id: "05030713"
    },
    {
      name: {
        km: "ចំការដូង",
        latin: "Chamkar Doung"
      },
      id: "05030714"
    },
    {
      name: {
        km: "ព្រៃដំណាក់",
        latin: "Prey Damnak"
      },
      id: "05030715"
    },
    {
      name: {
        km: "បឹងចង្រៀក",
        latin: "Boeng Changriek"
      },
      id: "05030716"
    },
    {
      name: {
        km: "ជ្រៃវារ",
        latin: "Chrey Vear"
      },
      id: "05030717"
    },
    {
      name: {
        km: "ចេកស្រទន់",
        latin: "Chek Sraton"
      },
      id: "05030718"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "05030719"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "05030801"
    },
    {
      name: {
        km: "ព្រីងទឹក",
        latin: "Pring Tuek"
      },
      id: "05030802"
    },
    {
      name: {
        km: "ដូនអៀត",
        latin: "Doun Iet"
      },
      id: "05030803"
    },
    {
      name: {
        km: "ព្រៃតាអូក",
        latin: "Prey Ta Ouk"
      },
      id: "05030804"
    },
    {
      name: {
        km: "ព្រៃពង្រ",
        latin: "Prey Pongro"
      },
      id: "05030805"
    },
    {
      name: {
        km: "តារោង",
        latin: "Ta Roung"
      },
      id: "05030806"
    },
    {
      name: {
        km: "អូរតាពូង",
        latin: "Ou Ta Pung"
      },
      id: "05030807"
    },
    {
      name: {
        km: "ស្រែធ្លក",
        latin: "Srae Thlok"
      },
      id: "05030808"
    },
    {
      name: {
        km: "ព្រៃខ្វាវ",
        latin: "Prey Khvav"
      },
      id: "05030809"
    },
    {
      name: {
        km: "ដំដែក",
        latin: "Dam Daek"
      },
      id: "05030810"
    },
    {
      name: {
        km: "រកាកោះ",
        latin: "Roka Kaoh"
      },
      id: "05030811"
    },
    {
      name: {
        km: "ជៃតាកួន",
        latin: "Chey Ta Kuon"
      },
      id: "05030812"
    },
    {
      name: {
        km: "ក្រាំងខ្លុង",
        latin: "Krang Khlong"
      },
      id: "05030901"
    },
    {
      name: {
        km: "ត្រពាំងលើក",
        latin: "Trapeang Leuk"
      },
      id: "05030902"
    },
    {
      name: {
        km: "ព្រៃចង្វា",
        latin: "Prey Changva"
      },
      id: "05030903"
    },
    {
      name: {
        km: "ស្ដុក",
        latin: "Sdok"
      },
      id: "05030904"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "05030905"
    },
    {
      name: {
        km: "ព្រៃចារ",
        latin: "Prey Char"
      },
      id: "05030906"
    },
    {
      name: {
        km: "បឹងត្រាវ",
        latin: "Boeng Trav"
      },
      id: "05030907"
    },
    {
      name: {
        km: "ត្រាំកង់",
        latin: "Tram Kang"
      },
      id: "05030908"
    },
    {
      name: {
        km: "ព្រៃខ្វាវ",
        latin: "Prey Khvav"
      },
      id: "05030909"
    },
    {
      name: {
        km: "ប៉ុកតង់",
        latin: "Pok Tang"
      },
      id: "05030910"
    },
    {
      name: {
        km: "ព្រៃអន្ទាវ",
        latin: "Prey Anteav"
      },
      id: "05030911"
    },
    {
      name: {
        km: "ត្រពាំងកកោះ",
        latin: "Trapeang Kakaoh"
      },
      id: "05030912"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "05030913"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "05030914"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05030915"
    },
    {
      name: {
        km: "បឹងព័ទ្ធ",
        latin: "Boeng Poat"
      },
      id: "05030916"
    },
    {
      name: {
        km: "ត្រពាំងព្រលឹត",
        latin: "Trapeang Proluet"
      },
      id: "05030917"
    },
    {
      name: {
        km: "ត្រពាំងស្រស់",
        latin: "Trapeang Sras"
      },
      id: "05030918"
    },
    {
      name: {
        km: "ត្រាំខ្នារ",
        latin: "Tram Khnar"
      },
      id: "05031001"
    },
    {
      name: {
        km: "ត្រពាំងដា",
        latin: "Trapeang Da"
      },
      id: "05031002"
    },
    {
      name: {
        km: "ត្រពាំងស្ទង",
        latin: "Trapeang Stong"
      },
      id: "05031003"
    },
    {
      name: {
        km: "ត្រពាំងធ្លក",
        latin: "Trapeang Thlok"
      },
      id: "05031004"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "05031005"
    },
    {
      name: {
        km: "សូគង",
        latin: "Soukong"
      },
      id: "05031006"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "05031007"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "05031008"
    },
    {
      name: {
        km: "ក្រាំងពន្លៃ",
        latin: "Krang Ponley"
      },
      id: "05031009"
    },
    {
      name: {
        km: "ក្រាំងកណ្ដាល",
        latin: "Krang Kandal"
      },
      id: "05031010"
    },
    {
      name: {
        km: "អង្គកន្ទច",
        latin: "Angk Kantorch"
      },
      id: "05031011"
    },
    {
      name: {
        km: "ថ្មស",
        latin: "Thma Sa"
      },
      id: "05031012"
    },
    {
      name: {
        km: "ព្រៃតាមួច",
        latin: "Prey Ta Muoch"
      },
      id: "05031013"
    },
    {
      name: {
        km: "ព្រៃនូក",
        latin: "Prey Nuk"
      },
      id: "05031014"
    },
    {
      name: {
        km: "កក់ក្រពើ",
        latin: "Kak Krapeu"
      },
      id: "05031015"
    },
    {
      name: {
        km: "ក្រាំងគ្រួស",
        latin: "Krang Kruos"
      },
      id: "05031016"
    },
    {
      name: {
        km: "បល្ល័ង្គរាជា",
        latin: "Ballangk Reachea"
      },
      id: "05031017"
    },
    {
      name: {
        km: "ព្រៃកំបោរ",
        latin: "Prey Kambaor"
      },
      id: "05031018"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "05031019"
    },
    {
      name: {
        km: "អណ្ដូងច្រុះ",
        latin: "Andoung Chroh"
      },
      id: "05031020"
    },
    {
      name: {
        km: "ត្រពាំងមាន",
        latin: "Trapeang Mean"
      },
      id: "05031021"
    },
    {
      name: {
        km: "ត្រើយត្រក",
        latin: "Traeuy Trak"
      },
      id: "05031022"
    },
    {
      name: {
        km: "ថ្លុកមាន",
        latin: "Thlok Mean"
      },
      id: "05031023"
    },
    {
      name: {
        km: "ម្កាក់",
        latin: "Mkak"
      },
      id: "05031024"
    },
    {
      name: {
        km: "អូរពង្រ",
        latin: "Ou Pongro"
      },
      id: "05031101"
    },
    {
      name: {
        km: "អណ្ដូងប្រាំង",
        latin: "Andoung Prang"
      },
      id: "05031102"
    },
    {
      name: {
        km: "អូរតាវ៉ាម",
        latin: "Ou Ta Vam"
      },
      id: "05031103"
    },
    {
      name: {
        km: "រលាំងទឹក",
        latin: "Roleang Tuek"
      },
      id: "05031104"
    },
    {
      name: {
        km: "ថ្លុកដូនស",
        latin: "Thlok Doun Sa"
      },
      id: "05031105"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "05031106"
    },
    {
      name: {
        km: "ស្រង់",
        latin: "Srang"
      },
      id: "05031107"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "05031108"
    },
    {
      name: {
        km: "ត្របែកទូង",
        latin: "Trabaek Tung"
      },
      id: "05031109"
    },
    {
      name: {
        km: "ក្រាំងឃ្នង",
        latin: "Krang Khnong"
      },
      id: "05031110"
    },
    {
      name: {
        km: "អង្គដូនតី",
        latin: "Angk Doun Tei"
      },
      id: "05031111"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "05031112"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "05031113"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "05031114"
    },
    {
      name: {
        km: "ត្រពាំងត្រាច",
        latin: "Trapeang Trach"
      },
      id: "05031115"
    },
    {
      name: {
        km: "ស្រែមាន់",
        latin: "Srae Moan"
      },
      id: "05031116"
    },
    {
      name: {
        km: "ភែក",
        latin: "Pheaek"
      },
      id: "05031117"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "05031118"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "05031201"
    },
    {
      name: {
        km: "ចំបក់ក្អែរ",
        latin: "Chambak K'aer"
      },
      id: "05031202"
    },
    {
      name: {
        km: "សន្លង់",
        latin: "Sanlang"
      },
      id: "05031203"
    },
    {
      name: {
        km: "អង្គដូនពិស",
        latin: "Angk Doun Pis"
      },
      id: "05031204"
    },
    {
      name: {
        km: "ប្រវឹកពង",
        latin: "Pravuek Pong"
      },
      id: "05031205"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "05031206"
    },
    {
      name: {
        km: "បឹងងោង",
        latin: "Boeng Ngoung"
      },
      id: "05031207"
    },
    {
      name: {
        km: "ព្រៃកន្ទោច",
        latin: "Prey Kantouch"
      },
      id: "05031208"
    },
    {
      name: {
        km: "តាងៅ",
        latin: "Ta Ngov"
      },
      id: "05031209"
    },
    {
      name: {
        km: "ស្នួលជ្រុំ",
        latin: "Snuol Chrum"
      },
      id: "05031210"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "05031211"
    },
    {
      name: {
        km: "ត្រាវ",
        latin: "Trav"
      },
      id: "05031212"
    },
    {
      name: {
        km: "ត្បូងភ្នំ",
        latin: "Tboung Phnum"
      },
      id: "05031301"
    },
    {
      name: {
        km: "ត្រាវ",
        latin: "Trav"
      },
      id: "05031302"
    },
    {
      name: {
        km: "ក្រសាំងជីមែ",
        latin: "Krasang Chi Meae"
      },
      id: "05031303"
    },
    {
      name: {
        km: "ព្រៃស្ដេច",
        latin: "Prey Sdach"
      },
      id: "05031304"
    },
    {
      name: {
        km: "ព្រៃខ្លុង",
        latin: "Prey Khlong"
      },
      id: "05031305"
    },
    {
      name: {
        km: "ព្រៃខ្ញែ",
        latin: "Prey Khmer"
      },
      id: "05031306"
    },
    {
      name: {
        km: "ព្រៃទទឹងខាងលិច",
        latin: "Prey Totueng Khang Lech"
      },
      id: "05031307"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "05031308"
    },
    {
      name: {
        km: "ព្រៃទទឹងខាងកើត",
        latin: "Prey Totueng Khang Kaeut"
      },
      id: "05031309"
    },
    {
      name: {
        km: "ថ្មបាំង",
        latin: "Thma Bang"
      },
      id: "05031310"
    },
    {
      name: {
        km: "តាយ៉ង",
        latin: "Ta Yang"
      },
      id: "05031311"
    },
    {
      name: {
        km: "ព្រៃជ្រៃ",
        latin: "Prey Chrey"
      },
      id: "05031312"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "05031313"
    },
    {
      name: {
        km: "ទួលល្វា",
        latin: "Tuol Lvea"
      },
      id: "05031314"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "05031315"
    },
    {
      name: {
        km: "ព្រៃស្បាត",
        latin: "Prey Sbat"
      },
      id: "05031316"
    },
    {
      name: {
        km: "ទួលខ្ពស់",
        latin: "Tuol Khpos"
      },
      id: "05031317"
    },
    {
      name: {
        km: "ត្បូងបឹង",
        latin: "Tboung Boeng"
      },
      id: "05031318"
    },
    {
      name: {
        km: "ក្រាំងទាវ",
        latin: "Krang Teav"
      },
      id: "05031319"
    },
    {
      name: {
        km: "លិចបឹង`",
        latin: "Lech Boeng"
      },
      id: "05031320"
    },
    {
      name: {
        km: "ព្រៃស្បូវ",
        latin: "Prey Sbov"
      },
      id: "05031321"
    },
    {
      name: {
        km: "ចាម",
        latin: "Cham"
      },
      id: "05031322"
    },
    {
      name: {
        km: "តាំងរបង",
        latin: "Tang Robang"
      },
      id: "05040101"
    },
    {
      name: {
        km: "ចំប៉ី",
        latin: "Champei"
      },
      id: "05040102"
    },
    {
      name: {
        km: "ដីឆ្នាំង",
        latin: "Dei Chhnang"
      },
      id: "05040103"
    },
    {
      name: {
        km: "ក្រាំងតាវ៉ា",
        latin: "Krang Ta Va"
      },
      id: "05040104"
    },
    {
      name: {
        km: "ក្រាំងគគីរ",
        latin: "Krang Kokir"
      },
      id: "05040105"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "05040106"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "05040107"
    },
    {
      name: {
        km: "មនោរម្យ",
        latin: "Monourom"
      },
      id: "05040108"
    },
    {
      name: {
        km: "ទួលធ្នង់",
        latin: "Tuol Thnong"
      },
      id: "05040109"
    },
    {
      name: {
        km: "រស្មី",
        latin: "Reaksmei"
      },
      id: "05040201"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "05040202"
    },
    {
      name: {
        km: "ព្រៃមៀន",
        latin: "Prey Mean"
      },
      id: "05040203"
    },
    {
      name: {
        km: "អូរផ្ដៅ",
        latin: "Ou Phdau"
      },
      id: "05040204"
    },
    {
      name: {
        km: "ពាមរស់",
        latin: "Peam Ros"
      },
      id: "05040205"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "05040206"
    },
    {
      name: {
        km: "ពស់វែក",
        latin: "Pos Vaek"
      },
      id: "05040207"
    },
    {
      name: {
        km: "សង្កែជ្រុំ",
        latin: "Sangkae Chrum"
      },
      id: "05040208"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "05040301"
    },
    {
      name: {
        km: "ផ្លូវគោ",
        latin: "Phlov Kou"
      },
      id: "05040302"
    },
    {
      name: {
        km: "ច្រកទៀក",
        latin: "Chrak Tiek"
      },
      id: "05040303"
    },
    {
      name: {
        km: "ពាមល្វា",
        latin: "Peam Lvea"
      },
      id: "05040304"
    },
    {
      name: {
        km: "ល្ងឹម",
        latin: "Lnguem"
      },
      id: "05040306"
    },
    {
      name: {
        km: "ត្រពាំងអង្គ្រង",
        latin: "Trapeang Angkrong"
      },
      id: "05040311"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Tealchhum"
      },
      id: "05040312"
    },
    {
      name: {
        km: "អូរតោង",
        latin: "Ou Taong"
      },
      id: "05040313"
    },
    {
      name: {
        km: "ស្វាយទាប",
        latin: "Svay Teab"
      },
      id: "05040314"
    },
    {
      name: {
        km: "ក្រវៀក",
        latin: "Kraviek"
      },
      id: "05040315"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "05040316"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "05040317"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Cham Bok"
      },
      id: "05040318"
    },
    {
      name: {
        km: "ត្រាង",
        latin: "Traing"
      },
      id: "05040319"
    },
    {
      name: {
        km: "តានិល",
        latin: "Ta Nil"
      },
      id: "05040320"
    },
    {
      name: {
        km: "កោរដូនតី",
        latin: "Koa Doun Tei"
      },
      id: "05040321"
    },
    {
      name: {
        km: "ពុទ្រា",
        latin: "Putrea"
      },
      id: "05040322"
    },
    {
      name: {
        km: "ក្រាំងធ្នង់",
        latin: "Kraing Thnung"
      },
      id: "05040323"
    },
    {
      name: {
        km: "ជំនាប់",
        latin: "Chumnoab"
      },
      id: "05040401"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "05040402"
    },
    {
      name: {
        km: "ព្រីងកោង",
        latin: "Pring Kaong"
      },
      id: "05040403"
    },
    {
      name: {
        km: "យ៉ាងពិស",
        latin: "Yang Pis"
      },
      id: "05040404"
    },
    {
      name: {
        km: "តាំងស្រឹង",
        latin: "Tang Sroeng"
      },
      id: "05040405"
    },
    {
      name: {
        km: "ទួលឈីនាង",
        latin: "Tuol Chheu Neang"
      },
      id: "05040406"
    },
    {
      name: {
        km: "តាមិញ",
        latin: "Ta Minh"
      },
      id: "05040407"
    },
    {
      name: {
        km: "កន្ទួត",
        latin: "Kantuot"
      },
      id: "05040408"
    },
    {
      name: {
        km: "តាដែស",
        latin: "Ta Daes"
      },
      id: "05040409"
    },
    {
      name: {
        km: "ដំបងវេញ",
        latin: "Dambang Vinh"
      },
      id: "05040410"
    },
    {
      name: {
        km: "គោក",
        latin: "Kouk"
      },
      id: "05040411"
    },
    {
      name: {
        km: "ក្រាំងពង្រ",
        latin: "Krang Pongro"
      },
      id: "05040412"
    },
    {
      name: {
        km: "ផ្សារកន្ទួត",
        latin: "Phsar Kantuot"
      },
      id: "05040413"
    },
    {
      name: {
        km: "ស្រែវៀន",
        latin: "Srae Vien"
      },
      id: "05040414"
    },
    {
      name: {
        km: "ត្រពាំងគង",
        latin: "Trapeang Kong"
      },
      id: "05040415"
    },
    {
      name: {
        km: "តាសាល",
        latin: "Ta Sal"
      },
      id: "05040501"
    },
    {
      name: {
        km: "អន្លង់សង្កែ",
        latin: "Anlong Sangkae"
      },
      id: "05040502"
    },
    {
      name: {
        km: "កោះរុន",
        latin: "Kaoh Run"
      },
      id: "05040503"
    },
    {
      name: {
        km: "ក្នុងអាយ",
        latin: "Knong Ay"
      },
      id: "05040504"
    },
    {
      name: {
        km: "រោងម៉ាស៊ីន",
        latin: "Roung Masin"
      },
      id: "05040505"
    },
    {
      name: {
        km: "ត្រពាំងឆ្មៀ",
        latin: "Trapeang Chhmea"
      },
      id: "05040506"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "05040507"
    },
    {
      name: {
        km: "កំប៉េះ",
        latin: "Kampeh"
      },
      id: "05040508"
    },
    {
      name: {
        km: "តាំងបំពង់",
        latin: "Tang Bampong"
      },
      id: "05040509"
    },
    {
      name: {
        km: "កេះ",
        latin: "Keh"
      },
      id: "05040510"
    },
    {
      name: {
        km: "ជាំ",
        latin: "Choam"
      },
      id: "05040511"
    },
    {
      name: {
        km: "សុរិយា",
        latin: "Sorya"
      },
      id: "05040512"
    },
    {
      name: {
        km: "ក្រៀលពង",
        latin: "Kreal Pong"
      },
      id: "05040513"
    },
    {
      name: {
        km: "ដូនជន់",
        latin: "Doun Chun"
      },
      id: "05040514"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05040515"
    },
    {
      name: {
        km: "រមាំង ស",
        latin: "Romeang Sor"
      },
      id: "05040516"
    },
    {
      name: {
        km: "អូរអញ្ចា",
        latin: "Ou Ancha"
      },
      id: "05040517"
    },
    {
      name: {
        km: "ក្រាំងទំនប់",
        latin: "Krang Tumnob"
      },
      id: "05050101"
    },
    {
      name: {
        km: "ស្ដុកតោលថ្មី",
        latin: "Sdok Taol Thmei"
      },
      id: "05050102"
    },
    {
      name: {
        km: "ស្ដុកតោលចាស់",
        latin: "Sdok Taol Chas"
      },
      id: "05050103"
    },
    {
      name: {
        km: "ថ្មីទម្លាប់",
        latin: "Thmei Tumloab"
      },
      id: "05050104"
    },
    {
      name: {
        km: "ទួលវិហារ",
        latin: "Tuol Vihear"
      },
      id: "05050105"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "05050106"
    },
    {
      name: {
        km: "ព្រៃទាន់",
        latin: "Prey Toan"
      },
      id: "05050107"
    },
    {
      name: {
        km: "អន្លង់ចំណារ",
        latin: "Anlong Chamnar"
      },
      id: "05050108"
    },
    {
      name: {
        km: "ចាន់សែន",
        latin: "Chanteak Saen"
      },
      id: "05050109"
    },
    {
      name: {
        km: "ត្រពាំងអន្ទង់",
        latin: "Trapeang Antong"
      },
      id: "05050110"
    },
    {
      name: {
        km: "ទំនប់ទឹក",
        latin: "Tumnob Tuek"
      },
      id: "05050111"
    },
    {
      name: {
        km: "ត្រពាំងគោ",
        latin: "Trapeang Kou"
      },
      id: "05050112"
    },
    {
      name: {
        km: "អំពិលទឹក",
        latin: "Ampil Tuek"
      },
      id: "05050113"
    },
    {
      name: {
        km: "ស្ដុកអក",
        latin: "Sdok Ak"
      },
      id: "05050114"
    },
    {
      name: {
        km: "ប៉ាលហាល",
        latin: "Pal Hal"
      },
      id: "05050115"
    },
    {
      name: {
        km: "យស់ជោ",
        latin: "Yos Chour"
      },
      id: "05050201"
    },
    {
      name: {
        km: "តាសាល",
        latin: "Ta Sal"
      },
      id: "05050202"
    },
    {
      name: {
        km: "សារិកាកែវ",
        latin: "Sarekar Kaev"
      },
      id: "05050203"
    },
    {
      name: {
        km: "តាយស់",
        latin: "Ta Yos"
      },
      id: "05050204"
    },
    {
      name: {
        km: "ភ្នំទទឹង",
        latin: "Phnum Totueng"
      },
      id: "05050205"
    },
    {
      name: {
        km: "សួសនៃ",
        latin: "Suos Ney"
      },
      id: "05050206"
    },
    {
      name: {
        km: "បន្ទាយខ្មែរ",
        latin: "Banteay Khmer"
      },
      id: "05050207"
    },
    {
      name: {
        km: "ត្រពាំងរការ",
        latin: "Trapeang Rokar"
      },
      id: "05050208"
    },
    {
      name: {
        km: "អង្គសេរី",
        latin: "Angk Serei"
      },
      id: "05050209"
    },
    {
      name: {
        km: "ភ្នំបាត",
        latin: "Phnum Bat"
      },
      id: "05050210"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "05050211"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "05050212"
    },
    {
      name: {
        km: "អង្គតាប៉ុក",
        latin: "Angk Ta Pok"
      },
      id: "05050213"
    },
    {
      name: {
        km: "ស្រង៉ែ",
        latin: "Srangae"
      },
      id: "05050214"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "05050215"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "05050216"
    },
    {
      name: {
        km: "ជំពូព្រឹក្ស",
        latin: "Chumpu Proeks"
      },
      id: "05050301"
    },
    {
      name: {
        km: "ក្រាំងតាសោម",
        latin: "Krang Ta Saom"
      },
      id: "05050302"
    },
    {
      name: {
        km: "ព្រៃអំពិល",
        latin: "Prey Ampil"
      },
      id: "05050303"
    },
    {
      name: {
        km: "ច្រកពពូល",
        latin: "Chrak Popul"
      },
      id: "05050304"
    },
    {
      name: {
        km: "ត្រាចខ្ពស់",
        latin: "Trach Khpos"
      },
      id: "05050305"
    },
    {
      name: {
        km: "ព្រៃអន្សែ",
        latin: "Prey Ansae"
      },
      id: "05050306"
    },
    {
      name: {
        km: "ត្រពាំងលាប",
        latin: "Trapeang Leab"
      },
      id: "05050307"
    },
    {
      name: {
        km: "អូរសំរ៉ែ",
        latin: "Ou Samrae"
      },
      id: "05050401"
    },
    {
      name: {
        km: "ដំបូកស",
        latin: "Dambouk Sa"
      },
      id: "05050402"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡូង",
        latin: "Trapeang Kraloung"
      },
      id: "05050403"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "kandal"
      },
      id: "05050404"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "05050405"
    },
    {
      name: {
        km: "តាលីង",
        latin: "Ta Ling"
      },
      id: "05050406"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "05050407"
    },
    {
      name: {
        km: "ត្រពាំងប្រិយ៍",
        latin: "Trapeang Prei"
      },
      id: "05050408"
    },
    {
      name: {
        km: "ត្រពាំងកន្សែង",
        latin: "Trapeang Kansaeng"
      },
      id: "05050409"
    },
    {
      name: {
        km: "កាកាប",
        latin: "Kakab"
      },
      id: "05050410"
    },
    {
      name: {
        km: "តាឡាក់",
        latin: "Ta Lak"
      },
      id: "05050411"
    },
    {
      name: {
        km: "បាត់ដឹង",
        latin: "Bat Doeng"
      },
      id: "05050412"
    },
    {
      name: {
        km: "បែកឃ្លោក",
        latin: "Baek Khlouk"
      },
      id: "05050413"
    },
    {
      name: {
        km: "ដំណាក់ត្រាច",
        latin: "Damnak Trach"
      },
      id: "05050414"
    },
    {
      name: {
        km: "ស្ដុកល្ពៅ",
        latin: "Sdok Lpov"
      },
      id: "05050415"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "05050416"
    },
    {
      name: {
        km: "តាឡក",
        latin: "Ta Lok"
      },
      id: "05050417"
    },
    {
      name: {
        km: "ត្រាចទង",
        latin: "Trach Tong"
      },
      id: "05050418"
    },
    {
      name: {
        km: "ព្រៃតាជៃ",
        latin: "Prey Ta Chey"
      },
      id: "05050419"
    },
    {
      name: {
        km: "គោកសណ្ដែក",
        latin: "Kouk Sandaek"
      },
      id: "05050501"
    },
    {
      name: {
        km: "រំពាត់ជ្រូក",
        latin: "Rumpoat Chruk"
      },
      id: "05050502"
    },
    {
      name: {
        km: "ទួលបារ៉ាយ",
        latin: "Tuol Baray"
      },
      id: "05050503"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "05050504"
    },
    {
      name: {
        km: "អកយំ",
        latin: "Ak Yum"
      },
      id: "05050505"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "05050506"
    },
    {
      name: {
        km: "ក្បាលគាយ",
        latin: "Kbal Keay"
      },
      id: "05050507"
    },
    {
      name: {
        km: "សំបុកក្រៀល",
        latin: "Sambok Kriel"
      },
      id: "05050508"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "05050509"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "05050510"
    },
    {
      name: {
        km: "ទួលស្ដៅ",
        latin: "Tuol Sdau"
      },
      id: "05050511"
    },
    {
      name: {
        km: "ច្បារច្រុះ",
        latin: "Chbar Chroh"
      },
      id: "05050512"
    },
    {
      name: {
        km: "ត្រពាំងមាន",
        latin: "Trapeang Mean"
      },
      id: "05050513"
    },
    {
      name: {
        km: "កោះ",
        latin: "Kaoh"
      },
      id: "05050514"
    },
    {
      name: {
        km: "តានី",
        latin: "Ta Ni"
      },
      id: "05050515"
    },
    {
      name: {
        km: "ក្រាំងចេក",
        latin: "Krang Chek"
      },
      id: "05050516"
    },
    {
      name: {
        km: "ក្រាំងជង្រុក",
        latin: "Krang Chongruk"
      },
      id: "05050517"
    },
    {
      name: {
        km: "គោករវាយ",
        latin: "Kouk Roveay"
      },
      id: "05050518"
    },
    {
      name: {
        km: "ទួលកែស",
        latin: "Tuol Kaes"
      },
      id: "05050519"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "05050520"
    },
    {
      name: {
        km: "ត្រពាំងក្រឹម",
        latin: "Trapeang Kroem"
      },
      id: "05050521"
    },
    {
      name: {
        km: "ស្ដៅឯម",
        latin: "Sdau Aem"
      },
      id: "05050522"
    },
    {
      name: {
        km: "ដន្លង",
        latin: "Danlang"
      },
      id: "05050523"
    },
    {
      name: {
        km: "អំពិលផ្អែម",
        latin: "Ampil Ph'aem"
      },
      id: "05050524"
    },
    {
      name: {
        km: "ក្រាំងសុទិន",
        latin: "Krang Sotin"
      },
      id: "05050601"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "05050602"
    },
    {
      name: {
        km: "ទឹកឫស្សី",
        latin: "Tuek Ruessei"
      },
      id: "05050603"
    },
    {
      name: {
        km: "រមាំងស្លាប់",
        latin: "Romeang Slab"
      },
      id: "05050604"
    },
    {
      name: {
        km: "ទំពូង",
        latin: "Tumpung"
      },
      id: "05050605"
    },
    {
      name: {
        km: "វាលទន្សាយ",
        latin: "Veal Tonsay"
      },
      id: "05050606"
    },
    {
      name: {
        km: "ព្រៃជង្រុក",
        latin: "Prey Chongruk"
      },
      id: "05050607"
    },
    {
      name: {
        km: "ស្ដុក",
        latin: "Sdok"
      },
      id: "05050608"
    },
    {
      name: {
        km: "ស្ដុកស្អាត",
        latin: "Sdok S'at"
      },
      id: "05050609"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "05050610"
    },
    {
      name: {
        km: "ត្រពាំងចំបក់",
        latin: "Trapeang Chambak"
      },
      id: "05050611"
    },
    {
      name: {
        km: "ព្រៃស្មិត",
        latin: "Prey Smet"
      },
      id: "05050612"
    },
    {
      name: {
        km: "ទំនប់អង្គ",
        latin: "Tumnob Angk"
      },
      id: "05050613"
    },
    {
      name: {
        km: "ភ្លើងឆេះ",
        latin: "Phleung Chheh"
      },
      id: "05050614"
    },
    {
      name: {
        km: "អង្គសណ្ដាន់",
        latin: "Angk Sandan"
      },
      id: "05050701"
    },
    {
      name: {
        km: "ពោធិ៍កោង",
        latin: "Pou Kaong"
      },
      id: "05050702"
    },
    {
      name: {
        km: "តាជៀល",
        latin: "Ta Chiel"
      },
      id: "05050703"
    },
    {
      name: {
        km: "ឈូកស",
        latin: "Chhuk Sa"
      },
      id: "05050704"
    },
    {
      name: {
        km: "ស្រែចិន្ដា",
        latin: "Srae Chenda"
      },
      id: "05050705"
    },
    {
      name: {
        km: "ស្វាយពក",
        latin: "Svay Pok"
      },
      id: "05050706"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "05050707"
    },
    {
      name: {
        km: "ក្បាលអូរ",
        latin: "Kbal ou"
      },
      id: "05050708"
    },
    {
      name: {
        km: "អំពិលរូង",
        latin: "Ampil Rung"
      },
      id: "05050709"
    },
    {
      name: {
        km: "ដំណាក់ព្រីង",
        latin: "Damnak Pring"
      },
      id: "05050710"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "05050711"
    },
    {
      name: {
        km: "ព្រៃផ្ដៀក",
        latin: "Prey Phdiek"
      },
      id: "05050712"
    },
    {
      name: {
        km: "រំលេច",
        latin: "Rumlech"
      },
      id: "05050713"
    },
    {
      name: {
        km: "ទួលតារា",
        latin: "Tuol Ta Ra"
      },
      id: "05050714"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "05050715"
    },
    {
      name: {
        km: "ប្រវឹកពង",
        latin: "Pravuek Pong"
      },
      id: "05050716"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "05050717"
    },
    {
      name: {
        km: "ប្រកិល",
        latin: "Prakel"
      },
      id: "05050718"
    },
    {
      name: {
        km: "ឡខ្ទមខ្ពស់",
        latin: "La Khtom Khpos"
      },
      id: "05050801"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "05050802"
    },
    {
      name: {
        km: "គង់លឹង្គ",
        latin: "Kong Lueng"
      },
      id: "05050803"
    },
    {
      name: {
        km: "ច្រក",
        latin: "Chrak"
      },
      id: "05050804"
    },
    {
      name: {
        km: "ត្រពាំងលាន",
        latin: "Trapeang Lean"
      },
      id: "05050805"
    },
    {
      name: {
        km: "ក្រាំងស្លែង",
        latin: "Krang Slaeng"
      },
      id: "05050806"
    },
    {
      name: {
        km: "ព្រៃក្រសាំង",
        latin: "Prey Krasang"
      },
      id: "05050807"
    },
    {
      name: {
        km: "កណ្ដក់",
        latin: "Kandak"
      },
      id: "05050808"
    },
    {
      name: {
        km: "ត្រពាំងបង្រុះ",
        latin: "Trapeang Bangruh"
      },
      id: "05050809"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "05050810"
    },
    {
      name: {
        km: "ក្រាំងដូង",
        latin: "Krang Doung"
      },
      id: "05050811"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៅ",
        latin: "Trapeang Chrov"
      },
      id: "05050812"
    },
    {
      name: {
        km: "ក្រាំងក្រូច",
        latin: "Krang Krouch"
      },
      id: "05050813"
    },
    {
      name: {
        km: "គោកចារ្យ",
        latin: "Kouk Char"
      },
      id: "05050814"
    },
    {
      name: {
        km: "ស្វាយភ្លោះ",
        latin: "Svay Phluoh"
      },
      id: "05050815"
    },
    {
      name: {
        km: "តាឌុង",
        latin: "Ta Dung"
      },
      id: "05050901"
    },
    {
      name: {
        km: "ឫស្សីមួយគុម្ព",
        latin: "Ruessei Muoy Kum"
      },
      id: "05050902"
    },
    {
      name: {
        km: "ក្រាំងត្រប់",
        latin: "Krang Trab"
      },
      id: "05050903"
    },
    {
      name: {
        km: "ត្រពាំងក្ដុល",
        latin: "Trapeang Kdol"
      },
      id: "05050904"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "05050905"
    },
    {
      name: {
        km: "អណ្ដូងច្រុះ",
        latin: "Andoung Chroh"
      },
      id: "05050906"
    },
    {
      name: {
        km: "ត្រពាំងលើក",
        latin: "Trapeang Leuk"
      },
      id: "05050907"
    },
    {
      name: {
        km: "ត្រពាំងអង្គ្រង",
        latin: "Trapeang Angkrong"
      },
      id: "05050908"
    },
    {
      name: {
        km: "ក្រសាំងផ្អែម",
        latin: "Krasang Ph'aem"
      },
      id: "05050909"
    },
    {
      name: {
        km: "ស្រះស្ដេច",
        latin: "Srah Sdach"
      },
      id: "05050910"
    },
    {
      name: {
        km: "ឃ្លាំង",
        latin: "Khleang"
      },
      id: "05050911"
    },
    {
      name: {
        km: "ទួលខ្លុង",
        latin: "Tuol Khlong"
      },
      id: "05050912"
    },
    {
      name: {
        km: "រ៉ា",
        latin: "Ra"
      },
      id: "05050913"
    },
    {
      name: {
        km: "បឹងវ៉ា",
        latin: "Boeng Va"
      },
      id: "05050914"
    },
    {
      name: {
        km: "ត្រពាំងខ្ចៅ",
        latin: "Trapeang Khchau"
      },
      id: "05050915"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05050916"
    },
    {
      name: {
        km: "ត្រពាំងល្ពាក់",
        latin: "Trapeang Lpeak"
      },
      id: "05050917"
    },
    {
      name: {
        km: "ព្រៃច្រាបកើត",
        latin: "Prey Chrab Kaeut"
      },
      id: "05051001"
    },
    {
      name: {
        km: "ព្រៃច្រាបលិច",
        latin: "Prey Chrab Lech"
      },
      id: "05051002"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "05051003"
    },
    {
      name: {
        km: "ផ្គរ",
        latin: "Phkor"
      },
      id: "05051004"
    },
    {
      name: {
        km: "ខ្នុរអំពិល",
        latin: "Khnor Ampil"
      },
      id: "05051005"
    },
    {
      name: {
        km: "ត្រពាំងក្អែក",
        latin: "Trapeang K'aek"
      },
      id: "05051006"
    },
    {
      name: {
        km: "ថល់",
        latin: "Thal"
      },
      id: "05051007"
    },
    {
      name: {
        km: "ខ្សាច់ពួន",
        latin: "Khsach Puon"
      },
      id: "05051008"
    },
    {
      name: {
        km: "ខ្ចាស់",
        latin: "Khchas"
      },
      id: "05051009"
    },
    {
      name: {
        km: "ក្រឡាញ់",
        latin: "Kralanh"
      },
      id: "05051010"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "05051011"
    },
    {
      name: {
        km: "ធ្លាចាស់",
        latin: "Thlea Chas"
      },
      id: "05051012"
    },
    {
      name: {
        km: "សូវង្សក្រោម",
        latin: "Souvongs Kraom"
      },
      id: "05051013"
    },
    {
      name: {
        km: "ក្រាំងពញា",
        latin: "Krang Ponhea"
      },
      id: "05051014"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "05051015"
    },
    {
      name: {
        km: "ត្រពាំងខ្ទុំ",
        latin: "Trapeang Khtum"
      },
      id: "05051016"
    },
    {
      name: {
        km: "ដំណាក់ស្មាច់",
        latin: "Damnak Smach"
      },
      id: "05051017"
    },
    {
      name: {
        km: "ចំការដូង",
        latin: "Chamkar Doung"
      },
      id: "05051018"
    },
    {
      name: {
        km: "ស្ដុកក្រោល",
        latin: "Sdok Kraol"
      },
      id: "05051019"
    },
    {
      name: {
        km: "វាលពង់",
        latin: "Veal Pong"
      },
      id: "05051020"
    },
    {
      name: {
        km: "សូវង្សលើ",
        latin: "Souvongs Leu"
      },
      id: "05051021"
    },
    {
      name: {
        km: "ស្នាមពុក",
        latin: "Snam Puk"
      },
      id: "05051022"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "05051023"
    },
    {
      name: {
        km: "អណ្ដូងចំបក់",
        latin: "Andoung Chambak"
      },
      id: "05051024"
    },
    {
      name: {
        km: "វត្ដក្ដី",
        latin: "Voat Kdei"
      },
      id: "05051025"
    },
    {
      name: {
        km: "បូរីរម្យ",
        latin: "Boureirom"
      },
      id: "05051026"
    },
    {
      name: {
        km: "សាមគ្គីភាព",
        latin: "Sameakkipheap"
      },
      id: "05051027"
    },
    {
      name: {
        km: "ឧដុង្គ",
        latin: "Oudong"
      },
      id: "05051101"
    },
    {
      name: {
        km: "អូរការុង",
        latin: "OKarong"
      },
      id: "05051102"
    },
    {
      name: {
        km: "ចំការហ្លួង",
        latin: "Chamkar Luong"
      },
      id: "05051103"
    },
    {
      name: {
        km: "វិហារខ្ពស់",
        latin: "Vihear Khpos"
      },
      id: "05051104"
    },
    {
      name: {
        km: "ឃ្លាំងប្រាំ",
        latin: "Khlang Pram"
      },
      id: "05051105"
    },
    {
      name: {
        km: "ក្នុងវាំង",
        latin: "Knong Veang"
      },
      id: "05051106"
    },
    {
      name: {
        km: "ចិនដាំម្លូ",
        latin: "Chen Dam Mlou"
      },
      id: "05051107"
    },
    {
      name: {
        km: "ស្រះកែវ",
        latin: "Srah Keo"
      },
      id: "05051108"
    },
    {
      name: {
        km: "ក្រាំងពន្លៃ",
        latin: "Krang Ponley"
      },
      id: "05051109"
    },
    {
      name: {
        km: "វត្ដពោធិ៍",
        latin: "Voat Pou"
      },
      id: "05051110"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "05051201"
    },
    {
      name: {
        km: "ត្រាចកោង",
        latin: "Trach Kaong"
      },
      id: "05051202"
    },
    {
      name: {
        km: "អង្គរបាន",
        latin: "Angkor Ban"
      },
      id: "05051203"
    },
    {
      name: {
        km: "រោងគោ",
        latin: "Roung Kou"
      },
      id: "05051204"
    },
    {
      name: {
        km: "ព្រៃជុំ",
        latin: "Prey Chum"
      },
      id: "05051205"
    },
    {
      name: {
        km: "ធម្មទ័យ",
        latin: "Thommeak Tey"
      },
      id: "05051206"
    },
    {
      name: {
        km: "ត្រពាំងខ្នា",
        latin: "Trapeang Khna"
      },
      id: "05051207"
    },
    {
      name: {
        km: "ព្រៃទាន",
        latin: "Prey Tean"
      },
      id: "05051208"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "05051209"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "05051210"
    },
    {
      name: {
        km: "រាំងទេ",
        latin: "Reang Te"
      },
      id: "05051211"
    },
    {
      name: {
        km: "ក្រាំងក្ដី",
        latin: "Krang Kdei"
      },
      id: "05051212"
    },
    {
      name: {
        km: "ក្រាំងច្រេស",
        latin: "Krang Chres"
      },
      id: "05051213"
    },
    {
      name: {
        km: "ត្រពាំងល្ពាក់",
        latin: "Trapeang Lpeak"
      },
      id: "05051214"
    },
    {
      name: {
        km: "ស្រះធុល",
        latin: "Srah Thul"
      },
      id: "05051215"
    },
    {
      name: {
        km: "ក្រាំងខ្នុរ",
        latin: "Krang Knor"
      },
      id: "05051301"
    },
    {
      name: {
        km: "ជំទប់មាន់",
        latin: "Chumtob Moan"
      },
      id: "05051302"
    },
    {
      name: {
        km: "ចំបក់រន្ធ",
        latin: "Chambak Ron"
      },
      id: "05051303"
    },
    {
      name: {
        km: "ស្ដុក",
        latin: "Sdok"
      },
      id: "05051304"
    },
    {
      name: {
        km: "ទួលសំណាង",
        latin: "Tuol Samnang"
      },
      id: "05051305"
    },
    {
      name: {
        km: "ត្រពាំងស្មាច់",
        latin: "Trapeang Smach"
      },
      id: "05051306"
    },
    {
      name: {
        km: "ខ្សាច់ពូន",
        latin: "Khsach Poun"
      },
      id: "05051307"
    },
    {
      name: {
        km: "ដំណាក់រាំង",
        latin: "Damnak Reang"
      },
      id: "05051308"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "05051309"
    },
    {
      name: {
        km: "អង្រ្គង",
        latin: "Angkrong"
      },
      id: "05051310"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "05051311"
    },
    {
      name: {
        km: "ទួលសំបូរ",
        latin: "Tuol Sambour"
      },
      id: "05051312"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "05051313"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "05051314"
    },
    {
      name: {
        km: "ទំនប់បាក់",
        latin: "Tumnob Bak"
      },
      id: "05051315"
    },
    {
      name: {
        km: "ពីងពង់",
        latin: "Pingpong"
      },
      id: "05051316"
    },
    {
      name: {
        km: "ដឹកពាង",
        latin: "Doek Peang"
      },
      id: "05051317"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "05051318"
    },
    {
      name: {
        km: "ពាំងល្វា",
        latin: "Peang Lvea"
      },
      id: "05051401"
    },
    {
      name: {
        km: "អាយាន",
        latin: "Ayean"
      },
      id: "05051402"
    },
    {
      name: {
        km: "គង្រុត",
        latin: "Kongrut"
      },
      id: "05051403"
    },
    {
      name: {
        km: "ស្រែអូរ",
        latin: "Srae Ou"
      },
      id: "05051404"
    },
    {
      name: {
        km: "យាផ្លង",
        latin: "Yea Phlang"
      },
      id: "05051405"
    },
    {
      name: {
        km: "សារ៉ាម",
        latin: "Saram"
      },
      id: "05051406"
    },
    {
      name: {
        km: "ត្រោក",
        latin: "Traok"
      },
      id: "05051407"
    },
    {
      name: {
        km: "អន្លង់ភេ",
        latin: "Anlong Phe"
      },
      id: "05051408"
    },
    {
      name: {
        km: "សួស្ដី",
        latin: "Suor Sdei"
      },
      id: "05051409"
    },
    {
      name: {
        km: "ត្រពាំងរំចេក",
        latin: "Trapeang Rumchek"
      },
      id: "05051410"
    },
    {
      name: {
        km: "ខ្វឹត",
        latin: "Khvet"
      },
      id: "05051411"
    },
    {
      name: {
        km: "ឆ្វេក",
        latin: "Chvek"
      },
      id: "05051412"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់",
        latin: "Trapeang Skon"
      },
      id: "05051413"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "05051414"
    },
    {
      name: {
        km: "ត្រពាំងអណ្ដូង",
        latin: "Trapeang Andoung"
      },
      id: "05051415"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "05051416"
    },
    {
      name: {
        km: "ចឹនថ្មី",
        latin: "Chen Thmei"
      },
      id: "05051417"
    },
    {
      name: {
        km: "មាត់អូរ",
        latin: "Moat Ou"
      },
      id: "05051418"
    },
    {
      name: {
        km: "ក្របីព្រៃ",
        latin: "Krabei Prey"
      },
      id: "05051501"
    },
    {
      name: {
        km: "រោងដំរី",
        latin: "Roung Damrei"
      },
      id: "05051502"
    },
    {
      name: {
        km: "ស្ដុកស្លាត",
        latin: "Sdok Slat"
      },
      id: "05051503"
    },
    {
      name: {
        km: "ចាអោក",
        latin: "Cha Aok"
      },
      id: "05051504"
    },
    {
      name: {
        km: "អង្គតាឯក",
        latin: "Angk Ta Aek"
      },
      id: "05051505"
    },
    {
      name: {
        km: "ដំណាក់ខ្លា",
        latin: "Damnak Khla"
      },
      id: "05051506"
    },
    {
      name: {
        km: "ព្រៃក្ដួច",
        latin: "Prey Kduoch"
      },
      id: "05051507"
    },
    {
      name: {
        km: "ក្រាំងអារីយ៏",
        latin: "Krang Arei"
      },
      id: "05051508"
    },
    {
      name: {
        km: "ត្រពាំងទូក",
        latin: "Trapeang Touk"
      },
      id: "05051509"
    },
    {
      name: {
        km: "ពន្នរ",
        latin: "Ponnor"
      },
      id: "05051510"
    },
    {
      name: {
        km: "ត្រពាំងសុភី",
        latin: "Trapeang Souphi"
      },
      id: "05051511"
    },
    {
      name: {
        km: "ត្រពាំងកំប៉ោះ",
        latin: "Trapeang Kampaoh"
      },
      id: "05051512"
    },
    {
      name: {
        km: "ព្រៃព្រាល",
        latin: "Prey Preal"
      },
      id: "05051513"
    },
    {
      name: {
        km: "ព្រៃរោង",
        latin: "Prey Rong"
      },
      id: "05051514"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡឹង",
        latin: "Trapeang Kraloeng"
      },
      id: "05051515"
    },
    {
      name: {
        km: "ព្រៃតាទ្រូ",
        latin: "Prey Ta Trou"
      },
      id: "05051516"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "05051517"
    },
    {
      name: {
        km: "វត្ដភ្នំ",
        latin: "Voat Phnom"
      },
      id: "05051518"
    },
    {
      name: {
        km: "ក្រាំងចេក",
        latin: "Krang Chek"
      },
      id: "05060101"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "05060102"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05060103"
    },
    {
      name: {
        km: "ចំបក់ដង្គុំ",
        latin: "Chambak Dangkum"
      },
      id: "05060104"
    },
    {
      name: {
        km: "ក្បាលទឹកលើ",
        latin: "Kbal Tuek Leu"
      },
      id: "05060201"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "05060202"
    },
    {
      name: {
        km: "តានៃ",
        latin: "Ta Ney"
      },
      id: "05060203"
    },
    {
      name: {
        km: "ព្រៃឫស្សី",
        latin: "Prey Ruessei"
      },
      id: "05060204"
    },
    {
      name: {
        km: "ក្បាលទឹកក្រោម",
        latin: "Kbal Tuek Kraom"
      },
      id: "05060205"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05060206"
    },
    {
      name: {
        km: "សាលា",
        latin: "Sala"
      },
      id: "05060207"
    },
    {
      name: {
        km: "រស្មីសាមគ្គី",
        latin: "Raksmey Samaki"
      },
      id: "05060208"
    },
    {
      name: {
        km: "ច្រាបក្រសាំង",
        latin: "Chrab Krasang"
      },
      id: "05060209"
    },
    {
      name: {
        km: "ពាមខ្វាវ",
        latin: "Peam Khvav"
      },
      id: "05060301"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "05060302"
    },
    {
      name: {
        km: "ដំណាក់ត្រាច",
        latin: "Damnak Trach"
      },
      id: "05060303"
    },
    {
      name: {
        km: "មានជ័យ",
        latin: "Mean Chey"
      },
      id: "05060304"
    },
    {
      name: {
        km: "ដំបូករូង",
        latin: "Dambouk Rung"
      },
      id: "05060305"
    },
    {
      name: {
        km: "ត្រពាំងខ្លុង",
        latin: "Trapeang Khlong"
      },
      id: "05060306"
    },
    {
      name: {
        km: "មានសិរី",
        latin: "Mean Serei"
      },
      id: "05060307"
    },
    {
      name: {
        km: "ទឹកថ្លា",
        latin: "Toek Thla"
      },
      id: "05060308"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡឹង",
        latin: "Trapeang Kraloeng"
      },
      id: "05060401"
    },
    {
      name: {
        km: "ត្រពាំងអត់ទឹក",
        latin: "Trapeang At Tuek"
      },
      id: "05060402"
    },
    {
      name: {
        km: "ក្រាំងច្រេស",
        latin: "Krang Chres"
      },
      id: "05060403"
    },
    {
      name: {
        km: "ក្រាំងប្រស្រុក",
        latin: "Krang Prasrok"
      },
      id: "05060404"
    },
    {
      name: {
        km: "ក្រាំងសេរី",
        latin: "Krang Serei"
      },
      id: "05060405"
    },
    {
      name: {
        km: "ក្រាំងខ្វាវ",
        latin: "Krang Khvav"
      },
      id: "05060406"
    },
    {
      name: {
        km: "ក្រាំងពន្លៃ",
        latin: "Krang Ponley"
      },
      id: "05060407"
    },
    {
      name: {
        km: "ផ្សារត្រពាំងក្រឡឹង",
        latin: "Phsar Trapeang Kraloeng"
      },
      id: "05060408"
    },
    {
      name: {
        km: "ក្រាំងស្យា",
        latin: "Krang Sya"
      },
      id: "05060409"
    },
    {
      name: {
        km: "បន្ទាយរកា",
        latin: "Banteay Rokar"
      },
      id: "05060501"
    },
    {
      name: {
        km: "ក្រសាំងខ្ពស់",
        latin: "Krasang Khpos"
      },
      id: "05060502"
    },
    {
      name: {
        km: "ត្រពាំងប្រិយ៍",
        latin: "Trapeang Prei"
      },
      id: "05060503"
    },
    {
      name: {
        km: "ក្រាំងគរ",
        latin: "Krang Kor"
      },
      id: "05060504"
    },
    {
      name: {
        km: "ក្រាំងក្រូច",
        latin: "Krang Krouch"
      },
      id: "05060505"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "05060506"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "05060507"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "05060508"
    },
    {
      name: {
        km: "ព្រៃកាហៀច",
        latin: "Prey Kahiech"
      },
      id: "05060509"
    },
    {
      name: {
        km: "ភក់",
        latin: "Phok"
      },
      id: "05060601"
    },
    {
      name: {
        km: "បន្ទាប់",
        latin: "Bantoab"
      },
      id: "05060602"
    },
    {
      name: {
        km: "ត្រពាំងឱប",
        latin: "Trapeang Aob"
      },
      id: "05060603"
    },
    {
      name: {
        km: "ក្រាំងថ្នល់",
        latin: "Krang Thnal"
      },
      id: "05060604"
    },
    {
      name: {
        km: "អន្លង់ថ្លឹង",
        latin: "Anlong Thloeng"
      },
      id: "05060605"
    },
    {
      name: {
        km: "ក្រាំងចចាត",
        latin: "Krang Chachat"
      },
      id: "05060606"
    },
    {
      name: {
        km: "ក្រឡាញ់",
        latin: "Kralanh"
      },
      id: "05060607"
    },
    {
      name: {
        km: "ក្រាំងក្ដី",
        latin: "Krang Kdei"
      },
      id: "05060608"
    },
    {
      name: {
        km: "ក្រាំងជ្រែ",
        latin: "Krang Chreae"
      },
      id: "05060609"
    },
    {
      name: {
        km: "តាព្រះ",
        latin: "Ta Preah"
      },
      id: "05060610"
    },
    {
      name: {
        km: "ច្រកត្រាច",
        latin: "Chrak Trach"
      },
      id: "05060611"
    },
    {
      name: {
        km: "តាមេញ",
        latin: "Ta Menh"
      },
      id: "05060612"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡាញ់",
        latin: "Trapeang Kralanh"
      },
      id: "05060613"
    },
    {
      name: {
        km: "ព្រៃស្ដុក",
        latin: "Prey Sdok"
      },
      id: "05060614"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "05060615"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "05060616"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "05060617"
    },
    {
      name: {
        km: "ទួលសេរី",
        latin: "Tuol Serei"
      },
      id: "05060618"
    },
    {
      name: {
        km: "តាំងរងៀង",
        latin: "Tang Rongieng"
      },
      id: "05060619"
    },
    {
      name: {
        km: "ទួលថ្មី",
        latin: "Tuol Thmei"
      },
      id: "05060620"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "05060621"
    },
    {
      name: {
        km: "ក្រាំងដូង",
        latin: "Krang Doung"
      },
      id: "05060622"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "05060623"
    },
    {
      name: {
        km: "ក្បាលថ្នល់",
        latin: "Kbal Thnal"
      },
      id: "05060624"
    },
    {
      name: {
        km: "សេរីវ័ន្ដ",
        latin: "Sereivoan"
      },
      id: "05060625"
    },
    {
      name: {
        km: "ក្រាំងល្ហុង",
        latin: "Krang Lhong"
      },
      id: "05060626"
    },
    {
      name: {
        km: "ក្រាំងតាតន់",
        latin: "Krang Ta Tan"
      },
      id: "05060701"
    },
    {
      name: {
        km: "អំពៅ",
        latin: "Ampov"
      },
      id: "05060702"
    },
    {
      name: {
        km: "កូនត្រុំ",
        latin: "Koun Trom"
      },
      id: "05060703"
    },
    {
      name: {
        km: "ក្រាំងភ្នៅ",
        latin: "Krang Pnov"
      },
      id: "05060704"
    },
    {
      name: {
        km: "ក្រាំងចោទ",
        latin: "Krang Chaot"
      },
      id: "05060705"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "05060706"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "05060707"
    },
    {
      name: {
        km: "រំដួល៨៨",
        latin: "Rumduol Paetprambei"
      },
      id: "05060708"
    },
    {
      name: {
        km: "អូរល្វា",
        latin: "Ou Lvea"
      },
      id: "05060709"
    },
    {
      name: {
        km: "ស្វាយជ្រំ",
        latin: "Svay Chrum"
      },
      id: "05060710"
    },
    {
      name: {
        km: "ព្រៃច្រេស",
        latin: "Prey Chres"
      },
      id: "05060711"
    },
    {
      name: {
        km: "តាឡាត់ចាស់",
        latin: "Ta Lat Chas"
      },
      id: "05060712"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "05060713"
    },
    {
      name: {
        km: "ផ្សារតាឡាត់",
        latin: "Phsar Ta Lat"
      },
      id: "05060714"
    },
    {
      name: {
        km: "ក្រាំងសំរ៉ែ",
        latin: "Krang Samrae"
      },
      id: "05060715"
    },
    {
      name: {
        km: "តាឡាត់ថ្មី",
        latin: "Ta Lat Thmei"
      },
      id: "05060716"
    },
    {
      name: {
        km: "តាវ៉ា",
        latin: "Ta Va"
      },
      id: "05060717"
    },
    {
      name: {
        km: "ព្រៃស្រោង",
        latin: "Prey Sraong"
      },
      id: "05060718"
    },
    {
      name: {
        km: "ក្រាំងពោធិ៍",
        latin: "Krang Pou"
      },
      id: "05060719"
    },
    {
      name: {
        km: "កុមារពេទ្យ",
        latin: "Komar Pet"
      },
      id: "05060720"
    },
    {
      name: {
        km: "ត្រពាំងស្តុកគ្រូ",
        latin: "Trapaeng Stock Kru"
      },
      id: "05060721"
    },
    {
      name: {
        km: "ក្រាំងរលួស",
        latin: "Krang Roluos"
      },
      id: "05060722"
    },
    {
      name: {
        km: "ជន្លង់ម្លូ",
        latin: "Chonlong Mlu"
      },
      id: "05060801"
    },
    {
      name: {
        km: "គីរីរស្មី",
        latin: "Kiri Reaksmei"
      },
      id: "05060802"
    },
    {
      name: {
        km: "ព្រំតោស",
        latin: "Prum Taos"
      },
      id: "05060803"
    },
    {
      name: {
        km: "រំដួលថ្មី",
        latin: "Rumduol Thmei"
      },
      id: "05060804"
    },
    {
      name: {
        km: "កាប់ទូក",
        latin: "Kab Tuk"
      },
      id: "05060805"
    },
    {
      name: {
        km: "អូរមុខទឹក",
        latin: "Ou Mukh Tuek"
      },
      id: "05060806"
    },
    {
      name: {
        km: "ព្រៃរមៀត",
        latin: "Prey Romiet"
      },
      id: "05060807"
    },
    {
      name: {
        km: "ប្រីយ៍",
        latin: "Prei"
      },
      id: "05060901"
    },
    {
      name: {
        km: "ស្រែត្រពាំង",
        latin: "Sre Trapang"
      },
      id: "05060902"
    },
    {
      name: {
        km: "គ្រៀលបួស",
        latin: "Kriel Buos"
      },
      id: "05060903"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "05060904"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "05060905"
    },
    {
      name: {
        km: "ព្រៃក្បុង",
        latin: "Prey Kbong"
      },
      id: "05060906"
    },
    {
      name: {
        km: "ជ័យថ្មី",
        latin: "Chey Thmei"
      },
      id: "05060907"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "05060908"
    },
    {
      name: {
        km: "ក្រាំងធំ",
        latin: "Krang Thum"
      },
      id: "05061001"
    },
    {
      name: {
        km: "ក្រាំងបឹង",
        latin: "Krang Boeng"
      },
      id: "05061002"
    },
    {
      name: {
        km: "ស្រែជ្រៅ",
        latin: "Srae Chrov"
      },
      id: "05061003"
    },
    {
      name: {
        km: "តាំងសំរោង",
        latin: "Tang Samraong"
      },
      id: "05061004"
    },
    {
      name: {
        km: "ខ្នងក្រាំង",
        latin: "Khnang Krang"
      },
      id: "05061005"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05061006"
    },
    {
      name: {
        km: "ស្ដុកជ្រៃ",
        latin: "Sdok Chrey"
      },
      id: "05061007"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "05061008"
    },
    {
      name: {
        km: "តាយ៉ូវ",
        latin: "Ta Youv"
      },
      id: "05061009"
    },
    {
      name: {
        km: "ជ្រៃសែនជ័យ",
        latin: "Chrey Saenchey"
      },
      id: "05061010"
    },
    {
      name: {
        km: "ឈើនាងខ្ពស់",
        latin: "Chheu Neang Khpos"
      },
      id: "05061101"
    },
    {
      name: {
        km: "ច្រកខ្លា",
        latin: "Chrak Khla"
      },
      id: "05061102"
    },
    {
      name: {
        km: "កណ្ដោលកោង",
        latin: "Kandaol Kaong"
      },
      id: "05061103"
    },
    {
      name: {
        km: "តាំងស្យា",
        latin: "Tang Sya"
      },
      id: "05061104"
    },
    {
      name: {
        km: "គីរីរស្មី",
        latin: "Kiri Reaksmei"
      },
      id: "05061105"
    },
    {
      name: {
        km: "តាំងស្រឡៅ",
        latin: "Tang Sralau"
      },
      id: "05061106"
    },
    {
      name: {
        km: "ជ្រែ",
        latin: "Chreae"
      },
      id: "05061107"
    },
    {
      name: {
        km: "ក្រាំងតាកន",
        latin: "Krang Ta Kan"
      },
      id: "05061108"
    },
    {
      name: {
        km: "ក្រាំងខ្ជាយ",
        latin: "Krang Khcheay"
      },
      id: "05061109"
    },
    {
      name: {
        km: "ព្រំរលក",
        latin: "Prum Rolok"
      },
      id: "05061110"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "05061111"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "05061112"
    },
    {
      name: {
        km: "ដីដុះ",
        latin: "Dei Doh"
      },
      id: "05061113"
    },
    {
      name: {
        km: "មាត់សាយ",
        latin: "Moat Say"
      },
      id: "05061114"
    },
    {
      name: {
        km: "ត្នោតព្រែក",
        latin: "Tnaot Preaek"
      },
      id: "05061115"
    },
    {
      name: {
        km: "ក្អែកពង",
        latin: "K'aek Pong"
      },
      id: "05061116"
    },
    {
      name: {
        km: "ក្រាំងស្លែង",
        latin: "Krang Slaeng"
      },
      id: "05061117"
    },
    {
      name: {
        km: "ទី១",
        latin: "Ti Muoy"
      },
      id: "05061301"
    },
    {
      name: {
        km: "ទី២",
        latin: "Ti Pir"
      },
      id: "05061302"
    },
    {
      name: {
        km: "ទី៣",
        latin: "Ti Bei"
      },
      id: "05061303"
    },
    {
      name: {
        km: "ទី៤",
        latin: "Ti Buon"
      },
      id: "05061304"
    },
    {
      name: {
        km: "ទី៥",
        latin: "Ti Pram"
      },
      id: "05061305"
    },
    {
      name: {
        km: "ទី៦",
        latin: "Ti Prammuoy"
      },
      id: "05061306"
    },
    {
      name: {
        km: "ពាមទុង",
        latin: "Peam Tung"
      },
      id: "05061307"
    },
    {
      name: {
        km: "ប្រសព្វជុំ",
        latin: "Prasob Chum"
      },
      id: "05061308"
    },
    {
      name: {
        km: "ស្បែកព្រាល",
        latin: "Sbaek Preal"
      },
      id: "05061309"
    },
    {
      name: {
        km: "ដំបូកខ្ពស់",
        latin: "Dambouk Kpus"
      },
      id: "05061310"
    },
    {
      name: {
        km: "អូរកូនត្រុំ",
        latin: "Ou Koun Trom"
      },
      id: "05061311"
    },
    {
      name: {
        km: "ដើមផ្តៀក",
        latin: "Derm Pdeak"
      },
      id: "05061312"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "05061313"
    },
    {
      name: {
        km: "ចំការចេក",
        latin: "Chamkar Chek"
      },
      id: "05061314"
    },
    {
      name: {
        km: "ទឹកចេញ",
        latin: "Toek Jenh"
      },
      id: "05061315"
    },
    {
      name: {
        km: "កងមាស",
        latin: "Kang Meas"
      },
      id: "05061316"
    },
    {
      name: {
        km: "ផ្សារត្រែងត្រយឹង",
        latin: "Phsar Traeng Trayueng"
      },
      id: "05061317"
    },
    {
      name: {
        km: "ស្រល់",
        latin: "Srol"
      },
      id: "05061318"
    },
    {
      name: {
        km: "វាលធំ",
        latin: "Veal Thom"
      },
      id: "05061319"
    },
    {
      name: {
        km: "សម្បទានសែនជ័យ",
        latin: "Sambatean Saenchey"
      },
      id: "05061320"
    },
    {
      name: {
        km: "អំពិលផ្អែម",
        latin: "Ampil Ph'aem"
      },
      id: "05070101"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "05070102"
    },
    {
      name: {
        km: "បាក់ធ្មេញ",
        latin: "Bak Thmenh"
      },
      id: "05070103"
    },
    {
      name: {
        km: "ព្រីង",
        latin: "Pring"
      },
      id: "05070104"
    },
    {
      name: {
        km: "លាក់អន្លូង",
        latin: "Leak Anlung"
      },
      id: "05070105"
    },
    {
      name: {
        km: "តាកេរ្ដិ៍",
        latin: "Ta Ker"
      },
      id: "05070106"
    },
    {
      name: {
        km: "ក្រាំងស្ពឺ",
        latin: "Krang Spueu"
      },
      id: "05070107"
    },
    {
      name: {
        km: "ក្រាំងធំ",
        latin: "Krang Thum"
      },
      id: "05070108"
    },
    {
      name: {
        km: "ស្រែចែង",
        latin: "Srae Chaeng"
      },
      id: "05070109"
    },
    {
      name: {
        km: "សៅកែ",
        latin: "Saukae"
      },
      id: "05070110"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05070111"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "05070112"
    },
    {
      name: {
        km: "មហាលាភ",
        latin: "Mohaleaph"
      },
      id: "05070113"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "05070114"
    },
    {
      name: {
        km: "អណ្ដូងប្រេង",
        latin: "Andoung Preng"
      },
      id: "05070115"
    },
    {
      name: {
        km: "ស្រែកក់",
        latin: "Srae Kak"
      },
      id: "05070116"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "05070117"
    },
    {
      name: {
        km: "បន្លាស្អិត",
        latin: "Banla S'et"
      },
      id: "05070118"
    },
    {
      name: {
        km: "ជ្រលងថ្កូវ",
        latin: "Chrolong Thkov"
      },
      id: "05070119"
    },
    {
      name: {
        km: "ព្រៃកំពោង",
        latin: "Prey Kampoung"
      },
      id: "05070201"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "05070202"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "05070203"
    },
    {
      name: {
        km: "ត្រពាំងអាង",
        latin: "Trapeang Ang"
      },
      id: "05070204"
    },
    {
      name: {
        km: "ព្រះខែ",
        latin: "Preah Khae"
      },
      id: "05070205"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៅ",
        latin: "Trapeang Chrov"
      },
      id: "05070206"
    },
    {
      name: {
        km: "វល្លិប្រេង",
        latin: "Voa Preng"
      },
      id: "05070207"
    },
    {
      name: {
        km: "ពង្រធំ",
        latin: "Pongro Thum"
      },
      id: "05070208"
    },
    {
      name: {
        km: "ទំពូង",
        latin: "Tumpung"
      },
      id: "05070209"
    },
    {
      name: {
        km: "រលាំងជ្រៃ",
        latin: "Roleang Chrey"
      },
      id: "05070210"
    },
    {
      name: {
        km: "គោករំលិច",
        latin: "Kouk Rumlich"
      },
      id: "05070211"
    },
    {
      name: {
        km: "កាហែង",
        latin: "Kahaeng"
      },
      id: "05070212"
    },
    {
      name: {
        km: "បុស្សតានៃ",
        latin: "Bos Ta Ney"
      },
      id: "05070213"
    },
    {
      name: {
        km: "អូរវែង",
        latin: "Ou Veaeng"
      },
      id: "05070214"
    },
    {
      name: {
        km: "ឡ",
        latin: "La"
      },
      id: "05070215"
    },
    {
      name: {
        km: "ស្រីសំពោង",
        latin: "Srei Sampoung"
      },
      id: "05070301"
    },
    {
      name: {
        km: "ក្បាលត្រឡាច",
        latin: "Kbal Tralach"
      },
      id: "05070302"
    },
    {
      name: {
        km: "ពេជ្រសង្វា",
        latin: "Pech Sangva"
      },
      id: "05070303"
    },
    {
      name: {
        km: "សង្កែជ្រុំ",
        latin: "Sangkae Chrum"
      },
      id: "05070304"
    },
    {
      name: {
        km: "ខ្វាន",
        latin: "Khvan"
      },
      id: "05070305"
    },
    {
      name: {
        km: "សប់ង៉",
        latin: "Sab Nga"
      },
      id: "05070306"
    },
    {
      name: {
        km: "សុវណ្ណគីរី",
        latin: "Sovann Kiri"
      },
      id: "05070307"
    },
    {
      name: {
        km: "យស់ជោ",
        latin: "Yos Chour"
      },
      id: "05070308"
    },
    {
      name: {
        km: "ច្រកបានសុខ",
        latin: "Chrak Ban Sokh"
      },
      id: "05070309"
    },
    {
      name: {
        km: "កាហុន",
        latin: "Kahon"
      },
      id: "05070310"
    },
    {
      name: {
        km: "ថ្លុករាំង",
        latin: "Thlok Reang"
      },
      id: "05070311"
    },
    {
      name: {
        km: "ព្រៃនរា",
        latin: "Prey Norea"
      },
      id: "05070312"
    },
    {
      name: {
        km: "ឯកភាព",
        latin: "Ek Pheap"
      },
      id: "05070313"
    },
    {
      name: {
        km: "តាសាល",
        latin: "Ta Sal"
      },
      id: "05070314"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "05070401"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "05070402"
    },
    {
      name: {
        km: "តានង់",
        latin: "Ta Nong"
      },
      id: "05070403"
    },
    {
      name: {
        km: "ថ្លុកដូនសុខ",
        latin: "Thlok Doun Sokh"
      },
      id: "05070404"
    },
    {
      name: {
        km: "ស្រែផ្សរ",
        latin: "Srae Phsa"
      },
      id: "05070405"
    },
    {
      name: {
        km: "ក្ដុល",
        latin: "Kdol"
      },
      id: "05070406"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "05070407"
    },
    {
      name: {
        km: "អង្វែរ",
        latin: "Angvae"
      },
      id: "05070408"
    },
    {
      name: {
        km: "ចំការវាល",
        latin: "Chamkar Veal"
      },
      id: "05070409"
    },
    {
      name: {
        km: "អន្លង់ពពាយ",
        latin: "Anlong Popeay"
      },
      id: "05070410"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "05070411"
    },
    {
      name: {
        km: "អូរទំនាប",
        latin: "Ou Tumneab"
      },
      id: "05070412"
    },
    {
      name: {
        km: "អូរសំបួរ",
        latin: "Ou Sambuor"
      },
      id: "05070413"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "05070414"
    },
    {
      name: {
        km: "តានាង",
        latin: "Ta Neang"
      },
      id: "05070415"
    },
    {
      name: {
        km: "តាំងក្រសាំង",
        latin: "Tang Krasang"
      },
      id: "05070501"
    },
    {
      name: {
        km: "បាទី",
        latin: "Bati"
      },
      id: "05070502"
    },
    {
      name: {
        km: "ត្រពាំងអូល",
        latin: "Trapeang Oul"
      },
      id: "05070503"
    },
    {
      name: {
        km: "ក្រាំងស្នួល",
        latin: "Krang Snuol"
      },
      id: "05070504"
    },
    {
      name: {
        km: "ត្រពាំងតាមាន់",
        latin: "Trapeang Ta Moan"
      },
      id: "05070505"
    },
    {
      name: {
        km: "តាំងតាឡាត់",
        latin: "Tang Ta Lat"
      },
      id: "05070506"
    },
    {
      name: {
        km: "ត្នោតជ្រំ",
        latin: "Tnaot Chrum"
      },
      id: "05070507"
    },
    {
      name: {
        km: "អូរលើ",
        latin: "Ou Leu"
      },
      id: "05070508"
    },
    {
      name: {
        km: "អូរក្រោម",
        latin: "Ou Kraom"
      },
      id: "05070509"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "05070510"
    },
    {
      name: {
        km: "ដំឡូងខ្យា",
        latin: "Damloung Khya"
      },
      id: "05070511"
    },
    {
      name: {
        km: "ព្រៃសំបុក",
        latin: "Prey Sambok"
      },
      id: "05070512"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "05070513"
    },
    {
      name: {
        km: "ក្រាំងតារ័ត្ន",
        latin: "Krang Ta Roatn"
      },
      id: "05070514"
    },
    {
      name: {
        km: "គោកមានជ័យ",
        latin: "Kouk Mean Chey"
      },
      id: "05070515"
    },
    {
      name: {
        km: "អង្គរជា",
        latin: "Angkor Chea"
      },
      id: "05070516"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "05070517"
    },
    {
      name: {
        km: "សំពៅង",
        latin: "Sampov Ngo"
      },
      id: "05070518"
    },
    {
      name: {
        km: "ញ្នយ",
        latin: "Pneay"
      },
      id: "05070519"
    },
    {
      name: {
        km: "រលាំងគ្រើល",
        latin: "Roleang Kreul"
      },
      id: "05070601"
    },
    {
      name: {
        km: "កណ្ដោលផើម",
        latin: "Kandaol Phaeum"
      },
      id: "05070602"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "05070603"
    },
    {
      name: {
        km: "ចំបក់ផ្អែម",
        latin: "Chambak Ph'aem"
      },
      id: "05070604"
    },
    {
      name: {
        km: "ចំការបុស្ស",
        latin: "Chamkar Bos"
      },
      id: "05070605"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "05070606"
    },
    {
      name: {
        km: "ត្រពាំងសាប",
        latin: "Trapeang Sab"
      },
      id: "05070607"
    },
    {
      name: {
        km: "ត្រពាំងខ្យង",
        latin: "Trapeang Khyang"
      },
      id: "05070608"
    },
    {
      name: {
        km: "ឈើទាលក្រោម",
        latin: "Chheu Teal Kraom"
      },
      id: "05070609"
    },
    {
      name: {
        km: "ព្រៃសាលា",
        latin: "Prey Sala"
      },
      id: "05070610"
    },
    {
      name: {
        km: "អង្គតានន",
        latin: "Angk Ta Non"
      },
      id: "05070611"
    },
    {
      name: {
        km: "ក្រាំងស្វាយ",
        latin: "Krang Svay"
      },
      id: "05070612"
    },
    {
      name: {
        km: "ដែកភ្លើង",
        latin: "Daek Phleung"
      },
      id: "05070613"
    },
    {
      name: {
        km: "ឈើទាលលើ",
        latin: "Chheu Teal Leu"
      },
      id: "05070614"
    },
    {
      name: {
        km: "ត្រពាំងតាឯក",
        latin: "Trapeang Ta Aek"
      },
      id: "05070615"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "05070616"
    },
    {
      name: {
        km: "រលាំងចក",
        latin: "Roleang Chak"
      },
      id: "05070617"
    },
    {
      name: {
        km: "អង្គមេត្រី",
        latin: "Angk Metrei"
      },
      id: "05070618"
    },
    {
      name: {
        km: "ស្រូល",
        latin: "Sroul"
      },
      id: "05070619"
    },
    {
      name: {
        km: "រំលេច",
        latin: "Rumlich"
      },
      id: "05070620"
    },
    {
      name: {
        km: "ស្ពានតោ",
        latin: "Spean Tao"
      },
      id: "05070621"
    },
    {
      name: {
        km: "ត្រពាំងត្រាវ",
        latin: "Trapeang Trav"
      },
      id: "05070622"
    },
    {
      name: {
        km: "ដូនទ្រីទី១",
        latin: "Doun Tri Ti Muoy"
      },
      id: "05070623"
    },
    {
      name: {
        km: "ដូនទ្រីទី២",
        latin: "Doun Tri Ti Pir"
      },
      id: "05070624"
    },
    {
      name: {
        km: "ត្រពាំងកកោះ",
        latin: "Trapeang Kakaoh"
      },
      id: "05070625"
    },
    {
      name: {
        km: "រវាង",
        latin: "Roveang"
      },
      id: "05070626"
    },
    {
      name: {
        km: "ត្រពាំងលែង",
        latin: "Trapeang Léng"
      },
      id: "05070701"
    },
    {
      name: {
        km: "ទន្លេកន្ទិល",
        latin: "Tonle Kantil"
      },
      id: "05070702"
    },
    {
      name: {
        km: "របងច្រុះ",
        latin: "Robang Chroh"
      },
      id: "05070703"
    },
    {
      name: {
        km: "រោងគោ",
        latin: "Rorng Kou"
      },
      id: "05070704"
    },
    {
      name: {
        km: "អង្គរជា",
        latin: "Angkor Chea"
      },
      id: "05070705"
    },
    {
      name: {
        km: "ត្រពាំងត្រស់",
        latin: "Trapeang Tras"
      },
      id: "05070706"
    },
    {
      name: {
        km: "អង្គទន្លាប់",
        latin: "Angk Tonloab"
      },
      id: "05070707"
    },
    {
      name: {
        km: "សំរោងទងកណ្ដាល",
        latin: "Samrong Tong Kandal"
      },
      id: "05070708"
    },
    {
      name: {
        km: "ត្រពាំងជួន",
        latin: "Trapeang Chuon"
      },
      id: "05070709"
    },
    {
      name: {
        km: "សំរោងទងក្រោម",
        latin: "Samrong Tong Kraom"
      },
      id: "05070710"
    },
    {
      name: {
        km: "សំរោងទងលើ",
        latin: "Samrong Tong Leu"
      },
      id: "05070711"
    },
    {
      name: {
        km: "ត្រពាំងតាឡាត់",
        latin: "Trapeang Ta Lat"
      },
      id: "05070712"
    },
    {
      name: {
        km: "ចង្ក្រានដែក",
        latin: "Changkran Daek"
      },
      id: "05070713"
    },
    {
      name: {
        km: "សុភី",
        latin: "Sophy"
      },
      id: "05070714"
    },
    {
      name: {
        km: "ចុងបឹង",
        latin: "Chong Boeng"
      },
      id: "05070715"
    },
    {
      name: {
        km: "ត្រពាំងព្រូស",
        latin: "Trapeang Prous"
      },
      id: "05070716"
    },
    {
      name: {
        km: "កែវមុនី",
        latin: "Keo Mony"
      },
      id: "05070717"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "05070718"
    },
    {
      name: {
        km: "អង្គតាឡឹក",
        latin: "Angk Ta Loek"
      },
      id: "05070801"
    },
    {
      name: {
        km: "ក្រាំងលាវ",
        latin: "Krang Leav"
      },
      id: "05070802"
    },
    {
      name: {
        km: "ស្ដុកព្រីង",
        latin: "Sdok Pring"
      },
      id: "05070803"
    },
    {
      name: {
        km: "ថ្មគោល",
        latin: "Thma Koul"
      },
      id: "05070804"
    },
    {
      name: {
        km: "កប់ចិន",
        latin: "Kab Chen"
      },
      id: "05070805"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "05070806"
    },
    {
      name: {
        km: "តាពរ",
        latin: "Ta Por"
      },
      id: "05070807"
    },
    {
      name: {
        km: "អំពិលទឹក",
        latin: "Ampil Tuek"
      },
      id: "05070808"
    },
    {
      name: {
        km: "អង្គខ្មៅ",
        latin: "Angk Khmau"
      },
      id: "05070809"
    },
    {
      name: {
        km: "ព្រៃតាចាប",
        latin: "Prey Ta Chab"
      },
      id: "05070810"
    },
    {
      name: {
        km: "ត្រពាំងវែង ទី១",
        latin: "Trapeang Veaeng Ti Muoy"
      },
      id: "05070811"
    },
    {
      name: {
        km: "ត្រពាំងវែង ទី២",
        latin: "Trapeang Veaeng Ti Pir"
      },
      id: "05070812"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "05070813"
    },
    {
      name: {
        km: "បង្កើយ",
        latin: "Bangkaeuy"
      },
      id: "05070814"
    },
    {
      name: {
        km: "ត្រពាំងនាន់",
        latin: "Trapeang Noan"
      },
      id: "05070815"
    },
    {
      name: {
        km: "ព្រៃពង្រ",
        latin: "Prey Pongro"
      },
      id: "05070816"
    },
    {
      name: {
        km: "ឡធ្យូង",
        latin: "La Thyung"
      },
      id: "05070817"
    },
    {
      name: {
        km: "ត្រពាំងកន្ទួត",
        latin: "Trapeang Kantuot"
      },
      id: "05070818"
    },
    {
      name: {
        km: "ព្រៃស្យា",
        latin: "Prey Sya"
      },
      id: "05070901"
    },
    {
      name: {
        km: "ស្វាយតាវង",
        latin: "Svay Ta Vong"
      },
      id: "05070902"
    },
    {
      name: {
        km: "សែនដី",
        latin: "Saen Dei"
      },
      id: "05070903"
    },
    {
      name: {
        km: "ស្រះស្រង់",
        latin: "Srah Srang"
      },
      id: "05070904"
    },
    {
      name: {
        km: "ផ្លូវជ្រូក",
        latin: "Phlov Chruk"
      },
      id: "05070905"
    },
    {
      name: {
        km: "ដូនកែវ",
        latin: "Doun Kaev"
      },
      id: "05070906"
    },
    {
      name: {
        km: "ទួលតាសុខ",
        latin: "Tuol Ta Sokh"
      },
      id: "05070907"
    },
    {
      name: {
        km: "ព្រៃរំដួល",
        latin: "Prey Rumduol"
      },
      id: "05070908"
    },
    {
      name: {
        km: "ត្រពាំងប្រីយ៍",
        latin: "Trapeang Prei"
      },
      id: "05070909"
    },
    {
      name: {
        km: "ត្រោកវែង",
        latin: "Traok Veaeng"
      },
      id: "05070910"
    },
    {
      name: {
        km: "ត្រពាំងដំរី",
        latin: "Trapeang Damrei"
      },
      id: "05070911"
    },
    {
      name: {
        km: "អូររំចេក",
        latin: "Ou Rumchek"
      },
      id: "05070912"
    },
    {
      name: {
        km: "មហាលំពាំងទីមួយ",
        latin: "Moha Lumpeang Ti Muoy"
      },
      id: "05070913"
    },
    {
      name: {
        km: "ទំនប់",
        latin: "Tumnob"
      },
      id: "05070914"
    },
    {
      name: {
        km: "ថ្លុកកណ្ដាល",
        latin: "Thlok Kandal"
      },
      id: "05070915"
    },
    {
      name: {
        km: "សំរោងជើងភ្នំ",
        latin: "Samraong Cheung Phnum"
      },
      id: "05070916"
    },
    {
      name: {
        km: "មហាលំពាំងទីពីរ",
        latin: "Moha Lumpeang Ti Pir"
      },
      id: "05070917"
    },
    {
      name: {
        km: "ស្រែត្រោក",
        latin: "Srae Traok"
      },
      id: "05070918"
    },
    {
      name: {
        km: "វាលវង់",
        latin: "Veal Vong"
      },
      id: "05070919"
    },
    {
      name: {
        km: "ត្រពាំងកែស",
        latin: "Trapeang Kaes"
      },
      id: "05070920"
    },
    {
      name: {
        km: "ព្រៃជ្រាវ",
        latin: "Prey Chreav"
      },
      id: "05070921"
    },
    {
      name: {
        km: "បិល",
        latin: "Bel"
      },
      id: "05071001"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "05071002"
    },
    {
      name: {
        km: "គោករងៀង",
        latin: "Kouk Rongieng"
      },
      id: "05071003"
    },
    {
      name: {
        km: "ក្រាំងមេត្រី",
        latin: "Krang Metrei"
      },
      id: "05071004"
    },
    {
      name: {
        km: "អូរស្នោ",
        latin: "Ou Snao"
      },
      id: "05071005"
    },
    {
      name: {
        km: "ស្វាយដង្គុំ",
        latin: "Svay Dangkum"
      },
      id: "05071006"
    },
    {
      name: {
        km: "ដូនអាត់",
        latin: "Doun At"
      },
      id: "05071007"
    },
    {
      name: {
        km: "ក្រាំងក្រូច",
        latin: "Krang Kruoch"
      },
      id: "05071008"
    },
    {
      name: {
        km: "ក្រាំងត្រឡាច",
        latin: "Krang Tralach"
      },
      id: "05071009"
    },
    {
      name: {
        km: "ព្រៃពង្រ",
        latin: "Prey Pongro"
      },
      id: "05071010"
    },
    {
      name: {
        km: "ត្រពាំងអង្គ",
        latin: "Trapeang Angk"
      },
      id: "05071011"
    },
    {
      name: {
        km: "អន្លង់ស្នោ",
        latin: "Anlong Snao"
      },
      id: "05071012"
    },
    {
      name: {
        km: "រើងពើង",
        latin: "Reung Peung"
      },
      id: "05071013"
    },
    {
      name: {
        km: "ក្រាំងអំពិល",
        latin: "Krang Ampil"
      },
      id: "05071014"
    },
    {
      name: {
        km: "ត្រពាំងស្រង់",
        latin: "Trapeang Srang"
      },
      id: "05071015"
    },
    {
      name: {
        km: "ទឹកលិច",
        latin: "Tuek Lich"
      },
      id: "05071016"
    },
    {
      name: {
        km: "ព្រៃបេង",
        latin: "Prey Beng"
      },
      id: "05071017"
    },
    {
      name: {
        km: "ព្រៃត្រាច",
        latin: "Prey Trach"
      },
      id: "05071018"
    },
    {
      name: {
        km: "ត្រពាំងតាលួង",
        latin: "Trapeang Ta Luong"
      },
      id: "05071019"
    },
    {
      name: {
        km: "ព្រៃរងៀង",
        latin: "Prey Rongieng"
      },
      id: "05071020"
    },
    {
      name: {
        km: "ទន្លាប់",
        latin: "Tonloab"
      },
      id: "05071021"
    },
    {
      name: {
        km: "អណ្ដូងស្លា",
        latin: "Andoung Sla"
      },
      id: "05071101"
    },
    {
      name: {
        km: "អន្លង់ធម្ម",
        latin: "Anlong Thomm"
      },
      id: "05071102"
    },
    {
      name: {
        km: "ក្រាំងស្ពឺ",
        latin: "Krang Spueu"
      },
      id: "05071103"
    },
    {
      name: {
        km: "សំរិត",
        latin: "Samret"
      },
      id: "05071104"
    },
    {
      name: {
        km: "សំប៉ាន",
        latin: "Sampan"
      },
      id: "05071105"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05071106"
    },
    {
      name: {
        km: "ក្រាំងម្កាក់",
        latin: "Krang Mkak"
      },
      id: "05071107"
    },
    {
      name: {
        km: "ទំពាម",
        latin: "Tumpeam"
      },
      id: "05071108"
    },
    {
      name: {
        km: "គ្រឹះធំ",
        latin: "Krues Thum"
      },
      id: "05071109"
    },
    {
      name: {
        km: "ទឹកល្អក់",
        latin: "Tuek L'ak"
      },
      id: "05071110"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "05071111"
    },
    {
      name: {
        km: "ចំការស្លែង",
        latin: "Chamkar Slaeng"
      },
      id: "05071112"
    },
    {
      name: {
        km: "ផ្ដៅពេន",
        latin: "Phdau Pen"
      },
      id: "05071113"
    },
    {
      name: {
        km: "ចំប៉ី",
        latin: "Champei"
      },
      id: "05071114"
    },
    {
      name: {
        km: "អង្គតាម៉ៅ",
        latin: "Angk Ta Mau"
      },
      id: "05071115"
    },
    {
      name: {
        km: "ចុងថ្នល់",
        latin: "Chong Thnal"
      },
      id: "05071116"
    },
    {
      name: {
        km: "អូរគគីរ",
        latin: "O koki"
      },
      id: "05071117"
    },
    {
      name: {
        km: "ព្រៃវាវ",
        latin: "Prey Veav"
      },
      id: "05071201"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "05071202"
    },
    {
      name: {
        km: "តាំងឫស្សី",
        latin: "Tang Ruessei"
      },
      id: "05071203"
    },
    {
      name: {
        km: "ព្រៃកន្ទ្រង់",
        latin: "Prey Kantrong"
      },
      id: "05071204"
    },
    {
      name: {
        km: "ដីក្រហម",
        latin: "Dei Kraham"
      },
      id: "05071205"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "05071206"
    },
    {
      name: {
        km: "ត្រពាំងសារ",
        latin: "Trapeang Sar"
      },
      id: "05071207"
    },
    {
      name: {
        km: "ត្រពាំងលាភ",
        latin: "Trapeang Leap"
      },
      id: "05071208"
    },
    {
      name: {
        km: "ក្រាំងស្វាយ",
        latin: "Krang Svay"
      },
      id: "05071209"
    },
    {
      name: {
        km: "ជើងក្ដី",
        latin: "Cheung Kdei"
      },
      id: "05071210"
    },
    {
      name: {
        km: "ថ្លុកដង្កោ",
        latin: "Thlok Dangkao"
      },
      id: "05071211"
    },
    {
      name: {
        km: "ម្កាក់",
        latin: "Mkak"
      },
      id: "05071212"
    },
    {
      name: {
        km: "ច្រកឫស្សី",
        latin: "Chrak Ruessei"
      },
      id: "05071213"
    },
    {
      name: {
        km: "ព្រៃកំប៉ុក",
        latin: "Prey Kampok"
      },
      id: "05071214"
    },
    {
      name: {
        km: "ព្រៃប្រដាក់",
        latin: "Prey Pradak"
      },
      id: "05071215"
    },
    {
      name: {
        km: "ជើងភ្នំ",
        latin: "Cheung Phnum"
      },
      id: "05071216"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "05071217"
    },
    {
      name: {
        km: "មន",
        latin: "Mon"
      },
      id: "05071218"
    },
    {
      name: {
        km: "ដីឡ",
        latin: "Dei La"
      },
      id: "05071219"
    },
    {
      name: {
        km: "ស្រែត្រែង",
        latin: "Srae Traeng"
      },
      id: "05071220"
    },
    {
      name: {
        km: "ស្រីប្រសើរ",
        latin: "Srei Prasaeur"
      },
      id: "05071221"
    },
    {
      name: {
        km: "ថ្មបាំង",
        latin: "Thmar Bang"
      },
      id: "05071301"
    },
    {
      name: {
        km: "ត្រគៀត",
        latin: "Trakiet"
      },
      id: "05071302"
    },
    {
      name: {
        km: "បាក់ចិញ្ជៀន",
        latin: "Bak Chenhchien"
      },
      id: "05071303"
    },
    {
      name: {
        km: "លំពែងព្រះរាម",
        latin: "Lumpeaeng Preah Ream"
      },
      id: "05071304"
    },
    {
      name: {
        km: "អូរក្រាំងអំបិល",
        latin: "Ou Krang Ambel"
      },
      id: "05071305"
    },
    {
      name: {
        km: "អន្លង់គង់",
        latin: "Anlong Kong"
      },
      id: "05071306"
    },
    {
      name: {
        km: "ត្រពាំងខ្យង",
        latin: "Trapeang Khyang"
      },
      id: "05071307"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "05071308"
    },
    {
      name: {
        km: "ស្រែអំបិល",
        latin: "Srae Ambel"
      },
      id: "05071309"
    },
    {
      name: {
        km: "បាញ់កង្កែប",
        latin: "Banh Kangkaeb"
      },
      id: "05071310"
    },
    {
      name: {
        km: "ត្រពាំងគង",
        latin: "Trapeang Kong"
      },
      id: "05071311"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Vaeng"
      },
      id: "05071312"
    },
    {
      name: {
        km: "សុបិន្ត",
        latin: "Soben"
      },
      id: "05071313"
    },
    {
      name: {
        km: "សង្គ្រោះជាតិ",
        latin: "Sangkruoh Cheat"
      },
      id: "05071314"
    },
    {
      name: {
        km: "ត្រពាំងសាំងជៀក",
        latin: "Trapeang Sang Chiek"
      },
      id: "05071315"
    },
    {
      name: {
        km: "ត្រពាំងរកា",
        latin: "Trapeang Rokar"
      },
      id: "05071316"
    },
    {
      name: {
        km: "ត្រពាំងអំពិល",
        latin: "Trapeang Ampil"
      },
      id: "05071317"
    },
    {
      name: {
        km: "ទួលព្រិច",
        latin: "Tuol Prich"
      },
      id: "05071318"
    },
    {
      name: {
        km: "ព្រៃគុយ",
        latin: "Prey Kuy"
      },
      id: "05071319"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "05071320"
    },
    {
      name: {
        km: "ថ្មសំលៀង",
        latin: "Thma Samlieng"
      },
      id: "05071321"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "05071322"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "05071323"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "05071324"
    },
    {
      name: {
        km: "ឧកញ៉ាទេព",
        latin: "Oknha Tep"
      },
      id: "05071325"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "05071326"
    },
    {
      name: {
        km: "កែវឧត្ដម",
        latin: "Kaev Otdom"
      },
      id: "05071327"
    },
    {
      name: {
        km: "រំដួល",
        latin: "Rumduol"
      },
      id: "05071328"
    },
    {
      name: {
        km: "ព្រៃសុភី",
        latin: "Prey Sophi"
      },
      id: "05071329"
    },
    {
      name: {
        km: "តាំងគម្ពីរ",
        latin: "Tang Kumpir"
      },
      id: "05071401"
    },
    {
      name: {
        km: "ទំព័រមាស",
        latin: "Tumpoar Meas"
      },
      id: "05071402"
    },
    {
      name: {
        km: "ក្រាំងស្ដុក",
        latin: "Krang Sdok"
      },
      id: "05071403"
    },
    {
      name: {
        km: "ទឹកស្អុយ",
        latin: "Tuek S'oy"
      },
      id: "05071404"
    },
    {
      name: {
        km: "ពញាអោង",
        latin: "Phnhea Aong"
      },
      id: "05071405"
    },
    {
      name: {
        km: "បាយស្រា",
        latin: "Bay Sra"
      },
      id: "05071406"
    },
    {
      name: {
        km: "តាំងកន្ទួត",
        latin: "Tang Kantuot"
      },
      id: "05071407"
    },
    {
      name: {
        km: "តាំងស្នោ",
        latin: "Tang Snao"
      },
      id: "05071408"
    },
    {
      name: {
        km: "ហោងខ្វាវ",
        latin: "Haong Khvav"
      },
      id: "05071409"
    },
    {
      name: {
        km: "ជ្រលងញ៉ូស",
        latin: "Chrolong Nhous"
      },
      id: "05071410"
    },
    {
      name: {
        km: "កំពង់ល្វា",
        latin: "Kampong Lvea"
      },
      id: "05071411"
    },
    {
      name: {
        km: "ពាមឃ្លៃ",
        latin: "Peam Khley"
      },
      id: "05071412"
    },
    {
      name: {
        km: "ប្រជៀវបាត",
        latin: "Prachiev Bat"
      },
      id: "05071413"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "05071501"
    },
    {
      name: {
        km: "វល្លិសរ",
        latin: "Voa Sar"
      },
      id: "05071502"
    },
    {
      name: {
        km: "ព្រៃកោះ",
        latin: "Prey Kaoh"
      },
      id: "05071503"
    },
    {
      name: {
        km: "រំលោង",
        latin: "Rumloung"
      },
      id: "05071504"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "05071505"
    },
    {
      name: {
        km: "ត្បែស",
        latin: "Tbaes"
      },
      id: "05071506"
    },
    {
      name: {
        km: "ចំការរដ្ឋ",
        latin: "Chamkar Rodth"
      },
      id: "05071507"
    },
    {
      name: {
        km: "ចាក់កាំបិត",
        latin: "Chak Kambet"
      },
      id: "05071508"
    },
    {
      name: {
        km: "ដក់ពរ",
        latin: "Dak Por"
      },
      id: "05071509"
    },
    {
      name: {
        km: "គោកព្នៅ",
        latin: "Kouk Pnov"
      },
      id: "05071510"
    },
    {
      name: {
        km: "រំលិចត្នោត",
        latin: "Rumlich Tnaot"
      },
      id: "05071511"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "05071512"
    },
    {
      name: {
        km: "ត្រពាំងពោធិ៍",
        latin: "Trapeang Pou"
      },
      id: "05071513"
    },
    {
      name: {
        km: "តាគាំ",
        latin: "Ta Koam"
      },
      id: "05071514"
    },
    {
      name: {
        km: "ទួលសំណាង",
        latin: "Tuol Samnang"
      },
      id: "05071515"
    },
    {
      name: {
        km: "ព្រៃទួល",
        latin: "Prey Tuol"
      },
      id: "05071516"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "05071517"
    },
    {
      name: {
        km: "ក្រាំងពង្រ",
        latin: "Krang Pongro"
      },
      id: "05071518"
    },
    {
      name: {
        km: "ចំការបុស្ស",
        latin: "Chamkar Bos"
      },
      id: "05071519"
    },
    {
      name: {
        km: "បឹងទន្លាប់",
        latin: "Boeng Tonloab"
      },
      id: "05071520"
    },
    {
      name: {
        km: "រកាបាញ់",
        latin: "Roka Banh"
      },
      id: "05071521"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "05071522"
    },
    {
      name: {
        km: "ត្រពាំងខ្ទុំ",
        latin: "Trapeang Khtum"
      },
      id: "05071523"
    },
    {
      name: {
        km: "ថ្មគប់",
        latin: "Thma Kob"
      },
      id: "05080101"
    },
    {
      name: {
        km: "ទំនាប",
        latin: "Tumneab"
      },
      id: "05080102"
    },
    {
      name: {
        km: "កុមារមាស",
        latin: "Komar Meas"
      },
      id: "05080103"
    },
    {
      name: {
        km: "ក្រាំងចំបក់",
        latin: "Krang Chambak"
      },
      id: "05080104"
    },
    {
      name: {
        km: "តាកោង",
        latin: "Ta Kaong"
      },
      id: "05080105"
    },
    {
      name: {
        km: "ថ្នល់បំបែក",
        latin: "Thnal Bambaek"
      },
      id: "05080106"
    },
    {
      name: {
        km: "អូរអង្គំ",
        latin: "Ou Angkum"
      },
      id: "05080107"
    },
    {
      name: {
        km: "ក្រាំងដូង",
        latin: "Krang Doung"
      },
      id: "05080108"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "05080109"
    },
    {
      name: {
        km: "គោក",
        latin: "Kouk"
      },
      id: "05080110"
    },
    {
      name: {
        km: "ពិស",
        latin: "Pis"
      },
      id: "05080111"
    },
    {
      name: {
        km: "ព្រែក",
        latin: "Preaek"
      },
      id: "05080112"
    },
    {
      name: {
        km: "ព្រៃជ្រៅ",
        latin: "Prey Chrouv"
      },
      id: "05080113"
    },
    {
      name: {
        km: "តាំងហោង",
        latin: "Taing Hoang"
      },
      id: "05080114"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "05080115"
    },
    {
      name: {
        km: "តាំងសំរោង",
        latin: "Taing Samrong"
      },
      id: "05080116"
    },
    {
      name: {
        km: "ទឹកចេញ",
        latin: "Tuek Chenh"
      },
      id: "05080117"
    },
    {
      name: {
        km: "តាំងស្ដុក",
        latin: "Tang Sdok"
      },
      id: "05080201"
    },
    {
      name: {
        km: "មនោរម្យ",
        latin: "Monourom"
      },
      id: "05080202"
    },
    {
      name: {
        km: "គោល",
        latin: "Koul"
      },
      id: "05080203"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "05080204"
    },
    {
      name: {
        km: "ច្រកខ្លុង",
        latin: "Chrak Khlong"
      },
      id: "05080205"
    },
    {
      name: {
        km: "តាំងរលាំង",
        latin: "Tang Roleang"
      },
      id: "05080206"
    },
    {
      name: {
        km: "ចំបក់ផ្អែម",
        latin: "Chambak Ph'aem"
      },
      id: "05080207"
    },
    {
      name: {
        km: "វាលពន់",
        latin: "Veal Pon"
      },
      id: "05080208"
    },
    {
      name: {
        km: "ចំពារ",
        latin: "Champear"
      },
      id: "05080209"
    },
    {
      name: {
        km: "តាមុំ",
        latin: "Ta Mom"
      },
      id: "05080210"
    },
    {
      name: {
        km: "ព្រៃវែង",
        latin: "Prey Veaeng"
      },
      id: "05080401"
    },
    {
      name: {
        km: "ឡ",
        latin: "La"
      },
      id: "05080402"
    },
    {
      name: {
        km: "តាំងពោធិ៍",
        latin: "Tang Pou"
      },
      id: "05080403"
    },
    {
      name: {
        km: "ត្បែងប្រជាប់",
        latin: "Tbaeng Prachoab"
      },
      id: "05080404"
    },
    {
      name: {
        km: "ត្រពាំងត្រោក",
        latin: "Trapeang Traok"
      },
      id: "05080405"
    },
    {
      name: {
        km: "ត្រាញ់វែង",
        latin: "Tranh Veaeng"
      },
      id: "05080406"
    },
    {
      name: {
        km: "ចំបក់ធំ",
        latin: "Chambak Thum"
      },
      id: "05080407"
    },
    {
      name: {
        km: "ដូនទិព្វ",
        latin: "Doun Tip"
      },
      id: "05080408"
    },
    {
      name: {
        km: "ត្រពាំងក្រើញ",
        latin: "Trapeang Kraeunh"
      },
      id: "05080409"
    },
    {
      name: {
        km: "អន្លង់ជ្រៃ",
        latin: "Anlong Chrey"
      },
      id: "05080410"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "05080411"
    },
    {
      name: {
        km: "ថ្នល់ទទឹង",
        latin: "Thnal Totueng"
      },
      id: "05080412"
    },
    {
      name: {
        km: "គាម្មនី",
        latin: "Keammoni"
      },
      id: "05080501"
    },
    {
      name: {
        km: "ត្រពាំងត្របែក",
        latin: "Trapeang Trabaek"
      },
      id: "05080502"
    },
    {
      name: {
        km: "ទឹកលង ទី ១",
        latin: "Tuek Long Ti Muoy"
      },
      id: "05080503"
    },
    {
      name: {
        km: "ទឹកលង ទី ២",
        latin: "Tuek Long Ti Pir"
      },
      id: "05080504"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "05080505"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "05080506"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "05080507"
    },
    {
      name: {
        km: "រលាំងថ្លើង",
        latin: "Roleang Thlaeung"
      },
      id: "05080508"
    },
    {
      name: {
        km: "ចំបក់សរ",
        latin: "Chambak Sar"
      },
      id: "05080509"
    },
    {
      name: {
        km: "ក្រាំងតាចរ",
        latin: "Krang Ta Char"
      },
      id: "05080510"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "05080601"
    },
    {
      name: {
        km: "ព្រៃសំរោង",
        latin: "Prey Samraong"
      },
      id: "05080602"
    },
    {
      name: {
        km: "ត្រពាំងដងទឹក",
        latin: "Trapeang Dang Tuek"
      },
      id: "05080603"
    },
    {
      name: {
        km: "អញ្ញាសេះ",
        latin: "Anhaseh"
      },
      id: "05080604"
    },
    {
      name: {
        km: "ស្ដៅវស្សា",
        latin: "Sdau Vossa"
      },
      id: "05080605"
    },
    {
      name: {
        km: "ស្រែអណ្ដូង",
        latin: "Srae Andoung"
      },
      id: "05080606"
    },
    {
      name: {
        km: "អូរស្នួល",
        latin: "Ou Snuol"
      },
      id: "05080607"
    },
    {
      name: {
        km: "អូរក្រសារ",
        latin: "Ou Krasar"
      },
      id: "05080701"
    },
    {
      name: {
        km: "ដុំខ្វិត",
        latin: "Dom Khvet"
      },
      id: "05080702"
    },
    {
      name: {
        km: "ទេពភិរម្យ",
        latin: "Tep Phirum"
      },
      id: "05080703"
    },
    {
      name: {
        km: "ទទឹងថ្ងៃ",
        latin: "Totueng Thngai"
      },
      id: "05080704"
    },
    {
      name: {
        km: "បន្ទាយល្វើ",
        latin: "Banteay Lveu"
      },
      id: "05080705"
    },
    {
      name: {
        km: "កន្ធាំ",
        latin: "Kanthoam"
      },
      id: "05080706"
    },
    {
      name: {
        km: "រាមកណ្ដាល",
        latin: "Ream Kandal"
      },
      id: "05080707"
    },
    {
      name: {
        km: "តាំងបន្ទាយ",
        latin: "Tang Banteay"
      },
      id: "05080708"
    },
    {
      name: {
        km: "អំពិលក្ដារ",
        latin: "Ampil Kdar"
      },
      id: "05080709"
    },
    {
      name: {
        km: "ព្រៃមិច",
        latin: "Prey Mich"
      },
      id: "05080710"
    },
    {
      name: {
        km: "ស្យា",
        latin: "Sya"
      },
      id: "05080711"
    },
    {
      name: {
        km: "ព្រៃមិច១",
        latin: "Prey Mich 1"
      },
      id: "05080712"
    },
    {
      name: {
        km: "ទេវាមានជ័យ",
        latin: "Tevea Mean Chey"
      },
      id: "05080713"
    },
    {
      name: {
        km: "ល្វាទេ",
        latin: "Lvea Te"
      },
      id: "05080801"
    },
    {
      name: {
        km: "ថ្មីជង្រុក",
        latin: "Thmei Chongruk"
      },
      id: "05080802"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "05080803"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "05080804"
    },
    {
      name: {
        km: "ទួលថ្មី",
        latin: "Tuol Thmei"
      },
      id: "05080805"
    },
    {
      name: {
        km: "ត្រោកលាក់",
        latin: "Traok Leak"
      },
      id: "05080806"
    },
    {
      name: {
        km: "រលាំងបែង",
        latin: "Roleang Baeng"
      },
      id: "05080807"
    },
    {
      name: {
        km: "ក្រាំងរំពាក់",
        latin: "Krang Rumpeak"
      },
      id: "05080808"
    },
    {
      name: {
        km: "ព្រៃទីទុយ",
        latin: "Prey Tituy"
      },
      id: "05080809"
    },
    {
      name: {
        km: "ពាណិជ្ជ",
        latin: "Peanich"
      },
      id: "05080810"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "05080811"
    },
    {
      name: {
        km: "ព្រិចខ្ពស់",
        latin: "Prich Khpos"
      },
      id: "05080812"
    },
    {
      name: {
        km: "ក្រវៀក",
        latin: "Kraviek"
      },
      id: "05080813"
    },
    {
      name: {
        km: "ស្យា",
        latin: "Sya"
      },
      id: "05080814"
    },
    {
      name: {
        km: "បាក់ស្នាក្រោម",
        latin: "Bak Sna Kraom"
      },
      id: "06010101"
    },
    {
      name: {
        km: "បាក់ស្នាលើ",
        latin: "Bak Sna Leu"
      },
      id: "06010102"
    },
    {
      name: {
        km: "អណ្ដោត",
        latin: "Andaot"
      },
      id: "06010103"
    },
    {
      name: {
        km: "ដោម",
        latin: "Daom"
      },
      id: "06010104"
    },
    {
      name: {
        km: "ត្រពាំងទូក",
        latin: "Trapeang Touk"
      },
      id: "06010105"
    },
    {
      name: {
        km: "អូរចំរេះ",
        latin: "Ou Chamres"
      },
      id: "06010106"
    },
    {
      name: {
        km: "កំពង់ពោន",
        latin: "Kampong Pon"
      },
      id: "06010107"
    },
    {
      name: {
        km: "ត្រស់",
        latin: "Tras"
      },
      id: "06010201"
    },
    {
      name: {
        km: "ស្រឡៅតូង",
        latin: "Sralau Toung"
      },
      id: "06010202"
    },
    {
      name: {
        km: "យាយទៀង",
        latin: "Yeay Tieng"
      },
      id: "06010203"
    },
    {
      name: {
        km: "បល្ល័ង្គ",
        latin: "Ballangk"
      },
      id: "06010204"
    },
    {
      name: {
        km: "តាឌុក",
        latin: "Ta Duk"
      },
      id: "06010205"
    },
    {
      name: {
        km: "ព្រៃស្រង៉ែ",
        latin: "Prey Srangae"
      },
      id: "06010206"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "06010207"
    },
    {
      name: {
        km: "តាភោគ",
        latin: "Ta Phouk"
      },
      id: "06010208"
    },
    {
      name: {
        km: "ព្រៃតាត្រាវ",
        latin: "Prey Ta Trav"
      },
      id: "06010209"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "06010210"
    },
    {
      name: {
        km: "ជ័យមង្គល",
        latin: "Chey Mongkol"
      },
      id: "06010211"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "06010212"
    },
    {
      name: {
        km: "ដូនដួង",
        latin: "Doun Duong"
      },
      id: "06010213"
    },
    {
      name: {
        km: "បារាយណ៌ជាន់ដែក",
        latin: "Baray Chorn Daek"
      },
      id: "06010214"
    },
    {
      name: {
        km: "បឹងសំរិទ្ធ",
        latin: "Boeung Samrith"
      },
      id: "06010215"
    },
    {
      name: {
        km: "ក្រសាំងជ័យ",
        latin: "Krasang Chey"
      },
      id: "06010301"
    },
    {
      name: {
        km: "បារាយណ៍តូច",
        latin: "Baray Touch"
      },
      id: "06010302"
    },
    {
      name: {
        km: "បារាយណ៍ធំ",
        latin: "Baray Thum"
      },
      id: "06010303"
    },
    {
      name: {
        km: "ថ្នល់ថ្មី",
        latin: "Thnal Thmei"
      },
      id: "06010304"
    },
    {
      name: {
        km: "ថ្នល់ជាតិ",
        latin: "Thnal Cheat"
      },
      id: "06010305"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "06010306"
    },
    {
      name: {
        km: "ជីអោក",
        latin: "Chi Aok"
      },
      id: "06010307"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "06010308"
    },
    {
      name: {
        km: "អូរសួស្ដី",
        latin: "Ou Suosdei"
      },
      id: "06010309"
    },
    {
      name: {
        km: "ចតុលោក",
        latin: "Chakto Louk"
      },
      id: "06010310"
    },
    {
      name: {
        km: "ពោធិ៍ពីរ",
        latin: "Pou Pir"
      },
      id: "06010311"
    },
    {
      name: {
        km: "បាណក",
        latin: "Banak"
      },
      id: "06010312"
    },
    {
      name: {
        km: "បឹងខាងជើង",
        latin: "Boeng​ Khang Cheung"
      },
      id: "06010401"
    },
    {
      name: {
        km: "បឹងកណ្ដាល",
        latin: "Boeng Kandal"
      },
      id: "06010402"
    },
    {
      name: {
        km: "បឹងខាងត្បូង",
        latin: "Boeng​ Khang Tboung"
      },
      id: "06010403"
    },
    {
      name: {
        km: "ដូនប៉ែន",
        latin: "Doun Paen"
      },
      id: "06010404"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "06010405"
    },
    {
      name: {
        km: "ពណ្ណរាយ",
        latin: "Ponnoreay"
      },
      id: "06010501"
    },
    {
      name: {
        km: "សំពៅលូន",
        latin: "Sampov Lun"
      },
      id: "06010502"
    },
    {
      name: {
        km: "ត្រដែត",
        latin: "Tradaet"
      },
      id: "06010503"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "06010504"
    },
    {
      name: {
        km: "ព្រៃដុំ",
        latin: "Prey Dom"
      },
      id: "06010505"
    },
    {
      name: {
        km: "ចើងដើង",
        latin: "Chaeung Daeung"
      },
      id: "06010506"
    },
    {
      name: {
        km: "តាអោង",
        latin: "Ta Aong"
      },
      id: "06010507"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "06010508"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "06010509"
    },
    {
      name: {
        km: "ប្រនាគ",
        latin: "Praneak"
      },
      id: "06010510"
    },
    {
      name: {
        km: "ឈូកខ្សាច់",
        latin: "Chhuk Khsach"
      },
      id: "06010701"
    },
    {
      name: {
        km: "តាប្រុក",
        latin: "Ta Prok"
      },
      id: "06010702"
    },
    {
      name: {
        km: "ជាំត្រាច",
        latin: "Choam Trach"
      },
      id: "06010703"
    },
    {
      name: {
        km: "ដូនតុំ",
        latin: "Doun Tom"
      },
      id: "06010704"
    },
    {
      name: {
        km: "ចាន់ល្ហង",
        latin: "Chan Lhang"
      },
      id: "06010705"
    },
    {
      name: {
        km: "ក្ដាមហា",
        latin: "Kdam Ha"
      },
      id: "06010706"
    },
    {
      name: {
        km: "ឆ្ពេន",
        latin: "Chhpen"
      },
      id: "06010707"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06010708"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "06010709"
    },
    {
      name: {
        km: "ផ្លូវទទឹង",
        latin: "Phlov Totueng"
      },
      id: "06010710"
    },
    {
      name: {
        km: "ផ្លូវទ្រាស",
        latin: "Phlov Treas"
      },
      id: "06010711"
    },
    {
      name: {
        km: "សិរីសុខុម",
        latin: "Serei Sokhom"
      },
      id: "06010712"
    },
    {
      name: {
        km: "សិរីរាជ្យ",
        latin: "Serei Reach"
      },
      id: "06010713"
    },
    {
      name: {
        km: "សង្កែជ្រុំ",
        latin: "Sangkae Chrum"
      },
      id: "06010714"
    },
    {
      name: {
        km: "ទួលដំណាក់",
        latin: "Tuol Damnak"
      },
      id: "06010801"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "06010802"
    },
    {
      name: {
        km: "ខ្សាច់ល្អិត",
        latin: "Khsach L'et"
      },
      id: "06010803"
    },
    {
      name: {
        km: "គូរ",
        latin: "Kur"
      },
      id: "06010804"
    },
    {
      name: {
        km: "ទួលសាលា",
        latin: "Tuol Sala"
      },
      id: "06010805"
    },
    {
      name: {
        km: "កំប៉ើយ",
        latin: "Kampaeuy"
      },
      id: "06010806"
    },
    {
      name: {
        km: "ពពេជ",
        latin: "Popech"
      },
      id: "06010807"
    },
    {
      name: {
        km: "ចុងដូង",
        latin: "Chong Doung"
      },
      id: "06010808"
    },
    {
      name: {
        km: "ល្បើក",
        latin: "Lbaeuk"
      },
      id: "06011001"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "06011002"
    },
    {
      name: {
        km: "អន្លង់ថ្ម",
        latin: "Anlong Thma"
      },
      id: "06011003"
    },
    {
      name: {
        km: "អន្លង់វែង",
        latin: "Anlong Veaeng"
      },
      id: "06011004"
    },
    {
      name: {
        km: "តាពៅ",
        latin: "Ta Pov"
      },
      id: "06011005"
    },
    {
      name: {
        km: "ទូកពីរ",
        latin: "Tuk Pir"
      },
      id: "06011006"
    },
    {
      name: {
        km: "វាលធំ",
        latin: "Veal Thum"
      },
      id: "06011007"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "06011008"
    },
    {
      name: {
        km: "វាលស្ពរ",
        latin: "Veal Spor"
      },
      id: "06011009"
    },
    {
      name: {
        km: "ក្រវ៉ា",
        latin: "Krava"
      },
      id: "06011101"
    },
    {
      name: {
        km: "កំពង់ស្ដេច",
        latin: "Kampong Sdach"
      },
      id: "06011102"
    },
    {
      name: {
        km: "ព្រៃរលាប",
        latin: "Prey Roleab"
      },
      id: "06011103"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "06011104"
    },
    {
      name: {
        km: "លាវ",
        latin: "Leav"
      },
      id: "06011105"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "06011106"
    },
    {
      name: {
        km: "ស្លាកែត",
        latin: "Sla Kaet"
      },
      id: "06011107"
    },
    {
      name: {
        km: "ពង្រលីង",
        latin: "Pongro Ling"
      },
      id: "06011108"
    },
    {
      name: {
        km: "ត្នោតជុំទី ១",
        latin: "Tnaot Chum Ti Muoy"
      },
      id: "06011701"
    },
    {
      name: {
        km: "ត្នោតជុំទី ២",
        latin: "Tnaot Chum Ti Pir"
      },
      id: "06011702"
    },
    {
      name: {
        km: "ត្នោតជុំទី ៣",
        latin: "Tnaot Chum Ti Bei"
      },
      id: "06011703"
    },
    {
      name: {
        km: "ត្នោតជុំទី ៤",
        latin: "Tnaot Chum Ti Buon"
      },
      id: "06011704"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "06011705"
    },
    {
      name: {
        km: "ព្រែកក្រោល",
        latin: "Preaek Kraol"
      },
      id: "06011706"
    },
    {
      name: {
        km: "បន្ទាយចាស់",
        latin: "Banteay Chas"
      },
      id: "06011707"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06011708"
    },
    {
      name: {
        km: "កងមាស",
        latin: "Kang Meas"
      },
      id: "06011709"
    },
    {
      name: {
        km: "មហរ៍",
        latin: "Mohar"
      },
      id: "06020101"
    },
    {
      name: {
        km: "តាធាវ",
        latin: "Ta Theav"
      },
      id: "06020102"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "06020103"
    },
    {
      name: {
        km: "ត្រពាំងអារ័ក្ស",
        latin: "Trapeang Areaks"
      },
      id: "06020104"
    },
    {
      name: {
        km: "ព្រៃទប់",
        latin: "Prey Tob"
      },
      id: "06020105"
    },
    {
      name: {
        km: "កូនត្នោត",
        latin: "Koun Tnaot"
      },
      id: "06020106"
    },
    {
      name: {
        km: "សង្គម",
        latin: "Sangkum"
      },
      id: "06020201"
    },
    {
      name: {
        km: "កប់ថ្លុក",
        latin: "Kab Thlok"
      },
      id: "06020202"
    },
    {
      name: {
        km: "មង្គលស្លា",
        latin: "Mongkol Sla"
      },
      id: "06020203"
    },
    {
      name: {
        km: "វល្លិយាវ",
        latin: "Voa Yeav"
      },
      id: "06020204"
    },
    {
      name: {
        km: "ដំរីស្លាប់",
        latin: "Damrei Slab"
      },
      id: "06020205"
    },
    {
      name: {
        km: "កំពង់គោលើ",
        latin: "Kampong Kou Leu"
      },
      id: "06020301"
    },
    {
      name: {
        km: "កំពង់គោក្រោម",
        latin: "Kampong Kou Kraom"
      },
      id: "06020302"
    },
    {
      name: {
        km: "ខ្សាច់ជីរស់",
        latin: "Khsach Chi Ros"
      },
      id: "06020303"
    },
    {
      name: {
        km: "បូពឹង",
        latin: "Bou Pueng"
      },
      id: "06020304"
    },
    {
      name: {
        km: "កោះគ្របបាយ",
        latin: "Kaoh Krob Bay"
      },
      id: "06020305"
    },
    {
      name: {
        km: "កំពង់ស្វាយ",
        latin: "Kampong Svay"
      },
      id: "06020401"
    },
    {
      name: {
        km: "តៀមចាស់",
        latin: "Tiem Chas"
      },
      id: "06020402"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "06020403"
    },
    {
      name: {
        km: "ឥន្ទកុមារ",
        latin: "Enteak Komar"
      },
      id: "06020404"
    },
    {
      name: {
        km: "ចុងព្រៃ",
        latin: "Chong Prey"
      },
      id: "06020405"
    },
    {
      name: {
        km: "ព្រៃព្រះ",
        latin: "Prey Preah"
      },
      id: "06020406"
    },
    {
      name: {
        km: "តាប៉ោង",
        latin: "Ta Paong"
      },
      id: "06020407"
    },
    {
      name: {
        km: "តាអាំ",
        latin: "Ta Am"
      },
      id: "06020408"
    },
    {
      name: {
        km: "ច្រាំងក្រហម",
        latin: "Chrang Kraham"
      },
      id: "06020409"
    },
    {
      name: {
        km: "អូរសាលា",
        latin: "Ou Sala"
      },
      id: "06020410"
    },
    {
      name: {
        km: "អន្លង់ក្រសាំង",
        latin: "Anlong Krasang"
      },
      id: "06020411"
    },
    {
      name: {
        km: "សូជៃ",
        latin: "Souchey"
      },
      id: "06020412"
    },
    {
      name: {
        km: "នីពេជ ក",
        latin: "Nipech ka"
      },
      id: "06020501"
    },
    {
      name: {
        km: "នីពេជ ខ",
        latin: "Nipech Kha"
      },
      id: "06020502"
    },
    {
      name: {
        km: "ដូនឈូក",
        latin: "Doun Chhuk"
      },
      id: "06020503"
    },
    {
      name: {
        km: "កំពង់ចំលង",
        latin: "Kampong Chamlang"
      },
      id: "06020601"
    },
    {
      name: {
        km: "ផាត់សណ្ដាយ",
        latin: "Phat Sanday"
      },
      id: "06020602"
    },
    {
      name: {
        km: "នាងសាវ",
        latin: "Neang Sav"
      },
      id: "06020603"
    },
    {
      name: {
        km: "ទួលនាងសាវ",
        latin: "Tuol Neang Sav"
      },
      id: "06020604"
    },
    {
      name: {
        km: "កោះតាពៅ",
        latin: "Kaoh Ta Pov"
      },
      id: "06020605"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "06020701"
    },
    {
      name: {
        km: "បល្ល័ង្គ",
        latin: "Balang"
      },
      id: "06020702"
    },
    {
      name: {
        km: "តាំងក្រូច",
        latin: "Tang Krouch"
      },
      id: "06020703"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "06020704"
    },
    {
      name: {
        km: "ជ័យ",
        latin: "Chey"
      },
      id: "06020705"
    },
    {
      name: {
        km: "ស្លែងខ្ពស់",
        latin: "Slaeng Khpos"
      },
      id: "06020706"
    },
    {
      name: {
        km: "សារី",
        latin: "Sari"
      },
      id: "06020707"
    },
    {
      name: {
        km: "សំពៅមាស",
        latin: "Sampov Meas"
      },
      id: "06020708"
    },
    {
      name: {
        km: "ក្បិល",
        latin: "Kbel"
      },
      id: "06020709"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "06020710"
    },
    {
      name: {
        km: "សាន់គ ក",
        latin: "San Kor Ka"
      },
      id: "06020711"
    },
    {
      name: {
        km: "សាន់គ ខ",
        latin: "San Kor Kha"
      },
      id: "06020712"
    },
    {
      name: {
        km: "ក្រសាំង ក",
        latin: "Krasang Ka"
      },
      id: "06020713"
    },
    {
      name: {
        km: "ក្រសាំង ខ",
        latin: "Krasang Kha"
      },
      id: "06020714"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "06020801"
    },
    {
      name: {
        km: "តារាម",
        latin: "Ta Ream"
      },
      id: "06020802"
    },
    {
      name: {
        km: "ឫស្សីជះ",
        latin: "Ruessei Cheah"
      },
      id: "06020803"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "06020804"
    },
    {
      name: {
        km: "ព្រៃប្រស់",
        latin: "Prey Pras"
      },
      id: "06020805"
    },
    {
      name: {
        km: "បឹងអណ្ដែង",
        latin: "Boeng Andaeng"
      },
      id: "06020806"
    },
    {
      name: {
        km: "តាអាំ",
        latin: "Ta Am"
      },
      id: "06020807"
    },
    {
      name: {
        km: "ត្បែង ក",
        latin: "Tbaeng Ka"
      },
      id: "06020808"
    },
    {
      name: {
        km: "ត្បែង ខ",
        latin: "Tbaeng Kha"
      },
      id: "06020809"
    },
    {
      name: {
        km: "បាគង់",
        latin: "Bakong"
      },
      id: "06020810"
    },
    {
      name: {
        km: "ត្រាំខ្លា",
        latin: "Tram Khla"
      },
      id: "06020811"
    },
    {
      name: {
        km: "ស្រង៉ែ",
        latin: "Srangae"
      },
      id: "06020812"
    },
    {
      name: {
        km: "អូរអំបែង",
        latin: "Ou Ambaeng"
      },
      id: "06020813"
    },
    {
      name: {
        km: "ភ្លង",
        latin: "Phlong"
      },
      id: "06020814"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "06020815"
    },
    {
      name: {
        km: "ព្រៃព្រាល ក",
        latin: "Prey Preal Ka"
      },
      id: "06020901"
    },
    {
      name: {
        km: "ព្រៃព្រាល ខ",
        latin: "Prey Preal Kha"
      },
      id: "06020902"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "06020903"
    },
    {
      name: {
        km: "រូង",
        latin: "Rung"
      },
      id: "06020904"
    },
    {
      name: {
        km: "រមាំងងាប់",
        latin: "Romeang Ngoab"
      },
      id: "06020905"
    },
    {
      name: {
        km: "ស្នោ",
        latin: "Snao"
      },
      id: "06020906"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "06020907"
    },
    {
      name: {
        km: "វល្លិយាវ",
        latin: "Voa Yeav"
      },
      id: "06020908"
    },
    {
      name: {
        km: "ថ្មល់បែក",
        latin: "Thnal Baek"
      },
      id: "06020909"
    },
    {
      name: {
        km: "ត្រពាំងព្រលិត",
        latin: "Trapeang Prolit"
      },
      id: "06020910"
    },
    {
      name: {
        km: "គោកងួន",
        latin: "Kouk Nguon"
      },
      id: "06020911"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "06020912"
    },
    {
      name: {
        km: "ល្វាជោម",
        latin: "Lvea Choum"
      },
      id: "06020913"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "06020914"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "06020915"
    },
    {
      name: {
        km: "សេរីវង្ស",
        latin: "Serei Vongs"
      },
      id: "06020916"
    },
    {
      name: {
        km: "ល្វៃ",
        latin: "Lvey"
      },
      id: "06020917"
    },
    {
      name: {
        km: "ត្រពាំងចំបក់",
        latin: "Trapeang Chambak"
      },
      id: "06020918"
    },
    {
      name: {
        km: "អូររំដេង",
        latin: "Ou Rumdeng"
      },
      id: "06020919"
    },
    {
      name: {
        km: "ថ្មល់បែក ក",
        latin: "Thnal Baek Ka"
      },
      id: "06020920"
    },
    {
      name: {
        km: "ក្ដីដូង",
        latin: "Kdei Doung"
      },
      id: "06021001"
    },
    {
      name: {
        km: "ពាមក្រែង",
        latin: "Peam Kraeng"
      },
      id: "06021002"
    },
    {
      name: {
        km: "អូរសំបួរ",
        latin: "Ou Sambuor"
      },
      id: "06021003"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "06021004"
    },
    {
      name: {
        km: "ព្រៃគុយ",
        latin: "Prey Kuy"
      },
      id: "06021101"
    },
    {
      name: {
        km: "អន្លង់លោក",
        latin: "Anlong Louk"
      },
      id: "06021102"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "06021103"
    },
    {
      name: {
        km: "បិណ្ដី",
        latin: "Bendei"
      },
      id: "06021104"
    },
    {
      name: {
        km: "ព្រេន",
        latin: "Pren"
      },
      id: "06021105"
    },
    {
      name: {
        km: "កំពង់ក្របី",
        latin: "Kampong Krabei"
      },
      id: "06021106"
    },
    {
      name: {
        km: "ស្វាយឃ្លោក",
        latin: "Svay Khlouk"
      },
      id: "06021107"
    },
    {
      name: {
        km: "ព្រៃគុយ ក",
        latin: "Prey Kuy Ka"
      },
      id: "06021108"
    },
    {
      name: {
        km: "បល្ល័ង្កកើត",
        latin: "Ballangk  Kaeut"
      },
      id: "06030101"
    },
    {
      name: {
        km: "បល្ល័ង្កលិច",
        latin: "Ballangk Lech"
      },
      id: "06030102"
    },
    {
      name: {
        km: "ពោធិបាក់ក",
        latin: "Pou Bak Ka"
      },
      id: "06030103"
    },
    {
      name: {
        km: "ដំរីជាន់ខ្លា",
        latin: "Damrei Choan Khla"
      },
      id: "06030104"
    },
    {
      name: {
        km: "ភូមិទី ១",
        latin: "Phum Ti Muoy"
      },
      id: "06030201"
    },
    {
      name: {
        km: "ភូមិទី ២",
        latin: "Phum Ti Pir"
      },
      id: "06030202"
    },
    {
      name: {
        km: "ភូមិទី ៣",
        latin: "Phum Ti Bei"
      },
      id: "06030203"
    },
    {
      name: {
        km: "ភូមិទី ៤",
        latin: "Phum Ti Buon"
      },
      id: "06030204"
    },
    {
      name: {
        km: "ភូមិទី ៥",
        latin: "Phum Ti Pram"
      },
      id: "06030205"
    },
    {
      name: {
        km: "ភូមិទី ៦",
        latin: "Phum Ti Prammuoy"
      },
      id: "06030206"
    },
    {
      name: {
        km: "ភូមិទី ៧",
        latin: "Phum Ti Prampir"
      },
      id: "06030207"
    },
    {
      name: {
        km: "កំពង់ធំ",
        latin: "Kampong Thum"
      },
      id: "06030301"
    },
    {
      name: {
        km: "កំពង់រទេះ",
        latin: "Kampong Roteh"
      },
      id: "06030302"
    },
    {
      name: {
        km: "ព្រែកស្បូវ",
        latin: "Preaek Sbov"
      },
      id: "06030401"
    },
    {
      name: {
        km: "អូរកន្ធរត្បូង",
        latin: "Ou Kanthor Tboung"
      },
      id: "06030402"
    },
    {
      name: {
        km: "អូរកន្ធរខាងជើង",
        latin: "Ou Kanthor Cheung"
      },
      id: "06030403"
    },
    {
      name: {
        km: "បឹងលៀស",
        latin: "Boeng Leas"
      },
      id: "06030404"
    },
    {
      name: {
        km: "ស្នែងក្របី",
        latin: "Snaeng Krabei"
      },
      id: "06030601"
    },
    {
      name: {
        km: "ស្ទឹងសែន",
        latin: "Stueng Saen"
      },
      id: "06030602"
    },
    {
      name: {
        km: "កំពង់ក្របៅ",
        latin: "Kampong Krabau"
      },
      id: "06030603"
    },
    {
      name: {
        km: "ស្លាកែត",
        latin: "Sla Kaet"
      },
      id: "06030801"
    },
    {
      name: {
        km: "ក្ដី",
        latin: "Kdei"
      },
      id: "06030802"
    },
    {
      name: {
        km: "ព្រៃតាហ៊ូ",
        latin: "Prey Ta Hu"
      },
      id: "06030803"
    },
    {
      name: {
        km: "ក្រចាប់",
        latin: "Krachab"
      },
      id: "06030901"
    },
    {
      name: {
        km: "អាចារ្យលាក់",
        latin: "Achar Leak"
      },
      id: "06030902"
    },
    {
      name: {
        km: "ព្រៃបន្លិច",
        latin: "Prey Banlech"
      },
      id: "06030903"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "06031001"
    },
    {
      name: {
        km: "ប្រម៉ាត់ដី",
        latin: "Pramat Dei"
      },
      id: "06031002"
    },
    {
      name: {
        km: "កំពង់សំរោង",
        latin: "Kampong Samraong"
      },
      id: "06031003"
    },
    {
      name: {
        km: "ពោធិសែនស្នាយ",
        latin: "Pou Saen Snay"
      },
      id: "06031004"
    },
    {
      name: {
        km: "ពោធិតាអ៊ុន",
        latin: "Pou Ta Un"
      },
      id: "06031005"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "06031006"
    },
    {
      name: {
        km: "ស្រយ៉ូវជើង",
        latin: "Srayov Cheung"
      },
      id: "06031007"
    },
    {
      name: {
        km: "ស្រយ៉ូវត្បូង",
        latin: "Srayov Tboung"
      },
      id: "06031008"
    },
    {
      name: {
        km: "ពុកយុក",
        latin: "Puk Yuk"
      },
      id: "06031009"
    },
    {
      name: {
        km: "ម្នាវ",
        latin: "Mneav"
      },
      id: "06031010"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "06031011"
    },
    {
      name: {
        km: "កំរ៉ែង",
        latin: "Kamraeng"
      },
      id: "06031012"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "06031013"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "06040101"
    },
    {
      name: {
        km: "គគរ",
        latin: "Kokor"
      },
      id: "06040102"
    },
    {
      name: {
        km: "គ្រួរ",
        latin: "Kruor"
      },
      id: "06040103"
    },
    {
      name: {
        km: "តាមុំ",
        latin: "Ta Mom"
      },
      id: "06040104"
    },
    {
      name: {
        km: "ក្របៅ",
        latin: "Krabau"
      },
      id: "06040105"
    },
    {
      name: {
        km: "គ្រួស",
        latin: "Kruos"
      },
      id: "06040106"
    },
    {
      name: {
        km: "ដងផ្ដៀក",
        latin: "Dang Phdiek"
      },
      id: "06040107"
    },
    {
      name: {
        km: "ដងតាឯក",
        latin: "Dang Ta Aek"
      },
      id: "06040108"
    },
    {
      name: {
        km: "សង្វាត",
        latin: "Sangvat"
      },
      id: "06040201"
    },
    {
      name: {
        km: "ក្រយាជើង",
        latin: "Kraya Cheung"
      },
      id: "06040202"
    },
    {
      name: {
        km: "ក្រយាត្បូង",
        latin: "Kraya Tboung"
      },
      id: "06040203"
    },
    {
      name: {
        km: "បារាយណ៍",
        latin: "Baray"
      },
      id: "06040204"
    },
    {
      name: {
        km: "អន្លង់ជួរ",
        latin: "Anlong Chuor"
      },
      id: "06040205"
    },
    {
      name: {
        km: "បុស្សធំ",
        latin: "Bos Thum"
      },
      id: "06040206"
    },
    {
      name: {
        km: "ត្រពាំងក្នុង",
        latin: "Trapeang Knong"
      },
      id: "06040301"
    },
    {
      name: {
        km: "ស្រម៉",
        latin: "Srama"
      },
      id: "06040302"
    },
    {
      name: {
        km: "ផ្ដៀក",
        latin: "Phdiek"
      },
      id: "06040303"
    },
    {
      name: {
        km: "ព្រៃម្រ៉ី",
        latin: "Prey Mari"
      },
      id: "06040304"
    },
    {
      name: {
        km: "ក្រញូង",
        latin: "Kranhung"
      },
      id: "06040305"
    },
    {
      name: {
        km: "ព្រហ៊ូត",
        latin: "Prohut"
      },
      id: "06040306"
    },
    {
      name: {
        km: "ស្មោញ",
        latin: "Smaonh"
      },
      id: "06040307"
    },
    {
      name: {
        km: "សុជុល",
        latin: "Sochol"
      },
      id: "06040308"
    },
    {
      name: {
        km: "សាគ្រាមជើង",
        latin: "Sakream Cheung"
      },
      id: "06040401"
    },
    {
      name: {
        km: "វាលថ្មល់",
        latin: "Veal Thnal"
      },
      id: "06040402"
    },
    {
      name: {
        km: "អូរខ្សង់",
        latin: "Ou Khsang"
      },
      id: "06040403"
    },
    {
      name: {
        km: "ព្រិច",
        latin: "Prich"
      },
      id: "06040404"
    },
    {
      name: {
        km: "ស្រែវាល",
        latin: "Srae Veal"
      },
      id: "06040405"
    },
    {
      name: {
        km: "អូរអង្គរ",
        latin: "Ou Angkor"
      },
      id: "06040406"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "06040407"
    },
    {
      name: {
        km: "កន្ទាក",
        latin: "Kanteak"
      },
      id: "06040408"
    },
    {
      name: {
        km: "វាលចាស់",
        latin: "Veal Chas"
      },
      id: "06040409"
    },
    {
      name: {
        km: "ពាមអាទិត្យ",
        latin: "Peam Atit"
      },
      id: "06040410"
    },
    {
      name: {
        km: "សាគ្រាមត្បូង",
        latin: "Sakream Tboung"
      },
      id: "06040411"
    },
    {
      name: {
        km: "ត្រពាំងក្រោល",
        latin: "Trapeang Kraol"
      },
      id: "06040501"
    },
    {
      name: {
        km: "បុស្សវែង",
        latin: "Bos Veaeng"
      },
      id: "06040502"
    },
    {
      name: {
        km: "ឫស្សីដូច",
        latin: "Ruessei Douch"
      },
      id: "06040503"
    },
    {
      name: {
        km: "ស្រែ",
        latin: "Srae"
      },
      id: "06040504"
    },
    {
      name: {
        km: "ចំណារ",
        latin: "Chamnar"
      },
      id: "06040505"
    },
    {
      name: {
        km: "អូរក្រូច",
        latin: "Ou Krouch"
      },
      id: "06040506"
    },
    {
      name: {
        km: "សាលាវិស័យ",
        latin: "Sala Visai"
      },
      id: "06040507"
    },
    {
      name: {
        km: "អណ្ដាស",
        latin: "Andas"
      },
      id: "06040508"
    },
    {
      name: {
        km: "ត្រពាំងផ្ដៀក",
        latin: "Trapeang Phdiek"
      },
      id: "06040509"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "06040510"
    },
    {
      name: {
        km: "បុស្សស្រមោច",
        latin: "Bos Sramaoch"
      },
      id: "06040511"
    },
    {
      name: {
        km: "វាលល្ពាក់",
        latin: "Veal Lpeak"
      },
      id: "06040512"
    },
    {
      name: {
        km: "ខ្មាក់",
        latin: "Khmak"
      },
      id: "06040513"
    },
    {
      name: {
        km: "តាឡែក",
        latin: "Talaek"
      },
      id: "06040514"
    },
    {
      name: {
        km: "ដងអន្ទាក់",
        latin: "Dang Anteak"
      },
      id: "06040515"
    },
    {
      name: {
        km: "ជ័យ",
        latin: "Chey"
      },
      id: "06040516"
    },
    {
      name: {
        km: "គគីរ",
        latin: "Kokir"
      },
      id: "06040517"
    },
    {
      name: {
        km: "ក្វាន់ទៀង",
        latin: "Kvan Tieng"
      },
      id: "06040518"
    },
    {
      name: {
        km: "សាលាពពេល",
        latin: "Sala Popel"
      },
      id: "06040519"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06040601"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "06040602"
    },
    {
      name: {
        km: "កំពឺត",
        latin: "Kampeut"
      },
      id: "06040603"
    },
    {
      name: {
        km: "ចាន់សេរី",
        latin: "Chan Serei"
      },
      id: "06040604"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "06040605"
    },
    {
      name: {
        km: "ជន្លូស",
        latin: "Chonlus"
      },
      id: "06040701"
    },
    {
      name: {
        km: "ម្រាក  ក",
        latin: "Mreak Ka"
      },
      id: "06040702"
    },
    {
      name: {
        km: "ម្រាក  ខ",
        latin: "Mreak Kha"
      },
      id: "06040703"
    },
    {
      name: {
        km: "ក្រពើ",
        latin: "Krapeu"
      },
      id: "06040704"
    },
    {
      name: {
        km: "ទួលគ្រើល",
        latin: "Tuol Kreul"
      },
      id: "06040705"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "06040706"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "06040707"
    },
    {
      name: {
        km: "អន្សរក្រហាយ",
        latin: "Aksar Krahay"
      },
      id: "06050101"
    },
    {
      name: {
        km: "សេរីសុខា",
        latin: "Serei Sokha"
      },
      id: "06050102"
    },
    {
      name: {
        km: "ឈូកបឹង",
        latin: "Chhuk Boeng"
      },
      id: "06050103"
    },
    {
      name: {
        km: "ឈូកស្ទឹង",
        latin: "Chhuk Stueng"
      },
      id: "06050104"
    },
    {
      name: {
        km: "ស្អៀរ",
        latin: "S'ier"
      },
      id: "06050105"
    },
    {
      name: {
        km: "ឈូកគ្រួស",
        latin: "Chhuk Kruos"
      },
      id: "06050106"
    },
    {
      name: {
        km: "អន្លង់ស្លែង",
        latin: "Anlong Slaeng"
      },
      id: "06050107"
    },
    {
      name: {
        km: "បឹងខ្វែក",
        latin: "Boeng Khvaek"
      },
      id: "06050108"
    },
    {
      name: {
        km: "ក្របៅ",
        latin: "Krabau"
      },
      id: "06050109"
    },
    {
      name: {
        km: "កំពីងតាគង់",
        latin: "Kamping Ta Kong"
      },
      id: "06050110"
    },
    {
      name: {
        km: "តាអោក",
        latin: "Ta Aok"
      },
      id: "06050111"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "06050112"
    },
    {
      name: {
        km: "វាលវែង",
        latin: "Veal Veaeng"
      },
      id: "06050113"
    },
    {
      name: {
        km: "អន្លង់ខ្ទុំ",
        latin: "Anlong Khtum"
      },
      id: "06050114"
    },
    {
      name: {
        km: "ទឹកអណ្ដូង",
        latin: "Tuek Andoung"
      },
      id: "06050115"
    },
    {
      name: {
        km: "ត្រពាំងសាលា",
        latin: "Trapeang Sala"
      },
      id: "06050116"
    },
    {
      name: {
        km: "កំពង់ជ្វា",
        latin: "Kampong Chvea"
      },
      id: "06050201"
    },
    {
      name: {
        km: "អូរតាសៀវ",
        latin: "Ou Ta Siev"
      },
      id: "06050202"
    },
    {
      name: {
        km: "ចំរេះ",
        latin: "Chamreh"
      },
      id: "06050203"
    },
    {
      name: {
        km: "បាក់ស្រី",
        latin: "Bak Srei"
      },
      id: "06050204"
    },
    {
      name: {
        km: "ទួលចារ",
        latin: "Tuol Char"
      },
      id: "06050205"
    },
    {
      name: {
        km: "ទួលធ្នង់",
        latin: "Tuol Thnong"
      },
      id: "06050206"
    },
    {
      name: {
        km: "បញ្ចឡា",
        latin: "Panhchak La"
      },
      id: "06050207"
    },
    {
      name: {
        km: "ក្យូវ",
        latin: "Kyov"
      },
      id: "06050208"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "06050209"
    },
    {
      name: {
        km: "កំពង់លុក",
        latin: "Kampong Luk"
      },
      id: "06050210"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "06050211"
    },
    {
      name: {
        km: "កំពង់ឈើទាល",
        latin: "Kampong Chheu Teal"
      },
      id: "06050301"
    },
    {
      name: {
        km: "សំបូរ",
        latin: "Sambour"
      },
      id: "06050302"
    },
    {
      name: {
        km: "សំរិត",
        latin: "Samret"
      },
      id: "06050303"
    },
    {
      name: {
        km: "ច្រម៉ាស់",
        latin: "Chramas"
      },
      id: "06050304"
    },
    {
      name: {
        km: "កូនក្អែក",
        latin: "Koun K'aek"
      },
      id: "06050305"
    },
    {
      name: {
        km: "ជាយសំពៅ",
        latin: "Cheay Sampov"
      },
      id: "06050306"
    },
    {
      name: {
        km: "ពោធិ៍ទ្រេត",
        latin: "Pou Tret"
      },
      id: "06050307"
    },
    {
      name: {
        km: "ច្រនៀង",
        latin: "Chranieng"
      },
      id: "06050308"
    },
    {
      name: {
        km: "ព្រែក",
        latin: "Preaek"
      },
      id: "06050309"
    },
    {
      name: {
        km: "ចារជ្រុំ",
        latin: "Char Chrum"
      },
      id: "06050310"
    },
    {
      name: {
        km: "ត្រពាំងជ្រូក",
        latin: "Trapeang Chrok"
      },
      id: "06050311"
    },
    {
      name: {
        km: "ប្រឡាយ",
        latin: "Pralay"
      },
      id: "06050312"
    },
    {
      name: {
        km: "ចារ្យ",
        latin: "Char"
      },
      id: "06050313"
    },
    {
      name: {
        km: "អូរគ្រូកែ",
        latin: "Ou Kru Kae"
      },
      id: "06050314"
    },
    {
      name: {
        km: "អាត់ស៊ូ",
        latin: "At Su"
      },
      id: "06050315"
    },
    {
      name: {
        km: "ត្នោតជួរ",
        latin: "Tnaot Chuor"
      },
      id: "06050401"
    },
    {
      name: {
        km: "អន្លង់ស្លែង",
        latin: "Anlong Slaeng"
      },
      id: "06050402"
    },
    {
      name: {
        km: "បឹងខ្វែក",
        latin: "Boeng Khvaek"
      },
      id: "06050403"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "06050404"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "06050405"
    },
    {
      name: {
        km: "ជាំបឹង",
        latin: "Choam Boeng"
      },
      id: "06050406"
    },
    {
      name: {
        km: "ទំនប់",
        latin: "Tumnob"
      },
      id: "06050407"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06050408"
    },
    {
      name: {
        km: "ស្រើង",
        latin: "Sraeung"
      },
      id: "06050409"
    },
    {
      name: {
        km: "តាំងក្រសៅ",
        latin: "Tang Krasau"
      },
      id: "06050501"
    },
    {
      name: {
        km: "ព្រៃក្ដី",
        latin: "Prey Kdei"
      },
      id: "06050502"
    },
    {
      name: {
        km: "តាំងស្ទោង",
        latin: "Tang Stoung"
      },
      id: "06050503"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06050504"
    },
    {
      name: {
        km: "សូរិយា",
        latin: "Souriya"
      },
      id: "06050505"
    },
    {
      name: {
        km: "តាំងក្រង",
        latin: "Tang Krang"
      },
      id: "06050506"
    },
    {
      name: {
        km: "ទឹកអណ្ដូង",
        latin: "Tuek Andoung"
      },
      id: "06050507"
    },
    {
      name: {
        km: "កំពង់ឈើទាល",
        latin: "Kampong Chheu Teal"
      },
      id: "06050508"
    },
    {
      name: {
        km: "គោកស្រុក",
        latin: "Kouk Srok"
      },
      id: "06050509"
    },
    {
      name: {
        km: "ជាំ",
        latin: "Choam"
      },
      id: "06050510"
    },
    {
      name: {
        km: "កំពង់ក្រសាំង",
        latin: "Kampong Krasang"
      },
      id: "06050511"
    },
    {
      name: {
        km: "តាប៉ោង",
        latin: "Ta Paong"
      },
      id: "06050512"
    },
    {
      name: {
        km: "ប្រឡាយ",
        latin: "Pralay"
      },
      id: "06050513"
    },
    {
      name: {
        km: "ជីនាង",
        latin: "Chi Neang"
      },
      id: "06050514"
    },
    {
      name: {
        km: "ក្របៅស្រោង",
        latin: "Krabau Sraong"
      },
      id: "06050515"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "06060101"
    },
    {
      name: {
        km: "បឹងរលំ",
        latin: "Boeng Rolum"
      },
      id: "06060102"
    },
    {
      name: {
        km: "កំពង់តាបែន",
        latin: "Kampong Ta Baen"
      },
      id: "06060103"
    },
    {
      name: {
        km: "សំរិត",
        latin: "Samret"
      },
      id: "06060104"
    },
    {
      name: {
        km: "វាលព្រីងក្រោម",
        latin: "Veal Pring Kraom"
      },
      id: "06060105"
    },
    {
      name: {
        km: "កែរ៉ង",
        latin: "Kae Rang"
      },
      id: "06060106"
    },
    {
      name: {
        km: "បឹងប្រា",
        latin: "Boeng Pra"
      },
      id: "06060107"
    },
    {
      name: {
        km: "អណ្ដូងព្រីង",
        latin: "Andoung Pring"
      },
      id: "06060108"
    },
    {
      name: {
        km: "ព្រៃកន្លែង",
        latin: "Prey Kanlaeng"
      },
      id: "06060109"
    },
    {
      name: {
        km: "ជាំផ្កា",
        latin: "Choam Phka"
      },
      id: "06060110"
    },
    {
      name: {
        km: "គន្លងខ្ទីង",
        latin: "Konlong Khting"
      },
      id: "06060111"
    },
    {
      name: {
        km: "ស្រែខ្សាច់",
        latin: "Srae Khsach"
      },
      id: "06060201"
    },
    {
      name: {
        km: "ស្រែវាលខាងលិច",
        latin: "Srae Veal Khang Lech"
      },
      id: "06060202"
    },
    {
      name: {
        km: "ស្រែវាលខាងកើត",
        latin: "Srae Veal Khang Kaeut"
      },
      id: "06060203"
    },
    {
      name: {
        km: "សំព័រតូច",
        latin: "Sampoar Touch"
      },
      id: "06060204"
    },
    {
      name: {
        km: "សំព័រធំ",
        latin: "Sampoar Thum"
      },
      id: "06060205"
    },
    {
      name: {
        km: "ពាមក្លែង",
        latin: "Peam Klaeng"
      },
      id: "06060301"
    },
    {
      name: {
        km: "រកាជួរ",
        latin: "Roka Chuor"
      },
      id: "06060302"
    },
    {
      name: {
        km: "ទឹកវិល",
        latin: "Tuek Vil"
      },
      id: "06060303"
    },
    {
      name: {
        km: "ព្រៃជ័រ",
        latin: "Prey Choar"
      },
      id: "06060304"
    },
    {
      name: {
        km: "ក្លែង",
        latin: "Klaeng"
      },
      id: "06060305"
    },
    {
      name: {
        km: "គុម្ពោតឈូក",
        latin: "Kampout Chhuk"
      },
      id: "06060306"
    },
    {
      name: {
        km: "ត្រកួន",
        latin: "Trakuon"
      },
      id: "06060307"
    },
    {
      name: {
        km: "អង្គរសែនជ័យ",
        latin: "Angkor Sen Chey"
      },
      id: "06060308"
    },
    {
      name: {
        km: "កន្ទីរ",
        latin: "Kantir"
      },
      id: "06060401"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "06060402"
    },
    {
      name: {
        km: "សំអោង",
        latin: "Sam Aong"
      },
      id: "06060403"
    },
    {
      name: {
        km: "ជាំស្វាយ",
        latin: "Choam Svay"
      },
      id: "06060404"
    },
    {
      name: {
        km: "ត្បូងទឹក",
        latin: "Tboung Tuek"
      },
      id: "06060405"
    },
    {
      name: {
        km: "ត្រពាំងត្រឡាច",
        latin: "Trapeang Tralach"
      },
      id: "06060406"
    },
    {
      name: {
        km: "រ៉ងក្នាយ",
        latin: "Rang Khnay"
      },
      id: "06060407"
    },
    {
      name: {
        km: "អូរពកសាមគ្គី",
        latin: "Ou Pok Sameakki"
      },
      id: "06060408"
    },
    {
      name: {
        km: "ចេកមួយស្ទង",
        latin: "Chek Muoy Stong"
      },
      id: "06060501"
    },
    {
      name: {
        km: "វាលស្នាយ",
        latin: "Veal Snay"
      },
      id: "06060502"
    },
    {
      name: {
        km: "រនាម",
        latin: "Roneam"
      },
      id: "06060503"
    },
    {
      name: {
        km: "ត្របែក",
        latin: "Trabaek"
      },
      id: "06060504"
    },
    {
      name: {
        km: "រំពុះ",
        latin: "Rumpuh"
      },
      id: "06060505"
    },
    {
      name: {
        km: "ជាន់លែង",
        latin: "Choan Leaeng"
      },
      id: "06060506"
    },
    {
      name: {
        km: "ជាំផល",
        latin: "Choam Phal"
      },
      id: "06060507"
    },
    {
      name: {
        km: "សមរម្យ",
        latin: "Samrom"
      },
      id: "06060508"
    },
    {
      name: {
        km: "ពពឹង",
        latin: "Popueng"
      },
      id: "06060509"
    },
    {
      name: {
        km: "ផ្ទោល",
        latin: "Phtoul"
      },
      id: "06060510"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06060511"
    },
    {
      name: {
        km: "ដងទទឹង",
        latin: "Dang Totueng"
      },
      id: "06060601"
    },
    {
      name: {
        km: "ខ្មែរ",
        latin: "Khmer"
      },
      id: "06060602"
    },
    {
      name: {
        km: "ក្រាំងដើម",
        latin: "Krang Daeum"
      },
      id: "06060603"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "06060604"
    },
    {
      name: {
        km: "ស្រឡៅ",
        latin: "Sralau"
      },
      id: "06060605"
    },
    {
      name: {
        km: "ងន",
        latin: "Ngan"
      },
      id: "06060606"
    },
    {
      name: {
        km: "សំភី",
        latin: "Samphi"
      },
      id: "06060607"
    },
    {
      name: {
        km: "ត្រឹប",
        latin: "Troeb"
      },
      id: "06060608"
    },
    {
      name: {
        km: "វាលព្រីងលើ",
        latin: "Veal Pring Leu"
      },
      id: "06060609"
    },
    {
      name: {
        km: "តាំងក្រសៅ",
        latin: "Tang Krasau"
      },
      id: "06060610"
    },
    {
      name: {
        km: "ក្របីព្រៃ",
        latin: "Krabei Prey"
      },
      id: "06060611"
    },
    {
      name: {
        km: "អូរត្នោត",
        latin: "Ou Tnaot"
      },
      id: "06060612"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "06060613"
    },
    {
      name: {
        km: "ដង្ហិត",
        latin: "Danghet"
      },
      id: "06060701"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "06060702"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "06060703"
    },
    {
      name: {
        km: "ស្រែចង់",
        latin: "Srae Chang"
      },
      id: "06060704"
    },
    {
      name: {
        km: "ប្រាសាទអណ្ដែត",
        latin: "Prasat Andaet"
      },
      id: "06060705"
    },
    {
      name: {
        km: "ក្បាលខ្លា",
        latin: "Kbal Khla"
      },
      id: "06060706"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "06060707"
    },
    {
      name: {
        km: "ជរ",
        latin: "Chor"
      },
      id: "06060708"
    },
    {
      name: {
        km: "ទឹកម្លាង",
        latin: "Tuek Mleang"
      },
      id: "06060709"
    },
    {
      name: {
        km: "សណ្ដាន់",
        latin: "Sandan"
      },
      id: "06060710"
    },
    {
      name: {
        km: "កំពង់ត្របែក",
        latin: "Kampong Trabaek"
      },
      id: "06060711"
    },
    {
      name: {
        km: "បាជ័យ",
        latin: "Ba Chey"
      },
      id: "06060712"
    },
    {
      name: {
        km: "ព្រៃគគីរ",
        latin: "Prey Kokir"
      },
      id: "06060713"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "06060801"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "06060802"
    },
    {
      name: {
        km: "ពោធិ៍រោង",
        latin: "Pou Roung"
      },
      id: "06060803"
    },
    {
      name: {
        km: "តាយ៉ង",
        latin: "Tayang"
      },
      id: "06060804"
    },
    {
      name: {
        km: "អន្សា",
        latin: "Ansa"
      },
      id: "06060805"
    },
    {
      name: {
        km: "ព្រេន",
        latin: "Pren"
      },
      id: "06060806"
    },
    {
      name: {
        km: "ស្រែព្រីង",
        latin: "Srae Pring"
      },
      id: "06060807"
    },
    {
      name: {
        km: "លែង",
        latin: "Leaeng"
      },
      id: "06060901"
    },
    {
      name: {
        km: "រនាម",
        latin: "Roneam"
      },
      id: "06060902"
    },
    {
      name: {
        km: "រន្ទះ",
        latin: "Ronteah"
      },
      id: "06060903"
    },
    {
      name: {
        km: "ទំអរ",
        latin: "Tum Ar"
      },
      id: "06060904"
    },
    {
      name: {
        km: "ក្បាលដំរី",
        latin: "Kbal Damrei"
      },
      id: "06060905"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "06060906"
    },
    {
      name: {
        km: "ស្រឡៅស្រោង",
        latin: "Sralau Sraong"
      },
      id: "06060907"
    },
    {
      name: {
        km: "ខោស",
        latin: "Khaos"
      },
      id: "06060908"
    },
    {
      name: {
        km: "បឹងល្វា",
        latin: "Boeng Lvea"
      },
      id: "06070101"
    },
    {
      name: {
        km: "កោះបង្គៅ",
        latin: "Kaoh Bangkov"
      },
      id: "06070102"
    },
    {
      name: {
        km: "ត្បែង",
        latin: "Tbaeng"
      },
      id: "06070103"
    },
    {
      name: {
        km: "សង្គ្រោះ",
        latin: "Sangkruoh"
      },
      id: "06070104"
    },
    {
      name: {
        km: "ត្រពាំងទឹម",
        latin: "Trapeang Tuem"
      },
      id: "06070105"
    },
    {
      name: {
        km: "ត្រពាំងប្រី",
        latin: "Trapeang Prei"
      },
      id: "06070106"
    },
    {
      name: {
        km: "អូរអំពិល",
        latin: "Ou Ampil"
      },
      id: "06070107"
    },
    {
      name: {
        km: "អូរអង្គុញ",
        latin: "Ou Angkonh"
      },
      id: "06070108"
    },
    {
      name: {
        km: "អូរធំ",
        latin: "Ou Thom"
      },
      id: "06070109"
    },
    {
      name: {
        km: "រំដួលថ្មី",
        latin: "Romdul Thmey"
      },
      id: "06070110"
    },
    {
      name: {
        km: "អូរគ្រញូង",
        latin: "Ou KroNhoung"
      },
      id: "06070111"
    },
    {
      name: {
        km: "អូរគគីរ",
        latin: "Ou Korki"
      },
      id: "06070112"
    },
    {
      name: {
        km: "ត្រពាំងកកោះ",
        latin: "Trapeang Korkos"
      },
      id: "06070113"
    },
    {
      name: {
        km: "ទួលវិហារ",
        latin: "Tuol Vihear"
      },
      id: "06070201"
    },
    {
      name: {
        km: "ជ័យមង្គល",
        latin: "Chey Mongkol"
      },
      id: "06070202"
    },
    {
      name: {
        km: "ស្ដុកស្ដម្ភ",
        latin: "Sdok Sdam"
      },
      id: "06070203"
    },
    {
      name: {
        km: "អូរកោះគគីរ",
        latin: "Ou Kaoh Kokir"
      },
      id: "06070204"
    },
    {
      name: {
        km: "ព្រៃភ្លូ",
        latin: "Prey Phlu"
      },
      id: "06070301"
    },
    {
      name: {
        km: "ធន់មោង",
        latin: "Thon Moung"
      },
      id: "06070302"
    },
    {
      name: {
        km: "ទួលសង្កែ",
        latin: "Tuol Sangkae"
      },
      id: "06070303"
    },
    {
      name: {
        km: "កំពង់ថ្ម",
        latin: "Kampong Thma"
      },
      id: "06070304"
    },
    {
      name: {
        km: "កងសៅ",
        latin: "Kang Sau"
      },
      id: "06070305"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "06070306"
    },
    {
      name: {
        km: "ឃ្លៃ",
        latin: "Khley"
      },
      id: "06070307"
    },
    {
      name: {
        km: "ស្នោ",
        latin: "Snao"
      },
      id: "06070308"
    },
    {
      name: {
        km: "ខ្វែក",
        latin: "Khvaek"
      },
      id: "06070309"
    },
    {
      name: {
        km: "ល្អក់",
        latin: "L'ak"
      },
      id: "06070310"
    },
    {
      name: {
        km: "ស្អាង",
        latin: "S'ang"
      },
      id: "06070311"
    },
    {
      name: {
        km: "ជ័យជំនះ",
        latin: "Chey Chumneah"
      },
      id: "06070401"
    },
    {
      name: {
        km: "គីរីវន្ដ",
        latin: "Kiri Von"
      },
      id: "06070402"
    },
    {
      name: {
        km: "ត្បូងក្រពើ",
        latin: "Tboung Krapeu"
      },
      id: "06070403"
    },
    {
      name: {
        km: "ជាយស្បៃ",
        latin: "Cheay Sbai"
      },
      id: "06070404"
    },
    {
      name: {
        km: "ស្វាយកាល់",
        latin: "Svay Kal"
      },
      id: "06070405"
    },
    {
      name: {
        km: "សន្ទុកក្នុង",
        latin: "Santuk Knong"
      },
      id: "06070406"
    },
    {
      name: {
        km: "សន្ទុកក្រៅ",
        latin: "Santuk Krau"
      },
      id: "06070407"
    },
    {
      name: {
        km: "ជីមាឃ",
        latin: "Chi Meakh"
      },
      id: "06070408"
    },
    {
      name: {
        km: "សាលាសន្ទុក",
        latin: "Sala Santuk"
      },
      id: "06070409"
    },
    {
      name: {
        km: "សំណាក",
        latin: "Samnak"
      },
      id: "06070410"
    },
    {
      name: {
        km: "ក្រយា",
        latin: "Kraya"
      },
      id: "06070501"
    },
    {
      name: {
        km: "ទក់",
        latin: "Tok"
      },
      id: "06070502"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "06070503"
    },
    {
      name: {
        km: "ដងក្ដារ",
        latin: "Dang Kdar"
      },
      id: "06070504"
    },
    {
      name: {
        km: "តាម៉ិញ",
        latin: "Ta Menh"
      },
      id: "06070505"
    },
    {
      name: {
        km: "សុភមង្គល",
        latin: "Sopheak Mongkol"
      },
      id: "06070506"
    },
    {
      name: {
        km: "ថ្មសំលៀង",
        latin: "Thma Samlieng"
      },
      id: "06070507"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "06070508"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "06070509"
    },
    {
      name: {
        km: "អូរទឹកថ្លា",
        latin: "Ou Tuek Thla"
      },
      id: "06070510"
    },
    {
      name: {
        km: "សែនសិរីមង្គល",
        latin: "Saen Serei Mongkul"
      },
      id: "06070511"
    },
    {
      name: {
        km: "ត្រើយអូរ",
        latin: "Traeuy Ou"
      },
      id: "06070601"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "06070602"
    },
    {
      name: {
        km: "កងមាស",
        latin: "Kang Meas"
      },
      id: "06070603"
    },
    {
      name: {
        km: "សំពង់",
        latin: "Sampong"
      },
      id: "06070701"
    },
    {
      name: {
        km: "ស៊ីវត្ថា",
        latin: "Sivottha"
      },
      id: "06070702"
    },
    {
      name: {
        km: "ចំបក់ជ្រុំ",
        latin: "Chambak Chrum"
      },
      id: "06070703"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "06070704"
    },
    {
      name: {
        km: "តាញ៉ោក",
        latin: "Ta Nhaok"
      },
      id: "06070705"
    },
    {
      name: {
        km: "ស្រែតាកោ",
        latin: "Srae Ta Kao"
      },
      id: "06070706"
    },
    {
      name: {
        km: "ត្រើយម្យ៉ាប",
        latin: "Traeuy Myab"
      },
      id: "06070707"
    },
    {
      name: {
        km: "លាវ",
        latin: "Leav"
      },
      id: "06070708"
    },
    {
      name: {
        km: "បន្ទាយយោមរាជ",
        latin: "Banteay Yumreach"
      },
      id: "06070709"
    },
    {
      name: {
        km: "ត្នោតជុំ",
        latin: "Tnaot Chum"
      },
      id: "06070710"
    },
    {
      name: {
        km: "៧មករា",
        latin: "Prampir Meakkakra"
      },
      id: "06070801"
    },
    {
      name: {
        km: "តាំងក្រសាំង",
        latin: "Tang Krasang"
      },
      id: "06070802"
    },
    {
      name: {
        km: "ឈើល្វីង",
        latin: "Chheu Lving"
      },
      id: "06070803"
    },
    {
      name: {
        km: "គគីរជួរ",
        latin: "Kokir Chuor"
      },
      id: "06070804"
    },
    {
      name: {
        km: "ទួលចាន់",
        latin: "Tuol Chan"
      },
      id: "06070805"
    },
    {
      name: {
        km: "ចំបក់ខាងជើង",
        latin: "Chambak Khang Cheung"
      },
      id: "06070806"
    },
    {
      name: {
        km: "សង់ឃ្លាំង",
        latin: "Sang Khleang"
      },
      id: "06070807"
    },
    {
      name: {
        km: "ធម្មនាថ",
        latin: "Thormmeak Neath"
      },
      id: "06070808"
    },
    {
      name: {
        km: "សង្គមថ្មី",
        latin: "Sangkom Thmei"
      },
      id: "06070809"
    },
    {
      name: {
        km: "វាំងខាងជើង",
        latin: "Veang Khang Cheung"
      },
      id: "06070810"
    },
    {
      name: {
        km: "វាំងខាងត្បូង",
        latin: "Veang Khang Tboung"
      },
      id: "06070811"
    },
    {
      name: {
        km: "ទីពោ",
        latin: "Ti Pou"
      },
      id: "06070901"
    },
    {
      name: {
        km: "និម្មិត",
        latin: "Nimit"
      },
      id: "06070902"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06070903"
    },
    {
      name: {
        km: "តាព្រាច",
        latin: "Ta Preach"
      },
      id: "06070904"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "06070905"
    },
    {
      name: {
        km: "ឈូករំដួល",
        latin: "Chhuk Rumduol"
      },
      id: "06070906"
    },
    {
      name: {
        km: "ជាំថ្នាញ",
        latin: "Choam Thnanh"
      },
      id: "06070907"
    },
    {
      name: {
        km: "ផ្លុង",
        latin: "Phlong"
      },
      id: "06070908"
    },
    {
      name: {
        km: "ក្បាលបី",
        latin: "Kbal Bei"
      },
      id: "06070909"
    },
    {
      name: {
        km: "ស្រែស្រម៉",
        latin: "Srae Srama"
      },
      id: "06070910"
    },
    {
      name: {
        km: "ត្រពាំងត្រុំ",
        latin: "Trapeang Trom"
      },
      id: "06070911"
    },
    {
      name: {
        km: "សែនអភិវឌ្ឍន៍ ១",
        latin: "Sen Akpiwat 1"
      },
      id: "06070912"
    },
    {
      name: {
        km: "សែនអភិវឌ្ឍន៍ ២",
        latin: "Sen Akpiwat 2"
      },
      id: "06070913"
    },
    {
      name: {
        km: "អូរធំ",
        latin: "Ou Thom"
      },
      id: "06070914"
    },
    {
      name: {
        km: "ពោធិ៍ខាវ",
        latin: "Pou Khav"
      },
      id: "06071001"
    },
    {
      name: {
        km: "បញ្ញាជី",
        latin: "Panhnha Chi"
      },
      id: "06071002"
    },
    {
      name: {
        km: "អំពុះ",
        latin: "Ampuh"
      },
      id: "06071003"
    },
    {
      name: {
        km: "ចុងដា",
        latin: "Chong Da"
      },
      id: "06071004"
    },
    {
      name: {
        km: "កាលមេឃ",
        latin: "Kal Mekh"
      },
      id: "06071005"
    },
    {
      name: {
        km: "ពន្លាជ័យ",
        latin: "Ponlea Chey"
      },
      id: "06080101"
    },
    {
      name: {
        km: "ចន្លុះ",
        latin: "Chanloh"
      },
      id: "06080102"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "06080103"
    },
    {
      name: {
        km: "បន្ទាយស្ទោង",
        latin: "Banteay Stoung"
      },
      id: "06080104"
    },
    {
      name: {
        km: "កុកគ្រួស",
        latin: "Kok Kruos"
      },
      id: "06080105"
    },
    {
      name: {
        km: "គោកសណ្ដែក",
        latin: "Kouk Sandaek"
      },
      id: "06080106"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "06080107"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "06080108"
    },
    {
      name: {
        km: "ស្លាករ",
        latin: "Sla Kar"
      },
      id: "06080109"
    },
    {
      name: {
        km: "ចំបក់បញ្ញា",
        latin: "Chambak Panhnha"
      },
      id: "06080110"
    },
    {
      name: {
        km: "បរវែង",
        latin: "Bar Veaeng"
      },
      id: "06080111"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "06080112"
    },
    {
      name: {
        km: "តាម៉ើ",
        latin: "Ta Maeu"
      },
      id: "06080113"
    },
    {
      name: {
        km: "ស្រោមដែក",
        latin: "Sraom Daek"
      },
      id: "06080114"
    },
    {
      name: {
        km: "ដូនប៉ុក",
        latin: "Doun Pok"
      },
      id: "06080115"
    },
    {
      name: {
        km: "ព្រះនង្គ័ល",
        latin: "Preah Neangkoal"
      },
      id: "06080201"
    },
    {
      name: {
        km: "សំព័រ",
        latin: "Sampoar"
      },
      id: "06080202"
    },
    {
      name: {
        km: "ស្រីរងិត",
        latin: "Srei Rongit"
      },
      id: "06080203"
    },
    {
      name: {
        km: "ស្វាយអៀ",
        latin: "Svay Ie"
      },
      id: "06080204"
    },
    {
      name: {
        km: "នាងនយ",
        latin: "Neang Noy"
      },
      id: "06080205"
    },
    {
      name: {
        km: "សណ្ដាន់",
        latin: "Sandan"
      },
      id: "06080206"
    },
    {
      name: {
        km: "ស្ពានគ្រង",
        latin: "Spean Krong"
      },
      id: "06080207"
    },
    {
      name: {
        km: "ជីអាប់",
        latin: "Chi Ab"
      },
      id: "06080208"
    },
    {
      name: {
        km: "ចំណាក់",
        latin: "Chamnak"
      },
      id: "06080209"
    },
    {
      name: {
        km: "លៀប",
        latin: "Lieb"
      },
      id: "06080210"
    },
    {
      name: {
        km: "ស្រង់",
        latin: "Srang"
      },
      id: "06080301"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "06080302"
    },
    {
      name: {
        km: "ត្រពាំងជ័រ",
        latin: "Trapeang Choar"
      },
      id: "06080303"
    },
    {
      name: {
        km: "ខ្មាក់",
        latin: "Khmak"
      },
      id: "06080304"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "06080305"
    },
    {
      name: {
        km: "ល្ហុង",
        latin: "Lhong"
      },
      id: "06080306"
    },
    {
      name: {
        km: "អណ្ដូងត្រុំ",
        latin: "Andoung Trom"
      },
      id: "06080307"
    },
    {
      name: {
        km: "ធ្លក",
        latin: "Thlok"
      },
      id: "06080308"
    },
    {
      name: {
        km: "ក្អិន",
        latin: "K'en"
      },
      id: "06080309"
    },
    {
      name: {
        km: "ផ្លោច",
        latin: "Phlaoch"
      },
      id: "06080310"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "06080401"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "06080402"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "06080403"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "06080404"
    },
    {
      name: {
        km: "នាងសល្ងាច",
        latin: "Neang Sa Lngeach"
      },
      id: "06080405"
    },
    {
      name: {
        km: "ត្រាច",
        latin: "Trach"
      },
      id: "06080406"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "06080501"
    },
    {
      name: {
        km: "លាបទង",
        latin: "Leab Tong"
      },
      id: "06080502"
    },
    {
      name: {
        km: "កំពង់ក្ដី",
        latin: "Kampong Kdei"
      },
      id: "06080503"
    },
    {
      name: {
        km: "កំពង់ចិន",
        latin: "Kampong Chen"
      },
      id: "06080504"
    },
    {
      name: {
        km: "ជីយោគ",
        latin: "Chi Youk"
      },
      id: "06080505"
    },
    {
      name: {
        km: "ស្វាយស",
        latin: "Svay Sa"
      },
      id: "06080506"
    },
    {
      name: {
        km: "ងួនសៀម",
        latin: "Nguon Siem"
      },
      id: "06080507"
    },
    {
      name: {
        km: "អូរដូង",
        latin: "Ou Doung"
      },
      id: "06080601"
    },
    {
      name: {
        km: "សំបួរត្បូង",
        latin: "Sambuor Tboung"
      },
      id: "06080602"
    },
    {
      name: {
        km: "សំបួរជើង",
        latin: "Sambuor Cheung"
      },
      id: "06080603"
    },
    {
      name: {
        km: "គោកទ្រា",
        latin: "Kouk Trea"
      },
      id: "06080604"
    },
    {
      name: {
        km: "ម្សាក្រងត្បូង",
        latin: "Msa Krang Tboung"
      },
      id: "06080605"
    },
    {
      name: {
        km: "ម្សាក្រងជើង",
        latin: "Msa Krang Cheung"
      },
      id: "06080606"
    },
    {
      name: {
        km: "កំពង់ប្រដំ",
        latin: "Kampong Pradam"
      },
      id: "06080607"
    },
    {
      name: {
        km: "បត់ត្រង់",
        latin: "Bat Trang"
      },
      id: "06080608"
    },
    {
      name: {
        km: "ប្រគាប",
        latin: "Prakeab"
      },
      id: "06080609"
    },
    {
      name: {
        km: "ជីមាស",
        latin: "Chi Meas"
      },
      id: "06080610"
    },
    {
      name: {
        km: "សំប៉ាន",
        latin: "Sampan"
      },
      id: "06080611"
    },
    {
      name: {
        km: "ពៅវើយ",
        latin: "Pov Veuy"
      },
      id: "06080701"
    },
    {
      name: {
        km: "ពាមបាង",
        latin: "Peam Bang"
      },
      id: "06080703"
    },
    {
      name: {
        km: "ដូនស្តើង",
        latin: "Doun Sderng"
      },
      id: "06080705"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "06080801"
    },
    {
      name: {
        km: "អន្លង់ក្រាញ់",
        latin: "Anlong Kranh"
      },
      id: "06080802"
    },
    {
      name: {
        km: "កោះសំរោង",
        latin: "Kaoh Samraong"
      },
      id: "06080803"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "06080804"
    },
    {
      name: {
        km: "ខ្ទមមន",
        latin: "Khtom Mon"
      },
      id: "06080805"
    },
    {
      name: {
        km: "ពពក",
        latin: "Popok"
      },
      id: "06080806"
    },
    {
      name: {
        km: "ផ្ទះដើម",
        latin: "Phteah Daeum"
      },
      id: "06080807"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "06080808"
    },
    {
      name: {
        km: "អង់ឃ្លាំ",
        latin: "Ang Khloam"
      },
      id: "06080901"
    },
    {
      name: {
        km: "កំពង់វាំង",
        latin: "Kampong Veang"
      },
      id: "06080902"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "06080903"
    },
    {
      name: {
        km: "ប្រឡាយ",
        latin: "Pralay"
      },
      id: "06080904"
    },
    {
      name: {
        km: "គោករវៀង",
        latin: "Kouk Rovieng"
      },
      id: "06080905"
    },
    {
      name: {
        km: "អន្លង់ព្រីង",
        latin: "Anlong Pring"
      },
      id: "06080906"
    },
    {
      name: {
        km: "កំព្រាល",
        latin: "Kampreal"
      },
      id: "06080907"
    },
    {
      name: {
        km: "កន្ធាន",
        latin: "Kanthean"
      },
      id: "06080908"
    },
    {
      name: {
        km: "នាងសល្ងាច",
        latin: "Neang Sa Lngeach"
      },
      id: "06080909"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06080910"
    },
    {
      name: {
        km: "ក្រែក",
        latin: "Kraek"
      },
      id: "06080911"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "06080912"
    },
    {
      name: {
        km: "តាទ្រៀល",
        latin: "Ta Treal"
      },
      id: "06080913"
    },
    {
      name: {
        km: "ស្រែតាម៉ែន",
        latin: "Srae Ta Maen"
      },
      id: "06080914"
    },
    {
      name: {
        km: "សៀមពាយ",
        latin: "Siem Peay"
      },
      id: "06081001"
    },
    {
      name: {
        km: "ជះ",
        latin: "Cheah"
      },
      id: "06081002"
    },
    {
      name: {
        km: "សូភី",
        latin: "Souphi"
      },
      id: "06081003"
    },
    {
      name: {
        km: "លឹក",
        latin: "Luek"
      },
      id: "06081004"
    },
    {
      name: {
        km: "កណ្ដោលចាស់",
        latin: "Kandaol Chas"
      },
      id: "06081005"
    },
    {
      name: {
        km: "កណ្ដោលថ្មី",
        latin: "Kandaol Thmei"
      },
      id: "06081006"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "06081007"
    },
    {
      name: {
        km: "សំរ៉ង",
        latin: "Samrang"
      },
      id: "06081008"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "06081009"
    },
    {
      name: {
        km: "បុសតាសោម",
        latin: "Bos Ta Saom"
      },
      id: "06081101"
    },
    {
      name: {
        km: "កន្ដុងរ៉ុង",
        latin: "Kantong Rong"
      },
      id: "06081102"
    },
    {
      name: {
        km: "ព្រំស្រី",
        latin: "Prum Srei"
      },
      id: "06081103"
    },
    {
      name: {
        km: "ស្វាយរៀង",
        latin: "Svay Rieng"
      },
      id: "06081104"
    },
    {
      name: {
        km: "បទុមលិច",
        latin: "Botum Lech"
      },
      id: "06081105"
    },
    {
      name: {
        km: "បទុមកើត",
        latin: "Botum Kaeut"
      },
      id: "06081106"
    },
    {
      name: {
        km: "កន្ដើប",
        latin: "Kantaeub"
      },
      id: "06081107"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "06081108"
    },
    {
      name: {
        km: "ក្ដីចារ្យ",
        latin: "Kdei Char"
      },
      id: "06081109"
    },
    {
      name: {
        km: "ក្រសាំងជ្រុំត្បូង",
        latin: "Krasang Chrum Tboung"
      },
      id: "06081201"
    },
    {
      name: {
        km: "ក្រសាំងជ្រុំជើង",
        latin: "Krasang Chrum Cheung"
      },
      id: "06081202"
    },
    {
      name: {
        km: "ត្នោតគម",
        latin: "Tnaot Kom"
      },
      id: "06081203"
    },
    {
      name: {
        km: "សំព្រោជ",
        latin: "Samprouch"
      },
      id: "06081204"
    },
    {
      name: {
        km: "គោកស្នាយ",
        latin: "Kouk Snay"
      },
      id: "06081205"
    },
    {
      name: {
        km: "ពៃ",
        latin: "Pey"
      },
      id: "06081206"
    },
    {
      name: {
        km: "ចំបក់ខ្ពស់",
        latin: "Chambak Khpos"
      },
      id: "06081207"
    },
    {
      name: {
        km: "ត្រាចជ្រុំ",
        latin: "Trach Chrum"
      },
      id: "06081208"
    },
    {
      name: {
        km: "ពភ្លូក",
        latin: "Pophluk"
      },
      id: "06081209"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "06081210"
    },
    {
      name: {
        km: "មឈាយ",
        latin: "Mochheay"
      },
      id: "06081211"
    },
    {
      name: {
        km: "ដូនល្អ",
        latin: "Doun L'a"
      },
      id: "06081212"
    },
    {
      name: {
        km: "ប្រដិត",
        latin: "Pradet"
      },
      id: "06081213"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "06081214"
    },
    {
      name: {
        km: "អូររំចេក",
        latin: "Ou Rumchek"
      },
      id: "06081215"
    },
    {
      name: {
        km: "អំពៅព្រៃ",
        latin: "Ampov Prey"
      },
      id: "06081216"
    },
    {
      name: {
        km: "ស្លាក់ក្រាញ់",
        latin: "Slak Kranh"
      },
      id: "06081217"
    },
    {
      name: {
        km: "ទទា",
        latin: "Totea"
      },
      id: "06081301"
    },
    {
      name: {
        km: "កោះ",
        latin: "Kaoh"
      },
      id: "06081302"
    },
    {
      name: {
        km: "ហប់",
        latin: "Hab"
      },
      id: "06081303"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "06081304"
    },
    {
      name: {
        km: "សាមក្អោក",
        latin: "Sam K'aok"
      },
      id: "06081305"
    },
    {
      name: {
        km: "ច្រាំងស",
        latin: "Chrang Sa"
      },
      id: "06081306"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "06081307"
    },
    {
      name: {
        km: "បុសតាឯក",
        latin: "Bos Ta Aek"
      },
      id: "06081308"
    },
    {
      name: {
        km: "តាចរ",
        latin: "Ta Char"
      },
      id: "06081309"
    },
    {
      name: {
        km: "លាបទង",
        latin: "Leab Tong"
      },
      id: "06081310"
    },
    {
      name: {
        km: "ផ្ទះវាល",
        latin: "Phteah Veal"
      },
      id: "06081311"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "06081312"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "06081313"
    },
    {
      name: {
        km: "កុចទីទុយ",
        latin: "Koch Tituy"
      },
      id: "06081314"
    },
    {
      name: {
        km: "ពពាយ",
        latin: "Popeay"
      },
      id: "06081315"
    },
    {
      name: {
        km: "ទំពេជ",
        latin: "Tumpech"
      },
      id: "06081316"
    },
    {
      name: {
        km: "តាព្រៃ",
        latin: "Ta Prey"
      },
      id: "06090101"
    },
    {
      name: {
        km: "អូររាំង",
        latin: "Ou Reang"
      },
      id: "06090102"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "06090103"
    },
    {
      name: {
        km: "អមទង",
        latin: "Am Tong"
      },
      id: "06090104"
    },
    {
      name: {
        km: "ត្រដក់ពង",
        latin: "Tradak Pong"
      },
      id: "06090105"
    },
    {
      name: {
        km: "ស្រះសំបួរ",
        latin: "Srah Sambuor"
      },
      id: "06090106"
    },
    {
      name: {
        km: "ថ្នល់កែង",
        latin: "Thnal Kaeng"
      },
      id: "06090107"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "06090201"
    },
    {
      name: {
        km: "ច្រនាង",
        latin: "Chranieng"
      },
      id: "06090202"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang veaeng"
      },
      id: "06090203"
    },
    {
      name: {
        km: "ថ្លា",
        latin: "Thla"
      },
      id: "06090204"
    },
    {
      name: {
        km: "ចាន់",
        latin: "Chan"
      },
      id: "06090205"
    },
    {
      name: {
        km: "ស្រះបន្ទាយ",
        latin: "Srah Banteay"
      },
      id: "06090206"
    },
    {
      name: {
        km: "សៀមរាង",
        latin: "Siem Reang"
      },
      id: "06090207"
    },
    {
      name: {
        km: "អង្គរជា",
        latin: "Angkor Chea"
      },
      id: "06090208"
    },
    {
      name: {
        km: "កន្ធំ",
        latin: "Kanthum"
      },
      id: "06090209"
    },
    {
      name: {
        km: "ត្រពាំងខ្លង",
        latin: "Trapeang khlong"
      },
      id: "06090210"
    },
    {
      name: {
        km: "ប្រាំងសំរោង",
        latin: "Prang Samraong"
      },
      id: "06090211"
    },
    {
      name: {
        km: "អូរំចេក",
        latin: "Ou Rumchek"
      },
      id: "06090212"
    },
    {
      name: {
        km: "ប៉ាង",
        latin: "Pang"
      },
      id: "06090213"
    },
    {
      name: {
        km: "ទួលទំពូង",
        latin: "Tuol Tumpung"
      },
      id: "06090301"
    },
    {
      name: {
        km: "បុស្សស្បែង",
        latin: "Bos Sbaeng"
      },
      id: "06090302"
    },
    {
      name: {
        km: "ជាប",
        latin: "Cheab"
      },
      id: "06090303"
    },
    {
      name: {
        km: "ត្រណាល",
        latin: "Tranal"
      },
      id: "06090304"
    },
    {
      name: {
        km: "ថ្មអណ្ដែត",
        latin: "Thma Andaet"
      },
      id: "06090305"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "06090306"
    },
    {
      name: {
        km: "សេរីចំប៉ា",
        latin: "Serei Champa"
      },
      id: "06090307"
    },
    {
      name: {
        km: "ហាន់ទ្វារ",
        latin: "Han Tvear"
      },
      id: "06090308"
    },
    {
      name: {
        km: "ត្រពាំងច្រនៀង",
        latin: "Trapeang Chranieng"
      },
      id: "06090309"
    },
    {
      name: {
        km: "គីរីអណ្ដែត",
        latin: "Kiri Andaet"
      },
      id: "06090401"
    },
    {
      name: {
        km: "រំចេក",
        latin: "Rumchek"
      },
      id: "06090402"
    },
    {
      name: {
        km: "ព្នៅ",
        latin: "Pnov"
      },
      id: "06090403"
    },
    {
      name: {
        km: "ស្វាយម្សៅ",
        latin: "Svay Msau"
      },
      id: "06090404"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "06090405"
    },
    {
      name: {
        km: "ទ្រៀល",
        latin: "Triel"
      },
      id: "06090406"
    },
    {
      name: {
        km: "ទន្លេវល្លិ",
        latin: "Tonle Voa"
      },
      id: "06090407"
    },
    {
      name: {
        km: "សាលាឃុំ",
        latin: "Sala Khum"
      },
      id: "06090408"
    },
    {
      name: {
        km: "កំញ៉ាត",
        latin: "Kamnhat"
      },
      id: "06090409"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "06090410"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "06090411"
    },
    {
      name: {
        km: "រពាក់ពេន",
        latin: "Ropeak Pen"
      },
      id: "06090412"
    },
    {
      name: {
        km: "អង្គរនាង",
        latin: "Angkor Neang"
      },
      id: "06090413"
    },
    {
      name: {
        km: "នាងល្អ",
        latin: "Neang L'a"
      },
      id: "06090414"
    },
    {
      name: {
        km: "ប្រខ្នាយ",
        latin: "Prakhnay"
      },
      id: "06090415"
    },
    {
      name: {
        km: "កំចាយមារ",
        latin: "Kamchay Mear"
      },
      id: "06090416"
    },
    {
      name: {
        km: "ដារ",
        latin: "Dar"
      },
      id: "06090417"
    },
    {
      name: {
        km: "ក្ដីតាចិន",
        latin: "Kdei Ta Chen"
      },
      id: "06090418"
    },
    {
      name: {
        km: "វាលអំពិល",
        latin: "Veal Ampil"
      },
      id: "06090419"
    },
    {
      name: {
        km: "ព្រៃវែង",
        latin: "Prey Veaeng"
      },
      id: "06090420"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "06090421"
    },
    {
      name: {
        km: "ប្រិច",
        latin: "Prech"
      },
      id: "06090422"
    },
    {
      name: {
        km: "តាំងគោក",
        latin: "Tang Kouk"
      },
      id: "06090501"
    },
    {
      name: {
        km: "រស្មីរំដោះ",
        latin: "Reaksmei Rumdaoh"
      },
      id: "06090502"
    },
    {
      name: {
        km: "សង្គមមានជ័យ",
        latin: "Sangkom Mean Chey"
      },
      id: "06090503"
    },
    {
      name: {
        km: "តាម៉ី",
        latin: "Ta Mei"
      },
      id: "06090504"
    },
    {
      name: {
        km: "ថ្លា",
        latin: "Thla"
      },
      id: "06090505"
    },
    {
      name: {
        km: "ខ្លុយកើត",
        latin: "Khloy Kaeut"
      },
      id: "06090506"
    },
    {
      name: {
        km: "ខ្លុយលិច",
        latin: "Khloy Lech"
      },
      id: "06090507"
    },
    {
      name: {
        km: "សៀមរាយ",
        latin: "Siem Reay"
      },
      id: "06090508"
    },
    {
      name: {
        km: "សូយោង",
        latin: "Souyoung"
      },
      id: "06090509"
    },
    {
      name: {
        km: "ខ្ញោម",
        latin: "Khnhaom"
      },
      id: "06090510"
    },
    {
      name: {
        km: "កាតាយ",
        latin: "Katay"
      },
      id: "06090511"
    },
    {
      name: {
        km: "សេរីសាមគ្គីខាងត្បូង",
        latin: "Serei Sameakki Khang Tboung"
      },
      id: "06090601"
    },
    {
      name: {
        km: "សេរីសាមគ្គីខាងជើង",
        latin: "Serei Sameakki Khang Cheung"
      },
      id: "06090602"
    },
    {
      name: {
        km: "ដំរីស្លាប់",
        latin: "Damrei Slab"
      },
      id: "06090603"
    },
    {
      name: {
        km: "គគរ",
        latin: "Kokor"
      },
      id: "06090604"
    },
    {
      name: {
        km: "អ្នកវាំង",
        latin: "Neak Veang"
      },
      id: "06090605"
    },
    {
      name: {
        km: "ទួលអំពិល",
        latin: "Tuol Ampil"
      },
      id: "06090606"
    },
    {
      name: {
        km: "សេរីសាមគ្កីកណ្ដាល",
        latin: "Serei Sameakki Kandal"
      },
      id: "06090607"
    },
    {
      name: {
        km: "ស្នួល",
        latin: "Snuol"
      },
      id: "06090608"
    },
    {
      name: {
        km: "គោកអណ្ដែត",
        latin: "Kouk Andaet"
      },
      id: "06090609"
    },
    {
      name: {
        km: "ទួលពភ្លា",
        latin: "Tuol Poplea"
      },
      id: "06090610"
    },
    {
      name: {
        km: "បុស្សល្វា",
        latin: "Bos Lvea"
      },
      id: "06090611"
    },
    {
      name: {
        km: "ទោង",
        latin: "Toung"
      },
      id: "06090612"
    },
    {
      name: {
        km: "ដំណាក់",
        latin: "Damnak"
      },
      id: "06090613"
    },
    {
      name: {
        km: "ឧទុម្ពរ",
        latin: "Otumpor"
      },
      id: "06090614"
    },
    {
      name: {
        km: "គោកត្របែក",
        latin: "Kork Trabaek"
      },
      id: "06090615"
    },
    {
      name: {
        km: "ប្រតោង",
        latin: "Prataong"
      },
      id: "06090701"
    },
    {
      name: {
        km: "ស្វាយភ្លើង",
        latin: "Svay Phleung"
      },
      id: "06090702"
    },
    {
      name: {
        km: "ខ្នាយទង",
        latin: "Khnay Tong"
      },
      id: "06090703"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "06090704"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "06090705"
    },
    {
      name: {
        km: "ប៉ប្រក",
        latin: "Paprak"
      },
      id: "06090706"
    },
    {
      name: {
        km: "ឫស្សីល័រ",
        latin: "Ruessei Lor"
      },
      id: "06090707"
    },
    {
      name: {
        km: "ជីវភាព",
        latin: "Chiveakpheap"
      },
      id: "06090801"
    },
    {
      name: {
        km: "បែកចាន",
        latin: "Baek Chan"
      },
      id: "06090802"
    },
    {
      name: {
        km: "ដំរីស្លាប់",
        latin: "Damrei Slab"
      },
      id: "06090803"
    },
    {
      name: {
        km: "អណ្ដូងពោធិ៍",
        latin: "Andoung Pou"
      },
      id: "06090804"
    },
    {
      name: {
        km: "វាលតូច",
        latin: "Veal Touch"
      },
      id: "06090805"
    },
    {
      name: {
        km: "គុហ៍បារែង",
        latin: "Ku Baraeng"
      },
      id: "06090806"
    },
    {
      name: {
        km: "គ្រើល",
        latin: "Kreul"
      },
      id: "06090807"
    },
    {
      name: {
        km: "ត្រពាំងកំភ្លាញ",
        latin: "Trapeang Kamphleanh"
      },
      id: "07010101"
    },
    {
      name: {
        km: "ត្រពាំងខ្យង",
        latin: "Trapeang Khyang"
      },
      id: "07010102"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "07010103"
    },
    {
      name: {
        km: "ពែងធំ",
        latin: "Peaeng Thum"
      },
      id: "07010104"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "07010105"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "07010201"
    },
    {
      name: {
        km: "ត្រពាំងស្រែ",
        latin: "Trapeang Srae"
      },
      id: "07010202"
    },
    {
      name: {
        km: "អង្គរជ័យលើ",
        latin: "Angkor Chey Leu"
      },
      id: "07010203"
    },
    {
      name: {
        km: "អង្គរជ័យក្រោម",
        latin: "Angkor Chey Kraom"
      },
      id: "07010204"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "07010205"
    },
    {
      name: {
        km: "អង្គរំដួល",
        latin: "Angk Rumduol"
      },
      id: "07010206"
    },
    {
      name: {
        km: "ខ្វាវ",
        latin: "Khvav"
      },
      id: "07010301"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "07010302"
    },
    {
      name: {
        km: "ទន្លេនាម",
        latin: "Tonle Neam"
      },
      id: "07010303"
    },
    {
      name: {
        km: "ណងសាហេតុ",
        latin: "Nang Sa Het"
      },
      id: "07010304"
    },
    {
      name: {
        km: "ចំប៉ី",
        latin: "Champei"
      },
      id: "07010305"
    },
    {
      name: {
        km: "ស្រែជា",
        latin: "Srae Chea"
      },
      id: "07010306"
    },
    {
      name: {
        km: "អង្គជោតិ",
        latin: "Ang Chorti"
      },
      id: "07010307"
    },
    {
      name: {
        km: "ដើមពោធិ៍",
        latin: "Daeum Pou"
      },
      id: "07010308"
    },
    {
      name: {
        km: "ត្រពាំងត្រកៀត",
        latin: "Trapeang Trakiet"
      },
      id: "07010401"
    },
    {
      name: {
        km: "ស្បូវអណ្ដែត",
        latin: "Sbov Andaet"
      },
      id: "07010402"
    },
    {
      name: {
        km: "ចាក់ជ្រុំ",
        latin: "Chak Chrum"
      },
      id: "07010403"
    },
    {
      name: {
        km: "តាកោ",
        latin: "Ta Kao"
      },
      id: "07010404"
    },
    {
      name: {
        km: "ដងទង់",
        latin: "Dang Tong"
      },
      id: "07010405"
    },
    {
      name: {
        km: "ត្រពាំងត្នោត",
        latin: "Trapeang Tnaot"
      },
      id: "07010406"
    },
    {
      name: {
        km: "របោះខ្ទុម",
        latin: "Robaoh Khtum"
      },
      id: "07010407"
    },
    {
      name: {
        km: "ដំបូកខ្ពស់",
        latin: "Dambouk Khpos"
      },
      id: "07010408"
    },
    {
      name: {
        km: "ត្រពាំងកំណប់",
        latin: "Trapeang Kamnab"
      },
      id: "07010409"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "07010410"
    },
    {
      name: {
        km: "អូរម្កាក់",
        latin: "Ou Mkak"
      },
      id: "07010411"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "07010501"
    },
    {
      name: {
        km: "ដានគោមខាងជើង",
        latin: "Dan Koum Khang Cheung"
      },
      id: "07010502"
    },
    {
      name: {
        km: "ដានគោមខាងត្ប្មូង",
        latin: "Dan Koum Khang Tboung"
      },
      id: "07010503"
    },
    {
      name: {
        km: "អង្គខ្ជាយខាងជើង",
        latin: "Angk Khcheay Khang Cheung"
      },
      id: "07010504"
    },
    {
      name: {
        km: "អង្គខ្ជាយខាងត្បូង",
        latin: "Angk Khcheay Khang Tboung"
      },
      id: "07010505"
    },
    {
      name: {
        km: "ព្រៃខ្ជាយ",
        latin: "Prey Khcheay"
      },
      id: "07010506"
    },
    {
      name: {
        km: "ដើមដូង",
        latin: "Daeum Doung"
      },
      id: "07010601"
    },
    {
      name: {
        km: "ច្រាប",
        latin: "Chrab"
      },
      id: "07010602"
    },
    {
      name: {
        km: "អូររំចេក",
        latin: "Ou Rumchek"
      },
      id: "07010603"
    },
    {
      name: {
        km: "សាដំបង",
        latin: "Sa Dambang"
      },
      id: "07010604"
    },
    {
      name: {
        km: "ព្រះឱង្ការ",
        latin: "Preah Aongkar"
      },
      id: "07010701"
    },
    {
      name: {
        km: "ម្រោម",
        latin: "Mroum"
      },
      id: "07010702"
    },
    {
      name: {
        km: "ត្រពាំងផ្នែល",
        latin: "Trapeang Phnael"
      },
      id: "07010703"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "07010704"
    },
    {
      name: {
        km: "គក",
        latin: "Kok"
      },
      id: "07010705"
    },
    {
      name: {
        km: "ភ្នំឆ្មារ",
        latin: "Phnum Chhmar"
      },
      id: "07010706"
    },
    {
      name: {
        km: "ស្រែរោង",
        latin: "Srae Roung"
      },
      id: "07010801"
    },
    {
      name: {
        km: "ស្គរទូង",
        latin: "Skor Tung"
      },
      id: "07010802"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "07010803"
    },
    {
      name: {
        km: "ព្រៃផ្គាំ",
        latin: "Prey Phkoam"
      },
      id: "07010804"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "07010805"
    },
    {
      name: {
        km: "ពេជចង្វា",
        latin: "Pech Changva"
      },
      id: "07010806"
    },
    {
      name: {
        km: "ព្រៃផ្ដៅ",
        latin: "Prey Phdau"
      },
      id: "07010807"
    },
    {
      name: {
        km: "ភ្នំត្រែល",
        latin: "Phnum Trael"
      },
      id: "07010808"
    },
    {
      name: {
        km: "ត្រពាំងខ្វា",
        latin: "Trapeang Khva"
      },
      id: "07010809"
    },
    {
      name: {
        km: "សង្កែបង្វែ",
        latin: "Sangkae Bangvaer"
      },
      id: "07010810"
    },
    {
      name: {
        km: "ប្រភ្នំ",
        latin: "Pra Phnum"
      },
      id: "07010901"
    },
    {
      name: {
        km: "ត្រពាំងថ្ងាន់",
        latin: "Trapeang Thngan"
      },
      id: "07010902"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "07010903"
    },
    {
      name: {
        km: "ព្រៃស្គរ",
        latin: "Prey Skor"
      },
      id: "07010904"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "07010905"
    },
    {
      name: {
        km: "ក្រាំងថ្កូវ",
        latin: "Krang Thkov"
      },
      id: "07010906"
    },
    {
      name: {
        km: "ដំណាក់ក្រសាំង",
        latin: "Damnak Krasang"
      },
      id: "07010907"
    },
    {
      name: {
        km: "ឫស្សីដុំ",
        latin: "Ruessei Dom"
      },
      id: "07010908"
    },
    {
      name: {
        km: "ទួលខ្ពស់",
        latin: "Tuol Khpos"
      },
      id: "07010909"
    },
    {
      name: {
        km: "កសិករ",
        latin: "Kaksekar"
      },
      id: "07011001"
    },
    {
      name: {
        km: "ស្ដុក",
        latin: "Sdok"
      },
      id: "07011002"
    },
    {
      name: {
        km: "កម្មករ",
        latin: "Kammeakkar"
      },
      id: "07011003"
    },
    {
      name: {
        km: "ត្រពាំងស្លា",
        latin: "Trapeang Sla"
      },
      id: "07011004"
    },
    {
      name: {
        km: "ប្រស្រែ",
        latin: "Prasrae"
      },
      id: "07011005"
    },
    {
      name: {
        km: "ស្មោង",
        latin: "Smaong"
      },
      id: "07011006"
    },
    {
      name: {
        km: "ត្របៀត",
        latin: "Trabiet"
      },
      id: "07011007"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "07011101"
    },
    {
      name: {
        km: "តាព្រាម",
        latin: "Ta Pream"
      },
      id: "07011102"
    },
    {
      name: {
        km: "កាន់ទ្រង់",
        latin: "Kan Trong"
      },
      id: "07011103"
    },
    {
      name: {
        km: "តាកុក",
        latin: "Ta Kok"
      },
      id: "07011104"
    },
    {
      name: {
        km: "ឫស្សី",
        latin: "Ruessei"
      },
      id: "07011105"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "07011106"
    },
    {
      name: {
        km: "ប្រាល",
        latin: "Pral"
      },
      id: "07011107"
    },
    {
      name: {
        km: "ពេជ្ជនា",
        latin: "Pechonea"
      },
      id: "07020101"
    },
    {
      name: {
        km: "ក្រសាំងក្រោម",
        latin: "Krasang Kraom"
      },
      id: "07020102"
    },
    {
      name: {
        km: "ក្រសាំងលើ",
        latin: "Krasang Leu"
      },
      id: "07020103"
    },
    {
      name: {
        km: "ត្រពាំងពោន",
        latin: "Trapeang Poun"
      },
      id: "07020104"
    },
    {
      name: {
        km: "ព្រៃបន្ទាំ",
        latin: "Prey Bantoam"
      },
      id: "07020105"
    },
    {
      name: {
        km: "ស្រែថ្លុក",
        latin: "Srae Thlok"
      },
      id: "07020106"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "07020107"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "07020108"
    },
    {
      name: {
        km: "លំទំពូង",
        latin: "Lum Tumpung"
      },
      id: "07020201"
    },
    {
      name: {
        km: "កណ្ដាញ់",
        latin: "Kandanh"
      },
      id: "07020202"
    },
    {
      name: {
        km: "កន្សោមអក",
        latin: "Kansaom Ak"
      },
      id: "07020203"
    },
    {
      name: {
        km: "ទង់លាង",
        latin: "Tong Leang"
      },
      id: "07020204"
    },
    {
      name: {
        km: "ត្បាល់កិន",
        latin: "Tbal Ken"
      },
      id: "07020205"
    },
    {
      name: {
        km: "ព្រៃលើ",
        latin: "Prey Leu"
      },
      id: "07020206"
    },
    {
      name: {
        km: "ហុងស៊ុយ",
        latin: "Hong Suy"
      },
      id: "07020207"
    },
    {
      name: {
        km: "បន្ទាយមាស",
        latin: "Banteay Meas"
      },
      id: "07020208"
    },
    {
      name: {
        km: "ក្រាំងបន្ទាយ",
        latin: "Krang Banteay"
      },
      id: "07020209"
    },
    {
      name: {
        km: "ព្រៃទន្លេ",
        latin: "Prey Tonle"
      },
      id: "07020301"
    },
    {
      name: {
        km: "ទួលក្រសាំង",
        latin: "Tuol Krasang"
      },
      id: "07020302"
    },
    {
      name: {
        km: "ព្រែកឫស្សី",
        latin: "Preaek Ruessei"
      },
      id: "07020303"
    },
    {
      name: {
        km: "សំរោងចិន",
        latin: "Samraong Chen"
      },
      id: "07020304"
    },
    {
      name: {
        km: "ម៉ឺនដាំ",
        latin: "Meun Dam"
      },
      id: "07020305"
    },
    {
      name: {
        km: "ត្រពាំងមន្ដ្រី",
        latin: "Trapeang Montrei"
      },
      id: "07020401"
    },
    {
      name: {
        km: "តាអៀក",
        latin: "Ta Iek"
      },
      id: "07020402"
    },
    {
      name: {
        km: "សែនពន្លួង",
        latin: "Saen Ponlung"
      },
      id: "07020403"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "07020404"
    },
    {
      name: {
        km: "តាទេន",
        latin: "Ta Ten"
      },
      id: "07020405"
    },
    {
      name: {
        km: "ក្រាំងដូង",
        latin: "Krang Doung"
      },
      id: "07020406"
    },
    {
      name: {
        km: "បរិវាស",
        latin: "Bariveas"
      },
      id: "07020501"
    },
    {
      name: {
        km: "ដំណាក់ចំបក់",
        latin: "Damnak Chambak"
      },
      id: "07020502"
    },
    {
      name: {
        km: "ដំណាក់ត្រយឹង",
        latin: "Damnak Trayueng"
      },
      id: "07020503"
    },
    {
      name: {
        km: "ត្រាំសសរ",
        latin: "Tram Sasar"
      },
      id: "07020504"
    },
    {
      name: {
        km: "ប្រផុង",
        latin: "Praphong"
      },
      id: "07020505"
    },
    {
      name: {
        km: "ជ្រុងស្រឡៅ",
        latin: "Chrung Sralau"
      },
      id: "07020601"
    },
    {
      name: {
        km: "កន្លង់",
        latin: "Kanlang"
      },
      id: "07020602"
    },
    {
      name: {
        km: "ត្រែង",
        latin: "Traeng"
      },
      id: "07020603"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "07020604"
    },
    {
      name: {
        km: "ព្រៃពព្រិច",
        latin: "Prey Po Prich"
      },
      id: "07020701"
    },
    {
      name: {
        km: "រំពើន",
        latin: "Rumpeun"
      },
      id: "07020702"
    },
    {
      name: {
        km: "ចម្លងជ្រៃ",
        latin: "Chamlang Chrey"
      },
      id: "07020703"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "07020704"
    },
    {
      name: {
        km: "ដើមចំរៀក",
        latin: "Daeum Chamriek"
      },
      id: "07020705"
    },
    {
      name: {
        km: "សង្កែដួច",
        latin: "Sangkae Duoch"
      },
      id: "07020801"
    },
    {
      name: {
        km: "ឫស្សីជួរ",
        latin: "Ruessei Chuor"
      },
      id: "07020802"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "07020803"
    },
    {
      name: {
        km: "ស្ដេចគង់",
        latin: "Sdach Kong"
      },
      id: "07020804"
    },
    {
      name: {
        km: "តាឡង់",
        latin: "Ta Lang"
      },
      id: "07020901"
    },
    {
      name: {
        km: "គោកវែង",
        latin: "Kouk Veaeng"
      },
      id: "07020902"
    },
    {
      name: {
        km: "ត្នោត",
        latin: "Tnaot"
      },
      id: "07020903"
    },
    {
      name: {
        km: "ដូនទាវ",
        latin: "Doun Teav"
      },
      id: "07020904"
    },
    {
      name: {
        km: "ឫស្សីជុំ",
        latin: "Ruessei Chum"
      },
      id: "07020905"
    },
    {
      name: {
        km: "ខ្នាយ",
        latin: "Khnay"
      },
      id: "07020906"
    },
    {
      name: {
        km: "សូរិយា",
        latin: "Souriya"
      },
      id: "07020907"
    },
    {
      name: {
        km: "អង្គញ់",
        latin: "Angkunh"
      },
      id: "07020908"
    },
    {
      name: {
        km: "ទ័ពស្ដេច",
        latin: "Toap Sdach"
      },
      id: "07021001"
    },
    {
      name: {
        km: "តាងើត",
        latin: "Ta Ngeut"
      },
      id: "07021002"
    },
    {
      name: {
        km: "ពោន",
        latin: "Poun"
      },
      id: "07021003"
    },
    {
      name: {
        km: "លៀប",
        latin: "Lieb"
      },
      id: "07021004"
    },
    {
      name: {
        km: "ខ្យើម",
        latin: "Khyaeum"
      },
      id: "07021005"
    },
    {
      name: {
        km: "ត្រពាំងក្ដុល",
        latin: "Trapeang Kdol"
      },
      id: "07021101"
    },
    {
      name: {
        km: "គាថាវង្សក្រោម",
        latin: "Keatha Vong Kraom"
      },
      id: "07021102"
    },
    {
      name: {
        km: "គាថាវង្សលើ",
        latin: "Keatha Vong Leu"
      },
      id: "07021103"
    },
    {
      name: {
        km: "សំព័រ",
        latin: "Sampoar"
      },
      id: "07021104"
    },
    {
      name: {
        km: "ព្រៃស្រឡៅ",
        latin: "Prey Sralau"
      },
      id: "07021105"
    },
    {
      name: {
        km: "ត្រពាំងព្រីង",
        latin: "Trapeang Pring"
      },
      id: "07021201"
    },
    {
      name: {
        km: "ស្រែកាន់ចិន",
        latin: "Srae Kan Chen"
      },
      id: "07021202"
    },
    {
      name: {
        km: "ច្រកស្ដៅ",
        latin: "Chrak Sdau"
      },
      id: "07021203"
    },
    {
      name: {
        km: "ស្រែកាន់ខាងកើត",
        latin: "Srae Kan Khang Kaeut"
      },
      id: "07021204"
    },
    {
      name: {
        km: "ស្រែកាន់ខាងលិច",
        latin: "Srae Kan Khang Lech"
      },
      id: "07021205"
    },
    {
      name: {
        km: "អង្គម្លី",
        latin: "Angk Mli"
      },
      id: "07021206"
    },
    {
      name: {
        km: "ទូកមាស",
        latin: "Tuk Meas"
      },
      id: "07021301"
    },
    {
      name: {
        km: "កោះទន្សែ",
        latin: "Kaoh Tonsae"
      },
      id: "07021302"
    },
    {
      name: {
        km: "ច្រកឃ្លៃ",
        latin: "Chrak Khley"
      },
      id: "07021303"
    },
    {
      name: {
        km: "ចំលងជ្រៃ",
        latin: "Chamlang Chrey"
      },
      id: "07021304"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "07021305"
    },
    {
      name: {
        km: "ព្រៃចេក",
        latin: "Prey Chek"
      },
      id: "07021306"
    },
    {
      name: {
        km: "ព្រៃក្រឡាខាងកើត",
        latin: "Prey Krala Khang Kaeut"
      },
      id: "07021307"
    },
    {
      name: {
        km: "ព្រៃក្រឡាខាងលិច",
        latin: "Prey Krala Khang Lech"
      },
      id: "07021308"
    },
    {
      name: {
        km: "ស្រែត្រែង",
        latin: "Srae Traeng"
      },
      id: "07021401"
    },
    {
      name: {
        km: "ត្នោតរលើង",
        latin: "Tnaot Roleung"
      },
      id: "07021402"
    },
    {
      name: {
        km: "ពញាអង្គរ",
        latin: "Ponhea Angkor"
      },
      id: "07021403"
    },
    {
      name: {
        km: "ស្វាយផ្អែម",
        latin: "Svay Ph'aem"
      },
      id: "07021404"
    },
    {
      name: {
        km: "សូប៉េង",
        latin: "Sou Peng"
      },
      id: "07021405"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "07021501"
    },
    {
      name: {
        km: "ខ្នាច",
        latin: "Khnach"
      },
      id: "07021502"
    },
    {
      name: {
        km: "ធាយ",
        latin: "Theay"
      },
      id: "07021503"
    },
    {
      name: {
        km: "ទទឹម",
        latin: "Totuem"
      },
      id: "07021504"
    },
    {
      name: {
        km: "ស្រែព្រៃ",
        latin: "Srae Prey"
      },
      id: "07021505"
    },
    {
      name: {
        km: "តាឡង់",
        latin: "Ta Lang"
      },
      id: "07030101"
    },
    {
      name: {
        km: "ព្រៃឈើនៀង",
        latin: "Prey Chheu Nieng"
      },
      id: "07030102"
    },
    {
      name: {
        km: "តាមុំ",
        latin: "Ta Mom"
      },
      id: "07030103"
    },
    {
      name: {
        km: "ព្រៃភ្ជុំ",
        latin: "Prey Phchum"
      },
      id: "07030104"
    },
    {
      name: {
        km: "ត្រពាំងតាសេក",
        latin: "Trapeang Ta Sek"
      },
      id: "07030105"
    },
    {
      name: {
        km: "ចំការមន",
        latin: "Chamkar Morn"
      },
      id: "07030201"
    },
    {
      name: {
        km: "វាលក្រសាំង",
        latin: "Veal Krasang"
      },
      id: "07030203"
    },
    {
      name: {
        km: "ជ័យតាស្វាយ",
        latin: "Chey Ta Svay"
      },
      id: "07030204"
    },
    {
      name: {
        km: "ដំណាក់ត្រយឹង",
        latin: "Damnak Trayueng"
      },
      id: "07030205"
    },
    {
      name: {
        km: "ត្រពាំងបី",
        latin: "Trapeang Bei"
      },
      id: "07030206"
    },
    {
      name: {
        km: "ស្រកានាគ",
        latin: "Sraka Neak"
      },
      id: "07030207"
    },
    {
      name: {
        km: "ខ្ពប",
        latin: "Khpob"
      },
      id: "07030208"
    },
    {
      name: {
        km: "ជ័យសេនា",
        latin: "Chey Sena"
      },
      id: "07030209"
    },
    {
      name: {
        km: "ត្រពាំងក្ដី",
        latin: "Trapeang Kdei"
      },
      id: "07030210"
    },
    {
      name: {
        km: "ត្រពាំងរុន",
        latin: "Trapeang Run"
      },
      id: "07030211"
    },
    {
      name: {
        km: "ភ្នំព្រាល",
        latin: "Phnum Preal"
      },
      id: "07030212"
    },
    {
      name: {
        km: "មនោសុខ",
        latin: "Monou Sokh"
      },
      id: "07030213"
    },
    {
      name: {
        km: "ស្រង៉ែ",
        latin: "Srangae"
      },
      id: "07030301"
    },
    {
      name: {
        km: "ព្រេច",
        latin: "Prech"
      },
      id: "07030302"
    },
    {
      name: {
        km: "ពោធិ៍ដុះ",
        latin: "Pou Doh"
      },
      id: "07030303"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "07030304"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "07030401"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "07030402"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "07030403"
    },
    {
      name: {
        km: "ក្រហូង",
        latin: "Krahung"
      },
      id: "07030404"
    },
    {
      name: {
        km: "ព្រៃឃ្មុំ",
        latin: "Prey Khmum"
      },
      id: "07030501"
    },
    {
      name: {
        km: "ក្រសាំងមានជ័យ",
        latin: "Krasang Mean Chey"
      },
      id: "07030502"
    },
    {
      name: {
        km: "ត្រពាំងកកោះ",
        latin: "Trapeang Kakaoh"
      },
      id: "07030503"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "07030504"
    },
    {
      name: {
        km: "ដូនយ៉យ",
        latin: "Doun Yay"
      },
      id: "07030505"
    },
    {
      name: {
        km: "ជ័រដុំ",
        latin: "Choar Dom"
      },
      id: "07030601"
    },
    {
      name: {
        km: "ត្រពាំងលើក",
        latin: "Trapeang Leuk"
      },
      id: "07030602"
    },
    {
      name: {
        km: "ក្រាំងម្ដេង",
        latin: "Krang Mdeng"
      },
      id: "07030603"
    },
    {
      name: {
        km: "ក្រាំងស្បូវ",
        latin: "Krang Sbov"
      },
      id: "07030604"
    },
    {
      name: {
        km: "មន",
        latin: "Mon"
      },
      id: "07030605"
    },
    {
      name: {
        km: "តូច",
        latin: "Touch"
      },
      id: "07030701"
    },
    {
      name: {
        km: "តានាន់",
        latin: "Ta Noan"
      },
      id: "07030702"
    },
    {
      name: {
        km: "ដំណាក់ទ្រព្យខាងជើង",
        latin: "Damnak Toap Khang Cheung"
      },
      id: "07030703"
    },
    {
      name: {
        km: "ដំណាក់ទ្រព្យខាងត្បូង",
        latin: "Damnak Toap Khang Tboung"
      },
      id: "07030704"
    },
    {
      name: {
        km: "ល្វេ",
        latin: "Lve"
      },
      id: "07030705"
    },
    {
      name: {
        km: "ក្រាំងរលួស",
        latin: "Krang Roluos"
      },
      id: "07030706"
    },
    {
      name: {
        km: "ស្រែជ្រៅ",
        latin: "Srae Chrov"
      },
      id: "07030801"
    },
    {
      name: {
        km: "ទួល",
        latin: "Tuol"
      },
      id: "07030802"
    },
    {
      name: {
        km: "ត្រពាំងគុយ",
        latin: "Trapeang Kuy"
      },
      id: "07030803"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "07030804"
    },
    {
      name: {
        km: "ត្រពាំងគគីរ",
        latin: "Trapeang Kokir"
      },
      id: "07030805"
    },
    {
      name: {
        km: "កោះស្លា",
        latin: "Kaoh Sla"
      },
      id: "07030901"
    },
    {
      name: {
        km: "ដុំផ្ដៅ",
        latin: "Dom Phdau"
      },
      id: "07030902"
    },
    {
      name: {
        km: "ព្រៃពាយ",
        latin: "Prey Peay"
      },
      id: "07030903"
    },
    {
      name: {
        km: "ទួលដូនតី",
        latin: "Tuol Doun Tei"
      },
      id: "07030904"
    },
    {
      name: {
        km: "ស្រែលាវ",
        latin: "Srae Leav"
      },
      id: "07030905"
    },
    {
      name: {
        km: "ត្រពាំងស្ដៅ",
        latin: "Trapeang Sdau"
      },
      id: "07030906"
    },
    {
      name: {
        km: "អង្គស្វាយ",
        latin: "Angk Svay"
      },
      id: "07031001"
    },
    {
      name: {
        km: "តាព្រុំ",
        latin: "Ta Prum"
      },
      id: "07031002"
    },
    {
      name: {
        km: "ទុល",
        latin: "Tul"
      },
      id: "07031003"
    },
    {
      name: {
        km: "ព្រៃត្រាង",
        latin: "Prey Trang"
      },
      id: "07031004"
    },
    {
      name: {
        km: "វាលត្បាល់",
        latin: "Veal Tbal"
      },
      id: "07031005"
    },
    {
      name: {
        km: "នារាយណ៍",
        latin: "Neareay"
      },
      id: "07031101"
    },
    {
      name: {
        km: "ពស់ពង",
        latin: "Pos Pong"
      },
      id: "07031102"
    },
    {
      name: {
        km: "ព្រៃស្បូវ",
        latin: "Prey Sbov"
      },
      id: "07031103"
    },
    {
      name: {
        km: "ខ្នាចរមាស",
        latin: "Khnach Romeas"
      },
      id: "07031104"
    },
    {
      name: {
        km: "សត្វពង",
        latin: "Satv Pong"
      },
      id: "07031201"
    },
    {
      name: {
        km: "ព្រៃបែន",
        latin: "Prey Baen"
      },
      id: "07031202"
    },
    {
      name: {
        km: "ត្រពាំងអណ្ដូង",
        latin: "Trapeang Andoung"
      },
      id: "07031203"
    },
    {
      name: {
        km: "ជ្រៃ",
        latin: "Chrey"
      },
      id: "07031204"
    },
    {
      name: {
        km: "ដំណាក់ឈ្នួល",
        latin: "Damnak Chhnuol"
      },
      id: "07031301"
    },
    {
      name: {
        km: "ត្រពាំងល្បើក",
        latin: "Trapeang Lbaeuk"
      },
      id: "07031302"
    },
    {
      name: {
        km: "ត្រពាំងបី",
        latin: "Trapeang Bei"
      },
      id: "07031303"
    },
    {
      name: {
        km: "កោះឫស្សី",
        latin: "Kaoh Ruessei"
      },
      id: "07031304"
    },
    {
      name: {
        km: "ឈើទាលជ្រុំ",
        latin: "Chheu Teal Chrum"
      },
      id: "07031401"
    },
    {
      name: {
        km: "ត្រមែង",
        latin: "Tramaeng"
      },
      id: "07031402"
    },
    {
      name: {
        km: "ប្រមូល",
        latin: "Pramoul"
      },
      id: "07031403"
    },
    {
      name: {
        km: "អង្គឃ្លៃ",
        latin: "Ang Khley"
      },
      id: "07031404"
    },
    {
      name: {
        km: "ត្រពាំងបឹង",
        latin: "Trapeang Boeng"
      },
      id: "07031405"
    },
    {
      name: {
        km: "តេជោអភិវឌ្ឍន៌",
        latin: "Te Chour Apiwat"
      },
      id: "07031501"
    },
    {
      name: {
        km: "តេជោក្បាលដំរី",
        latin: "Te Chour Kbal Damrey"
      },
      id: "07031502"
    },
    {
      name: {
        km: "តេជោអង្កាញ់",
        latin: "Te Chour Angkanh"
      },
      id: "07031503"
    },
    {
      name: {
        km: "តេជោពង្រក",
        latin: "Te Chour Pongrok"
      },
      id: "07031504"
    },
    {
      name: {
        km: "តេជោជ្រៃបាក់",
        latin: "Te Chour Chreybak"
      },
      id: "07031505"
    },
    {
      name: {
        km: "តេជោអន្លងក្មេងលេង",
        latin: "Te Chour Anlong Kmeng Leng"
      },
      id: "07031506"
    },
    {
      name: {
        km: "តាទែន",
        latin: "Ta Teaen"
      },
      id: "07040101"
    },
    {
      name: {
        km: "ត្រពាំងឈើទាល",
        latin: "Trapeang Chheu Teal"
      },
      id: "07040102"
    },
    {
      name: {
        km: "ធ្មា",
        latin: "Thmea"
      },
      id: "07040103"
    },
    {
      name: {
        km: "ច្រេស",
        latin: "Chres"
      },
      id: "07040104"
    },
    {
      name: {
        km: "ឃ្លៃ",
        latin: "Khley"
      },
      id: "07040201"
    },
    {
      name: {
        km: "មនោណុប",
        latin: "Monou Nob"
      },
      id: "07040202"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "07040203"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "07040204"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "07040205"
    },
    {
      name: {
        km: "ដំរីកូន",
        latin: "Damrei Koun"
      },
      id: "07040206"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "07040207"
    },
    {
      name: {
        km: "ស្នាយអញ្ជិត",
        latin: "Snay Anhchit"
      },
      id: "07040301"
    },
    {
      name: {
        km: "ដូនឪ",
        latin: "Doun Ov"
      },
      id: "07040302"
    },
    {
      name: {
        km: "តាភុល",
        latin: "Ta Phol"
      },
      id: "07040303"
    },
    {
      name: {
        km: "ព្រៃឃ្ជាយ",
        latin: "Prey Khcheay"
      },
      id: "07040304"
    },
    {
      name: {
        km: "ដំណាក់ឈើក្រំ",
        latin: "Damnak Chheu Kram"
      },
      id: "07040305"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "07040401"
    },
    {
      name: {
        km: "ខ្ពបរុន",
        latin: "Khpob Run"
      },
      id: "07040402"
    },
    {
      name: {
        km: "ព្រៃឃ្លៃ",
        latin: "Prey Khley"
      },
      id: "07040403"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "07040404"
    },
    {
      name: {
        km: "ស្រែចែង",
        latin: "Srae Chaeng"
      },
      id: "07040405"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "07040501"
    },
    {
      name: {
        km: "ព្រៃវែង",
        latin: "Prey Veaeng"
      },
      id: "07040502"
    },
    {
      name: {
        km: "ព្រៃខ្មៅ",
        latin: "Prey Khmau"
      },
      id: "07040503"
    },
    {
      name: {
        km: "ព្រៃយ៉ាវ",
        latin: "Prey Yav"
      },
      id: "07040504"
    },
    {
      name: {
        km: "ដំណាក់ឈ្នួល",
        latin: "Damnak Chhnuol"
      },
      id: "07040505"
    },
    {
      name: {
        km: "ត្បែងពក",
        latin: "Tbaeng Pok"
      },
      id: "07040506"
    },
    {
      name: {
        km: "ធ្លកយុល",
        latin: "Thlok Yul"
      },
      id: "07040601"
    },
    {
      name: {
        km: "ស្រែសំរោង",
        latin: "Srae Samraong"
      },
      id: "07040602"
    },
    {
      name: {
        km: "ត្រពាំងប្រី",
        latin: "Trapeang Prei"
      },
      id: "07040603"
    },
    {
      name: {
        km: "រកាថ្មី",
        latin: "Roka Thmei"
      },
      id: "07040604"
    },
    {
      name: {
        km: "ពោធិ៍",
        latin: "Pou"
      },
      id: "07040605"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "07040701"
    },
    {
      name: {
        km: "រវៀង",
        latin: "Rovieng"
      },
      id: "07040702"
    },
    {
      name: {
        km: "តារាជ",
        latin: "Ta Reach"
      },
      id: "07040703"
    },
    {
      name: {
        km: "ត្រពាំងស្គន់",
        latin: "Trapeang Skon"
      },
      id: "07040704"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "07040705"
    },
    {
      name: {
        km: "ត្រពាំងតាមាស",
        latin: "Trapeang Ta Meas"
      },
      id: "07050101"
    },
    {
      name: {
        km: "អង្គ រពាក់",
        latin: "Angk Ropeak"
      },
      id: "07050102"
    },
    {
      name: {
        km: "ឃ្ជាយខាងលិច",
        latin: "Khcheay Khang Lech"
      },
      id: "07050103"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "07050104"
    },
    {
      name: {
        km: "ក្រាំងអំពៅ",
        latin: "Krang Ampov"
      },
      id: "07050105"
    },
    {
      name: {
        km: "ធំថ្មី",
        latin: "Thum Thmei"
      },
      id: "07050201"
    },
    {
      name: {
        km: "ច្រកកែស",
        latin: "Chrak Kaes"
      },
      id: "07050202"
    },
    {
      name: {
        km: "ត្រពាំងម្នាស់",
        latin: "Trapeang Mnoas"
      },
      id: "07050203"
    },
    {
      name: {
        km: "ឃ្ជាយខាងជើង",
        latin: "Khcheay Khang Cheung"
      },
      id: "07050204"
    },
    {
      name: {
        km: "ត្រពាំងវែងខាងកើត",
        latin: "Trapeang Veaeng Khang Kaeut"
      },
      id: "07050205"
    },
    {
      name: {
        km: "ត្រពាំងវែងខាងលិច",
        latin: "Trapeang Veaeng Khang Lech"
      },
      id: "07050206"
    },
    {
      name: {
        km: "ស្រូវលើ",
        latin: "Srov Leu"
      },
      id: "07050207"
    },
    {
      name: {
        km: "ឃ្ជាយខាងត្បូង",
        latin: "Khcheay Khang Tboung"
      },
      id: "07050208"
    },
    {
      name: {
        km: "ព្រៃពក",
        latin: "Prey Pok"
      },
      id: "07050209"
    },
    {
      name: {
        km: "ព្រៃគគីរ",
        latin: "Prey Kokir"
      },
      id: "07050210"
    },
    {
      name: {
        km: "ស្រូវក្រោម",
        latin: "Srov Kraom"
      },
      id: "07050211"
    },
    {
      name: {
        km: "ព្រៃឃ្មុំ",
        latin: "Prey Khmum"
      },
      id: "07050301"
    },
    {
      name: {
        km: "ព្រៃសំណាងលើ",
        latin: "Prey Samnang Leu"
      },
      id: "07050302"
    },
    {
      name: {
        km: "ព្រៃសំណាងក្រោម",
        latin: "Prey Samnang Kraom"
      },
      id: "07050303"
    },
    {
      name: {
        km: "កំរ៉ែងក្រសាំង",
        latin: "Kamraeng Krasang"
      },
      id: "07050304"
    },
    {
      name: {
        km: "ដំណាក់",
        latin: "Damnak"
      },
      id: "07050305"
    },
    {
      name: {
        km: "ដំណាក់ត្រយឹង",
        latin: "Damnak Trayueng"
      },
      id: "07050401"
    },
    {
      name: {
        km: "ឈ្លីតក្រោម",
        latin: "Chhlit Kraom"
      },
      id: "07050402"
    },
    {
      name: {
        km: "ឈ្លីតលើ",
        latin: "Chhlit Leu"
      },
      id: "07050403"
    },
    {
      name: {
        km: "ត្រពាំងអណ្ដូង",
        latin: "Trapeang Andoung"
      },
      id: "07050404"
    },
    {
      name: {
        km: "ព្រៃក្រាំងខាងជើង",
        latin: "Prey Krang Khang Cheung"
      },
      id: "07050501"
    },
    {
      name: {
        km: "ព្រៃក្រាំងខាងត្បូង",
        latin: "Prey Krang Khang Tboung"
      },
      id: "07050502"
    },
    {
      name: {
        km: "ក្រាំងលាវ",
        latin: "Krang Leav"
      },
      id: "07050503"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "07050504"
    },
    {
      name: {
        km: "សុភី",
        latin: "Sophi"
      },
      id: "07050601"
    },
    {
      name: {
        km: "ប្រីពីរ",
        latin: "Prei Pir"
      },
      id: "07050602"
    },
    {
      name: {
        km: "ព្រានទុំ",
        latin: "Prean Tum"
      },
      id: "07050603"
    },
    {
      name: {
        km: "ត្រពាំងច្រនៀង",
        latin: "Trapeang Chranieng"
      },
      id: "07050604"
    },
    {
      name: {
        km: "ត្រពាំងក្ដារ",
        latin: "Trapeang Kdar"
      },
      id: "07050701"
    },
    {
      name: {
        km: "ខ្ពស់",
        latin: "Khpos"
      },
      id: "07050702"
    },
    {
      name: {
        km: "អូរឫស្សី",
        latin: "Ou Ruessei"
      },
      id: "07050703"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "07050801"
    },
    {
      name: {
        km: "ទួលខ្ពស់",
        latin: "Tuol Khpos"
      },
      id: "07050802"
    },
    {
      name: {
        km: "ចង្កៀងខាងកើត",
        latin: "Changkieng Khang Kaeut"
      },
      id: "07050803"
    },
    {
      name: {
        km: "ចង្កៀងខាងលិច",
        latin: "Changkieng Khang Lech"
      },
      id: "07050804"
    },
    {
      name: {
        km: "ស្ដុកធ្លក",
        latin: "Sdok Thlok"
      },
      id: "07050805"
    },
    {
      name: {
        km: "ដំរីលេង",
        latin: "Damrei Leng"
      },
      id: "07050806"
    },
    {
      name: {
        km: "ត្រពាំងនៀល",
        latin: "Trapeang Niel"
      },
      id: "07050807"
    },
    {
      name: {
        km: "អូរកណ្តោល",
        latin: "Ou Kandal"
      },
      id: "07050808"
    },
    {
      name: {
        km: "ឃ្ជាយខាងកើត",
        latin: "Khcheay Khang Kaeut"
      },
      id: "07050809"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "07050901"
    },
    {
      name: {
        km: "អន្ទងបែក",
        latin: "Antong Baek"
      },
      id: "07050902"
    },
    {
      name: {
        km: "ស្នោតូច",
        latin: "Snaor Touch"
      },
      id: "07050903"
    },
    {
      name: {
        km: "ពន្លៃ",
        latin: "Ponley"
      },
      id: "07050904"
    },
    {
      name: {
        km: "បរក្នុង",
        latin: "Bar Knong"
      },
      id: "07050905"
    },
    {
      name: {
        km: "ត្រពាំងសេះ",
        latin: "Trapeang Seh"
      },
      id: "07051001"
    },
    {
      name: {
        km: "បើន",
        latin: "Baeun"
      },
      id: "07051002"
    },
    {
      name: {
        km: "ដំណាក់អំពិល",
        latin: "Damnak Ampil"
      },
      id: "07051003"
    },
    {
      name: {
        km: "ល្អាង",
        latin: "L'ang"
      },
      id: "07051004"
    },
    {
      name: {
        km: "កោះទ្រមូង",
        latin: "Kaoh Tromung"
      },
      id: "07060101"
    },
    {
      name: {
        km: "ដាសស្គរ",
        latin: "Das Skor"
      },
      id: "07060102"
    },
    {
      name: {
        km: "កោះម៉ាក់ប្រាំង",
        latin: "Kaoh Makprang"
      },
      id: "07060103"
    },
    {
      name: {
        km: "ព្រៃទប់",
        latin: "Prey Tob"
      },
      id: "07060104"
    },
    {
      name: {
        km: "កោះចំការ",
        latin: "Kaoh Chamkar"
      },
      id: "07060201"
    },
    {
      name: {
        km: "ជ្រេស",
        latin: "Chres"
      },
      id: "07060202"
    },
    {
      name: {
        km: "ស្វាយផ្អែម",
        latin: "Svay Ph'aem"
      },
      id: "07060203"
    },
    {
      name: {
        km: "អូរស្លែង",
        latin: "Ou Slaeng"
      },
      id: "07060301"
    },
    {
      name: {
        km: "ព្រៃកែស",
        latin: "Prey Kes"
      },
      id: "07060302"
    },
    {
      name: {
        km: "អូរពពូល",
        latin: "Ou Popul"
      },
      id: "07060303"
    },
    {
      name: {
        km: "ភ្នំដំរី",
        latin: "Phnum Damrei"
      },
      id: "07060304"
    },
    {
      name: {
        km: "ដំណាក់កន្ទួត",
        latin: "Damnak Kantuot"
      },
      id: "07060401"
    },
    {
      name: {
        km: "អូររលួស",
        latin: "Ou Roluos"
      },
      id: "07060402"
    },
    {
      name: {
        km: "អង្គរជ័យទី ១",
        latin: "Angkor Chey Ti  Muoy"
      },
      id: "07060403"
    },
    {
      name: {
        km: "អង្គរជ័យទី ២",
        latin: "Angkor Chey  Ti  Pir"
      },
      id: "07060404"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "07060405"
    },
    {
      name: {
        km: "កំពង់ត្រាចទី ១",
        latin: "Kampong Trach Ti  Muoy"
      },
      id: "07060501"
    },
    {
      name: {
        km: "កោះខ្លូត",
        latin: "Kaoh Khlout"
      },
      id: "07060502"
    },
    {
      name: {
        km: "កោះតាចាន់",
        latin: "Kaoh Ta Chan"
      },
      id: "07060503"
    },
    {
      name: {
        km: "របងក្រាស",
        latin: "Robang Kras"
      },
      id: "07060504"
    },
    {
      name: {
        km: "កណ្តាលទួល",
        latin: "Kandal Tuol"
      },
      id: "07060601"
    },
    {
      name: {
        km: "អង្រ្គង",
        latin: "Angkrong"
      },
      id: "07060602"
    },
    {
      name: {
        km: "ភ្នំសាឡី",
        latin: "Phnum Salei"
      },
      id: "07060603"
    },
    {
      name: {
        km: "ដើមចារ",
        latin: "Daeum Char"
      },
      id: "07060604"
    },
    {
      name: {
        km: "កោះផ្ដៅ",
        latin: "Kaoh Phdau"
      },
      id: "07060605"
    },
    {
      name: {
        km: "អូរច្រនៀង",
        latin: "Ou Chranieng"
      },
      id: "07060606"
    },
    {
      name: {
        km: "កំពង់ត្រាចទី ២",
        latin: "Kampong Trach Ti  Pir"
      },
      id: "07060607"
    },
    {
      name: {
        km: "ព្រៃកឹក",
        latin: "Prey Koek"
      },
      id: "07060701"
    },
    {
      name: {
        km: "ភ្នំខ្យង",
        latin: "Phnum Khyang"
      },
      id: "07060702"
    },
    {
      name: {
        km: "ជ្រោះតាសោម",
        latin: "Chruoh Ta Saom"
      },
      id: "07060703"
    },
    {
      name: {
        km: "ច្រនៀងទេ",
        latin: "Chranieng Te"
      },
      id: "07060801"
    },
    {
      name: {
        km: "បង់បក់",
        latin: "Bang Bat"
      },
      id: "07060802"
    },
    {
      name: {
        km: "ភ្នំប្រសាទ",
        latin: "Phnom Prasat"
      },
      id: "07060803"
    },
    {
      name: {
        km: "ឃ្លាំង",
        latin: "Khleang"
      },
      id: "07060804"
    },
    {
      name: {
        km: "ដំណាក់ក្រឡាញ់",
        latin: "Damnak Kralanh"
      },
      id: "07060805"
    },
    {
      name: {
        km: "ស្ពានធំ",
        latin: "Spean Thom"
      },
      id: "07060806"
    },
    {
      name: {
        km: "ចុងសួង",
        latin: "Chong Suong"
      },
      id: "07060807"
    },
    {
      name: {
        km: "ដើមពោធិ៍",
        latin: "Daeum Pou"
      },
      id: "07060901"
    },
    {
      name: {
        km: "អង្គសុរភី",
        latin: "Ang Sophy"
      },
      id: "07060902"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "07060903"
    },
    {
      name: {
        km: "ស្នាមគូរ",
        latin: "Snam Kur"
      },
      id: "07060904"
    },
    {
      name: {
        km: "បឹងធំខាងលិច",
        latin: "Boeng Thum Khang Lech"
      },
      id: "07060905"
    },
    {
      name: {
        km: "បឹងធំខាងកើត",
        latin: "Boeng Thum Khang Kaeut"
      },
      id: "07060906"
    },
    {
      name: {
        km: "ព្រែកក្រឹស",
        latin: "Preaek Kroes"
      },
      id: "07061201"
    },
    {
      name: {
        km: "ព្រៃព្រូស",
        latin: "Prey Pruos"
      },
      id: "07061202"
    },
    {
      name: {
        km: "លក្ខជា",
        latin: "Leak Chea"
      },
      id: "07061203"
    },
    {
      name: {
        km: "ដើមស្នាយ",
        latin: "Daeum Snay"
      },
      id: "07061204"
    },
    {
      name: {
        km: "កោះតាកូវ",
        latin: "Kaoh Ta Kov"
      },
      id: "07061205"
    },
    {
      name: {
        km: "ព្រះទ្រហ៊ឹង",
        latin: "Preah Trohueng"
      },
      id: "07061206"
    },
    {
      name: {
        km: "កោះត្នោត",
        latin: "Kaoh Tnaot"
      },
      id: "07061207"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "07061301"
    },
    {
      name: {
        km: "កោះស្នូក",
        latin: "Kaoh Snouk"
      },
      id: "07061302"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "07061303"
    },
    {
      name: {
        km: "អន្លង់ថ្ងាន់",
        latin: "Anlong Thngan"
      },
      id: "07061304"
    },
    {
      name: {
        km: "ថ្កូវ",
        latin: "Thkov"
      },
      id: "07061401"
    },
    {
      name: {
        km: "លក",
        latin: "Lok"
      },
      id: "07061402"
    },
    {
      name: {
        km: "កោះក្រឹស្នា",
        latin: "Kaoh Kruesna"
      },
      id: "07061403"
    },
    {
      name: {
        km: "ត្រពាំងនៀល",
        latin: "Trapeang Niel"
      },
      id: "07061404"
    },
    {
      name: {
        km: "ដំណាក់ត្របែក",
        latin: "Damnak Trabaek"
      },
      id: "07061405"
    },
    {
      name: {
        km: "កំពូលមាស",
        latin: "Kampul Meas"
      },
      id: "07061406"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "07061501"
    },
    {
      name: {
        km: "ឈើទាលរាំ",
        latin: "Chheu Teal Roam"
      },
      id: "07061502"
    },
    {
      name: {
        km: "បាយទា",
        latin: "Bay Tea"
      },
      id: "07061503"
    },
    {
      name: {
        km: "ភ្នំក្ងាប",
        latin: "Phnum Kngab"
      },
      id: "07061504"
    },
    {
      name: {
        km: "កោះត្រាច",
        latin: "Kaoh Trach"
      },
      id: "07061601"
    },
    {
      name: {
        km: "កោះឈ្វាំង",
        latin: "Kaoh Chhveang"
      },
      id: "07061602"
    },
    {
      name: {
        km: "តាខ្វាយ",
        latin: "Ta Khvay"
      },
      id: "07061603"
    },
    {
      name: {
        km: "ស្លាបតាអោន",
        latin: "Slab Ta Aon"
      },
      id: "07061604"
    },
    {
      name: {
        km: "ស្វាយទង",
        latin: "Svay Tong"
      },
      id: "07061605"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "07070101"
    },
    {
      name: {
        km: "កែបថ្មី",
        latin: "Kaeb Thmei"
      },
      id: "07070102"
    },
    {
      name: {
        km: "ទទឹងថ្ងៃ",
        latin: "Totueng Thngai"
      },
      id: "07070103"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "07070201"
    },
    {
      name: {
        km: "ជុំគ្រៀល",
        latin: "Chum Kriel"
      },
      id: "07070202"
    },
    {
      name: {
        km: "សំរ៉ោង",
        latin: "Samraong"
      },
      id: "07070203"
    },
    {
      name: {
        km: "កំពង់កណ្ដាល",
        latin: "Kampong Kandal"
      },
      id: "07070204"
    },
    {
      name: {
        km: "អណ្ដូងជីម៉ឺន",
        latin: "Andoung Chi Meun"
      },
      id: "07070302"
    },
    {
      name: {
        km: "ព្រៃត្នោត",
        latin: "Prey Tnaot"
      },
      id: "07070303"
    },
    {
      name: {
        km: "កំពង់គ្រែង",
        latin: "Kampong Kraeng"
      },
      id: "07070304"
    },
    {
      name: {
        km: "កំពង់ក្រុង",
        latin: "Kampong Krong"
      },
      id: "07070305"
    },
    {
      name: {
        km: "ម៉ាក់ប្រាង្គ",
        latin: "Makprang"
      },
      id: "07070306"
    },
    {
      name: {
        km: "ត្រពាំងកញ្ឆែត",
        latin: "Trapeang Kanhchhaet"
      },
      id: "07070401"
    },
    {
      name: {
        km: "កំពង់សំរោងខាងជើង",
        latin: "Kampong Samraong Khang Cheung"
      },
      id: "07070402"
    },
    {
      name: {
        km: "កំពង់សំរោងខាងត្បូង",
        latin: "Kampong Samraong Khang Tboung"
      },
      id: "07070403"
    },
    {
      name: {
        km: "ភ្នំតូច",
        latin: "Phnum Touch"
      },
      id: "07070501"
    },
    {
      name: {
        km: "អន្លង់គគី",
        latin: "Anlong Kokir"
      },
      id: "07070502"
    },
    {
      name: {
        km: "ទឹកក្រហម",
        latin: "Tuek Kraham"
      },
      id: "07070503"
    },
    {
      name: {
        km: "មានរិទ្ធ",
        latin: "Mean Ritth"
      },
      id: "07070504"
    },
    {
      name: {
        km: "ដំណាក់ត្រាច",
        latin: "Damnak Trach"
      },
      id: "07070505"
    },
    {
      name: {
        km: "គីឡូ ១២",
        latin: "Kilou Dabpir"
      },
      id: "07070701"
    },
    {
      name: {
        km: "ព្រែកចេក",
        latin: "Preaek Chek"
      },
      id: "07070702"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "07070703"
    },
    {
      name: {
        km: "ព្រែកអំពិល",
        latin: "Preaek Ampil"
      },
      id: "07070704"
    },
    {
      name: {
        km: "បុស្សញិញ",
        latin: "Bos Nhinh"
      },
      id: "07070801"
    },
    {
      name: {
        km: "កូនសត្វ",
        latin: "Koun Satv"
      },
      id: "07070802"
    },
    {
      name: {
        km: "កំពង់នង់",
        latin: "Kampong Nong"
      },
      id: "07070803"
    },
    {
      name: {
        km: "កំពង់ត្នោត",
        latin: "Kampong Tnaot"
      },
      id: "07070804"
    },
    {
      name: {
        km: "ស្នំប្រាំពីរ",
        latin: "Snam Prampir"
      },
      id: "07070901"
    },
    {
      name: {
        km: "បត់ក្បាលដំរី",
        latin: "Bat Kbal Damrei"
      },
      id: "07070902"
    },
    {
      name: {
        km: "មាត់ពាម",
        latin: "Moat Peam"
      },
      id: "07070903"
    },
    {
      name: {
        km: "ត្រពាំងរពៅ",
        latin: "Trapeang Ropov"
      },
      id: "07071101"
    },
    {
      name: {
        km: "ព្រែកក្រែង",
        latin: "Preaek Kraeng"
      },
      id: "07071102"
    },
    {
      name: {
        km: "ព្រែកត្នោត",
        latin: "Preaek Tnaot"
      },
      id: "07071103"
    },
    {
      name: {
        km: "ចង្ហោន",
        latin: "Changhaon"
      },
      id: "07071104"
    },
    {
      name: {
        km: "ព្រៃតុម្ព",
        latin: "Prey Tomh"
      },
      id: "07071201"
    },
    {
      name: {
        km: "បឹងតារោង",
        latin: "Boeng Ta Raung"
      },
      id: "07071202"
    },
    {
      name: {
        km: "ព្រៃឃ្មុំ",
        latin: "Prey Khmum"
      },
      id: "07071203"
    },
    {
      name: {
        km: "វត្ដអង្គ",
        latin: "Voat Angk"
      },
      id: "07071204"
    },
    {
      name: {
        km: "ចក្រីទីង",
        latin: "Chakkrei Ting"
      },
      id: "07071301"
    },
    {
      name: {
        km: "ដំណាក់ហ្លួង",
        latin: "Damnak Luong"
      },
      id: "07071302"
    },
    {
      name: {
        km: "ព្រៃថ្នង",
        latin: "Prey Thnang"
      },
      id: "07071303"
    },
    {
      name: {
        km: "ទ្វារថ្មី",
        latin: "Tvear Thmei"
      },
      id: "07071304"
    },
    {
      name: {
        km: "ច្បារអំពៅ",
        latin: "Chbar Ampov"
      },
      id: "07071305"
    },
    {
      name: {
        km: "កំពង់ចិន",
        latin: "Kampong Chen"
      },
      id: "07071501"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "07071502"
    },
    {
      name: {
        km: "ដូង",
        latin: "Doung"
      },
      id: "07071503"
    },
    {
      name: {
        km: "ម្លិចគល់",
        latin: "Mlich Kol"
      },
      id: "07071504"
    },
    {
      name: {
        km: "អន្លង់ម៉ាក់ប្រាង្គ",
        latin: "Anlong Makprang"
      },
      id: "07071505"
    },
    {
      name: {
        km: "ត្រសេកកោង",
        latin: "Trasek Kaong"
      },
      id: "07071601"
    },
    {
      name: {
        km: "ត្រពាំងច្រាប",
        latin: "Trapeang Chrab"
      },
      id: "07071602"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "07071603"
    },
    {
      name: {
        km: "វត្ដពោធិ៍",
        latin: "Voat Pou"
      },
      id: "07071604"
    },
    {
      name: {
        km: "ដូនស៊យ",
        latin: "Doun Soy"
      },
      id: "07071605"
    },
    {
      name: {
        km: "គោចិនលែង",
        latin: "Kou Chen Laeng"
      },
      id: "07071606"
    },
    {
      name: {
        km: "ត្រពាំងព្រីងខាងជើង",
        latin: "Trapeang Pring Khang Cheung"
      },
      id: "07071701"
    },
    {
      name: {
        km: "ត្រពាំងព្រីងខាងត្បូង",
        latin: "Trapeang Pring Khang Tboung"
      },
      id: "07071702"
    },
    {
      name: {
        km: "បុះត្របែក",
        latin: "Bos Trabaek"
      },
      id: "07071703"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "07071704"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "07071801"
    },
    {
      name: {
        km: "កំពង់កែស",
        latin: "Kampong Kaes"
      },
      id: "07071802"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "07071803"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "07071901"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "07071902"
    },
    {
      name: {
        km: "ត្រពាំងធំ",
        latin: "Trapeang Thum"
      },
      id: "07071903"
    },
    {
      name: {
        km: "ស្វាយធំ",
        latin: "Svay Thum"
      },
      id: "07071904"
    },
    {
      name: {
        km: "សុវណ្ណសាគរ",
        latin: "Sovann Sakor"
      },
      id: "07080101"
    },
    {
      name: {
        km: "ភូមិ១ឧសភា",
        latin: "Phum  Muoy Ousaphea"
      },
      id: "07080102"
    },
    {
      name: {
        km: "ក្រាំង",
        latin: "Krang"
      },
      id: "07080201"
    },
    {
      name: {
        km: "ស្វាយធំ",
        latin: "Svay Thum"
      },
      id: "07080202"
    },
    {
      name: {
        km: "កំពង់បាយខាងជើង",
        latin: "Kampong Bay Khang Cheung"
      },
      id: "07080301"
    },
    {
      name: {
        km: "កំពង់បាយខាងត្បូង",
        latin: "Kampong Bay Khang Tboung"
      },
      id: "07080302"
    },
    {
      name: {
        km: "ទ្វីខាងជើង",
        latin: "Tvi Khang Cheung"
      },
      id: "07080401"
    },
    {
      name: {
        km: "ទ្វីខាងត្បូង",
        latin: "Tvi Khang Tboung"
      },
      id: "07080402"
    },
    {
      name: {
        km: "អូតូច",
        latin: "Ou Touch"
      },
      id: "07080403"
    },
    {
      name: {
        km: "អណ្ដូងខ្មែរ",
        latin: "Andoung Khmer"
      },
      id: "07080404"
    },
    {
      name: {
        km: "តាដិប",
        latin: "Ta Doeb"
      },
      id: "07080405"
    },
    {
      name: {
        km: "ដូនតោក",
        latin: "Doun Kaot"
      },
      id: "07080501"
    },
    {
      name: {
        km: "តាអង្គ",
        latin: "Ta Angk"
      },
      id: "07080502"
    },
    {
      name: {
        km: "បឹងតាព្រាម",
        latin: "Boeng Ta Pream"
      },
      id: "07080503"
    },
    {
      name: {
        km: "ស្រែ",
        latin: "Srae"
      },
      id: "07080504"
    },
    {
      name: {
        km: "អំពៅព្រៃទី១",
        latin: "Ampov Prey Ti Muoy"
      },
      id: "08010101"
    },
    {
      name: {
        km: "អំពៅព្រៃទី២",
        latin: "Ampov Prey Ti Pir"
      },
      id: "08010102"
    },
    {
      name: {
        km: "អំពៅព្រៃទី៣",
        latin: "Ampov Prey Ti Bei"
      },
      id: "08010103"
    },
    {
      name: {
        km: "តាដោលទី១",
        latin: "Ta Daol Ti Muoy"
      },
      id: "08010104"
    },
    {
      name: {
        km: "តាដោលទី២",
        latin: "Ta Daol Ti Pir"
      },
      id: "08010105"
    },
    {
      name: {
        km: "តាដោលទី៣",
        latin: "Ta Daol Ti Bei"
      },
      id: "08010106"
    },
    {
      name: {
        km: "ជើងព្រៃទី១",
        latin: "Cheung Prey Ti Muoy"
      },
      id: "08010107"
    },
    {
      name: {
        km: "ជើងព្រៃទី២",
        latin: "Cheung Prey Ti Pir"
      },
      id: "08010108"
    },
    {
      name: {
        km: "ជើងព្រៃទី៣",
        latin: "Cheung Prey Ti Bei"
      },
      id: "08010109"
    },
    {
      name: {
        km: "អន្លង់រមៀតខាងជើង",
        latin: "Anlong Romiet Khang Cheung"
      },
      id: "08010201"
    },
    {
      name: {
        km: "អន្លង់រមៀតខាងត្បូង",
        latin: "Anlong Romiet Khang Tboung"
      },
      id: "08010202"
    },
    {
      name: {
        km: "អន្លង់រមៀតខាងលិច",
        latin: "Anlong Roniet Khang Lech"
      },
      id: "08010203"
    },
    {
      name: {
        km: "ស្រែគោក",
        latin: "Srae Kouk"
      },
      id: "08010204"
    },
    {
      name: {
        km: "ដើមត្រាំង",
        latin: "Daeum Trang"
      },
      id: "08010205"
    },
    {
      name: {
        km: "កំពង់ទួល",
        latin: "Kampong Tuol"
      },
      id: "08010206"
    },
    {
      name: {
        km: "បារគូ",
        latin: "Barku"
      },
      id: "08010301"
    },
    {
      name: {
        km: "ឃ្មុត",
        latin: "Khmut"
      },
      id: "08010302"
    },
    {
      name: {
        km: "វាលកណ្ដាល",
        latin: "Veal Kandal"
      },
      id: "08010303"
    },
    {
      name: {
        km: "ពោធិ៍ដុះ",
        latin: "Pou Doh"
      },
      id: "08010304"
    },
    {
      name: {
        km: "ត្បូងក្ដី",
        latin: "Tboung Kdei"
      },
      id: "08010305"
    },
    {
      name: {
        km: "ស្វាយមីង",
        latin: "Svay Ming"
      },
      id: "08010306"
    },
    {
      name: {
        km: "អូរអណ្ដូង",
        latin: "Ou Andoung"
      },
      id: "08010307"
    },
    {
      name: {
        km: "បឹងខ្យាង",
        latin: "Boeng Khyang"
      },
      id: "08010401"
    },
    {
      name: {
        km: "ព្រៃតាតូច",
        latin: "Prey Ta Touch"
      },
      id: "08010402"
    },
    {
      name: {
        km: "ស្រុកចេក",
        latin: "Srok Chek"
      },
      id: "08010403"
    },
    {
      name: {
        km: "កំពង់តាឡុង",
        latin: "Kampong Ta Long"
      },
      id: "08010404"
    },
    {
      name: {
        km: "ប្រឡាយ",
        latin: "Pralay"
      },
      id: "08010405"
    },
    {
      name: {
        km: "តាព្រហ្ម",
        latin: "Ta Prum"
      },
      id: "08010406"
    },
    {
      name: {
        km: "ក្រសាំង",
        latin: "Krasang"
      },
      id: "08010501"
    },
    {
      name: {
        km: "ស្រុកធំ",
        latin: "Srok Thum"
      },
      id: "08010502"
    },
    {
      name: {
        km: "អំបឺស",
        latin: "Amboes"
      },
      id: "08010503"
    },
    {
      name: {
        km: "ពោធិ៍ស្មាត",
        latin: "Pou Smat"
      },
      id: "08010504"
    },
    {
      name: {
        km: "ឆ្មាពួន",
        latin: "Chhma Puon"
      },
      id: "08010505"
    },
    {
      name: {
        km: "ប្រជុំអង្គ",
        latin: "Prachum Angk"
      },
      id: "08010506"
    },
    {
      name: {
        km: "តាកុច",
        latin: "Ta Koch"
      },
      id: "08010601"
    },
    {
      name: {
        km: "ស្លែងគង់",
        latin: "Slaeng Kong"
      },
      id: "08010602"
    },
    {
      name: {
        km: "ដើមឫស",
        latin: "Daeum Rues"
      },
      id: "08010603"
    },
    {
      name: {
        km: "ទន្សាយកៀច",
        latin: "Tonsay Kiech"
      },
      id: "08010604"
    },
    {
      name: {
        km: "ក្រាំងចេក",
        latin: "Krang Chek"
      },
      id: "08010605"
    },
    {
      name: {
        km: "រលួស",
        latin: "Roluos"
      },
      id: "08010606"
    },
    {
      name: {
        km: "សាម៉រ",
        latin: "Samar"
      },
      id: "08010607"
    },
    {
      name: {
        km: "អន្លង់ព្រីង",
        latin: "Anlong Pring"
      },
      id: "08010608"
    },
    {
      name: {
        km: "ទែន",
        latin: "Teaen"
      },
      id: "08010609"
    },
    {
      name: {
        km: "ក្រូចសើច",
        latin: "Krouch Saeuch"
      },
      id: "08010610"
    },
    {
      name: {
        km: "បទល្វា",
        latin: "Bat Lvea"
      },
      id: "08010611"
    },
    {
      name: {
        km: "បាង",
        latin: "Bang"
      },
      id: "08010612"
    },
    {
      name: {
        km: "ព្រៃប្រាសាទ",
        latin: "Prey Prasat"
      },
      id: "08010613"
    },
    {
      name: {
        km: "ម្កាក់",
        latin: "Mkak"
      },
      id: "08010614"
    },
    {
      name: {
        km: "កណ្ដោក",
        latin: "Kandaok"
      },
      id: "08010701"
    },
    {
      name: {
        km: "ទឹកនឹម",
        latin: "Tuek Nuem"
      },
      id: "08010702"
    },
    {
      name: {
        km: "ស្វាយព្រៃ",
        latin: "Svay Prey"
      },
      id: "08010703"
    },
    {
      name: {
        km: "ជ្រៃរយោង",
        latin: "Chrey Ro Young"
      },
      id: "08010704"
    },
    {
      name: {
        km: "គោករមៀត",
        latin: "Kouk Romiet"
      },
      id: "08010705"
    },
    {
      name: {
        km: "តោតម៉ា",
        latin: "Taot Ma"
      },
      id: "08010706"
    },
    {
      name: {
        km: "អំពៅព្រៃ",
        latin: "Ampov Prey"
      },
      id: "08010707"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08010801"
    },
    {
      name: {
        km: "ត្រពាំងចក",
        latin: "Trapeang Chak"
      },
      id: "08010802"
    },
    {
      name: {
        km: "ទួលកំរៀង",
        latin: "Tuol Kamrieng"
      },
      id: "08010803"
    },
    {
      name: {
        km: "ក្រាំងតី",
        latin: "Krang Tei"
      },
      id: "08010804"
    },
    {
      name: {
        km: "ទន្លា",
        latin: "Tonlea"
      },
      id: "08010805"
    },
    {
      name: {
        km: "ក្បាលសេះ",
        latin: "Kbal Seh"
      },
      id: "08010901"
    },
    {
      name: {
        km: "ក្រាំងធ្មៃ",
        latin: "Krang Thmey"
      },
      id: "08010902"
    },
    {
      name: {
        km: "ចារ",
        latin: "Char"
      },
      id: "08010903"
    },
    {
      name: {
        km: "គោកព្រីង",
        latin: "Kouk Pring"
      },
      id: "08010904"
    },
    {
      name: {
        km: "គោកត្រប់",
        latin: "Kouk Trab"
      },
      id: "08010905"
    },
    {
      name: {
        km: "លៀក",
        latin: "Liek"
      },
      id: "08010906"
    },
    {
      name: {
        km: "ឈើនាង",
        latin: "Chheu Neang"
      },
      id: "08010907"
    },
    {
      name: {
        km: "ស្វាយកើត",
        latin: "Svay Kaeut"
      },
      id: "08010908"
    },
    {
      name: {
        km: "ស្វាយលិច",
        latin: "Svay Lech"
      },
      id: "08010909"
    },
    {
      name: {
        km: "ក្រាំងទ្រា",
        latin: "Krang Trea"
      },
      id: "08011301"
    },
    {
      name: {
        km: "បិនបោរ",
        latin: "Ben Baor"
      },
      id: "08011302"
    },
    {
      name: {
        km: "ព្រះពុទ្ធ",
        latin: "Preah Putth"
      },
      id: "08011303"
    },
    {
      name: {
        km: "ក្រាំងស្បូវ",
        latin: "Krang Sbov"
      },
      id: "08011304"
    },
    {
      name: {
        km: "បុណ្ណា",
        latin: "Bonna"
      },
      id: "08011305"
    },
    {
      name: {
        km: "ចំបក់ត្រប់",
        latin: "Chambak Trab"
      },
      id: "08011501"
    },
    {
      name: {
        km: "បឹងក្អែក",
        latin: "Boeng K'aek"
      },
      id: "08011502"
    },
    {
      name: {
        km: "កោះខ្នុរ",
        latin: "Kaoh Knor"
      },
      id: "08011503"
    },
    {
      name: {
        km: "ព្រែករកា",
        latin: "Preaek Roka"
      },
      id: "08011504"
    },
    {
      name: {
        km: "ព្រែកស្លែង",
        latin: "Preaek Slaeng"
      },
      id: "08011601"
    },
    {
      name: {
        km: "ពន់ចាន",
        latin: "Pong Chan"
      },
      id: "08011602"
    },
    {
      name: {
        km: "ព្រៃតាថុក",
        latin: "Prey Ta Thok"
      },
      id: "08011603"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "08011604"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "08011701"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "08011702"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "08011703"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "08011704"
    },
    {
      name: {
        km: "រុន",
        latin: "Run"
      },
      id: "08011705"
    },
    {
      name: {
        km: "ក្រូច",
        latin: "Krouch"
      },
      id: "08011706"
    },
    {
      name: {
        km: "ត្រាញ់",
        latin: "Tranh"
      },
      id: "08011707"
    },
    {
      name: {
        km: "ភីរី",
        latin: "Phiri"
      },
      id: "08011801"
    },
    {
      name: {
        km: "ចំការតាង៉ែត",
        latin: "Chamkar Ta Ngaet"
      },
      id: "08011802"
    },
    {
      name: {
        km: "តាប៉េង",
        latin: "Ta Peng"
      },
      id: "08011803"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08011804"
    },
    {
      name: {
        km: "ក្រាំងរលួស",
        latin: "Krang Roluos"
      },
      id: "08011805"
    },
    {
      name: {
        km: "ច្រឡាំង",
        latin: "Chralang"
      },
      id: "08011806"
    },
    {
      name: {
        km: "អន្លង់បារាំង",
        latin: "Anlong Barang"
      },
      id: "08011807"
    },
    {
      name: {
        km: "អង្គ្រង",
        latin: "Angkrong"
      },
      id: "08011808"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "08011809"
    },
    {
      name: {
        km: "ព្រៃកន្ទ្រា",
        latin: "Prey Kantrea"
      },
      id: "08011810"
    },
    {
      name: {
        km: "រលាំងកែន",
        latin: "Roleang Kaen"
      },
      id: "08011811"
    },
    {
      name: {
        km: "សៀមរាប",
        latin: "Siem Reab"
      },
      id: "08012201"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "08012202"
    },
    {
      name: {
        km: "ព្រែកអង្គុញ",
        latin: "Preaek Angkunh"
      },
      id: "08012203"
    },
    {
      name: {
        km: "ជ័យជំនះ",
        latin: "Chey Chumneah"
      },
      id: "08012204"
    },
    {
      name: {
        km: "រានថ្ម",
        latin: "Rean Thma"
      },
      id: "08012205"
    },
    {
      name: {
        km: "រាយដប",
        latin: "Reay Dab"
      },
      id: "08012206"
    },
    {
      name: {
        km: "អង្គក្លើ",
        latin: "Angk Klaeu"
      },
      id: "08012501"
    },
    {
      name: {
        km: "កុកទិល",
        latin: "Kok Til"
      },
      id: "08012502"
    },
    {
      name: {
        km: "ជ្រលង",
        latin: "Chrolong"
      },
      id: "08012503"
    },
    {
      name: {
        km: "ជីមៅ",
        latin: "Chi Mau"
      },
      id: "08012504"
    },
    {
      name: {
        km: "ក្រាំងគាំ",
        latin: "Krang Koam"
      },
      id: "08012505"
    },
    {
      name: {
        km: "ក្រាំងឈើនាង",
        latin: "Krang Chheu Neang"
      },
      id: "08012506"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "08012507"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "08012701"
    },
    {
      name: {
        km: "តំណាក់ត្របែក",
        latin: "Damnak Trabaek"
      },
      id: "08012702"
    },
    {
      name: {
        km: "ត្រពាំងបារគូ",
        latin: "Trapeang Barku"
      },
      id: "08012703"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "08012704"
    },
    {
      name: {
        km: "តាឡឹក",
        latin: "Ta Loek"
      },
      id: "08012705"
    },
    {
      name: {
        km: "ត្រស់",
        latin: "Tras"
      },
      id: "08012801"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "08012802"
    },
    {
      name: {
        km: "រោងគោ",
        latin: "Roung Kou"
      },
      id: "08012803"
    },
    {
      name: {
        km: "មាត់បឹង",
        latin: "Moat Boeng"
      },
      id: "08012804"
    },
    {
      name: {
        km: "កាប់លាវ",
        latin: "Kab Leav"
      },
      id: "08012805"
    },
    {
      name: {
        km: "ដូនវង្ស",
        latin: "Doun Vongs"
      },
      id: "08012806"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "08012807"
    },
    {
      name: {
        km: "ត្រពាំងស្វា",
        latin: "Trapeang Sva"
      },
      id: "08012808"
    },
    {
      name: {
        km: "ដំរីស្លាប់",
        latin: "Damrei Slab"
      },
      id: "08012809"
    },
    {
      name: {
        km: "ខ្សុំ",
        latin: "Khsom"
      },
      id: "08020101"
    },
    {
      name: {
        km: "កណ្ដាលលើ",
        latin: "Kandal Leu"
      },
      id: "08020102"
    },
    {
      name: {
        km: "កណ្ដាលក្រោម",
        latin: "Kandal Kraom"
      },
      id: "08020103"
    },
    {
      name: {
        km: "អង្គរជ័យ",
        latin: "Angkor Chey"
      },
      id: "08020104"
    },
    {
      name: {
        km: "កណ្តាល",
        latin: "Kandal"
      },
      id: "08020105"
    },
    {
      name: {
        km: "ព្រែកប៉ុល",
        latin: "Preaek Pol"
      },
      id: "08020106"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "08020201"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "08020202"
    },
    {
      name: {
        km: "ព្រែកស្វាយ",
        latin: "Preaek Svay"
      },
      id: "08020203"
    },
    {
      name: {
        km: "ស្រែអំពិល",
        latin: "Srae Ampil"
      },
      id: "08020204"
    },
    {
      name: {
        km: "ឫស្សីស្រុក ២",
        latin: "Ruessei Srok 2"
      },
      id: "08020205"
    },
    {
      name: {
        km: "ឈើទាល ២",
        latin: "Chheu Teal 2"
      },
      id: "08020206"
    },
    {
      name: {
        km: "ព្រែកស្វាយ ២",
        latin: "Preaek Svay 2"
      },
      id: "08020207"
    },
    {
      name: {
        km: "ស្រែអំពិល ២",
        latin: "Srae Ampil 2"
      },
      id: "08020208"
    },
    {
      name: {
        km: "ពពាលខែ",
        latin: "Popeal Khae"
      },
      id: "08020301"
    },
    {
      name: {
        km: "ដីឥដ្ឋកោះផុស",
        latin: "Dei Edth Kaoh Phos"
      },
      id: "08020302"
    },
    {
      name: {
        km: "ស្ដៅកន្លែង",
        latin: "Sdau Kanlaeng"
      },
      id: "08020303"
    },
    {
      name: {
        km: "ពពាលខែ ២",
        latin: "Popeal Khae 2"
      },
      id: "08020304"
    },
    {
      name: {
        km: "ដីឥដ្ឋកោះផុស ២",
        latin: "Dei Edth Kaoh Phos 2"
      },
      id: "08020305"
    },
    {
      name: {
        km: "ដីឥដ្ឋកោះផុស ៣",
        latin: "Dei Edth Kaoh Phos 3"
      },
      id: "08020306"
    },
    {
      name: {
        km: "ស្តៅកន្លែង ២",
        latin: "Sdau Kanlaeng 2"
      },
      id: "08020307"
    },
    {
      name: {
        km: "ស្តៅកន្លែង ៣",
        latin: "Sdau Kanlaeng 3"
      },
      id: "08020308"
    },
    {
      name: {
        km: "ស្តៅកន្លែង ៤",
        latin: "Sdau Kanlaeng 4"
      },
      id: "08020309"
    },
    {
      name: {
        km: "ស្តៅកន្លែង ៥",
        latin: "Sdau Kanlaeng 5"
      },
      id: "08020310"
    },
    {
      name: {
        km: "ព្រែកដូង",
        latin: "Preaek Doung"
      },
      id: "08020401"
    },
    {
      name: {
        km: "កំពង់ស្វាយ",
        latin: "Kampong Svay"
      },
      id: "08020402"
    },
    {
      name: {
        km: "ព្រែកតានប់",
        latin: "Preaek Ta Nob"
      },
      id: "08020403"
    },
    {
      name: {
        km: "កំពង់ស្វាយ ២",
        latin: "Kampong Svay 2"
      },
      id: "08020404"
    },
    {
      name: {
        km: "ព្រែកដូង ២",
        latin: "Preaek Doung 2"
      },
      id: "08020405"
    },
    {
      name: {
        km: "ព្រែកតានប់ ២",
        latin: "Preaek Ta Nob 2"
      },
      id: "08020406"
    },
    {
      name: {
        km: "ទួលត្នោត",
        latin: "Tuol Tnaot"
      },
      id: "08020601"
    },
    {
      name: {
        km: "តារាបដូនស",
        latin: "Ta Reab Doun Sa"
      },
      id: "08020602"
    },
    {
      name: {
        km: "ស្លាបតាអោន",
        latin: "Slab Ta Aon"
      },
      id: "08020603"
    },
    {
      name: {
        km: "ចន្លក់",
        latin: "Chanlak"
      },
      id: "08020604"
    },
    {
      name: {
        km: "ទួលក្របៅ",
        latin: "Tuol Krabau"
      },
      id: "08020605"
    },
    {
      name: {
        km: "គគីរ",
        latin: "Kokir"
      },
      id: "08020606"
    },
    {
      name: {
        km: "ដូនស",
        latin: "Doun Sa"
      },
      id: "08020607"
    },
    {
      name: {
        km: "ទួលដូង",
        latin: "Tuol Daung"
      },
      id: "08020608"
    },
    {
      name: {
        km: "កោះបៀ",
        latin: "Kaoh Bie"
      },
      id: "08020609"
    },
    {
      name: {
        km: "ចិនកោះ",
        latin: "Chen Kaoh"
      },
      id: "08020610"
    },
    {
      name: {
        km: "ជ្រោយតាប៉ា",
        latin: "Chrouy Ta Pa"
      },
      id: "08020611"
    },
    {
      name: {
        km: "ចន្លក់ក្រៅ",
        latin: "Chanlak Kraw"
      },
      id: "08020612"
    },
    {
      name: {
        km: "ពោធិ៍មៀវ",
        latin: "Pou Miev"
      },
      id: "08020701"
    },
    {
      name: {
        km: "គគីរធំ",
        latin: "Kokir Thum"
      },
      id: "08020702"
    },
    {
      name: {
        km: "រាំងដេក",
        latin: "Reang Dek"
      },
      id: "08020703"
    },
    {
      name: {
        km: "កោះតេជោ",
        latin: "Kaoh Dechou"
      },
      id: "08020704"
    },
    {
      name: {
        km: "គគីរធំ ២",
        latin: "Kokir Thum 2"
      },
      id: "08020705"
    },
    {
      name: {
        km: "រាំងដេក ២",
        latin: "Reang Dek 2"
      },
      id: "08020706"
    },
    {
      name: {
        km: "កោះតេជោ ២",
        latin: "Kaoh Dechou 2"
      },
      id: "08020707"
    },
    {
      name: {
        km: "កោះប្រាក់",
        latin: "Kaoh Prak"
      },
      id: "08020801"
    },
    {
      name: {
        km: "ភូមិធំ",
        latin: "Phum Thum"
      },
      id: "08020802"
    },
    {
      name: {
        km: "រទាំង",
        latin: "Roteang"
      },
      id: "08020803"
    },
    {
      name: {
        km: "កោះប្រាក់ចាស់",
        latin: "Kaoh Prak Chas"
      },
      id: "08020804"
    },
    {
      name: {
        km: "រទាំងត្បូង",
        latin: "Roteang Tbaung"
      },
      id: "08020805"
    },
    {
      name: {
        km: "ជ័យឧត្ដម",
        latin: "Chey Otdam"
      },
      id: "08021101"
    },
    {
      name: {
        km: "ព្រែកតាកែវ",
        latin: "Preaek Ta Kaev"
      },
      id: "08021102"
    },
    {
      name: {
        km: "ជ្រោយដង",
        latin: "Chrouy Dang"
      },
      id: "08021103"
    },
    {
      name: {
        km: "ស្ទឹង",
        latin: "Stueng"
      },
      id: "08021104"
    },
    {
      name: {
        km: "ព្រែកត្រែង",
        latin: "Preaek Traeng"
      },
      id: "08021105"
    },
    {
      name: {
        km: "សំរោងក្អែរ",
        latin: "Samraong K'aer"
      },
      id: "08021106"
    },
    {
      name: {
        km: "ជ័យឧត្ដម ២",
        latin: "Chey Otdam 2"
      },
      id: "08021107"
    },
    {
      name: {
        km: "ព្រែកតាកែវ ២",
        latin: "Preaek Ta Kaev 2"
      },
      id: "08021108"
    },
    {
      name: {
        km: "ជ្រោយដង ២",
        latin: "Chrouy Dang 2"
      },
      id: "08021109"
    },
    {
      name: {
        km: "ស្ទឹង ២",
        latin: "Stueng 2"
      },
      id: "08021110"
    },
    {
      name: {
        km: "ព្រែកត្រែង ២",
        latin: "Preaek Traeng 2"
      },
      id: "08021111"
    },
    {
      name: {
        km: "សំរោងក្អែរ ២",
        latin: "Samraong K'aer 2"
      },
      id: "08021112"
    },
    {
      name: {
        km: "សំរោងក្អែរ ៣",
        latin: "Samraong K'aer 3"
      },
      id: "08021113"
    },
    {
      name: {
        km: "ជ្រោយខ្សាច់",
        latin: "Chrouy Khsach"
      },
      id: "08030101"
    },
    {
      name: {
        km: "បាក់ដាវលើ",
        latin: "Bak Dav Leu"
      },
      id: "08030102"
    },
    {
      name: {
        km: "បាក់ដាវក្រោម",
        latin: "Bak Dav Kraom"
      },
      id: "08030103"
    },
    {
      name: {
        km: "ព្រែកជ្រូក",
        latin: "Preaek Chruk"
      },
      id: "08030104"
    },
    {
      name: {
        km: "ជ័យធំ",
        latin: "Chey Thum"
      },
      id: "08030201"
    },
    {
      name: {
        km: "ជ័យតូច",
        latin: "Chey Touch"
      },
      id: "08030202"
    },
    {
      name: {
        km: "ព្រែកថ្មី",
        latin: "Preaek Thmei"
      },
      id: "08030203"
    },
    {
      name: {
        km: "ជ្រៃលាស់",
        latin: "Chrey Loas"
      },
      id: "08030204"
    },
    {
      name: {
        km: "តាគាត់លិច",
        latin: "Ta Koat Lech"
      },
      id: "08030205"
    },
    {
      name: {
        km: "តាគាត់កើត",
        latin: "Ta Koat Kaeut"
      },
      id: "08030206"
    },
    {
      name: {
        km: "កំពង់ចំលង",
        latin: "Kampong Chamlang"
      },
      id: "08030301"
    },
    {
      name: {
        km: "ត្បូងដំរី",
        latin: "Tboung Damrei"
      },
      id: "08030302"
    },
    {
      name: {
        km: "ព្រែកដំបង",
        latin: "Preaek Dambang"
      },
      id: "08030303"
    },
    {
      name: {
        km: "ត្បូង",
        latin: "Tboung"
      },
      id: "08030401"
    },
    {
      name: {
        km: "ក្រោម",
        latin: "Kraom"
      },
      id: "08030402"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "08030403"
    },
    {
      name: {
        km: "លើ",
        latin: "Leu"
      },
      id: "08030404"
    },
    {
      name: {
        km: "កោះតូច",
        latin: "Kaoh Touch"
      },
      id: "08030501"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "08030502"
    },
    {
      name: {
        km: "កណ្ដាលកោះ",
        latin: "Kandal Kaoh"
      },
      id: "08030503"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "08030504"
    },
    {
      name: {
        km: "ខ្ពប",
        latin: "Khpob"
      },
      id: "08030505"
    },
    {
      name: {
        km: "ព្រះប្រសប់",
        latin: "Preah Prasab"
      },
      id: "08030601"
    },
    {
      name: {
        km: "ព្រែកតាបែន",
        latin: "Preaek Ta Baen"
      },
      id: "08030602"
    },
    {
      name: {
        km: "ព្រែកតាទន់",
        latin: "Preaek Ta Ton"
      },
      id: "08030603"
    },
    {
      name: {
        km: "ទេពមន្ដ្រី",
        latin: "Tep Montrei"
      },
      id: "08030604"
    },
    {
      name: {
        km: "ឃ្លាំងមឿងជើង",
        latin: "Khleang Moeang Cheung"
      },
      id: "08030701"
    },
    {
      name: {
        km: "ឃ្លាំងមឿងត្បូង",
        latin: "Khleang Moeang Tboung"
      },
      id: "08030702"
    },
    {
      name: {
        km: "ព្រែកក្របៅ ទី១",
        latin: "Preaek Krabau Ti Muoy"
      },
      id: "08030703"
    },
    {
      name: {
        km: "ព្រែកក្របៅ ទី២",
        latin: "Preaek Krabau Ti Pir"
      },
      id: "08030704"
    },
    {
      name: {
        km: "ព្រែកក្របៅ ទី៣",
        latin: "Preaek Krabau Ti Bei"
      },
      id: "08030705"
    },
    {
      name: {
        km: "ព្រែកដូនហែម",
        latin: "Preaek Doun Haem"
      },
      id: "08030706"
    },
    {
      name: {
        km: "ព្រែកអំពិល",
        latin: "Preaek Ampil"
      },
      id: "08030707"
    },
    {
      name: {
        km: "ក្ដីចាស់",
        latin: "Kdei Chas"
      },
      id: "08030801"
    },
    {
      name: {
        km: "ព្រែកតាទេព",
        latin: "Preaek Ta Tep"
      },
      id: "08030802"
    },
    {
      name: {
        km: "ព្រែកលួង",
        latin: "Preaek Luong"
      },
      id: "08030803"
    },
    {
      name: {
        km: "ព្រែកថោង",
        latin: "Preaek Thaong"
      },
      id: "08030804"
    },
    {
      name: {
        km: "ព្រែកតាកូវ",
        latin: "Preaek Ta Kov"
      },
      id: "08030901"
    },
    {
      name: {
        km: "ព្រែកល្វា",
        latin: "Preaek Lvea"
      },
      id: "08030902"
    },
    {
      name: {
        km: "ព្រែកបង្កង",
        latin: "Preak Bangkang"
      },
      id: "08030903"
    },
    {
      name: {
        km: "ព្រែកតាគង់",
        latin: "Preaek Ta Kong"
      },
      id: "08031001"
    },
    {
      name: {
        km: "កំពង់ដំរី",
        latin: "Kampong Damrei"
      },
      id: "08031002"
    },
    {
      name: {
        km: "ក្នុង",
        latin: "Knong"
      },
      id: "08031003"
    },
    {
      name: {
        km: "ព្រែកតាមាក់",
        latin: "Preaek Ta Meak"
      },
      id: "08031004"
    },
    {
      name: {
        km: "ស្វាយអាត់លើ",
        latin: "Svay At Leu"
      },
      id: "08031005"
    },
    {
      name: {
        km: "ស្វាយអាត់កណ្ដាល",
        latin: "Svay At Kandal"
      },
      id: "08031006"
    },
    {
      name: {
        km: "ស្វាយអាត់ក្រោម",
        latin: "Svay At Kraom"
      },
      id: "08031007"
    },
    {
      name: {
        km: "បឹងក្រចាប់ជើង",
        latin: "Boeng Krachab Cheung"
      },
      id: "08031008"
    },
    {
      name: {
        km: "បឹងក្រចាប់ត្បូង",
        latin: "Boeng Krachab Tboung"
      },
      id: "08031009"
    },
    {
      name: {
        km: "អន្លង់",
        latin: "Anlong"
      },
      id: "08031010"
    },
    {
      name: {
        km: "ក្រូចសើច",
        latin: "Krouch Saeuch"
      },
      id: "08031101"
    },
    {
      name: {
        km: "អញ្ជែងលើ",
        latin: "Anhcheaeng Leu"
      },
      id: "08031102"
    },
    {
      name: {
        km: "អញ្ជែងក្រោម",
        latin: "Anhcheaeng Kraom"
      },
      id: "08031103"
    },
    {
      name: {
        km: "ពុកឫស្សីលើ",
        latin: "Puk Ruessei Leu"
      },
      id: "08031104"
    },
    {
      name: {
        km: "ពុកឫស្សីកណ្ដាល",
        latin: "Puk Ruessei Kandal"
      },
      id: "08031105"
    },
    {
      name: {
        km: "ពុកឫស្សីក្រោម",
        latin: "Puk Ruessei Kraom"
      },
      id: "08031106"
    },
    {
      name: {
        km: "ជន្លឹង",
        latin: "Chonlueng"
      },
      id: "08031201"
    },
    {
      name: {
        km: "រកា ទី២",
        latin: "Roka Ti Pir"
      },
      id: "08031202"
    },
    {
      name: {
        km: "តាំងឫស្សី",
        latin: "Tang Ruessei"
      },
      id: "08031203"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "08031204"
    },
    {
      name: {
        km: "រកា ទី១",
        latin: "Roka Ti Muoy"
      },
      id: "08031205"
    },
    {
      name: {
        km: "សន្លុង",
        latin: "Sanlung"
      },
      id: "08031301"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "08031302"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08031303"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "08031304"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "08031305"
    },
    {
      name: {
        km: "ដុល",
        latin: "Dol"
      },
      id: "08031306"
    },
    {
      name: {
        km: "ព្រៃបាំង",
        latin: "Prey Bang"
      },
      id: "08031401"
    },
    {
      name: {
        km: "ស៊ីធរលិច",
        latin: "Sithor Lech"
      },
      id: "08031402"
    },
    {
      name: {
        km: "ស៊ីធរកើត",
        latin: "Sithor Kaeut"
      },
      id: "08031403"
    },
    {
      name: {
        km: "ទួលពង្រ",
        latin: "Tuol Pongro"
      },
      id: "08031404"
    },
    {
      name: {
        km: "ម៉ែបាន",
        latin: "Mae Ban"
      },
      id: "08031405"
    },
    {
      name: {
        km: "កំពង់ល្វា",
        latin: "Kampong Lvea"
      },
      id: "08031406"
    },
    {
      name: {
        km: "លើ",
        latin: "Leu"
      },
      id: "08031501"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "08031502"
    },
    {
      name: {
        km: "បារាជ",
        latin: "Ba Reach"
      },
      id: "08031503"
    },
    {
      name: {
        km: "ព្រែកតាបែន",
        latin: "Preaek Ta Baen"
      },
      id: "08031601"
    },
    {
      name: {
        km: "ស្លា",
        latin: "Sla"
      },
      id: "08031602"
    },
    {
      name: {
        km: "ស្វាយរមៀត",
        latin: "Svay Romiet"
      },
      id: "08031603"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "08031604"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "08031605"
    },
    {
      name: {
        km: "ស្វាយដំណាក់",
        latin: "Svay Damnak"
      },
      id: "08031606"
    },
    {
      name: {
        km: "តាឯក",
        latin: "Ta Aek"
      },
      id: "08031701"
    },
    {
      name: {
        km: "ទ្រាំងក្រោម",
        latin: "Treang Kraom"
      },
      id: "08031702"
    },
    {
      name: {
        km: "ទ្រាំងលើ",
        latin: "Treang Leu"
      },
      id: "08031703"
    },
    {
      name: {
        km: "ព្រែកចាស់",
        latin: "Preaek Chas"
      },
      id: "08031801"
    },
    {
      name: {
        km: "សេដា",
        latin: "Seda"
      },
      id: "08031802"
    },
    {
      name: {
        km: "វិហារសួគ៌ជើង",
        latin: "Vihear Suork Cheung"
      },
      id: "08031803"
    },
    {
      name: {
        km: "វិហារសួគ៌ត្បូង",
        latin: "Vihear Suork Tboung"
      },
      id: "08031804"
    },
    {
      name: {
        km: "ព្រៃធំ",
        latin: "Prey Thum"
      },
      id: "08031805"
    },
    {
      name: {
        km: "តាកែវទី២",
        latin: "Ta Kaev Ti Pir"
      },
      id: "08031806"
    },
    {
      name: {
        km: "តាកែវទី១",
        latin: "Ta Kaev Ti Muoy"
      },
      id: "08031807"
    },
    {
      name: {
        km: "ស្វាយមាស",
        latin: "Svay Meas"
      },
      id: "08031808"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "08040101"
    },
    {
      name: {
        km: "ឈើខ្មៅ",
        latin: "Chheu Khmau"
      },
      id: "08040102"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "08040103"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៃ",
        latin: "Trapeang Chrey"
      },
      id: "08040104"
    },
    {
      name: {
        km: "ត្រើយកោះ",
        latin: "Traeuy Kaoh"
      },
      id: "08040105"
    },
    {
      name: {
        km: "ក្បាលជ្រោយ",
        latin: "Kbal Chrouy"
      },
      id: "08040106"
    },
    {
      name: {
        km: "កោះតូច",
        latin: "Kaoh Touch"
      },
      id: "08040107"
    },
    {
      name: {
        km: "ចុងខ្សាច់",
        latin: "Chong Khsach"
      },
      id: "08040108"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thom"
      },
      id: "08040109"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08040110"
    },
    {
      name: {
        km: "ទួលស្វាយ",
        latin: "Tuol Svay"
      },
      id: "08040111"
    },
    {
      name: {
        km: "ព្រែកថ្មី",
        latin: "Preaek Thmei"
      },
      id: "08040201"
    },
    {
      name: {
        km: "ព្រែកត្រឡាច",
        latin: "Preaek Tralach"
      },
      id: "08040202"
    },
    {
      name: {
        km: "កំពង់ដ",
        latin: "Kampong Dar"
      },
      id: "08040203"
    },
    {
      name: {
        km: "ជ្រោយតាកែវ",
        latin: "Chrouy Ta Kaev"
      },
      id: "08040204"
    },
    {
      name: {
        km: "ព្រែកតាឃិន",
        latin: "Preaek Ta Khin"
      },
      id: "08040205"
    },
    {
      name: {
        km: "ព្រែកតាទៀង",
        latin: "Preaek Ta Tieng"
      },
      id: "08040206"
    },
    {
      name: {
        km: "ព្រែកតាសេក",
        latin: "Preaek Ta Sek"
      },
      id: "08040207"
    },
    {
      name: {
        km: "ដើមពោធិ៍",
        latin: "Daeum Pou"
      },
      id: "08040208"
    },
    {
      name: {
        km: "ព្រែកភូមិ",
        latin: "Preaek Phum"
      },
      id: "08040209"
    },
    {
      name: {
        km: "ក្បាលដំរីលើ",
        latin: "Kbal Damrei Leu"
      },
      id: "08040301"
    },
    {
      name: {
        km: "ក្បាលដំរីក្រោម",
        latin: "Kbal Damrei Kraom"
      },
      id: "08040302"
    },
    {
      name: {
        km: "ព្រែកផ្អាវ",
        latin: "Preaek Ph'av"
      },
      id: "08040303"
    },
    {
      name: {
        km: "កំពង់កុង",
        latin: "Kampong Kong"
      },
      id: "08040304"
    },
    {
      name: {
        km: "ជ្រុងរមាស",
        latin: "Chrung Meas"
      },
      id: "08040305"
    },
    {
      name: {
        km: "ព្រែកហង់",
        latin: "Preaek Hang"
      },
      id: "08040306"
    },
    {
      name: {
        km: "ទួលសង្កែ",
        latin: "Tuol Sangkae"
      },
      id: "08040307"
    },
    {
      name: {
        km: "ត្របែកពក",
        latin: "Trabaek Pok"
      },
      id: "08040308"
    },
    {
      name: {
        km: "ព្រែកឫស្សី",
        latin: "Preaek Ruessei"
      },
      id: "08040309"
    },
    {
      name: {
        km: "ល្វាទោង",
        latin: "Lvea Toung"
      },
      id: "08040310"
    },
    {
      name: {
        km: "ទួលដូនគាំ",
        latin: "Tuol Doun Koam"
      },
      id: "08040311"
    },
    {
      name: {
        km: "ទួលស្វាយ",
        latin: "Tuol Svay"
      },
      id: "08040312"
    },
    {
      name: {
        km: "ក្បាលកោះធំ",
        latin: "Kbal Kaoh Thum"
      },
      id: "08040401"
    },
    {
      name: {
        km: "កណ្ដាលកោះធំ",
        latin: "Kandal Kaoh Thum"
      },
      id: "08040402"
    },
    {
      name: {
        km: "ចុងកោះធំ",
        latin: "Chong Kaoh Thum"
      },
      id: "08040403"
    },
    {
      name: {
        km: "ពោធិ៍ទន្លេ",
        latin: "Pou Tonle"
      },
      id: "08040404"
    },
    {
      name: {
        km: "ក្បាលកោះថ្មី",
        latin: "Kbal Kaoh Thmei"
      },
      id: "08040405"
    },
    {
      name: {
        km: "ចុងកោះថ្មី",
        latin: "Chong Kaoh Thmei"
      },
      id: "08040406"
    },
    {
      name: {
        km: "ទួលកន្ទួត",
        latin: "Tuol Kantuot"
      },
      id: "08040407"
    },
    {
      name: {
        km: "សំប៉ាន",
        latin: "Sampan"
      },
      id: "08040501"
    },
    {
      name: {
        km: "ព្រែកតាកេរ",
        latin: "Preaek Ta Ker"
      },
      id: "08040502"
    },
    {
      name: {
        km: "ព្រែកសំរោង",
        latin: "Preaek Samraong"
      },
      id: "08040503"
    },
    {
      name: {
        km: "ព្រែកបិ",
        latin: "Preaek Be"
      },
      id: "08040504"
    },
    {
      name: {
        km: "ស្វាយតាមេឃ",
        latin: "Svay Ta Mekh"
      },
      id: "08040505"
    },
    {
      name: {
        km: "ចំការដូង",
        latin: "Chamkar Doung"
      },
      id: "08040701"
    },
    {
      name: {
        km: "លើកដែក",
        latin: "Leuk Daek"
      },
      id: "08040702"
    },
    {
      name: {
        km: "ព្រែកអណ្ដូង",
        latin: "Preaek Andoung"
      },
      id: "08040703"
    },
    {
      name: {
        km: "ពោធិមិត្ដ",
        latin: "Pouthi Mitt"
      },
      id: "08040704"
    },
    {
      name: {
        km: "អន្លង់ស្លាត",
        latin: "Anlong Slat"
      },
      id: "08040705"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "08040706"
    },
    {
      name: {
        km: "ទួលស្លែង",
        latin: "Tuol Slaeng"
      },
      id: "08040707"
    },
    {
      name: {
        km: "ពាមផ្ទោលកើត",
        latin: "Peam Phtoul Kaeut"
      },
      id: "08040708"
    },
    {
      name: {
        km: "ពាមផ្ទោលលិច",
        latin: "Peam Phtoul Lech"
      },
      id: "08040709"
    },
    {
      name: {
        km: "ឃ្លាំងកើត",
        latin: "Khleang Kaeut"
      },
      id: "08040710"
    },
    {
      name: {
        km: "ឃ្លាំងលិច",
        latin: "Khleang Lech"
      },
      id: "08040711"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08040712"
    },
    {
      name: {
        km: "ទួលចាន់",
        latin: "Tuol Chan"
      },
      id: "08040713"
    },
    {
      name: {
        km: "ត្រឡោកបែក",
        latin: "Tra Laok Baek"
      },
      id: "08040714"
    },
    {
      name: {
        km: "ព្រែកខ្នាច",
        latin: "Preaek Khnach"
      },
      id: "08040715"
    },
    {
      name: {
        km: "ព្រែកអញ្ចាញ",
        latin: "Preaek Anhchanh"
      },
      id: "08040801"
    },
    {
      name: {
        km: "ខ្វែងអណ្ដូង",
        latin: "Khvaeng Andoung"
      },
      id: "08040802"
    },
    {
      name: {
        km: "ក្បាលជ្រោយ",
        latin: "Kbal Chrouy"
      },
      id: "08040803"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08040804"
    },
    {
      name: {
        km: "ព្រែកតាអ៊ិន",
        latin: "Preaek Ta In"
      },
      id: "08040805"
    },
    {
      name: {
        km: "កំពង់គរ",
        latin: "Kampong Kor"
      },
      id: "08040806"
    },
    {
      name: {
        km: "ព្រែកតាដុល",
        latin: "Preaek Ta Dol"
      },
      id: "08040807"
    },
    {
      name: {
        km: "ព្រែកតារ័ត្ន",
        latin: "Preaek Ta Roatn"
      },
      id: "08040808"
    },
    {
      name: {
        km: "ពោធិបាន",
        latin: "Pouthi Ban"
      },
      id: "08040809"
    },
    {
      name: {
        km: "ជ្រោយស្នោ",
        latin: "Chrouy Snao"
      },
      id: "08040901"
    },
    {
      name: {
        km: "ព្រែកជ្រៃ",
        latin: "Preaek Chrey"
      },
      id: "08040902"
    },
    {
      name: {
        km: "ប៉ាកណាម",
        latin: "Pak Nam"
      },
      id: "08040903"
    },
    {
      name: {
        km: "ខ្នារតាំងយូ",
        latin: "Khnar Tangyu"
      },
      id: "08040904"
    },
    {
      name: {
        km: "ព្រែកជ្រៃក្រៅ",
        latin: "Preaek Chrey Kraw"
      },
      id: "08040905"
    },
    {
      name: {
        km: "ខ្នាតាំងយូរត្បូង",
        latin: "Khnar Tangyu Tboung"
      },
      id: "08040906"
    },
    {
      name: {
        km: "ព្រែកទន្លា",
        latin: "Preaek Tunlea"
      },
      id: "08040907"
    },
    {
      name: {
        km: "ព្រែកមេស្រុក",
        latin: "Preaek Me Srok"
      },
      id: "08041001"
    },
    {
      name: {
        km: "ព្រែកលោក",
        latin: "Preaek Louk"
      },
      id: "08041002"
    },
    {
      name: {
        km: "ព្រែកប៉ុក",
        latin: "Preaek Pok"
      },
      id: "08041003"
    },
    {
      name: {
        km: "អន្លង់សាន្ដ",
        latin: "Anlong Sant"
      },
      id: "08041004"
    },
    {
      name: {
        km: "ចុងព្រែក",
        latin: "Chong Preaek"
      },
      id: "08041005"
    },
    {
      name: {
        km: "ព្រែកតាមេម",
        latin: "Preaek Ta Mem"
      },
      id: "08041006"
    },
    {
      name: {
        km: "កោះចាស់",
        latin: "Kaoh Chas"
      },
      id: "08041007"
    },
    {
      name: {
        km: "ពោធិ៍រាម្មា",
        latin: "Pouthi Reamea"
      },
      id: "08041008"
    },
    {
      name: {
        km: "ប្រធាតុ",
        latin: "Pratheat"
      },
      id: "08041009"
    },
    {
      name: {
        km: "ព្រែកស្តី",
        latin: "Preaek Sdei"
      },
      id: "08041010"
    },
    {
      name: {
        km: "ចាស់",
        latin: "Chas"
      },
      id: "08041011"
    },
    {
      name: {
        km: "ឡក្បឿង",
        latin: "La Kbueng"
      },
      id: "08041012"
    },
    {
      name: {
        km: "ពោធិ៍រាម្មា ក",
        latin: "Pouthi Reamea Ka"
      },
      id: "08041013"
    },
    {
      name: {
        km: "ពោធិ៍អណ្តែត",
        latin: "Pouthi Andaet"
      },
      id: "08041014"
    },
    {
      name: {
        km: "ប្រធាតុ ក",
        latin: "Pratheat Ka"
      },
      id: "08041015"
    },
    {
      name: {
        km: "ព្រែកតាដួង",
        latin: "Preaek Ta Duong"
      },
      id: "08041101"
    },
    {
      name: {
        km: "ព្រែកយាយហាយ",
        latin: "Preaek Yeay Hay"
      },
      id: "08041102"
    },
    {
      name: {
        km: "កំពង់សំបួរលើ",
        latin: "Kampong Sambuor Leu"
      },
      id: "08041103"
    },
    {
      name: {
        km: "កំពង់សំបួរក្រោម",
        latin: "Kampong Sambuor Kraom"
      },
      id: "08041104"
    },
    {
      name: {
        km: "កំពង់ស្វាយលើ",
        latin: "Kampong Svay Leu"
      },
      id: "08041105"
    },
    {
      name: {
        km: "កំពង់ស្វាយកណ្ដាល",
        latin: "Kampong Svay Kandal"
      },
      id: "08041106"
    },
    {
      name: {
        km: "កំពង់ស្វាយក្រោម",
        latin: "Kampong Svay Kraom"
      },
      id: "08041107"
    },
    {
      name: {
        km: "ព្រែកថ្មី",
        latin: "Preaek Thmei"
      },
      id: "08041108"
    },
    {
      name: {
        km: "ចាមលើ",
        latin: "Cham Leu"
      },
      id: "08041109"
    },
    {
      name: {
        km: "ព្រែកតាហ៊ីង",
        latin: "Preaek Ta Hing"
      },
      id: "08041110"
    },
    {
      name: {
        km: "ព្រេកធន់",
        latin: "Preaek Thon"
      },
      id: "08041111"
    },
    {
      name: {
        km: "ចាមក្រោម",
        latin: "Cham Kraom"
      },
      id: "08041112"
    },
    {
      name: {
        km: "ក្បាលកោះទៀវ",
        latin: "Kbal Koh Teav"
      },
      id: "08041201"
    },
    {
      name: {
        km: "កោះទៀវ ក",
        latin: "Koh h Teav kor"
      },
      id: "08041202"
    },
    {
      name: {
        km: "កោះទៀវ ខ",
        latin: "Koh Teav Khor"
      },
      id: "08041203"
    },
    {
      name: {
        km: "ខ្ពប",
        latin: "Khpop"
      },
      id: "08041204"
    },
    {
      name: {
        km: "កំពង់ថ្កុល",
        latin: "Kampong Thkol"
      },
      id: "08041205"
    },
    {
      name: {
        km: "កប៉ាល់គឿង",
        latin: "Kapal Koeung"
      },
      id: "08041206"
    },
    {
      name: {
        km: "ព្រែកស៊ឹង",
        latin: "Prek Sung"
      },
      id: "08041207"
    },
    {
      name: {
        km: "ជ្រៃធំ",
        latin: "Chrey Thom"
      },
      id: "08041208"
    },
    {
      name: {
        km: "ព្រែកស្បូវ",
        latin: "Prek Sbov"
      },
      id: "08041209"
    },
    {
      name: {
        km: "ព្រែកគង់",
        latin: "Preaek Kong"
      },
      id: "08041210"
    },
    {
      name: {
        km: "ព្រែកសែម",
        latin: "Preaek Saem"
      },
      id: "08041211"
    },
    {
      name: {
        km: "ក្បាលជ្រោយ",
        latin: "Kbal Chrouy"
      },
      id: "08050101"
    },
    {
      name: {
        km: "កំពង់ពោធិ៍",
        latin: "Kampong Pou"
      },
      id: "08050102"
    },
    {
      name: {
        km: "អំពិលទឹក",
        latin: "Ampil Tuek"
      },
      id: "08050103"
    },
    {
      name: {
        km: "កោះចំរើន",
        latin: "Kaoh Chamraeun"
      },
      id: "08050104"
    },
    {
      name: {
        km: "ក្អមសំណក្រោម",
        latin: "K'am Samnar Kraom"
      },
      id: "08050201"
    },
    {
      name: {
        km: "ក្អមសំណលើ",
        latin: "K'am Samnar Leu"
      },
      id: "08050202"
    },
    {
      name: {
        km: "រាំងជួរ",
        latin: "Reang Chuor"
      },
      id: "08050203"
    },
    {
      name: {
        km: "បឹងកណ្ដាល",
        latin: "Boeng Kandal"
      },
      id: "08050301"
    },
    {
      name: {
        km: "បឹងក្រោម",
        latin: "Boeng Kraom"
      },
      id: "08050302"
    },
    {
      name: {
        km: "បឹងលើ",
        latin: "Boeng Leu"
      },
      id: "08050303"
    },
    {
      name: {
        km: "ពាមរាំងក្រោម",
        latin: "Peam Reang Kraom"
      },
      id: "08050401"
    },
    {
      name: {
        km: "ពាមរាំងលើ",
        latin: "Peam Reang Leu"
      },
      id: "08050402"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08050403"
    },
    {
      name: {
        km: "ព្រែកតាទួន",
        latin: "Preak Ta Tuon"
      },
      id: "08050404"
    },
    {
      name: {
        km: "កោះកន្ធាយ",
        latin: "Kaoh Kantheay"
      },
      id: "08050501"
    },
    {
      name: {
        km: "ព្រែកដាច់",
        latin: "Preaek Dach"
      },
      id: "08050502"
    },
    {
      name: {
        km: "ព្រែកតូច",
        latin: "Preaek Touch"
      },
      id: "08050503"
    },
    {
      name: {
        km: "តាហ៊ីង",
        latin: "Ta Hing"
      },
      id: "08050504"
    },
    {
      name: {
        km: "កំពង់ចំលង",
        latin: "Kampong Chamlang"
      },
      id: "08050601"
    },
    {
      name: {
        km: "ព្រែកបាក់",
        latin: "Preaek Bak"
      },
      id: "08050602"
    },
    {
      name: {
        km: "ព្រែកតូច",
        latin: "Preaek Touch"
      },
      id: "08050603"
    },
    {
      name: {
        km: "ស្ពានដែក",
        latin: "Spean Daek"
      },
      id: "08050604"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "08050701"
    },
    {
      name: {
        km: "ដងក្ដោង",
        latin: "Dang Kdaong"
      },
      id: "08050702"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "08050703"
    },
    {
      name: {
        km: "អរិយក្សត្រ",
        latin: "Akreiy Ksatr"
      },
      id: "08060101"
    },
    {
      name: {
        km: "ខ្សាច់",
        latin: "Khsach"
      },
      id: "08060102"
    },
    {
      name: {
        km: "ពោធិ៍ធំ",
        latin: "Pou Thum"
      },
      id: "08060103"
    },
    {
      name: {
        km: "ទួលមាស",
        latin: "Tuol Meas"
      },
      id: "08060104"
    },
    {
      name: {
        km: "ខ្នោការ",
        latin: "Khnaor Kar"
      },
      id: "08060201"
    },
    {
      name: {
        km: "បារុង",
        latin: "Barong"
      },
      id: "08060202"
    },
    {
      name: {
        km: "បឹងគ្រំលើ",
        latin: "Boeng Krum Leu"
      },
      id: "08060301"
    },
    {
      name: {
        km: "បឹងគ្រំក្រោម",
        latin: "Boeng Krum Kraom"
      },
      id: "08060302"
    },
    {
      name: {
        km: "កោះកែវលើ",
        latin: "Kaoh Kaev Leu"
      },
      id: "08060401"
    },
    {
      name: {
        km: "កោះកែវក្រោម",
        latin: "Kaoh Kaev Kraom"
      },
      id: "08060402"
    },
    {
      name: {
        km: "កោះរះលើ",
        latin: "Kaoh Reah Leu"
      },
      id: "08060501"
    },
    {
      name: {
        km: "កោះរះក្រោម",
        latin: "Kaoh Reah Kraom"
      },
      id: "08060502"
    },
    {
      name: {
        km: "ល្វាសរលើ",
        latin: "Lvea Sar Leu"
      },
      id: "08060601"
    },
    {
      name: {
        km: "ល្វាសរកណ្ដាល",
        latin: "Lvea Sar Kandal"
      },
      id: "08060602"
    },
    {
      name: {
        km: "ល្វាសរក្រោម",
        latin: "Lvea Sar Kraom"
      },
      id: "08060603"
    },
    {
      name: {
        km: "ពាមតាឯក",
        latin: "Peam Ta Aek"
      },
      id: "08060701"
    },
    {
      name: {
        km: "ព្រែកតាអុងទី១",
        latin: "Preaek Ta Ong Ti Muoy"
      },
      id: "08060702"
    },
    {
      name: {
        km: "ព្រែកតាអុងទី២",
        latin: "Preaek Ta Ong Ti Pir"
      },
      id: "08060703"
    },
    {
      name: {
        km: "ព្រែកតាអុងទី៣",
        latin: "Preaek Ta Ong Ti Bei"
      },
      id: "08060704"
    },
    {
      name: {
        km: "វាលធំ",
        latin: "Veal Thum"
      },
      id: "08060705"
    },
    {
      name: {
        km: "ព្រែកតាប្រាំង",
        latin: "Preaek Ta Prang"
      },
      id: "08060801"
    },
    {
      name: {
        km: "ព្រែកក្រូច",
        latin: "Preaek Krouch"
      },
      id: "08060802"
    },
    {
      name: {
        km: "ព្រែកក្មេង",
        latin: "Preaek Kmeng"
      },
      id: "08060901"
    },
    {
      name: {
        km: "ទួលទ្រា",
        latin: "Tuol Trea"
      },
      id: "08060902"
    },
    {
      name: {
        km: "ព្រែករៃ",
        latin: "Preaek Rey"
      },
      id: "08061001"
    },
    {
      name: {
        km: "ព្រែកឈ្មោះ",
        latin: "Preaek Chhmuoh"
      },
      id: "08061002"
    },
    {
      name: {
        km: "ព្រែកគង់រាជ",
        latin: "Preaek Kong Reach"
      },
      id: "08061003"
    },
    {
      name: {
        km: "ព្រែកប្រា",
        latin: "Preaek Pra"
      },
      id: "08061101"
    },
    {
      name: {
        km: "ព្រែកជ្រៃ",
        latin: "Preaek Chrey"
      },
      id: "08061102"
    },
    {
      name: {
        km: "ព្រែកឫស្សី",
        latin: "Preaek Ruessei"
      },
      id: "08061103"
    },
    {
      name: {
        km: "អន្លង់ទ្រា",
        latin: "Anlong Trea"
      },
      id: "08061104"
    },
    {
      name: {
        km: "ពាមស្ដី",
        latin: "Peam Sdei"
      },
      id: "08061105"
    },
    {
      name: {
        km: "សំបួរ",
        latin: "Sambuor"
      },
      id: "08061201"
    },
    {
      name: {
        km: "ព្រែកចារ",
        latin: "Preaek Char"
      },
      id: "08061202"
    },
    {
      name: {
        km: "ជ្រោយជ្រែ",
        latin: "Chrouy Chreae"
      },
      id: "08061203"
    },
    {
      name: {
        km: "តាជោ",
        latin: "Ta Chou"
      },
      id: "08061301"
    },
    {
      name: {
        km: "ក្ដីកណ្ដាល",
        latin: "Kdei Kandal"
      },
      id: "08061302"
    },
    {
      name: {
        km: "តាស្គរ",
        latin: "Ta Skor"
      },
      id: "08061303"
    },
    {
      name: {
        km: "ថ្មគរ",
        latin: "Thma Kor"
      },
      id: "08061401"
    },
    {
      name: {
        km: "ផ្លូវត្រី",
        latin: "Phlov Trei"
      },
      id: "08061402"
    },
    {
      name: {
        km: "ទឹកឃ្លាំង",
        latin: "Tuek Khleang"
      },
      id: "08061501"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "08061502"
    },
    {
      name: {
        km: "ជ្រោយពិសី",
        latin: "Chrouy Pisei"
      },
      id: "08061503"
    },
    {
      name: {
        km: "ព្រែកតាបែន",
        latin: "Preaek Ta Baen"
      },
      id: "08070301"
    },
    {
      name: {
        km: "ព្រែកថ្មី",
        latin: "Preaek Thmei"
      },
      id: "08070302"
    },
    {
      name: {
        km: "ក្រោម",
        latin: "Kraom"
      },
      id: "08070303"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "08070304"
    },
    {
      name: {
        km: "លើ",
        latin: "Leu"
      },
      id: "08070305"
    },
    {
      name: {
        km: "កោះរកា",
        latin: "Kaoh Roka"
      },
      id: "08070306"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "08070307"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "08070401"
    },
    {
      name: {
        km: "ឡឥដ្ឋ",
        latin: "La Edth"
      },
      id: "08070402"
    },
    {
      name: {
        km: "វត្ដចាស់",
        latin: "Voat Chas"
      },
      id: "08070403"
    },
    {
      name: {
        km: "វត្ដថ្មី",
        latin: "Voat Thmei"
      },
      id: "08070404"
    },
    {
      name: {
        km: "សាមគ្គី",
        latin: "Sameakki"
      },
      id: "08070405"
    },
    {
      name: {
        km: "រកាកោង",
        latin: "Roka Kaong"
      },
      id: "08070701"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "08070702"
    },
    {
      name: {
        km: "ព្រែកផ្ដៅ",
        latin: "Preaek Phdau"
      },
      id: "08070703"
    },
    {
      name: {
        km: "គោហ៊េ",
        latin: "Kouhe"
      },
      id: "08070704"
    },
    {
      name: {
        km: "ដើមជ្រៃ",
        latin: "Daeum Chrey"
      },
      id: "08070801"
    },
    {
      name: {
        km: "ពោធិ៍រលំ",
        latin: "Pou Rolum"
      },
      id: "08070802"
    },
    {
      name: {
        km: "ស្ពានថ្មី",
        latin: "Speam Thmei"
      },
      id: "08070803"
    },
    {
      name: {
        km: "ព្រែកជ្រៅ",
        latin: "Preaek Chrov"
      },
      id: "08070804"
    },
    {
      name: {
        km: "ព្រែកយាយហ៊ិន១",
        latin: "Preaek Yeay Hin  Muoy"
      },
      id: "08070901"
    },
    {
      name: {
        km: "ព្រែកយាយហ៊ិន២",
        latin: "Preaek Yeay Hin  Pir"
      },
      id: "08070902"
    },
    {
      name: {
        km: "ព្រែកតាសោម",
        latin: "Preaek Ta Saom"
      },
      id: "08070903"
    },
    {
      name: {
        km: "ឫស្សីជ្រោយ",
        latin: "Ruessei Chrouy"
      },
      id: "08070904"
    },
    {
      name: {
        km: "បឹងជន្លេន",
        latin: "Boeng Chonlen"
      },
      id: "08070905"
    },
    {
      name: {
        km: "ជ្រោយមេត្រីក្រោម",
        latin: "Chrouy Metrei Kraom"
      },
      id: "08070906"
    },
    {
      name: {
        km: "ជ្រោយមេត្រីលើ",
        latin: "Chrouy Metrei Leu"
      },
      id: "08070907"
    },
    {
      name: {
        km: "អន្លុងស្លែង",
        latin: "Anlong Slaeng"
      },
      id: "08071001"
    },
    {
      name: {
        km: "ជ្រៃមួយរយ",
        latin: "Chrey Muoy Roy"
      },
      id: "08071002"
    },
    {
      name: {
        km: "អំពិលទឹក",
        latin: "Ampil Tuek"
      },
      id: "08071003"
    },
    {
      name: {
        km: "ពោង",
        latin: "Poung"
      },
      id: "08071004"
    },
    {
      name: {
        km: "ព្រែកឫស្សី",
        latin: "Preaek Ruessei"
      },
      id: "08071005"
    },
    {
      name: {
        km: "ក្រោលគោ",
        latin: "Kraol Kou"
      },
      id: "08071006"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "08071007"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08071101"
    },
    {
      name: {
        km: "ស្វាយអំពារ",
        latin: "Svay Ampear"
      },
      id: "08071102"
    },
    {
      name: {
        km: "ឈើទាលភ្លោះ",
        latin: "Chheu Teal Phluoh"
      },
      id: "08071103"
    },
    {
      name: {
        km: "កំពង់ប្រាសាទ",
        latin: "Kampong Prasat"
      },
      id: "08071104"
    },
    {
      name: {
        km: "ក្រងស្វាយ",
        latin: "Krang Svay"
      },
      id: "08071105"
    },
    {
      name: {
        km: "បែកចាន",
        latin: "Baek Chan"
      },
      id: "08080101"
    },
    {
      name: {
        km: "មនោរម្យ",
        latin: "Monourom"
      },
      id: "08080102"
    },
    {
      name: {
        km: "ចុងបង្គោល",
        latin: "Chong Bangkoul"
      },
      id: "08080103"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08080104"
    },
    {
      name: {
        km: "ត្រពាំងទ្រា",
        latin: "Trapeang Trea"
      },
      id: "08080105"
    },
    {
      name: {
        km: "ព្រៃសំរោង",
        latin: "Prey Samraong"
      },
      id: "08080106"
    },
    {
      name: {
        km: "ព្រៃទន្លាប់",
        latin: "Prey Tonloab"
      },
      id: "08080107"
    },
    {
      name: {
        km: "ត្រាចទោល",
        latin: "Trach Toul"
      },
      id: "08080108"
    },
    {
      name: {
        km: "បូរីកម្មករ",
        latin: "Borei Kammeakkar"
      },
      id: "08080109"
    },
    {
      name: {
        km: "កៅ",
        latin: "Kau"
      },
      id: "08080110"
    },
    {
      name: {
        km: "ត្រពាំងសុក្រំ",
        latin: "Trapeang Sokram"
      },
      id: "08080111"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "08080112"
    },
    {
      name: {
        km: "ត្រពាំងប្រុយ",
        latin: "Trapeang Proy"
      },
      id: "08080113"
    },
    {
      name: {
        km: "រកាធំ",
        latin: "Roka Thum"
      },
      id: "08080114"
    },
    {
      name: {
        km: "កម្ពោតស្បូវ",
        latin: "Kampout Sbov"
      },
      id: "08080115"
    },
    {
      name: {
        km: "ព្រៃបឹង",
        latin: "Prey Boeng"
      },
      id: "08080116"
    },
    {
      name: {
        km: "ត្នោតម្ដើម",
        latin: "Tnaot Mdaeum"
      },
      id: "08080117"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "08080118"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "08080119"
    },
    {
      name: {
        km: "ចក",
        latin: "Chak"
      },
      id: "08080120"
    },
    {
      name: {
        km: "ស្វាយជ្រុំ",
        latin: "Svay Chrum"
      },
      id: "08080121"
    },
    {
      name: {
        km: "ព្រៃរការ",
        latin: "Prey Rokar"
      },
      id: "08080301"
    },
    {
      name: {
        km: "ត្រពាំងវែង",
        latin: "Trapeang Veaeng"
      },
      id: "08080302"
    },
    {
      name: {
        km: "ត្រពាំងជូន",
        latin: "Trapeang Choun"
      },
      id: "08080303"
    },
    {
      name: {
        km: "សាំងដំរី",
        latin: "Sang Damrei"
      },
      id: "08080304"
    },
    {
      name: {
        km: "ឆក់ឈើនាង",
        latin: "Chhak Chheu Neang"
      },
      id: "08080305"
    },
    {
      name: {
        km: "ត្រពាំងខ្នារ",
        latin: "Trapeang Khnar"
      },
      id: "08080306"
    },
    {
      name: {
        km: "ឈូកធំ",
        latin: "Chhuk Thum"
      },
      id: "08080307"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "08080308"
    },
    {
      name: {
        km: "ត្រពាំងសុព័រ",
        latin: "Trapeang Sopoar"
      },
      id: "08080309"
    },
    {
      name: {
        km: "ក្រឡឹងដុំ",
        latin: "Kraloeng Dom"
      },
      id: "08080310"
    },
    {
      name: {
        km: "ថ្នល់ទទឹង",
        latin: "Thnal Totueng"
      },
      id: "08080401"
    },
    {
      name: {
        km: "ទន្លាប់ខ្ពស់ត្បូង",
        latin: "Tonloab Khpos Tboung"
      },
      id: "08080402"
    },
    {
      name: {
        km: "ទន្លាប់ខ្ពស់ជើង",
        latin: "Tonloab Khpos Cheung"
      },
      id: "08080403"
    },
    {
      name: {
        km: "ពងទឹក",
        latin: "Pong Tuek"
      },
      id: "08080404"
    },
    {
      name: {
        km: "ព្រៃសំរោង",
        latin: "Prey Samraong"
      },
      id: "08080405"
    },
    {
      name: {
        km: "ដំណាក់អំពិល",
        latin: "Damnak Ampil"
      },
      id: "08080406"
    },
    {
      name: {
        km: "ក្ដាន់រយ",
        latin: "Kdan Roy"
      },
      id: "08080407"
    },
    {
      name: {
        km: "ត្រពាំងត្រាច",
        latin: "Trapeang Trach"
      },
      id: "08080408"
    },
    {
      name: {
        km: "សេរីសុខា",
        latin: "Serei Sokha"
      },
      id: "08080409"
    },
    {
      name: {
        km: "ជ្រៃមង្គ្គល",
        latin: "Chrey Mongkol"
      },
      id: "08080410"
    },
    {
      name: {
        km: "ត្រពាំងក្រពើ",
        latin: "Trapeang Krapeu"
      },
      id: "08080701"
    },
    {
      name: {
        km: "ត្រពាំងខ្ទឹម",
        latin: "Trapeang Khtuem"
      },
      id: "08080702"
    },
    {
      name: {
        km: "ក្រាំងម្កាក់",
        latin: "Krang Mkak"
      },
      id: "08080703"
    },
    {
      name: {
        km: "ត្រពាំងស្វាយ",
        latin: "Trapeang Svay"
      },
      id: "08080704"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "08080705"
    },
    {
      name: {
        km: "ត្រពាំងនប់",
        latin: "Trapeang Nob"
      },
      id: "08080706"
    },
    {
      name: {
        km: "ព្រៃពពេល",
        latin: "Prey Popel"
      },
      id: "08080707"
    },
    {
      name: {
        km: "ព្រៃខ្លា",
        latin: "Prey Khla"
      },
      id: "08080708"
    },
    {
      name: {
        km: "ត្រពាំងផ្លុង",
        latin: "Trapeang Phlong"
      },
      id: "08080709"
    },
    {
      name: {
        km: "អង្គពពាយ",
        latin: "Angk Popeay"
      },
      id: "08080710"
    },
    {
      name: {
        km: "ស រមាំង",
        latin: "Sa Romeang"
      },
      id: "08080711"
    },
    {
      name: {
        km: "ប្រាំបីមុម",
        latin: "Prambei Mum"
      },
      id: "08080712"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៅ",
        latin: "Trapeang Chrov"
      },
      id: "08080713"
    },
    {
      name: {
        km: "ត្រពាំងរការ",
        latin: "Trapeang Rokar"
      },
      id: "08080714"
    },
    {
      name: {
        km: "ស្រែខ្សាច់",
        latin: "Srae Khsach"
      },
      id: "08080715"
    },
    {
      name: {
        km: "គោកពពេល",
        latin: "Kouk Popel"
      },
      id: "08080801"
    },
    {
      name: {
        km: "កន្ដោលតុ",
        latin: "Kantaol Tok"
      },
      id: "08080802"
    },
    {
      name: {
        km: "យសមេត្រី",
        latin: "Yos Metrei"
      },
      id: "08080803"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "08080804"
    },
    {
      name: {
        km: "លំហាច",
        latin: "Lumhach"
      },
      id: "08080805"
    },
    {
      name: {
        km: "កណ្ដោស",
        latin: "Kandaos"
      },
      id: "08080806"
    },
    {
      name: {
        km: "ត្រពាំងនំ",
        latin: "Trapeang Num"
      },
      id: "08080807"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "08080808"
    },
    {
      name: {
        km: "ព្រៃក្រឡាញ់",
        latin: "Prey Kralanh"
      },
      id: "08080809"
    },
    {
      name: {
        km: "លាក់កូប",
        latin: "Leak Koub"
      },
      id: "08080810"
    },
    {
      name: {
        km: "អណ្ដូងទឹក",
        latin: "Andoung Tuek"
      },
      id: "08080811"
    },
    {
      name: {
        km: "ទំនប់សាប",
        latin: "Tumnob Sab"
      },
      id: "08080812"
    },
    {
      name: {
        km: "ព្រៃចំការ",
        latin: "Prey Chamkar"
      },
      id: "08080813"
    },
    {
      name: {
        km: "ព្រៃគោ",
        latin: "Prey Kou"
      },
      id: "08080814"
    },
    {
      name: {
        km: "ជើងអក",
        latin: "Cheung Ak"
      },
      id: "08080815"
    },
    {
      name: {
        km: "សេកពង",
        latin: "Sek Pong"
      },
      id: "08080816"
    },
    {
      name: {
        km: "ពន្លឺ",
        latin: "Ponlueu"
      },
      id: "08080817"
    },
    {
      name: {
        km: "តារ័ត្ន",
        latin: "Ta Roatn"
      },
      id: "08080818"
    },
    {
      name: {
        km: "សុខាភិរម្យ",
        latin: "Sokha Phirom"
      },
      id: "08080819"
    },
    {
      name: {
        km: "ទូករទេះ",
        latin: "Tuk Roteh"
      },
      id: "08080820"
    },
    {
      name: {
        km: "ប្រសិទ្ធិ",
        latin: "Braseth"
      },
      id: "08080901"
    },
    {
      name: {
        km: "ជោទ្រាច",
        latin: "Chou Treach"
      },
      id: "08080902"
    },
    {
      name: {
        km: "ត្រពាំងធ្នង់",
        latin: "Trapeang Thnong"
      },
      id: "08080903"
    },
    {
      name: {
        km: "ត្រពាំងក្រសាំង",
        latin: "Trapeang Krasang"
      },
      id: "08080904"
    },
    {
      name: {
        km: "ត្រពាំងទៃ",
        latin: "Trapeang Tey"
      },
      id: "08080905"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "08080906"
    },
    {
      name: {
        km: "តាប្រាប",
        latin: "Ta Prab"
      },
      id: "08080907"
    },
    {
      name: {
        km: "ត្រពាំងស្មាច់",
        latin: "Trapeang Smach"
      },
      id: "08080908"
    },
    {
      name: {
        km: "ស្រែកណ្ដោល",
        latin: "Srae Kandaol"
      },
      id: "08080909"
    },
    {
      name: {
        km: "ត្រពាំងស្នោរ",
        latin: "Trapeang Snaor"
      },
      id: "08080910"
    },
    {
      name: {
        km: "អូរ",
        latin: "Ou"
      },
      id: "08080911"
    },
    {
      name: {
        km: "ត្រើង",
        latin: "Traeung"
      },
      id: "08080912"
    },
    {
      name: {
        km: "ជង្រុក",
        latin: "Chongruk"
      },
      id: "08080913"
    },
    {
      name: {
        km: "ត្រពាំងអណ្ដែង",
        latin: "Trapeang Andaeng"
      },
      id: "08080914"
    },
    {
      name: {
        km: "ត្រពាំងត្នោត",
        latin: "Trapeang Tnaot"
      },
      id: "08080915"
    },
    {
      name: {
        km: "អង្គ រមាស",
        latin: "Angk Romeas"
      },
      id: "08080916"
    },
    {
      name: {
        km: "រំដេញ",
        latin: "Rumdenh"
      },
      id: "08080917"
    },
    {
      name: {
        km: "ស្ដុកវែង",
        latin: "Sdok Veaeng"
      },
      id: "08080918"
    },
    {
      name: {
        km: "អន្ទុងក្រវៀន",
        latin: "Antong Kravien"
      },
      id: "08080919"
    },
    {
      name: {
        km: "បេង",
        latin: "Beng"
      },
      id: "08080920"
    },
    {
      name: {
        km: "លំហាច",
        latin: "Lumhach"
      },
      id: "08080921"
    },
    {
      name: {
        km: "បឹងថ្នល់",
        latin: "Boeng Thnal"
      },
      id: "08080922"
    },
    {
      name: {
        km: "វែង",
        latin: "Veaeng"
      },
      id: "08080923"
    },
    {
      name: {
        km: "ពោធិ៍បួន",
        latin: "Pou Buon"
      },
      id: "08080924"
    },
    {
      name: {
        km: "ចុងបឹង",
        latin: "Chong Boeng"
      },
      id: "08080925"
    },
    {
      name: {
        km: "ចំការចិន",
        latin: "Chamkar Chen"
      },
      id: "08080926"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "08080927"
    },
    {
      name: {
        km: "ឈើបួន",
        latin: "Chheu Buon"
      },
      id: "08080928"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "08080929"
    },
    {
      name: {
        km: "ព្រៃពពេល",
        latin: "Prey Popel"
      },
      id: "08081101"
    },
    {
      name: {
        km: "ចំការជោ",
        latin: "Chamkar Chou"
      },
      id: "08081102"
    },
    {
      name: {
        km: "ត្រពាំងពើក",
        latin: "Trapeang Peuk"
      },
      id: "08081103"
    },
    {
      name: {
        km: "ទឹកជា",
        latin: "Tuek Chea"
      },
      id: "08081104"
    },
    {
      name: {
        km: "ត្រពាំងឈើនាង",
        latin: "Trapeang Chheu Neang"
      },
      id: "08081105"
    },
    {
      name: {
        km: "ថ្លើក",
        latin: "Thlaeuk"
      },
      id: "08081106"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "08081107"
    },
    {
      name: {
        km: "ត្រយឹង",
        latin: "Trayueng"
      },
      id: "08081108"
    },
    {
      name: {
        km: "ត្រពាំងភូមិ",
        latin: "Trapeang Phum"
      },
      id: "08081109"
    },
    {
      name: {
        km: "ស្រះសង្គម",
        latin: "Srah Sangkom"
      },
      id: "08081110"
    },
    {
      name: {
        km: "ទួលស្រម៉",
        latin: "Tuol Srama"
      },
      id: "08081111"
    },
    {
      name: {
        km: "ទួលត្នោត",
        latin: "Tuol Tnaot"
      },
      id: "08081112"
    },
    {
      name: {
        km: "អង្គសំណាង",
        latin: "Angk Samnang"
      },
      id: "08081113"
    },
    {
      name: {
        km: "ចំការត្រាច",
        latin: "Chamkar Trach"
      },
      id: "08081114"
    },
    {
      name: {
        km: "ខ្លាកូន",
        latin: "Khla Koun"
      },
      id: "08081115"
    },
    {
      name: {
        km: "ព្រៃទំពូង",
        latin: "Prey Tumpung"
      },
      id: "08081116"
    },
    {
      name: {
        km: "អង្គស្នួលទី១",
        latin: "Angk Snuol Ti Muoy"
      },
      id: "08081117"
    },
    {
      name: {
        km: "អង្គស្នួលទី២",
        latin: "Angk Snuol Ti Pir"
      },
      id: "08081118"
    },
    {
      name: {
        km: "អង្គស្នួលទី៣",
        latin: "Angk Snuol Ti Bei"
      },
      id: "08081119"
    },
    {
      name: {
        km: "ពាម",
        latin: "Peam"
      },
      id: "08081301"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "08081302"
    },
    {
      name: {
        km: "ឈើទាល",
        latin: "Chheu Teal"
      },
      id: "08081303"
    },
    {
      name: {
        km: "អូរកំបុត្រ",
        latin: "Ou Kambot"
      },
      id: "08081304"
    },
    {
      name: {
        km: "អង្គរស្មី",
        latin: "Angk Reaksmei"
      },
      id: "08081305"
    },
    {
      name: {
        km: "ទ្រា",
        latin: "Trea"
      },
      id: "08081306"
    },
    {
      name: {
        km: "ព្រៃពួច",
        latin: "Prey Puoch"
      },
      id: "08081307"
    },
    {
      name: {
        km: "ប្របវត្ដ",
        latin: "Prab Voat"
      },
      id: "08081308"
    },
    {
      name: {
        km: "ត្រើយបឹង",
        latin: "Traeuy Boeng"
      },
      id: "08081309"
    },
    {
      name: {
        km: "ទួលលាប",
        latin: "Tuol Leab"
      },
      id: "08081310"
    },
    {
      name: {
        km: "សល់ដី",
        latin: "Sal Dei"
      },
      id: "08081311"
    },
    {
      name: {
        km: "ទន្លាប់",
        latin: "Tonloab"
      },
      id: "08081312"
    },
    {
      name: {
        km: "ព្រៃភក្ដី",
        latin: "Prey Pheakdei"
      },
      id: "08081313"
    },
    {
      name: {
        km: "ព្រៃក្រាយ",
        latin: "Prey Kray"
      },
      id: "08081314"
    },
    {
      name: {
        km: "ចំការគួយ",
        latin: "Chamkar Kuoy"
      },
      id: "08081315"
    },
    {
      name: {
        km: "ចំការស្លែង",
        latin: "Chamkar Slaeng"
      },
      id: "08081316"
    },
    {
      name: {
        km: "ចំបក់ទន្សាយ",
        latin: "Chambak Tonsay"
      },
      id: "08081317"
    },
    {
      name: {
        km: "ក្រាំងលាវ",
        latin: "Krang Leav"
      },
      id: "08081318"
    },
    {
      name: {
        km: "ប្រមរ",
        latin: "Pramor"
      },
      id: "08081319"
    },
    {
      name: {
        km: "ព្រៃមាន",
        latin: "Prey Mean"
      },
      id: "08081320"
    },
    {
      name: {
        km: "កប់ចន្លុះ",
        latin: "Kab Chanloh"
      },
      id: "08081321"
    },
    {
      name: {
        km: "ត្រពាំងរាំង",
        latin: "Trapeang Reang"
      },
      id: "08081322"
    },
    {
      name: {
        km: "ទួលសាលា",
        latin: "Tuol Sala"
      },
      id: "08081323"
    },
    {
      name: {
        km: "គីរីថ្មី",
        latin: "Kiri Thmei"
      },
      id: "08081401"
    },
    {
      name: {
        km: "បឹង",
        latin: "Boeng"
      },
      id: "08081402"
    },
    {
      name: {
        km: "តាប៉ាង",
        latin: "Ta Pang"
      },
      id: "08081403"
    },
    {
      name: {
        km: "ត្រពាំងកក់",
        latin: "Trapeang Kak"
      },
      id: "08081404"
    },
    {
      name: {
        km: "ត្រពាំងត្បែង",
        latin: "Trapeang Tbaeng"
      },
      id: "08081405"
    },
    {
      name: {
        km: "ល្វី",
        latin: "Lvi"
      },
      id: "08081406"
    },
    {
      name: {
        km: "ទីទុយពង",
        latin: "Tituy Pong"
      },
      id: "08081407"
    },
    {
      name: {
        km: "អង្គឃ្វាន",
        latin: "Angk Khvean"
      },
      id: "08081408"
    },
    {
      name: {
        km: "ត្រពាំងថ្ម",
        latin: "Trapeang Thma"
      },
      id: "08081409"
    },
    {
      name: {
        km: "ស្រែអំព្រុំ",
        latin: "Srae Amprum"
      },
      id: "08081410"
    },
    {
      name: {
        km: "មង្គលបូរី",
        latin: "Mongkol Borei"
      },
      id: "08081411"
    },
    {
      name: {
        km: "កោះគ្របបាយ",
        latin: "Kaoh Krob Bay"
      },
      id: "08081412"
    },
    {
      name: {
        km: "ដំណាក់កកោះ",
        latin: "Damnak Kakaoh"
      },
      id: "08081413"
    },
    {
      name: {
        km: "ត្រពាំងក្រឡាញ់",
        latin: "Trapeang Kralanh"
      },
      id: "08081414"
    },
    {
      name: {
        km: "ពណ្ណរាយ",
        latin: "Ponnoreay"
      },
      id: "08081415"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08081416"
    },
    {
      name: {
        km: "ព្រៃផ្ចឹកទី១",
        latin: "Prey Phchoek Ti Muoy"
      },
      id: "08081417"
    },
    {
      name: {
        km: "ព្រៃផ្ចឹកទី២",
        latin: "Prey Phchoek Ti Pir"
      },
      id: "08081418"
    },
    {
      name: {
        km: "ភ្នំដី",
        latin: "Phnum Dei"
      },
      id: "08081419"
    },
    {
      name: {
        km: "ត្រពាំងអណ្ដូង",
        latin: "Trapeang Andoung"
      },
      id: "08081420"
    },
    {
      name: {
        km: "ព្រៃឈើទាល",
        latin: "Prey Chheu Teal"
      },
      id: "08081421"
    },
    {
      name: {
        km: "ព្រៃលួង",
        latin: "Prey Luong"
      },
      id: "08081422"
    },
    {
      name: {
        km: "ត្រពាំងទួល",
        latin: "Trapeang Tuol"
      },
      id: "08081423"
    },
    {
      name: {
        km: "ត្រពាំងទន្លាប់",
        latin: "Trapeang Tonloab"
      },
      id: "08081424"
    },
    {
      name: {
        km: "ដូនម៉ាន់",
        latin: "Doun Man"
      },
      id: "08081425"
    },
    {
      name: {
        km: "ចំការដូង",
        latin: "Chamkar Doung"
      },
      id: "08081426"
    },
    {
      name: {
        km: "ស្វាយឧត្ដម",
        latin: "Svay Otdam"
      },
      id: "08081427"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៅ",
        latin: "Trapeang Chrov"
      },
      id: "08081428"
    },
    {
      name: {
        km: "ចំបក់កោង",
        latin: "Chambak Kaong"
      },
      id: "08081601"
    },
    {
      name: {
        km: "បឹងអញ្ចាញ",
        latin: "Boeng Anhchanh"
      },
      id: "08081602"
    },
    {
      name: {
        km: "ខ្លុង",
        latin: "Khlong"
      },
      id: "08081603"
    },
    {
      name: {
        km: "អង្គស្រែពោធិ៍",
        latin: "Angk Srae Pou"
      },
      id: "08081604"
    },
    {
      name: {
        km: "រំលេច",
        latin: "Rumlech"
      },
      id: "08081605"
    },
    {
      name: {
        km: "ទួលខ្លុង",
        latin: "Tuol Khlong"
      },
      id: "08081606"
    },
    {
      name: {
        km: "ពពេលរលំ",
        latin: "Popel Rolum"
      },
      id: "08081607"
    },
    {
      name: {
        km: "គោល",
        latin: "Koul"
      },
      id: "08081608"
    },
    {
      name: {
        km: "ទួលសេរី",
        latin: "Tuol Serei"
      },
      id: "08081609"
    },
    {
      name: {
        km: "ក្រាំងក្រូច",
        latin: "Krang Krouch"
      },
      id: "08081610"
    },
    {
      name: {
        km: "ច្រកក្រសាំង",
        latin: "Chrak Krasang"
      },
      id: "08081611"
    },
    {
      name: {
        km: "ត្រពាំងកំភ្លាញ",
        latin: "Trapeang Kamphleanh"
      },
      id: "08081612"
    },
    {
      name: {
        km: "បឹងរមាស់",
        latin: "Boeng Romoas"
      },
      id: "08081613"
    },
    {
      name: {
        km: "ម៉ឺនរៀម",
        latin: "Meun Riem"
      },
      id: "08081614"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "08081615"
    },
    {
      name: {
        km: "ចន្ទ្រាថ្មី",
        latin: "Chantrea Thmei"
      },
      id: "08081616"
    },
    {
      name: {
        km: "ព្រៃរំដួល",
        latin: "Prey Rumduol"
      },
      id: "08081617"
    },
    {
      name: {
        km: "អង្គតាសិត",
        latin: "Angk Ta Set"
      },
      id: "08081618"
    },
    {
      name: {
        km: "បឹងទ្រា",
        latin: "Boeng Trea"
      },
      id: "08081619"
    },
    {
      name: {
        km: "ស្រែកាច់",
        latin: "Srae Kach"
      },
      id: "08081620"
    },
    {
      name: {
        km: "បឹងខ្នារ",
        latin: "Boeng Khnar"
      },
      id: "08081621"
    },
    {
      name: {
        km: "ខ្នារ",
        latin: "Khnar"
      },
      id: "08081622"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08081623"
    },
    {
      name: {
        km: "ព្រៃសំរោង",
        latin: "Prey Samraong"
      },
      id: "08081624"
    },
    {
      name: {
        km: "ទន្លាប់",
        latin: "Tonloab"
      },
      id: "08081625"
    },
    {
      name: {
        km: "តាំងស្ដុក",
        latin: "Tang Sdok"
      },
      id: "08090101"
    },
    {
      name: {
        km: "ស្ដុកតាចាន់",
        latin: "Sdok Ta Chan"
      },
      id: "08090102"
    },
    {
      name: {
        km: "ស្ដុកប្រនួត",
        latin: "Sdok Pranuot"
      },
      id: "08090103"
    },
    {
      name: {
        km: "ប្រាសាទ",
        latin: "Prasat"
      },
      id: "08090104"
    },
    {
      name: {
        km: "ឈ្វាំង",
        latin: "Chhveang"
      },
      id: "08090105"
    },
    {
      name: {
        km: "ស្លែងមានជ័យ",
        latin: "Slaeng Meanchey"
      },
      id: "08090106"
    },
    {
      name: {
        km: "តាគោក",
        latin: "Ta Kouk"
      },
      id: "08090107"
    },
    {
      name: {
        km: "ស្វាយ",
        latin: "Svay"
      },
      id: "08090108"
    },
    {
      name: {
        km: "ព្រៃគោល",
        latin: "Prey Koul"
      },
      id: "08090109"
    },
    {
      name: {
        km: "ទស្សាស្វិត",
        latin: "Tosa Svet"
      },
      id: "08090110"
    },
    {
      name: {
        km: "កាកាប",
        latin: "Kakab"
      },
      id: "08090111"
    },
    {
      name: {
        km: "តាពេជ",
        latin: "Ta Pech"
      },
      id: "08090112"
    },
    {
      name: {
        km: "ស្រែអំពិល",
        latin: "Srae Ampil"
      },
      id: "08090113"
    },
    {
      name: {
        km: "ព្រៃផ្ចឹក",
        latin: "Prey Phchoek"
      },
      id: "08090114"
    },
    {
      name: {
        km: "តាអោក",
        latin: "Ta Aok"
      },
      id: "08090115"
    },
    {
      name: {
        km: "ពង្រ",
        latin: "Pongro"
      },
      id: "08090116"
    },
    {
      name: {
        km: "ស្លែង",
        latin: "Slaeng"
      },
      id: "08090201"
    },
    {
      name: {
        km: "តាពេទ្យ",
        latin: "Ta Pet"
      },
      id: "08090202"
    },
    {
      name: {
        km: "ត្រពាំងរបង",
        latin: "Trapeang Robang"
      },
      id: "08090203"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08090204"
    },
    {
      name: {
        km: "ត្រពាំងវាល",
        latin: "Trapeang Veal"
      },
      id: "08090205"
    },
    {
      name: {
        km: "ព្រៃសុពណ៌",
        latin: "Prey Sopoar"
      },
      id: "08090206"
    },
    {
      name: {
        km: "តាគោ",
        latin: "Ta Kou"
      },
      id: "08090207"
    },
    {
      name: {
        km: "ពពែ",
        latin: "Popeae"
      },
      id: "08090208"
    },
    {
      name: {
        km: "ត្រពាំងសង្កែ",
        latin: "Trapeang Sangkae"
      },
      id: "08090209"
    },
    {
      name: {
        km: "ត្បូងវត្ដ",
        latin: "Tboung Voat"
      },
      id: "08090210"
    },
    {
      name: {
        km: "ត្រពាំងព្រលិត",
        latin: "Trapeang Prolit"
      },
      id: "08090211"
    },
    {
      name: {
        km: "តាតូច",
        latin: "Ta Touch"
      },
      id: "08090212"
    },
    {
      name: {
        km: "ជ្រៃលាស់",
        latin: "Chrey Loas"
      },
      id: "08090213"
    },
    {
      name: {
        km: "តាជៃ",
        latin: "Ta Chey"
      },
      id: "08090214"
    },
    {
      name: {
        km: "ទួលព្រិច",
        latin: "Tuol Prich"
      },
      id: "08090215"
    },
    {
      name: {
        km: "ឃ្លាំងស្បែក",
        latin: "Khleang Sbaek"
      },
      id: "08090301"
    },
    {
      name: {
        km: "ទួលងោក",
        latin: "Tuol Ngouk"
      },
      id: "08090302"
    },
    {
      name: {
        km: "ពោធិ៍តូច",
        latin: "Pou Touch"
      },
      id: "08090303"
    },
    {
      name: {
        km: "សង្វរ",
        latin: "Sangvar"
      },
      id: "08090304"
    },
    {
      name: {
        km: "ពាមជំនីក",
        latin: "Peam Chumnik"
      },
      id: "08090305"
    },
    {
      name: {
        km: "ពាមល្វែក",
        latin: "Peam Lveaek"
      },
      id: "08090306"
    },
    {
      name: {
        km: "ជ្រោយឫស្សី",
        latin: "Chrouy Ruessei"
      },
      id: "08090307"
    },
    {
      name: {
        km: "ខ្លាត្រាំ",
        latin: "Khla Tram"
      },
      id: "08090308"
    },
    {
      name: {
        km: "ដងគោម",
        latin: "Dang Koum"
      },
      id: "08090401"
    },
    {
      name: {
        km: "ព្រែកតាមែ",
        latin: "Preaek Ta Meae"
      },
      id: "08090402"
    },
    {
      name: {
        km: "កំពុងអុស",
        latin: "Kampung Os"
      },
      id: "08090403"
    },
    {
      name: {
        km: "បឹងក្ដុល",
        latin: "Boeng Kdol"
      },
      id: "08090404"
    },
    {
      name: {
        km: "ព្រែកតាព្រហ្ម",
        latin: "Preaek Ta Prum"
      },
      id: "08090405"
    },
    {
      name: {
        km: "ដងគោម",
        latin: "Dang Koum"
      },
      id: "08090501"
    },
    {
      name: {
        km: "ព្រែកជីក",
        latin: "Preaek Chik"
      },
      id: "08090502"
    },
    {
      name: {
        km: "ព្រែកក្ដាម២",
        latin: "Preaek Kdam Pir"
      },
      id: "08090503"
    },
    {
      name: {
        km: "ព្រែកក្ដាម១",
        latin: "Preaek Kdam Muoy"
      },
      id: "08090504"
    },
    {
      name: {
        km: "កោះចិន",
        latin: "Kaoh Chen"
      },
      id: "08090505"
    },
    {
      name: {
        km: "សសី",
        latin: "Sasei"
      },
      id: "08090506"
    },
    {
      name: {
        km: "ថ្នល់បត់",
        latin: "Thnal Bat"
      },
      id: "08090507"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "08090508"
    },
    {
      name: {
        km: "កំពង់រទេះ",
        latin: "Kampong Roteh"
      },
      id: "08090509"
    },
    {
      name: {
        km: "ជ្រោយសំបួរ",
        latin: "Chrouy Sambuor"
      },
      id: "08090510"
    },
    {
      name: {
        km: "តាពៅ",
        latin: "Ta Pov"
      },
      id: "08090511"
    },
    {
      name: {
        km: "ពោធិ៍រ៉ាល",
        latin: "Pou Ral"
      },
      id: "08090601"
    },
    {
      name: {
        km: "ស្រះពោធិ៍",
        latin: "Srah Pou"
      },
      id: "08090602"
    },
    {
      name: {
        km: "ត្រពាំងស្លែង",
        latin: "Trapeang Slaeng"
      },
      id: "08090603"
    },
    {
      name: {
        km: "វាលថ្មី",
        latin: "Veal Thmei"
      },
      id: "08090604"
    },
    {
      name: {
        km: "ដើមពោធិ៍",
        latin: "Daeum Pou"
      },
      id: "08090605"
    },
    {
      name: {
        km: "ភ្នំបាត",
        latin: "Phnum Bat"
      },
      id: "08090606"
    },
    {
      name: {
        km: "ធម្មស្រះ",
        latin: "Thommeak Srah"
      },
      id: "08090607"
    },
    {
      name: {
        km: "ក្បាលស្ពាន",
        latin: "Kbal Spean"
      },
      id: "08090608"
    },
    {
      name: {
        km: "អូរស្លាត",
        latin: "Ou Slat"
      },
      id: "08090609"
    },
    {
      name: {
        km: "ថ្មស",
        latin: "Thma Sa"
      },
      id: "08090610"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08090611"
    },
    {
      name: {
        km: "កំចាត់ព្រាយ",
        latin: "Kamchat Preay"
      },
      id: "08090612"
    },
    {
      name: {
        km: "កំពង់ក្រសាំង",
        latin: "Kampong Krasang"
      },
      id: "08090613"
    },
    {
      name: {
        km: "អង្គសេរី",
        latin: "Angk Serei"
      },
      id: "08090614"
    },
    {
      name: {
        km: "ស្ដុក",
        latin: "Sdok"
      },
      id: "08090615"
    },
    {
      name: {
        km: "ឆ្កែលុង",
        latin: "Chhkae Lung"
      },
      id: "08090616"
    },
    {
      name: {
        km: "ក្រោលខៀវ",
        latin: "Kraol Khiev"
      },
      id: "08090617"
    },
    {
      name: {
        km: "ត្រពាំងរកា",
        latin: "Trapeang Roka"
      },
      id: "08090618"
    },
    {
      name: {
        km: "ចំបក់ភ្លោះ",
        latin: "Chambak Phluoh"
      },
      id: "08090619"
    },
    {
      name: {
        km: "ថ្លុកអង្ក្រង",
        latin: "Thlok Angkrong"
      },
      id: "08090620"
    },
    {
      name: {
        km: "ថ្លុកត្របែក",
        latin: "Thlok Trabaek"
      },
      id: "08090621"
    },
    {
      name: {
        km: "បន្ទាយតូច",
        latin: "Banteay Touch"
      },
      id: "08090622"
    },
    {
      name: {
        km: "ទួលអំពិល",
        latin: "Tuol Ampil"
      },
      id: "08090701"
    },
    {
      name: {
        km: "ពញាឮ",
        latin: "Ponhea Lueu"
      },
      id: "08090702"
    },
    {
      name: {
        km: "ព្រែកល្វា",
        latin: "Preaek Lvea"
      },
      id: "08090703"
    },
    {
      name: {
        km: "ពោធិ៍មង្គល",
        latin: "Pou Mongkol"
      },
      id: "08091001"
    },
    {
      name: {
        km: "ស្លែងដីដុះ",
        latin: "Slaeng Dei Doh"
      },
      id: "08091002"
    },
    {
      name: {
        km: "ព្រែកក្ដី",
        latin: "Preaek Kdei"
      },
      id: "08091003"
    },
    {
      name: {
        km: "ធម្មត្រ័យ",
        latin: "Thommeak Trai"
      },
      id: "08091101"
    },
    {
      name: {
        km: "ម្លូម៉ឺន",
        latin: "Mlu Meun"
      },
      id: "08091102"
    },
    {
      name: {
        km: "ផ្សារដែកលើ",
        latin: "Phsar Daek Leu"
      },
      id: "08091103"
    },
    {
      name: {
        km: "ផ្សារដែកក្រោម",
        latin: "Phsar Daek Kraom"
      },
      id: "08091104"
    },
    {
      name: {
        km: "បដិមាករ",
        latin: "Pakdemakar"
      },
      id: "08091105"
    },
    {
      name: {
        km: "ចំបក់មាស",
        latin: "Chambak Meas"
      },
      id: "08091106"
    },
    {
      name: {
        km: "ទួលអង្គុញ",
        latin: "Tuol Angkunh"
      },
      id: "08091107"
    },
    {
      name: {
        km: "ភ្នំ",
        latin: "Phnum"
      },
      id: "08091108"
    },
    {
      name: {
        km: "ជ័យឧត្ដម",
        latin: "Chey Otdam"
      },
      id: "08091109"
    },
    {
      name: {
        km: "ស្រះពោធិ៍",
        latin: "Srah Pou"
      },
      id: "08091110"
    },
    {
      name: {
        km: "ល្វា",
        latin: "Lvea"
      },
      id: "08091301"
    },
    {
      name: {
        km: "ស្រែតាមែង",
        latin: "Srae Ta Meaeng"
      },
      id: "08091302"
    },
    {
      name: {
        km: "ស្ពានទំលាប់",
        latin: "Spean Tumloab"
      },
      id: "08091303"
    },
    {
      name: {
        km: "ស្ដុកឈូក",
        latin: "Sdok Chhuk"
      },
      id: "08091304"
    },
    {
      name: {
        km: "ស្រែតាសេក",
        latin: "Srae Ta Sek"
      },
      id: "08091305"
    },
    {
      name: {
        km: "ស្វាយលាប",
        latin: "Svay Leab"
      },
      id: "08091306"
    },
    {
      name: {
        km: "ត្រពាំងជ្រៅ",
        latin: "Trapeang Chrov"
      },
      id: "08091307"
    },
    {
      name: {
        km: "អញ្ចាញ",
        latin: "Anhchanh"
      },
      id: "08091308"
    },
    {
      name: {
        km: "ត្រពាំងឫស្សី",
        latin: "Trapeang Ruessei"
      },
      id: "08091309"
    },
    {
      name: {
        km: "កំណប់",
        latin: "Kamnab"
      },
      id: "08091310"
    },
    {
      name: {
        km: "បែកថ្លាង",
        latin: "Baek Thlang"
      },
      id: "08091311"
    },
    {
      name: {
        km: "ដំបូកមានល័ក្ខណ៍",
        latin: "Dambouk Mean Leak"
      },
      id: "08091312"
    },
    {
      name: {
        km: "ត្រពាំងអណ្ដូង",
        latin: "Trapeang Andoung"
      },
      id: "08091313"
    },
    {
      name: {
        km: "ត្រពាំងធ្នង់",
        latin: "Trapeang Thnong"
      },
      id: "08091314"
    },
    {
      name: {
        km: "ដំណាក់ព្រីង",
        latin: "Damnak Pring"
      },
      id: "08091315"
    },
    {
      name: {
        km: "ត្រពាំងព្រៃភូមិ",
        latin: "Trapeang Prey Phum"
      },
      id: "08091316"
    },
    {
      name: {
        km: "ស្រែរាំង",
        latin: "Srae Reang"
      },
      id: "08091317"
    },
    {
      name: {
        km: "អំពិលរូង",
        latin: "Ampil Rung"
      },
      id: "08091318"
    },
    {
      name: {
        km: "ត្រពាំងពុទ្រា",
        latin: "Trapeang Putrea"
      },
      id: "08091319"
    },
    {
      name: {
        km: "ប្រក់ក្ដារ",
        latin: "Prak Kdar"
      },
      id: "08091401"
    },
    {
      name: {
        km: "ទេពប្រណម្យ",
        latin: "Tep Pranam"
      },
      id: "08091402"
    },
    {
      name: {
        km: "កំពង់ចុះវារ",
        latin: "Kampong Choh Vear"
      },
      id: "08091403"
    },
    {
      name: {
        km: "ចេតីយ៍ទ្រេត",
        latin: "Chedei Tret"
      },
      id: "08091404"
    },
    {
      name: {
        km: "អំពិលដាំទឹក",
        latin: "Ampil Dam Tuek"
      },
      id: "08091405"
    },
    {
      name: {
        km: "សាលាកាត់សក់",
        latin: "Sala Kat Sak"
      },
      id: "08091406"
    },
    {
      name: {
        km: "ធ្យូង",
        latin: "Thyung"
      },
      id: "08091407"
    },
    {
      name: {
        km: "ចេតីយ៍ថ្មី",
        latin: "Chedei Thmei"
      },
      id: "08091408"
    },
    {
      name: {
        km: "ពោធិ៍កំបោរ",
        latin: "Pou Kambaor"
      },
      id: "08091409"
    },
    {
      name: {
        km: "អំពិលផ្អែម",
        latin: "Ampil Ph'aem"
      },
      id: "08091410"
    },
    {
      name: {
        km: "ថ្នល់",
        latin: "Thnal"
      },
      id: "08091411"
    },
    {
      name: {
        km: "ចតុទិស",
        latin: "Chakto Tis"
      },
      id: "08091412"
    },
    {
      name: {
        km: "ឫស្សីស្រុក",
        latin: "Ruessei Srok"
      },
      id: "08100101"
    },
    {
      name: {
        km: "ខ្ពបលើ",
        latin: "Khpob Leu"
      },
      id: "08100102"
    },
    {
      name: {
        km: "ខ្ពបក្រោម",
        latin: "Khpob Kraom"
      },
      id: "08100103"
    },
    {
      name: {
        km: "រកាលើ",
        latin: "Roka Leu"
      },
      id: "08100104"
    },
    {
      name: {
        km: "រកាក្រោម",
        latin: "Roka Kraom"
      },
      id: "08100105"
    },
    {
      name: {
        km: "កោះថ្មី",
        latin: "Kaoh Thmei"
      },
      id: "08100106"
    },
    {
      name: {
        km: "បឹងខ្ពប",
        latin: "Boeng Khpob"
      },
      id: "08100107"
    },
    {
      name: {
        km: "ដំរីឆ្លង",
        latin: "Damrei Chhlang"
      },
      id: "08100108"
    },
    {
      name: {
        km: "ព្រៃទទឹង",
        latin: "Prey Totueng"
      },
      id: "08100109"
    },
    {
      name: {
        km: "ត្នោតញី",
        latin: "Tnaot Nhi"
      },
      id: "08100110"
    },
    {
      name: {
        km: "ស្វាយជួរ",
        latin: "Svay Chuor"
      },
      id: "08100301"
    },
    {
      name: {
        km: "ទេពអរជូន",
        latin: "Tep Archun"
      },
      id: "08100302"
    },
    {
      name: {
        km: "ព្រែកកែវ",
        latin: "Preaek Kaev"
      },
      id: "08100303"
    },
    {
      name: {
        km: "កោះខែល",
        latin: "Kaoh Khael"
      },
      id: "08100304"
    },
    {
      name: {
        km: "ព្រែកប៉ាង",
        latin: "Preaek Pang"
      },
      id: "08100305"
    },
    {
      name: {
        km: "ដើមព្រីង",
        latin: "Daeum Pring"
      },
      id: "08100306"
    },
    {
      name: {
        km: "ក្បាលកោះកើត",
        latin: "Kbal Kaoh Khang Kaeut"
      },
      id: "08100401"
    },
    {
      name: {
        km: "ក្បាលកោះលិច",
        latin: "Kbal Kaoh Khang Lech"
      },
      id: "08100402"
    },
    {
      name: {
        km: "កណ្ដាលកោះ",
        latin: "Kandal Kaoh"
      },
      id: "08100403"
    },
    {
      name: {
        km: "ចុងកោះកើត",
        latin: "Chong Kaoh Kaeut"
      },
      id: "08100404"
    },
    {
      name: {
        km: "ចុងកោះលិច",
        latin: "Chong Kaoh Lech"
      },
      id: "08100405"
    },
    {
      name: {
        km: "ក្បាលកោះខាងត្បូង",
        latin: "Kbal Kaoh Khang Tboung"
      },
      id: "08100406"
    },
    {
      name: {
        km: "បទុមសាគរ",
        latin: "Botum Sakor"
      },
      id: "08100407"
    },
    {
      name: {
        km: "ព្រែកយាយម៉ី",
        latin: "Preak Yeay Mey"
      },
      id: "08100408"
    },
    {
      name: {
        km: "កំពង់ពោធិ៍",
        latin: "Kampong Pou"
      },
      id: "08100501"
    },
    {
      name: {
        km: "ទួលក្រាំង",
        latin: "Tuol Krang"
      },
      id: "08100502"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "08100503"
    },
    {
      name: {
        km: "អណ្ដូង",
        latin: "Andoung"
      },
      id: "08100504"
    },
    {
      name: {
        km: "រកា",
        latin: "Roka"
      },
      id: "08100505"
    },
    {
      name: {
        km: "វិហារ",
        latin: "Vihear"
      },
      id: "08100506"
    },
    {
      name: {
        km: "ពីងពង់",
        latin: "Ping Pong"
      },
      id: "08100507"
    },
    {
      name: {
        km: "អំពិល",
        latin: "Ampil"
      },
      id: "08100508"
    },
    {
      name: {
        km: "តាគល់",
        latin: "Ta Kol"
      },
      id: "08100509"
    },
    {
      name: {
        km: "ធំ",
        latin: "Thum"
      },
      id: "08100510"
    },
    {
      name: {
        km: "គរ",
        latin: "Kor"
      },
      id: "08100511"
    },
    {
      name: {
        km: "កណ្ដាល",
        latin: "Kandal"
      },
      id: "08100512"
    },
    {
      name: {
        km: "តាពេជ",
        latin: "Ta Pech"
      },
      id: "08100513"
    },
    {
      name: {
        km: "ចេក",
        latin: "Chek"
      },
      id: "08100514"
    },
    {
      name: {
        km: "អង្គ",
        latin: "Angk"
      },
      id: "08100515"
    },
    {
      name: {
        km: "កំពង់ពោធិ៍ត្បូង",
        latin: "Kampong Pou Tboung"
      },
      id: "08100516"
    },
    {
      name: {
        km: "ទួលក្រួច",
        latin: "Tuol Kruoch"
      },
      id: "08100517"
    },
    {
      name: {
        km: "អម្ពិលលាស់",
        latin: "Ampil Las"
      },
      id: "08100518"
    },
    {
      name: {
        km: "ស្វាយដំណាក់",
        latin: "Svay Damnak"
      },
      id: "08100519"
    },
    {
      name: {
        km: "លេខ១",
        latin: "Lekh Muoy"
      },
      id: "08100601"
    },
    {
      name: {
        km: "លេខ២",
        latin: "Lekh Pir"
      },
      id: "08100602"
    },
    {
      name: {
        km: "លេខ៣",
        latin: "Lekh Bei"
      },
      id: "08100603"
    },
    {
      name: {
        km: "លេខ៤",
        latin: "Lekh Buon"
      },
      id: "08100604"
    },
    {
      name: {
        km: "លេខ៥",
        latin: "Lekh Pram"
      },
      id: "08100605"
    },
    {
      name: {
        km: "ត្រើយត្រឹង្ស",
        latin: "Traeuy Troeng"
      },
      id: "08100701"
    },
    {
      name: {
        km: "ព្រែកតាឡៃ",
        latin: "Preaek Ta Lai"
      },
      id: "08100702"
    },
    {
      name: {
        km: "សំប៉ានលើ",
        latin: "Sampan Leu"
      },
      id: "08100703"
    },
    {
      name: {
        km: "សំប៉ានក្រោម",
        latin: "Sampan Kraom"
      },
      id: "08100704"
    },
    {
      name: {
        km: "អន្លង់តាសេកលើ",
        latin: "Anlong Ta Sek Leu"
      },
      id: "08100705"
    },
    {
      name: {
        km: "អន្លង់តាសេកក្រោម",
        latin: "Anlong Ta Sek Kraom"
      },
      id: "08100706"
    },
    {
      name: {
        km: "កូនជ្រែ",
        latin: "Koun Chreae"
      },
      id: "08100707"
    },
    {
      name: {
        km: "ព្រែកក្រាញ់",
        latin: "Preaek Kranh"
      },
      id: "08100708"
    },
    {
      name: {
        km: "ពាមប្រជុំ",
        latin: "Peam Prachum"
      },
      id: "08100709"
    },
    {
      name: {
        km: "ព្រែកគ្រួស",
        latin: "Preaek Khruos"
      },
      id: "08100710"
    },
    {
      name: {
        km: "ព្រែកអំបិល",
        latin: "Preaek Ambil"
      },
      id: "08100711"
    },
    {
      name: {
        km: "ព្រែកថ្មី",
        latin: "Preaek Thmei"
      },
      id: "08100712"
    },
    {
      name: {
        km: "ឫស្សីដាច់",
        latin: "Russey Dach"
      },
      id: "08100713"
    },
    {
      name: {
        km: "ព្រែកតាទាវ",
        latin: "Preaek Ta Teav"
      },
      id: "08100714"
    },
    {
      name: {
        km: "អន្លង់ធំ",
        latin: "Anlong Thom"
      },
      id: "08100715"
    },
    {
      name: {
        km: "អន្លង់ឈើខ្លាង",
        latin: "Anlong Chheu Khlang"
      },
      id: "08100716"
    },
    {
      name: {
        km: "ក្នុងព្រែក",
        latin: "Knong Preaek"
      },
      id: "08100801"
    },
    {
      name: {
        km: "ព្រែករុន",
        latin: "Preaek Run"
      },
      id: "08100802"
    },
    {
      name: {
        km: "ព្រែកស្នង",
        latin: "Preaek Snang"
      },
      id: "08100803"
    },
    {
      name: {
        km: "ព្រែកស្នាយ",
        latin: "Preaek Snay"
      },
      id: "08100804"
    },
    {
      name: {
        km: "ស្វាយតានី",
        latin: "Svay Ta Ni"
      },
      id: "08100805"
    },
    {
      name: {
        km: "ព្រែកតាជ្រូក",
        latin: "Preaek Ta Chruk"
      },
      id: "08100806"
    },
    {
      name: {
        km: "ទួលសូភី",
        latin: "Tuol Souphi"
      },
      id: "08100807"
    },
    {
      name: {
        km: "ត្រពាំងឈូក",
        latin: "Trapeang Chhuk"
      },
      id: "08100808"
    },
    {
      name: {
        km: "ក្បាលជ្រោយ",
        latin: "Kbal Chrouy"
      },
      id: "08100809"
    },
    {
      name: {
        km: "ព្រែករុនក្រោម",
        latin: "Preak Run Krom"
      },
      id: "08100810"
    },
    {
      name: {
        km: "ព្រែកស្លែង",
        latin: "Preaek Slaeng"
      },
      id: "08101001"
    },
    {
      name: {
        km: "ទួលសាលា",
        latin: "Tuol Sala"
      },
      id: "08101002"
    },
    {
      name: {
        km: "ព្រែកខ្មែរ",
        latin: "Preaek Khmer"
      },
      id: "08101003"
    },
    {
      name: {
        km: "កំពង់ទ្រា",
        latin: "Kampong Trea"
      },
      id: "08101004"
    },
    {
      name: {
        km: "គោកអណ្ដែត",
        latin: "Kouk Andaet"
      },
      id: "08101005"
    },
    {
      name: {
        km: "ដំរីឆ្លង",
        latin: "Damrei Chhlang"
      },
      id: "08101006"
    },
    {
      name: {
        km: "ពាមសាលា",
        latin: "Peam Sala"
      },
      id: "08101007"
    },
    {
      name: {
        km: "វាល",
        latin: "Veal"
      },
      id: "08101008"
    },
    {
      name: {
        km: "តានូ",
        latin: "Ta Nu"
      },
      id: "08101009"
    },
    {
      name: {
        km: "ព្រែកតាតិន",
        latin: "Preaek Ta Ten"
      },
      id: "08101201"
    },
    {
      name: {
        km: "ឫស្សីជ្រោយ",
        latin: "Ruessei Chrouy"
      },
      id: "08101202"
    },
    {
      name: {
        km: "ព្រែកតាជ័រ",
        latin: "Preaek Ta Choar"
      },
      id: "08101203"
    },
    {
      name: {
        km: "ព្រែកតាសៅ",
        latin: "Preaek Ta Sau"
      },
      id: "08101204"
    },
    {
      name: {
        km: "ចុងកោះគរ",
        latin: "Chong Kaoh Kor"
      },
      id: "08101205"
    },
    {
      name: {
        km: "ប៉ារែនក្រោម",
        latin: "Paraen Kraom"
      },
      id: "08101206"
    },
    {
      name: {
        km: "ប៉ារែនលើ",
        latin: "Paraen Leu"
      },
      id: "08101207"
    },
    {
      name: {
        km: "អូររំចេក",
        latin: "Ou Rumchek"
      },
      id: "08101208"
    },
    {
      name: {
        km: "ពោធិ៍តាប៉ាង",
        latin: "Pou Ta Pang"
      },
      id: "08101209"
    },
    {
      name: {
        km: "ព្រែកតាប្រាក់",
        latin: "Preaek Ta Prak"
      },
      id: "08101401"
    },
    {
      name: {
        km: "តាលន់",
        latin: "Ta Lon"
      },
      id: "08101402"
    },
    {
      name: {
        km: "ចុងកោះតូច",
        latin: "Chong Kaoh Touch"
      },
      id: "08101403"
    },
    {
      name: {
        km: "កណ្ដាលកោះតូច",
        latin: "Kandal Kaoh Touch"
      },
      id: "08101404"
    },
    {
      name: {
        km: "ក្បាលកោះតូច",
        latin: "Kbal Kaoh Touch"
      },
      id: "08101405"
    },
    {
      name: {
        km: "ទួលស្ពឺ",
        latin: "Tuol Spueu"
      },
      id: "08101406"
    },
    {
      name: {
        km: "ព្រែកស្លែង",
        latin: "Preaek Slaeng"
      },
      id: "08101407"
    },
    {
      name: {
        km: "ព្រែកតាឯក",
        latin: "Preaek Ta Aek"
      },
      id: "08101408"
    },
    {
      name: {
        km: "វាលត្រែង",
        latin: "Veal Traeng"
      },
      id: "08101409"
    },
    {
      name: {
        km: "ពោធិ៍លើ",
        latin: "Pou Leu"
      },
      id: "08101501"
    },
    {
      name: {
        km: "ពោធិ៍កណ្ដាល",
        latin: "Pou Kandal"
      },
      id: "08101502"
    },
    {
      name: {
        km: "ពោធិ៍ក្រោម",
        latin: "Pou Kraom"
      },
      id: "08101503"
    },
    {
      name: {
        km: "ព្រែកតាឯក",
        latin: "Preaek Ta Aek"
      },
      id: "08101504"
    },
    {
      name: {
        km: "ព្រែក",
        latin: "Preaek"
      },
      id: "08101505"
    },
    {
      name: {
        km: "ព្រែកប៉ាន",
        latin: "Preaek Pan"
      },
      id: "08101506"
    },
    {
      name: {
        km: "ព្រែកបាឡាត់ឆឹង",
        latin: "Preaek Balat Chhoeng"
      },
      id: "08101507"
    },
    {
      name: {
        km: "ថ្កុល",
        latin: "Thkol"
      },
      id: "08101508"
    },
    {
      name: {
        km: "ទួលក្ដី",
        latin: "Tuol Kdei"
      },
      id: "08101509"
    },
    {
      name: {
        km: "ព្រែកថ្មី",
        latin: "Preaek Thmei"
      },
      id: "08101601"
    },
    {
      name: {
        km: "ព្រែកតាប៉ឹម",
        latin: "Preaek Ta Poem"
      },
      id: "08101602"
    },
    {
      name: {
        km: "ព្រែកតាវ៉ា",
        latin: "Preaek Ta Va"
      },
      id: "08101603"
    },
    {
      name: {
        km: "ព្រែកអុងប៉ាង",
        latin: "Preaek Ong Pang"
      },
      id: "08101604"
    },
    {
      name: {
        km: "វត្ដកណ្ដាល",
        latin: "Voat Kandal"
      },
      id: "08101605"
    },
    {
      name: {
        km: "ផ្លូវបំបែក",
        latin: "Phlov Bambaek"
      },
      id: "08101606"
    },
    {
      name: {
        km: "ព្រែកពោធិ៍",
        latin: "Preaek Pou"
      },
      id: "08101607"
    },
    {
      name: {
        km: "ព្រែករាំង",
        latin: "Preaek Reang"
      },
      id: "08101608"
    },
    {
      name: {
        km: "ព្រែករាំង ក",
        latin: "Preaek Reang Ka"
      },
      id: "08101609"
    },
    {
      name: {
        km: "ទួលខ្មួញ",
        latin: "Tuol Kmuoj"
      },
      id: "08101610"
    },
    {
      name: {
        km: "តាក្ដុល",
        latin: "Ta Kdol"
      },
      id: "08110101"
    },
    {
      name: {
        km: "ព្រែកកាត់",
        latin: "Preaek Kat"
      },
      id: "08110102"
    },
    {
      name: {
        km: "ព្រែកឡុង",
        latin: "Preaek Long"
      },
      id: "08110103"
    },
    {
      name: {
        km: "តាក្ដុលត្បូង",
        latin: "Ta Kdol Tboung"
      },
      id: "08110104"
    },
    {
      name: {
        km: "ក្រពើហា",
        latin: "Krapeu Ha"
      },
      id: "08110201"
    },
    {
      name: {
        km: "ព្រែកឫស្សី",
        latin: "Prek Ruessey"
      },
      id: "08110202"
    },
    {
      name: {
        km: "ព្រែកអញ្ចាញ",
        latin: "Prek Anhchanh"
      },
      id: "08110203"
    },
    {
      name: {
        km: "ព្រែកឫស្សីលិច",
        latin: "Prek Ruessey Lix"
      },
      id: "08110204"
    },
    {
      name: {
        km: "ក្រពើហាកើត",
        latin: "Krapeu Ha Kout"
      },
      id: "08110205"
    },
    {
      name: {
        km: "ដើមមៀន",
        latin: "Deum Mien"
      },
      id: "08110301"
    },
    {
      name: {
        km: "ដើមគរ",
        latin: "Doeum Kor"
      },
      id: "08110302"
    },
    {
      name: {
        km: "ព្រែកតាពៅ",
        latin: "Prek Ta Pov"
      },
      id: "08110303"
    },
    {
      name: {
        km: "ស្ទឹងជ្រៅ",
        latin: "Stoeng Chrov"
      },
      id: "08110304"
    },
    {
      name: {
        km: "ដើមមៀន ១",
        latin: "Deum Mien 1"
      },
      id: "08110305"
    },
    {
      name: {
        km: "ព្រែកតាពៅ ១",
        latin: "Prek Ta Pov 1"
      },
      id: "08110306"
    },
    {
      name: {
        km: "តាខ្មៅ",
        latin: "Ta Khmao"
      },
      id: "08110401"
    },
    {
      name: {
        km: "ព្រែកសំរោង",
        latin: "Prek Samraong"
      },
      id: "08110402"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "08110403"
    },
    {
      name: {
        km: "តាខ្មៅ ១",
        latin: "Ta Khmao 1"
      },
      id: "08110404"
    },
    {
      name: {
        km: "តាខ្មៅ ២",
        latin: "Ta Khmao 2"
      },
      id: "08110405"
    },
    {
      name: {
        km: "តាខ្មៅ ៣",
        latin: "Ta Khmao 3"
      },
      id: "08110406"
    },
    {
      name: {
        km: "ថ្មី ១",
        latin: "Thmei 1"
      },
      id: "08110407"
    },
    {
      name: {
        km: "ថ្មី ២",
        latin: "Thmei 2"
      },
      id: "08110408"
    },
    {
      name: {
        km: "ព្រែកសំរោង ១",
        latin: "Prek Samraong 1"
      },
      id: "08110409"
    },
    {
      name: {
        km: "ព្រែកសំរោង ២",
        latin: "Prek Samraong 2"
      },
      id: "08110410"
    },
    {
      name: {
        km: "ព្រែកសំរោង ៣",
        latin: "Prek Samraong 3"
      },
      id: "08110411"
    },
    {
      name: {
        km: "ព្រែកហូរកើត",
        latin: "Prek Ho Kout"
      },
      id: "08110501"
    },
    {
      name: {
        km: "ព្រែកហូរលិច",
        latin: "Prek Ho Lix"
      },
      id: "08110502"
    },
    {
      name: {
        km: "បត្ដាជី",
        latin: "Battachi"
      },
      id: "08110503"
    },
    {
      name: {
        km: "ព្រែកហូរលិច ១",
        latin: "Prek Ho Lix 1"
      },
      id: "08110504"
    },
    {
      name: {
        km: "ព្រែកហូរកើត ១",
        latin: "Prek Ho Kout 1"
      },
      id: "08110505"
    },
    {
      name: {
        km: "បត្ដាជី ១",
        latin: "Battachi 1"
      },
      id: "08110506"
    },
    {
      name: {
        km: "កំពង់សំណាញ់",
        latin: "Kampong Samnanh"
      },
      id: "08110601"
    },
    {
      name: {
        km: "ខ្ពបវែង",
        latin: "Khpob Veng"
      },
      id: "08110602"
    },
    {
      name: {
        km: "ព្រែករាំង",
        latin: "Prek Riang"
      },
      id: "08110603"
    },
    {
      name: {
        km: "អាចម៍កុក",
        latin: "Ach Kok"
      },
      id: "08110604"
    },
    {
      name: {
        km: "កំពង់សំណាញ់ ១",
        latin: "Kampong Samnanh 1"
      },
      id: "08110605"
    },
    {
      name: {
        km: "ព្រែករាំង ១",
        latin: "Prek Riang 1"
      },
      id: "08110606"
    },
    {
      name: {
        km: "លេខ១",
        latin: "Lekh Muoy"
      },
      id: "08110701"
    },
    {
      name: {
        km: "លេខ២",
        latin: "Lekh Pir"
      },
      id: "08110702"
    },
    {
      name: {
        km: "លេខ៣",
        latin: "Lekh Bei"
      },
      id: "08110703"
    },
    {
      name: {
        km: "លេខ៤",
        latin: "Lekh Buon"
      },
      id: "08110704"
    },
    {
      name: {
        km: "លេខ៥",
        latin: "Lekh Pram"
      },
      id: "08110705"
    },
    {
      name: {
        km: "លេខ១ ក",
        latin: "Lekh Muoy Kar"
      },
      id: "08110706"
    },
    {
      name: {
        km: "ក្បាលកោះ",
        latin: "Kbal Kaoh"
      },
      id: "08110801"
    },
    {
      name: {
        km: "កណ្ដាលកោះ",
        latin: "Kandal Kaoh"
      },
      id: "08110802"
    },
    {
      name: {
        km: "ស្វាយពងអង្ក្រង",
        latin: "Svay Pong Angkrang"
      },
      id: "08110803"
    },
    {
      name: {
        km: "ចុងកោះ",
        latin: "Chong Kaoh"
      },
      id: "08110804"
    },
    {
      name: {
        km: "ព្រែកតាព្រីង",
        latin: "Preaek Ta Pring"
      },
      id: "08110901"
    },
    {
      name: {
        km: "សិត្បូ",
        latin: "Setbou"
      },
      id: "08110902"
    },
    {
      name: {
        km: "កំពង់ព្រីង",
        latin: "Kampong Pring"
      },
      id: "08110903"
    },
    {
      name: {
        km: "ព្រែកត្រែង",
        latin: "Preaek Traeng"
      },
      id: "08110904"
    },
    {
      name: {
        km: "កោះគរ",
        latin: "Kaoh Kor"
      },
      id: "08111001"
    },
    {
      name: {
        km: "ព្រែកថី",
        latin: "Preaek Thei"
      },
      id: "08111002"
    },
    {
      name: {
        km: "ព្រែកសំរោង",
        latin: "Preaek Samraong"
      },
      id: "08111003"
    },
    {
      name: {
        km: "ព្រែកខ្សេវ",
        latin: "Preaek Khsev"
      },
      id: "08111004"
    },
    {
      name: {
        km: "ទួលក្រសាំង",
        latin: "Tuol Krasang"
      },
      id: "08111005"
    },
    {
      name: {
        km: "កោះរំដួល",
        latin: "Kaoh Romduol"
      },
      id: "08111006"
    },
    {
      name: {
        km: "ព្រែកឡុង",
        latin: "Preak Long"
      },
      id: "08111007"
    },
    {
      name: {
        km: "អណ្ដូងទឹក",
        latin: "Andoung Tuek"
      },
      id: "09010101"
    },
    {
      name: {
        km: "ជីមាល",
        latin: "Chi Meal"
      },
      id: "09010102"
    },
    {
      name: {
        km: "ប្រៃ",
        latin: "Prai"
      },
      id: "09010103"
    },
    {
      name: {
        km: "ជីត្រេះ",
        latin: "Chi Treh"
      },
      id: "09010104"
    },
    {
      name: {
        km: "ប្រទាល",
        latin: "Prateal"
      },
      id: "09010105"
    },
    {
      name: {
        km: "តាមាឃ",
        latin: "Ta Meakh"
      },
      id: "09010106"
    },
    {
      name: {
        km: "តាអុក",
        latin: "Ta Ok"
      },
      id: "09010107"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "09010201"
    },
    {
      name: {
        km: "ព្រលាន",
        latin: "Prolean"
      },
      id: "09010202"
    },
    {
      name: {
        km: "សុវណ្ណាបៃតង",
        latin: "Sovanna Bai Tong"
      },
      id: "09010203"
    },
    {
      name: {
        km: "តាំកន់",
        latin: "Tam Kan"
      },
      id: "09010204"
    },
    {
      name: {
        km: "ធ្នង់",
        latin: "Thnong"
      },
      id: "09010205"
    },
    {
      name: {
        km: "បាក់រនាស់",
        latin: "Bak Ronoas"
      },
      id: "09010301"
    },
    {
      name: {
        km: "ព្រែកខ្យង",
        latin: "Preaek Khyang"
      },
      id: "09010302"
    },
    {
      name: {
        km: "តានូន",
        latin: "Ta Noun"
      },
      id: "09010303"
    },
    {
      name: {
        km: "ទួលពោធិ៍",
        latin: "Tuol Pou"
      },
      id: "09010304"
    },
    {
      name: {
        km: "ចម្លងគោ",
        latin: "Chamlang Kou"
      },
      id: "09010401"
    },
    {
      name: {
        km: "ចំការលើ",
        latin: "Chamkar Leu"
      },
      id: "09010402"
    },
    {
      name: {
        km: "ស្រែថ្មី",
        latin: "Srae Thmei"
      },
      id: "09010403"
    },
    {
      name: {
        km: "ស្រែត្រាវ",
        latin: "Srae Trav"
      },
      id: "09010404"
    },
    {
      name: {
        km: "ថ្មស",
        latin: "Thma Sa"
      },
      id: "09010405"
    },
    {
      name: {
        km: "កោះស្ដេច",
        latin: "Kaoh Sdach"
      },
      id: "09020101"
    },
    {
      name: {
        km: "ពាមកាយ",
        latin: "Peam Kay"
      },
      id: "09020102"
    },
    {
      name: {
        km: "ព្រែកស្មាច់",
        latin: "Preaek Smach"
      },
      id: "09020103"
    },
    {
      name: {
        km: "ភ្ញីមាស",
        latin: "Phnhi Meas"
      },
      id: "09020201"
    },
    {
      name: {
        km: "កៀនក្រឡាញ់",
        latin: "Kien Kralanh"
      },
      id: "09020202"
    },
    {
      name: {
        km: "តានី",
        latin: "Ta Ni"
      },
      id: "09020203"
    },
    {
      name: {
        km: "ព្រែកខ្សាច់",
        latin: "Preaek Khsach"
      },
      id: "09020301"
    },
    {
      name: {
        km: "សំរុងតាកែវ",
        latin: "Samrong Ta Kaev"
      },
      id: "09020302"
    },
    {
      name: {
        km: "យាយសែន",
        latin: "Yeay Saen"
      },
      id: "09020303"
    },
    {
      name: {
        km: "ជ្រោយប្រស់",
        latin: "Chrouy Pras"
      },
      id: "09030101"
    },
    {
      name: {
        km: "ថ្មី",
        latin: "Thmei"
      },
      id: "09030102"
    },
    {
      name: {
        km: "ភូមិទី១",
        latin: "Phum Ti Muoy"
      },
      id: "09030201"
    },
    {
      name: {
        km: "ភូមិទី២",
        latin: "Phum Ti Pir"
      },
      id: "09030202"
    },
    {
      name: {
        km: "កោះស្រឡៅ",
        latin: "Kaoh Sralau"
      },
      id: "09030203"
    },
    {
      name: {
        km: "កោះអណ្ដែត",
        latin: "Kaoh Andaet"
      },
      id: "09030301"
    },
    {
      name: {
        km: "អន្លង់វ៉ាក់",
        latin: "Anlong Vak"
      },
      id: "09030302"
    },
    {
      name: {
        km: "ដីទំនាប",
        latin: "Dei Tumneab"
      },
      id: "09030401"
    },
    {
      name: {
        km: "កោះកុងក្នុង",
        latin: "Kaoh Kong Knong"
      },
      id: "09030402"
    },
    {
      name: {
        km: "ព្រែកអង្គុញ",
        latin: "Preaek Angkunh"
      },
      id: "09030403"
    },
    {
      name: {
        km: "ត្រពាំងរូង",
        latin: "Trapeang Rung"
      },
      id: "09030404"
    },
    {
      name: {
        km: "ភូមិទី១",
        latin: "Phum Ti Muoy"
      },
      id: "09040101"
    },
    {
      name: {
        km: "ភូមិទី២",
        latin: "Phum Ti Pir"
      },
      id: "09040102"
    },
    {
      name: {
        km: "ភូមិទី៣",
        latin: "Phum Ti Bei"
      },
      id: "09040103"
    },
    {
      name: {
        km: "បឹងឃុនឆាង",
        latin: "Boeng Khun Chhang"
      },
      id: "09040104"
    },
    {
      name: {
        km: "ស្មាច់មានជ័យ",
        latin: "Smach Mean Chey"
      },
      id: "09040105"
    },
    {
      name: {
        km: "ភូមិទី១",
        latin: "Phum Ti Muoy"
      },
      id: "09040201"
    },
    {
      name: {
        km: "ភូមិទី២",
        latin: "Phum Ti Pir"
      },
      id: "09040202"
    },
    {
      name: {
        km: "ភូមិទី៣",
        latin: "Phum Ti Bei"
      },
      id: "09040203"
    },
    {
      name: {
        km: "ភូមិទី៤",
        latin: "Phum Ti Buon"
      },
      id: "09040204"
    },
    {
      name: {
        km: "ស្ទឹងវែង",
        latin: "Stueng Veaeng"
      },
      id: "09040301"
    },
    {
      name: {
        km: "ព្រែកស្វាយ",
        latin: "Preaek Svay"
      },
      id: "09040302"
    },
    {
      name: {
        km: "ប៉ាក់ខ្លង១",
        latin: "Pak Khlang Muoy"
      },
      id: "09050101"
    },
    {
      name: {
        km: "ប៉ាក់ខ្លង២",
        latin: "Pak Khlang Pir"
      },
      id: "09050102"
    },
    {
      name: {
        km: "ប៉ាក់ខ្លង៣",
        latin: "Pak Khlang Bei"
      },
      id: "09050103"
    },
    {
      name: {
        km: "បឹងកាឆាង",
        latin: "Boeng Kachhang"
      },
      id: "09050104"
    },
    {
      name: {
        km: "កោះប៉ោ",
        latin: "Kaoh Pao"
      },
      id: "09050105"
    },
    {
      name: {
        km: "នាងកុក",
        latin: "Neang Kok"
      },
      id: "09050106"
    },
    {
      name: {
        km: "ចាំយាម",
        latin: "Cham Yeam"
      },
      id: "09050107"
    },
    {
      name: {
        km: "ភូមិ១",
        latin: "Phum Muoy"
      },
      id: "09050201"
    },
    {
      name: {
        km: "ភូមិ២",
        latin: "Phum Pir"
      },
      id: "09050202"
    },
    {
      name: {
        km: "កោះចាក",
        latin: "Kaoh Chak"
      },
      id: "09050301"
    },
    {
      name: {
        km: "តាចាត",
        latin: "Ta Chat"
      },
      id: "09050302"
    },
    {
      name: {
        km: "ទួលគគីរលើ",
        latin: "Tuol Kokir Leu"
      },
      id: "09050303"
    },
    {
      name: {
        km: "ទួលគគីរក្រោម",
        latin: "Tuol Kokir Kraom"
      },
      id: "09050304"
    },
    {
      name: {
        km: "អូជ្រៅ",
        latin: "Ou Chrov"
      },
      id: "09060101"
    },
    {
      name: {
        km: "បឹងព្រាវ",
        latin: "Boeng Preav"
      },
      id: "09060102"
    },
    {
      name: {
        km: "ជ្រោយ",
        latin: "Chrouy"
      },
      id: "09060103"
    },
    {
      name: {
        km: "ផ្លោង",
        latin: "Phlaong"
      },
      id: "09060104"
    },
    {
      name: {
        km: "សាលាម្នាង",
        latin: "Sala Mneang"
      },
      id: "09060105"
    },
    {
      name: {
        km: "ទឹកប៉ោង",
        latin: "Tuek Paong"
      },
      id: "09060107"
    },
    {
      name: {
        km: "អន់ឆ្អើត",
        latin: "An Chh'aeut"
      },
      id: "09060201"
    },
    {
      name: {
        km: "ចំបក់",
        latin: "Chambak"
      },
      id: "09060202"
    },
    {
      name: {
        km: "ខ្សាច់ក្រហម",
        latin: "Khsach Kraham"
      },
      id: "09060203"
    },
    {
      name: {
        km: "នាពិសី",
        latin: "Nea Pisei"
      },
      id: "09060204"
    },
    {
      name: {
        km: "តាបែន",
        latin: "Ta Baen"
      },
      id: "09060205"
    },
    {
      name: {
        km: "ព្រែកជីក",
        latin: "Preaek Chik"
      },
      id: "09060206"
    },
    {
      name: {
        km: "ឈូក",
        latin: "Chhuk"
      },
      id: "09060301"
    },
    {
      name: {
        km: "ជី ខ",
        latin: "Chi Kha"
      },
      id: "09060302"
    },
    {
      name: {
        km: "តានី",
        latin: "Ta Ni"
      },
      id: "09060303"
    },
    {
      name: {
        km: "ត្រពាំងកណ្ដោល",
        latin: "Trapeang Kandaol"
      },
      id: "09060304"
    },
    {
      name: {
        km: "ឈើនាង",
        latin: "Chheu Neang"
      },
      id: "09060401"
    },
    {
      name: {
        km: "ជ្រោយស្វាយខាងលិច",
        latin: "Chrouy Svay Khang Lech"
      },
      id: "09060402"
    },
    {
      name: {
        km: "ជ្រោយស្វាយខាងកើត",
        latin: "Chrouy Svay Khang Kaeut"
      },
      id: "09060403"
    },
    {
      name: {
        km: "កំពង់ស្ដាំ",
        latin: "Kampong Sdam"
      },
      id: "09060404"
    },
    {
      name: {
        km: "នេសាទ",
        latin: "Nesat"
      },
      id: "09060405"
    },
    {
      name: {
        km: "ភ្នំស្រឡៅ",
        latin: "Phnum Sralau"
      },
      id: "09060406"
    },
    {
      name: {
        km: "សារ៉ាយ",
        latin: "Saray"
      },
      id: "09060407"
    },
    {
      name: {
        km: "បានទៀត",
        latin: "Ban Tiet"
      },
      id: "09060501"
    },
    {
      name: {
        km: "ដងពែង",
        latin: "Dang Peaeng"
      },
      id: "09060502"
    },
    {
      name: {
        km: "ព្រាំង",
        latin: "Prang"
      },
      id: "09060503"
    },
    {
      name: {
        km: "ព្រះអង្គកែវ",
        latin: "Preah Angk Kaev"
      },
      id: "09060504"
    },
    {
      name: {
        km: "តាធង",
        latin: "Ta Thaong"
      },
      id: "09060505"
    },
    {
      name: {
        km: "បាក់អង្រុត",
        latin: "Bak Angrot"
      },
      id: "09060506"
    },
    {
      name: {
        km: "ពោធិបឹង",
        latin: "Por Boeung"
      },
      id: "09060507"
    },
    {
      name: {
        km: "ចំការក្រោម",
        latin: "Chamkar Kraom"
      },
      id: "09060601"
    },
    {
      name: {
        km: "ខ្លុង",
        latin: "Khlong"
      },
      id: "09060602"
    },
    {
      name: {
        km: "ស្រែអំបិល",
        latin: "Srae Ambel"
      },
      id: "09060603"
    },
    {
      name: {
        km: "ត្រពាំង",
        latin: "Trapeang"
      },
      id: "09060604"
    },
    {
      name: {
        km: "ត្រៀក",
        latin: "Triek"
      },
      id: "09060605"
    },
    {
      name: {
        km: "វាលជើង",
        latin: "Veal Cheung"
      },
      id: "09060606"
    },
    {
      name: {
        km: "វាលត្បូង",
        latin: "Veal Tboung"
      },
      id: "09060607"
    },
    {
      name: {
        km: "សា្ពនក្ដារ",
        latin: "Spean Kdar"
      },
      id: "09070101"
    },
    {
      name: {
        km: "កណ្ដោល",
        latin: "Kandaol"
      },
      id: "09070102"
    },
    {
      name: {
        km: "ត្រពាំងខា្នរ",
        latin: "Trapeang Khnar"
      },
      id: "09070103"
    },
    {
      name: {
        km: "ចំណារ",
        latin: "Chamnar"
      },
      id: "09070201"
    },
    {
      name: {
        km: "ប្រឡាយ",
        latin: "Pralay"
      },
      id: "09070202"
    },
    {
      name: {
        km: "សំរោង",
        latin: "Samraong"
      },
      id: "09070203"
    },
    {
      name: {
        km: "ទ័ពឃ្លៃ",
        latin: "Toap Khley"
      },
      id: "09070204"
    },
    {
      name: {
        km: "ជំនាប់",
        latin: "Chumnoab"
      },
      id: "09070301"
    },
    {
      name: {
        km: "ច្រកឫស្សី",
        latin: "Chrak Ruessei"
      },
      id: "09070302"
    },
    {
      name: {
        km: "ត្រពាំងឈើត្រាវ",
        latin: "Trapeang Chheu Trav"
      },
      id: "09070403"
    },
    {
      name: {
        km: "គគីរជ្រុំ",
        latin: "Kokir Chrum"
      },
      id: "09070404"
    },
    {
      name: {
        km: "ជីផាត",
        latin: "Chi Phat"
      },
      id: "09070501"
    },
    {
      name: {
        km: "កំលត",
        latin: "Kamlot"
      },
      id: "09070502"
    },
    {
      name: {
        km: "ទឹកល្អក់",
        latin: "Tuek L'ak"
      },
      id: "09070503"
    },
    {
      name: {
        km: "ជាំស្លា",
        latin: "Choam Sla"
      },
      id: "09070504"
    },
    {
      name: {
        km: "កោះ",
        latin: "Kaoh"
      },
      id: "09070601"
    },
    {
      name: {
        km: "ព្រែកស្វាយ",
        latin: "Preaek Svay"
      },
      id: "09070602"
    }
  ]

  export default villages