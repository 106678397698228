import { Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { AddButton as BaseAddButton } from 'Components'
import { UserAdd } from './Add/UserAdd'
import { UserList } from './UserList'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { userStore } from 'Models'
import { toJS } from 'mobx'

const Container = styled.div``

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`

const AddButton = styled(BaseAddButton)`
  margin-left: auto;
`

interface Props {}
export const UserPage = observer((props: Props) => {
  const [showAddUserModal, setShowAddUserModal] = useState(false)

  useEffect(() => {
    loadUsers()
  }, [])

  async function loadUsers() {
    try {
      await userStore.loadUsers()
    } catch (e: any) {
      message.error(e.message)
    }
  }

  // console.log('user', toJS(userStore.users))

  return (
    <Container>
      <Row>
        <div>Users</div>
        <AddButton onClick={() => setShowAddUserModal(true)}></AddButton>
      </Row>
      <UserList users={userStore.users.slice()}></UserList>
      {showAddUserModal && (
        <Modal
          title="បង្កើតអ្នកប្រើប្រាស់ (Add User)"
          visible={showAddUserModal}
          onCancel={() => setShowAddUserModal(false)}
          footer={[]}
        >
          <UserAdd onSaveFinish={() => setShowAddUserModal(false)}></UserAdd>
        </Modal>
      )}
    </Container>
  )
})
