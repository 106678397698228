import { Checkbox, Form, Input } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Button } from 'Components'
import React from 'react'
import styled from 'styled-components'

const StyledForm = styled(Form)`
  margin-top: 30px;
  margin-left: -50px;
`

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}

interface Props {
  saving: boolean
  onSubmit: (value: unknown, form: FormInstance<any>) => void
}

export const CollectionPointForm = (props: Props) => {
  const {
    onSubmit,
    saving
  } = props
  const [form] = Form.useForm()

  const initialValues = {
    Type: 'Public',
  }

  function onFinish(values: unknown) {
    onSubmit && onSubmit(values, form)
  }

  function onFinishFailed() {
    console.log('on finish failed')
  }

  return (
    <StyledForm
      {...layout}
      name="basic"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Name"
        name="Name"
        rules={[{ required: false, message: 'Please input name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Type"
        name="Type"
        rules={[{ required: true, message: 'Please input your type' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout} name="Organic" valuePropName="checked">
        <Checkbox>Organic</Checkbox>
      </Form.Item>
      <Form.Item {...tailLayout} name="Waste" valuePropName="checked">
        <Checkbox>Waste</Checkbox>
      </Form.Item>
      <Form.Item {...tailLayout} name="Recycle" valuePropName="checked">
        <Checkbox>Recycle</Checkbox>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button loading={saving} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </StyledForm>
  )
}
