import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 5rem;
  margin: 0 5px 0 5px;
`

type Props = {
  onChange?: (value: SelectValue) => void
  onClear?: () => void
  disabled?: boolean
  value?: number
  defaultValue?: number
  placeholder?: string
  options?: { value: any; label: any }[]
}

export const FilterDaySelector = (props: Props) => {
  const { value, disabled, defaultValue, placeholder, options } = props

  function onChange(givenValue: any) {
    Number.isInteger(givenValue) &&
      props.onChange &&
      props.onChange(givenValue.toString())
  }

  function onClear() {
    props.onClear && props.onClear()
  }

  const defaultOptions = [
    {
      value: 1,
      label: 'Today',
    },
    {
      value: 3,
      label: 'Last 3 days',
    },
    {
      value: 7,
      label: 'Last one week',
    },
  ]

  const activeOptions = options && options.length > 0 ? options : defaultOptions

  return (
    <StyledSelect
      style={{ minWidth: 200 }}
      placeholder={placeholder || 'Please select'}
      value={value + '' ? value : undefined}
      onChange={onChange}
      onClear={onClear}
      disabled={disabled}
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={true}
      allowClear
    >
      <Option disabled key={''} value={''}>
        {placeholder || 'Please select'}
      </Option>
      {activeOptions.map((option) => {
        return (
          <Option key={option?.value - 1} value={option?.value - 1}>
            {option?.label}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
