import React, { useEffect, useRef, useState } from 'react'
import socketIOClient, { Socket } from 'socket.io-client'

import { WialonTruck } from 'Models/TruckModel'
const SOCKET_SERVER_URL = 'https://truck-socket-server.samraam.com'
// const SOCKET_SERVER_URL = 'http://localhost:4000'

export const useLiveTruckLocation = () => {
  const [currentSubscribedRoute, setCurrentSubscribedRoute] = useState<string>()
  const [locations, setLocations] = useState<WialonTruck[]>([])
  const [connected, setConnected] = useState(false)
  const [error, setError] = useState('')
  const socketRef = useRef<Socket>()

  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      transports: ['websocket'],
      auth: {
        token:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjUwMjksImlhdCI6MTYxMjA4NzA3MX0.je1MrFJ3GY_zkCxvAz4Vh3Hjd605rf2jpR4k7LcYtTg',
      },
    })

    socketRef.current.on('locations', (givenLocations) => {
      // console.log('got location: ', givenLocations)
      setLocations(givenLocations)
    })

    socketRef.current.on('connect', () => {
      console.log('truck location socket connected.')
      setError('')
      setConnected(true)
    })

    socketRef.current.on('disconnect', () => {
      console.log('disconnect: ')
      setConnected(false)
    })

    socketRef.current.on('connect_error', (givenError) => {
      console.log('connect_error: ', givenError)
      setConnected(false)
      setError(givenError.message)
    })

    return () => {
      socketRef?.current?.disconnect()
    }
  }, [])

  const subscribe = (routeId: string) => {
    console.log('subscribe: ', routeId)
    if (currentSubscribedRoute) {
      unsubscribe(currentSubscribedRoute)
    }
    socketRef?.current?.emit('subscribe', routeId)
    setCurrentSubscribedRoute(routeId)
  }

  const unsubscribe = (routeId: string) => {
    socketRef?.current?.emit('unsubscribe', routeId)
  }

  const filterTruckSubscribe = ({ coordinate, distance }: any) => {
    socketRef?.current?.emit('filterTruckSubscribe', { coordinate, distance })
  }

  const filterTruckUnsubscribe = () => {
    socketRef?.current?.emit('filterTruckUnsubscribe')
  }

  const clearLocation = () => {
    setLocations([])
  }

  return {
    locations,
    clearLocation,
    subscribe,
    unsubscribe,
    connected,
    error,
    filterTruckSubscribe,
    filterTruckUnsubscribe,
  }
}
