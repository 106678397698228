import axios, { CancelTokenSource } from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

export const getUsers = async () => {
  const url = `${apiBaseUrl}/users`
  const result = await axios.get(url)
  return (result.data as any[]).map((user) => ({ id: user._id, ...user }))
}

export const add = async (values: any) => {
  const url = `${apiBaseUrl}/users/signup`
  const data = {
    ...values,
  }
  const response = await axios.post(url, data)
  return response.data
}

export const edit = async (values: any) => {
  const url = `${apiBaseUrl}/users/role/${values.id}`
  const response = await axios.patch(url, { ...values })
  return response.data
}

export const deleteUser = async (id: string) => {
  const url = `${apiBaseUrl}/users/${id}`
  const response = await axios.delete(url)
  return response.data
}

let searchRequest: CancelTokenSource
export const searchUsers = async (query: string) => {
  if (searchRequest) {
    searchRequest.cancel()
  }

  searchRequest = axios.CancelToken.source()
  const url = `${apiBaseUrl}/api/users?${encodeURI(query)}`
  const result = await axios.get(url, { cancelToken: searchRequest.token })
  if (!result) {
    return []
  }
  if (result.data.error) {
    console.log('result.data.error: ', result.data.error)
    throw new Error('error' + result.data.error)
  }
  return result.data
}

export const getAllLocations = async () => {
  const url = `${apiBaseUrl}/locations`
  const response = await axios.get(url)
  return response.data
}

export const getLocationByCode = async (code: string) => {
  const url = `${apiBaseUrl}/locations/${code}/code`
  const response = await axios.get(url)
  return response.data
}
