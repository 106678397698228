import React from 'react'
import { Button } from 'antd'
import mapPin from 'Assets/map-pin.png'

interface Props<T> {
  size: number
  entity: T
  primary?: boolean
  onClick?: (entity: T) => void
}

export function MapPinButton<T>(props: Props<T>) {
  return (
    <Button
      onClick={() => props.onClick && props.onClick(props.entity)}
      shape='circle'
      type={props.primary? 'primary' : undefined}
    >
      <img
        width={props.size}
        src={mapPin}
        alt=""
      />
    </Button>
  )
}

