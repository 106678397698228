import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

export const CollectionCompany = types
  .model('CollectionCompany', {
    OBJECTID: types.string,
    Name: types.maybeNull(types.string),
    RegionID: types.maybeNull(types.string),
    isDeleting: false,
  })
  .actions((self) => ({
    edit: flow(function* (values) {
      const data = {
        ...values,
        id: self.OBJECTID,
      }
      yield api.edit(data)
      const updated = {
        ...self,
        ...values,
      }
      applySnapshot(self, updated)
    }),
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(`${self.OBJECTID}`)
      const store = getParent(self, 2) as Instance<
        typeof CollectionCompanyStore
      >
      store.remove(self)
    }),
  }))

const CollectionCompanyStore = types
  .model('CollectionCompanyStore', {
    items: types.optional(types.array(CollectionCompany), []),
    loading: false,
  })
  .actions((self) => ({
    loadAll: flow(function* () {
      applySnapshot(self.items, [])

      self.loading = true
      const items = yield api.getAll()
      const mappedItems = items.map((item: any) => {
        return {
          ...item.attributes,
          OBJECTID: item.attributes.OBJECTID + '',
        }
      })
      applySnapshot(self.items, mappedItems)
      self.loading = false
    }),

    add: flow(function* (values: any) {
      const data = {
        features: {
          attributes: {
            ...values,
          },
        },
      }
      const result = yield api.add(data)
      if (result.error) {
        alert('Cannot add member ' + result.error.details[0])
      } else {
        const OBJECTID = result?.addResults[0]?.objectId + ''
        const added = { ...values, OBJECTID }

        self.items.push(added)
        return result
      }
    }),

    remove(item: any) {
      self.items.splice(self.items.indexOf(item), 1)
    },
  }))

export interface ICollectionCompany
  extends Instance<typeof CollectionCompany> {}

export const collectionCompanyStore = CollectionCompanyStore.create({
  items: [],
})
