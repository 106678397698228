import * as api from './api'

import { Instance, applySnapshot, flow, types } from 'mobx-state-tree'

import moment from 'moment'
import { user } from 'Models/UserModel'

const Coordinate = types.model('Coordinate', {
  lat: types.number,
  lng: types.number,
})

const Path = types.model('Path', {
  id: types.string,
  coordinates: types.array(Coordinate),
})

export const Route = types
  .model('Route', {
    OBJECTID: types.identifier,
    Name: types.maybeNull(types.string),
    Paths: types.array(Path),
    HasSchedule: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    RouteColor: types.maybe(types.string),
  })
  .actions((self) => ({
    edit: flow(function* (values) {
      const data = {
        OBJECTID: self.OBJECTID,
        ...values,
        Editor: user.name,
        EditDate: moment().valueOf(),
      }
      yield api.patchRoute(data)
      applySnapshot(self, data)
    }),
  }))

const RouteStore = types
  .model('RouteStore', {
    routes: types.optional(types.array(Route), []),
    selectedRoute: types.maybe(types.safeReference(Route)),
  })
  .actions((self) => ({
    loadRoutes: flow(function* (query) {
      applySnapshot(self.routes, [])
      const routes = yield api.getRoutes(query)
      const mappedRoutes = routes?.map((route: any) => {
        const Paths = route.geometry.rings.map((path: any, index: number) => {
          const coordinates = path.map((coordinate: any) => {
            return {
              lng: coordinate[0],
              lat: coordinate[1],
            }
          })
          return {
            id: `${route.attributes.OBJECTID}-${index}`,
            coordinates,
          }
        })
        return {
          ...route.attributes,
          OBJECTID: route.attributes.OBJECTID + '',
          Paths,
        }
      })
      applySnapshot(self.routes, mappedRoutes)
    }),

    setSelectedRoute(route: string | undefined) {
      // @ts-ignore:
      self.selectedRoute = route
    },
  }))

export interface IRoute extends Instance<typeof Route> {}
export const routeStore = RouteStore.create({
  routes: [],
  selectedRoute: '1',
})
