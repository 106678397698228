import React from 'react'
import styled from 'styled-components'
import { FormInstance } from 'antd/lib/form'
import { DeleteConfirmButton } from '../DeleteConfirmButton'
import { Admin } from 'Auth/Role'

const Table = styled.table`
  margin: 1.5rem 0;
  width: 100%;
  border: 1px solid #000;

  tr {
    width: 100%;
  }

  th {
    border: 1px solid #000;
    padding: 4px;
    font-weight: bold;
  }
`

interface Props {
  form?: FormInstance
  calculationOptions: Record<string | number, any>[]
  setCalculationOptions: (
    calculationOptions: Record<string | number, any>[]
  ) => void
}

export const CalculationOptionsTable = (props: Props) => {
  function removeItemFromCalculationOptionList(
    calculationOption: Record<string | number, any>
  ) {
    const newList = props.calculationOptions.filter((calOpt) => {
      return calculationOption !== calOpt
    })
    props.form && props.form.setFieldsValue({ calculationOptions: newList })
    props.setCalculationOptions(newList)
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Unit Price</th>
          <th>Landfill Price</th>
          <th>Minimum Units</th>
          <th>Maximum Units</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>
        {props.calculationOptions.map((calculationOption, index) => {
          return (
            <tr key={index}>
              <td>{calculationOption.name}</td>
              <td>{calculationOption.unitPrice}</td>
              <td>{calculationOption.landfillPrice}</td>
              <td>{calculationOption.minimumUnit}</td>
              <td>{calculationOption.maximumUnit}</td>
              <td>
                <DeleteConfirmButton
                  onConfirmClick={() =>
                    removeItemFromCalculationOptionList(calculationOption)
                  }
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
