import {
  DownloadButton,
  MoreButton as BaseMoreButton,
  RouteButton,
} from 'Components'
import { billStore, IProperty, propertyStore } from 'Models'
import React from 'react'
import { Button, Space, Spin, Table, Tag } from 'antd'
import styled from 'styled-components'
import { MapPinButton } from 'Components/MapPinButton/MapPinButton'
import { isUserMOE } from 'Auth/Authorization'
import { renderStatus } from 'Utils/property'
import { toJS } from 'mobx'

const Container = styled.section`
  height: 100%;
  overflow: scroll;
`

const Title = styled.h3`
  margin-top: 10px;
`

const MoreButton = styled(BaseMoreButton)`
  /* margin-left: auto; */
`

const BillButton = styled(Button)`
  /* margin-left: auto; */
`

const SelectedDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #50e3c2;
  margin-right: 6px;
`

const SelectedDotWrapper = styled.div`
  display: flex;
  align-items: center;
`

interface Props {
  showDistributeMonth?: boolean
  distributeMonthCheck?: string
  tableTitle?: string
  properties?: IProperty[]
  loading?: boolean
  isPropertiesHasNoLocation?: boolean
  onPropertyClick?: (property: IProperty) => void
  onMoreButtonClick?: (property: IProperty) => void
  onRouteButtonClick?: (property: IProperty) => void
  onMapPinButtonClick?: (property: IProperty) => void
  onBillButtonClick?: (property: IProperty) => void
  onDownloadButtonClick?: (property: IProperty) => void
}

const RedStar = () => <span style={{ color: 'red' }}>*</span>

export const PropertyList = (props: Props) => {
  const {
    showDistributeMonth = false,
    distributeMonthCheck = '',
    tableTitle = 'Properties',
    properties = [],
    loading,
    isPropertiesHasNoLocation,
    onPropertyClick,
    onMoreButtonClick,
    onRouteButtonClick,
    onMapPinButtonClick,
    onBillButtonClick,
    onDownloadButtonClick,
  } = props

  const isMOE = isUserMOE()

  function resetTable() {
    propertyStore.setSelectedProperty(undefined)
  }

  const column1: any[] = [
    {
      title: 'Property ID',
      dataIndex: 'OBJECTID',
      key: 'OBJECTID',
      render: function render(text: string, record: IProperty) {
        console.log('record', toJS(record))

        return (
          <SelectedDotWrapper>
            {record.isSelected && <SelectedDot />}
            {`${text}`}
            <span title="មានភ្ជាប់រូបភាពរួចរាល់">
              &nbsp;{record?.Photo && '📷'}
            </span>
            <span title="ជ្រើសរើសប្រភេទរួចរាល់">
              &nbsp;{record?.Type ? '🎯' : ''}
            </span>
          </SelectedDotWrapper>
        )
      },
    },
    {
      title: 'Owner',
      dataIndex: 'OwnerName',
      key: 'OwnerName',
      render: (text: string, record: IProperty) => {
        return (
          <span title="have route">
            {text} {record.RouteID && <RedStar />}
          </span>
        )
      },
    },
    {
      title: 'Contact',
      dataIndex: 'OwnerPhone',
      key: 'OwnerPhone',
      render: (text: string, record: IProperty) => {
        return <span>{record?.OwnerPhone}</span>
      },
    },
    {
      title: 'Collector',
      dataIndex: 'Collector',
      key: 'Collector',
    },
    {
      title: 'Status',
      dataIndex: 'IsActive',
      key: 'IsActive',
      render: function render(text: string, record: IProperty) {
        return renderStatus(record)
      },
    },
  ]

  let column2: any[] = []
  if (showDistributeMonth) {
    column2 = [
      {
        title: 'Distribute Month',
        dataIndex: 'DistributeMonth',
        key: 'DistributeMonth',
        render: function render(text: string, record: IProperty) {
          return text?.includes(distributeMonthCheck) ? (
            <Tag color="green">Distributed</Tag>
          ) : (
            ''
          )
        },
      },
    ]
  }

  const column3: any[] = [
    {
      title: 'Action',
      key: 'action',
      render: function render(text: string, property: IProperty) {
        const shouldShowBillButton =
          !!property?.IsActive && !!property.Collector

        return (
          <Space>
            {onMoreButtonClick && (
              <MoreButton
                onClick={() => onMoreButtonClick && onMoreButtonClick(property)}
              ></MoreButton>
            )}
            {onBillButtonClick && shouldShowBillButton && (
              <Button
                onClick={() => onBillButtonClick && onBillButtonClick(property)}
                shape="circle"
              >
                Bill
              </Button>
            )}
            {onDownloadButtonClick && !isMOE && !!property?.IsActive && (
              <DownloadButton
                onClick={() =>
                  onDownloadButtonClick && onDownloadButtonClick(property)
                }
              />
            )}
            {isPropertiesHasNoLocation && !isMOE && (
              <MapPinButton
                entity={property}
                size={20}
                onClick={() =>
                  onMapPinButtonClick && onMapPinButtonClick(property)
                }
                primary={
                  property.OBJECTID === propertyStore.selectedProperty?.OBJECTID
                }
              />
            )}
            {onRouteButtonClick && !isPropertiesHasNoLocation && (
              <RouteButton onClick={() => onRouteButtonClick(property)} />
            )}
          </Space>
        )
      },
    },
  ]

  const columns = column1.concat(column2, column3)

  return (
    <Container>
      <Title style={{ justifyContent: 'space-between', display: 'flex' }}>
        <span>
          {tableTitle} ({properties?.length})
        </span>
        {propertyStore.selectedProperty && (
          <Button type="primary" onClick={resetTable} ghost>
            Reset Table
          </Button>
        )}
      </Title>
      {/* {loading && <Spin />} */}
      <Table
        loading={loading}
        columns={columns}
        rowKey={(row) => row.OBJECTID}
        dataSource={properties.slice()}
        summary={(pageData) => {
          if (!showDistributeMonth) {
            return ''
          }

          let count = 0
          pageData?.map((item) => {
            if (!item?.DistributeMonth?.includes(distributeMonthCheck)) {
              count++
            }
          })

          return properties?.length > 0 ? (
            <Tag color="blue" style={{ marginTop: 10 }}>
              ផ្ទះអាចទាញយកបានចំនួន {count}
            </Tag>
          ) : (
            ''
          )
        }}
        onRow={(property, index) => {
          return {
            onClick: () =>
              !isPropertiesHasNoLocation &&
              onPropertyClick &&
              onPropertyClick(property), // click row
          }
        }}
      />
    </Container>
  )
}
