import { Form, message } from 'antd'
import React, { useState } from 'react'

import { BusinessForm } from '../Form/BusinessForm'
import { IBusiness } from 'Models/BusinessModel/BusinessModel'
import moment from 'moment'
import styled from 'styled-components'

const Container = styled.section``

interface Props {
  business: IBusiness | undefined
  onSaveFinish?: () => void
}

export const BusinessEdit = (props: Props) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const { business } = props

  const initialValues = {
    ...business,
    type: business?.type + '',
  }

  async function onSubmit(values: any) {
    const data = {
      ...values,
    }
    setSaving(true)
    try {
      await business?.edit(data)
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return (
    <Container>
      <BusinessForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
      ></BusinessForm>
    </Container>
  )
}
