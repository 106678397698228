import React from 'react';
import { DatePicker, Select, Button, Table, Col, Row, Form } from 'antd';
import useAxios from 'axios-hooks';
import moment from 'moment-timezone';

const { Option } = Select;

console.log(moment().toLocaleString())

const formCol = {xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12};

const formatDate = (value: any) => value ? moment(value).format('DD/MM/YYYY') : '';
const formatTime = (value: any) => value ? moment(value).format('LT') : '';

const ScheduleHistory = () => {
  const [form] = Form.useForm();
  const [{ data, loading, error }, refetch] = useAxios({ url: '/api/route-history/filter' }, { manual:true })
  // ?date=03-11-2022&status=មិនទាន់បានទៅយក

  const handleFormFinish = ({ date, status }: any) => {
    refetch({
      params: {
        status,
        date: formatDate(date)
      }
    });
  }

  console.log('data: ', data);

  if (error) {
    console.log('error: ', error);
  }

  const columns = [
    {
      title: 'Route Id',
      dataIndex: 'routeId',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'From Hour',
      dataIndex: 'fromHour',
      render: formatTime
    },
    {
      title: 'To Hour',
      dataIndex: 'toHour',
      render: formatTime
    },
  ];

  return (
    <Form form={form} autoComplete="off" onFinish={handleFormFinish}>
      <Row gutter={[16,16]}>
        <Col {...formCol}>
          <Form.Item noStyle name="date">
            <DatePicker placeholder="Select date" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col {...formCol}>
          <Form.Item noStyle name="status">
            <Select placeholder="Select status" style={{ width: '100%' }}
            >
              <Option value="ទៅយករួចហើយ" key="ទៅយករួចហើយ">
                ទៅយករួចហើយ
              </Option>
              <Option value="មិនទាន់បានទៅយក" key="មិនទាន់បានទៅយក">
                មិនទាន់បានទៅយក
              </Option>
            </Select>
          </Form.Item>
        </Col> 
        <Col xs={24}>
          <Button htmlType="submit">Search</Button>
        </Col>
        <Col xs={24}>
          <Table rowKey={(row: any) => row.id} loading={loading} size="small" dataSource={data || []} columns={columns} />
        </Col>
      </Row>
    </Form>
  )
}

export default ScheduleHistory;
