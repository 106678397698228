/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Upload, Form, Image } from 'antd'
import _ from 'lodash'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const ImagesUpload = ({
  actionUrl,
  label = 'រូបភាព',
  name = 'image_url',
  oldFiles = [],
  rules = {},
  limit = 5,
  required = false,
  className = '',
  inputClassName = '',
}) => {
  const initialFileList = _.isEmpty(oldFiles) ? [] : oldFiles
  const [fileList, setFileList] = useState(initialFileList)

  const [visible, setVisible] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(null)

  const onChange = ({ file, fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const handlePreview = async (file) => {
    console.log('handlePreview start')
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    console.log('handlePreview end')
    setPreviewUrl(file.url || file.preview)
    setVisible(true)
  }

  const onPreview = async (file) => {
    let src = file.url
    if (src) {
      window.open(src, '_blank').focus()
    } else {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow.document.write(image.outerHTML)
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }

    return e && e.fileList
  }

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        required={required}
        labelCol={{ span: 4 }}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={rules}
        className={className}
      >
        <Upload
          name={name}
          action={actionUrl}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={handlePreview}
          className={inputClassName}
          maxCount={limit}
        >
          + Upload
        </Upload>
      </Form.Item>
      <Image
        width={200}
        style={{ display: 'none' }}
        src={previewUrl ? previewUrl + '?dim=50' : null}
        preview={{
          visible,
          src: previewUrl,
          onVisibleChange: (value, prev) => {
            setVisible(value)
          },
        }}
      />
    </>
  )
}
