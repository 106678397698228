import axios, { CancelTokenSource } from 'axios'

import { environment } from 'environment'

const { apiBaseUrl } = environment

export type Citizen = {
  attributes: {
    OBJECTID: string
    Name: string
    Phone: string
    properties: any
  }
}

let ajaxRequest: CancelTokenSource
export const getCitizens = async (query: string): Promise<Citizen[]> => {
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }

  ajaxRequest = axios.CancelToken.source()
  const url = `${apiBaseUrl}/api/citizens/query?${encodeURI(query)}`

  const result = await axios.get(url, { cancelToken: ajaxRequest.token })
  if (!result) {
    return []
  }
  if (result.data.error) {
    console.log('result.data.error: ', result.data.error)
    throw new Error('error' + result.data.error)
  }
  return result.data
}
