import { Form, message } from 'antd'
import React, { useState } from 'react'

import { PropertyForm } from '../Form/PropertyForm'
import moment from 'moment'
import { propertyStore } from 'Models/PropertyModel'
import styled from 'styled-components'
import { user } from 'Models'
import { componentLevelAuthorization } from 'Auth/Authorization'
import { Admin, WasteCompany } from 'Auth/Role'

const Container = styled.section``

interface Props {
  citizenId: string | undefined
  onSaveFinish?: () => void
}

export const PropertyAddContainer = (props: Props) => {
  const [saving, setSaving] = useState(false)
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>('')
  const [form] = Form.useForm()

  const initialValues = {}

  async function onSubmit(values: any) {
    const data = {
      ...values,
      Editor: user.name,
      EditDate: moment().valueOf(),
      CreateDate: moment().valueOf(),
      CitizenID: props.citizenId,
    }
    setSaving(true)
    try {
      await propertyStore.addProperty(data)
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  function onPhotoUploaded(url?: string) {
    setUploadedPhotoUrl(url ?? '')
  }

  return componentLevelAuthorization(
    <Container>
      <PropertyForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
        onPhotoUploaded={onPhotoUploaded}
        uploadedPhotoUrl={uploadedPhotoUrl}
      ></PropertyForm>
    </Container>,
    [Admin, WasteCompany]
  )
}
