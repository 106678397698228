import { IBusiness, businessStore } from 'Models/BusinessModel/BusinessModel'
import React, { useState } from 'react'
import { Select, message } from 'antd'

import styled from 'styled-components'

const { Option } = Select

interface SelectProp {
  loading: boolean
}

const StyledSelect = styled(Select)<SelectProp>`
  width: 250px;
  color: ${(props) => (props.loading ? 'coral' : 'black')};
`

interface Props {
  onBusinessIdSelected?: (id: string) => void
  onBusinessSelected?: (business: IBusiness) => void
}

export const BusinessSearchInput = (props: Props) => {
  const { onBusinessIdSelected, onBusinessSelected } = props
  const { businesses } = businessStore
  const [loading, setLoading] = useState(false)

  async function search(query: string) {
    setLoading(true)
    const queryString = `where=Label LIKE '${query}%'`
    try {
      await businessStore.searchBusinesses(queryString)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }
    }
    setLoading(false)
  }

  function onChange(value: any) {
    const business = businesses.find((business) => {
      return business.OBJECTID + '' === value
    })
    if (business) {
      onBusinessSelected && onBusinessSelected(business)
    }
    onBusinessIdSelected && onBusinessIdSelected(value + '')
  }

  function onSearch(val: string) {
    if (val.length > 0) {
      search(val)
    } else {
      businessStore.emptyBusinesses()
    }
  }

  return (
    <StyledSelect
      {...props}
      showSearch
      loading={loading}
      placeholder="Type to Search Business"
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option) => {
        if (option && option.children) {
          const match =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          return match
        } else {
          return false
        }
      }}
    >
      <Option key={'NONE'} value={''}>
        None
      </Option>
      {businesses.map((business) => {
        return (
          <Option key={business.OBJECTID} value={business.OBJECTID + ''}>
            {`${business.Label} | ${business.Label_kh}`}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
