import {
  Button as BaseButton,
  CitizenSelector as BaseCitizenSelector,
} from 'Components'
import { IProperty, propertyStore } from 'Models/PropertyModel'

import { PropertyList } from './PropertyList'
import React from 'react'
import { Space } from 'antd'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
`

const Controls = styled.div``

const Button = styled(BaseButton)`
  margin-left: 0px;
  width: 100%;
`

const CitizenSelector = styled(BaseCitizenSelector)`
  width: 100% !important;
`

let currentSelectedProperty: IProperty | undefined

interface Props {
  onPropertySelected?: (property: IProperty) => void
  onLoadAllPropertyClick?: () => void
  loading?: boolean
  onCitizenIdSelected?: (id: string) => void
  onMoreButtonClick?: (property: IProperty) => void
}

export const ControlPanel = observer((props: Props) => {
  const { onPropertySelected, onLoadAllPropertyClick, loading } = props
  const { properties } = propertyStore

  function onLoadAllPropertyButtonClick() {
    currentSelectedProperty = undefined
    onLoadAllPropertyClick && onLoadAllPropertyClick()
  }

  function onCitizenIdSelected(citizenId: string) {
    props.onCitizenIdSelected && props.onCitizenIdSelected(citizenId)
    currentSelectedProperty = undefined
  }

  function onPropertyClick(property: IProperty) {
    if (currentSelectedProperty) {
      currentSelectedProperty.setSelected(false)
    }
    property.setSelected(true)
    currentSelectedProperty = property
    onPropertySelected && onPropertySelected(property)
  }

  return (
    <Container>
      <Controls>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Button onClick={onLoadAllPropertyButtonClick}>
            Load Route & Properties
          </Button>
          <CitizenSelector
            onCitizenIdSelected={onCitizenIdSelected}
          ></CitizenSelector>
        </Space>
      </Controls>

      <PropertyList
        onPropertyClick={onPropertyClick}
        onMoreButtonClick={props.onMoreButtonClick}
        properties={properties}
        loading={loading}
      ></PropertyList>
    </Container>
  )
})
