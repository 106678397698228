import axios from 'axios'

import { environment } from 'environment'
const { apiBaseUrl } = environment

export const searchLogs = async (keyword: string) => {
  let url = `${apiBaseUrl}/invoice-logs/check-logs`

  const result = await axios.get(url, { params: { keyword } })

  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}
