import * as api from './api'

import { IRoute, Route } from 'Models/RouteModel'
import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import moment from 'moment'
import { user } from 'Models/UserModel'

export const Schedule = types
  .model('Schedule', {
    OBJECTID: types.identifier,
    FromHour: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.string)
    ),
    ToHour: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.string)
    ),
    TruckID: types.maybeNull(types.string),
    Monday: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    Tuesday: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    Wednesday: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    Thursday: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    Friday: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    Saturday: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    Sunday: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.boolean)
    ),
    RouteID: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.string)
    ),
    Route: types.maybeNull(Route),
    isDeleting: false,
    isSaving: false,
  })
  .actions((self) => ({
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteSchedule(self.OBJECTID)
      const store = getParent(self, 2) as Instance<typeof ScheduleStore>
      self.isDeleting = false
      store.removeSchedule(self)
    }),
    loadRoute: flow(function* () {
      if (self.RouteID) {
        const routes = yield api.getRouteById(self.RouteID)
        if (routes.length > 0) {
          const route = routes[0]
          const Paths = route.geometry.paths.map((path: any, index: number) => {
            const coordinates = path.map((coordinate: any) => {
              return {
                lng: coordinate[0],
                lat: coordinate[1],
              }
            })
            return {
              id: `${route.attributes.OBJECTID}-${index}`,
              coordinates,
            }
          })

          const values = {
            ...route.attributes,
            OBJECTID: route.attributes.OBJECTID + '',
            Paths,
          }
          self.Route = values
        }
      }
    }),
    edit: flow(function* (values) {
      self.isSaving = true
      const data = {
        OBJECTID: self.OBJECTID,
        ...values,
        FromHour: moment(values?.FromHour, 'HH:mm').valueOf(),
        ToHour: moment(values?.ToHour, 'HH:mm').valueOf(),
        Editor: user.id,
        EditDate: moment().valueOf(),
      }
      yield api.patchSchedule(data)
      applySnapshot(self, data)
      self.isSaving = false
    }),
  }))

const ScheduleStore = types
  .model('ScheduleStore', {
    schedules: types.optional(types.array(Schedule), []),
  })
  .actions((self) => ({
    loadSchedulesByRoute: flow(function* (routeId) {
      applySnapshot(self.schedules, [])
      const schedules = yield api.getSchedulesByRoute(routeId)

      const mappedSchedules = schedules?.map((schedule: any) => {
        return {
          ...schedule.attributes,
          OBJECTID: schedule.attributes.OBJECTID + '',
          FromHour: moment(schedule?.attributes?.FromHour).format('HH:mm'),
          ToHour: moment(schedule?.attributes?.ToHour).format('HH:mm'),
        }
      })

      applySnapshot(self.schedules, mappedSchedules)
    }),

    setSchedules(schedules: ISchedule[]) {
      applySnapshot(self.schedules, schedules)
    },
    removeSchedule(item: any) {
      self.schedules.splice(self.schedules.indexOf(item), 1)
    },
    addSchedule: flow(function* (schedule) {
      const userName = user.name
      const data = {
        features: {
          attributes: {
            ...schedule,
            FromHour: moment(schedule?.FromHour, 'HH:mm').valueOf(),
            ToHour: moment(schedule?.ToHour, 'HH:mm').valueOf(),
            Editor: userName,
            EditDate: moment().valueOf(),
            CreateDate: moment().valueOf(),
          },
        },
      }

      const result = yield api.addSchedule(data)

      const OBJECTID = result?.addResults[0]?.objectId + ''
      const addedSchedule = {
        ...schedule,
        OBJECTID,
        FromHour: moment(schedule?.FromHour).format('HH:mm'),
        ToHour: moment(schedule?.ToHour).format('HH:mm'),
      }

      self.schedules.push(addedSchedule)
    }),
  }))

export interface ISchedule extends Instance<typeof Schedule> {}

export const scheduleStore = ScheduleStore.create({
  schedules: [],
})
