import React from "react"
import { Image, Button } from 'antd'
import routeIcon from './assets/routeIcon.png'

interface Props {
  onClick: () => void
}

export const RouteButton = (props: Props) => {
  const size = 16

  function onClick(e: any) {
    e.stopPropagation()
    props.onClick && props.onClick()
  }

  return (
    <Button
      {...props}
      onClick={onClick}
      shape="circle"
    >
      <Image
        src={routeIcon}
        height={size}
        width={size}
        preview={false}
      />
    </Button>
  )
}
