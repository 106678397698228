import { Button, Form, message } from 'antd'
import React, { useState } from 'react'

import { DatePicker } from 'antd'
import { IProperty } from 'Models/PropertyModel'
import moment from 'moment'

interface BillFormProp {
  property: IProperty
  onSaveFinish?: () => void
}

export const PropertyStatusEditContainer = (props: BillFormProp) => {
  const { property } = props
  const [form] = Form.useForm()
  const [saving, setSaving] = useState(false)

  const initialValues = {
    ...property,
    LastBilledDate: property.LastBilledDate
      ? moment(property.LastBilledDate)
      : undefined,
  }

  async function onSubmit(values: any) {
    setSaving(true)
    const data = {
      ...values,
      LastBilledDate: values.LastBilledDate.valueOf(),
      ActivateDate: moment().valueOf(),
      IsActive: 1,
    }

    try {
      await property.edit(data)
      props.onSaveFinish && props.onSaveFinish()
    } catch (e: any) {
      message.error(e.message)
    }

    setSaving(false)
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 5 }}
      initialValues={initialValues}
    >
      <Form.Item
        label="Start Bill Date"
        name="LastBilledDate"
        rules={[{ required: true, message: 'Required' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 1 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
