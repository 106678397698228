import { Button, Checkbox, message, Modal, Row, Space } from 'antd'
import { CitizenSelector, PropertySelector, Spinner } from 'Components'
import { IProperty, propertyStore } from 'Models/PropertyModel'
import React, { useEffect, useState } from 'react'

import { PlusCircleOutlined } from '@ant-design/icons'
import { PropertyAddContainer } from './Add'
import { PropertyList } from './PropertyList'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { isUserMOE } from 'Auth/Authorization'
import { PropertySearchInput } from 'Components/PropertySearchInput/PropertySearchInput'
import { FilterDaySelector } from 'Components/FilterDaySelector/FilterDaySelector'
import moment from 'moment'
import { arcgisDateFormat } from 'Utils/Date'
import { getQueryByProvince } from 'Models/PropertyModel/api'

const Container = styled.section`
  padding: 20px;
`

const CitizenBox = styled.div`
  margin-bottom: 20px;
`

const Label = styled.div`
  color: gray;
`

const FullRow = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
`

const AddButton = styled(Button)`
  margin-left: auto;
`

interface Props {}

export const PropertyActivationPage = observer((props: Props) => {
  const [shouldShowAddPropertyModal, setShouldShowAddPropertyModal] =
    useState(false)
  const [shouldShowEditPropertyModal, setShouldShowEditPropertyModal] =
    useState(false)
  const [selectedCitizenId, setSelectedCitizenId] = useState<string>()
  const [showPendingProperties, setShowPendingProperties] = useState(false)
  const [filter, setFilter] = useState<any>(null)

  const isMOE = isUserMOE()

  useEffect(() => {
    if (showPendingProperties) {
      loadPendingProperties()
    } else {
      propertyStore.emptyProperties()
    }
  }, [showPendingProperties])

  async function loadPendingProperties() {
    propertyStore.loadPendingActivationProperties()
  }

  function onCitizenIdSelected(id: string) {
    setSelectedCitizenId(id)
    propertyStore.loadPropertyByCitizenId(id)
  }

  function handlePropertySelectedFromSearch(property: IProperty) {
    console.log('property', property)

    setFilter({ propertyId: property?.OBJECTID })
    setShowPendingProperties(false)
  }

  async function handleUpdatedFilterDaySearch(numberOfDay: any) {
    setShowPendingProperties(false)
    setFilter({ updateDay: parseInt(numberOfDay) })
    const startDate = moment()
      .subtract(numberOfDay, 'days')
      .startOf('day')
      .format(arcgisDateFormat)
    const endDate = moment().endOf('day').format(arcgisDateFormat)
    let queryString = `where=(EditDate BETWEEN timestamp '${startDate}' AND timestamp '${endDate}' ${
      getQueryByProvince() ? ' AND ' + getQueryByProvince() : ''
    })&orderByFields=EditDate DESC`
    try {
      await propertyStore.searchProperties(queryString)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  async function handleCreatedFilterDaySearch(numberOfDay: any) {
    setShowPendingProperties(false)
    setFilter({ createDay: parseInt(numberOfDay) })
    const startDate = moment()
      .subtract(numberOfDay, 'days')
      .startOf('day')
      .format(arcgisDateFormat)
    const endDate = moment().endOf('day').format(arcgisDateFormat)
    let queryString = `where=(CreateDate BETWEEN timestamp '${startDate}' AND timestamp '${endDate}' ${
      getQueryByProvince() ? ' AND ' + getQueryByProvince() : ''
    })&orderByFields=CreateDate DESC`
    try {
      await propertyStore.searchProperties(queryString)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  async function handleClear() {
    try {
      await propertyStore.emptyProperties()
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  console.log('filter?.createDay', filter?.createDay)
  return (
    <Container>
      <Row style={{ justifyContent: 'space-between' }}>
        <Space direction="vertical">
          <Checkbox
            checked={showPendingProperties}
            onChange={(e) => {
              setFilter({})
              setShowPendingProperties(e.target.checked)
            }}
            style={{ marginBottom: 15 }}
          >
            Show Pending Activation Properties
          </Checkbox>

          {/* {!showPendingProperties && (
          <CitizenBox>
            <Label>Citizen</Label>
            <CitizenSelector
              onCitizenIdSelected={onCitizenIdSelected}
            ></CitizenSelector>
          </CitizenBox>
        )} */}
        </Space>
        <div>
          <FilterDaySelector
            value={filter?.createDay ?? ''}
            placeholder="Search created properties"
            onChange={(v) => handleCreatedFilterDaySearch(v)}
            onClear={handleClear}
          />
          <FilterDaySelector
            value={filter?.updateDay ?? ''}
            placeholder="Search updated properties"
            onChange={(v) => handleUpdatedFilterDaySearch(v)}
            onClear={handleClear}
          />
          <PropertySearchInput
            value={filter?.propertyId}
            onPropertySelected={handlePropertySelectedFromSearch}
          />
        </div>
      </Row>

      <FullRow>
        {selectedCitizenId && !isMOE && (
          <AddButton
            onClick={() => setShouldShowAddPropertyModal(true)}
            icon={<PlusCircleOutlined />}
          >
            Add
          </AddButton>
        )}
      </FullRow>

      {propertyStore.loading ? (
        <Spinner></Spinner>
      ) : (
        <PropertyList
          properties={propertyStore.properties.slice()}
        ></PropertyList>
      )}

      {shouldShowAddPropertyModal && (
        <Modal
          title="បង្កើតអចលនទ្រព្យ (Add Property)"
          visible={shouldShowAddPropertyModal}
          onCancel={() => setShouldShowAddPropertyModal(false)}
          footer={[]}
          width={600}
        >
          <PropertyAddContainer
            citizenId={selectedCitizenId}
            onSaveFinish={() => setShouldShowAddPropertyModal(false)}
          ></PropertyAddContainer>
        </Modal>
      )}
    </Container>
  )
})
