import { BillingManager } from './../../Auth/Role'
import { user } from 'Models/UserModel/UserModel'
import axios, { CancelTokenSource } from 'axios'

import { environment } from 'environment'
import moment from 'moment'
const { apiBaseUrl } = environment

export interface PropertyQuery {
  geometry?: string
  distant?: number
  calculateBalance?: boolean
  isActive?: number
}

export const getQueryByProvince = (where = false) => {
  if (user?.province_code) {
    if (where) {
      return `&where=Province=${user?.province_code}`
    }
    return `Province=${user?.province_code}`
  }
  return ''
}

const getConcatString = (url: string) => {
  if (url.includes('where')) {
    return ' AND '
  }

  return '&where= '
}

export const getNearbyProperties = async (query: PropertyQuery) => {
  // console.log('getNearbyProperties: ', query)
  let url = `${apiBaseUrl}/api/properties/query?geometryType=esriGeometryPoint&units=esriSRUnit_Meter&geometry=${
    query.geometry
  }&distance=${query.distant}&calculate-balance=${
    query.calculateBalance
  }&outFields=*${getQueryByProvince(true)}`
  if (query.isActive === 0) {
    url += `${getConcatString(url)}IsActive=0 OR IsActive IS NULL`
  } else if (query.isActive === 1) {
    url += `${getConcatString(url)}IsActive=1`
  } else if (query.isActive === 2) {
    url += `${getConcatString(url)}IsActive=2`
  }

  if (user?.role === BillingManager) {
    url += `${getConcatString(url)}IsActive=1`
  }

  const result = await axios.get(url)
  if (result.data.error) {
    throw new Error(result.data.error.message)
  }
  return result.data
}

export const checkPropertiesCanBill = async () => {
  const thisMonth = moment()
    .startOf('month')
    .subtract(1, 'days')
    .format('YYYY-MM-DD')

  const url = `${apiBaseUrl}/api/properties/query?where=(LastBilledDate<'${thisMonth}' OR LastBilledDate=null) AND ActivateDate <'${thisMonth}' AND IsActive=1&outFields=*`

  const result = await axios.get(url)
  return result.data
}

export const edit = async (values: any) => {
  const url = `${apiBaseUrl}/api/properties/patch`
  const data: any = {
    features: {
      attributes: values,
    },
  }

  if (values.geometry) {
    data.features.geometry = values.geometry
  }

  // axios.defaults.headers.common['samraam-agent'] = `android`

  const response = await axios.patch(url, data)
  const { error } = response.data
  if (error) {
    throw new Error(error)
  }
  const success = response.data.updateResults[0].success
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const deleteById = async (id: string) => {
  const url = `${apiBaseUrl}/api/properties/delete/${id}`
  const response = await axios.delete(url)
  const success = response.data.deleteResults[0].success
  if (!success) {
    throw new Error('Failed to delete')
  }
  return response.data
}

export const add = async (data: any) => {
  const url = `${apiBaseUrl}/api/properties/add`
  const response = await axios.post(url, data)
  const success = response.data.addResults[0].success
  if (!success) {
    throw new Error('Failed to add')
  }
  return response.data
}

export const getCitizenProperties = async (citizenId: string) => {
  const query = `where=CitizenID='${citizenId}'`
  const url = `${apiBaseUrl}/api/properties/query?${query}`
  const result = await axios.get(url)
  return result.data
}

export const getPropertiesByRouteId = async (routeId: string) => {
  const query = `where=RouteID='${routeId}'`
  const url = `${apiBaseUrl}/api/properties/query?${query}`
  const result = await axios.get(url)
  return result.data
}

export const getPropertiesByImportId = async (importId: string) => {
  const query = `where=ImportID='${importId}'`
  const url = `${apiBaseUrl}/api/properties/query?${query}`
  const result = await axios.get(url)
  return result.data
}

export const getPendingActivationProperties = async () => {
  const query = `where=IsActive=0 OR IsActive IS NULL&resultRecordCount=100`
  const url = `${apiBaseUrl}/api/properties/query?${query}`
  const result = await axios.get(url)
  return result.data
}

export const getPropertiesWithNoLocation = async () => {
  let query = `where=HasLocation=0 AND Province=${user?.province_code}`

  if (user?.province_code) {
    query += ` AND Province=${user?.province_code}`
  }

  if (user?.role === BillingManager) {
    query += `${getConcatString(query)}IsActive=1`
  }

  let url = `${apiBaseUrl}/api/Properties/query?${query}&outFields=*`

  const result = await axios.get(url)
  return result.data
}

export const getAllProperties = async () => {
  let query = ``

  if (user?.province_code) {
    query += `Province=${user?.province_code}`
  }

  if (user?.role === BillingManager) {
    query += `${getConcatString(query)}IsActive=1`
  }

  let url = `${apiBaseUrl}/api/properties/query?${query}&outFields=*`

  const result = await axios.get(url)
  return result.data
}

let searchRequest: CancelTokenSource
export const searchProperty = async (query: string) => {
  if (searchRequest) {
    searchRequest.cancel()
  }

  searchRequest = axios.CancelToken.source()
  let searchQueryString = `${encodeURI(query)}`

  if (user?.role === BillingManager) {
    searchQueryString += `${getConcatString(searchQueryString)}IsActive=1`
  }

  const url = `${apiBaseUrl}/api/properties/query?${searchQueryString}&outfields=*`
  const result = await axios.get(url, { cancelToken: searchRequest.token })
  if (!result) {
    return []
  }
  if (result.data.error) {
    console.log('result.data.error: ', result.data.error)
    throw new Error('error' + result.data.error)
  }
  return result.data
}

export const getPropertyById = async (propertyId: number) => {
  const query = `where=OBJECTID='${propertyId}'`
  const url = `${apiBaseUrl}/api/properties/query?${query}`
  const result = await axios.get(url)
  return result.data
}
