import { Button, Space, message } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'

import { IProperty } from 'Models/PropertyModel'
import { Map } from 'Components/Map'
import { Marker } from '@react-google-maps/api'
import assignedMarkerIcon from './assets/AssignedMarker.png'
import { observer } from 'mobx-react-lite'
import pendingMarkerIcon from './assets/PendingMarker.png'
import selectedMarkerIcon from './assets/SelectedMarker.png'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  min-height: 400px;
`

const Left = styled.section`
  flex: 1;
  position: relative;
`

interface Props {
  properties: IProperty[]
}

const initialCenter = {
  lat: 10.6343527660001,
  lng: 103.503359524,
}

export const PropertyMap = observer((props: Props) => {
  const { properties } = props
  const [selectedProperty, setSelectedProperty] = useState<IProperty>()
  const [center, setCenter] = useState(initialCenter)
  const [map, setMap] = useState<any>()

  useEffect(() => {
    if (properties.length > 0) {
      const firstProperty = properties[0]
      const location = {
        lat: firstProperty.geometry?.lat || initialCenter.lat,
        lng: firstProperty.geometry?.lng || initialCenter.lng,
      }
      setCenter(location)
      setSelectedProperty(firstProperty)
    }
  }, [properties])

  function onMapDragEnd() {
    const lat = map?.center.lat()
    const lng = map?.center.lng()
    setCenter({
      lat,
      lng,
    })
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  async function onPropertyMarkerClick(property: IProperty) {
    setSelectedProperty(property)
  }

  function onPropertyClick(property: IProperty) {
    map?.panTo(property.geometry)
    setSelectedProperty(property)
  }

  async function onMarkerDragEnd(property: IProperty, event: any) {
    const messageKey = 'update-location'
    message.loading({ content: 'Saving...', key: messageKey })
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    await property.edit({
      geometry: {
        x: lng,
        y: lat,
      },
    })
    message.success({ content: 'Success!', key: messageKey, duration: 2 })
  }

  return (
    <Container>
      <Left>
        <Space>
          {properties?.map((property) => {
            const buttonType =
              property.OBJECTID === selectedProperty?.OBJECTID
                ? 'primary'
                : 'default'
            return (
              <Button
                type={buttonType}
                onClick={() => onPropertyClick(property)}
                key={property.OBJECTID}
              >
                {property.Name}
              </Button>
            )
          })}
        </Space>
        <Map
          center={center}
          zoom={15}
          onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
        >
          {properties.reduce(
            (propertyMarkers: ReactElement<any, any>[], property) => {
              let markerIcon = property.RouteID
                ? assignedMarkerIcon
                : pendingMarkerIcon

              const isSelected =
                property.OBJECTID === selectedProperty?.OBJECTID
              if (isSelected) {
                markerIcon = selectedMarkerIcon
              }

              const label = property.isSaving ? 'Saving...' : ''
              const iconSize = isSelected ? 16 : 8
              if (
                property.geometry &&
                property.geometry.lat &&
                property.geometry.lng
              ) {
                propertyMarkers.push(
                  // @ts-ignore
                  <Marker
                    key={property.OBJECTID}
                    position={property.geometry}
                    onClick={() => onPropertyMarkerClick(property)}
                    label={label}
                    draggable
                    onDragEnd={(event) => onMarkerDragEnd(property, event)}
                    icon={{
                      url: markerIcon,
                      scaledSize: {
                        width: iconSize,
                        height: iconSize,
                      },
                    }}
                  ></Marker>
                )
              }

              return propertyMarkers
            },
            []
          )}
        </Map>
      </Left>
    </Container>
  )
})
