import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 5rem;
  margin: 0 5px 0 5px;
`

type Props = {
  onChange?: (value: SelectValue) => void
  onClear?: () => void
  disabled?: boolean
  value?: string,
  defaultValue?: string
}

export const BusinessStatusSelector = (props: Props) => {
  const { value, disabled, defaultValue } = props

  function onChange(givenValue: any) {
    props.onChange && props.onChange(givenValue)
  }

  function onClear() {
    props.onClear && props.onClear()
  }

  const options = [
    {
      value: 'Negotiate',
      label: 'Negotiate'
    },
    {
      value: 'Write off',
      label: 'Write off'
    },
    {
      value: 'Unsuspend',
      label: 'Unsuspend'
    },
    {
      value: 'Suspend',
      label: 'Suspend'
    },
    {
      value: 'Block',
      label: 'Block'
    },
  ]

  return (
    <StyledSelect
      style={{minWidth: 200}}
      placeholder="Please select"
      value={value ? value : undefined}
      onChange={onChange}
      onClear={onClear}
      disabled={disabled}
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={true}
      allowClear
    >
      {options.map((option) => {
        return (
          <Option key={(option?.value)} value={(option?.value)}>
            {option?.label}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
