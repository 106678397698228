import * as api from './api'

import { Marker, Polyline } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'
import { Spin, message } from 'antd'

import { Button } from 'Components'
import { CollectionPointForm } from './Form'
import { Map } from 'Components/Map'
import { Space } from 'antd'
import assignedMarker from './assets/AssignedMarker.png'
import { handleApiError } from 'Utils'
import { routeStore } from 'Models'
import styled from 'styled-components'

const NORMAL_ROUTE_COLOR = 'gray'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Left = styled.div`
  flex: 1;
  position: relative;
`

const Right = styled.section`
  flex: 0.3;
  height: 100%;
  padding: 20px;
  overflow: scroll;
  background: white;
`

const Spinner = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
`

const SihanoukVil = {
  lat: 10.6343527660001,
  lng: 103.503359524,
}

const PhnonPenh = {
  lat: 11.554944,
  lng: 104.9366131,
}

const Places = [
  {
    name: 'SihanoukVil',
    location: SihanoukVil,
  },
  {
    name: 'Phnom Penh',
    location: PhnonPenh,
  },
]

export const CollectionPointPage = () => {
  const initialCenter = {
    lat: 10.6343527660001,
    lng: 103.503359524,
  }

  const { routes } = routeStore
  const [center, setCenter] = useState(initialCenter)
  const [collectionPoints, setCollectionPoints] = useState<any>([])
  const [loadingCollectionPoints, setLoadingCollectionPoints] = useState(false)
  const [loadingRoute, setLoadingRoute] = useState(false)
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location: any) => {},
  })
  const [currentMarkerPosition, setCurrentMarkerPosition] =
    useState(initialCenter)
  const [saving, setSaving] = useState(false)
  useEffect(() => {
    async function loadCollectionPoints() {
      setLoadingCollectionPoints(true)
      const query = {
        geometry: `${center.lng},${center.lat}`,
        distant: 2000,
      }
      try {
        const points = await api.loadNearbyCollectionPoints(query)
        const mappedPoints = points.map((point: any) => {
          return {
            ...point.attributes,
            geometry: {
              lng: point.geometry.x,
              lat: point.geometry.y,
            },
          }
        })
        setCollectionPoints(mappedPoints)
      } catch (e) {
        handleApiError(e)
      }

      setLoadingCollectionPoints(false)
    }

    loadCollectionPoints()
  }, [center])

  async function loadRoutes() {
    setLoadingRoute(true)
    const query = {
      geometry: `${center.lng},${center.lat}`,
      distant: 1500,
    }

    try {
      await routeStore.loadRoutes(query)
    } catch (e) {
      handleApiError(e)
    }
    setLoadingRoute(false)
  }

  function onMapClicked(event: any) {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    const position = {
      lat,
      lng,
    }

    setCurrentMarkerPosition(position)
  }

  async function onFormSubmit(values: any, form: any) {
    setSaving(true)

    const data = {
      features: {
        attributes: values,
        geometry: {
          x: currentMarkerPosition.lng,
          y: currentMarkerPosition.lat,
        },
      },
    }

    try {
      const result = await api.addCollectionPoint(data)
      const id = result.addResults[0].objectId
      const collectionPoint = {
        ...values,
        OBJECTID: id,
        geometry: {
          ...currentMarkerPosition,
        },
      }

      const points = [...collectionPoints, collectionPoint]
      setCollectionPoints(points)

      form.resetFields()
      setSaving(false)
    } catch (e) {
      handleApiError(e)
      setSaving(false)
    }
  }

  function onMapDragEnd() {
    const lat = map.center.lat()
    const lng = map.center.lng()
    setCenter({
      lat,
      lng,
    })
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  return (
    <Container>
      <Left>
        <Map
          initialCenter={initialCenter}
          center={center}
          zoom={16}
          onClick={onMapClicked}
          onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
          style={{ position: 'relative', width: '75%', height: '100%' }}
        >
          <Marker position={currentMarkerPosition}></Marker>
          {collectionPoints.map((point: any) => {
            return (
              <Marker
                key={point.OBJECTID}
                position={point.geometry}
                icon={{
                  url: assignedMarker,
                  // @ts-ignore
                  scaledSize: {
                    width: 8,
                    height: 8,
                  },
                }}
              ></Marker>
            )
          })}

          {routes.map((route) => {
            return route.Paths.map((Path) => {
              return (
                <Polyline
                  key={Path.id}
                  path={Path.coordinates}
                  options={{
                    strokeColor: NORMAL_ROUTE_COLOR,
                    strokeWeight: 5,
                  }}
                ></Polyline>
              )
            })
          })}
        </Map>
        {loadingCollectionPoints && <Spinner size="large"></Spinner>}
      </Left>
      <Right>
        <section>
          <h3>Place</h3>
          <Space direction="vertical">
            {Places.map((place) => {
              return (
                <Button
                  onClick={() => setCenter(place.location)}
                  key={place.name}
                  type="primary"
                >
                  {place.name}
                </Button>
              )
            })}
          </Space>
        </section>

        <section>
          <h3>Routes</h3>
          <Button loading={loadingRoute} onClick={loadRoutes} type="primary">
            Load Routes
          </Button>
        </section>
        <CollectionPointForm
          saving={saving}
          onSubmit={onFormSubmit}
        ></CollectionPointForm>
      </Right>
    </Container>
  )
}
