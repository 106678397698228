import 'antd/dist/antd.css'
import './App.css'

import {
  BillingPage,
  BusinessPage,
  CitizenPage,
  CollectionCompanyPage,
  CollectionPointPage,
  KswmPage,
  LoginPage,
  PropertyMapPage,
  PropertyActivationPage,
  PropertyPage,
  AllPropertyListPage,
  PropertyTypePage,
  RouteAndProperty,
  RouteAndTruckPage,
  SchedulePage,
  UserPage,
  WelcomePage,
  ImportedDataPage,
  ReportPage,
} from 'Pages'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { HeaderSection } from 'Header'
import { Layout } from 'antd'
import { PrivateRoute } from 'Auth'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { RouteAndPropertyAndBill } from 'Pages/RoutePropertyAndBill/RoutePropertyAndBill'
import { CollectorPage } from 'Pages/Collector'
import { DailyReportPage } from 'Pages/DailyReport/DailyReportPage'
import DistributeReportPage from 'Pages/DistributeReport/DistributeReportPage'
import { CheckLogsPage } from 'Pages/CheckLogs'
import GenerateBillPage from 'Pages/GenerateBill/GenerateBillPage'

const { Content } = Layout

const App = observer(() => {
  return (
    <Router>
      <Layout>
        <HeaderSection></HeaderSection>
        <Content
          style={{
            padding: '0 0',
            marginTop: 0,
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Switch>
            <Route exact path="/login" component={LoginPage} />

            <PrivateRoute path="/" exact>
              <WelcomePage></WelcomePage>
            </PrivateRoute>

            <PrivateRoute path="/route-property">
              <RouteAndProperty></RouteAndProperty>
            </PrivateRoute>

            <PrivateRoute path="/collector">
              <CollectorPage />
            </PrivateRoute>

            <PrivateRoute path="/daily-report">
              <DailyReportPage />
            </PrivateRoute>

            <PrivateRoute path="/check-logs">
              <CheckLogsPage />
            </PrivateRoute>
            
            <PrivateRoute path="/generate-bill">
              <GenerateBillPage />
            </PrivateRoute>

            <PrivateRoute path="/distribute-report">
              <DistributeReportPage />
            </PrivateRoute>

            <PrivateRoute path="/route-property-bill">
              <RouteAndPropertyAndBill></RouteAndPropertyAndBill>
            </PrivateRoute>

            <PrivateRoute path="/collection-points">
              <CollectionPointPage></CollectionPointPage>
            </PrivateRoute>

            <PrivateRoute path="/route-truck">
              <RouteAndTruckPage></RouteAndTruckPage>
            </PrivateRoute>

            <PrivateRoute path="/schedules">
              <SchedulePage></SchedulePage>
            </PrivateRoute>

            <PrivateRoute path="/property-activation">
              <PropertyActivationPage></PropertyActivationPage>
            </PrivateRoute>

            <PrivateRoute path="/property">
              <PropertyPage></PropertyPage>
            </PrivateRoute>

            <PrivateRoute path="/all-property">
              <AllPropertyListPage></AllPropertyListPage>
            </PrivateRoute>

            <PrivateRoute path="/property-map">
              <PropertyMapPage></PropertyMapPage>
            </PrivateRoute>

            <PrivateRoute path="/billing">
              <BillingPage></BillingPage>
            </PrivateRoute>

            <PrivateRoute path="/business">
              <BusinessPage></BusinessPage>
            </PrivateRoute>

            <PrivateRoute path="/citizens">
              <CitizenPage></CitizenPage>
            </PrivateRoute>

            <PrivateRoute path="/property-types">
              <PropertyTypePage></PropertyTypePage>
            </PrivateRoute>

            <PrivateRoute path="/shv-billing">
              <KswmPage></KswmPage>
            </PrivateRoute>

            <PrivateRoute path="/collection-companies">
              <CollectionCompanyPage></CollectionCompanyPage>
            </PrivateRoute>

            <PrivateRoute path="/users">
              <UserPage></UserPage>
            </PrivateRoute>

            <PrivateRoute path="/imported-data">
              <ImportedDataPage></ImportedDataPage>
            </PrivateRoute>

            <PrivateRoute path="/reports">
              <ReportPage></ReportPage>
            </PrivateRoute>
          </Switch>
        </Content>
      </Layout>
    </Router>
  )
})

export default App
