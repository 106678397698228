import { IProperty, propertyStore } from 'Models'
import React, { useState } from 'react'
import { Select, message, Spin } from 'antd'

import styled from 'styled-components'
import { getQueryByProvince } from 'Models/PropertyModel/api'

const { Option } = Select

interface SelectProp {
  loading: boolean
}

const StyledSelect = styled(Select)<SelectProp>`
  width: 250px;
  color: ${(props) => (props.loading ? 'coral' : 'black')};
`

interface Props {
  onPropertyIdSelected?: (id: string) => void
  onPropertySelected?: (property: IProperty) => void
  disabled?: boolean
  allowClear?: boolean
  value?: string
}

export const PropertySearchInput = (props: Props) => {
  const {
    onPropertyIdSelected,
    onPropertySelected,
    disabled,
    value,
    allowClear = false,
  } = props
  const { properties } = propertyStore
  const [loading, setLoading] = useState(false)

  async function search(query: string) {
    setLoading(true)
    const queryString = `where=(Name LIKE '${query}%' OR OwnerPhone LIKE '${query}%' OR OBJECTID LIKE '${query}%' OR OwnerName LIKE '${query}%') ${
      getQueryByProvince() !== '' ? ' AND ' + getQueryByProvince() : ''
    }`
    try {
      await propertyStore.searchProperties(queryString)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }
    }
    setLoading(false)
  }

  function onChange(value: any) {
    const property = properties.find((propertyObj) => {
      return propertyObj.OBJECTID + '' === value
    })
    if (property) {
      onPropertySelected && onPropertySelected(property)
    }
    onPropertyIdSelected && onPropertyIdSelected(value + '')
  }

  function onSearch(val: string) {
    if (val.length > 0) {
      search(val)
    } else {
      propertyStore.emptyProperties()
    }
  }

  return (
    <StyledSelect
      {...props}
      allowClear={allowClear}
      showSearch
      value={value ? value : undefined}
      loading={loading}
      placeholder="Type to Search Property"
      optionFilterProp="children"
      notFoundContent={loading ? <Spin size="small" /> : null}
      onChange={onChange}
      onSearch={onSearch}
      disabled={disabled}
      filterOption={(input, option) => {
        if (option && option.children) {
          const match =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          return match
        } else {
          return false
        }
      }}
    >
      {/* <Option key={'NONE'} value={''}>
        None
      </Option> */}
      {properties?.map((property) => {
        const id = property.OBJECTID ? `id: ${property.OBJECTID}` : ''
        const IDPOI = property.IDPOI ? `, IDPOI: ${property.IDPOI}` : ''
        const name = property.Name ? `, name: ${property.Name}` : ''
        const owner = property.OwnerName ? `, owner: ${property.OwnerName}` : ''
        const contact = property.OwnerPhone
          ? `, contact: ${property.OwnerPhone}`
          : ''
        return (
          <Option key={property.OBJECTID} value={property.OBJECTID + ''}>
            {id + IDPOI + name + owner + contact}
          </Option>
        )
      })}
    </StyledSelect>
  )
}
