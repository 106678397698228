import { Form, message } from 'antd'
import { IProperty, user } from 'Models'
import React, { useState } from 'react'

import { BillForm } from '../Form/BillForm'
import { billStore } from 'Models/BillModel'
import moment from 'moment'
import { componentLevelAuthorization, isAccounting } from 'Auth/Authorization'
import {
  Accounting,
  Admin,
  BillingManager,
  IT,
  KSWM,
  WasteCompany,
} from 'Auth/Role'
import { patchProperty } from 'Models/BillModel/api'

interface Prop {
  property: IProperty | undefined
  onSaveFinish?: () => void
}

export const BillAddContainer = (props: Prop) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  const initialValues = {
    BillForDate: moment(),
    // Amount: props.property?.OldFee,
    // CurrentFee: props.property?.OldFee,
    Amount: props.property?.DiscountedFee || props.property?.CollectionFee,
    CurrentFee: props.property?.CollectionFee,
    Paid: 0,
  }

  async function onSubmit(values: any) {
    const data = {
      ...values,
      BillForDate: values.BillForDate
        ? values.BillForDate.startOf('month').valueOf()
        : undefined,
      PaidDate: values.PaidDate ? values.PaidDate.valueOf() : undefined,
      Editor: user.name,
      CreateDate: moment().valueOf(),
      EditDate: moment().valueOf(),
      PropertyID: props.property && parseInt(props.property.OBJECTID),
      CitizenID: props.property && props.property.CitizenID,
      PropertyStatus: props.property && props.property.IsActive,
    }
    setSaving(true)
    try {
      await billStore.addBill(data)
      if (props.property) {
        await patchProperty(props.property)
      }
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return componentLevelAuthorization(
    <BillForm
      form={form}
      onSubmit={onSubmit}
      initialValues={initialValues}
      saving={saving}
    ></BillForm>,
    [Admin, WasteCompany, KSWM, BillingManager, Accounting, IT]
  )
}
