import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

export const addCollectionPoint = async (data) => {
  const url = `${apiBaseUrl}/api/CollectionPoints/add`
  const result = await axios.post(url, data)
  return result.data
}

export const loadNearbyCollectionPoints = async (query) => {
  const url = `${apiBaseUrl}/api/CollectionPoints/query?geometryType=esriGeometryPoint&units=esriSRUnit_Meter&geometry=${query.geometry}&distance=${query.distant}&where=Type='Public'`
  const result = await axios.get(url)
  return result.data
}
