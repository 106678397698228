import { Button, Form, Input, Select } from 'antd'

import { FormInstance } from 'antd/lib/form'
import React from 'react'

const { Option } = Select

interface Props {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: FormInstance
}

export const PayBillForm = (props: Props) => {
  const { onSubmit, saving, form } = props

  return (
    <Form form={form} onFinish={onSubmit} size="large" labelCol={{ span: 5 }}>
      <Form.Item
        label="Payment Method"
        name="PaymentMethod"
        rules={[{ required: true, message: 'Required' }]}
      >
        <Select>
          <Option value="Cash">Cash</Option>
          <Option value="Bank Transfer">Bank Transfer</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Transaction ID"
        name="TransactionID"
        rules={[{ required: false, message: 'Required' }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          Pay
        </Button>
      </Form.Item>
    </Form>
  )
}
