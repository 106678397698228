import { IProperty, propertyStore } from 'Models/PropertyModel'
import React, { useState } from 'react'

import { Modal } from 'antd'
import { PayBillContainer } from './PayBillContainer'
import { PropertyList } from './PropertyList'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
`

interface Props {}

export const ControlPanel = observer((props: Props) => {
  const { properties } = propertyStore
  const [selectedProperty, setSelectedProperty] = useState<IProperty>()
  const [shouldShowPayBillContainer, setShouldShowPayBillContainer] = useState(
    false
  )

  async function onPayButtonClick(property: IProperty) {
    console.log('onPayButtonClick: ', property)
    setSelectedProperty(property)
    setShouldShowPayBillContainer(true)
  }

  return (
    <Container>
      <div>Properties:</div>
      <PropertyList
        properties={properties}
        onPayButtonClick={onPayButtonClick}
      ></PropertyList>

      {shouldShowPayBillContainer && (
        <Modal
          visible={shouldShowPayBillContainer}
          onCancel={() => setShouldShowPayBillContainer(false)}
          footer={[]}
          width={600}
        >
          <PayBillContainer
            property={selectedProperty!}
            onSaveFinish={() => setShouldShowPayBillContainer(false)}
          ></PayBillContainer>
        </Modal>
      )}
    </Container>
  )
})
