import Bluebird from 'bluebird'
import { PropertyTypeExcelRecord } from './ImportFromExcel'
import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

const addRecord = async (record: PropertyTypeExcelRecord, cb: () => void) => {
  const {
    Code: code,
    Name_Khmer: name_khmer,
    Name_English: name_english,
    Location_Code: locationCode,
  } = record

  const data = {
    code: code + '',
    name_khmer,
    name_english,
    locationCode,
  }

  const url = `${apiBaseUrl}/property-type-and-fees/import`
  const response = await axios.post(url, data)
  cb()
  return response.data
}

export const addRecords = async (
  records: PropertyTypeExcelRecord[],
  onProgress: (addedCount: number) => void
) => {
  let addedCount = 0
  function onAdded() {
    addedCount++
    onProgress(addedCount)
  }

  return Bluebird.map(
    records,
    function (record: any) {
      return addRecord(record, onAdded)
    },
    {
      concurrency: 5,
    }
  )
}
