// @ts-nocheck
import React from 'react'
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import { IProperty } from 'Models'
import TypeMap from '../../../Components/PropertyTypeDisplay/TypeMap'
import { getImage } from 'Utils/files'
import { thousandSeparator } from 'Utils/Currency'
import { propertyTypeStore } from 'Models/PropertyTypeModel/PropertyTypeModel'
import _ from 'lodash'
import { renderStatus } from 'Utils/property'

Font.register({
  family: 'Khmer',
  src: 'https://file.wastecambodia.com/uploads/9bb5279bb6fc7a15e0306f9db1ce3111.ttf', // Hanuman font
})

Font.register({
  family: 'KhMoul',
  src: 'https://file.wastecambodia.com/uploads/5ac3a39e-cc0b-4f2a-bc62-3ce44b3ca75a.ttf', // Moul
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  section: {
    margin: 10,
    padding: 10,
    alignItems: 'center',
  },
  qrSection: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '450',
    marginTop: 30,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 30,
  },
  title: {
    fontFamily: 'KhMoul',
  },
  titleEnglish: {
    fontSize: 16,
    fontFamily: 'Khmer',
  },
  small: {
    fontFamily: 'Khmer',
    fontSize: 10,
  },
  tableSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 8,
  },
  oddRow: {
    backgroundColor: '#f3f3f3',
  },
  pdfContainer: {
    padding: 30,
  },
  titleEn: {
    fontSize: 16,
  },
  titleKh: {
    fontSize: 18,
    fontFamily: 'KhMoul',
  },
  subTitle: {
    fontSize: 18,
    fontFamily: 'Khmer',
  },
  spaceS: {
    width: '100%',
    height: 15,
  },
  spaceM: {
    width: '100%',
    height: 20,
  },
  spaceL: {
    width: '100%',
    height: 30,
  },
  boxContainer: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  left: {
    height: 130,
    width: 300,
    flexGrow: 1,
  },
  right: {
    width: 120,
    height: 150,
  },
  qrContainer: {
    borderWidth: 10,
    borderColor: '#c7c7c7',
    borderRadius: 10,
    borderBottomWidth: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: 10,
  },
  labelContainer: {
    width: 150,
  },
  value: {
    flex: 1,
    height: 20,
  },
  hr: {
    border: 'none',
    height: 3,
    color: '#c7c7c7',
    backgroundColor: '#c7c7c7',
  },
  label: {
    fontFamily: 'Khmer',
    fontSize: 12,
  },
  viewMapLabel: {
    fontFamily: 'Khmer',
    fontSize: 10,
    color: '#ffffff',
  },
  bgGray: {
    backgroundColor: '#c7c7c7',
    marginHorizontal: -1,
    marginBottom: -1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 5,
    fontWeight: 'bold',
  },
  optionalFieldsContainer: {
    flexDirection: 'column',
  },
  itemContainer: {
    flexDirection: 'row',
    paddingBottom: 10,
  },
  imageContainer: {
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
})

const _propertyPropsLabelsMap = {
  OBJECTID: 'លេខសម្គាល់ទីតាំង',
  Name: 'ឈ្មោះ',
  CollectionFee: 'ថ្លៃសេវា',
  Type: 'ប្រភេទ',
  HouseNo: 'លេខ​ផ្ទះ',
  StreetNo: 'លេខ​ផ្លូវ',
  Sangkat: 'សង្កាត់',
  Khan: 'ខណ្ឌ',
  City: 'ក្រុង',
  CitizenID: 'លេខសម្គាល់ម្ចាស់',
  RouteID: 'លេខសម្គាល់ផ្លូវ',
  OwnerName: 'ឈ្មោះ​ម្ចាស់',
  OwnerPhone: 'លេខទូរស័ព្ទម្ចាស់',
}

const optionalFields = {
  Bars: 'បារ',
  Floors: 'ជាន់',
  Restaurants: 'អាហារដ្ឋាន',
  Room: 'បន្ទប់',
  Floor_Area: 'ផ្ទៃជាន់',
  Gym: 'កន្លែងហាត់ប្រាណ',
  Bed: 'គ្រែ',
  Land_Size: 'ទំហំដី',
  BuildingSize: 'ទំហំអាគារ',
  Capacity: 'ទំហំផ្ទុក',
  TableCount: 'ចំនួនតុ',
  BuildingCount: 'ចំនួនអគារ',
  Cafes: 'ហាងកាហ្វេ',
  Building: 'អគារ',
  Chairs: 'កៅអុី',
  Businesses: 'អាជីវកម្ម',
  Marts: 'ម៉ាត',
}

const suffixOptionalFields = {
  Bars: '',
  Floors: '',
  Restaurants: '',
  Room: 'បន្ទប់',
  Floor_Area: 'ម',
  Gym: '',
  Bed: '',
  Land_Size: 'ម',
  BuildingSize: 'ម',
  Capacity: '',
  TableCount: '',
  BuildingCount: '',
  Cafes: '',
  Building: '',
  Chairs: '',
  Businesses: '',
  Marts: '',
}

interface Props {
  property: IProperty
  qrUri?: string
  detailQrUri?: string
}

export const PDFDoc = (props: Props) => {
  const { property, qrUri, detailQrUri } = props
  // const propertyType: IPropertyType = await getPropertyTypeByCode(property.Type ?? '')
  const { HouseNo, StreetNo, Village, Sangkat, Khan, City } = property

  const propertyAddress =
    (HouseNo ? HouseNo + ' ' : '') +
    (StreetNo ? StreetNo + ' ' : '') +
    (Village ? Village + ' ' : '') +
    (Sangkat ? Sangkat + ' ' : '') +
    (Khan ? Khan + ' ' : '') +
    (City ? City + ' ' : '')

  const renderOptionalField = (label: any, value: string, suffix: string) => {
    return (
      <View style={styles.itemContainer}>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>{label} </Text>
        </View>
        {suffix === 'ម' ? (
          <Text style={styles.label}>{value} ម&sup2;</Text>
        ) : (
          <Text style={styles.label}>
            {value} {suffix}
          </Text>
        )}
      </View>
    )
  }

  let propertyTypeName = _.find(propertyTypeStore.propertyTypes, (type) => {
    return type?.code === property?.Type
  })

  const shouldRenderProperty = (fieldName: string) => {
    // @ts-ignore
    return (
      property &&
      property[fieldName] !== undefined &&
      property[fieldName] !== null &&
      property[fieldName] !== 0
    )
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.pdfContainer}>
          <View
            style={[
              styles.boxContainer,
              { justifyContent: 'space-between', alignItems: 'center' },
            ]}
          >
            <View>
              <View>
                <Text style={styles.titleKh}>
                  អត្តសញ្ញាណឌីជីថលសំរាមសំរាប់អចលនទ្រព្យ
                </Text>
              </View>
              <View>
                <Text style={styles.titleEn}>
                  SAMRAAM Digital Property Identity
                </Text>
              </View>
            </View>
            <View style={styles.centerContainer}>
              <Image
                source={{
                  uri: detailQrUri || '',
                  method: 'GET',
                  headers: {},
                  body: '',
                }}
                style={{
                  width: 50,
                }}
              />
              <Text style={styles.small}>ព៌ត៌មានលំអិត</Text>
            </View>
          </View>
          <View style={styles.spaceM}></View>
          <hr style={styles.hr} />
          <View style={styles.spaceM}></View>
          <Text style={styles.subTitle}>ព័ត៌មានអំពីទីតាំង</Text>
          <View style={styles.spaceM}></View>
          <View style={styles.boxContainer}>
            <View style={styles.left}>
              <View style={styles.row}>
                <View style={styles.labelContainer}>
                  <Text style={styles.label}>លេខសំគាល់អតិថិជន/ID </Text>
                </View>
                <View style={styles.value}>
                  <Text style={styles.label}>{property?.OBJECTID} </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.labelContainer}>
                  <Text style={styles.label}>ឈ្មោះ/Owner name </Text>
                </View>
                <View style={styles.value}>
                  <Text style={styles.label}>{property?.OwnerName} </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.labelContainer}>
                  <Text style={styles.label}>ឈ្មោះទីតាំង/Location </Text>
                </View>
                <View style={styles.value}>
                  <Text style={styles.label}> </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.labelContainer}>
                  <Text style={styles.label}>លេខទូរស័ព្ទ/Telephone </Text>
                </View>
                <View style={styles.value}>
                  <Text style={styles.label}>{property?.OwnerPhone} </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.labelContainer}>
                  <Text style={styles.label}>អាស័យដ្ឋាន/Address </Text>
                </View>
                <View style={styles.value}>
                  <Text style={styles.label}>{propertyAddress}</Text>
                </View>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.qrContainer}>
                <Image
                  source={{
                    uri: qrUri || '',
                    method: 'GET',
                    headers: {},
                    body: '',
                  }}
                  style={{
                    width: 100,
                  }}
                />
                <View style={styles.bgGray}>
                  <Text style={styles.viewMapLabel}>មើលទីតាំង </Text>
                </View>
              </View>
            </View>
          </View>
          <hr style={styles.hr} />
          <View style={styles.spaceM}></View>
          <Text style={styles.subTitle}>ព័ត៌មានអំពីតម្លៃសេវា</Text>
          <View style={styles.spaceM}></View>
          <View style={styles.optionalFieldsContainer}>
            <View style={styles.itemContainer}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>ប្រភេទទីតាំង-អាជីវកម្ម</Text>
              </View>
              {/* @ts-ignore */}
              <Text style={styles.label}>{propertyTypeName?.name_khmer} </Text>
            </View>
            {Object.keys(optionalFields).map((key, index) => {
              if (shouldRenderProperty(key)) {
                // @ts-ignore
                return renderOptionalField(
                  optionalFields[key],
                  property[key],
                  suffixOptionalFields[key]
                )
              }
            })}

            <View style={styles.itemContainer}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>ថ្លៃសេវា </Text>
              </View>
              <Text style={styles.label}>
                {thousandSeparator(property.CollectionFee || 0)} ៛
              </Text>
            </View>
            <View style={styles.itemContainer}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>ថ្លៃសេវាចាស់ </Text>
              </View>
              <Text style={styles.label}>
                {thousandSeparator(property.OldFee || 0)} ៛
              </Text>
            </View>
            <View style={styles.itemContainer}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Status </Text>
              </View>
              <Text style={styles.label}>{renderStatus(property)}</Text>
            </View>
            <View style={styles.itemContainer}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Collector </Text>
              </View>
              <Text style={styles.label}>{property?.Collector || 'N/A'}</Text>
            </View>

            <View style={styles.itemContainer}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>មតិ/វាយតម្លៃ </Text>
              </View>
              <Text style={[styles.label, { flex: 1 }]}>{property.Note}</Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 10 }}>
          {property.Photo ? (
            <View style={styles.imageContainer}>
              <Image
                source={{
                  uri: getImage(property.Photo) || '',
                  method: 'GET',
                  headers: {},
                  body: '',
                }}
                style={{
                  height: 180,
                }}
              />
            </View>
          ) : (
            <View style={[styles.imageContainer, { minHeight: 100 }]}>
              <Text style={{ fontSize: 11 }}>No image</Text>
            </View>
          )}
        </View>
        <View style={styles.footer}>
          <Text style={styles.small}>រៀបចំដោយក្រសួងបរិស្ថាន​​</Text>
        </View>
      </Page>
    </Document>
  )
}
