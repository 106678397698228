import React from 'react'
import { Select } from 'antd'

import { IReport, REPORT_TYPES } from 'Models'
import { observer } from 'mobx-react-lite'
import { isUserMOE } from 'Auth/Authorization'

const { Option } = Select

interface Props {
  report: IReport
  onChange?: (report: IReport, type: string) => void
}

export const ReportStatusSelector = observer((props: Props) => {
  const { report, onChange } = props
  const isMOE = isUserMOE()

  function setDefaultValue(value?: string | null) {
    if (!value) return REPORT_TYPES.UNDEFINED
    return value
  }

  return (
    <div>
      <Select
        defaultValue={setDefaultValue(report.status)}
        onChange={(value) => onChange && onChange(report, value)}
        disabled={isMOE}
      >
        <Option
          key={REPORT_TYPES.UNDEFINED}
          value={REPORT_TYPES.UNDEFINED}
          disabled
        >
          {REPORT_TYPES.UNDEFINED}
        </Option>

        <Option key={REPORT_TYPES.PENDING} value={REPORT_TYPES.PENDING}>
          {REPORT_TYPES.PENDING}
        </Option>

        <Option key={REPORT_TYPES.ACCEPTED} value={REPORT_TYPES.ACCEPTED}>
          {REPORT_TYPES.ACCEPTED}
        </Option>

        <Option key={REPORT_TYPES.DONE} value={REPORT_TYPES.DONE}>
          {REPORT_TYPES.DONE}
        </Option>
      </Select>
    </div>
  )
})
