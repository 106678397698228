import moment from 'moment'

export const arcgisDateFormat = 'YYYY-MM-DD HH:mm:ss'
export const monthOnlyFormat = 'MMMM'

export function formatDate(
  date: number | string | Date,
  formatString = 'YYYY-MM-DD'
) {
  if (typeof date === 'number' && String(date).length == 10) {
    return momentDate(date).format(formatString)
  } else {
    return momentDate(date).format(formatString)
  }
}

export function formatDateTime(
  date: number | string | Date,
  formatString = 'YYYY-MM-DD hh:mm'
) {
  if (typeof date === 'number' && String(date).length == 10) {
    return momentDate(date).format(formatString)
  } else {
    return momentDate(date).format(formatString)
  }
}

export function formatMonth(
  date: number | string | Date,
  formatString = 'MMMM-YYYY'
) {
  if (typeof date === 'number' && String(date).length == 10) {
    return momentDate(date).format(formatString)
  } else {
    return momentDate(date).format(formatString)
  }
}

export function momentDate(date: number | string | Date) {
  if (typeof date === 'number' && String(date).length == 10) {
    return moment.unix(date)
  } else {
    return moment(date)
  }
}
