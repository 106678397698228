import axios from 'axios'
import { environment } from 'environment'
const { apiBaseUrl } = environment

export const getSchedulesByRoute = async (routeId) => {
  const url = `${apiBaseUrl}/api/schedules/query?where=RouteID=${routeId}`
  const result = await axios.get(url)
  const data = result.data
  if (data.error) {
    throw new Error(data.error.message)
  }
  return data
}

export const addSchedule = async (data) => {
  const url = `${apiBaseUrl}/api/schedules/add`
  const result = await axios.post(url, data)
  if (result.data.error) {
    console.log('result.data.error: ', result.data.error)
    throw new Error(`Error ${result.error.details[0]}`)
  }
  return result.data
}

export const patchSchedule = async (values) => {
  const url = `${apiBaseUrl}/api/schedules/patch`
  const data = {
    features: {
      attributes: values,
    },
  }
  const response = await axios.patch(url, data)
  const success = response.data.updateResults[0].success
  if (!success) {
    throw new Error('Failed to update')
  }
  return response.data
}

export const deleteSchedule = async (id) => {
  const url = `${apiBaseUrl}/api/schedules/delete/${id}`
  const response = await axios.delete(url)
  const success = response.data.deleteResults[0].success
  if (!success) {
    throw new Error('Failed to delete')
  }
  return response.data
}

export const getRouteById = async (routeId) => {
  const url = `${apiBaseUrl}/api/routes/query?where=OBJECTID=${routeId}`
  const result = await axios.get(url)
  const data = result.data
  if (data.error) {
    throw new Error(data.error.message)
  }
  return data
}
