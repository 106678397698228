import React, { useState } from 'react'
import { useAntdTable } from 'ahooks'
import { Button, DatePicker, Form, Space, Tag } from 'antd'
import axios from 'axios'
import moment from 'moment'
import styled from 'styled-components'

import { environment } from 'environment'
import { CollectorSelector } from 'Components/CollectorSelector'
import { generateBill } from './api'
const { apiBaseUrl } = environment

type Props = {}

const Container = styled.div`
  padding: 20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const GenerateBillPage = (props: Props) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any>(undefined)

  const handleFinish = async (values: any) => {
    const { month, collector } = values
    if (month && collector) {
      setLoading(true)

      const result = await generateBill(month, collector)

      setResult(result?.addResults || [])
      setLoading(false)
    }
  }

  return (
    <Container>
      <Form form={form} onFinish={handleFinish}>
        <Space>
          <Form.Item label="សំរាប់ខែ" name="month" initialValue={''}>
            <DatePicker
              placeholder="ជ្រើសរើសខែ"
              picker="month"
              format={'MM/YYYY'}
            />
          </Form.Item>
          <Form.Item
            label="Collector"
            name="collector"
            rules={[{ required: false, message: 'Required' }]}
          >
            <CollectorSelector />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Create Bill
            </Button>
          </Form.Item>
        </Space>
      </Form>

      {result ? <p>បានបង្កើតវិក្កយបត្រចំនួន {result?.length}</p> : ''}

      {result?.length > 0 &&
        result.map((item: any) => {
          return (
            <Tag key={item?.objectId} color="blue">
              {item?.objectId}
            </Tag>
          )
        })}
    </Container>
  )
}

export default GenerateBillPage
