import { message, Spin } from 'antd'
import React, { useState, useEffect, ReactElement } from 'react'
import styled from 'styled-components'

import { Map } from 'Components/Map'
import { ReportList } from 'Components/ReportList'
import { IReport, reportStore } from 'Models'
import { ReportQuery } from 'Models/ReportModel/api'
import { InfoWindow, Marker } from '@react-google-maps/api'
import { PrimaryColor } from 'Utils/Color'
import { ReportInfoWindow } from 'Components/ReportInfoWindow/ReportInfoWindow'

import anarchyLitteringSelected from 'Assets/AnarchyLitteringSelected.png'
import anarchyLitteringUnselected from 'Assets/AnarchyLitteringUnselected.png'
import cloggedTrashSelected from 'Assets/CloggedTrashSelected.png'
import cloggedTrashUnselected from 'Assets/CloggedTrashUnselected.png'
import constructionSelected from 'Assets/ConstructionSelected.png'
import constructionUnselected from 'Assets/ConstructionUnselected.png'
import familySelected from 'Assets/FamilySelected.png'
import familyUnselected from 'Assets/FamilyUnselected.png'
import lateWasteSelected from 'Assets/LateWasteSelected.png'
import lateWasteUnselected from 'Assets/LateWasteUnselected.png'
import parkSelected from 'Assets/ParkSelected.png'
import parkUnselected from 'Assets/ParkUnselected.png'
import undefinedMarkerSelected from 'Assets/UndefinedWasteSelected.png'
import undefinedMarkerUnselected from 'Assets/UndefinedWasteUnselected.png'
import { observer } from 'mobx-react-lite'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
`

const Left = styled.section`
  flex: 1;
  position: relative;
`

const Right = styled.section`
  flex: 0.7;
  height: 100%;
  padding: 30px;
  overflow: scroll;
  background: white;
`

const Spinner = styled(Spin)`
  position: relative;
  background: white;
  border: 1px solid ${PrimaryColor};
  padding: 18px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
`

export const ReportPage = observer(() => {
  const { reports } = reportStore
  const initialCenter = {
    lat: 10.6343527660001,
    lng: 103.503359524,
  }

  const [isLoading, setIsLoading] = useState(false)
  const [selectReportId, setSelectReportId] = useState<string>()
  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location: any) => {},
  })
  const [center, setCenter] = useState(initialCenter)

  useEffect(() => {
    loadAllProperty()
  }, [center])

  async function loadAllProperty() {
    try {
      setIsLoading(true)
      await reportStore.loadAllReport()
      setIsLoading(false)
    } catch (e: any) {
      console.log('error at report page: ', e)
      message.error(e.message)
    }
  }

  function getMarker(report: IReport) {
    switch (report.type) {
      case 'Illegal Dumping':
        return report.id + '' === selectReportId
          ? anarchyLitteringSelected
          : anarchyLitteringUnselected
      case 'Clogged Waste Bin':
        return report.id + '' === selectReportId
          ? cloggedTrashSelected
          : cloggedTrashUnselected
      case 'Construction':
        return report.id + '' === selectReportId
          ? constructionSelected
          : constructionUnselected
      case 'Family':
        return report.id + '' === selectReportId
          ? familySelected
          : familyUnselected
      case 'Late or No Waste Collection':
        return report.id + '' === selectReportId
          ? lateWasteSelected
          : lateWasteUnselected
      case 'Park':
        return report.id + '' === selectReportId ? parkSelected : parkUnselected
      default:
        return report.id + '' === selectReportId
          ? undefinedMarkerSelected
          : undefinedMarkerUnselected
    }
  }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
  }

  function onMapClick() {
    setSelectReportId(undefined)
  }

  function onMapDragEnd() {
    const lat = map.center.lat()
    const lng = map.center.lng()
    setCenter({
      lat,
      lng,
    })
    setSelectReportId(undefined)
  }

  function onReportMarkerClick(report: IReport) {
    setSelectReportId(report.id + '')
    map.panTo(report.geometry)
  }

  function onLocationClick(report: IReport) {
    report.geometry && map.panTo(report.geometry)
    setSelectReportId(report.id + '')
  }

  function onDeleteClick() {
    setSelectReportId(undefined)
  }

  return (
    <Container>
      <Left>
        <Map
          center={center}
          zoom={15}
          // onDragEnd={onMapDragEnd}
          onLoad={onMapLoaded}
          onClick={onMapClick}
        >
          {isLoading && <Spinner />}
          {!isLoading &&
            (selectReportId
              ? reportStore.getSelectedReportListById(selectReportId)
              : reports
            ).reduce((reportMarkers: ReactElement<any, any>[], report) => {
              const iconSize = report.isSelected ? 40 : 30
              if (
                report.geometry &&
                report.geometry.lat &&
                report.geometry.lng
              ) {
                reportMarkers.push(
                  // @ts-ignore
                  <Marker
                    key={report.id}
                    position={report.geometry}
                    onClick={() => onReportMarkerClick(report)}
                    icon={{
                      url: getMarker(report),
                      scaledSize: {
                        width: iconSize,
                        height: iconSize,
                      },
                    }}
                  >
                    {selectReportId && report.geometry && (
                      <InfoWindow
                        // @ts-ignore
                        position={report.geometry}
                        onCloseClick={onMapClick}
                      >
                        <ReportInfoWindow report={report} />
                      </InfoWindow>
                    )}
                  </Marker>
                )
              }

              return reportMarkers
            }, [])}
        </Map>
      </Left>
      <Right>
        <ReportList
          reports={
            selectReportId
              ? reportStore.getSelectedReportListById(selectReportId)
              : reports
          }
          onLocationClick={onLocationClick}
          onDeleteClick={onDeleteClick}
        />
      </Right>
    </Container>
  )
})
