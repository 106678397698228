import * as api from './api'

import React, { useEffect, useState } from 'react'
import { Select, message } from 'antd'

import { Collector } from './api'
import styled from 'styled-components'
import { collectorStore } from 'Models/CollectorModel'
import { useDebounce, useDebounceEffect, useDebounceFn } from 'ahooks'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 250px;
`

interface Props {
  value?: string
}

export const CollectorSelector = (props: Props) => {
  const [collectors, setCollectors] = useState<Collector[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      const result = await collectorStore.collectorByCode(props.value || '')
      setCollectors([result])
    }

    if (props?.value) {
      loadData()
    }
  }, [])

  const { run } = useDebounceFn(
    (val) => {
      searchCollectors(val)
    },
    {
      wait: 500,
    }
  )

  async function searchCollectors(val: string) {
    setLoading(true)
    try {
      const queryCollectors = await api.getCollectors(val)
      setCollectors(queryCollectors)
    } catch (e: any) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }

      setCollectors([])
    }
    setLoading(false)
  }

  function onSearch(val: string) {
    if (val.length > 0) {
      run(val)
    } else {
      setCollectors([])
    }
  }

  return (
    <StyledSelect
      {...props}
      filterOption={false}
      showSearch
      loading={loading}
      options={collectors.map(({ collector_code, name }: any) => {
        return { label: collector_code, value: collector_code }
      })}
      placeholder="Type name or code to search collector"
      onSearch={onSearch}
    />
  )
}
