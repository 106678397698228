import { Form, message } from 'antd'
import React, { useState } from 'react'

import { UserForm } from '../Form/UserForm'
import styled from 'styled-components'
import { userStore } from 'Models'

const Container = styled.section``

interface Props {
  onSaveFinish?: () => void
}

export const UserAdd = (props: Props) => {
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  const initialValues = {}

  async function onSubmit(values: any) {
    const data = {
      ...values,
      email: values.username + '@wastecambodia.com',
    }
    setSaving(true)
    try {
      await userStore.addUser(data)
    } catch (e: any) {
      message.error(e.message)
    }
    setSaving(false)
    props.onSaveFinish && props.onSaveFinish()
  }

  return (
    <Container>
      <UserForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        saving={saving}
        isAdd={true}
      ></UserForm>
    </Container>
  )
}
