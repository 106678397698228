import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

export const CustomImport = types
  .model('CustomImport', {
    id: types.identifier,
    name: types.string,
    isSelected: false,
    isSaving: false,
    isDeleting: false,
  })
  .actions((self) => ({
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteCustomImportById(self.id + '')
      const store = getParent(self, 2) as Instance<typeof CustomImportStore>
      store.remove(self)
    }),
    setSelected(selected: boolean) {
      self.isSelected = selected
    },
    setIsSaving(saving: boolean) {
      self.isSaving = saving
    },
  }))
  .views((self) => ({
    // get FormattedAddress() {
    //   console.log('formatted', self.HouseNo)
    //   return `${self.HouseNo}, ${self.StreetNo}, ${self.Sangkat}, ${self.Khan}, ${self.City}`
    // },
  }))

const CustomImportStore = types
  .model('CustomImportStore', {
    customImports: types.optional(types.array(CustomImport), []),
    selectedCustomImport: types.safeReference(CustomImport),
    loading: false,
  })
  .actions((self) => ({
    loadCustomImport: flow(function* () {
      self.loading = true
      applySnapshot(self.customImports, [])
      const customImports = yield api.getCustomImport()

      applySnapshot(self.customImports, customImports)
      self.loading = false
    }),

    addCustomImport: flow(function* (customImport: ICustomImport) {
      const result = yield api.createCustomImport()
      self.customImports.push(result)
      return result
    }),

    remove(item: any) {
      self.customImports.splice(self.customImports.indexOf(item), 1)
    },

    emptyCustomImports() {
      applySnapshot(self.customImports, [])
    },

    setSelectedCustomImports(customImport: string | undefined) {
      // @ts-ignore:
      self.selectedCustomImports = customImport
    },
  }))

export interface ICustomImport extends Instance<typeof CustomImport> {}

export const customImportStore = CustomImportStore.create({
  customImports: [],
  selectedCustomImport: '1',
})
