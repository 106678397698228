import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

const Geometry = types.model('Geometry', {
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
})

export const Citizen = types
  .model('Citizen', {
    id: types.identifier,
    // CustomerID: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    gender: types.maybeNull(
      types.union(types.literal('Male'), types.literal('Female'))
    ),
    dateOfBirth: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    picture: types.maybeNull(types.string),
    balance: types.maybeNull(types.number),
  })

export const Report = types
  .model('Report', {
    id: types.identifier,
    citizen: types.maybeNull(Citizen),
    description: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    photos: types.maybeNull(types.array(types.string)),
    status: types.maybeNull(types.string),
    geometry: types.maybe(Geometry),
    createdAt: types.string,
    isSelected: false,
    isDeleting: false,
  })
  .actions((self) => ({
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.id)
      const store = getParent<IReportStore>(self, 2)
      self.isDeleting = false
      store.removeReport(self as IReport)
    }),

    edit: flow(function* (values) {
      const data = {
        ...values,
      }
      yield api.edit(self.id, data)
      const updated = {
        ...self,
        ...values,
      }
      applySnapshot(self, updated)
    }),
  }))

export interface IReport extends Instance<typeof Report> {}
export interface IReportStore extends Instance<typeof ReportStore> {}

const ReportStore = types
  .model('ReportStore', {
    reports: types.optional(types.array(Report), []),
    selectedReport: types.safeReference(Report),
    isLoading: false,
  })
  .actions((self) => ({
    // loadNearbyReport: flow(function* (query: api.ReportQuery) {
    //   self.isLoading = true
    //   applySnapshot(self.reports, [])
    //   const reports = yield api.getNearbyReports(query)

    //   const mappedReports = reports.map((report: any) => {
    //     return {
    //       ...report.attributes,
    //       geometry: {
    //         lng: report.geometry?.longitude,
    //         lat: report.geometry?.latitude,
    //       },
    //     }
    //   })
    //   applySnapshot(self.reports, mappedReports)
    //   self.isLoading = false
    // }),

    loadAllReport: flow(function* () {
      self.isLoading = true
      applySnapshot(self.reports, [])
      const reports = yield api.getAllReports()

      const mappedReports = reports.map((report: any) => {
        return {
          ...report,
          geometry: {
            lng: report.geometry?.longitude,
            lat: report.geometry?.latitude,
          },
        }
      })
      console.log('maooef', mappedReports)

      applySnapshot(self.reports, mappedReports)
      self.isLoading = false
    }),

    removeReport(report: IReport) {
      self.reports.splice(self.reports.indexOf(report), 1)
    },
  }))
  .views((self) => ({
    get getSelectedReportList() {
      return [self.selectedReport]
    },

    getSelectedReportListById(id: string) {
      return self.reports.filter((report) => report.id === id)
    },
  }))

export const reportStore = ReportStore.create({
  reports: [],
  selectedReport: undefined,
})

export enum REPORT_TYPES {
  UNDEFINED = 'Undefined',
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  DONE = 'Done',
}
